export const calculateTargetForRange = (amount?: number, startDateStr?: string, endDateStr?: string) => {
  const today = new Date()
  const month = today.getMonth() + 1
  let differenceInMonths = 12 - month // Default to remaining months in the current year

  if (startDateStr && endDateStr) {
    const startDate = new Date(startDateStr)
    const endDate = new Date(endDateStr)

    // ensure today is between the date range
    if (startDate <= today && endDate >= today) {
      let months = (endDate.getFullYear() - today.getFullYear()) * 12
      months -= today.getMonth()
      months += endDate.getMonth()
      differenceInMonths = months <= 0 ? 1 : months
    } else {
      // Minimum of 1 month difference even if endDate is past
      differenceInMonths = 1
    }
  }

  return Math.ceil((amount || 0) / differenceInMonths)
}
