import { Observable } from 'rxjs'

import config from 'src/config'
import { FlippedCoordinates } from 'src/utils/coordinates'

import 'whatwg-fetch'

export const getTimeZoneFromGoogle = (
  location: { coordinates: FlippedCoordinates; type?: any },
  timestamp?: number
): Observable<any> => {
  // @NOTE-CH: Google wants location=[Latitude,Longitude], but for Deeds (the only place this code is used), we have [Longitude,Latitude]
  // Go read the comments on `addressToLocation` file
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.coordinates[1]},${
    location.coordinates[0]
  }&timestamp=${(timestamp ?? Date.now()) / 1000}&key=${config.googleMapsApiKey}`

  return Observable.from(fetch(url))
    .mergeMap(async (response) => response.json())
    .catch((error) => {
      throw error
    })
}
