import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View, StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Action, ActivityIndicator, Button, Form, Screen, Text, TextField } from 'src/retired/elements'
import { injectReducer, injectEpics, validators } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { colors, metrics } from 'src/theme'

import { selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch',
    flex: 1,
  },
  field: {
    marginTop: 25,
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  label: {
    marginRight: 15,
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 10,
  },
})

export const RegisterEmail = ({
  submitting,
  actions,
  values,
  errors,
  touched,
  isValid,
  setFieldValue,
  setFieldTouched,
}) => {
  const { t } = useTranslation('emailRegister')
  const handleSubmit = (e) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    if (values.email && isValid) {
      actions.submitAction(values.email)
    }
  }
  return (
    <Screen fixed>
      <Screen padding narrow action middle={metrics.screenWidth > 640}>
        <Form onSubmit={handleSubmit} style={styles.form}>
          <Text size={24} lineHeight={32} bold style={{ alignSelf: 'stretch' }}>
            {t`pleaseConfirmYourEmailAddress`}
          </Text>
          <View style={styles.field}>
            <TextField
              onChangeText={setFieldValue}
              onTouched={setFieldTouched}
              placeholder={t`common:email`}
              name="email"
              value={values.email}
              maxLength={50}
              keyboardType="email-address"
              autoCapitalize="none"
              autoFocus
              onSubmitEditing={handleSubmit}
            />
          </View>
          <Text color={colors.redDark} style={styles.error}>
            {touched.email && errors.email && errors.email}
          </Text>
          <Text size={20}>{t`yourEmailWillOnlyBeUsed`}</Text>
        </Form>
      </Screen>
      <Action>
        <Button type="submit" disabled={!values.email && !isValid} onPress={handleSubmit} color="primary" fluid>
          {submitting ? <ActivityIndicator color="#fff" /> : t`confirm`}
        </Button>
      </Action>
    </Screen>
  )
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerEmail', reducer })
const withEpics = injectEpics({ key: 'registerEmail', epics })
const withForm = withFormik({
  validate: (values) => {
    const errors = {
      email: validators.notEmpty(values.email) || validators.isEmail(values.email),
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  enableReinitialize: true,
  mapPropsToValues: ({ user }) => ({
    email: user && user.facebook && user.facebook.email ? user.facebook.email : '',
  }),
  validateOnBlur: true,
  validateOnChange: true,
  isInitialValid: true,
})

export default compose(withReducer, withEpics, withConnect, withForm)(RegisterEmail)
