import { fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'
import { CampaignScreenActions } from 'src/containers/screens/Campaign/actions'

import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export type CampaignScreenState = TypedMap<{ loading: boolean; error: null | Error }>

export const initialState = fromJS({ loading: false, error: null }) as CampaignScreenState

export default (state = initialState, action: CampaignScreenActions | { type: 'RESET' }): CampaignScreenState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
