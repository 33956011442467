import { LocalSettingsMutable } from 'src/localSettings/reducer'

import { SET } from './constants'

export interface LocalSettingsSetAction<T extends keyof LocalSettingsMutable = keyof LocalSettingsMutable> {
  type: typeof SET
  key: T
  value: LocalSettingsMutable[T]
}

export const setLocalSettingAction = <T extends keyof LocalSettingsMutable>(
  key: T,
  value: LocalSettingsMutable[T]
): LocalSettingsSetAction<T> => ({
  type: SET,
  key,
  value,
})
