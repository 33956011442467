import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export const initAction = () => ({
  type: INIT,
})

export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})

export const initFailedAction = (error: any, errorCode: any) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})
