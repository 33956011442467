import i18n from 'i18next'
import moment from 'moment'

import Donation from 'src/entities/donation/model'

export enum FrequencyType {
  P1M = 'P1M',
  P3M = 'P3M',
  P1Y = 'P1Y',
}

export const frequencyOptions = {
  P1M: {
    adverb: i18n.t('common:monthly'),
    noun: i18n.t('common:month'),
  },
  P3M: {
    adverb: i18n.t('common:quarterly'),
    noun: i18n.t('common:quarter'),
  },
  P1Y: {
    adverb: i18n.t('common:annually'),
    noun: i18n.t('common:year'),
  },
  PPC: {
    adverb: i18n.t('common:everyPayCheck'),
    noun: i18n.t('common:payCheck'),
  },
  OneTime: {
    adverb: i18n.t('common:oneTime'),
    noun: i18n.t('common:oneTime'),
  },
  Payroll: {
    adverb: i18n.t('common:everyPayCheck'),
    noun: i18n.t('common:payCheck'),
  },
}

export const getNextPaymentDate = (startDate: Date): Date => {
  const now = moment(new Date())
  const diff = moment.duration(now.diff(moment(startDate))).asMonths()
  return moment(startDate).add(Math.ceil(diff), 'M').toDate()
}

export const getNextPaymentDateFromLatestDonation = (scheduleStartDate: Date, donation: Donation | undefined) => {
  const now = moment(new Date())
  if (donation?.createdAt && moment(donation?.createdAt).isBefore(now, 'month')) {
    return getNextPaymentDate(scheduleStartDate)
  }
  const diff = moment.duration(now.diff(moment(donation?.createdAt ?? scheduleStartDate))).asMonths()
  return moment(now).add(Math.ceil(diff), 'M').toDate()
}
