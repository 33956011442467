import { SEARCH_NONPROFITS, SEARCH_SUCCESS, SEARCH_FAILED } from './constants'

export interface SearchNonprofits {
  type: typeof SEARCH_NONPROFITS
  country: string
  search: string
  acceptsDonations?: boolean
  excludeDonationProviders: string[]
  populateMatchingResults?: boolean
}
export const searchNonprofitsAction = (
  country: string,
  search: string,
  acceptsDonations?: boolean,
  excludeDonationProviders: string[] = [],
  populateMatchingResults?: boolean
): SearchNonprofits => ({
  type: SEARCH_NONPROFITS,
  country,
  search,
  acceptsDonations,
  excludeDonationProviders,
  populateMatchingResults,
})

export const searchSuccessAction = (value = true) => ({
  type: SEARCH_SUCCESS,
  value,
})

export const searchFailedAction = (error: object) => ({
  type: SEARCH_FAILED,
  error,
})
