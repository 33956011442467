// @NOTE-AC: Ideally we'd have this in a package
// See https://github.com/joindeed/marketplace/pull/1968

import type { CurrencyCode, CurrencyRates } from 'src/containers/modules/CurrencyFormat'

const getRate = ({ usdRates, currency }: { usdRates: CurrencyRates; currency: CurrencyCode }): number => {
  const currencyRate = usdRates[currency]
  if (!currencyRate) {
    throw new Error(`Could not find rate for currency: ${currency}`)
  }
  return currencyRate
}

export const convertUsdToCurrency = ({
  amount,
  usdRates,
  targetCurrency,
}: {
  amount: number
  usdRates: CurrencyRates
  targetCurrency: CurrencyCode
}): number => {
  const currencyRate = getRate({ usdRates, currency: targetCurrency })
  return amount / currencyRate
}

export const convertCurrencyToUsd = ({
  amount,
  usdRates,
  sourceCurrency,
}: {
  amount: number
  sourceCurrency: CurrencyCode
  usdRates: CurrencyRates
}): number => {
  const currencyRate = getRate({ usdRates, currency: sourceCurrency })
  return amount * currencyRate
}

export const currencyConvert = ({
  amount,
  usdRates,
  sourceCurrency,
  targetCurrency,
}: {
  amount: number
  usdRates: CurrencyRates
  sourceCurrency: CurrencyCode
  targetCurrency: CurrencyCode
}): number => {
  const amountUsd = convertCurrencyToUsd({ amount, usdRates, sourceCurrency })
  return convertUsdToCurrency({ amount: amountUsd, usdRates, targetCurrency })
}
