import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { selectIsNew } from 'src/entities/auth/selectors'
import { selectCurrentUser, selectUserBrandColor } from 'src/entities/user/selectors'
import { Gradient, Image } from 'src/retired/elements'
import { H3 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

const WelcomeBanner = () => {
  const { metrics, colors } = useDeedTheme()

  const { t } = useTranslation('homeScreen')

  const user = useSelector(selectCurrentUser)
  const brandColor = useSelector(selectUserBrandColor)
  const hasBanner = user?.isEmployee() && !!user.organization.homepageBanner
  const hideHomepageTagline = user?.isEmployee() && user.organization.hideHomepageTagline
  const height = metrics.isSmall ? 180 : 274
  const isNew = useSelector(selectIsNew)
  const userName = user?.fullName?.first || user?.name
  const WelcomeTextContent = t('letsDoSomethingGood', {
    context: metrics.isMedium || metrics.isLarge ? '' : 'narrow',
  })

  if (!hasBanner && hideHomepageTagline) {
    return null
  }

  return (
    <View
      style={{
        backgroundColor: hasBanner ? colors.black : brandColor || colors.darkBlue,
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        height,
      }}
    >
      {hasBanner && (
        <>
          <Image source={{ uri: user?.organization.homepageBanner }} style={{ width: '100%', height }} />
          {user?.organization.homepageGradient && (
            <Gradient
              height="100%"
              startColor={colors.imageOverlay}
              startOpacity={0.2}
              endColor={colors.imageOverlay}
              endOpacity={0.95}
              vertical
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          )}
        </>
      )}
      {!hideHomepageTagline && (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: '10%',
          }}
        >
          <H3 colour={colors.white} style={{ opacity: 0.9, fontSize: metrics.isSmall ? 24 : 38 }} weight="bold" center>
            {!isNew ? t(`welcomeBack`, { userName }) : t(`welcome`, { userName })}
            {'\n'}
            {user?.isEmployee() && user.organization.homepageTagline
              ? user.organization.homepageTagline
              : WelcomeTextContent}
          </H3>
        </View>
      )}
    </View>
  )
}
export default WelcomeBanner
