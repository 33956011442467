import { Observable } from 'rxjs'
import _ from 'lodash'
import i18n from 'i18next'

import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'
import DeedsApi from 'src/entities/deed/api'
import RelationApi from 'src/entities/relation/api'

import {
  initSuccessAction,
  initFailedAction,
  refreshSuccessAction,
  refreshFailedAction,
  relationAcceptFailedAction,
  relationRejectFailedAction,
  invitationRequestFinishedAction,
  relationRequestFinishedAction,
  invitationRejectFailedAction,
} from './actions'
import { INIT, REFRESH, RELATION_ACCEPT, RELATION_REJECT, INVITATION_REJECT } from './constants'
import { selectInitialized } from './selectors'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    if (selectInitialized(state)) {
      return []
    }

    return RelationApi.fetch()
      .mergeMap((resultingAction) => [resultingAction, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const refresh = (action$) =>
  action$.ofType(REFRESH).exhaustMap(() =>
    Observable.combineLatest(RelationApi.fetch(), UserApi.fetch('me'))
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), refreshSuccessAction()])
      .catch((e) => Observable.of(refreshFailedAction(e)))
  )

const relationAccept = (action$) =>
  action$.ofType(RELATION_ACCEPT).exhaustMap(({ id }) =>
    RelationApi.accept(id)
      .mergeMap((resultingAction) =>
        UserApi.fetch('me').mergeMap((userUpdateAction) => [
          resultingAction,
          userUpdateAction,
          relationRequestFinishedAction(id),
          showMessageAction(i18n.t('notificationsScreen:friendRequestAccepted')),
        ])
      )
      .catch((e) =>
        Observable.of(
          showErrorAction(i18n.t('notificationsScreen:failedFriendRequest')),
          relationRequestFinishedAction(id),
          relationAcceptFailedAction(e)
        )
      )
  )
const relationReject = (action$) =>
  action$.ofType(RELATION_REJECT).exhaustMap(({ id }) =>
    RelationApi.reject(id)
      .mergeMap((resultingAction) => [
        resultingAction,
        relationRequestFinishedAction(id),
        showMessageAction(i18n.t('notificationsScreen:friendRequestRejected')),
      ])
      .catch((e) =>
        Observable.of(
          showErrorAction(i18n.t('notificationsScreen:failedRejecting')),
          relationRequestFinishedAction(id),
          relationRejectFailedAction(e)
        )
      )
  )

const invitationReject = (action$) =>
  action$.ofType(INVITATION_REJECT).exhaustMap(({ id }) =>
    DeedsApi.declineInvite(id)
      .mergeMap((resultingAction) =>
        UserApi.fetch('me').mergeMap((userUpdateAction) => [
          resultingAction,
          userUpdateAction,
          invitationRequestFinishedAction(id),
          showMessageAction(i18n.t('notificationsScreen:invitationRejected')),
        ])
      )
      .catch((e) =>
        Observable.of(
          showErrorAction(i18n.t('notificationsScreen:failedInvitation')),
          invitationRequestFinishedAction(id),
          invitationRejectFailedAction(e)
        )
      )
  )

export default [init, refresh, relationAccept, relationReject, invitationReject]
