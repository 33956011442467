import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedLocationCodeAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedLocationCodeSynchronizer = () => {
  const dispatch = useDispatch()
  const { location } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    if (!location) {
      dispatch(setSelectedLocationCodeAction(null))
      return
    }

    dispatch(setSelectedLocationCodeAction(location))
  }, [location])

  return null
}
