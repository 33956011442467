import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { stringify } from 'query-string'

import * as NonprofitActions from 'src/containers/screens/Nonprofits/actions'
import * as OrganizationActions from 'src/entities/organization/actions'
import * as FeedActions from 'src/containers/screens/Feed/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { selectFilteredDeedsByAllFilters } from 'src/containers/modules/Search/selectors'
import { selectDisplayedLocalOrganizations } from 'src/entities/organization/selectors'
import epics from 'src/containers/screens/Nonprofits/epics'
import feedEpics from 'src/containers/screens/Feed/epics'
import feedReducer from 'src/containers/screens/Feed/reducer'
import nonprofitsReducer from 'src/containers/screens/Nonprofits/reducer'
import { OrganizationMap } from 'src/entities/organization/reducer'
import { selectDeedTypeFeedCurrentPage } from 'src/containers/screens/Feed/selectors'

import { useSearchParams } from '../../utils'

import { DonateResults } from './DonateResults'

export const DonateResultsContainer = () => {
  useInjectReducer({ key: 'nonprofits', reducer: nonprofitsReducer })
  useInjectEpics({ key: 'nonprofits', epics })
  useInjectReducer({ key: 'feed', reducer: feedReducer })
  useInjectEpics({ key: 'feed', epics: feedEpics })

  const dispatch = useDispatch()

  const { searchTerm, location, donateTypes, currentActiveCauses, categories, ergs, pillars, sdgs } = useSearchParams()
  const query = {
    searchTerm,
    location,
    categories,
    ergs,
    pillars,
    sdgs,
  }

  // All nonprofit deeds filtered by the search term
  const filteredNonprofits = useSelector(selectDisplayedLocalOrganizations) as unknown as OrganizationMap
  // All fundraiser deeds filtered by the search term
  const deedType = 'Campaign'
  const filteredFundraisers = useSelector((state) =>
    selectFilteredDeedsByAllFilters({ searchTerm, causes: currentActiveCauses, location, deedType })(state, deedType)
  )

  // This triggers the filter api request and updates the search/nonprofits/entities->organizations->displayedLocalOrganizationIds stores
  // It also handles pagination for nonprofits (nonprofits store)
  // (It checks the current page and increases it for the next request)

  const searchNonprofits = () => {
    dispatch(
      NonprofitActions.searchNonprofits(
        false,
        `?${stringify(
          {
            searchTerm,
            location,
            // we use _name here because we don't want to use the translated value for the api request
            cause: currentActiveCauses?.size
              ? [...new Set(currentActiveCauses.map((cause) => cause._name).toArray())].join() // sometimes custom causes and public causes have the same name that's why we make the array unique here
              : undefined,
          },
          { skipEmptyString: true }
        )}`,
        true
      )
    )
  }

  const currentPage = useSelector((state) => selectDeedTypeFeedCurrentPage(state, 'Campaign'))
  const loadMoreFundraisers = () => {
    dispatch(FeedActions.loadFeedForDeedTypeAction('Campaign', { ...query, page: currentPage + 1, isLoadMore: true }))
  }

  useEffect(() => {
    // Needed for initial render when there are search params in the url: Even without this screen doing anything, a lot
    // of actions are triggered. One of them loads nonprofits without any search filter/term.
    // We want to abort that and make our own 'real' search
    dispatch(NonprofitActions.cancelSearchAction())
    dispatch(OrganizationActions.clearDisplayedOrganizationIdsAction())
    dispatch(NonprofitActions.resetNonprofits())
    searchNonprofits()
  }, [searchTerm, location, currentActiveCauses])

  return (
    <DonateResults
      fundraisers={filteredFundraisers}
      nonprofits={filteredNonprofits}
      searchTerm={searchTerm}
      loadMoreNonprofits={searchNonprofits}
      loadMoreFundraisers={loadMoreFundraisers}
      donateTypes={donateTypes}
    />
  )
}
