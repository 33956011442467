import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLocation } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { colors } from 'src/theme'
import { Platform } from 'src/utils'

const WIDGET_URL = 'https://acsbapp.com/apps/app/dist/js/app.js'

const SETTINGS = {
  statementLink: '',
  footerHtml: '',
  hideMobile: false,
  hideTrigger: false,
  disableBgProcess: false,
  language: 'en',
  position: 'right',
  leadColor: colors.widgetTheme,
  triggerColor: colors.widgetTheme,
  triggerRadius: '50%',
  triggerPositionX: 'right',
  triggerPositionY: 'bottom',
  triggerIcon: 'people',
  triggerSize: 'medium',
  mobile: {
    triggerSize: 'medium',
    triggerPositionX: 'right',
    triggerPositionY: 'bottom',
    triggerOffsetY: 80,
    triggerRadius: '20',
  },
}

interface AcessibeSDK {
  init: (settings: unknown) => void
  destroy: () => void
}
declare const window: Window & { acsbJS?: AcessibeSDK }

interface AccessibeWidgetProps {
  offsetVertical?: string
  offsetHorizontal?: string
}

const loadScript = async (): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = WIDGET_URL
    script.async = true
    script.addEventListener('load', () => resolve())
    script.addEventListener('error', () => reject(new Error('Failed loading accessibe script.')))
    document.body.appendChild(script)
  })

const AccessibeWidget = ({ offsetHorizontal = '0px', offsetVertical = '0px' }: AccessibeWidgetProps): JSX.Element => {
  const [isLoaded, setIsLoaded] = useState(!!window.acsbJS)

  useEffect(() => {
    if (!isLoaded) {
      loadScript()
        .then(() => {
          if (!window.acsbJS) {
            throw new Error('Accessibe script is not available upon loading.')
          }
          setIsLoaded(true)
          window.acsbJS.init(SETTINGS)
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
    }
    return () => {
      if (typeof window.acsbJS !== 'undefined') {
        window.acsbJS.destroy()
      }
    }
    // We'll disable the linter rule as we only want this to run at first run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // We use css to be able to constantly override whatever widget styles are assigned
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
    body .acsb-trigger .acsb-trigger-icon svg {
      max-width: 40% !important;
      max-height: 40% !important;
    }
    body .acsb-trigger { 
      z-index: 999997 !important;
      right: ${offsetHorizontal} !important;
      bottom: ${offsetVertical} !important;
    }
  `,
      }}
    />
  )
}

const STACKED_ROUTES = ['/home', '/events', '/volunteer/projects', '/volunteer/events', '/donate/fundraisers']
const AccessibilityWidget = () => {
  const { metrics } = useDeedTheme()
  const location = useLocation()

  const menuVisible = useSelector<unknown, boolean>((state) =>
    // TODO: for some reason selectLocalSetting is not picking the right type, throwing errors due to argument count
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    (selectLocalSetting as any)(state, 'menuModalVisibility')
  )

  const isStacked: boolean =
    metrics.isLarge ||
    STACKED_ROUTES.includes(location.pathname) ||
    (menuVisible && location.pathname !== '/profile/settings')

  const isShifted = !metrics.isLarge && !menuVisible
  let offsetVertical = isShifted ? 90 : 20
  const offsetHorizontal = 20
  if (isStacked && isShifted) {
    offsetVertical += 60
  }

  if (Platform.OS !== 'web') {
    return null
  }

  return <AccessibeWidget offsetHorizontal={`${offsetHorizontal}px`} offsetVertical={`${offsetVertical}px`} />
}

export default AccessibilityWidget
