import React from 'react'
import { View } from 'react-primitives'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { Checkbox, Divider, Row, Spacing, TextField } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { currencies, CurrencySelect } from 'src/containers/modules/CurrencyFormat'
import { ErrorText } from 'src/components/ErrorText'
import CurrencyInput from 'src/components/CurrencyInput'
import { DatePicker } from 'src/components/DatePicker/DatePicker'

import { setStepAction } from '../actions'
import { CommunitySelect } from '../CommunitySelect'

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  min-height: 40px;
  height: auto;
`

const SetGoals = ({ formik }): JSX.Element => {
  const { t } = useTranslation('createDeedScreen')
  const dispatch = useDispatch()

  const currencySymbol = currencies[formik.values.currency] ? currencies[formik.values.currency].symbol : '$'

  return (
    <>
      <Divider />
      <Text fontSize={12}>{t`howMuchWouldYouLikeToRaise`}</Text>
      <Row>
        <View style={{ marginRight: 10, marginBottom: 15, marginTop: 8 }}>
          <CurrencySelect
            value={formik.values.currency}
            showcasedOptions={['USD', 'EUR']}
            onSelect={(value) => formik.setFieldValue('currency', value)}
          />
        </View>
        <View style={{ flexGrow: 1 }}>
          <CurrencyInput
            name="amount"
            value={formik.values.amount}
            onValueChange={(value, name) => {
              formik.setFieldTouched('amount', true, false)
              formik.setFieldValue(name, value)
            }}
            currencySymbol={currencySymbol}
          />
          {formik.touched.amount && !!formik.errors.amount && <ErrorText text={formik.errors.amount} />}
        </View>
      </Row>
      <Text fontSize={12}>{t`tellPeopleWhyYouAreFundraising`}</Text>
      <TextField
        name="description"
        numberOfLines={5}
        multiline
        scrollEnabled={false}
        value={formik.values.description}
        placeholder={t`addADescriptionOfTheFundraiser`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('description', value)}
        onTouched={() => formik.setFieldTouched('description', true, false)}
      />
      {formik.touched.description && !!formik.errors.description && <ErrorText text={formik.errors.description} />}
      <Text fontSize={12}>{t`whatWouldYouLikeToNameYourFundraiser`}</Text>
      <TextField
        name="name"
        scrollEnabled={false}
        value={formik.values.name}
        placeholder={t`exHelpSchoolsFundEquipment`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('name', value)}
        onTouched={() => formik.setFieldTouched('name', true, false)}
      />
      {formik.touched.name && !!formik.errors.name && <ErrorText text={formik.errors.name} />}

      <Text fontSize={12} style={{ marginBottom: 5 }}>{t`startDate`}</Text>
      <DatePicker
        minDate={new Date()}
        value={formik.values.startingAt}
        onChange={(date) => formik.setFieldValue('startingAt', date)}
      />
      <Spacing marginBottom={10} />
      <Text fontSize={12} style={{ marginBottom: 5 }}>{t`endDate`}</Text>
      <DatePicker
        minDate={formik.values.startingAt ? new Date(formik.values.startingAt) : new Date()}
        value={formik.values.endingAt}
        onChange={(date) => formik.setFieldValue('endingAt', date)}
      />

      <Spacing marginBottom={15} />
      <Text fontSize={12}>{t`linkOnly`}</Text>
      <Spacing marginBottom={10} />
      <Checkbox
        name="linkOnly"
        checked={formik.values.linkOnly}
        onChange={(e) => formik.setFieldValue('linkOnly', e.target.checked)}
        style={{ paddingLeft: 0 }}
      >
        <Text fontSize={12}>{t`isThisEventOnlyAccessibleThroughDirectLink`}</Text>
      </Checkbox>

      <CommunitySelect
        onChange={(communityIds) => formik.setFieldValue('organizingCommunityIds', communityIds)}
        value={formik.values.organizingCommunityIds}
      />

      <Spacing marginTop={30}>
        <Button
          palette="primary"
          disabled={!!(formik.errors.name || formik.errors.description || formik.errors.amount)}
          onPress={() => dispatch(setStepAction(2))}
        >
          {t`next`}
        </Button>
      </Spacing>
    </>
  )
}

SetGoals.displayName = 'SetGoals'
export default SetGoals
