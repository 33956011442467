import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { css, styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Redirect, useLocation, useParams } from 'src/navigation'
import useEmailTemplates from 'src/data/emails'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import User from 'src/entities/user/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Screen, ScrollView, Loading, TextField } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Text } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'

import { selectSending } from './selectors'
import { sendEmailAction } from './actions'
import reducer from './reducer'
import epics from './epics'

const FormContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '25px 50px' : '25px')};
`

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  height: auto;
  font-size: 14px;
  min-height: 400px;
  line-height: 16px;
`

const MailForm = (): JSX.Element => {
  useInjectReducer({ key: 'mailForm', reducer })
  useInjectEpics({ key: 'mailForm', epics })
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { colors } = useDeedTheme()
  const user: User | undefined = useSelector(selectCurrentUser)

  const { emailType } = useParams<{ emailType?: 'nominate-nonprofit-for-matching' | 'create-deed' }>()
  const { state } = useLocation()
  const sending = useSelector(selectSending)

  const emailTemplates = useEmailTemplates(colors.brandColor, user, state?.nonprofit, state?.deedType)
  const emailData = emailType && emailTemplates[emailType]

  const [emailContent, setEmailContent] = useState('')

  useEffect(() => {
    if (emailData) {
      setEmailContent(emailData.body)
    }
  }, [emailData?.body])

  if (!user || !emailData) {
    return <Loading />
  }

  if (emailType === 'create-deed' && !user.hasFeature('userCreateDeed')) {
    return <Redirect to="/home" />
  }

  return (
    <Screen>
      <PageTitle title={emailData.title} />
      <ScrollView>
        <NavigationHeader title={emailData.title} />
        <FormContainer>
          <Text fontSize={12} weight="500" marginBottom={25} center>
            {emailData.note}
          </Text>
          <TextField
            name="theme"
            numberOfLines={20}
            multiline
            scrollEnabled={false}
            value={emailContent}
            style={roundedInputStyle}
            onChangeText={(_e: Event, value: string) => setEmailContent(value)}
          />
          <Button
            palette="primary"
            style={{ justifyContent: 'center', maxWidth: 200, alignSelf: 'center' }}
            loading={sending}
            onPress={() => dispatch(sendEmailAction(emailData.type, emailContent))}
          >
            {t`common:Submit`}
          </Button>
        </FormContainer>
      </ScrollView>
    </Screen>
  )
}

export default MailForm
