import { isBefore, parseISO } from 'date-fns'

import { ActivityStatus } from 'src/containers/modules/Actions/types'

export const handleActionButtonPress = ({
  currentActivityStatus,
  onUpsertAction,
  setConfirmCompletionModalVisible,
}: {
  currentActivityStatus: ActivityStatus | undefined
  onUpsertAction: (nextStatus: ActivityStatus | null) => Promise<void>
  setConfirmCompletionModalVisible: (value: boolean) => void
}) => {
  const isNotStarted = currentActivityStatus === ActivityStatus.Left || !currentActivityStatus

  if (isNotStarted) {
    onUpsertAction(ActivityStatus.Joined)
  } else {
    setConfirmCompletionModalVisible(true)
  }
}

export const isDateInThePast = (date?: string): boolean => !!(date && isBefore(parseISO(date), new Date()))
