import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { initialState, NonprofitsSearchState } from './reducer'

const selectState = (state: State): NonprofitsSearchState => state.get('nonprofitsSearch') || initialState

export const selectSearchLoading = createSelector(selectState, (state) => state.get('searching'))

export const selectSearchError = createSelector(selectState, (state) => state.get('searchError'))

export const selectSearchSuccess = createSelector(selectState, (state) => state.get('searchSuccess'))
