import React, { useState } from 'react'

import { Touchable } from 'src/retired/elements'
import { Body1, Text } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Hoverable from 'src/retired/shared/Hoverable'

import { StateNode, CountryNode } from './utils'

export const CountrySelectorOption = ({
  props,
  option,
  handleChangeSubOption,
  currentInput,
  currentSelectedLocationValue,
  showStates,
}: {
  props: React.HTMLAttributes<HTMLLIElement>
  option: CountryNode
  handleChangeSubOption: (option: StateNode) => void
  currentInput: string | null
  currentSelectedLocationValue: string
  showStates: boolean
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isExpandTouched, setIsExpandTouched] = useState(false)
  const searchTermMatchesRootCountry = option.label.toLowerCase().includes(currentInput?.toLowerCase())
  const { colors } = useDeedTheme()

  const filteredSupOptions =
    currentInput && !searchTermMatchesRootCountry
      ? option.options?.filter((op) => op.label.toLowerCase().includes(currentInput?.toLowerCase()))
      : option.options

  const isExpandedByDefault = !isExpandTouched && currentInput

  return (
    <div style={{ paddingTop: 5, paddingBottom: 5, cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 50 }}>
          {option.options?.length ? (
            <Touchable
              onPress={() => {
                setIsExpanded(!(isExpanded || isExpandedByDefault))
                setIsExpandTouched(true)
              }}
              style={{
                padding: 10,
                marginLeft: 10,
              }}
            >
              <Text fontSize={8}>{isExpanded || isExpandedByDefault ? '▼' : '▶'}</Text>
            </Touchable>
          ) : null}
        </div>
        <Hoverable>
          {(isHovered: boolean) => (
            <div
              {...props}
              style={{
                backgroundColor:
                  currentSelectedLocationValue === option.value || isHovered ? colors.lightPurple : colors.white,
                width: '100%',
                marginRight: 20,
                padding: 5,
                borderRadius: 10,
              }}
            >
              <Body1 style={{ paddingLeft: 20 }}>{option.label}</Body1>
            </div>
          )}
        </Hoverable>
      </div>
      {showStates && filteredSupOptions?.length && (isExpanded || isExpandedByDefault) ? (
        <div
          style={{
            paddingLeft: 20,
            borderLeftWidth: 2,
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderStyle: 'dotted',
            marginLeft: 23,
            paddingRight: 10,
            paddingTop: 5,
          }}
        >
          {filteredSupOptions.map((stateOption) => (
            <Hoverable>
              {(isHovered: boolean) => (
                <div
                  style={{
                    backgroundColor:
                      (currentSelectedLocationValue === stateOption.value || isHovered) && colors.lightPurple,
                    borderRadius: 5,
                    width: '100%',
                    marginTop: 5,
                    marginBottom: 10,
                  }}
                  key={stateOption.label}
                >
                  <Touchable
                    style={{ paddingLeft: 23, padding: 5 }}
                    onPress={() => {
                      handleChangeSubOption(stateOption)
                    }}
                  >
                    <Body1> {stateOption.label}</Body1>
                  </Touchable>
                </div>
              )}
            </Hoverable>
          ))}
        </div>
      ) : null}
    </div>
  )
}
