import React from 'react'
import { useTranslation } from 'react-i18next'

import DonationSchedule from 'src/entities/donationSchedule/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { DonationScheduleStatus } from 'src/generated/graphql'

import { StatusMessage } from '../StatusMessage'

interface Props {
  donationScheduleItem: DonationSchedule
}
export const DonationScheduleExecutionStatus = ({ donationScheduleItem }: Props) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('donationScheduleDetailsScreen')
  const hasExecutionFailures =
    donationScheduleItem?.executionFailure && donationScheduleItem.executionFailure.length > 0
  if (
    !donationScheduleItem ||
    donationScheduleItem.status === DonationScheduleStatus.Inactive ||
    !hasExecutionFailures
  ) {
    return null
  }

  const latestError = donationScheduleItem.executionFailure[donationScheduleItem.executionFailure.length - 1]
  if (!latestError) {
    return null
  }
  return (
    <StatusMessage
      icon="infoCircleOutlinedRed"
      text={latestError.message ? latestError.message : t`unableToProcessDonationSchedule`}
      bgColor={colors.redLight}
      textColor={colors.redDark}
    />
  )
}
