import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { injectReducer, injectEpics, validators } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { validatePassword, getUserInputs } from 'src/utils/password'

import PasswordForm from '../PasswordForm'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSubmitting, selectError } from './selectors'

export const SetPassword = ({
  currentUser,
  submitting,
  actions,
  values,
  errors,
  dirty,
  touched,
  isValid,
  setFieldValue,
  setFieldTouched,
  match,
  validateForm,
}) => {
  const { t } = useTranslation('loginScreen')
  const operation = window.location.pathname.includes('/password/') ? 'set' : 'reset'
  const handleSubmit = (e) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    if (dirty && isValid) {
      actions.submitAction(match.params.token, values.password, operation)
    }
  }
  const formProps = {
    ...submitting,
    actions,
    values,
    errors,
    dirty,
    touched,
    isValid,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  }

  const formFields = {
    title: t('setPasswordTitle', { context: operation }),
    backTo: operation === 'reset' ? '/login/forgot' : '',
    subTitle: t('pleaseSetYourPassword', { context: operation }),
    placeholder: t('password', { context: operation }),
    placeholder2: t('repeatPassword', { context: operation }),
    submitCta: t('confirm', { context: operation }),
  }

  useEffect(() => {
    validateForm()
  }, [])

  useEffect(() => {
    validateForm()
  }, [values.password2])

  return <PasswordForm formProps={formProps} formFields={formFields} userInputs={getUserInputs({ currentUser })} />
}

const withConnect = connect(
  (state) => ({
    currentUser: selectCurrentUser(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'setPassword', reducer })
const withEpics = injectEpics({ key: 'setPassword', epics })
const withForm = withFormik({
  validate: (values, props) => {
    const { currentUser } = props
    const errors = {
      password: validatePassword(values.password, getUserInputs({ currentUser })),
      password2: validators.notEmpty(values.password2) || values.password !== values.password2 ? 'Does not match' : '',
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  mapPropsToValues: () => ({
    password: '',
    password2: '',
  }),
  validateOnBlur: true,
  validateOnChange: true,
})

export default compose(withReducer, withEpics, withConnect, withForm)(SetPassword)
