import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { useHistory, useParams } from 'src/navigation'
import { useInjectEpics, useInjectReducer } from 'src/utils'

import { TabType, getSearch } from '../../GeneralSearch/utils'
import { initAction } from '../../Profile/VolunteerTime/types'
import epics from '../epics'
import reducer from '../reducer'

import { VolunteerRewardEligibilityUI } from './VolunteerRewardEligibilityUI'

export const VolunteerRewardEligibility = () => {
  const { id: volunteerTimeId } = useParams<{ id: string }>()
  const { t } = useTranslation('volunteerRewardEligibility')
  const dispatch = useDispatch()
  const history = useHistory()

  useInjectEpics({ key: 'volunteerTime', epics })
  useInjectReducer({ key: 'volunteerTime', reducer })

  const user = useSelector(selectCurrentUser)
  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, volunteerTimeId))

  useEffect(() => {
    if (!volunteerTime) {
      dispatch(initAction(volunteerTimeId))
    }
  }, [dispatch, volunteerTime, volunteerTimeId])

  const handleFindNextOpportunity = () => {
    history.push({
      pathname: '/search',
      search: getSearch({
        tab: TabType.volunteer,
      }),
    })
  }

  return (
    <VolunteerRewardEligibilityUI
      headerTitle={t`applyForVolunteerReward`}
      headerSubtitle={t`volunteerRewardEligibilityReview`}
      illustration="womanStarIllustration"
      styleIllustration={{ width: 125 }}
      contentTitle={t`volunteerRewardNoFormTitle`}
      content={t`volunteerRewardNoFormDescription`}
      buttonText={t`ctaFindYourNextOpportunity`}
      onPressCta={handleFindNextOpportunity}
      loading={!user}
    />
  )
}

export default VolunteerRewardEligibility
