import { Observable } from 'rxjs'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import Api from 'src/entities/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { submitSuccessAction, submitFailedAction } from './actions'
import { SUBMIT } from './constants'

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ token }) =>
      UserApi.verifyUser(token).mergeMap((action) => {
        Api.defaultHeaders.Authorization = `Bearer ${action.token}`
        return [action, submitSuccessAction()]
      })
    )
    .catch((e) => Observable.of(submitFailedAction(e), showErrorAction(i18n.t('companyRegister:confirmationFailed'))))

export default [submit]
