import { Observable } from 'rxjs'
import _ from 'lodash'
import { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import { selectCauses } from 'src/entities/cause/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import CausesApi from 'src/entities/cause/api'
import DeedsApi from 'src/entities/deed/api'
import OrganizationApi from 'src/entities/organization/api'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import VolunteerTimeApi from 'src/entities/volunteerTime/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'

import { INIT, initSuccessAction, initFailedAction, InitAction } from './types'

const init = (action$: ActionsObservable<InitAction>, store: Store) =>
  action$.ofType(INIT).mergeMap(({ id, opportunityId, opportunityType }) => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)

    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    if (id) {
      const volunteerTime = selectVolunteerTimeById(state, id)

      if (!volunteerTime) {
        actions.push(VolunteerTimeApi.getByFilter({ id: { equals: id } }))
      }
    }

    if (opportunityType === 'deed' && opportunityId) {
      const deed = selectDeedById(state, opportunityId)

      if (!deed) {
        actions.push(DeedsApi.fetch(opportunityId))
      }
    }

    if (opportunityType === 'nonprofit' && opportunityId) {
      const nonprofit = selectOrganizationById(state, opportunityId)

      if (!nonprofit) {
        actions.push(OrganizationApi.fetch(opportunityId))
      }
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => [initFailedAction(e), showErrorAction(i18n.t('volunteerTimeFormProfile:loadingFailed'))])
  })

export default [init]
