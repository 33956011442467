import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Platform from 'src/utils/Platform'

/* NOTICE this is a heuristic value, if you can make it more precise go for it */
const LIST_HORIZONTAL_LEFTOVERS = Platform.OS === 'web' ? 50 : 40 // account for scroll bar on web

interface ScrollViewCardProps {
  children: React.ReactNode
}
export type ScrollViewCardElement = React.ReactElement<ScrollViewCardProps>

const ScrollViewCard: React.FC<ScrollViewCardProps> = ({ children }): ScrollViewCardElement => {
  const { metrics } = useDeedTheme()

  return (
    <View style={{ width: metrics.isSmall ? metrics.screenWidth - LIST_HORIZONTAL_LEFTOVERS : '100%' }}>
      {children}
    </View>
  )
}

export default ScrollViewCard
