/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { currencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import { CapBalanceInfo } from 'src/entities/user/model'

const CapLimitPerUser = ({ capBalanceByBudget }: { capBalanceByBudget: CapBalanceInfo[] }): JSX.Element | null => {
  const { t } = useTranslation('capLimitPerUser')

  const { rates, code } = useCurrency()

  if (!capBalanceByBudget?.length) {
    return <>{t`indefinite`}</>
  }

  const isUnlimited = capBalanceByBudget.some(({ balance }) => balance === null)

  if (isUnlimited) {
    return <>{t`anUnlimitedAmount`}</>
  }

  if (!rates) {
    return null
  }

  const totalLimitPerUserInUserCurrency = capBalanceByBudget
    .map(({ currencyCode = 'USD', limitPerUser }) => (limitPerUser ? limitPerUser * rates[currencyCode] : 0))
    .reduce((total, limitPerUserInUserCurrency) => total + limitPerUserInUserCurrency, 0)

  return <>{currencyFormat(totalLimitPerUserInUserCurrency, code)}</>
}
export default CapLimitPerUser
