import { App } from '@capacitor/app'

import config from 'src/config'

import Platform from './Platform'

export default class DeviceInfo {
  static getApplicationName() {
    return config.isProduction ? 'DEED' : 'DEED-Dev'
  }

  static getNotchValue(side?: 'top' | 'right' | 'bottom' | 'left') {
    return Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue(`--sa${side ?? 'top'}`)
        .replace('px', '')
    )
  }

  static hasNotch(side?: 'top' | 'right' | 'bottom' | 'left') {
    return Boolean(DeviceInfo.getNotchValue(side))
  }

  static async getBuildNumber() {
    if (Platform.OS !== 'web') {
      const info = await App.getInfo()
      return Number(info.build)
    }
    return undefined
  }
}
