import { useSelector } from 'react-redux'

import config from 'src/config'
import { selectUserBrand } from 'src/entities/user/selectors'

import createUrlFromHost from './createUrlFromHost'

export const useWebUrl = (): string => {
  const brand = useSelector(selectUserBrand)
  const webUrl = brand?.appVanityDomain ? createUrlFromHost(brand.appVanityDomain) : config.webUrl
  return webUrl
}
