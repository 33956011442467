import React, { LegacyRef, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ScrollView, Screen, Row } from 'src/retired/elements'
import StatusBar from 'src/retired/blocks/StatusBar'
import DesktopHeader from 'src/retired/blocks/DesktopHeader'
import DesktopFooter from 'src/retired/blocks/DesktopFooter'
import { colors } from 'src/theme'
import { useLocation, useParams, useHistory } from 'src/navigation'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectSearchVisible } from 'src/containers/modules/Search/selectors'
import { LargeUp, MediumDown, MediumUp, SmallOnly } from 'src/retired/elements/MediaQuery'
import ScrollToTop from 'src/retired/shared/ScrollToTop'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import { DeviceInfo, Platform } from 'src/utils'
import TabMenu from 'src/retired/blocks/TabMenu'
import { SearchToggle, SearchOverlay } from 'src/containers/modules/Search'
import NotifcationsIcon from 'src/containers/modules/NotifcationsIcon'
import CheckInBar from 'src/containers/modules/CheckInBar'
import LiteHeader from 'src/retired/blocks/LiteHeader'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { ErrorBoundary } from 'src/retired/blocks/ErrorBoundaryPage'
import { getLanguageCode } from 'src/utils/languageUtils'
import SafeAreaView from 'src/retired/elements/SafeAreaView'

export interface LayoutProps {
  narrow?: boolean
  fullWidth?: boolean
  padding?: boolean
  withoutHeader?: boolean
  keepTabMenu?: boolean
  backgroundColor?: string
  scrollRef?: LegacyRef<ScrollView>
  children?: ReactNode
}

const Layout = ({
  children,
  narrow,
  fullWidth,
  padding,
  withoutHeader = false,
  keepTabMenu = false,
  backgroundColor = colors.gray03,
  scrollRef = null,
}: LayoutProps) => {
  const { metrics } = useDeedTheme()
  const { i18n } = useTranslation()
  const searchVisible = useSelector(selectSearchVisible)
  const user = useSelector(selectCurrentUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { lite } = useParams<{ lite?: string }>()
  const history = useHistory()

  const location = useLocation()

  const showCheckInBar = /^\/(home|volunteer|donate|causes)/.test(location.pathname)
  const showBanners = metrics.isLarge
    ? !/^\/(register)/.test(location.pathname)
    : !/^\/(register|volunteer|donate|events)/.test(location.pathname)
  const showLiteHeader = /(campaign|donate)/.test(location.pathname) && (lite || (!isAuthenticated && metrics.isSmall))
  const disableNonprofitSearch = user?.getSetting('partnerNonprofitsOnly') ?? true

  useEffect(() => {
    if (user && lite) {
      history.replace(`${location.pathname.replace('/lite', '')}${location.search}`)
    }
  }, [user])

  return (
    <SafeAreaView withoutHeader={withoutHeader}>
      {Platform.OS !== 'web' && <StatusBar />}

      <ScrollToTop />

      <MediumDown>
        <Screen fixed safeArea={false}>
          <View style={{ minHeight: '100vh', paddingBottom: DeviceInfo.getNotchValue() + 50 }}>
            <SmallOnly>
              {!withoutHeader ? (
                <>
                  <View style={{ flexGrow: 0, padding: 15, paddingTop: metrics.isSmall ? 10 : 15, zIndex: 25 }}>
                    <Row style={{ alignItems: 'center' }}>
                      <NotifcationsIcon />
                      {!disableNonprofitSearch && (
                        <View style={{ flexGrow: 1, marginLeft: 18 }}>
                          <SearchToggle />
                        </View>
                      )}
                    </Row>
                  </View>
                  {showBanners && (
                    <>
                      <PendingApprovalBanner />
                      <CompanyNotificationBanner />
                    </>
                  )}
                </>
              ) : null}
              {showLiteHeader && <LiteHeader />}
              <ScrollView
                style={{
                  flex: 1,
                  alignSelf: 'stretch',
                  marginBottom: lite ? 0 : -20,
                  paddingBottom: 20,
                  backgroundColor,
                }}
                contentContainerStyle={{ paddingHorizontal: padding ? 20 : 0, flexGrow: 1 }}
                ref={scrollRef}
              >
                <ErrorBoundary>{children}</ErrorBoundary>
              </ScrollView>

              {showCheckInBar && <CheckInBar />}
              {!lite && (!withoutHeader || keepTabMenu) && <TabMenu />}
              <SearchOverlay />
            </SmallOnly>

            <MediumUp style={{ height: '100%' }}>
              <View style={{ flex: 1 }}>
                {!lite && (
                  <View style={{ zIndex: 2 }}>
                    <DesktopHeader tablet />
                    {showBanners && (
                      <>
                        <PendingApprovalBanner />
                        <CompanyNotificationBanner />
                      </>
                    )}
                  </View>
                )}

                {showLiteHeader && <LiteHeader />}

                <ScrollView
                  style={{
                    flex: 1,
                    alignSelf: 'stretch',
                    marginBottom: lite ? 0 : -20,
                    paddingBottom: 20,
                    backgroundColor,
                  }}
                  contentContainerStyle={{ paddingHorizontal: padding ? 20 : 0 }}
                  ref={scrollRef}
                >
                  <ErrorBoundary>{children}</ErrorBoundary>
                </ScrollView>
              </View>
              {showCheckInBar && <CheckInBar />}
              {!lite && (!withoutHeader || keepTabMenu) && <TabMenu />}
            </MediumUp>
          </View>
        </Screen>
      </MediumDown>
      <LargeUp>
        <ScrollView
          nativeID="scroll-view"
          style={{ overflowY: searchVisible ? 'hidden' : 'auto' }}
          contentContainerStyle={{
            justifyContent: 'space-between',
            minHeight: Platform.OS === 'web' ? '100vh' : '100%',
          }}
          ref={scrollRef}
        >
          <View
            style={{
              backgroundColor: backgroundColor ?? colors.gray03,
              flexGrow: 1,
              paddingBottom: 50,
              zIndex: 10,
            }}
          >
            {!lite && (
              <>
                <DesktopHeader />
                {showBanners && (
                  <>
                    <PendingApprovalBanner />
                    <CompanyNotificationBanner />
                  </>
                )}
              </>
            )}
            {showLiteHeader && <LiteHeader />}
            <View
              style={{
                width: '100%',
                maxWidth: fullWidth ? '100%' : narrow ? metrics.maxWidthNarrow : metrics.maxWidthWide,
                paddingHorizontal: padding ? 56 : 0,
                marginHorizontal: 'auto',
                flexGrow: 1,
              }}
            >
              <ErrorBoundary>{children}</ErrorBoundary>
            </View>
          </View>
          {showCheckInBar && <CheckInBar />}
          {!lite && <DesktopFooter locale={getLanguageCode(i18n.language)} />}
        </ScrollView>
      </LargeUp>
    </SafeAreaView>
  )
}

export default Layout
