import config from 'src/config'

export const createUrlFromHost = (host: string): string => {
  try {
    const { protocol } = new URL(config.webUrl)
    const url = new URL(`${protocol}//${host}`)

    return url.href.slice(0, -1)
  } catch (err) {
    return host
  }
}

export default createUrlFromHost
