import React, { ReactElement } from 'react'
import { createTheme, CssBaseline, lighten, ThemeProvider } from '@mui/material'

import { colors } from '..'

import { breakpoints } from './breakpoints'
import { components } from './components'
import { typography } from './typography'

export interface MuiThemeProviderProps {
  brandColor: string
  children: ReactElement
}

// @mui/material/styles/createPalette.d.ts
declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line no-undef
  interface PaletteColor extends ColorPartial {
    50: string
    100: string
  }
}

export const MuiThemeProvider = ({ brandColor, children }: MuiThemeProviderProps): ReactElement => {
  const muiTheme = createTheme({
    breakpoints,
    components,
    typography,
    spacing: 8,
    palette: {
      // NOTE-AZ: we set brandColor for primary, and let MUI to calculate all other shades of primary `light`, `dark`
      primary: {
        main: brandColor,
        50: lighten(brandColor, 0.9),
        100: lighten(brandColor, 0.8),
      },

      // NOTE-AC: putting this color we often see in Figma here, that will be less random than MUI default
      secondary: { main: colors.grayMild },
      error: { main: colors.red },
    },
  })

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
