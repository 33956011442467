import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('deedCheckIn') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectGeolocation = createSelector(selectState, (state) => state.get('geolocation'))

export const selectGeolocationWatchId = createSelector(selectState, (state) => state.get('geolocationWatchId'))

export const selectDisabledCheckInClicks = createSelector(selectState, (state) => state.get('disabledCheckInClicks'))
