import React, { useEffect, useState } from 'react'

import { Action, ActivityStatus } from 'src/containers/modules/Actions/types'
import { JoinSuccessModal } from 'src/containers/screens/Actions/Modals/JoinSuccessModal'

import { CompleteSuccessModal } from './CompleteSuccessModal'

export const SuccessModals = ({ action, buttonsDisabled = false }: { action: Action; buttonsDisabled?: boolean }) => {
  const [joinSuccessModalVisible, setJoinSuccessModalVisible] = useState(false)
  const [completeSuccessModalVisible, setCompleteSuccessModalVisible] = useState(false)

  const [userActivity] = action?.userActivities ?? []
  const [currentActivityStatus, setCurrentActivityStatus] = useState(userActivity?.status)

  useEffect(() => {
    if (userActivity?.status !== currentActivityStatus) {
      if (userActivity?.status === ActivityStatus.Joined) {
        setJoinSuccessModalVisible(true)
      } else if (userActivity?.status === ActivityStatus.Completed) {
        setCompleteSuccessModalVisible(true)
      }
      setCurrentActivityStatus(userActivity?.status)
    }
  }, [userActivity])

  return (
    <>
      <JoinSuccessModal
        onClose={() => setJoinSuccessModalVisible(false)}
        action={action}
        visible={joinSuccessModalVisible}
        buttonsDisabled={buttonsDisabled}
      />
      <CompleteSuccessModal
        onClose={() => setCompleteSuccessModalVisible(false)}
        action={action}
        visible={completeSuccessModalVisible}
        buttonsDisabled={buttonsDisabled}
      />
    </>
  )
}
