import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'
import type { ActionsObservable } from 'redux-observable'
import type { AnyAction, Store } from 'redux'

import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import AuthApi from 'src/entities/auth/api'
import UserApi from 'src/entities/user/api'
import LocationApi from 'src/entities/location/api'
import { selectLocations } from 'src/entities/location/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { selectBrand } from 'src/localSettings/selectors'
import truthy from 'src/utils/truthy'
import { Platform } from 'src/utils'
import type { State } from 'src/reducers'

import {
  submitSuccessAction,
  submitFailedAction,
  logoutFailedAction,
  initSuccessAction,
  initFailedAction,
} from './actions'
import { LOGOUT, SUBMIT, INIT } from './constants'

const init = (action$: ActionsObservable<AnyAction>, store: Store<State>): Observable<{}> =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    const locations = selectLocations(state)

    if (locations.size > 0) {
      return Observable.of(initSuccessAction())
    }

    return LocationApi.fetchAll()
      .mergeMap((action) => [action, initSuccessAction()])
      .catch((e) =>
        Observable.of(initFailedAction(e), showErrorAction(i18n.t('settingsProfile:failedLoadingLocations')))
      )
  })

const logout = (action$: ActionsObservable<AnyAction>, store: Store<State>) =>
  action$.ofType(LOGOUT).exhaustMap(() => {
    if (window?.sessionStorage) {
      window.sessionStorage.removeItem('previousLocation')
    }

    return AuthApi.logoutLocal()
      .mergeMap(() => {
        const state = store.getState()
        const brand = selectBrand(state)
        const shouldPurgeBrand =
          Platform.OS === 'web'
            ? (brand?.appVanityDomain && !window?.location.host.match(brand.appVanityDomain)) ?? true
            : true

        return [
          { type: 'RESET' },
          replace('/'),
          setLocalSettingAction('previousLocation', null),
          shouldPurgeBrand && setLocalSettingAction('brandColor', null),
          shouldPurgeBrand && setLocalSettingAction('brand', null),
        ].filter(truthy)
      })
      .catch((e) => Observable.of(logoutFailedAction(e)))
  })

const submit = (action$: ActionsObservable<AnyAction>) =>
  action$.ofType(SUBMIT).exhaustMap((payload) =>
    UserApi.update(payload.data)
      .mergeMap((resultingAction) => [
        resultingAction,
        showMessageAction(i18n.t('settingsProfile:saved')),
        submitSuccessAction(),
      ])
      .catch((e) => Observable.of(submitFailedAction(e), showErrorAction(i18n.t('settingsProfile:savingFailed'))))
  )

export default [init, logout, submit]
