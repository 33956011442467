import React, { useCallback, useEffect, useState } from 'react'

import SelectBox from 'src/retired/shared/SelectBox'
import { PaymentMethodType, PayrollPaymentOption } from 'src/generated/graphql'

import { PaymentType } from './constants'

export type SelectPayrollPaymentOptionCode = (paymentOptionCode: string) => void

type PayrollPaymentOptionsInputProps = {
  payrollPaymentOptions: PayrollPaymentOption[]
  selectedPayrollPaymentOption?: PayrollPaymentOption
  setSelectedPayrollPaymentOptionCode: SelectPayrollPaymentOptionCode
}

const findPayrollPaymentOptionByCode = (payrollPaymentOptions: PayrollPaymentOption[], paymentOptionCode: string) =>
  payrollPaymentOptions.find((paymentOption) => paymentOption.code === paymentOptionCode)

export const usePayrollPaymentOption = (payrollPaymentOptions: PayrollPaymentOption[], paymentType: PaymentType) => {
  const [selectedPayrollPaymentOption, setSelectedPayrollPaymentOption] = useState<PayrollPaymentOption | undefined>(
    payrollPaymentOptions.length > 0 ? payrollPaymentOptions[0] : undefined
  )

  const handleSelectPaymentOption = useCallback(
    (paymentOptionCode: string) => {
      const foundPaymentOption = findPayrollPaymentOptionByCode(payrollPaymentOptions, paymentOptionCode)
      if (!foundPaymentOption) {
        return
      }

      setSelectedPayrollPaymentOption(foundPaymentOption)
    },
    [payrollPaymentOptions]
  )

  useEffect(() => {
    if (selectedPayrollPaymentOption || !payrollPaymentOptions || paymentType !== PaymentMethodType.Payroll) {
      return
    }

    setSelectedPayrollPaymentOption(payrollPaymentOptions[0])
  }, [payrollPaymentOptions, selectedPayrollPaymentOption, paymentType])

  return { selectedPayrollPaymentOption, setSelectedPayrollPaymentOptionCode: handleSelectPaymentOption }
}

export const SelectPayrollPaymentOptions = ({
  payrollPaymentOptions,
  selectedPayrollPaymentOption,
  setSelectedPayrollPaymentOptionCode,
}: PayrollPaymentOptionsInputProps) => (
  <SelectBox
    onSelect={(paymentOptionCode: string) => {
      const foundPaymentOption = findPayrollPaymentOptionByCode(payrollPaymentOptions, paymentOptionCode)
      if (!foundPaymentOption) {
        return
      }
      setSelectedPayrollPaymentOptionCode(paymentOptionCode)
    }}
    value={selectedPayrollPaymentOption?.code ?? ''}
    options={payrollPaymentOptions.map((paymentOption) => ({
      value: paymentOption.code,
      title: paymentOption.displayName,
    }))}
  />
)
