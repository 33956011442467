import { createSelector } from 'reselect'

const selectState = (state) => state.get('registerLocation')

export const selectSelectedLocation = createSelector(selectState, (state) => state && state.get('selectedLocation'))

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
