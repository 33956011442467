import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { darken, lighten } from 'polished'

import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import Hoverable from 'src/retired/shared/Hoverable'
import Touchable from 'src/retired/shared/Touchable'
import { colors } from 'src/theme'
import { css } from 'src/theme/styled'
import Icon from 'src/retired/shared/Icon'
import { Label, Body } from 'src/retired/shared/Typography'
import { selectUserBrandColor } from 'src/entities/user/selectors'

export interface ButtonProps {
  disabled?: boolean
  palette?: 'default' | 'primary' | 'secondary' | 'soft'
  iconLeft?: string
  iconRight?: string
  loading?: boolean
  largeText?: boolean
  textStyle?: object
  rounded?: boolean
  style?: any
}

const Button: React.FC<ButtonProps> = ({
  children,
  palette = 'default',
  disabled,
  iconLeft,
  iconRight,
  loading,
  largeText = false,
  textStyle = {},
  style = {},
  rounded = false,
  ...rest
}) => {
  let borderColor = ''
  let borderColorHover: string
  let fillColor: string
  let fillColorHover: string
  let color = 'white'
  let opacityText = '1'
  let opacityHover = '1'

  const brandColor = useSelector(selectUserBrandColor)

  switch (palette) {
    case 'primary':
      if (disabled) {
        borderColor = brandColor || colors.blue03
        borderColorHover = brandColor || colors.blue03
        fillColor = brandColor || colors.blue03
        fillColorHover = brandColor || colors.blue03
        opacityText = '0.5'
        opacityHover = '0.5'
      } else {
        borderColor = brandColor || colors.blue
        borderColorHover = brandColor ? darken(0.05, brandColor) : colors.darkBlue
        fillColor = brandColor || colors.blue
        fillColorHover = brandColor ? darken(0.05, brandColor) : colors.darkBlue
      }
      break

    case 'secondary':
      borderColor = disabled
        ? brandColor
          ? lighten(0.1, brandColor)
          : colors.transparentBlue
        : brandColor || colors.blue
      borderColorHover = disabled
        ? brandColor
          ? lighten(0.1, brandColor)
          : colors.transparentBlue
        : brandColor || colors.lightBlue02
      fillColor = colors.white
      fillColorHover = colors.white
      color = 'black'
      opacityText = disabled ? '0.3' : '1'
      opacityHover = disabled ? '0.3' : '0.8'
      break

    case 'default':
      borderColor = colors.gray02
      borderColorHover = disabled ? colors.gray02 : colors.gray01
      fillColor = colors.white
      fillColorHover = colors.white
      color = 'black'
      opacityText = '1'
      opacityHover = disabled ? '0.3' : '0.7'
      break

    case 'soft':
      borderColor = brandColor ? lighten(0.7, brandColor) : colors.lightPink
      borderColorHover = disabled
        ? brandColor
          ? lighten(0.7, brandColor)
          : colors.lightPink
        : brandColor
        ? lighten(0.68, brandColor)
        : colors.pink03
      fillColor = brandColor ? lighten(0.7, brandColor) : colors.lightPink
      fillColorHover = disabled
        ? brandColor
          ? lighten(0.7, brandColor)
          : colors.lightPink
        : brandColor
        ? lighten(0.68, brandColor)
        : colors.pink03
      color = brandColor || 'darkPink'
      opacityText = disabled ? '0.3' : '1'
      break
  }

  /** NOTICE the undefined check is for StoryBook */
  const showIconLeft = Boolean(iconLeft && iconLeft !== 'undefined')
  const showIconRight = Boolean(iconRight && iconRight !== 'undefined')

  const marginLeft = showIconLeft ? 9 : 25
  const marginRight = showIconRight ? 9 : 25

  const buttonPaddingLeft = showIconLeft ? '10px' : '0px'
  const buttonPaddingRight = showIconRight ? '10px' : '0px'

  const iconColor = palette === 'secondary' ? borderColor : color

  const Text = largeText ? Body : Label

  const fontWeight = {}

  if (!textStyle.fontFamily) {
    fontWeight.weight = '500'
  }

  return (
    <Hoverable>
      {(isHovered: boolean) => (
        <Touchable
          style={[
            css`
              padding-left: ${buttonPaddingLeft};
              padding-right: ${buttonPaddingRight};
              flex-direction: row;
              align-content: center;
              align-items: center;
              justify-content: space-between;
              height: 40px;
              width: auto;
              background-color: ${isHovered ? fillColorHover : fillColor};
              border-radius: ${rounded ? '100px' : '12px'};
              border-width: 1px;
              border-color: ${isHovered ? borderColorHover : borderColor};
              cursor: ${disabled ? 'not-allowed' : 'pointer'};
            `,
            style,
          ]}
          {...rest}
          disabled={disabled}
        >
          {iconLeft && (
            <View
              style={[
                css`
                  margin-left: 4px;
                  margin-right: 14px;
                `,
              ]}
            >
              <Icon icon={iconLeft} width={16} height={16} hexColor={iconColor} />
            </View>
          )}
          {loading ? (
            <ActivityIndicator color={colors[color]} style={{ marginLeft, marginRight }} />
          ) : (
            <Text
              {...fontWeight}
              marginLeft={marginLeft}
              marginRight={marginRight}
              colour={color}
              style={[
                css`
                  opacity: ${isHovered ? opacityHover : opacityText};
                  flex-grow: 1;
                `,
                textStyle,
              ]}
              center
            >
              {children}
            </Text>
          )}
          {iconRight && (
            <View
              style={[
                css`
                  margin-right: 4px;
                  margin-left: 14px;
                `,
              ]}
            >
              <Icon icon={iconRight} width={16} height={16} hexColor={iconColor} />
            </View>
          )}
        </Touchable>
      )}
    </Hoverable>
  )
}

export default Button
