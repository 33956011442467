import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import Text from 'src/retired/elements/Text'
import { colors } from 'src/theme'

const styles = StyleSheet.create({
  line: {
    borderTopWidth: 1,
    borderTopColor: colors.mediumGray,
    alignSelf: 'stretch',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  text: {
    marginHorizontal: 15,
  },
})

const Divider = ({ style = {}, children, marginTop = 25, marginBottom = 25 }) => {
  const marginStyle = { marginTop, marginBottom }
  if (!children) {
    return <View style={[styles.line, marginStyle, style]} />
  }
  return (
    <View style={styles.container}>
      <View style={[styles.line, marginStyle, { flex: 1 }, style]} />
      <Text size={15} lineHeight={15} style={styles.text}>
        {children}
      </Text>
      <View style={[styles.line, marginStyle, { flex: 1 }, style]} />
    </View>
  )
}
Divider.propTypes = {
  style: PropTypes.any,
  children: PropTypes.node,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
}

export default Divider
