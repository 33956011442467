import React from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import Campaign from 'src/entities/campaign/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'

import { CampaignStatsCard } from './CampaignStatsCard'

export const GoalReachedCard = ({ campaign }: { campaign: Campaign }) => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('campaignScreen')

  if (!campaign || !campaign.campaignStats) {
    return null
  }

  const { campaignStats, isPledge, goalAmount } = campaign

  const { projectedTotalAmountCurrencies, totalRaisedCurrencies, goalAmountCurrencies } = campaignStats

  const goalAmountUSD = goalAmountCurrencies?.USD ?? 0
  const goalExists = goalAmount !== 0 && goalAmountUSD > 0

  if (!goalExists) {
    return null
  }

  let value = totalRaisedCurrencies?.USD ?? 0

  if (isPledge) {
    value = projectedTotalAmountCurrencies.USD ?? 0
  }

  const goalReachedPercentage = Math.round((value * 100) / goalAmountUSD)

  return (
    <CampaignStatsCard
      primaryElement={<H3>{goalReachedPercentage}%</H3>}
      secondaryElement={
        <View style={{ width: metrics.isSmall ? 120 : 'auto' }}>
          <Trans
            t={t}
            i18nKey="goalReached"
            values={{
              percentageAmount: goalReachedPercentage,
            }}
            style={{
              color: colors.green,
              fontSize: 16,
            }}
            components={{
              Text: (
                <Body1 style={{ fontSize: 16, color: colors.gray08, textAlign: metrics.isSmall ? 'left' : 'center' }} />
              ),
              Goal: <CurrencyFormat amountCurrencies={goalAmountCurrencies} process="round" short />,
            }}
          />
        </View>
      }
    />
  )
}
