// eslint-disable-next-line
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global __DEV__ */
import { Observable } from 'rxjs'
import 'whatwg-fetch'

import config from 'src/config'
import * as Sentry from 'src/utils/Sentry'

interface ResponseError extends Error {
  responseJson?: unknown
  response?: Response
}

interface DataMap {
  [key: string]: string | number | Date | null | boolean | DataMap
}

const X_DEED_API_VERSION = '5'

interface DefaultHeaders {
  'X-DEED-API-VERSION': typeof X_DEED_API_VERSION
  Authorization?: string
  'Viewer-Country'?: string
}

class Api {
  endpoint: string

  setAuthorization: ((token: string) => void) | null = null

  authenticationFailed: (() => void) | null = null

  defaultHeaders: DefaultHeaders = {
    'X-DEED-API-VERSION': X_DEED_API_VERSION,
  }

  constructor(values: { endpoint?: string } = {}) {
    this.endpoint = values.endpoint || config.apiEndpoint
  }

  request<Response>(path: string, additionalOptions?: RequestInit, data?: DataMap): Observable<Response | null> {
    const options: RequestInit = { ...additionalOptions }
    if (data) {
      options.body = JSON.stringify(data)
    }
    return Observable.from(
      fetch(`${this.endpoint}/${path}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...this.defaultHeaders,
        },
        mode: 'cors',
        credentials: 'include',
      }).then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          // Not an error
          const token = response.headers.get('Set-Authorization') // Update token
          if (token && this.setAuthorization) {
            this.setAuthorization(token)
          }

          if (response.status === 204 || response.status === 205) {
            // Not empty
            return null
          }
          return response.json() as Promise<Response>
        }

        if (response.status === 401 && this.authenticationFailed) {
          // Not authorized
          this.authenticationFailed()
        }

        const statusTexts: { [key: number]: string } = {
          400: 'Bad Request',
          401: 'Unauthorized',
          403: 'Forbidden',
          404: 'Not Found',
          408: 'Request Timeout',
          500: 'Internal Server Error',
          503: 'Service Unavailable',
          504: 'Gateway Timeout',
        }

        const error: ResponseError = new Error(response.statusText || statusTexts[response.status])
        error.response = response

        let fallbackMessage

        // @TODO-AS some responses are not json but text, get them too
        try {
          error.responseJson = await response.json()
        } catch (err) {
          // Silence if response.json() will get error
          fallbackMessage = "Unable to parse BE's response"
        }
        if (!__DEV__) {
          if (![401, 403].includes(response.status)) {
            Sentry.captureException(new Error(`[Rest error]: ${error.message || response.statusText}`), {
              level: Sentry.Severity.Error,
              extra: {
                path,
                method: additionalOptions?.method,
                rawResponse: response,
                rawJsonResponse: error.responseJson ?? fallbackMessage,
              },
            })
          }
        }

        throw error
      })
    )
  }

  get<Response = any>(path: string, options: DataMap = {}): Observable<Response | null> {
    return this.request<Response>(path, options)
  }

  post<Response>(path: string, data: DataMap = {}, options: DataMap = {}): Observable<Response | null> {
    return this.request<Response>(path, { ...options, method: 'POST' }, data)
  }

  put(path: string, data: DataMap = {}, options: DataMap = {}): Observable<any> {
    return this.request(path, { ...options, method: 'PUT' }, data)
  }

  patch(path: string, data: DataMap = {}, options: DataMap = {}): Observable<any> {
    return this.request(path, { ...options, method: 'PATCH' }, data)
  }

  delete(path: string, options: DataMap = {}): Observable<any> {
    return this.request(path, { ...options, method: 'DELETE' })
  }
}

export { Api as ApiClient }
export default new Api()
