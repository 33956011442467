import { Observable } from 'rxjs'
import { Map } from 'immutable'

import {
  type DonationScheduleWhereInput,
  type DonationSchedulesQuery,
  type DonationSchedulesQueryVariables,
  DonationSchedulesDocument,
  type CancelDonationScheduleMutation,
  type CancelDonationScheduleMutationVariables,
  CancelDonationScheduleDocument,
  type UpdateDonationScheduleMutation,
  type UpdateDonationScheduleMutationVariables,
  UpdateDonationScheduleDocument,
} from 'src/generated/graphql'
import type { CurrencyCode } from 'src/containers/modules/CurrencyFormat'

import { query, mutate } from '../graphql'

import { addMultipleAction, listLoadedAction, addAction } from './actions'
import DonationSchedule from './model'

type RecurringDonationUpdatableFields = {
  amount?: number
  endDate?: Date
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class DonationScheduleApi {
  public static getByFilter(where: DonationScheduleWhereInput): Observable<
    Array<{
      type: string
      donationSchedules: Map<string, DonationSchedule>
    }>
  > {
    return Observable.from(
      query<DonationSchedulesQuery, DonationSchedulesQueryVariables>(DonationSchedulesDocument, { where })
    ).map((result) => {
      const actions = [
        addMultipleAction(
          Map(
            result.data.donationSchedules.map(({ Campaign, Nonprofits, ...donationSchedule }) => {
              const donationScheduleRecord = new DonationSchedule({
                ...donationSchedule,
                currencyCode: donationSchedule.currencyCode as CurrencyCode,
                campaign: Campaign,
                nonprofits: Nonprofits,
              })
              return [donationScheduleRecord.id, donationScheduleRecord]
            })
          )
        ),
      ]
      if (!where.id) {
        actions.push(listLoadedAction())
      }
      return actions
    })
  }

  public static cancel(id: string): Observable<{
    type: string
    donationSchedule?: DonationSchedule
  }> {
    return Observable.from(
      mutate<CancelDonationScheduleMutation, CancelDonationScheduleMutationVariables>(CancelDonationScheduleDocument, {
        id,
      })
      // Updating status to Inactive in canceled schedule
    ).map((result) =>
      addAction(
        new DonationSchedule({
          ...result.data?.cancelDonationSchedule,
          currencyCode: result.data?.cancelDonationSchedule?.currencyCode as CurrencyCode,
          nonprofits: result.data?.cancelDonationSchedule?.Nonprofits,
        })
      )
    )
  }

  // Updates a donationSchedule
  public static update(
    id: string,
    data: RecurringDonationUpdatableFields
  ): Observable<{
    type: string
    donationSchedule?: DonationSchedule
  }> {
    return Observable.from(
      mutate<UpdateDonationScheduleMutation, UpdateDonationScheduleMutationVariables>(UpdateDonationScheduleDocument, {
        id,
        data,
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ).map((result) =>
      addAction(
        new DonationSchedule({
          ...result.data?.updateDonationSchedule,
          currencyCode: result.data?.updateDonationSchedule?.currencyCode as CurrencyCode,
          nonprofits: result.data?.updateDonationSchedule?.Nonprofits,
        })
      )
    )
  }
}
