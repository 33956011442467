import { Observable } from 'rxjs'
import i18n from 'i18next'

import config from 'src/config'
import OrganizationApi from 'src/entities/organization/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCompanies, selectCompaniesLoaded } from 'src/entities/organization/selectors'

import { initSuccessAction, initFailedAction, setUrlAction, setSelectedCompanyIdAction } from './actions'
import { INIT, LOGIN } from './constants'
import { selectSelectedCompanyId } from './selectors'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ preSelectedCompanyId }) => {
    const state = store.getState()
    const actions = [initSuccessAction()]

    if (preSelectedCompanyId) {
      actions.unshift(setSelectedCompanyIdAction(preSelectedCompanyId))
    }

    if (selectCompaniesLoaded(state)) {
      return Observable.of(...actions)
    }

    return OrganizationApi.fetchCompanies()
      .mergeMap((resultingActions) => [...resultingActions, ...actions])
      .catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('loginScreen:failedLoadingCompanies'))))
  })

const login = (action$, store) =>
  action$.ofType(LOGIN).mergeMap(({ username }) => {
    const state = store.getState()
    const companies = selectCompanies(state)
    const companyId = selectSelectedCompanyId(state)
    const company = companies.find((companyObject) => companyObject.id === companyId)
    return Observable.of(
      setUrlAction(
        `${config.apiEndpoint}/auth/${company.sso.provider || company.sso2.provider}/${
          company.sso.identifier || company.sso2.identifier
        }?username=${encodeURIComponent(username)}`
      )
    )
  })

export default [init, login]
