import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, StyleSheet } from 'react-primitives'
import { Set } from 'immutable'
import { useTranslation } from 'react-i18next'

import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import DonateButton from 'src/retired/shared/DonateButton'
import Chip from 'src/retired/shared/Chip'
import {
  Action,
  ActivityIndicator,
  Alert,
  Avatar,
  Button,
  Divider,
  Icon,
  Row,
  Spacing,
  Text,
  Touchable,
} from 'src/retired/elements'
import { Redirect, useHistory, useLocation } from 'src/navigation'
import { colors } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { formatNumber } from 'src/utils/format'
import User from 'src/entities/user/model'
import { Body1, Body2 } from 'src/retired/shared/Typography'

import { selectLoading, selectRating, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export const DeedRate = () => {
  const { t } = useTranslation('deedScreen')
  const location = useLocation<{ location: string }>()
  const history = useHistory()

  const dispatch = useDispatch()

  useInjectReducer({ key: 'deedRate', reducer })
  useInjectEpics({ key: 'deedRate', epics })

  const user = useSelector(selectCurrentUser)
  const rating = useSelector(selectRating) as number
  const loading = useSelector(selectLoading) as boolean
  const error = useSelector(selectError) as Error

  const [selected, setSelected] = useState(Set())

  const deed = user?.ratePending[0]

  const redirectLocation = location?.state?.location || '/home'

  if (error) {
    return <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen>
  }
  if (!deed) {
    return <Redirect to={redirectLocation} />
  }

  return (
    <>
      <ImageHeaderScrollView
        image={deed.mainPicture}
        title={deed.name}
        subTitle={deed.organization?.name}
        noBack
        renderForeground={() => (
          <View style={styles.titleContainer}>
            {deed.organization?.name ? (
              <Text size={12} color={colors.white} style={styles.organization} numberOfLines={1}>
                {deed.organization?.name}
              </Text>
            ) : null}
            <Text size={24} color={colors.white} medium style={styles.title} numberOfLines={2}>
              {deed.name}
            </Text>
          </View>
        )}
      >
        <View style={styles.content}>
          <Body2
            weight="500"
            underline
            style={{ alignSelf: 'center', position: 'absolute', top: 24, right: 24 }}
            onPress={() => {
              dispatch(Actions.submitAction(deed.id, 0, selected, true))
              history.push(redirectLocation)
            }}
          >
            {t`Skip`}
          </Body2>

          <Body1 weight="500" style={{ alignSelf: 'center' }}>
            {t`congrats`} {t`howWasYourDeed`}
          </Body1>

          <Spacing marginBottom={16} />

          <Body2 style={{ alignSelf: 'center', textAlign: 'center' }}>{t`yourFeedbackHelpsUs`}</Body2>

          <Spacing marginBottom={16} />

          <View style={styles.ratingContainer}>
            <Button color="transparent" onPress={() => dispatch(Actions.rateAction(1))}>
              <Icon icon={rating > 0 ? 'starFilledPink' : 'starOutlinePink'} style={styles.ratingStar} />
            </Button>
            <Button color="transparent" onPress={() => dispatch(Actions.rateAction(2))}>
              <Icon icon={rating > 1 ? 'starFilledPink' : 'starOutlinePink'} style={styles.ratingStar} />
            </Button>
            <Button color="transparent" onPress={() => dispatch(Actions.rateAction(3))}>
              <Icon icon={rating > 2 ? 'starFilledPink' : 'starOutlinePink'} style={styles.ratingStar} />
            </Button>
            <Button color="transparent" onPress={() => dispatch(Actions.rateAction(4))}>
              <Icon icon={rating > 3 ? 'starFilledPink' : 'starOutlinePink'} style={styles.ratingStar} />
            </Button>
            <Button color="transparent" onPress={() => dispatch(Actions.rateAction(5))}>
              <Icon icon={rating > 4 ? 'starFilledPink' : 'starOutlinePink'} style={styles.ratingStar} />
            </Button>
          </View>

          {deed.organization?.canDonate() && user?.hasFeature('donations') && (
            <>
              <Divider />
              <DonateButton
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                model={deed.organization}
              >{t`donateToOrganization`}</DonateButton>
              {deed.matchingPercentage &&
              deed.matchingMaximumCurrencies &&
              deed.matchingMaximumCurrencies[deed.currencyCode] > 0 ? (
                <Spacing marginTop={15}>
                  <Chip type="square" backgroundColor="yellow" textWeight="bold">
                    {`${t('donationsMatched', {
                      percentage: `${formatNumber(deed.matchingPercentage / 100)}X`,
                    })}`.toUpperCase()}
                  </Chip>
                </Spacing>
              ) : null}
            </>
          )}

          {deed.attendees && deed.attendees.length > 1 && (
            <>
              <Divider />

              <Body1 weight="500">{t`deedIsAboutBuildingACommunity`}</Body1>
              <Body2>{t`addFriendsFromTheDeedBelow`}</Body2>

              <View style={{ alignSelf: 'stretch' }}>
                {deed.attendees
                  .filter(
                    (attendee) =>
                      !(user?.myFriends.find((friend) => friend.id === attendee.id) || user?.id === attendee.id)
                  )
                  .map((attendee: User) => {
                    const isSelected = selected.has(attendee.id)

                    return (
                      <Touchable
                        key={attendee.id}
                        style={{ marginTop: 20 }}
                        onPress={() => {
                          setSelected((prev) => (isSelected ? prev.delete(attendee.id) : prev.add(attendee.id)))
                        }}
                      >
                        <Row style={{ alignItems: 'center' }}>
                          <Avatar user={attendee} size={50} link={false} selected={isSelected} />
                          <Text size={15} medium numberOfLines={1} style={{ paddingHorizontal: 10, flexGrow: 1 }}>
                            {attendee.name}
                          </Text>
                          <Text size={13} medium underline right>
                            {isSelected ? t`remove` : t`add`}
                          </Text>
                        </Row>
                      </Touchable>
                    )
                  })}
              </View>
            </>
          )}
        </View>
      </ImageHeaderScrollView>

      <Action>
        <Button
          color={rating > 0 ? 'primary' : 'light'}
          onPress={() => {
            if (rating) {
              dispatch(Actions.submitAction(deed.id, rating, selected))
              history.replace(redirectLocation)
            } else {
              Alert.alert(t`pleaseRateTheDeedBelow`)
            }
          }}
        >
          {loading ? <ActivityIndicator color="#fff" /> : t`common:Submit`}
        </Button>
      </Action>
    </>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    width: '100%',
    height: 240,
    position: 'absolute',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingLeft: 25,
    paddingRight: 25,
  },
  organization: {
    marginBottom: 10,
  },
  title: {
    minHeight: 30,
    marginBottom: 25,
  },
  ratingContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: 195,
  },
  ratingStar: {
    width: 27,
    height: 26,
    // marginLeft: 5,
    // marginRight: 5
  },
  content: {
    alignSelf: 'stretch',
    padding: 25,
    paddingBottom: 100,
    alignItems: 'flex-start',
  },
  match: {
    backgroundColor: colors.yellow,
    marginTop: 10,
    paddingTop: 3,
    paddingBottom: 1,
    paddingHorizontal: 10,
    borderRadius: 15,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default DeedRate
