import React, { useState } from 'react'
import PopoverMUI from '@mui/material/Popover'
import Button from '@mui/material/Button'

import { Body2 } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'

export interface PopoverMenuOption {
  label: string
  color: string
}

export const PopoverMenu = ({
  buttonContent,
  options,
  onOptionClicked,
  disabled,
}: {
  buttonContent: JSX.Element
  options: PopoverMenuOption[]
  onOptionClicked: (index: number) => void
  disabled: boolean
}): JSX.Element => {
  const [leaveCommunityDropdownRef, setLeaveCommunityDropdownRef] = useState<HTMLButtonElement | null>(null)

  const onClickOption = (index: number): void => {
    setLeaveCommunityDropdownRef(null)
    onOptionClicked(index)
  }

  return (
    <>
      <Button
        onClick={(evt) => {
          if (!disabled) {
            setLeaveCommunityDropdownRef(evt.currentTarget)
          }
        }}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
        }}
      >
        {buttonContent}
      </Button>
      <PopoverMUI
        open={Boolean(leaveCommunityDropdownRef)}
        anchorEl={leaveCommunityDropdownRef}
        onClose={() => setLeaveCommunityDropdownRef(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          {options.map((option, index) => (
            <Touchable
              key={index}
              style={{ padding: 12, display: 'flex', alignItems: 'center' }}
              onPress={() => {
                void onClickOption(index)
              }}
            >
              <Body2 style={{ width: 144 }} fontSize={16} weight="500" colour={option.color} center>
                {option.label}
              </Body2>
            </Touchable>
          ))}
        </>
      </PopoverMUI>
    </>
  )
}
