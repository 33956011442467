import { Observable } from 'rxjs'
import { push, replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import LocationApi from 'src/entities/location/api'
import { selectUserLocations } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { initAction as initFeedAction, resetScrollPositionsAction } from 'src/containers/screens/Feed/actions'
import { clearAction as clearDeedsAction } from 'src/entities/deed/actions'
import { clearAction as clearOrganizationsAction } from 'src/entities/organization/actions'
import { clearAction as clearCampaignsAction } from 'src/entities/campaign/actions'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { initSuccessAction, initFailedAction, submitAction, submitSuccessAction, submitFailedAction } from './actions'
import { INIT, SELECT_LOCATION, SUBMIT } from './constants'
import { selectSelectedLocation } from './selectors'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    const locations = selectUserLocations(state)

    if (locations.size > 0) {
      return Observable.of(initSuccessAction())
    }

    return LocationApi.fetchAll()
      .mergeMap((action) => [action, initSuccessAction()])
      .catch((e) =>
        Observable.of(initFailedAction(e), showErrorAction(i18n.t('locationRegister:failedLoadingLocations')))
      )
  })

const selectLocation = (action$, store) =>
  action$.ofType(SELECT_LOCATION).exhaustMap(({ path, replacePath }) => {
    const state = store.getState()
    if (selectIsAuthenticated(state)) {
      const onboadring = path === '/register/location'
      if (onboadring) {
        return []
      }
      return Observable.of(submitAction('/profile'))
    }
    return Observable.of(replacePath ? replace('/register/individual') : push('/register/individual'))
  })

const submit = (action$, store) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ redirectTo }) =>
      UserApi.update({ location: selectSelectedLocation(store.getState()) })
        .mergeMap((action) => [
          action,
          clearDeedsAction(),
          clearOrganizationsAction(),
          clearCampaignsAction(),
          initFeedAction(),
          resetScrollPositionsAction(),
          push(redirectTo),
          submitSuccessAction(),
        ])
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    )
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [init, selectLocation, submit]
