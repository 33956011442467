import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import DeedsBlock from 'src/retired/blocks/DeedsBlock'
import { selectSuggestedDeedsLoaded, selectSuggestedDeeds } from 'src/entities/deed/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'

import { fetchSuggestedDeedsAction } from './actions'
import epics from './epics'
import reducer from './reducer'

export const SuggestedDeedsBlock = () => {
  const { t } = useTranslation('homeScreen')
  useInjectReducer({ key: 'home', reducer })
  useInjectEpics({ key: 'home', epics })

  const suggestedDeedsLoaded = useSelector(selectSuggestedDeedsLoaded)
  const suggestedDeeds = useSelector(selectSuggestedDeeds)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!suggestedDeedsLoaded) {
      dispatch(fetchSuggestedDeedsAction())
    }
  }, [])

  // We don't show a loading spinner for suggested deeds as there are already many spinners on the home screen
  // Suggested deeds is the last item, so there it might be fine if it pops up out of nowhere
  return suggestedDeeds?.size > 0 ? (
    <DeedsBlock title={t`suggestedDeedsForYou`} deeds={suggestedDeeds.take(15)} hidePartnerLogo subTitle="" />
  ) : null
}
