import React from 'react'
import { View } from 'react-primitives'

import { css } from 'src/theme/styled'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import Hoverable from 'src/retired/shared/Hoverable'
import { colors } from 'src/theme'

export interface IconButtonProps {
  icon: string | JSX.Element
  size?: number
  title?: string
  color?: 'danger' | 'inverse' | 'black-icon'
  style?: React.CSSProperties
  accessibilityLabel?: string
}
export type IconButtonElement = React.ReactElement<IconButtonProps>

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  color,
  size = 40,
  style,
  accessibilityLabel = 'button',
  ...rest
}): IconButtonElement => {
  let borderColor: string
  let borderColorHover: string
  let fillColor: string
  let backgroundColor = colors.white
  let backgroundColorHover = colors.white
  switch (color) {
    case 'danger':
      borderColor = '#FF3131'
      borderColorHover = '#E62626'
      break

    case 'inverse':
      borderColor = colors.black
      borderColorHover = colors.black
      backgroundColor = colors.black
      backgroundColorHover = '#333536'
      fillColor = colors.white
      break

    case 'black-icon':
      borderColor = colors.gray01
      borderColorHover = colors.black
      fillColor = colors.black
      break

    default:
      borderColor = colors.gray02
      borderColorHover = colors.gray01
  }
  return (
    <Touchable {...rest} accessibilityRole="button" focusable accessibilityLabel={accessibilityLabel}>
      <Hoverable>
        {(isHovered: boolean) => (
          <View
            style={[
              css`
                width: ${`${size}px`};
                height: ${`${size}px`};
                background-color: ${isHovered && !rest.disabled ? backgroundColorHover : backgroundColor};
                border-color: ${isHovered && !rest.disabled ? borderColorHover : borderColor};
                border-width: 1px;
                border-radius: 40px;
                align-items: center;
                justify-content: center;
                cursor: ${rest.disabled ? undefined : 'pointer'};
              `,
              style,
            ]}
          >
            {typeof icon === 'string' ? (
              <Icon icon={icon} width={size < 40 ? 12 : 16} height={size < 40 ? 12 : 16} hexColor={fillColor} />
            ) : (
              icon
            )}
          </View>
        )}
      </Hoverable>
    </Touchable>
  )
}
export default IconButton
