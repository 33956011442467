import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { addMinutes } from 'date-fns'

import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { Row, Loading } from 'src/retired/elements'
import { Body1, H4, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Donation from 'src/entities/donation/model'
import { selectDonationsForUser } from 'src/entities/donation/selectors'
import { useInjectEpics } from 'src/utils/injectEpics'
import profileEpics from 'src/containers/screens/Profile/Donations/epics'
import profileReducer from 'src/containers/screens/Profile/Donations/reducer'
import * as donationsActions from 'src/containers/screens/Profile/Donations/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import { TypedMap } from 'src/utils/typed-map'
import { TextWithIcon } from 'src/components/TextWithIcon'
import { PageTitle } from 'src/components'
import { formatFullName } from 'src/utils'

import { useDateRangeFromUrl } from './useDateRangeFromUrl'
import { DonationReceiptTable } from './DonationReceiptTable'

import './styles.css'

export const DonationReceipt = () => {
  useInjectEpics({ key: 'profileDonations', epics: profileEpics })
  useInjectReducer({ key: 'profileDonations', reducer: profileReducer })

  const dispatch = useDispatch()

  const { t } = useTranslation('donationReceipt')
  const { images, colors, metrics } = useDeedTheme()
  const user = useSelector(selectCurrentUser)
  const brand = useSelector(selectUserBrand)

  const dateRange = useDateRangeFromUrl()
  const from = dateRange?.from
  const to = dateRange?.to
  const invalidDates = !from || !to

  const fromAsDate = !invalidDates && new Date(from)
  const toAsDate = !invalidDates && new Date(to)

  const donations = useSelector((state) => selectDonationsForUser(state, user)) as TypedMap<Donation>

  useEffect(() => {
    if (!invalidDates) {
      dispatch(donationsActions.initAction('me', from, to))
    }
  }, [])

  if (!user) {
    return <Loading fill={false} />
  }

  // addMinutes is needed, because the dates are in utc. If we don't add the TZ offset it would show the previous selected month (in countries with negative tz offset like US)
  const datePeriodHeader =
    !invalidDates &&
    `${t('dayMonthYear', {
      date: addMinutes(fromAsDate, fromAsDate.getTimezoneOffset()),
    })} - ${t('dayMonthYear', {
      date: addMinutes(toAsDate, toAsDate.getTimezoneOffset()),
    })}`

  return (
    <View style={{ width: '100%', alignItems: 'flex-start', padding: metrics.isLarge ? 64 : 24 }}>
      <PageTitle title={datePeriodHeader} titleTemplate="Donation Summary %s" />
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: 64,
        }}
      >
        <H5>{`${formatFullName(user.legalName)}`}</H5>
        {/* note-AZ: Image component from the retured folder disapears on print screen, so for now we use img tag */}
        <View style={{ flexDirection: 'row', alignContent: 'flex-end' }}>
          {brand?.mainPicture && (
            <img src={brand.mainPicture} width="125" height="50" style={{ objectFit: 'contain' }} alt="" />
          )}
          <img src={images.poweredByDeed} style={{ width: 64, height: 50 }} alt="" />
        </View>
      </View>

      <View style={{ width: '100%', flexDirection: 'column', marginBottom: metrics.isSmall ? 20 : 52 }}>
        <View
          style={{
            width: '100%',
            flexDirection: metrics.isSmall ? 'column' : 'row',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <H4 weight="bold" marginBottom={20}>{t`donationSummaryHeader`}</H4>
          </View>
          <Button onClick={() => window.print()} variant="outlined" sx={{ height: 32, displayPrint: 'none' }}>
            <TextWithIcon icon="receiptLongOutlined" text={t`printOrSaveAsPdf`} color={colors.brandColor} />
          </Button>
        </View>

        <H5 style={{ marginTop: metrics.isSmall ? 20 : 0 }}>{datePeriodHeader}</H5>
      </View>

      <View style={{ flex: 3, width: '100%' }}>
        {invalidDates ? (
          <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Body1> {t('invalidDates')}</Body1>
          </View>
        ) : (
          <DonationReceiptTable donations={donations.toArray()} />
        )}
      </View>

      <Body1 marginBottom={40} marginTop={40} colour={colors.grayMediumDark}>
        {t('legalDisclaimer')}
      </Body1>

      <Row
        style={{
          borderTopWidth: 2,
          borderTopColor: colors.grayLight,
          paddingTop: 16,
          alignItems: 'center',
          alignContent: 'flex-start',
          gap: 16,
        }}
      >
        <img src={images.deedLogoHorizontalGray} style={{ width: 75, height: 30 }} alt="" />
        <Body1 colour={colors.grayMediumDark}>Copyright {new Date().getFullYear()}</Body1>
      </Row>
    </View>
  )
}

export default DonationReceipt
