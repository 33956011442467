import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import FeedList from 'src/retired/blocks/Feed/List'
import FeedCalendar from 'src/retired/blocks/Feed/Calendar'

import { getIndexOfFirstDeedForDate } from './helpers'

export default class FeedScrollHandler extends PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    deeds: PropTypes.object,
    small: PropTypes.bool,
    scrollPositions: PropTypes.object,
    color: PropTypes.string,
    listProps: PropTypes.object,
    style: PropTypes.any,
    deedType: PropTypes.string,
    match: PropTypes.object,
  }

  scrolling = false

  state = {
    activeDate: null,
  }

  calendarRef = React.createRef()

  listRef = React.createRef()

  componentDidMount() {
    this.props.actions.initAction(this.props.deedType)
    this.setScrollPosition()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.props.actions.setScrollPositionAction(prevProps.match.url, this.scrollPosition)
      this.setScrollPosition()
    }
  }

  componentWillUnmount() {
    if (this.listRef.current) {
      this.props.actions.setScrollPositionAction(this.props.match.url, this.scrollPosition)
    }
  }

  handleScroll = (event) => {
    this.scrollPosition = event.nativeEvent.contentOffset
      ? event.nativeEvent.contentOffset.y
      : event.nativeEvent.target.scrollTop
    if (this.scrolling) {
      return
    }
    let firstElementInViewDate = null
    const items = [...event.nativeEvent.target.querySelectorAll('[data-date]')]
    items.forEach((item) => {
      if (
        item.parentElement.offsetTop + item.parentElement.clientHeight >= event.target.scrollTop + 80 &&
        !firstElementInViewDate
      ) {
        firstElementInViewDate = item.dataset.date
      }
    })
    if (this.state.activeDate !== firstElementInViewDate) {
      if (this.calendarRef.current) {
        this.calendarRef.current.scrollCalendarIntoView(firstElementInViewDate)
      }
      this.setState({ activeDate: firstElementInViewDate })
    }
  }

  scrollListIntoView = (date) => {
    if (this.listRef.current) {
      const firstDeedForDateIndex = getIndexOfFirstDeedForDate(this.props.deeds, date)
      if (firstDeedForDateIndex !== null) {
        const items = [...this.listRef.current.querySelectorAll('[data-date]')]
        if (items[firstDeedForDateIndex]) {
          this.scrolling = true
          setTimeout(() => items[firstDeedForDateIndex].scrollIntoView(true), 100)
          this.setState({ activeDate: date })
          setTimeout(() => {
            this.scrolling = false
          }, 500)
        }
      }
    }
  }

  setScrollPosition() {
    const scrollPosition = this.props.scrollPositions.get(this.props.match.url)
    if (this.listRef.current && scrollPosition >= 0 && this.props.deeds.size > 0) {
      // No idea why it's needed, but without it the scrolling doesn't happen on Android (at least)
      setTimeout(() => {
        if (this.listRef.current) {
          if (this.listRef.current.scrollTo) {
            this.listRef.current.scrollTo(0, scrollPosition)
          } else {
            this.listRef.current.scrollTop = scrollPosition
          }
        }
      }, 0)
    }
  }

  render() {
    return (
      <>
        {this.props.small && ['Event', 'BaseEvent'].includes(this.props.deedType) && (
          <FeedCalendar
            ref={this.calendarRef}
            activeDate={this.state.activeDate}
            deeds={this.props.deeds}
            scrollListIntoView={this.scrollListIntoView}
            small={this.props.small}
            color={this.props.color}
          />
        )}
        <FeedList {...this.props.listProps} listRef={this.listRef} handleScroll={this.handleScroll} />
      </>
    )
  }
}
