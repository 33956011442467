import { Map } from 'immutable'

import { ADD_MULTIPLE } from './constants'
import Skill from './model'

export type SkillMap = Map<string, Skill>

export const initialState: SkillMap = Map()

export default (state: SkillMap = initialState, action: any): SkillMap => {
  switch (action.type) {
    case ADD_MULTIPLE:
      return state.merge(action.skills)
  }
  return state
}
