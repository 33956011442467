import React from 'react'
import moment from 'moment'
import { View } from 'react-primitives'

import { Activity } from 'src/containers/modules/Actions/types'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Avatar, Row } from 'src/retired/elements'
import { Body2, Label } from 'src/retired/shared/Typography'
import { StatusChip } from 'src/containers/modules/Actions/ActivityStatus/StatusChip'
import { formatFullName } from 'src/utils/formatFullName'

const getLatestUpdateTimestamp = (activity: Activity) => {
  switch (activity.status) {
    case 'joined':
      return activity.lastJoinedAt

    case 'completed':
      return activity.lastCompletedAt
  }
}

export const ActivityItem = ({ activity }: { activity: Activity }) => {
  const { colors } = useDeedTheme()
  const latestUpdate = getLatestUpdateTimestamp(activity)

  if (!activity) {
    return null
  }

  return (
    <Row
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: `1px solid ${colors.gray02}`,
      }}
    >
      <Row>
        <Avatar user={activity.User} style={{ marginRight: 12 }} />
        <View style={{ flex: 1, marginTop: 2, marginBottom: 12, paddingRight: 8, minWidth: 65 }}>
          <Body2 weight="500" numberOfLines={2}>
            {formatFullName(activity.User.preferredName || activity.User.fullName)}
          </Body2>
          {latestUpdate && <Label colour={colors.gray}>{moment(latestUpdate).fromNow()}</Label>}
        </View>
      </Row>
      <View style={{ marginTop: 2 }}>
        <StatusChip activityStatus={activity.status} small />
      </View>
    </Row>
  )
}
