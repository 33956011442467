import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useLocation } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectCookieConsentResponse, selectShowCookieConsent } from 'src/containers/modules/CookieConsent/selectors'
import { Platform } from 'src/utils'

const WIDGET_URL = 'https://static.zdassets.com/ekr/snippet.js'
const WIDGET_ID = 'ze-snippet'
const WIDGET_SNIPPET_KEY = '00877dfa-debb-46a5-a306-54ca1a96c98b'

declare const zE: (prompt1: string, prompt2: string, value?: unknown) => void

declare const window: Window & { zE: typeof zE }

const loadScript = async (): Promise<void> =>
  new Promise((resolve) => {
    const script = document.createElement('script')
    script.id = WIDGET_ID
    script.src = `${WIDGET_URL}?key=${WIDGET_SNIPPET_KEY}`
    script.addEventListener('load', () => resolve())
    document.body.appendChild(script)
  })

const ZendeskWidget = () => {
  const [isLoaded, setIsLoaded] = useState(!!window.zE)

  useEffect(() => {
    if (!isLoaded && Platform.OS === 'web') {
      loadScript().then(
        () => {
          setIsLoaded(true)
        },
        (e) => {
          // eslint-disable-next-line no-console
          console.error(`Failed to load zendesk: ${e}`)
        }
      )
    }

    return () => {
      if (typeof window.zE !== 'undefined') {
        // If PS team switches from webWidget to messenger, the hide function would throw.
        try {
          zE('webWidget', 'hide')
        } catch (e) {
          zE('messenger', 'hide')
        }
      }
    }
    // We'll disable the linter rule as we only want this to run at first run
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const SupportChatWidget = (): JSX.Element | null => {
  const { metrics } = useDeedTheme()
  const location = useLocation()
  const hasAcceptedCookies = useSelector(selectCookieConsentResponse)
  const isCookieConsentDisplayed = useSelector(selectShowCookieConsent)
  // I guess it doesn't correspond with legal standards to give data away when the user ignores the cookie consent.
  // I was told that we will fix it soon
  const noCookieResponseOrAccepted = hasAcceptedCookies || isCookieConsentDisplayed

  const showChatWidget = metrics.isLarge || location.pathname === '/profile/settings'
  if (showChatWidget && noCookieResponseOrAccepted) {
    return <ZendeskWidget />
  }

  return null
}

export default SupportChatWidget
