import React from 'react'
import { StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { styled } from 'src/theme/styled'
import { Platform } from 'src/utils'
import { AffiliationProfileFragment, useAffiliationsListProfileQuery } from 'src/generated/graphql'
import { ScrollView } from 'src/retired/elements'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { colors } from 'src/theme'
import { H5 } from 'src/retired/shared/Typography'

import AffiliationCard from './AffiliationCard'

const Section = styled.View`
  background-color: white;
  border-width: 1px;
  border-color: ${colors.lightBorderGray};
  ${(props) =>
    !props.last &&
    `
      margin-bottom: 25px;
      border-bottom-width: 1px;
      border-bottom-color: ${colors.lightBorderGray};
  `}
`

export const SectionTitle = styled.View`
  padding: 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SectionContent = styled.View`
  padding: 0 25px 15px;
`

const ieScrollViewFix = Platform.isIE ? { overflowY: 'visible', overflowX: 'visible' } : {}

const styles = StyleSheet.create({
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginHorizontal: -25,
    marginBottom: -15,
    ...ieScrollViewFix,
  },
  scrollViewContainer: {
    paddingRight: 10,
    paddingBottom: 15,
  },
})

// Sort first by if affiliation is active, then by starting date
const sortAffiliations = (affiliations: AffiliationProfileFragment[]): AffiliationProfileFragment[] =>
  affiliations
    ?.filter((affiliation) => (Date.parse(affiliation.endingAt) || Infinity) > Date.now())
    .sort((a, b) => Date.parse(b.startingAt) - Date.parse(a.startingAt))
    .concat(
      affiliations
        .filter((affiliation) => (Date.parse(affiliation.endingAt) || Infinity) < Date.now())
        .sort((a, b) => Date.parse(b.startingAt) - Date.parse(a.startingAt))
    )

const AffiliationCompact = (): JSX.Element | null => {
  const { data, loading, error } = useAffiliationsListProfileQuery({
    onError: () => {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    },
  })
  const { t } = useTranslation('affiliationCompact')
  const dispatch = useDispatch()

  if (loading || error) {
    return null
  }

  return data?.affiliations ? (
    <Section>
      <SectionTitle>
        <H5>{t`affiliatedOrganizations`}</H5>
      </SectionTitle>
      <SectionContent>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContainer}
          showsHorizontalScrollIndicator
          horizontal
        >
          {sortAffiliations(data.affiliations).map((affiliation) => (
            <AffiliationCard key={affiliation.id} affiliation={affiliation} />
          ))}
        </ScrollView>
      </SectionContent>
    </Section>
  ) : null
}

export default AffiliationCompact
