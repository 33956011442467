import React from 'react'
import { useTranslation } from 'react-i18next'

import { Action, getRewardText } from 'src/containers/modules/Actions/types'

import { ActionModal, ActionModalType } from './ActionModal'

export const CompleteSuccessModal = ({
  action,
  visible,
  buttonsDisabled = false,
  onClose,
}: {
  action: Action
  visible: boolean
  buttonsDisabled?: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation('actions')
  const title = t('actionCompleted')
  const subTitle = getRewardText(action, t, t('youHaveCompleted', { action: action.title }))

  return (
    <ActionModal
      type={ActionModalType.CompleteSuccess}
      title={title}
      subTitle={subTitle}
      visible={visible}
      buttonsDisabled={buttonsDisabled}
      onClose={onClose}
      primaryButtonData={{ text: t('gotIt'), onPress: onClose }}
    />
  )
}
