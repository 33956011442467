import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Spacing } from 'src/retired/elements'
import { selectCausesLoaded, selectPillars } from 'src/entities/cause/selectors'
import CausesBlock from 'src/retired/blocks/CausesBlock'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'

import { LoadingSpinner } from './LoadingSpinner'
import { fetchCausesAction } from './actions'
import epics from './epics'
import reducer from './reducer'

export const PillarsBlock = () => {
  const { t } = useTranslation('homeScreen')
  useInjectReducer({ key: 'home', reducer })
  useInjectEpics({ key: 'home', epics })

  const causesLoaded = useSelector(selectCausesLoaded)
  const pillars = useSelector(selectPillars)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!causesLoaded) {
      dispatch(fetchCausesAction())
    }
  }, [])

  if (!causesLoaded) {
    return (
      <Spacing marginTop={60}>
        <LoadingSpinner />
      </Spacing>
    )
  }

  return pillars.size > 0 ? (
    <CausesBlock causes={pillars.toArray()} title={t`featuredCauseAreas`} link="/causes/pillars" />
  ) : null
}
