import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  RELATION_ACCEPT,
  RELATION_ACCEPT_FAILED,
  RELATION_REJECT,
  RELATION_REJECT_FAILED,
  RELATION_REQUEST_FINISHED,
  INVITATION_REJECT,
  INVITATION_REJECT_FAILED,
  INVITATION_REQUEST_FINISHED,
} from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const refreshAction = () => ({
  type: REFRESH,
})
export const refreshSuccessAction = () => ({
  type: REFRESH_SUCCESS,
})
export const refreshFailedAction = (error) => ({
  type: REFRESH_FAILED,
  error,
})

export const relationAcceptAction = (id) => ({
  type: RELATION_ACCEPT,
  id,
})
export const relationAcceptFailedAction = (error) => ({
  type: RELATION_ACCEPT_FAILED,
  error,
})
export const relationRejectAction = (id) => ({
  type: RELATION_REJECT,
  id,
})
export const relationRejectFailedAction = (error) => ({
  type: RELATION_REJECT_FAILED,
  error,
})
export const relationRequestFinishedAction = (id) => ({
  type: RELATION_REQUEST_FINISHED,
  id,
})

export const invitationRejectAction = (id) => ({
  type: INVITATION_REJECT,
  id,
})
export const invitationRejectFailedAction = (error) => ({
  type: INVITATION_REJECT_FAILED,
  error,
})
export const invitationRequestFinishedAction = (id) => ({
  type: INVITATION_REQUEST_FINISHED,
  id,
})
