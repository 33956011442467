import React, { useEffect } from 'react'
import { View } from 'react-primitives'

import TouchableWithoutFeedback from 'src/retired/elements/TouchableWithoutFeedback'

interface Props {
  isOpen: boolean
  close: () => void
  backgroundColor?: string
  children: React.ReactNode
  innerStyle?: object
}

const MobileOverlay: React.FC<Props> = ({
  isOpen,
  close,
  backgroundColor = 'transparent',
  children,
  innerStyle = {},
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <View
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: 'rgba(30, 31, 32, 0.4)',
      }}
    >
      <TouchableWithoutFeedback
        onPress={(e) => {
          e.stopPropagation()
          e.preventDefault()
          close()
        }}
      >
        <View style={{ flexGrow: 1 }} />
      </TouchableWithoutFeedback>
      <View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 11,
          backgroundColor,
          padding: 20,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          flexGrow: 0,
          ...innerStyle,
        }}
      >
        {children}
      </View>
    </View>
  )
}
export default MobileOverlay
