import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSearchTerm } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SearchTermSynchronizer = () => {
  const dispatch = useDispatch()
  const { searchTerm } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    dispatch(setSearchTerm(searchTerm || ''))
  }, [searchTerm])

  return null
}
