import { Set, Record } from 'immutable'

import { VolunteerTimeOff, VolunteerTimeApproval, VolunteerReward, DeedRole } from 'src/generated/graphql'

import Milestone from '../deed/milestone'
import Organization from '../organization/model'

interface Deed {
  id: string
  name: string
}

const properties = {
  id: '',
  deed: undefined,
  nonprofit: undefined,
  duration: 0,
  date: undefined,
  milestone: undefined,
  notes: '',
  approval: '',
  externalNonprofitId: '',
  externalNonprofitName: '',
  VolunteerTimeOff: null,
  volunteerReward: undefined,
  VolunteerReward: undefined,
  Role: undefined,
}

export default class VolunteerTime extends Record(properties, 'VolunteerTime') implements VolunteerTime {
  public readonly id!: string

  public readonly deed!: Deed

  public readonly nonprofit!: Organization

  public readonly duration!: number

  public readonly date!: Date

  public readonly milestone!: Milestone

  public readonly notes!: string

  public readonly externalNonprofitId!: string

  public readonly externalNonprofitName!: string

  public readonly approval!: VolunteerTimeApproval

  public readonly VolunteerTimeOff!: VolunteerTimeOff

  public readonly volunteerReward!: string | undefined

  public readonly VolunteerReward!: VolunteerReward | undefined

  public readonly Role!: DeedRole | undefined

  constructor(values: any = {}) {
    super({
      ...values,
      id: values._id || values.id,
      deed: values.Deed
        ? {
            ...values.Deed,
            milestones:
              values.Deed.milestones && Set(values.Deed.milestones.map((milestone: any) => new Milestone(milestone))),
          }
        : undefined,
      nonprofit: values.Nonprofit || values.nonprofit || undefined,
      date: new Date(values.date),
    })
  }
}
