import Api from '../api'
import { ratedDeedAction } from '../user/actions'

export const RatingApi = {
  rate: (type: string, id: string, rating: string, skipped: boolean) =>
    Api.post('api/ratings', {
      documentType: type,
      documentId: id,
      rate: rating,
      ...(skipped && { skipped, rate: 0 }), // if we skip the rating, our rate is 0
      voteType: 'rate',
    }).map(() => ratedDeedAction()),
}

export default RatingApi
