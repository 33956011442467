import React from 'react'

import Chip from 'src/retired/shared/Chip'
import { ColorsProps } from 'src/theme/colors'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const RecurringChip = ({ text }: { text: string }): JSX.Element => {
  const { colors } = useDeedTheme()

  const backgroundColor = colors.lightGray as keyof ColorsProps

  return (
    <Chip type="square" backgroundColor={backgroundColor} textStyle={{ width: '3.3rem', textAlign: 'center' }}>
      {text}
    </Chip>
  )
}
