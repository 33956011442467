import { Observable } from 'rxjs'
import i18n from 'i18next'
import { Geolocation } from '@capacitor/geolocation'

import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import DeedsApi from 'src/entities/deed/api'
import * as Sentry from 'src/utils/Sentry'

import {
  initFailedAction,
  watchPositionAction,
  stopWatchingAction,
  setGeolocationAction,
  setGeolocationWatchIdAction,
  checkInSuccessAction,
  checkInFailedAction,
} from './actions'
import { INIT, WATCH_POSITION, STOP_WATCHING, CHECK_IN, DISABLED_CHECK_IN_CLICKED } from './constants'
import { selectGeolocationWatchId, selectDisabledCheckInClicks } from './selectors'

const PermissionStates = {
  prompt: 'prompt',
  promptWithRationale: 'prompt-with-rationale',
  granted: 'granted',
  denied: 'denied',
}

const init = (action$) =>
  action$
    .ofType(INIT)
    .mergeMap(() =>
      Observable.fromPromise(Geolocation.checkPermissions()).mergeMap((checkResponse) => {
        switch (checkResponse.location) {
          case PermissionStates.granted:
            return Observable.of(watchPositionAction())

          case PermissionStates.denied:
          case PermissionStates.promptWithRationale:
          case PermissionStates.prompt:
            return Observable.fromPromise(Geolocation.requestPermissions()).mergeMap((requestResponse) =>
              requestResponse.location === PermissionStates.granted
                ? Observable.of(watchPositionAction())
                : Observable.of()
            )

          default:
            console.error('Invalid response', checkResponse) // eslint-disable-line no-console
        }
        return Observable.of()
      })
    )
    .catch((e) => console.error(e)) // eslint-disable-line no-console

const watchPosition = (action$) =>
  action$.ofType(WATCH_POSITION).mergeMap(() =>
    Observable.create((observer) => {
      const geolocationWatchId = Geolocation.watchPosition(
        {
          timeout: 60000,
          enableHighAccuracy: true,
        },
        (geolocation) => {
          observer.next(setGeolocationAction(geolocation.coords))
        }
      )
      // geolocationWatchId is a Promise here. We didn't find an easy fix to resolve and don't break
      // the observer.next function above.
      observer.next(setGeolocationWatchIdAction(geolocationWatchId))
      return () => {
        geolocationWatchId?.then((watchId) => {
          Geolocation.clearWatch({ id: watchId })
        })
      }
    }).catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('deedScreen:retrievingLocationFailed'))))
  )

const stopWatching = (action$, store) =>
  action$.ofType(STOP_WATCHING).mergeMap(() => {
    selectGeolocationWatchId(store.getState())?.then((watchId) => {
      Geolocation.clearWatch({ id: watchId })
    })
    return Observable.of(setGeolocationWatchIdAction(null))
  })

const checkIn = (action$) =>
  action$.ofType(CHECK_IN).exhaustMap((payload) =>
    DeedsApi.checkIn(payload.id, payload.userId)
      .mergeMap((resultingAction) => [
        resultingAction,
        showMessageAction('Checked in!'),
        checkInSuccessAction(),
        stopWatchingAction(),
      ])
      .catch((e) => Observable.of(checkInFailedAction(e), showErrorAction(i18n.t('deedScreen:checkInFailed'))))
  )

const disabledCheckInClicked = (action$, store) =>
  action$.ofType(DISABLED_CHECK_IN_CLICKED).mergeMap(({ reason }) => {
    const disabledCheckInClicks = selectDisabledCheckInClicks(store.getState())
    if (disabledCheckInClicks > 1) {
      const error = new Error(`Disabled Check In Alert: ${reason}`)
      Sentry.captureException(error)
      return [
        showErrorAction(
          i18n.t('deedScreen:havingIssuesWithCheckingIn'),
          i18n.t('deedScreen:pleaseMakeSureYourLocation')
        ),
      ]
    }
    return []
  })

export default [init, watchPosition, stopWatching, checkIn, disabledCheckInClicked]
