export const GET_NONPROFIT = 'containers/logOffPlatformDonation/GET_NONPROFIT'
export const SUBMIT_EXTERNAL_DONATION = 'containers/logOffPlatformDonation/SUBMIT_EXTERNAL_DONATION'
export const SUBMIT_SUCCESS = 'containers/logOffPlatformDonation/SUBMIT_SUCCESS'
export const SUBMIT_FAILED = 'containers/logOffPlatformDonation/SUBMIT_FAILED'
export const ESTIMATE_MATCH = 'containers/logOffPlatformDonation/ESTIMATE_MATCH'
export const ESTIMATE_MATCH_RULE_CHANGE = 'containers/logOffPlatformDonation/ESTIMATE_MATCH_RULE_CHANGE'
export const ESTIMATE_MATCH_SUCCESS = 'containers/logOffPlatformDonation/ESTIMATE_MATCH_SUCCESS'
export const ESTIMATE_MATCH_FAILED = 'containers/logOffPlatformDonation/ESTIMATE_MATCH_FAILED'
export const FETCH_CAMPAIGNS_BY_NONPROFIT = 'containers/logOffPlatformDonation/FETCH_CAMPAIGNS_BY_NONPROFIT'
export const FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS =
  'containers/logOffPlatformDonation/FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS'
export const FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED =
  'containers/logOffPlatformDonation/FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED'
