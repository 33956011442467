import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import type { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import _ from 'lodash'
import i18n from 'i18next'

import { selectDeedById } from 'src/entities/deed/selectors'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import UserApi from 'src/entities/user/api'
import DeedsApi from 'src/entities/deed/api'
import OrganizationApi from 'src/entities/organization/api'
import DonationApi from 'src/entities/donation/api'
import { addAction, donationsForUserLoadedAction } from 'src/entities/donation/actions'
import Donation from 'src/entities/donation/model'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import * as Sentry from 'src/utils/Sentry'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { selectCampaignById, selectLoaded as selectCampaignsLoaded } from 'src/entities/campaign/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'
import type { State } from 'src/reducers'
import CampaignsApi from 'src/entities/campaign/api'
import { setLocalSettingAction } from 'src/localSettings/actions'
import Campaign from 'src/entities/campaign/model'
import { campaignLoadedAction } from 'src/entities/deed/actions'
import { addAction as addCampaignAction } from 'src/entities/campaign/actions'

import {
  INIT,
  DONATE,
  DONATE_SUCCESS,
  REGISTER_BETTERPLACE_DONATION,
  REGISTER_GIVEINDIA_DONATION,
  REGISTER_STRIPE_DONATION,
  REGISTER_PAYPAL_DONATION,
  REGISTER_RECURRING_PAYPAL_DONATION,
  REGISTER_RESUME_RECURRING_PAYPAL_DONATION,
  FETCH_PROVIDER_DATA,
  CREATE_CUSTOMER_ID,
} from './actionNames'
import {
  initSuccessAction,
  initFailedAction,
  donateSuccessAction,
  donateFailedAction,
  nonStripeDonationAction,
  fetchProviderDataSuccessAction,
  fetchProviderDataFailedAction,
  createCustomerIdSuccessAction,
  createCustomerIdFailedAction,
  DonatePayload,
} from './actions'
import type { FetchedProviderData } from './actions'

const init = (
  action$: ActionsObservable<{ id: string; kind: 'deed' | 'organization'; campaignId?: string; type: typeof INIT }>,
  store: Store<State>
) =>
  action$.ofType(INIT).mergeMap(({ id, kind, campaignId }) => {
    const actions = []
    const state = store.getState()
    const isAuthenticated = selectIsAuthenticated(state)

    if (!isAuthenticated) {
      actions.push(OrganizationApi.fetchCompanies())
    }

    if (id.startsWith('ext-')) {
      actions.push(Observable.of(nonStripeDonationAction()))
    }

    if (kind === 'deed') {
      const deed = selectDeedById(state, id)
      if (!deed) {
        actions.push(DeedsApi.fetch(id))
      }
    } else if (kind === 'organization') {
      const organization = selectOrganizationById(state, id)
      if (!organization) {
        actions.push(OrganizationApi.fetch(id))
      }

      const campaignsLoaded = selectCampaignsLoaded(state)
      // We need to load other campaigns, too for the edge case if a nonprofit is attached to multiple campaigns.
      // In this scenario, a user should be able to select the associated campaign.
      if (!campaignsLoaded) {
        actions.push(CampaignsApi.fetchAll())
      }
    }

    if (campaignId && !selectCampaignById(state, campaignId)) {
      actions.push(
        DeedsApi.fetchCampaignInfo(campaignId)
          .map((data) => [addCampaignAction(new Campaign(data)), campaignLoadedAction(campaignId)])
          .catch((error) => {
            const isAuthError =
              error?.response?.status === 403 || error?.responseJson?.message.toLowerCase() === 'not allowed'

            if (isAuthError) {
              Sentry.captureException(error)

              // @NOTE-CH: if the user is authenticated, but has no access to the campaign, we ignore it
              if (isAuthenticated) {
                return Observable.of(initSuccessAction())
              }

              const previousLocation = `${window.location.pathname}${window.location.search}`
              if (window?.sessionStorage) {
                window.sessionStorage.setItem('previousLocation', previousLocation)
              }

              return Observable.combineLatest([
                Observable.of(setLocalSettingAction('previousLocation', previousLocation)),
                Observable.of(replace(`/login`)),
              ])
            }

            throw error
          })
      )
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) =>
        kind === 'deed' && e.response?.status === 401
          ? Observable.of(replace(`/deeds/${id}/authenticate`))
          : Observable.of(initFailedAction(e))
      )
  })

const refetchDeed = (
  action$: ActionsObservable<{ donation: Donation; type: typeof DONATE_SUCCESS }>,
  store: Store<State>
) =>
  action$.ofType(DONATE_SUCCESS).mergeMap(({ donation }) => {
    const state = store.getState()

    if (donation?.deed) {
      const deed = selectDeedById(state, donation.deed.id || donation.deed)
      const existingDonation = deed.get('donations')?.find((d) => d.id && d.id === donation.id)
      if (existingDonation) {
        const error = new Error(
          `Tried to register duplicate donation with id ${existingDonation.id}, that shouldn't be happening!`
        )
        console.warn(error) // eslint-disable-line no-console
        Sentry.captureException(error)
        return []
      } else {
        return DeedsApi.fetch(deed.id)
      }
    } else {
      return []
    }
  })

const redirectAfterDonating = (
  action$: ActionsObservable<{ donation: Donation & { donationScheduleId?: string }; type: typeof DONATE_SUCCESS }>,
  store: Store<State>
) =>
  action$.ofType(DONATE_SUCCESS).mergeMap(({ donation }) => {
    const state = store.getState()
    const location = state.get('router').get('location').get('pathname')
    const lite = location.includes('/lite/')

    // If the donation is a Pledge, we only return the `donationScheduleId`
    if (donation?.donationScheduleId) {
      return [
        replace(
          `${lite ? '/lite' : ''}/donation-schedule-complete/${donation.donationScheduleId}${
            donation.campaign ? `?campaign=${donation.campaign.id}` : ''
          }`
        ),
      ]
    }
    if (!donation?.id) {
      // Fallback to donation id in case details are missing
      const error = new Error(`Donation not returned in successful donation response: ${JSON.stringify(donation)}`)
      console.warn(error) // eslint-disable-line no-console
      Sentry.captureException(error)
      return [
        showMessageAction('Success! Your donation was processed.'),
        donationsForUserLoadedAction('me', false),
        replace('/profile/donations'),
      ]
    }
    return [
      replace(
        `${lite ? '/lite' : ''}/donation-complete/${donation.id}${
          donation.campaign ? `?campaign=${donation.campaign.id}` : ''
        }`
      ),
    ]
  })

const refetchUser = (
  action$: ActionsObservable<{ donation: Donation; type: typeof DONATE_SUCCESS }>,
  store: Store<State>
) =>
  action$.ofType(DONATE_SUCCESS).exhaustMap(() => {
    const state = store.getState()
    const isAuthenticated = selectIsAuthenticated(state)
    if (!isAuthenticated) {
      return []
    }
    return UserApi.fetch('me').mergeMap((action) => [action])
  })

const donationErrorHandler = (e) => {
  if (e.responseJson) {
    return [
      donateFailedAction(e.responseJson.message || e),
      showErrorAction(i18n.t('donateScreen:donationFailed'), renderErrorMessage(e.responseJson.errors)),
    ]
  }
  return Observable.of(donateFailedAction(e), showErrorAction(i18n.t('donateScreen:donationFailed')))
}

const donate = (action$: ActionsObservable<{ data: DonatePayload; type: typeof DONATE }>) =>
  action$.ofType(DONATE).exhaustMap(({ data }) =>
    DonationApi.donate(data)
      .mergeMap((action) => [action, donateSuccessAction(action.donation)])
      .catch(donationErrorHandler)
  )

const betterplaceDonation = (
  action$: ActionsObservable<{ data: { donation: any }; type: typeof REGISTER_BETTERPLACE_DONATION }>
) =>
  action$
    .ofType(REGISTER_BETTERPLACE_DONATION)
    .exhaustMap(({ data }) => {
      const donation = new Donation(data)
      return [addAction(donation), donateSuccessAction(donation)]
    })
    .catch((e) => Observable.of(donateFailedAction(e), showErrorAction(i18n.t('donateScreen:donationFailed'))))

const giveIndiaDonation = (
  action$: ActionsObservable<{ data: { donation: any }; type: typeof REGISTER_GIVEINDIA_DONATION }>
) =>
  action$
    .ofType(REGISTER_GIVEINDIA_DONATION)
    .exhaustMap(({ data }) => {
      const donation = new Donation(data)
      return [addAction(donation), donateSuccessAction(donation)]
    })
    .catch((e) => Observable.of(donateFailedAction(e), showErrorAction(i18n.t('donateScreen:donationFailed'))))

const stripeDonation = (action$: ActionsObservable<{ intentId: string; type: typeof REGISTER_STRIPE_DONATION }>) =>
  action$.ofType(REGISTER_STRIPE_DONATION).exhaustMap(({ intentId }) =>
    DonationApi.registerStripePayment(intentId)
      .mergeMap((action) => [action, donateSuccessAction(action.donation)])
      .catch(donationErrorHandler)
  )

const paypalDonation = (action$: ActionsObservable<{ orderId: string; type: typeof REGISTER_PAYPAL_DONATION }>) =>
  action$.ofType(REGISTER_PAYPAL_DONATION).exhaustMap(({ orderId }) =>
    DonationApi.capturePaypalOrder(orderId)
      .mergeMap((action) => [action, donateSuccessAction(action.donation)])
      .catch(donationErrorHandler)
  )

const paypalRecurringDonation = (
  action$: ActionsObservable<{ orderId: string; type: typeof REGISTER_RECURRING_PAYPAL_DONATION }>
) =>
  action$.ofType(REGISTER_RECURRING_PAYPAL_DONATION).exhaustMap(({ data }) =>
    DonationApi.captureRecurringPaypalOrder(data)
      .mergeMap((action) => [action, donateSuccessAction(action.donation)])
      .catch(donationErrorHandler)
  )

const paypalResumeRecurringDonation = (
  action$: ActionsObservable<{ orderId: string; type: typeof REGISTER_RESUME_RECURRING_PAYPAL_DONATION }>
) =>
  action$.ofType(REGISTER_RESUME_RECURRING_PAYPAL_DONATION).exhaustMap(({ data }) =>
    DonationApi.resumeCaptureRecurringPaypalOrder(data)
      .mergeMap((action) => [action, donateSuccessAction(action.donation)])
      .catch(donationErrorHandler)
  )

const fetchProviderData = (
  action$: ActionsObservable<{
    deedId: string
    nonprofitId: string
    type: typeof FETCH_PROVIDER_DATA
    campaignId?: string
  }>
) =>
  action$.ofType(FETCH_PROVIDER_DATA).exhaustMap(({ deedId, nonprofitId, campaignId }) =>
    DonationApi.fetchProviderData(deedId, nonprofitId)
      .mergeMap((providerData: FetchedProviderData) => [
        fetchProviderDataSuccessAction(`${deedId ? 'deed' : 'organization'}_${deedId || nonprofitId}`, providerData),
      ])
      .catch((e) => {
        // Fallback to donation id in case details are missing
        const errorMsg = String(e.responseJson?.errors[0]?.message ?? i18n.t('donateScreen:loadingFailed'))
        const error = new Error(`FetchProviderData Error: ${errorMsg}. Error: ${e}`)
        console.warn(error) // eslint-disable-line no-console
        Sentry.captureException(error)
        const destination =
          (deedId && `/deeds/${deedId}${campaignId ? `?campaign=${campaignId}` : ''}`) ||
          (campaignId && `/campaign/${campaignId}`) ||
          `/organization/${nonprofitId}`
        return [fetchProviderDataFailedAction(e), showErrorAction(errorMsg), replace(destination)]
      })
  )

const createCustomerId = (action$: any) =>
  action$.ofType(CREATE_CUSTOMER_ID).exhaustMap(() =>
    DonationApi.createCustomerId()
      .mergeMap((data: any) => [createCustomerIdSuccessAction(data.client_token, data.paymentToken)])
      .catch(() =>
        Observable.of(
          createCustomerIdFailedAction(),
          showErrorAction(i18n.t('donateScreen:anErrorOccurredWhileLoadingDonationForm'))
        )
      )
  )

export default [
  init,
  donate,
  redirectAfterDonating,
  refetchDeed,
  refetchUser,
  betterplaceDonation,
  giveIndiaDonation,
  stripeDonation,
  paypalDonation,
  fetchProviderData,
  createCustomerId,
  paypalRecurringDonation,
  paypalResumeRecurringDonation,
]
