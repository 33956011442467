import { createSelector } from 'reselect'

import Relation from './model'
import { RelationList } from './reducer'

export const selectRelations = (state: any): RelationList => state.get('entities').relation

export const selectPendingRelations = createSelector(selectRelations, (relations) =>
  relations.filter((relation: Relation) => relation.state === 'pending')
)
