import { Action } from 'redux'

import { PERSIST_LANGUAGE } from './constants'

export interface PersistLanguageAction extends Action {
  locale: string
}

export const persistLanguageAction = (locale: string): PersistLanguageAction => ({
  type: PERSIST_LANGUAGE,
  locale,
})
