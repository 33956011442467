import React, { useState } from 'react'
import { View } from 'react-primitives'
import S3Upload from 'react-s3-uploader/s3upload'
import { useTranslation } from 'react-i18next'
import uniqid from 'uniqid'

import * as Sentry from 'src/utils/Sentry'
import Api from 'src/entities/api'
import { Text } from 'src/retired/shared/Typography'
import { Loading, Spacing, ExternalLink } from 'src/retired/elements'
import { ErrorText } from 'src/components/ErrorText'

import FileSelector from './FileSelector'

const fileExtension = (type = '') => {
  const [, ext = ''] = type.split('/')
  return ['png', 'jpg', 'jpeg', 'pdf'].includes(ext) ? `.${ext}` : ''
}

const updateName = (name = '', type = ''): string =>
  `${uniqid()}${name.split('.')[0]}${fileExtension(type)}`.replace(/[^\w\d_\-.]+/gi, '')

interface StringMap {
  [key: string]: string
}

const FileUploader = ({
  returnFileUrl,
  objectPrefix,
}: {
  returnFileUrl: (url: string) => void
  objectPrefix?: string
}): JSX.Element => {
  const { t } = useTranslation('fileUploadComponent')
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const [fileUrl, setFileUrl] = useState('')
  const [fileName, setFileName] = useState('')

  const getSignedUrl = (file: File, onUploaded: (p: { signedUrl: string; publicUrl: string }) => void): void => {
    const data: StringMap = {
      // eslint-disable-next-line camelcase
      file_name: file.name,
      // eslint-disable-next-line camelcase
      file_type: file.type,
      acl: 'private',
      bucket: 'secure',
    }
    if (objectPrefix) {
      data.objectPrefix = objectPrefix
    }

    Api.post<{
      signedRequest: string
      url: string
    }>('api/files/credentials', data).subscribe(
      (response) => {
        if (response) {
          onUploaded({ signedUrl: response.signedRequest, publicUrl: response.url })
        } else {
          Sentry.captureException(new Error('Received empty response from api/files/credentials'), {
            fingerprint: ['Empty response from api/files/credentials'],
          })
        }
      },
      (e) => console.error(e) // eslint-disable-line no-console
    )
  }

  const uploadBlob = (file: File): void => {
    const processedFile = new File([file], updateName(file.name, file.type), {
      type: file.type,
      lastModified: file.lastModified,
    })
    const options = {
      files: [processedFile],
      getSignedUrl,
      contentDisposition: 'auto',
      uploadRequestHeaders: { 'x-amz-acl': 'private' },
      onFinishS3Put: (info: { publicUrl: string }, uploadedFile: File) => {
        setUploading(false)
        setError(false)
        setFileUrl(info.publicUrl)
        returnFileUrl(info.publicUrl)
        setFileName(uploadedFile.name)
      },
      onProgress: () => setUploading(true),
      onError: () => setError(true),
    }
    new S3Upload(options) // eslint-disable-line no-new
  }

  return (
    <>
      {!!fileUrl && (
        <View style={{ flexDirection: 'row', marginTop: 20 }}>
          <Text
            fontSize={12}
            style={{
              whiteSpace: 'pre',
            }}
          >
            {t`uploadedFile`}{' '}
          </Text>
          <ExternalLink href={`${fileUrl}?download=false`}>
            <Text fontSize={12} underline style={{ wordBreak: 'break-all' }}>
              {fileName}
            </Text>
          </ExternalLink>
        </View>
      )}
      <View>
        {uploading ? (
          <Spacing marginBottom={30} marginTop={30}>
            <Loading fill={false} />
          </Spacing>
        ) : (
          <FileSelector returnFile={uploadBlob} />
        )}
      </View>
      {error && <ErrorText text={t`thereWasAnErrorDuringUploading`} />}
    </>
  )
}

export default FileUploader
