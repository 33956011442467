import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  TOGGLE_SELECTED_SKILL,
} from './constants'

export const initAction = () => ({
  type: INIT,
})

export const initSuccessAction = (user) => ({
  type: INIT_SUCCESS,
  user,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const submitAction = (skills, path) => ({
  type: SUBMIT,
  skills,
  path,
})

export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})

export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})

export const toggleSelectedSkillAction = (skill) => ({
  type: TOGGLE_SELECTED_SKILL,
  skill,
})
