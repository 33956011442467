import React from 'react'
import { View } from 'react-primitives'

import { resize } from 'src/utils/resize'
import ImageCard from 'src/retired/shared/ImageCard'
import Image from 'src/retired/elements/Image'
import { CommunitiesQuery } from 'src/generated/graphql'

type Community = CommunitiesQuery['communities'][number]

interface CommunityCardProps {
  community: Community
  spacing?: boolean
  forceLarge?: boolean
  small?: boolean
  onPress?: () => void
  isResizable?: boolean
  index: number
  fullWidthText?: boolean
}
export type CommunityCardElement = React.ReactElement<CommunityCardProps>

const CommunityCard: React.FC<CommunityCardProps> = ({
  community,
  spacing = true,
  forceLarge = false,
  small = false,
  onPress,
  isResizable,
  index,
  fullWidthText,
}): CommunityCardElement => {
  const image = community.tileImage || community.headerImage || undefined

  return (
    <View>
      <ImageCard
        text={community.title}
        image={(!small && image && resize(image, '960x540')) || undefined}
        link={`/community/${community.id}`}
        spacing={spacing}
        forceLarge={forceLarge}
        onPress={onPress}
        small={small}
        isResizableImage={isResizable}
        isContained={false}
        fullWidthText={fullWidthText}
      >
        {small && (
          <View style={{ height: index === 12 || index === 16 ? 69 : 58 }}>
            <Image
              source={{ uri: image }}
              style={{
                position: 'absolute',
                width: 150,
                height: 150,
                top: 3,
              }}
              resizeMode="cover"
            />
          </View>
        )}
      </ImageCard>
    </View>
  )
}

export default CommunityCard
