import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Text } from 'src/retired/elements'
import { Link, useLocation, useHistory } from 'src/navigation'
import { setLocalSettingAction } from 'src/localSettings/actions'

const SignInLink = () => {
  const { t } = useTranslation('signInLinkBlock')
  const dispatch = useDispatch()
  const { colors } = useDeedTheme()
  const history = useHistory()
  const { pathname, search } = useLocation()

  return (
    <Row style={{ alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
      <Text>{t`alreadyHaveAnAccount`}</Text>
      <Link
        to="/login"
        style={{ textDecoration: 'underline', color: colors.gray05, marginLeft: 10 }}
        onClick={() => {
          dispatch(setLocalSettingAction('previousLocation', `${pathname}${search}`))
          if (window?.sessionStorage) {
            window.sessionStorage.setItem('previousLocation', `${pathname}${search}`)
          }
          history.push('/login')
        }}
      >
        {t`signIn`}
      </Link>
    </Row>
  )
}

export default SignInLink
