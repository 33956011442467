import { formatInTimeZone } from 'date-fns-tz'
import { format as dateFnsFormat } from 'date-fns'
import en from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import pt from 'date-fns/locale/pt'
import it from 'date-fns/locale/it'
import de from 'date-fns/locale/de'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import zhCN from 'date-fns/locale/zh-CN'
import zhTW from 'date-fns/locale/zh-TW'
import nl from 'date-fns/locale/nl'
import da from 'date-fns/locale/da'
import id from 'date-fns/locale/id'

type LocalesMap = Record<string, Record<string, unknown>>
export const locales: LocalesMap = {
  en,
  ru,
  es,
  fr,
  pt,
  it,
  de,
  ko,
  ja,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
  nl,
  da,
  id,
}

interface DateWithTimezone {
  value: Date
  timeZone: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDateWithTimeZone(arg: any): arg is DateWithTimezone {
  return typeof arg?.timeZone === 'string' && arg?.value instanceof Date
}

export const interpolationFormat = (value: Date | string, format: string, fullLocale: string): string => {
  // Short-circuit for better performance
  if (typeof value === 'string') {
    return value
  }

  // Find date-fns' locale that matches current locale (current locale should begin with date-fns' locale)
  const localeKey = Object.keys(locales).find((l) => fullLocale.startsWith(l)) as string
  const locale = (localeKey && locales[localeKey]) || en

  if (value instanceof Date) {
    return dateFnsFormat(value, format, locale ? { locale } : undefined)
  }

  if (isDateWithTimeZone(value)) {
    return formatInTimeZone(value.value, value.timeZone, format, {
      locale,
    })
  }

  return value
}
