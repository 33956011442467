import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import Button from 'src/retired/elements/Button'
import { useDeedTheme } from 'src/theme/ThemeProvider'

enum ThresholdHeight {
  'small' = 150,
  'large' = 300,
} // these numbers can be adjusted to fit our designs

const Expandable = ({
  children,
  threshold = ThresholdHeight.large,
}: {
  children: string | JSX.Element
  threshold?: ThresholdHeight
}): JSX.Element => {
  const { t } = useTranslation('common')
  const { metrics } = useDeedTheme()

  const contentRef = useRef<View | null>(null)
  const [readMore, setReadMore] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)

  const isLongText = contentHeight - threshold > 40 // if the difference between contentHeight and threshold is less than 40px it is too short to hide.
  const smallScreen = metrics.isSmall
  const thresholdByScreenSize = smallScreen ? ThresholdHeight.small : threshold

  useEffect(() => {
    if (contentRef.current) {
      // In React Native, View doesn't have an offsetHeight property like standard HTML elements.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setContentHeight(contentRef.current.offsetHeight)
    }
  }, [])

  return (
    <>
      <View
        style={{
          height: readMore || !isLongText ? 'auto' : thresholdByScreenSize,
          overflow: readMore ? 'visible' : 'hidden',
        }}
      >
        <View ref={contentRef}>{children}</View>
      </View>
      {isLongText && (
        <Button
          size="extraSmall"
          fluid={smallScreen}
          style={{ margin: 'auto', marginTop: 30 }}
          onPress={() => setReadMore(!readMore)}
        >
          {!readMore ? t`readMore` : t`readLess`}
        </Button>
      )}
    </>
  )
}

export default Expandable
