import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import type DonationSchedule from './model'

export const selectDonationScheduleState = (state: State) => state.get('entities').donationSchedule

export const selectDonationSchedules = createSelector(selectDonationScheduleState, (donationScheduleState) =>
  donationScheduleState.get('donationSchedules')
)

export const selectDonationScheduleById = createSelector(
  selectDonationSchedules,
  (_state: State, id: string): string => id,
  (donationSchedule, id) => donationSchedule.get(id)
)

export const selectDonationSchedulesListLoaded = createSelector(selectDonationScheduleState, (donationScheduleState) =>
  donationScheduleState.get('donationSchedulesListLoaded')
)

export const selectActiveDonationSchedules = createSelector(selectDonationSchedules, (donationSchedules) =>
  donationSchedules
    .filter((donationSchedule: DonationSchedule) => donationSchedule.status === 'Active')
    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
)
