import React from 'react'
import PropTypes from 'prop-types'
import { Image as RPImage, View } from 'react-primitives'

import { ImageProps } from './types'

const Image: React.FC<ImageProps> = ({
  backgroundPosition,
  backgroundSize = 'cover',
  backgroundRepeat = 'repeat',
  source,
  style,
  ...props
}) => {
  if (backgroundPosition) {
    return (
      <View
        style={[
          {
            backgroundImage: `url('${typeof source === 'object' && 'uri' in source ? source.uri : source}')`,
            backgroundRepeat,
            backgroundSize,
            backgroundPosition,
          },
          style,
        ]}
        {...props}
      />
    )
  }
  return <RPImage source={source} style={style} {...props} />
}
Image.propTypes = {
  backgroundPosition: PropTypes.string,
  backgroundSize: PropTypes.oneOf(['cover', 'contain']),
  backgroundRepeat: PropTypes.oneOf(['repeat', 'repeat-x', 'repeat-y', 'no-repeat']),
  source: PropTypes.any,
  style: PropTypes.any,
}
export default Image
