import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { css, styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import usePrevious from 'src/utils/usePrevious'
import { Loading, TextField, Touchable, ScrollView, Spacing, Row } from 'src/retired/elements'
import { Body2, Label } from 'src/retired/shared/Typography'
import SelectBox from 'src/retired/shared/SelectBox'
import Icon from 'src/retired/shared/Icon'
import Organization from 'src/entities/organization/model'
import { selectNonprofitsSearchResults } from 'src/entities/organization/selectors'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { useCountries } from 'src/entities/location/api'

import { selectSearchLoading, selectSearchError, selectSearchSuccess } from './selectors'
import reducer from './reducer'
import epics from './epics'
import { searchNonprofitsAction, searchSuccessAction } from './actions'

const NonprofitsSearchBlock = styled.View<object, EmotionTheme>`
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.gray03};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
  border: 1px solid ${({ theme }) => theme.colors.gray01};
`

const BottomLineRounded = styled.View<object, EmotionTheme>`
  padding: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray03};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray01};
  border-left: 1px solid ${({ theme }) => theme.colors.gray01};
  border-right: 1px solid ${({ theme }) => theme.colors.gray01};
`

const NonprofitsNoResults = styled.View`
  padding: 45px 25px;
  display: flex;
  justify-content: center;
`

const BottomLink = styled.View<object, EmotionTheme>`
  background-color: ${({ theme }) => theme.colors.brandColor};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 15px;
`

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  min-height: 40px;
  height: auto;
`
const NonprofitsResults = styled.View<object, EmotionTheme>(({ theme }: { theme: EmotionTheme }) => ({
  borderWidth: 1,
  borderColor: theme.colors.gray01,
  maxHeight: 150,
  justifyContent: 'center',
}))

const NonprofitItem = styled.View<{ last: boolean; selected: boolean }, EmotionTheme>(
  ({ last, selected, theme }: { last: boolean; selected: boolean; theme: EmotionTheme }) => ({
    padding: 15,
    backgroundColor: theme.colors.white,
    ...(selected && {
      backgroundColor: theme.colors.grayMild,
      borderLeftWidth: 2,
      borderLeftColor: theme.colors.black,
    }),
    ...(!last && { borderBottomWidth: 1, borderBottomColor: theme.colors.gray01 }),
  })
)

type NonprofitsSearchProps = {
  bottomAction: React.ReactNode
  emptyAction: React.ReactNode
  onSetSearchTerm?: (searchTerm: string) => void
  excludeDonationProviders?: string[]
  populateMatchingResults?: boolean
  acceptsDonations?: boolean
  selectOrganizationLabel?: string
} & (
  | { allowMultiple?: false; onSetSelectedNonprofit: (nonprofitId: string, nonprofit?: Organization) => void }
  | { allowMultiple: true; onSetSelectedNonprofit: (nonprofitIds: string[]) => void }
)

const NonprofitsSearch = ({
  bottomAction = null,
  emptyAction,
  onSetSearchTerm,
  acceptsDonations,
  excludeDonationProviders = [],
  populateMatchingResults = false,
  allowMultiple,
  selectOrganizationLabel,
  onSetSelectedNonprofit,
}: NonprofitsSearchProps): JSX.Element => {
  const { t } = useTranslation('nonprofitsSearch')
  useInjectReducer({ key: 'nonprofitsSearch', reducer })
  useInjectEpics({ key: 'nonprofitsSearch', epics })
  const dispatch = useDispatch()

  const [country, setCountry] = useState<undefined | string>(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedNonprofits, setSelectedNonprofits] = useState<string[]>([])

  const user = useSelector(selectCurrentUser)
  const nonprofits = useSelector((state) =>
    selectNonprofitsSearchResults(
      state,
      user,
      searchTerm,
      country,
      acceptsDonations,
      undefined,
      excludeDonationProviders
    )
  )
  const searching = useSelector(selectSearchLoading)
  const searchError = useSelector(selectSearchError)
  const searchSuccess = useSelector(selectSearchSuccess)
  const locations = useSelector(selectUserLocations)
  const { colors } = useDeedTheme()
  const countries = useCountries()

  const prevCountryValue = usePrevious({ country })

  useEffect(() => {
    if (onSetSearchTerm) {
      onSetSearchTerm(searchTerm)
    }

    if (country && searchTerm.length > 2) {
      setSelectedNonprofits([])
      if (allowMultiple) {
        onSetSelectedNonprofit([])
      } else {
        onSetSelectedNonprofit('')
      }
      dispatch(
        searchNonprofitsAction(country, searchTerm, acceptsDonations, excludeDonationProviders, populateMatchingResults)
      )
    }
    if (prevCountryValue?.country !== country && searchTerm.length < 3) {
      dispatch(searchSuccessAction(false))
    }
    if (!country && user) {
      const countryCode = locations?.get(user.location)?.countryCode
      if (countryCode) {
        setCountry(countryCode)
      }
    }
  }, [country, user, searchTerm])

  return (
    <>
      <NonprofitsSearchBlock>
        <Label>{t`selectOrganizationCountry`}</Label>
        <SelectBox
          placeholder={t`selectCountry`}
          value={country}
          onSelect={(value) => setCountry(value)}
          options={countries.map((_country) => ({ value: _country.countryCode, title: _country.country }))}
          style={{ marginBottom: 15, marginTop: 8, marginLeft: 0, width: '100%' }}
          showSearch
        />
        <Label>{selectOrganizationLabel || t`selectAnOrganization`}</Label>
        <View
          style={css`
            // eslint-disable-next-line @typescript-eslint/no-explicit-any @typescript-eslint/no-explicit-any
            ${roundedInputStyle as any}
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <Icon icon="searchNew" width={16} />
          <TextField
            placeholder={t`searchByOrganizationName`}
            value={searchTerm}
            onChangeText={(n: string, value: string) => setSearchTerm(value)}
            returnKeyType="done"
            style={{ paddingLeft: 10 }}
          />
        </View>
        <Label colour={colors.grayMediumDark}>{t`typeAtLeast`}</Label>
      </NonprofitsSearchBlock>

      {searching && (
        <NonprofitsResults>
          <Spacing marginBottom={60} marginTop={60}>
            <Loading fill={false} />
          </Spacing>
        </NonprofitsResults>
      )}

      {searchSuccess && !!nonprofits.size && (
        <NonprofitsResults>
          <ScrollView>
            {nonprofits.toArray().map((nonprofit: Organization, index: number) => {
              const selected = selectedNonprofits.includes(nonprofit.id)
              return (
                <Touchable
                  key={nonprofit.id}
                  onPress={() => {
                    const newValue = allowMultiple
                      ? selectedNonprofits.includes(nonprofit.id)
                        ? selectedNonprofits.filter((n) => n !== nonprofit.id)
                        : [...selectedNonprofits, nonprofit.id]
                      : [nonprofit.id]

                    setSelectedNonprofits(newValue)
                    if (allowMultiple) {
                      onSetSelectedNonprofit(newValue)
                    } else {
                      onSetSelectedNonprofit(newValue[0], nonprofit)
                    }
                  }}
                >
                  <NonprofitItem last={index === nonprofits.size - 1} selected={selected}>
                    <Label weight={selected ? '500' : 'normal'}>{nonprofit.name}</Label>
                    <Label weight={selected ? '500' : 'normal'} colour={colors.grayText}>
                      {nonprofit.locationObject.toString()}
                    </Label>
                  </NonprofitItem>
                </Touchable>
              )
            })}
          </ScrollView>
        </NonprofitsResults>
      )}
      {searchSuccess && !nonprofits.size && (
        <NonprofitsResults>
          <NonprofitsNoResults>{emptyAction}</NonprofitsNoResults>
        </NonprofitsResults>
      )}
      {searchError && (
        <NonprofitsResults>
          <NonprofitsNoResults>
            <Body2 center>{t`thereWasAnError`}</Body2>
          </NonprofitsNoResults>
        </NonprofitsResults>
      )}
      {bottomAction ? (
        <BottomLink>
          <Row style={{ justifyContent: 'center' }}>{bottomAction}</Row>
        </BottomLink>
      ) : (
        <BottomLineRounded />
      )}
    </>
  )
}

NonprofitsSearch.displayName = 'NonprofitsSearch'
export default NonprofitsSearch
