import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { key } from './types'
import { DonationCompleteState, initialState } from './reducer'

const selectState = (state: State): DonationCompleteState => state.get(key) || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
