import React from 'react'
import { View } from 'react-primitives'

import { Chip } from 'src/components/Chip/Chip'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const ChipWithIconAndText = ({
  iconName,
  label,
  style,
  hexColor,
  small,
}: {
  iconName: string
  label: string
  style?: React.CSSProperties
  hexColor?: string
  small?: boolean
}) => {
  const { metrics, colors } = useDeedTheme()
  return (
    <Chip
      icon={
        <View style={{ marginRight: -4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Icon icon={iconName} width={17} hexColor={hexColor || colors.black} />
        </View>
      }
      label={label}
      style={{
        borderRadius: small ? 19 : 23,
        fontWeight: small ? 400 : 600,
        height: 'auto',
        width: metrics.isSmall ? 'auto' : 'fit-content',
        paddingLeft: small ? 10 : 18,
        paddingRight: small ? 6 : 18,
        paddingTop: small ? 3 : 8,
        paddingBottom: small ? 3 : 8,
        fontSize: 14,
        borderColor: hexColor || colors.black,
        color: hexColor || colors.black,
        borderWidth: 0,
        ...style,
      }}
      textColor={hexColor || colors.black}
    />
  )
}
