import { ADD_MULTIPLE, RESET, CAUSES_LOADED } from './constants'
import { CauseMap } from './reducer'

export interface AddMultipleAction {
  type: typeof ADD_MULTIPLE
  causes: CauseMap
}

export const addMultipleAction = (causes: CauseMap) => ({
  type: ADD_MULTIPLE,
  causes,
})

export interface ResetAction {
  type: typeof RESET
}

export const resetAction = () => ({
  type: RESET,
})

export interface CausesLoadedAction {
  type: typeof CAUSES_LOADED
}

export const causesLoadedAction = () => ({
  type: CAUSES_LOADED,
})

export type CauseActions = AddMultipleAction | ResetAction | CausesLoadedAction
