export const key = 'donationComplete'

export const INIT = 'containers/DonateComplete/INIT'
export const INIT_SUCCESS = 'containers/DonateComplete/INIT_SUCCESS'
export const INIT_FAILED = 'containers/DonateComplete/INIT_FAILED'

export interface InitAction {
  type: typeof INIT
  id: string
}
export const initAction = (id: string): InitAction => ({
  type: INIT,
  id,
})

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS
}
export const initSuccessAction = (): InitSuccessAction => ({
  type: INIT_SUCCESS,
})

export interface InitFailedAction {
  type: typeof INIT_FAILED
  error: Error
  errorCode?: number
}
export const initFailedAction = (error: Error, errorCode?: number): InitFailedAction => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export type DonationCompleteActions = InitAction | InitSuccessAction | InitFailedAction
