import React, { FC } from 'react'
import { View } from 'react-primitives'

import DeedCardList from 'src/retired/shared/DeedCardList'
import { DeedMap } from 'src/entities/deed/reducer'
import List from 'src/retired/blocks/List'
import DeedCard from 'src/retired/shared/DeedCard'
import ScrollContainer from 'src/retired/shared/ScrollContainer'
import { useDeedTheme } from 'src/theme/ThemeProvider'

type callback = () => void

interface DeedsBlockProps {
  deeds: DeedMap
  title?: string
  link?: string | callback
  subTitle?: string | number
  linkColour?: string
  titleStyle?: object
  expandable?: boolean
  hidePartnerLogo?: boolean
  hideLocation?: boolean
  noMargin?: boolean
  noPadding?: boolean
}
const DeedsBlock: FC<DeedsBlockProps> = ({
  deeds,
  title,
  subTitle,
  link,
  linkColour,
  titleStyle,
  expandable = false,
  hidePartnerLogo = false,
  hideLocation,
  noMargin,
  noPadding,
}) => {
  const { metrics } = useDeedTheme()

  return deeds.size === 0 ? (
    <View />
  ) : (
    <ScrollContainer
      title={title}
      titleStyle={titleStyle}
      subtitle={subTitle === '' ? undefined : subTitle || deeds.size}
      cardType="deed"
      dimension={deeds.size}
      link={link}
      expandable={expandable}
      linkColour={linkColour}
      customScrollViewOffset={360}
      render={(scrollViewOffset) => (
        <DeedCardList
          deeds={deeds}
          scrollViewOffset={scrollViewOffset}
          hidePartnerLogo={hidePartnerLogo}
          hideLocation={hideLocation}
          noMargin={noMargin}
          noPadding={noPadding}
        />
      )}
      renderList={() => (
        <List
          data={deeds.toArray()}
          keyExtractor={(item) => item.get('id')}
          numColumns={2}
          style={{
            marginLeft: metrics.isLarge ? -20 : metrics.isMedium ? -8 : 0,
            marginRight: metrics.isLarge ? -20 : metrics.isMedium ? -8 : 0,
          }}
          itemProps={{ style: { width: metrics.isSmall ? '100%' : undefined } }}
          renderItem={({ item }) => (
            <DeedCard
              deed={item}
              size={metrics.isLarge ? 'standard' : metrics.isMedium ? 'small' : 'variable'}
              hidePartnerLogo={hidePartnerLogo}
              hideLocation={hideLocation}
            />
          )}
        />
      )}
    />
  )
}

export default DeedsBlock
