import { useEffect, useState } from 'react'
import { Map } from 'immutable'

import * as Sentry from 'src/utils/Sentry'

import Api from '../api'

import Cause from './model'
import { addMultipleAction, causesLoadedAction } from './actions'

export interface Category extends Cause {
  supportedByPayPal: boolean
}

export default class CauseApi {
  static fetchAll() {
    return Api.get('api/causes').map((causes) => [
      addMultipleAction(Map(causes.map((cause: any) => [cause.id, new Cause(cause)]))),
      causesLoadedAction(),
    ])
  }

  static fetchCategories() {
    return Api.get('api/causes/categories')
  }
}

let categoriesCache: Promise<Category[]> | null = null
const fetchCategories = async () => {
  if (categoriesCache) {
    return categoriesCache
  }
  categoriesCache = CauseApi.fetchCategories().toPromise()
  return categoriesCache
}

export const useCategories = (): Category[] => {
  const [causes, setCategories] = useState<Category[]>([])
  useEffect(() => {
    let isCancelled = false
    fetchCategories()
      .then((data) => {
        if (!isCancelled) {
          setCategories(data.map((cause: any) => new Cause(cause)) as Category[])
        }
      })
      .catch((e: any) => Sentry.captureException(e))
    return () => {
      isCancelled = true
    }
  }, [])
  return causes
}
