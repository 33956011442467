import React from 'react'
import { Avatar, IconButton, Paper } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'

import { colors } from 'src/theme'
import { UserFragment } from 'src/generated/graphql'

/**
 * @NOTE-DP: this component is migrated from organizer and therefore not yet implemented in Native
 * Use in web-only places or implement the same interface using Native components
 */

interface Props {
  user: UserFragment
  removeMember: (userId: string) => void
}

export const UserSelected = ({ user, removeMember }: Props) => {
  const { t } = useTranslation('userSelected')
  const name = `${user.fullName?.first || ''} ${user.fullName?.last || ''}`
  return (
    <Paper
      elevation={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        background: colors.gray03,
        padding: '6px 10px',
      }}
    >
      {!!user.mainPicture && (
        <Avatar
          alt={name}
          src={user.mainPicture}
          style={{
            width: 36,
            height: 36,
            marginRight: 5,
            marginLeft: 5,
          }}
        />
      )}
      <span>
        {name} <span style={{ color: '#676767' }}>{user.organizationDepartment}</span>
      </span>

      <IconButton
        style={{ marginLeft: 'auto' }}
        aria-label={t('remove', { name })}
        onClick={(): void => removeMember(user.id)}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  )
}
