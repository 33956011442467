import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'
import _ from 'lodash'

import { selectCurrentUser } from 'src/entities/user/selectors'
import UserApi from 'src/entities/user/api'
import CausesApi from 'src/entities/cause/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCauses } from 'src/entities/cause/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { renderErrorMessage } from 'src/utils/errorMessages'
import truthy from 'src/utils/truthy'

import { submitSuccessAction, submitFailedAction, initSuccessAction, initFailedAction } from './actions'
import { SUBMIT, INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    const causes = selectCauses(state)
    const user = selectCurrentUser(state)

    if (causes.size > 0) {
      return Observable.of(initSuccessAction(user))
    }

    return CausesApi.fetchAll()
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction(user)])
      .catch((e) =>
        Observable.of(initFailedAction(e), showErrorAction(i18n.t('interestsRegister:failedLoadingInterests')))
      )
  })

const submit = (action$, store) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ interests, path }) =>
      UserApi.update({ interests })
        .mergeMap((action) => {
          const state = store.getState()
          const previousLocation = selectLocalSetting(state, 'previousLocation')
          const user = selectCurrentUser(state)
          const pending = user.status === 'pending' && user.organization
          return [
            action,
            replace(
              path === '/profile/interests'
                ? '/profile'
                : pending
                ? '/register/company/pending'
                : previousLocation || '/home'
            ),
            !pending && previousLocation && setLocalSettingAction('previousLocation', null),
            submitSuccessAction(),
          ].filter(truthy)
        })
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    )
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [init, submit]
