import { fromJS } from 'immutable'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  CONFIRM,
  CONFIRM_SUCCESS,
  CONFIRM_FAILED,
  VTO_REQUEST,
  VTO_REQUEST_SUCCESS,
  VTO_REQUEST_FAILED,
} from './constants'

export const initialState = fromJS({
  loading: false,
  submitting: false,
  submittingVto: false,
  error: null,
  errorVto: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null, errorVto: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case CONFIRM:
      return state.set('submitting', true)

    case CONFIRM_SUCCESS:
      return state.set('submitting', false)

    case CONFIRM_FAILED:
      return state.merge({ submitting: false, error: action.error })

    case VTO_REQUEST:
      return state.set({ submittingVto: true })

    case VTO_REQUEST_SUCCESS:
      return state.set('submittingVto', false)

    case VTO_REQUEST_FAILED:
      return state.merge({ submittingVto: false, errorVto: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
