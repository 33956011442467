import Api from 'src/entities/api'

import {
  fetchCampaignsByNonprofitFailedAction,
  CampaignsByNonprofitPayload,
  fetchCampaignsByNonprofitSuccessAction,
} from './actions'

export const LogOffPlatformDonationApi = {
  fetchAllByNonprofit: (nonprofitId: string) =>
    Api.get(`api/campaigns?nonprofitId=${nonprofitId}&limited=1`)
      .map((campaigns: CampaignsByNonprofitPayload[]) => fetchCampaignsByNonprofitSuccessAction(campaigns))
      .catch((error: Error) => [fetchCampaignsByNonprofitFailedAction(error)]),
}
