import { Record } from 'immutable'
import _ from 'lodash'
import i18n from 'i18next'

const properties = {
  id: '',
  code: '',
  type: '',
  name: '',
  banner: undefined,
  picture: undefined,
  color: undefined,
  gradient: true,
  number: 0,
  description: undefined,
  sorting: 0,
  supportedByPayPal: false,
  organization: undefined,
}

export const enum CauseTypes {
  Pillar = 'Pillar',
  CauseAreas = 'Cause Areas',
  ERG = 'ERG',
  Category = 'Category',
  SDG = 'SDG',
}

export default class Cause extends Record(properties, 'Cause') implements Cause {
  public readonly id!: string

  public readonly code!: string

  public readonly type!: CauseTypes

  public readonly _name!: string

  public readonly banner!: string

  public readonly picture!: string

  public readonly color!: string

  public readonly gradient!: boolean

  public readonly number!: number

  public readonly description!: string

  public readonly sorting!: number

  public readonly supportedByPayPal!: boolean

  public readonly organization?: string | undefined

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    const { __t: type } = values as { __t: CauseTypes }
    super({
      ...validValues,
      type,
      /* 
        @NOTE: B.I
        - we might have to update the logic below
        - code alone might not be enough to dictate the platform 'ownership' of a particular cause
        - this is why we can't use RTE for all causes
      */
      description: validValues.code ? i18n.t(`causes:${validValues.code}-description`) : validValues.description,
    })
    this._name = validValues.name
  }

  get name() {
    const translationKeyFromCode = `causes:${this.code.toLowerCase()}-name`
    if (i18n.exists(translationKeyFromCode)) {
      return i18n.t(translationKeyFromCode)
    }
    const translationKeyFromName = `causes:${this._name.toLowerCase()}-name`
    if (i18n.exists(translationKeyFromName)) {
      return i18n.t(translationKeyFromName)
    }

    return this._name
  }

  public label(): string {
    return this.type === 'SDG' ? `#${this.number} ${this.name}` : this.name
  }

  public fieldName(): string {
    switch (this.type) {
      case 'Pillar':
        return 'pillars'

      case 'ERG':
        return 'ERGs'

      case 'Category':
        return 'categories'

      case 'SDG':
        return 'SDGs'

      default:
        return ''
    }
  }
}
