import { useDispatch, useSelector } from 'react-redux'
import countryList from 'country-list'
import { DonationProvider, PaymentMethodType } from '@joindeed/calculate-fees'
import { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'

import { selectCurrentUser } from 'src/entities/user/selectors'
import Organization from 'src/entities/organization/model'
import Deed from 'src/entities/deed/model'
import config from 'src/config'

import { getNonprofitCountryCode } from '../nonprofits'
import { computeDonationProviderForNonprofit } from '../computeDonationProviderForNonprofit'
import * as actions from '../actions'
import type { FetchedProviderData } from '../actions'
import { getPayPalModel } from '../getPayPalModel'
import { PayPalCountryCode, PayPalModel } from '../constants'

type BaseProviderData<T extends DonationProvider | null> = {
  donationProvider: T
}

interface PaypalProviderSpecificData {
  countryCode: PayPalCountryCode
  country: string
  payPalModel: PayPalModel
  isEnrolled: boolean
}

interface StripeProviderSpecificData {
  stripePromise: ReturnType<typeof loadStripe> | null
}

type ProvidersData = {
  [T in DonationProvider]: T extends DonationProvider.PayPal
    ? BaseProviderData<T> & PaypalProviderSpecificData
    : T extends DonationProvider.Stripe
    ? BaseProviderData<T> & StripeProviderSpecificData
    : T extends DonationProvider.External
    ? BaseProviderData<T | null>
    : BaseProviderData<T>
}

export type AnyProviderData = ReturnType<typeof getProviderData>
export type ProviderData<T extends DonationProvider> = Extract<AnyProviderData, { donationProvider: T }>

const getPayPalCountry = (firstNonprofit: Organization) => {
  if (firstNonprofit?.externalId) {
    const matches: RegExpMatchArray | null = firstNonprofit?.externalId.match(/^ext-pp-([A-Z]{2})\d+$/)
    const theMatch = Array.isArray(matches) && matches[1]
    if (theMatch) {
      return {
        countryCode: theMatch as NonNullable<PayPalCountryCode>,
        country: countryList.getName(theMatch) || '',
      }
    }
  }

  return {
    countryCode: firstNonprofit?.locationObject?.countryCode as PayPalCountryCode,
    country: firstNonprofit?.locationObject?.country,
  }
}

const getPaypalProviderSpecificData = (nonprofit: Organization): PaypalProviderSpecificData => ({
  ...getPayPalCountry(nonprofit),
  payPalModel: getPayPalModel({ donationProvider: DonationProvider.PayPal, firstNonprofit: nonprofit }),
  isEnrolled: nonprofit?.paypalCharityType !== 'PPGF_UNENROLLED',
})

const getProviderData = ({
  nonprofits,
  donationProvider,
}: {
  nonprofits: Organization[]
  donationProvider: DonationProvider | null | undefined
}) => {
  // @NOTE-AC: This should probably be inside `if (donationProvider === DonationProvider.Stripe)`.
  //           But not calling `loadStripe` at every render could maybe change the behaviour(?)
  //           Keeping existing behaviour for now.
  const stripeAccount = nonprofits?.[0]?.stripe?.userId
  const stripePromise = stripeAccount ? loadStripe(config.stripePubKey, { stripeAccount }) : null

  const firstNonprofit = nonprofits[0]

  if (!donationProvider) {
    return { donationProvider: null }
  }

  const providersData: ProvidersData = {
    [DonationProvider.PayPal]: {
      donationProvider: DonationProvider.PayPal as const,
      ...getPaypalProviderSpecificData(firstNonprofit),
    },
    [DonationProvider.Stripe]: { donationProvider: DonationProvider.Stripe as const, stripePromise },
    [DonationProvider.NFG]: { donationProvider: DonationProvider.NFG as const },
    [DonationProvider.Betterplace]: { donationProvider: DonationProvider.Betterplace as const },
    [DonationProvider.GlobalGiving]: { donationProvider: DonationProvider.GlobalGiving as const },
    [DonationProvider.GiveIndia]: { donationProvider: DonationProvider.GiveIndia as const },
    [DonationProvider.CAF]: { donationProvider: DonationProvider.CAF as const },
    [DonationProvider.External]: { donationProvider: null },
  }

  return providersData[donationProvider]
}

type UsePaymentInfoProps = {
  deed?: Deed | null
  donationPaymentMethod: PaymentMethodType | undefined
  nonprofits: Organization[]
  fetchedProviderData?: FetchedProviderData
  selectedCampaignId?: string
}

export const useProviderData = ({
  nonprofits,
  donationPaymentMethod,
  deed,
  fetchedProviderData,
  selectedCampaignId,
}: UsePaymentInfoProps) => {
  const user = useSelector(selectCurrentUser)
  const firstNonprofit: Organization | undefined = nonprofits[0]

  const preferStripe =
    donationPaymentMethod && [PaymentMethodType.DeedCredit, PaymentMethodType.Payroll].includes(donationPaymentMethod)
      ? false
      : !!user?.organization?.settings?.preferStripe

  const getInitialDonationProvider = () => {
    if (firstNonprofit) {
      return computeDonationProviderForNonprofit(firstNonprofit, preferStripe)
    }
    return null
  }
  const initialDonationProvider = getInitialDonationProvider()

  const nonprofitCountryCode = getNonprofitCountryCode({
    nonprofit: firstNonprofit,
    donationProvider: initialDonationProvider,
  })

  // Fetch provider data if any nonprofits has donateViaNonprofitId set
  const providerDataPointer = Boolean(firstNonprofit?.id || deed?.id)

  const dispatch = useDispatch()
  useEffect(() => {
    if (providerDataPointer) {
      dispatch(actions.fetchProviderDataAction(deed?.id, firstNonprofit?.id, selectedCampaignId))
    }
  }, [dispatch, providerDataPointer, deed, firstNonprofit, selectedCampaignId])

  const rerouteDonationProvider = () => {
    if (!providerDataPointer) {
      return initialDonationProvider
    }
    return fetchedProviderData ? fetchedProviderData.donationProvider : deed?.donationProvider
  }
  const donationProvider = rerouteDonationProvider()

  const nonprofitId =
    firstNonprofit?.id || (nonprofitCountryCode === 'US' && firstNonprofit?.ein ? `ext-cn-${firstNonprofit?.ein}` : '')

  return {
    providerData: getProviderData({
      nonprofits,
      donationProvider,
    }),
    nonprofitId,

    // This should probably be removed at some point
    // Keeping it for now to avoid changing behaviour
    initialDonationProvider,
  }
}
