import React from 'react'

import { Loading } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const LoadingSpinner = () => {
  const { colors, metrics } = useDeedTheme()

  return <Loading fill={false} backgroundColor={metrics.isLarge ? colors.gray03 : colors.white} />
}
