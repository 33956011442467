import React, { useState, useEffect } from 'react'

import { styled } from 'src/theme/styled'

const AccordionBlock = styled.View`
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-top: 20px;
`

interface AccordionProps {
  defaultSelected: number
  children: React.ReactElement[]
  onSelectedUpdate?: (selected: number) => void
}

const Accordion: React.FC<AccordionProps> = ({
  defaultSelected,
  children,
  onSelectedUpdate,
}): React.ReactElement<AccordionProps> => {
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    setSelected(defaultSelected)
    onSelectedUpdate?.(defaultSelected)
  }, [defaultSelected])

  const accordionItems = React.Children.map(children, (child, i) =>
    React.cloneElement(child, {
      onPress: () => {
        onSelectedUpdate?.(i)
        setSelected(i)
      },
      selected: selected === i,
    })
  )

  return <AccordionBlock>{accordionItems}</AccordionBlock>
}

export default Accordion
