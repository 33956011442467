import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { Platform } from 'src/utils'

export const NoTypeSelectedWithArrow = () => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('searchScreen')

  return (
    <View style={{ position: 'relative', top: metrics.isSmall ? 0 : -20 }}>
      {Platform.OS === 'web' && (
        <View
          style={{
            position: 'absolute',
            width: 16,
            height: 16,
            backgroundColor: colors.blue,
            borderTopLeftRadius: 3,
            zIndex: 1,
            ...(metrics.isSmall
              ? {
                  left: 20,
                  top: -7.6,
                  transform: 'rotate(45deg)',
                }
              : {
                  top: 30,
                  left: -7.6,
                  transform: 'rotate(315deg)',
                }),
          }}
        />
      )}
      <View
        style={{
          borderRadius: 4,
          backgroundColor: colors.blue,
          padding: 32,
          width: metrics.isSmall ? '100%' : 360,
          minHeight: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Icon icon="noResultsFilterType" width={232} height={109} />

        <H3 colour={colors.white} marginBottom={16} marginTop={16}>
          {t('itsEmptyHere')}
        </H3>

        <Body1 colour={colors.white}>{t('selectDeedType')}</Body1>
      </View>
    </View>
  )
}
