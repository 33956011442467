import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'
import LocationApi from 'src/entities/location/api'
import { selectLocations } from 'src/entities/location/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { submitSuccessAction, submitFailedAction, initSuccessAction, initFailedAction } from './actions'
import { SUBMIT, INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    const locations = selectLocations(state)

    if (locations.size > 0) {
      return Observable.of(initSuccessAction())
    }

    return LocationApi.fetchAll()
      .mergeMap((action) => [action, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('phoneRegister:failedLoadingLocations'))))
  })

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ phone, deedId }) =>
      UserApi.update({ phone })
        .mergeMap((action) => [action, replace(`/deeds/${deedId}/confirm`), submitSuccessAction()])
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    )
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [init, submit]
