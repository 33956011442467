import { Observable } from 'rxjs'
import { ActionsObservable } from 'redux-observable'
import { Store } from 'redux'
import _ from 'lodash'

import { selectUpcomingDeedsLoaded, selectPastDeedsLoaded } from 'src/entities/deed/selectors'
import DeedsApi from 'src/entities/deed/api'

import { INIT, InitAction } from './constants'
import { initSuccessAction, initFailedAction } from './actions'

const init = (action$: ActionsObservable<InitAction>, store: Store) =>
  action$.ofType(INIT).exhaustMap(() => {
    const actions = []
    const state = store.getState()

    if (!selectUpcomingDeedsLoaded(state)) {
      actions.push(DeedsApi.fetchUpcoming())
    }

    if (!selectPastDeedsLoaded(state)) {
      actions.push(DeedsApi.fetchPast())
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
