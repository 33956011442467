import Organization from 'src/entities/organization/model'
import User from 'src/entities/user/model'
import { useCorporateProgramQuery } from 'src/generated/graphql'

export const useCorporateProgram = ({ user, nonprofits }: { user?: User; nonprofits: Organization[] }) => {
  const companyId = user?.organization?.id || ''
  const nonprofitIds = nonprofits.map((nonprofit) => nonprofit.id)
  const skip = !companyId || nonprofitIds.length === 0

  const { data: corporateProgramQuery } = useCorporateProgramQuery({
    skip,
    variables: { companyId, nonprofitIds },
  })

  return corporateProgramQuery?.corporateProgram
}
