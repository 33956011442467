import React from 'react'
import { View } from 'react-primitives'

import Organization from 'src/entities/organization/model'
import { Image, Row, Spacing } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface AnnouncementBannerProps {
  title: string
  content: string
  brand?: Organization
}

export const AnnouncementBanner = ({ title, content, brand }: AnnouncementBannerProps): JSX.Element => {
  const { metrics, colors } = useDeedTheme()
  const { baseSpacing } = metrics

  return (
    <View>
      <Spacing
        style={{ backgroundColor: colors.white }}
        paddingHorizontal={baseSpacing * 3}
        paddingVertical={baseSpacing * 3}
        marginHorizontal={baseSpacing * 2}
        marginVertical={baseSpacing * 3}
      >
        <Row style={{ alignItems: 'center' }}>
          {brand?.logo && (
            <Image
              source={{ uri: brand.logo }}
              style={{
                width: baseSpacing * 6,
                height: baseSpacing * 6,
                marginRight: baseSpacing * 3,
              }}
            />
          )}
          <View style={{ flex: 1 }}>
            <Body1 weight="500" colour={colors.black} marginBottom={8}>
              {title}
            </Body1>
            <Body2 colour={colors.black} marginBottom={8}>
              {content}
            </Body2>
          </View>
        </Row>
      </Spacing>
    </View>
  )
}
