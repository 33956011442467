import React, { useEffect, useState, useRef } from 'react'
import { View, Animated } from 'react-primitives'
import Lottie from 'lottie-react'

import { css } from 'src/theme/styled'
import colors from 'src/theme/colors'

import animatedLogo from './animatedLogo'

interface LogoLoaderProps {
  brandColor: string
  texts: string[]
}

const LogoLoader = ({ brandColor, texts }: LogoLoaderProps): JSX.Element => {
  const fade = useRef(new Animated.Value(0)).current
  const [index, setIndex] = useState(0)

  const animation = Animated.timing(fade, {
    toValue: 2,
    duration: 4000,
    useNativeDriver: true,
  })

  useEffect(() => {
    animation.start(() => {
      fade.setValue(0)
      // eslint-disable-next-line no-unsafe-optional-chaining
      setIndex((i) => (i < texts?.length - 1 ? i + 1 : 0))
      animation.start()
    })

    return () => animation.stop()
  }, [])

  const fadeInterpolated = fade.interpolate({
    inputRange: [0, 0.5, 1, 1.5, 2],
    outputRange: [0, 1, 1, 1, 0],
  })

  return (
    <View
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
      }}
    >
      <Lottie loop autoplay style={{ width: 100, marginLeft: 10 }} animationData={animatedLogo} />
      <Animated.Text
        style={[
          css`
            color: ${brandColor || colors.purple};
            font-weight: bold;
            margin-top: 10px;
          `,
          { opacity: fadeInterpolated },
        ]}
      >
        {texts?.[index]}
      </Animated.Text>
    </View>
  )
}

export default LogoLoader
