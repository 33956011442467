import React from 'react'
import { EnrichedActivity } from 'getstream'
import { useTranslation } from 'react-i18next'
import CommentIcon from '@mui/icons-material/Comment'

import { Row } from 'src/retired/elements'

export const CommentCount = ({ activity }: { activity: EnrichedActivity }): JSX.Element | null => {
  const { t } = useTranslation('communityFeed')
  const commentCount = activity?.reaction_counts?.comment
  return commentCount ? (
    <Row style={{ marginLeft: 24, alignItems: 'center' }}>
      <CommentIcon color="primary" />
      <div className="raf-reaction-icon__label">{t('commentCount', { count: activity?.reaction_counts?.comment })}</div>
    </Row>
  ) : null
}
