import { Observable } from 'rxjs'

import { VolunteerTimeOffSummaryDocument } from 'src/generated/graphql'
import { Platform, DeviceInfo } from 'src/utils'
import { apolloClient } from 'src/entities/graphql'

import { storeAction } from '../auth/actions'
import Api from '../api'

import User from './model'
import { addAction, updateAction } from './actions'

export default class UserApi {
  public static fetch(id = 'me'): Observable<object> {
    return Api.get(`api/users/${id}`).map((user) => addAction(new User(user, id === 'me')))
  }

  public static update(data: any): Observable<object> {
    return Api.patch('api/users/me', data).map((user) => updateAction(new User(user, true), Object.keys(data)))
  }

  public static create(user: any): Observable<object> {
    return Api.post('api/users', user).map((response) => storeAction(response.token, response.isNew))
  }

  public static verifyUser(token: string): Observable<object> {
    return Api.post(`api/users/verify/user/${token}`).map((response) => storeAction(response.token, response.isNew))
  }

  public static requestResetPassword(email: string): Observable<object> {
    return Api.post('api/users/resetPassword', { email })
  }

  public static resetPassword(token: string, password: string): Observable<object> {
    return Api.post(`api/users/resetPassword/${token}`, { password })
  }

  public static addDevicePushToken(token: string): Observable<null> {
    return Api.post('api/users/addDevicePushToken', {
      device: Platform.OS === 'ios' ? (DeviceInfo.getApplicationName() === 'DEED-Dev' ? 'iosdev' : 'ios') : Platform.OS,
      pushToken: token,
    })
  }

  public static search(term: string): Observable<null> {
    return Api.post('api/users/search', { term })
  }

  public static myDonationCredit(): Observable<object> {
    return Api.get('api/users/myDonationCredit')
  }

  public static myCurrency(): Observable<object> {
    return Api.get('api/users/myCurrency')
  }

  public static requestDeletion(): Observable<object> {
    return Api.put(`api/users/requestDeletion`)
  }

  public static myVolunteerTimeOffSummary(): Observable<object> {
    return Observable.from(
      apolloClient.query({
        query: VolunteerTimeOffSummaryDocument,
      })
    )
  }
}
