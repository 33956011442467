import { INIT, INIT_SUCCESS, INIT_FAILED, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const submitAction = (phone, deedId) => ({
  type: SUBMIT,
  phone,
  deedId,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
