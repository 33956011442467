import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Campaign from 'src/entities/campaign/model'
import { selectCampaigns } from 'src/entities/campaign/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectBrand } from 'src/localSettings/selectors'
import ScrollContainer from 'src/retired/shared/ScrollContainer'
import CardList from 'src/retired/shared/CardList'

import CampaignItem from './CampaignItem'

const ListView = ({
  scrollViewOffset,
  campaigns,
}: {
  scrollViewOffset: number
  campaigns: Campaign[]
}): JSX.Element => (
  <CardList scrollViewOffset={scrollViewOffset}>
    {campaigns.map((campaign) => (
      <CampaignItem key={campaign.id} campaign={campaign} />
    ))}
  </CardList>
)

const CampaignsBlock = () => {
  const user = useSelector(selectCurrentUser)
  const brand = useSelector(selectBrand)
  const { t } = useTranslation('homeScreen')

  const campaigns = useSelector(selectCampaigns)
    .filter(
      (campaign) =>
        !campaign?.linkOnly &&
        (user?.isEmployee()
          ? campaign?.company?.id === user.organization.id ||
            campaign?.organizations?.includes(user.organization.id) ||
            campaign?.optedIn
          : true)
    )
    .toArray() as unknown as Campaign[]

  if (!campaigns.length) {
    return null
  }

  return (
    <ScrollContainer
      title={
        campaigns.length
          ? t('campaignsTitle', { context: brand?.name ? 'companyName' : '', companyName: brand?.name })
          : ''
      }
      subtitle={campaigns.length}
      cardType="campaign"
      dimension={campaigns.length}
      render={(scrollViewOffset) => <ListView scrollViewOffset={scrollViewOffset} campaigns={campaigns} />}
      titleStyle={{ marginTop: 32 }}
    />
  )
}

export default CampaignsBlock
