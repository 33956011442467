export const INIT = 'containers/Search/INIT'
export const INIT_SUCCESS = 'containers/Search/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Search/INIT_FAILED'
export const TOGGLE_SEARCH = 'containers/Search/TOGGLE_SEARCH'
export const SEARCH = 'containers/Search/SEARCH'
export const SEARCH_DEEDS = 'containers/Search/SEARCH_DEEDS'
export const SEARCH_DEEDS_SUCCESS = 'containers/Search/SEARCH_DEEDS_SUCCESS'
export const SEARCH_DEEDS_FAILED = 'containers/Search/SEARCH_DEEDS_FAILED'
export const SEARCH_NONPROFITS = 'containers/Search/SEARCH_NONPROFITS'
export const SEARCH_NONPROFITS_SUCCESS = 'containers/Search/SEARCH_NONPROFITS_SUCCESS'
export const SEARCH_NONPROFITS_FAILED = 'containers/Search/SEARCH_NONPROFITS_FAILED'
export const RESET = 'containers/Search/RESET'
export const SEARCH_PROGRESS = 'containers/Search/SEARCH_PROGRESS'
export const RESET_SEARCH_PROGRESS = 'containers/Search/RESET_SEARCH_PROGRESS'
