import React from 'react'
import Close from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { View } from 'react-primitives'

import { Employee } from 'src/utils/useFetchEmployees'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import UserChip from 'src/components/UserChip/UserChip'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

interface Props {
  member: Optional<Employee, 'communities'>
  toggleMember: (userId: string) => void
  emphasised?: boolean
}

export const ChosenMember = ({ member, toggleMember, emphasised }: Props) => {
  const { colors } = useDeedTheme()
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // @NOTE-DP: Actually I don't know how to add opacity to hex colour (we probably need a colour manipulation library)
        backgroundColor: emphasised ? 'rgba(81, 40, 170, 0.08)' : colors.gray03,
        borderRadius: 4,
        paddingLeft: 6,
      }}
    >
      <UserChip small user={member} />
      <IconButton onClick={() => toggleMember(member.id)}>
        <Close />
      </IconButton>
    </View>
  )
}
