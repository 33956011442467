/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Box, Chip, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Modal, ModalContents, ModalTitle, ModalButton } from 'src/components/Modal/Modal'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { BackButton } from 'src/components/Buttons/BackButton/BackButton'
import UserChip from 'src/components/UserChip/UserChip'
import { Employee } from 'src/utils/useFetchEmployees'

import { ChosenMember } from '../ChosenMember'

import { EmployeeSearch } from './EmployeeSearch'
import { AddMembersReviewModal } from './AddMembersReviewModal'

interface Props {
  communityId: string
  onClickClose: () => void
}

export const AddMembersModal = ({ communityId, onClickClose }: Props) => {
  const { t } = useTranslation('communityScreen')
  const { colors } = useDeedTheme()
  const [reviewToggled, setReviewToggled] = useState(false)
  const goBack = () => setReviewToggled(false)
  const [chosenMembers, setChosenMembers] = useState<Employee[]>([])
  const chosenMemberIds = chosenMembers.map((m) => m.id)
  const removeMember = (memberId: string) => {
    setChosenMembers(chosenMembers.filter((m) => m.id !== memberId))
  }
  const addMember = (user: Employee) => {
    if (!chosenMemberIds.includes(user.id)) {
      setChosenMembers([...chosenMembers, user])
    }
  }

  if (reviewToggled) {
    return (
      <AddMembersReviewModal
        communityId={communityId}
        goBack={goBack}
        onClickClose={onClickClose}
        chosenMembers={chosenMembers}
        removeMember={removeMember}
      />
    )
  }

  return (
    <Modal open fullWidth onClose={onClickClose} style={{ minHeight: 500 }}>
      <ModalContents
        onClickClose={onClickClose}
        body={
          <View>
            <Box display="flex" alignItems="center" mb={3}>
              <BackButton onPress={onClickClose} />
              <ModalTitle style={{ marginBottom: 0, marginLeft: 12 }}>{t`addMembers`}</ModalTitle>
            </Box>

            <EmployeeSearch
              UserRenderer={({ user }) => {
                const alreadyMember = user.communities.some((c) => communityId === c.communityId)
                const alreadySelected = chosenMemberIds.includes(user.id)
                return (
                  <Box
                    onClick={alreadyMember || alreadySelected ? undefined : () => addMember(user)}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                  >
                    <UserChip user={user} disabled={alreadyMember || alreadySelected} />
                    {alreadyMember && <Chip size="small" label="Member" style={{ borderRadius: 0 }} />}
                  </Box>
                )
              }}
            />
            {chosenMembers.length > 0 && (
              <Box mt={3} mb={1}>
                <Typography variant="body2">{t`added`}</Typography>
              </Box>
            )}
            <Box mb={3} maxHeight={300} style={{ overflowY: 'auto' }}>
              <Grid container spacing={1}>
                {chosenMembers.map((member) => (
                  <Grid key={member.id} item xs={12} md={6}>
                    <ChosenMember member={member} toggleMember={removeMember} emphasised />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </View>
        }
        actions={
          <>
            <ModalButton
              style={{ color: colors.primaryBlue, marginRight: 16 }}
              variant="outlined"
              onClick={onClickClose}
            >
              {t`common:Cancel`}
            </ModalButton>
            <ModalButton
              disabled={chosenMembers.length === 0}
              onClick={() => {
                setReviewToggled(true)
              }}
            >
              {t`review`}
            </ModalButton>
          </>
        }
      />
    </Modal>
  )
}
