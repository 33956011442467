import { Record } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

import Organization from 'src/entities/organization/model'
import Donation from 'src/entities/donation/model'
import { IncentiveUnit } from 'src/containers/modules/Actions/types'

export interface AvatarData {
  id?: string
  _id?: string
  fullName?: {
    first: string
    last?: string | null
  } | null
  preferredName?: {
    first: string
    last?: string | null
  } | null
  mainPicture?: string | null
}

export interface IncentiveData {
  amount: number
  unit: IncentiveUnit
}

interface ActionStats {
  incentiveSummary: IncentiveData[]
  totalCompletedActivities: number
}

interface CampaignStats {
  employeesAmountCurrencies: { [currencyCode: string]: number }
  matchedAmountCurrencies: { [currencyCode: string]: number }
  otherAmountCurrencies: { [currencyCode: string]: number }
  goalAmountCurrencies: { [currencyCode: string]: number }
  totalRaisedCurrencies: { [currencyCode: string]: number }
  projectedTotalAmountCurrencies: { [currencyCode: string]: number }
  donatedByUsersAmountCurrencies: { [currencyCode: string]: number }
  supportersCount: number
  supporters: AvatarData[]
  actionStats: ActionStats
}

interface ContentSorting {
  items: {
    fundraisers: string[]
    volunteering: string[]
    events: string[]
    nonprofits: string[]
  }
  sections: string[]
}

const properties = {
  id: '',
  linkOnly: false,
  name: '',
  title: '',
  description: '',
  nameColor: '',
  tagline: '',
  cta: '',
  ctaTextColor: '',
  ctaButtonColor: '',
  bannerSquare: '',
  bannerRectangle: '',
  colorA: '',
  colorB: '',
  backgroundDeedsColor: '',
  backgroundHeroColor: '',
  backgroundImage: '',
  sorting: 0,
  goalAmount: undefined,
  totalHeadline: '',
  totalText: '',
  customFont: '',
  company: null,
  organization: '',
  organizations: null,
  nonprofits: null,
  nonprofitsIds: null,
  donationAmountOptions: undefined,
  totalDonationsCount: 0,
  allDonationsList: [],
  shareLink: '',
  nonprofitDonations: undefined,
  campaignStats: undefined,
  contentSorting: {
    items: {
      fundraisers: [],
      volunteering: [],
      events: [],
      nonprofits: [],
    },
    sections: [],
  },
  contentLocationFiltering: false,
  optedIn: false,
  isPledge: false,
  pledgedDonationEndingAt: undefined,
  pledgedDonationStartingAt: undefined,
  allowOneTimePledge: undefined,
  hideProgressBar: false,
  videoLink: undefined,
  showLinkToDonateSearch: false,
  autoTagDonations: false,
}

export default class Campaign extends Record(properties, 'Campaign') implements Campaign {
  public readonly id!: string

  public readonly linkOnly!: boolean

  public readonly isPledge!: boolean

  public readonly pledgedDonationStartingAt!: Date

  public readonly pledgedDonationEndingAt!: Date

  public readonly allowOneTimePledge?: boolean

  public readonly name!: string

  public readonly title!: string

  public readonly description!: string

  public readonly nameColor!: string

  public readonly tagline!: string

  public readonly cta!: string

  public readonly ctaTextColor!: string

  public readonly ctaButtonColor!: string

  public readonly bannerSquare!: string

  public readonly bannerRectangle!: string

  public readonly colorA!: string

  public readonly colorB!: string

  public readonly backgroundDeedsColor!: string

  public readonly backgroundHeroColor!: string

  public readonly backgroundImage!: string

  public readonly sorting!: number

  public readonly goalAmount!: number

  public readonly totalHeadline!: string

  public readonly totalText!: string

  public readonly customFont!: string

  public readonly company!: Organization

  public readonly organization!: string

  public readonly organizations!: string[]

  public readonly nonprofits!: Organization[]

  public readonly nonprofitsIds!: string[]

  public readonly donationAmountOptions!: number[]

  public readonly totalDonationsCount!: number

  public readonly allDonationsList!: Donation[]

  public readonly shareLink!: string

  public readonly nonprofitDonations!: Donation[]

  public readonly campaignStats!: CampaignStats

  public readonly contentSorting!: ContentSorting

  public readonly contentLocationFiltering!: boolean

  public readonly optedIn!: boolean[]

  public readonly hideProgressBar!: boolean

  public readonly videoLink?: string

  public readonly showLinkToDonateSearch!: boolean

  public readonly autoTagDonations!: boolean

  constructor(values: Partial<Campaign> = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    const {
      isPledge = false,
      pledgedDonationEndingAt,
      pledgedDonationStartingAt,
      nonprofits,
      company,
      nonprofitDonations,
      allDonationsList,
    } = values

    super({
      ...validValues,
      isPledge,
      pledgedDonationEndingAt: isPledge ? new Date(pledgedDonationEndingAt!) : undefined,
      pledgedDonationStartingAt: isPledge ? new Date(pledgedDonationStartingAt!) : undefined,
      nonprofits:
        Array.isArray(nonprofits) &&
        nonprofits
          .filter((nonprofit) => typeof nonprofit !== 'string')
          .map((nonprofit: any) => new Organization(nonprofit)),
      nonprofitsIds:
        Array.isArray(nonprofits) &&
        nonprofits.map((nonprofit) => {
          if (typeof nonprofit === 'string') {
            return nonprofit
          }
          return nonprofit?.id
        }),
      company: company && new Organization(company),
      nonprofitDonations: (nonprofitDonations ?? [])
        .filter((donation) => typeof donation !== 'string')
        .map((donation: any) => new Donation(donation)),
      allDonationsList: (allDonationsList ?? [])
        .filter((donation) => typeof donation !== 'string')
        .map((donation: any) => new Donation(donation)),
    })
  }

  public isGivingPledgeCampaign() {
    const { pledgedDonationStartingAt, isPledge } = this

    if (!isPledge) {
      return false
    }

    if (!moment(pledgedDonationStartingAt).isValid()) {
      return false
    }

    return true
  }

  public isActivePledge() {
    const isGivingPledgeCampaign = this.isGivingPledgeCampaign()

    if (!isGivingPledgeCampaign) {
      return false
    }

    const { pledgedDonationStartingAt, pledgedDonationEndingAt } = this

    if (moment(pledgedDonationStartingAt).isValid()) {
      return moment(moment.now()).isBefore(pledgedDonationStartingAt)
    }

    if (moment(pledgedDonationEndingAt).isValid()) {
      return moment(moment.now()).isBefore(pledgedDonationEndingAt)
    }

    return false
  }
}
