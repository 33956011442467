import React from 'react'
import { View } from 'react-primitives'

import Icon from 'src/retired/elements/Icon'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const StepItem = ({
  title,
  children,
  checked,
  stepNumber,
  end,
}: {
  title: string
  children: JSX.Element
  checked?: boolean
  stepNumber: number
  end?: boolean
}) => {
  const { colors } = useDeedTheme()
  return (
    <View
      style={{
        paddingLeft: 32,
        borderLeftWidth: end ? 0 : 1,
        borderColor: colors.grayLighter,
        paddingBottom: end ? 0 : 40,
      }}
    >
      {checked ? (
        <Icon
          icon="tickCircleFilledGreen"
          style={{
            position: 'absolute',
            left: -13,
            top: -3,
            backgroundColor: 'white',
            borderColor: 'white',
            border: 3,
            width: 26,
            height: 26,
          }}
        />
      ) : (
        <View
          style={{
            width: 29,
            height: 29,
            position: 'absolute',
            left: -15,
            top: -3,
            borderRadius: 15,
            backgroundColor: colors.grayLighter,
            borderWidth: 3,
            borderColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Body2 style={{ color: colors.white }}>{stepNumber}</Body2>
        </View>
      )}
      <H5 marginBottom={12} style={{ color: checked ? colors.greenDark : undefined }}>
        {title}
      </H5>
      {children}
    </View>
  )
}
