import React, { useEffect } from 'react'

import './styles.less'

const RenderHtml = ({
  source,
  customClass,
  customFont,
  customStyles,
}: {
  source: { html: string }
  customStyles?: React.CSSProperties
  customClass?: string
  customFont?: string
}): JSX.Element => {
  // Add the html attribute target="_blank" to all <a> tags inside the html-content div
  useEffect(() => {
    const htmlContent = document.getElementById('html-content')
    if (htmlContent) {
      const links = htmlContent.getElementsByTagName('a')
      for (let i = 0; i < links.length; i += 1) {
        links[i].setAttribute('target', '_blank')
      }
    }
  }, [source])

  return (
    <div
      style={{
        fontFamily: customFont,
        ...customStyles,
      }}
      className={customClass}
      id="html-content"
      dangerouslySetInnerHTML={{ __html: source?.html.replace(/\n/g, '<br />') ?? '' }}
    />
  )
}

export { RenderHtml }
