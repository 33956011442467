import { Observable } from 'rxjs'
import type { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import _ from 'lodash'

import DeedsApi from 'src/entities/deed/api'
import OrganizationApi from 'src/entities/organization/api'
import { selectUserLocations, selectCurrentUser } from 'src/entities/user/selectors'
import {
  selectDisplayedLocalOrganizations,
  selectDisplayedPartnerOrganizations,
} from 'src/entities/organization/selectors'
import { selectDeedTypeFeedLoading } from 'src/containers/screens/Feed/selectors'
import { ADD as ADD_USER } from 'src/entities/user/constants'
import type { AddUser } from 'src/entities/user/actions'
import type { State } from 'src/reducers'

import {
  LOAD_FUNDRAISERS,
  LOAD_LOCAL_NONPROFITS,
  LOAD_PARTNER_NONPROFITS,
  CANCEL_LOAD_LOCAL_NONPROFITS,
} from './constants'
import {
  LoadFundraisers,
  LoadLocalNonprofits,
  LoadPartnerNonprofits,
  loadFundraisersSuccessAction,
  loadFundraisersFailureAction,
  loadLocalNonprofitsSuccessAction,
  loadLocalNonprofitsFailureAction,
  loadPartnerNonprofitsSuccessAction,
  loadPartnerNonprofitsFailureAction,
} from './actions'

const loadFundraisers = (action$: ActionsObservable<LoadFundraisers>, store: Store<State>) =>
  action$
    .ofType(LOAD_FUNDRAISERS)
    .mergeMap(() => {
      const actions = []
      const state = store.getState()

      if (!selectDeedTypeFeedLoading(state, 'Campaign')) {
        actions.push(DeedsApi.fetchFeed({ type: 'Campaign', limit: 10 }))
      }

      if (actions.length === 0) {
        return Observable.of(loadFundraisersSuccessAction())
      }

      return Observable.combineLatest(actions).mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        loadFundraisersSuccessAction(),
      ])
    })
    .catch((e) => Observable.of(loadFundraisersFailureAction(e)))

let numberOfLocalItems: number
const loadLocalNonprofits = (action$: ActionsObservable<LoadLocalNonprofits | AddUser>, store: Store<State>) =>
  action$
    .ofType(LOAD_LOCAL_NONPROFITS, ADD_USER)
    .mergeMap((payload) => {
      if (payload.type === ADD_USER && !payload.user.me) {
        return []
      }
      if (payload.type === LOAD_LOCAL_NONPROFITS) {
        numberOfLocalItems = payload.numberOfItems
      }

      const actions = []
      const state = store.getState()
      const user = selectCurrentUser(state)
      if (!user) {
        return []
      }

      const locations = selectUserLocations(state)
      const countryCode = locations?.get(user?.location)?.countryCode || 'US'
      const stateCode = locations?.get(user?.location)?.stateCode || ''
      const partnerNonprofitsOnly = user?.getSetting('partnerNonprofitsOnly')

      if (selectDisplayedLocalOrganizations(state).size === 0) {
        actions.push(
          OrganizationApi.listNonprofits(
            {
              countryCode,
              stateCode,
              acceptsDonations: true,
              includeApproved: true,
              pageSize: numberOfLocalItems,
              page: 1,
            },
            // HOTFIX: disable new search for closed environments (only partners are searchable)
            user?.organization?.settings.searchNonprofitDB && !partnerNonprofitsOnly
          ).takeUntil(action$.ofType(CANCEL_LOAD_LOCAL_NONPROFITS))
        )
      }

      if (actions.length === 0) {
        return Observable.of(loadLocalNonprofitsSuccessAction())
      }

      return Observable.combineLatest(actions).mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        loadLocalNonprofitsSuccessAction(),
      ])
    })
    .catch((e) => Observable.of(loadLocalNonprofitsFailureAction(e)))

let numberOfPartnerItems: number
const loadPartnerNonprofits = (action$: ActionsObservable<LoadPartnerNonprofits | AddUser>, store: Store<State>) =>
  action$
    .ofType(LOAD_PARTNER_NONPROFITS, ADD_USER)
    .mergeMap((payload) => {
      if (payload.type === ADD_USER && !payload.user.me) {
        return []
      }
      if (payload.type === LOAD_PARTNER_NONPROFITS) {
        numberOfPartnerItems = payload.numberOfItems
      }

      const actions = []
      const state = store.getState()
      const user = selectCurrentUser(state)
      if (!user) {
        return []
      }

      const locations = selectUserLocations(state)
      const countryCode = locations?.get(user?.location)?.countryCode || 'US'
      const stateCode = locations?.get(user?.location)?.stateCode || ''

      if (selectDisplayedPartnerOrganizations(state).size === 0 && user.isEmployee()) {
        actions.push(
          OrganizationApi.fetchPartnerNonprofits({
            countryCode,
            stateCode,
            pageSize: numberOfPartnerItems,
            page: 1,
          })
        )
      }

      if (actions.length === 0) {
        return Observable.of(loadPartnerNonprofitsSuccessAction())
      }

      return Observable.combineLatest(actions).mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        loadPartnerNonprofitsSuccessAction(),
      ])
    })
    .catch((e) => Observable.of(loadPartnerNonprofitsFailureAction(e)))

export default [loadFundraisers, loadLocalNonprofits, loadPartnerNonprofits]
