import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import epics from 'src/containers/screens/Organization/epics'
import reducer from 'src/containers/screens/Organization/reducer'
import { initAction } from 'src/containers/screens/Organization/actions'
import { colors, icons } from 'src/theme'
import { Button, Text, Image } from 'src/retired/elements'
import { useDeleteEmployeeAffiliationMutation, AffiliationsTableDocument, Affiliation } from 'src/generated/graphql'
import Alert from 'src/retired/elements/Alert'
import SafeAreaView from 'src/retired/elements/SafeAreaView'
import Organization from 'src/entities/organization/model'
import makeGoBack from 'src/navigation/makeGoBack'

import { AffiliationScrollView } from '../common'

const RemoveAffiliation = (): React.ReactElement => {
  const { metrics } = useDeedTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const goBack = makeGoBack(history, '/profile')
  useInjectReducer({ key: 'affiliation', reducer })
  useInjectEpics({ key: 'affiliation', epics })
  const { t } = useTranslation('removeAffiliation')
  const { organization: organizationId, affiliationId } = useParams<{
    organization: Organization['id']
    affiliationId: Affiliation['id']
  }>()

  useEffect(() => {
    dispatch(initAction(organizationId))
  }, [organizationId])

  const nonprofit = useSelector((state) => selectOrganizationById(state, organizationId))

  const [deleteEmployeeAffiliationMutation, { loading }] = useDeleteEmployeeAffiliationMutation({
    onCompleted: () => {
      history.goBack()
      Alert.alert(t`Affiliation removed`)
    },
    onError: () => {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    },
  })

  const submit = async (): Promise<void> => {
    await deleteEmployeeAffiliationMutation({
      variables: {
        deleteEmployeeAffiliationId: affiliationId,
      },
      update: (cache, { data: update }) => {
        const affiliationsList: { affiliations: Affiliation[] } | null = cache.readQuery({
          query: AffiliationsTableDocument,
          variables: { affiliationId },
        })

        const updatedAffiliations = affiliationsList?.affiliations.filter(
          (affiliation) => affiliation.id !== update?.deleteEmployeeAffiliation?.id
        )
        cache.writeQuery({
          query: AffiliationsTableDocument,
          variables: { affiliationId },
          data: {
            affiliations: updatedAffiliations,
          },
        })
      },
    })
  }

  return (
    <SafeAreaView>
      <AffiliationScrollView>
        <View
          style={{
            backgroundColor: colors.lightYellow,
            borderRadius: 20,
            alignItems: 'center',
            padding: 20,
            marginBottom: 40,
          }}
        >
          <Image source={icons.sadCloud} style={{ height: 200, width: 200 }} />
        </View>

        <View style={{ marginBottom: 40 }}>
          <Text center size={32} bold style={{ marginBottom: 20 }}>{t`removeAffiliation`}</Text>
          <Text center size={20} light>
            {t('doYouWannaRemove', { nonprofitName: nonprofit?.name })}
          </Text>
        </View>

        <View
          style={{
            flexDirection: metrics.isSmall ? 'column' : 'row',
            justifyContent: metrics.isSmall ? 'center' : 'space-between',
          }}
        >
          <Button
            disabled={loading}
            style={{ width: metrics.isSmall ? '100%' : 325, marginBottom: metrics.isSmall ? 15 : 0 }}
            color="primary"
            onPress={goBack}
          >{t`cancelAndKeep`}</Button>
          <Button
            disabled={loading}
            style={{ width: metrics.isSmall ? '100%' : 325 }}
            onPress={submit}
          >{t`removeAffiliation`}</Button>
        </View>
      </AffiliationScrollView>
    </SafeAreaView>
  )
}

export default RemoveAffiliation
