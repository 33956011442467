import React from 'react'
import { View } from 'react-primitives'

import Image from 'src/retired/elements/Image'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const BADGE_SIZE_SMALL = 85
export const BADGE_SIZE_BIG = 125

export const BadgeImage = ({
  imageURL,
  dimension,
  isEarned,
}: {
  imageURL: string
  dimension?: number
  isEarned: boolean
}) => {
  const { metrics } = useDeedTheme()

  const badgeSize = dimension ?? (metrics.isSmall ? BADGE_SIZE_SMALL : BADGE_SIZE_BIG)

  return (
    <View
      style={{ width: badgeSize, height: badgeSize, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {isEarned ? (
        <Image style={{ width: '100%', height: '100%' }} source={{ uri: imageURL }} resizeMode="contain" />
      ) : (
        <Icon icon="circleWithLock" />
      )}
    </View>
  )
}
