import React from 'react'
import moment from 'moment'
import { TimePicker as AntdTimePicker } from 'antd'
import type { TimePickerProps as AntdTimePickerProps } from 'antd'
import { useTranslation } from 'react-i18next'

import './style.less'

interface TimePickerProps extends Omit<AntdTimePickerProps, 'onChange'> {
  style?: object
  format?: string
  use12Hours?: boolean
  onChange: (value: Date | null) => Date | null
}

const TimePicker: React.FC<TimePickerProps> = ({
  onChange = () => null,
  value = null,
  placeholder,
  bordered = true,
  style,
  format = 'h:mm a',
  use12Hours = true,
  ...props
}) => {
  const { t } = useTranslation('datePicker')

  return (
    <AntdTimePicker
      value={value ? moment(value) : undefined}
      placeholder={placeholder || t`selectDate`}
      allowClear
      format={format}
      use12Hours={use12Hours}
      showNow={false}
      minuteStep={5}
      onChange={(newTime) => {
        onChange(newTime?.toDate() ?? null)
      }}
      onSelect={(newTime) => {
        onChange(newTime.toDate())
      }}
      popupStyle={{
        paddingTop: 4,
      }}
      bordered={bordered}
      style={style}
      {...props}
    />
  )
}

export default TimePicker
