import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Row from 'src/retired/elements/Row'
import Spacing from 'src/retired/elements/Spacing'
import ScrollView from 'src/retired/elements/ScrollView'
import Chip from 'src/retired/shared/Chip'
import Organization from 'src/entities/organization/model'
import Deed from 'src/entities/deed/model'
import { Link } from 'src/navigation'
import { selectCauses } from 'src/entities/cause/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Platform } from 'src/utils'

interface CauseChipsProps {
  model: Deed | Organization
  link?: boolean
  wrapOnLarge?: boolean
}

export type CauseChipsElement = React.ReactElement<CauseChipsProps>

const ScrollWrapper = ({ children, wrapOnLarge }: { children: JSX.Element; wrapOnLarge: boolean }) => {
  const { metrics } = useDeedTheme()

  return !wrapOnLarge && !metrics.isSmall ? (
    <Row style={{ flexWrap: 'wrap', marginHorizontal: -14 }}>{children}</Row>
  ) : (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={{
        flexWrap: 'wrap',
        marginHorizontal: -24,
        flexBasis: 'auto',
        alignSelf: 'stretch',
        flexShrink: 1,
        flexGrow: Platform.OS === 'web' ? 1 : 0,
      }}
      contentContainerStyle={{ paddingLeft: 12, paddingRight: 24 }}
    >
      {children}
    </ScrollView>
  )
}

const CauseChips: React.FC<CauseChipsProps> = ({ model, wrapOnLarge = true, link = false }): CauseChipsElement => {
  const Wrapper = link ? Link : Fragment
  const causes = useSelector(selectCauses)
  const user = useSelector(selectCurrentUser)
  const logo =
    !model.partner || model.optedIn || user?.organization?.id === model.partner?.id
      ? user?.organization?.logo || user?.organization?.mainPicture
      : undefined

  return (
    <ScrollWrapper wrapOnLarge={wrapOnLarge}>
      {model.pillars.map((causeId) => {
        const cause = causes?.get(causeId)
        const WrapperProps = cause && link ? { to: `/causes/${cause.type.toLowerCase()}/${causeId}` } : {}
        return (
          cause && (
            <Spacing key={causeId} marginLeft={12} marginBottom={12}>
              <Wrapper {...WrapperProps}>
                <Chip type="cause" backgroundColor={cause.color} image={logo}>
                  {cause.name}
                </Chip>
              </Wrapper>
            </Spacing>
          )
        )
      })}

      {model.categories.map((causeId) => {
        const cause = causes?.get(causeId)
        const WrapperProps = cause && link ? { to: `/causes/${cause.type.toLowerCase()}/${causeId}` } : {}
        return (
          cause && (
            <Spacing key={causeId} marginLeft={12} marginBottom={12}>
              <Wrapper {...WrapperProps}>
                <Chip type="cause" backgroundColor={cause.color}>
                  {cause.name}
                </Chip>
              </Wrapper>
            </Spacing>
          )
        )
      })}
      {model.ERGs.map((causeId) => {
        const cause = causes?.get(causeId)
        const WrapperProps = cause && link ? { to: `/causes/${cause.type.toLowerCase()}/${causeId}` } : {}
        return (
          cause && (
            <Spacing key={causeId} marginLeft={12} marginBottom={12}>
              <Wrapper {...WrapperProps}>
                <Chip type="cause" backgroundColor={cause.color} image={logo}>
                  {cause.name}
                </Chip>
              </Wrapper>
            </Spacing>
          )
        )
      })}
    </ScrollWrapper>
  )
}

export default CauseChips
