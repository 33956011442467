/* eslint-disable react/no-unstable-nested-components */
import React, { PureComponent } from 'react'
import { View } from 'react-primitives'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { useTranslation, withTranslation, Trans } from 'react-i18next'

import { injectReducer, injectEpics } from 'src/utils'
import {
  ActivityIndicator,
  Screen,
  Divider,
  Text,
  Touchable,
  Icon,
  Row,
  Image,
  RefreshControl,
  Spacing,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import TabMenu from 'src/retired/blocks/TabMenu'
import List from 'src/retired/blocks/List'
import { SmallOnly } from 'src/retired/elements/MediaQuery'
import { colors } from 'src/theme'
import { Link, withRouter } from 'src/navigation'
import { PageTitle } from 'src/components'

import {
  selectLoading,
  selectNotifications,
  selectPendingInvitationRequests,
  selectPendingRelationRequests,
} from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const NotificationItem = ({
  profilePic,
  userName,
  userId,
  description,
  onAccept,
  onReject,
  isSaving,
  link = false,
}) => (
  <Row style={{ margin: 25 }}>
    <Link to={`/profile/${userId}`}>
      <Image source={{ uri: profilePic }} style={{ marginRight: 25, width: 50, height: 50, borderRadius: 25 }} />
    </Link>
    <Spacing marginRight={10} marginTop={5} style={{ flex: 1 }}>
      <Link to={`/profile/${userId}`}>
        <Text size={15} bold style={{ marginBottom: 5 }}>
          {userName}
        </Text>
      </Link>
      <Text size={13}>{description}</Text>
    </Spacing>
    {isSaving ? (
      <View style={{ width: 77, height: 36, justifyContent: 'center' }}>
        <ActivityIndicator color={colors.pink} size="small" />
      </View>
    ) : (
      <>
        <Touchable onPress={onReject}>
          <Icon icon="rejectCircleBlack" style={{ width: 36, height: 36 }} />
        </Touchable>
        <Spacing marginRight={5} />
        <Touchable onPress={onAccept}>
          <Icon icon={link ? 'arrowCircleRightPink' : 'tickCirclePink'} style={{ width: 36, height: 36 }} />
        </Touchable>
      </>
    )}
  </Row>
)

const DeedInviteRenderer = withRouter(({ notification, actions, history, pendingInvitationRequests }) => {
  const { t } = useTranslation('notificationsScreen')
  return (
    <NotificationItem
      profilePic={notification.user.mainPicture}
      userName={notification.user?.fullName?.first || notification.user.name}
      userId={notification.user.id}
      description={
        <Trans
          t={t}
          i18nKey="invitedYouToAttend"
          values={{
            deedName: notification.deed.name,
            date: notification.deed.virtual
              ? notification.deed.startingAt
              : { value: notification.deed.startingAt, timeZone: notification.deed.timeZone },
          }}
          components={{
            DeedLink: <Link to={`/deeds/${notification.deed.id}`} />,
            DeedLinkText: <Text size={13} bold color={colors.brandColor} />,
          }}
        />
      }
      onAccept={() => history.push(`/deeds/${notification.deed.id}`)}
      onReject={() => actions.invitationRejectAction(notification.deed.id)}
      isSaving={pendingInvitationRequests.get(notification.deed.id)}
      link
    />
  )
})
const RelationRenderer = ({ notification, actions, pendingRelationRequests }) => {
  const { t } = useTranslation('notificationsScreen')
  return (
    <NotificationItem
      profilePic={notification.from.mainPicture}
      userName={notification.from.name}
      userId={notification.from.id}
      description={
        notification.deed ? (
          <Trans
            t={t}
            i18nKey="requestedToConnectAfter"
            values={{
              deedName: notification.deed.name,
              date: notification.deed.virtual
                ? notification.deed.startingAt
                : { value: notification.deed.startingAt, timeZone: notification.deed.timeZone },
            }}
            components={{
              DeedName: <Text size={13} bold color={colors.brandColor} />,
            }}
          />
        ) : (
          t('requestedToConnectOn', { date: notification.createdAt })
        )
      }
      onAccept={() => actions.relationAcceptAction(notification.from.id)}
      onReject={() => actions.relationRejectAction(notification.from.id)}
      isSaving={pendingRelationRequests.get(notification.from.id)}
    />
  )
}

class Notifications extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  rendererBindings = {
    DeedInvite: DeedInviteRenderer,
    Relation: RelationRenderer,
  }

  render() {
    const { t } = this.props
    return (
      <Screen fixed>
        <PageTitle title={t('notifications')} />
        <NavigationHeader title={t`notifications`} />
        <List
          style={{ minWidth: 100, minHeight: 100, flex: 1 }}
          data={this.props.notifications}
          keyExtractor={(item) => item.from?.id || item.user?.id || item.deed?.id}
          renderItem={({ item }) => {
            const Component = this.rendererBindings[item.type]
            return <Component {...this.props} notification={item} />
          }}
          itemProps={{ style: { width: '100%' } }}
          ItemSeparatorComponent={() => <Divider marginTop={0} marginBottom={0} />}
          refreshControl={
            <RefreshControl
              refreshing={this.props.loading}
              onRefresh={() => this.props.actions.refreshAction()}
              tintColor={colors.pink}
            />
          }
          ListEmptyComponent={() =>
            !this.props.loading && (
              <View style={{ flex: 1, padding: 25 }}>
                <Text size={16} lineHeight={26} center>
                  {t`youAreAllCaughtUp`}
                </Text>
                <Text size={16} lineHeight={26} center>
                  <Trans
                    t={t}
                    i18nKey="nowGoDoSomethingGood"
                    components={{
                      Emoji: <Text size={16} lineHeight={20} style={{ fontFamily: 'System' }} />,
                    }}
                  />
                </Text>
              </View>
            )
          }
        />
        <SmallOnly>
          <TabMenu />
        </SmallOnly>
      </Screen>
    )
  }
}
Notifications.propTypes = {
  loading: PropTypes.bool,
  notifications: PropTypes.array,
}

const withConnect = connect(
  (state) => ({
    loading: selectLoading(state),
    notifications: selectNotifications(state),
    pendingInvitationRequests: selectPendingInvitationRequests(state),
    pendingRelationRequests: selectPendingRelationRequests(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'notifications', reducer })
const withEpics = injectEpics({ key: 'notifications', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('notificationsScreen'))(Notifications)
