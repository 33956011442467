import React, { Dispatch, SetStateAction } from 'react'
import { View } from 'react-primitives'

import { Checkbox } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'

export interface CheckboxItemProps {
  id: string
  name: string
  status: boolean
}

export interface CheckboxListProps {
  values: CheckboxItemProps[]
  setValues: Dispatch<SetStateAction<CheckboxItemProps[]>>
}

export const CheckboxList = ({ values, setValues }: CheckboxListProps) => {
  const onChipToggleHandler = (chipItem: CheckboxItemProps) => {
    setValues((prev) =>
      prev.map((item) => {
        if (item.id === chipItem.id) {
          // eslint-disable-next-line no-param-reassign
          item.status = !item.status
          return item
        }
        return { ...item, status: item.status }
      })
    )
  }

  return (
    <>
      {values.map((item) => (
        <View key={item.id} style={{ paddingTop: 15 }}>
          <Checkbox
            name={item.name}
            checked={item.status}
            onChange={() => onChipToggleHandler(item)}
            onTouched={() => onChipToggleHandler(item)}
            style={{ cursor: 'pointer' }}
          >
            <Body1>{item.name}</Body1>
          </Checkbox>
        </View>
      ))}
    </>
  )
}
