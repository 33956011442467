import React from 'react'
import PropTypes from 'prop-types'

import Text from 'src/retired/elements/Text'

const ExternalLink = ({ href, emailSubject = '', target, ...props }) => {
  const linkObject = {
    type: 'url',
    href,
  }

  if (href.startsWith('mailto:')) {
    linkObject.type = 'mail'
  }
  if (href.startsWith('tel:')) {
    linkObject.type = 'tel'
  }

  switch (linkObject.type) {
    case 'mail':
      linkObject.href = `${href}${emailSubject ? `?subject=${emailSubject}` : ''}`
      break

    case 'url':
      linkObject.href = href.startsWith('http') ? href : `http://${href}`
      break

    default:
      break
  }

  return (
    <Text
      {...props}
      href={linkObject.href}
      accessibilityRole="link"
      target={target || ['mail', 'tel'].includes(linkObject.type) ? '' : '_blank'}
      rel="noopener noreferrer"
      onPress={(e) => {
        if (linkObject.type !== 'url') {
          return
        }
        e.preventDefault()
        window.open(linkObject.href, '_blank')
      }}
    />
  )
}
ExternalLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
}

export default ExternalLink
