import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state: any) => state.get('donateFeed') || initialState

export const selectFundraisersLoading = createSelector(selectState, (state) => state.get('fundraisersLoading'))
export const selectLocalNonprofitsLoading = createSelector(selectState, (state) => state.get('localNonprofitsLoading'))
export const selectPartnerNonprofitsLoading = createSelector(selectState, (state) =>
  state.get('partnerNonprofitsLoading')
)
