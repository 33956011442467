import { fromJS } from 'immutable'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  INVITATION_REJECT,
  INVITATION_REQUEST_FINISHED,
  RELATION_ACCEPT,
  RELATION_REJECT,
  RELATION_REQUEST_FINISHED,
} from './constants'

export const initialState = fromJS({
  initialized: false,
  loading: false,
  error: null,
  pendingRelationRequests: {},
  pendingInvitationRequests: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      if (state.get('initialized')) {
        return state
      }
      return state.merge({ loading: true, error: null })

    case REFRESH:
      return state.set('loading', true)

    case INIT_SUCCESS:
    case REFRESH_SUCCESS:
      return state.merge({ loading: false, initialized: true })

    case REFRESH_FAILED:
    case INIT_FAILED:
      return state.merge({
        loading: false,
        initialized: true,
        error: action.error,
      })

    case INVITATION_REJECT:
      return state.setIn(['pendingInvitationRequests', action.id], true)

    case INVITATION_REQUEST_FINISHED:
      return state.setIn(['pendingInvitationRequests', action.id], false)

    case RELATION_ACCEPT:
    case RELATION_REJECT:
      return state.setIn(['pendingRelationRequests', action.id], true)

    case RELATION_REQUEST_FINISHED:
      return state.setIn(['pendingRelationRequests', action.id], false)

    case 'RESET':
      return initialState

    default:
      return state
  }
}
