import { Record } from 'immutable'
import _ from 'lodash'

import Organization from '../organization/model'

const properties = {
  createdAt: null,
  updatedAt: null,
  organization: Organization,
  user: '',
}

export default class Following extends Record(properties, 'Following') {
  public readonly createdAt!: Date

  public readonly updatedAt!: Date

  public readonly organization!: Organization

  public readonly user!: string

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      createdAt: new Date(values.createdAt),
      updatedAt: new Date(values.updatedAt),
      organization: values.organization && new Organization(values.organization),
    })
  }
}
