import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Loading, Row, ScrollView } from 'src/retired/elements'
import { PageTitle } from 'src/components'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Action, ActivityStatus } from 'src/containers/modules/Actions/types'
import { useActivityMutations } from 'src/containers/modules/Actions/hooks'
import { H3 } from 'src/retired/shared/Typography'
import IconButton from 'src/retired/shared/IconButton'
import makeGoBack from 'src/navigation/makeGoBack'
import { useHistory } from 'src/navigation'
import { styled } from 'src/theme/styled'
import { CampaignTag } from 'src/containers/modules/Actions/CampaignTag'
import { handleActionButtonPress, isDateInThePast } from 'src/containers/modules/Actions/Card/utils'
import { ConfirmCompletionModal } from 'src/containers/screens/Actions/Modals/ConfirmCompletionModal'
import { SuccessModals } from 'src/containers/screens/Actions/Modals/SuccessModals'
import { EndDateTag } from 'src/containers/modules/Actions/EndDateTag'

import { SideContent } from '../SideContent'
import { ButtonBar } from '../ButtonBar'
import { BannerImageWithStatus } from '../Banner/BannerImageWithStatus'
import { CauseChips } from '../CauseChips'
import { DescriptionBlock } from '../DescriptionBlock'
import { ConfirmLeaveModal } from '../Modals/ConfirmLeaveModal'

const TopRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const ActionScreenView = ({
  action,
  campaignId,
  loading,
}: {
  action: Action | null
  campaignId?: string
  loading: boolean
}) => {
  const { t } = useTranslation('actions')

  const { metrics } = useDeedTheme()
  const history = useHistory()
  const goBack = makeGoBack(history)

  const [userActivity] = action?.userActivities ?? []

  // @NOTE-CH We always fallback to the first Campaign the Action is tagged to if any
  // eslint-disable-next-line no-param-reassign
  campaignId = campaignId ?? action?.Campaigns?.[0]?.id
  const [campaign] =
    // eslint-disable-next-line no-unsafe-optional-chaining
    (action?.Campaigns ?? []).filter((currentCampaign) => currentCampaign.id === campaignId) ?? action?.Campaigns

  const { onUpsertAction, loadingUpsertActionActivity } = useActivityMutations({
    userActivity,
    campaignId,
    actionId: action?.id,
  })

  const isMobile = metrics.isSmall

  const [confirmCompletionModalVisible, setConfirmCompletionModalVisible] = useState(false)
  const [confirmLeaveModalVisible, setConfirmLeaveModalVisible] = useState(false)

  const onPrimaryButtonPress = () => {
    handleActionButtonPress({
      currentActivityStatus: userActivity?.status,
      onUpsertAction,
      setConfirmCompletionModalVisible,
    })
  }

  const onSecondaryButtonPress = () => {
    setConfirmLeaveModalVisible(true)
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const Buttons = () => (
    <ButtonBar
      activity={userActivity}
      onPrimaryButtonPress={onPrimaryButtonPress}
      onSecondaryButtonPress={onSecondaryButtonPress}
      loading={loadingUpsertActionActivity}
      isExpired={isDateInThePast(action?.endingAt)}
    />
  )

  return (
    <View style={{ marginBottom: isMobile ? 50 : 0 }}>
      <PageTitle title={action?.title || t('action')} />
      {loading ? (
        <View style={{ paddingTop: 80 }}>
          <Loading fill={false} />
        </View>
      ) : action ? (
        <>
          <TopRow style={{ marginTop: isMobile ? 20 : 40 }}>
            <Row style={{ alignItems: 'center' }}>
              <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />
              <H3 numberOfLines={2} style={{ flex: 1, fontSize: isMobile ? 26 : 32 }}>
                {action?.title}
              </H3>
            </Row>
            {!isMobile && <Buttons />}
          </TopRow>
          <View style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', marginTop: 30 }}>
            <View style={{ overflow: 'hidden', width: isMobile ? '100%' : '66%' }}>
              <BannerImageWithStatus action={action} activityStatus={userActivity?.status ?? null} />
              <View style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                {campaign ? <CampaignTag campaign={campaign} /> : null}
                <View style={{ paddingLeft: isMobile ? 0 : 20 }}>
                  <EndDateTag endingAt={action.endingAt} status={userActivity?.status ?? null} />
                </View>
              </View>
              <ScrollView
                horizontal
                style={{
                  paddingTop: metrics.isSmall ? 12 : 24,
                  paddingBottom: metrics.isSmall ? 10 : 20,
                }}
              >
                <CauseChips causes={action?.Causes || []} />
              </ScrollView>

              {isMobile && (
                <>
                  <SideContent action={action} />
                  <View style={{ paddingTop: 24 }}>
                    <Buttons />
                  </View>
                </>
              )}
              <DescriptionBlock title={t('description')} description={action?.description} />
            </View>
            {!isMobile && (
              <View style={{ paddingLeft: 40, width: '34%' }}>
                <SideContent action={action} />
              </View>
            )}
          </View>
          <SuccessModals action={action} buttonsDisabled={loading || loadingUpsertActionActivity} />
          <ConfirmCompletionModal
            action={action}
            visible={confirmCompletionModalVisible}
            buttonsDisabled={loading || loadingUpsertActionActivity}
            onClose={() => {
              setConfirmCompletionModalVisible(false)
            }}
            onPressPrimaryButton={() => {
              onUpsertAction(ActivityStatus.Completed)
              setConfirmCompletionModalVisible(false)
            }}
          />
          <ConfirmLeaveModal
            action={action}
            visible={confirmLeaveModalVisible}
            buttonsDisabled={loading || loadingUpsertActionActivity}
            onClose={() => {
              setConfirmLeaveModalVisible(false)
            }}
            onPressPrimaryButton={() => {
              onUpsertAction(ActivityStatus.Left)
              setConfirmLeaveModalVisible(false)
            }}
          />
        </>
      ) : null}
    </View>
  )
}
