import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useHistory } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ScrollView } from 'src/retired/elements'
import Layout from 'src/retired/blocks/Layout'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { ActionsFeed } from './ActionsFeed'

const ActionsFeedContainer = () => {
  const { metrics } = useDeedTheme()
  const history = useHistory()

  const user = useSelector(selectCurrentUser)
  const isActionsFeatureEnabled = user?.hasFeature('actions')

  const [showActiveActions, setShowActiveActions] = useState(true)

  if (user && !isActionsFeatureEnabled) {
    history.replace('/home')
    return null
  }

  return (
    <Layout padding={!metrics.isExtraLarge}>
      <ScrollView>
        <ActionsFeed showActiveActions={showActiveActions} setShowActiveActions={setShowActiveActions} />
      </ScrollView>
    </Layout>
  )
}

export default ActionsFeedContainer
