import { STORE, UPDATE, CLEAR } from './constants'

export interface StoreAction {
  type: typeof STORE
  token: string
  isNew: boolean
}
export const storeAction = (token: string, isNew = false): StoreAction => ({
  type: STORE,
  token,
  isNew,
})

export interface UpdateAction {
  type: typeof UPDATE
  token: string
}
export const updateAction = (token: string): UpdateAction => ({
  type: UPDATE,
  token,
})

export interface ClearAction {
  type: typeof CLEAR
}
export const clearAction = (): ClearAction => ({
  type: CLEAR,
})

export type AuthAction = StoreAction | UpdateAction | ClearAction
