import React from 'react'
import { useTranslation } from 'react-i18next'

import { IncentiveUnit } from '../types'

import { ActionChip } from './ActionChip'

const getChipDataByType = (unit?: string) => {
  switch (unit) {
    case IncentiveUnit.co2:
      return {
        translationKey: 'impact_carbon_removal',
        backgroundColor: '#E1F1F9',
        iconName: 'cloud',
      }

    default:
      return {
        translationKey: 'impact_default',
        backgroundColor: '#E1F1F9',
        iconName: 'smile',
      }
  }
}

export const ImpactChip = ({ amount, unit }: { amount?: number; unit?: string }) => {
  const { t } = useTranslation('actions')

  const chipData = getChipDataByType(unit)
  if (!chipData) {
    return null
  }

  const { translationKey, iconName, backgroundColor } = chipData
  return (
    <ActionChip content={t(translationKey, { count: amount })} iconName={iconName} backgroundColor={backgroundColor} />
  )
}
