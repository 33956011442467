import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  LOGOUT,
  LOGOUT_FAILED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
} from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: Error, errorCode: number) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const logoutAction = () => ({
  type: LOGOUT,
})
export const logoutFailedAction = (error: Error) => ({
  type: LOGOUT_FAILED,
  error,
})

export const submitAction = (data) => ({
  type: SUBMIT,
  data,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error: Error, errorCode: number) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
