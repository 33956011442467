/* @NOTE-CH: Forked from https://github.com/ntucker/redux-persist-transform-filter-immutable/blob/master/index.js */
import { Iterable, Map } from 'immutable'
import type { KeyedCollection } from 'immutable'
import { createTransform } from 'redux-persist'
import get from 'lodash/get'
import set from 'lodash/set'
import isUndefined from 'lodash/isUndefined'
import isString from 'lodash/isString'

export type returnType = KeyedCollection<string, any> | { [key: string]: any }

export default (reducerName: string, inboundPaths: string | string[], outboundPaths: string | string[]) =>
  createTransform(
    (inboundState: any) => (inboundPaths ? persistFilter(inboundState, inboundPaths) : inboundState),
    (outboundState: any) => (outboundPaths ? persistFilter(outboundState, outboundPaths) : outboundState),
    { whitelist: [reducerName] }
  )

export function persistFilter(state: any, paths: string | string[] = []): returnType {
  const iterable = Iterable.isIterable(state)
  let subset: returnType = iterable ? Map({}) : {}

  ;(isString(paths) ? [paths] : paths).forEach((path) => {
    const value = iterable ? state[isString(path) ? 'get' : 'getIn'](path) : get(state, path)
    if (!isUndefined(value)) {
      if (iterable) {
        subset = subset[isString(path) ? 'set' : 'setIn'](path, value)
      } else {
        set(subset, path, value)
      }
    }
  })

  return subset
}
