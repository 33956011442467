import { SEND_EMAIL, SEND_EMAIL_FAILED, SEND_EMAIL_SUCCESS } from './constants'

export interface SendEmailAction {
  type: typeof SEND_EMAIL
  emailType: string
  message: string
}
export const sendEmailAction = (emailType: string, message: string): SendEmailAction => ({
  type: SEND_EMAIL,
  emailType,
  message,
})

export const sendEmailFailedAction = (error: object) => ({
  type: SEND_EMAIL_FAILED,
  error,
})

export const sendEmailSuccessAction = () => ({
  type: SEND_EMAIL_SUCCESS,
})
