/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect } from 'react'
import { Activity, EnrichedReaction, UR, Reaction, EnrichedActivity } from 'getstream'
import { useFeedContext } from 'react-activity-feed'
import type { DefaultAT, DefaultUT } from 'react-activity-feed'
import type { PropsWithElementAttributes } from 'react-activity-feed/dist/utils'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import classNames from 'classnames'

import Icon from 'src/retired/elements/Icon'

const IconContainer = ({ children }: { children: React.ReactNode }) => (
  <View style={{ height: 30, width: 26, justifyContent: 'flex-end', alignItems: 'center', marginTop: -10 }}>
    {children}
  </View>
)

export type LikeButtonProps<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR
> = PropsWithElementAttributes<{
  /** The activity received from stream that should be liked when pressing the LikeButton. */
  activity?: EnrichedActivity<UT, AT, CT, RT, CRT>
  /** The reaction received from stream that should be liked when pressing the LikeButton. */
  reaction?: EnrichedReaction<RT, CRT, UT>
  /** onAddReaction supports targetFeeds that you can use to send a notification to the post owner like ["notification:USER_ID"] */
  targetFeeds?: string[]
}>

export const LikeButton = <
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
  PT extends UR = UR
>({
  activity,
  reaction,
  targetFeeds,
  className,
  style,
}: LikeButtonProps<UT, AT, CT, RT, CRT>): JSX.Element => {
  const feed = useFeedContext<UT, AT, CT, RT, CRT, PT>()
  const { t } = useTranslation('communityFeed')

  useEffect(() => {
    if (!reaction && !activity) {
      console.warn('LikeButton requires an activity or reaction to work properly')
    }
    if (reaction && activity) {
      console.warn('LikeButton requires only one of the activity or reaction to work properly')
    }
  }, [])

  const isCheered = (reaction?.own_children ?? activity?.own_reactions)?.like?.length
  const cheersCount = (reaction?.children_counts ?? activity?.reaction_counts)?.like ?? 0

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames('raf-reaction-icon', className)}
      role="button"
      onClick={() => {
        if (reaction) {
          void feed.onToggleChildReaction('like', reaction as Reaction<RT>, {} as CRT, { targetFeeds })
        } else if (activity) {
          void feed.onToggleReaction('like', activity as Activity<AT>, {} as RT, { targetFeeds })
        }
      }}
      style={style}
    >
      <IconContainer>
        {isCheered ? (
          <Icon icon="cheered" style={{ width: 26, height: 30 }} />
        ) : (
          <Icon icon="cheer" style={{ width: 23, height: 19, marginLeft: 3 }} />
        )}
      </IconContainer>

      <p className="raf-reaction-icon__label">
        {activity && t`cheers`} {cheersCount}
      </p>
    </div>
  )
}
