import { OrderedMap } from 'immutable'
import { Observable } from 'rxjs'

import { VolunteerTimeOffsListDocument } from 'src/generated/graphql'
import { apolloClient } from 'src/entities/graphql'
import config from 'src/config'
import defaultApi, { ApiClient } from 'src/entities/api'

import { addMultipleAction } from './actions'
import VolunteerTimeOff from './model'
export default class VolunteerTimeOffApi {
  public static submit(data: VolunteerTimeOff): Observable<object> {
    const apiClient = new ApiClient({ endpoint: config.timeOffApiUrl })
    apiClient.defaultHeaders = defaultApi.defaultHeaders
    return apiClient.post('volunteer', {
      deed: data.deed.id,
      duration: data.duration.toString(),
      startingAt: data.deed.startingAt.toISOString(),
      endingAt: data.deed.endingAt.toISOString(),
    })
  }

  public static fetchVolunteerTimeOffs(): Observable<object> {
    return Observable.from(
      apolloClient.query({
        query: VolunteerTimeOffsListDocument,
      })
    ).map(({ data: { volunteerTimeOffs } }) =>
      addMultipleAction(
        OrderedMap(volunteerTimeOffs.map((vto: VolunteerTimeOff) => [vto.id, new VolunteerTimeOff(vto)]))
      )
    )
  }
}
