export const FETCH_DEED = 'FETCH_DEED'
export const FETCH_DEED_SUCCESS = 'FETCH_DEED_SUCCESS'
export const FETCH_DEED_FAILED = 'FETCH_DEED_FAILED'

export const LOAD_VOLUNTEER_TIMES = 'containers/Deed/LOAD_VOLUNTEER_TIMES'
export const LOAD_VOLUNTEER_TIMES_SUCCESS = 'containers/Deed/LOAD_VOLUNTEER_TIMES_SUCCESS'
export const LOAD_VOLUNTEER_TIMES_FAILED = 'containers/Deed/LOAD_VOLUNTEER_TIMES_FAILED'

export const REFRESH = 'containers/Deed/REFRESH'
export const REFRESH_SUCCESS = 'containers/Deed/REFRESH_SUCCESS'
export const REFRESH_FAILED = 'containers/Deed/REFRESH_FAILED'

export const APPLY_FOR_ROLE = 'containers/Deed/APPLY_FOR_ROLE'
export const APPLY_FOR_ROLE_SUCCESS = 'containers/Deed/APPLY_FOR_ROLE_SUCCESS'
export const APPLY_FOR_ROLE_FAILED = 'containers/Deed/APPLY_FOR_ROLE_FAILED'

export const COMPLETE_DEED = 'containers/Deed/COMPLETE_DEED'
export const COMPLETE_DEED_SUCCESS = 'containers/Deed/COMPLETE_DEED_SUCCESS'
export const COMPLETE_DEED_FAILED = 'containers/Deed/COMPLETE_DEED_FAILED'

export const APPLY_TO_EXTERNAL_DEED = 'containers/Deed/APPLY_TO_EXTERNAL_DEED'
