import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('deedInviteConfirm') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectDeed = createSelector(selectState, (state) => state.get('deed'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
