export const INIT = 'containers/CreateDeedForm/INIT'
export const INIT_SUCCESS = 'containers/CreateDeedForm/INIT_SUCCESS'
export const INIT_FAILED = 'containers/CreateDeedForm/INIT_FAILED'
export const SET_STEP = 'containers/CreateDeedForm/SET_STEP'
export const SUBMIT_DEED = 'containers/CreateDeedForm/SUBMIT_DEED'
export const SUBMIT_DEED_SUCCESS = 'containers/CreateDeedForm/SUBMIT_DEED_SUCCESS'
export const SUBMIT_DEED_FAILED = 'containers/CreateDeedForm/SUBMIT_DEED_FAILED'
export const DELETE_DEED = 'containers/CreateDeedForm/DELETE_DEED'
export const DELETE_DEED_SUCCESS = 'containers/CreateDeedForm/DELETE_DEED_SUCCESS'
export const DELETE_DEED_FAILED = 'containers/CreateDeedForm/DELETE_DEED_FAILED'
