import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'

const Box = ({
  children,
  half = false,
  first = false,
  style = {},
}: {
  children?: any
  half?: boolean
  first?: boolean
  style?: any
}): React.ReactElement => {
  const { metrics, colors } = useDeedTheme()

  return (
    <View
      style={{
        width: metrics.isMedium && half ? '50%' : '100%',
      }}
    >
      <View
        style={[
          {
            alignSelf: 'stretch',
            borderStyle: 'solid',
            borderColor: colors.borderGray,
            backgroundColor: 'white',
            borderTopWidth: metrics.isSmall && first ? 0 : 1,
            borderBottomWidth: 1,
            paddingTop: 25,
            paddingBottom: 5,
            paddingLeft: 25,
            paddingRight: 25,
            overflow: 'hidden',
            marginBottom: metrics.isSmall ? 18 : metrics.isMedium ? 12 : 30,
            marginLeft: metrics.isMedium ? 6 : 0,
            marginRight: metrics.isMedium ? 6 : 0,
          },
          metrics.isSmall
            ? {}
            : {
                flexShrink: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderRadius: 12,
              },
          style,
        ]}
      >
        {children}
      </View>
    </View>
  )
}

export default Box
