import React from 'react'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { ScrollView, Text } from 'src/retired/elements'
import { colors } from 'src/theme'
import { useHistory } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import IconButton from 'src/retired/shared/IconButton/index'

export const AffiliationScrollView = styled(ScrollView)`
  background-color: ${colors.gray03};
  border-radius: 20px;
  height: 100%;
  padding: 20px 30px;
  max-width: 1200px;
`

export const AffiliationHeader = ({ title }: { title: string }) => {
  const history = useHistory()
  const goBack = makeGoBack(history, '/profile')

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingBottom: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <IconButton onPress={goBack} icon="arrowLeftNew" size={40} />
      <Text size={16} center>
        {title.toLocaleUpperCase()}
      </Text>
      <View style={{ width: 50 }} />
    </View>
  )
}
