/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import DonationApi from 'src/entities/donation/api'
import Deed from 'src/entities/deed/model'
import { H5 } from 'src/retired/shared/Typography'
import config from 'src/config'
import { colors } from 'src/theme'
import DeviceInfo from 'src/utils/DeviceInfo'
import Platform from 'src/utils/Platform'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { registerBetterplaceDonationAction } from '../actions'

const applicationName = DeviceInfo.getApplicationName()

interface BetterplaceFormType {
  deed: Deed
}

const BetterplaceForm = ({ deed }: BetterplaceFormType): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const [iframeReady, setIframeReady] = useState(false)
  const [donationReference, setDonationReference] = useState(null)
  const { metrics } = useDeedTheme()

  const dispatch = useDispatch()

  const handleMessage = (event: MessageEvent): void => {
    if (event.origin === config.apiEndpoint) {
      if (event.data?.message) {
        dispatch(showErrorAction(event.data.message))
      }
      dispatch(registerBetterplaceDonationAction(JSON.parse(event.data)))
    }
  }

  useEffect(() => {
    DonationApi.createDonationReference('Betterplace', deed.id)
      .toPromise()
      .then((ref) => setDonationReference(ref.reference))
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [deed.id])

  const iframeRef = useRef()

  if (!donationReference) {
    return <ActivityIndicator color={colors.pink} size="large" />
  }

  const id = deed.externalId.substr(7)
  const type = id[0]
  const eventId = id.substr(1)
  const iframeUrl = `https://${config.betterplaceDomain}/en/donate/joindeed-com/${
    type === 'f' ? 'fundraising-events' : 'projects'
  }/${eventId}?client_reference=${donationReference}&donation_amount=50`

  const iframeStyle = metrics.isSmall
    ? {
        width: Math.min(metrics.screenWidth, 420),
        height: applicationName === 'DEED-Dev' ? 1800 : 1240,
        // top: -150,
        overflow: 'hidden',
        border: 'none',
        position: 'relative',
      }
    : {
        width: Platform.isIE ? 420 : 640,
        height: (applicationName === 'DEED-Dev' ? 1800 : 1240) + (Platform.isIE ? 100 : 0),
        left: 0,
        right: 0,
        // marginTop: -333,
        overflow: 'hidden',
        border: 'none',
        position: 'relative',
        borderRadius: 12,
      }

  return (
    <>
      <H5 weight="500" marginBottom={10} style={{ maxWidth: iframeStyle.width }}>
        {t`ifYouSelectShowName`}
      </H5>

      <div
        style={{
          overflow: 'hidden',
          marginLeft: metrics.isSmall ? -20 : 0,
          width: iframeStyle.width,
          borderRadius: 12,
          marginBottom: -5,
        }}
      >
        {!iframeReady && (
          <View style={{ marginBottom: 10 }}>
            <ActivityIndicator color={colors.pink} size="large" />
          </View>
        )}
        <iframe
          ref={iframeRef}
          style={iframeStyle}
          scrolling="no"
          onLoad={() => {
            setIframeReady(true)
          }}
          src={iframeUrl}
        />
      </div>
    </>
  )
}

export default BetterplaceForm
