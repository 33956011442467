import { DeedType } from 'src/generated/graphql'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  FOLLOW,
  FETCH_CAUSE_NONPROFITS,
  FETCH_CAUSE_NONPROFITS_SUCCESS,
  FETCH_CAUSE_NONPROFITS_FAILED,
  FOLLOW_SUCCESS,
  FOLLOW_FAILED,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED,
} from './constants'

export interface InitAction {
  type: typeof INIT
}
export const initAction = (): InitAction => ({
  type: INIT,
})

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS
}
export const initSuccessAction = (): InitSuccessAction => ({
  type: INIT_SUCCESS,
})

export interface InitFailedAction {
  type: typeof INIT_FAILED
  error: unknown
}
export const initFailedAction = (error: object): InitFailedAction => ({
  type: INIT_FAILED,
  error,
})

export interface FetchCauseNonprofitsAction {
  type: typeof FETCH_CAUSE_NONPROFITS
  id: string
  limit?: number
}
export const fetchCauseNonprofitsAction = (id: string, limit?: number): FetchCauseNonprofitsAction => ({
  type: FETCH_CAUSE_NONPROFITS,
  id,
  limit,
})

export interface FetchCauseNonprofitsSuccessAction {
  type: typeof FETCH_CAUSE_NONPROFITS_SUCCESS
}
export const fetchCauseNonprofitsSuccessAction = (): FetchCauseNonprofitsSuccessAction => ({
  type: FETCH_CAUSE_NONPROFITS_SUCCESS,
})

export interface FetchCauseNonprofitsFailedAction {
  type: typeof FETCH_CAUSE_NONPROFITS_FAILED
  error: unknown
}
export const fetchCauseNonprofitsFailedAction = (error: object): FetchCauseNonprofitsFailedAction => ({
  type: FETCH_CAUSE_NONPROFITS_FAILED,
  error,
})

export interface FetchCauseDeedsByDeedTypeAction {
  type: typeof FETCH_CAUSE_DEEDS_BY_DEED_TYPE
  deedType: DeedType
  id: string
}
export const fetchCauseDeedsByDeedTypeAction = (id: string, deedType: DeedType): FetchCauseDeedsByDeedTypeAction => ({
  type: FETCH_CAUSE_DEEDS_BY_DEED_TYPE,
  deedType,
  id,
})
export interface FetchCauseDeedsByDeedTypeSuccessAction {
  type: typeof FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS
  deedType: DeedType
}
export const fetchCauseDeedsByDeedTypeSuccessAction = (deedType: DeedType): FetchCauseDeedsByDeedTypeSuccessAction => ({
  type: FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS,
  deedType,
})

export interface FetchCauseDeedsByDeedTypeFailedAction {
  type: typeof FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED
  deedType: DeedType
  error: unknown
}
export const fetchCauseDeedsByDeedTypeFailedAction = (
  error: object,
  deedType: DeedType
): FetchCauseDeedsByDeedTypeFailedAction => ({
  type: FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED,
  deedType,
  error,
})

export interface FollowAction {
  type: string
  id: string
}
export const followAction = (id: string): FollowAction => ({
  type: FOLLOW,
  id,
})

export interface FollowSuccessAction {
  type: typeof FOLLOW_SUCCESS
}
export const followSuccessAction = () => ({
  type: FOLLOW_SUCCESS,
})

export interface FollowFailedAction {
  type: typeof FOLLOW_FAILED
  error: unknown
  errorCode?: number
}
export const followFailedAction = (error: object, errorCode?: number): FollowFailedAction => ({
  type: FOLLOW_FAILED,
  error,
  errorCode,
})

export type CauseActions =
  | InitAction
  | InitSuccessAction
  | InitFailedAction
  | FetchCauseNonprofitsAction
  | FetchCauseNonprofitsSuccessAction
  | FetchCauseNonprofitsFailedAction
  | FetchCauseDeedsByDeedTypeAction
  | FetchCauseDeedsByDeedTypeSuccessAction
  | FetchCauseDeedsByDeedTypeFailedAction
  | FollowAction
  | FollowSuccessAction
  | FollowFailedAction
