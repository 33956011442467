export interface LocationData {
  pathname: string
  key: string
  hash: string
  search: string
  state: string | undefined
}

export interface AnalyticsMockClient {
  load: (writeKey?: string) => any
  debug: (shouldDebug?: boolean) => any
  identify: (userId: string | null, traits?: object, options?: object, callback?: Function) => any
  track: (eventName: string, properties?: object, options?: object, callback?: Function) => any
  page: (page?: object, options?: object, callback?: Function) => any
  reset: (shouldReset?: boolean) => any
}

export interface AnalyticsClientConfiguration {
  traits?: object | null
  properties?: object | null
  context?: object | null
  options?: object | null
}

export const generateMockClient = (clientName = 'metrics', verbose = false): AnalyticsMockClient => ({
  load(writeKey?: string) {
    if (verbose) {
      console.debug(`mock-${clientName}:load: %o`, writeKey)
    }
  },
  debug(shouldDebug = false) {
    if (verbose) {
      console.debug(`mock-${clientName}:debug: %o`, shouldDebug)
    }
  },
  identify(userId: string | null, traits?: object, options?: object, callback?: Function) {
    if (verbose) {
      console.debug(`mock-${clientName}:identify: %o, traits: %o, options: %o`, userId, traits, options)
    }
    if (callback) {
      callback()
    }
  },
  track(eventName: string, properties?: object, options?: object, callback?: Function) {
    if (verbose) {
      console.debug(`mock-${clientName}:track: %o, properties: %o, options: %o`, eventName, properties, options)
    }
    if (callback) {
      callback()
    }
  },
  page(page?: object, options?: object, callback?: Function) {
    if (verbose) {
      console.debug(`mock-${clientName}:page: %o, options: %o`, page, options)
    }
    if (callback) {
      callback()
    }
  },
  reset(shouldReset = false) {
    if (verbose) {
      console.debug(`mock-${clientName}:reset: %o`, shouldReset)
    }
  },
})

// @NOTE-RT: 'cause `ts-jest` doesn't know about the `jest` mock
export declare const _mockedClient: AnalyticsMockClient

export default generateMockClient
