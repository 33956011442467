import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Picker as RNWPicker } from 'react-native-web'

import { colors } from 'src/theme'

export default class Picker extends PureComponent {
  static propTypes = {
    style: PropTypes.any,
  }

  render() {
    const { style, ...props } = this.props
    return (
      <RNWPicker
        {...props}
        style={[
          {
            fontFamily: 'GTWalsheimLC',
            fontSize: 15,
            color: colors.darkGray,
            marginLeft: -5,
            width: '100%',
          },
          style,
        ]}
      />
    )
  }
}
Picker.Item = ({ label, value }) => <option value={value}>{label}</option>
Picker.Item.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
}
