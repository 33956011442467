import { calculateFees, type CalculateFeesParams, FeeCoveredBy } from '@joindeed/calculate-fees'

import config from 'src/config'
import User from 'src/entities/user/model'
import DeviceInfo from 'src/utils/DeviceInfo'

// eslint-disable-next-line import/no-relative-packages
import pkg from '../../../../package.json'

const applicationName = DeviceInfo.getApplicationName()
const isSandbox = applicationName === 'DEED-Dev'

type Fees = ReturnType<typeof calculateFees>

const calculateTakenFees = ({
  user,
  calculateFeesParams,
}: {
  user?: User
  calculateFeesParams: CalculateFeesParams
}): Fees & { feeCalculationErrored: boolean } => {
  if (user?.feeCovered) {
    return {
      applicationFeeAmount: 0,
      providerFeeAmount: 0,
      totalFeeAmount: 0,
      feeCalculationErrored: false,
    }
  }

  try {
    const fees = calculateFees(calculateFeesParams)

    return {
      ...fees,
      feeCalculationErrored: false,
    }
  } catch (error) {
    if (isSandbox) {
      console.error(error) // eslint-disable-line no-console
    }
    return {
      providerFeeAmount: 0,
      applicationFeeAmount: 0,
      totalFeeAmount: 0,
      feeCalculationErrored: true,
    }
  }
}

interface FrontendFeesValue {
  feeCalculationErrored: boolean
  applicationFeeAmount: number
  totalFeeAmount: number
  providerFeeAmount: number
  grossAmount: number
}

export interface FrontendFees {
  id: 'frontend-estimation'
  value: FrontendFeesValue
  input: CalculateFeesParams
  date: Date
}

export type FeesWithHistory = {
  providerFeeAmount: number
  applicationFeeAmount: number
  totalFeeAmount: number
  grossAmount: number
  netAmount: number
  feeCalculationErrored: boolean
  feesHistoryItem: FrontendFees
}

export const calculateFeesWithHistory = ({
  amount,
  cardCountryCode,
  coverFeesParam,
  donationProvider,
  donationPaymentMethod,
  isRecurring,
  nonprofitCountryCode,
  user,
  applicationFeeAmountCap,
  applicationFeeSetting,
}: Required<Omit<CalculateFeesParams, 'isSandbox'>> & {
  user?: User
}): FeesWithHistory => {
  const calculateFeesParams: CalculateFeesParams = {
    amount,
    coverFeesParam,
    donationProvider,
    nonprofitCountryCode,
    isRecurring,
    donationPaymentMethod,
    cardCountryCode,
    feeCoveredBy: FeeCoveredBy.User,
    applicationFeeAmountCap,
    applicationFeeSetting,
    isSandbox: config.payPalEnv === 'SANDBOX',
  }

  const takenFees = calculateTakenFees({ calculateFeesParams, user })

  const grossAmount = coverFeesParam && amount ? amount + takenFees.totalFeeAmount : amount
  const netAmount = grossAmount - takenFees.totalFeeAmount

  const value = {
    ...takenFees,
    grossAmount,
    netAmount,
  }

  const versions = {
    'calculate-fees': pkg.dependencies['@joindeed/calculate-fees'],
    'paypal-public': pkg.dependencies['@joindeed/paypal-public'],
  }

  const feesHistoryItem = {
    id: 'frontend-estimation' as const,
    value,
    input: calculateFeesParams,
    date: new Date(),
    versions,
  }

  return {
    ...value,
    feesHistoryItem,
  }
}
