import { Record } from 'immutable'
import _ from 'lodash'

import Deed from '../deed/model'

import User from './model'

const properties = {
  type: 'DeedInvite',
  user: null,
  deed: null,
  date: null,
}

export default class Invite extends Record(properties, 'Invite') {
  public readonly type!: string

  public readonly user!: User

  public readonly deed!: Deed

  public readonly date!: Date

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      date: new Date(values.date),
      deed: values.deed && new Deed(values.deed),
      user: values.user && new User(values.user),
    })
  }
}
