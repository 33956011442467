import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ActivityStatus } from 'src/containers/modules/Actions/types'
import { ChipWithIconAndText } from 'src/components/Chip/ChipWithIconAndText'
import { ColorsProps } from 'src/theme/colors'

const getInfoChipData = (
  status: ActivityStatus | null,
  colors: ColorsProps,
  isActionExpired?: boolean,
  small?: boolean
) => {
  if (isActionExpired && status !== ActivityStatus.Completed) {
    return {
      iconName: 'disabledHourGlass',
      translationKey: 'noLongerActive',
      color: colors.gray,
      backgroundColor: colors.grayLighter,
    }
  }

  switch (status) {
    case ActivityStatus.Completed: {
      return {
        iconName: 'checkmark',
        translationKey: small ? 'completed' : 'actionCompleted',
        color: colors.greenDark,
        backgroundColor: colors.greenLighter,
      }
    }

    case ActivityStatus.Joined: {
      return {
        iconName: 'personRunning',
        translationKey: small ? 'inProgress' : 'actionInProgress',
        color: small ? '#01579B' : colors.white,
        backgroundColor: small ? '#e0f1f9' : '#0288D1',
      }
    }
  }
  return null
}

export const StatusChip = ({
  activityStatus,
  isActionExpired,
  small,
}: {
  activityStatus: ActivityStatus | null
  isActionExpired?: boolean
  small?: boolean
}) => {
  const { t } = useTranslation('actions')
  const { colors } = useDeedTheme()

  const infoChipData = getInfoChipData(activityStatus, colors, isActionExpired, small)

  return infoChipData ? (
    <ChipWithIconAndText
      small={small}
      iconName={infoChipData.iconName}
      label={t(infoChipData.translationKey)}
      hexColor={infoChipData.color}
      style={{
        backgroundColor: infoChipData.backgroundColor,
      }}
    />
  ) : null
}
