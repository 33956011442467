import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserBrand } from 'src/entities/user/selectors'
import { LinkButton } from 'src/retired/elements'
import Organization from 'src/entities/organization/model'
import { Platform } from 'src/utils'

const SingleSignOnButton = ({
  partner,
  onRedirect,
  style,
}: {
  partner: Organization
  onRedirect?: () => void
  style: any
}): React.ReactElement => {
  const { t } = useTranslation()

  const brand = useSelector(selectUserBrand)
  const isVanityDomainContext = Boolean(Platform.OS === 'web' && brand?.appVanityDomain?.match(window?.location.host))

  const ssoLink = isVanityDomainContext ? '/' : `/login/sso${partner ? `/${partner.id}` : ''}`

  return (
    <LinkButton to={ssoLink} onPress={onRedirect} style={style}>
      {partner?.name ? t('guestCheckoutScreen:logInAsEmployee') : t`common:logIn`}
    </LinkButton>
  )
}

export default SingleSignOnButton
