import { Map, fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'
import { CampaignActions } from 'src/entities/campaign/actions'

import { ADD, ADD_MULTIPLE, LOADED, CLEAR } from './constants'
import Campaign from './model'

export type CampaignMap = Map<string, Campaign>

export type CampaignState = TypedMap<{ campaigns: CampaignMap; loaded: boolean }>

export const initialState = fromJS({
  campaigns: Map(),
  loaded: false,
}) as CampaignState

export default (state: CampaignState = initialState, action: CampaignActions | { type: 'RESET' }): CampaignState => {
  switch (action.type) {
    case ADD:
      return state.set('campaigns', state.get('campaigns').set(action.campaign.id, action.campaign))

    case ADD_MULTIPLE: {
      const loadedCampaigns = state.get('campaigns')
      return state.set(
        'campaigns',
        loadedCampaigns.merge(
          action.campaigns.filter((campaign) => !!campaign?.id && !loadedCampaigns.has(campaign.id))
        )
      )
    }

    case LOADED:
      return state.set('loaded', true)

    case CLEAR:
    case 'RESET':
      return initialState

    default:
      return state
  }
}
