import 'whatwg-fetch'

import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'
import type {
  ApolloQueryResult,
  FetchResult,
  FieldFunctionOptions,
  NormalizedCacheObject,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client'

import config from 'src/config'
import { metricsLink } from 'src/metrics/metricsLink'
import { sentryLink } from 'src/utils/sentryLink'

import Api from './api'

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV !== 'production',
    link: ApolloLink.from([
      metricsLink,
      sentryLink,
      new MultiAPILink({
        endpoints: {
          graph: config.graphApiUrl,
          nonprofit: config.nonprofitApiUrl,
          payroll: config.payrollApiUrl,
          loggedTime: config.loggedTimeApiUrl,
          timeOff: config.timeOffApiUrl,
        },
        httpSuffix: '/',
        createHttpLink: () =>
          createHttpLink({
            fetch: async (uri: string, options: RequestInit): Promise<Response> =>
              fetch(uri, {
                ...options,
                headers: { ...Api.defaultHeaders, ...options.headers },
              }),
          }),
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            volunteerTimeOff: {
              read: (_, { args, toReference }: FieldFunctionOptions<{ id?: string }>) =>
                args?.id ? toReference({ __typename: 'VolunteerTimeOff', id: args.id }) : undefined,
            },
            actions: {
              read(existActions, { args, toReference, canRead }) {
                const id = args?.where?.id?.equals
                if (id) {
                  const refs = [toReference({ __typename: 'Action', id })]

                  if (refs.find((ref) => !canRead(ref))) {
                    return undefined
                  }

                  return refs
                }
                return existActions
              },
              keyArgs: ['where'],
              merge(existing = [], incoming = []) {
                return Array.from([
                  ...new Map([...existing, ...incoming].map((item: { __ref: string }) => [item.__ref, item])).values(),
                ])
              },
            },
            badges: {
              read(existBadges, { args, toReference, canRead }) {
                const id = args?.where?.id?.equals
                if (id) {
                  const refs = [toReference({ __typename: 'Badge', id })]

                  if (refs.find((ref) => !canRead(ref))) {
                    return undefined
                  }

                  return refs
                }
                return existBadges
              },
            },
          },
        },
      },
    }),
  })
}

/**
 * It's safe to treat Apollo Client as a singleton, as the auth taken is stored on Api.defaultHeaders and accessed on each new request via `createHttpLink`
 */
export const apolloClient = createApolloClient()

const interceptUnauthorized = <T, R extends ApolloQueryResult<T> | FetchResult<T>>(response: R): R => {
  if (response?.errors) {
    if (response?.errors.find((error) => error.message === 'Error: Unauthorized' || error.message === 'jwt expired')) {
      if (Api.authenticationFailed) {
        Api.authenticationFailed()
      }
      throw new Error('Not Authorized!')
    }
    throw new Error(JSON.stringify(response.errors))
  }
  return response
}

export const query = async <T, Tvariables = OperationVariables>(
  document: TypedDocumentNode<T, Tvariables>,
  variables?: Tvariables
): Promise<ApolloQueryResult<T>> =>
  apolloClient.query<T, Tvariables>({ query: document, variables }).then(interceptUnauthorized)

export const mutate = async <T, Tvariables = OperationVariables>(
  document: TypedDocumentNode<Tvariables, T>,
  variables?: Tvariables,
  refetchQueries?: () => Array<any>
): Promise<FetchResult<T>> =>
  apolloClient
    .mutate<T, Tvariables>({ mutation: document, variables, ...(refetchQueries ? { refetchQueries } : {}) })
    .then(interceptUnauthorized)
