import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { View } from 'react-primitives'

import { historyStack, useHistory } from 'src/navigation'
import { H3, Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { Action, Screen, LinkText, Image } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import IconButton from 'src/retired/shared/IconButton'

import { StyledContent, StyledHeader } from '../Profile.styled'

const EditVolunteerTimeOffSuccess = () => {
  const { t } = useTranslation('editVolunteerTimeOffSuccess')
  const history = useHistory()
  const { colors, images, metrics } = useDeedTheme()
  const dismissScreenPath =
    historyStack.reverse().find((path: string) => !path.includes('volunteer-time-off-hours')) || ''

  return (
    <Screen fixed>
      <Screen middle action padding safeArea={false}>
        <StyledContent style={{ paddingTop: 0 }}>
          <StyledHeader>
            <Image
              source={images.deedLogoPurple}
              style={{ width: 40, height: 35, marginLeft: 'auto', marginRight: 'auto', marginBottom: 40 }}
            />
            <H3 center>{t`thanksForSubmittingYourTimeOff`}</H3>
          </StyledHeader>
          <Body2 weight="normal" colour="grayMediumDark" marginTop={8} lineHeight={25} center style={{ maxWidth: 500 }}>
            <Trans
              t={t}
              i18nKey="yourVTOWillBeReflected"
              components={{
                Link: (
                  <LinkText
                    size={14}
                    lineHeight={25}
                    color="grayMediumDark"
                    underline
                    to="/profile/volunteer-time-off-hours"
                  />
                ),
              }}
            />
          </Body2>
        </StyledContent>

        <Action style={{ paddingLeft: 25, paddingRight: 25 }}>
          <Button
            style={{
              borderRadius: 100,
              backgroundColor: colors.brandColor,
              alignSelf: 'flex-start',
              paddingLeft: 15,
              paddingRight: 15,
              height: metrics.isSmall ? 26 : 30,
              justifyContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            palette="primary"
            onPress={() => history.replace(dismissScreenPath)}
          >
            {t`common:Ok`}
          </Button>
        </Action>
        <View style={{ position: 'absolute', top: 20, left: 20 }}>
          <IconButton icon="closeNew" onPress={() => history.replace(dismissScreenPath)} size={30} />
        </View>
      </Screen>
    </Screen>
  )
}

export default EditVolunteerTimeOffSuccess
