import validator from 'validator'
import i18n from 'i18next'

import phoneNumberCountryList from './phoneNumberCountryList'

export const required = (value) => (value && value !== '' ? null : i18n.t('common:required'))

export const isAddress = (value) =>
  !value || value === '' || /(.+, .+, .+, .*)/i.test(value) ? null : i18n.t('validators:invalidAddress')

export const isURL = (value) =>
  !value || value === '' || validator.isURL(value) ? null : i18n.t('validators:invalidUrl')

export const isEmail = (value) =>
  !value || value === '' || validator.isEmail(value) ? null : i18n.t('validators:invalidEmailAddress')

export const isNumber = (value) => (Number.isNaN(value) ? i18n.t('validators:mustBeANumber') : null)

export const isRequired = (value) => (value ? null : i18n.t('common:required'))

export const minLength = (len) => (value) =>
  (value && (value.size || value.length) >= len) || !value
    ? null
    : typeof value === 'string'
    ? i18n.t('validators:pleaseTypeAtLeastCountCharacters', { count: len })
    : i18n.t('validators:pleaseSelectAtLeastCountItems', { count: len })

export const maxLength = (max) => (value) =>
  value && (value.size || value.length) > max
    ? typeof value === 'string'
      ? i18n.t('validators:charactersLongOrLess')
      : i18n.t('validators:itemsOrLess')
    : null

export const isPhone = (value) =>
  value &&
  validator.isMobilePhone(
    value.replace(/[^0-9+]/g, ''),
    phoneNumberCountryList.map((country) => country.locale)
  )
    ? null
    : i18n.t('validators:invalidPhoneNumber')

export const notEmpty = (value) => {
  if (!value || (value.trim && value.trim().length === 0) || value.length === 0) {
    return i18n.t('common:required')
  }

  return null
}

export const balanceRemaining = (values, user) =>
  Number(values.hours * 60) + Number(values.minutes) > user?.volunteerTimeOffSummary.balance
    ? i18n.t('validators:youDoNotHaveEnoughVto')
    : null

export const maxRequestLength = (values, deed) =>
  Number(values.hours * 60) + Number(values.minutes) > deed?.duration ? i18n.t('validators:maxRequestLength') : null

export const isEin = (value) => (/^\d{2}-\d{7}$/.test(value) || value === '' ? null : i18n.t('validators:invalidEin'))

export default {
  required,
  isAddress,
  isURL,
  isEmail,
  isNumber,
  isRequired,
  minLength,
  maxLength,
  isPhone,
  notEmpty,
  balanceRemaining,
  maxRequestLength,
  isEin,
}
