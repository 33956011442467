import React from 'react'
import { View } from 'react-primitives'

import Icon from 'src/retired/shared/Icon'
import { Text } from 'src/retired/shared/Typography'

interface Props {
  text: string
  icon: string
  bgColor?: string
  textColor?: string
}

export const StatusMessage = ({ text, bgColor, textColor, icon }: Props): JSX.Element => (
  <View
    style={{
      marginBottom: '10px',
      backgroundColor: bgColor,
      padding: 10,
      width: '240px',
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <View style={{ marginRight: '5px' }}>
      <Icon icon={icon} />
    </View>
    <View style={{ flex: 1 }}>
      <Text colour={textColor} fontSize={12}>
        {text}
      </Text>
    </View>
  </View>
)
