import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('alerts') || initialState

export const selectTitle = createSelector(selectState, (state) => state.get('title'))

export const selectMessage = createSelector(selectState, (state) => state.get('message'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectOpen = createSelector(selectState, (state) => state.get('open'))

export const selectReload = createSelector(selectState, (state) => state.get('reload'))
