import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Loading, Touchable, Spacing, Screen } from 'src/retired/elements'
import { Body, Title, Label, H5 } from 'src/retired/shared/Typography'
import Hoverable from 'src/retired/shared/Hoverable'
import { injectReducer, injectEpics } from 'src/utils'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectUserLocations, selectCurrentUser } from 'src/entities/user/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { Link, Redirect } from 'src/navigation'
import { distance } from 'src/utils/Geolocation'
import { selectDeedId } from 'src/containers/screens/Deed/Authenticate/selectors'
import Onboarding, { getOnboardingStepCount } from 'src/retired/blocks/Onboarding'

import { selectSelectedLocation, selectLoading, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const LocationsItemsList = styled.View`
  align-self: stretch;
  flex-wrap: wrap;
  flex-direction: ${({ theme }) => (theme.metrics.isSmall ? 'column' : 'row')};
  align-items: ${({ theme }) => (theme.metrics.isSmall ? 'center' : 'flex-start')};
  justify-content: space-between;
`

const LocationItem = styled.View`
  justify-content: ${({ theme }) => (theme.metrics.isSmall ? 'center' : 'flex-start')};
  margin-bottom: 12px;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.colors.brandColor : theme.colors.gray)};
  border-radius: 30px;
  padding: 20px 12px;
  background-color: ${({ selected, isHovered, theme }) =>
    selected ? theme.colors.brandColor : isHovered ? theme.colors.gray06 : 'transparent'};
`

const LocationsList = ({ locations, selectedId, onSelect }) => {
  const { metrics } = useDeedTheme()

  return (
    <LocationsItemsList>
      {locations
        .toArray()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((location) => {
          const selected = selectedId === location.id
          return (
            <Touchable
              activeOpacity={0.2}
              onPress={() => onSelect(location.id)}
              key={location.id}
              style={{ flexBasis: metrics.isSmall ? 'auto' : '48%', width: '100%' }}
            >
              <Hoverable>
                {(isHovered) => (
                  <LocationItem selected={selected} isHovered={isHovered}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginLeft: 12,
                      }}
                    >
                      <Body colour={selected ? 'white' : 'black'} style={{ whiteSpace: 'nowrap' }}>
                        {location.name}
                      </Body>
                      <Spacing marginTop={4} marginRight={4} />
                      <Body
                        colour={selected ? 'white' : 'gray05'}
                        style={{ opacity: selected ? 0.6 : 1 }}
                        activeOpacity={0.6}
                        numberOfLines={1}
                      >
                        {location.name === location.country
                          ? ''
                          : `${location.country}${location.state ? `, ${location.state}` : ''}`}
                      </Body>
                    </View>
                  </LocationItem>
                )}
              </Hoverable>
            </Touchable>
          )
        })}
    </LocationsItemsList>
  )
}

export class RegisterLocation extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    const {
      t,
      locations,
      selectedLocation,
      user,
      isAuthenticated,
      loading,
      submitting,
      actions,
      location,
      authenticateDeed,
    } = this.props
    if (loading || submitting || (isAuthenticated && !user)) {
      return <Loading />
    }

    const path = location.pathname
    const onboarding = path === '/register/location'

    const hasDepartments = user?.organization?.departments?.length > 0
    const redirectUrl = hasDepartments
      ? '/register/company/department'
      : !user?.interests?.length
      ? '/register/interests'
      : '/'

    if (user?.getSetting('disableEmployeeLocationUpdate')) {
      return <Redirect to={onboarding ? redirectUrl : '/profile'} />
    }

    if (onboarding && user?.location) {
      return <Redirect to={redirectUrl} />
    }

    if (authenticateDeed) {
      const authenticateDeedLocation = locations.find(
        (locationObject) =>
          distance(
            locationObject.coordinates[0],
            locationObject.coordinates[1],
            authenticateDeed.locationLatLng.coordinates[1],
            authenticateDeed.locationLatLng.coordinates[0]
          ) < 20
      )
      if (authenticateDeedLocation) {
        actions.selectLocationAction(authenticateDeedLocation.id, path, true)
      }
    }

    const availableRegions = locations
      .map((availableLocation) => availableLocation.region)
      .filter((region) => region)
      .toSet()
      .toArray()

    if (locations.find((availableLocation) => !availableLocation.region)) {
      availableRegions.push('Other')
    }

    const getSegregatedLocationsByRegions = () =>
      availableRegions
        .sort((a, b) => a.localeCompare(b))
        .map((region) => ({
          name: region,
          locations: locations.filter((availableLocation) =>
            region === 'Other' ? !availableLocation.region : availableLocation.region === region
          ),
        }))

    const handleSelectLocation = (locationId) => {
      actions.selectLocationAction(locationId, path)
    }

    return (
      <Screen fixed>
        <Onboarding
          page={!onboarding ? undefined : getOnboardingStepCount(user).locationStep}
          confirmHandler={() => actions.submitAction(redirectUrl)}
          confirmDisabled={!selectedLocation}
          confirmSubmitting={submitting}
          title={t`pleaseSelectYourLocation`}
          hideConfirm={!onboarding}
        >
          <View
            style={{
              alignItems: 'center',
              minHeight: onboarding ? 432 : 0,
              marginTop: onboarding ? 0 : -18,
              paddingTop: onboarding ? 30 : 0,
            }}
          >
            {onboarding && (
              <>
                <H5>{t`pleaseSelectYourLocation`}</H5>
                <Body colour="grayMediumDark" marginTop={8} center>
                  {t`youCanChangeYourLocationAnyTime`}
                </Body>
              </>
            )}
            <Spacing marginBottom={48} />
            {availableRegions.length > 1 ? (
              getSegregatedLocationsByRegions().map((region) => (
                <View key={region.name} style={{ marginBottom: 16, width: '100%' }}>
                  <Body weight="bold" marginBottom={8}>
                    {region.name}
                  </Body>
                  <LocationsList
                    locations={region.locations}
                    selectedId={selectedLocation}
                    onSelect={handleSelectLocation}
                  />
                </View>
              ))
            ) : (
              <LocationsList locations={locations} selectedId={selectedLocation} onSelect={handleSelectLocation} />
            )}
          </View>
          {user?.organization ? (
            <Spacing paddingBottom={25} />
          ) : (
            <Spacing paddingHorizontal={25} paddingTop={20} paddingBottom={35}>
              <Title>{t`notSeeingYourCity`}</Title>
              <Spacing paddingTop={15} />
              <Link to="/help-us-launch-near-you">
                <Label underline>{t`helpUsLaunchNearYou`}</Label>
              </Link>
            </Spacing>
          )}
        </Onboarding>
      </Screen>
    )
  }
}
RegisterLocation.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  locations: PropTypes.object,
  actions: PropTypes.object,
  authenticateDeed: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  location: PropTypes.object,
}

const withConnect = connect(
  (state) => ({
    locations: selectUserLocations(state),
    loading: selectLoading(state),
    submitting: selectSubmitting(state),
    user: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state),
    selectedLocation: selectSelectedLocation(state),
    error: selectError(state),
    authenticateDeed: selectDeedById(state, selectDeedId(state)),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerLocation', reducer })
const withEpics = injectEpics({ key: 'registerLocation', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('locationRegister'))(RegisterLocation)
