import Deed from 'src/entities/deed/model'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SET_STEP,
  SUBMIT_DEED,
  SUBMIT_DEED_SUCCESS,
  SUBMIT_DEED_FAILED,
  DELETE_DEED,
  DELETE_DEED_SUCCESS,
  DELETE_DEED_FAILED,
} from './constants'

export interface Init {
  type: typeof INIT
  id: string
}
export const initAction = (id: string): Init => ({
  type: INIT,
  id,
})

export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})

export const initFailedAction = (error: Error) => ({
  type: INIT_FAILED,
  error,
})

export const setStepAction = (step: number) => ({
  type: SET_STEP,
  step,
})

export interface SubmitDeed {
  type: typeof SUBMIT_DEED
  data: object
  id?: string
}
export const submitDeedAction = (data: object, id?: string): SubmitDeed => ({
  type: SUBMIT_DEED,
  data,
  id,
})

export const submitDeedSuccessAction = (submittedDeed: Deed) => ({
  type: SUBMIT_DEED_SUCCESS,
  submittedDeed,
})

export const submitDeedFailedAction = (error: object) => ({
  type: SUBMIT_DEED_FAILED,
  error,
})

export interface DeleteDeed {
  type: typeof DELETE_DEED
  id: string
}
export const deleteDeedAction = (id: string): DeleteDeed => ({
  type: DELETE_DEED,
  id,
})

export const deleteDeedSuccessAction = () => ({
  type: DELETE_DEED_SUCCESS,
})

export const deleteDeedFailedAction = (error: object) => ({
  type: DELETE_DEED_FAILED,
  error,
})
