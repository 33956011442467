import { View } from 'react-primitives'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Box } from 'src/containers/screens/Deed/components/MapBlock'
import { Avatar, Row, Spacing, Text } from 'src/retired/elements'
import { Link } from 'src/navigation'
import Chip from 'src/retired/shared/Chip'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import Deed from 'src/entities/deed/model'
import { Body1, Body2, H5, Label } from 'src/retired/shared/Typography'
import { formatFullName } from 'src/utils/formatFullName'

export const CampaignDonationsBlock = ({ deed }: { deed: Deed }): JSX.Element | null => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()
  if (deed.type !== 'Campaign') {
    return null
  }
  if (deed.donations.size < 1) {
    return null
  }
  return (
    <Box half>
      <View style={{ alignSelf: 'stretch' }}>
        <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
          <H5 weight="500">
            {t`donations`}
            {deed.donationsCount > 0 && ` (${deed.donationsCount})`}
          </H5>
          <Link to={`/deeds/${deed.id}/donations`}>
            <Body2 weight="500" colour={colors.brandColor} style={{ textAlign: 'right' }}>
              {t`seeMore`}
            </Body2>
          </Link>
        </Row>
        {deed.donations
          .slice(0, 5)
          .toArray()
          .map((donation, index) => {
            const donor = donation.user
            return (
              <View
                key={donation.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  borderTopWidth: index === 0 ? 0 : 1,
                  borderTopStyle: 'solid',
                  borderTopColor: colors.gray04,
                  paddingTop: 18,
                  paddingBottom: 18,
                }}
              >
                <Avatar
                  user={donation.privacy !== 'Anonymous' ? donor : undefined}
                  size={32}
                  spacing={0}
                  style={{ marginBottom: 10 }}
                  link={false}
                />
                <View
                  style={{
                    flex: 1,
                    paddingLeft: 15,
                    paddingRight: 5,
                    justifyContent: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Body1 weight="500" numberOfLines={1} marginBottom={4}>
                    {donation.privacy === 'Anonymous' || !donor ? 'Anonymous' : formatFullName(donor.fullName)}
                  </Body1>
                  <Label numberOfLines={1} colour={colors.grayDark}>
                    {t('date:dayMonthShort', { date: donation.date })}
                  </Label>
                </View>
                {donation.matchedAmount ? (
                  <Spacing marginRight={8}>
                    <Chip type="square" backgroundColor="yellow" textSize={12}>
                      {t`matched`}
                    </Chip>
                  </Spacing>
                ) : null}

                {donation.privacy === 'Public' && donation.amount ? (
                  <View style={{ flexGrow: 0 }}>
                    <Text size={12} right bold>
                      <CurrencyFormat amountCurrencies={donation.amountCurrencies} />
                    </Text>
                  </View>
                ) : null}
              </View>
            )
          })}
      </View>
    </Box>
  )
}
