import { createSelector } from 'reselect'
import i18n from 'i18next'
import { Map } from 'immutable'

import { selectCurrentUser } from 'src/entities/user/selectors'
import type { State } from 'src/reducers'
import truthy from 'src/utils/truthy'

import Cause from './model'
import type { CauseMap } from './reducer'

const sortFunction = (a: Cause, b: Cause): number => b.sorting - a.sorting || a.name.localeCompare(b.name)
const selectCauseState = (state: State) => state.get('entities').cause.causes

export const selectCauses = createSelector(selectCauseState, (causes) => causes.sort(sortFunction))

export const selectCauseById = createSelector(
  selectCauseState,
  (_state: State, causeId: string) => causeId,
  (causes, causeId) => causes.get(causeId)
)

export const selectPillars = createSelector(selectCauseState, (causes) =>
  causes.filter((cause: Cause) => cause.type === 'Pillar').sort(sortFunction)
)

export const selectERGs = createSelector(
  selectCauseState,
  selectCurrentUser,
  (causes, user): CauseMap =>
    user?.hasFeature('communities')
      ? (new Map() as CauseMap)
      : causes.filter((cause: Cause) => cause.type === 'ERG').sort(sortFunction)
)

export const selectCategories = createSelector(selectCauseState, (causes) =>
  causes.filter((cause: Cause) => cause.type === 'Category').sort(sortFunction)
)

export const selectSDGs = createSelector(selectCauseState, (causes) =>
  causes.filter((cause: Cause) => cause.type === 'SDG').sort((a, b) => a.get('number') - b.get('number'))
)

export const selectCausesLoaded = (state: State) => state.get('entities').cause.causesLoaded

const causeToOption = (cause: Cause) => ({ value: cause.get('id'), title: cause.label() })

export const selectGroupedCauses = createSelector(
  selectPillars,
  selectERGs,
  selectCategories,
  selectSDGs,
  selectCurrentUser,
  (pillars, ERGs, categories, SDGs, user) =>
    [
      pillars.size > 0 && {
        label: `${user?.organization?.name} ${i18n.t('causes:causeAreas')}`.trim(),
        options: pillars.toArray().map(causeToOption),
      },
      ERGs.size > 0 && {
        label: `${user?.organization?.name} ${i18n.t('causes:employeeResourceGroups')}`.trim(),
        options: ERGs.toArray().map(causeToOption),
      },
      categories.size > 0 && {
        label: i18n.t('causesScreen:causeAreas'),
        options: categories.toArray().map(causeToOption),
      },
      SDGs.size > 0 && {
        label: i18n.t('causesScreen:unitedNationsSDGs'),
        options: SDGs.toArray().map(causeToOption),
      },
    ].filter(truthy)
)

export const selectFlatCauses = createSelector(
  selectPillars,
  selectERGs,
  selectSDGs,
  selectCategories,
  (pillars, ERGs, SDGs, categories) => categories.concat(pillars).concat(ERGs).concat(SDGs) as CauseMap
)

// @TODO-ZM: once we have a actual API call (deed-service?), call it and return Cause[] models instead
export const selectSuggestedCauses = createSelector(selectCauseState, (causes) => ({
  trendingCauses: causes.filter((cause) =>
    [
      '59e7cbc792b6a2fc1ac9e5b2', // Environment
      '59e7cbc792b6a2fc1ac9e5bf', // Women
      '59e7cbc792b6a2fc1ac9e5bb', // LGBTQ
      '5f2c7483c7e38fa4ad11b276', // Disaster Recovery
      '59e7cbc792b6a2fc1ac9e5b1', // Animals
    ].includes(cause.id)
  ),
  topCauses: causes.filter((cause) =>
    [
      '5d371256e43af8b54ae4f2e9', // Sustainability
      '59e7cbc792b6a2fc1ac9e5ba', // Health
      '59e7cbc792b6a2fc1ac9e5b3', // Seniors
      '59e7cbc792b6a2fc1ac9e5b4', // Children
      '59e7cbc792b6a2fc1ac9e5b8', // Mental Health
      '59e7cbc792b6a2fc1ac9e5b7', // Arts
    ].includes(cause.id)
  ),
}))
