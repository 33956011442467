import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

export const ResultsWrapper = styled(View)(({ theme }: { theme: EmotionTheme }) => ({
  flex: 1,
  height: '100%',
  width: '100%',
  paddingBottom: 30,
  paddingTop: theme.metrics.isSmall ? 0 : 24,
  paddingLeft: theme.metrics.isLarge ? 20 : 0,
}))
