import { ACCEPT, DECLINE, SHOW_COOKIE_CONSENT } from './constants'

export const acceptAction = (event, shareData, marketingEmails) => ({
  type: ACCEPT,
  event,
  shareData,
  marketingEmails,
})

export const declineAction = (event, shareData, marketingEmails) => ({
  type: DECLINE,
  event,
  shareData,
  marketingEmails,
})

export const showCookieConsentAction = () => ({
  type: SHOW_COOKIE_CONSENT,
})
