import React from 'react'
import Loadable from 'react-loadable'

import Loading from 'src/retired/elements/Loading'
import SplashScreen from 'src/utils/SplashScreen'

let loadedOnce = false
const loading = ({ pastDelay }) => (pastDelay ? <Loading fill /> : null)
const createLoadable = (loader) =>
  Loadable({
    loader,
    loading,
    render(loaded, props) {
      const Component = loaded.default
      if (!loadedOnce && SplashScreen) {
        loadedOnce = true
        setTimeout(() => SplashScreen.hide(), 300)
      }
      return <Component {...props} />
    },
  })
export default createLoadable
