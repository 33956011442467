import React from 'react'

import { TabType, useSearchParams } from './utils'
import { DonateFiltersContainer } from './DonateTab/Filters/DonateFiltersContainer'
import { VolunteerFiltersContainer } from './VolunteerTab/Filters/VolunteerFiltersContainer'

export const FiltersContainer = ({ close }: { close?: () => void }) => {
  const { activeTab } = useSearchParams()

  if (activeTab === TabType.volunteer) {
    return <VolunteerFiltersContainer close={close} />
  }
  /* if (activeTab === TabType.event) {
    return <EventFiltersContainer close={close} />
  } */

  return <DonateFiltersContainer close={close} />
}
