import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Info from '@mui/icons-material/InfoOutlined'
import Paper, { PaperProps } from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { useTranslation, Trans, type TFunction } from 'react-i18next'

import { Body2 } from 'src/retired/shared/Typography'
import Chip from 'src/retired/shared/Chip'
import {
  currencies,
  type CurrencyCode,
  CurrencyFormat,
  type CurrencyFormatProps,
  type CurrencyRates,
} from 'src/containers/modules/CurrencyFormat'
import CurrencyInput from 'src/components/CurrencyInput'
import { CloseButton } from 'src/components/Modal/ModalWithCloseButton/ContentWrapper'
import type { MatchData } from 'src/containers/screens/Donate/match'
import roundNumber from 'src/utils/roundNumber'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { currencyConvert } from 'src/containers/screens/Donate/convert'
import { roundedInputStyleObject } from 'src/components/Input/roundedInputStyles'
import { formatAmount, formatNumber } from 'src/utils/format'

const RadioDiv = ({
  checked,
  select,
  label,
  children,
  tooltip,
  ...rest
}: PaperProps & { checked: boolean; select: () => void; label: React.ReactNode; tooltip: string }) => (
  <Paper
    sx={{
      color: (theme) => (checked ? theme.palette.primary.main : undefined),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      position: 'relative',
      gap: 3,
      py: 2,
      px: 5,
      cursor: checked ? undefined : 'pointer',
      backgroundColor: (theme: Theme) => (checked ? theme.palette.primary[50] : undefined),
      backgroundOpacity: checked ? '0.2' : undefined,
      outline: checked ? (theme) => `1px solid ${theme.palette.primary.main}` : undefined,
      '&:focus': { outline: (theme) => `2px solid ${theme.palette.primary.main}` },
    }}
    aria-checked={checked}
    onClick={checked ? undefined : select}
    onKeyDown={(e) => !checked && (e.key === 'Enter' || e.key === ' ') && select()}
    role="radio"
    tabIndex={0}
    variant="outlined"
    {...rest}
  >
    <Box sx={{ flex: 1, position: 'relative', display: 'flex', alignItems: 'center', width: '100%', mr: -3 }}>
      <Radio tabIndex={-1} checked={checked} sx={{ position: 'absolute', right: '100%', mr: -0.25 }} />
      <Tooltip style={{ position: 'absolute', left: '100%' }} title={tooltip}>
        <Info />
      </Tooltip>
      <div style={{ width: '100%', fontWeight: checked ? 500 : undefined }}>{label}</div>
    </Box>
    {children}
  </Paper>
)

const matchAmountError = (
  num: number,
  max: number,
  t: TFunction<'donateScreen'>,
  currency: CurrencyCode
): string | undefined => {
  const currencySymbol = currencies[currency].symbol
  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  if (Number.isNaN(num)) {
    return t('desiredMatchInvalid') as string
  }
  if (num < 0) {
    return t('desiredMatchTooLow', { currencySymbol, minAmount: formatAmount(0) }) as string
  }
  if (max < num) {
    return t('desiredMatchTooHigh', { currencySymbol, maxAmount: formatAmount(max) }) as string
  }
  /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion */
  return undefined
}

// @NOTE-AC: This doesn't work in translations when inlined somehow
const CurrencyFormatBold = (props: CurrencyFormatProps) => (
  <span style={{ fontWeight: 500 }}>
    <CurrencyFormat {...props} />
  </span>
)

const InputAmountText = ({
  chip,
  value,
  setValue,
  currencyCode,
}: {
  chip: React.ReactNode
  value: number
  setValue: (v: number) => void
  currencyCode: CurrencyCode
}) => (
  <Stack direction="row-reverse" flexWrap="wrap" gap={1} alignItems="center" justifyContent="flex-end">
    {chip}
    <Box sx={{ flex: '0 1 170px' }}>
      <CurrencyInput
        decimalsLimit={0}
        name="desired-match-input"
        value={String(value)}
        onValueChange={(v) => setValue(Number.parseFloat(v || '0'))}
        currencySymbol={currencies?.[currencyCode]?.symbol || ''}
        maxLength={8}
        style={{ ...roundedInputStyleObject, color: '#000', margin: 0, width: '100%' }}
        autoFocus
      />
    </Box>
  </Stack>
)

interface DesiredMatchDialogProps {
  matchData: Omit<MatchData, 'matchingApplicableForPaymentMethod'>
  netAmount: number
  customCurrency: CurrencyCode
  baseCurrency: CurrencyCode
  visible: boolean
  onClose: () => void
  onDesiredMatchedAmountChange: (amount: number) => void
  usdRates: CurrencyRates
}
export const DesiredMatchDialog = ({
  matchData,
  netAmount,
  baseCurrency,
  customCurrency,
  visible,
  onClose,
  usdRates,
  onDesiredMatchedAmountChange = () => {},
}: DesiredMatchDialogProps) => {
  const { t } = useTranslation('donateScreen')
  const { capBalance, matchedAmountMaximum, matchingPercentage } = matchData
  const [desiredAmount, setDesiredAmount] = useState<undefined | number>(matchData.desiredMatchAmount)
  const hasCustomAmount = desiredAmount !== undefined

  const _onDesiredMatchedAmountChange = () => {
    const newValue = hasCustomAmount ? roundNumber(desiredAmount) : undefined
    if (onDesiredMatchedAmountChange) {
      onDesiredMatchedAmountChange.call(this, newValue)
    }
    matchData?.setDesiredMatchAmount(newValue)
    onClose.call(this)
  }

  useEffect(() => {
    setDesiredAmount(matchData.desiredMatchAmount)
  }, [matchData.desiredMatchAmount])

  const maxAmountInDisplayCurrency = currencyConvert({
    amount: matchedAmountMaximum,
    usdRates,
    targetCurrency: customCurrency,
    sourceCurrency: baseCurrency,
  })

  const desiredAmountInDisplayCurrency =
    desiredAmount &&
    currencyConvert({
      amount: desiredAmount,
      usdRates,
      targetCurrency: customCurrency,
      sourceCurrency: baseCurrency,
    })

  const setUsingCustomCurrency = (v: number) =>
    setDesiredAmount(
      Math.min(
        matchedAmountMaximum,
        currencyConvert({ amount: v, usdRates, targetCurrency: baseCurrency, sourceCurrency: customCurrency })
      )
    )

  const error =
    !!desiredAmountInDisplayCurrency &&
    matchAmountError(desiredAmountInDisplayCurrency, maxAmountInDisplayCurrency, t, customCurrency)

  const { colors } = useDeedTheme()
  const marks = [
    {
      value: 0,
      label: (
        <div style={{ transform: 'translateX(50%)', color: colors.brandColor, opacity: 0.8 }}>
          <CurrencyFormat baseCurrency={baseCurrency} amount={0} customCurrency={customCurrency} />
        </div>
      ),
    },
    {
      value: matchedAmountMaximum,
      label: (
        <div style={{ transform: 'translateX(-50%)', color: colors.brandColor, opacity: 0.8 }}>
          <CurrencyFormat baseCurrency={baseCurrency} amount={matchedAmountMaximum} customCurrency={customCurrency} />
        </div>
      ),
    },
  ]

  const capBalanceInBaseCurrency =
    (capBalance?.currencyCode &&
      capBalance?.balance &&
      currencyConvert({
        amount: capBalance.balance,
        usdRates,
        targetCurrency: baseCurrency,
        sourceCurrency: capBalance.currencyCode,
      })) ||
    undefined
  const matchableAmount = desiredAmount ?? matchedAmountMaximum
  const matchingRatio = (matchingPercentage || 100) / 100
  const adjustedMatchableAmount = capBalance?.contributionBased ? matchableAmount / matchingRatio : matchableAmount

  const futureRemainingAmount = capBalanceInBaseCurrency && capBalanceInBaseCurrency - adjustedMatchableAmount
  const futureRemaining = futureRemainingAmount !== undefined && (
    <Box>
      <Typography variant="body2">
        <Trans
          t={t}
          i18nKey="yourRemainingMatchingBudgetWillBe"
          components={{
            Amount: (
              <CurrencyFormatBold
                baseCurrency={baseCurrency}
                amount={futureRemainingAmount}
                customCurrency={customCurrency}
              />
            ),
          }}
        />
      </Typography>
    </Box>
  )

  const chip =
    matchData.matchedAmountMaximum > 0 ? (
      <Chip type="square" backgroundColor="yellow" textWeight="500">
        {`${t('matchFactor', {
          factor: `${formatNumber((desiredAmount ?? matchData.matchedAmountMaximum) / netAmount)}`,
        })}`.toUpperCase()}
      </Chip>
    ) : undefined
  return (
    <Dialog open={visible} fullWidth maxWidth="md">
      <DialogTitle sx={{ mr: 3, position: 'relative' }}>
        <CloseButton onClose={onClose} />
        {t`changeMatchAmount`}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3,
          wordBreak: 'break-word',
          minHeight: 330,
          borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
          borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
          backgroundColor: 'rgba(250, 250, 252, 1)',
        }}
      >
        <RadioGroup name="desired-match-amount-radio-group" sx={{ gap: 3 }}>
          <RadioDiv
            checked={!hasCustomAmount}
            select={() => setDesiredAmount(undefined)}
            tooltip={t`tooltipMatchMaximum`}
            label={t`labelMatchMaximum`}
          >
            {!hasCustomAmount && (
              <>
                <Stack flexDirection="row" flexWrap="wrap" gap={2} alignItems="center">
                  <Typography variant="h5" color="primary">
                    <CurrencyFormat
                      baseCurrency={baseCurrency}
                      amount={matchData.matchedAmountMaximum}
                      customCurrency={customCurrency}
                    />
                  </Typography>
                  {chip}
                </Stack>
                {futureRemaining}
              </>
            )}
          </RadioDiv>
          <RadioDiv
            checked={hasCustomAmount}
            select={() => setDesiredAmount(matchData.matchedAmountMaximum)}
            tooltip={t`tooltipMatchCustom`}
            label={t`labelMatchCustom`}
          >
            {hasCustomAmount && (
              <>
                <Box>
                  <InputAmountText
                    value={roundNumber(desiredAmountInDisplayCurrency || 0)}
                    setValue={(v) => setUsingCustomCurrency(v)}
                    currencyCode={customCurrency}
                    chip={chip}
                  />
                  {error && <Body2 colour={colors.redDark}>{error}</Body2>}
                  <Slider
                    value={desiredAmount}
                    onChange={(_event, value) => setDesiredAmount(Array.isArray(value) ? value[0] : value)}
                    marks={marks}
                    max={matchData.matchedAmountMaximum}
                    min={0}
                    step={currencyConvert({
                      amount: 1,
                      sourceCurrency: customCurrency,
                      targetCurrency: baseCurrency,
                      usdRates,
                    })}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </Box>
                {futureRemaining}
              </>
            )}
          </RadioDiv>
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ my: 2, justifyContent: 'center' }}>
        <Button variant="outlined" onClick={onClose}>
          {t`common:Cancel`}
        </Button>
        <Button
          onClick={() => _onDesiredMatchedAmountChange()}
          disabled={!!desiredAmountInDisplayCurrency && !!error}
          variant="contained"
        >
          {t`common:Save`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
