import React from 'react'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { OrganizationMap } from 'src/entities/organization/reducer'
import { selectPage, selectTotalPages } from 'src/containers/screens/Nonprofits/selectors'

import { getCardListDimensions } from '../../utils'
import { DeedListWithPagination } from '../../common/DeedListWithPagination'

export const NonprofitListContainer = ({
  nonprofits,
  loading,
  loadMoreNonprofits,
}: {
  nonprofits: OrganizationMap
  loading: boolean
  loadMoreNonprofits: () => void
}) => {
  const currentPage = useSelector(selectPage)
  const totalPages = useSelector(selectTotalPages)

  const { metrics } = useDeedTheme()
  const { cardWidth, numberOfColumns } = getCardListDimensions(metrics)

  return (
    <DeedListWithPagination
      loading={loading}
      deeds={nonprofits}
      onSeeMore={loadMoreNonprofits}
      hasMoreItems={currentPage <= totalPages}
      cardWidth={cardWidth}
      numberOfColumns={numberOfColumns}
      useNonprofitList
    />
  )
}
