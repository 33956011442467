import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { historyStack, useHistory } from 'src/navigation'
import { Text, H4, Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import Icon from 'src/retired/shared/Icon'
import { Action, Screen, ExternalLink } from 'src/retired/elements'
import { selectCurrentUser } from 'src/entities/user/selectors'

const Content = styled.View`
  align-items: center;
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '60px 150px' : '30px 40px')};
`

const LogOffPlatformDonationSuccess = () => {
  const { t } = useTranslation('logOffPlatformDonation')
  const { colors } = useDeedTheme()
  const history = useHistory()
  const user = useSelector(selectCurrentUser)

  // Find the previous location in history that is not within 'off-platform'
  const dismissScreenPath = historyStack.reverse().find((path: string) => !path.includes('off-platform')) || ''

  return (
    <Screen fixed>
      <Screen middle action safeArea={false}>
        <Content>
          <Text
            fontSize={12}
            marginBottom={8}
            center
            weight="500"
            colour={colors.brandColor}
            style={{ textTransform: 'uppercase' }}
          >
            {t`yourRequestHasBeenSubmitted`}
          </Text>
          <H4 center lineHeight={28} marginBottom={8}>
            {t`thankYouForSubmittingYourOfflineDonation`}
          </H4>
          <Body2 center>
            {t(`ifYourDonationIsEligibleForMatching`, { userOrganizationName: user?.organization.name })}
          </Body2>
          <View style={{ marginTop: 40, marginBottom: 40 }}>
            <Icon icon="thankYouModal" width={400} height={144} />
          </View>

          <Body2 center>
            <Trans
              t={t}
              i18nKey="ifYouHaveAnyQuestionsOrConcerns"
              components={{
                supportTeamLink: (
                  <ExternalLink
                    target="_blank"
                    href="mailto:support@joindeed.com"
                    size={14}
                    color={colors.brandColor}
                  />
                ),
              }}
            />
          </Body2>
        </Content>

        <Action style={{ paddingLeft: 25, paddingRight: 25 }}>
          <Button palette="primary" style={{ width: '50%' }} onPress={() => history.replace(dismissScreenPath)}>
            {t`done`}
          </Button>
        </Action>
      </Screen>
    </Screen>
  )
}

export default LogOffPlatformDonationSuccess
