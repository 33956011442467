export const INIT = 'containers/Login/INIT'
export const INIT_SUCCESS = 'containers/Login/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Login/INIT_FAILED'
export const AUTH = 'containers/Login/AUTH'
export const AUTH_SUCCESS = 'containers/Login/AUTH_SUCCESS'
export const AUTH_FAILED = 'containers/Login/AUTH_FAILED'

export const APPLE_LOGIN = 'containers/Login/APPLE_LOGIN'
export const APPLE_LOGIN_FAILED = 'containers/Login/APPLE_LOGIN_FAILED'
export const APPLE_AUTH = 'containers/Login/APPLE_AUTH'
export const APPLE_AUTH_SUCCESS = 'containers/Login/APPLE_AUTH_SUCCESS'
export const APPLE_AUTH_FAILED = 'containers/Login/APPLE_AUTH_FAILED'
