import React from 'react'
import { View } from 'react-primitives'

import { DeviceInfo } from 'src/utils'

const SafeAreaView = ({
  withoutHeader = false,
  paddingOnly = false,
  children,
  style,
}: {
  withoutHeader?: boolean
  paddingOnly?: boolean
  children?: React.ReactNode
  style?: any
}) => (
  <View
    style={{
      height: paddingOnly ? 'auto' : '100%',
      paddingTop: withoutHeader ? 0 : DeviceInfo.getNotchValue('top'),
      ...style,
    }}
  >
    {children}
  </View>
)

export default SafeAreaView
