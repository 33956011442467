import { fromJS } from 'immutable'

import {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  SET_PAGINATION_DETAILS,
  SET_SCROLL_POSITION,
  RESET_NONPROFITS,
} from './constants'

export const initialState = fromJS({
  loading: true,
  scrollPosition: 0,
  queryId: '',
  totalItems: 0,
  totalPages: 0,
  page: 1,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return state.set('loading', true)

    case SET_PAGINATION_DETAILS:
      return state.merge({
        queryId: action.queryId,
        totalItems: action.totalItems,
        totalPages: action.totalPages,
        page: action.page,
      })

    case SEARCH_SUCCESS:
      return state.set('loading', false)

    case SEARCH_FAILED:
      return state.merge({ loading: false, error: action.error })

    case SET_SCROLL_POSITION:
      return state.set('scrollPosition', action.scrollPosition)

    case RESET_NONPROFITS:
    case 'RESET':
      return initialState

    default:
      return state
  }
}
