import React from 'react'

import { styled } from 'src/theme/styled'
import Icon from 'src/retired/shared/Icon'
import { Body1 } from 'src/retired/shared/Typography'

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const Text = styled(Body1)`
  padding-right: 10px;
`

const IconWrapper = styled.View`
  margin-top: 3px;
`

export const TextWithIcon = ({ icon, text, color }: { icon: string; text: string; color: string }) => (
  <Wrapper>
    <Text colour={color}>{text}</Text>
    <IconWrapper>
      <Icon icon={icon} width={16} hexColor={color} />
    </IconWrapper>
  </Wrapper>
)
