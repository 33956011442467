import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Cause, { CauseTypes } from 'src/entities/cause/model'
import { CauseMap } from 'src/entities/cause/reducer'
import { selectFlatCauses } from 'src/entities/cause/selectors'
import { selectUserBrand } from 'src/entities/user/selectors'
import { getCauseIconData } from 'src/retired/shared/DeedCard/helper'
import { resize } from 'src/utils/resize'

import { FilterItemContainer } from '../../common/FilterItemContainer'
import { ChipItemProps, ToggleableChipList } from '../../common/ToggleableChipList'

export const CauseFilters = ({
  causesOptions,
  onChangeValues,
  initCausesOptions,
  currentActiveCauses,
}: {
  causesOptions: ChipItemProps[]
  onChangeValues: Dispatch<SetStateAction<ChipItemProps[]>>
  initCausesOptions: (value: ChipItemProps[]) => void
  currentActiveCauses: CauseMap
}) => {
  const { t } = useTranslation('searchScreen')

  const allCauses = useSelector(selectFlatCauses)
  const companyName = useSelector(selectUserBrand)?.name

  const pillars = causesOptions.filter((c) => c.type === CauseTypes.Pillar)
  const ergs = causesOptions.filter((c) => c.type === CauseTypes.ERG)
  const publicCauses = causesOptions.filter((c) => c.type === CauseTypes.Category)
  const sdgs = causesOptions.filter((c) => c.type === CauseTypes.SDG)

  const getIconData = (cause: Cause) => {
    if ([CauseTypes.Category, CauseTypes.SDG].includes(cause.type)) {
      return getCauseIconData([cause.id])
    }
  }

  useEffect(() => {
    initCausesOptions(
      allCauses
        .map((cause) => ({
          id: cause.id,
          type: cause.type,
          name: cause.name,
          iconData: getIconData(cause),
          image: resize(cause.picture || cause.banner, '192x192'),
          status: !!currentActiveCauses?.find((c) => c.id === cause.id),
        }))
        .toArray() as unknown as ChipItemProps[]
    )
  }, [allCauses])

  if (!causesOptions.length) {
    return null
  }

  return (
    <>
      {[
        { type: CauseTypes.Pillar, options: pillars },
        { type: CauseTypes.ERG, options: ergs },
        { type: CauseTypes.Category, options: publicCauses },
        { type: CauseTypes.SDG, options: sdgs },
      ]
        .filter((causeData) => causeData.options?.length)
        .map((causeData) => (
          <FilterItemContainer
            key={causeData.type}
            title={t(causeData.type.toLocaleLowerCase(), {
              companyName,
            })}
          >
            <ToggleableChipList
              values={causeData.options}
              setValues={onChangeValues}
              direction="vertical"
              maxCollapsedItems={5}
              // Using onlySingleSelection here is temporary
              // Basically everything is setup on FE for supporting multiple causes.
              // But BE doesn't support it yet see https://app.shortcut.com/deed/story/52498/allow-multiple-causes
              // Once BE is ready, just remove this prop
              multipleSelection={false}
            />
          </FilterItemContainer>
        ))}
    </>
  )
}
