import { fromJS } from 'immutable'

import {
  INIT,
  INIT_STANDALONE,
  INIT_SUCCESS,
  INIT_FAILED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  RESET_ARE_RESPONSES_VALID,
} from './constants'

export const initialState = fromJS({})
export const initialFormState = fromJS({
  loading: false,
  submitting: false,
  submitSuccess: false,
  error: null,
  areResponsesValid: undefined,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.set('loading', true).set('error', null)

    case INIT_STANDALONE:
      return state.set('loading', true).set('error', null)

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.set('error', action.error)

    case SUBMIT:
      return state.set('submitting', true)

    case SUBMIT_SUCCESS:
      return state
        .set('submitSuccess', true)
        .set('submitting', false)
        .set('areResponsesValid', action.areResponsesValid)

    case SUBMIT_FAILED:
      return state.set('submitting', false).set('error', action.error)

    case RESET_ARE_RESPONSES_VALID:
      return state.set('areResponsesValid', undefined)

    case 'RESET':
      return initialState

    default:
      return state
  }
}
