export default class Dimensions {
  static get(target) {
    switch (target) {
      case 'window':
        return { width: window.innerWidth, height: window.innerHeight }

      case 'screen':
      default:
        return { width: window.clientWidth, height: window.clientHeight }
    }
  }
}
