import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useParams, Redirect, useHistory } from 'src/navigation'
import Layout from 'src/retired/blocks/Layout'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { Spacing, Loading } from 'src/retired/elements'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import {
  selectPillars,
  selectERGs,
  selectCategories,
  selectSDGs,
  selectCausesLoaded,
} from 'src/entities/cause/selectors'
import CausesBlock from 'src/retired/blocks/CausesBlock'
import { PageTitle } from 'src/components'
import CommunitiesBlockComponent from 'src/containers/screens/Communities/CommunitiesBlock'
import { useCommunitiesQuery } from 'src/generated/graphql'
import epics from 'src/containers/screens/Home/epics'
import reducer from 'src/containers/screens/Home/reducer'

import { fetchCausesAction } from '../Home/actions'

const Causes: FC = () => {
  const { t } = useTranslation('causesScreen')
  useInjectEpics({ key: 'home', epics })
  useInjectReducer({ key: 'home', reducer })

  const dispatch = useDispatch()
  const { type } = useParams<{ type?: string }>()

  const causesLoaded = useSelector(selectCausesLoaded)
  const pillars = useSelector(selectPillars)
  const ERGs = useSelector(selectERGs)
  const categories = useSelector(selectCategories)
  const SDGs = useSelector(selectSDGs)
  const user = useSelector(selectCurrentUser)

  useEffect(() => {
    if (!causesLoaded) {
      dispatch(fetchCausesAction())
    }
  }, [dispatch])

  const { metrics } = useDeedTheme()
  const hideExploreSection = user?.getSetting('hideExploreSection') ?? true
  const isCommunitiesFeatureEnabled = user?.hasFeature('communities')

  const {
    data: communitiesData,
    error: communitiesQueryError,
    loading: communitiesDataLoading,
  } = useCommunitiesQuery({ skip: !isCommunitiesFeatureEnabled })
  const isLoaded = causesLoaded || !communitiesDataLoading

  const history = useHistory()

  const communities = communitiesData?.communities || []

  if (communitiesQueryError) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  if (!type && user && hideExploreSection) {
    return <Redirect to="/home" />
  }

  const backLink = hideExploreSection ? '/home' : '/causes'

  const PillarsBlock = (
    <CausesBlock
      causes={pillars.toArray()}
      title={t`featuredCauses`}
      link={!type ? '/causes/pillars' : undefined}
      back={type ? backLink : undefined}
      isResizable
    />
  )

  const ERGsBlock = (
    <CausesBlock
      causes={ERGs.toArray()}
      title={t`employeeResourceGroups`}
      link={!type ? '/causes/ergs' : undefined}
      back={type ? backLink : undefined}
    />
  )

  const CommunitiesBlock = (
    <CommunitiesBlockComponent
      communities={communities}
      title={t`communities`}
      link={!type ? '/communities' : undefined}
      back={type ? backLink : undefined}
      isResizable
    />
  )
  const CategoriesBlock = (
    <CausesBlock
      causes={categories.toArray()}
      title={`${pillars.size || ERGs.size ? `${t`other`} ` : ''}${t`causeAreas`}`}
      link={!type ? '/causes/categories' : undefined}
      back={type ? backLink : undefined}
      isResizable
    />
  )
  const SDGsBlock = (
    <CausesBlock
      causes={SDGs.toArray()}
      title={t`unitedNationsSDGs`}
      link={!type ? '/causes/sdgs' : undefined}
      back={type ? backLink : undefined}
      isResizable
    />
  )

  if (type) {
    let causes
    switch (type) {
      case 'pillars':
        causes = PillarsBlock
        break

      case 'categories':
        causes = CategoriesBlock
        break

      case 'sdgs':
        causes = SDGsBlock
        break

      case 'ergs':
        if (isCommunitiesFeatureEnabled) {
          history.replace('/communities')
        } else {
          causes = ERGsBlock
        }

        break
    }

    return (
      <Layout fullWidth padding>
        <PageTitle title={t`featuredCauseAreas`} />
        <Spacing paddingTop={metrics.isSmall ? 15 : 30}>{causes}</Spacing>
      </Layout>
    )
  }

  return (
    <Layout fullWidth padding>
      <PageTitle title={t`featuredCauseAreas`} />
      {!isLoaded || !user ? (
        <Loading />
      ) : (
        <Spacing paddingTop={metrics.isSmall ? 15 : 30} paddingBottom={metrics.isSmall ? 30 : 0}>
          {pillars.size > 0 && PillarsBlock}

          {!isCommunitiesFeatureEnabled && ERGs.size > 0 && ERGsBlock}

          {isCommunitiesFeatureEnabled && communities.length > 0 && CommunitiesBlock}

          {CategoriesBlock}

          {SDGsBlock}
        </Spacing>
      )}
    </Layout>
  )
}

export default Causes
