import React, { useEffect, useState, useImperativeHandle, forwardRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { PPGFCountries, DIRECTCountries } from '@joindeed/paypal-public'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { useLocation, useHistory } from 'src/navigation'
import { useCategories, Category } from 'src/entities/cause/api'
import { Row } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import SelectBox from 'src/retired/shared/SelectBox'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { setPaginationDetailsAction } from 'src/containers/screens/Nonprofits/actions'
import { clearDisplayedOrganizationIdsAction } from 'src/entities/organization/actions'
import CountrySelector from 'src/components/CountrySelector'
import truthy from 'src/utils/truthy'
import { Body1 } from 'src/retired/shared/Typography'
import { getSearch } from 'src/containers/screens/GeneralSearch/utils'

import { FreeTextSearch } from './FreeTextSearch'

const SearchBox = styled(View)<object, EmotionTheme>`
  background-color: ${({ theme }) => theme.colors.white};
  border-width: 1px;
  border-radius: 12px;
  border-color: ${({ theme }) => theme.colors.gray02};
  padding: 24px 20px;
  margin: 15px 0;
  z-index: 1;
  box-shadow: 0 1px 4px #00000014;
`

const NonprofitsSearchBar = forwardRef(
  (
    {
      onCausesListChange,
      campaignId,
      withoutBackground,
    }: { onCausesListChange?: (causesList: Category[]) => void; campaignId?: string; withoutBackground?: boolean },
    ref
  ) => {
    const { t } = useTranslation('nonprofitsSearchBar')
    const dispatch = useDispatch()
    const user = useSelector(selectCurrentUser)
    const locations = useSelector(selectUserLocations)
    const history = useHistory()
    const { search } = useLocation()
    const { metrics, colors } = useDeedTheme()
    const categories = useCategories()

    const userCountryCode = locations?.get(user?.location)?.countryCode
    const userStateCode = locations?.get(user?.location)?.stateCode
    const disableNonprofitSearch = user?.getSetting('partnerNonprofitsOnly') ?? true

    const searchParams = search ? parse(search) : ''

    const [searchTerm, setSearchTerm] = useState<string>(searchParams ? (searchParams.searchTerm as string) : '')
    const [location, setLocation] = useState<string>(searchParams ? (searchParams.location as string) : '')
    const [cause, setCause] = useState<string | undefined>(searchParams ? (searchParams.cause as string) : undefined)

    const countryCode = location?.substring(0, 2) ?? userCountryCode ?? 'US'

    const searchSupportedByPayPal =
      !!countryCode && (PPGFCountries.includes(countryCode) || DIRECTCountries.includes(countryCode))

    // filter out unsupported by PP causes if needed
    const causesList = useMemo(
      () =>
        categories
          .map((category) => (searchSupportedByPayPal ? category.supportedByPayPal && category : category))
          .filter(truthy),
      [searchSupportedByPayPal, categories]
    )
    const [initialLocationCode, setInitialLocationCode] = useState(
      userCountryCode ? `${userCountryCode}${userStateCode ? `-${userStateCode}` : ''}` : ''
    )

    useEffect(() => {
      if (!search) {
        setLocation(initialLocationCode)
      }
    }, [initialLocationCode, search])

    useEffect(() => {
      if (
        searchSupportedByPayPal &&
        cause &&
        categories.length &&
        !categories.find((category) => category.name === cause)?.supportedByPayPal
      ) {
        setCause(undefined)
      }
    }, [searchSupportedByPayPal])

    useEffect(() => {
      if (onCausesListChange) {
        onCausesListChange(causesList)
      }
    }, [causesList])

    const handleSearchPress = (parentCause?: string): void => {
      dispatch(setPaginationDetailsAction(0, 0, '', 1))
      dispatch(clearDisplayedOrganizationIdsAction())

      const selectedCause = categories.find((category) => [cause, parentCause].includes(category.name))

      history.push({
        pathname: '/search',
        search: getSearch({
          searchTerm,
          location,
          cause: selectedCause?.name,
          ...(campaignId ? { campaign: campaignId } : {}),
        }),
      })
    }

    useImperativeHandle(ref, () => ({
      triggerSearch(causeName: string) {
        handleSearchPress(causeName)
      },
    }))

    if (disableNonprofitSearch) {
      return <View style={{ marginBottom: 100 }} />
    }

    const Wrapper = withoutBackground ? View : SearchBox

    return (
      <Wrapper>
        <Row
          style={{
            display: 'flex',
            flexDirection: metrics.isSmall ? 'column' : 'row',
            alignItems: metrics.isSmall ? 'stretch' : 'flex-end',
            gap: 10,
          }}
        >
          <View
            style={{
              flex: metrics.isMedium ? 1 : metrics.isSmall ? 3 : 4 * (1 + Number(!!categories?.length)),
              marginBottom: metrics.isSmall ? 10 : 0,
            }}
          >
            <Body1 style={{ color: withoutBackground && colors.white }}>{t`findAnOrganization`}</Body1>
            <View style={{ marginTop: 10 }}>
              <FreeTextSearch
                fieldName="nonprofitName"
                placeholder={t`search`}
                inputValue={searchTerm}
                onChange={(_, value) => setSearchTerm(value)}
                onEnter={(e: KeyboardEvent) => {
                  if (e.key === 'Enter' && location) {
                    handleSearchPress()
                  }
                }}
                backgroundColor={colors.white}
              />
            </View>
          </View>
          {causesList?.length ? (
            <View
              style={{
                flex: metrics.isMedium ? 1 : metrics.isSmall ? 3 : 4,
                marginBottom: metrics.isSmall ? 10 : 0,
              }}
            >
              <Body1 style={{ color: withoutBackground && colors.white }} marginLeft={8}>{t`causeArea`}</Body1>
              <SelectBox
                onDeselect={() => setCause(undefined)}
                onSelect={(value) => setCause(value)}
                value={cause}
                placeholder={t`chooseACause`}
                options={causesList.map((causeItem) => ({ title: causeItem.name, value: causeItem.name }))}
                style={{ marginTop: 8, marginLeft: 0, width: '100%', fontSize: '12px' }}
                showSearch
                allowClear
              />
            </View>
          ) : null}
          <View
            style={{
              flex: metrics.isMedium ? 1 : 3,
              marginBottom: metrics.isSmall ? 10 : 0,
              zIndex: 2,
            }}
          >
            <View style={{ marginTop: 8, marginLeft: 0, marginRight: 20, width: '100%' }}>
              <Body1
                marginLeft={8}
                marginBottom={8}
                style={{ color: withoutBackground && colors.white }}
              >{t`organizationLocation`}</Body1>
              <CountrySelector
                country={location || undefined}
                initialLocationCode={initialLocationCode}
                setInitialLocationCode={setInitialLocationCode}
                handleChangeLocation={(value) => {
                  if (value) {
                    setLocation(value)
                  }
                }}
                showStates
                placeholder={t`selectLocation`}
                width="100%"
              />
            </View>
          </View>
          <View
            style={!metrics.isSmall ? { marginTop: 25, marginLeft: metrics.isMedium ? 10 : 20 } : { marginTop: 20 }}
          >
            <Button onPress={() => handleSearchPress()} palette="primary" disabled={!location}>
              {t`search`}
            </Button>
          </View>
        </Row>
      </Wrapper>
    )
  }
)

export default NonprofitsSearchBar
