import React from 'react'
import PropTypes from 'prop-types'

import Screen from 'src/retired/elements/Screen'
import Text from 'src/retired/elements/Text'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { colors } from 'src/theme'
import Icon from 'src/retired/shared/Icon'
import { styled } from 'src/theme/styled'

const IconWrapper = styled.View`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
`

const ErrorScreen = ({ children, back = true }) => (
  <Screen fixed>
    <NavigationHeader logo gradient back={back} />
    <Screen padding middle narrow>
      <IconWrapper>
        <Icon icon="infoCircleOutlinedRed" width={30} hexColor={colors.redDark} />
      </IconWrapper>
      <Text size={30} color={colors.redDark} style={{ textAlign: 'center' }}>
        {children}
      </Text>
    </Screen>
  </Screen>
)
ErrorScreen.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  back: PropTypes.bool,
}
export default ErrorScreen
