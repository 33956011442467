import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-primitives'
import urlParse from 'url-parse'
import { useTranslation } from 'react-i18next'

import { useParams, Redirect } from 'src/navigation'
import { Communications } from 'src/utils'
import { useInjectEpics } from 'src/utils/injectEpics'
import config from 'src/config'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { selectDeedById } from 'src/entities/deed/selectors'
import { Text, Label } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { Action, Screen } from 'src/retired/elements'
import { colors } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { applyToExternalDeedAction } from '../actions'
import epics from '../epics'

const DeedExternal: FC = () => {
  const { t } = useTranslation('deedScreen')
  useInjectEpics({ key: 'deed', epics })
  const dispatch = useDispatch()

  const { deed: id = '' } = useParams()
  const deed = useSelector((state) => selectDeedById(state, id))
  const user = useSelector(selectCurrentUser)
  if (!deed) {
    return <Redirect to={`/deeds/${id}`} />
  }

  const url = urlParse(deed.externalLink)

  return (
    <Screen fixed>
      <ImageHeaderScrollView maxHeight={200} minHeight={150} title={deed.name} image={deed.mainPicture} close>
        <View style={{ alignItems: 'center', paddingTop: 40, paddingBottom: 90, paddingHorizontal: 20 }}>
          <Text fontSize={20} weight="bold" center marginBottom={20}>
            {t`projectIsHostedOnAnotherWebsite`}
          </Text>
          <Label weight="500" center lineHeight={20} marginBottom={20} style={{ maxWidth: 400 }}>
            {t`youWillBeRedirected`}
          </Label>
          <View
            style={{
              borderRadius: 4,
              borderWidth: 1,
              borderColor: colors.blue,
              alignItems: 'center',
              alignContent: 'center',
              padding: 15,
            }}
          >
            <Label colour="blue" lineHeight={17} center style={{ userSelect: 'none' }}>
              {url.origin}
              {url.pathname}
            </Label>
          </View>
        </View>
        <Action>
          <View>
            <Button
              palette="primary"
              onPress={() => {
                dispatch(applyToExternalDeedAction(id))
                Communications.web(`${config.apiEndpoint}/api/deeds/${id}/external/${user.id}`)
              }}
            >
              {deed.type === 'Project' ? t`continueYourApplication` : t`continueToOpportunity`}
            </Button>
          </View>
        </Action>
      </ImageHeaderScrollView>
    </Screen>
  )
}

export default DeedExternal
