import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-primitives'

import Layout from 'src/retired/blocks/Layout'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Screen, Row } from 'src/retired/elements'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import * as CauseActions from 'src/containers/screens/Cause/actions'
import * as FeedActions from 'src/containers/screens/Feed/actions'
import { selectDeedTypeFeedLoaded, selectDeedTypeFeedLoading } from 'src/containers/screens/Feed/selectors'
import feedReducer from 'src/containers/screens/Feed/reducer'
import feedEpics from 'src/containers/screens/Feed/epics'
import causeEpics from 'src/containers/screens/Cause/epics'
import searchEpics from 'src/containers/modules/Search/epics'
import { LargeUp, MediumDown } from 'src/retired/elements/MediaQuery'
import MobileOverlay from 'src/retired/shared/MobileOverlay'
import { selectCausesLoaded, selectFlatCauses } from 'src/entities/cause/selectors'

import { TopBar } from './TopBar'
import { ResultsContainer } from './ResultsContainer'
import { TabType, useSearchParams } from './utils'
import { MobileOpenFiltersButton } from './MobileOpenFiltersButton'
import { VolunteerFiltersSynchronizers } from './VolunteerTab/Filters/VolunteerFiltersSynchronizers'
import { FiltersContainer } from './FiltersContainer'
// import { EventFiltersContainer } from './EventTab/Filters/EventFiltersContainer'

const SearchPageContainer = () => {
  const { colors, metrics } = useDeedTheme()

  useInjectReducer({ key: 'feed', reducer: feedReducer })
  useInjectEpics({ key: 'feed', epics: feedEpics })
  useInjectEpics({ key: 'cause', epics: causeEpics })
  useInjectEpics({ key: 'search', epics: searchEpics })

  const fundraisersLoaded = useSelector((state) => selectDeedTypeFeedLoaded(state, 'Campaign'))
  const fundraisersLoading = useSelector((state) => selectDeedTypeFeedLoading(state, 'Campaign'))

  const volunteerProjectsLoaded = useSelector((state) => selectDeedTypeFeedLoaded(state, 'Project'))
  const volunteerProjectsLoading = useSelector((state) => selectDeedTypeFeedLoading(state, 'Project'))

  const volunteerEventsLoaded = useSelector((state) => selectDeedTypeFeedLoaded(state, 'Event'))
  const volunteerEventsLoading = useSelector((state) => selectDeedTypeFeedLoading(state, 'Event'))

  // Event tab
  // const eventsLoaded = useSelector((state) => selectDeedTypeFeedLoading(state, "BaseEvent"))
  // const events = selectFilteredDeeds(state, "BaseEvent"),

  const [filtersShown, setFiltersShown] = useState(false)
  const dispatch = useDispatch()

  const allCauses = useSelector(selectFlatCauses)
  const causesLoaded = useSelector(selectCausesLoaded)

  const { activeTab, searchTerm, location, coordinates, radius, categories, ergs, pillars, sdgs } = useSearchParams()
  const query = {
    searchTerm,
    location,
    radius,
    coordinates,
    categories,
    ergs,
    pillars,
    sdgs,
  }

  useEffect(() => {
    if (!causesLoaded) {
      dispatch(CauseActions.initAction())
    }
  }, [allCauses, dispatch])

  // @NOTE-CH: This effect will run whenever the filter parameters change and the init load has already happened at least once
  // We intentionally don't listen to changes on `fundraisersLoaded`, `volunteerProjectsLoaded`, and `volunteerEventsLoaded` because we want it to run only when new parameters are set
  useEffect(() => {
    /* @FIXME-CH: We should be checking for `donateType=Fundraisers` as well, but the unrelated parameters are not cleaned when switching tabs */
    if (activeTab === TabType.donate && !fundraisersLoading && fundraisersLoaded && causesLoaded) {
      dispatch(FeedActions.loadFeedForDeedTypeAction('Campaign', query))
    }
    /* @FIXME-CH: Should we be triggering both tabs at same time, or just the visible one? */
    if (activeTab === TabType.volunteer) {
      if (!volunteerProjectsLoading && volunteerProjectsLoaded && causesLoaded) {
        dispatch(FeedActions.loadFeedForDeedTypeAction('Project', query))
      }
      if (!volunteerEventsLoading && volunteerEventsLoaded && causesLoaded) {
        dispatch(FeedActions.loadFeedForDeedTypeAction('Event', query))
      }
    }
  }, [activeTab, searchTerm, location, coordinates, radius, categories, ergs, pillars, sdgs])

  // Donate tab related init
  useEffect(() => {
    if (!fundraisersLoading && !fundraisersLoaded && causesLoaded) {
      dispatch(FeedActions.loadFeedForDeedTypeAction('Campaign', query))
    }
  }, [fundraisersLoaded, fundraisersLoading, causesLoaded, dispatch])

  // Volunteer tab related init
  useEffect(() => {
    if (!volunteerProjectsLoading && !volunteerProjectsLoaded && causesLoaded) {
      dispatch(FeedActions.loadFeedForDeedTypeAction('Project', query))
    }
  }, [volunteerProjectsLoading, volunteerProjectsLoaded, causesLoaded, dispatch])

  useEffect(() => {
    if (!volunteerEventsLoading && !volunteerEventsLoaded && causesLoaded) {
      dispatch(FeedActions.loadFeedForDeedTypeAction('Event', query))
    }
  }, [volunteerEventsLoading, volunteerEventsLoaded, causesLoaded, dispatch])

  const getHandleMobileClose = () => (metrics.isSmall || metrics.isMedium ? () => setFiltersShown(false) : undefined)

  return (
    <>
      <MediumDown>
        <MobileOverlay isOpen={filtersShown} close={() => setFiltersShown(false)} innerStyle={{ top: 0 }}>
          <FiltersContainer close={getHandleMobileClose()} />
        </MobileOverlay>
      </MediumDown>

      <Layout fullWidth>
        <Screen style={{ backgroundColor: colors.gray03 }} safeArea={false}>
          <MediumDown>
            <TopBar />
            <MobileOpenFiltersButton setFiltersShown={setFiltersShown} />
            <View style={{ padding: 30 }}>
              <ResultsContainer />
            </View>
          </MediumDown>

          <LargeUp>
            <TopBar />
            <Row style={{ padding: 30 }}>
              <FiltersContainer close={getHandleMobileClose()} />
              <ResultsContainer />
            </Row>
          </LargeUp>
        </Screen>
      </Layout>
      {activeTab === TabType.volunteer && <VolunteerFiltersSynchronizers />}
    </>
  )
}

export default SearchPageContainer
