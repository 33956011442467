/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from 'src/components'
import CommunitiesBlockComponent from 'src/containers/screens/Communities/CommunitiesBlock'
import { selectERGs } from 'src/entities/cause/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useCommunitiesQuery } from 'src/generated/graphql'
import CausesBlock from 'src/retired/blocks/CausesBlock'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import Layout from 'src/retired/blocks/Layout'
import { Loading, Spacing } from 'src/retired/elements'
import { LargeUp, MediaQuery, MediumDown } from 'src/retired/elements/MediaQuery'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { H4, H5 } from 'src/retired/shared/Typography'

import { BadgesWidget } from '../Profile/Badges/BadgesWidget'

import DonationWidget from './DonationWidget/DonationWidget'
import CampaignsBlock from './CampaignBlock/CampaignsBlock'
import { LoadingSpinner } from './LoadingSpinner'
import { PillarsBlock } from './PillarsBlock'
import QuickActions from './QuickActions/QuickActions'
import { SuggestedDeedsBlock } from './SuggestedDeedsBlock'
import { TimelyDeedsBlock } from './TimelyDeedsBlock'
import WelcomeBanner from './WelcomeBanner'
import { fetchCampaignsAction, fetchSkillsAction } from './actions'
import epics from './epics'
import reducer from './reducer'
import { selectError } from './selectors'
import { BackupWidget } from './BackupWidget'

const Home: FC = () => {
  const { colors, metrics } = useDeedTheme()

  useInjectReducer({ key: 'home', reducer })
  useInjectEpics({ key: 'home', epics })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCampaignsAction())
    dispatch(fetchSkillsAction())
  }, [dispatch])
  const { t } = useTranslation('homeScreen')
  const user = useSelector(selectCurrentUser)
  const ERGs = useSelector(selectERGs)

  const showBackupWidget = (!user?.hasFeature('volunteering') || !user?.hasFeature('donations')) && !metrics.isSmall
  const isCommunitiesFeatureEnabled = user?.hasFeature('communities')

  const {
    data: communitiesData,
    error: communitiesQueryError,
    loading: communitiesDataLoading,
  } = useCommunitiesQuery({ skip: !isCommunitiesFeatureEnabled })

  const error = useSelector(selectError) || communitiesQueryError

  const communities = communitiesData?.communities || []

  if (error) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }
  if (!user) {
    return <Loading backgroundColor={metrics.isLarge ? colors.gray03 : colors.white} />
  }

  const ERGsBlock = (): JSX.Element => (
    <View>
      {ERGs.size > 0 && <CausesBlock causes={ERGs.toArray()} title={t`employeeResourceGroups`} link="/causes/ergs" />}
    </View>
  )

  const CommunitiesBlock = (): JSX.Element => (
    <View>
      {communities.length > 0 && (
        <CommunitiesBlockComponent communities={communities} title={t`communities`} link="/communities" />
      )}
    </View>
  )

  const widgetStyle = {
    marginHorizontal: metrics.isSmall ? 12 : 16,
    marginTop: metrics.isSmall ? 20 : 32,
  }
  return (
    <Layout padding backgroundColor={colors.white}>
      <PageTitle />
      <MediumDown>
        <WelcomeBanner />
        <View
          style={{
            flexDirection: metrics.isSmall ? 'column' : 'row',
            marginHorizontal: metrics.isSmall ? -12 : -16,
          }}
        >
          <DonationWidget style={widgetStyle} />
          <BadgesWidget style={widgetStyle} />
          {showBackupWidget ? <BackupWidget style={widgetStyle} /> : null}
        </View>

        <View
          style={{
            paddingVertical: 24,
          }}
        >
          <H5 weight="500" marginTop={24} marginBottom={24}>
            {t(`quickActions`)}
          </H5>
          <QuickActions />
          <CampaignsBlock />
          <PillarsBlock />
          {communitiesDataLoading ? (
            <Spacing marginTop={60} marginBottom={10}>
              <LoadingSpinner />
            </Spacing>
          ) : (
            <>{isCommunitiesFeatureEnabled ? <CommunitiesBlock /> : <ERGsBlock />}</>
          )}
          <TimelyDeedsBlock />
          <SuggestedDeedsBlock />
        </View>
      </MediumDown>
      <LargeUp>
        <WelcomeBanner />
        <H4 weight="500" marginTop={32}>
          {t(`quickActions`)}
        </H4>
        <View style={{ flexDirection: 'row', marginHorizontal: -16 }}>
          <QuickActions style={widgetStyle} />
          <DonationWidget style={widgetStyle} />
          <BadgesWidget style={widgetStyle} />
          {showBackupWidget ? <BackupWidget style={widgetStyle} /> : null}
        </View>
        <MediaQuery maxWidth={metrics.maxWidthNarrow}>
          <CampaignsBlock />
        </MediaQuery>
        <MediaQuery minWidth={metrics.maxWidthNarrow + 1}>
          <Spacing marginBottom={20}>
            <CampaignsBlock />
          </Spacing>
        </MediaQuery>
        <PillarsBlock />
        {communitiesDataLoading ? (
          <Spacing marginTop={60}>
            <LoadingSpinner />
          </Spacing>
        ) : (
          <>{isCommunitiesFeatureEnabled ? <CommunitiesBlock /> : <ERGsBlock />}</>
        )}
        <TimelyDeedsBlock />
        <SuggestedDeedsBlock />
      </LargeUp>
    </Layout>
  )
}

export default Home
