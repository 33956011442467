import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import SelectBox from 'src/retired/shared/SelectBox'
import { Body1 } from 'src/retired/shared/Typography'
import { ItemsPerLocation } from 'src/components/CountrySelector/utils'
import truthy from 'src/utils/truthy'
import CountrySelector from 'src/components/CountrySelector'

interface CampaignLocationSelectorProps {
  customFont?: string
  contentLocationFiltering?: boolean
  setLocationFilter: (value: string) => void
  locationFilter: string
  deedsCountries: string[]
  countries: string[]
  regions: Record<string, string[]>
  itemsPerLocation: ItemsPerLocation
  withVirtualFilter?: boolean
}

const CampaignLocationSelector = ({
  customFont,
  contentLocationFiltering,
  setLocationFilter,
  locationFilter,
  deedsCountries,
  countries,
  regions,
  itemsPerLocation,
  withVirtualFilter,
}: CampaignLocationSelectorProps): JSX.Element => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('campaignScreen')

  const regionKeys = regions && Object.keys(regions)
  const hasRegions = !!regionKeys?.length

  const additionalFilters = [{ value: 'All Locations', title: t`allLocations` }]
  if (withVirtualFilter) {
    additionalFilters.push({ value: 'Virtual', title: t`virtual` })
  }

  return (
    <View
      style={{
        flexDirection: metrics.isSmall ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        flexGrow: 0,
        width: metrics.isLarge ? '45%' : '100%',
        marginBottom: 20,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 15,
        zIndex: 1,
      }}
    >
      <Body1
        colour={colors.black}
        center
        marginBottom={metrics.isSmall ? 12 : 0}
        lineHeight={metrics.isSmall ? 16 : 20}
        customFont={customFont}
        weight="500"
      >
        {t`selectYourLocation`}
      </Body1>

      {contentLocationFiltering ? ( // This is the new filtering implementation that clients can set from organiser. Old one is not used by many clients anymore.
        <View
          style={{
            marginTop: 0,
            minWidth: metrics.isSmall ? '100%' : 160,
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            background: 'white',
          }}
        >
          <CountrySelector
            width="100%"
            placeholder={locationFilter}
            country={locationFilter}
            countries={deedsCountries}
            itemsPerLocation={itemsPerLocation}
            handleChangeLocation={(value) => setLocationFilter(value || 'All Locations')}
            showStates
            allowClear
            withVirtualFilter={withVirtualFilter}
          />
        </View>
      ) : (
        <SelectBox
          getPopupContainer={(target) => target.parentNode}
          onSelect={(value) => (Array.isArray(value) ? setLocationFilter(value[0]) : setLocationFilter(value))}
          value={locationFilter}
          options={
            !hasRegions
              ? [
                  ...additionalFilters,
                  ...countries.map((country) => ({
                    value: country,
                    title: country.replace('USA', 'United States'),
                  })),
                ]
              : undefined
          }
          optionGroups={
            hasRegions
              ? [
                  ...additionalFilters,
                  ...regionKeys.map((region) => ({
                    label: region,
                    options: regions[region]
                      .map(
                        (country: string) =>
                          countries.includes(country) && {
                            value: country,
                            title: country.replace('USA', 'United States'),
                          }
                      )
                      .filter(truthy),
                  })),
                ]
              : undefined
          }
          style={{
            minWidth: metrics.isSmall ? '100%' : 160,
            marginLeft: 20,
            marginRight: 20,
            flex: 1,
            background: 'white',
          }}
        />
      )}
    </View>
  )
}

export default CampaignLocationSelector
