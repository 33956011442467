import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  LOAD_FEED_FOR_DEED_TYPE,
  LOAD_FEED_FOR_DEED_TYPE_SUCCESS,
  LOAD_FEED_FOR_DEED_TYPE_FAILURE,
  SET_SCROLL_POSITION,
  RESET_SCROLL_POSITIONS,
} from './constants'

export const initAction = () => ({
  type: INIT,
})

export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})

export const initFailedAction = (error) => ({
  type: INIT_FAILED,
  error,
})

export const loadFeedForDeedTypeAction = (deedType, query) => ({
  type: LOAD_FEED_FOR_DEED_TYPE,
  deedType,
  query,
})

export const loadFeedForDeedTypeSuccessAction = (deedType, payload) => ({
  type: LOAD_FEED_FOR_DEED_TYPE_SUCCESS,
  deedType,
  payload,
})

export const loadFeedForDeedTypeFailureAction = (deedType, error) => ({
  type: LOAD_FEED_FOR_DEED_TYPE_FAILURE,
  deedType,
  error,
})

/**
 * Sets scroll position for the feed
 *
 * @param {string} location - url of the current feed
 * @param {number} scrollPosition
 */
export const setScrollPositionAction = (location, scrollPosition) => ({
  type: SET_SCROLL_POSITION,
  location,
  scrollPosition,
})
export const resetScrollPositionsAction = () => ({
  type: RESET_SCROLL_POSITIONS,
})
