import { createSelector } from 'reselect'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDeeds } from 'src/entities/deed/selectors'
import { selectPendingRelations } from 'src/entities/relation/selectors'

import { initialState } from './reducer'

const selectState = (state) => state.get('notifications') || initialState

export const selectInitialized = createSelector(selectState, (state) => state.get('initialized'))

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectPendingInvitationRequests = createSelector(selectState, (state) =>
  state.get('pendingInvitationRequests')
)

export const selectPendingRelationRequests = createSelector(selectState, (state) =>
  state.get('pendingRelationRequests')
)

const getDate = (item) => new Date(item.updatedAt || item.date)

export const selectNotifications = createSelector(
  selectCurrentUser,
  selectPendingRelations,
  selectDeeds,
  (user, relations, deeds) => {
    const invites = user ? user.get('myInvites') : []
    const myDeeds = deeds.filter((deed) => deed.isUserAttending(user) || deed.isUserWaitlisted(user))
    const invitesImNotAlreadyAttending = invites.filter((invite) => !myDeeds.get(invite.deed.id))
    relations = relations && user ? relations.filter((relation) => relation.to === user.id).values() : [] // eslint-disable-line no-param-reassign
    const result = [...invitesImNotAlreadyAttending, ...relations]
    return result.sort((a, b) => getDate(a) - getDate(b))
  }
)

export const selectNotificationsCount = createSelector(selectNotifications, (notifications) => notifications.length)
