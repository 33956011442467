import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import { Loading, Row, Icon, Screen, Spacing, Text, LinkText } from 'src/retired/elements'
import { Link, Redirect } from 'src/navigation'
import { metrics } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'

const smallScreen = metrics.screenWidth < 360
const fontSize = smallScreen ? 22 : 24
const lineHeight = smallScreen ? 28 : 32

interface FirstDeedProps {
  match: any
}
const FirstDeed: FC<FirstDeedProps> = ({ match }) => {
  const { t } = useTranslation('deedScreen')
  const user = useSelector(selectCurrentUser)
  const deed = useSelector((state) => selectDeedById(state, match.params.deed))
  if (deed?.type === 'Campaign') {
    return <Redirect to={`/deeds/${match.params.deed}/donate`} />
  }
  if (user?.isEmployee() && deed?.partner) {
    return <Redirect to={`/register/phone/${match.params.deed}`} />
  }
  if (!deed || !user) {
    return <Loading />
  }
  return (
    <Screen fixed>
      <Screen padding middle narrow>
        <Text size={smallScreen ? 24 : 26} lineHeight={lineHeight} bold style={{ alignSelf: 'stretch' }}>
          {t`thankYouForSigningUpForYourFirstDeed`}
        </Text>
        <Spacing marginTop={25} marginBottom={25}>
          <Text size={fontSize} lineHeight={lineHeight}>
            <Trans
              t={t}
              i18nKey="cancellationPolicy"
              components={{
                a: <LinkText to="/cancellation-policy" size={fontSize} lineHeight={lineHeight} underline />,
              }}
              tOptions={{
                context: user?.isEmployee() ? 'employee' : 'individual',
              }}
            />
          </Text>
        </Spacing>
        <Link to={`/register/phone/${match.params.deed}`} replace>
          <Row style={{ alignItems: 'center' }}>
            <Text size={fontSize} lineHeight={lineHeight} style={{ marginRight: 10 }}>
              {t`common:next`}
            </Text>
            <Icon icon="arrowRightBlack" style={{ width: 19, height: 16 }} />
          </Row>
        </Link>
      </Screen>
    </Screen>
  )
}

export default FirstDeed
