import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import { initialState, DonationState } from './reducer'

const selectState = (state: State): DonationState => state.get('donation') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectRefreshing = createSelector(selectState, (state) => state.get('refreshing'))

export const selectCancelling = createSelector(selectState, (state) => state.get('cancelling'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
