/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from 'immutable/dist/immutable'
import { Observable } from 'rxjs'
import { stringify } from 'query-string'

import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import type { FrontendFees } from 'src/containers/screens/Donate/calculateFeesWithHistory'

import {
  CancelDeductionDocument,
  type CancelDeductionMutation,
  type CancelDeductionMutationVariables,
  CancelPayrollDonationFlatFileDocument,
  type CancelPayrollDonationFlatFileMutation,
  type CancelPayrollDonationFlatFileMutationVariables,
  DonationProvider,
} from '../../generated/graphql'
import { mutate } from '../graphql'
import Api from '../api'

import {
  addAction,
  addMultipleAction,
  removeAction,
  donationsForUserLoadedAction,
  donationsForDeedLoadedAction,
  addPlegdeAction,
} from './actions'
import Donation, { DonationType } from './model'

interface SubmitData {
  user: string
  nonprofits: string[]
  currency: CurrencyCode
  date: Date
  receiptUrl: string
  amount: number
  desiredMatchAmount: number | undefined
  donationProvider: DonationProvider
  donationPaymentMethod: string
  desiredMatchingRule?: string
  campaign?: string
}

type DeedOrNonprofit = { deed: string } | { nonprofit: string }
// Inferred from the backend
type RecurringDonationParams = {
  amount: number
  currencyCode: string
  coverFee: boolean
  privacy: string
  donorPrivacy: string
  designation: string
  dedication: string
  billingAddress: string | undefined
  campaign: string
  feesHistoryItem: FrontendFees
  giftAidApplies: boolean | undefined
} & DeedOrNonprofit

interface ResumeRecurringDonationParams {
  paymentToken: string
  donationScheduleId: string
}

// @NOTE-AC: Incomplete. Work in progress.
type CreatePaypalOrderPayload = {
  amount: number
  desiredMatchAmount: number | undefined
  nonprofit?: string
  deed?: string
  currencyCode: CurrencyCode
  coverFee: boolean
  privacy: unknown
  donorPrivacy: unknown
  dedication: unknown
  designation: unknown
  donor: unknown
  campaign?: string
  feeCovered: unknown
  billingAddress: unknown
  feesHistoryItem: unknown
  desiredMatchingRule?: string
  giftAidApplies?: boolean | undefined
}

// @NOTE-AC: Incomplete. Work in progress.
type CreateStripePaymentIntentPayload = {
  desiredMatchAmount: number | undefined
  desiredMatchingRule?: string
}

export default class DonationApi {
  static donate(data: SubmitData): Observable<any> {
    return Api.post<{ donationScheduleId?: string } | Partial<Donation>>(
      `api/donations/${data.donationPaymentMethod || data.donationProvider}`.toLowerCase(),
      data
    ).map((donation) =>
      donation && 'donationScheduleId' in donation && donation.donationScheduleId
        ? addPlegdeAction({ donationScheduleId: donation.donationScheduleId })
        : addAction(new Donation(donation))
    )
  }

  static fetch(id: string): Observable<any> {
    return Api.get(`api/donations/${id}`).map((donation) => addAction(new Donation(donation)))
  }

  static cancelPayroll(
    donationId: string,
    type: DonationType.PayrollDonation | DonationType.PayrollFlatFileDonation
  ): Observable<any> {
    const mutations = {
      [DonationType.PayrollDonation]: () =>
        mutate<CancelDeductionMutation, CancelDeductionMutationVariables>(CancelDeductionDocument, {
          id: donationId,
        }),
      [DonationType.PayrollFlatFileDonation]: () =>
        mutate<CancelPayrollDonationFlatFileMutation, CancelPayrollDonationFlatFileMutationVariables>(
          CancelPayrollDonationFlatFileDocument,
          { donationId }
        ),
    }
    return Observable.from(mutations[type]()).map(() => removeAction(donationId))
  }

  static fetchForUser(userId = 'me', query?: object): Observable<any> {
    return Api.get(`api/donations/users/${userId}${query ? `?${stringify(query)}` : ''}`).map((donations) => [
      addMultipleAction(Map(donations.map((donation: any) => [donation.id, new Donation(donation)]))),
      donationsForUserLoadedAction(userId),
    ])
  }

  static fetchForDeed(deedId: string): Observable<any> {
    return Api.get(`api/donations/deeds/${deedId}`).map((donations) => [
      addMultipleAction(
        Map(donations.map((donation: any) => [donation.id, new Donation({ ...donation, deed: { id: deedId } })]))
      ),
      donationsForDeedLoadedAction(deedId),
    ])
  }

  static updateRecurringDonationCreditCard(recurringId: string, data: any): Observable<any> {
    return Api.patch(`api/donations/external/recurring/${recurringId}`, data)
  }

  static cancelRecurringDonation(recurringId: string): Observable<any> {
    return Api.delete(`api/donations/external/recurring/${recurringId}`)
  }

  static createDonationReference(provider: DonationProvider, destination: string): Observable<any> {
    return Api.post(`api/donations/createDonationReference/${provider}/${destination}`)
  }

  static fetchProviderData(deedId: string, nonprofitId: string): Observable<any> {
    return Api.get(`api/donations/${deedId ? 'deed' : 'nonprofit'}/${deedId || nonprofitId}/provider`)
  }

  static createGiveIndiaDonationReference(data: any): Observable<any> {
    return Api.post('api/donations/createGiveIndiaDonationReference', data)
  }

  static createStripePaymentIntent(data: CreateStripePaymentIntentPayload): Observable<any> {
    return Api.post('api/donations/createStripePaymentIntent', data)
  }

  static registerStripePayment(intentId: string): Observable<any> {
    return Api.post('api/donations/registerStripePayment', { intentId }).map((donation) =>
      addAction(new Donation(donation))
    )
  }

  static createPaypalOrder(data: CreatePaypalOrderPayload): Observable<any> {
    return Api.post('api/donations/createPaypalOrder', data)
  }

  static createRecurringPaypalOrder(data: RecurringDonationParams): Observable<any> {
    return Api.post('api/donations/recurring/paypal/create', data)
  }

  static resumeCaptureRecurringPaypalOrder(data: ResumeRecurringDonationParams): Observable<any> {
    return Api.post('api/donations/recurring/paypal/resumeCapture', data).map((donation) =>
      addAction(new Donation(donation))
    )
  }

  static resumeRecurringPaypalOrder(data: ResumeRecurringDonationParams): Observable<any> {
    return Api.post('api/donations/recurring/paypal/resume', data)
  }

  static capturePaypalOrder(orderId: string): Observable<any> {
    return Api.post('api/donations/capturePaypalOrder', { orderId }).map((donation) =>
      addAction(new Donation(donation))
    )
  }

  static captureRecurringPaypalOrder(data: any): Observable<any> {
    return Api.post('api/donations/recurring/paypal/capture', data).map((donation) => addAction(new Donation(donation)))
  }

  static createCustomerId(): Observable<any> {
    return Api.post('api/donations/recurring/paypal/createCustomerId')
  }

  static estimateMatch(data: {
    nonprofitId: string
    currencyCode: CurrencyCode
    amount: number
    donationDate: Date
  }): Observable<{ matchingPercentage: number; matchingMaximumCurrencies: Record<CurrencyCode, number> } | null> {
    return Api.post('api/donations/estimateMatch', data)
  }
}
