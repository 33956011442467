import advocacy from '../assets/icons/categories/advocacy.svg'
import animals from '../assets/icons/categories/animals.svg'
import arts from '../assets/icons/categories/arts.svg'
import disabilities from '../assets/icons/categories/disabilities.svg'
import children from '../assets/icons/categories/children.svg'
import education from '../assets/icons/categories/education.svg'
import employment from '../assets/icons/categories/employment.svg'
import environment from '../assets/icons/categories/environment.svg'
import foodAccess from '../assets/icons/categories/foodAccess.svg'
import health from '../assets/icons/categories/health.svg'
import homelessness from '../assets/icons/categories/homelessness.svg'
import lgbtq from '../assets/icons/categories/lgbtq.svg'
import mentalHealth from '../assets/icons/categories/mentalHealth.svg'
import mentoring from '../assets/icons/categories/mentoring.svg'
import politics from '../assets/icons/categories/politics.svg'
import seniors from '../assets/icons/categories/seniors.svg'
import sportsRecreation from '../assets/icons/categories/sportsRecreation.svg'
import sustainability from '../assets/icons/categories/sustainability.svg'
import veterans from '../assets/icons/categories/veterans.svg'
import women from '../assets/icons/categories/women.svg'
import youth from '../assets/icons/categories/youth.svg'

export default {
  Advocacy: advocacy,
  Animals: animals,
  Arts: arts,
  Children: children,
  Disabilities: disabilities,
  Education: education,
  Employment: employment,
  Environment: environment,
  'Food Access': foodAccess,
  Health: health,
  Homelessness: homelessness,
  LGBTQ: lgbtq,
  'Mental Health': mentalHealth,
  Mentoring: mentoring,
  Politics: politics,
  Seniors: seniors,
  'Sports & Recreation': sportsRecreation,
  Sustainability: sustainability,
  Veterans: veterans,
  Women: women,
  Youth: youth,
}
