/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { addressToLocation } from 'src/utils/addressToLocation/addressToLocation'
import { css } from 'src/theme/styled'
import { Checkbox, Divider, Spacing, TextField } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import TimePicker from 'src/retired/shared/TimePicker'
import LocationAutocompleteField from 'src/retired/shared/LocationAutocompleteField'
import { getTimeZoneFromGoogle } from 'src/utils/getTimeZoneFromGoogle'
import LocationObject from 'src/entities/locationObject/model'
import { ErrorText } from 'src/components/ErrorText'

import { setStepAction } from '../actions'
import { CommunitySelect } from '../CommunitySelect'

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 8px 16px;
  min-height: 30px;
  height: auto;
  width: 100%;
  font-size: 12px;
`

const Details = ({ formik }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('createDeedScreen')

  const [date, setDate] = useState<Date>(formik.values.startingAt || new Date())

  const handleLocationSelect = (address) => {
    if (!address) {
      formik.setFieldValue('locationLatLng', undefined)
      formik.setFieldValue('locationObject', undefined)
      formik.setFieldValue('location', undefined)
      return
    }

    addressToLocation(address).subscribe(({ location, locationObject }) => {
      formik.setFieldValue('locationLatLng', location)
      formik.setFieldValue('locationObject', locationObject)
      formik.setFieldValue('location', location)

      getTimeZoneFromGoogle(location).subscribe((result) => {
        if (!result) {
          throw Error('Error getting timezone data')
        }
        formik.setFieldValue('timeZone', result.timeZoneId)
      })
    })
  }

  const handleStartingAt = (startingDate: Date | null) => {
    if (startingDate) {
      formik.setFieldValue('startingAt', new Date(date.setHours(startingDate.getHours(), startingDate.getMinutes())))
      if (formik.values.endingAt && formik.values.endingAt.getTime() - startingDate.getTime() <= 0) {
        formik.setFieldValue('endingAt', null)
      }
    } else {
      // Happens when the user clicks the delete button inside the date selector
      formik.setFieldValue('startingAt', null)
    }
    return startingDate
  }

  const handleEndingAt = (endingDate: Date | null) => {
    if (endingDate) {
      const newEndingDateToSet = new Date(date.setHours(endingDate.getHours(), endingDate.getMinutes()))
      if (formik.values.startingAt) {
        if (formik.values.startingAt.getTime() - endingDate.getTime() < 0) {
          formik.setFieldValue('endingAt', newEndingDateToSet)
          return newEndingDateToSet
        }
      } else {
        formik.setFieldValue('endingAt', newEndingDateToSet)
        return newEndingDateToSet
      }
    } else {
      formik.setFieldValue('endingAt', null)
      return endingDate
    }
  }

  const handleDateChange = (newDate: Date | null | undefined) => {
    if (!newDate) {
      return
    }
    setDate(newDate)

    if (formik.values.startingAt) {
      const formikStartingAt = formik.values.startingAt
      const newStartingAt = new Date(newDate.setHours(formikStartingAt.getHours(), formikStartingAt.getMinutes()))
      formik.setFieldValue('startingAt', newStartingAt)
    }

    if (formik.values.endingAt) {
      const formikEndingAt = formik.values.endingAt
      const newEndingAt = new Date(newDate.setHours(formikEndingAt.getHours(), formikEndingAt.getMinutes()))
      formik.setFieldValue('endingAt', newEndingAt)
    }
  }

  return (
    <>
      <Divider />
      <Text fontSize={12}>{t`whatWouldYouLikeToNameYourEvent`}</Text>
      <TextField
        multiline
        scrollEnabled={false}
        value={formik.values.name}
        placeholder={t`chooseANameForYourEvent`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('name', value)}
        onTouched={() => formik.setFieldTouched('name', true, false)}
      />
      {formik.touched.name && !!formik.errors.name && <ErrorText text={formik.errors.name} />}
      <Text fontSize={12}>{t`howWouldYouDescribeYourEvent`}</Text>
      <TextField
        name="description"
        numberOfLines={5}
        multiline
        scrollEnabled={false}
        value={formik.values.description}
        placeholder={t`giveADescriptionForYourEvent`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('description', value)}
        onTouched={() => formik.setFieldTouched('description', true, false)}
      />
      {formik.touched.description && !!formik.errors.description && <ErrorText text={formik.errors.description} />}
      <Text fontSize={12}>{t`whereIsYourEventLocated`}</Text>
      <Spacing marginBottom={10} />
      <Checkbox
        name="virtual"
        checked={formik.values.virtual}
        onChange={(e) => formik.setFieldValue('virtual', e.target.checked)}
        onTouched={() => formik.setFieldTouched('location', true, false)}
        style={{ paddingLeft: 0 }}
      >
        <Text fontSize={12}>{t`thisEventWillBeHostedVirtually`}</Text>
      </Checkbox>
      {formik.values.virtual ? (
        <>
          <TextField
            name="externalLink"
            scrollEnabled={false}
            value={formik.values.externalLink}
            placeholder={t`enterEventMeetingLink`}
            style={roundedInputStyle}
            onChangeText={(e, value) => formik.setFieldValue('externalLink', value)}
            onTouched={() => formik.setFieldTouched('externalLink', true, false)}
          />
          {formik.touched.externalLink && !!formik.errors.externalLink && (
            <ErrorText text={formik.errors.externalLink} />
          )}
        </>
      ) : (
        <>
          <LocationAutocompleteField
            placeholder={t`enterAFullAddress`}
            fieldStyle={roundedInputStyle}
            value={String(new LocationObject(formik.values.locationObject))}
            onSelect={(address) => handleLocationSelect(address)}
            disabled={formik.values.virtual}
            onTouched={() => formik.setFieldTouched('location', true, false)}
          />
          {formik.touched.location && !!formik.errors.location && <ErrorText text={formik.errors.location} />}
        </>
      )}
      <Text fontSize={12}>{t`linkOnly`}</Text>
      <Spacing marginBottom={10} />
      <Checkbox
        name="linkOnly"
        checked={formik.values.linkOnly}
        onChange={(e) => formik.setFieldValue('linkOnly', e.target.checked)}
        style={{ paddingLeft: 0 }}
      >
        <Text fontSize={12}>{t`isThisEventOnlyAccessibleThroughDirectLink`}</Text>
      </Checkbox>
      <Divider />
      <Text fontSize={12} marginBottom={10}>
        {t`dateStartAndEndTime`}
      </Text>
      <View
        style={{
          alignSelf: 'stretch',
          flexGrow: 1,
          flexShrink: 1,
          flexDirection: 'row',
          zIndex: 0,
        }}
      >
        <View>
          <DatePicker
            placeholder={t`date`}
            value={date}
            onChange={(newDate) => handleDateChange(newDate)}
            minDate={new Date()}
            hideClearAction
          />
        </View>
        <View>
          <TimePicker
            name="startingAt"
            value={formik.values.startingAt}
            placeholder={t`startTime`}
            onChange={(newDate) => handleStartingAt(newDate)}
            onOpenChange={() => formik.setFieldTouched('startingAt', true, false)}
            style={{ height: 33, marginLeft: 10, marginBottom: 16 }}
            onBlur={() => {
              if (!formik.values.endingAt && formik.values.startingAt) {
                const startingDate = new Date(formik.values.startingAt)
                formik.setFieldValue('endingAt', new Date(startingDate.setHours(startingDate.getHours() + 2)))
              }
            }}
          />
          {formik.touched.startingAt && !!formik.errors.startingAt && (
            <View style={{ marginLeft: 14 }}>
              <ErrorText text={formik.errors.startingAt} />
            </View>
          )}
        </View>
        <View>
          <TimePicker
            name="endingAt"
            value={formik.values.endingAt}
            placeholder={t`endTime`}
            onChange={(newDate) => handleEndingAt(newDate)}
            onOpenChange={() => formik.setFieldTouched('endingAt', true, false)}
            style={{ height: 33, marginLeft: 10, marginBottom: 16 }}
          />
          {formik.touched.endingAt && !!formik.errors.endingAt && (
            <View style={{ marginLeft: 14 }}>
              <ErrorText text={formik.errors.endingAt} />
            </View>
          )}
        </View>
      </View>
      <Divider marginTop={15} />
      <Text fontSize={12} weight="bold" marginBottom={10}>
        {t`volunteerInformation`}
      </Text>
      <Text fontSize={12}>{t`whatAreTheVolunteerDuties`}</Text>
      <TextField
        name="duties"
        numberOfLines={5}
        multiline
        scrollEnabled={false}
        value={formik.values.duties}
        placeholder={t`giveABriefDescription`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('duties', value)}
        onTouched={() => formik.setFieldTouched('duties', true, false)}
      />
      {formik.touched.duties && !!formik.errors.duties && <ErrorText text={formik.errors.duties} />}
      <Text fontSize={12}>{t`whatAreTheMustKnows`}</Text>
      <TextField
        name="mustKnows"
        numberOfLines={5}
        multiline
        scrollEnabled={false}
        value={formik.values.mustKnows}
        placeholder={t`pleaseProvideAnyEssentialInformation`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('mustKnows', value)}
        onTouched={() => formik.setFieldTouched('mustKnows', true, false)}
      />
      {formik.touched.mustKnows && !!formik.errors.mustKnows && <ErrorText text={formik.errors.mustKnows} />}
      <Text fontSize={12}>{t`howManyPeopleWillBeNeeded`}</Text>
      <TextField
        name="attendeeLimit"
        type="number"
        pattern="\d{1,3}"
        step="1"
        min="1"
        keyboardType="number-pad"
        returnKeyType="done"
        scrollEnabled={false}
        value={formik.values.attendeeLimit}
        placeholder={t`overestimate`}
        style={roundedInputStyle}
        onChangeText={(e, value) => formik.setFieldValue('attendeeLimit', value)}
        onTouched={() => formik.setFieldTouched('attendeeLimit', true, false)}
      />
      {formik.touched.attendeeLimit && !!formik.errors.attendeeLimit && (
        <ErrorText text={formik.errors.attendeeLimit} />
      )}

      <CommunitySelect
        onChange={(communityIds) => formik.setFieldValue('organizingCommunityIds', communityIds)}
        value={formik.values.organizingCommunityIds}
      />

      <Spacing marginTop={30}>
        <Button
          palette="primary"
          disabled={
            !!(
              formik.errors.name ||
              formik.errors.description ||
              (formik.values.virtual ? formik.errors.externalLink : formik.errors.location) ||
              formik.errors.startingAt ||
              formik.errors.endingAt ||
              formik.errors.duties ||
              formik.errors.mustKnows ||
              formik.errors.attendeeLimit ||
              !date
            )
          }
          onPress={() => dispatch(setStepAction(2))}
        >
          {t`next`}
        </Button>
      </Spacing>
    </>
  )
}

Details.displayName = 'Details'
export default Details
