/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import { Body2 } from 'src/retired/shared/Typography'

import { type ProviderData } from '../payment/useProviderData'

enum SupportedCountryCodes {
  US = 'US',
  AU = 'AU',
  GB = 'GB',
  CA = 'CA',
  IE = 'IE',
}

type SupportedProviders = Extract<DonationProvider, DonationProvider.PayPal | DonationProvider.CAF>

interface Props {
  providerData: ProviderData<SupportedProviders>
}

type LegalLinks = {
  terms: string
  donationDelivery: string
  deliveryPolicy: string
}

type ProviderLegalLinks = {
  [provider in SupportedProviders]: {
    [code in SupportedCountryCodes]?: LegalLinks
  }
}

const providerLegalLinks: ProviderLegalLinks = {
  [DonationProvider.PayPal]: {
    US: {
      terms: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service',
      donationDelivery:
        'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-2',
      deliveryPolicy: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-5',
    },
    GB: {
      terms: '',
      donationDelivery: '',
      deliveryPolicy: 'https://www.paypal.com/uk/webapps/mpp/givingfund/policies#DonationDeliveryPolicy',
    },
    AU: {
      terms: 'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donor-terms-of-service',
      donationDelivery: '',
      deliveryPolicy: 'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
    },
    IE: {
      terms: 'https://www.paypal.com/ie/webapps/mpp/givingfund/donortermsofservice',
      donationDelivery: '',
      deliveryPolicy: 'https://www.paypal.com/ie/webapps/mpp/givingfund/donation-delivery-policy',
    },
    CA: {
      terms: 'https://www.paypal.com/ca/webapps/mpp/givingfund/policies/donor-terms-of-service',
      donationDelivery: '',
      deliveryPolicy: 'https://www.paypal.com/CA/webapps/mpp/givingfund/policies/donation-delivery-policy',
    },
  },
  [DonationProvider.CAF]: {
    US: {
      terms: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service',
      donationDelivery:
        'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-2',
      deliveryPolicy: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-5',
    },
  },
}

const PayPalMessage = ({ providerData }: Props) => {
  const { t } = useTranslation('donateScreen')

  const isPayPalDonation = providerData.donationProvider === DonationProvider.PayPal
  const isCAFDonation = providerData.donationProvider === DonationProvider.CAF

  const countryContext =
    (isPayPalDonation && (providerData.countryCode as SupportedCountryCodes)) || SupportedCountryCodes.US

  const ppgfMessage = isPayPalDonation && providerData.isEnrolled ? 'PPGFMessageEnrolled' : 'PPGFMessageUnenrolled'
  const cafMessage = 'PPGFMessageEnrolled_CAF'

  const translationKey = isPayPalDonation ? ppgfMessage : cafMessage

  const legalLinks = providerLegalLinks[providerData.donationProvider][countryContext]

  if (
    (!(isPayPalDonation && providerData.payPalModel === 'PPGF' && providerData.countryCode) && !isCAFDonation) ||
    !legalLinks
  ) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{t`yourDonationWillBeSentDirectly`}</>
  }

  return (
    <Body2>
      <Trans
        t={t}
        i18nKey={translationKey}
        components={{
          Terms: <a target="_blank" href={legalLinks.terms} />,
          DonationDelivery: <a target="_blank" href={legalLinks.donationDelivery} />,
          DeliveryPolicy: <a target="_blank" href={legalLinks.deliveryPolicy} />,
        }}
        tOptions={{ context: countryContext }}
      />
    </Body2>
  )
}

export default PayPalMessage
