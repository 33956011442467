import React from 'react'
import { useTranslation } from 'react-i18next'

import { Community, CommunityUserRole } from 'src/generated/graphql'
import { CompactCard } from 'src/components/CompactCard'

export const CommunityCompact = ({
  community,
  role,
}: {
  community: Community
  role?: CommunityUserRole
}): JSX.Element => {
  const { t } = useTranslation('communityCompact')

  const { members, membersCount } = community

  const membersCountWithoutCurrentUser = membersCount - 1

  return (
    <CompactCard
      link={`/community/${community.id}`}
      image={community.tileImage || community.headerImage}
      title={community.title}
      description={community.description}
      label={role === CommunityUserRole.Lead ? t`communityLead` : undefined}
      avatarItems={community?.members.map((member) => ({
        id: member?.User.id,
        mainPicture: member?.User.mainPicture,
        fullName: member?.User.fullName,
        preferredName: member?.User.preferredName,
      }))}
      numNotShownAvatars={
        members && membersCountWithoutCurrentUser && membersCountWithoutCurrentUser > members.length
          ? membersCountWithoutCurrentUser - members.length
          : undefined
      }
    />
  )
}

export default CommunityCompact
