import React, { type Dispatch, type SetStateAction, useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import MuiButton from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'

import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import {
  PayPalHostedFieldContainer,
  PayPalError,
} from 'src/containers/screens/Donate/elements/PayPalHostedFieldContainer'
import config from 'src/config'
import getPayPalLanguageCode from 'src/utils/getPayPalLanguageCode'
import { Body } from 'src/retired/shared/Typography'
import getFullPaymentMethodName from 'src/utils/paymentMethods'
import { Platform } from 'src/utils'
import { DonationProvider } from 'src/generated/graphql'
import DonationSchedule from 'src/entities/donationSchedule/model'
import DonationApi from 'src/entities/donation/api'
import { ActivityIndicator } from 'src/retired/elements'
import { colors } from 'src/theme'
import { ErrorText } from 'src/components/ErrorText'
import * as Sentry from 'src/utils/Sentry'

import epics from '../Donate/epics'
import reducer from '../Donate/reducer'
import { registerCaptureRecurringPaypalDonationAction } from '../Donate/actions'

interface EditPayPalPaymentProps {
  donationSchedule: DonationSchedule
}

const StyledErrorText = ({ text }: { text: string }): JSX.Element => <ErrorText text={text} style={{ marginTop: 0 }} />

export const EditPaymentMethod = ({ donationSchedule }: EditPayPalPaymentProps) => {
  useInjectReducer({ key: 'donate', reducer })
  useInjectEpics({ key: 'donate', epics })

  const [loading, setLoading] = useState(false)
  const [tokens, setTokenData] = useState<{ paymentToken: string; clientToken: string }>()
  const { t } = useTranslation('donateScreen')

  const [cardNumberError, setCardNumberError] = useState('')
  const [cardExpiryError, setCardExpityError] = useState('')

  const dispatch = useDispatch()

  const createCustomerID = useCallback(async () => {
    setTokenData(undefined)
    setLoading(true)
    const tokenData = await DonationApi.createCustomerId()
      .toPromise()
      .then(({ paymentToken, client_token }: { paymentToken: string; client_token: string }) => ({
        paymentToken,
        clientToken: client_token,
      }))
    setTokenData(tokenData)
    setLoading(false)
    return tokenData
  }, [])

  useEffect(() => {
    void createCustomerID()
  }, [createCustomerID, donationSchedule])

  const createOrder = useMemo(
    () =>
      !!tokens &&
      (async () => {
        setLoading(true)
        setCardNumberError('')
        setCardExpityError('')
        return DonationApi.resumeRecurringPaypalOrder({
          paymentToken: tokens.paymentToken,
          donationScheduleId: donationSchedule.id,
        })
          .toPromise()
          .then(({ orderId }: { orderId: string }) => orderId)
      }),
    [donationSchedule.id, tokens]
  )

  const onPaymentApproved = useMemo(
    () =>
      !!tokens &&
      (async ({ orderId }: { orderId: string }) => {
        setLoading(true)
        dispatch(
          registerCaptureRecurringPaypalDonationAction({
            orderId,
            paymentToken: tokens.paymentToken,
            donationScheduleId: donationSchedule.id,
          })
        )
        return Promise.resolve()
      }),
    [dispatch, donationSchedule.id, tokens]
  )

  const onPaymentError = (payPalError: Error | PayPalError) => {
    setLoading(false)
    // @TODO-RB: This should go into a component for reusability
    if ('details' in payPalError) {
      setCardNumberError(
        payPalError?.details?.find((err) => err?.field === '/payment_source/card/number')?.description || ''
      )
      setCardExpityError(
        payPalError?.details?.find((err) => err?.field === '/payment_source/card/expiry')?.description || ''
      )
    } else {
      Sentry.captureException(
        payPalError instanceof Error ? payPalError : new Error(payPalError.message || 'Unexpected PayPal error'),
        { extra: { payPalError } }
      )
    }
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: { xs: 1, md: 2 },
        marginTop: 1,
        background: 'transparent',
      }}
      square
    >
      {(loading || !tokens || !createOrder || !onPaymentApproved) && (
        <Grid m={5}>
          <ActivityIndicator color={colors.pink} size={40} />
        </Grid>
      )}
      {tokens && createOrder && onPaymentApproved && (
        <PayPalHostedFieldContainer
          scriptOptions={{
            paypalClientId: config.paypalClientId,
            dataClientToken: tokens.clientToken,
          }}
          onError={onPaymentError}
          createOrder={createOrder}
          onApprove={onPaymentApproved}
        />
      )}
      {!!cardNumberError && (
        <div>
          <StyledErrorText text={`${t('cardNumber')}: ${cardNumberError}`} />
        </div>
      )}
      {!!cardExpiryError && (
        <div>
          <StyledErrorText text={`${t('expirationDate')}: ${cardExpiryError}`} />
        </div>
      )}
    </Paper>
  )
}

const PaymentMethodLabel = ({
  editingState: [editing, setEditing],
  isEditable,
}: {
  editingState: [boolean, Dispatch<SetStateAction<boolean>>]
  isEditable: boolean
}) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')
  if (Platform.OS !== 'web') {
    return <Body style={{ marginBottom: 10 }}>{t`paymentMethod`}</Body>
  }
  return (
    <Grid container sx={{ justifyContent: 'space-between' }}>
      <Grid item>
        <Body marginBottom={10}>{t`paymentMethod`}</Body>
      </Grid>
      {isEditable && (
        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MuiButton
            variant="text"
            onClick={() => setEditing((v) => !v)}
            startIcon={editing ? <HighlightOffRoundedIcon /> : <TuneOutlinedIcon />}
          >
            {editing ? t('common:Cancel') : t('common:edit')}
          </MuiButton>
        </Grid>
      )}
    </Grid>
  )
}

interface PaymentMethodProps {
  donationSchedule: DonationSchedule
}
export const PaymentMethod = ({ donationSchedule }: PaymentMethodProps) => {
  const { i18n } = useTranslation('donationScheduleDetailsScreen')
  const isEditable = Platform.OS === 'web' && donationSchedule?.provider === DonationProvider.PayPal
  const editingState = useState(false)
  const hasErrors = Boolean(donationSchedule.executionFailure && donationSchedule.executionFailure.length > 0)
  const isPayroll = donationSchedule?.isPayroll()
  return (
    <>
      <PaymentMethodLabel editingState={editingState} isEditable={isEditable && hasErrors} />
      <Body weight="500">
        {isPayroll ? getFullPaymentMethodName(donationSchedule.paymentMethod?.type) : donationSchedule.provider}
      </Body>
      {editingState[0] && (
        <EditPaymentMethod
          donationSchedule={donationSchedule}
          currency={donationSchedule.currencyCode}
          locale={getPayPalLanguageCode(i18n.language) || undefined}
        />
      )}
    </>
  )
}
