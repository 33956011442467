import { Map } from 'immutable'

import Api from '../api'

import Skill from './model'
import { addMultipleAction } from './actions'

export default class SkillsApi {
  static fetchAll() {
    return Api.get('api/skills').map((skills) =>
      addMultipleAction(Map(skills.map((skill: any) => [skill.id, new Skill(skill)])))
    )
  }
}
