import { fromJS } from 'immutable'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  LOAD_FEED_FOR_DEED_TYPE,
  LOAD_FEED_FOR_DEED_TYPE_SUCCESS,
  LOAD_FEED_FOR_DEED_TYPE_FAILURE,
  SET_SCROLL_POSITION,
  RESET_SCROLL_POSITIONS,
} from './constants'

export const feedLoaded = fromJS({
  page: 0,
  moreResults: false,
  totalItems: 0,
  loaded: false,
  loading: false,
  error: null,
})

export const initialState = fromJS({
  Campaign: feedLoaded,
  Project: feedLoaded,
  Event: feedLoaded,
  BaseEvent: feedLoaded,
  loading: false,
  error: null,
  scrollPositions: {},
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ error: null, loading: true })

    case INIT_SUCCESS:
      return state.merge({ error: null, loading: false })

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case LOAD_FEED_FOR_DEED_TYPE:
      return state.setIn([action.deedType, 'loading'], true)

    case LOAD_FEED_FOR_DEED_TYPE_SUCCESS:
      return state
        .setIn([action.deedType, 'error'], null)
        .setIn([action.deedType, 'loading'], false)
        .setIn([action.deedType, 'loaded'], true)
        .setIn([action.deedType, 'moreResults'], action.payload.moreResults)
        .setIn([action.deedType, 'totalItems'], action.payload.totalItems)
        .setIn([action.deedType, 'page'], action.payload.page)

    case LOAD_FEED_FOR_DEED_TYPE_FAILURE:
      return state.setIn([action.deedType, 'error'], action.error).setIn([action.deedType, 'loading'], false)

    case SET_SCROLL_POSITION:
      return state.setIn(['scrollPositions', action.location], action.scrollPosition)

    case RESET_SCROLL_POSITIONS:
      return state.set('scrollPositions', initialState.get('scrollPositions'))

    case 'RESET':
      return initialState

    default:
      return state
  }
}
