import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startOfDay, parseISO } from 'date-fns'

import { setSelectedDateAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedDateSynchronizer = () => {
  const dispatch = useDispatch()
  const { date } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    dispatch(setSelectedDateAction(typeof date === 'string' ? startOfDay(parseISO(date)) : null))
  }, [date])

  return null
}
