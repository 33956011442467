import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { historyStack, useHistory } from 'src/navigation'
import { useInjectEpics, useInjectReducer } from 'src/utils'

import { TabType, getSearch } from '../../GeneralSearch/utils'
import { initAction } from '../../Profile/VolunteerTime/types'
import epics from '../epics'
import reducer from '../reducer'

import { VolunteerRewardEligibilityUI } from './VolunteerRewardEligibilityUI'

export const VolunteerRewardEligibility = ({
  areResponsesValid,
  volunteerTimeId,
}: {
  areResponsesValid: boolean
  volunteerTimeId: string
}) => {
  const { t } = useTranslation('volunteerRewardEligibility')
  const dispatch = useDispatch()
  const history = useHistory()

  useInjectEpics({ key: 'volunteerTime', epics })
  useInjectReducer({ key: 'volunteerTime', reducer })

  const dismissScreenPath = historyStack.reverse().find((path: string) => !path.includes('volunteer-hours')) || ''
  const user = useSelector(selectCurrentUser)
  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, volunteerTimeId))

  useEffect(() => {
    if (!volunteerTime) {
      dispatch(initAction(volunteerTimeId))
    }
  }, [dispatch, volunteerTimeId])

  const handleFindNextOpportunity = () => {
    history.push({
      pathname: '/search',
      search: getSearch({
        tab: TabType.volunteer,
      }),
    })
  }

  const handleClose = () => {
    history.replace(dismissScreenPath)
  }

  return (
    <VolunteerRewardEligibilityUI
      headerTitle={t`applyForVolunteerReward`}
      headerSubtitle={t`volunteerRewardEligibilityReview`}
      illustration={areResponsesValid ? 'womanStarIllustration' : 'thinkingHumanIllustration'}
      styleIllustration={{ width: areResponsesValid ? 125 : 90 }}
      contentTitle={areResponsesValid ? t`yourVolunteerHourEligible` : t`awShucks`}
      content={areResponsesValid ? t`positiveDescription` : t`negativeDescription`}
      buttonText={areResponsesValid ? t`ctaFindYourNextOpportunity` : t`common:Close`}
      onPressCta={areResponsesValid ? handleFindNextOpportunity : handleClose}
      loading={!user}
    />
  )
}

export default VolunteerRewardEligibility
