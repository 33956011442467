import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import { IncentiveData } from 'src/entities/campaign/model'
import { ExternalLink } from 'src/retired/elements'
import { selectUserBrand } from 'src/entities/user/selectors'
import Icon from 'src/retired/shared/Icon'
import { formatAmount } from 'src/utils/format'
import { Tooltip } from 'src/components/Tooltip/Tooltip'
import { IncentiveUnit } from 'src/containers/modules/Actions/types'

import { CampaignStatsCard } from './CampaignStatsCard'

export const ActionIncentiveCard = ({
  incentiveData,
  numCompletedActions,
}: {
  incentiveData: IncentiveData
  numCompletedActions?: number
}) => {
  const { unit, amount } = incentiveData
  const { t } = useTranslation('campaignScreen')
  const { metrics, colors, icons } = useDeedTheme()
  const brand = useSelector(selectUserBrand)

  const primaryStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }

  const secondaryStyle = {
    fontSize: 16,
    color: colors.gray08,
    textAlign: metrics.isLarge ? 'center' : 'left',
    width: metrics.isLarge ? 'auto' : 120,
  }

  const numberStyle = {
    fontSize: amount >= 1000 ? 24 : 32,
  }

  if (numCompletedActions === 0 || amount === 0) {
    return null
  }

  switch (unit) {
    case IncentiveUnit.co2: {
      return (
        <CampaignStatsCard
          primaryElement={
            <View style={{ paddingTop: 4, ...primaryStyle }}>
              <View style={{ paddingRight: 8 }}>
                <Icon icon="cloud" width={32} />
              </View>
              <Trans
                t={t}
                i18nKey="kg"
                values={{ amount: formatAmount(amount, true) }}
                components={{
                  Text: <Body1 style={{ fontSize: 16, color: colors.gray08 }} />,
                  BoldText: <H3 style={numberStyle} />,
                }}
              />
            </View>
          }
          secondaryElement={
            <Body1 style={{ ...secondaryStyle, marginLeft: metrics.isLarge ? 0 : 8 }}>{t('co2Saved')}</Body1>
          }
        />
      )
    }

    case IncentiveUnit.USD: {
      return typeof numCompletedActions === 'number' ? (
        <CampaignStatsCard
          primaryElement={
            <View style={primaryStyle}>
              <View style={{ paddingRight: 8, paddingBottom: 8 }}>
                <Icon icon="clap" width={32} />
              </View>
              <H3 style={numberStyle}>{formatAmount(numCompletedActions, true)}</H3>
            </View>
          }
          secondaryElement={
            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Body1 style={secondaryStyle}>{t('amountActions', { count: numCompletedActions })}</Body1>
              <Body1 style={{ ...secondaryStyle, marginTop: -2 }}>{t('completed')}</Body1>
            </View>
          }
        />
      ) : null
    }

    case IncentiveUnit.tree: {
      return (
        <CampaignStatsCard
          primaryElement={
            <View style={primaryStyle}>
              <View style={{ paddingRight: 8, paddingBottom: 8 }}>
                <Icon icon="trees" width={32} />
              </View>
              <H3 style={{ ...numberStyle, paddingRight: 10 }}>{formatAmount(amount, true)}</H3>
              <Tooltip
                placement="top"
                hoverIcon={icons.infoCircleOutlinedBlack}
                title={
                  <View style={{ padding: 20 }}>
                    <Body1
                      style={{
                        textAlign: 'center',
                        color: colors.white,
                      }}
                    >
                      <Trans
                        t={t}
                        i18nKey="treesTooltip"
                        components={{
                          Link: (
                            <ExternalLink
                              href="https://onetreeplanted.org/"
                              size={16}
                              style={{
                                textDecorationLine: 'underline',
                                color: colors.white,
                              }}
                            />
                          ),
                        }}
                      />
                      <View style={{ paddingTop: 8 }}>
                        <Body1 style={{ textAlign: 'center' }}>{t('treesTooltipSub')}</Body1>
                      </View>
                    </Body1>
                  </View>
                }
              />
            </View>
          }
          secondaryElement={
            <Body1 style={secondaryStyle}>
              {brand?.name
                ? t('treesPlantedCompany', { count: amount, company: brand.name })
                : t('treesPlanted', { count: amount })}
            </Body1>
          }
        />
      )
    }

    default:
      return null
  }
}
