import config from 'src/config'

export const getAnalyticsContext = ({ userId }: { userId: string | undefined }) => ({
  eventSource: {
    name: 'app-web',
  },
  userId,
  environment: config.environment,
  publishedAt: new Date().toString(),
  userAgent: window.navigator.userAgent,
})
