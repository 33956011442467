import { INIT, INIT_SUCCESS, INIT_FAILED, SET_SELECTED_COMPANY_ID, LOGIN, SET_URL, UNSET_URL } from './constants'

export const initAction = (preSelectedCompanyId) => ({
  type: INIT,
  preSelectedCompanyId,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const setSelectedCompanyIdAction = (companyId) => ({
  type: SET_SELECTED_COMPANY_ID,
  companyId,
})

export const loginAction = (username) => ({
  type: LOGIN,
  username,
})

export const setUrlAction = (url) => ({
  type: SET_URL,
  url,
})
export const unsetUrlAction = () => ({
  type: UNSET_URL,
})
