import { SET } from './constants'
import Setting from './model'

export const initialState = new Setting()

export default (state: Setting = initialState, action: any): Setting => {
  switch (action.type) {
    case SET:
      return action.settings
  }
  return state
}
