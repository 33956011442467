import { goBack } from 'connected-react-router'
import { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import VolunteerTimeApi from 'src/entities/volunteerTime/api'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'

import {
  INIT,
  DELETE,
  initSuccessAction,
  initFailedAction,
  deleteSuccessAction,
  deleteFailedAction,
  InitAction,
  DeleteAction,
} from './types'

const init = (action$: ActionsObservable<InitAction>, store: Store) =>
  action$.ofType(INIT).exhaustMap(({ id }) => {
    const state = store.getState()
    const volunteerTime = selectVolunteerTimeById(state, id)

    if (!volunteerTime) {
      return VolunteerTimeApi.getByFilter({ id: { equals: id } })
        .mergeMap((resultingAction) => [resultingAction, initSuccessAction()])
        .catch((e) => [initFailedAction(e), showErrorAction(i18n.t('volunteerTimeProfile:loadingFailed'))])
    }

    return []
  })

const del = (action$: ActionsObservable<DeleteAction>) =>
  action$.ofType(DELETE).exhaustMap(({ id, skipGoBack }) =>
    VolunteerTimeApi.delete(id)
      .mergeMap((resultingAction) => [
        resultingAction,
        deleteSuccessAction(),
        showMessageAction(i18n.t('volunteerTimeProfile:deleted')),
        ...(skipGoBack ? [] : [goBack()]),
      ])
      .catch((e) => [deleteFailedAction(e), showErrorAction(i18n.t('volunteerTimeProfile:deletingFailed'))])
  )

export default [init, del]
