import { Map, OrderedSet, fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import { TypedMap } from 'src/utils/typed-map'

import {
  NONPROFITS_LOADED,
  COMPANIES_LOADED,
  ADD,
  ADD_MULTIPLE,
  SET_DISPLAYED_LOCAL_ORGANIZATION_IDS,
  SET_DISPLAYED_PARTNER_ORGANIZATION_IDS,
  CLEAR_DISPLAYED_ORGANIZATION_IDS,
  FOR_CAUSES_LOADED,
  CLEAR,
} from './constants'
import Organization from './model'

export type OrganizationMap = Map<string, Organization>

export type OrganizationState = TypedMap<{
  organizations: OrganizationMap
  displayedPartnerOrganizationIds: OrderedSet<string>
  displayedLocalOrganizationIds: OrderedSet<string>
  nonprofitsLoaded: boolean
  companiesLoaded: boolean
  forCausesLoaded: string[]
}>

const initialState: OrganizationState = fromJS({
  organizations: Map<string, Organization>(),
  displayedPartnerOrganizationIds: OrderedSet<string>(),
  displayedLocalOrganizationIds: OrderedSet<string>(),
  nonprofitsLoaded: false,
  companiesLoaded: false,
  forCausesLoaded: [],
})

export default (state: OrganizationState = initialState, action: AnyAction): OrganizationState => {
  switch (action.type) {
    case NONPROFITS_LOADED:
      return state.set('nonprofitsLoaded', true)

    case COMPANIES_LOADED:
      return state.set('companiesLoaded', true)

    case ADD:
      return state.set('organizations', state.get('organizations').set(action.organization.id, action.organization))

    case ADD_MULTIPLE:
      return state.set('organizations', state.get('organizations').merge(action.organizations))

    case SET_DISPLAYED_LOCAL_ORGANIZATION_IDS:
      return state.set(
        'displayedLocalOrganizationIds',
        state.get('displayedLocalOrganizationIds').size
          ? state.get('displayedLocalOrganizationIds').merge(action.organizationIds)
          : action.organizationIds
      )

    case SET_DISPLAYED_PARTNER_ORGANIZATION_IDS:
      return state.set(
        'displayedPartnerOrganizationIds',
        state.get('displayedPartnerOrganizationIds').size
          ? state.get('displayedPartnerOrganizationIds').merge(action.organizationIds)
          : action.organizationIds
      )

    case CLEAR_DISPLAYED_ORGANIZATION_IDS:
      return state.merge({
        displayedLocalOrganizationIds: initialState.get('displayedLocalOrganizationIds'),
        displayedPartnerOrganizationIds: initialState.get('displayedPartnerOrganizationIds'),
      })

    case FOR_CAUSES_LOADED:
      return state.set('forCausesLoaded', [...state.get('forCausesLoaded'), ...action.causesIds])

    case CLEAR:
    case 'RESET':
      return initialState
  }
  return state
}
