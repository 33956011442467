/* eslint-disable react/no-unstable-nested-components */
import { Alert } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import Button from 'src/retired/shared/Button'
import { InformationCard } from 'src/components'
import { CurrencyCode, CurrencyFormat, currencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useCurrentPledgeQuery } from 'src/generated/graphql'
import { ActivityIndicator, LinkButton } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import ProgressBar from 'src/retired/shared/ProgressBar'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

const Pledge = ({
  donated,
  target,
  currencyCode,
  editable,
}: {
  donated: number
  target: number
  currencyCode: CurrencyCode
  editable: boolean
}) => {
  const { t } = useTranslation('donationWidget')
  const { colors } = useDeedTheme()

  return (
    <View style={{ width: '100%', marginTop: 24 }}>
      <ProgressBar
        items={[
          {
            value: Math.min(target, donated),
            color: colors.brandColor,
          },
        ]}
        height={5}
        total={target}
        backgroundColor={colors.borderGray}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Body2 style={{ fontSize: 12 }}>
          {target ? (
            <Trans
              t={t}
              i18nKey="progress"
              components={{
                Highlight: <Body2 style={{ fontSize: 12 }} colour={colors.brandColor} weight="500" />,
              }}
              values={{
                donated: currencyFormat(donated, currencyCode),
                total: currencyFormat(target, currencyCode),
              }}
            />
          ) : (
            t`setAGoalMessage`
          )}
        </Body2>
        <LinkButton
          to="/pledge"
          Container={(props) => (
            <Button
              {...props}
              palette="secondary"
              style={{ border: 0, textDecoration: 'underline', backgroundColor: 'transparent' }}
            >
              {target ? (editable ? t`editGoal` : t`viewGoal`) : t`setGoal`}
            </Button>
          )}
        />
      </View>
    </View>
  )
}

const DonationWidget = ({ style }: { style?: React.CSSProperties }) => {
  const user = useSelector(selectCurrentUser)
  const { t } = useTranslation('donationWidget')
  const { colors, metrics } = useDeedTheme()
  const donationsEnabled = user?.hasFeature('donations')
  const pledgeEnabled = user?.hasFeature('pledge')

  const { data: pledgeData, loading, error } = useCurrentPledgeQuery({ skip: !donationsEnabled })
  const displayCurrency = useCurrency()

  if (!donationsEnabled || !user) {
    return null
  }

  const donationCreditEnabled = user.hasFeature('donationCredit')
  const donationMatchingEnabled = user.hasFeature('donationMatching')

  if (loading && pledgeEnabled) {
    return (
      <InformationCard style={style} childrenStyle={{ flex: 1 }}>
        <ActivityIndicator color={colors.brandColor} />
      </InformationCard>
    )
  }

  if (error) {
    return (
      <InformationCard style={style}>
        <Alert severity="error">{t`common:thereWasAnErrorLoading`}</Alert>
      </InformationCard>
    )
  }

  const donatedGeneral = user.donationsAmountCurrencies
  const donatedForPledge = pledgeData?.currentPledge?.donations?.donated || 0
  const currencyCode = (pledgeData?.currentPledge?.donations?.currencyCode || 'USD') as CurrencyCode
  const target = pledgeData?.currentPledge?.donations?.amount || 0
  const showEmptyState = !donationCreditEnabled && !donationMatchingEnabled && !pledgeEnabled
  const showBackupMessage =
    [pledgeEnabled, donationCreditEnabled, donationMatchingEnabled].filter((featureFlag) => !featureFlag).length >= 2
  const givingGoalConfigPresent = pledgeData?.currentPledge?.givingGoalConfig

  return (
    <InformationCard
      style={style}
      title={
        pledgeEnabled && target ? (
          <CurrencyFormat amount={donatedForPledge} baseCurrency={currencyCode} />
        ) : donatedGeneral['USD' as CurrencyCode] ? (
          <CurrencyFormat amountCurrencies={donatedGeneral} />
        ) : (
          t`donate`
        )
      }
      subtitle={
        pledgeEnabled && target ? (
          <Trans
            t={t}
            i18nKey={givingGoalConfigPresent ? 'donatedInTimeframe' : 'donatedInYear'}
            values={{
              year: pledgeData?.currentPledge?.id || new Date().getFullYear(),
              dateFrom: givingGoalConfigPresent && new Date(pledgeData?.currentPledge?.givingGoalConfig?.startDate),
              dateTo: givingGoalConfigPresent && new Date(pledgeData?.currentPledge?.givingGoalConfig?.endDate),
            }}
          />
        ) : donatedGeneral['USD' as CurrencyCode] ? (
          t`donated`
        ) : (
          t`subtitle`
        )
      }
      buttonText={t`searchOrganization`}
      buttonTo="/search?tab=donate"
      bottomText={showBackupMessage ? t`startBrowsing` : undefined}
      bottomTextIconName="heartMessage"
    >
      {showEmptyState ? (
        <View style={{ alignItems: 'center', marginTop: 24 }}>
          <Icon icon="starBadge" height={metrics.isSmall ? 85 : 120} />
        </View>
      ) : (
        <View style={{ width: '100%' }}>
          {donationCreditEnabled ? (
            <View style={{ width: '100%', marginTop: 24 }}>
              <H5 colour={colors.brandColor} weight="500">
                <CurrencyFormat
                  amount={user.donationCreditsWallet?.balance ?? 0}
                  baseCurrency={user.donationCreditsWallet?.currencyCode ?? displayCurrency?.code}
                />
              </H5>
              <Body2>{t`donationCreditsRemaining`}</Body2>
            </View>
          ) : null}
          {donationMatchingEnabled ? (
            <View style={{ width: '100%', marginTop: 24 }}>
              <H5 colour={colors.brandColor} weight="500">
                <CurrencyFormat amountCurrencies={user._matchedAmountTotalCurrencies} />
              </H5>
              <Body2>{t`matched`}</Body2>
            </View>
          ) : null}
          {pledgeEnabled ? (
            <Pledge
              donated={donatedForPledge}
              target={target}
              currencyCode={currencyCode}
              editable={pledgeData?.currentPledge?.givingGoalConfig?.isEditableByEmployee ?? true}
            />
          ) : null}
        </View>
      )}
    </InformationCard>
  )
}

export default DonationWidget
