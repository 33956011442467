export default class Communications {
  public static phonecall(number: string) {
    window.open(`tel:${number}`)
  }

  public static email(emails: string[], _cc: string, _bcc: string, subject: string, body: string) {
    window.open(`mailto:${emails ? emails.join(',') : ''}${subject && `?subject=${subject}`}${body && `&body=${body}`}`)
  }

  public static web(address: string, name?: string, options?: string) {
    window.open(address, name, options)
  }
}
