import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { resize } from 'src/utils/resize'
import { selectUserBrand } from 'src/entities/user/selectors'
import { useHistory, Link } from 'src/navigation'
import { CauseChips } from 'src/containers/screens/Actions/CauseChips'
import { Action, ActivityStatus, IncentiveType } from 'src/containers/modules/Actions/types'
import { useActivityMutations } from 'src/containers/modules/Actions/hooks'
import { SuccessModals } from 'src/containers/screens/Actions/Modals/SuccessModals'
import { ConfirmCompletionModal } from 'src/containers/screens/Actions/Modals/ConfirmCompletionModal'

import { AvatarRow } from '../../AvatarRow'
import { CampaignTag } from '../CampaignTag'
import { EndDateTag } from '../EndDateTag'

import { RewardChip } from './RewardChip'
import {
  CardWrapper,
  CompanyIconWrapper,
  ContentWrapper,
  RewardWrapper,
  Title,
  SubTitleWrapper,
  SubTitle,
  CauseWrapper,
  BannerImage,
  CompanyIcon,
} from './styles'
import { ActionButton } from './ActionButton'
import { handleActionButtonPress, isDateInThePast } from './utils'

export const ActionCard = ({
  action,
  campaignId,
  hideCampaignTag = false,
}: {
  action: Action
  campaignId?: string
  hideCampaignTag?: boolean
}) => {
  const { metrics, colors } = useDeedTheme()
  const history = useHistory()

  const [confirmCompletionModalVisible, setConfirmCompletionModalVisible] = useState(false)

  const reward = action.incentives.find((incentive) => incentive.type === IncentiveType.Reward)
  const {
    id,
    bannerImage,
    Organization,
    title,
    subTitle,
    Activities: activities,
    Causes: causes,
    Campaigns = [],
    endingAt,
  } = action

  const userOrganizationId = useSelector(selectUserBrand)?.id

  const isOwnCompanyAction = Organization?.id === userOrganizationId
  const resizedImage = resize(bannerImage, '960x540')

  // @NOTE-CH We always fallback to the first Campaign the Action is tagged to if any
  const usedCampaignId = campaignId ?? action?.Campaigns?.[0]?.id
  const [campaign] = Campaigns.filter((c) => c.id === usedCampaignId) ?? Campaigns

  const actionDetailsLink = `/action/${id}${usedCampaignId ? `?campaignId=${usedCampaignId}` : ''}`
  const navigateToActionDetails = () => {
    history.push(actionDetailsLink, { campaignId: usedCampaignId })
  }

  const [userActivity] = action?.userActivities ?? []

  const isCompleted = userActivity?.status === ActivityStatus.Completed
  const isActionExpired = isDateInThePast(endingAt)

  const { onUpsertAction, loadingUpsertActionActivity } = useActivityMutations({
    userActivity,
    campaignId: usedCampaignId,
    actionId: id,
  })

  return (
    <>
      <CardWrapper style={{ width: metrics.isLarge ? 300 : '100%' }}>
        <Link to={actionDetailsLink}>
          <BannerImage source={{ uri: resizedImage }} backgroundPosition="center" backgroundSize="cover" />
          {isOwnCompanyAction && Organization?.logo && (
            <CompanyIconWrapper>
              <CompanyIcon source={{ uri: Organization?.logo }} resizeMode="contain" />
            </CompanyIconWrapper>
          )}
          <ContentWrapper style={{ paddingBottom: 0 }}>
            <RewardWrapper>{reward && <RewardChip unit={reward.unit} amount={reward.amount} />}</RewardWrapper>
            <Title numberOfLines={1}>{title}</Title>
            <SubTitleWrapper>
              <SubTitle colour={colors.gray} numberOfLines={2}>
                {subTitle}
              </SubTitle>
            </SubTitleWrapper>
            {!hideCampaignTag ? (
              campaign ? (
                <CampaignTag campaign={campaign} />
              ) : (
                <CauseWrapper>
                  <CauseChips causes={causes} />
                </CauseWrapper>
              )
            ) : null}
            <EndDateTag endingAt={endingAt} status={userActivity?.status ?? null} />
          </ContentWrapper>
        </Link>
        <div>
          <ContentWrapper style={{ paddingTop: 8 }}>
            <AvatarRow
              items={(activities || [])?.map((activity) => activity.User)}
              numAllItems={action.activitiesCount}
            />
          </ContentWrapper>
          <ContentWrapper>
            <ActionButton
              status={userActivity?.status ?? null}
              isActionExpired={isActionExpired}
              onPress={() => {
                if (isCompleted || isActionExpired) {
                  navigateToActionDetails()
                } else {
                  handleActionButtonPress({
                    currentActivityStatus: userActivity?.status,
                    onUpsertAction,
                    setConfirmCompletionModalVisible,
                  })
                }
              }}
              loading={loadingUpsertActionActivity}
              fluid
            />
          </ContentWrapper>
        </div>
      </CardWrapper>
      <SuccessModals action={action} buttonsDisabled={loadingUpsertActionActivity} />
      <ConfirmCompletionModal
        action={action}
        visible={confirmCompletionModalVisible}
        buttonsDisabled={loadingUpsertActionActivity}
        onClose={() => {
          setConfirmCompletionModalVisible(false)
        }}
        onPressPrimaryButton={() => {
          onUpsertAction(ActivityStatus.Completed)
          setConfirmCompletionModalVisible(false)
        }}
      />
    </>
  )
}
