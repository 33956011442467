import React from 'react'

import { Button } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const OutlineButton = ({
  onPress,
  children,
  hexColor,
  disabled,
  small,
}: {
  onPress: (e: any) => void
  children: JSX.Element
  hexColor?: string
  disabled?: boolean
  small?: boolean
}) => {
  const { colors } = useDeedTheme()
  return (
    <Button
      style={{
        backgroundColor: colors.white,
        boxShadow: 'none',
        shadowOpacity: 0,
        borderRadius: 30,
        paddingVertical: 12,
        color: hexColor || colors.black,
        borderWidth: small ? 1 : 2,
        borderColor: hexColor || colors.black,
        width: 'auto',
        paddingLeft: 20,
        paddingRight: 20,
      }}
      size={small ? 'small' : 'default'}
      disabled={disabled}
      textStyle={{ color: hexColor || colors.black }}
      onPress={onPress}
    >
      {children}
    </Button>
  )
}
