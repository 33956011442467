import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import DonateButton from 'src/retired/shared/DonateButton'
import { Body2 } from 'src/retired/shared/Typography'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import Spacing from 'src/retired/elements/Spacing'
import { Row } from 'src/retired/elements'
import ProgressBar from 'src/retired/shared/ProgressBar'
import Deed from 'src/entities/deed/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import truthy from 'src/utils/truthy'

interface CardProps {
  deed: Deed
}
export type CardElement = React.ReactElement<CardProps>

const Content: React.FC<CardProps> = ({ deed }): CardElement => {
  const { t } = useTranslation('deedCard')
  const user = useSelector(selectCurrentUser)

  const nonprofits = deed.getNonprofits()
  const { colors } = useDeedTheme()
  const eventTypes = ['Event', 'BaseEvent']

  return (
    <>
      {deed.type === 'Project' ? null : (
        <>
          <View
            style={[
              css`
                flex-direction: row;
                justify-content: space-between;
                margin-top: 24px;
              `,
            ]}
          >
            {eventTypes.includes(deed.type) ? (
              <>
                <Body2 colour={colors.grayDark}>{deed.type === 'Event' ? t`volunteers` : t`attendees`}</Body2>
                <Body2 colour={colors.grayDark}>
                  {deed.attendees.length}/{deed.attendeeLimit}
                </Body2>
              </>
            ) : deed.type === 'Campaign' ? (
              <Body2 colour={colors.grayDark}>
                <Trans
                  t={t}
                  i18nKey={deed.goalAmount > 0 ? 'xRaisedOfY' : 'xRaised'}
                  components={{
                    x: <CurrencyFormat amountCurrencies={deed.donationStats.totalAmountCurrencies} />,
                    y: <CurrencyFormat amountCurrencies={deed.goalAmountCurrencies} />,
                  }}
                />
              </Body2>
            ) : null}
          </View>
          {eventTypes.includes(deed.type) && (
            <Spacing marginTop={12}>
              <ProgressBar
                items={[
                  {
                    color: colors.userOrganizationBrandColor || colors.coral,
                    value: deed.attendees.length,
                    label: t`attendees`,
                  },
                ].filter(truthy)}
                total={deed.attendeeLimit}
                backgroundColor={colors.gray02}
              />
            </Spacing>
          )}
          {deed.type === 'Campaign' && (
            <Spacing marginTop={12}>
              <ProgressBar
                items={[
                  deed?.donationStats?.otherAmountCurrencies?.[deed.currencyCode] > 1 && {
                    label: deed.donationStats.employeeAmountCurrencies?.[deed.currencyCode] > 1 ? t`others` : t`donors`,
                    color: colors.teal,
                    value: deed.donationStats.otherAmountCurrencies[deed.currencyCode],
                  },
                  deed?.donationStats?.employeeAmountCurrencies?.[deed.currencyCode] > 1 && {
                    label: t`employees`,
                    color: colors.brandColor,
                    value: deed.donationStats.employeeAmountCurrencies[deed.currencyCode],
                  },
                  deed?.donationStats?.matchedByEmployerAmountCurrencies?.[deed.currencyCode] > 1 && {
                    label: t`matched`,
                    color: colors.yellow,
                    value: deed.donationStats.matchedByEmployerAmountCurrencies[deed.currencyCode],
                  },
                ].filter(truthy)}
                total={deed.goalAmount}
                backgroundColor={colors.gray02}
              />
            </Spacing>
          )}
        </>
      )}
      <Row
        style={{
          marginTop: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 32,
        }}
      >
        {nonprofits?.size > 1 ? (
          <Body2 colour={colors.gray} style={{ maxWidth: '75%' }}>
            {t('benefittingOrganizations', { size: nonprofits.size })}
          </Body2>
        ) : nonprofits?.size ? (
          <View style={{ flexShrink: 1, overflow: 'hidden' }}>
            <OrganizationChip key={nonprofits.first()?.id} organization={nonprofits.first()} />
          </View>
        ) : (
          <View style={{ flexShrink: 1, overflow: 'hidden' }}>
            <OrganizationChip organization={deed.organization || deed.provisionalNonprofitData} />
          </View>
        )}
        {deed.type === 'Campaign' && deed.isOngoing() && (!user || user?.hasFeature('donations')) && (
          <DonateButton model={deed} size="extraSmall">
            {t`donate`}
          </DonateButton>
        )}
      </Row>
    </>
  )
}
export default Content
