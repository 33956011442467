import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import Platform from 'src/utils/Platform'
import { colors, metrics } from 'src/theme'
import { Link, useHistory } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import TouchableWithoutFeedback from 'src/retired/elements/TouchableWithoutFeedback'

import { SmallOnly, MediumUp } from '../MediaQuery'

const styles = StyleSheet.create({
  overlayBg: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 10,
  },
  overlay: {
    position: 'fixed',
    alignItems: 'center',
    top: 0,
    left: metrics.isSmall ? 0 : 20,
    right: metrics.isSmall ? 0 : 20,
    bottom: 0,
    zIndex: 30,
  },
  overlayInner: {
    width: '100%',
    height: Platform.isIE ? '100%' : 'auto',
    maxHeight: 'calc(100vh - 80px)',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    shadowColor: colors.black,
    elevation: 1,
    backgroundColor: colors.white,
    margin: 40,
    borderRadius: 8,
    overflow: 'hidden',
  },
  linkOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
})

const Overlay = ({
  children,
  force = false,
  wide = false,
  dismissOnEsc = true,
  overlayStyle = {},
  close,
  transparent,
  backgroundLink,
  forceBackTo = false,
}) => {
  const history = useHistory()

  const goBack = makeGoBack(history, backgroundLink, forceBackTo)

  useEffect(() => {
    if (Platform.OS === 'web' && dismissOnEsc && backgroundLink) {
      const keyDownHandler = (event) => {
        if (event.key === 'Escape') {
          event.preventDefault()
          // eslint-disable-next-line no-unused-expressions
          close ? close() : goBack(event)
        }
      }

      document.addEventListener('keydown', keyDownHandler)

      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [])

  let BackgroundComponent = Link
  let backgroundProps = { onClick: goBack }

  if (close) {
    BackgroundComponent = TouchableWithoutFeedback
    backgroundProps = { onPress: close }
  }

  return (
    <>
      <View style={[styles.overlayBg, !transparent && { backgroundColor: 'rgba(0, 0, 0, 0.3)' }]} focusable={false} />
      <View style={styles.overlay} focusable={false} tabIndex={-1}>
        {!force && !transparent ? (
          <BackgroundComponent
            {...backgroundProps}
            focusable={false}
            style={
              Platform.OS !== 'web'
                ? {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  }
                : null
            }
          >
            <View style={styles.linkOverlay} focusable={false} />
          </BackgroundComponent>
        ) : null}
        <View
          style={[
            styles.overlayInner,
            { marginTop: transparent ? 90 : 40 },
            { maxWidth: wide ? 1100 : 800 },
            overlayStyle,
          ]}
          focusable={false}
        >
          {children}
        </View>
      </View>
    </>
  )
}
Overlay.propTypes = {
  children: PropTypes.node,
  wide: PropTypes.bool,
  force: PropTypes.bool,
  close: PropTypes.func,
  overlayStyle: PropTypes.object,
  transparent: PropTypes.bool,
  history: PropTypes.object,
  backgroundLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export const ResponsiveOverlay = ({ children, close, overlayStyle }) => (
  <>
    <SmallOnly>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#fff',
          zIndex: 30,
        }}
      >
        {children}
      </View>
    </SmallOnly>
    <MediumUp>
      <Overlay close={close} overlayStyle={overlayStyle}>
        {children}
      </Overlay>
    </MediumUp>
  </>
)
ResponsiveOverlay.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func,
  overlayStyle: PropTypes.object,
}
export default Overlay
