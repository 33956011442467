import { fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'

import { INIT, INIT_SUCCESS, INIT_FAILED } from './types'

export interface VolunteerTimeFormState {
  loading: boolean
  formLoading: boolean
  error: Error | null
}

export type TypedVolunteerTimeFormState = TypedMap<VolunteerTimeFormState>

export const initialState = fromJS({
  loading: true,
  formLoading: false,
  error: null,
}) as TypedVolunteerTimeFormState

export default (state = initialState, action: { type: string; error?: Error }): TypedVolunteerTimeFormState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    default:
      return state
  }
}
