import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { ShareIconsRow } from 'src/components/ShareIconsRow'
import { H5 } from 'src/retired/shared/Typography'

interface ShareBlockProps {
  shareLink: string
  campaignName: string
}

const ShareBlock = ({ shareLink, campaignName }: ShareBlockProps): JSX.Element | null => {
  const { t } = useTranslation('campaignScreen')

  const sharingMessage = t('helpSupport', { campaignName })

  return (
    <View style={{ marginTop: 80 }}>
      <H5 marginBottom={24} center>{t`shareThisCampaign`}</H5>
      <ShareIconsRow shareLink={shareLink} sharingMessage={sharingMessage} centered />
    </View>
  )
}

export default ShareBlock
