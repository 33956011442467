import React from 'react'

import { Platform } from 'src/utils'
import { isHoverEnabled } from 'src/utils/HoverState'

export interface HoverableProps {
  children: React.ReactNode
  onHoverIn?: () => void
  onHoverOut?: () => void
}

interface HoverableState {
  isHovered: boolean
  showHover: boolean
}

export default class Hoverable extends React.PureComponent<HoverableProps, HoverableState> {
  constructor(props: HoverableProps) {
    super(props)
    this.state = { isHovered: false, showHover: true }
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleGrant = this.handleGrant.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
  }

  private handleMouseEnter() {
    if (isHoverEnabled() && !this.state.isHovered) {
      if (this.props.onHoverIn) {
        this.props.onHoverIn()
      }
      this.setState((state) => ({ ...state, isHovered: true }))
    }
  }

  private handleMouseLeave() {
    if (this.state.isHovered) {
      if (this.props.onHoverOut) {
        this.props.onHoverOut()
      }
      this.setState((state) => ({ ...state, isHovered: false }))
    }
  }

  private handleGrant() {
    this.setState((state) => ({ ...state, showHover: false }))
  }

  private handleRelease() {
    this.setState((state) => ({ ...state, showHover: true }))
  }

  render() {
    const { children } = this.props
    const { showHover, isHovered } = this.state
    const child = typeof children === 'function' ? children(showHover && isHovered) : children

    const props: any = {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      // prevent hover showing while responder
      ...(Platform.OS !== 'web'
        ? {
            onResponderGrant: this.handleGrant,
            onResponderRelease: this.handleRelease,
          }
        : {}),
    }

    // if child is Touchable
    if (child.type.displayName && child.type.displayName.includes('Touchable')) {
      props.onPressIn = this.handleGrant
      props.onPressOut = this.handleRelease
    }

    return React.cloneElement(React.Children.only(child), props)
  }
}

export const withHover =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    <Hoverable>{(isHovered: boolean) => <Component {...props} isHovered={isHovered} />}</Hoverable>
