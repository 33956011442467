import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { Platform } from 'src/utils'
import DonationSchedule from 'src/entities/donationSchedule/model'
import { Body } from 'src/retired/shared/Typography'
import roundNumber from 'src/utils/roundNumber'
import { PaymentMethodType } from 'src/generated/graphql'
import { useCurrency } from 'src/containers/modules/CurrencyFormat'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { ValidationErrorObject, clearUpdateErrorAction, updateAction } from '../types'
import { selectUpdateError } from '../selectors'

import { EditButton, EditableAmount, SaveButton, ValidationErrors } from '.'

// Ref: https://github.com/joindeed/marketplace/pull/3658#discussion_r1541115007
export const MINIMUM_DONATION_AMOUNT = 1
interface DonationAmountProps {
  donationSchedule: DonationSchedule
}

const checkIfEditable = (donationSchedule: DonationSchedule) => {
  const hasErrors = Boolean(donationSchedule.executionFailure && donationSchedule.executionFailure.length > 0)
  const isPayrollDonation = donationSchedule.paymentMethod.type === PaymentMethodType.Payroll
  const isOnWeb = Platform.OS === 'web'

  return isPayrollDonation && isOnWeb && !hasErrors
}

export const DonationAmount = ({ donationSchedule }: DonationAmountProps) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')
  const user = useSelector(selectCurrentUser)

  const isEditable = checkIfEditable(donationSchedule)
  const currencySymbol = useCurrency().symbol

  const dispatch = useDispatch()
  const updateError = useSelector(selectUpdateError)

  const [amount, setAmount] = useState<number>(donationSchedule.amount)
  const [inputAmount, setInputAmount] = useState<string>(String(donationSchedule.amount))
  const [inEditing, setInEditing] = useState(false)
  const [frontendError, setFrontendError] = useState<ValidationErrorObject | null>(null)

  if (updateError?.toJS().unknownError) {
    dispatch(showErrorAction(t('updatingFailed')))
    dispatch(clearUpdateErrorAction())
  }

  useEffect(() => setAmount(roundNumber(parseFloat(inputAmount) || 0, true)), [setAmount, inputAmount])

  useEffect(() => {
    if (amount < MINIMUM_DONATION_AMOUNT) {
      setFrontendError({ translationCode: `donationAmountGreaterEqualError` })
      return
    }

    setFrontendError(null)
  }, [amount, currencySymbol])

  const edit = useCallback(() => {
    setInEditing(true)
    dispatch(clearUpdateErrorAction())
  }, [dispatch])

  const cancel = useCallback(() => {
    setFrontendError(null)
    setInputAmount(String(donationSchedule.amount))
    setInEditing(false)
  }, [donationSchedule.amount])

  const save = useCallback(() => {
    const canSave = amount !== donationSchedule.amount && !frontendError

    if (!canSave) {
      return
    }

    dispatch(updateAction(donationSchedule.id, { amount }))
    setInEditing(false)
  }, [dispatch, frontendError, amount, donationSchedule])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'inline-flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Body style={{ marginBottom: 10 }}>{t`amount`}</Body>
          <Box sx={{ display: 'inline-flex', flexGrow: 1, alignItems: 'center' }}>
            <EditableAmount
              inputAmount={inputAmount}
              setInputAmount={setInputAmount}
              inEditing={inEditing}
              donationSchedule={donationSchedule}
            ></EditableAmount>
            {inEditing && <SaveButton onSave={() => save()}></SaveButton>}
          </Box>
        </Box>
        {user?.hasFeature('editDonationAmount') && isEditable && (
          <EditButton inEditing={inEditing} onEdit={() => edit()} onCancel={() => cancel()}></EditButton>
        )}
      </Box>
      <ValidationErrors field="amount" frontendError={frontendError} updateError={updateError}></ValidationErrors>
    </Box>
  )
}
