import React from 'react'
import { View, StyleSheet } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body1 } from 'src/retired/shared/Typography'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import Header from 'src/retired/blocks/Header'
import { MediumUp, SmallOnly } from 'src/retired/elements/MediaQuery'
import { AlignedImage } from 'src/retired/elements'
import { images } from 'src/theme'
import { Link, withRouter } from 'src/navigation'
import Organization from 'src/entities/organization/model'
import LanguageSwitcher from 'src/containers/modules/LanguageSwitcher'

export interface NavigationHeaderProps {
  back: boolean
  backTo: string
  logo: boolean
  title: string
  brand?: Organization
  transparent: boolean
  gradient: boolean
  linkLogo: boolean
  forceBackTo?: boolean
  onGoBack: () => void
  showLanguageSwitcher?: boolean
}

export const NavigationHeader = ({
  back = true,
  backTo = '',
  logo = false,
  linkLogo = false,
  title = '',
  brand,
  transparent = false,
  gradient = false,
  onGoBack,
  forceBackTo,
  showLanguageSwitcher,
}: Partial<NavigationHeaderProps>): JSX.Element => {
  const { metrics } = useDeedTheme()

  const logoImage = <AlignedImage source={images.logoInlineBlack} width={99} height={49} />

  let logoElement = logo ? logoImage : null
  if (logo && linkLogo) {
    logoElement = <Link to="/home">{logoElement}</Link>
  }

  return (
    <Header transparent={transparent} shadow={gradient}>
      <View style={styles.left}>
        {back && <BackArrowButton backTo={backTo} onGoBack={onGoBack} forceBackTo={forceBackTo} />}
      </View>
      <View style={styles.center}>
        {title ? (
          <Body1 weight="500" numberOfLines={1}>
            {title}
          </Body1>
        ) : null}
        <SmallOnly>{logoElement}</SmallOnly>
        <MediumUp>{!title ? logoElement : null}</MediumUp>
      </View>
      <View style={styles.right}>
        {showLanguageSwitcher && (
          <View
            style={{
              marginTop: metrics.isSmall ? 0 : 4,
              alignSelf: metrics.isSmall ? 'end' : 'center',
              paddingRight: metrics.isSmall ? 0 : 55,
            }}
          >
            <LanguageSwitcher globeOnly={metrics.isSmall} />
          </View>
        )}
        {brand && (
          <AlignedImage height={metrics.isSmall ? 30 : 40} source={{ uri: brand.mainPicture }} alignment="right" />
        )}
      </View>
    </Header>
  )
}

const styles = StyleSheet.create({
  left: {
    flex: 1,
    alignItems: 'flex-start',
  },
  center: {
    flex: 5,
    alignItems: 'center',
  },
  right: {
    flex: 1,
  },
  backButton: {
    width: 40,
    height: 40,
  },
})

export default withRouter(NavigationHeader as React.ComponentType<any>)
