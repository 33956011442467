import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import _ from 'lodash'
import { parse } from 'query-string'

import { useLocation } from 'src/navigation'
import { useActionsQuery } from 'src/generated/graphql'
import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import { SearchInput } from 'src/components/SearchInput/SearchInput'
import { Action } from 'src/containers/modules/Actions/types'
import { ScrollView, Loading } from 'src/retired/elements'
import { Body1, Body2, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { formatFullName } from 'src/utils/formatFullName'

import { NUM_INITIAL_ACTIVITIES } from '../constants'

import { ActivitiesList } from './ActivitiesList'

const storeSearchTermDebounced = _.debounce((callback: () => void) => {
  callback()
}, 1000)

const onKeyUp = (callback: () => void) => {
  storeSearchTermDebounced(callback)
}

export const ActivitiesModal = ({
  action,
  visible,
  onClose,
}: {
  action: Action
  visible: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation('actions')
  const { metrics } = useDeedTheme()

  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [allActivities, setAllActivities] = useState(action.Activities)
  const [filteredActivities, setFilteredActivities] = useState(action.Activities)
  const [moreActivitiesLoaded, setMoreActivitiesLoaded] = useState(false)

  const { search } = useLocation()
  const { campaignId } = parse(search) as { campaignId?: string }

  const handleSearch = (searchTerm: string) => {
    setFilteredActivities(
      !searchTerm?.length
        ? allActivities
        : allActivities.filter((activity) =>
            formatFullName(activity.User.preferredName || activity.User.fullName)
              .toLocaleLowerCase()
              .includes(searchTerm)
          )
    )
  }

  // Initially we only have 4 activities. This loads the rest
  const { data: actionsData, loading: actionsLoading } = useActionsQuery({
    variables: {
      where: {
        id: { equals: action.id },
        ...(campaignId ? { campaignIds: { has: campaignId } } : {}),
      },
      activityArgs: {
        skip: NUM_INITIAL_ACTIVITIES,
        take: 300, // Safety mechanism to not break mongo if an action really has thousands of activities
      },
    },
    fetchPolicy: 'no-cache',
    skip: !visible || moreActivitiesLoaded,
  })

  useEffect(() => {
    const additionalActivities = actionsData?.actions[0]?.Activities
    if (!additionalActivities) {
      return
    }
    const activities = [...allActivities, ...additionalActivities]
    setAllActivities(activities)
    if (!currentSearchTerm) {
      setFilteredActivities(activities)
    } else {
      handleSearch(currentSearchTerm)
    }

    // Needed to not trigger the gql query again in case a user opens and closes the modal again
    setMoreActivitiesLoaded(true)
  }, [actionsData])

  return (
    <ModalWithCloseButton
      visible={visible}
      onClose={onClose}
      title={
        <H5 weight="500">
          {t`participantsTitle`} <Body2>({allActivities.length})</Body2>
        </H5>
      }
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxWidth: metrics.isLarge ? 415 : 'auto',
          width: metrics.isSmall ? '100%' : 415,
        }}
      >
        <View style={{ paddingVertical: 30, width: '100%' }}>
          <SearchInput
            outlined
            small
            fieldName="searchParticipants"
            placeholder={t('searchParticipants')}
            inputValue={currentSearchTerm}
            onChange={(_fieldName, value) => {
              setCurrentSearchTerm(value)
              onKeyUp(() => {
                handleSearch(value?.toLocaleLowerCase())
              })
            }}
            onReset={() => {
              setCurrentSearchTerm('')
              handleSearch('')
            }}
            triggerSearch={() => {
              handleSearch(currentSearchTerm)
            }}
          />
        </View>
        <ScrollView style={{ width: '100%' }}>
          {filteredActivities.length ? (
            <ActivitiesList activities={filteredActivities} />
          ) : (
            <Body1>{t`noResults`}</Body1>
          )}
        </ScrollView>
      </View>

      {actionsLoading && (
        <View style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: 30 }}>
          <Loading fill={false} />
        </View>
      )}
    </ModalWithCloseButton>
  )
}
