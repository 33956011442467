import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import { TypedMap } from 'src/utils/typed-map'

import { CANCEL, CANCEL_SUCCESS, CANCEL_FAILED } from './constants'

export type DeedCancelState = TypedMap<{ cancelling: boolean; error: unknown }>

export const initialState: DeedCancelState = fromJS({ cancelling: false, error: null })

export default (state = initialState, action: AnyAction): DeedCancelState => {
  switch (action.type) {
    case CANCEL:
      return state.merge({ cancelling: true, error: null })

    case CANCEL_SUCCESS:
      return state.set('cancelling', false)

    case CANCEL_FAILED:
      return state.merge({ cancelling: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
