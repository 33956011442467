import { AnalyticsBrowser } from '@segment/analytics-next'
import type { AnalyticsBrowserSettings, InitOptions } from '@segment/analytics-next'

import config from 'src/config'

import { defaultContext, pageDefaults } from '../../util'
import { AnalyticsMockClient, LocationData } from '../util'

import { AbstractSegmentMetrics } from './abstract'

const isFordVanityDomain = window.location.href.includes('ford.joindeed')

export const getSegmentClient = (
  mockClient: AnalyticsMockClient,
  analyticsSettings: AnalyticsBrowserSettings,
  options?: InitOptions
) => {
  // @NOTE-AT: Don't track Segment Activity for TestRigor Automated Tests
  const trackActivity = config.segment && !pageDefaults?.ua?.includes('TestRigorDeed')
  const client = trackActivity ? AnalyticsBrowser.load(analyticsSettings, options) : mockClient
  return client
}

export class SegmentMetrics extends AbstractSegmentMetrics {
  private readonly _mock: AnalyticsMockClient

  private readonly _analytics: AnalyticsMockClient | AnalyticsBrowser

  constructor({
    name = 'segment',
    write = config.segment,
    debug = !config.isProduction,
    cdn = config.segmentCdn,
  } = {}) {
    super({
      name,
      write,
      debug,
    })

    this._analytics = getSegmentClient(
      this._mock,
      {
        writeKey: write,
        cdnURL: cdn,
      },
      { integrations: { Hotjar: !isFordVanityDomain } }
    )

    if (debug) {
      this.analytics.debug(debug)
    }
  }

  get analytics(): AnalyticsMockClient | AnalyticsBrowser {
    return this._analytics
  }

  async pageView(locationData: LocationData): Promise<void> {
    // @NOTE-CH: let's not send the location state payload
    const { state, ...location } = locationData

    await this.analytics.page(
      {
        ...this.configured.properties,
        ...pageDefaults,
        ...(location || {}),
      },
      {
        context: {
          ...defaultContext,
          ...this.configured.context,
        },
        ...this.configured.options,
      }
    )
  }

  async track(eventName: string, analyticsEvent: object): Promise<void> {
    await this.analytics.track(eventName, { ...this.configured.properties, analyticsEvent })
  }
}

export default SegmentMetrics
