import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { useHistory } from 'src/navigation'
import { Loading, Row, Screen, ScrollView, Touchable } from 'src/retired/elements'
import { H5, Body2, Label, Title } from 'src/retired/shared/Typography'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import { convertDuration } from 'src/utils/convertDuration'
import Icon from 'src/retired/shared/Icon'
import { useVolunteerTimeOffsListQuery } from 'src/generated/graphql'

import { VOLUNTEER_TIME_OFF_STATUS } from './constants'

const Table = styled.View`
  margin: 0 30px 30px 30px;
  border: 1px #ebeef0 solid;
  border-radius: 15px;
  background-color: #ffffff;
`

const TableHeader = styled.View`
  flex-direction: row;
  padding: 20px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px #ebeef0 solid;
  margin-bottom: 10px;
`

const TableRow = styled.View`
  border-radius: 32px;
  flex-direction: row;
  padding: 20px 15px;
  margin: 0 10px;
`

const LongColumn = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0px 10px' : '0px 15px')};
  flex: auto;
`

const ShortColumn = styled.View<object, EmotionTheme>`
  width: ${({ theme }) => (theme.metrics.isSmall ? '95px' : '150px')};
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0px 10px' : '0px 15px')};
  align-items: flex-start;
`

const Status = styled.View<object, EmotionTheme>`
  padding: 4px 16px;
  margin-top: ${({ theme }) => (theme.metrics.isSmall || theme.metrics.isMedium ? '10px' : '0px')};
  border-radius: 20px;
  flex-direction: row;
`

const VolunteerTimeOffHours = (): React.ReactElement => {
  const { data: vtoList, loading, error } = useVolunteerTimeOffsListQuery()
  const { t } = useTranslation('volunteerTimeOffHoursProfile')
  const history = useHistory()
  const { metrics, colors } = useDeedTheme()
  const volunteerTimeOffs = vtoList?.volunteerTimeOffs

  return (
    <Screen>
      <Row style={{ justifyContent: 'center', padding: 35, backgroundColor: colors.gray03 }}>
        <BackArrowButton backTo="/profile" />
        <Row style={{ alignItems: 'center' }}>
          <Row style={{ justifyContent: 'center', alignItems: 'center', marginRight: 30 }}>
            <H5 lineHeight={metrics.isSmall ? 16 : 20} numberOfLines={1}>
              {t`volunteerTimeOffHours`}
            </H5>
          </Row>
        </Row>
      </Row>
      <ScrollView style={{ backgroundColor: colors.gray03 }}>
        {error ? (
          <Label center paddingTop={30}>
            {t`thereWasAnErrorLoadingVolunteerTimeOffHours`}
          </Label>
        ) : loading || !volunteerTimeOffs ? (
          <View style={{ paddingTop: 30, paddingBottom: 50 }}>
            <Loading fill={false} />
          </View>
        ) : (
          <Table>
            <TableHeader style={{ backgroundColor: colors.gray03, paddingLeft: 25, paddingRight: 25 }}>
              <LongColumn>
                <Title weight="500">{t`common:deed`}</Title>
              </LongColumn>
              <Row style={{ flexShrink: 0, flexGrow: 0 }}>
                <ShortColumn>
                  <Title weight="500">{t`common:status`}</Title>
                </ShortColumn>
                <ShortColumn>
                  <Title weight="500">{t`common:time`}</Title>
                </ShortColumn>
                <ShortColumn>
                  <Title weight="500">{t`common:date`}</Title>
                </ShortColumn>
              </Row>
            </TableHeader>

            {volunteerTimeOffs?.length ? (
              <View style={{ marginBottom: 20 }}>
                {volunteerTimeOffs.map((volunteerTimeOff, index) => (
                  <Touchable
                    onPress={() => history.push(`/profile/volunteer-time-off-hours/${volunteerTimeOff?.id}`)}
                    key={volunteerTimeOff.id}
                  >
                    <TableRow style={{ backgroundColor: index % 2 !== 0 ? colors.gray03 : '#FFFFFF' }}>
                      <LongColumn>
                        <Row
                          style={{
                            alignItems: metrics.isSmall || metrics.isMedium ? 'start' : 'center',
                            flexWrap: 'wrap',
                            flexDirection: metrics.isSmall || metrics.isMedium ? 'column' : 'row',
                            alignSelf: metrics.isSmall || metrics.isMedium ? 'start' : 'stretch',
                          }}
                        >
                          <Body2
                            colour="purple2"
                            style={{
                              width: metrics.isSmall || metrics.isMedium ? '100%' : 'auto',
                              marginRight: metrics.isSmall || metrics.isMedium ? 0 : 25,
                            }}
                          >
                            {volunteerTimeOff.Deed?.name ||
                              t(`common:externalVolunteeringFor`, { nonprofitName: volunteerTimeOff.Nonprofit?.name })}
                          </Body2>
                        </Row>
                      </LongColumn>
                      <Row style={{ flexShrink: 0, flexGrow: 0 }}>
                        <ShortColumn>
                          <Status
                            style={{
                              backgroundColor: colors[VOLUNTEER_TIME_OFF_STATUS[volunteerTimeOff.status].chipColor],
                            }}
                          >
                            <Body2
                              weight="500"
                              style={{
                                paddingVertical: 2,
                                paddingHorizontal: 8,
                                marginRight: 10,
                                color: colors[VOLUNTEER_TIME_OFF_STATUS[volunteerTimeOff.status].textColor],
                              }}
                            >
                              {t('volunteerTimeOff', { context: volunteerTimeOff.status })}
                            </Body2>
                            <Icon
                              icon={VOLUNTEER_TIME_OFF_STATUS[volunteerTimeOff.status].icon}
                              color={VOLUNTEER_TIME_OFF_STATUS[volunteerTimeOff.status].textColor}
                              width={14}
                            />
                          </Status>
                        </ShortColumn>
                        <ShortColumn>
                          <Body2>{convertDuration(volunteerTimeOff.duration)}</Body2>
                        </ShortColumn>
                        <ShortColumn>
                          <Body2>
                            {t('date:dayMonthYearDigits', {
                              date: format(new Date(volunteerTimeOff.startingAt), 'yyyy/MM/dd'),
                            })}
                          </Body2>
                        </ShortColumn>
                      </Row>
                    </TableRow>
                  </Touchable>
                ))}
              </View>
            ) : (
              <View style={{ marginBottom: 10 }}>
                <Body2 lineHeight={20} center marginTop={20} paddingLeft={20} paddingRight={20}>
                  {t`youHaveNotLoggedAnyVolunteerTimeOffHoursYet`}
                </Body2>
              </View>
            )}
          </Table>
        )}
      </ScrollView>
    </Screen>
  )
}

export default VolunteerTimeOffHours
