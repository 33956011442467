/* eslint-disable import/no-import-module-exports */
/* global __DEV__ */
/**
 * Create the store with dynamic reducers
 */
import type { History } from 'history'
import { BehaviorSubject } from 'rxjs'
import { createStore, applyMiddleware, compose } from 'redux'
import type { AnyAction, MiddlewareAPI, Middleware } from 'redux'
import { persistStore, createTransform } from 'redux-persist-immutable'
import { fromJS } from 'immutable'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import type { ActionsObservable, Epic } from 'redux-observable'
import { routerMiddleware } from 'connected-react-router/immutable'

import createFilter from 'src/utils/createFilter'
import metricsMiddleware from 'src/middleware/metrics'
import * as Sentry from 'src/utils/Sentry'
import { selectLocalSetting } from 'src/localSettings/selectors'
import type { DonateAction } from 'src/containers/screens/Donate/actions'

import { selectCurrentUser } from './entities/user/selectors'
import { selectUserContext } from './sentry/selectors'
import Auth from './entities/auth/model'
import reduxPersistStorage from './utils/reduxPersistStorage'
import { Platform } from './utils'
import createReducer, { State } from './reducers'
import globalEpics from './epics'

const epic$ = new BehaviorSubject(combineEpics(...globalEpics))
const rootEpic: Epic<AnyAction, any> = (action$: ActionsObservable<AnyAction>, $store: MiddlewareAPI) =>
  epic$.mergeMap((epic) => epic(action$, $store))

const epicMiddleware = createEpicMiddleware<AnyAction, any>(rootEpic)

const logActions: Middleware = () => (next) => (action) => {
  if (process.env.NODE_ENV !== 'production') {
    // console.log(action)
  }
  next(action)
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key])
    }
    // recurse
    else if (obj[key] == null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-param-reassign
      delete obj[key]
    } // delete
  })
}

interface UserContext {
  id: string
  companyId?: string
  company?: string
}
export const getUserContext = (state: State) => {
  const user = selectCurrentUser(state) || selectUserContext(state)
  if (!user) {
    const userId = selectLocalSetting(state, 'userId')
    if (userId) {
      const userContext: UserContext = {
        id: userId,
      }
      const organizationId = selectLocalSetting(state, 'organization')
      if (organizationId) {
        userContext.companyId = organizationId
      }
      return userContext
    }
    return null
  }
  const userContext: UserContext = {
    id: user.id,
  }
  if (user.organization) {
    userContext.company = user.organization.name
    userContext.companyId = user.organization.id
  }
  return userContext
}
export const stateTransformer = (state: State) => {
  const { auth, entities, cookieConsent, localSettings, ...cleanedState } = state.toJS()
  removeEmpty(cleanedState)
  return cleanedState
}

const actionTransformer = (action: DonateAction): AnyAction => {
  switch (action.type) {
    case 'containers/Donate/DONATE': {
      const sanitizedAction: DonateAction = { ...action }
      if (sanitizedAction.data?.creditCardData) {
        sanitizedAction.data.creditCardData.number = `'[Filtered] ${sanitizedAction.data.creditCardData.number?.substr(
          -4
        )}`
        sanitizedAction.data.creditCardData.securityCode = '[Filtered]'
        sanitizedAction.data.creditCardData.expiration.month = '[Filtered]'
        sanitizedAction.data.creditCardData.expiration.year = '[Filtered]'
      }
      if (sanitizedAction.data?.billingAddress) {
        sanitizedAction.data.billingAddress.street = '[Filtered]'
        sanitizedAction.data.billingAddress.postalCode = '[Filtered]'
      }
      return sanitizedAction
    }

    default: {
      return action
    }
  }
}

const logErrors =
  (store) =>
  (next) =>
  (action = {}) => {
    const context = {}
    if (action?.type?.includes('FAILED')) {
      const { error: maybeError, errorCode, type, ...rest } = action

      const ignoredErrors = ['Failed to fetch', 'The request timed out.']

      let level

      if (
        (maybeError?.response?.status < 500 || ignoredErrors.includes(maybeError?.message)) &&
        maybeError?.message !== 'Bad Request'
      ) {
        if (action?.type?.includes('DONATE')) {
          level = 'warning'
        } else {
          level = 'info'
        }
      } else {
        level = 'error'
      }

      if (typeof __DEV__ !== 'undefined' && __DEV__) {
        // eslint-disable-next-line no-console
        console[level](type, errorCode, maybeError, rest)
      } else {
        const error =
          maybeError instanceof Error
            ? maybeError
            : new Error(
                typeof maybeError === 'string' ? maybeError : `Unknown exception: ${JSON.stringify(maybeError)}`
              )
        error.name = `${action.type}${error.name ? `: ${error.name}` : ''}`
        if (error?.response) {
          context.requestUrl = error.response.url
          context.requestStatusCode = error.response.status
        }
        const state = store.getState()
        const userContext = getUserContext(state)
        if (userContext) {
          Sentry.setUser(userContext)
        }
        const lastAction = actionTransformer(fromJS(action).toJS())
        removeEmpty(lastAction)
        Sentry.setExtras({
          lastAction,
          state: stateTransformer(state),
          context,
        })
        Sentry.setTags(context)
        Sentry.captureException(error, { level })
      }
    }
    next(action)
  }

export default (initialState = {}, history: History) => {
  // Create the store with two middlewares
  // 1. epicMiddleware: Makes redux-observable work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [logActions, epicMiddleware, logErrors, routerMiddleware(history), metricsMiddleware]

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
      const userContext = getUserContext(state)
      if (userContext) {
        scope.setUser(userContext)
      }
    },
    stateTransformer,
    actionTransformer,
  })

  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose
  /* eslint-enable */

  const store = createStore(createReducer(history), fromJS(initialState), composeEnhancers(...enhancers))
  const authTokenOnly = createFilter('entities', ['auth'])

  const cookieConsentTransform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState) => {
      const state = store.getState()
      const hasAcceptedCookies = state.get('cookieConsent').get('hasAcceptedCookies')

      if (Platform.OS !== 'web' || hasAcceptedCookies) {
        return inboundState
      }
      return null
    },

    // transform state being rehydrated
    (outboundState) => outboundState,
    // define which reducers this transform gets called for.
    {
      whitelist: ['entities', 'cookieConsent', 'localSettings', __DEV__ && Platform.OS !== 'web' && 'router'].filter(
        Boolean
      ),
    }
  )

  persistStore(store, {
    whitelist: ['entities', 'cookieConsent', 'localSettings', __DEV__ && Platform.OS !== 'web' && 'router'].filter(
      Boolean
    ),
    storage: reduxPersistStorage,
    records: [Auth],
    transforms: [authTokenOnly, cookieConsentTransform],
  })

  // Extensions
  store.injectedReducers = {} // Reducer registry
  store.injectedEpics = {} // Epics registry
  store.rootEpic = epic$
  store.history = history

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(history, store.injectedReducers))
    })
  }

  return store
}
