import { OrderedSet } from 'immutable'

import {
  ADD,
  ADD_MULTIPLE,
  CLEAR,
  NONPROFITS_LOADED,
  COMPANIES_LOADED,
  SET_DISPLAYED_LOCAL_ORGANIZATION_IDS,
  SET_DISPLAYED_PARTNER_ORGANIZATION_IDS,
  CLEAR_DISPLAYED_ORGANIZATION_IDS,
  FOR_CAUSES_LOADED,
} from './constants'
import Organization from './model'
import { OrganizationMap } from './reducer'

export const addAction = (organization: Organization) => ({
  type: ADD,
  organization,
})

export const addMultipleAction = (organizations: OrganizationMap) => ({
  type: ADD_MULTIPLE,
  organizations,
})

export const clearAction = () => ({
  type: CLEAR,
})

export const nonprofitsLoadedAction = () => ({
  type: NONPROFITS_LOADED,
})

export const companiesLoadedAction = () => ({
  type: COMPANIES_LOADED,
})

export const setDisplayedLocalOrganizationIdsAction = (organizationIds: OrderedSet<string>) => ({
  type: SET_DISPLAYED_LOCAL_ORGANIZATION_IDS,
  organizationIds,
})

export const setDisplayedPartnerOrganizationIdsAction = (organizationIds: OrderedSet<string>) => ({
  type: SET_DISPLAYED_PARTNER_ORGANIZATION_IDS,
  organizationIds,
})

export const clearDisplayedOrganizationIdsAction = () => ({
  type: CLEAR_DISPLAYED_ORGANIZATION_IDS,
})

export const forCausesLoadedAction = (causesIds: string[]) => ({
  type: FOR_CAUSES_LOADED,
  causesIds,
})
