import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DeedMap } from 'src/entities/deed/reducer'
import { OrganizationMap } from 'src/entities/organization/reducer'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectLoading as selectNonprofitsLoading } from 'src/containers/screens/Nonprofits/selectors'
import { selectDeedTypeFeedLoading, selectDeedTypeFeedMoreResults } from 'src/containers/screens/Feed/selectors'
import { Body2 } from 'src/retired/shared/Typography'
import { ExternalLink } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { ResultsTitle } from '../../common/ResultsTitle'
import { ResultsWrapper } from '../../common/styles'
import { StaticDeedListWithPagination } from '../../common/StaticDeedListWithPagination'
import { NoResults, NoResultType } from '../../common/NoResults/NoResults'
import { DonateTypes } from '../../utils'

import { NonprofitListContainer } from './NonprofitListContainer'

interface ResultProps {
  fundraisers: DeedMap
  nonprofits: OrganizationMap
  searchTerm?: string
  loadMoreNonprofits: () => void
  loadMoreFundraisers: () => void
  donateTypes: DonateTypes[]
}

export const DonateResults = ({
  fundraisers,
  nonprofits,
  searchTerm,
  loadMoreNonprofits,
  loadMoreFundraisers,
  donateTypes,
}: ResultProps) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('searchScreen')

  const fundraisersLoading = useSelector((state) => selectDeedTypeFeedLoading(state, 'Campaign')) as boolean
  const fundraisersMoreResults = useSelector((state) => selectDeedTypeFeedMoreResults(state, 'Campaign')) as boolean
  const nonprofitsLoading = useSelector(selectNonprofitsLoading) as boolean

  const user = useSelector(selectCurrentUser)
  const isDonationFeatureEnabled = user?.hasFeature('donations')

  const fundraiserTitle =
    searchTerm?.length && !fundraisersLoading
      ? `${t('fundraisersMatching', { count: fundraisers.count() })}`
      : `${t('fundraisers')}:`

  const nonprofitsTitle =
    searchTerm?.length && !nonprofitsLoading
      ? `${t('organizationsMatching', { count: nonprofits.count() })}`
      : `${t('organizations')}:`

  const fundraisersSelected = !donateTypes?.length || donateTypes?.includes(DonateTypes.fundraisers)
  const nonprofitsSelected = !donateTypes?.length || donateTypes?.includes(DonateTypes.nonprofits)

  return (
    <ResultsWrapper>
      {isDonationFeatureEnabled && fundraisersSelected && (
        <>
          <ResultsTitle title={fundraiserTitle} searchTerm={searchTerm} loading={fundraisersLoading} />
          {fundraisers?.count() || fundraisersLoading ? (
            <StaticDeedListWithPagination
              loading={fundraisersLoading}
              deeds={fundraisers}
              useBigList={fundraisersSelected && !nonprofitsSelected}
              loadMore={loadMoreFundraisers}
              hasMoreItems={fundraisersMoreResults}
            />
          ) : (
            <NoResults noResultType={NoResultType.NO_FUNDRAISERS} />
          )}
        </>
      )}

      {nonprofitsSelected && (
        <>
          <ResultsTitle title={nonprofitsTitle} searchTerm={searchTerm} loading={nonprofitsLoading} />
          {nonprofits?.count() || nonprofitsLoading ? (
            <NonprofitListContainer
              loadMoreNonprofits={loadMoreNonprofits}
              loading={nonprofitsLoading}
              nonprofits={nonprofits}
            />
          ) : (
            <NoResults noResultType={NoResultType.NO_NONPROFITS}>
              <Body2 style={{ color: colors.grayMediumDark }}>
                <Trans
                  t={t}
                  i18nKey="nominateOrganizations"
                  components={{
                    DeedEmail: (
                      <ExternalLink href="mailto:support@joindeed.com" size={14} style={{ color: colors.primary }} />
                    ),
                  }}
                />
              </Body2>
              <Body2 style={{ paddingTop: 8, color: colors.grayMediumDark }}>{t('thankYou')}</Body2>
            </NoResults>
          )}
        </>
      )}
      {/* Should almost never happen, only when there is a non existing filterType in the url  */}
      {!fundraisersSelected && !nonprofitsSelected && <NoResults noResultType={NoResultType.NO_FILTER_TYPE} />}
    </ResultsWrapper>
  )
}
