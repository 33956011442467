import React from 'react'
import { View } from 'react-primitives'

export const Wrapper = ({ narrow, children }: { narrow: boolean; children: React.ReactNode }) => (
  <View
    style={{
      marginRight: narrow ? 4 : 10,
      marginLeft: narrow ? 4 : 10,
      marginBottom: narrow ? 10 : 62,
      position: 'relative',
    }}
  >
    {children}
  </View>
)
