import { colors, metrics } from '..'
import { fontStyles } from '../loadWebFont'

import { breakpoints } from './breakpoints'

export const components = {
  MuiCssBaseline: {
    styleOverrides: fontStyles,
  },
  MuiPaper: {
    styleOverrides: {
      rounded: { borderRadius: 8 },
      outlined: { border: `1px solid ${colors.gray02}` },
    },
  },
  MuiContainer: {
    styleOverrides: {
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: metrics.maxWidthWide,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.black,
        color: colors.white,
        borderColor: colors.black,
      },
      arrow: {
        color: colors.black,
      },
    },
  },
} as const
