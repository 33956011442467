import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import { LogOffPlatformDonationState, initialState } from './reducer'

const selectState = (state: State): LogOffPlatformDonationState => state.get('logOffPlatformDonation') || initialState

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectEstimating = createSelector(selectState, (state) => state.get('estimating'))
export const selectEstimation = createSelector(selectState, (state) => state.get('estimation'))

export const selectCampaignsLimited = createSelector(selectState, (state) => state.get('campaignsLimited'))
