import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { initAction } from 'src/containers/screens/Organization/actions'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { Text } from 'src/retired/elements'
import Loading from 'src/retired/elements/Loading'
import SafeAreaView from 'src/retired/elements/SafeAreaView'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import epics from 'src/containers/screens/Organization/epics'
import reducer from 'src/containers/screens/Organization/reducer'
import AffiliationForm from 'src/retired/shared/AffiliationForm'

import { AffiliationScrollView, AffiliationHeader } from '../common'

const AddAffiliation = (): React.ReactElement => {
  const { metrics } = useDeedTheme()
  const dispatch = useDispatch()
  useInjectReducer({ key: 'affiliation', reducer })
  useInjectEpics({ key: 'affiliation', epics })

  const { organization: organizationId } = useParams<{ organization: string }>()

  useEffect(() => {
    dispatch(initAction(organizationId))
  }, [organizationId])

  const { t } = useTranslation('addAffiliation')

  const nonprofit = useSelector((state) => selectOrganizationById(state, organizationId))

  return (
    <SafeAreaView>
      <AffiliationScrollView>
        <AffiliationHeader title={t`newAffiliation`} />
        <View style={{ marginHorizontal: metrics.isSmall ? 0 : 80 }}>
          {!nonprofit ? (
            <Loading fill={false} />
          ) : (
            <>
              <View style={{ flexDirection: 'column' }}>
                <Text size={18} center bold style={{ marginBottom: 20 }}>
                  {t('addAffiliation', { nonprofitName: nonprofit?.name })}
                </Text>
                <Text size={16} center>
                  {t`declareAffiliation`}
                </Text>
              </View>
              <AffiliationForm nonprofitId={organizationId} />
            </>
          )}
        </View>
      </AffiliationScrollView>
    </SafeAreaView>
  )
}

export default AddAffiliation
