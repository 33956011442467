import React, { useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import type { FileRejection } from 'react-dropzone'
import { Cropper } from 'react-cropper'
import type { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Pica from 'pica'
import { View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'

import { colors } from 'src/theme'
import { Text, Icon, Touchable } from 'src/retired/elements'

const ImageCropper = ({
  withTitle,
  customWidth,
  customHeight,
  customCopy,
  customRatio,
  disableResize,
  onCrop,
  onCancel,
  onError,
}: {
  withTitle: boolean
  customWidth: string | number
  customHeight: string | number
  customCopy: unknown
  customRatio: number
  disableResize: boolean
  onCrop: (blob: Blob) => void
  onCancel: () => void
  onError: (msg: string) => void
}): JSX.Element => {
  const cropperRef = useRef<ReactCropperElement>(null)
  const [selectedFile, setSelectedFile] = useState<(File & { preview: string }) | null>(null)

  const { t } = useTranslation('imageCropper')
  const handleDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
    if (rejectedFiles.length) {
      onError(t(`invalidFileType`))
    }
    if (acceptedFiles.length > 0) {
      const [file] = acceptedFiles
      setSelectedFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    }
  }

  const handleCrop = async (file: File): Promise<void> => {
    const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas()
    if (!croppedCanvas) {
      return
    }

    const maxDimension = 250
    const quality = 0.7
    if (!disableResize && (croppedCanvas.width > maxDimension || croppedCanvas.height > maxDimension)) {
      const pica = new Pica()
      const resizedCanvas = document.createElement('canvas')
      const scalingRatio =
        croppedCanvas.width > croppedCanvas.height
          ? maxDimension / croppedCanvas.width
          : maxDimension / croppedCanvas.height
      resizedCanvas.width = croppedCanvas.width * scalingRatio
      resizedCanvas.height = croppedCanvas.height * scalingRatio
      const canvasElement: HTMLCanvasElement = await pica.resize(croppedCanvas, resizedCanvas)
      canvasElement.toBlob((blob) => blob && onCrop(blob), file.type, quality)
      // .then((rc) => rc.toBlob((blob) => onCrop(blob), file.type, quality))
    } else {
      croppedCanvas.toBlob((blob) => blob && onCrop(blob), file.type, quality)
    }
  }

  const cancelCrop = (): void => setSelectedFile(null)

  if (selectedFile) {
    return (
      <View style={{ alignSelf: 'stretch' }}>
        <View
          style={{
            backgroundColor: colors.black,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Touchable onPress={cancelCrop}>
            <Icon icon="close" style={{ width: 16, height: 16, margin: 16 }} />
          </Touchable>
          <Text medium color={colors.white}>
            {t`cropTheImage`}
          </Text>
          <Touchable
            onPress={() => {
              void handleCrop(selectedFile)
            }}
          >
            <Icon icon="tickCircleWhite" style={{ width: 24, height: 24, margin: 8, marginRight: 16 }} />
          </Touchable>
        </View>

        <Cropper
          src={selectedFile.preview}
          ref={cropperRef}
          guides={false}
          zoomable={false}
          rotatable={false}
          scalable={false}
          minCropBoxWidth={100}
          minCropBoxHeight={100}
          viewMode={2}
          autoCropArea={1}
          dragMode="move"
          aspectRatio={customRatio || 1}
          style={{
            width: '100vw',
            height: 'calc(100vh - 80px - 48px)',
            maxWidth: customWidth || 800,
            maxHeight: customHeight || 1000,
            overflow: 'hidden',
          }}
        />
      </View>
    )
  }

  return (
    <View style={{ alignSelf: 'stretch' }}>
      {withTitle && (
        <View
          style={{
            backgroundColor: colors.black,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Touchable onPress={onCancel}>
            <Icon icon="close" style={{ width: 16, height: 16, margin: 16 }} />
          </Touchable>
          <Text medium color={colors.white}>
            {t`uploadAnImageFile`}
          </Text>
          <View style={{ width: 48 }} />
        </View>
      )}
      <Dropzone onDrop={handleDrop} maxSize={1024 * 1024 * 10} multiple={false} accept="image/jpeg, image/png">
        {({ getRootProps, getInputProps, isDragReject }) => (
          <div
            {...getRootProps({
              style: {
                margin: 20,
                padding: 40,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `2px dashed ${isDragReject ? colors.pink : colors.gray}`,
              },
            })}
          >
            <input {...getInputProps()} />
            <Text>
              {customCopy || (
                <Trans
                  t={t}
                  i18nKey="dragNDropTheProfilePictureOrPressHere"
                  components={{ u: <span style={{ textDecoration: 'underline' }} /> }}
                />
              )}
            </Text>
          </div>
        )}
      </Dropzone>
    </View>
  )
}

export default ImageCropper
