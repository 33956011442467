import React from 'react'

// import { Text } from 'src/retired/shared/Typography'

import { TabType, useSearchParams } from './utils'
import { DonateResultsContainer } from './DonateTab/Results/DonateResultsContainer'
import { VolunteerResultsContainer } from './VolunteerTab/Results/VolunteerResultsContainer'

export const ResultsContainer = () => {
  const { activeTab } = useSearchParams()

  if (activeTab === TabType.volunteer) {
    return <VolunteerResultsContainer />
  }

  /* if (activeTab === TabType.event) {
    return <Text>I am the event results content. Implement me</Text>
  } */

  return <DonateResultsContainer />
}
