import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('organization') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectFollowing = createSelector(selectState, (state) => state.get('following'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
