import React from 'react'
import { State } from 'countries-state-list'
import { useTranslation } from 'react-i18next'

import { DeedMap } from 'src/entities/deed/reducer'
import { useCountries } from 'src/entities/location/api'
import Location from 'src/entities/location/model'
import Organization from 'src/entities/organization/model'
import { splitLocationCode } from 'src/utils'
import payPalCountries from 'src/utils/payPalCountriesWithStates'
import truthy from 'src/utils/truthy'

// @NOTE-CH: because of the country-states lib used in the backend https://github.com/lwhiteley/country-states/tree/master/data
const SKIPPABLE_STATES = ['TW', 'HK']

export interface CountrySelectorProps<T> {
  placeholder?: string
  countries?: Array<Location['countryCode']>
  country?: string
  defaultOpen?: boolean
  style?: React.CSSProperties
  disabled?: boolean
  showStates?: boolean
  itemsPerLocation?: Record<string, Record<string, number>>
  withVirtualFilter?: boolean
  handleChangeLocation: (value: string | null, node: T | null) => void
}

export type ItemsPerLocation = Record<string, Record<string, number>>

interface LocationNode {
  value: string
  label: string
}

export type StateNode = LocationNode & {
  stateCode: string
  countryCode: string
  type: 'STATE'
}

export type CountryNode = LocationNode & {
  stateCode: string
  countryCode: string
  options: StateNode[]
  type: 'COUNTRY'
}

export const useCountriesOptions = <T>(
  countries: string[],
  showStates: boolean,
  itemsPerLocation?: ItemsPerLocation,
  withVirtualFilter?: boolean
) => {
  const { t } = useTranslation('campaignScreen')
  const defaultCountries = useCountries()

  const filteredLocations = countries?.length
    ? defaultCountries.filter(({ countryCode }) => countries.includes(countryCode))
    : defaultCountries

  const locations = filteredLocations.map((location) => {
    const data = {
      value: location.countryCode,
      countryCode: location.countryCode,
      type: 'COUNTRY',
      label: location.country,
      options: [],
    } as T

    if (showStates && payPalCountries.includes(location.countryCode)) {
      data.options = getStatesOptions(location.countryCode, itemsPerLocation).filter(
        (option) => !SKIPPABLE_STATES.includes(option.stateCode)
      )
    }

    return data
  })
  if (withVirtualFilter) {
    locations.unshift({
      value: 'Virtual',
      countryCode: null,
      type: 'COUNTRY',
      label: t`virtual`,
      options: [],
    })
  }
  return locations
}

export const getStatesOptions = (countryCode: string, itemsPerLocation?: ItemsPerLocation) => {
  const statesMap = State.getStatesOfCountry(countryCode)

  return statesMap
    .map(({ name, isoCode }) => {
      const stateCode = isoCode
      const itemsOnLocation = itemsPerLocation?.[countryCode]?.[stateCode] ?? 0

      if (!itemsOnLocation && itemsPerLocation) {
        return null
      }

      return {
        value: `${countryCode}-${stateCode}`,
        countryCode,
        stateCode,
        type: 'STATE',
        label: name,
        disabled: !itemsPerLocation ? false : !itemsOnLocation,
      }
    })
    .filter(Boolean)
}

export const getItemsPerLocation = (deeds: DeedMap, nonprofits: Organization[]) => {
  const locations: string[] = []

  nonprofits.forEach((nonprofit) => {
    if (nonprofit?.locationObject?.locationCode) {
      locations.push(nonprofit?.locationObject?.locationCode)
    }
  })

  deeds.forEach((deed) => {
    // there is a data inconsistency, some deeds are virtual but also they have locations. So we remove the virtual ones here.
    if (!deed.virtual) {
      if (deed.locationObject?.locationCode) {
        locations.push(deed.locationObject?.locationCode)
      }

      deed
        ?.getNonprofits()
        .filter((nonprofit) => nonprofit?.locationObject?.countryCode)
        .forEach((nonprofit) => locations.push(nonprofit?.locationObject?.locationCode))
    }
  })

  return locations.filter(truthy).reduce<ItemsPerLocation>((acc, location) => {
    const { countryCode, stateCode } = splitLocationCode(location)

    if (countryCode) {
      // eslint-disable-next-line no-param-reassign
      acc = {
        ...acc,
        [countryCode]: {
          ...(acc[countryCode] || {}),
          [stateCode]: (acc[countryCode]?.[stateCode] || 0) + 1,
        },
      }
    }

    return acc
  }, {})
}
