import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import User, { RelationToMe } from 'src/entities/user/model'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { ActivityIndicator, Alert, Text } from 'src/retired/elements'
import { selectPendingRelations } from 'src/entities/relation/selectors'

import { selectRelationRequestInProgress, selectRelationRemovalInProgress } from '../Friends/selectors'
import * as FriendsActions from '../Friends/actions'
import friendsEpics from '../Friends/epics'
import friendReducer from '../Friends/reducer'

import { FriendRequestButton } from './FriendRequestButton'

export interface FriendRequestButtonProps {
  user: User
  isFriend: boolean
  relationtoMe?: RelationToMe
}

export const FriendRequestButtonWrapper = ({ user, isFriend, relationtoMe }: FriendRequestButtonProps): JSX.Element => {
  useInjectEpics({ key: 'profileFriends', epics: friendsEpics })
  useInjectReducer({ key: 'profileFriends', reducer: friendReducer })

  const dispatch = useDispatch()
  const relationRequestInProgress = useSelector((state) => selectRelationRequestInProgress(state))
  const relationRemovalInProgress = useSelector((state) => selectRelationRemovalInProgress(state))
  const pendingRelations = useSelector((state) => selectPendingRelations(state))

  const isRequested = pendingRelations?.find((relation) => relation?.to === user.id)
  const isPending = pendingRelations?.find((relation) => relation?.from.id === user.id)

  const { t } = useTranslation('profileScreen')
  if (relationRequestInProgress || relationRemovalInProgress) {
    return <FriendRequestButton text={<ActivityIndicator />} />
  } else if (isFriend && !relationtoMe) {
    return (
      <FriendRequestButton
        text={
          <Text size={13} bold style={{ textAlign: 'right' }}>
            {t`viaFacebook`}
          </Text>
        }
      />
    )
  } else if (isFriend) {
    return (
      <FriendRequestButton
        text={t`unfriend`}
        onPress={() =>
          Alert.alert(t('areYouSureYouWantToUnfriend', { userName: user.fullName.first }), undefined, [
            { text: t`common:Cancel`, style: 'cancel' },
            {
              text: t`common:Yes`,
              style: 'destructive',
              onPress: () => dispatch(FriendsActions.relationRemoveAction(user.id)),
            },
          ])
        }
      />
    )
  } else if (isRequested) {
    return (
      <FriendRequestButton
        text={t`cancelFriendRequest`}
        onPress={() => dispatch(FriendsActions.relationRemoveAction(user.id, true))}
      />
    )
  } else if (isPending) {
    return (
      <FriendRequestButton
        text={t`acceptFriendRequest`}
        onPress={() => dispatch(FriendsActions.relationAcceptAction(user.id))}
      />
    )
  }

  return (
    <FriendRequestButton text={t`addFriend`} onPress={() => dispatch(FriendsActions.relationRequestAction(user.id))} />
  )
}

export default FriendRequestButtonWrapper
