import React, { FC } from 'react'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Platform } from 'src/utils'
import { Touchable, Text } from 'src/retired/elements'

const TabsPickerRow = styled.View<object, EmotionTheme>`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid ${({ theme }: { theme: EmotionTheme }) => theme.colors.brandColor};
  border-radius: 20px;
`

const TabsPickerItem = styled.View<{ active?: boolean }>`
  text-align: center;
  background-color: ${({ active, theme }: { active: boolean; theme: EmotionTheme }) =>
    active ? theme.colors.brandColor : theme.colors.white};
  border-radius: 20px;
`

interface TabsPickerProps {
  options: Array<{ name: string | React.ReactElement; value: string }>
  selected: string
  onOptionSelect: (value: string) => void
}

const TabsPickerRowWrapper: FC<{ colors: unknown }> = ({ colors, children }) =>
  Platform.isIE ? (
    <div
      style={{
        display: '-ms-inline-grid',
        msGridTemplateColumns: '1fr 1fr',
        borderRadius: 20,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.brandColor,
      }}
    >
      {children}
    </div>
  ) : (
    <TabsPickerRow>{children}</TabsPickerRow>
  )

const TabsPickerItemWrap: FC = ({ colors, active, order, children }) =>
  Platform.isIE ? (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: active ? colors.brandColor : colors.white,
        borderRadius: 20,
        msGridColumn: order + 1,
      }}
    >
      {children}
    </div>
  ) : (
    <TabsPickerItem active={active}>{children}</TabsPickerItem>
  )

const TabsPicker: React.FC<TabsPickerProps> = ({ options, selected, onOptionSelect }) => {
  const { colors, metrics } = useDeedTheme()
  return (
    <View style={{ alignItems: 'flex-start' }}>
      <TabsPickerRowWrapper colors={colors}>
        {options.map((option, i) => (
          <TabsPickerItemWrap active={selected === option.value} colors={colors} order={i} key={option.value}>
            <Touchable onPress={() => onOptionSelect(option.value)}>
              <View style={{ paddingHorizontal: metrics.isSmall ? 16 : 20, paddingVertical: 10 }}>
                <Text
                  fontSize={12}
                  color={selected === option.value ? colors.white : colors.brandColor}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {option.name}
                </Text>
              </View>
            </Touchable>
          </TabsPickerItemWrap>
        ))}
      </TabsPickerRowWrapper>
    </View>
  )
}

export default TabsPicker
