import React, { useEffect, useMemo } from 'react'
import { View } from 'react-primitives'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'

import { styled } from 'src/theme/styled'
import { selectSuggestedCauses } from 'src/entities/cause/selectors'
import Chip from 'src/retired/shared/Chip'
import { LinkButton, Spacing, TextField, Touchable, TouchableWithoutFeedback, Text } from 'src/retired/elements'
import { Title, Body, Body1 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { selectNonprofitsSearchResults } from 'src/entities/organization/selectors'
import Icon from 'src/retired/shared/Icon'
import LoadingBar from 'src/retired/shared/LoadingBar'
import LogoLoader from 'src/retired/shared/LogoLoader'
import DeedSearchPlaceholder from 'src/retired/shared/DeedSearchPlaceholder'
import DeedHorizontal from 'src/retired/blocks/DeedHorizontal'
import NonprofitHorizontal from 'src/retired/blocks/NonprofitHorizontal'
import ScrollView from 'src/retired/elements/ScrollView'
import usePrevious from 'src/utils/usePrevious'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { ResponsiveOverlay } from 'src/retired/elements/Overlay'
import CountrySelector from 'src/components/CountrySelector'
import { Link } from 'src/navigation'

import reducer from './reducer'
import epics from './epics'
import {
  selectCountryCode,
  selectSearchTerm,
  selectSearchingDeeds,
  selectSearchingNonprofits,
  selectSearchingDeedsFailed,
  selectSearchingNonprofitsFailed,
  selectFilteredDeedsBySearchTerm,
  selectLoading,
  selectSearchVisible,
  selectSearchProgress,
  selectNonprofitsOnly,
  selectStateCode,
} from './selectors'
import * as Actions from './actions'

const NoResults = styled.View`
  border-top-width: 1px;
  border-top-color: #d8d8d8;
  border-bottom-width: 1px;
  border-bottom-color: #d8d8d8;
  padding: 44px 25px 44px 10px;
  background-color: white;
`

const SearchResults = ({
  searchProgress,
  loadingFailed,
  deeds,
  nonprofits,
  searchTerm,
  searchingDeeds,
  searchingNonprofits,
  searchingDeedsFailed,
  searchingNonprofitsFailed,
  nonprofitsOnly,
  close,
}) => {
  const { t } = useTranslation('search')
  const { colors, metrics } = useDeedTheme()
  const user = useSelector(selectCurrentUser)

  if (loadingFailed) {
    return (
      <View
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <Text bold size={18} color={colors.redDark}>
          {t`thereWasAnError`}
        </Text>
      </View>
    )
  }

  return searchTerm.length > 2 ? (
    <View style={{ backgroundColor: colors.gray03 }}>
      <LoadingBar progress={searchProgress} />
      <Spacing marginBottom={24}>
        <ScrollView
          style={{
            height: `calc(100vh - ${metrics.isSmall ? 195 : 260}px)`,
          }}
        >
          <Title
            marginBottom={18}
            marginTop={18}
            paddingLeft={metrics.isSmall ? 25 : 15}
            paddingRight={metrics.isSmall ? 25 : 30}
          >
            {t`organizations`}
          </Title>
          {searchingNonprofits ? (
            <LogoLoader
              brandColor={user?.organization?.brandColor}
              texts={[t`searchingForThePerfectMatch`, t`over2m`, t`almostThere`]}
            />
          ) : nonprofits.size === 0 ? (
            <NoResults>
              <Body center weight="bold" colour="grayText">
                {t`noResultsFound`}
              </Body>
              <Body center colour="grayText">
                {t`dontForget`}
              </Body>
            </NoResults>
          ) : null}
          <TouchableWithoutFeedback onPress={() => close()}>
            <View
              style={!searchingNonprofits && nonprofits.size !== 0 && { borderTopColor: '#d8d8d8', borderTopWidth: 1 }}
            >
              {nonprofits
                .map((nonprofit) => (
                  <NonprofitHorizontal key={nonprofit.id} nonprofit={nonprofit} ignoreCampaignParam />
                ))
                .toArray()}
            </View>
          </TouchableWithoutFeedback>
          {searchingNonprofitsFailed && (
            <View
              style={{
                marginTop: 20,
                marginBottom: 20,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <Text size={13} center color={colors.redDark}>
                {t`thereWasAnError2`}
              </Text>
            </View>
          )}
          {!nonprofitsOnly && (
            <Spacing marginTop={12} marginBottom={24}>
              <Title
                marginTop={18}
                marginBottom={18}
                paddingLeft={metrics.isSmall ? 25 : 15}
                paddingRight={metrics.isSmall ? 25 : 15}
              >
                {t`deeds`}
              </Title>
              {searchingDeeds ? (
                <DeedSearchPlaceholder />
              ) : deeds.size === 0 ? (
                <NoResults>
                  <Body center weight="bold" colour="grayText">
                    {t`noResultsFound`}
                  </Body>
                </NoResults>
              ) : null}
              <TouchableWithoutFeedback onPress={() => close()}>
                <View style={!searchingDeeds && deeds.size !== 0 && { borderTopColor: '#d8d8d8', borderTopWidth: 1 }}>
                  {deeds.map((deed) => <DeedHorizontal key={deed.id} deed={deed} ignoreCampaignParam />).toArray()}
                </View>
              </TouchableWithoutFeedback>
              {searchingDeedsFailed && (
                <View
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 25,
                  }}
                >
                  <Text size={13} center color={colors.redDark}>
                    {t`thereWasAnError2`}
                  </Text>
                </View>
              )}
            </Spacing>
          )}
        </ScrollView>
      </Spacing>
    </View>
  ) : null
}
SearchResults.propTypes = {
  loadingFailed: PropTypes.bool,
  deeds: PropTypes.object,
  nonprofits: PropTypes.object,
  searchTerm: PropTypes.string,
  close: PropTypes.func,
  searchProgress: PropTypes.number,
  nonprofitsOnly: PropTypes.bool,
}

const SearchOverlay = () => {
  const { t } = useTranslation('search')
  useInjectReducer({ key: 'search', reducer })
  useInjectEpics({ key: 'search', epics })
  const dispatch = useDispatch()

  const locations = useSelector(selectUserLocations)
  const user = useSelector(selectCurrentUser)
  const filteredDeedsBySearchType = useSelector(selectFilteredDeedsBySearchTerm)
  const searchTerm = useSelector(selectSearchTerm)
  const countryCode = useSelector(selectCountryCode)
  const stateCode = useSelector(selectStateCode)
  const userCountryCode = locations?.get(user?.location)?.countryCode
  const countryCodeWithFallback = countryCode || userCountryCode || 'US'
  const nonprofits = useSelector((state) =>
    selectNonprofitsSearchResults(state, user, searchTerm, countryCodeWithFallback, false, stateCode, [])
  )
  const searchingDeeds = useSelector(selectSearchingDeeds)
  const searchingNonprofits = useSelector(selectSearchingNonprofits)
  const searchingDeedsFailed = useSelector(selectSearchingDeedsFailed)
  const searchingNonprofitsFailed = useSelector(selectSearchingNonprofitsFailed)
  const { trendingCauses, topCauses } = useSelector(selectSuggestedCauses)
  const searchVisible = useSelector(selectSearchVisible)
  const loading = useSelector(selectLoading)
  const searchProgress = useSelector(selectSearchProgress)
  const nonprofitsOnly = useSelector(selectNonprofitsOnly)

  const includedDeedTypes = [
    user?.hasFeature('donations') && 'Campaign',
    ...(user?.hasFeature('volunteering') ? ['Event', 'BaseEvent', 'Project'] : []),
  ].filter(Boolean)
  const deeds = filteredDeedsBySearchType.filter(({ type }) => includedDeedTypes.includes(type))

  const prevValues = usePrevious({ searchingDeeds, searchingNonprofits, loading })
  const { colors, metrics } = useDeedTheme()

  const countrySelectorValue = useMemo(
    () => (stateCode ? `${countryCode}-${stateCode}` : countryCode) || countryCodeWithFallback,
    [countryCode, countryCodeWithFallback, stateCode]
  )

  useEffect(() => {
    if (searchVisible) {
      dispatch(Actions.initAction())
    }
  }, [searchVisible])

  useEffect(() => {
    if (
      (prevValues?.searchingDeeds || prevValues?.searchingNonprofits || prevValues?.loading) &&
      !searchingDeeds &&
      !searchingNonprofits &&
      !loading
    ) {
      dispatch(Actions.resetSearchProgressAction())
    }
  }, [searchingDeeds, searchingNonprofits, loading])

  if (!searchVisible) {
    return null
  }

  const onLocationChange = (value, node) => {
    // eslint-disable-next-line no-shadow
    const { countryCode, stateCode } = node || {} // in case the country selector empty, node will be null
    dispatch(Actions.searchNonprofitsAction(searchTerm, countryCode, stateCode))
  }

  const close = (reset = false) =>
    setTimeout(() => {
      dispatch(
        Actions.toggleSearchAction({
          show: false,
          searchTerm: reset ? '' : searchTerm,
        })
      )
      dispatch(Actions.resetSearchProgressAction())
    }, 100)

  return (
    <ResponsiveOverlay close={() => close()} overlayStyle={{ marginTop: metrics.isSmall ? 4 : 14 }}>
      <View style={{ height: '100%', overflow: 'hidden' }}>
        <View
          style={{
            padding: !metrics.isSmall ? 24 : 25,
            paddingTop: !metrics.isSmall ? 24 : 0,
            paddingBottom: !metrics.isSmall ? 24 : 15,
            borderBottomColor: colors.mediumGray,
            borderBottomWidth: 1,
            shadowColor: colors.black,
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.2,
            shadowRadius: 10,
            zIndex: 1,
            height: 'auto',
            backgroundColor: colors.white,
          }}
        >
          <View
            style={{
              borderRadius: 33,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors.grayLighter,
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 15,
              height: 42,
            }}
          >
            <View style={{ marginRight: 10 }}>
              <Icon icon="searchNew" width={16} />
            </View>
            <TextField
              placeholder={metrics.screenWidth > 430 ? t`searchDeedsAndOrganizationsByName` : t`search`}
              value={searchTerm}
              onChangeText={(name, text) => dispatch(Actions.searchAction(text, countryCodeWithFallback))}
              onTouched={() => {}}
              autoCapitalize="none"
              autoFocus
            />
            <Touchable onPress={() => close(true)} accessibilityRole="button" accessibilityLabel={t`close`} focusable>
              <View style={{ paddingHorizontal: 15, paddingVertical: 13 }}>
                <Icon icon="closeThick" width={14} height={14} />
              </View>
            </Touchable>
          </View>

          <Spacing marginBottom={15} />

          <View style={{ marginBottom: 15 }}>
            <Body1 weight="500" marginBottom={8}>{t`organizationLocation`}</Body1>
            <View
              style={{
                marginTop: 8,
                marginLeft: 0,
                width: '100%',
                zIndex: 1000,
              }}
            >
              <CountrySelector
                width="100%"
                placeholder={t`selectCountry`}
                country={countrySelectorValue}
                showStates
                getPopupContainer={() => document.body}
                handleChangeLocation={onLocationChange}
                showSearch
              />
            </View>
          </View>

          {metrics.isSmall ? <Spacing marginBottom={30} /> : null}

          {!user?.organization?.settings?.hideExploreSection && searchTerm.length <= 2 ? (
            <View>
              {[
                {
                  label: t`trendingCauses`,
                  causesList: trendingCauses,
                },
                {
                  label: t`topCauses`,
                  causesList: topCauses,
                },
              ].map(({ label, causesList }, suggestedCauseIndex) =>
                causesList.count() > 0 ? (
                  <React.Fragment key={`cause-suggestion-${suggestedCauseIndex}`}>
                    <Body1 weight="500" marginTop={8}>
                      {label}
                    </Body1>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        marginTop: 16,
                        marginBottom: 8,
                      }}
                    >
                      {causesList
                        .map((cause, index) => (
                          <Link
                            key={`suggestion-cause-list-${index}`}
                            to={`/causes/${(cause.type || '').toLowerCase()}/${cause.id}`}
                            onClick={() => close(true)}
                            style={{ marginRight: 8, marginBottom: 8 }}
                          >
                            <Chip type="cause-suggestion" image={cause.banner}>
                              {cause.name}
                            </Chip>
                          </Link>
                        ))
                        .toArray()}
                    </View>
                  </React.Fragment>
                ) : null
              )}
              <LinkButton
                to="/causes"
                color="transparent"
                style={{ alignSelf: 'flex-start', marginTop: 8 }}
                onPress={() => close(true)}
              >
                <Body1 colour={colors.brandColor} weight="500" paddingRight={2}>
                  {t`exploreAllCauses`}
                </Body1>

                <FlagOutlinedIcon style={{ color: colors.brandColor, fontSize: 16, verticalAlign: 'middle' }} />
              </LinkButton>
            </View>
          ) : null}
        </View>
        <SearchResults
          searchProgress={searchProgress}
          deeds={deeds}
          nonprofits={nonprofits}
          actions={Actions}
          searchTerm={searchTerm}
          searchingDeeds={searchingDeeds}
          searchingNonprofits={searchingNonprofits}
          searchingDeedsFailed={searchingDeedsFailed}
          searchingNonprofitsFailed={searchingNonprofitsFailed}
          nonprofitsOnly={nonprofitsOnly}
          close={close}
        />
      </View>
    </ResponsiveOverlay>
  )
}

export default SearchOverlay
