import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Button, Image, ExternalLink } from 'src/retired/elements'
import { metrics } from 'src/theme'
import { Platform } from 'src/utils'

const width = metrics.screenWidth
const height = metrics.screenHeight

const styles = StyleSheet.create({
  root: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(74, 71, 71, .7)',
  },
  wrapper: {
    backgroundColor: '#CFD1D0',
    borderRadius: 10,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  button: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
})

// eslint-disable-next-line jsx-a11y/alt-text
const WebImage = ({ source, ...props }) => <img src={source.uri} {...props} />
WebImage.propTypes = {
  source: PropTypes.object,
}
const Img = Platform.OS === 'web' ? WebImage : Image

const ImageOverlay = ({ image, buttonText, close }) => {
  const { t } = useTranslation('imageOverlay')

  return (
    <View style={styles.root}>
      <View style={{ paddingTop: 0, paddingBottom: 73 }}>
        <View style={styles.wrapper}>
          <ExternalLink href={image} style={{ fontSize: 0, lineHeight: 0, flexShrink: 0 }}>
            <Img
              source={{ uri: image }}
              style={{
                width: width - 50,
                maxHeight: height - 150,
                borderRadius: 10,
                backgroundColor: '#fff',
                ...Platform.select({
                  default: {
                    aspectRatio: 314 / 480,
                  },
                  web: {
                    width: width < height ? 628 : 'auto',
                    height: width > height ? 960 : 'auto',
                    maxWidth: 'calc(100vw - 50px)',
                    maxHeight: 'calc(100vh - 150px)',
                    minWidth: width > 640 ? 'calc(100vh * (942 / 1440))' : undefined,
                    minHeight: width > 640 ? 'calc(100vh - 150px)' : undefined,
                  },
                }),
              }}
            />
          </ExternalLink>
        </View>
        <View style={styles.button}>
          <Button onPress={close}>{buttonText || t`iAgree`}</Button>
        </View>
      </View>
    </View>
  )
}
ImageOverlay.propTypes = {
  image: PropTypes.string.isRequired,
  close: PropTypes.func,
  buttonText: PropTypes.string,
}
export default ImageOverlay
