import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import { initialState } from './reducer'

const selectState = (state: State) => state.get('donationSchedule') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectUpdating = createSelector(selectState, (state) => state.get('updating'))

export const selectDeleting = createSelector(selectState, (state) => state.get('deleting'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectUpdateError = createSelector(selectState, (state) => state.get('updateError'))

export const selectDeleteError = createSelector(selectState, (state) => state.get('deleteError'))
