import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider as EmotionThemeProvider, useTheme } from '@emotion/react'
import type { ThemeProviderProps, Theme } from '@emotion/react'

import { MuiThemeProvider } from 'src/theme/MuiTheme/MuiThemeProvider'
import { getContrastColor } from 'src/utils'
import * as theme from 'src/theme'
import { calculateMetrics, MetricsProps } from 'src/theme/metrics'
import { selectUserBrandColor } from 'src/entities/user/selectors'

export type EmotionTheme = Theme

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [metrics, setMetrics] = useState<MetricsProps>(calculateMetrics())
  const userOrganizationBrandColor = useSelector(selectUserBrandColor)
  const brandColor = userOrganizationBrandColor || theme.colors.primary

  useEffect(() => {
    const handleResize = (): void => {
      setMetrics(calculateMetrics())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty('--brand', brandColor)
  }, [brandColor])

  const emotionTheme: EmotionTheme = {
    ...theme,
    colors: { ...theme.colors, brandColor, userOrganizationBrandColor },
    metrics,
    getContrastColor,
  }

  return (
    <MuiThemeProvider brandColor={brandColor}>
      <EmotionThemeProvider theme={emotionTheme}>{children}</EmotionThemeProvider>
    </MuiThemeProvider>
  )
}

export const useDeedTheme = useTheme as () => EmotionTheme
