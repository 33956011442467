import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('deedConfirm') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
