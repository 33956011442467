import { Map } from 'immutable'

import { BOOTSTRAP, BRAND } from './constants'

export const initialState = Map({
  bootstrapped: false,
  branded: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case BOOTSTRAP:
      return state.set('bootstrapped', true)

    case BRAND:
      return state.set('branded', true)

    default:
      return state
  }
}
