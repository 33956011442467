export const getStyles = (colors, metrics) => ({
  button: {
    width: metrics.screenWidth > 375 ? 375 : metrics.screenWidth - 50,
    minHeight: 48,
    padding: 0,
    backgroundColor: colors.black,
    borderRadius: 100,
    borderWidth: 0,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    shadowColor: colors.black,
    elevation: 2,
    borderStyle: 'solid',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fluid: {
    width: 'auto',
    paddingLeft: 50,
    paddingRight: 50,
  },
  small: {
    borderRadius: 50,
    minHeight: 40,
    lineHeight: '40px',
  },
  extraSmall: {
    borderRadius: 32,
    minHeight: 32,
    lineHeight: '32px',
    boxShadow: 'none',
  },
  icon: {
    width: 52,
    height: 52,
  },
  primary: {
    backgroundColor: colors.brandColor,
  },
  secondary: {
    backgroundColor: colors.pink,
  },
  tertiary: {
    backgroundColor: colors.teal,
  },
  light: {
    backgroundColor: colors.white,
  },
  gray: {
    backgroundColor: colors.gray02,
  },
  outlined: {
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderWidth: 1,
    borderStyle: 'solid',
    shadowColor: 'transparent',
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    boxShadow: 'none',
  },
  transparent: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
    padding: 0,
    width: 'auto',
    cursor: 'pointer',
    lineHeight: 1,
  },
  text: {
    fontFamily: 'GTWalsheimLC',
    fontWeight: 500,
    color: colors.white,
    fontSize: 15,
    lineHeight: '48px',
    userSelect: 'none',
  },
  alternativeText: {
    color: 'rgba(0, 0, 0, 0.2)',
  },
  darkText: {
    color: colors.darkGray,
  },
  smallText: {
    fontFamily: 'GTWalsheimLC',
    fontWeight: 500,
    fontSize: 14,
  },
  extraSmallText: {
    fontFamily: 'GTWalsheimLC',
    fontWeight: 500,
    fontSize: 12,
  },
  fluidSmall: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  disabled: {
    opacity: 0.25,
    cursor: 'not-allowed',
  },
})

export default getStyles
