import React from 'react'

import ScrollContainer from 'src/retired/shared/ScrollContainer'
import CardList from 'src/retired/shared/CardList'
import { CompactCard } from 'src/components/CompactCard'
import { Activity, Action } from 'src/containers/modules/Actions/types'

interface CardActivity extends Activity {
  Action: Action
}

const getCampaignTitle = (action: Action) => {
  const campaignTitle = action?.Campaigns[0]?.title
  if (!campaignTitle) {
    return null
  }

  if (campaignTitle.length < 19) {
    return campaignTitle
  }

  return `${campaignTitle.substring(0, 16)}...`
}

export const ActionCardsRow = ({ activities }: { activities: CardActivity[] }) => (
  <ScrollContainer
    cardType="card"
    dimension={activities?.length}
    withoutTopSpacing
    withoutBottomSpacing
    render={(scrollViewOffset) => (
      <CardList scrollViewOffset={scrollViewOffset}>
        {activities?.map((activity) => {
          const action = activity.Action
          const avatarItems =
            action?.Activities.map((actionActivity) => ({
              id: actionActivity.User.id,
              mainPicture: actionActivity.User.mainPicture,
              fullName: actionActivity.User.fullName,
              preferredName: actionActivity.User.preferredName,
            })).slice(0, 2) || []

          return (
            <CompactCard
              key={activity.id}
              link={`/action/${action.id}`}
              image={action.bannerImage}
              title={action.title}
              description={action.subTitle}
              avatarItems={avatarItems}
              // eslint-disable-next-line no-unsafe-optional-chaining
              numNotShownAvatars={action.Activities?.length - 2 > 0 ? action.Activities?.length - 2 : undefined}
              label={getCampaignTitle(action)}
            />
          )
        })}
      </CardList>
    )}
  />
)
