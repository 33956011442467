import React, { FC, PropsWithChildren } from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface CardCompactWrapperProps extends PropsWithChildren {
  cardColor: string
  fullWidth?: boolean
  height?: number
}

export const CardCompactWrapper: FC<CardCompactWrapperProps> = ({ cardColor, children, fullWidth, height }) => {
  const { colors } = useDeedTheme()
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.grayLighter,
        borderTopWidth: 3,
        borderTopColor: cardColor,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 12,
        width: fullWidth ? '100%' : 148,
        height: height || 148,
        marginRight: 15,
      }}
    >
      {children}
    </View>
  )
}
