import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import { TypedMap } from 'src/utils/typed-map'

import { SEND_EMAIL, SEND_EMAIL_FAILED, SEND_EMAIL_SUCCESS } from './constants'

export type MailFormState = TypedMap<{ sending: boolean }>

export const initialState = fromJS({
  sending: false,
}) as MailFormState

export default (state = initialState, action: AnyAction): MailFormState => {
  switch (action.type) {
    case SEND_EMAIL:
      return state.set('sending', true)

    case SEND_EMAIL_FAILED:
      return state.set('sending', false)

    case SEND_EMAIL_SUCCESS:
      return state.set('sending', false)

    default:
      return state
  }
}
