import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Spacing } from 'src/retired/elements'
import DeedsBlock from 'src/retired/blocks/DeedsBlock'
import { selectFeaturedDeedsLoaded, selectFeaturedDeeds } from 'src/entities/deed/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'

import { LoadingSpinner } from './LoadingSpinner'
import { fetchFeaturedDeedsAction } from './actions'
import epics from './epics'
import reducer from './reducer'

export const TimelyDeedsBlock = () => {
  const { t } = useTranslation('homeScreen')
  useInjectReducer({ key: 'home', reducer })
  useInjectEpics({ key: 'home', epics })

  const featuredDeedsLoaded = useSelector(selectFeaturedDeedsLoaded)
  const featuredDeeds = useSelector(selectFeaturedDeeds)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!featuredDeedsLoaded) {
      dispatch(fetchFeaturedDeedsAction())
    }
  }, [])

  if (!featuredDeedsLoaded) {
    return (
      <Spacing marginTop={60}>
        <LoadingSpinner />
      </Spacing>
    )
  }

  return featuredDeeds.size > 0 ? (
    <DeedsBlock title={t`timelyDeedsYouCanHelpWith`} deeds={featuredDeeds} hidePartnerLogo subTitle="" />
  ) : null
}
