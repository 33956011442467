import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body1, H5 } from 'src/retired/shared/Typography'
import { useHistory } from 'src/navigation'
import { Button, Image } from 'src/retired/elements'

import { MainContainer, InnerContainer } from './style'

interface ErrorBoundaryPageProps {
  resetError: () => void
}

export const ErrorBoundaryPage = ({ resetError }: ErrorBoundaryPageProps): JSX.Element => {
  const { t } = useTranslation('errorTemplates')
  const { images } = useDeedTheme()
  const history = useHistory()

  return (
    <MainContainer>
      <InnerContainer>
        <Button
          onPress={() => {
            resetError()
            history.push('/')
          }}
          color="transparent"
        >
          <Image source={images.deedLogoHorizontal} style={{ width: 100, height: 41 }} />
        </Button>
        <H5 style={{ marginTop: 16 }}>{t`somethingWentWrongTitle`}</H5>
        <Body1>{t`somethingWentWrongContent`}</Body1>
      </InnerContainer>
    </MainContainer>
  )
}
