import { getAnalyticsContext } from 'src/utils/analyticsContext'
import { useSendSegmentEventMutation } from 'src/generated/graphql'

export const makeReactionCreatedEvent = ({
  reactionId,
  reactionType,
  postId,
  userId,
  communityId,
}: {
  reactionId?: string
  reactionType: string
  postId: string
  userId: string
  communityId?: string
}) => ({
  version: 1,
  event: 'communities:reaction-created',
  properties: {
    reactionId,
    reactionType,
    postId,
    communityId,
  },
  userId,
  context: getAnalyticsContext({ userId }),
})

export const useSegment = () => {
  const [sendSegmentEventMutation] = useSendSegmentEventMutation()
  return async (event: Record<string, unknown>) =>
    sendSegmentEventMutation({
      variables: { event: JSON.stringify(event) },
    })
}
