import React from 'react'
import { View } from 'react-primitives'

import { ImpactChip } from 'src/containers/modules/Actions/Card/ImpactChip'
import { RewardChip } from 'src/containers/modules/Actions/Card/RewardChip'
import { Action, IncentiveType } from 'src/containers/modules/Actions/types'

import { ActivitiesContainer } from './Activities/ActivitiesContainer'

export const SideContent = ({ action }: { action: Action }) => {
  const impact = action.incentives.find((incentive) => incentive.type === IncentiveType.Impact)
  const reward = action.incentives.find((incentive) => incentive.type === IncentiveType.Reward)

  return (
    <>
      {reward && <RewardChip unit={reward.unit} amount={reward.amount} />}
      <View style={{ paddingTop: 12 }}>
        <ImpactChip unit={impact?.unit} amount={impact?.amount} />
      </View>
      {action?.Activities ? (
        <View style={{ paddingTop: 12 }}>
          <ActivitiesContainer action={action} />
        </View>
      ) : null}
    </>
  )
}
