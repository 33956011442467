import { CauseActions } from './actions'
import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  FETCH_CAUSE_NONPROFITS,
  FETCH_CAUSE_NONPROFITS_SUCCESS,
  FETCH_CAUSE_NONPROFITS_FAILED,
  FOLLOW,
  FOLLOW_SUCCESS,
  FOLLOW_FAILED,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS,
  FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED,
} from './constants'

export interface CauseState {
  loading: boolean
  fetchingNonprofits: boolean
  fetchingNonprofitsError: unknown
  fetchingProjects: boolean
  fetchingProjectsError: unknown
  fetchingCampaigns: boolean
  fetchingCampaignsError: unknown
  fetchingEvents: boolean
  fetchingEventsError: unknown
  fetchingBaseEvents: boolean
  fetchingBaseEventsError: unknown
  error: unknown
  submitting: boolean
}

export const initialState = {
  loading: false,
  fetchingNonprofits: false,
  fetchingNonprofitsError: null,
  fetchingProjects: false,
  fetchingProjectsError: null,
  fetchingCampaigns: false,
  fetchingCampaignsError: null,
  fetchingEvents: false,
  fetchingEventsError: null,
  fetchingBaseEvents: false,
  fetchingBaseEventsError: null,
  error: null,
  submitting: false,
} as CauseState

export default (state = initialState, action: CauseActions) => {
  switch (action.type) {
    case INIT:
      return { ...state, loading: true, error: null }

    case INIT_SUCCESS:
      return { ...state, loading: false }

    case INIT_FAILED:
      return { ...state, loading: false, error: action.error }

    case FETCH_CAUSE_NONPROFITS:
      return { ...state, fetchingNonprofits: true, fetchingNonprofitsError: null }

    case FETCH_CAUSE_NONPROFITS_SUCCESS:
      return { ...state, fetchingNonprofits: false }

    case FETCH_CAUSE_NONPROFITS_FAILED:
      return { ...state, fetchingNonprofits: false, fetchingNonprofitsError: action.error }

    case FETCH_CAUSE_DEEDS_BY_DEED_TYPE:
      return { ...state, [`fetching${action.deedType}s`]: true, [`fetching${action.deedType}sError`]: false }

    case FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS:
      return { ...state, [`fetching${action.deedType}s`]: false }

    case FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED:
      return { ...state, [`fetching${action.deedType}s`]: false, [`fetching${action.deedType}sError`]: action.error }

    case FOLLOW:
      return { ...state, submitting: true, error: null }

    case FOLLOW_SUCCESS:
      return initialState

    case FOLLOW_FAILED:
      return { ...state, submitting: false, error: action.error }

    case 'RESET':
      return initialState

    default:
      return state
  }
}
