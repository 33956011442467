import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { CampaignScreenState, initialState } from './reducer'

const selectState = (state: State): CampaignScreenState => state.get('campaign') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
