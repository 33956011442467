import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { selectCurrentUser } from 'src/entities/user/selectors'
import UserApi from 'src/entities/user/api'
import SkillsApi from 'src/entities/skill/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectSkills } from 'src/entities/skill/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'
import truthy from 'src/utils/truthy'

import { submitSuccessAction, submitFailedAction, initSuccessAction, initFailedAction } from './actions'
import { SUBMIT, INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    const skills = selectSkills(state)
    const user = selectCurrentUser(state)

    if (skills.size > 0) {
      return Observable.of(initSuccessAction(user))
    }

    return SkillsApi.fetchAll()
      .mergeMap((action) => [action, initSuccessAction(user)])
      .catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('skillsRegister:failedLoadingSkills'))))
  })

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ skills, path }) =>
      UserApi.update({ skills })
        .mergeMap((action) =>
          [
            action,
            path === '/profile/skills' && replace('/profile'),
            path === '/register/skills' && replace('/search?tab=volunteer'),
            submitSuccessAction(),
          ].filter(truthy)
        )
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    )
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [init, submit]
