import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Loading, Spacing, Screen } from 'src/retired/elements'
import { injectReducer, injectEpics, Platform } from 'src/utils'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { H5 } from 'src/retired/shared/Typography'
import Onboarding, { getOnboardingStepCount } from 'src/retired/blocks/Onboarding'
import Icon from 'src/retired/shared/Icon'
import SelectBox from 'src/retired/shared/SelectBox'
import { Redirect } from 'src/navigation'

import { selectSelectedDepartment, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export const RegisterCompanyDepartment = ({ actions, selectedDepartment, user, submitting }) => {
  const { t } = useTranslation('companyRegister')
  if (!user) {
    return <Loading />
  }

  if (user.organizationDepartment) {
    return (
      <Redirect
        to={
          !user.location && !user.getSetting('disableEmployeeLocationUpdate')
            ? '/register/location'
            : '/register/interests'
        }
      />
    )
  }

  return (
    <Screen fixed>
      <Onboarding
        page={getOnboardingStepCount(user).departmentStep}
        confirmHandler={() => actions.submitAction()}
        confirmDisabled={!selectedDepartment}
        confirmSubmitting={submitting}
      >
        <View
          style={{
            alignItems: 'center',
            minHeight: 432,
            paddingTop: 30,
            paddingBottom: 30,
          }}
        >
          <View
            style={{
              marginBottom: 8,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <H5>{t`whichDepartmentAreYouIn`} </H5>
            <Icon icon="city" width={22} height={22} />
          </View>
          <Spacing marginTop={48} />
          <SelectBox
            style={Platform.OS === 'web' ? { width: '65%' } : { alignSelf: 'stretch' }}
            onValueChange={(value) => actions.selectDepartmentAction(value)}
            onSelect={(value) => actions.selectDepartmentAction(value)}
            onDeSelect={(value) => actions.selectDepartmentAction(value)}
            placeholder={t`selectDepartment`}
            value={selectedDepartment || 'Select department'}
            options={user.organization.departments}
          />
        </View>
      </Onboarding>
    </Screen>
  )
}

const withConnect = connect(
  (state) => ({
    selectedDepartment: selectSelectedDepartment(state),
    submitting: selectSubmitting(state),
    user: selectCurrentUser(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerCompanyDepartment', reducer })
const withEpics = injectEpics({ key: 'registerCompanyDepartment', epics })

export default compose(withReducer, withEpics, withConnect)(RegisterCompanyDepartment)
