import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'

import { SUBMIT } from './constants'
import { submitSuccessAction, submitFailedAction } from './actions'

const submit = (action$) =>
  action$.ofType(SUBMIT).exhaustMap(({ token, password, operation }) =>
    UserApi.resetPassword(token, password)
      .mergeMap((result) => [
        submitSuccessAction(),
        showMessageAction(i18n.t('loginScreen:passwordSetSuccessfully', { context: operation })),
        replace(`/login#${result.email}`),
      ])
      .catch((e) => {
        if (!e.response) {
          return Observable.of(submitFailedAction(e), showErrorAction('An error occurred'))
        }
        return Observable.of(
          submitFailedAction(e),
          replace('/login/forgot'),
          e.response.status === 404
            ? showErrorAction(
                i18n.t('loginScreen:tokenNotFound', { context: operation }),
                i18n.t('loginScreen:cannotFindToken', { context: operation })
              )
            : showErrorAction(
                i18n.t('loginScreen:passwordResetExpired', { context: operation }),
                i18n.t('loginScreen:pleaseRequestANewLink', { context: operation })
              )
        )
      })
  )

export default [submit]
