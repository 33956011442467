import React, { useMemo } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body2 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { Row } from 'src/retired/elements'
import { getPasswordRequirements } from 'src/utils/password'

const ValidationRow = ({
  valid,
  message,
  colors,
}: {
  valid: boolean
  message: string
  colors: { valid: string; invalid: string }
}) => {
  const color = valid ? colors.valid : colors.invalid
  const icon = valid ? 'checkmark' : 'closeNew'

  if (!message) {
    return null
  }

  return (
    <Row style={{ alignItems: 'center' }}>
      <View>
        <Icon icon={icon} width={11} hexColor={color} />
      </View>
      <Body2 colour={color} style={{ marginVertical: 5, marginLeft: 5, fontSize: 12 }}>
        {message}
      </Body2>
    </Row>
  )
}

export const PasswordRequirements = ({ password, userInputs = [] }: { password: string; userInputs?: string[] }) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('password')

  const userInputsSerialized = JSON.stringify(userInputs)
  const requirements = useMemo(
    () =>
      getPasswordRequirements(password, JSON.parse(userInputsSerialized) as string[]).filter(
        ({ message }) => !!message
      ),
    [password, userInputsSerialized]
  )

  return (
    <View>
      {requirements.map(({ message, parameters, validator }) => (
        <ValidationRow
          key={message}
          message={t(message, parameters)}
          valid={validator(password, userInputs)}
          colors={{ valid: colors.green, invalid: colors.redDark }}
        />
      ))}
    </View>
  )
}
