import React from 'react'
import { View } from 'react-primitives'

import Icon from 'src/retired/elements/Icon'
import i18n from 'src/i18n'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Divider, Loading, Screen, ScrollView } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Body1, H4, H5, Label } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getLanguageCode } from 'src/utils/languageUtils'

export const VolunteerRewardEligibilityUI = ({
  headerTitle,
  headerSubtitle,
  illustration,
  styleIllustration,
  contentTitle,
  content,
  buttonText,
  onPressCta,
  loading,
}: {
  headerTitle: string
  headerSubtitle: string
  illustration: string
  styleIllustration: StyleSheet
  contentTitle: string
  content: string
  buttonText: string
  onPressCta: () => void
  loading: boolean
}) => {
  const { colors } = useDeedTheme()

  return (
    <Screen>
      <ScrollView>
        <NavigationHeader transparent back={false} />
        <View>
          <Label marginBottom={8} center weight="500" colour={colors.brandColor}>
            {headerTitle.toLocaleUpperCase(getLanguageCode(i18n.language))}
          </Label>
          <H4 center marginBottom={8}>
            {headerSubtitle}
          </H4>
        </View>
        <Divider style={{ marginLeft: 20, marginRight: 20 }} />

        {loading ? (
          <View style={{ height: 100 }}>
            <Loading fill={false} backgroundColor="transparent" />
          </View>
        ) : (
          <View style={{ alignItems: 'center', paddingHorizontal: 32, paddingVertical: 16 }}>
            <Icon icon={illustration} style={{ ...styleIllustration, height: 159, marginBottom: 32 }} />
            <H5 center style={{ marginBottom: 32 }}>
              {contentTitle}
            </H5>
            <Body1 style={{ color: colors.gray08 }}>{content}</Body1>
          </View>
        )}

        <View style={{ display: 'flex', width: '100%', alignItems: 'center', paddingTop: 25, paddingBottom: 25 }}>
          <Button style={{ width: 'auto' }} palette="primary" largeText onPress={onPressCta} rounded>
            {buttonText}
          </Button>
        </View>
      </ScrollView>
    </Screen>
  )
}
