import { RATE, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const rateAction = (rating) => ({
  type: RATE,
  rating,
})

export const submitAction = (id, rating, friendRequests, skipped = false) => ({
  type: SUBMIT,
  id,
  rating,
  friendRequests,
  skipped,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
