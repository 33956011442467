import { Record } from 'immutable'
import _ from 'lodash'
import i18n from 'i18next'

const properties = {
  id: '',
  name: '',
  code: '',
}

export default class Skill extends Record(properties, 'Skill') implements Skill {
  public readonly id!: string

  public readonly _name!: string

  public readonly code!: string

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({ ...validValues, _name: validValues.name })
  }

  get name() {
    return i18n.exists(`skills:${this.code}-name`) ? i18n.t(`skills:${this.code}-name`) : this._name
  }
}
