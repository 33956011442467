/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unsafe-optional-chaining */
import React, { PureComponent } from 'react'
import _ from 'lodash'
import { View, StyleSheet } from 'react-primitives'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import type { FormikProps } from 'formik'
import { withTranslation } from 'react-i18next'
import type { TransProps } from 'react-i18next'

import locales from 'src/i18n/locales'
import { interpolationFormat } from 'src/i18n/interpolationFormat'
import PhoneInput from 'src/retired/elements/PhoneInput'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import {
  Button,
  Action,
  Alert,
  Loading,
  TextField,
  ActivityIndicator,
  Picker,
  Spacing,
  Text,
  Screen,
  ScrollView,
  Row,
  Tooltip,
} from 'src/retired/elements'
import { Prompt } from 'src/navigation'
import { colors } from 'src/theme'
import { injectReducer, injectEpics, Platform, validators } from 'src/utils'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import User from 'src/entities/user/model'
import Location from 'src/entities/location/model'
import { State } from 'src/reducers'

import { selectLoading, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'
import { DeleteAccountSection } from './DeleteAccountSection'

const styles = StyleSheet.create({
  header: {
    paddingTop: 25,
    paddingBottom: 10,
    marginBottom: 10,
  },
  field: {
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
  },
  error: {
    color: colors.redDark,
    minHeight: 16,
    marginTop: 2,
    textAlign: 'center',
  },
  label: {
    marginRight: 15,
  },
  link: {
    color: colors.blue,
  },
  pickerField: {
    height: 30,
    marginBottom: 5,
    marginRight: -15,
    flexGrow: 1,
  },
  pickerFieldItem: {
    fontFamily: 'GTWalsheimLC',
    fontSize: 12,
    color: colors.darkGray,
  },
  birthdayOption: {
    width: 'auto',
    height: 30,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 10,
    flexGrow: 0,
  },
})

const genericDate = new Date(0)

interface FormValues {
  instagram: string
  fullName: { first: string; last: string }
  preferredName: { first: string; last: string }
}

type Props = {
  user: User
  locations: Location
  actions: typeof Actions
  setFieldValue: () => void
  t: (v: string) => string
  dirty: boolean
  setFieldTouched: () => void
  loading: boolean
  submitting: boolean
} & FormikProps<FormValues> &
  TransProps

class Settings extends PureComponent<Props> {
  state = {
    dobSelect: false,
    dob: { month: 0, day: 0, year: 0 },
  }

  dateTool(
    inputdate: Date,
    method: 'setUTCFullYear' | 'getMonth' | 'setDate' | 'setUTCMonth' | 'getDate' | 'getFullYear' | 'getFullDate',
    term: string
  ): Date | number {
    const d = typeof inputdate === 'object' ? inputdate : new Date(inputdate)
    let dateResult: Date | number = new Date()
    // eslint-disable-next-line radix
    const digit = parseInt(term)

    switch (method) {
      case 'setUTCFullYear':
        if (d.getTime() !== genericDate.getTime()) {
          inputdate[method](digit)
          this.setSelected()
        } else if (this.state.dob.month > 0 && this.state.dob.day > 0) {
          inputdate.setUTCFullYear(digit, this.state.dob.month - 1, this.state.dob.day)
          this.setSelected()
        }
        this.setState({ dob: { ...this.state.dob, year: digit } })
        dateResult = inputdate
        break

      case 'setDate':
        if (d.getTime() !== genericDate.getTime()) {
          inputdate[method](digit)
          this.setSelected()
        } else if (this.state.dob.month > 0 && this.state.dob.year > 0) {
          inputdate.setUTCFullYear(this.state.dob.year, this.state.dob.month - 1, digit)
          this.setSelected()
        }
        this.setState({ dob: { ...this.state.dob, day: digit } })
        dateResult = inputdate
        break

      case 'setUTCMonth':
        if (d.getTime() !== genericDate.getTime()) {
          inputdate[method](digit - 1)
          this.setSelected()
        } else if (this.state.dob.day > 0 && this.state.dob.year > 0) {
          inputdate.setUTCFullYear(this.state.dob.year, digit - 1, this.state.dob.day)
          this.setSelected()
        }
        this.setState({ dob: { ...this.state.dob, month: digit } })
        dateResult = inputdate
        break

      case 'getFullYear':
      case 'getDate':
        dateResult = d[method]()
        break

      case 'getMonth':
        dateResult = d[method]() + 1
        break

      case 'getFullDate':
        dateResult = d
        break

      default:
        console.error('Invalid date method')
        break
    }

    return dateResult
  }

  labelMonth(m: number, l: string): string {
    if (m < 0 || m > 12) {
      return ''
    }

    const s = m < 10 ? `0${m}` : String(m)

    const numericDate = new Date(`1970-${s}-02`)
    return interpolationFormat(numericDate, 'LLLL', l)
  }

  setSelected(): void {
    this.setState({ dobSelect: true })
  }

  componentDidMount(): void {
    this.props.actions.initAction()
  }

  render(): JSX.Element {
    const {
      t,
      user,
      loading,
      submitting,
      values,
      errors,
      touched,
      actions,
      setFieldValue,
      setFieldTouched,
      dirty,
      isValid,
      locations,
    } = this.props

    if (loading || !user) {
      return <Loading />
    }

    const departments = [...(user.organization?.departments ?? [])]
    // Add custom user departments
    if (user.organizationDepartment && departments?.indexOf(user.organizationDepartment) === -1) {
      departments.unshift(user.organizationDepartment)
    }

    const isPreferredNameEnabled = Boolean(user.organization?.hasHrisIntegration || user.sso?.id)

    const minDate = new Date().getUTCFullYear() - 15
    const yearRange = _.range(minDate, minDate - 83)
    const dayRange = _.range(1, 32)
    const monthRange = _.range(1, 13)
    const hasFormChanged = dirty || this.state.dobSelect

    return (
      <Screen>
        <ScrollView>
          <NavigationHeader title={t`settings`} />
          {user && (
            <Screen padding action header safeArea={false}>
              <Prompt when={dirty} message={t`youHaveUnsavedChanges`} />

              <Text size={13} bold style={styles.header}>
                {t`general`}
              </Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`language`}
                </Text>
                <Picker
                  onValueChange={(value) => {
                    setFieldValue('locale', value)
                    setFieldTouched('locale', true)
                  }}
                  selectedValue={values.locale}
                  style={[styles.pickerFieldItem, styles.pickerField]}
                >
                  {locales.map((locale) => (
                    <Picker.Item key={locale.code} value={locale.code} label={locale.name} />
                  ))}
                </Picker>
              </View>

              <Text size={13} bold style={styles.header}>
                {t`personalInformation`}
                {isPreferredNameEnabled ? <Tooltip>{t`chosenNameMessage`}</Tooltip> : null}
              </Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`common:firstName`}
                  {t`common:requiredSymbol`}:
                </Text>
                <TextField
                  onChangeText={setFieldValue}
                  onTouched={setFieldTouched}
                  name="fullName.first"
                  value={values?.fullName?.first}
                  maxLength={35}
                  disabled={isPreferredNameEnabled}
                />
              </View>

              <Text style={styles.error}>
                {touched?.fullName?.first && errors?.fullName?.first && errors.fullName.first}
              </Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`common:lastName`}
                  {t`common:requiredSymbol`}:
                </Text>
                <TextField
                  onChangeText={setFieldValue}
                  onTouched={setFieldTouched}
                  name="fullName.last"
                  value={values?.fullName?.last}
                  maxLength={70}
                  disabled={isPreferredNameEnabled}
                />
              </View>

              <Text style={styles.error}>
                {touched?.fullName?.last && errors?.fullName?.last && errors.fullName.last}
              </Text>

              {isPreferredNameEnabled ? (
                <>
                  <View style={styles.field}>
                    <Text size={12} medium style={styles.label}>
                      {t`chosenFirstName`}:
                    </Text>
                    <TextField
                      onChangeText={setFieldValue}
                      onTouched={setFieldTouched}
                      name="preferredName.first"
                      value={values?.preferredName?.first}
                      maxLength={35}
                    />
                  </View>

                  <View style={styles.field}>
                    <Text size={12} medium style={styles.label}>
                      {t`chosenLastName`}:
                    </Text>
                    <TextField
                      onChangeText={setFieldValue}
                      onTouched={setFieldTouched}
                      name="preferredName.last"
                      value={values?.preferredName?.last}
                      maxLength={70}
                    />
                  </View>

                  {(touched?.preferredName?.first || touched?.preferredName?.last) &&
                  errors?.preferredName?.bothNamesRequired ? (
                    <Text style={styles.error}>{t`bothNamesRequired`}</Text>
                  ) : null}
                </>
              ) : null}

              {!user.isEmployee() && (
                <>
                  <View style={styles.field}>
                    <Text size={12} medium style={styles.label}>
                      {t`instagram`}
                    </Text>
                    <TextField
                      onChangeText={setFieldValue}
                      onTouched={setFieldTouched}
                      name="instagram"
                      value={values.instagram}
                      placeholder={t`yourInstagram`}
                      autoCapitalize="none"
                      keyboardType={Platform.OS === 'ios' ? 'twitter' : 'default'}
                      maxLength={30}
                    />
                  </View>
                  <Text style={styles.error}>{touched.instagram && errors.instagram}</Text>
                </>
              )}

              <Text size={13} bold style={styles.header}>
                {t`privateInformation`}
              </Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`common:email`}
                  {t`common:requiredSymbol`}:
                </Text>
                <TextField
                  onChangeText={setFieldValue}
                  onTouched={setFieldTouched}
                  name="email"
                  value={values.email}
                  keyboardType="email-address"
                  maxLength={50}
                />
              </View>

              <Text style={styles.error}>{touched.email && errors.email && errors.email}</Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`common:phone`}
                  {!user.isEmployee() && t`common:requiredSymbol`}:
                </Text>

                <PhoneInput
                  user={user}
                  locations={locations}
                  value={values.phone}
                  onChange={setFieldValue}
                  onTouched={setFieldTouched}
                />
              </View>

              <Text style={styles.error}>{touched.phone && errors.phone && errors.phone}</Text>

              <View style={styles.field}>
                <Text size={12} medium style={styles.label}>
                  {t`tShirtSize`}
                </Text>
                <Picker
                  onValueChange={(value) => {
                    setFieldValue('tShirtSize', value)
                    setFieldTouched('tShirtSize', true)
                  }}
                  selectedValue={values.tShirtSize}
                  style={[styles.pickerFieldItem, styles.pickerField]}
                >
                  <Picker.Item label={t`select`} value="" />
                  <Picker.Item label={t`XS`} value="XS" />
                  <Picker.Item label={t`S`} value="S" />
                  <Picker.Item label={t`M`} value="M" />
                  <Picker.Item label={t`L`} value="L" />
                  <Picker.Item label={t`XL`} value="XL" />
                  <Picker.Item label={t`XXL`} value="XXL" />
                  <Picker.Item label={t`XXXL`} value="XXXL" />
                </Picker>
              </View>

              <Spacing marginBottom={20} />

              <View style={[styles.field]}>
                <Text size={12} medium style={styles.label}>
                  {t`dateOfBirth`}
                </Text>

                <Row>
                  <Picker
                    onValueChange={(selectedvalue) => {
                      const value = selectedvalue !== 'month' ? selectedvalue : '1'
                      this.dateTool(this.dateTool(values.birthday, 'getFullDate'), 'setUTCMonth', value)
                    }}
                    selectedValue={
                      values.birthday.getTime() !== genericDate.getTime()
                        ? this.dateTool(values.birthday, 'getMonth')
                        : this.state.dob.month || 'Month'
                    }
                    style={[styles.birthdayOption, styles.pickerFieldItem]}
                  >
                    <Picker.Item key="month-label" label={t`common:month`} value="month" />
                    {monthRange.map((month) => (
                      <Picker.Item key={month} label={this.labelMonth(month, values.locale)} value={month} />
                    ))}
                  </Picker>

                  <Picker
                    onValueChange={(selectedvalue) => {
                      const value = selectedvalue !== 'day' ? selectedvalue : '1'
                      this.dateTool(this.dateTool(values.birthday, 'getFullDate'), 'setDate', value)
                    }}
                    selectedValue={
                      values.birthday.getTime() !== genericDate.getTime()
                        ? this.dateTool(values.birthday, 'getDate')
                        : this.state.dob.day || 'Day'
                    }
                    style={[styles.birthdayOption, styles.pickerFieldItem]}
                  >
                    <Picker.Item key="day-label" label={t`common:day`} value="day" />
                    {dayRange.map((day) => (
                      <Picker.Item key={day} label={String(day)} value={day} />
                    ))}
                  </Picker>

                  <Picker
                    onValueChange={(selectedvalue) => {
                      const value = selectedvalue !== 'year' ? selectedvalue : minDate.toString()
                      this.dateTool(this.dateTool(values.birthday, 'getFullDate'), 'setUTCFullYear', value)
                    }}
                    selectedValue={
                      values.birthday.getTime() !== genericDate.getTime()
                        ? this.dateTool(values.birthday, 'getFullYear')
                        : this.state.dob.year || 'Year'
                    }
                    style={[styles.birthdayOption, styles.pickerFieldItem]}
                  >
                    <Picker.Item key="year-label" label={t`common:year`} value="year" />
                    {yearRange.map((year) => (
                      <Picker.Item key={year} label={String(year)} value={year} />
                    ))}
                  </Picker>
                </Row>
              </View>

              {user.organization && user.status === 'accepted' ? (
                <View>
                  <Text size={13} bold style={styles.header}>
                    {t`companyInformation`}
                  </Text>
                  <View style={styles.field}>
                    <Text size={12} medium style={styles.label}>
                      {t`company`}
                    </Text>
                    <Text size={12} style={{ lineHeight: 30 }}>
                      {user.organization.name}
                    </Text>
                  </View>
                  {user.organizationDepartment || user.organization?.departments?.length ? (
                    <>
                      <Spacing marginBottom={15} />
                      <View style={styles.field}>
                        <Text size={12} medium style={styles.label}>
                          {t`department`}
                        </Text>
                        {user.organizationDepartment && !user.organization?.departments?.length ? (
                          <Text size={12} style={{ lineHeight: 30 }}>
                            {user.organizationDepartment}
                          </Text>
                        ) : (
                          <Picker
                            onValueChange={(value: string) => {
                              setFieldValue('organizationDepartment', value)
                              setFieldTouched('organizationDepartment', true)
                            }}
                            selectedValue={values.organizationDepartment}
                            style={[styles.pickerFieldItem, styles.pickerField]}
                          >
                            <Picker.Item label={t`selectDepartment`} value="" />
                            {departments.map((department) => (
                              <Picker.Item key={department} label={department} value={department} />
                            ))}
                          </Picker>
                        )}
                      </View>
                    </>
                  ) : null}
                </View>
              ) : null}

              <DeleteAccountSection textStyle={styles.label} />
            </Screen>
          )}
        </ScrollView>

        <Action>
          <Button
            disabled={dirty && !isValid}
            color={hasFormChanged ? 'primary' : 'default'}
            onPress={() =>
              hasFormChanged
                ? isValid
                  ? actions.submitAction(values)
                  : Alert.alert(t`invalidFields`)
                : Alert.alert(t`confirmLogOut`, null, [
                    { text: t`common:Cancel`, style: 'cancel' },
                    {
                      text: t`common:Yes`,
                      style: 'destructive',
                      onPress: () => actions.logoutAction(),
                    },
                  ])
            }
          >
            {hasFormChanged ? submitting ? <ActivityIndicator color="#fff" /> : t`save` : t`logOut`}
          </Button>
        </Action>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state: State) => ({
    user: selectCurrentUser(state),
    loading: selectLoading(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    locations: selectUserLocations(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)

const withReducer = injectReducer({ key: 'profileSettings', reducer })

const withEpics = injectEpics({ key: 'profileSettings', epics })

const withForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ user }) => ({
    fullName: user?.legalName || {},
    preferredName: user?.preferredName || null,
    instagram: user?.instagram || '',
    email: user?.email || user?.facebook?.email || '',
    phone: user?.phone || '',
    tShirtSize: user?.tShirtSize || '',
    birthday: user?.birthday || new Date(0),
    organizationDepartment: user?.organizationDepartment || '',
    locale: user?.locale || 'en-US',
  }),
  validate: (values, { user }) => {
    const errors = {
      fullName: {
        first: validators.notEmpty(values.fullName?.first),
        last: validators.notEmpty(values.fullName?.last),
      },
      preferredName: {
        bothNamesRequired:
          !!values?.preferredName?.first?.trim().length !== !!values?.preferredName?.last?.trim().length,
      },
      email: validators.notEmpty(values.email) || validators.isEmail(values.email),
      phone:
        user?.isEmployee() && !values.phone
          ? null
          : validators.notEmpty(values.phone) || validators.isPhone(values.phone),
      instagram: /^[a-zA-Z0-9._]*$/.test(values.instagram) ? null : 'Invalid Instagram',
    }

    const isValid = Object.values(errors).every((value) =>
      typeof value === 'object' && value !== null ? Object.values(value).every((nestedValue) => !nestedValue) : !value
    )

    return !isValid ? errors : {}
  },
  validateOnChange: true,
  validateOnBlur: true,
  isInitialValid: true,
  validateOnMount: true,
})

export default compose(withReducer, withEpics, withConnect, withForm, withTranslation('settingsProfile'))(Settings)
