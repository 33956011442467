import React from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Icon, Spacing } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { formatAmount } from 'src/utils/format'
import { CurrencyFormat, useCurrency, currencies } from 'src/containers/modules/CurrencyFormat'
import CurrencyInput from 'src/components/CurrencyInput'

import { ButtonBox } from '.'

interface DonationAmountOptionsProps {
  donationAmountOptions: number[]
  amount: number
  otherActive: boolean
  otherAmount: string
  baseCurrency: string
  minimumDonation: number
  maximumDonation: number
  amountValid: boolean
  setOtherAmount: (value: string) => void
  setOtherActive: (value: boolean) => void
  setAmount: (value: number) => void
}

const DonationAmountOptions: React.FC<DonationAmountOptionsProps> = ({
  donationAmountOptions,
  amount,
  otherActive,
  otherAmount,
  baseCurrency,
  minimumDonation,
  maximumDonation,
  amountValid,
  setOtherAmount,
  setOtherActive,
  setAmount,
}): React.ReactElement<DonationAmountOptionsProps> => {
  const { t } = useTranslation('donateScreen')
  const currency = useCurrency()
  const { colors } = useDeedTheme()

  const currencySymbol = currency.symbol
  const baseCurrencySymbol = currencies[baseCurrency]?.symbol

  return (
    <Row style={{ flexWrap: 'wrap', flexGrow: 0, marginRight: -22 }}>
      {donationAmountOptions.map((value) => {
        const isSelected = value === amount
        return (
          <ButtonBox
            selected={isSelected}
            brandColor={colors.brandColor}
            color="transparent"
            onPress={() => {
              setOtherAmount('')
              setOtherActive(false)
              setAmount(value)
            }}
            key={value}
          >
            <View style={{ position: 'absolute', top: 7, left: 12 }}>
              <Icon icon="tickWhite" width={16} height={16} />
            </View>
            <Body1 weight="500" colour={isSelected ? colors.white : colors.black}>
              {currencySymbol}
              {formatAmount(value, true)}
            </Body1>
          </ButtonBox>
        )
      })}

      <ButtonBox
        selected={otherActive}
        brandColor={colors.brandColor}
        color="transparent"
        onPress={() => {
          setOtherActive(true)
          setAmount(0)
        }}
      >
        <View style={{ position: 'absolute', top: 7, left: 12 }}>
          <Icon icon="tickWhite" width={16} height={16} />
        </View>
        {!otherActive ? (
          <Body1 weight="500" center colour={colors.black}>
            {t`customAmount`}
          </Body1>
        ) : (
          <CurrencyInput
            name="OtherAmount"
            value={otherAmount}
            onValueChange={(value) => {
              if (!value) {
                setOtherAmount('')
                return
              }
              setOtherAmount(value)
            }}
            currencySymbol={currencySymbol}
            maxLength={8}
            style={{
              color: '#ffffff',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              display: 'inline',
              fontSize: 16,
              flexGrow: 1,
              minHeight: 30,
              fontFamily: 'GTWalsheimLC',
              fontWeight: 500,
              border: '1px solid #ebeef0',
              borderRadius: '7px',
              backgroundColor: colors.brandColor,
            }}
            customPlaceholder=""
            autoFocus
          />
        )}
      </ButtonBox>

      {!(otherActive && otherAmount === '') && !amountValid && (
        <Body1 colour={colors.redDark}>
          {baseCurrency !== currency.code ? (
            <Trans
              t={t}
              i18nKey="donationsMustRangeBetweenBaseCurrency"
              components={{
                BaseCurrencyMin: <CurrencyFormat baseCurrency={baseCurrency} amount={minimumDonation} />,
                BaseCurrencyMax: (
                  <CurrencyFormat baseCurrency={baseCurrency} amount={maximumDonation} process="floor" />
                ),
              }}
              values={{
                baseCurrencySymbol,
                minimumDonation: formatAmount(minimumDonation, currencies[baseCurrency].zeroDecimal),
                maximumDonation: formatAmount(maximumDonation, currencies[baseCurrency].zeroDecimal),
              }}
            />
          ) : (
            t('donationsMustRangeBetween', {
              baseCurrencySymbol,
              minimumDonation: formatAmount(minimumDonation, currencies[baseCurrency].zeroDecimal),
              maximumDonation: formatAmount(maximumDonation, currencies[baseCurrency].zeroDecimal),
            })
          )}
        </Body1>
      )}

      <Spacing marginBottom={32} />
    </Row>
  )
}

export default DonationAmountOptions
