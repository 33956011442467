import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTheme } from '@emotion/react'
import { withTranslation } from 'react-i18next'

import { injectReducer, injectEpics } from 'src/utils'
import { colors } from 'src/theme'
import { withRouter } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import IconButton from 'src/retired/shared/IconButton'
import { Loading, Spacing, Row } from 'src/retired/elements'
import { MediumDown } from 'src/retired/elements/MediaQuery'
import { selectCurrentUser } from 'src/entities/user/selectors'
import {
  selectDisplayedLocalOrganizations,
  selectDisplayedPartnerOrganizations,
} from 'src/entities/organization/selectors'
import { clearDisplayedOrganizationIdsAction } from 'src/entities/organization/actions'
import { selectLocations } from 'src/entities/location/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { setPaginationDetailsAction } from 'src/containers/screens/Nonprofits/actions'
import {
  selectCorporateOnly,
  selectSearchTerm,
  selectSearching,
  selectSearchFailed,
} from 'src/containers/modules/NonprofitsFilter/selectors'
import Layout from 'src/retired/blocks/Layout'
import NonprofitsSearchBar from 'src/containers/modules/NonprofitsSearchBar'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import { H3 } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'

import NonprofitsContainer from './NonprofitsContainer'
import { selectLoading, selectError, selectScrollPosition, selectPage, selectTotalPages } from './selectors'
import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'

export class Nonprofits extends PureComponent {
  scrollPosition = 0

  listRef = React.createRef()

  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.setScrollPosition = this.setScrollPosition.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleListScroll, { capture: true })
    this.props.actions.searchNonprofits(
      this.props.location.pathname === '/donate/partners',
      this.props.location.search,
      this.props.location.pathname.includes('/donate')
    )
    this.setScrollPosition()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.actions.searchNonprofits(
        this.props.location.pathname === '/donate/partners',
        this.props.location.search,
        this.props.location.pathname.includes('/donate')
      )
    }
  }

  componentWillUnmount() {
    if (this.listRef.current) {
      this.props.actions.setScrollPositionAction(this.scrollPosition)
    }
    window.removeEventListener('scroll', this.handleListScroll, { capture: true })
    this.props.actions.clearDisplayedOrganizationIdsAction()
    this.props.actions.setPaginationDetailsAction(0, 0, '', 1)
    this.props.actions.cancelSearchAction()
  }

  shouldSearchForMore = () => this.props.page <= this.props.totalPages

  handleListScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - 200 < e.target.clientHeight &&
      !this.props.loading &&
      !this.props.error &&
      this.shouldSearchForMore()
    ) {
      this.props.actions.searchNonprofits(
        this.props.location.pathname === '/donate/partners',
        this.props.location.search,
        this.props.location.pathname.includes('/donate')
      )
    }
  }

  handleScroll(event) {
    this.scrollPosition = event.nativeEvent.contentOffset
      ? event.nativeEvent.contentOffset.y
      : event.nativeEvent.target.scrollTop
  }

  setScrollPosition() {
    if (this.listRef.current && this.props.scrollPosition >= 0 && this.props.nonprofits?.size > 0) {
      // No idea why it's needed, but without it the scrolling doesn't happen on Android (at least)
      setTimeout(() => {
        if (this.listRef.current) {
          if (this.listRef.current.scrollTo) {
            this.listRef.current.scrollTo(0, this.props.scrollPosition)
          } else {
            this.listRef.current.scrollTop = this.props.scrollPosition
          }
        }
      }, 0)
    }
  }

  render() {
    const {
      t,
      loading,
      localNonprofits,
      partnerNonprofits,
      user,
      actions,
      locations,
      searching,
      searchFailed,
      corporateOnly,
      theme: { metrics },
      history,
      location,
    } = this.props
    const isEmployee = Boolean(user && user.organization)
    const nonprofits = location.pathname === '/donate/partners' ? partnerNonprofits : localNonprofits
    const listProps = {
      nonprofits,
      isEmployee,
      actions,
      locations,
      searching,
      searchFailed,
      corporateOnly,
      onScroll: this.handleScroll,
      onScrollEndDrag: this.handleScroll,
      listRef: this.listRef,
    }
    const goBack = makeGoBack(history, '/donate')
    const showBackButton = location.pathname !== '/nonprofits'
    return (
      <Layout withoutHeader keepTabMenu>
        <PageTitle title={t`organizations`} />
        <View style={{ flex: 1, backgroundColor: metrics.isSmall ? colors.gray06 : 'transparent' }}>
          <MediumDown style={{ height: '100%' }}>
            {!metrics.isLarge && (
              <>
                <PendingApprovalBanner />
                <CompanyNotificationBanner />
              </>
            )}
          </MediumDown>

          <Spacing marginTop={40} marginBottom={20} marginLeft={20} marginRight={20} style={{ zIndex: 1 }}>
            <Row style={{ flexGrow: 0 }}>
              {showBackButton && <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />}
              <View style={{ flexShrink: 1, paddingVertical: 1 }}>
                <H3 numberOfLines={2}>{location.state?.parentCause || t`organizations`}</H3>
              </View>
            </Row>
          </Spacing>
          <Spacing marginLeft={20} marginRight={20} marginBottom={20} style={{ zIndex: 2 }}>
            <NonprofitsSearchBar />
          </Spacing>
          <Spacing marginBottom={20} marginLeft={20} marginRight={20} style={{ zIndex: 1 }}>
            {(!!nonprofits.size || !loading) && <NonprofitsContainer {...listProps} />}
            {loading && (
              <Spacing marginBottom={30} marginTop={30}>
                <Loading fill={false} />
              </Spacing>
            )}
          </Spacing>
        </View>
      </Layout>
    )
  }
}

const withConnect = connect(
  (state) => ({
    loading: selectLoading(state),
    error: selectError(state),
    localNonprofits: selectDisplayedLocalOrganizations(state),
    partnerNonprofits: selectDisplayedPartnerOrganizations(state),
    scrollPosition: selectScrollPosition(state),
    user: selectCurrentUser(state),
    locations: selectLocations(state),
    searchTerm: selectSearchTerm(state),
    searching: selectSearching(state),
    searchFailed: selectSearchFailed(state),
    corporateOnly: selectCorporateOnly(state),
    totalPages: selectTotalPages(state),
    page: selectPage(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(
      { ...Actions, setLocalSettingAction, clearDisplayedOrganizationIdsAction, setPaginationDetailsAction },
      dispatch
    ),
  })
)
const withReducer = injectReducer({ key: 'nonprofits', reducer })
const withEpics = injectEpics({ key: 'nonprofits', epics })

export default compose(
  withRouter,
  withTheme,
  withReducer,
  withEpics,
  withConnect,
  withTranslation('nonprofitsScreen')
)(Nonprofits)
