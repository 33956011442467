import React from 'react'
import { useSelector } from 'react-redux'

import { selectLocalSetting } from 'src/localSettings/selectors'
import { Redirect } from 'src/navigation'
import isDefaultAppOrigin from 'src/utils/isDefaultAppOrigin'
import { State } from 'src/reducers'

export const getQuickRedirectToLoginUrl = () => {
  const isDeedDomain = isDefaultAppOrigin(window.location.host)

  return isDeedDomain ? `/login` : '/'
}

// Redirects a user to the page where they can login with the least amount of button clicks.
// This means, for vanity domain, the quickest access to the login is through the "/" home page.
// However, for non-vanity domains it is through the "/login" route.
// This should be used, when users try to access specific subpages directly or their session expired.

// However, the fast routing to the login is tricky, if you did never sign up with deed, because on those screens
// there are no signup buttons. Therefore we check the `userId` in the local storage. If its not empty
// it means the user most likely signed up before and therefore we can lead to the login page.
export const QuickRedirectToLogin = () => {
  const hasLoggedInBefore = !!useSelector((state: State) => selectLocalSetting(state, 'userId'))

  if (!hasLoggedInBefore) {
    return <Redirect to="/" />
  }

  return <Redirect to={getQuickRedirectToLoginUrl()} />
}
