import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import countryList from 'country-list'

import { Text } from 'src/retired/elements'
import { Link } from 'src/navigation'
import Chip from 'src/retired/shared/Chip'
import { colors } from 'src/theme'
import { NonprofitNomination } from 'src/generated/graphql'
import { CardCompactWrapper } from 'src/components/CardCompactWrapper/CardCompactWrapper'
import { Body1, Label } from 'src/retired/shared/Typography'

interface NonprofitNominationCardProps {
  nomination: NonprofitNomination
}

const NonprofitNominationCard = ({ nomination }: NonprofitNominationCardProps): JSX.Element => {
  const { t } = useTranslation('nonprofitNominationsCompact')
  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  return (
    <Link key={nomination.id} to={`/nonprofit-nomination/${nomination.id}`}>
      {tooltipVisibility && (
        <View
          style={{
            backgroundColor: colors.black,
            borderRadius: 10,
            zIndex: 100,
            padding: 4,
            opacity: 0.8,
            pointerEvents: 'none',
            position: 'absolute',
            width: 200,
          }}
        >
          <Text center color={colors.white}>
            {t('tooltip', { context: nomination.status })}
          </Text>
        </View>
      )}
      <CardCompactWrapper cardColor={colors.brandColor}>
        <Label marginBottom={12}>{t('date:weekdayDayMonthShort', { date: new Date(nomination.createdAt) })}</Label>

        <Body1 numberOfLines={1} marginBottom={12}>
          {nomination.nonprofitName}
        </Body1>

        <Label numberOfLines={1} marginBottom={4}>
          {countryList.getName(nomination.country)}
        </Label>

        <View
          style={{ position: 'absolute', right: 12, bottom: 12 }}
          onMouseOver={() => setTooltipVisibility(true)}
          onMouseOut={() => setTooltipVisibility(false)}
        >
          <Chip type="square" backgroundColor={colors.grayLight}>
            {t('nominationStatus', { context: nomination.status })}
          </Chip>
        </View>
      </CardCompactWrapper>
    </Link>
  )
}

export default NonprofitNominationCard
