/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
import { createSelector } from 'reselect'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import uniqBy from 'lodash/uniqBy'
import { Map, OrderedMap } from 'immutable'

import { selectCauses } from 'src/entities/cause/selectors'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { selectSelectedLocationCode } from 'src/containers/modules/FeedFilter/selectors'
import { selectCampaignById } from 'src/entities/campaign/selectors'
import { State } from 'src/reducers'
import Campaign from 'src/entities/campaign/model'
import truthy from 'src/utils/truthy'

import User from '../user/model'
import Donation from '../donation/model'
import { CauseMap } from '../cause/reducer'

import Deed from './model'
import type { DeedMap, DeedState } from './reducer'

const MATCHING_SKILL_SCORE = 1.5
const MATCHING_INTEREST_SCORE = 1.25

export const selectDeedState = (state: State): DeedState => state.get('entities').deed

const sortNewestFirst = (a: Deed, b: Deed): number =>
  a.type === 'Project' && b.type === 'Project'
    ? a.createdAt?.getTime() - b.createdAt?.getTime()
    : a.startingAt?.getTime() - b.startingAt?.getTime()
const sortOldestFirst = (a: Deed, b: Deed): number =>
  a.type === 'Project' && b.type === 'Project'
    ? b.createdAt?.getTime() - a.createdAt?.getTime()
    : b.startingAt?.getTime() - a.startingAt?.getTime()

const deedTypes = {
  Campaign: 'fundraisers',
  Project: 'volunteering',
  Event: 'volunteering',
  BaseEvent: 'events',
} as const

type DeedType = keyof typeof deedTypes

const mapDeedToContentType = (deedType: DeedType): typeof deedTypes[DeedType] => {
  if (deedTypes[deedType]) {
    return deedTypes[deedType]
  }
  throw new Error('unknown Deed type')
}

export const selectDeeds = createSelector(selectDeedState, (deedState) => deedState.get('deeds'))

export const selectCommunityDeeds = createSelector(
  selectDeeds,
  (_state: State, communityId: string) => communityId,
  (deeds, communityId) => deeds.filter((deed) => deed.communityIds?.includes(communityId))
)

export const selectDeedsByType = createSelector(
  selectDeeds,
  (_state: State, deedType: DeedType) => deedType,
  (deeds, deedType) => (deedType ? deeds.filter((deed: Deed) => deed.type === deedType) : deeds)
)

export const filterUpcomingDeeds = (deed: Deed) => {
  if (deed.type === 'Campaign') {
    return deed.isOngoing()
  }

  return !deed.ended
}

const filterDeedsByLocation = (deeds, user, locations) =>
  deeds
    .filter((deed: Deed) =>
      user?.location && deed.allowedLocations?.length > 0 ? deed.allowedLocations.includes(user.location) : true
    )
    .filter(
      (deed: Deed) =>
        !deed?.locationObject.country ||
        !user?.location ||
        !locations?.get(user.location)?.country ||
        (user.location && deed.locationObject.country === locations.get(user.location).country)
    )

const filterUpcomingDeedsWithLocation = (deeds, user, locations) => {
  deeds = deeds.filter(filterUpcomingDeeds)
  deeds = filterDeedsByLocation(deeds, user, locations)
  return deeds
}

export const selectUpcomingDeeds = createSelector(selectDeedsByType, (deeds) =>
  deeds.filter(filterUpcomingDeeds).sort(sortNewestFirst)
)

export const selectUpcomingDeedsWithLocationFiltering = createSelector(
  selectDeedsByType,
  selectCurrentUser,
  selectUserLocations,
  (deeds, user, locations) => filterUpcomingDeedsWithLocation(deeds, user, locations)
)

export const selectVolunteerSearchResults = createSelector(
  selectDeedState,
  (_state: State, deedType: DeedType) => deedType,
  (deedState, deedType) => {
    if (deedType === 'Project') {
      return deedState.get('projectSearchResultsDeedIds')
    }

    // default to Events
    return deedState.get('eventSearchResultsDeedIds')
  }
)

export const selectFundraiserSearchResults = createSelector(selectDeedState, (deedState) =>
  deedState.get('fundraiserSearchResultsDeedIds')
)

const filterDeedsByTypeAndSearchResults = (deeds, deedType, searchResultsDeedIds) => {
  if (deedType) {
    deeds = deeds.filter((deed: Deed) => deed.type === deedType)
  }

  if (searchResultsDeedIds?.size > 0) {
    const orderedIds = Array.from(searchResultsDeedIds)
    deeds = deeds
      .filter((deed: Deed) => searchResultsDeedIds.has(deed.id))
      .sort((a: Deed, b: Deed) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id))
  }

  deeds = deeds.filter(filterUpcomingDeeds)

  return deeds
}

export const filterVolunteerSearchResultsDeedsByType = createSelector(
  selectDeeds,
  (_state: State, deedType: DeedType) => deedType,
  selectVolunteerSearchResults,
  (deeds, deedType, volunteerSearchResultsDeedIds) =>
    filterDeedsByTypeAndSearchResults(deeds, deedType, volunteerSearchResultsDeedIds)
)

export const filterFundraiserSearchResultsDeedsByType = createSelector(
  selectDeeds,
  (_state: State, deedType: DeedType) => deedType,
  selectFundraiserSearchResults,
  (deeds, deedType, fundraiserSearchResultsDeedIds) =>
    filterDeedsByTypeAndSearchResults(deeds, deedType, fundraiserSearchResultsDeedIds)
)

export const selectDeedsByCountryCode = createSelector(
  selectDeedsByType,
  selectCurrentUser,
  selectSelectedLocationCode,
  selectUserLocations,
  (deeds, user, locationCode, locations) =>
    deeds
      .filter((deed: Deed) => !deed.ended)
      .filter((deed: Deed) => {
        const locationFilter = locationCode || locations.get(user.location)?.countryCode || 'US'
        return deed.matchLocation(locationFilter, { includeVirtual: true, includeNonprofits: false })
      })
      .sort(sortNewestFirst)
)

export const selectFeaturedDeeds = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  (deeds: DeedMap, user?: User): DeedMap =>
    deeds
      .filter(
        (deed: Deed) =>
          deed.featured && (user?.isEmployee() ? deed.optedIn || deed.partner?.id === user.organization.id : true)
      )
      .sort(sortNewestFirst)
)

const calculateDeedUserScore = (deed: Deed, user: User, causes: CauseMap): number => {
  const deedSkills = deed.get('type') === 'Project' ? deed.allSkills().toArray() : []

  const matchingSkills = (user?.skills || []).filter((skillId: string) => deedSkills.includes(skillId))
  const matchingInterests = (user?.interests || []).filter((causeId: string) => {
    const cause = causes.get(causeId)
    return cause && deed.hasCause(cause)
  })

  return matchingSkills.length * MATCHING_SKILL_SCORE + matchingInterests.length * MATCHING_INTEREST_SCORE
}

export const selectSuggestedDeeds = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  selectCauses,
  (deeds, user: User | undefined, causes) =>
    deeds
      .filter((deed: Deed) => (user?.isEmployee() ? deed.optedIn || deed.partner?.id === user.organization.id : true))
      .filter((deed: Deed) => user && calculateDeedUserScore(deed, user, causes) > 0)
      .sortBy((deed: Deed) => user && calculateDeedUserScore(deed, user, causes))
      .reverse()
)

export const selectSuggestedDeedsLoaded = createSelector(
  selectDeedState,
  (deedState) => deedState.get('suggestedDeedsLoaded') || false
)

export const selectUpcomingDeedsByCause = createSelector(
  selectUpcomingDeeds,
  selectCauses,
  (_state, _deedType, causeId: string) => causeId,
  (deeds, causes, causeId) => {
    if (!causeId) {
      return deeds
    }
    const cause = causes.get(causeId)
    return deeds.filter((deed: Deed) => cause && deed.hasCause(cause))
  }
)

export const selectUpcomingDeedsForUser = createSelector(
  selectDeeds,
  (_state: State, user: User) => user,
  (deeds, user) => deeds.filter((deed: Deed) => deed.isUpcomingForUser(user)).sort(sortNewestFirst)
)

export const selectPastDeeds = createSelector(selectDeeds, (deeds) =>
  deeds.filter((deed: Deed) => deed.ended).sort(sortNewestFirst)
)

export const selectPastDeedsForUser = createSelector(
  selectDeeds,
  (_state: State, user: User) => user,
  (deeds, user) => deeds.filter((deed: Deed) => deed.isPastForUser(user)).sort(sortOldestFirst)
)

export const selectOngoingDeeds = createSelector(selectDeeds, (deeds) =>
  deeds.filter((deed: Deed) => deed.started && !deed.ended).sort(sortNewestFirst)
)

export const selectOngoingDeedsForUser = createSelector(
  selectDeeds,
  (_state: State, user: User) => user,
  (deeds: DeedMap, user) => deeds.filter((deed: Deed) => deed.isOngoingForUser(user)).sort(sortOldestFirst)
)

const getDeedByExternalId = (deeds: Map<string, Deed>, externalId: string): Deed | undefined =>
  externalId ? deeds.find((d) => d?.externalId === externalId) : undefined

export const selectDeedById = createSelector(
  selectDeeds,
  (_state: State, deedId: string) => deedId,
  (deeds: DeedMap, deedId) => (deedId ? deeds.get(deedId) || getDeedByExternalId(deeds, deedId) : null)
)

export const selectDeedFormsByDeedId = createSelector(
  selectDeedById,
  (_state: State, deedId: string) => deedId,
  (deed?: Deed | undefined | null) => deed?.get('forms')
)

export const selectCommunityDeedsLoaded = createSelector(
  selectDeedState,
  (_state: State, communityId: string) => communityId,
  (deedState, communityId) => deedState.get('communityDeedsLoaded').get(communityId) || false
)

export const selectUpcomingDeedsLoaded = createSelector(
  selectDeedState,
  (_state: State, userId?: string) => userId,
  (deedState, userId) => deedState.get('upcomingDeedsLoaded').get(userId || 'me') || false
)

export const selectFeaturedDeedsLoaded = createSelector(
  selectDeedState,
  (deedState) => deedState.get('featuredDeedsLoaded') || false
)

export const selectPastDeedsLoaded = createSelector(
  selectDeedState,
  (_state: State, userId: string) => userId,
  (deedState, userId) => deedState.get('pastDeedsLoaded').get(userId || 'me') || false
)

export const selectUserSubmittedDeedsLoaded = createSelector(selectDeedState, (deedState) =>
  deedState.get('userSubmittedDeedsLoaded')
)

export const selectCampaignLoaded = createSelector(
  selectDeedState,
  (_state: State, id: string) => id,
  (deedState, id) => deedState.get('campaignsLoaded').get(id) || false
)

export const selectAllDeedsForCampaign = createSelector(
  selectDeeds,
  (_state: State, campaignId: string) => campaignId,
  (deeds, campaignId) => deeds.filter((deed: Deed) => deed.hasCampaign(campaignId)).sort(sortNewestFirst)
)

export const selectDeedsForCampaign = createSelector(
  selectDeedsByType,
  (_state, deedType, campaign: Campaign) => ({ deedType, campaign }),
  (deeds, { deedType, campaign }) => {
    const filteredContent = deeds.filter((deed: Deed) => deed.hasCampaign(campaign?.id))

    const contentSortingByType = campaign?.contentSorting?.items[mapDeedToContentType(deedType)]

    const contentWithSorting: Deed[] =
      contentSortingByType
        ?.map((eachContentSorting) => filteredContent.find((eachDeed) => eachDeed.get('id') === eachContentSorting))
        .filter(truthy) ?? []

    const contentWithoutSorting: Deed[] = filteredContent
      .filter((eachDeed) => !contentSortingByType.includes(eachDeed.id))
      .toList()
      .toArray()

    return OrderedMap<string, Deed>(
      contentWithSorting.concat(contentWithoutSorting.sort(sortNewestFirst)).map((deed: Deed) => [deed?.id, deed])
    )
  }
)

export const selectDonationsActivitiesForCampaign = createSelector(
  selectAllDeedsForCampaign,
  selectCampaignById,
  (_state, campaignId) => campaignId,
  (deeds, campaign, campaignId) => {
    const activities = deeds.reduce<Donation[]>((acc, deed) => {
      if (deed.type === 'Campaign') {
        acc.push(
          ...deed.donations.filter(
            // The `selectAllDeedsForCampaign` returns deeds of a campaign. However, those `deeds.donations` include all its donations, which might not be towards this campaign.
            (donation) => (donation.campaign?.id ?? donation.campaign) === campaignId
          )
        )
      }
      return acc
    }, [])

    const nonprofitsActivities =
      campaign?.nonprofitDonations?.filter(
        // We filter it to play safe and only return donations for the given campaign
        (donation) => (donation.campaign?.id ?? donation.campaign) === campaignId
      ) || []

    return [...activities, ...nonprofitsActivities].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
  }
)

export const selectNonprofitsForCampaign = createSelector(
  selectAllDeedsForCampaign,
  selectCampaignById,
  (_state, campaignId) => campaignId,
  (deeds, campaign) => {
    const nonprofits = deeds.reduce((acc, deed) => {
      if (deed?.nonprofits) {
        acc.push(...deed.nonprofits.filter((nonprofit) => !!nonprofit.id))
      } else if (deed.organization?.id) {
        acc.push(deed.organization)
      }
      return acc
    }, [])

    return uniqBy([...nonprofits, ...(campaign?.nonprofits || [])], 'id')
  }
)

//
// Generates dates object for calendar of shape:
// { '2019-01-01': numberOfDeedsForDate }
//
export const selectDeedsCountByDate = createSelector(selectDeeds, (deeds) =>
  deeds.reduce<{ [date: string]: number }>((result, deed: Deed) => {
    if (deed.type === 'Event') {
      if (deed.startingAt && deed.endingAt && deed.startingAt <= deed.endingAt) {
        const lastDateString = format(new Date(deed.endingAt), 'yyyy/MM/dd')
        for (
          const d = new Date(deed.startingAt.getTime());
          format(d, 'yyyy/MM/dd') !== lastDateString;
          d.setDate(d.getDate() + 1)
        ) {
          const key = format(d, 'yyyy/MM/dd')
          result[key] = result[key] ? result[key] + 1 : 1
        }
        result[lastDateString] = result[lastDateString] ? result[lastDateString] + 1 : 1
      } else if (deed.startingAt) {
        const key = formatInTimeZone(deed.startingAt, deed.timeZone, 'yyyy/MM/dd')
        result[key] = result[key] ? result[key] + 1 : 1
      }
    }

    return result
  }, {})
)

export const selectUserSubmittedDeeds = createSelector(
  selectDeeds,
  (_state: State, userOrUserId: User | string) => userOrUserId,
  (_state: State, _userOrUserId: User | string, status: string) => status,
  (deeds, userOrUserId, status) => {
    if (status === 'active') {
      return deeds
        .filter((deed: Deed) => deed.isSubmittedByUser(userOrUserId) && deed.status === 'published' && !deed.ended)
        .sort((a: Deed, b: Deed) => b.createdAt.getTime() - a.createdAt.getTime())
    }

    if (status === 'pending') {
      return deeds
        .filter(
          (deed: Deed) => deed.isSubmittedByUser(userOrUserId) && (deed.status === 'pending' || deed.status === 'draft')
        )
        .sort((a: Deed, b: Deed) => b.createdAt.getTime() - a.createdAt.getTime())
    }

    if (status === 'completed') {
      return deeds
        .filter((deed: Deed) => deed.isSubmittedByUser(userOrUserId) && deed.status === 'published' && deed.ended)
        .sort((a: Deed, b: Deed) => b.createdAt.getTime() - a.createdAt.getTime())
    }

    return deeds
      .filter((deed: Deed) => deed.isSubmittedByUser(userOrUserId))
      .sort((a: Deed, b: Deed) => b.createdAt.getTime() - a.createdAt.getTime())
  }
)

export const selectForCauseLoaded = createSelector(
  selectDeedState,
  (_state: State, id: string) => id,
  (deedState, id) => deedState.get('forCausesLoaded').includes(id)
)
