import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'src/navigation'

import InformativeScreen from './InformativeScreen'

const MissingVolunteerTimeScreen = () => {
  const { t } = useTranslation('missingVolunteerTimeScreen')
  const history = useHistory()

  return (
    <InformativeScreen
      headerTitle={t`oops`}
      headerContent={t`linkNotAvailable`}
      content={t`description`}
      buttonText={t`logVolunteerHours`}
      buttonOnPress={() => history.push(`/profile/volunteer-hours/select-deed`)}
      buttonSecondaryOnPress={() => history.push(`/profile`)}
      buttonSecondaryText={t`myProfile`}
    />
  )
}

export default MissingVolunteerTimeScreen
