import React from 'react'
import { View, StyleSheet } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'

const ActivityIndicatorOverlay = (): JSX.Element => {
  const { colors } = useDeedTheme()
  return (
    <View style={[styles.root]}>
      <View>
        <ActivityIndicator color={colors.userOrganizationBrandColor || colors.coral} size="large" />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    zIndex: 100,
    justifyContent: 'center',
  },
})

export default ActivityIndicatorOverlay
