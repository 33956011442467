import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import { selectOrganizationById } from 'src/entities/organization/selectors'
import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Link, useHistory } from 'src/navigation'
import { Loading, Screen, ScrollView, Spacing } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { useCountries } from 'src/entities/location/api'
import NonprofitsSearch from 'src/containers/modules/NonprofitsSearch'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useInjectEpics } from 'src/utils/injectEpics'
import { State } from 'src/reducers'

import Header from './Header'
import { estimatePayPalMatchDisbursementDate, isPPGFEnrolled } from './util'
import epics from './epics'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '195px' : '15px')};
  margin-bottom: 50px;
`

const LogOffPlatformDonation = (): JSX.Element => {
  useInjectEpics({ key: 'logOffPlatformDonation', epics })

  const { t } = useTranslation('logOffPlatformDonation')

  const { colors } = useDeedTheme()
  const history = useHistory()
  const countries = useCountries()

  const user = useSelector(selectCurrentUser)

  const [selectedNonprofitId, setSelectedNonprofitId] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [estimatedMatchDisbursementNDays, setEstimatedMatchDisbursementNDays] = useState<number | null>(null)

  const nonprofit = useSelector((state: State) => selectOrganizationById(state, selectedNonprofitId)) ?? undefined

  useEffect(() => {
    if (selectedNonprofitId) {
      setEstimatedMatchDisbursementNDays(estimatePayPalMatchDisbursementDate(nonprofit?.paypalCharityType))
    } else {
      setEstimatedMatchDisbursementNDays(null)
    }
  }, [selectedNonprofitId, nonprofit])

  if (!user) {
    return <Loading />
  }

  return (
    <Screen fixed>
      <ScrollView>
        <Header
          userOrganization={user.organization.name}
          estimatedMatchDisbursementNDays={estimatedMatchDisbursementNDays}
          npoName={nonprofit?.name}
          isPPGFEnrolled={isPPGFEnrolled(nonprofit?.paypalCharityType)}
        />
        {!countries.length ? (
          <Spacing marginBottom={30} marginTop={30}>
            <Loading fill={false} />
          </Spacing>
        ) : (
          <FormWrapper>
            <NonprofitsSearch
              acceptsDonations
              // @NOTE-AC: Stripe Nonprofits are excluded from the donation match
              // because we are unable to perform batch payments to them
              excludeDonationProviders={[DonationProvider.Stripe]}
              onSetSelectedNonprofit={setSelectedNonprofitId}
              onSetSearchTerm={setSearchTerm}
              bottomAction={
                user?.hasFeature('userNominateNonprofitForMatching') && (
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Body2 colour={colors.white}>{t`notOnDeed`} </Body2>
                    <Link
                      to={{
                        pathname: '/nominate-nonprofit',
                        state: { nonprofit: { name: searchTerm }, ignoreFeatureFlag: true },
                      }}
                    >
                      <Body2 colour={colors.white} underline style={{ textDecorationColor: colors.white }}>
                        {t`nominateThisOrganization`}
                      </Body2>
                    </Link>
                  </View>
                )
              }
              emptyAction={
                <Body1 center>
                  {t`weWereNotAbleToFind`}{' '}
                  {user?.hasFeature('userNominateNonprofitForMatching') && t('nominateThemBelow')}
                </Body1>
              }
            />
            <Spacing marginTop={30}>
              <Button
                palette="primary"
                disabled={!selectedNonprofitId}
                onPress={() => history.push(`/off-platform/${selectedNonprofitId}`)}
              >
                {t`next`}
              </Button>
            </Spacing>
          </FormWrapper>
        )}
      </ScrollView>
    </Screen>
  )
}
LogOffPlatformDonation.displayName = 'LogOffPlatformDonation'
export default LogOffPlatformDonation
