import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { useHistory } from 'src/navigation'
import { Loading, Row, Screen, ScrollView, Spacing } from 'src/retired/elements'
import Touchable from 'src/retired/shared/Touchable'
import { Body1, Body2, H4, Label } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { PageTitle } from 'src/components'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '55px' : '15px')};
  margin-bottom: 50px;
`
const ModalTitle = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 55px 20px 55px' : '0 25px 25px 25px')};
`

const DeedType = styled.View<object, EmotionTheme>`
  padding: 20px 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  border-radius: 20px;
  width: 180px;
  margin: 10px;
`

const DeedTypeIcon = styled.View<{ color: string }>`
  width: 38px;
  height: 38px;
  border-radius: 38px;
  background-color: ${({ color }) => color};
  margin: 0 auto 12px;
`

const CreateDeed = (): JSX.Element => {
  const { t } = useTranslation('createDeedScreen')
  const user = useSelector(selectCurrentUser)

  const { colors } = useDeedTheme()
  const history = useHistory()

  if (!user?.hasFeature('userCreateEvent') && !user?.hasFeature('userCreateFundraiser')) {
    history.push('/')
  }

  return (
    <Screen fixed>
      <PageTitle title={t`createADeed`} />
      <ScrollView>
        <NavigationHeader transparent />

        <ModalTitle>
          <H4 weight="500" center marginBottom={8}>
            {t`createADeed`}
          </H4>
          <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <View style={{ width: 450, maxWidth: '100%' }}>
              <Body2 center>{t`feelingInspiredToSupport`}</Body2>
            </View>
          </View>
        </ModalTitle>

        <FormWrapper>
          {!user ? (
            <Spacing marginBottom={30} marginTop={30}>
              <Loading fill={false} />
            </Spacing>
          ) : (
            <Row style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
              {user.hasFeature('volunteering') && user.hasFeature('userCreateEvent') && (
                <Touchable onPress={() => history.push('/create-deed/event')}>
                  <DeedType>
                    <DeedTypeIcon color={colors.teal} />
                    <Body1 weight="500" center>
                      {t`event`}
                    </Body1>
                    <Label center marginTop={8}>
                      {t`scheduledGroupVolunteeringEvents`}
                    </Label>
                  </DeedType>
                </Touchable>
              )}
              {user.hasFeature('donations') && user.hasFeature('userCreateFundraiser') && (
                <Touchable onPress={() => history.push('/create-deed/fundraiser')}>
                  <DeedType>
                    <DeedTypeIcon color={colors.blue} />
                    <Body1 weight="500" center>
                      {t`fundraiser`}
                    </Body1>
                    <Label center marginTop={8}>
                      {t`raiseMoneyForAnOrganizationOrProject`}
                    </Label>
                  </DeedType>
                </Touchable>
              )}
            </Row>
          )}
        </FormWrapper>
      </ScrollView>
    </Screen>
  )
}
CreateDeed.displayName = 'CreateDeed'

export default CreateDeed
