import { useLocation } from 'react-router'
import { parse } from 'query-string'
import { isValid, parseISO as parseDateFns } from 'date-fns'

export const useDateRangeFromUrl = () => {
  const { search: querySearch } = useLocation()
  const { from, to } = parse(querySearch)

  if (
    typeof from !== 'string' ||
    !isValid(parseDateFns(from)) ||
    typeof to !== 'string' ||
    !isValid(parseDateFns(to))
  ) {
    return null
  }

  return { from, to }
}
