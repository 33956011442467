import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'

export default class OnLayout extends Component {
  static propTypes = {
    children: PropTypes.func,
  }

  state = {
    dimensions: {
      width: 0,
      height: 0,
    },
  }

  onLayout = (e) => {
    if (e && e.nativeEvent && e.nativeEvent.layout) {
      this.setState({
        dimensions: e.nativeEvent.layout,
      })
    }
  }

  render() {
    const { children, ...props } = this.props
    return (
      <View {...props} onLayout={this.onLayout}>
        {children(this.state.dimensions)}
      </View>
    )
  }
}
