import React from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import Authenticated from 'src/containers/modules/Authenticated'
import { Action, LinkButton, Screen, Text } from 'src/retired/elements'
import { Redirect } from 'src/navigation'
import { storeAction } from 'src/entities/auth/actions'
import { PageTitle } from 'src/components'

const NotFound = () => {
  const dispatch = useDispatch()
  const { pathname, hash } = useLocation()
  const { t } = useTranslation('notFoundScreen')
  /** @deprecated, temporary hotfix, now using TokenLoginRedirect instead (pending native app force update) */
  if (pathname.includes('//campaign')) {
    if (hash) {
      dispatch(storeAction(hash.substr(1)))
    }
    return <Redirect to={pathname.substr(1)} />
  }
  return (
    <Screen fixed>
      <PageTitle title={t`pageNotFound`} />
      <NavigationHeader title={t`pageNotFound`} />
      <Screen padding middle header action narrow>
        <Text size={24} lineHeight={32} style={{ alignSelf: 'stretch' }}>
          {t`thisPageCannotBeFound`}
        </Text>
      </Screen>
      <Action>
        <Authenticated
          render={(isAuthenticated) => (
            <LinkButton to={isAuthenticated ? '/home' : '/'} color="primary" replace>
              {t`startOver`}
            </LinkButton>
          )}
        />
      </Action>
    </Screen>
  )
}
export default NotFound
