import { ADD, ADD_MULTIPLE, REMOVE, VOLUNTEER_TIMES_FOR_DEED_LOADED } from './constants'
import VolunteerTime from './model'
import { VolunteerTimeMap } from './reducer'

export const addAction = (volunteerTime: VolunteerTime) => ({
  type: ADD,
  volunteerTime,
})

export const addMultipleAction = (volunteerTimes: VolunteerTimeMap) => ({
  type: ADD_MULTIPLE,
  volunteerTimes,
})

export const removeAction = (id: string) => ({
  type: REMOVE,
  id,
})

export const volunteerTimesForDeedLoadedAction = (deedId: string) => ({
  type: VOLUNTEER_TIMES_FOR_DEED_LOADED,
  deedId,
})
