import type { TypographyOptions } from '@mui/material/styles/createTypography'

export const typography: TypographyOptions = {
  fontFamily: 'GTWalsheimLC',
  fontSize: 14,

  button: {
    fontWeight: 500,
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },

  h1: {
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '71px',
  },

  h2: {
    fontWeight: 500,
    fontSize: '48px',
    lineHeight: '57px',
  },

  h3: {
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
  },

  h4: {
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '28px',
  },

  h5: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
  },

  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
  },

  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
  },
}
