import { SELECT_DEPARTMENT, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const selectDepartmentAction = (department) => ({
  type: SELECT_DEPARTMENT,
  department,
})

export const submitAction = () => ({
  type: SUBMIT,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
