import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeedContext } from 'react-activity-feed'
import { View } from 'react-primitives'

import { Modal, Row } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { H4, Body } from 'src/retired/shared/Typography'

interface Props {
  onClose: () => void
  activityId: string
}

export const DeleteActivityConfirmationModal = ({ activityId, onClose }: Props): JSX.Element => {
  const { t } = useTranslation('communityFeed')

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const feed = useFeedContext()

  const onDelete = async (): Promise<void> => {
    try {
      setError(false)
      setSubmitting(true)
      await feed.feedManager.onRemoveActivity(activityId)
      onClose()
    } catch (e) {
      setError(true)
    }
    setSubmitting(false)
  }

  return (
    <Modal
      transparent
      visible
      title={<H4>{t`doYouWantToDeleteActivity`}</H4>}
      style={{ width: 500 }}
      bodyStyle={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      onClose={onClose}
    >
      <View style={{ marginTop: 8 }}>
        <Row style={{ justifyContent: 'center', marginTop: 20 }}>
          <Button palette="secondary" onPress={() => onClose()} style={{ marginRight: 10 }}>
            {t`common:Cancel`}
          </Button>
          <Button palette="primary" onPress={onDelete} style={{ marginLeft: 10 }} loading={submitting}>
            {t`yesDelete`}
          </Button>
        </Row>
        {error && (
          <Body colour="redDark" style={{ marginTop: 5 }}>
            {t`errorDeleting`}
          </Body>
        )}
      </View>
    </Modal>
  )
}
