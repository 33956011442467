import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { parseISO } from 'date-fns'

import config from 'src/config'
import { useLocation, useHistory } from 'src/navigation'
import { selectCurrentUser, selectUserBrand, selectUserLocations } from 'src/entities/user/selectors'
import { selectCorporateOnly } from 'src/containers/modules/FeedFilter/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'
import { selectDeedsCountByDate } from 'src/entities/deed/selectors'
import { CheckboxItemProps } from 'src/retired/elements/Checkbox/CheckboxList'
import { selectSkills } from 'src/entities/skill/selectors'
import Location from 'src/entities/location/model'
import { selectSearchLocation } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { Coordinates } from 'src/utils/coordinates'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { SidebarContainer } from '../../Sidebar/SidebarContainer'
import { ChipItemProps } from '../../common/ToggleableChipList'
import {
  AttendanceOptions,
  DayOptions,
  DeedsOrigin,
  SearchParams,
  VolunteerTypes,
  addSearchDataToUrl,
  setSearchDataToUrl,
  allDays,
  allTimes,
  TabType,
  getTranslatedWeekdays,
  useSearchParams,
} from '../../utils'

import { VolunteerFilters } from './VolunteerFilters'

const defaultRadius = 20

export const VolunteerFiltersContainer = ({ close }: { close?: () => void }) => {
  const { t } = useTranslation('searchScreen')
  const user = useSelector(selectCurrentUser)
  const locale = user?.locale ?? 'en-US'
  const { metrics } = useDeedTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  const { search } = useLocation()
  const {
    location: locationFromUrl,
    coordinates: coordinatesFromUrl,
    radius: radiusFromUrl,
    volunteerTypes,
    currentActiveCauses,
    currentActiveSkills,
    deedsOrigin: initialDeedsOriginUrl,
    attendanceOptions,
    date: dateFromUrl,
    days: daysOptions,
    times: timeOptions,
  } = useSearchParams()

  const [areFiltersTouched, setAreFiltersTouched] = useState(false)

  // SearchRadiusFilter Filter
  const [searchRadius, setSearchRadius] = useState(defaultRadius)
  useEffect(() => {
    setSearchRadius(Number(radiusFromUrl || defaultRadius))
  }, [])

  useEffect(() => {
    setAttendanceOptionsList([
      {
        id: AttendanceOptions.inPerson,
        name: t('inPerson'),
        status: !attendanceOptions?.length || attendanceOptions.includes(AttendanceOptions.inPerson),
      },
      {
        id: AttendanceOptions.virtual,
        name: t('virtual'),
        status: !attendanceOptions?.length || attendanceOptions.includes(AttendanceOptions.virtual),
      },
    ])
  }, [])

  // Volunteer type visibility toggle
  const [volunteerTypesList, setVolunteerTypesList] = useState<ChipItemProps[]>(() => [])

  useEffect(() => {
    setVolunteerTypesList([
      {
        id: VolunteerTypes.oneOff,
        name: t('oneOff'),
        status: !volunteerTypes?.length || volunteerTypes.includes(VolunteerTypes.oneOff),
      },
      {
        id: VolunteerTypes.ongoing,
        name: t('ongoing'),
        status: !volunteerTypes?.length || volunteerTypes.includes(VolunteerTypes.ongoing),
      },
    ])
  }, [])

  // Deed-Origin Filter
  const corporateOnly = useSelector((state) => selectCorporateOnly(state))
  const corporateOnlyToggleDefault = user?.organization?.settings.corporateOnlyToggleDefault

  const companyName = useSelector(selectUserBrand)?.name
  const initialDeedsOriginCompanyData = {
    id: DeedsOrigin.company,
    name: t('onlyCompany', { companyName }),
    status:
      (initialDeedsOriginUrl && initialDeedsOriginUrl === DeedsOrigin.company) ||
      (!initialDeedsOriginUrl && !!corporateOnly),
  }

  const initialDeedsOriginAllDeedsData = {
    id: DeedsOrigin.all,
    name: t('allOpportunities'),
    status:
      (initialDeedsOriginUrl && initialDeedsOriginUrl === DeedsOrigin.all) ||
      (!initialDeedsOriginUrl && !corporateOnly),
  }

  const [deedsOriginList, setDeedsOriginList] = useState<ChipItemProps[]>(() => [
    initialDeedsOriginCompanyData,
    initialDeedsOriginAllDeedsData,
  ])

  useEffect(() => {
    if (!initialDeedsOriginUrl) {
      setDeedsOriginList([
        { ...initialDeedsOriginCompanyData, status: !!corporateOnly },
        { ...initialDeedsOriginAllDeedsData, status: !corporateOnly },
      ])
    }
  }, [corporateOnly])

  // Attendance-Options Filter
  const [attendanceOptionsList, setAttendanceOptionsList] = useState<ChipItemProps[]>(() => [])

  useEffect(() => {
    setAttendanceOptionsList([
      {
        id: AttendanceOptions.inPerson,
        name: t('inPerson'),
        status: !attendanceOptions?.length || attendanceOptions.includes(AttendanceOptions.inPerson),
      },
      {
        id: AttendanceOptions.virtual,
        name: t('virtual'),
        status: !attendanceOptions?.length || attendanceOptions.includes(AttendanceOptions.virtual),
      },
    ])
  }, [])

  // Dates Filter
  const [date, setDate] = useState<Date | null>(null)
  const deedsCountByDate = useSelector(selectDeedsCountByDate)

  useEffect(() => {
    setDate(dateFromUrl ? parseISO(dateFromUrl) : null)
  }, [])

  // Days filter
  const [daysOptionsList, setDaysOptionsList] = useState<CheckboxItemProps[]>(() => [])

  const translatedDays = getTranslatedWeekdays(locale)

  useEffect(() => {
    setDaysOptionsList(
      allDays.map((day, index) => ({
        id: day,
        name: translatedDays[index],
        status: daysOptions.includes(day),
      }))
    )
  }, [locale])

  // Time filter
  const [timeOptionsList, setTimeOptionsList] = useState<CheckboxItemProps[]>(() => [])

  useEffect(() => {
    setTimeOptionsList(
      allTimes.map((time) => ({
        id: time,
        name: t(time.toLowerCase()),
        status: timeOptions.includes(time),
      }))
    )
  }, [])

  // Skills filter
  const allSkills = useSelector(selectSkills)
  const allSkillsAsJS = allSkills.toArray()

  const [skillsOptionsList, setSkillsOptionsList] = useState<ChipItemProps[]>([])

  useEffect(() => {
    setSkillsOptionsList(
      allSkillsAsJS.map((skill) => ({
        id: skill.code,
        name: skill.name,
        status: !!currentActiveSkills?.find((s) => s.code === skill.code),
      }))
    )
  }, [allSkills, currentActiveSkills])

  // Cause filter
  const [causesOptionsList, setCausesOptionsList] = useState<ChipItemProps[]>([])

  // Location filter
  const locations = useSelector(selectUserLocations)
  const persistedSearchLocation = useSelector(selectSearchLocation)
  const isLocationFilterEnabled = !config.isProduction || user?.organization?.settings?.deedFeedLocationFilter
  const userLocation = user?.location ? (locations?.get(user?.location) as Location) : undefined
  const userLocationString = userLocation?.name
  const defaultLocation = {
    country: 'United States',
    countryCode: 'US',
    // @NOTE-CH: This is "St. Joseph, MO" (roughly in the middle of US)
    coordinates: [39.7592302, -94.8623832] as Coordinates,
  }

  const userLocationOrDefault = userLocationString || defaultLocation.country
  const userCoordinatesOrDefault = userLocation?.coordinates || defaultLocation.coordinates

  const locationCodeWithFallback = locationFromUrl || persistedSearchLocation?.location || userLocationOrDefault
  const coordinatesWithFallback = coordinatesFromUrl || persistedSearchLocation?.coordinates || userCoordinatesOrDefault

  const [location, setLocation] = useState<string | false | null>(locationCodeWithFallback)
  const [coordinates, setCoordinates] = useState<Coordinates | null>(coordinatesWithFallback)

  const handleSetLocation = (newLocation?: { coordinates: Coordinates; location: string }) => {
    if (!newLocation?.location) {
      setLocation(false)
      setCoordinates(null)
      return
    }

    setLocation(newLocation?.location ?? userLocationOrDefault)
    setCoordinates(newLocation?.coordinates || userCoordinatesOrDefault)
  }

  useEffect(() => {
    setLocation(locationCodeWithFallback)
    setCoordinates(coordinatesWithFallback)
  }, [locationCodeWithFallback, coordinatesFromUrl, userLocation])

  // Clear and apply filters
  const onClearAllClick = () => {
    setLocation(userLocationOrDefault)
    setCoordinates(userCoordinatesOrDefault)
    setSearchRadius(defaultRadius)

    setVolunteerTypesList((prev) => prev.map((item) => ({ ...item, status: true })))
    setDeedsOriginList([
      { ...initialDeedsOriginCompanyData, status: !!corporateOnlyToggleDefault },
      { ...initialDeedsOriginAllDeedsData, status: !corporateOnlyToggleDefault },
    ])
    setAttendanceOptionsList((prev) => prev.map((item) => ({ ...item, status: true })))
    setDate(null)
    setDaysOptionsList((prev) => prev.map((item) => ({ ...item, status: false })))
    setTimeOptionsList((prev) => prev.map((item) => ({ ...item, status: false })))
    setSkillsOptionsList((prev) => prev.map((item) => ({ ...item, status: false })))
    setCausesOptionsList((prev) => prev.map((item) => ({ ...item, status: false })))

    if (isLocationFilterEnabled) {
      dispatch(setLocalSettingAction('searchLocation', null))
    }

    setSearchDataToUrl(
      {
        [SearchParams.tab]: TabType.volunteer,
        [SearchParams.location]: userLocationOrDefault,
        [SearchParams.coordinates]: userCoordinatesOrDefault,
        [SearchParams.radius]: defaultRadius,
      },
      history
    )
    setAreFiltersTouched(true)
  }

  // Updates the URL and redux states. The actual api request is triggered by the SearchPageContainer
  const onSubmitAllClick = () => {
    const deedOrigin = deedsOriginList.filter((item) => item.status).map(({ id }) => id) as DeedsOrigin[]
    const updatedVolunteerType = volunteerTypesList
      .filter((item) => item.status)
      .map(({ id }) => id) as VolunteerTypes[]

    const updatedAttendanceOption = attendanceOptionsList
      .filter((item) => item.status)
      .map(({ id }) => id) as AttendanceOptions[]

    const updatedADaysOption = daysOptionsList.filter((item) => item.status).map(({ id }) => id) as DayOptions[]
    const updatedTimesOption = timeOptionsList.filter((item) => item.status).map(({ id }) => id) as DayOptions[]
    const updatedSkillOption = skillsOptionsList.filter((item) => item.status).map(({ id }) => id)

    const selectedCausesIds = causesOptionsList.filter((cause) => cause.status).map(({ id }) => id)
    const selectedCausesNames = causesOptionsList
      .filter((cause) => selectedCausesIds.includes(cause.id))
      .map((cause) => cause.name)

    if (isLocationFilterEnabled) {
      dispatch(setLocalSettingAction('searchLocation', { location, coordinates }))
    }

    // @FIXME-CH: We should clean up the unrelated parameters from the URL when switching tabs or apply new filters
    addSearchDataToUrl(
      {
        [SearchParams.volunteerType]: updatedVolunteerType,
        [SearchParams.deedsOrigin]: deedOrigin,
        [SearchParams.attendanceOption]: updatedAttendanceOption,
        [SearchParams.date]: date?.toISOString(),
        [SearchParams.day]: updatedADaysOption,
        [SearchParams.time]: updatedTimesOption,
        [SearchParams.skill]: updatedSkillOption,
        [SearchParams.cause]: selectedCausesNames,
        [SearchParams.location]: location,
        [SearchParams.coordinates]: coordinates,
        [SearchParams.radius]: searchRadius,
      },
      search,
      history
    )
  }

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const updatedVolunteerType = volunteerTypesList
        .filter((item) => item.status)
        .map(({ id }) => id) as VolunteerTypes[]

      addSearchDataToUrl(
        {
          [SearchParams.volunteerType]: updatedVolunteerType,
        },
        search,
        history
      )
    }
  }, [volunteerTypesList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const deedOrigin = deedsOriginList.filter((item) => item.status).map(({ id }) => id) as DeedsOrigin[]
      addSearchDataToUrl(
        {
          [SearchParams.deedsOrigin]: deedOrigin,
        },
        search,
        history
      )
    }
  }, [deedsOriginList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const updatedAttendanceOption = attendanceOptionsList
        .filter((item) => item.status)
        .map(({ id }) => id) as AttendanceOptions[]
      addSearchDataToUrl(
        {
          [SearchParams.attendanceOption]: updatedAttendanceOption,
        },
        search,
        history
      )
    }
  }, [attendanceOptionsList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      addSearchDataToUrl(
        {
          [SearchParams.date]: date?.toISOString(),
        },
        search,
        history
      )
    }
  }, [date])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const updatedADaysOption = daysOptionsList.filter((item) => item.status).map(({ id }) => id) as DayOptions[]
      addSearchDataToUrl(
        {
          [SearchParams.day]: updatedADaysOption,
        },
        search,
        history
      )
    }
  }, [daysOptionsList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const updatedTimesOption = timeOptionsList.filter((item) => item.status).map(({ id }) => id) as DayOptions[]
      addSearchDataToUrl(
        {
          [SearchParams.time]: updatedTimesOption,
        },
        search,
        history
      )
    }
  }, [timeOptionsList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const updatedSkillOption = skillsOptionsList.filter((item) => item.status).map(({ id }) => id)
      addSearchDataToUrl(
        {
          [SearchParams.skill]: updatedSkillOption,
        },
        search,
        history
      )
    }
  }, [skillsOptionsList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      const selectedCausesIds = causesOptionsList.filter((cause) => cause.status).map(({ id }) => id)
      const selectedCausesNames = causesOptionsList
        .filter((cause) => selectedCausesIds.includes(cause.id))
        .map((cause) => cause.name)

      addSearchDataToUrl(
        {
          [SearchParams.cause]: selectedCausesNames,
        },
        search,
        history
      )
    }
  }, [causesOptionsList])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      if (isLocationFilterEnabled) {
        dispatch(setLocalSettingAction('searchLocation', { location, coordinates }))
      }

      addSearchDataToUrl(
        {
          [SearchParams.location]: location,
          [SearchParams.coordinates]: coordinates,
        },
        search,
        history
      )
    }
  }, [location])

  useEffect(() => {
    if (metrics.isLarge && areFiltersTouched) {
      addSearchDataToUrl(
        {
          [SearchParams.radius]: searchRadius,
        },
        search,
        history
      )
    }
  }, [searchRadius])

  return (
    <SidebarContainer
      close={close}
      onClearAllClick={onClearAllClick}
      onSubmitAllClick={onSubmitAllClick}
      areFiltersTouched={areFiltersTouched}
    >
      <VolunteerFilters
        location={location}
        setLocation={handleSetLocation}
        searchRadius={searchRadius}
        isLocationFilterEnabled={isLocationFilterEnabled}
        setSearchRadius={setSearchRadius}
        deedsOriginList={deedsOriginList}
        setDeedsOriginList={setDeedsOriginList}
        setAreFiltersTouched={setAreFiltersTouched}
        volunteerTypesList={volunteerTypesList}
        setVolunteerTypesList={setVolunteerTypesList}
        attendanceOptionsList={attendanceOptionsList}
        setAttendanceOptionsList={setAttendanceOptionsList}
        date={date}
        setDate={setDate}
        deedsCountByDate={deedsCountByDate}
        daysOptionsList={daysOptionsList}
        setDaysOptionsList={setDaysOptionsList}
        timeOptionsList={timeOptionsList}
        setTimeOptionsList={setTimeOptionsList}
        skillsOptionsList={skillsOptionsList}
        setSkillsOptionsList={setSkillsOptionsList}
        currentActiveCauses={currentActiveCauses}
        causesOptionsList={causesOptionsList}
        setCausesOptionsList={setCausesOptionsList}
      />
    </SidebarContainer>
  )
}
