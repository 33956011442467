import React from 'react'

import { Screen } from 'src/retired/elements'
import Carousel from 'src/retired/blocks/Carousel'
import { PageTitle } from 'src/components'

export const Landing = () => (
  <Screen style={{ backgroundColor: 'black' }} fixed>
    <PageTitle />
    <Carousel />
  </Screen>
)

export default Landing
