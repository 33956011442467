import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body, H5 } from 'src/retired/shared/Typography'
import Tooltip from 'src/retired/elements/Tooltip'

const giveIndiaRed = '#ff5050'

export const GiveIndiaModal = (): JSX.Element => {
  const theme = useDeedTheme()
  const { t } = useTranslation('donateScreen')
  return (
    <Tooltip
      width={Math.min(theme.metrics.screenWidth - 20, 600)}
      closeButton
      // eslint-disable-next-line react/no-unstable-nested-components
      Toggle={() => (
        <View>
          <Body colour={giveIndiaRed}>
            {t`giveIndiaModalLink`}{' '}
            <svg width="12" height="12" fill="none">
              <path d="M6 0 4.942 1.058 9.127 5.25H0v1.5h9.127l-4.185 4.193L6 12l6-6-6-6Z" fill="#FF5052" />
            </svg>
          </Body>
        </View>
      )}
    >
      <View style={{ paddingHorizontal: 40 }}>
        <H5 weight="bold" marginTop={30} marginBottom={30}>
          {t`giveIndiaModalFAQ`}
        </H5>
        <Body weight="bold" marginBottom={15} lineHeight={22}>
          {t`giveIndiaModalBody1`}
        </Body>
        <Body marginBottom={30} lineHeight={22}>
          {t`giveIndiaModalBody2`}
        </Body>

        <Body weight="bold" marginBottom={15} lineHeight={22}>
          {t`giveIndiaModalBody3`}
        </Body>
        <Body marginBottom={30} lineHeight={22}>
          {t`giveIndiaModalBody4`}
        </Body>

        <Body weight="bold" marginBottom={15} lineHeight={22}>
          {t`giveIndiaModalBody5`}
        </Body>
        <Body marginBottom={30} lineHeight={22}>
          {t`giveIndiaModalBody6`}
        </Body>
      </View>
    </Tooltip>
  )
}
