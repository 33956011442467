/** @jsx jsx */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { jsx } from '@emotion/react'
import { View } from 'react-primitives'

import { selectCurrentUser } from 'src/entities/user/selectors'
import locales from 'src/i18n/locales'
import { useInjectEpics } from 'src/utils/injectEpics'
import { getLanguageCode, getClosestLocaleCode, overrideLanguageCodeOnLocale } from 'src/utils/languageUtils'
import { Select } from 'src/components'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { persistLanguageAction } from './actions'
import epics from './epics'

const LanguageSwitcher = ({
  transparent,
  globeOnly,
  large,
}: {
  transparent?: boolean
  globeOnly?: boolean
  // large only works together with globeOnly=true
  large?: boolean
}): JSX.Element => {
  const { i18n } = useTranslation()
  const { colors } = useDeedTheme()
  useInjectEpics({ key: 'languageSwitcher', epics })
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const currentLocale = getLanguageCode(i18n.language)
  const actualCurrentLocale = getClosestLocaleCode(currentLocale, locales)

  const onLanguageChange = (selectedLocale: string): void => {
    // Override only the language code and leave the private part intact
    const newLocale = overrideLanguageCodeOnLocale(i18n.language, selectedLocale)

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(newLocale)

    // The displayed language is switched immediately, but we want to silently persist it in background
    // Keep in mind that we're only persisting the public part, private part is dynamically reconstructed
    if (user) {
      dispatch(persistLanguageAction(selectedLocale))
    }
  }

  const getRightMargin = () => {
    if (globeOnly) {
      return large ? 13 : 7
    }
    return 10
  }

  return (
    <View style={!globeOnly ? { minWidth: 110 } : {}}>
      <Select
        options={locales.map((loc) => ({
          value: loc.code,
          title: loc.name,
        }))}
        onSelect={onLanguageChange}
        value={actualCurrentLocale}
        placeholder={i18n.t`languageSwitcher:switchLanguage`}
        customIcon={() =>
          (
            <View style={{ position: 'absolute', right: getRightMargin(), pointerEvents: 'none' }}>
              <Icon width={14} height={14} icon="globe" hexColor={transparent ? 'white' : colors.grayDark} />
            </View>
          ) as unknown as React.ElementType
        }
        transparent={transparent}
        iconOnly={globeOnly}
        customBorderColor={colors.grayLighter}
        large={large}
      />
    </View>
  )
}
export default LanguageSwitcher
