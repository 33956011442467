import React from 'react'

import { Activity } from 'src/containers/modules/Actions/types'
import List from 'src/retired/blocks/List'

import { ActivityItem } from './ActivityItem'

export const ActivitiesList = ({ activities, limit }: { activities: Activity[]; limit?: number }) => (
  <List
    initialNumToRender={5}
    windowSize={3}
    numColumns={1}
    itemProps={{
      style: {
        width: '100%',
      },
    }}
    showsVerticalScrollIndicator={false}
    showsHorizontalScrollIndicator={false}
    data={activities.slice(0, limit ?? Infinity)}
    keyExtractor={(item) => item.id}
    renderItem={({ item }) => <ActivityItem activity={item} />}
  />
)
