import React from 'react'

import { styled } from 'src/theme/styled'
import { Body2 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'

const Wrapper = styled.View`
  height: 40px;
  padding: 12px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const ActionChip = ({
  iconName,
  content,
  backgroundColor,
  iconColor,
}: {
  iconName: string
  content: string | JSX.Element
  backgroundColor: string
  iconColor?: string
}) => (
  <Wrapper style={{ backgroundColor }}>
    <Icon icon={iconName} width={24} hexColor={iconColor} />
    <Body2
      style={{
        paddingLeft: 12,
        fontSize: 13,
        opacity: 0.9,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {content}
    </Body2>
  </Wrapper>
)
