import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import User from '../user/model'
import Deed from '../deed/model'

import Donation from './model'
import type { DonationMap, DonationState } from './reducer'

export const selectDonationState = (state: State): DonationState => state.get('entities').donation

const sortNewestFirst = (a: Donation, b: Donation): number =>
  new Date(b.date || b.createdAt).getTime() - new Date(a.date || b.createdAt).getTime()

export const selectDonations = createSelector(selectDonationState, (donationState) =>
  donationState.get('donations').sort(sortNewestFirst)
)

export const selectDonationById = createSelector(
  selectDonations,
  (_state: State, donationId: string) => donationId,
  (donations, donationId): Donation | undefined => donations.get(donationId)
)

export const selectDonationsForUser = createSelector(
  selectDonations,
  (_state: State, user: User | string) => user,
  (donations: DonationMap, user) => donations.filter((donation: Donation) => donation.isByUser(user))
)

export const selectDonationsForUserLoaded = createSelector(
  selectDonationState,
  (_state: State, userId?: string) => userId || 'me',
  (donationState, userId) => donationState.get('donationsForUserLoaded').get(userId) || false
)

export const selectDonationsForDeed = createSelector(
  selectDonations,
  (_state: State, deed: Deed | string) => deed,
  (donations, deed) => donations.filter((donation: Donation) => donation.isForDeed(deed)).sort(sortNewestFirst)
)

export const selectDonationsForDeedLoaded = createSelector(
  selectDonationState,
  (_state: State, deedId: string) => deedId,
  (donationState, deedId) => donationState.get('donationsForDeedLoaded').get(deedId) || false
)

export const selectDonationsForCampaignLoading = createSelector(
  selectDonationState,
  (_state: State, campaignId: string) => campaignId,
  (donationState, campaignId) => donationState?.get('donationsForCampaignLoading')?.get(campaignId) || false
)
