import React from 'react'
import PropTypes from 'prop-types'

const FluidImage = ({ source, style, aspectRatio, ...props }) => (
  <img
    src={source.uri}
    alt=""
    style={{
      maxWidth: '100%',
      ...style,
    }}
    {...props}
  />
)
FluidImage.propTypes = {
  source: PropTypes.object,
  aspectRatio: PropTypes.number,
  style: PropTypes.any,
}

export default FluidImage
