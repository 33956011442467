import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCommunitiesWithMembersQuery } from 'src/generated/graphql'
import User from 'src/entities/user/model'
import ScrollContainer from 'src/retired/shared/ScrollContainer'
import CardList from 'src/retired/shared/CardList'
import CommunityCompact from 'src/retired/blocks/CommunityCompact'

import { Section, SectionContent } from '../Profile.styled'

const CommunitiesSection = ({ user }: { user?: User }) => {
  const { t } = useTranslation('profileScreen')

  const isCommunitiesFeatureEnabled = user?.hasFeature('communities')

  const { data } = useCommunitiesWithMembersQuery({
    variables: {
      where: {
        id: { in: user?.communities ? user.communities.map((c) => c.communityId) : [] },
      },
      membersTake: 2,
      excludeUserIds: user?.id ? [user.id] : [],
    },
    skip: !isCommunitiesFeatureEnabled,
  })

  const communities = data?.communities

  if (!communities?.length) {
    return null
  }

  return (
    <Section>
      <SectionContent>
        <ScrollContainer
          title={t`communities`}
          titleStyle={{ fontSize: 20 }}
          titleWeight="normal"
          cardType="card"
          dimension={communities?.length}
          render={(scrollViewOffset) => (
            <CardList scrollViewOffset={scrollViewOffset}>
              {communities?.map((community) => (
                <CommunityCompact
                  key={community.id}
                  community={community}
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role={user.communities?.find((c) => c.communityId === community.id)?.role}
                />
              ))}
            </CardList>
          )}
        />
      </SectionContent>
    </Section>
  )
}

export default CommunitiesSection
