/* global __DETECTED_VIEWER_TIME_ZONE__ */

import { Observable } from 'rxjs'
// eslint-disable-next-line import/named
import { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'
import { zonedTimeToUtc } from 'date-fns-tz'
import { replace, goBack } from 'connected-react-router'
import moment from 'moment'

import DeedsApi from 'src/entities/deed/api'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showErrorAction, showMessageAction } from 'src/containers/modules/Alerts/actions'

import {
  setStepAction,
  Init,
  SubmitDeed,
  initSuccessAction,
  initFailedAction,
  submitDeedSuccessAction,
  submitDeedFailedAction,
  DeleteDeed,
  deleteDeedSuccessAction,
  deleteDeedFailedAction,
} from './actions'
import { INIT, SUBMIT_DEED, DELETE_DEED } from './constants'

const init = (action$: ActionsObservable<Init>, store: Store) =>
  action$.ofType(INIT).exhaustMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const deed = selectDeedById(state, id)
    const user = selectCurrentUser(state)

    if (!deed) {
      actions.push(DeedsApi.fetch(id))
    } else if (!deed.isSubmittedByUser(user)) {
      return Observable.of(replace('/home'))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => {
        if (!resultingActions[0].deed.isSubmittedByUser(user)) {
          return [replace('/home')]
        }
        return [...resultingActions, initSuccessAction()]
      })
      .catch((error) => [initFailedAction(error), showErrorAction(i18n.t('createDeedScreen:loadingDeedFailed'))])
  })

const submitDeed = (action$: ActionsObservable<SubmitDeed>) =>
  action$.ofType(SUBMIT_DEED).exhaustMap(({ data, id }) => {
    const submitData = {
      deedType: data.deedType,
      status: data.status,
      pictures: data.picture && [data.picture],
      name: data.name,
      description: data.description,
      timeZone: __DETECTED_VIEWER_TIME_ZONE__,
      organizingCommunityIds: data.organizingCommunityIds,
      linkOnly: data.linkOnly,
    }

    if (data.deedType === 'Event') {
      submitData.duties = data.duties
      submitData.mustKnows = data.mustKnows
      submitData.attendeeLimit = data.attendeeLimit
      // @NOTE-CH: The order here is [Longitude,Latitude] because the `addressToLocation` give us in that order
      submitData.location = data.locationLatLng
      submitData.locationObject = data.locationObject
      submitData.virtual = data.virtual
      submitData.externalLink = data.virtual ? data.externalLink : undefined
      submitData.roles = data.roles
      submitData.formQuestions = data.formQuestions
      submitData.donationText = data.donationText
      submitData.donationLink = data.donationLink
      submitData.organization = data.nonprofit
        ? {
            id: data.nonprofit.get('id'),
            externalId: data.nonprofit.get('externalId'),
          }
        : null
      submitData.timeZone = data.timeZone
      submitData.startingAt = zonedTimeToUtc(data.startingAt, data.timeZone)
      submitData.endingAt = zonedTimeToUtc(data.endingAt, data.timeZone)
      submitData.duration = Math.floor(moment(data.endingAt).diff(data.startingAt) / 60000)
    }

    if (data.deedType === 'Campaign') {
      const nonprofitId = data.nonprofit?.get('id')
      submitData.goalAmount = data.amount
      submitData.currencyCode = data.currency
      submitData.nonprofits = data.nonprofit && [
        { id: !nonprofitId?.startsWith('ext-') && nonprofitId, externalId: data.nonprofit.get('externalId') },
      ]
      submitData.timeZone = data.timeZone
      submitData.startingAt = data.startingAt
        ? zonedTimeToUtc(new Date(new Date(data.startingAt).setHours(0, 0, 0, 0)), data.timeZone)
        : undefined
      submitData.endingAt = data.endingAt
        ? zonedTimeToUtc(new Date(new Date(data.endingAt).setHours(23, 59, 59, 0)), data.timeZone)
        : undefined
    }

    if (id) {
      return DeedsApi.update(id, submitData)
        .mergeMap((resultingAction) => [
          resultingAction,
          submitDeedSuccessAction(resultingAction.deed),
          setStepAction(data.deedType === 'Event' ? 5 : 4),
        ])
        .catch((error) => [submitDeedFailedAction(error), showErrorAction(i18n.t('createDeedScreen:submissionFailed'))])
    }

    return DeedsApi.create({
      ...submitData,
      startingAt: submitData.startingAt ?? new Date().toISOString(),
      endingAt: submitData.endingAt ?? undefined,
    })
      .mergeMap((resultingAction) => [
        resultingAction,
        submitDeedSuccessAction(resultingAction.deed),
        setStepAction(data.deedType === 'Event' ? 5 : 4),
      ])
      .catch((error) => [submitDeedFailedAction(error), showErrorAction(i18n.t('createDeedScreen:submissionFailed'))])
  })

const deleteDeed = (action$: ActionsObservable<DeleteDeed>, store: Store) =>
  action$.ofType(DELETE_DEED).exhaustMap(({ id }) => {
    const state = store.getState()

    const locationState = state.get('router').get('location').get('state')

    return DeedsApi.delete(id)
      .mergeMap((resultingAction) => [
        resultingAction,
        deleteDeedSuccessAction(),
        locationState?.get('fromDeedPage') ? replace('/home') : goBack(),
        showMessageAction('Deed was removed'),
      ])
      .catch((error) => [deleteDeedFailedAction(error), showErrorAction('Deed removal failed')])
  })

export default [init, submitDeed, deleteDeed]
