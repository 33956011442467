import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useHistory } from 'src/navigation'
import { Screen, ScrollView, ActivityIndicator, Alert } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { useBadgesQuery } from 'src/generated/graphql'
import { styled } from 'src/theme/styled'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'

import { BadgesEarnedText } from '../BadgesEarnedText'

import { BadgesList } from './BadgesList'

const BadgesContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? '15px 0px' : '25px 25px 0px 25px')};
  border-style: solid;
  border-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.lightBorderGray};
  border-top-width: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? '0px' : '1px')};
`

const BadgesScreen = () => {
  const { t } = useTranslation('badges')
  const { colors } = useDeedTheme()
  const history = useHistory()
  const { data: badgesData, error: badgesError, loading: badgesLoading } = useBadgesQuery()
  if (badgesError) {
    Alert.alert(t`errorLoadingBadges`)
    history.replace('/')
  }

  const allBadges = badgesData?.badges
  const badgesWithAwards = allBadges?.filter((badge) => !!badge.awardedBadge) || []

  return (
    <Screen>
      <ScrollView style={{ paddingBottom: 10 }}>
        <NavigationHeader title={t`badges`} transparent />
        {badgesLoading && (
          <View style={{ paddingVertical: 40 }}>
            <ActivityIndicator color={colors.brandColor} />
          </View>
        )}
        {!badgesLoading && allBadges?.length ? (
          <View style={{ paddingHorizontal: 25, paddingBottom: 25 }}>
            <BadgesContainer>
              <BadgesEarnedText badgesWithAwardsLength={badgesWithAwards?.length} allBadgesLength={allBadges.length} />
            </BadgesContainer>

            <View style={{ paddingTop: 25 }}>
              <BadgesList badges={allBadges || []} />
            </View>
          </View>
        ) : null}
      </ScrollView>
    </Screen>
  )
}

export default BadgesScreen
