import { Map, fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'

import {
  ADD,
  ADD_MULTIPLE,
  REMOVE,
  CLEAR,
  DONATIONS_FOR_USER_LOADED,
  DONATIONS_FOR_DEED_LOADED,
  LOAD_DONATIONS_FOR_CAMPAIGN,
  DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS,
  DONATIONS_FOR_CAMPAIGN_LOADING_FAILED,
} from './constants'
import Donation from './model'

export type DonationMap = Map<string, Donation>
type BooleanMap = Map<string, boolean>

export type DonationState = TypedMap<{
  donations: DonationMap
  donationsForUserLoaded: BooleanMap
  donationsForDeedLoaded: BooleanMap
  donationsForCampaignLoading: BooleanMap
}>

const initialState = fromJS({
  donations: Map(),
  donationsForUserLoaded: Map(),
  donationsForDeedLoaded: Map(),
  donationsForCampaignLoading: Map(),
}) as DonationState

export default (state: DonationState = initialState, action: any): DonationState => {
  switch (action.type) {
    case ADD:
      return state.set('donations', state.get('donations').set(action.donation.id, action.donation))

    case ADD_MULTIPLE:
      return state.set('donations', state.get('donations').merge(action.donations))

    case REMOVE:
      return state.set('donations', state.get('donations').delete(action.id))

    case DONATIONS_FOR_USER_LOADED:
      return state.set('donationsForUserLoaded', state.get('donationsForUserLoaded').set(action.userId, action.value))

    case DONATIONS_FOR_DEED_LOADED:
      return state.set('donationsForDeedLoaded', state.get('donationsForDeedLoaded').set(action.deedId, true))

    case LOAD_DONATIONS_FOR_CAMPAIGN:
      return state.set(
        'donationsForCampaignLoading',
        state.get('donationsForCampaignLoading').set(action.campaignId, true)
      )

    case DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS:
      return state.set(
        'donationsForCampaignLoading',
        state.get('donationsForCampaignLoading').set(action.campaignId, false)
      )

    case DONATIONS_FOR_CAMPAIGN_LOADING_FAILED:
      return state.set(
        'donationsForCampaignLoading',
        state.get('donationsForCampaignLoading').set(action.campaignId, false)
      )

    case CLEAR:
    case 'RESET':
      return initialState
  }
  return state
}
