import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useJoinCommunityMutation, useLeaveCommunityMutation } from 'src/generated/graphql'
import { Row, ActivityIndicator } from 'src/retired/elements'
import Touchable from 'src/retired/shared/Touchable'
import { PopoverMenu } from 'src/components/PopoverMenu'
import Icon from 'src/retired/shared/Icon'
import { Body1, Label } from 'src/retired/shared/Typography'
import { css } from 'src/theme/styled'

import { ModalLeaveCommunity } from '../ModalLeaveCommunity'

interface JoinLeaveCommunityProps {
  communityId: string
  communityTitle: string
  onComplete: () => void
  isCurrentUserCommunityMember: boolean
  isCommunityLoading: boolean
}

export const JoinLeaveCommunity = ({
  communityId,
  communityTitle,
  onComplete,
  isCurrentUserCommunityMember,
  isCommunityLoading,
}: JoinLeaveCommunityProps): JSX.Element => {
  const { t } = useTranslation('communityScreen')
  const { metrics, colors } = useDeedTheme()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [joinCommunityMutation, { loading: joinCommunityLoading }] = useJoinCommunityMutation({
    onError: () => {
      alert(t`somethingWentWrong`)
    },
  })
  const [leaveCommunityMutation, { loading: leaveCommunityLoading }] = useLeaveCommunityMutation({
    onError: () => {
      alert(t`somethingWentWrong`)
    },
  })

  const onPressLeaveCommunityButton = (): void => {
    setIsConfirmationModalOpen(true)
  }

  const onJoinOrLeaveCommunity = async (): Promise<void> => {
    if (isConfirmationModalOpen) {
      setIsConfirmationModalOpen(false)
    }

    if (isCurrentUserCommunityMember) {
      await leaveCommunityMutation({
        variables: {
          communityId,
        },
      })
    } else {
      await joinCommunityMutation({
        variables: {
          communityId,
        },
      })
    }

    onComplete()
  }

  const isJoinLeaveButtonLoading = isCommunityLoading || joinCommunityLoading || leaveCommunityLoading

  return (
    <>
      <View
        style={css`
          height: ${`${metrics.isLarge ? 50 : 42}px`};
          background-color: #ffffff;
          justify-content: center;
          border-radius: 12px;
          border: 1px solid ${colors.primaryLight};
          position: relative;
          overflow: hidden;
        `}
      >
        {isCurrentUserCommunityMember ? (
          <PopoverMenu
            buttonContent={
              isJoinLeaveButtonLoading ? (
                <View style={{ width: '100%', height: '100%' }}>
                  <Row style={{ gap: 8, paddingLeft: 16, paddingRight: 16, alignItems: 'center' }}>
                    <Icon icon="communityJoined" width={18} height={12} hexColor={colors.brandColor} />
                    <Label style={{ fontSize: 16, fontWeight: '600', color: colors.brandColor }}>{t`Joined`}</Label>
                    <Icon icon="triangleDownFilled" width={12} height={6} hexColor={colors.brandColor} />
                  </Row>
                  <View
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0,0,0,0.3)',
                    }}
                  >
                    <ActivityIndicator />
                  </View>
                </View>
              ) : (
                <Row style={{ gap: 8, paddingLeft: 16, paddingRight: 16, alignItems: 'center' }}>
                  <Icon icon="communityJoined" width={18} height={12} hexColor={colors.brandColor} />
                  <Label style={{ fontSize: 16, fontWeight: '600', color: colors.brandColor }}>{t`Joined`}</Label>
                  <Icon icon="triangleDownFilled" width={12} height={6} hexColor={colors.brandColor} />
                </Row>
              )
            }
            options={[{ label: t`leaveCommunity`, color: colors.brandColor }]}
            onOptionClicked={() => {
              void onPressLeaveCommunityButton()
            }}
            disabled={isJoinLeaveButtonLoading}
          />
        ) : (
          <View style={{ height: '100%', justifyContent: 'center' }}>
            <Touchable
              style={{ padding: 12, display: 'flex', alignItems: 'center' }}
              onPress={() => {
                void onJoinOrLeaveCommunity()
              }}
            >
              <Body1 style={{ width: 144 }} weight="500" colour={colors.brandColor} center>
                {t`joinCommunity`}
              </Body1>
            </Touchable>
            {isJoinLeaveButtonLoading && (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                }}
              >
                <ActivityIndicator />
              </View>
            )}
          </View>
        )}
      </View>
      {isConfirmationModalOpen && (
        <ModalLeaveCommunity
          visible
          onClose={() => setIsConfirmationModalOpen(false)}
          onConfirm={() => {
            void onJoinOrLeaveCommunity()
          }}
          communityTitle={communityTitle}
        />
      )}
    </>
  )
}
