import {
  LOAD_FUNDRAISERS,
  LOAD_FUNDRAISERS_SUCCESS,
  LOAD_FUNDRAISERS_FAILURE,
  LOAD_LOCAL_NONPROFITS,
  LOAD_LOCAL_NONPROFITS_SUCCESS,
  LOAD_LOCAL_NONPROFITS_FAILURE,
  CANCEL_LOAD_LOCAL_NONPROFITS,
  LOAD_PARTNER_NONPROFITS,
  LOAD_PARTNER_NONPROFITS_SUCCESS,
  LOAD_PARTNER_NONPROFITS_FAILURE,
} from './constants'

export interface LoadFundraisers {
  type: typeof LOAD_FUNDRAISERS
}
export const loadFundraisersAction = (): LoadFundraisers => ({
  type: LOAD_FUNDRAISERS,
})
export const loadFundraisersSuccessAction = () => ({
  type: LOAD_FUNDRAISERS_SUCCESS,
})
export interface LoadFundraisersNonprofitsFailurePayload {
  type: typeof LOAD_FUNDRAISERS_FAILURE
  error: Error
}
export const loadFundraisersFailureAction = (error: Error): LoadFundraisersNonprofitsFailurePayload => ({
  type: LOAD_FUNDRAISERS_FAILURE,
  error,
})

export interface LoadLocalNonprofits {
  type: typeof LOAD_LOCAL_NONPROFITS
  numberOfItems: number
}
export const loadLocalNonprofitsAction = (numberOfItems: number): LoadLocalNonprofits => ({
  type: LOAD_LOCAL_NONPROFITS,
  numberOfItems,
})
export const loadLocalNonprofitsSuccessAction = () => ({
  type: LOAD_LOCAL_NONPROFITS_SUCCESS,
})
export interface LoadLocalNonprofitsFailurePayload {
  type: typeof LOAD_LOCAL_NONPROFITS_FAILURE
  error: Error
}
export const loadLocalNonprofitsFailureAction = (error: Error): LoadLocalNonprofitsFailurePayload => ({
  type: LOAD_LOCAL_NONPROFITS_FAILURE,
  error,
})
export const cancelLoadLocalNonprofitsAction = () => ({
  type: CANCEL_LOAD_LOCAL_NONPROFITS,
})

export interface LoadPartnerNonprofits {
  type: typeof LOAD_PARTNER_NONPROFITS
  numberOfItems: number
}
export const loadPartnerNonprofitsAction = (numberOfItems: number): LoadPartnerNonprofits => ({
  type: LOAD_PARTNER_NONPROFITS,
  numberOfItems,
})
export const loadPartnerNonprofitsSuccessAction = () => ({
  type: LOAD_PARTNER_NONPROFITS_SUCCESS,
})
export interface LoadPartnerNonprofitsFailurePayload {
  type: typeof LOAD_PARTNER_NONPROFITS_FAILURE
  error: Error
}
export const loadPartnerNonprofitsFailureAction = (error: Error): LoadPartnerNonprofitsFailurePayload => ({
  type: LOAD_PARTNER_NONPROFITS_FAILURE,
  error,
})
