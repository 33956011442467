import { format } from 'date-fns'

export function getIndexOfFirstDeedForDate(deeds, date) {
  let index = 0
  let firstDeedForDateIndex = null
  deeds.forEach((item) => {
    if (!firstDeedForDateIndex && format(new Date(item.get('startingAt')), 'yyyy-MM-dd') === date) {
      firstDeedForDateIndex = index
    } else {
      index += 1
    }
  })
  return firstDeedForDateIndex
}
