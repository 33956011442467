import React from 'react'
import { View } from 'react-primitives'

import Tooltip from 'src/components/Tooltip/Tooltip'
import { styled } from 'src/theme/styled'

interface BarProps {
  colour: string
  height: number
}
const Bar = styled.View<BarProps>`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ height }) => `${height}px`};
  flex-grow: 0;
  background-color: ${({ colour }) => colour};
  overflow: hidden;
  flex-direction: row;
  flex-basis: auto;
`

interface ValueProps {
  offset: number
  value: number
  total: number
  height: number
  colour: string
  first?: boolean
  last?: boolean
}
const Value = styled.View<ValueProps>`
  width: ${({ value, total }) => `${(value / total) * 100}%`};
  height: ${({ height }) => `${height}px`};
  background-color: ${({ colour }) => colour};
  border-top-left-radius: ${({ first, height }) => (first ? `${height}px` : 0)};
  border-bottom-left-radius: ${({ first, height }) => (first ? `${height}px` : 0)};
  border-top-right-radius: ${({ last, height }) => (last ? `${height}px` : 0)};
  border-bottom-right-radius: ${({ last, height }) => (last ? `${height}px` : 0)};
  overflow: hidden;
`

export interface ProgressBarItem {
  label?: string
  color: string
  value: number
  tooltip?: string | JSX.Element
}
interface ProgressBarProps {
  items: ProgressBarItem[]
  total: number
  backgroundColor: string
  height?: number
}
export type ProgressBarElement = React.ReactElement<ProgressBarProps>

const ProgressBar: React.FC<ProgressBarProps> = ({
  items = [],
  total = 0,
  backgroundColor,
  height = 3,
}): ProgressBarElement => {
  const maximum = Math.max(
    total,
    items.reduce((acc, item) => acc + Number(item.value), 0)
  )
  const size = items.length
  let offset = 0
  return (
    <Bar colour={backgroundColor} height={height}>
      {items.map((item, index) => {
        const _offset = offset
        offset += item.value
        return (
          <Value
            key={item.label || `ProgressBar${index}`}
            first={index === 0}
            last={index + 1 === size}
            offset={_offset}
            value={item.value}
            total={maximum}
            colour={item.color}
            height={height}
          >
            {item.tooltip && (
              <Tooltip placement="bottom" title={item.tooltip}>
                <View style={{ width: '100%', height }} />
              </Tooltip>
            )}
          </Value>
        )
      })}
    </Bar>
  )
}
export default ProgressBar
