import React from 'react'
import Lottie from 'lottie-react'
import { View } from 'react-primitives'

import donationLoading from 'src/assets/animations/donationsLoading.json'

export const DonationsReceiptLoading = () => (
  <View
    style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Lottie loop autoplay style={{ width: 100 }} animationData={donationLoading} />
  </View>
)
