module.exports = [
  {
    code: 'en-US',
    name: 'English (US)',
    folderName: 'en',
  },
  // {
  //   code: 'en-GB',
  //   name: 'English (UK)',
  // },
  {
    code: 'es-ES',
    name: 'Español',
    folderName: 'Spanish',
  },
  {
    code: 'pt',
    name: 'Português',
    folderName: 'Portuguese',
  },
  {
    code: 'fr',
    name: 'Français',
    folderName: 'French',
  },
  {
    code: 'fr-CA',
    name: 'Français (Canada)',
    folderName: 'French, Canada',
  },
  {
    code: 'it',
    name: 'Italiana',
    folderName: 'Italian',
  },
  {
    code: 'de',
    name: 'Deutsch',
    folderName: 'German',
  },
  {
    code: 'nl',
    name: 'Nederlands',
    folderName: 'Dutch',
  },
  {
    code: 'da',
    name: 'Dansk',
    folderName: 'Danish',
  },
  {
    code: 'id',
    name: 'Bahasa Indonesia',
    folderName: 'Indonesian',
  },
  {
    code: 'ru',
    name: 'Русский',
    folderName: 'Russian',
  },
  {
    code: 'hi',
    name: 'हिंदी',
    folderName: 'Hindi',
  },
  {
    code: 'ko',
    name: '한국어',
    folderName: 'Korean',
  },
  {
    code: 'ja',
    name: '日本語',
    folderName: 'Japanese',
  },
  {
    code: 'zh-CN',
    name: '简体中文',
    folderName: 'Chinese Simplified',
  },
  {
    code: 'zh-TW',
    name: '繁體中文',
    folderName: 'Chinese Traditional',
  },
]
