import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useDeedTheme } from 'src/theme/ThemeProvider'

import { CountrySelectorProps, useCountriesOptions, StateNode, CountryNode } from './utils'
import { CountrySelectorOption } from './CountrySelectorOption'

interface CountrySelectorWebProps extends CountrySelectorProps<CountryNode | StateNode> {
  allowClear?: boolean
  getPopupContainer?: (node: HTMLElement) => HTMLElement
  currentInput?: string
  width?: string | number
  initialLocationCode?: string
  setInitialLocationCode?: (location: string) => void
}

const CountrySelector = ({
  placeholder,
  countries = [],
  country,
  disabled,
  handleChangeLocation,
  showStates = false,
  itemsPerLocation,
  withVirtualFilter = false,
  initialLocationCode,
  width,
  setInitialLocationCode,
  getPopupContainer = (triggerNode) => triggerNode.parentElement!,
}: CountrySelectorWebProps): JSX.Element => {
  const { colors } = useDeedTheme()

  const options = useCountriesOptions<CountryNode>(countries, showStates, itemsPerLocation, withVirtualFilter)
  const [initialCountryCode = '', initialStateCode = ''] = initialLocationCode?.split('-') ?? []

  const [currentOption, setCurrentOption] = useState<CountryNode | StateNode | null>(null)
  const [currentInput, setCurrentInput] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const getDefaultOptionByCode = (locationCode: string | undefined) => {
    if (!locationCode || !options) {
      return ''
    }

    const countryOption = options.find((option) => option.value === locationCode)
    if (countryOption) {
      return countryOption
    }
    const stateOptions = options
      .map((option) => {
        if (option.options?.length) {
          return option.options
        }
        return null
      })
      .flat()

    const stateOption = stateOptions.find((option) => option?.value === locationCode)
    if (stateOption) {
      return stateOption
    }
  }

  useEffect(() => {
    if (options && !currentOption && !isOpen) {
      const defaultOption = getDefaultOptionByCode(country)
      setCurrentOption(defaultOption || null)
    }
  }, [options])

  useEffect(() => {
    const defaultOption = getDefaultOptionByCode(country)

    setCurrentOption(defaultOption || null)
  }, [country])

  const initialCountryInOptions = initialCountryCode
    ? options?.find(({ countryCode }) => countryCode === initialCountryCode)
    : null
  const initialStateCodeInOptions =
    initialStateCode && initialCountryInOptions
      ? initialCountryInOptions.options?.find(({ stateCode }) => stateCode === initialStateCode)
      : null

  useEffect(() => {
    if (initialLocationCode && setInitialLocationCode && options.length) {
      if (initialCountryInOptions && !initialStateCodeInOptions) {
        setInitialLocationCode(initialCountryCode)
      } else if (!initialCountryInOptions && !initialStateCodeInOptions) {
        setInitialLocationCode('US') // reset to fallback value
      }
    }
  }, [options])

  return (
    <Autocomplete
      placeholder={placeholder}
      options={options}
      getOptionLabel={(option) => option.label}
      sx={{
        width: width || 300,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${colors.gray02}!important`,
          borderWidth: '1px!important',
        },
        '& .MuiOutlinedInput-root': {
          borderWidth: '1px',
          borderRadius: '20px',
          padding: '2px 10px',
          backgroundColor: `${colors.white}!important`,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
            '.MuiInputBase-input': { fontSize: '12px', height: 20 },
            '&::placeholder, & *::-webkit-input-placeholder': {
              color: `${colors.grayMediumDark} !important`,
              opacity: '1 !important',
            },
          }}
          placeholder={placeholder}
        />
      )}
      clearIcon={null}
      renderOption={(props, option) => (
        <CountrySelectorOption
          props={props}
          option={option}
          key={`${option.label} ${option.countryCode}`}
          handleChangeSubOption={(op) => {
            setCurrentOption(op)
            handleChangeLocation(op?.value, op)
          }}
          currentInput={currentInput}
          currentSelectedLocationValue={currentOption?.value}
          showStates
        />
      )}
      onChange={(_, option) => {
        setCurrentOption(option)
        handleChangeLocation(option?.value, option)
      }}
      filterOptions={(allOptions) =>
        allOptions.filter((option) => {
          if (option.label.toLowerCase().includes(currentInput?.toLowerCase() || '')) {
            return true
          }
          if (option.options.some((o) => o.label.toLowerCase().includes(currentInput?.toLowerCase() || ''))) {
            return true
          }
          return false
        })
      }
      onInputChange={(_, value) => {
        setCurrentInput(value)
      }}
      value={currentOption}
      isOptionEqualToValue={() => true}
      blurOnSelect
      onOpen={() => {
        setCurrentInput(null)
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      disabled={disabled}
      getPopupContainer={getPopupContainer}
    />
  )
}

export default CountrySelector
