export const LOAD_FUNDRAISERS = 'containers/donateFeed/LOAD_FUNDRAISERS'
export const LOAD_FUNDRAISERS_SUCCESS = 'containers/donateFeed/LOAD_FUNDRAISERS_SUCCESS'
export const LOAD_FUNDRAISERS_FAILURE = 'containers/donateFeed/LOAD_FUNDRAISERS_FAILURE'

export const LOAD_LOCAL_NONPROFITS = 'containers/donateFeed/LOAD_LOCAL_NONPROFITS'
export const LOAD_LOCAL_NONPROFITS_SUCCESS = 'containers/donateFeed/LOAD_LOCAL_NONPROFITS_SUCCESS'
export const LOAD_LOCAL_NONPROFITS_FAILURE = 'containers/donateFeed/LOAD_LOCAL_NONPROFITS_FAILURE'
export const CANCEL_LOAD_LOCAL_NONPROFITS = 'containers/donateFeed/CANCEL_LOAD_LOCAL_NONPROFITS'

export const LOAD_PARTNER_NONPROFITS = 'containers/donateFeed/LOAD_PARTNER_NONPROFITS'
export const LOAD_PARTNER_NONPROFITS_SUCCESS = 'containers/donateFeed/LOAD_PARTNER_NONPROFITS_SUCCESS'
export const LOAD_PARTNER_NONPROFITS_FAILURE = 'containers/donateFeed/LOAD_PARTNER_NONPROFITS_FAILURE'
