import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { View } from 'react-primitives'
import { parse } from 'query-string'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { AlignedImage, Image, Row } from 'src/retired/elements'
import SignInLink from 'src/retired/blocks/SignInLink'
import { useLocation } from 'src/navigation'
import { images } from 'src/theme'
import { selectCampaignById } from 'src/entities/campaign/selectors'
import { selectUserBrand } from 'src/entities/user/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'

const LiteHeader = () => {
  const dispatch = useDispatch()
  const { colors, metrics } = useDeedTheme()
  const { id } = useParams()
  const { pathname, search } = useLocation()
  const campaignId = pathname.includes('campaign') ? id : parse(search).campaign

  const campaign = useSelector((state) => selectCampaignById(state, campaignId))
  const brand = useSelector(selectUserBrand)

  useEffect(() => {
    if (!brand) {
      if (campaign?.company?.brandColor) {
        dispatch(setLocalSettingAction('brandColor', campaign.company.brandColor))
      }
      if (campaign?.company) {
        dispatch(setLocalSettingAction('brand', campaign.company))
      }
    }
  }, [campaign, brand])

  return (
    <View style={{ backgroundColor: colors.white }}>
      <Row
        style={{
          justifyContent: 'space-between',
          paddingTop: 18,
          paddingBottom: 18,
          paddingLeft: 20,
          paddingRight: 20,
          flexGrow: 0,
          width: metrics.maxWidthNarrow,
          maxWidth: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Row style={{ alignItems: 'center' }}>
          {brand?.mainPicture && !metrics.isSmall ? (
            <View
              style={{
                marginRight: 10,
                paddingRight: 10,
                borderRightWidth: 1,
                borderRightStyle: 'solid',
                borderColor: '#E6E8E8',
              }}
            >
              <AlignedImage width={60} height={34} source={{ uri: brand.mainPicture }} />
            </View>
          ) : null}
          <Image source={images.logoGraphicBlack} style={{ width: 28, height: 34 }} />
        </Row>
        <SignInLink />
      </Row>
    </View>
  )
}

export default LiteHeader
