import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import VolunteerTime from './model'
import { VolunteerTimeState } from './reducer'

export const selectVolunteerTimeState = (state: State) => state.get('entities').volunteerTime

export const selectVolunteerTimes = createSelector(selectVolunteerTimeState, (volunteerTimeState) =>
  volunteerTimeState.get('volunteerTimes')
)

export const selectVolunteerTimeById = createSelector(
  selectVolunteerTimes,
  (_state: State, id: string): string => id,
  (volunteerTimes, id) => volunteerTimes.get(id)
)

export const selectVolunteerTimeByDeed = createSelector(
  selectVolunteerTimes,
  (_state: State, deedId: string) => deedId,
  (volunteerTimes, deedId) =>
    volunteerTimes.filter((volunteerTime: VolunteerTime) => volunteerTime?.deed?.id === deedId)
)

export const selectVolunteerTimesForDeedLoaded = createSelector(
  selectVolunteerTimeState,
  (_state: State, deedId: string) => deedId,
  (volunteerTimeState: VolunteerTimeState, deedId: string) =>
    volunteerTimeState.get('volunteerTimesForDeedLoaded').get(deedId) || false
)

export const selectVolunteerTimeSortedByDate = createSelector(selectVolunteerTimes, (volunteerTimes) =>
  volunteerTimes.sort((a: VolunteerTime, b: VolunteerTime) => b.date.getTime() - a.date.getTime())
)
