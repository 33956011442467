import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import Platform from 'src/utils/Platform'
import Text from 'src/retired/elements/Text'
import Touchable from 'src/retired/elements/Touchable'
import { colors } from 'src/theme'
import { MediumUp } from 'src/retired/elements/MediaQuery'

export default class Tabs extends PureComponent {
  static propTypes = {
    activeTab: PropTypes.number,
    children: PropTypes.node,
  }

  state = {
    activeTab: 0,
  }

  UNSAFE_componentWillMount() {
    this.setState({ activeTab: this.props.activeTab || 0 })
  }

  render() {
    return (
      <View style={{ alignSelf: 'stretch' }}>
        <View style={{ flexDirection: 'row' }}>
          {React.Children.map(this.props.children, (tab, index) => (
            <View key={index} style={[styles.header, index === this.state.activeTab && styles.headerActive]}>
              <Touchable onPress={() => this.setState({ activeTab: index })} style={{ width: '100%', height: '100%' }}>
                <Text
                  medium={index === this.state.activeTab}
                  light={index !== this.state.activeTab}
                  size={13}
                  lineHeight={50}
                  style={{ textAlign: 'center' }}
                >
                  {tab.props.title}
                </Text>
              </Touchable>
              {index === this.state.activeTab ? <View style={styles.border} /> : null}
            </View>
          ))}
        </View>
        <View style={styles.borderTop} />
        <View style={styles.panel}>
          {React.Children.map(this.props.children, (tab, index) => index === this.state.activeTab && tab)}
          {Platform.OS !== 'web' && <View style={styles.borderBottom} />}
          <MediumUp>
            <View style={styles.borderBottom} />
          </MediumUp>
        </View>
      </View>
    )
  }
}

Tabs.Tab = (props) => <View {...props} />

const styles = StyleSheet.create({
  header: {
    flexGrow: 1,
    height: 50,
    cursor: 'pointer',
  },
  headerActive: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowColor: colors.black,
    elevation: 2,
    backgroundColor: colors.white,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  borderTop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 50,
    height: 10,
    backgroundColor: colors.white,
  },
  borderBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: Platform.OS === 'web' ? -5 : -15,
    height: 15,
    backgroundColor: colors.white,
  },
  panel: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    shadowColor: colors.black,
    elevation: 2,
    backgroundColor: colors.white,
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 25,
    zIndex: -1,
  },
})
