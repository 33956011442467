/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, ComponentType, FC, useEffect, useState, PropsWithChildren } from 'react'

import { Platform } from 'src/utils'
import Modal from 'src/retired/elements/Modal'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import { Body1, Body2 } from 'src/retired/shared/Typography'

interface TooltipProps extends PropsWithChildren {
  Toggle?: ComponentType
  title?: string
  width?: number
  closeButton?: boolean
  dismissOnEsc?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const TooltipContext = createContext(() => {})

const Tooltip: FC<TooltipProps> = ({
  Toggle = null,
  title,
  width = 300,
  closeButton = false,
  dismissOnEsc = true,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (Platform.OS === 'web' && isOpen) {
      setTimeout(() => {
        const modalElement = document.querySelector<HTMLElement>('.am-modal')
        modalElement?.setAttribute('role', 'dialog')
        modalElement?.setAttribute('tabIndex', '0')
        modalElement?.focus()
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    if (Platform.OS === 'web' && dismissOnEsc) {
      const keyDownHandler = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          event.preventDefault()
          setIsOpen(false)
        }
      }

      document.addEventListener('keydown', keyDownHandler)

      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }
  }, [])

  return (
    <>
      <Touchable onPress={() => setIsOpen(true)} accessible accessibilityRole="button" style={{ marginHorizontal: 3 }}>
        {Toggle ? <Toggle /> : <Icon icon="infoCircleBlack" width={11} height={11} />}
      </Touchable>
      <Modal
        maskClosable
        transparent
        visible={isOpen}
        title={
          title ? (
            <Body1 weight="500" center>
              {title}
            </Body1>
          ) : undefined
        }
        style={{ width }}
        onClose={() => setIsOpen(false)}
      >
        {closeButton && (
          <Touchable
            onPress={() => setIsOpen(false)}
            accessible
            accessibilityRole="button"
            style={{ position: 'absolute', top: 15, right: 15, zIndex: 1 }}
          >
            <Icon icon="closeCircle" width={29} />
          </Touchable>
        )}
        <TooltipContext.Provider value={() => setIsOpen(false)}>
          {typeof children === 'string' ? (
            <Body2
              colour="black"
              center
              style={{
                paddingHorizontal: 10,
                marginTop: 15,
                whiteSpace: 'initial',
              }}
            >
              {children}
            </Body2>
          ) : (
            children
          )}
        </TooltipContext.Provider>
      </Modal>
    </>
  )
}
export default Tooltip
