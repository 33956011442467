import React, { ReactNode } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { Button } from 'src/retired/elements'
import IconButton from 'src/retired/shared/IconButton'
import { H3, H4 } from 'src/retired/shared/Typography'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'

interface BackAndTitleProps {
  title: string | React.ReactElement
  onBackPress: (e: unknown) => void
}

const BackAndTitle = ({ title, onBackPress }: BackAndTitleProps): JSX.Element => (
  <HeaderContainer>
    <IconButton icon="arrowLeftNew" style={{ marginRight: 32 }} onPress={onBackPress} />
    <H3 weight="500">{title}</H3>
  </HeaderContainer>
)

const HeaderContainer = styled(View)`
  flex-direction: row;
  flex: 1;
`

interface ButtonBoxProps {
  selected: boolean
  brandColor: string
  children: ReactNode
}
const ButtonBox = ({ selected, brandColor, children, ...props }: ButtonBoxProps): JSX.Element => (
  <Button
    style={{
      width: 'calc(20% - 24px)',
      minWidth: '125px',
      height: '90px',
      background: selected ? brandColor : '#ffffff',
      border: '1px solid #ebeef0',
      borderRadius: '8px',
      textAlign: 'center',
      marginRight: '24px',
      marginBottom: '18px',
      position: 'relative',
    }}
    {...props}
  >
    {children}
  </Button>
)

const Impact = (): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  return (
    <ImpactContainer style={{ boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.08)' }}>
      <View style={{ backgroundColor: '#FF866F', padding: '12px' }}>
        <H4 colour={colors.lightPink} weight="500" center>
          02
        </H4>
      </View>
      <H4 weight="500" center colour={colors.black} style={{ padding: '12px' }}>
        {t`kidsImpacted`}
      </H4>
    </ImpactContainer>
  )
}

const ImpactContainer = styled(View)`
  flex-direction: row;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
`

const TotalPaymentContainer = styled.View<object, EmotionTheme>`
  align-self: flex-start;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.grayLighter};
  border-radius: 8px;
  width: 100%;
`

const DonationSubtitle = styled(View)<object, EmotionTheme>`
  width: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isExtraLarge ? '55%' : '100%')};
`

const TemplateOverlay = styled.View`
  position: fixed;
  display: ${({ donating }: { donating: boolean }) => (donating ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
  cursor: wait;
`

export { BackAndTitle, ButtonBox, Impact, DonationSubtitle, TemplateOverlay, TotalPaymentContainer }
export { default as FeeCheckbox } from './feeCheckbox'
export { default as NFGForm } from './NFGForm'
export { default as GlobalGivingForm } from './GlobalGivingForm'
export { default as PayPalForm } from './PayPalForm'
export { default as PayPalRecurringForm } from './PayPalRecurringForm'
export { default as BetterplaceForm } from './BetterplaceForm'
export { default as GiveIndiaForm } from './GiveIndiaForm'
export { default as StripeForm } from './StripeForm'
export { default as ShareCheckbox } from './shareCheckbox'
export { default as Privacy } from './Privacy'
export { Sidebar } from './Sidebar'
export { default as DonationAmountOptions } from './DonationAmountOptions'
export { default as PaymentForm } from './PaymentForm'
export { default as PaymentBlock } from './PaymentBlock'
