import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import { initAction } from 'src/containers/screens/Organization/actions'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { Text, ScrollView, Image, LinkButton, Loading } from 'src/retired/elements'
import SafeAreaView from 'src/retired/elements/SafeAreaView'
import { Body2 } from 'src/retired/shared/Typography'
import { colors, icons } from 'src/theme'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import epics from 'src/containers/screens/Organization/epics'
import reducer from 'src/containers/screens/Organization/reducer'
import { useAffiliationsTableQuery } from 'src/generated/graphql'
import { PageTitle } from 'src/components'
import Platform from 'src/utils/Platform'

import { AffiliationScrollView, AffiliationHeader } from '../common'

const Table = styled.View`
  border: 1px ${colors.gray02} solid;
  border-radius: 15px;
  background-color: ${colors.white};
`

const TableHeader = styled.View`
  flex-direction: row;
  padding: 20px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px ${colors.gray02} solid;
  margin-bottom: 10px;
  align-items: center;
`

const TableRow = styled.View`
  border-radius: 32px;
  flex-direction: row;
  padding: 20px 15px;
  margin: 0 10px;
  align-items: center;
`

const Column = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0px 10px' : '0px 25px')};
  flex: 1;
`

const AffiliationsTable = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { metrics } = useDeedTheme()
  useInjectReducer({ key: 'affiliation', reducer })
  useInjectEpics({ key: 'affiliation', epics })

  const { organization: organizationId } = useParams<{
    organization: string
  }>()

  useEffect(() => {
    dispatch(initAction(organizationId))
  }, [organizationId])

  const { t } = useTranslation('affiliationsTable')

  const { data, loading } = useAffiliationsTableQuery()
  const nonprofit = useSelector((state) => selectOrganizationById(state, organizationId))

  const affiliations = (data?.affiliations ?? []).filter((affiliation) => affiliation.nonprofitId === organizationId)

  if (!loading && !affiliations.length) {
    return <Redirect to={`/organization/${organizationId}`} />
  }

  return (
    <SafeAreaView>
      <PageTitle title={t`yourAffiliation`} />

      {!loading ? (
        <AffiliationScrollView>
          <View>
            <AffiliationHeader title={t`yourAffiliation`} />
            <View
              style={{
                flexDirection: 'column',
                marginBottom: 20,
              }}
            >
              <Text size={18} center bold style={{ marginBottom: 20 }}>
                {t('affiliationsToNonprofit', { nonprofitName: nonprofit?.name })}
              </Text>
              <Text size={16} center>
                {t`affiliationsShow`}
              </Text>
            </View>
          </View>
          <Table style={{ marginBottom: 0 }}>
            <TableHeader style={{ backgroundColor: colors.gray03, marginBottom: 0 }}>
              <Column>
                <Text weight="500">{t`affiliation`}</Text>
              </Column>
              {!metrics.isSmall && (
                <>
                  <Column>
                    <Text weight="500">{t`boardPosition`}</Text>
                  </Column>

                  <Column>
                    <Text weight="500">{t`startDate`}</Text>
                  </Column>
                  <Column>
                    <Text weight="500">{t`endDate`}</Text>
                  </Column>
                </>
              )}
              <Column>
                <Text weight="500">{t`edit`}</Text>
              </Column>
              <Column>
                <Text weight="500">{t`remove`}</Text>
              </Column>
            </TableHeader>
            <ScrollView
              style={{
                maxHeight: 300,
                backgroundColor: colors.gray03,
                paddingVertical: 10,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
              }}
            >
              {affiliations?.map((affiliation, index) => (
                <TableRow
                  key={affiliation?.id}
                  style={{ backgroundColor: index % 2 !== 0 ? colors.gray03 : colors.white }}
                >
                  <Column>
                    <Text style={{ overflowWrap: 'normal' }}>
                      {t('affiliation', { context: affiliation.affiliationType })}
                    </Text>
                  </Column>
                  {!metrics.isSmall && (
                    <>
                      <Column>
                        <Text>{t('boardPositionType', { context: affiliation.boardPosition })}</Text>
                      </Column>

                      <Column>
                        <Text>
                          {t('date:dayMonthShort', {
                            date: new Date(affiliation.startingAt),
                          })}
                        </Text>
                      </Column>
                      <Column>
                        <Text>
                          {affiliation.endingAt
                            ? t('date:dayMonthShort', {
                                date: new Date(affiliation.endingAt),
                              })
                            : t`ongoing`}
                        </Text>
                      </Column>
                    </>
                  )}
                  <Column>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Link to={`/organization/${organizationId}/affiliation/edit/${affiliation.id}`}>
                        <Body2 style={{ marginRight: 10 }}>{t`edit`}</Body2>
                      </Link>
                      <Image source={icons.edit} style={{ width: 10, height: 10 }} />
                    </View>
                  </Column>
                  <Column>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Link to={`/organization/${organizationId}/affiliation/remove/${affiliation.id}`}>
                        <Body2 style={{ marginRight: 10 }}>{t`remove`}</Body2>
                      </Link>
                      <Image source={icons.cancel} style={{ width: 10, height: 10 }} />
                    </View>
                  </Column>
                </TableRow>
              ))}
            </ScrollView>
          </Table>
          <View
            style={{
              paddingVertical: 40,
              alignItems: 'center',
              paddingBottom: 0,
            }}
          >
            <LinkButton to={`/organization/${organizationId}/affiliation/new`} button fluid={Platform.OS !== 'web'}>
              {t`addNewAffiliation`}
            </LinkButton>
          </View>
        </AffiliationScrollView>
      ) : (
        <Loading />
      )}
    </SafeAreaView>
  )
}

export default AffiliationsTable
