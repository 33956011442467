import { DonationProvider } from '@joindeed/calculate-fees'

import Organization from 'src/entities/organization/model'
import Deed from 'src/entities/deed/model'
import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'

export const getNonprofits = ({
  nonprofit,
  deed,
}: {
  nonprofit: Organization | undefined
  deed: Deed | null | undefined
}): Organization[] => {
  if (nonprofit) {
    return [nonprofit]
  }
  if (deed) {
    return deed.organization ? [deed.organization] : deed.nonprofits.toArray()
  }
  return []
}

export const getNonprofitCountryCode = ({
  nonprofit,
  donationProvider,
}: {
  nonprofit: Organization | undefined
  donationProvider: DonationProvider | null
}) => {
  if (donationProvider === DonationProvider.Stripe && nonprofit?.stripe?.accountCountry) {
    return nonprofit.stripe.accountCountry
  }

  if (nonprofit?.externalId) {
    const matches = nonprofit?.externalId.match(/^ext-pp-([A-Z]{2})\d+$/)
    if (Array.isArray(matches) && matches[1]) {
      return matches[1]
    }
  }

  return nonprofit?.locationObject?.countryCode || 'US'
}

export const getBaseCurrency = ({
  nonprofit,
  deed,
}: {
  nonprofit: Organization | undefined
  deed: Deed | null | undefined
}): CurrencyCode => (nonprofit?.currencyCode as CurrencyCode) || deed?.currencyCode || 'USD'
