import i18n from 'i18next'

import { images } from 'src/theme'

export default [
  {
    image: images.landing1,
    backgroundPosition: '0% 40%',
    text: i18n.t('carousel:doSomethingGoodToday'),
  },
  {
    image: images.landing2,
    backgroundPosition: '0% 10%',
    text: i18n.t('carousel:choseFromAVariety'),
  },
  {
    image: images.landing3,
    backgroundPosition: '0% 30%',
    text: i18n.t('carousel:seeWhoElseIsGoing'),
  },
]
