import React from 'react'
import { Box, Button } from '@mui/material'
import { CheckCircleOutlineRounded } from '@mui/icons-material'

interface SaveButtonProps {
  onSave: () => void
}

export const SaveButton = ({ onSave }: SaveButtonProps) => (
  <Box>
    <Button variant="text" onClick={onSave} startIcon={<CheckCircleOutlineRounded />}></Button>
  </Box>
)
