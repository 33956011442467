import React from 'react'
import { subMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Platform } from 'src/utils'
import { CardCompactWrapper } from 'src/components'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { LinkButton, Spacing } from 'src/retired/elements'
import { getDonationColor } from 'src/utils/getDonationColor'
import { Label, Body2 } from 'src/retired/shared/Typography'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { PledgeChip } from 'src/containers/screens/Donation/PledgeChip'
import { MatchingChip } from 'src/containers/screens/Donation/MatchingChip'
import { RecurringChip } from 'src/containers/screens/Donation/RecurringChip'
import Donation, { DonationCommitmentStatus } from 'src/entities/donation/model'

interface DonationCompactProps {
  donation: Donation
}

const DonationCommitmentStatusSection = ({ donation }: { donation: Donation }): JSX.Element | null => {
  const { t } = useTranslation('donationCompact')

  const { matchedAmount } = donation
  const isCurrentlyPledgeOrWasPledged = donation.isAssociatedToPledgingCampaign() || donation.isCurrentlyPledged()
  const isCurrentlyRecurring = donation.isRecurring()

  const spacingStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '132px',
    height: '24px',
    position: 'relative',
    top: '-5px',
    left: '-5px',
    gap: '2px',
  }

  if (isCurrentlyPledgeOrWasPledged && matchedAmount) {
    return (
      <Spacing style={spacingStyle}>
        <MatchingChip text={t(`${DonationCommitmentStatus.Matched}`)}></MatchingChip>
        <PledgeChip text={t(`${DonationCommitmentStatus.Pledged}`)}></PledgeChip>
      </Spacing>
    )
  }

  if (matchedAmount) {
    return (
      <Spacing style={spacingStyle}>
        <MatchingChip text={t(`${DonationCommitmentStatus.Matched}`)}></MatchingChip>
      </Spacing>
    )
  }

  if (isCurrentlyPledgeOrWasPledged && isCurrentlyRecurring) {
    return (
      <Spacing style={spacingStyle}>
        <RecurringChip text={t(`${DonationCommitmentStatus.Recurring}`)}></RecurringChip>
        <PledgeChip text={t(`${DonationCommitmentStatus.Pledged}`)}></PledgeChip>
      </Spacing>
    )
  }

  if (isCurrentlyPledgeOrWasPledged) {
    return (
      <Spacing style={spacingStyle}>
        <PledgeChip text={t(`${DonationCommitmentStatus.Pledged}`)}></PledgeChip>
      </Spacing>
    )
  }

  if (isCurrentlyRecurring) {
    return (
      <Spacing style={spacingStyle}>
        <RecurringChip text={t(`${DonationCommitmentStatus.Recurring}`)}></RecurringChip>
      </Spacing>
    )
  }

  return <Spacing style={spacingStyle} />
}

const DateLabel = ({ donation, textColour }: { donation: Donation; textColour: string }): JSX.Element => {
  const { date } = donation
  const { t } = useTranslation('donationCompact')
  const oldDate = subMonths(new Date(), 11) > date

  let translationTextKey = 'date:dayMonthYear'
  if (oldDate) {
    translationTextKey = 'date:dayMonthYearShort'
  }

  return (
    <Label colour={textColour} marginBottom={8}>
      {t(translationTextKey, { date })}
    </Label>
  )
}

const DonationAmount = ({ donation }: { donation: Donation }): JSX.Element | null => {
  const { t } = useTranslation('donationCompact')
  const { colors } = useDeedTheme()
  const textColour = colors.black
  const { amount, amountCurrencies } = donation
  if (!!amount === false) {
    return null
  }

  return (
    <>
      <Label colour={textColour}>{t`amount`}:</Label>
      <Body2 weight="500" marginTop={2} marginBottom={8} colour={textColour}>
        <CurrencyFormat amountCurrencies={amountCurrencies} />
      </Body2>
    </>
  )
}

const DonationDeed = ({
  donation,
  textColour,
  numberOfLines,
}: {
  donation: Donation
  textColour: string
  numberOfLines: number
}): JSX.Element | null => {
  const { deed } = donation
  if (!deed) {
    return null
  }
  const { name: deedName } = deed

  return (
    <Body2 weight="500" colour={textColour} numberOfLines={numberOfLines}>
      {deedName}
    </Body2>
  )
}

const DonationNonprofit = ({
  donation,
  textColour,
  numberOfLines,
}: {
  donation: Donation
  textColour: string
  numberOfLines: number
}): JSX.Element | null => {
  const { nonprofits = [], paymentMethodDetails } = donation

  let nonprofitsText = nonprofits.map((nonprofit) => nonprofit.name)?.join(', ')

  if (!nonprofits) {
    if (paymentMethodDetails) {
      const { additionalData } = paymentMethodDetails

      if (additionalData) {
        const {
          nonprofit: { externalName },
        } = additionalData as unknown as { nonprofit: { externalName?: string } }

        if (externalName) {
          nonprofitsText = externalName
        }
      }
    }
  }

  return (
    <Label colour={textColour} numberOfLines={numberOfLines}>
      {nonprofitsText}
    </Label>
  )
}

export const DonationCompact = ({ donation }: DonationCompactProps): JSX.Element => {
  const { colors } = useDeedTheme()

  const isPlatformWeb = Platform.OS === 'web'
  let numberOfLines = 1
  if (isPlatformWeb) {
    numberOfLines = 2
  }

  const { id } = donation
  const toLink = `/donations/${id}`

  return (
    <LinkButton to={toLink} button={false}>
      <CardCompactWrapper cardColor={getDonationColor(donation, colors)}>
        <DonationCommitmentStatusSection donation={donation}></DonationCommitmentStatusSection>
        <DateLabel textColour={colors.grayMediumDark} donation={donation}></DateLabel>
        <DonationAmount donation={donation}></DonationAmount>
        <DonationDeed numberOfLines={numberOfLines} textColour={colors.black} donation={donation}></DonationDeed>
        <DonationNonprofit
          numberOfLines={numberOfLines}
          donation={donation}
          textColour={colors.black}
        ></DonationNonprofit>
      </CardCompactWrapper>
    </LinkButton>
  )
}

export default DonationCompact
