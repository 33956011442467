import React from 'react'
import { View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'

import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { Screen, ScrollView, Image, Spacing, Divider, ExternalLink, Button, Touchable } from 'src/retired/elements'
import { PageTitle } from 'src/components'
import { Body1, H5 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import Organization from 'src/entities/organization/model'
import { styled } from 'src/theme/styled'
import { useHistory } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import Chip from 'src/retired/shared/Chip'
import { formatNumber } from 'src/utils/format'

const ContainerStyled = styled(View)(({ theme: { metrics } }: { theme: EmotionTheme }) => ({
  marginBottom: 80,
  marginTop: 28,
  marginHorizontal: metrics.isSmall ? 20 : 96,
}))

export const OffPlatformDonationView = ({ organization }: { organization: Organization }) => {
  const { t } = useTranslation('offPlatformDonation')

  const { colors, metrics } = useDeedTheme()

  const history = useHistory()
  const goBack = makeGoBack(history)

  return (
    <Screen fixed>
      <PageTitle title={t`logOffPlatformDonation:logOffPlatformDonation`} />
      <ScrollView>
        <View
          style={{
            backgroundColor: colors.brandColor,
            padding: 32,
            height: 150,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <View style={{ position: 'absolute', top: 37, left: 14 }}>
            <Touchable
              onPress={goBack}
              style={{
                width: 40,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon icon="arrowLeftNew" color="white" width={24} height={24} />
            </Touchable>
          </View>

          <H5 center colour={colors.white} weight="500">
            {t('donateToSupport', { organizationName: organization.name })}
          </H5>
        </View>

        <ContainerStyled>
          <View>
            {organization.mainPicture ? (
              <>
                <Image
                  source={{ uri: organization.mainPicture }}
                  style={{ width: '100%', height: 70, resizeMode: 'contain' }}
                />

                <Spacing marginBottom={15} />
              </>
            ) : null}

            {organization.canDonate() &&
              !!organization.matchingPercentage &&
              organization.matchingMaximumCurrencies &&
              organization.matchingMaximumCurrencies[organization.currencyCode] > 0 && (
                <View style={{ marginTop: 10, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>
                  <Chip
                    type="square"
                    backgroundColor={colors.yellow}
                    textColor="black"
                    textStyle={{ textTransform: 'uppercase' }}
                  >
                    {t('logOffPlatformDonation:timesMatched', {
                      matchPercentage: formatNumber(organization.matchingPercentage / 100),
                    })}
                  </Chip>
                </View>
              )}

            <Spacing marginBottom={5}>
              <Body1 center>{t`dueToCertainRegulatoryRestrictionsInYourRegion`}</Body1>
            </Spacing>

            <Divider />

            {organization.website && (
              <View style={{ width: '100%', alignItems: 'center' }}>
                <H5 weight="500" marginBottom={25} center>
                  {t`clickTheLinkBelowToVisitThisOrganizationDirectly`}
                </H5>

                <ExternalLink
                  href={organization.website}
                  color={colors.white}
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <Button color="primary" size={metrics.isSmall ? 'small' : 'default'} style={{ width: '80%' }}>
                    {t`goToOrganizationWebsite`}
                  </Button>
                </ExternalLink>
              </View>
            )}

            <Body1 marginTop={40} center>
              <Trans
                t={t}
                i18nKey="onceYouHaveMadeYourDonation"
                components={{
                  RequestDonationMatchLink: (
                    <Body1 weight="500" underline onPress={() => history.push(`/off-platform/${organization.id}`)}>
                      \1
                    </Body1>
                  ),
                }}
              />
            </Body1>
          </View>
        </ContainerStyled>
      </ScrollView>
    </Screen>
  )
}
