import { Map } from 'immutable'

import Api from '../api'

import { setAction } from './actions'

export default class SettingsApi {
  static fetch() {
    return Api.get('api/settings').map((settings: any) => setAction(Map(settings)))
  }
}
