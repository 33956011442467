import { fromJS } from 'immutable'

import { INIT } from './constants'

export const initialState = fromJS({ deedId: null })

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.set('deedId', action.id)

    default:
      return state
  }
}
