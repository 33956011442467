/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { useSelector } from 'react-redux'
import { Activity, CommentList, LoadMorePaginator } from 'react-activity-feed'
import type { ActivityProps } from 'react-activity-feed'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { Box } from 'src/components/Box/Box'

import { ActivityHeader } from './ActivityHeader'
import { CommentItem } from './CommentItem'
import { CommentField } from './CommentField'
import { LoadMoreButton } from './LoadMoreButton'
import { LikeButton } from './LikeButton'
import { CommentCount } from './CommentCount'

export const FeedActivity = (props: ActivityProps): JSX.Element => {
  const user = useSelector(selectCurrentUser)

  return (
    <Activity
      {...props}
      Header={ActivityHeader}
      Footer={() => (
        <Box className="raf-activity-footer-wrapper" sx={{ mb: 0, p: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LikeButton {...props} />
            <CommentCount activity={props.activity} />
          </Box>
          <CommentField activity={props.activity} image={user?.mainPicture} />
          <CommentList
            activityId={props.activity.id}
            Paginator={(paginatorProps): JSX.Element => (
              <LoadMorePaginator {...paginatorProps} LoadMoreButton={LoadMoreButton} />
            )}
            CommentItem={({ comment }) => (
              <Box sx={{ mt: 3 }} className="raf-comment-item-wrapper">
                <CommentItem comment={comment} />
              </Box>
            )}
          />
        </Box>
      )}
    />
  )
}
