import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import SafeAreaView from 'src/retired/elements/SafeAreaView'
import StatusBar from 'src/retired/blocks/StatusBar'

import ActivityIndicatorOverlay from './ActivityIndicatorOverlay'

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    flex: 1,
    backgroundColor: '#fff',
  },
  fixed: {
    height: '100%',
  },
  center: {
    alignItems: 'center',
  },
  middle: {
    justifyContent: 'center',
  },
  padding: {
    padding: 25,
  },
  paddingHorizontal: {
    paddingHorizontal: 25,
  },
  header: {
    paddingTop: 0,
    paddingBottom: 25 + 30,
  },
  action: {
    paddingBottom: 25 + 48 + 25,
  },
  narrow: {
    maxWidth: 425,
    width: '100%',
    alignSelf: 'center',
  },
})

const Screen = ({
  children,
  fixed,
  middle,
  center,
  padding,
  paddingHorizontal,
  header,
  action,
  narrow,
  style,
  loading = false,
  safeArea = true,
  ...props
}) => (
  <View
    {...props}
    style={[
      styles.root,
      fixed && styles.fixed,
      middle && styles.middle,
      center && styles.center,
      padding && styles.padding,
      paddingHorizontal && styles.paddingHorizontal,
      padding && header && styles.header,
      action && styles.action,
      narrow && styles.narrow,
      style,
    ]}
  >
    <StatusBar />
    {loading && <ActivityIndicatorOverlay />}
    {safeArea ? <SafeAreaView>{children}</SafeAreaView> : children}
  </View>
)

Screen.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  fixed: PropTypes.bool,
  middle: PropTypes.bool,
  center: PropTypes.bool,
  padding: PropTypes.bool,
  paddingHorizontal: PropTypes.bool,
  header: PropTypes.bool,
  action: PropTypes.bool,
  narrow: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  loading: PropTypes.bool,
}
export default Screen
