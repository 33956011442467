import React from 'react'
import { View } from 'react-primitives'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Button, ScrollView } from 'src/retired/elements'
import { OutlineButton } from 'src/retired/elements/Button/OutlineButton'
import Icon from 'src/retired/shared/Icon'

const getImageByType = (type: ActionModalType) => {
  switch (type) {
    case ActionModalType.JoinSuccess:
      return 'personStar'

    case ActionModalType.ConfirmLeave: {
      return 'personThinking'
    }

    case ActionModalType.ConfirmCompletion: {
      return 'personBox'
    }

    default: {
      return 'personFistUp'
    }
  }
}

export enum ActionModalType {
  JoinSuccess = 'JoinSuccess',
  CompleteSuccess = 'CompleteSuccess',
  ConfirmLeave = 'ConfirmLeave',
  ConfirmCompletion = 'ConfirmCompletion',
}

interface ButtonData {
  text: string
  onPress: () => void
}

export const ActionModal = ({
  title,
  subTitle,
  type,
  visible,
  onClose,
  primaryButtonData,
  secondaryButtonData,
  buttonsDisabled = false,
  customVisual,
}: {
  title: string
  subTitle?: string
  type: ActionModalType
  visible: boolean
  onClose: () => void
  primaryButtonData: ButtonData
  secondaryButtonData?: ButtonData
  buttonsDisabled?: boolean
  customVisual?: JSX.Element
}) => {
  const { colors, metrics } = useDeedTheme()

  const imageName = getImageByType(type)

  return (
    <ModalWithCloseButton visible={visible} onClose={onClose} title={title}>
      <ScrollView>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: metrics.isLarge ? 475 : '100%',
            marginVertical: 24,
            paddingTop: metrics.isLarge ? 24 : 12,
            paddingBottom: metrics.isLarge ? 30 : 15,
            borderWidth: 1,
            borderColor: colors.gray02,
            borderLeftWidth: 0,
            borderRightWidth: 0,
          }}
        >
          <View
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {customVisual ? <>{customVisual}</> : <Icon icon={imageName} />}
          </View>
          {subTitle ? (
            <Body1 colour={colors.gray05} style={{ marginTop: 24, textAlign: 'center' }}>
              {subTitle}
            </Body1>
          ) : null}
        </View>
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: metrics.isLarge ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!!secondaryButtonData && (
            <View
              style={{
                paddingRight: metrics.isLarge ? 12 : 0,
                marginBottom: metrics.isLarge ? 0 : 12,
                width: metrics.isLarge ? 'auto' : '100%',
              }}
            >
              <OutlineButton onPress={secondaryButtonData.onPress} disabled={buttonsDisabled}>
                <Body2 style={{ fontWeight: '600' }}>{secondaryButtonData.text}</Body2>
              </OutlineButton>
            </View>
          )}
          <Button
            style={{
              backgroundColor: colors.brandColor,
              boxShadow: 'none',
              shadowOpacity: 0,
              width: metrics.isLarge ? 'fit-content' : '100%',
              paddingLeft: 22,
              paddingRight: 22,
            }}
            onPress={primaryButtonData.onPress}
            disabled={buttonsDisabled}
          >
            <Body2 style={{ fontWeight: '600', color: colors.white }}>{primaryButtonData.text}</Body2>
          </Button>
        </View>
      </ScrollView>
    </ModalWithCloseButton>
  )
}
