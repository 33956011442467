import Api from '../api'

import { addAction } from './actions'
// import { Form } from './model'

export default class FormApi {
  public static fetch(id: string, volunteerTimeId?: string) {
    return Api.get(`api/forms/${id}${volunteerTimeId ? `?volunteerTimeId=${volunteerTimeId}` : ''}`).map((form) =>
      addAction(form)
    )
    // TODO: convert to immutable
    // return Api.get(`api/forms/${id}`).map(form => addAction(new Form(form)))
  }

  public static submit(formResponse: any) {
    return Api.post('api/forms/responses/', formResponse)
  }
}
