import React, { Fragment } from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import Icon from 'src/retired/shared/Icon'
import Touchable from 'src/retired/shared/Touchable'

import { IconData } from '../DeedCard/helper'

import { getDimensions } from './common/helpers'
import { Wrapper } from './common/Wrapper'
import { Subtext } from './common/Subtext'

interface ImageCardProps {
  link?: string
  text?: string
  forceLarge?: boolean
  small?: boolean
  onPress?: () => void
  isResizable?: boolean
  iconData: IconData
}
export type ImageCardElement = React.ReactElement<ImageCardProps>

const ImageCard: React.FC<ImageCardProps> = ({
  link,
  text,
  small = false,
  forceLarge = false,
  onPress,
  isResizable,
  iconData,
}): ImageCardElement => {
  const Component = onPress ? Touchable : link ? Link : Fragment
  const ComponentProps = onPress ? { onPress } : link ? { to: link } : {}
  const { metrics } = useDeedTheme()

  const large = forceLarge || metrics.isLarge
  const dimensions = getDimensions(small, large)
  const customProperties = isResizable ? { width: '100%' } : {}

  return (
    <Component {...ComponentProps}>
      <Wrapper narrow={!metrics.isLarge || small}>
        <View
          style={{
            ...dimensions,
            ...customProperties,
            borderRadius: 8,
          }}
        >
          <View
            style={{
              borderRadius: 12,
              width: '100%',
              height: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: iconData.hexCode,
            }}
          >
            <Icon width={60} icon={iconData.iconName} />
          </View>
        </View>
        {!!text && <Subtext width={dimensions.width} text={text} />}
      </Wrapper>
    </Component>
  )
}

export default ImageCard
