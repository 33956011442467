import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import type { Reducer } from 'redux'
import { ReactReduxContext } from 'react-redux'

import getInjectors from './reducerInjectors'

interface Arguments {
  key: string
  reducer: Reducer
}

export default <T extends object>({ key, reducer }: Arguments) =>
  (WrappedComponent: React.ComponentType<T>) => {
    class ReducerInjector extends React.Component {
      static WrappedComponent = WrappedComponent

      static contextType = ReactReduxContext

      static displayName = `withReducer(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

      constructor(props: T, context: any) {
        super(props, context)

        getInjectors(context.store).injectReducer(key, reducer)
      }

      render() {
        return <WrappedComponent {...(this.props as T)} />
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent)
  }

const useInjectReducer = ({ key, reducer }: Arguments): void => {
  const context = React.useContext(ReactReduxContext)
  React.useEffect(() => {
    getInjectors(context.store).injectReducer(key, reducer)
  }, [context.store, key, reducer])
}

export { useInjectReducer }
