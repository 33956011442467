/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import type { Reducer } from 'redux'
import type { History } from 'history'
import { Map } from 'immutable'
import type { RouterState } from 'connected-react-router'

import { entitiesReducer, EntityState } from 'src/entities/reducer'
import localSettingsReducer, { LocalSettingsState } from 'src/localSettings/reducer'
import sentryReducer from 'src/sentry/reducer'
import cookieConsentReducer from 'src/containers/modules/CookieConsent/reducer'
import type { TypedMap } from 'src/utils/typed-map'
import type { CookieConsentState } from 'src/containers/modules/CookieConsent/reducer'
import type { DeedState } from 'src/containers/screens/Deed/reducer'
import type { DeedCancelState } from 'src/containers/modules/DeedCancel/reducer'
import type { DonateState } from 'src/containers/screens/Donate/reducer'
import type { NonprofitsSearchState } from 'src/containers/modules/NonprofitsSearch/reducer'
import type { LogOffPlatformDonationState } from 'src/containers/screens/LogOffPlatformDonation/reducer'
import type { MailFormState } from 'src/containers/screens/MailForm/reducer'
import type { CampaignScreenState } from 'src/containers/screens/Campaign/reducer'
import type { DonationCompleteState } from 'src/containers/screens/DonationComplete/reducer'
import type { DonationState } from 'src/containers/screens/Donation/reducer'
import type { ProfileSettingsState } from 'src/containers/screens/Profile/Settings/reducer'
import { CommunityScreenState } from 'src/containers/screens/Community/reducer'
import { DonationScheduleDetailsState } from 'src/containers/screens/DonationScheduleDetails/reducer'
import { FeedState } from 'src/containers/screens/Feed/selectors'

import { CauseState } from './containers/screens/Cause/reducer'

export interface MutableState {
  router: RouterState
  entities: EntityState
  sentry: Map<string, unknown>
  localSettings: LocalSettingsState
  cookieConsent: CookieConsentState
}

export interface InjectedState {
  campaign?: CampaignScreenState
  cause?: CauseState
  deed?: DeedState
  deedCancel?: DeedCancelState
  donate?: DonateState
  donation?: DonationState
  donationComplete?: DonationCompleteState
  donationSchedule?: DonationScheduleDetailsState
  logOffPlatformDonation?: LogOffPlatformDonationState
  mailForm?: MailFormState
  nonprofitsSearch?: NonprofitsSearchState
  profileSettings?: ProfileSettingsState
  community?: CommunityScreenState
  feed?: FeedState
}

export type State = TypedMap<MutableState & InjectedState>

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default (history: History, injectedReducers = {}): Reducer<MutableState> =>
  combineReducers({
    router: connectRouter(history),
    entities: entitiesReducer,
    sentry: sentryReducer,
    localSettings: localSettingsReducer,
    cookieConsent: cookieConsentReducer,
    ...injectedReducers,
  })
