import React from 'react'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import CardList from 'src/retired/shared/CardList'
import ScrollViewCard from 'src/retired/shared/ScrollViewCard'
import OrganizationCard from 'src/retired/shared/OrganizationCard'
import Organization from 'src/entities/organization/model'

interface OrganizationCardListProps {
  organizations: Organization[]
  scrollViewOffset: number
  hideLocation?: boolean
}
export type OrganizationCardListElement = React.ReactElement<OrganizationCardListProps>

const OrganizationCardList: React.FC<OrganizationCardListProps> = ({
  organizations,
  scrollViewOffset,
  hideLocation,
}): OrganizationCardListElement => {
  const { metrics } = useDeedTheme()

  return (
    <CardList scrollViewOffset={scrollViewOffset}>
      {organizations.map((organization: Organization, index: number) => (
        <ScrollViewCard key={organization.get('id')}>
          <OrganizationCard
            organization={organization}
            marginRight={index < organizations.length - 1}
            size={metrics.isSmall ? 'variable' : 'standard'}
            cardStyle={{ marginLeft: metrics.isSmall ? 8 : 0, marginRight: metrics.isSmall ? 8 : 0 }}
            hideLocation={hideLocation}
          />
        </ScrollViewCard>
      ))}
    </CardList>
  )
}

export default OrganizationCardList
