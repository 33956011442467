import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-primitives'

import TextInput from 'src/retired/elements/TextInput'
import TextInputMask from 'src/retired/elements/TextInputMask'
import { colors } from 'src/theme'

const styles = StyleSheet.create({
  textField: {
    flexGrow: 1,
    minHeight: 30,
    fontFamily: 'GTWalsheimLC',
    fontSize: 12,
    color: colors.darkGray,
  },
  textArea: {
    flexGrow: 1,
    fontFamily: 'GTWalsheimLC',
    fontSize: 12,
    color: colors.darkGray,
    height: 56,
  },
  disabled: {
    backgroundColor: '#f5f5f5',
    color: 'rgba(0, 0, 0, .25)',
    cursor: 'not-allowed',
  },
})

class TextField extends PureComponent {
  handleChange = async (value, type) => {
    const isNumeric = /^[0-9]+$/.test(value.trim())

    if (type === 'number' && !isNumeric && value !== '') {
      return
    }
    await this.props.onChangeText(this.props.name, value)
    if (!this.props.onBlur && this.props.onTouched) {
      this.props.onTouched(this.props.name, true)
    }
  }

  onBlur = () => {
    if (this.props.onTouched) {
      this.props.onTouched(this.props.name, true)
    }
  }

  render() {
    const { mask, onChangeText, onTouched, onBlur, style, maxLength, ...props } = this.props
    const TextInputComponent = mask ? TextInputMask : TextInput
    return (
      <TextInputComponent
        onChangeText={(value) => this.handleChange(value, props.type)}
        onBlur={this.onBlur}
        style={[props.multiline ? styles.textArea : styles.textField, props.disabled && styles.disabled, style]}
        autoCorrect={false}
        mask={mask}
        maxLength={mask ? mask.length : maxLength}
        placeholderTextColor={colors.grayMediumDark}
        {...props}
      />
    )
  }
}
TextField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  onChangeText: PropTypes.func,
  onTouched: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.any,
  maxLength: PropTypes.number,
}
export default TextField
