import {
  INIT,
  TOGGLE_SELECTED_DAYS_OR_TIME,
  SET_SELECTED_DAYS_OR_TIME,
  SET_SELECTED_DATE,
  TOGGLE_SELECTED_LOCATION,
  SET_SELECTED_LOCATION,
  SET_SELECTED_LOCATION_CODE,
  TOGGLE_SELECTED_CAUSE,
  SET_SELECTED_CAUSES,
  TOGGLE_SELECTED_SKILL,
  SET_SELECTED_SKILLS,
  TOGGLE_CORPORATE_ONLY,
  SET_SEARCH_TERM,
  RESET,
} from './constants'

export const initAction = (corporateOnly) => ({
  type: INIT,
  corporateOnly,
})

export const toggleSelectedDaysOrTimeAction = (daysOrTime) => ({
  type: TOGGLE_SELECTED_DAYS_OR_TIME,
  daysOrTime,
})

export const setSelectedDaysOrTime = (daysOrTime) => ({
  type: SET_SELECTED_DAYS_OR_TIME,
  daysOrTime,
})

export const setSelectedDateAction = (date) => ({
  type: SET_SELECTED_DATE,
  date,
})

export const toggleSelectedLocationAction = (location) => ({
  type: TOGGLE_SELECTED_LOCATION,
  location,
})

export const setSelectedLocationsAction = (locations) => ({
  type: SET_SELECTED_LOCATION,
  locations,
})

export const setSelectedLocationCodeAction = (locationCode) => ({
  type: SET_SELECTED_LOCATION_CODE,
  locationCode,
})

export const toggleSelectedCauseAction = (cause) => ({
  type: TOGGLE_SELECTED_CAUSE,
  cause,
})

export const setSelectedCausesAction = (causes) => ({
  type: SET_SELECTED_CAUSES,
  causes,
})

export const toggleSelectedSkillAction = (skill) => ({
  type: TOGGLE_SELECTED_SKILL,
  skill,
})

export const setSelectedSkillsAction = (skills) => ({
  type: SET_SELECTED_SKILLS,
  skills,
})

export const toggleCorporateOnlyAction = (state) => ({
  type: TOGGLE_CORPORATE_ONLY,
  state,
})

export const setSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM,
  searchTerm,
})

export const resetAction = () => ({
  type: RESET,
})
