import React from 'react'
import { View } from 'react-primitives'

import { RawCard } from 'src/components/RawCard/RawCard'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const CampaignStatsCard = ({
  primaryElement,
  secondaryElement,
}: {
  primaryElement: React.ReactNode
  secondaryElement: React.ReactNode
}) => {
  const { metrics } = useDeedTheme()

  return (
    <RawCard
      style={{
        borderRadius: 40,
        width: metrics.isLarge ? 300 : '100%',
        height: metrics.isLarge ? 170 : 100,
        marginRight: metrics.isLarge ? 12 : 0,
        marginBottom: metrics.isLarge ? 0 : 12,
        paddingLeft: metrics.isLarge ? 40 : 28,
        paddingRight: metrics.isLarge ? 40 : 28,
        paddingTop: metrics.isLarge ? 40 : 20,
        paddingBottom: metrics.isLarge ? 40 : 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: metrics.isLarge ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: metrics.isLarge ? 'center' : 'space-between',
          width: '100%',
        }}
      >
        <View style={{ paddingBottom: metrics.isLarge ? 12 : 0 }}>{primaryElement}</View>
        <View style={{ maxWidth: 150 }}>{secondaryElement}</View>
      </View>
    </RawCard>
  )
}
