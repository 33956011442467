/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { View } from 'react-primitives'
import Dropdown from 'antd/lib/dropdown'
import Menu from 'antd/lib/menu'

import { useGoogleMapsApi } from 'src/utils/loadGoogleMapsApi'
import { TextField } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'

interface LocationAutocompleteFieldProps {
  value: string
  placeholder: string
  disabled?: boolean
  fieldStyle?: React.CSSProperties
  onSelect: (address: string) => void
  onTouched: () => void
}

const LocationAutocompleteField = ({
  fieldStyle,
  value,
  disabled = false,
  placeholder,
  onSelect,
  onTouched,
}: LocationAutocompleteFieldProps): JSX.Element | null => {
  const [location, setLocation] = useState(value || '')
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const googleMapsApiLoaded = useGoogleMapsApi()

  useEffect(() => {
    setLocation(value)
  }, [value])

  if (!googleMapsApiLoaded) {
    return null
  }

  return (
    <View style={{ position: 'relative', zIndex: 100 }}>
      <PlacesAutocomplete
        value={location}
        onChange={(newValue) => {
          setLocation(newValue)
          if (newValue === '') {
            onSelect(newValue)
          }
        }}
        onSelect={(newLocation) => {
          setLocation(newLocation)
          onSelect(newLocation)
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Dropdown
            overlayStyle={{
              zIndex: 30,
              backgroundColor: 'white',
              minWidth: 'auto',
              borderRadius: 20,
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px',
            }}
            disabled={disabled}
            trigger={['click']}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement!}
            onVisibleChange={(visible) => setDropdownVisible(visible)}
            overlay={() => (
              <Menu style={{ listStyle: 'none', paddingLeft: 0 }}>
                {dropdownVisible &&
                  suggestions.map((suggestion, i) => (
                    <Menu.Item
                      {...getSuggestionItemProps(suggestion)}
                      key={i}
                      style={{
                        borderBottom: i !== suggestions.length - 1 ? '1px solid #ebeef0' : '',
                        padding: 10,
                        cursor: 'pointer',
                      }}
                    >
                      <Text fontSize={12}>{suggestion.description}</Text>
                    </Menu.Item>
                  ))}
              </Menu>
            )}
          >
            <TextField
              {...getInputProps({
                placeholder,
                style: fieldStyle,
                disabled,
              })}
              onChangeText={() => null}
              onTouched={onTouched}
            />
          </Dropdown>
        )}
      </PlacesAutocomplete>
    </View>
  )
}

export default LocationAutocompleteField
