import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H5, Body2 } from 'src/retired/shared/Typography'
import { ActivityIndicator, ScrollView, Spacing } from 'src/retired/elements'
import GivingPledge from 'src/entities/donationSchedule/GivingPledge'
import { DonationSchedulesQueryResult, useDonationSchedulesQuery } from 'src/generated/graphql'
import { Section, SectionContent, SectionTitle, styles } from 'src/containers/screens/Profile/Profile.styled'

import GivingPledgeCompact from '../Compact'

const GIVING_PLEDGE_SECTION = 'pledges'

const ErrorLoadingPledges = () => {
  const { colors } = useDeedTheme()

  const { t } = useTranslation('profileScreen')

  const emptyMessage = t('errorLoadingPledges')

  return (
    <span
      style={{
        display: 'flex',
        flexFlow: 'row',
        flexGrow: 1,
        alignItems: 'stretch',
        minHeight: '140px',
        flexWrap: 'nowrap',
        justifyContent: 'start',
      }}
    >
      <Spacing marginTop={16} marginBottom={20} style={{ width: '100%' }}>
        <Body2 colour={colors.grayMediumDark}>{emptyMessage}</Body2>
      </Spacing>
    </span>
  )
}

const PledgesLoading = () => (
  <span
    style={{
      display: 'flex',
      flexFlow: 'row',
      flexGrow: 1,
      alignItems: 'stretch',
      minHeight: '140px',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
    }}
  >
    <ActivityIndicator />
  </span>
)

const PledgesEmpty = (): JSX.Element => {
  const { colors } = useDeedTheme()

  const { t } = useTranslation('profileScreen')

  const emptyMessage = t('youHaveNoPledges')

  return (
    <span
      style={{
        display: 'flex',
        flexFlow: 'row',
        flexGrow: 1,
        alignItems: 'stretch',
        minHeight: '70px',
        flexWrap: 'nowrap',
        justifyContent: 'start',
      }}
    >
      <Body2 colour={colors.grayMediumDark}>{emptyMessage}</Body2>
    </span>
  )
}

const PledgesView = ({
  pledges,
  showsHorizontalScrollbar,
}: {
  pledges: DonationSchedulesQueryResult['data']
  showsHorizontalScrollbar: boolean
}) => {
  const { donationSchedules } = pledges || { donationSchedules: [] }

  if (!donationSchedules.length) {
    return <PledgesEmpty></PledgesEmpty>
  }

  return (
    <ScrollView
      style={styles.scrollView}
      contentContainerStyle={styles.scrollViewContainer}
      showsHorizontalScrollIndicator={showsHorizontalScrollbar}
      horizontal
    >
      {pledges?.donationSchedules.map((pledge) => {
        const givingPledge = new GivingPledge(pledge as unknown as Partial<GivingPledge>)

        const { id } = givingPledge

        return <GivingPledgeCompact key={id} pledge={givingPledge}></GivingPledgeCompact>
      })}
    </ScrollView>
  )
}

export const PledgesSection = ({ showsHorizontalScrollbar }: { showsHorizontalScrollbar: boolean }) => {
  const { t } = useTranslation('profileScreen')

  const {
    data,
    loading: pledgesLoading,
    error: errorLoadingPledges,
  } = useDonationSchedulesQuery({
    variables: {
      where: { Campaign: { is: { isPledge: { equals: true } } } },
    },
  })

  return (
    <Section>
      <SectionTitle>
        <H5>{t(GIVING_PLEDGE_SECTION)}</H5>
      </SectionTitle>
      <SectionContent>
        {errorLoadingPledges && <ErrorLoadingPledges></ErrorLoadingPledges>}

        {pledgesLoading && <PledgesLoading></PledgesLoading>}

        {!pledgesLoading && !errorLoadingPledges && (
          <PledgesView showsHorizontalScrollbar={showsHorizontalScrollbar} pledges={data}></PledgesView>
        )}
      </SectionContent>
    </Section>
  )
}
