import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import { colors, images } from 'src/theme'
import { Column, Image, Row } from 'src/retired/elements'

import { CommunityContext } from './CommunityFeed'

export const FeedPlaceholder = (): JSX.Element => {
  const { metrics } = useDeedTheme()
  const { t } = useTranslation('communityFeed')
  const { readOnly } = useContext(CommunityContext)
  return (
    <div style={{ position: 'relative', marginTop: readOnly ? -10 : 36, marginBottom: 15 }}>
      <div
        style={{
          position: 'absolute',
          [readOnly ? 'right' : 'left']: 50,
          top: -7.6,
          width: 16,
          height: 16,
          backgroundColor: colors.blue,
          transform: 'rotate(45deg)',
          borderTop: '1px solid black',
          borderLeft: '1px solid black',
          borderTopLeftRadius: 4,
          zIndex: 1,
        }}
      />
      <Row
        style={{
          border: '1px solid black',
          borderRadius: 4,
          backgroundColor: colors.blue,
          color: colors.white,
          flexWrap: 'wrap',
        }}
      >
        <Column
          cols={metrics.isSmall ? 12 : 6}
          style={{ padding: 36, ...(metrics.isSmall ? { paddingBottom: 0 } : { paddingRight: 18 }) }}
        >
          <Image
            source={images.communitiesPlaceholder}
            style={{ width: metrics.isSmall ? 150 : '100%', paddingBottom: metrics.isSmall ? 123 : '82%' }}
          />
        </Column>
        <Column
          cols={metrics.isSmall ? 12 : 6}
          style={{ padding: 36, ...(metrics.isSmall ? { paddingTop: 18 } : { paddingLeft: 18 }) }}
        >
          <H3 colour="white" marginBottom={18}>
            {t('placeholderTitle', readOnly ? { context: 'guest' } : {})}
          </H3>
          <Body1 colour="white" lineHeight={1.4 * 16}>
            {t('placeholderBody', readOnly ? { context: 'guest' } : {})}
          </Body1>
        </Column>
      </Row>
    </div>
  )
}
