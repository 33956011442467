import React from 'react'
import Svg from 'react-inlinesvg'
import type { Props as IProps } from 'react-inlinesvg'

import { colors, icons, categoryIcons } from 'src/theme'

import replaceFillColor from './replaceFillColor'

export interface IconProps extends IProps {
  icon: string
  width: number | string
  height?: number
  color?: string
  hexColor?: string
}

const Icon = ({ icon, width, height, color, hexColor }: IconProps) => {
  const src = icons[icon] || categoryIcons[icon]
  const fillColor = hexColor || (color && colors[color])

  return (
    <Svg
      src={src}
      style={{ width, height: height || width }}
      key={`${src}${width}${height}${fillColor}`}
      preProcessor={fillColor ? replaceFillColor(fillColor) : undefined}
    />
  )
}
export default Icon
