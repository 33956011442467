// this file is manually transpiled, so it'd be possible to use it from webpack.config.js
Object.defineProperty(exports, '__esModule', {
  value: true,
})

// https://www.figma.com/file/PUorYyAQHSiZ5Swp9DmR6z/Basic-styleguides-for-Devs-(Design-System-v1)?node-id=412%3A4112

const _default = {
  // Updated colors as part of redesign process

  black: '#000000',
  white: '#FFFFFF',

  // Primary
  primary: '#5128AA',
  primaryDark: '#452291',
  primaryLight: '#D3C9EA',
  primaryLighter: '#EEE9F6',

  // Yellow
  yellow: '#FFBF00',
  yellowDark: '#D9A200',
  yellowLight: '#FFEFBF',
  yellowLighter: '#FFF9E5',

  // Red
  red: '#FF5D36',
  redDark: '#B84327',
  redLight: '#FFD6CD',
  redLighter: '#FFEFEB',
  redMedium: '#EB4C60',
  redStrong: '#D32F2F',

  // Green
  green: '#20A771',
  greenDark: '#1B5E20',
  greenLight: '#C7E9DB',
  greenLighter: '#EAF2EA',

  // Blue
  blue: '#2970FF',
  blueDark: '#235FD9',
  blueLight: '#C9DBFF',
  blueLighter: '#EAF1FF',

  // Gray
  gray: '#8C8C8C',
  // Use grayMediumDark for gray texts on white background.
  // If the text has a more light gray it doesn't apply with accessibility guidelines
  grayMediumDark: '#666666',
  grayDark: '#262626',
  grayLight: '#BFBFBF',
  grayLighter: '#D9D9D9',
  grayMedium: '#979797',
  grayMild: '#E9E5F4',

  // Some weird image overlay color
  imageOverlay: '#1E1F20',

  // Legacy Old colors
  primaryBlue: '#4F55E2',
  grayText: '#6F747E',
  borderGray: '#DFDFDF',
  lightBorderGray: '#EAEAEA',
  lightGray: '#ADADC3',
  gold: '#E89134',
  gray01: '#B6BDBD',
  mediumGray: '#E7E7E9',
  gray02: '#EBEEF0',
  gray03: '#FAFAFC',
  gray04: '#e6e8e8',
  gray05: '#7e8383',
  gray06: '#f4f4f6' /** NOTICE only used once */,
  gray08: '#313e47',
  darkGray: '#282830',
  lightGray02: '#f4f4f4',
  blue02: '#57BEFF',
  blue03: '#767BFF',
  blue04: '#d1e2f5' /** NOTICE only used once */,
  transparentBlue: '#6369FF66',
  lightBlue: '#A9C9FF',
  lightBlue02: '#8589FF',
  darkBlue: '#484DD8',
  pink: '#F25F5C',
  pink03: '#FFEEE7',
  lightYellow: '#FFE168',
  lightPink: '#FFF3EE',
  darkPink: '#F08C75',
  purple: '#7815f5',
  mediumPurple: '#BDA9FF',
  purple2: '#5128AA',
  lightPurple: '#e9e9fe',
  pink02: '#CC94EC',
  teal: '#0F6D8A',
  lightTeal: '#b7d3db',
  custard: '#FCEB6A',
  coral: '#F25F5C',
  brandColor: '#6369FF',
  mintGreen: '#a3fabb',
  mintBlue: '#aef6f9',
  statusSuccess: '#E8FFF3',
  statusError: '#FFE9E9',
  statusNeutral: '#FFF2DC',
  statusTextSuccess: '#23A461',
  statusTextError: '#EC6545',
  statusTextNeutral: '#DD941B',
  darkViolet: '#3B3679',
  widgetTheme: '#442d87',
}
exports.default = _default
