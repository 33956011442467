import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import Touchable from 'src/retired/elements/Touchable'
import { colors } from 'src/theme'

const styles = StyleSheet.create({
  switch: {
    width: 30,
    height: 18,
    justifyContent: 'center',
    borderRadius: 10,
  },
  slider: {
    width: 12,
    height: 12,
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 10,
    transitionProperty: 'translateX',
    transitionDuration: '.2',
    transitionTimingFunction: 'east-in-out',
    marginHorizontal: 1,
    top: 3,
  },
})

const Switch = ({ value, onValueChange, style, trackColor }) => (
  <View style={[styles.switch, style]}>
    <Touchable
      style={[styles.switch, { backgroundColor: value ? trackColor.true || colors.purple : trackColor.false }]}
      activeOpacity={0.8}
      onPress={() => setTimeout(() => onValueChange(!value), 100)}
    >
      <View
        style={[
          styles.slider,
          {
            transform: [{ translateX: value ? 15 : 1 }],
          },
        ]}
      />
    </Touchable>
  </View>
)
Switch.propTypes = {
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  style: PropTypes.any,
  trackColor: PropTypes.object,
}

export default Switch
