import { useEffect } from 'react'
import { StatusBar, Style } from '@capacitor/status-bar'

import { Platform } from 'src/utils'

const Bar = () => {
  useEffect(() => {
    if (Platform.OS !== 'web') {
      StatusBar.setStyle({ style: Style.Light })
      // Background color only works on android https://capacitorjs.com/docs/apis/status-bar#backgroundcoloroptions
      if (Platform.OS === 'android') {
        StatusBar.setBackgroundColor({ color: '#ffffff' })
      }
    }
  }, [])
  return null
}

export default Bar
