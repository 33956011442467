import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
  },
})

const Row = ({ children, ...props }) => (
  <View {...props} style={[styles.root, props.style]}>
    {children}
  </View>
)
Row.propTypes = {
  children: PropTypes.node,
  style: PropTypes.any,
}

export default Row
