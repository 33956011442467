import { fromJS } from 'immutable'

import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export const initialState = fromJS({
  error: null,
  loading: true,
  deed: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.merge({ loading: false, deed: action.deed })

    case INIT_FAILED:
      return state.set('error', true).set('loading', false)

    case 'RESET':
      return initialState

    default:
      return state
  }
}
