import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { injectReducer, injectEpics } from 'src/utils'
import { Action, Screen, Spacing, Text, LinkButton, Loading } from 'src/retired/elements'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSubmitting, selectError } from './selectors'

export class RegisterCompanyVerify extends PureComponent {
  componentDidMount() {
    if (this.props.match.params.token) {
      this.props.actions.submitAction(this.props.match.params.token)
    }
  }

  render() {
    const { t } = this.props
    if (this.props.submitting) {
      return <Loading />
    }
    if (!this.props.match.params.token || this.props.error) {
      return <ErrorScreen back={false}>{this.props.error ? t`couldNotVerifyToken` : t`noTokenGiven`}</ErrorScreen>
    }
    return (
      <Screen fixed>
        <Screen padding narrow middle action>
          <Text size={24} lineHeight={32} bold>
            {t`thankYouForVerifyingYourEmail`}
          </Text>
          <Spacing marginTop={25} marginBottom={25}>
            <Text size={24} lineHeight={32}>
              {t`youAreNowReadyToDoSomeGood`}
            </Text>
          </Spacing>
          <Text size={24} lineHeight={32}>
            {t`clickNextToViewUpcoming`}
          </Text>
          <Action>
            <LinkButton to="/register/location" color="primary">
              {t`common:Next`}
            </LinkButton>
          </Action>
        </Screen>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    submitting: selectSubmitting(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerCompanyVerify', reducer })
const withEpics = injectEpics({ key: 'registerCompanyVerify', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('companyRegister'))(RegisterCompanyVerify)
