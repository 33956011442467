import React, { useState } from 'react'
import { View } from 'react-primitives'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { useTranslation } from 'react-i18next'

import { css, styled } from 'src/theme/styled'
import { TextField, Spacing, ActivityIndicator } from 'src/retired/elements'
import { Label } from 'src/retired/shared/Typography'
import User from 'src/entities/user/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { DonateButton } from '..'

import { Donor } from './DonorDetailsForm'
import { BillingAddressForm, BillingAddress, isBillingAddressAdded } from './BillingAddressForm'

const BASE_SPACING = 36

interface Errors {
  name?: string
  address?: string
  terms?: string
}

interface NFGFormProps {
  onSubmit: (data: { donor: object; creditCard: object }) => void
  submitting: boolean
  total: number
  amountValid: boolean
  donor?: Donor
  user?: User
}

const NFGForm = ({ submitting, total, amountValid, donor, user, onSubmit }: NFGFormProps): JSX.Element => {
  const userFirstName = user?.legalName?.first
  const userLastName = user?.legalName?.last
  const userEmail = user?.email
  const userPhone = user?.phone
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  const { wrapperProps, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps, meta } =
    usePaymentInputs({
      errorMessages: {
        emptyCardNumber: t`emptyCardNumber`,
        invalidCardNumber: t`invalidCardNumber`,
        emptyExpiryDate: t`emptyExpiryDate`,
        monthOutOfRange: t`monthOutOfRange`,
        yearOutOfRange: t`yearOutOfRange`,
        dateOutOfRange: t`dateOutOfRange`,
        invalidExpiryDate: t`invalidExpiryDate`,
        emptyCVC: t`emptyCVC`,
        invalidCVC: t`invalidCVC`,
      },
    })

  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    street1: '',
    city: '',
    country: 'US',
    postalCode: '',
  })

  const [firstName, setFirstName] = useState(userFirstName)
  const [lastName, setLastName] = useState(userLastName)
  const [creditCardNumber, setCreditCardNumber] = useState('')
  const [creditCardExpiration, setCreditCardExpiration] = useState('')
  const [creditCardCVC, setCreditCardCVC] = useState('')
  const [errors, setErrors] = useState<Errors>({})
  const [touched, setTouched] = useState(false)

  const buttonDisabled = !total || !amountValid || !(user || donor)
  const billingAddressError = !isBillingAddressAdded(billingAddress)

  return (
    <PaymentDetailsContainer>
      <div>
        <Label colour={colors.grayMediumDark} marginLeft={20}>
          {t`cardHolderName`}
        </Label>
        <Spacing marginBottom={BASE_SPACING / 3} />
        <PaymentFieldsContainer>
          <PaymentFieldContainer>
            <TextField
              placeholder={t`common:firstName`}
              defaultValue={firstName}
              onChangeText={(name, value) => setFirstName(value)}
              name="firstName"
              maxLength={50}
            />
          </PaymentFieldContainer>

          <PaymentFieldContainer>
            <TextField
              placeholder={t`common:lastName`}
              onChangeText={(name, value) => setLastName(value)}
              defaultValue={lastName}
              name="lastName"
              maxLength={50}
            />
          </PaymentFieldContainer>
        </PaymentFieldsContainer>

        {errors?.name && (
          <View>
            <Label colour={colors.redDark} lineHeight={21} marginLeft={20}>
              {errors.name}
            </Label>

            <Spacing marginBottom={BASE_SPACING / 4} />
          </View>
        )}
      </div>

      <BillingAddressForm billingAddress={billingAddress} setBillingAddress={setBillingAddress} touched={touched} />

      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 360 }}>
        <Label colour={colors.green} marginLeft={20}>
          {t`creditCard`}
        </Label>

        <Spacing marginBottom={BASE_SPACING / 3} />

        <PaymentInputsWrapper
          {...wrapperProps}
          styles={{
            inputWrapper: {
              base: css`
                border-radius: 20px;
                font-family: 'GTWalsheimLC';
                padding: 0 11px;
                height: 40px;
                border-color: rgb(235, 238, 240);
                font-size: 12px;
              `,
            },
            input: {
              base: css`
                width: 185px;
              `,
            },
            errorText: {
              base: css`
                display: none;
              `,
            },
          }}
        >
          <svg {...getCardImageProps({ images })} />
          <input
            {...getCardNumberProps({ onChange: (e) => setCreditCardNumber(e.target.value) })}
            style={{ width: '100%', border: 'none' }}
          />
          <div style={{ marginLeft: 'auto', flex: '0 0 auto' }}>
            <input
              {...getExpiryDateProps({ onChange: (e) => setCreditCardExpiration(e.target.value) })}
              style={{ border: 'none' }}
            />
            <input
              {...getCVCProps({ onChange: (e) => setCreditCardCVC(e.target.value) })}
              maxLength="4"
              style={{ border: 'none' }}
            />
          </div>
        </PaymentInputsWrapper>

        {meta.error && touched && (
          <>
            <Spacing marginBottom={BASE_SPACING / 4} />

            <View>
              <Label colour={colors.redDark} lineHeight={21} style={{ marginLeft: 20 }}>
                {meta.error}
              </Label>
            </View>
          </>
        )}
      </div>

      <Spacing marginBottom={BASE_SPACING} />

      <DonateButton
        color="primary"
        size="small"
        disabled={buttonDisabled}
        onPress={(ev) => {
          if (!submitting) {
            ev.preventDefault()

            setTouched(true)
            setErrors({})

            const nameError = (!firstName || !lastName) && t`nameCannotBeEmpty`

            if (nameError) {
              return setErrors({
                name: nameError,
              })
            }

            if (meta.error || billingAddressError) {
              return
            }

            const expiration = creditCardExpiration.split(' / ')

            onSubmit({
              donor: {
                firstName,
                lastName,
                email: userEmail,
                phone: userPhone,
                billingAddress,
              },
              creditCard: {
                nameOnCard: `${firstName} ${lastName}`,
                type: meta.cardType.type.charAt(0).toUpperCase() + meta.cardType.type.substring(1),
                number: creditCardNumber.replace(/ /g, ''),
                expiration: {
                  month: expiration[0],
                  year: `20${expiration[1]}`,
                },
                securityCode: creditCardCVC,
              },
            })
          }
        }}
      >
        {submitting ? <ActivityIndicator color="#fff" /> : t`completeDonation`}
      </DonateButton>
    </PaymentDetailsContainer>
  )
}

const PaymentDetailsContainer = styled(View)`
  max-width: 500px;
`

export const PaymentFieldsContainer = styled(View)`
  flex-direction: ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'column' : 'row')};
  justify-content: space-between;
  margin: 0 -5px;
`

export const PaymentFieldContainer = styled(View)`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin-bottom: 9px;
  padding-horizontal: 20px;
  flex: ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'auto' : '1')};
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
`

export default NFGForm
