import React, { useState, useEffect } from 'react'
import { View } from 'react-primitives'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useHistory } from 'src/navigation'
import { colors } from 'src/theme'
import Alert from 'src/retired/elements/Alert'
import SelectBox from 'src/retired/shared/SelectBox'
import { Button, Form, Screen, Checkbox } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { selectCurrentUser } from 'src/entities/user/selectors'
import {
  AffiliationsTableDocument,
  Affiliation,
  AffiliationType,
  useUpdateEmployeeAffiliationMutation,
  useCreateEmployeeAffiliationMutation,
} from 'src/generated/graphql'
import { validators } from 'src/utils'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import truthy from 'src/utils/truthy'
import Platform from 'src/utils/Platform'
import makeGoBack from 'src/navigation/makeGoBack'

const isBoardMemberAffiliationType = (value: unknown) => value === AffiliationType.BoardMember
const isValidAffiliationType = (value: unknown) => Object.values(AffiliationType).includes(value as AffiliationType)

const AffiliationForm = ({
  nonprofitId,
  affiliation,
}: {
  nonprofitId: string
  affiliation?: Affiliation
}): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const goBack = makeGoBack(history, '/profile')
  const { t } = useTranslation('affiliationForm')
  const user = useSelector(selectCurrentUser)

  const [isOngoing, setIsOngoing] = useState(!affiliation?.endingAt)

  const [updateEmployeeAffiliationMutation, { loading: updateLoading }] = useUpdateEmployeeAffiliationMutation({
    onCompleted: (data) => {
      history.push(`/organization/${data?.updateEmployeeAffiliation?.nonprofitId || nonprofitId}`)
      Alert.alert(t`Affiliation updated successfully`)
    },
    onError: () => {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    },
  })
  const [createEmployeeAffiliationMutation, { loading: createLoading }] = useCreateEmployeeAffiliationMutation({
    onCompleted: (data) => {
      history.push(`/organization/${data?.createEmployeeAffiliation?.nonprofitId || nonprofitId}`)
      Alert.alert(t`Affiliation created successfully`)
    },
    onError: () => {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    },
  })

  const loading = updateLoading || createLoading

  const onSubmit = async (): Promise<void> => {
    const data = {
      affiliationType: formik.values.affiliationType,
      boardPosition: formik.values.boardPosition && formik.values.boardPosition,
      startingAt: formik.values.startingAt as unknown as string,
      endingAt: formik.values.endingAt as unknown as string,
    }

    if (affiliation) {
      await updateEmployeeAffiliationMutation({
        variables: {
          updateEmployeeAffiliationId: affiliation.id,
          data,
        },
      })
    } else {
      await createEmployeeAffiliationMutation({
        variables: {
          data: {
            userId: user.id,
            companyId: user.organization.id,
            nonprofitId,
            ...data,
          },
        },
        refetchQueries: [
          {
            query: AffiliationsTableDocument,
            variables: { userId: user?.id },
          },
        ],
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      affiliationType: affiliation?.affiliationType,
      boardPosition: affiliation?.boardPosition,
      startingAt: affiliation?.startingAt ? new Date(affiliation.startingAt) : undefined,
      endingAt: affiliation?.endingAt ? new Date(affiliation.endingAt) : undefined,
    },
    validate: (values) => {
      const errors = {
        affiliationType: validators.notEmpty(values.affiliationType) && isValidAffiliationType(values.affiliationType),
        boardPosition: isBoardMemberAffiliationType(values.affiliationType)
          ? validators.notEmpty(values.boardPosition)
          : null,
        startingAt: validators.notEmpty(values.startingAt),
        endingAt: isOngoing ? null : validators.notEmpty(values.endingAt),
      }
      const hasErrors = Object.values(errors).some(truthy)
      return hasErrors ? errors : {}
    },
    validateOnMount: true,
    onSubmit,
  })

  useEffect(() => {
    if (isOngoing) {
      formik.setFieldValue('endingAt', null)
    }
  }, [isOngoing])

  useEffect(() => {
    if (!isBoardMemberAffiliationType(formik.values.affiliationType)) {
      formik.setFieldValue('boardPosition', null)
    }
  }, [formik.values.affiliationType])

  return (
    <Screen
      style={{
        backgroundColor: colors.gray03,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
      }}
      loading={loading}
    >
      <Form onSubmit={formik.handleSubmit}>
        <View>
          <Text style={{ marginBottom: 10 }}>{t`affiliation`}</Text>
          <SelectBox
            onSelect={async (value: string) => formik.setFieldValue('affiliationType', value)}
            onDeselect={async (value: string) => formik.setFieldValue('affiliationType', value)}
            value={formik.values.affiliationType}
            options={[
              { value: 'BoardMember', title: t`boardMember` },
              { value: 'Volunteer', title: t`volunteer` },
              { value: 'Donor', title: t`donor` },
              { value: 'PersonalConnection', title: t`personalConnection` },
              { value: 'FamilyMemberAffiliation', title: t`familyMemberAffiliation` },
            ]}
            placeholder={t`selectAffiliation`}
            style={{ width: 345 }}
          />
        </View>

        {!!formik.errors.affiliationType && formik.touched.affiliationType && (
          <Text size={16} center style={{ color: colors.redDark }}>
            {formik.errors.affiliationType}
          </Text>
        )}

        {isBoardMemberAffiliationType(formik.values.affiliationType) && (
          <View style={{ margin: 10 }}>
            <Text style={{ paddingBottom: 10 }}>{t`boardPosition`}</Text>
            <SelectBox
              onSelect={async (value: string) => formik.setFieldValue('boardPosition', value)}
              onDeselect={async (value: string) => formik.setFieldValue('boardPosition', value)}
              value={formik.values?.boardPosition || t`selectBoardPosition`}
              options={[
                { value: 'President', title: t`president` },
                { value: 'Director', title: t`director` },
                { value: 'VicePresident', title: t`vicePresident` },
                { value: 'Secretary', title: t`secretary` },
                { value: 'Member', title: t`member` },
              ]}
              placeholder={t`selectBoardPosition`}
              style={{ width: 345 }}
            />
          </View>
        )}

        {!!formik.errors.boardPosition && formik.touched.boardPosition && (
          <Text size={16} center style={{ color: colors.redDark }}>
            {formik.errors.boardPosition}
          </Text>
        )}

        <View>
          <View
            style={{
              flexDirection: isOngoing ? 'column' : 'row',
              justifyContent: 'space-between',
              width: 345,
              paddingVertical: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: isOngoing && 345,
              }}
            >
              <Text style={{ marginBottom: 10 }}>{t`startDate`}</Text>
              <DatePicker
                placeholder={t`date`}
                value={formik.values.startingAt}
                onChange={async (value: any) => formik.setFieldValue('startingAt', value)}
                maxDate={formik.values?.endingAt}
                disableTodayAction
              />
            </View>
            {!isOngoing && (
              <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                <Text style={{ marginBottom: 10 }}>{t`endDate`}</Text>
                <DatePicker
                  placeholder={t`date`}
                  value={formik.values.endingAt}
                  onChange={async (value: any) => formik.setFieldValue('endingAt', value)}
                  minDate={formik.values.startingAt}
                />
              </View>
            )}
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {!!formik.errors.startingAt && formik.touched.startingAt && (
              <Text size={16} center style={{ color: colors.redDark }}>
                {formik.errors.startingAt}
              </Text>
            )}

            {!!formik.errors.endingAt && formik.touched.endingAt && (
              <Text size={16} center style={{ color: colors.redDark }}>
                {formik.errors.endingAt}
              </Text>
            )}
          </View>
          <View style={{ padding: 10, alignItems: 'center' }}>
            <Checkbox
              checked={isOngoing}
              onChange={(e) => setIsOngoing(e.target.checked)}
              style={{ backgroundColor: colors.gray03 }}
            >
              <Text>{t`selectIfAffiliationIsOngoing`}</Text>
            </Checkbox>
          </View>
        </View>

        <View
          style={{
            paddingTop: 40,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <View style={{ width: '100%', alignItems: 'center' }}>
            <Button
              disabled={loading}
              onPress={formik.handleSubmit}
              fluid={Platform.OS !== 'web'}
              type="submit"
            >{t`submit`}</Button>
          </View>
          <Text onPress={goBack} style={{ paddingTop: 20 }}>{t`cancel`}</Text>
        </View>
      </Form>
    </Screen>
  )
}

export default AffiliationForm
