import GTWalsheimLCRgWoff from 'src/assets/fonts/GTWalsheimLC-Rg.woff'
import GTWalsheimLCMdWoff from 'src/assets/fonts/GTWalsheimLC-Md.woff'
import GTWalsheimLCBlWoff from 'src/assets/fonts/GTWalsheimLC-Bl.woff'
import GTWalsheimLCUBdWoff from 'src/assets/fonts/GTWalsheimLC-UBd.woff'
import GTWalsheimLCRgWoff2 from 'src/assets/fonts/GTWalsheimLC-Rg.woff2'
import GTWalsheimLCMdWoff2 from 'src/assets/fonts/GTWalsheimLC-Md.woff2'
import GTWalsheimLCBlWoff2 from 'src/assets/fonts/GTWalsheimLC-Bl.woff2'
import GTWalsheimLCUBdWoff2 from 'src/assets/fonts/GTWalsheimLC-UBd.woff2'

export const fontStyles = `
@font-face {
  font-family: 'GTWalsheimLC';
  font-weight: 400;
  src: url(${GTWalsheimLCRgWoff}) format('woff'),
    url(${GTWalsheimLCRgWoff2}) format('woff2');
}

@font-face {
  font-family: 'GTWalsheimLC';
  font-weight: 500;
  src: url(${GTWalsheimLCMdWoff}) format('woff'),
    url(${GTWalsheimLCMdWoff2}) format('woff2');
}

@font-face {
  font-family: 'GTWalsheimLC';
  font-weight: 700;
  src: url(${GTWalsheimLCBlWoff}) format('woff'),
    url(${GTWalsheimLCBlWoff2}) format('woff2');

}

@font-face {
  font-family: 'GTWalsheimLC';
  font-weight: 800;
  src: url(${GTWalsheimLCUBdWoff}) format('woff'),
    url(${GTWalsheimLCUBdWoff2}) format('woff2');
}
`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = fontStyles
} else {
  style.appendChild(document.createTextNode(fontStyles))
}

// Inject stylesheet
document.head.appendChild(style)
