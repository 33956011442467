import React, { FC } from 'react'
import { View } from 'react-primitives'

import ScrollContainer from 'src/retired/shared/ScrollContainer'
import OrganizationCardList from 'src/retired/shared/OrganizationCardList'
import Organization from 'src/entities/organization/model'

type callback = () => void

interface OrganizationsBlockProps {
  title?: string
  organizations: Organization[]
  link?: string | callback
  titleStyle?: object
  hideLocation?: boolean
}

const OrganizationsBlock: FC<OrganizationsBlockProps> = ({ title, organizations, link, titleStyle, hideLocation }) =>
  organizations.length === 0 ? (
    <View />
  ) : (
    <ScrollContainer
      title={title}
      titleStyle={titleStyle}
      subtitle={organizations.length}
      cardType="card"
      dimension={organizations.length}
      link={link}
      render={(scrollViewOffset) => (
        <OrganizationCardList
          scrollViewOffset={scrollViewOffset}
          organizations={organizations}
          hideLocation={hideLocation}
        />
      )}
    />
  )

export default OrganizationsBlock
