import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Body1, H3, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Image } from 'src/retired/elements'

export const Tips = () => {
  const { t } = useTranslation('pledge')
  const { metrics, colors, images } = useDeedTheme()
  return (
    <View
      style={{
        position: 'relative',
        borderWidth: 1,
        borderColor: colors.black,
        borderRadius: 4,
        backgroundColor: colors.blue,
        padding: 25,
        marginTop: 30,
        marginHorizontal: 30,
        marginLeft: metrics.isSmall ? 30 : 0,
        marginBottom: 30,
      }}
    >
      {!metrics.isSmall && (
        <div
          style={{
            position: 'absolute',
            left: -9,
            top: 76,
            width: 16,
            height: 16,
            backgroundColor: colors.blue,
            transform: 'rotate(-45deg)',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            borderTopLeftRadius: 4,
            zIndex: 1,
          }}
        />
      )}
      <View style={metrics.isSmall ? { transform: [{ scale: 0.8 }], alignSelf: 'center' } : {}}>
        <Image source={images.pledge} style={{ width: 327, height: 156 }} />
      </View>

      <H3 colour="white" paddingTop={15} marginBottom={15}>
        {t`pledgeTips`}
      </H3>
      <H5 colour="white" paddingBottom={15}>
        {t`tip1Header`}
      </H5>
      <Body1 colour="white" paddingBottom={30}>
        {t`tip1Body`}
      </Body1>
      <H5 colour="white" paddingBottom={15}>
        {t`tip2Header`}
      </H5>
      <Body1 colour="white" paddingBottom={30}>
        {t`tip2Body`}
      </Body1>
      <H5 colour="white" paddingBottom={15}>
        {t`tip3Header`}
      </H5>
      <Body1 colour="white">{t`tip3Body`}</Body1>
    </View>
  )
}
