import _ from 'lodash'
import { stringify } from 'query-string'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { PageTitle, Select } from 'src/components'
import { ErrorText } from 'src/components/ErrorText'
import { selectAuth } from 'src/entities/auth/selectors'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Action, Row, Screen, ScrollView, Spacing } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Body1, H4, H5, Label } from 'src/retired/shared/Typography'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'
import { Communications } from 'src/utils'
import { useWebUrl } from 'src/utils/webUrl'

const Header = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`
const ButtonContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '20px 195px 0' : '25px')};
  width: 100%;
`

interface DateComponents {
  year: number
  month: number
}

const currentYear = new Date().getUTCFullYear()
const yearList = [currentYear - 2, currentYear - 1, currentYear]

const getUTCStartAndEndDates = (startDate: DateComponents, endDate: DateComponents) => {
  const fromDate = new Date(Date.UTC(startDate.year, startDate.month, 1))
  const toDate = new Date(Date.UTC(endDate.year, endDate.month + 1, 0))

  // Set the time of toDate to the end of the day in UTC
  toDate.setUTCHours(23, 59, 59, 999)

  return {
    fromDate,
    toDate,
  }
}

const SelectTimePeriod = (): JSX.Element => {
  const auth = useSelector(selectAuth)
  const webUrl = useWebUrl()

  const { t } = useTranslation('selectTimePeriod')
  const { colors, metrics } = useDeedTheme()

  const monthList = useMemo(
    () =>
      _.range(1, 13).map((month) =>
        _.capitalize(t('date:monthStandalone', { date: new Date(`${currentYear}-${`0${month}`.slice(-2)}-15`) }))
      ),
    [t]
  )

  const [startDate, setStartDate] = useState({ year: currentYear, month: new Date().getMonth() })
  const [endDate, setEndDate] = useState({ year: currentYear, month: new Date().getMonth() })
  const [isInvalidDate, setIsInvalidDate] = useState(false)

  const queryFromTo = useMemo<string>(() => {
    // uses UTC timezones for datetime to avoid bugs with timezone conversion when converting to string
    const { fromDate, toDate } = getUTCStartAndEndDates(startDate, endDate)

    setIsInvalidDate(false)
    if (fromDate > toDate) {
      setIsInvalidDate(true)
    }
    return stringify({ from: fromDate.toISOString(), to: toDate.toISOString() })
  }, [endDate, startDate])

  return (
    <Screen fixed>
      <PageTitle title={t`donationSummary`} />
      <ScrollView>
        <NavigationHeader transparent />
        <Header>
          <Label marginBottom={8} center weight="500" colour={colors.brandColor}>
            {t`donationSummary`.toUpperCase()}
          </Label>

          <H4 center marginBottom={8}>
            {t`createDonationSummary`}
          </H4>
          <H5 center marginBottom={8}>
            {t`weWillDoTheRest`}
          </H5>
        </Header>

        <View
          style={{
            padding: 24,
            alignContent: 'center',
            marginBottom: 50,
            alignItems: 'center',
          }}
        >
          <View style={{ width: metrics.isLarge ? '25%' : '100%' }}>
            {/* start date range */}
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: 24 }}>
              <Body1 marginBottom={8}>{t`from`}</Body1>

              <Row>
                <View style={{ marginRight: 16 }}>
                  <Select
                    options={monthList.map((month, i) => ({
                      value: i.toString(),
                      title: month,
                    }))}
                    onSelect={(value) => {
                      setStartDate((prev) => ({ ...prev, month: Number(value) }))
                    }}
                    value={startDate.month.toString()}
                    placeholder={t('common:month')}
                  />
                </View>

                <View>
                  <Select
                    options={yearList.map((year) => ({
                      value: year.toString(),
                      title: year.toString(),
                    }))}
                    onSelect={(selectedYear) => {
                      setStartDate((prev) => ({ ...prev, year: Number(selectedYear) }))
                    }}
                    value={startDate.year.toString()}
                    placeholder={t('common:year')}
                  />
                </View>
              </Row>
            </View>

            {/* end date range */}
            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <Body1 marginBottom={8}>{t`to`}</Body1>

              <Row>
                <View style={{ marginRight: 16 }}>
                  <Select
                    options={monthList.map((month, i) => ({
                      value: i.toString(),
                      title: month,
                    }))}
                    onSelect={(value) => {
                      setEndDate((prev) => ({ ...prev, month: Number(value) }))
                    }}
                    value={endDate.month.toString()}
                    placeholder={t('common:month')}
                  />
                </View>

                <View>
                  <Select
                    options={yearList.map((year) => ({
                      value: year.toString(),
                      title: year.toString(),
                    }))}
                    onSelect={(selectedYear) => {
                      setEndDate((prev) => ({ ...prev, year: Number(selectedYear) }))
                    }}
                    value={endDate.year.toString()}
                    placeholder={t('common:year')}
                  />
                </View>
              </Row>
            </View>

            {isInvalidDate && (
              <View style={{ marginTop: 24 }}>
                <ErrorText text={t`invalidDate`} />
              </View>
            )}
          </View>
        </View>

        <Spacing marginBottom={56} />
      </ScrollView>

      <Action>
        <ButtonContainer>
          <Button
            onPress={() => {
              Communications.web(`${webUrl}/login/token?d=/donations/summary?${queryFromTo}#${auth.token}`)
            }}
            palette="secondary"
            disabled={isInvalidDate}
            textStyle={{ color: colors.brandColor }}
            iconRight="downloadOutlined"
            largeText
          >
            {t`donationSummaryButton`}
          </Button>
        </ButtonContainer>
      </Action>
    </Screen>
  )
}

export default SelectTimePeriod
