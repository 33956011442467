import React, { Fragment } from 'react'
import { View, StyleSheet } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import Image from 'src/retired/elements/Image'
import Touchable from 'src/retired/shared/Touchable'

import { getDimensions } from './common/helpers'
import { Wrapper } from './common/Wrapper'
import { Subtext } from './common/Subtext'

interface ImageCardProps {
  link?: string
  image?: string
  backgroundColor?: string
  text?: string
  forceLarge?: boolean
  small?: boolean
  spacing?: boolean
  children?: React.ReactNode
  onPress?: () => void
  isResizableImage?: boolean
  isContained?: boolean
  fullWidthText?: boolean
}
export type ImageCardElement = React.ReactElement<ImageCardProps>

const ImageCard: React.FC<ImageCardProps> = ({
  link,
  image,
  backgroundColor,
  text,
  small = false,
  forceLarge = false,
  onPress,
  children,
  isResizableImage,
  isContained,
  fullWidthText,
}): ImageCardElement => {
  const Component = onPress ? Touchable : link ? Link : Fragment
  const ComponentProps = onPress ? { onPress } : link ? { to: link } : {}
  const { metrics } = useDeedTheme()

  const large = forceLarge || metrics.isLarge

  const dimensions = getDimensions(small, large)

  const customProperties = isResizableImage ? { width: '100%' } : {}
  const withBackground = backgroundColor
    ? {
        backgroundColor,
      }
    : {}

  const imageCustomProperties = isResizableImage ? { width: '100%' } : {}

  return (
    <Wrapper narrow={!metrics.isLarge || small}>
      <Component {...ComponentProps}>
        <View
          style={{
            ...dimensions,
            ...customProperties,
            ...withBackground,
            borderRadius: 8,
          }}
        >
          {children}
          {!!image && (
            <>
              <Image
                source={{ uri: image }}
                style={{ ...dimensions, ...imageCustomProperties, borderRadius: 8 }}
                resizeMode={isContained ? 'contain' : 'cover'}
              />
              {!!text && <View style={{ ...StyleSheet.absoluteFillObject }} />}
            </>
          )}
        </View>
        {!!text && <Subtext width={!fullWidthText ? dimensions.width : undefined} text={text} />}
      </Component>
    </Wrapper>
  )
}

export default ImageCard
