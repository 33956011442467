import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('nonprofits') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectScrollPosition = createSelector(selectState, (state) => state.get('scrollPosition'))

export const selectQueryId = createSelector(selectState, (state) => state.get('queryId'))
export const selectPage = createSelector(selectState, (state) => state.get('page'))
export const selectTotalItems = createSelector(selectState, (state) => state.get('totalItems'))
export const selectTotalPages = createSelector(selectState, (state) => state.get('totalPages'))
