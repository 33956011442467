import React from 'react'
import { View } from 'react-primitives'

import { resize } from 'src/utils/resize'
import IconCard from 'src/retired/shared/ImageCard/IconCard'
import ImageCard from 'src/retired/shared/ImageCard'
import Image from 'src/retired/elements/Image'
import Cause from 'src/entities/cause/model'

import { getCauseIconData } from '../DeedCard/helper'

interface CauseCardProps {
  cause: Cause
  forceLarge?: boolean
  small?: boolean
  onPress?: () => void
  isResizable?: boolean
}
export type CauseCardElement = React.ReactElement<CauseCardProps>

const CauseCard: React.FC<CauseCardProps> = ({
  cause,
  forceLarge = false,
  small = false,
  onPress,
  isResizable,
}): CauseCardElement => {
  const { type } = cause
  const isSDG = type === 'SDG'
  const image = isSDG
    ? `https://files.godeed.today/SDGs/${`0${cause.number}`.slice(-2)}.png`
    : resize(cause.picture || cause.banner, '960x540')

  const iconData = cause.type === 'Category' && getCauseIconData([cause.id])

  const commonProps = {
    text: !isSDG && cause.name,
    link: `/causes/${type.toLowerCase()}/${cause.id}`,
    forceLarge,
    onPress,
    small,
  }

  return (
    <View style={{ height: '100%' }}>
      {iconData?.iconName ? (
        <IconCard {...commonProps} isResizable={isResizable} iconData={iconData} />
      ) : (
        <ImageCard
          {...commonProps}
          image={!(small && isSDG) && image}
          backgroundColor={cause.color}
          arrow={!isSDG}
          isResizableImage={isResizable}
          isContained={isSDG}
        >
          {small && isSDG && (
            <View style={{ height: cause.number === 12 || cause.number === 16 ? 69 : 58 }}>
              <Image
                source={{ uri: image }}
                style={{
                  position: 'absolute',
                  width: 150,
                  height: 150,
                  top: 3,
                }}
                resizeMode="cover"
              />
            </View>
          )}
        </ImageCard>
      )}
    </View>
  )
}

export default CauseCard
