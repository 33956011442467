import React from 'react'
import { View } from 'react-primitives'

import { FluidImage } from 'src/retired/elements'
import { resize } from 'src/utils/resize'
import { Action, ActivityStatus } from 'src/containers/modules/Actions/types'
import { StatusChip } from 'src/containers/modules/Actions/ActivityStatus/StatusChip'
import { isDateInThePast } from 'src/containers/modules/Actions/Card/utils'

export const BannerImageWithStatus = ({
  action,
  activityStatus,
}: {
  action: Action
  activityStatus: ActivityStatus | null
}) => {
  const resizedImage = action?.bannerImage ? resize(action.bannerImage, '960x540') : null

  return (
    <View style={{ borderRadius: 12, overflow: 'hidden', marginBottom: 8 }}>
      <FluidImage source={{ uri: resizedImage }} aspectRatio={960 / 540} />
      <View style={{ position: 'absolute', bottom: 24, left: 24 }}>
        <StatusChip activityStatus={activityStatus} isActionExpired={isDateInThePast(action.endingAt)} />
      </View>
    </View>
  )
}
