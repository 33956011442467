import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Avatar, LinkButton, Row } from 'src/retired/elements'
import { Label, Body1 } from 'src/retired/shared/Typography'
import { CardCompactWrapper } from 'src/components'
import Image from 'src/retired/elements/Image'
import { AvatarData } from 'src/entities/campaign/model'

export const CompactCard = ({
  link,
  image,
  title,
  description,
  label,
  avatarItems,
  numNotShownAvatars,
}: {
  link: string
  image: string
  title: string
  description?: string | null
  label?: string
  avatarItems?: AvatarData[]
  numNotShownAvatars?: number
}): JSX.Element => {
  const { colors } = useDeedTheme()

  return (
    <LinkButton to={link} button={false}>
      <CardCompactWrapper cardColor={colors.statusTextSuccess} height={220}>
        <View style={{ flexGrow: 1, justifyContent: 'space-between' }}>
          <View>
            <Image
              source={{ uri: image }}
              resizeMode="cover"
              style={{
                width: '100%',
                height: 64,
                marginBottom: 8,
              }}
            />
            <Body1 weight="500" numberOfLines={1} marginBottom={8}>
              {title}
            </Body1>
            {description && (
              <Label numberOfLines={2} marginBottom={8} colour={colors.grayMedium}>
                {description}
              </Label>
            )}
          </View>

          <View>
            {label && (
              <View
                style={{
                  backgroundColor: colors.grayMild,
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  marginBottom: 8,
                }}
              >
                <Label colour={colors.primary}>{label}</Label>
              </View>
            )}

            <Row style={{ alignItems: 'center', flexGrow: 0 }}>
              {avatarItems?.map((avatar, index) => (
                <Avatar
                  link={false}
                  key={index}
                  user={avatar}
                  style={{ borderWidth: 2, borderColor: colors.blue }}
                  size={24}
                  spacing={4}
                />
              ))}
              {!!numNotShownAvatars && (
                <View
                  style={{
                    backgroundColor: colors.grayMild,
                    paddingVertical: 4,
                    paddingHorizontal: 8,
                    borderRadius: 16,
                  }}
                >
                  <Label colour={colors.primary}>{`+ ${numNotShownAvatars}`}</Label>
                </View>
              )}
            </Row>
          </View>
        </View>
      </CardCompactWrapper>
    </LinkButton>
  )
}
