import React, { FC } from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Text } from 'src/retired/shared/Typography'

export const SummaryContent: FC<{ title?: string }> = ({ title, children }) => {
  const { metrics } = useDeedTheme()

  return (
    <View>
      <Text fontSize={12} lineHeight={16} marginBottom={5}>
        {title}
      </Text>
      <View style={{ marginBottom: metrics.isSmall ? 10 : 0 }}>{children}</View>
    </View>
  )
}
