import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import Icon from 'src/retired/shared/Icon'
import { Body1, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const EmptyActions = ({ isActiveActionsFilterEnabled }: { isActiveActionsFilterEnabled: boolean }) => {
  const { t } = useTranslation('actions')
  const { colors, metrics } = useDeedTheme()

  return (
    <View
      style={{
        display: 'flex',
        width: '100%',
        paddingTop: metrics.isSmall ? '30%' : '3%',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: 10,
        }}
      >
        <Icon icon="noActionsIllustration" width={metrics.isSmall ? 250 : 305} height={metrics.isSmall ? 120 : 143} />
        <H5 style={{ paddingVertical: 22 }}>{t('emptyActionsTitle')}</H5>
        <Body1 style={{ color: colors.grayMediumDark, width: metrics.isSmall ? 'auto' : 370, textAlign: 'center' }}>
          {isActiveActionsFilterEnabled ? t('emptyActionsSubTitleActive') : t('emptyActionsSubTitleExpired')}
        </Body1>
      </View>
    </View>
  )
}
