import {
  INIT,
  INIT_FAILED,
  WATCH_POSITION,
  STOP_WATCHING,
  SET_GEOLOCATION,
  SET_GEOLOCATION_WATCH_ID,
  CHECK_IN,
  CHECK_IN_SUCCESS,
  CHECK_IN_FAILED,
  DISABLED_CHECK_IN_CLICKED,
} from './constants'

export const initAction = () => ({
  type: INIT,
})

export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const watchPositionAction = () => ({
  type: WATCH_POSITION,
})

export const stopWatchingAction = () => ({
  type: STOP_WATCHING,
})

export const setGeolocationAction = (geolocation) => ({
  type: SET_GEOLOCATION,
  geolocation,
})

export const setGeolocationWatchIdAction = (geolocationWatchId) => ({
  type: SET_GEOLOCATION_WATCH_ID,
  geolocationWatchId,
})

export const checkInAction = (id, userId) => ({
  type: CHECK_IN,
  id,
  userId,
})
export const checkInSuccessAction = () => ({
  type: CHECK_IN_SUCCESS,
})
export const checkInFailedAction = (error, errorCode) => ({
  type: CHECK_IN_FAILED,
  error,
  errorCode,
})

export const disabledCheckInClickAction = (reason) => ({
  type: DISABLED_CHECK_IN_CLICKED,
  reason,
})
