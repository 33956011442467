import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import countryList from 'country-list'

import { US_STATES } from 'src/data/states'
import { Spacing, TextField } from 'src/retired/elements'
import SelectBox from 'src/retired/shared/SelectBox'
import { Label } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Alert from 'src/retired/elements/Alert'

import { PaymentFieldsContainer, PaymentFieldContainer } from './NFGForm'

export interface BillingAddress {
  street1: string
  city: string
  country: string
  state?: string
  postalCode: string
}

interface BillingAddressFormProps {
  billingAddress: BillingAddress
  setBillingAddress: (billingAddress: BillingAddress) => void
  touched?: boolean
  giftAidApplies?: boolean
}

const GREAT_BRITAIN = 'GB'
// GB is the only UK country code in our country UI list
const UK_COUNTRY_CODES = [GREAT_BRITAIN]

export const BillingAddressForm = ({
  billingAddress,
  setBillingAddress,
  touched,
  giftAidApplies = undefined,
}: BillingAddressFormProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  const streetError = !billingAddress.street1 && t`streetCannotBeEmpty`
  const cityError = !billingAddress.city && t`cityCannotBeEmpty`
  const countryError = !billingAddress.country && t`countryCannotBeEmpty`
  const stateError = billingAddress.country === 'US' && !billingAddress.state && t`stateCannotBeEmpty`
  const zipCodeError = !billingAddress.postalCode && t`zipCodeCannotBeEmpty`

  const addressError = streetError || cityError || countryError || zipCodeError || stateError || undefined

  useEffect(() => {
    if (giftAidApplies) {
      setBillingAddress({ ...billingAddress, country: GREAT_BRITAIN })
    }
  }, [giftAidApplies])

  return (
    <div>
      <Label colour={colors.grayMediumDark} style={{ marginLeft: 20 }}>
        {t`billingAddress`}
      </Label>
      <Spacing marginBottom={12} />
      <PaymentFieldsContainer>
        <PaymentFieldContainer>
          <TextField
            onChangeText={(_name, street1) => setBillingAddress({ ...billingAddress, street1 })}
            placeholder={t`street`}
            name="street"
            maxLength={100}
            autoCapitalize="none"
          />
        </PaymentFieldContainer>
        <PaymentFieldContainer>
          <TextField
            onChangeText={(_name, city) => setBillingAddress({ ...billingAddress, city })}
            placeholder={t`city`}
            name="city"
            maxLength={100}
            autoCapitalize="none"
          />
        </PaymentFieldContainer>
      </PaymentFieldsContainer>

      <PaymentFieldsContainer>
        <SelectBox
          onSelect={(country) => {
            if (!giftAidApplies || (giftAidApplies && UK_COUNTRY_CODES.includes(country))) {
              setBillingAddress({ ...billingAddress, country })
            } else {
              Alert.alert(t('selectGiftAidCountry'))
            }
          }}
          value={billingAddress.country}
          placeholder={t`country`}
          showSearch
          // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
          options={countryList
            .getNames()
            .sort()
            .map((item) => ({ title: item, value: countryList.getCode(item) }))}
          style={{ flex: 1, marginBottom: '9px', marginRight: '5px', marginLeft: '5px' }}
          largeSelectionPadding
        />
        {billingAddress.country === 'US' && (
          <SelectBox
            onSelect={(state) => setBillingAddress({ ...billingAddress, state })}
            value={billingAddress.state}
            placeholder={t`state`}
            showSearch
            options={US_STATES.map((state) => ({ title: state.name, value: state.abbreviation }))}
            style={{ flex: 1, marginBottom: '9px', marginRight: '5px', marginLeft: '5px' }}
            largeSelectionPadding
          />
        )}
        <PaymentFieldContainer>
          <TextField
            onChangeText={(_name, postalCode) => setBillingAddress({ ...billingAddress, postalCode })}
            placeholder={t`zip`}
            name="zip"
            maxLength={10}
            autoCapitalize="none"
          />
        </PaymentFieldContainer>
      </PaymentFieldsContainer>
      {touched && addressError && (
        <View>
          <Label colour={colors.redDark} style={{ marginLeft: 20 }}>
            {addressError}
          </Label>
        </View>
      )}
    </div>
  )
}

export const isBillingAddressAdded = (billingAddress: BillingAddress): boolean =>
  Boolean(
    billingAddress.street1 &&
      billingAddress.city &&
      billingAddress.country &&
      billingAddress.postalCode &&
      (billingAddress.country === 'US' ? billingAddress.state : true)
  )
