import React from 'react'
import { View } from 'react-primitives'

import ScrollContainer from 'src/retired/shared/ScrollContainer'
import CardList from 'src/retired/shared/CardList'
import { BadgeSubsetFragment } from 'src/generated/graphql'

import { BadgeWithTitle } from '../BadgeWithTitle'

export const BadgesRow = ({ badges }: { badges: BadgeSubsetFragment[] }) => (
  <ScrollContainer
    cardType="card"
    dimension={badges?.length}
    withoutTopSpacing
    withoutBottomSpacing
    hideNavArrow
    render={(scrollViewOffset) => (
      <CardList scrollViewOffset={scrollViewOffset}>
        {badges.map((badge, index) => (
          <View style={{ paddingLeft: index === 0 ? 0 : 40 }} key={badge.id}>
            <BadgeWithTitle badge={badge} />
          </View>
        ))}
      </CardList>
    )}
  />
)
