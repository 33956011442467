import i18n from 'i18next'

import truthy from 'src/utils/truthy'

export interface ApiError {
  message?: string
  path?: string[]
  extensions: {
    errorCode: string
    value?: string
  }
}

export const renderErrorMessage = (errors?: ApiError[]): string =>
  errors
    ?.map((error) => {
      if (!error.extensions?.errorCode) {
        return null
      }
      const errorMessage = i18n.t(`errorMessages:${error.extensions.errorCode}`, {
        value: error.extensions.value,
        path: error.path?.join('.') || '',
      })
      if (errorMessage === error.extensions?.errorCode && error.message) {
        return error.message
      }
      return errorMessage
    })
    .filter(truthy)
    .join(', ') || i18n.t('errorMessages:genericError')
