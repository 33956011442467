import React from 'react'
import { Chip as MaterialChip, Avatar as MaterialAvatar } from '@mui/material'

import type { ChipProps } from './ChipProps'

export const Chip = ({
  label,
  image,
  icon,
  variant,
  color,
  size,
  disabled,
  selected,
  onDelete,
  onClick,
  style = {},
}: ChipProps): JSX.Element => (
  <MaterialChip
    label={label}
    avatar={image ? <MaterialAvatar src={image} /> : undefined}
    icon={icon}
    variant={variant}
    color={color}
    disabled={disabled}
    onClick={selected ? undefined : onClick}
    onDelete={selected ? onDelete : undefined}
    size={size}
    style={style}
  />
)
