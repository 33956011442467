/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { useTranslation } from 'react-i18next'

import { currencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import { CapBalanceInfo } from 'src/entities/user/model'

const CapBalance = ({
  capBalanceByBudget,
  unlimitedMessaging,
}: {
  capBalanceByBudget: CapBalanceInfo[]
  unlimitedMessaging?: string
}): JSX.Element | null => {
  const { t } = useTranslation('capBalance')

  const { rates, code } = useCurrency()

  if (!capBalanceByBudget?.length) {
    return <>{t`noBudget`}</>
  }

  const isUnlimited = capBalanceByBudget.some(({ balance }) => balance === null)

  if (isUnlimited) {
    return <>{unlimitedMessaging}</>
  }

  if (!rates) {
    return null
  }

  const totalCapBalanceInUserCurrency = capBalanceByBudget
    .map(({ currencyCode = 'USD', balance }) => (balance ? balance * rates[currencyCode] : 0))
    .reduce((total, balanceInUserCurrency) => total + balanceInUserCurrency, 0)

  return <>{currencyFormat(totalCapBalanceInUserCurrency, code)}</>
}
export default CapBalance
