import {
  INIT,
  INIT_STANDALONE,
  INIT_SUCCESS,
  INIT_FAILED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  RESET_ARE_RESPONSES_VALID,
} from './constants'

export const initAction = ({ deedId, formId, volunteerTimeId, standalone }) => ({
  type: INIT,
  deedId,
  formId,
  volunteerTimeId,
  standalone,
})

export const initStandaloneAction = (formId) => ({
  type: INIT_STANDALONE,
  formId,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const submitAction = (deed, formId, data, user, formStatus, volunteerTimeId, standalone) => ({
  type: SUBMIT,
  deed,
  formId,
  data,
  user,
  formStatus,
  volunteerTimeId,
  standalone,
})
export const submitSuccessAction = (areResponsesValid) => ({
  type: SUBMIT_SUCCESS,
  areResponsesValid,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
export const resetAreResponsesValidAction = () => ({
  type: RESET_ARE_RESPONSES_VALID,
})
