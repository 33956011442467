import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { selectDeedById } from 'src/entities/deed/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import DeedsApi from 'src/entities/deed/api'
import truthy from 'src/utils/truthy'

import { initSuccessAction, initFailedAction, confirmFailedAction } from './actions'
import { INIT, CONFIRM } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const deed = selectDeedById(state, id)
    if (!deed) {
      actions.push(DeedsApi.fetch(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const confirm = (action$) =>
  action$.ofType(CONFIRM).exhaustMap(({ id, invites }) => {
    const actions = []

    if (invites.size > 0) {
      const usersIds = invites
        .filter((invite) => !invite.email)
        .toArray()
        .map((user) => user.id)
      const emails = invites.filter((invite) => invite.email).toArray()
      actions.push(DeedsApi.invite(id, usersIds, emails))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, replace(`/deeds/${id}`)].filter(truthy))
      .catch((e) => Observable.of(confirmFailedAction(e), showErrorAction(i18n.t('deedScreen:confirmationFailed'))))
  })

export default [init, confirm]
