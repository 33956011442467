import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Spacing, Text } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import NonprofitsSearch from 'src/containers/modules/NonprofitsSearch'

const SelectNonprofit = ({ formik, acceptsDonations }: { formik: any; acceptsDonations?: boolean }) => {
  const { t } = useTranslation('nominateNonprofitSearchForm')
  const { colors } = useDeedTheme()
  const history = useHistory()

  const [searchTerm, setSearchTerm] = useState('')

  const selectedNonprofit = formik.values.nonprofit

  const isNonprofitSelected = typeof selectedNonprofit !== 'undefined'

  return (
    <>
      <NonprofitsSearch
        acceptsDonations={acceptsDonations}
        onSetSelectedNonprofit={(_nonprofitId, nonprofit) => {
          if (nonprofit) {
            formik.setFieldValue('nonprofit', nonprofit)
          }
        }}
        onSetSearchTerm={setSearchTerm}
        emptyAction={<Body1 center>{t`sorryWeWerentAbleToFind`}</Body1>}
        selectOrganizationLabel={t`organizationName`}
      />

      <Spacing marginTop={30} />

      {isNonprofitSelected && (
        <Row style={{ marginBottom: 30 }}>
          <Body2 weight="500" marginRight={20}>
            {t`selected`}
          </Body2>
          <View style={{ flex: 1 }}>
            <Body2 weight="500">{formik.values.nonprofit.name}</Body2>
            <Body2 weight="500" colour={colors.grayText}>
              {formik.values.nonprofit.locationObject.toString()}
            </Body2>
          </View>
        </Row>
      )}

      <Row style={{ marginBottom: 30, justifyContent: isNonprofitSelected ? 'space-between' : 'center' }}>
        {isNonprofitSelected && (
          <Button
            palette="primary"
            style={{ marginRight: '12px' }}
            onPress={() => {
              history.push({
                pathname: `/organization/${selectedNonprofit.get('id')}`,
              })
            }}
          >
            {t`yesTakeMeToIt`}
          </Button>
        )}

        <Button
          palette={isNonprofitSelected ? undefined : 'primary'}
          onPress={() => {
            history.push({
              pathname: '/nominate-nonprofit',
              search: searchTerm && `?nonprofitName=${searchTerm}`,
            })
          }}
        >
          {isNonprofitSelected ? t`noProceedToNomination` : t`proceedToNomination`}
        </Button>
      </Row>

      <Spacing marginBottom={12} />

      <Text
        style={{ cursor: 'pointer', textAlign: 'center' }}
        onPress={() => history.goBack()}
        underline
        color={colors.lightGray}
      >{t`common:Cancel`}</Text>
    </>
  )
}

SelectNonprofit.displayName = 'SelectNonprofit'

export default SelectNonprofit
