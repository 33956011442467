export const INIT = 'containers/Donate/INIT'
export const INIT_SUCCESS = 'containers/Donate/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Donate/INIT_FAILED'

export const SET_DONATING = 'containers/Donate/SET_DONATING'

export const DONATE = 'containers/Donate/DONATE'
export const DONATE_SUCCESS = 'containers/Donate/DONATE_SUCCESS'
export const DONATE_FAILED = 'containers/Donate/DONATE_FAILED'
export const NON_STRIPE_DONATION = 'containers/Donate/NON_STRIPE_DONATION'

export const REGISTER_BETTERPLACE_DONATION = 'containers/Donate/REGISTER_BETTERPLACE_DONATION'

export const REGISTER_GIVEINDIA_DONATION = 'containers/Donate/REGISTER_GIVEINDIA_DONATION'

export const REGISTER_STRIPE_DONATION = 'containers/Donate/REGISTER_STRIPE_DONATION'

export const REGISTER_PAYPAL_DONATION = 'containers/Donate/REGISTER_PAYPAL_DONATION'
export const REGISTER_RECURRING_PAYPAL_DONATION = 'containers/Donate/REGISTER_RECURRING_PAYPAL_DONATION'
export const REGISTER_RESUME_RECURRING_PAYPAL_DONATION = 'containers/Donate/REGISTER_RESUME_RECURRING_PAYPAL_DONATION'

export const FETCH_PROVIDER_DATA = 'containers/Donate/FETCH_PROVIDER_DATA'
export const FETCH_PROVIDER_DATA_SUCCESS = 'containers/Donate/FETCH_PROVIDER_DATA_SUCCESS'
export const FETCH_PROVIDER_DATA_FAILED = 'containers/Donate/FETCH_PROVIDER_DATA_FAILED'

export const CREATE_CUSTOMER_ID = 'containers/Donate/CREATE_CUSTOMER_ID'
export const CREATE_CUSTOMER_ID_SUCCESS = 'containers/Donate/CREATE_CUSTOMER_ID_SUCCESS'
export const CREATE_CUSTOMER_ID_FAILED = 'containers/Donate/CREATE_CUSTOMER_ID_FAILED'

export const PAYPAL_CANCEL = 'containers/Donate/PAYPAL_CANCEL'

export const FETCH_ORGANIZATION_BY_ID = 'containers/Donate/FETCH_ORGANIZATION_BY_ID'
export const FETCH_ORGANIZATION_BY_ID_SUCCESS = 'containers/Donate/FETCH_ORGANIZATION_BY_ID_SUCCESS'

// non-redux
export const SHARE_ALL_KEY = 'shareAll'
export const SHARE_NAME_ONLY_KEY = 'shareNameOnly'
export const SHARE_NOTHING_KEY = 'shareNothing'
export const GIFT_AID_KEY = 'giftAidCheckbox'
