import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectOrganizationById } from 'src/entities/organization/selectors'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { VolunteerRewardBudgetingType } from 'src/generated/graphql'
import { useParams } from 'src/navigation'
import { ExternalLink, LinkText } from 'src/retired/elements'
import { Body1, Label } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getHours, getMinutes } from 'src/utils/convertDuration'

import { initAction as organizationInitAction } from '../../Organization/actions'

import ProgressBar from './ProgressBar'
import { StepItem } from './StepItem'
import { calculateIsAllOfBudgetUsed } from './calculateIsAllOfBudgetUsed'

export const ApplyForVolunteerRewardStep = ({
  volunteerRewardBudgeting,
}: {
  volunteerRewardBudgeting?: VolunteerRewardBudgetingType
}) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('volunteerTimeSuccessProfile')
  const { colors } = useDeedTheme()
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const volunteerRewardSettings = user?.organization?.settings?.volunteerReward
  const validationForm = volunteerRewardSettings?.validationForm
  const fallbackNonprofitId = volunteerRewardSettings?.fallbackNonprofit

  const brand = useSelector(selectUserBrand)
  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, id))
  const volunteeredNonprofit = volunteerTime?.nonprofit
  const volunteeredNonprofitId = volunteeredNonprofit?.id
  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')

  const isVolunteerRewardBudgetAlreadyUsed = calculateIsAllOfBudgetUsed(volunteerRewardBudgeting)

  const budgetHours = getHours(volunteerRewardBudgeting?.limitPerUserInMinutes ?? 0)

  const approvedMinutes = volunteerRewardBudgeting?.amountApprovedInMinutes ?? 0
  const eligiblePendingMinutes = volunteerRewardBudgeting?.amountEligiblePendingInMinutes ?? 0
  const usedBudgetHours = getHours(approvedMinutes + eligiblePendingMinutes)

  const requestedHours = getHours(volunteerRewardBudgeting?.requestedVolunteerRewardAmountInMinutes ?? 0)

  const nonprofitWithDetails = useSelector((state) => {
    if (isVolunteerRewardEnabled) {
      return selectOrganizationById(state, volunteeredNonprofitId)
    }
  })
  const nonprofitAcceptsDonations = nonprofitWithDetails?.acceptsDonations

  const fallbackNonprofitWithDetails = useSelector((state) => {
    if (isVolunteerRewardEnabled && nonprofitWithDetails && !nonprofitAcceptsDonations) {
      return selectOrganizationById(state, fallbackNonprofitId)
    }
  })

  const showFallbackNonprofit = fallbackNonprofitWithDetails && !nonprofitAcceptsDonations

  useEffect(() => {
    if (volunteeredNonprofitId && isVolunteerRewardEnabled) {
      dispatch(organizationInitAction(volunteeredNonprofitId))
    }
  }, [dispatch, isVolunteerRewardEnabled, volunteeredNonprofitId])

  useEffect(() => {
    if (nonprofitWithDetails && !nonprofitAcceptsDonations && fallbackNonprofitId && isVolunteerRewardEnabled) {
      dispatch(organizationInitAction(fallbackNonprofitId))
    }
  }, [
    dispatch,
    fallbackNonprofitId,
    isVolunteerRewardEnabled,
    nonprofitAcceptsDonations,
    volunteeredNonprofitId,
    nonprofitWithDetails,
  ])

  const externalLink = user?.organization?.settings?.volunteerReward?.externalLink
  const loggedHoursHasFraction = volunteerTime?.duration && getMinutes(volunteerTime?.duration)

  return (
    <StepItem title={validationForm ? t`applyForVolunteerReward` : t`claimYourVolunteerRewards`} stepNumber={2} end>
      {volunteerRewardBudgeting && (
        <ProgressBar
          progress1={(usedBudgetHours * 100) / budgetHours}
          progress2={(requestedHours * 100) / budgetHours}
          progress2label={`+${requestedHours}h`}
          title={t(`progressBarTitle`, { usedAmount: usedBudgetHours, budget: budgetHours })}
          progress2labelTooltip={loggedHoursHasFraction ? t`progress2labelTooltip` : undefined}
        />
      )}
      <Body1>
        {isVolunteerRewardBudgetAlreadyUsed ? (
          t`congratsBudgetAlreadyUsed`
        ) : !volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget ? (
          t`volunteerBudgetExceeded`
        ) : (
          <Trans
            t={t}
            i18nKey="volunteerRewardInfo"
            components={{
              Link: (
                <LinkText
                  size={16}
                  lineHeight={25}
                  color={brand?.brandColor}
                  underline
                  to={`/organization/${showFallbackNonprofit ? fallbackNonprofitId : volunteeredNonprofitId}`}
                />
              ),
            }}
            values={{
              nonprofit: showFallbackNonprofit ? fallbackNonprofitWithDetails.name : volunteeredNonprofit?.name,
            }}
          />
        )}
      </Body1>
      {showFallbackNonprofit && volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget ? (
        <Label style={{ color: colors.gray05 }} marginTop={8} marginBottom={16}>{t`noteAboutFallbackNonprofit`}</Label>
      ) : null}

      {externalLink ? (
        <div style={{ marginTop: 10 }}>
          <ExternalLink
            size={16}
            lineHeight={25}
            color={brand?.brandColor}
            underline
            href={externalLink}
          >{t`reviewTheProgramGuidelines`}</ExternalLink>
        </div>
      ) : null}
    </StepItem>
  )
}
