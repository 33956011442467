import React, { memo, useMemo } from 'react'
import { Box } from '@mui/material'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { ErrorText } from 'src/components/ErrorText'
import { useCurrency } from 'src/containers/modules/CurrencyFormat'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { UpdateError } from '../reducer'
import { ValidationErrors as BackendValidationErrors, ValidationErrorObject } from '../types'

import { MINIMUM_DONATION_AMOUNT } from './DonationAmount'

interface ValidationErrorsProps {
  field: keyof BackendValidationErrors
  frontendError: ValidationErrorObject | null
  updateError: UpdateError | null
}

const StyledErrorText = memo(({ text }: { text: string }) => (
  <ErrorText text={text} style={{ marginTop: 0, marginBottom: 0 }} />
))

const ErrorIcon = memo(() => (
  <ErrorOutlineRounded
    style={{ color: useDeedTheme().colors.redDark, fontSize: 15, marginRight: 5 }}
  ></ErrorOutlineRounded>
))

export const ValidationErrors = ({ frontendError, updateError, field }: ValidationErrorsProps) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')
  const currencySymbol = useCurrency().symbol

  const errorContent = useMemo(() => {
    const renderErrorText = (translationCode: string) =>
      t(translationCode, { currencySymbol, minimumDonationAmount: MINIMUM_DONATION_AMOUNT })

    if (!frontendError && !updateError) {
      return null
    }

    if (frontendError && !updateError) {
      return <StyledErrorText text={renderErrorText(frontendError.translationCode)} />
    }

    if (!updateError) {
      return null
    }

    const { mutationError, validationErrors: backendValidationErrors } = updateError.toJS()

    if (mutationError) {
      return <StyledErrorText text={t(mutationError.message)} />
    }

    if (backendValidationErrors && field in backendValidationErrors) {
      return backendValidationErrors[field]!.map((error) => (
        <StyledErrorText key={error.translationCode} text={renderErrorText(error.translationCode)} />
      ))
    }

    return null
  }, [frontendError, updateError, field, t, currencySymbol])

  if (!errorContent) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
      <ErrorIcon />
      {errorContent}
    </Box>
  )
}
