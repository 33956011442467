import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PhoneNumberInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import Platform from 'src/utils/Platform'
import phoneNumberCountryList from 'src/utils/phoneNumberCountryList'

import './style.css'

const countries = phoneNumberCountryList.map((country) => country.iso2.toLocaleUpperCase())

const PhoneInput = ({ user, locations, value, onChange, onTouched, onSubmit, placeholder }) => {
  const userLocation = locations?.get(user?.location)
  const userCountryCode = userLocation?.get('countryCode') || 'US'
  const [selectedCountryCode, setSelectedCountryCode] = useState(userCountryCode)
  const [wasChanged, setWasChanged] = useState(false)
  return (
    <PhoneNumberInput
      placeholder={placeholder}
      value={value}
      name="phone"
      style={Platform.isIE === true ? { width: '100%' } : undefined}
      onChange={(number) => {
        onChange('phone', number || null)

        if (!wasChanged) {
          onTouched('phone')
          setWasChanged(true)
        }
      }}
      countries={countries}
      showCountrySelect={selectedCountryCode !== 'US'}
      country={selectedCountryCode}
      onCountryChange={(countryCode) => {
        setSelectedCountryCode(countryCode)
        onTouched('phone')
      }}
      limitMaxLength
      onBlur={() => {
        onTouched('phone')

        if (onSubmit) {
          onSubmit()
        }
      }}
    />
  )
}
PhoneInput.propTypes = {
  user: PropTypes.object,
  locations: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onTouched: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
}
export default PhoneInput
