/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import TooltipMUI from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/InfoOutlined'

interface TooltipProps {
  title: string | JSX.Element
  placement: 'top' | 'right' | 'bottom' | 'left'
  hoverText?: string
  hoverIcon?: string
  children?: JSX.Element
  showInfoIcon?: boolean
  infoIconColor?: string
}

export const Tooltip = ({
  hoverText,
  hoverIcon,
  title,
  placement,
  children,
  infoIconColor,
  showInfoIcon,
}: TooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <TooltipMUI color="black" title={title} arrow placement={placement} open={isOpen}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4 }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {hoverIcon ? (
          typeof hoverIcon === 'string' ? (
            <img src={hoverIcon} alt="" />
          ) : (
            hoverIcon
          )
        ) : showInfoIcon ? (
          <IconButton size="small" sx={{ height: 19, width: 19 }}>
            <InfoIcon fontSize="small" sx={{ color: infoIconColor ?? 'black' }} />
          </IconButton>
        ) : undefined}
        {hoverText && <span>{hoverText}</span>}
        {children && <>{children}</>}
      </div>
    </TooltipMUI>
  )
}

export default Tooltip
