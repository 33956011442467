/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, MouseEventHandler, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { humanizeTimestamp, useOnClickUser, userOrDefault } from 'react-activity-feed/dist/utils'
import type { ElementOrComponentOrLiteralType, PropsWithElementAttributes } from 'react-activity-feed/dist/utils'
import { Avatar, useTranslationContext } from 'react-activity-feed'
import type { ActivityProps, DefaultAT, DefaultUT } from 'react-activity-feed'
import { useSelector } from 'react-redux'
import { Flex } from 'react-activity-feed/dist/components/Flex'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { DropdownMenu } from 'src/components/DropdownMenu/DropdownMenu'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Box } from 'src/components/Box/Box'
import { CommunityUserRole } from 'src/generated/graphql'
import { Chip } from 'src/components/Chip/Chip'

import { DeleteActivityConfirmationModal } from './DeleteActivityConfirmationModal'
import { CommunityContext } from './CommunityFeed'

export type UserBarProps = PropsWithElementAttributes<{
  activity: ActivityProps['activity']
  username: string
  AfterUsername?: React.ReactNode
  avatar?: string
  follow?: boolean
  icon?: string
  role?: CommunityUserRole
  onClickUser?: MouseEventHandler
  Right?: ElementOrComponentOrLiteralType
  subtitle?: string
  time?: string // text that should be displayed as the time
  timestamp?: string | number | Date // a timestamp that should be humanized
}>

const UserBar = ({
  activity,
  time,
  timestamp,
  icon,
  AfterUsername,
  username,
  role,
  onClickUser,
  avatar,
  className,
  style,
}: UserBarProps): JSX.Element => {
  const { tDateTimeParser } = useTranslationContext()
  const { t } = useTranslation('communityFeed')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { metrics } = useDeedTheme()

  const { role: myRole } = useContext(CommunityContext)

  const currentUser = useSelector(selectCurrentUser)

  const [humanReadableTimestamp, parsedTimestamp] = useMemo(
    () => [
      !time && timestamp ? humanizeTimestamp(timestamp, tDateTimeParser) : time,
      timestamp ? tDateTimeParser(timestamp).toJSON() : undefined,
    ],
    [timestamp, tDateTimeParser, time]
  )

  const actorId = typeof activity?.actor === 'string' ? activity?.actor : activity?.actor?.id
  const dropdownMenu = (myRole === CommunityUserRole.Lead || currentUser?.id === actorId) && (
    <DropdownMenu
      options={[
        // @TODO-DP: implement editing
        // { id: 'edit' as const, label: t`edit` },
        { id: 'delete' as const, label: t`delete` },
      ]}
      onSelect={(optionId) => {
        if (optionId === 'delete') {
          setDeleteModalOpen(true)
        }
      }}
    />
  )

  return (
    <div className={classNames('raf-user-bar', className)} style={style}>
      {avatar && <Avatar onClick={onClickUser} size={metrics.screenWidth >= 640 ? 56 : 40} circle image={avatar} />}
      <div className="raf-user-bar__details">
        <Flex a="center">
          <div style={{ marginRight: 0 }}>
            <Flex a="center" j="flex-start">
              <div className="raf-user-bar__username" onClick={onClickUser}>
                {username}
              </div>
              {role === CommunityUserRole.Lead && <Chip color="secondary" size="small" label={t`lead`} />}
            </Flex>

            <div className="raf-user-bar__extra">
              <time dateTime={parsedTimestamp} title={parsedTimestamp}>
                {humanReadableTimestamp}
              </time>
            </div>
          </div>
          <div style={{ marginLeft: 2 }}>{dropdownMenu}</div>
        </Flex>

        {AfterUsername}
        {icon && <img src={icon} alt="icon" />}
      </div>
      {deleteModalOpen && (
        <DeleteActivityConfirmationModal
          onClose={() => {
            setDeleteModalOpen(false)
          }}
          activityId={activity.id}
        />
      )}
    </div>
  )
}

export type ActivityHeaderProps<UT extends DefaultUT = DefaultUT, AT extends DefaultAT = DefaultAT> = Pick<
  ActivityProps<UT, AT>,
  'activity' | 'HeaderRight' | 'icon' | 'onClickUser' | 'className' | 'style'
>

export const ActivityHeader = <UT extends DefaultUT = DefaultUT, AT extends DefaultAT = DefaultAT>({
  activity,
  HeaderRight,
  icon,
  onClickUser,
  className,
}: ActivityHeaderProps<UT, AT>): JSX.Element => {
  const { tDateTimeParser } = useTranslationContext()

  const actor = userOrDefault<UT>(activity.actor)
  const handleUserClick = useOnClickUser<UT>(onClickUser)

  return (
    <Box className={className} sx={{ p: 0 }}>
      <UserBar
        activity={activity}
        username={actor.data.name}
        avatar={actor.data.profileImage}
        role={
          // Duh Stream types are hard to deal with
          (
            actor.data as {
              role?: CommunityUserRole
            }
          ).role
        }
        onClickUser={handleUserClick?.(actor)}
        subtitle={HeaderRight ? humanizeTimestamp(activity.time, tDateTimeParser) : undefined}
        timestamp={activity.time}
        icon={icon}
        Right={HeaderRight}
      />
    </Box>
  )
}
