import { Observable } from 'rxjs'
import i18n from 'i18next'

import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import RatingApi from 'src/entities/rating/api'
import RelationApi from 'src/entities/relation/api'
import truthy from 'src/utils/truthy'

import { submitSuccessAction, submitFailedAction } from './actions'
import { SUBMIT } from './constants'

const submit = (action$) =>
  action$.ofType(SUBMIT).exhaustMap(({ id, rating, friendRequests, skipped = false }) => {
    const actions = [RatingApi.rate('Deed', id, rating, !!skipped)]

    if (friendRequests.size > 0) {
      actions.push(...friendRequests.map((userId) => RelationApi.request(userId, id).onErrorResumeNext(() => [])))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) =>
        [
          resultingActions[0],
          !skipped &&
            showMessageAction(
              i18n.t('deedScreen:thankYou'),
              friendRequests.size > 0 ? i18n.t('deedScreen:yourFriendRequestsHaveBeenSent') : ''
            ),
          submitSuccessAction(),
        ].filter(truthy)
      )
      .catch((e) => Observable.of(submitFailedAction(e), showErrorAction(i18n.t('deedScreen:ratingFailed'))))
  })
export default [submit]
