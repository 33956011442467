export default [
  {
    areaCodes: null,
    dialCode: 1,
    iso2: 'us',
    name: 'United States',
    locale: 'en-US',
  },
  {
    areaCodes: null,
    dialCode: 49,
    iso2: 'de',
    name: 'Germany',
    locale: 'de-DE',
  },
  {
    areaCodes: null,
    dialCode: 43,
    iso2: 'at',
    name: 'Austria',
    locale: 'de-AT',
  },
  {
    areaCodes: null,
    dialCode: 32,
    iso2: 'be',
    name: 'Belgium',
    locale: 'fr-BE',
  },
  {
    areaCodes: null,
    dialCode: 359,
    iso2: 'bg',
    name: 'Bulgaria',
    locale: 'bg-BG',
  },
  {
    areaCodes: null,
    dialCode: 357,
    iso2: 'cy',
    name: 'Cyprus',
    locale: 'en-CY',
  },
  {
    areaCodes: null,
    dialCode: 420,
    iso2: 'cz',
    name: 'Czechia',
    locale: 'cs-CZ',
  },
  {
    areaCodes: null,
    dialCode: 30,
    iso2: 'gr',
    name: 'Greece',
    locale: 'el-GR',
  },
  {
    areaCodes: null,
    dialCode: 36,
    iso2: 'hu',
    name: 'Hungary',
    locale: 'hu-HU',
  },
  {
    areaCodes: null,
    dialCode: 370,
    iso2: 'lt',
    name: 'Lithuania',
    locale: 'lt-LT',
  },
  {
    areaCodes: null,
    dialCode: 371,
    iso2: 'lv',
    name: 'Latvia',
    locale: 'lv-LV',
  },
  {
    areaCodes: null,
    dialCode: 356,
    iso2: 'mt',
    name: 'Malta',
    locale: 'en-MT',
  },
  {
    areaCodes: null,
    dialCode: 47,
    iso2: 'no',
    name: 'Norway',
    locale: 'nb-NO',
  },
  {
    areaCodes: null,
    dialCode: 46,
    iso2: 'se',
    name: 'Sweden',
    locale: 'sv-SE',
  },
  {
    areaCodes: null,
    dialCode: 41,
    iso2: 'ch',
    name: 'Switzerland',
    locale: 'de-CH',
  },
  {
    areaCodes: null,
    dialCode: 386,
    iso2: 'si',
    name: 'Slovenia',
    locale: 'sl-SI',
  },
  {
    areaCodes: null,
    dialCode: 421,
    iso2: 'sk',
    name: 'Slovakia',
    locale: 'sk-SK',
  },
  {
    areaCodes: null,
    dialCode: 7,
    iso2: 'kz',
    name: 'Kazakhstan',
    locale: 'kk-KZ',
  },
  {
    areaCodes: null,
    dialCode: 352,
    iso2: 'lu',
    name: 'Luxembourg',
    locale: 'fr-LU',
  },
  {
    areaCodes: null,
    dialCode: 63,
    iso2: 'ph',
    name: 'Philippines',
    locale: 'en-PH',
  },
  {
    areaCodes: null,
    dialCode: 380,
    iso2: 'ua',
    name: 'Ukraine',
    locale: 'uk-UA',
  },
  {
    areaCodes: null,
    dialCode: 353,
    iso2: 'ie',
    name: 'Ireland',
    locale: 'en-IE',
  },
  {
    areaCodes: null,
    dialCode: 91,
    iso2: 'in',
    name: 'India',
    locale: 'en-IN',
  },
  {
    areaCodes: null,
    dialCode: 972,
    iso2: 'il',
    name: 'Israel',
    locale: 'he-IL',
  },
  {
    areaCodes: null,
    dialCode: 20,
    iso2: 'eg',
    name: 'Egypt',
    locale: 'ar-EG',
  },
  {
    areaCodes: null,
    dialCode: 962,
    iso2: 'jo',
    name: 'Jordan',
    locale: 'ar-JO',
  },
  {
    areaCodes: null,
    dialCode: 61,
    iso2: 'au',
    name: 'Australia',
    locale: 'en-AU',
  },
  {
    areaCodes: null,
    dialCode: 1,
    iso2: 'ca',
    name: 'Canada',
    locale: 'en-CA',
  },
  {
    areaCodes: null,
    dialCode: 44,
    iso2: 'gb',
    name: 'United Kingdom',
    locale: 'en-GB',
  },
  {
    areaCodes: null,
    dialCode: 27,
    iso2: 'za',
    name: 'South Africa',
    locale: 'en-ZA',
  },
  {
    areaCodes: null,
    dialCode: 90,
    iso2: 'tr',
    name: 'Turkey',
    locale: 'tr-TR',
  },
  {
    areaCodes: null,
    dialCode: 65,
    iso2: 'sg',
    name: 'Singapore',
    locale: 'en-SG',
  },
  {
    areaCodes: null,
    dialCode: 31,
    iso2: 'nl',
    name: 'Netherlands',
    locale: 'nl-NL',
  },
  {
    areaCodes: null,
    dialCode: 45,
    iso2: 'dk',
    name: 'Denmark',
    locale: 'da-DK',
  },
  {
    areaCodes: null,
    dialCode: 81,
    iso2: 'jp',
    name: 'Japan',
    locale: 'ja-JP',
  },
  {
    areaCodes: null,
    dialCode: 33,
    iso2: 'fr',
    name: 'France',
    locale: 'fr-FR',
  },
  {
    areaCodes: null,
    dialCode: 86,
    iso2: 'cn',
    name: 'China',
    locale: 'zh-CN',
  },
  {
    areaCodes: null,
    dialCode: 82,
    iso2: 'kr',
    name: 'South Korea',
    locale: 'ko-KR',
  },
  {
    areaCodes: null,
    dialCode: 54,
    iso2: 'ar',
    name: 'Argentina',
    locale: 'es-AR',
  },
  {
    areaCodes: null,
    dialCode: 34,
    iso2: 'es',
    name: 'Spain',
    locale: 'es-ES',
  },
  {
    areaCodes: null,
    dialCode: 39,
    iso2: 'it',
    name: 'Italy',
    locale: 'it-IT',
  },
  {
    areaCodes: null,
    dialCode: 52,
    iso2: 'mx',
    name: 'Mexico',
    locale: 'es-MX',
  },
  {
    areaCodes: null,
    dialCode: 66,
    iso2: 'th',
    name: 'Thailand',
    locale: 'th-TH',
  },
  {
    areaCodes: null,
    dialCode: 7,
    iso2: 'ru',
    name: 'Russia',
    locale: 'ru-RU',
  },
  {
    areaCodes: null,
    dialCode: 51,
    iso2: 'pe',
    name: 'Peru',
    locale: 'es-PE',
  },
  {
    areaCodes: null,
    dialCode: 507,
    iso2: 'pa',
    name: 'Panama',
    locale: 'es-PA',
  },
  {
    areaCodes: null,
    dialCode: 57,
    iso2: 'co',
    name: 'Colombia',
    locale: 'es-CO',
  },
  {
    areaCodes: null,
    dialCode: 56,
    iso2: 'cl',
    name: 'Chile',
    locale: 'es-CL',
  },
  {
    areaCodes: null,
    dialCode: 55,
    iso2: 'br',
    name: 'Brazil',
    locale: 'pt-BR',
  },
  {
    areaCodes: null,
    dialCode: 55,
    iso2: 'cr',
    name: 'Costa Rica',
    locale: 'es-CR',
  },
]
