import { CANCEL, CANCEL_SUCCESS, CANCEL_FAILED } from './constants'

export interface CancelAction {
  type: typeof CANCEL
  id: string
  redirect?: string
  roleIds?: string[]
}
export const cancelAction = (id: string, redirect?: string, roleIds?: string[]): CancelAction => ({
  type: CANCEL,
  id,
  redirect,
  roleIds,
})

export interface CancelSuccessAction {
  type: typeof CANCEL_SUCCESS
}
export const cancelSuccessAction = (): CancelSuccessAction => ({
  type: CANCEL_SUCCESS,
})

export interface CancelFailedAction {
  type: typeof CANCEL_FAILED
  error?: Error
  errorCode?: string
}
export const cancelFailedAction = (error?: Error, errorCode?: string): CancelFailedAction => ({
  type: CANCEL_FAILED,
  error,
  errorCode,
})
