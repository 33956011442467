import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'
import type { ActionsObservable } from 'redux-observable'

import { selectDonationById } from 'src/entities/donation/selectors'
import DonationsApi from 'src/entities/donation/api'
import { showErrorAction, showMessageAction } from 'src/containers/modules/Alerts/actions'

import { CANCEL, INIT, REFRESH } from './constants'
import {
  type CancelAction,
  cancelFailedAction,
  cancelSuccessAction,
  initFailedAction,
  initSuccessAction,
  refreshFailedAction,
  refreshSuccessAction,
} from './actions'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const donation = selectDonationById(state, id)
    if (!donation) {
      actions.push(DonationsApi.fetch(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const refresh = (action$) =>
  action$.ofType(REFRESH).exhaustMap(({ id }) =>
    DonationsApi.fetch(id)
      .mergeMap((resultingAction) => [resultingAction, refreshSuccessAction()])
      .catch((e) => Observable.of(refreshFailedAction(e)))
  )

const cancelPayroll = (action$: ActionsObservable<CancelAction>) =>
  action$.ofType(CANCEL).exhaustMap(({ donation }) =>
    DonationsApi.cancelPayroll(donation.id, donation.__t)
      .mergeMap((resultingAction) => [
        resultingAction,
        replace('/profile/donations'),
        showMessageAction(i18n.t('donationScreen:canceled')),
        cancelSuccessAction(),
      ])
      .catch((e) => [cancelFailedAction(e), showErrorAction(i18n.t('donationScreen:cancelingFailed'))])
  )

export default [init, refresh, cancelPayroll]
