import React from 'react'
import { View } from 'react-primitives'

interface ColumnProps {
  cols?: number
  padded?: boolean
  style?: any
}
const Column: React.FC<ColumnProps> = ({ children, style = {}, ...props }) => (
  <View
    {...props}
    style={[
      {
        // eslint-disable-next-line prefer-template
        width: props.cols ? (props.cols * 100) / 12 + '%' : 'auto',
        flexShrink: 1,
        flexGrow: 1,
        padding: props.padded ? 15 : 0,
      },
      style,
    ]}
  >
    {children}
  </View>
)
export default Column
