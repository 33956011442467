/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { Spacing, Row, Loading, LinkButton } from 'src/retired/elements'
import { MediumDown } from 'src/retired/elements/MediaQuery'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectLocations } from 'src/entities/location/selectors'
import {
  selectDisplayedLocalOrganizations,
  selectDisplayedPartnerOrganizations,
} from 'src/entities/organization/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { Category } from 'src/entities/cause/api'
import FeedList from 'src/retired/blocks/Feed/List'
import Layout from 'src/retired/blocks/Layout'
import CausesBlock from 'src/retired/blocks/CausesBlock'
import { H5 } from 'src/retired/shared/Typography'
import NonprofitsSearchBar from 'src/containers/modules/NonprofitsSearchBar'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import NonprofitsContainer from 'src/containers/screens/Nonprofits/NonprofitsContainer'
import { selectCampaignDeeds } from 'src/containers/screens/Feed/selectors'
import DonateFeedBanner from 'src/containers/screens/DonateFeed/DonateFeedBanner'
import { DonateTypes, TabType, getSearch } from 'src/containers/screens/GeneralSearch/utils'
import Button from 'src/retired/shared/Button'
import { PageTitle } from 'src/components'

import reducer from './reducer'
import epics from './epics'
import { loadFundraisersAction, loadLocalNonprofitsAction, loadPartnerNonprofitsAction } from './actions'
import { selectFundraisersLoading, selectLocalNonprofitsLoading, selectPartnerNonprofitsLoading } from './selectors'

const DonateFeed = (): JSX.Element => {
  useInjectEpics({ key: 'donateFeed', epics })
  useInjectReducer({ key: 'donateFeed', reducer })
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { t } = useTranslation('donateFeedScreen')
  const dispatch = useDispatch()
  const { colors, metrics } = useDeedTheme()

  const numberOfItems = metrics.isSmall
    ? 2
    : metrics.isMedium
    ? 4
    : metrics.screenWidth < metrics.maxWidthNarrow
    ? 6
    : 8

  const childRef = useRef()

  useEffect(() => {
    dispatch(loadFundraisersAction())
    dispatch(loadLocalNonprofitsAction(numberOfItems))
    dispatch(loadPartnerNonprofitsAction(numberOfItems))
  }, [dispatch])

  const user = useSelector(selectCurrentUser)
  const disableNonprofitSearch = user?.getSetting('partnerNonprofitsOnly') ?? true
  const deeds = useSelector((state) => selectCampaignDeeds(state, 'Campaign'))

  const isEmployee = user?.isEmployee()
  const locations = useSelector(selectLocations)
  const localOrganizations = useSelector(selectDisplayedLocalOrganizations)
  const partnerOrganizations = useSelector(selectDisplayedPartnerOrganizations)

  const fundraisersLoading = useSelector(selectFundraisersLoading)
  const localNonprofitsLoading = useSelector(selectLocalNonprofitsLoading)
  const partnerNonprofitsLoading = useSelector(selectPartnerNonprofitsLoading)

  const [causesList, setCausesList] = useState<Category[]>([])

  const localNonprofitsListProps = {
    loading: localNonprofitsLoading,
    nonprofits: localOrganizations,
    isEmployee,
    locations,
  }
  const partnerNonprofitsListProps = {
    loading: partnerNonprofitsLoading,
    nonprofits: partnerOrganizations,
    isEmployee,
    locations,
  }
  const fundraisersListProps = {
    loading: fundraisersLoading,
    deeds: deeds.slice(0, numberOfItems),
    isEmployee,
    numberOfColumns: metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1,
    corporateOnly: false,
    deedType: 'Campaign',
  }

  const causesItemsNumber = metrics.isSmall
    ? 4
    : metrics.isMedium
    ? 8
    : metrics.screenWidth > metrics.XLScreen
    ? 10
    : metrics.isLarge
    ? 8
    : 6

  interface SectionHeaderProps {
    title: string
    linkTitle?: string
    link?: string
  }

  const SectionHeader = ({ title }: SectionHeaderProps): JSX.Element => (
    <Row style={{ justifyContent: 'space-between', marginTop: 20, flexGrow: 0 }}>
      <H5 weight="500">{title}</H5>
    </Row>
  )

  const onCausesListChange = (causeList: Category[]): void => {
    setCausesList(causeList)
  }

  const ViewAllButton = ({ link }: { link: string }): JSX.Element => (
    <View style={{ display: 'flex', alignItems: 'center' }}>
      <LinkButton
        to={link}
        Container={(props: any) => (
          <Button
            {...props}
            palette="primary"
            style={{
              width: 200,
              boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 4px',
            }}
          >{t`viewAll`}</Button>
        )}
      />
    </View>
  )

  if (isAuthenticated && !user) {
    return <Loading />
  }

  return (
    <Layout padding={!!metrics.isLarge} keepTabMenu fullWidth>
      <PageTitle title={t`supportYourFavoriteCause`} />

      <View style={{ flex: 1, backgroundColor: metrics.isSmall ? colors.gray06 : 'transparent' }}>
        <MediumDown>
          {!metrics.isLarge && (
            <>
              <PendingApprovalBanner />
              <CompanyNotificationBanner />
            </>
          )}
        </MediumDown>
        <DonateFeedBanner />
        <View style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <View
            style={{
              maxWidth: metrics.isSmall ? '100%' : !metrics.isLarge ? metrics.maxWidthNarrow : metrics.maxWidthWide,
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: 'auto',
              width: '100%',
            }}
          >
            <View
              style={{
                marginLeft: -56,
                marginTop: -56,
                marginRight: -56,
                zIndex: 1000,
              }}
            >
              <View style={{ zIndex: 1000, marginTop: -20, paddingLeft: 80, paddingRight: 80 }}>
                <NonprofitsSearchBar ref={childRef} onCausesListChange={onCausesListChange} />
              </View>
            </View>
            <Spacing marginTop={20} marginBottom={40} marginLeft={20} marginRight={20}>
              {user?.hasFeature('fundraisers') &&
                (fundraisersListProps.deeds?.size > 0 || fundraisersListProps.loading) && (
                  <>
                    <SectionHeader title={t`exploreTimelyFundraisers`} />
                    <Spacing marginLeft={-8} marginRight={-8}>
                      <FeedList {...fundraisersListProps} />
                    </Spacing>
                    <ViewAllButton
                      link={`/search${getSearch({ tab: TabType.donate, donateType: DonateTypes.fundraisers })}`}
                    />
                  </>
                )}
              {!disableNonprofitSearch && (
                <>
                  <SectionHeader title={t`searchByCauseArea`} />
                  <Spacing marginBottom={metrics.isSmall ? 0 : 20} paddingBottom={14}>
                    <CausesBlock
                      causes={causesList.slice(0, causesItemsNumber)}
                      title={t`exploreByCauseArea`}
                      onPress={(cause) => {
                        childRef.current?.triggerSearch(cause.name)
                      }}
                      isListView
                      isResizable
                    />
                    <ViewAllButton link="/donate/categories" />
                  </Spacing>
                  {(localNonprofitsListProps.nonprofits?.size > 0 || localNonprofitsListProps.loading) && (
                    <>
                      <SectionHeader title={t`browseLocalOrganizations`} />
                      <Spacing marginLeft={-8} marginRight={-8}>
                        <NonprofitsContainer {...localNonprofitsListProps} />
                      </Spacing>
                      <ViewAllButton link="/donate/nonprofits" />
                    </>
                  )}
                </>
              )}
              {user?.isEmployee() &&
                (partnerNonprofitsListProps.nonprofits?.size > 0 || partnerNonprofitsListProps.loading) && (
                  <>
                    <SectionHeader title={t('affiliatedOrganizations', { orgName: user.organization.name })} />
                    <Spacing marginLeft={-8} marginRight={-8}>
                      <NonprofitsContainer {...partnerNonprofitsListProps} />
                    </Spacing>
                    <ViewAllButton link="/donate/partners" />
                  </>
                )}
            </Spacing>
          </View>
        </View>
      </View>
    </Layout>
  )
}

export default DonateFeed
