import React, { useState } from 'react'
import { View, StyleSheet } from 'react-primitives'

import { DeviceInfo } from 'src/utils'
import { Link, withRouter } from 'src/navigation'
import { Image, Gradient, Text, Touchable } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import Chip from 'src/retired/shared/Chip'
import ScrollView from 'src/retired/elements/ScrollView'
import { H5, Label } from 'src/retired/shared/Typography'
import makeGoBack from 'src/navigation/makeGoBack'
import { colors, metrics } from 'src/theme'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { IconHeader } from './IconHeader'

const imageWidth = '100%'
const imageHeight = metrics.screenWidth > 640 ? 360 : 240

export default withRouter(
  ({
    image,
    color = colors.black,
    close = false,
    noBack = false,
    renderForeground,
    renderHeader = true,
    renderTouchableForeground,
    renderTouchableFixedForeground,
    title,
    status,
    link,
    linkTitle,
    subTitle,
    subTitle2,
    children,
    history,
    maxHeight = imageHeight,
    centered,
    backTo,
    cta,
    iconData,
    outerContainerStyle,
  }) => {
    const { colors, metrics } = useDeedTheme()

    const styles = StyleSheet.create({
      image: {
        width: imageWidth,
        height: maxHeight,
      },
      back: {
        position: 'absolute',
        top: 16 + DeviceInfo.getNotchValue(),
        left: 14,
      },
      close: {
        position: 'absolute',
        top: 16 + DeviceInfo.getNotchValue(),
        right: 16,
      },
      imageOverlay: {
        position: 'absolute',
        top: 0,
        width: imageWidth,
        height: maxHeight,
        backgroundColor: colors.black,
        opacity: 0.6,
      },
      titleContainer: {
        position: 'absolute',
        width: imageWidth,
        height: '100%',
        justifyContent: 'flex-end',
        paddingHorizontal: 25,
        paddingTop: 25,
        paddingBottom: 20,
      },
      centered: {
        alignItems: 'center',
      },
      container: {
        backgroundColor: color,
        height: maxHeight,
        overflow: 'hidden',
      },
      top: {
        backgroundColor: '#fff',
      },
      corners: {
        backgroundColor: color,
        width: '100%',
        height: 30,
        marginTop: metrics.isSmall ? -10 : -27,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      },
    })

    const [isTooltipShown, setIsTooltipShown] = useState(false)

    const goBack = makeGoBack(history, backTo)

    const showImage = !!image && !iconData?.iconName
    const showIcon = !!iconData?.iconName

    return (
      <ScrollView style={outerContainerStyle}>
        <View style={styles.container}>
          {showIcon ? <IconHeader iconName={iconData.iconName} backgroundColor={iconData.hexCode} /> : null}
          {showImage ? <Image source={{ uri: image }} style={styles.image} /> : null}
          {showImage ? <View style={styles.imageOverlay} /> : null}
          {renderHeader && !noBack && close && !showIcon && (
            <View style={styles.top}>
              <Gradient
                height={25 + DeviceInfo.getNotchValue()}
                startColor="#fff"
                startOpacity={0}
                endColor={colors.darkGray}
                endOpacity={0.3}
                vertical
              />
              <View style={styles.corners} />
            </View>
          )}
          {renderForeground ? (
            renderForeground()
          ) : (
            <View style={[styles.titleContainer, centered && styles.centered]}>
              <H5 weight="500" colour={colors.white}>
                {title}
              </H5>

              {status && (
                <View style={{ marginBottom: 10, alignItems: 'flex-start' }}>
                  <Chip
                    type="square"
                    backgroundColor="grayLight"
                    textSize={12}
                    textStyle={{ textTransform: 'capitalize' }}
                  >
                    {status}
                  </Chip>
                </View>
              )}

              {link && (
                <Link style={{ color: colors.white, marginBottom: 10, textDecoration: 'underline' }} to={link}>
                  {linkTitle}
                </Link>
              )}

              {subTitle ? (
                <Label medium={!centered} colour={colors.white}>
                  {subTitle}
                </Label>
              ) : null}

              {subTitle2 ? (
                <Label medium={!centered} colour={colors.white}>
                  {subTitle2}
                </Label>
              ) : null}
            </View>
          )}
          {renderTouchableFixedForeground && renderTouchableFixedForeground()}
          {renderTouchableForeground ? (
            <View style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>{renderTouchableForeground()}</View>
          ) : null}
          {!noBack && (
            <View style={close ? styles.close : styles.back}>
              <Touchable
                onPress={goBack}
                style={{
                  width: 40,
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  icon={close ? 'crossWhite' : 'arrowLeftNew'}
                  color="white"
                  width={close ? 18 : 24}
                  height={close ? 18 : 24}
                />
              </Touchable>
            </View>
          )}
          {cta && (
            <View
              style={{
                position: 'absolute',
                top: 37,
                right: close ? 'auto' : 16,
                left: close ? 16 : 'auto',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {cta.tooltipText && isTooltipShown && (
                <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 4 }}>
                  <Text
                    style={{
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 4,
                      paddingBottom: 4,
                      backgroundColor: '#3A3A3A',
                      color: colors.white,
                      borderRadius: 4,
                      fontSize: 10,
                    }}
                  >
                    {cta.tooltipText}
                  </Text>
                  <View
                    style={{
                      backgroundColor: '#3A3A3A',
                      width: 8,
                      height: 8,
                      transform: 'translateX(-50%) rotate(-45deg)',
                    }}
                  />
                </View>
              )}
              <Touchable
                onPress={cta.action}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingLeft: 24,
                  paddingRight: 24,
                  backgroundColor: cta.style.backgroundColor,
                  borderRadius: 20,
                  borderWidth: cta.style.borderWidth,
                  borderColor: cta.style.borderColor,
                  cursor: cta.disabled ? 'not-allowed' : 'pointer',
                }}
                disabled={cta.disabled}
                onMouseEnter={() => setIsTooltipShown(true)}
                onMouseLeave={() => setIsTooltipShown(false)}
              >
                <Text style={{ color: cta.style.color, marginRight: cta.icon ? 8 : 0 }}>{cta.text}</Text>
                {cta.icon && <Icon icon={cta.icon} width={16} height={16} />}
              </Touchable>
            </View>
          )}
        </View>
        {children}
      </ScrollView>
    )
  }
)
