import React from 'react'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { useParams, useLocation } from 'src/navigation'
import DonateButton from 'src/retired/shared/DonateButton'
import AbstractCard from 'src/retired/shared/AbstractCard'
import Image from 'src/retired/elements/Image'
import Spacing from 'src/retired/elements/Spacing'
import Chip from 'src/retired/shared/Chip'
import { colors } from 'src/theme'
import Organization from 'src/entities/organization/model'
import { formatNumber } from 'src/utils/format'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { getLanguageCode } from 'src/utils/languageUtils'
import { getCauseIconData, IconData } from 'src/retired/shared/DeedCard/helper'
import { selectCampaignById } from 'src/entities/campaign/selectors'
import { State } from 'src/reducers'

export interface OrganizationCardProps {
  size: 'standard' | 'small' | 'variable'
  organization: Organization
  cardStyle?: object
  hidePartnerLogo?: boolean
  hideLocation?: boolean
}
export type OrganizationCardElement = React.ReactElement<OrganizationCardProps>

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  size,
  organization,
  cardStyle,
  hidePartnerLogo = false,
  hideLocation = false,
}): OrganizationCardElement => {
  const { t, i18n } = useTranslation('organizationCard')
  const user = useSelector(selectCurrentUser)
  const { id, lite } = useParams()
  const { pathname, search } = useLocation()
  const campaignId = pathname.includes('campaign') ? id : parse(search).campaign
  const campaign = useSelector((state: State) => selectCampaignById(state, campaignId))
  const logo = organization.logo || organization.mainPicture

  const topLeft = logo ? (
    <View
      style={[
        css`
          width: 48px;
          height: 48px;
          border-radius: 24px;
          border: 1px solid #0000001a;
          position: absolute;
          top: 0;
          left: 0;
        `,
      ]}
    >
      <View
        style={[
          css`
            width: 48px;
            height: 48px;
            border-radius: 24px;
            overflow: hidden;
            background-color: ${colors.white};
            position: relative;
            align-items: center;
            justify-content: center;
          `,
        ]}
      >
        <Image
          source={{ uri: logo }}
          resizeMode="contain"
          style={{
            width: 46,
            height: 46,
            borderRadius: 23,
            overflow: 'hidden',
          }}
        />
      </View>
    </View>
  ) : null

  let topRight = null
  if (!hidePartnerLogo && organization.isPartnerNonprofit) {
    let imageUri = null

    if (typeof campaign !== 'undefined') {
      imageUri = campaign.company.logo || campaign.company.mainPicture
    } else {
      imageUri = user?.organization?.logo || user?.organization?.mainPicture
    }

    topRight = imageUri && (
      <View
        style={[
          css`
            width: 48px;
            height: 48px;
            border-radius: 24px;
            border: 1px solid #0000001a;
            position: absolute;
            top: 0;
            right: 0;
          `,
        ]}
      >
        <View
          style={[
            css`
              width: 48px;
              height: 48px;
              border-radius: 24px;
              overflow: hidden;
              background-color: ${colors.white};
              position: relative;
              align-items: center;
              justify-content: center;
            `,
          ]}
        >
          <Image
            source={{ uri: imageUri }}
            resizeMode="contain"
            style={{
              width: 46,
              height: 46,
              borderRadius: 23,
              overflow: 'hidden',
            }}
          />
        </View>
      </View>
    )
  }

  const organizationLocation =
    organization.locationObject.city || organization.locationObject.state || organization.locationObject.country

  const bottomLeft =
    !hideLocation && organizationLocation ? (
      <Chip type="square" backgroundColor="rgba(255, 255, 255, 0.3)" textColor="white" textSize={12}>
        {organizationLocation}
      </Chip>
    ) : null

  const bottomRight =
    organization.canDonate() &&
    organization.matchingPercentage &&
    organization.matchingMaximumCurrencies &&
    organization.matchingMaximumCurrencies[organization.currencyCode] > 0 ? (
      <Chip type="square" backgroundColor="yellow" textColor="black">
        {t('timesMatched', { matchPercentage: formatNumber(organization.matchingPercentage / 100) }).toLocaleUpperCase(
          getLanguageCode(i18n.language)
        )}
      </Chip>
    ) : null

  const getFallbackIconData = (): IconData => {
    const iconData = getCauseIconData(organization.categories)
    return iconData
  }

  return (
    <AbstractCard
      wrapperProps={{ style: cardStyle }}
      size={size}
      title={organization.name}
      titleNumberOfLines={2}
      topLeft={topLeft}
      topRight={topRight}
      bottomLeft={bottomLeft}
      bottomRight={bottomRight}
      image={organization.banner || organization.mainPicture}
      fallbackIconData={!(organization.banner || organization.mainPicture) && getFallbackIconData()}
      link={`${lite ? '/lite' : ''}/organization/${organization.id}${campaignId ? `?campaign=${campaignId}` : ''}`}
    >
      <Spacing marginTop={20} style={{ minHeight: 32 }}>
        {organization.canDonate() && (!user || user?.hasFeature('donations')) && (
          <DonateButton
            model={organization}
            size="extraSmall"
            style={{
              marginLeft: 'auto',
              alignSelf: 'flex-end',
            }}
          >
            {t`donate`}
          </DonateButton>
        )}
      </Spacing>
    </AbstractCard>
  )
}

export default OrganizationCard
