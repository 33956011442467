const getFullPaymentMethodName = (paymentMethod: string | undefined): string => {
  switch (paymentMethod) {
    case 'PayPal':
      return 'Paypal Account'

    case 'DeedCredit':
      return 'Donation credits'

    case 'Payroll':
      return 'Payroll Deduction'

    default:
      return 'Card'
  }
}

export default getFullPaymentMethodName
