import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { InformationCard } from 'src/components'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const BackupWidget = ({ style }: { style: React.CSSProperties }) => {
  const { t } = useTranslation('homeScreen')
  const { metrics } = useDeedTheme()

  return (
    <InformationCard
      title={t`backupWidget-title`}
      subtitle={t`backupWidget-subtitle`}
      style={style}
      headerStyle={{ height: '100%' }}
      headerContent={
        <View style={{ flex: 1, justifyContent: 'center', paddingTop: metrics.isSmall ? 12 : 24 }}>
          <Icon icon="highfiveIllustration" />
        </View>
      }
    />
  )
}
