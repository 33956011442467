import React from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import { Row, Spacing } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import NonprofitsSearch from 'src/containers/modules/NonprofitsSearch'

import { setStepAction } from './actions'

const SelectNonprofit = ({
  formik,
  acceptsDonations,
  required = true,
}: {
  formik: any
  acceptsDonations?: boolean
  required: boolean
}) => {
  const { t } = useTranslation('createDeedScreen')
  const { colors } = useDeedTheme()

  const dispatch = useDispatch()

  return (
    <>
      {!!formik.values.nonprofit && (
        <Row style={{ marginBottom: 30 }}>
          <Body2 weight="500" marginRight={20}>
            {t`selected`}
          </Body2>
          <View style={{ flex: 1 }}>
            <Body2 weight="500">{formik.values.nonprofit.name}</Body2>
            <Body2 weight="500" colour={colors.grayText}>
              {formik.values.nonprofit.locationObject.toString()}
            </Body2>
          </View>
        </Row>
      )}
      <NonprofitsSearch
        acceptsDonations={acceptsDonations}
        onSetSelectedNonprofit={(_nonprofitId, nonprofit) => {
          if (nonprofit) {
            formik.setFieldValue('nonprofit', nonprofit)
            formik.setFieldValue('currency', nonprofit?.get('currencyCode'))
          }
        }}
        bottomAction={
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Body2 center colour={colors.white}>
              {t`notOnDeed`}{' '}
            </Body2>
            <Link
              to={{
                pathname: '/nominate-nonprofit',
              }}
            >
              <Body2 colour={colors.white} underline style={{ textDecorationColor: colors.white }}>
                {t`nominateThisOrganization`}
              </Body2>
            </Link>
          </View>
        }
        emptyAction={<Body1 center>{t`sorryWeWerentAbleToFind`}</Body1>}
      />
      <Spacing marginTop={30}>
        <Button
          palette="primary"
          disabled={required && !formik.values.nonprofit}
          onPress={() => dispatch(setStepAction(1))}
        >
          {required || formik.values.nonprofit ? t`next` : t`proceedWithoutNonprofit`}
        </Button>
      </Spacing>
    </>
  )
}

SelectNonprofit.displayName = 'SelectNonprofit'

export default SelectNonprofit
