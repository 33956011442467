import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import NonprofitsSearch from 'src/containers/modules/NonprofitsSearch'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useHistory } from 'src/navigation'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Screen, ScrollView, Spacing } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Body2, H4, Text } from 'src/retired/shared/Typography'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'

const Header = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`

const FormContainer = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '195px' : '15px')};
  margin-bottom: 50px;
`

const VolunteerTimeSelectNonprofit = () => {
  const { t } = useTranslation('volunteerTimeSelectedNonprofitProfile')
  const { colors } = useDeedTheme()
  const history = useHistory()
  const user = useSelector(selectCurrentUser)
  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')

  const [selectedNonprofit, setSelectedNonprofit] = useState('')
  const [showVolunteerRewardWarning, setShowVolunteerRewardWarning] = useState(false)

  const handleSkipThisStep = () => {
    if (isVolunteerRewardEnabled) {
      setShowVolunteerRewardWarning(true)
    } else {
      history.push('/profile/volunteer-hours/add')
    }
  }

  const handleContinueSkip = () => {
    setShowVolunteerRewardWarning(false)
    history.push('/profile/volunteer-hours/add')
  }

  return (
    <Screen fixed>
      <ModalWithCloseButton
        visible={showVolunteerRewardWarning}
        onClose={() => setShowVolunteerRewardWarning(false)}
        title={t`volunteerRewardWarningTitle`}
      >
        <View style={{ alignItems: 'space-between', minHeight: 40, paddingHorizontal: 40 }}>
          <View style={{ paddingVertical: 16, maxWidth: 500 }}>
            <Body2 style={{ textAlign: 'center' }}>{t`volunteerRewardWarningMessage`}</Body2>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'center', columnGap: 16 }}>
            <Button palette="secondary" rounded onPress={() => setShowVolunteerRewardWarning(false)}>
              {t`common:Cancel`}
            </Button>
            <Button palette="primary" rounded onPress={handleContinueSkip}>{t`common:continue`}</Button>
          </View>
        </View>
      </ModalWithCloseButton>
      <ScrollView>
        <NavigationHeader transparent title={t`logHours`} />
        <Header>
          <H4 center marginBottom={8}>
            {t`selectTheOrganizationYouVolunteeredFor`}
          </H4>
          <Text fontSize={12} lineHeight={21} center>
            {t`letsLogYourVolunteerSessions`}
          </Text>
        </Header>
        <FormContainer>
          <NonprofitsSearch
            onSetSelectedNonprofit={setSelectedNonprofit}
            bottomAction={
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <Text center fontSize={12} colour={colors.white}>
                  {t`cantFindWhatYouAreLookingFor`}{' '}
                </Text>
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    shadowOpacity: 0,
                    border: 1,
                    width: 'fit-content',
                    height: 18,
                    padding: 0,
                  }}
                  textStyle={{ margin: 0 }}
                  onPress={handleSkipThisStep}
                >
                  <Text fontSize={12} colour={colors.white} underline style={{ textDecorationColor: colors.white }}>
                    {t`skipThisStep`}
                  </Text>
                </Button>
              </View>
            }
            emptyAction={
              <>
                <Text fontSize={14} center>
                  {t`cantFindWhatYouAreLookingFor`}{' '}
                </Text>
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    shadowOpacity: 0,
                    border: 1,
                    width: 'fit-content',
                    padding: 0,
                    alignSelf: 'center',
                  }}
                  textStyle={{ margin: 0 }}
                  onPress={handleSkipThisStep}
                >
                  <Text
                    fontSize={12}
                    colour={colors.brandColor}
                    underline
                    center
                    style={{ textDecorationColor: colors.brandColor }}
                  >
                    {t`skipThisStep`}
                  </Text>
                </Button>
              </>
            }
          />
          <Spacing marginTop={30}>
            <Button
              onPress={() => history.push(`/profile/volunteer-hours/add/${selectedNonprofit}/nonprofit`)}
              palette="primary"
              disabled={!selectedNonprofit}
              style={{ justifyContent: 'center' }}
              rounded
            >
              {t`common:Next`}
            </Button>
          </Spacing>
        </FormContainer>
      </ScrollView>
    </Screen>
  )
}

VolunteerTimeSelectNonprofit.displayName = 'VolunteerTimeSelectNonprofit'
export default VolunteerTimeSelectNonprofit
