export const INIT = 'containers/VolunteerTime/INIT'
export const INIT_SUCCESS = 'containers/VolunteerTime/INIT_SUCCESS'
export const INIT_FAILED = 'containers/VolunteerTime/INIT_FAILED'

export const DELETE = 'containers/VolunteerTime/DELETING'
export const DELETE_SUCCESS = 'containers/VolunteerTime/DELETE_SUCCESS'
export const DELETE_FAILED = 'containers/VolunteerTime/DELETE_FAILED'

export interface InitAction {
  type: typeof INIT
  id: string
}
export const initAction = (id: string): InitAction => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: Error) => ({
  type: INIT_FAILED,
  error,
})

export interface DeleteAction {
  type: typeof DELETE
  id: string
  skipGoBack?: boolean
}
export const deleteAction = (id: string, skipGoBack?: boolean): DeleteAction => ({
  type: DELETE,
  id,
  skipGoBack,
})
export const deleteSuccessAction = () => ({
  type: DELETE_SUCCESS,
})
export const deleteFailedAction = (error: Error) => ({
  type: DELETE_FAILED,
  error,
})
