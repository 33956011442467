import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, H4 } from 'src/retired/shared/Typography'
import Row from 'src/retired/elements/Row'
import Spacing from 'src/retired/elements/Spacing'
import { DeedMap } from 'src/entities/deed/reducer'
import FeedList from 'src/retired/blocks/Feed/List'
import { Button } from 'src/retired/elements'

interface DeedTitlesProps {
  customFont?: string
  title: React.ReactNode
  subTitle: React.ReactNode
  color?: string
  rowStyle?: React.CSSProperties
}

export const CampaignSectionHeader = ({
  customFont,
  title,
  subTitle,
  color,
  rowStyle,
}: DeedTitlesProps): JSX.Element => {
  const { colors } = useDeedTheme()
  return (
    <Row style={{ marginTop: 20, marginBottom: 30, alignItems: 'center', ...rowStyle }}>
      <H3 weight="500" colour={color || colors.white} customFont={customFont}>
        {title}
      </H3>
      <H4 colour={color || colors.white} customFont={customFont} style={{ opacity: 0.6 }} marginLeft={5}>
        {subTitle}
      </H4>
    </Row>
  )
}

export interface CampaignSectionBlockProps {
  title: string
  customFont?: string
  deedList: DeedMap
  withSpacing?: boolean
}

export const useDeedsItemsNumber = (): number => {
  const { metrics } = useDeedTheme()

  if (metrics.isSmall) {
    return 2
  }
  if (metrics.isMedium) {
    return 4
  }
  if (metrics.screenWidth < metrics.maxWidthNarrow) {
    return 6
  }
  return 8
}

export const CampaignSectionBlock = ({
  title,
  customFont,
  deedList,
  withSpacing = true,
}: CampaignSectionBlockProps): JSX.Element | null => {
  const { t } = useTranslation('campaignScreen')
  const deedsItemsNumber = useDeedsItemsNumber()
  const { metrics } = useDeedTheme()
  const [viewAll, setViewAll] = useState(false)

  if (!deedList.size) {
    return null
  }

  return (
    <Spacing marginBottom={withSpacing ? 40 : 0}>
      <CampaignSectionHeader customFont={customFont} title={title} subTitle={deedList.size} />
      <FeedList
        deeds={viewAll ? deedList : deedList.slice(0, deedsItemsNumber)}
        numberOfColumns={metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1}
        backgroundColorForSmallScreens="transparent"
      />
      {deedsItemsNumber < deedList.size && (
        <View style={{ alignItems: 'center' }}>
          <Button style={{ marginHorizontal: 'auto' }} onPress={() => setViewAll((v) => !v)}>
            {viewAll ? t`viewLess` : t`viewMore`}
          </Button>
        </View>
      )}
    </Spacing>
  )
}
