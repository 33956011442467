import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import truthy from 'src/utils/truthy'

import { SUBMIT } from './constants'
import { submitSuccessAction, submitFailedAction } from './actions'

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(() =>
      UserApi.fetch('me').mergeMap((action) =>
        [
          action,
          submitSuccessAction(),
          action.user.status !== 'accepted'
            ? showErrorAction(i18n.t('companyRegister:pleaseCheckYourInbox'))
            : replace(
                action.user.organization && action.user.organization.departments.length > 0
                  ? '/register/company/department'
                  : '/register/location'
              ),
        ].filter(truthy)
      )
    )
    .catch((e) =>
      Observable.of(submitFailedAction(e), showErrorAction(i18n.t('companyRegister:checkingConfirmationFailed')))
    )

export default [submit]
