import React, { useRef, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Spacing } from 'src/retired/elements'
import { MediumDown } from 'src/retired/elements/MediaQuery'
import { Category } from 'src/entities/cause/api'
import Layout from 'src/retired/blocks/Layout'
import makeGoBack from 'src/navigation/makeGoBack'
import { useHistory } from 'src/navigation'
import NonprofitsSearchBar from 'src/containers/modules/NonprofitsSearchBar'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import List from 'src/retired/blocks/List'
import CauseCard from 'src/retired/shared/CauseCard'
import IconButton from 'src/retired/shared/IconButton'
import { H3 } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'

const Categories = (): JSX.Element => {
  const { t } = useTranslation('categoriesScreen')
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()

  const childRef = useRef()

  const [causesList, setCausesList] = useState<Category[]>([])

  const onCausesListChange = (causeList: Category[]): void => {
    setCausesList(causeList)
  }

  const goBack = makeGoBack(history, '/donate')

  return (
    <Layout keepTabMenu>
      <PageTitle title={t`causes`} />
      <View style={{ flex: 1, backgroundColor: metrics.isSmall ? colors.gray06 : 'transparent' }}>
        <MediumDown>
          {!metrics.isLarge && (
            <>
              <PendingApprovalBanner />
              <CompanyNotificationBanner />
            </>
          )}
        </MediumDown>
        <Spacing marginTop={40} marginBottom={40} marginLeft={20} marginRight={20}>
          <Spacing marginBottom={20}>
            <Row style={{ flexGrow: 0 }}>
              <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />
              <View style={{ flexShrink: 1, paddingVertical: 1 }}>
                <H3 numberOfLines={1}>{t`causes`}</H3>
              </View>
            </Row>
          </Spacing>
          <Spacing marginBottom={35} style={{ zIndex: 1 }}>
            <NonprofitsSearchBar ref={childRef} onCausesListChange={onCausesListChange} />
          </Spacing>
          <Spacing marginTop={14}>
            <List
              data={causesList}
              renderItem={({ item }) => (
                <CauseCard
                  cause={item}
                  onPress={() => {
                    childRef.current!.triggerSearch(item.name)
                  }}
                  isResizable
                />
              )}
              itemProps={{
                style: {
                  width: metrics.isSmall
                    ? '50%'
                    : metrics.isMedium
                    ? '25%'
                    : metrics.screenWidth > metrics.XLScreen
                    ? '20%'
                    : metrics.isLarge
                    ? '25%'
                    : '33.3%',
                },
              }}
              keyExtractor={(item) => item.get('id')}
            />
          </Spacing>
        </Spacing>
      </View>
    </Layout>
  )
}

export default Categories
