import { Record, List } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

import { currencies, CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import type {
  DonationFrequency,
  DonationPrivacy,
  DonationScheduleStatus,
  PaymentMethodType,
} from 'src/generated/graphql'

import Campaign from '../campaign/model'
import Organization from '../organization/model'

interface PaymentMethod {
  id?: string
  type?: PaymentMethodType | null | undefined
}

interface Failure {
  reason?: string
  code?: string
  message?: string | null
}

const properties = {
  id: '',
  frequency: undefined,
  startingAt: undefined,
  endingAt: undefined,
  createdAt: undefined,
  amount: 0,
  status: '',
  currencyCode: '',
  nonprofits: List(),
  privacy: undefined,
  provider: '',
  paymentMethod: undefined,
  executionFailure: undefined,
  campaign: undefined,
}

export default class DonationSchedule extends Record(properties, 'DonationSchedule') implements DonationSchedule {
  public readonly id!: string

  public readonly campaign?: Campaign

  public readonly frequency?: DonationFrequency

  public readonly startingAt!: Date

  public readonly endingAt?: Date

  public readonly createdAt!: Date

  public readonly amount!: number

  public readonly status!: DonationScheduleStatus | null | undefined

  public readonly currencyCode!: CurrencyCode

  public readonly nonprofits!: List<Organization>

  public readonly privacy!: DonationPrivacy | null | undefined

  public readonly dedication!: string

  public readonly designation!: string

  public readonly provider!: string

  public readonly paymentMethod!: PaymentMethod

  public readonly executionFailure?: Failure[]

  constructor(values: Partial<Omit<DonationSchedule, 'nonprofits'> & { nonprofits?: Partial<Organization>[] }> = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    const { id, nonprofits = [], startingAt, createdAt, endingAt, campaign } = values

    const nonprofitsList = List(nonprofits.map((nonprofit: Partial<Organization>) => new Organization(nonprofit)) || [])

    super({
      ...validValues,
      id,
      campaign: campaign && new Campaign(campaign),
      nonprofits: nonprofitsList,
      startingAt: new Date(startingAt as Date),
      createdAt: new Date(createdAt as Date),
      endingAt: endingAt && new Date(endingAt),
    })
  }

  public getCurrencySymbol(): string {
    return currencies?.[this.currencyCode?.toUpperCase()]?.symbol || '$'
  }

  public isPayroll(): boolean {
    return this.paymentMethod?.type === 'Payroll'
  }

  public isCurrentlyPledged(): boolean {
    if (!this.campaign) {
      return false
    }

    return this.campaign.isActivePledge()
  }

  public isAssociatedToPledgeCampaign(): boolean {
    return !!this.campaign?.isPledge
  }

  public isRecurring() {
    const { endingAt } = this

    if (moment(moment.now()).isAfter(endingAt)) {
      return false
    }

    return true
  }
}
