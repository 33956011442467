import React from 'react'
import { View } from 'react-primitives'

import { css, styled } from 'src/theme/styled'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { Body1, Body2, H3, H4, H5 } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'
import Spacing from 'src/retired/elements/Spacing'
import Gradient from 'src/retired/elements/Gradient'
import Image from 'src/retired/elements/Image'
import Campaign from 'src/entities/campaign/model'
import { Link, useHistory } from 'src/navigation'
import { resize } from 'src/utils/resize'

interface TouchableStyledProps {
  ctaButtonColor?: string
}
const TouchableStyled = styled(Touchable)<TouchableStyledProps>(
  ({ ctaButtonColor, theme: { metrics, colors } }: TouchableStyledProps & { theme: EmotionTheme }) => ({
    backgroundColor: ctaButtonColor || colors.black,
    justifyContent: 'center',
    paddingHorizontal: metrics.isSmall ? 16 : 60,
    paddingVertical: metrics.isSmall ? 8 : 24,
    borderRadius: 4,
  })
)

const CampaignItem = ({ campaign }: { campaign: Campaign }): JSX.Element => {
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()

  const width = metrics.isLarge ? 930 : metrics.screenWidth - 40

  const aspectRatio = 368 / width
  const CampaignTitle = metrics.isSmall ? H4 : H3
  const CampaignDescription = metrics.isSmall ? Body1 : H5
  const CampaignCtaTitle = metrics.isSmall ? Body2 : Body1

  return (
    <Link key={campaign.id} to={`/campaign/${campaign.id}`}>
      <View
        style={css`
          border-radius: 16px;
          margin-bottom: ${`${metrics.isLarge ? 0 : 20}px`};
          margin-left: 10px;
          margin-right: 10px;
          padding-bottom: ${`${aspectRatio * 100}%`};
          width: ${`${width}px`};
          overflow: hidden;
          background-color: ${campaign.backgroundHeroColor || 'transparent'};
        `}
      >
        {!campaign.backgroundHeroColor && (
          <View
            style={css`
              position: absolute;
              width: 100%;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
            `}
          >
            <Gradient
              width="100%"
              height="100%"
              startColor={campaign.colorA}
              vertical={false}
              endColor={campaign.colorB}
            />
          </View>
        )}
        <View
          style={css`
            position: absolute;
            width: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            padding: ${campaign.bannerSquare || campaign.bannerRectangle || campaign.backgroundImage ? '0' : '0 20px'};
          `}
        >
          {campaign.bannerSquare || campaign.bannerRectangle ? (
            <Image
              source={{
                uri: metrics.isSmall ? campaign.bannerSquare : campaign.bannerRectangle,
              }}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <>
              {campaign.backgroundImage ? (
                <View
                  style={css`
                    position: absolute;
                    width: 100%;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                  `}
                >
                  <Image
                    source={{ uri: resize(campaign.backgroundImage, metrics.isSmall ? '1200x1200' : '1504x1002') }}
                    style={{ width: '100%', height: '100%' }}
                  />
                  <View
                    style={{
                      width: '100%',
                      height: '100%',
                      opacity: 0.4,
                      backgroundColor: '#000',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                </View>
              ) : null}
              <View
                style={css`
                  z-index: 1;
                  flex: 1;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  padding: ${metrics.isLarge ? '0 40px' : '0 20px'};
                `}
              >
                <CampaignDescription colour={campaign.nameColor || campaign.ctaButtonColor || colors.white} center>
                  {campaign.description}
                </CampaignDescription>

                <Spacing marginTop={12} />

                <CampaignTitle
                  colour={colors.white}
                  letterSpacing={metrics.isLarge ? 1.2 : metrics.isMedium ? 1 : 0.75}
                  lineHeight={metrics.isLarge ? 54 : metrics.isMedium ? 45 : 41}
                  center
                  style={{ textTransform: 'uppercase' }}
                >
                  {campaign.title}
                </CampaignTitle>

                <Spacing marginTop={15} />

                <TouchableStyled
                  ctaButtonColor={campaign.ctaButtonColor}
                  onPress={() => {
                    history.push(`/campaign/${campaign.id}`)
                  }}
                >
                  <CampaignCtaTitle
                    weight="500"
                    colour={campaign.ctaTextColor || colors.white}
                    letterSpacing={0.67}
                    center
                  >
                    {campaign.cta}
                  </CampaignCtaTitle>
                </TouchableStyled>
              </View>
            </>
          )}
        </View>
      </View>
    </Link>
  )
}

export default CampaignItem
