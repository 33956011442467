import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedSkillsAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedSkillsSynchronizer = () => {
  const dispatch = useDispatch()
  const { currentActiveSkills } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    if (!currentActiveSkills?.size) {
      dispatch(setSelectedSkillsAction([]))
      return
    }

    const skillIds = currentActiveSkills.toArray().map((skill) => skill.id)

    dispatch(setSelectedSkillsAction(skillIds))
  }, [currentActiveSkills])

  return null
}
