import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import { initialState, ProfileSettingsState } from './reducer'

const selectState = (state: State): ProfileSettingsState => state.get('profileSettings') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
