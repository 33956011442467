import React from 'react'
import { Alert, Box, CircularProgress, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useRemoveCommunityUsersMutation } from 'src/generated/graphql'
import { Modal, ModalButton, ModalContents, ModalTitle } from 'src/components/Modal/Modal'
import { colors } from 'src/theme'
import { UserSelected } from 'src/components/UserSelected/UserSelected'
import { Row } from 'src/retired/elements'
import { BackButton } from 'src/components/Buttons/BackButton/BackButton'

import { CommunityMembers } from '../../types'

interface Props {
  members: CommunityMembers
  communityId: string
  onClickClose: () => void
  removeMember: (userId: string) => void
}

export const RemoveMembersModal = ({ members, communityId, onClickClose, removeMember }: Props) => {
  const { t } = useTranslation('communityScreen')
  const userIds = members.map((m) => m.User.id)
  const [removeCommunityUsers, { loading, error }] = useRemoveCommunityUsersMutation({
    onCompleted: () => {
      onClickClose()
    },
    variables: {
      communityId,
      userIds,
    },
    // Evict the removed members from the cache
    update(cache) {
      const normalizedId = cache.identify({ id: communityId, __typename: 'Community' })
      cache.evict({
        id: normalizedId,
        fieldName: 'membersCount',
      })
      cache.evict({
        id: normalizedId,
        fieldName: 'members',
      })
      cache.gc()
    },
  })

  return (
    <Modal open fullWidth onClose={onClickClose}>
      <ModalContents
        onClickClose={onClickClose}
        body={
          <View>
            <Row style={{ alignItems: 'center', marginBottom: 30 }}>
              <BackButton onPress={onClickClose} />
              <ModalTitle style={{ marginBottom: 0, marginLeft: 12 }}>
                {t('removeMembers', { count: members.length })}
              </ModalTitle>
            </Row>
            <Box paddingBottom={4}>
              {error ? (
                <Alert severity="error" sx={{ '& .v5-MuiAlert-message': { marginTop: '2px' } }}>
                  {t`removeUserError`}
                </Alert>
              ) : (
                <Alert severity="warning" sx={{ '& .v5-MuiAlert-message': { marginTop: '2px' } }}>
                  {t('removeUserConfirmation', { count: members.length })}
                </Alert>
              )}
            </Box>

            <Grid container spacing={1}>
              {members.map((member) => (
                <Grid key={member.User.id} item xs={12} md={6}>
                  <UserSelected user={member.User} removeMember={removeMember} />
                </Grid>
              ))}
            </Grid>
          </View>
        }
        actions={
          <>
            <ModalButton
              style={{ color: colors.primaryBlue, marginRight: 16 }}
              variant="outlined"
              onClick={onClickClose}
              disabled={loading}
            >
              {t`common:Cancel`}
            </ModalButton>
            <ModalButton
              disabled={members.length === 0 || loading}
              color="error"
              onClick={() => {
                void removeCommunityUsers()
              }}
            >
              {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : t`remove`}
            </ModalButton>
          </>
        }
      />
    </Modal>
  )
}
