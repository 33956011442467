import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Row } from 'src/retired/elements'
import { styled } from 'src/theme/styled'
import { Body2 } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'

import { useSearchParams } from './utils'
import { FilterIcon } from './FilterIcon'

const StyledFilterContainer = styled(View)(() => ({
  marginTop: 24,
  paddingHorizontal: 30,
}))

const FilterButton = styled(View)(() => ({
  width: 158,
  height: 42,
  padding: 12,
  borderWidth: 1,
  borderRadius: 20,
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const MobileOpenFiltersButton = ({ setFiltersShown }: { setFiltersShown: (value: boolean) => void }) => {
  const { t } = useTranslation('searchScreen')
  const { filtersCount } = useSearchParams()

  return (
    <StyledFilterContainer>
      <FilterButton>
        <Touchable onPress={() => setFiltersShown(true)}>
          <Row style={{ alignItems: 'center' }}>
            <Body2 style={{ marginRight: 8 }}>
              {t`showFilters`}&nbsp;&bull;&nbsp;{filtersCount + 1}
            </Body2>
            <FilterIcon />
          </Row>
        </Touchable>
      </FilterButton>
    </StyledFilterContainer>
  )
}
