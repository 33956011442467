import { createSelector } from 'reselect'

import Setting from './model'

export const selectSettings = (state: any): Setting => state.get('entities').setting

export const selectSetting = createSelector(
  selectSettings,
  (state: any, key: string) => key,
  (settings, key) => settings.get(key)
)
