import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import Organization from 'src/entities/organization/model'
import { Checkbox } from 'src/retired/elements'
import { styled } from 'src/theme/styled'

import { GIFT_AID_KEY } from '../actionNames'

import CollapsibleText from './CollapsibleText'

const Row = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: start;
`

interface GiftAidCheckboxType {
  selected: boolean
  setGiftAidApplies: (applies: boolean) => void
  nonprofit: Organization
}

const nonprofitIsEligibleForGiftAid = (nonprofit: Organization) => nonprofit.externalId?.startsWith?.('ext-pp-GB')

const GiftAidCheckbox = ({ selected, setGiftAidApplies, nonprofit }: GiftAidCheckboxType) => {
  const { t } = useTranslation('donateScreen')

  const toggleGiftAidCheckbox = () => {
    setGiftAidApplies((prev) => !prev)
  }

  if (!nonprofitIsEligibleForGiftAid(nonprofit)) {
    return null
  }

  return (
    <Row>
      <Checkbox key={GIFT_AID_KEY} checked={selected} onChange={toggleGiftAidCheckbox} />
      <CollapsibleText primaryText={t`giftAidPrimaryText`} secondaryText={t`giftAidSecondaryText`} />
    </Row>
  )
}

export default GiftAidCheckbox
