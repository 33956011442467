import React, { FC } from 'react'

import CauseCardList from 'src/retired/shared/CauseCardList'
import CauseCard from 'src/retired/shared/CauseCard'
import ScrollContainer from 'src/retired/shared/ScrollContainer'
import Cause from 'src/entities/cause/model'
import List from 'src/retired/blocks/List'
import { Spacing } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface CausesBlockProps {
  causes: Cause[]
  title: string
  link?: string
  linkColour?: string
  back?: string
  small?: boolean
  onPress?: (cause: Cause) => void
  isListView?: boolean
  isResizable?: boolean
}

const CausesBlock: FC<CausesBlockProps> = ({
  causes,
  title,
  link,
  linkColour,
  small,
  back,
  onPress,
  isListView,
  isResizable,
}) => {
  const { metrics } = useDeedTheme()
  const numberOfColumns = metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListView = (): JSX.Element => (
    <List
      numColumns={numberOfColumns}
      style={{
        marginLeft: metrics.isLarge && !small ? -8 : -4,
        marginRight: metrics.isLarge && !small ? -8 : -4,
        marginTop: metrics.isLarge && !small ? 20 : 14,
        marginBottom: metrics.isLarge && !small ? 20 : 14,
        zIndex: -1,
      }}
      itemProps={{
        style: {
          width: metrics.isSmall
            ? '50%'
            : metrics.isMedium
            ? '25%'
            : metrics.screenWidth > metrics.XLScreen
            ? '20%'
            : metrics.isLarge
            ? '25%'
            : '33.3%',
        },
      }}
      data={causes}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <CauseCard
          cause={item}
          forceLarge={false}
          small={small}
          onPress={onPress ? () => onPress(item) : undefined}
          isResizable={isResizable}
        />
      )}
      ListFooterComponent={<Spacing marginBottom={10} />}
      loading={false}
    />
  )

  const isRowView = !link || isListView

  return causes.length === 0 ? null : isListView ? (
    <ListView />
  ) : (
    <ScrollContainer
      title={title}
      subtitle={causes.length}
      cardType="card"
      back={back}
      link={link}
      linkColour={linkColour}
      dimension={causes.length}
      hideNavArrow={isRowView}
      small={small}
      numColumns={metrics.screenWidth < 375 ? 1 : metrics.screenWidth > 640 ? 3 : 2}
      render={(scrollViewOffset) =>
        isRowView ? (
          <ListView />
        ) : (
          <Spacing marginBottom={20}>
            <CauseCardList causes={causes} small={small} scrollViewOffset={scrollViewOffset} onPress={onPress} />
          </Spacing>
        )
      }
    />
  )
}
export default CausesBlock
