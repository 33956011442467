import { parse, stringify } from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectAuth, selectIsAuthenticated } from 'src/entities/auth/selectors'
import Deed from 'src/entities/deed/model'
import Organization from 'src/entities/organization/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { useHistory, useLocation, useParams } from 'src/navigation'
import Button from 'src/retired/elements/Button'
import { Communications, Platform } from 'src/utils'
import { useWebUrl } from 'src/utils/webUrl'

import { makeUserClickedDonateEvent, track } from './analytics'

interface DonateButtonProps {
  model: Deed | Organization
  children?: React.ReactNode
  ignoreCampaignParam: boolean
}

interface QueryParams {
  campaign?: string
  prevDonation?: string | string[]
}

export type DonateButtonElement = React.ReactElement<DonateButtonProps>

const DonateButton: React.FC<DonateButtonProps> = ({
  model,
  children,
  ignoreCampaignParam = false,
  ...rest
}): DonateButtonElement => {
  const { t } = useTranslation('donateButton')
  const history = useHistory()
  const { id, lite } = useParams<{ id?: string; lite?: string }>()
  const { pathname, search } = useLocation()
  const webUrl = useWebUrl()
  const campaignId = pathname.includes('campaign') ? id : parse(search).campaign
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const allowDonation = isAuthenticated || lite || model.type === 'Nonprofit' || (model instanceof Deed && model.public)
  const previousDonation = parse(search).prevDonation
  const user = useSelector(selectCurrentUser)

  return (
    <Button
      onPress={(e: { preventDefault: () => void }) => {
        e.preventDefault()
        if (Platform.OS !== 'web') {
          Communications.web(
            `${webUrl}/login/token?d=/donate/${model instanceof Deed ? 'deed' : 'nonprofit'}/${model.id}#${auth.token}`
          )
        } else {
          const queryParams: QueryParams = {}

          if (campaignId && !ignoreCampaignParam) {
            queryParams.campaign = campaignId
          }
          if (previousDonation) {
            queryParams.prevDonation = previousDonation
          }

          const donateRoute = `${lite ? '/lite' : ''}/${model instanceof Deed ? 'deeds' : 'organization'}/${
            model.id
          }/donate?${stringify(queryParams)}`

          history.push(allowDonation ? donateRoute : '/login')
          if (!isAuthenticated) {
            dispatch(setLocalSettingAction('previousLocation', donateRoute))
            if (window?.sessionStorage) {
              window.sessionStorage.setItem('previousLocation', donateRoute)
            }
          }
        }

        if (model instanceof Organization) {
          try {
            const event = makeUserClickedDonateEvent({ nonprofitId: model.id, userId: user?.id })
            void track(event)
          } catch (exception) {
            // @NOTE-AT: Nothing to do - we couldn't send the event here
          }
        }
      }}
      color="primary"
      fluid
      {...rest}
    >
      {children || t`donate`}
    </Button>
  )
}

export default DonateButton
