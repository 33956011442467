import Donation from 'src/entities/donation/model'
import { DonationStatus, PaymentMethodType } from 'src/generated/graphql'
import { ColorsProps } from 'src/theme/colors'

export const getDonationColor = (donation: Donation, colors: ColorsProps): string => {
  if (donation.paymentMethodDetails.type === PaymentMethodType.External) {
    return colors.grayMediumDark
  }

  if (donation.paymentMethodDetails.type === PaymentMethodType.Payroll) {
    return colors.blue
  }

  switch (donation.status) {
    case DonationStatus.Failed:
      return colors.darkPink

    case DonationStatus.Pending:
      return colors.yellow

    default:
      return colors.blue
  }
}
