export const INIT = 'containers/VolunteerTimes/INIT'
export const INIT_SUCCESS = 'containers/VolunteerTimes/INIT_SUCCESS'
export const INIT_FAILED = 'containers/VolunteerTimes/INIT_FAILED'

export interface InitAction {
  type: typeof INIT
}
export const initAction = (): InitAction => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: Error) => ({
  type: INIT_FAILED,
  error,
})
