import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Divider, Loading, Spacing } from 'src/retired/elements'
import { Text } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import DeedCard from 'src/retired/shared/DeedCard'
import Deed from 'src/entities/deed/model'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { selectSubmitting } from './selectors'
import { setStepAction } from './actions'

const Review = ({ formik }) => {
  const { t } = useTranslation('createDeedScreen')
  const submitting = useSelector(selectSubmitting)
  const user = useSelector(selectCurrentUser)

  const dispatch = useDispatch()

  const deed = new Deed({
    __t: formik.values.deedType,
    status: formik.values.status,
    nonprofits: formik.values.nonprofit && [formik.values.nonprofit.toJSON()],
    organization: formik.values.nonprofit?.toJSON(),
    locationObject: formik.values.nonprofit?.get('locationObject')?.toJSON(),
    location: String(formik.values.nonprofit?.get('locationObject')),
    locationLatLng: String(formik.values.nonprofit?.get('locationLatLng')),
    goalAmount: formik.values.amount,
    goalAmountCurrencies: {
      [formik.values.currency]: formik.values.amount,
    },
    donatedAmount: 0,
    donatedAmountCurrencies: {
      [formik.values.currency]: 0,
    },
    donationStats: {
      employeeAmount: 0,
      employeeAmountCurrencies: {},
      otherAmount: 0,
      otherAmountCurrencies: {},
      matchedByEmployerAmount: 0,
      matchedByEmployerAmountCurrencies: {},
      totalAmountCurrencies: {},
    },
    currencyCode: formik.values.currency,
    startingAt: formik.values.startingAt ?? new Date().toISOString(),
    endingAt: formik.values.endingAt ?? undefined,
    pictures: [formik.values.picture],
    mainPicture: formik.values.picture,
    name: formik.values.name,
    description: formik.values.description,
    timeZone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    attendeeLimit: formik.values.attendeeLimit,
  })

  return (
    <>
      <Divider />
      <DeedCard size="variable" deed={deed} disableLink />
      <Spacing>
        {submitting ? (
          <Spacing marginBottom={45} marginTop={45}>
            <Loading fill={false} />
          </Spacing>
        ) : (
          <>
            <Text
              fontSize={12}
              style={{ textDecoration: 'underline' }}
              center
              onPress={() => dispatch(setStepAction(formik.values.status === 'published' ? 1 : 0))}
            >
              {t`edit`}
            </Text>
            <Button
              palette="primary"
              type="submit"
              onPress={() => {
                formik.setFieldValue(
                  'status',
                  user.hasFeature('userPublishDeed') || deed.status === 'published' ? 'published' : 'pending'
                )
                setTimeout(() => formik.handleSubmit())
              }}
              disabled={!formik.isValid}
              style={{ marginTop: 10, marginBottom: 10, justifyContent: 'center' }}
            >
              {user.hasFeature('userPublishDeed') || deed.status === 'published' ? t`publish` : t`submitForApproval`}
            </Button>
            {deed.status !== 'published' && (
              <Text
                fontSize={12}
                style={{ textDecoration: 'underline' }}
                onPress={() => {
                  formik.setFieldValue('status', 'draft')
                  setTimeout(() => formik.handleSubmit())
                }}
                center
              >
                {t`saveForLater`}
              </Text>
            )}
          </>
        )}
      </Spacing>
    </>
  )
}

Review.displayName = 'Review'
export default Review
