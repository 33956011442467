import React from 'react'

import { DeviceInfo } from 'src/utils'
import { Text, Touchable } from 'src/retired/elements'

export interface FriendRequestButtonWrapperProps {
  text: string | React.ReactNode
  onPress?: () => void
}

export const FriendRequestButton = ({ text, onPress }: FriendRequestButtonWrapperProps): JSX.Element => (
  <Touchable
    style={{
      position: 'absolute',
      top: DeviceInfo.getNotchValue() + 25,
      right: 25,
      zIndex: 11,
    }}
    onPress={onPress ? () => onPress() : undefined}
  >
    {typeof text === 'string' ? (
      <Text size={13} bold underline style={{ textAlign: 'right' }}>
        {text}
      </Text>
    ) : (
      text
    )}
  </Touchable>
)

export default FriendRequestButton
