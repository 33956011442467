import { causes as allCauses } from 'src/data/causes'

const map = {
  Advocacy: { iconName: 'advocacy', hexCode: '#DEB7AF' },
  Animals: { iconName: 'animals', hexCode: '#EAC489' },
  Arts: { iconName: 'arts', hexCode: '#F1C864' },
  Children: { iconName: 'children', hexCode: '#FFBD73' },
  Disabilities: { iconName: 'disabilities', hexCode: '#FFAD82' },
  'Disaster Recovery': { iconName: 'disasterRecovery', hexCode: '#FFA8A8' },
  Education: { iconName: 'education', hexCode: '#FFB8A9' },
  Employment: { iconName: 'employment', hexCode: '#FBD1BF' },
  Environment: { iconName: 'environment', hexCode: '#FFAECA' },
  'Food Access': { iconName: 'foodAccess', hexCode: '#FF98E2' },
  Health: { iconName: 'health', hexCode: '#DBAFFF' },
  Homelessness: { iconName: 'homelessness', hexCode: '#C8B6FF' },
  LGBTQ: { iconName: 'lgbtq', hexCode: '#E7C6FF' },
  'Mental Health': { iconName: 'mentalHealth', hexCode: '#DBCBD8' },
  Mentoring: { iconName: 'mentoring', hexCode: '#B0D3ED' },
  Politics: { iconName: 'politics', hexCode: '#AEEFF1' },
  Seniors: { iconName: 'seniors', hexCode: '#A2DADE' },
  'Sports & Recreation': { iconName: 'sportsRecreation', hexCode: '#B0C3D7' },
  Sustainability: { iconName: 'sustainability', hexCode: '#CFE8F6' },
  Technology: { iconName: 'technology', hexCode: '#9EEAD3' },
  Veterans: { iconName: 'veterans', hexCode: '#A8D9C6' },
  Women: { iconName: 'women', hexCode: '#B7DD8E' },
  Youth: { iconName: 'youth', hexCode: '#CCD789' },
  // SDGs
  'Affordable and Clean Energy': { iconName: 'affordableEnergy', hexCode: '#EB1C2D' },
  'Clean Water and Sanitation': { iconName: 'cleanWater', hexCode: '#00AED9' },
  'Climate Action': { iconName: 'climateAction', hexCode: '#48773E' },
  'Decent Work and Economic Growth': { iconName: 'decentWork', hexCode: '#8F1838' },
  'Gender Equality': { iconName: 'genderEquality', hexCode: '#EF402B' },
  'Good Health and Well-Being': { iconName: 'goodHealth', hexCode: '#279B48' },
  'Industry, Innovation, and Infrastructure': { iconName: 'industry', hexCode: '#F36D25' },
  'Life Below Water': { iconName: 'lifeBelowWater', hexCode: '#007DBC' },
  'Life On Land': { iconName: 'lifeOnLand', hexCode: '#3EB049' },
  'No Poverty': { iconName: 'noPoverty', hexCode: '#EB1C2D' },
  Partnerships: { iconName: 'partnershipForGoals', hexCode: '#183668' },
  'Peace, Justice and Strong Institutions': { iconName: 'peaceJustice', hexCode: '#02558B' },
  'Quality Education': { iconName: 'qualityEducation', hexCode: '#C31F33' },
  'Reduced Inequalities': { iconName: 'reduceInequalities', hexCode: '#E11484' },
  'Responsible Consumption and Production': { iconName: 'responsibleConsumption', hexCode: '#CF8D2A' },
  'Sustainable Cities and Communities': { iconName: 'sustainableCities', hexCode: '#F99D26' },
  'Zero Hunger': { iconName: 'zeroHunger', hexCode: '#D3A029' },
}
export interface IconData {
  iconName: string
  hexCode: string
}

const mapCauseNameToIconData = (causeName: string): IconData => map[causeName]

export const getCauseIconData = (categoryIds: string[]): IconData => {
  let matchedCause

  // eslint-disable-next-line no-restricted-syntax
  for (const categoryId of categoryIds) {
    const cause = allCauses.find((currentCause) => currentCause.id === categoryId)
    if (cause) {
      matchedCause = cause
      break
    }
  }

  if (!matchedCause) {
    return {
      iconName: 'smileFallback',
      hexCode: '#BDA9FF',
    }
  }

  return mapCauseNameToIconData(matchedCause.name)
}
