import { ADD, ADD_MULTIPLE, REMOVE } from './constants'
import Relation from './model'

export const addAction = (relation: Relation) => ({
  type: ADD,
  relation,
})

export const addMultipleAction = (relations: Relation[]) => ({
  type: ADD_MULTIPLE,
  relations,
})

export const removeAction = (id: string) => ({
  type: REMOVE,
  id,
})
