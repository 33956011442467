import React from 'react'
import { View } from 'react-primitives'

import { css } from 'src/theme/styled'
import { colors } from 'src/theme'
import { Text } from 'src/retired/shared/Typography'

interface StepNumberProps {
  number: number
  state: 'default' | 'current' | 'completed'
}
export type StepNumberElement = React.ReactElement<StepNumberProps>

const StepNumber: React.FC<StepNumberProps> = ({ number, state = 'default' }): StepNumberElement => (
  <View
    style={[
      css`
        border-color: ${state === 'current'
          ? colors.primaryBlue
          : state === 'completed'
          ? colors.primaryBlue
          : colors.gray01};
        background-color: ${state === 'completed' ? colors.primaryBlue : colors.white};
        border-radius: 20px;
        border-width: 1px;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
      `,
    ]}
  >
    <Text
      weight="normal"
      fontSize={14}
      colour={state === 'current' ? 'black' : state === 'completed' ? 'white' : 'gray01'}
    >
      {number}
    </Text>
  </View>
)
export default StepNumber
