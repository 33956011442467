import React from 'react'

import Touchable from 'src/retired/elements/Touchable'
import { withRouter } from 'src/navigation'
import Button from 'src/retired/elements/Button'

export default withRouter(
  ({ button = true, replace = false, children, to, history, staticContext, onPress, ...props }) => {
    const Component = button ? Button : Touchable
    return (
      <Component
        {...props}
        onPress={(e) => {
          onPress?.()
          e.stopPropagation()
          e.preventDefault()
          if (replace) {
            history.replace(to)
          } else {
            history.push(to)
          }
        }}
      >
        {children}
      </Component>
    )
  }
)
