export const INIT = 'containers/Login/SingleSignOn/INIT'
export const INIT_SUCCESS = 'containers/Login/SingleSignOn/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Login/SingleSignOn/INIT_FAILED'

export const SET_SELECTED_COMPANY_ID = 'containers/Login/SingleSignOn/SET_SELECTED_COMPANY_ID'

export const LOGIN = 'containers/Login/SingleSignOn/LOGIN'

export const SET_URL = 'containers/Login/SingleSignOn/SET_URL'
export const UNSET_URL = 'containers/Login/SingleSignOn/UNSET_URL'
