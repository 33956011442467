import { ActionsObservable } from 'redux-observable'
import { replace } from 'connected-react-router'
import type { Store } from 'redux'
import i18n from 'i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import OrganizationApi from 'src/entities/organization/api'
import DonationApi from 'src/entities/donation/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Organization } from 'src/generated/graphql'

import {
  GetNonprofit,
  SubmitExternalDonation,
  submitSuccessAction,
  submitFailedAction,
  estimateMatchAction,
  estimateMatchSuccessAction,
  estimateMatchFailedAction,
  fetchCampaignsByNonprofitAction,
} from './actions'
import { SUBMIT_EXTERNAL_DONATION, GET_NONPROFIT, ESTIMATE_MATCH, FETCH_CAMPAIGNS_BY_NONPROFIT } from './constants'
import { FormikDataProps } from './LogOffPlatformDonationForm'
import { LogOffPlatformDonationApi } from './api'

const getNonprofit = (action$: ActionsObservable<GetNonprofit>) =>
  action$.ofType(GET_NONPROFIT).exhaustMap(({ nonprofitId }) =>
    OrganizationApi.fetch(nonprofitId)
      .mergeMap((resultingAction) => [resultingAction])
      .catch(() => [showErrorAction('There was an error during loading the form. Please try again.'), replace('/')])
  )

const submitExternalDonation = (action$: ActionsObservable<SubmitExternalDonation>, store: Store) =>
  action$
    .ofType(SUBMIT_EXTERNAL_DONATION)
    .exhaustMap(
      ({
        data,
        nonprofit,
        donationProvider,
      }: {
        data: FormikDataProps
        nonprofit?: Organization
        donationProvider: DonationProvider | null
      }) => {
        const state = store.getState()
        const user = selectCurrentUser(state)

        const submitData = {
          user: user.id,
          nonprofits: [nonprofit],
          currency: data.donationAmountCurrency,
          date: data.date,
          receiptUrl: data.receipt,
          amount: Number(data.donationAmount),
          desiredMatchAmount: data.desiredMatchAmount,
          donationProvider,
          donationPaymentMethod: 'External',
          designation: data.designation,
          dedication: data.dedication,
          campaignId: data.campaignId,
          desiredMatchingRule: data.desiredMatchingRule,
        }

        return DonationApi.donate(submitData)
          .mergeMap((/* resultingAction */) => [submitSuccessAction(), replace('/off-platform/success')])
          .catch((error: Error) => [submitFailedAction(error), showErrorAction('Submission failed!')])
      }
    )

const estimateMatch = (action$: ActionsObservable<ReturnType<typeof estimateMatchAction>>) =>
  action$.ofType(ESTIMATE_MATCH).exhaustMap(({ amount, currencyCode, donationDate, nonprofitId }) =>
    DonationApi.estimateMatch({
      amount,
      currencyCode,
      donationDate,
      nonprofitId,
    })
      .mergeMap((result) => [estimateMatchSuccessAction(result)])
      .catch((error: Error) => [estimateMatchFailedAction(error), showErrorAction(i18n.t('matchEstimationError'))])
  )

const campaignsByNonprofit = (action$: ActionsObservable<ReturnType<typeof fetchCampaignsByNonprofitAction>>) =>
  action$
    .ofType(FETCH_CAMPAIGNS_BY_NONPROFIT)
    .mergeMap(({ nonprofitId }) => LogOffPlatformDonationApi.fetchAllByNonprofit(nonprofitId))

export default [getNonprofit, submitExternalDonation, estimateMatch, campaignsByNonprofit]
