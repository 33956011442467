import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { AmountCurrencies, CurrencyFormat, CurrencySelectorTooltip } from 'src/containers/modules/CurrencyFormat'
import ProgressBar, { ProgressBarItem } from 'src/retired/shared/ProgressBar'
import { Body1, H4 } from 'src/retired/shared/Typography'
import { Row, Text } from 'src/retired/elements'
import Campaign from 'src/entities/campaign/model'
import { DeedMap } from 'src/entities/deed/reducer'
import { selectCurrentUser } from 'src/entities/user/selectors'
import truthy from 'src/utils/truthy'
import { styled } from 'src/theme/styled'

const Wrapper = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;
  padding-top: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? '50px' : '')};
`

const NumbersRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`

const RaisedWrapper = styled.View`
  display: flex;
  align-items: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? 'column' : 'row')};
`

const GoalWrapper = styled.View`
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isSmall ? 'column-reverse' : 'row')};
`

enum ToolTipText {
  GivingPledgeProjectionContribution = 'pledgedAmount',
  UserDonationContribution = 'donatedByUsers',
  EmployeeDonationContribution = 'donatedByEmployees',
  MatchedDonationContribution = 'donatedByMatched',
}

interface DonationGoalProps {
  campaign: Campaign
  fundraisers: DeedMap
}

const GoalExceededPercentage = ({
  show,
  value,
  color,
}: {
  show: boolean
  value: number
  color: string
}): JSX.Element | null => {
  if (!show) {
    return null
  }

  const displayText = `${value}%`

  return <span style={{ marginLeft: '0.5rem', alignSelf: 'flex-start', color }}>{displayText}</span>
}

const ProgressItemToolTip = ({
  components,
  amountReasonText,
}: {
  components: { [key: string]: JSX.Element }
  amountReasonText: ToolTipText
}): JSX.Element => {
  const { t } = useTranslation('campaignScreen')
  const { colors } = useDeedTheme()

  return (
    <Text style={{ color: colors.white }}>
      <Trans t={t} i18nKey={amountReasonText} components={components} />
    </Text>
  )
}

const getProgressItem = (
  color: string,
  amountCurrencies: AmountCurrencies,
  amountReasonText: ToolTipText
): ProgressBarItem => {
  const currencyComponent = <CurrencyFormat amountCurrencies={amountCurrencies} process="round" short />
  return {
    color,
    value: amountCurrencies?.USD,
    tooltip: (
      <ProgressItemToolTip
        amountReasonText={amountReasonText}
        components={{ CurrencyFormat: currencyComponent }}
      ></ProgressItemToolTip>
    ),
  }
}

export const DonationGoalProgress = ({ campaign, fundraisers }: DonationGoalProps) => {
  const { t } = useTranslation('campaignScreen')
  const { colors, metrics } = useDeedTheme()
  const user = useSelector(selectCurrentUser)

  if (!campaign) {
    return null
  }

  const { campaignStats } = campaign

  if (!campaignStats) {
    return null
  }

  const { hideProgressBar, nonprofits, isPledge, goalAmount } = campaign

  const hideProgressBarFlag = (user && !user?.hasFeature('donations')) || hideProgressBar

  const campaignNonprofitsLength = nonprofits?.length

  const {
    otherAmountCurrencies,
    goalAmountCurrencies,
    totalRaisedCurrencies,
    matchedAmountCurrencies,
    employeesAmountCurrencies,
    projectedTotalAmountCurrencies,
    donatedByUsersAmountCurrencies,
  } = campaignStats

  // @NOTE-AC: For some reason, we show employeesAmount or usersAmount depending on campaignNonprofitsLength
  let employeeOrUserAmountCurrencies = donatedByUsersAmountCurrencies

  if (!campaignNonprofitsLength) {
    employeeOrUserAmountCurrencies = employeesAmountCurrencies
  }

  let otherProgressItem: ProgressBarItem | undefined
  let matchProgressItem: ProgressBarItem | undefined
  let givingPledgeProgressItem: ProgressBarItem | undefined
  let donationContributionItem: ProgressBarItem | undefined

  if (!campaignNonprofitsLength && otherAmountCurrencies?.USD) {
    otherProgressItem = {
      color: colors.teal,
      value: otherAmountCurrencies?.USD,
    }
  }

  if (employeeOrUserAmountCurrencies?.USD) {
    let text = ToolTipText.UserDonationContribution

    if (!campaignNonprofitsLength) {
      text = ToolTipText.EmployeeDonationContribution
    }
    donationContributionItem = getProgressItem(campaign.colorA, employeeOrUserAmountCurrencies, text)
  }

  if (matchedAmountCurrencies?.USD) {
    matchProgressItem = getProgressItem(
      campaign.colorB,
      matchedAmountCurrencies,
      ToolTipText.MatchedDonationContribution
    )
  }

  if (isPledge) {
    givingPledgeProgressItem = getProgressItem(
      campaign.colorA,
      projectedTotalAmountCurrencies,
      ToolTipText.GivingPledgeProjectionContribution
    )
  }

  const statsItems = [otherProgressItem, donationContributionItem, matchProgressItem, givingPledgeProgressItem].filter(
    truthy
  )

  const statsTotal =
    goalAmount === 0 ? totalRaisedCurrencies.USD : goalAmountCurrencies?.USD || totalRaisedCurrencies.USD

  const progressBarDisplayed =
    !hideProgressBarFlag && (campaignNonprofitsLength || matchedAmountCurrencies?.USD || fundraisers.size > 0)

  if (!progressBarDisplayed) {
    return null
  }

  let contribution = totalRaisedCurrencies?.USD ?? 0
  let contributionAmountCurrencies = totalRaisedCurrencies
  let contributionDisplayText = t('totalAmount')

  if (isPledge) {
    contribution = projectedTotalAmountCurrencies.USD ?? 0
    contributionAmountCurrencies = projectedTotalAmountCurrencies
    contributionDisplayText = t('projectedTotalAmount')
  }

  const goalContributionDifference = contribution - goalAmountCurrencies.USD
  const amountRemainingTowardsGoalPercentage = Math.round((goalContributionDifference * 100) / goalAmountCurrencies.USD)
  const goalHasBeenExceeded = amountRemainingTowardsGoalPercentage > 0

  let contributionGoalPercentage = 100

  if (goalHasBeenExceeded) {
    contributionGoalPercentage += amountRemainingTowardsGoalPercentage
  }

  const goalAmountExists = goalAmount !== 0 && goalAmountCurrencies?.USD > 0

  return (
    <Wrapper>
      <View style={{ width: metrics.isSmall ? '100%' : '50%' }}>
        <NumbersRow>
          <RaisedWrapper>
            <H4 style={{ paddingRight: 6, marginBottom: -2, color: colors.white }}>
              <CurrencyFormat amountCurrencies={contributionAmountCurrencies} process="round" short />
            </H4>
            <Body1 style={{ color: colors.white }}>{contributionDisplayText}</Body1>
          </RaisedWrapper>

          {goalAmountExists && (
            <GoalWrapper>
              <Body1 style={{ color: colors.white }}>{t('goal')}</Body1>
              <H4 style={{ paddingLeft: 6, marginBottom: -2, color: colors.white }}>
                <CurrencyFormat amountCurrencies={goalAmountCurrencies} process="round" short />
              </H4>
              <GoalExceededPercentage
                show={goalHasBeenExceeded}
                value={contributionGoalPercentage}
                color={colors.white}
              ></GoalExceededPercentage>
            </GoalWrapper>
          )}
        </NumbersRow>
        <View>
          <ProgressBar items={statsItems} total={statsTotal} backgroundColor={colors.gray02} height={16} />
        </View>

        <Row
          style={{
            width: '100%',
            justifyContent: 'flex-end',
            marginTop: 12,
          }}
        >
          <CurrencySelectorTooltip
            labelContainerStyle={{ borderStyle: 'solid', borderColor: colors.white }}
            labelStyle={{ color: colors.white, fontSize: 16 }}
            text={t`changeMyCurrency`}
          />
        </Row>
      </View>
    </Wrapper>
  )
}
