import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'

import { useHistory } from 'src/navigation'
import { useActionsQuery, ActionWhereInput } from 'src/generated/graphql'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { H3, Body2 } from 'src/retired/shared/Typography'
import { Spacing, Loading, Switch, Row } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import Touchable from 'src/retired/shared/Touchable'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { ActionsList } from '../ActionsList/ActionsList'
import { NUM_INITIAL_ACTIVITIES } from '../constants'

import { EmptyActions } from './EmptyActions'

const currentDate = new Date()

const SeeMore = ({
  onFetchMore,
  skip = 0,
  label,
}: {
  onFetchMore: (args: any) => void
  skip?: number
  label: string
}) => (
  <View style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
    <Button palette="secondary" onPress={() => onFetchMore({ variables: { skip } })}>
      {label}
    </Button>
  </View>
)

const Loader = ({ loading }: { loading: boolean }) =>
  loading ? (
    <View style={{ height: 80 }}>
      <Loading fill={false} backgroundColor="transparent" />
    </View>
  ) : null

interface ActionsFeedPayload {
  showActiveActions: boolean
  setShowActiveActions: (showActiveActions: boolean) => void
}

export const ActionsFeed = ({ showActiveActions, setShowActiveActions }: ActionsFeedPayload) => {
  const { t } = useTranslation('actions')
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()

  const user = useSelector(selectCurrentUser)
  const isActionsFeatureEnabled = user?.hasFeature('actions')

  const where: ActionWhereInput = showActiveActions
    ? { OR: [{ endingAt: { equals: null } }, { endingAt: { gt: currentDate } }] }
    : { endingAt: { lt: currentDate, not: null } }

  const {
    data: actionsData,
    error: actionsError,
    loading: actionsLoading,
    fetchMore: actionsFetchMore,
  } = useActionsQuery({
    skip: Boolean(user && !isActionsFeatureEnabled),
    notifyOnNetworkStatusChange: true,
    variables: {
      take: 10,
      where,
      activityArgs: {
        take: NUM_INITIAL_ACTIVITIES,
      },
    },
  })

  if ((!actionsLoading && !actionsData && user) || actionsError) {
    alert(t`errorTemplates:somethingWentWrongTitle`)
    history.replace('/home')
    return null
  }

  return (
    <>
      <Spacing marginTop={40} marginBottom={20}>
        <Row
          style={{
            justifyContent: metrics.isLarge ? 'flex-start' : 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <H3 numberOfLines={2}>{t`actions`}</H3>
          <View style={{ marginBottom: 6 }}>
            <Row style={{ paddingLeft: metrics.isLarge ? 60 : 0, alignItems: 'center' }}>
              <Touchable onPress={() => setShowActiveActions(true)}>
                <Body2>{t('active')}</Body2>
              </Touchable>
              <Switch
                value={!showActiveActions}
                onValueChange={() => setShowActiveActions(!showActiveActions)}
                style={{ marginHorizontal: 10 }}
                trackColor={{ true: colors.brandColor, false: colors.brandColor }}
                thumbColor={colors.white}
              />
              <Touchable onPress={() => setShowActiveActions(false)}>
                <Body2>{t('expiredToday')}</Body2>
              </Touchable>
            </Row>
          </View>
        </Row>
      </Spacing>
      {actionsData?.actions?.length ? (
        <>
          <ActionsList actions={actionsData?.actions ?? []} />
          <Loader loading={!!actionsLoading} />
          <SeeMore onFetchMore={actionsFetchMore} skip={actionsData?.actions?.length} label={t('seeMore')} />
        </>
      ) : actionsLoading ? (
        <Loader loading={!!actionsLoading} />
      ) : (
        <EmptyActions isActiveActionsFilterEnabled={showActiveActions} />
      )}
    </>
  )
}
