import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('profile') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectLoadedOnce = createSelector(selectState, (state) => state.get('loadedOnce'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
