import { Set } from 'immutable'

export const INIT = 'containers/VolunteerTimeForm/INIT'
export const INIT_SUCCESS = 'containers/VolunteerTimeForm/INIT_SUCCESS'
export const INIT_FAILED = 'containers/VolunteerTimeForm/INIT_FAILED'

export interface InitAction {
  type: typeof INIT
  id?: string
  opportunityId?: string
  opportunityType?: 'deed' | 'nonprofit'
}
export const initAction = (id: string, opportunityId?: string, opportunityType?: 'deed' | 'nonprofit'): InitAction => ({
  type: INIT,
  id,
  opportunityId,
  opportunityType,
})

interface InitSuccessAction {
  type: typeof INIT_SUCCESS
}
export const initSuccessAction = (): InitSuccessAction => ({
  type: INIT_SUCCESS,
})

interface InitFailedAction {
  type: typeof INIT_FAILED
  error: Error
}
export const initFailedAction = (error: Error): InitFailedAction => ({
  type: INIT_FAILED,
  error,
})

export type FormikData =
  | { nonprofitCountry?: unknown; nonprofitName?: unknown } & (
      | {
          hours: string
          minutes: string
          date: string
          notes?: string
          milestone: undefined
          causes: Set<unknown>
        }
      | {
          hours: string
          minutes: string
          date: unknown
          notes?: unknown
          milestone: unknown
          causes?: string | null
        }
    )
