import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useHistory } from 'react-router'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import makeGoBack from 'src/navigation/makeGoBack'
import { Screen, Image, Button, Spacing } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'

const NominationSuccessConfirmation = (): JSX.Element => {
  const { t } = useTranslation('nominateNonprofitForm')
  const { images } = useDeedTheme()
  const history = useHistory()
  const goBack = makeGoBack(history, '/home')

  return (
    <Screen style={{ padding: 60 }}>
      <PageTitle title={t`yourNominationHasBeenSubmitted`} />
      <View style={{ alignItems: 'center' }}>
        <Body2>{t`yourNominationHasBeenSubmitted`.toUpperCase()}</Body2>
        <Spacing marginBottom={20} />
        <Image source={images.wavingLogo} style={{ height: 120, width: 120 }}></Image>
        <Spacing marginBottom={20} />
        <Body1 marginBottom={8} weight="500">{t`thankYou`}</Body1>
        <Body2>{t`youWillReceiveUpdates`}</Body2>
        <Spacing marginBottom={40} />
        <Button onPress={goBack}>{t`common:Ok`}</Button>
      </View>
    </Screen>
  )
}

export default NominationSuccessConfirmation
