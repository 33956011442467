import { OrganizationMap } from 'src/entities/organization/reducer'

export const matchCompanySso = (value = '', companies: OrganizationMap) =>
  companies.find((company) => {
    if (!value || value?.length < 5) {
      return false
    }

    const emailHost = value.split('@')[1]?.toLowerCase()
    if (emailHost) {
      return company.emailDomains.includes(emailHost)
    }

    const cleanValue = value.replace(/-/g, '').toLowerCase()
    const companyFields = company.emailDomains
      .map((emailDomain) => emailDomain.match(/.*(?=\.)/)?.[0])
      .filter((domain) => domain && domain.length > 4)
      .concat([...(company.emailDomains ?? []), company.name?.toLowerCase(), company.code?.toLowerCase()])
      .filter(Boolean)

    return companyFields.includes(value) || companyFields.includes(cleanValue)
  })
