import React from 'react'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

const CardStyled = styled(View)(({ theme }: { theme: EmotionTheme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 16,
  paddingTop: theme.metrics.isLarge ? 36 : 24,
  paddingBottom: theme.metrics.isLarge ? 36 : 24,
  paddingLeft: 20,
  paddingRight: 20,
  alignItems: 'center',
  boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 58px',
}))

export const RawCard = ({ children, style }: { children: JSX.Element; style?: React.CSSProperties }) => (
  <CardStyled style={style}>{children}</CardStyled>
)
