import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import { withTheme } from '@emotion/react'

import { Platform } from 'src/utils'
import DeedCard from 'src/retired/shared/DeedCard'
import List from 'src/retired/blocks/List'
import { Spacing, RefreshControl } from 'src/retired/elements'
import { withRouter } from 'src/navigation'
import { getCardWidth, getInnerListStyle } from 'src/retired/blocks/Feed/List/helpers'

const isAndroid = Platform.OS === 'android'

const DeedCardIPadWrapper = (props) =>
  Platform.isPad ? (
    <View
      style={{
        width: '50%',
      }}
    >
      <DeedCard {...props} />
    </View>
  ) : (
    <DeedCard {...props} />
  )

class FeedList extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    loaderPosition: PropTypes.string,
    deeds: PropTypes.object,
    cardWidth: PropTypes.string,
    numberOfColumns: PropTypes.number,
    causes: PropTypes.object,
    skills: PropTypes.object,
    user: PropTypes.object,
    isEmployee: PropTypes.bool,
    handleScroll: PropTypes.func,
    corporateOnly: PropTypes.bool,
    onViewableItemsChanged: PropTypes.func,
    listRef: PropTypes.any,
    emptyState: PropTypes.node,
    backgroundColorForSmallScreens: PropTypes.string,
    withoutPaddingBottom: PropTypes.bool,
    onRefresh: PropTypes.func,
  }

  render() {
    const {
      loading,
      loaderPosition,
      deeds,
      cardWidth,
      numberOfColumns,
      handleScroll,
      corporateOnly,
      onViewableItemsChanged,
      listRef,
      withoutPaddingBottom,
      theme: { colors, metrics },
      backgroundColorForSmallScreens,
      onRefresh,
    } = this.props

    const listProps = {}
    if (onViewableItemsChanged) {
      listProps.onViewableItemsChanged = onViewableItemsChanged
    }
    if (Platform.OS !== 'web') {
      const cardImageAspectRatio = 327 / 150
      const cardImageHeight = Math.floor((metrics.screenWidth - 20 * (numberOfColumns + 1)) / cardImageAspectRatio)
      const cardHeight = cardImageHeight + 210 + 12
      listProps.getItemLayout = (data, index) => ({ length: cardHeight, offset: cardHeight * index, index })
      listProps.listKey = 'deeds'
    }

    return (
      <List
        {...listProps}
        initialNumToRender={3}
        windowSize={3}
        ref={listRef}
        numColumns={numberOfColumns}
        onScroll={handleScroll}
        onScrollEndDrag={handleScroll}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        style={{
          ...getInnerListStyle(metrics.isSmall, !!withoutPaddingBottom),
          backgroundColor: metrics.isSmall ? backgroundColorForSmallScreens || colors.gray06 : 'transparent',
        }}
        contentContainerStyle={{
          paddingLeft: isAndroid && metrics.isSmall ? 8 : 0,
          paddingRight: isAndroid && metrics.isSmall ? 8 : 0,
          paddingTop: isAndroid && metrics.isSmall ? 14 : 0,
          paddingBottom: isAndroid && !withoutPaddingBottom ? 14 : 0,
        }}
        itemProps={{
          style: { width: cardWidth ?? getCardWidth(metrics) },
        }}
        data={deeds.toArray()}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <DeedCardIPadWrapper size="variable" deed={item} hidePartnerLogo={corporateOnly} />}
        ListFooterComponent={<Spacing marginBottom={10} />}
        loading={loading}
        loaderPosition={loaderPosition}
        refreshControl={
          <RefreshControl refreshing={loading} fullHeight={false} tintColor={colors.pink} onRefresh={onRefresh} />
        }
      />
    )
  }
}

export default withRouter(withTheme(FeedList))
