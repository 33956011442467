import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import type { TypedMap } from 'src/utils/typed-map'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  CANCEL,
  CANCEL_SUCCESS,
  CANCEL_FAILED,
} from './constants'

export type DonationState = TypedMap<{
  loading: boolean
  refreshing: boolean
  cancelling: boolean
  error: null | Error
}>

export const initialState = fromJS({
  loading: false,
  refreshing: false,
  cancelling: false,
  error: null,
}) as DonationState

export default (state = initialState, action: AnyAction): DonationState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case REFRESH:
      return state.merge({ refreshing: true, error: null })

    case REFRESH_SUCCESS:
      return state.set('refreshing', false)

    case REFRESH_FAILED:
      return state.merge({ refreshing: false, error: action.error })

    case CANCEL:
      return state.set('cancelling', true)

    case CANCEL_SUCCESS:
      return state.set('cancelling', false)

    case CANCEL_FAILED:
      return state.merge({ cancelling: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
