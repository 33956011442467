import React from 'react'
import { View } from 'react-primitives'

import { css } from 'src/theme/styled'

export const DeedSearchPlaceholder = (): JSX.Element => (
  <View
    style={[
      css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top-width: 1px;
        border-top-color: #d8d8d8;
        border-bottom-width: 1px;
        border-bottom-color: #d8d8d8;
        border-radius: 4px;
        padding: 16px 25px;
        background-color: white;
      `,
    ]}
  >
    <View
      style={[
        css`
          width: 150px;
          height: 8px;
          margin-top: 10px;
          margin-bottom: 17px;
          background-color: #d8d8d8;
        `,
      ]}
    />
    <View
      style={[
        css`
          width: 88px;
          max-width: 100%;
          height: 8px;
          margin-bottom: 13px;
          background-color: #d8d8d8;
        `,
      ]}
    />
  </View>
)

export default DeedSearchPlaceholder
