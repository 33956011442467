import React from 'react'
import { Feed, useStreamContext } from 'react-activity-feed'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'

import { makeReactionCreatedEvent, useSegment } from './analytics/utils'

export const StreamFeed: typeof Feed = (feedProps): JSX.Element => {
  const { client } = useStreamContext()
  const user = useSelector(selectCurrentUser)
  const segment = useSegment()

  return (
    <Feed
      {...feedProps}
      doChildReactionAddRequest={async (kind, activity, data, options) => {
        const result = await client?.reactions.add(kind, activity, data, options)
        void segment(
          makeReactionCreatedEvent({
            reactionId: result?.id,
            postId: activity.id,
            userId: user.id,
            reactionType: kind,
            communityId: feedProps.userId,
          })
        )
        return result
      }}
      doReactionAddRequest={async (kind: 'comment' | 'like', activity, data, options) => {
        const result = await client?.reactions.add(kind, activity, data, options)
        void segment(
          makeReactionCreatedEvent({
            reactionId: result?.id,
            postId: activity.id,
            userId: user.id,
            reactionType: kind,
            communityId: feedProps.userId,
          })
        )
        return result
      }}
    />
  )
}
