import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Campaign from 'src/entities/campaign/model'
import User from 'src/entities/user/model'
import Organization from 'src/entities/organization/model'
import { Loading } from 'src/retired/elements'

import { GoalReachedCard } from './GoalReachedCard'
import { SupportersCard } from './SupportersCard'
import { NonprofitsCard } from './NonprofitsCard'
import { ActionIncentiveCard } from './ActionIncentiveCard'

interface CampaignStatsProps {
  campaignMetadataLoading: boolean
  campaign: Campaign
  user?: User
  nonprofits: Organization[]
}

const CampaignStats = ({ campaignMetadataLoading, campaign, user, nonprofits }: CampaignStatsProps): JSX.Element => {
  const { metrics } = useDeedTheme()

  const isActionsFeatureEnabled = user?.hasFeature('actions')

  return campaignMetadataLoading ? (
    <View style={{ marginBottom: 20, marginTop: 40, minHeight: 50 }}>
      <Loading fill={false} backgroundColor="transparent" />
    </View>
  ) : (
    <View
      style={{
        display: 'flex',
        flexDirection: metrics.isLarge ? 'row' : 'column',
        marginTop: -50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GoalReachedCard campaign={campaign} />
      <SupportersCard campaign={campaign} />
      <NonprofitsCard nonprofits={nonprofits} />
      {isActionsFeatureEnabled &&
        campaign?.campaignStats?.actionStats?.incentiveSummary.map((incentiveData, index) => (
          <ActionIncentiveCard
            key={index}
            incentiveData={incentiveData}
            numCompletedActions={campaign?.campaignStats?.actionStats?.totalCompletedActivities}
          />
        ))}
    </View>
  )
}

export default CampaignStats
