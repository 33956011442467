import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'

import { Text } from 'src/retired/elements'

const Section = ({ question }) => (
  <View key={question._id} style={{ marginTop: 25 }}>
    <Text size={24} style={{ marginBottom: 25 }}>
      {question.title}
    </Text>
    <Text size={16} style={{ marginBottom: 20, color: '#999999', lineHeight: 24 }}>
      {question.description}
    </Text>
  </View>
)
Section.propTypes = {
  question: PropTypes.object,
}

export default Section
