import React from 'react'
import PropTypes from 'prop-types'

import Loading from 'src/retired/elements/Loading'

const RefreshControl = ({ refreshing, fullHeight }) =>
  refreshing ? <Loading fill={false} backgroundColor="transparent" fullHeight={fullHeight} /> : null

RefreshControl.propTypes = {
  refreshing: PropTypes.bool,
}

export default RefreshControl
