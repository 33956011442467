import React, { FC } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { RenderHtml } from 'src/components/RenderHtml'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Label } from 'src/retired/shared/Typography'
import { Image, Spacing } from 'src/retired/elements'
import Links from 'src/retired/elements/Links'
import Campaign from 'src/entities/campaign/model'
import User from 'src/entities/user/model'
import Expandable from 'src/components/Expandable'

import { EmphasizeText, EmphasisFormatProps } from './EmphasizeText'

interface CampaignContentProps {
  campaign: Campaign
  user?: User
}

export const CampaignContent = ({ campaign, user }: CampaignContentProps): JSX.Element => {
  const { t } = useTranslation('campaignScreen')
  const { colors, metrics } = useDeedTheme()
  const wide = metrics.screenWidth > 640

  // eslint-disable-next-line react/no-unstable-nested-components
  const EmphasizeFormatHeadline: FC<EmphasisFormatProps> = ({ children }) => (
    <H3 colour={campaign.colorB || campaign.colorA} customFont={campaign.customFont} weight="500">
      {children}
    </H3>
  )

  const showCampaignBy =
    (campaign.company?.mainPicture || campaign.company?.logo) && campaign.company?.id !== user?.organization?.id

  const hasTotalHeadline = !!campaign.totalHeadline && campaign.totalHeadline !== '<p>&nbsp;</p>'
  const hasTotalText = !!campaign.totalText && campaign.totalText !== '<p>&nbsp;</p>'
  const hasVideoLink = !!campaign.videoLink

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: showCampaignBy ? 62 : 82,
        paddingBottom: hasTotalText || hasTotalHeadline ? 82 : 0,
      }}
    >
      <View style={{ width: metrics.isSmall || metrics.isMedium ? '85%' : 1000 }}>
        <Links>
          {showCampaignBy && (
            <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
              <Label
                colour={colors.black}
                center
                letterSpacing={campaign.customFont ? (metrics.isSmall ? 1.6 : 1.5) : 0.8}
                marginBottom={12}
                customFont={campaign.customFont}
              >
                {t`campaignBy`}
              </Label>
              <View>
                <Image
                  source={{ uri: campaign.company.mainPicture || campaign.company.logo }}
                  resizeMode="contain"
                  style={{
                    width: campaign.company.mainPicture ? (metrics.isSmall ? 130 : 150) : 40,
                    height: campaign.company.mainPicture ? (metrics.isSmall ? 75 : 75) : 40,
                    borderRadius: campaign.company.mainPicture ? 0 : 20,
                  }}
                />
              </View>
            </View>
          )}
          {hasVideoLink && (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 30,
                position: 'relative',
                paddingTop: '56.25%',
                width: '100%',
                borderRadius: metrics.isSmall ? '1rem' : '2rem',
                overflow: 'hidden',
              }}
            >
              <iframe
                title={campaign.title}
                width="100%"
                height="100%"
                src={campaign.videoLink}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                frameBorder="0"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: 0,
                }}
              ></iframe>
            </View>
          )}
          {hasTotalHeadline && (
            <H3
              marginBottom={20}
              center
              letterSpacing={metrics.isLarge ? 2.03 : metrics.isMedium ? 1.33 : 1}
              lineHeight={metrics.isLarge ? 59 : metrics.isMedium ? 53 : 38}
              customFont={campaign.customFont}
              weight="500"
            >
              <EmphasizeText EmphasisFormat={EmphasizeFormatHeadline}>{campaign.totalHeadline}</EmphasizeText>
            </H3>
          )}
        </Links>
        {hasTotalText && (
          <View>
            <Expandable>
              <RenderHtml
                customFont={campaign.customFont}
                customClass={
                  campaign.customFont
                    ? metrics.isSmall
                      ? 'description_block_small'
                      : 'description_block_normal'
                    : 'description_block'
                }
                source={{ html: campaign.totalText?.trim() }} // remove trailing whitespace
                customStyles={{ lineHeight: '35px' }}
              />
            </Expandable>
          </View>
        )}

        <Spacing marginBottom={wide ? 30 : 15} />
      </View>
    </View>
  )
}
