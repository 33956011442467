import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Organization from 'src/entities/organization/model'
import { Column, Image, Row } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import AffiliationButton from 'src/retired/shared/AffiliationButton'
import Box from 'src/retired/shared/Box'
import { useAffiliationsListQuery } from 'src/generated/graphql'

const AffiliationBanner = ({ nonprofit }: { nonprofit: Organization }): React.ReactElement | null => {
  const { t } = useTranslation('affiliationBanner')
  const { images, metrics } = useDeedTheme()
  const { data, loading, error } = useAffiliationsListQuery()

  const isAffiliatedToNonprofit = !!data?.affiliations.some((affiliation) => affiliation.nonprofitId === nonprofit.id)

  if (loading || error) {
    return null
  }

  return (
    <Box>
      <Row>
        <Column>
          <Body1 lineHeight={22}>
            {t('affiliationToNonprofit', {
              context: isAffiliatedToNonprofit ? 'view' : 'add',
              nonprofitName: nonprofit.name,
            })}
          </Body1>
          <AffiliationButton nonprofitId={nonprofit?.id} />
        </Column>
        {!metrics.isSmall && (
          <Image source={images.puzzlePiece} backgroundPosition="relative" style={{ width: 240, top: -25 }} />
        )}
      </Row>
    </Box>
  )
}

export default AffiliationBanner
