/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { PureComponent, FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View, StyleSheet } from 'react-primitives'
import { Map } from 'immutable'
import { withTranslation, Trans } from 'react-i18next'
import type { WithTranslation } from 'react-i18next'
import { withTheme } from '@emotion/react'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { injectReducer, injectEpics, Platform } from 'src/utils'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser, selectUserBrand, selectUserLocations } from 'src/entities/user/selectors'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { Alert, Loading, Row, Spacing } from 'src/retired/elements'
import { Redirect } from 'src/navigation'
import { Body1, Body2, H4, H5 } from 'src/retired/shared/Typography'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import Organization from 'src/entities/organization/model'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import Icon from 'src/retired/shared/Icon'
import StatusBar from 'src/retired/blocks/StatusBar'

import { ShareBlock } from '../components/ShareBlock'
import { selectErrorVto } from '../Confirm/selectors'
import {
  getStartDateFormattedTime,
  getEventStartDateTime,
  getEventEndDateTime,
  getEndDateFormattedTime,
} from '../utils'

import { selectLoading, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const Wrapper: FC<{ showInviteOverlay?: boolean }> = ({ children, showInviteOverlay = false }) => {
  if (showInviteOverlay && Platform.OS === 'web') {
    return <View style={{ filter: 'blur(2px)', height: '100%', overflow: 'scroll' }}>{children}</View>
  }
  return <>{children}</>
}

interface DeedShareProps extends WithTranslation {
  loading: boolean
  deed: Deed
  user: User
  error: Error
  errorVto: Error
  match: { params: { deed?: string } }
  theme: EmotionTheme
  locations: Organization['locations']
  brand?: Organization
}
export class DeedShare extends PureComponent<DeedShareProps> {
  state = {
    selected: Map(),
    showInviteOverlay: false,
    selectedRole: undefined,
    volunteerTimeOffForm: {},
    allowSubmit: true,
  }

  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.deed)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deed && this.props.deed.id !== nextProps.deed.id) {
      this.setState({ selected: Map(), showInviteOverlay: false })
    }
  }

  render(): JSX.Element | null {
    const {
      t,
      deed,
      user,
      error,
      errorVto,
      loading,
      theme: { colors },
      locations,
      match,
      brand,
    } = this.props

    if (error) {
      return <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen>
    }
    if (loading || !deed || !user || !locations) {
      return <Loading />
    }
    if (!deed.type) {
      return <Redirect to={`/deeds/${match.params.deed}`} />
    }
    if (deed.type === 'Campaign') {
      return <Redirect to={`/deeds/${match.params.deed}/donate`} />
    }
    if (deed.type === 'Project' && deed.externalLink) {
      return <Redirect to={`/deeds/${match.params.deed}/external`} />
    }
    const userLocation = locations.get(user.location)
    if (!user.phone && userLocation && userLocation.phone) {
      return <Redirect to={`/register/phone/${match.params.deed}`} />
    }
    if (user && (!user.consent || !user.consent.shareData)) {
      return <Redirect to={`/register/consent/${match.params.deed}`} />
    }

    const userAttending = deed.isUserAttending(user)
    const userWaitlisted = deed.isUserWaitlisted(user)
    const userAppliedForRole = deed.hasUserAppliedForRole(user)

    if (
      !userAttending &&
      !userWaitlisted &&
      !userAppliedForRole &&
      (deed.formQuestions?.length > 0 || deed.forms?.length > 0)
    ) {
      return <Redirect to={`/deeds/${match.params.deed}/forms`} />
    }
    if (deed.startingAt && deed.startingAt < new Date()) {
      Alert.alert(t`deedAlreadyStarted`)
      return <Redirect to="/feed" />
    }

    const waitlist = deed.isFull() && userWaitlisted

    const eventTypes = ['Event', 'BaseEvent']

    const startDateformattedTime = getStartDateFormattedTime(deed, user.id)
    const endDateFormattedTime = getEndDateFormattedTime(deed, user.id)

    const renderForeground = (): JSX.Element | null => {
      if (eventTypes.includes(deed.type)) {
        return (
          <View style={styles.titleContainer}>
            <H4 weight="500" colour={colors.white} center style={styles.title} numberOfLines={2} marginBottom={32}>
              {waitlist ? t('waitlistJoined') : t(`youAreGoodToGo`)}
            </H4>
            <Body2 colour={colors.white} center style={{ height: 20 }}>
              {t('date:weekdayDayMonthShort', {
                date: deed.virtual
                  ? deed.getStartingAtForUser(user.id) || deed.createdAt
                  : { value: deed.getStartingAtForUser(user.id) || deed.createdAt, timeZone: deed.timeZone },
              })}
            </Body2>
            <Body2 colour={colors.white} center style={{ height: 20 }}>
              {getEventStartDateTime(deed, user.id)} – {getEventEndDateTime(deed, user.id)}
            </Body2>
            <Body2 colour={colors.white} center numberOfLines={2}>
              {deed.location}
            </Body2>
            {waitlist ? (
              <Spacing marginTop={15}>
                <Body2 colour={colors.white} center>
                  {t`eventIsAtFullCapacity`}
                </Body2>
              </Spacing>
            ) : null}
          </View>
        )
      }
      if (deed.type === 'Project') {
        return (
          <View style={styles.titleContainer}>
            <H5 lineHeight={30} weight="500" colour={colors.white} center style={styles.title} numberOfLines={2}>
              {t('thankYouForYourInterest', { deedName: deed.name })}
            </H5>
            {deed.virtual ? (
              <Body1 colour={colors.white} center numberOfLines={2}>
                {t`locationVirtual`}
              </Body1>
            ) : null}
            {deed.location && !deed.virtual ? (
              <Body1 colour={colors.white} center numberOfLines={2}>
                {deed.location}
              </Body1>
            ) : null}
          </View>
        )
      }
      return null
    }

    return (
      <Wrapper>
        <StatusBar />
        <ImageHeaderScrollView
          backTo={`/deeds/${deed.id}`}
          close
          image={deed.get('mainPicture') as string}
          color={brand?.brandColor || (eventTypes.includes(deed.type) ? colors.teal : colors.pink)}
          minOverlayOpacity={1}
          maxOverlayOpacity={1}
          maxHeight={280}
          minHeight={215}
          title={deed.name}
          subTitle={
            startDateformattedTime
              ? `${startDateformattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
              : ''
          }
          subTitle2={!deed.virtual && deed.location}
          renderForeground={renderForeground}
          renderHeader={false}
        >
          <View style={{ minHeight: 100 }}>
            <View style={{ display: 'flex', alignContent: 'center', alignSelf: 'center', alignItems: 'center' }}>
              {errorVto && (
                <Spacing marginTop={32} marginHorizontal={64}>
                  <Row
                    style={{
                      backgroundColor: '#FBEAEB',
                      padding: 20,
                      justifyContent: 'flex-start',
                      gap: 12,
                    }}
                  >
                    <View>
                      <Icon icon="infoCircleOutlinedRed" width={18} height={18} />
                    </View>
                    <View style={{ flexShrink: 1 }}>
                      <Body2>
                        <Trans
                          t={t}
                          i18nKey="weWereNotAbleToProcessVTO"
                          values={{
                            vtoVendor: 'Workday',
                          }}
                        />
                      </Body2>
                    </View>
                  </Row>
                </Spacing>
              )}

              <Spacing marginTop={errorVto ? 20 : 32} marginBottom={32}>
                <ShareBlock title={t`increaseYourImpactBySharingThisDeed`} deed={deed} centered />
              </Spacing>
            </View>
          </View>
        </ImageHeaderScrollView>
      </Wrapper>
    )
  }
}

const styles = StyleSheet.create({
  titleContainer: {
    width: '100%',
    height: 240,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 64,
  },
  title: {
    minHeight: 30,
    marginBottom: 25,
  },
  content: {
    alignSelf: 'stretch',
    paddingHorizontal: 25,
    paddingBottom: 100,
    paddingTop: 20,
    marginTop: -5,
    minHeight: 100,
  },
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginLeft: -25,
    marginRight: -25,
  },
})

const withConnect = connect(
  (state, props) => ({
    deed: selectDeedById(state, props.match.params.deed),
    user: selectCurrentUser(state),
    locations: selectUserLocations(state),
    loading: selectLoading(state),
    error: selectError(state),
    errorVto: selectErrorVto(state),
    brand: selectUserBrand(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedShare', reducer })
const withEpics = injectEpics({ key: 'deedShare', epics })

export default compose(withTheme, withReducer, withEpics, withConnect, withTranslation('deedScreen'))(DeedShare)
