import React from 'react'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'

const Banner = styled.View<{ backgroundColor: string }, EmotionTheme>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ theme }) => (!theme.metrics.isSmall ? '15px' : '10px')};
`

const BannerText = styled.Text<{ textColor: string }, EmotionTheme>`
  color: ${({ textColor }) => textColor};
  text-align: center;
  font-size: ${({ theme }) => (!theme.metrics.isSmall ? '14px' : '12px')};
  font-family: GTWalsheimLC;
  font-weight: 500;
`

interface NotificationBannerProps {
  children: React.ReactNode
  backgroundColor?: string
  textColor?: string
}

const NotificationBanner = ({ children, backgroundColor, textColor }: NotificationBannerProps) => {
  const { colors } = useDeedTheme()

  return (
    <Banner backgroundColor={backgroundColor || colors.pink}>
      <BannerText textColor={textColor || colors.white}>{children}</BannerText>
    </Banner>
  )
}
NotificationBanner.displayName = 'NotificationBanner'
export default NotificationBanner
