import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import injectReducer from 'src/utils/injectReducer'
import injectEpics from 'src/utils/injectEpics'
import { Loading, LinkButton, Spacing, Tabs, DashedContainer, Text, Screen, ScrollView } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import Deed from 'src/retired/blocks/Deed'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { selectCurrentUser, selectUserById } from 'src/entities/user/selectors'
import {
  selectUpcomingDeedsForUser,
  selectPastDeedsForUser,
  selectOngoingDeedsForUser,
  selectUpcomingDeedsLoaded,
  selectPastDeedsLoaded,
} from 'src/entities/deed/selectors'
import User from 'src/entities/user/model'
import { colors } from 'src/theme'
import { styled } from 'src/theme/styled'

import { selectLoading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const DeedsWrapper = styled.View`
  padding-top: 15;
  margin-bottom: -15;
`

const linkButtonStyle = {
  borderRadius: 100,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.grayMediumDark,
  alignSelf: 'flex-start',
  paddingLeft: 15,
  paddingRight: 15,
  height: 21,
  justifyContent: 'center',
}

export class Deeds extends PureComponent {
  componentDidMount() {
    const userId = this.props.match.params.id
    this.props.actions.initAction(userId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextUserId = nextProps.match.params.id
    if (nextUserId && nextUserId !== this.props.match.params.id) {
      this.props.actions.initAction(nextUserId)
    }
  }

  render() {
    const user = this.props.user || (this.props.location.state && new User(this.props.location.state.user))
    const isMyProfile = !this.props.match.params.id
    const { t, upcomingDeeds, pastDeeds, ongoingDeeds, upcomingDeedsLoaded, pastDeedsLoaded } = this.props
    if (!user) {
      return <Loading />
    }

    return (
      <Screen>
        <ScrollView>
          <NavigationHeader
            backTo={isMyProfile ? '/profile' : `/profile/${user.id}`}
            title={isMyProfile ? t`myDeeds` : t('usernamesDeeds', { userName: user.fullName.first })}
            transparent
          />
          <Tabs activeTab={this.props.location.hash === '#past' ? 2 : this.props.location.hash === '#ongoing' ? 1 : 0}>
            <Tabs.Tab title={t`upcoming`}>
              {upcomingDeeds.size > 0 ? (
                <DeedsWrapper>
                  {upcomingDeeds.toArray().map((deed) => {
                    const isUserAttending = deed.isUserAttending(user)
                    const hasUserAppliedForRole = deed.hasUserAppliedForRole(user)
                    const checkIn = isUserAttending && deed.isDuringCheckInWindow()
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <Deed
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        checkIn={checkIn}
                        isCheckedIn={isCheckedIn}
                        applied={hasUserAppliedForRole}
                        approved={isUserAttending}
                        isUpcoming
                      />
                    )
                  })}
                  {!upcomingDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>
                  {upcomingDeedsLoaded ? (
                    <DashedContainer>
                      <Spacing marginBottom={20} style={{ width: '100%' }}>
                        <Text size={12} medium color={colors.grayMediumDark}>
                          {isMyProfile
                            ? t`youHaveNoUpcomingDeeds`
                            : t('inviteAUser', { userName: user.fullName.first })}
                        </Text>
                      </Spacing>
                      <LinkButton
                        to={isMyProfile ? '/search?tab=volunteer' : '/profile/deeds'}
                        color="transparent"
                        style={linkButtonStyle}
                      >
                        <Text size={12} lineHeight={21} light left color={colors.grayMediumDark}>
                          {t`browse`}
                        </Text>
                      </LinkButton>
                    </DashedContainer>
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </Tabs.Tab>
            <Tabs.Tab title={t`ongoing`}>
              {ongoingDeeds.size > 0 ? (
                <DeedsWrapper>
                  {ongoingDeeds.toArray().map((deed) => {
                    const isUserAttending = deed.isUserAttending(user)
                    const hasUserAppliedForRole = deed.hasUserAppliedForRole(user)
                    const checkIn = isUserAttending && deed.isDuringCheckInWindow()
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <Deed
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        checkIn={checkIn}
                        isCheckedIn={isCheckedIn}
                        applied={hasUserAppliedForRole}
                        approved={isUserAttending}
                        isUpcoming
                      />
                    )
                  })}
                  {!upcomingDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>
                  {upcomingDeedsLoaded ? (
                    <DashedContainer>
                      <Spacing marginBottom={20} style={{ width: '100%' }}>
                        <Text size={12} medium color={colors.grayMediumDark}>
                          {isMyProfile ? t`youHaveNoOngoingDeeds` : t('inviteAUser', { userName: user.fullName.first })}
                        </Text>
                      </Spacing>
                      <LinkButton
                        to={isMyProfile ? '/search?tab=volunteer' : '/profile/deeds'}
                        color="transparent"
                        style={linkButtonStyle}
                      >
                        <Text size={12} lineHeight={21} light left color={colors.grayMediumDark}>
                          {t`browse`}
                        </Text>
                      </LinkButton>
                    </DashedContainer>
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </Tabs.Tab>
            <Tabs.Tab title={t`past`}>
              {pastDeeds.size > 0 ? (
                <DeedsWrapper>
                  {pastDeeds.toArray().map((deed) => {
                    const canCheckIn = deed.isUserAttending(user) && deed.isDuringCheckInWindow()
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <Deed
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        canCheckIn={canCheckIn}
                        isCheckedIn={isCheckedIn}
                      />
                    )
                  })}
                  {!pastDeedsLoaded ? <ActivityIndicator /> : null}
                </DeedsWrapper>
              ) : (
                <View>
                  {pastDeedsLoaded ? (
                    <DashedContainer>
                      <Spacing marginBottom={20} style={{ width: '100%' }}>
                        <Text size={12} medium color={colors.grayMediumDark}>
                          {isMyProfile ? t`youHaveNoPastDeeds` : t('inviteAUser', { userName: user.fullName.first })}
                        </Text>
                      </Spacing>
                      <LinkButton
                        to={isMyProfile ? '/search?tab=volunteer' : '/profile/deeds'}
                        color="transparent"
                        style={linkButtonStyle}
                      >
                        <Text size={12} lineHeight={21} light left color={colors.grayMediumDark}>
                          {t`browse`}
                        </Text>
                      </LinkButton>
                    </DashedContainer>
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </Tabs.Tab>
          </Tabs>
        </ScrollView>
      </Screen>
    )
  }
}
Deeds.propTypes = {
  user: PropTypes.object,
  upcomingDeeds: PropTypes.object,
  pastDeeds: PropTypes.object,
}

const withConnect = connect(
  (state, props) => ({
    loading: selectLoading(state),
    upcomingDeedsLoaded: selectUpcomingDeedsLoaded(state, props.match.params.id),
    pastDeedsLoaded: selectPastDeedsLoaded(state, props.match.params.id),
    upcomingDeeds: selectUpcomingDeedsForUser(
      state,
      props.match.params.id ? selectUserById(props.match.params.id) : selectCurrentUser(state)
    ),
    pastDeeds: selectPastDeedsForUser(
      state,
      props.match.params.id ? selectUserById(props.match.params.id) : selectCurrentUser(state)
    ),
    ongoingDeeds: selectOngoingDeedsForUser(
      state,
      props.match.params.id ? selectUserById(props.match.params.id) : selectCurrentUser(state)
    ),
    user: props.match.params.id ? selectUserById(state, props.match.params.id) : selectCurrentUser(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'profileDeeds', reducer })
const withEpics = injectEpics({ key: 'profileDeeds', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('deedsProfile'))(Deeds)
