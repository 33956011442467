import React from 'react'
import { View } from 'react-primitives'

import { BadgeSubsetFragment } from 'src/generated/graphql'
import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Screen } from 'src/retired/elements'

import { BadgeWithTitle } from '../BadgeWithTitle'

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const BadgesList = ({ badges }: { badges: BadgeSubsetFragment[] }) => {
  const { metrics } = useDeedTheme()
  const itemsPerRow = metrics.isSmall ? 3 : 4

  return (
    <Screen>
      <Container>
        {badges.map((badge) => (
          <View
            key={badge.id}
            style={{
              paddingRight: badges.length < 4 ? 20 : 0,
              paddingLeft: badges.length < 4 ? 20 : 0,
              width: `${100 / itemsPerRow}%`,
              paddingBottom: 30,
              justifyContent: badges.length > 3 ? 'space-between' : 'initial',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <BadgeWithTitle badge={badge} />
          </View>
        ))}
      </Container>
    </Screen>
  )
}
