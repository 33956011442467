import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { colors } from 'src/theme'
import Icon from 'src/retired/shared/Icon'
import LinkButton from 'src/retired/elements/LinkButton'
import { useAffiliationsListQuery } from 'src/generated/graphql'

const AffiliationButton = ({
  nonprofitId,
  color = 'primary',
}: {
  nonprofitId: string
  color?: string
}): React.ReactElement => {
  const { t } = useTranslation('affiliationButton')
  const { data } = useAffiliationsListQuery()

  const isAffiliatedToNonprofit = (data?.affiliations ?? []).find(
    (affiliation) => affiliation.nonprofitId === nonprofitId
  )

  if (isAffiliatedToNonprofit) {
    return (
      <LinkButton
        to={`/organization/${nonprofitId}/affiliations`}
        button
        size="small"
        color="light"
        textStyle={{ color: colors.black }}
        style={{ width: 180, marginTop: 20, marginBottom: 20, display: 'flex', textAnchor: 'middle' }}
      >
        {t`viewAffiliations`}
        <View style={{ alignItems: 'center', marginLeft: 8 }}>
          <Icon icon="eyeIcon" width={16} height={10} />
        </View>
      </LinkButton>
    )
  }

  return (
    <LinkButton
      to={`/organization/${nonprofitId}/affiliation/new`}
      button
      size="small"
      color={color}
      style={{ marginTop: 20, marginBottom: 20, width: 'auto', paddingLeft: 15, paddingRight: 15 }}
    >
      {t`addAffiliation`} +
    </LinkButton>
  )
}

export default AffiliationButton
