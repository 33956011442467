import jwtDecode from 'jwt-decode'

import { CLEAR as ENTITIES_AUTH_CLEAR, STORE as ENTITIES_AUTH_STORE } from 'src/entities/auth/constants'
import { selectDecodedToken } from 'src/entities/auth/selectors'
import { ADD as ENTITIES_USER_ADD } from 'src/entities/user/constants'
import metricsClient from 'src/metrics/client'

export const trackProfileActions = (store, action) => {
  const { identify, reset } = metricsClient
  const state = store.getState()

  switch (action.type) {
    case 'RESET':
    case ENTITIES_AUTH_CLEAR: {
      reset()
      break
    }

    case ENTITIES_AUTH_STORE: {
      const { token } = action

      try {
        const decodedToken = jwtDecode(token)
        const decodedTokenId = decodedToken?._id

        identify(decodedTokenId, { id: decodedTokenId })
      } catch (error) {
        // @NOTE-RT: Do nothing if we can't decode the token
      }

      break
    }

    case ENTITIES_USER_ADD: {
      const decodedToken = selectDecodedToken(state)
      const decodedTokenId = decodedToken?._id
      const { user } = action

      if (user.id === decodedTokenId) {
        identify(user.id, user.toSegmentIdentity())
      }

      break
    }
  }
}

export default trackProfileActions
