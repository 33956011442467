import { useCallback, useState } from 'react'
import _ from 'lodash'
import { LazyQueryResult } from '@apollo/client'

import { EmployeesQuery, EmployeesQueryVariables, useEmployeesLazyQuery } from 'src/generated/graphql'

export type Employee = EmployeesQuery['employees']['users'][number]

const take = 10

export const useFetchEmployees = (): {
  search: string
  setSearch: (value: string) => void
  result: LazyQueryResult<EmployeesQuery, EmployeesQueryVariables>
} => {
  const [search, setSearch] = useState('')
  const [fetchEmployees, result] = useEmployeesLazyQuery({
    // Needed for fetchMore to work without making redundant calls https://github.com/apollographql/apollo-client/issues/7689
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first', // this line
    notifyOnNetworkStatusChange: true,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchEmployeesDebounced = useCallback(_.debounce(fetchEmployees, 600), [])

  return {
    search,
    setSearch: (value: string): void => {
      setSearch(value)
      if (value.length > 0) {
        fetchEmployeesDebounced({ variables: { take, search: value } })
      }
    },
    result:
      search.length > 0
        ? result
        : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ({
            ...result,
            loading: false,
            error: undefined,
            data: {
              employees: {
                users: [],
                total: 0,
              },
            },
          } as LazyQueryResult<EmployeesQuery, EmployeesQueryVariables>),
  }
}
