import type { History } from 'history'

import { historyStack } from 'src/navigation'

const makeGoBack =
  (history: History, backTo = '', forceBackTo = false) =>
  (e: Event) => {
    e.preventDefault()

    const isPreviousRouteCurrentRoute = historyStack.length === 1 && historyStack[0] === history?.location?.pathname
    if (
      // Needed for Capacitor: Somehow if you land on a page directly, the first item of historyStack is filled with the current location
      // even without having done any navigation
      isPreviousRouteCurrentRoute ||
      // No history
      historyStack.length === 0 ||
      // Or previous navigation happened via the back button.
      // Back button shouldn't take back to the previous location, if it was also transitioned via the back button
      history.location.state?.fromBack
    ) {
      history.push(backTo || '/', { fromBack: true })
    } else if (forceBackTo && backTo) {
      history.push(backTo)
    } else {
      history.goBack()
    }
  }
export default makeGoBack
