import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Body2, H5 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'

export const BadgeEmpty = () => {
  const { t } = useTranslation('badges')

  return (
    <View style={{ alignItems: 'center' }}>
      <Icon icon="starBadge" width={87} />
      <H5 weight="500">{t`getYourFirstBadge`}</H5>
      <Body2 lineHeight={24} marginTop={10} center>{t`unlockFirstBadge`}</Body2>
    </View>
  )
}
