import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  FETCH_CAMPAIGNS,
  FETCH_CAUSES,
  FETCH_SKILLS,
  FETCH_UPCOMING_DEEDS,
  FETCH_FEATURED_DEEDS,
  FETCH_SUGGESTED_DEEDS,
} from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: object) => ({
  type: INIT_FAILED,
  error,
})
export const fetchCampaignsAction = () => ({
  type: FETCH_CAMPAIGNS,
})
export const fetchCausesAction = () => ({
  type: FETCH_CAUSES,
})
export const fetchSkillsAction = () => ({
  type: FETCH_SKILLS,
})
export const fetchUpcomingDeedsAction = () => ({
  type: FETCH_UPCOMING_DEEDS,
})
export const fetchFeaturedDeedsAction = () => ({
  type: FETCH_FEATURED_DEEDS,
})
export const fetchSuggestedDeedsAction = () => ({
  type: FETCH_SUGGESTED_DEEDS,
})
