import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'

import { currencyFormat } from 'src/containers/modules/CurrencyFormat'
import Donation from 'src/entities/donation/model'
import { selectDonationsForUserLoaded } from 'src/entities/donation/selectors'

import { DonationReceiptEmpty } from './DonationReceiptEmpty'
import { DonationsReceiptLoading } from './DonationReceiptLoading'

interface Column {
  id: 'date' | 'nonprofitName' | 'nonprofitEin' | 'amount'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

interface RowData {
  date: Date
  nonprofitName: string
  nonprofitEin: string
  amount: string
}

interface DonationReceiptTableProps {
  donations: Donation[]
}

export const DonationReceiptTable = ({ donations }: DonationReceiptTableProps) => {
  const { t } = useTranslation('donationReceipt')

  const donationsLoaded = useSelector(selectDonationsForUserLoaded)

  const columns: readonly Column[] = [
    { id: 'date', label: t('common:date'), minWidth: 170 },
    { id: 'nonprofitName', label: t('organizationName'), minWidth: 100 },
    {
      id: 'nonprofitEin',
      label: t('registrationNumber'),
      minWidth: 170,
    },
    {
      id: 'amount',
      label: t('donationAmount'),
      minWidth: 170,
      align: 'right',
    },
  ]

  const rows = donations?.map<RowData>((donationItem) => ({
    date: new Date(donationItem.date),
    nonprofitName:
      donationItem.nonprofits.map(({ name }) => name).join(', ') || donationItem.externalNonprofitName || '',
    nonprofitEin: donationItem.nonprofits
      .map(({ ein }) => ein)
      .filter(Boolean)
      .join(', '),
    amount: currencyFormat(donationItem.amount, donationItem.currency),
  }))

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ id: columnId, label, align }) => (
              <TableCell key={columnId} {...(align && { align })}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell>
                {t('dayMonthYearShort', {
                  date: row.date,
                })}
              </TableCell>
              <TableCell>{row.nonprofitName}</TableCell>
              <TableCell>{row.nonprofitEin}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        {!rows.length && (
          <TableFooter>
            <TableRow>
              <TableCell align="center" colSpan={5} sx={{ padding: 4 }}>
                {donationsLoaded ? <DonationReceiptEmpty /> : <DonationsReceiptLoading />}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
