import React from 'react'
import PropTypes from 'prop-types'

import config from 'src/config'
import ExternalLink from 'src/retired/elements/ExternalLink'

const AddToCalendar = ({ deed, userId, children, type, ...props }) => (
  <ExternalLink
    href={`${config.apiEndpoint}/api/deeds/${deed.id}/calendar/${userId || ''}${type ? `?type=${type}` : ''}`}
    {...props}
  >
    {children}
  </ExternalLink>
)
AddToCalendar.propTypes = {
  deed: PropTypes.object.isRequired,
  userId: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
}

export default AddToCalendar
