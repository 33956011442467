import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Modal, Row, Text } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { H4, Body } from 'src/retired/shared/Typography'

export const DeleteAccountModal = ({
  onClose,
  onConfirm,
  loading,
  showError,
}: {
  onClose: () => void
  onConfirm: () => Promise<void>
  loading: boolean
  showError: boolean
}) => {
  const { t } = useTranslation('settingsProfile')

  return (
    <Modal style={{ padding: 12 }} visible transparent title={<H4>{t`doYouWantToDeleteAccount`}</H4>}>
      <View style={{ paddingTop: 20 }}>
        <Text>{t`thisCanNotBeUndone`}</Text>
        <Row style={{ justifyContent: 'center', paddingTop: 20 }}>
          <Button palette="secondary" onPress={() => onClose()} style={{ marginRight: 10 }}>
            {t`common:Cancel`}
          </Button>
          <Button palette="primary" onPress={onConfirm} style={{ marginLeft: 10 }} loading={loading}>
            {t`yesDelete`}
          </Button>
        </Row>
        {showError && (
          <Body colour="redDark" style={{ paddingTop: 20 }}>
            {t`errorDeleting`}
          </Body>
        )}
      </View>
    </Modal>
  )
}
