import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import OrganizationChip from 'src/retired/shared/OrganizationChip'
import { H5, Body2 } from 'src/retired/shared/Typography'
import Organization from 'src/entities/organization/model'
import { Row, ScrollView, Divider } from 'src/retired/elements'
import { formatNumber, formatAmount } from 'src/utils/format'
import { currencies } from 'src/containers/modules/CurrencyFormat'
import { Paper } from 'src/components/Paper/Paper'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import truthy from 'src/utils/truthy'
import { jsxJoin } from 'src/utils/jsxJoin'

export const TotalImpact = ({
  communityTitle,
  nonprofits,
  totalDonations,
  totalVolunteering,
}: {
  communityTitle: string
  nonprofits: Organization[]
  totalDonations: number
  totalVolunteering: number
}): JSX.Element | null => {
  const { t } = useTranslation('communityScreen')
  const { colors } = useDeedTheme()

  const nonprofitList = nonprofits.length > 0 && (
    <View key="nonprofitList">
      <Body2 paddingBottom={12}>{t`organizationsSupported`}</Body2>

      <View style={{ alignItems: 'flex-start' }}>
        <ScrollView horizontal style={{ width: '100%' }}>
          <Row>
            {nonprofits.map((nonprofit) => (
              <View key={nonprofit.id} style={{ marginBottom: 5 }}>
                <OrganizationChip organization={nonprofit} link />
              </View>
            ))}
          </Row>
        </ScrollView>
      </View>
    </View>
  )

  const donationImpact = totalDonations > 0 && (
    <Row key="donationImpact" style={{ justifyContent: 'space-between' }}>
      <Body2>{t`totalOfDollars`}</Body2>
      <Body2 colour={colors.purple2}>${formatAmount(totalDonations, currencies.USD.zeroDecimal)}</Body2>
    </Row>
  )

  const volunteeringImpact = totalVolunteering > 0 && (
    <Row key="volunteeringImpact" style={{ justifyContent: 'space-between' }}>
      <Body2>{t`totalOfHours`}</Body2>
      <Body2 colour={colors.purple2}>{formatNumber(totalVolunteering / 60)}</Body2>
    </Row>
  )

  const elements = [nonprofitList, donationImpact, volunteeringImpact].filter(truthy)

  if (elements.length === 0) {
    return null
  }

  const title = (donationImpact || volunteeringImpact) && (
    <H5 weight="500" center paddingBottom={20} style={{ width: '100%', textAlign: 'left' }}>
      {t(`totalImpactAcross`, { communityTitle })}
    </H5>
  )

  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column' }} variant="outlined">
      {title}
      {jsxJoin(elements, (key) => (
        <Divider key={key} marginTop={25} marginBottom={25} />
      ))}
    </Paper>
  )
}
