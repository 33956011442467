import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  TOGGLE_SELECTED_INTEREST,
  TOGGLE_EXPAND_SDGS,
} from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = (user) => ({
  type: INIT_SUCCESS,
  user,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const submitAction = (interests, path) => ({
  type: SUBMIT,
  interests,
  path,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})

export const toggleSelectedInterestAction = (interest) => ({
  type: TOGGLE_SELECTED_INTEREST,
  interest,
})

export const toggleExpandSDGsAction = (expanded) => ({
  type: TOGGLE_EXPAND_SDGS,
  expanded,
})
