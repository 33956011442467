import React from 'react'
import { Alert, Box, CircularProgress, Grid } from '@mui/material'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Employee } from 'src/utils/useFetchEmployees'
import { Modal, ModalContents, ModalTitle, ModalButton } from 'src/components/Modal/Modal'
import { CommunityMembersDocument, useAddCommunityUsersMutation } from 'src/generated/graphql'
import { BackButton } from 'src/components/Buttons/BackButton/BackButton'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row } from 'src/retired/elements'

import { ChosenMember } from '../ChosenMember'

interface Props {
  communityId: string
  onClickClose: () => void
  goBack: () => void
  chosenMembers: Employee[]
  removeMember: (userId: string) => void
}

export const AddMembersReviewModal = ({ communityId, onClickClose, chosenMembers, removeMember, goBack }: Props) => {
  const { t } = useTranslation('communityScreen')
  const { colors } = useDeedTheme()
  const chosenMemberIds = chosenMembers.map((m) => m.id)

  const [addCommunityUsers, { loading, error }] = useAddCommunityUsersMutation({
    onCompleted: () => {
      onClickClose()
    },
    variables: {
      communityId,
      userIds: chosenMemberIds,
    },
    refetchQueries: [
      {
        query: CommunityMembersDocument,
        variables: {
          communityId,
          userIds: chosenMemberIds,
        },
      },
      'communityMembers',
    ],
  })

  return (
    <Modal open fullWidth onClose={onClickClose}>
      <ModalContents
        onClickClose={onClickClose}
        body={
          <View>
            <Row>
              <BackButton onPress={goBack} />
              <ModalTitle style={{ marginBottom: 0, marginLeft: 12 }}>
                {t('addXMembers', { count: chosenMembers.length })}
              </ModalTitle>
            </Row>

            <Box paddingTop={4} paddingBottom={2} maxHeight={300} style={{ overflowY: 'auto' }}>
              {error ? (
                <Alert severity="error" sx={{ '& .v5-MuiAlert-message': { marginTop: '2px' } }}>
                  {t`common:anErrorOccurred`}
                </Alert>
              ) : (
                <Alert severity="warning" sx={{ '& .v5-MuiAlert-message': { marginTop: '2px' } }}>
                  {t('addMembersConfirm', { count: chosenMembers.length })}
                </Alert>
              )}
            </Box>

            <Grid container spacing={1}>
              {chosenMembers.map((member) => (
                <Grid key={member.id} item xs={12} md={6}>
                  <ChosenMember member={member} toggleMember={removeMember} />
                </Grid>
              ))}
            </Grid>
          </View>
        }
        actions={
          <>
            <ModalButton
              style={{ color: colors.primaryBlue, marginRight: 16 }}
              variant="outlined"
              onClick={goBack}
              disabled={loading}
            >
              {t`common:back`}
            </ModalButton>
            <ModalButton
              disabled={chosenMembers.length === 0 || loading}
              // Weird one-of yellow
              style={{ backgroundColor: '#FFC475', color: colors.black }}
              onClick={() => {
                void addCommunityUsers()
              }}
            >
              {loading ? <CircularProgress size={21} style={{ color: 'white' }} /> : t`add`}
            </ModalButton>
          </>
        }
      />
    </Modal>
  )
}
