import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import Api from 'src/entities/api'
import OrganizationApi from 'src/entities/organization/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectSelectedLocation } from 'src/containers/screens/Register/Location/selectors'
import { selectCompaniesLoaded } from 'src/entities/organization/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { initSuccessAction, initFailedAction, createSuccessAction, createFailedAction } from './actions'
import { INIT, CREATE } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()

    if (selectCompaniesLoaded(state)) {
      return Observable.of(initSuccessAction())
    }

    return OrganizationApi.fetchCompanies()
      .mergeMap((actions) => [...actions, initSuccessAction()])
      .catch((e) =>
        Observable.of(initFailedAction(e), showErrorAction(i18n.t('credentialsRegister:failedLoadingCompanies')))
      )
  })

const create = (action$, store) =>
  action$
    .ofType(CREATE)
    .exhaustMap(({ firstName, lastName, email, password, company, externalId }) => {
      const state = store.getState()
      const location = selectSelectedLocation(state)
      const expediteOnboarding = selectLocalSetting(state, 'expediteOnboarding')
      const previousLocation = selectLocalSetting(state, 'previousLocation') || '/'

      const data = {
        fullName: { first: firstName, last: lastName },
        email,
        password,
      }
      if (externalId) {
        data.externalId = externalId
      }
      if (company) {
        data.organization = company
      } else if (location) {
        data.location = location
      }
      return UserApi.create(data)
        .mergeMap((action) => {
          if (!action.token) {
            return [replace('/register/company/confirm', { email })]
          }

          Api.defaultHeaders.Authorization = `Bearer ${action.token}`
          return [
            action,
            createSuccessAction(),
            replace(
              expediteOnboarding
                ? previousLocation
                : location && !company
                ? '/register/interests'
                : '/register/location'
            ),
          ]
        })
        .catch((e) => {
          if (e.responseJson) {
            return [
              createFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(createFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    })
    .catch((e) => Observable.of(createFailedAction(e)))

export default [init, create]
