import colors from 'src/theme/colors'

export const getContrastColor = (initialColor: string): string => {
  // convert HEX to RGB
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(initialColor)

  if (result) {
    const red = parseInt(result[1], 16)
    const green = parseInt(result[2], 16)
    const blue = parseInt(result[3], 16)

    const contrastText = Math.round((red * 299 + green * 587 + blue * 114) / 1000)

    if (contrastText <= 125) {
      return colors.white
    }
  }

  return colors.black
}
