import Box from '@mui/material/Box'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { VolunteerRewardsUserBudgetType } from 'src/generated/graphql'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getHours } from 'src/utils/convertDuration'

const InfoListContainer = ({ children }: { children: React.ReactNode }) => {
  const { colors } = useDeedTheme()
  return (
    <Box border={`1px solid ${colors.borderGray}`} borderRadius="3px" margin="0 24px 24px">
      {children}
    </Box>
  )
}

const InfoListItem = ({
  label,
  content,
  hideDivider = false,
}: {
  label: string
  content: string | JSX.Element
  hideDivider?: boolean
}) => {
  const { colors } = useDeedTheme()
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="12px"
      borderBottom={hideDivider ? 0 : `1px solid ${colors.borderGray}`}
    >
      <Body2>{label}</Body2>
      <Body2 weight="500" colour={colors.brandColor}>
        {content}
      </Body2>
    </Box>
  )
}

export const VolunteerRewardsSummary = ({
  volunteerRewardsUserBudget,
}: {
  volunteerRewardsUserBudget: VolunteerRewardsUserBudgetType
}) => {
  const { t } = useTranslation('volunteerRewardsSection')

  if (!volunteerRewardsUserBudget) {
    return null
  }

  const {
    currency,
    amountApprovedInMoney,
    amountApprovedInMinutes,
    amountEligiblePendingInMoney,
    amountEligiblePendingInMinutes,
    limitPerUserInMoney,
    limitPerUserInMinutes,
  } = volunteerRewardsUserBudget
  const remainingBudgetInMoney =
    (limitPerUserInMoney ?? 0) - (amountApprovedInMoney ?? 0) - (amountEligiblePendingInMoney ?? 0)
  const remainingBudgetInMinutes =
    (limitPerUserInMinutes ?? 0) - (amountApprovedInMinutes ?? 0) - (amountEligiblePendingInMinutes ?? 0)

  return (
    <InfoListContainer>
      <InfoListItem
        label={t`remainingVolunteerGrants`}
        content={
          <Trans
            t={t}
            i18nKey="hoursAndMoney"
            values={{ hours: remainingBudgetInMinutes ? getHours(remainingBudgetInMinutes) : 0 }}
            components={{
              CurrencyFormat: <CurrencyFormat baseCurrency={currency} amount={remainingBudgetInMoney} />,
            }}
          />
        }
      />
      <InfoListItem
        label={t`grantsPendingApproval`}
        content={
          <Trans
            t={t}
            i18nKey="hoursAndMoney"
            values={{ hours: amountEligiblePendingInMinutes ? getHours(amountEligiblePendingInMinutes) : 0 }}
            components={{
              CurrencyFormat: <CurrencyFormat baseCurrency={currency} amount={amountEligiblePendingInMoney} />,
            }}
          />
        }
      />
      <InfoListItem
        label={t`totalGrantsMatched`}
        content={
          <Trans
            t={t}
            i18nKey="hoursAndMoney"
            values={{ hours: amountApprovedInMinutes ? getHours(amountApprovedInMinutes) : 0 }}
            components={{
              CurrencyFormat: <CurrencyFormat baseCurrency={currency} amount={amountApprovedInMoney ?? 0} />,
            }}
          />
        }
        hideDivider
      />
    </InfoListContainer>
  )
}
