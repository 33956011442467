import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import { Row } from 'src/retired/elements'
import Button from 'src/retired/elements/Button'
import Donation from 'src/entities/donation/model'
import { Body1 } from 'src/retired/shared/Typography'

interface PostDonationFormBannerProps {
  donation: Donation
}

const PostDonationFormBanner = ({ donation }: PostDonationFormBannerProps): JSX.Element | null => {
  const { t } = useTranslation('postDonationForm')
  const { colors } = useDeedTheme()

  if (!donation.deed?.forms?.length) {
    return null
  }

  return (
    <View style={{ marginBottom: 20 }}>
      {donation.deed.forms.map((form, i) => (
        <View key={i} style={{ backgroundColor: colors.brandColor, marginBottom: 20, borderRadius: 10, padding: 20 }}>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Body1 colour={colors.white} bold>
              {form.name}
            </Body1>

            <Link to={`/donation-complete/${donation.id}/forms/${form.id}?deed=${donation.deed.id}`}>
              <Button
                id={form.id}
                size="extraSmall"
                style={{ width: 250 }}
                color="outlined"
              >{t`fillOutTheForm`}</Button>
            </Link>
          </Row>
        </View>
      ))}
    </View>
  )
}

export default PostDonationFormBanner
