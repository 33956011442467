import React from 'react'
import { View } from 'react-primitives'

import { Body2 } from 'src/retired/shared/Typography'
import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import Icon from 'src/retired/shared/Icon'

/**
 * @NOTE-DP: this basically mimics MUI's Alert's style
 * In the future we need to figure out how to support MUI on native without re-implmemnting all of it
 */
const WarningMessageStyled = styled.View<object, EmotionTheme>`
  border-radius: 8px;
  padding: 6px 16px;
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const WarningMessage = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <WarningMessageStyled>
    <View style={{ paddingTop: 7, paddingBottom: 7, paddingRight: 12 }}>
      <Icon icon="warn" width={22} height={22} />
    </View>
    <Body2>{children}</Body2>
  </WarningMessageStyled>
)
