import { ADD, ADD_MULTIPLE, LIST_LOADED, REMOVE } from './constants'
import DonationSchedule from './model'
import { DonationScheduleMap } from './reducer'

export const addAction = (donationSchedule: DonationSchedule) =>
  ({
    type: ADD,
    donationSchedule,
  } as const)

export const addMultipleAction = (donationSchedules: DonationScheduleMap) =>
  ({
    type: ADD_MULTIPLE,
    donationSchedules,
  } as const)

export const listLoadedAction = () =>
  ({
    type: LIST_LOADED,
  } as const)

export const removeAction = (id: string) =>
  ({
    type: REMOVE,
    id,
  } as const)

export type DonationScheduleActionTypes = ReturnType<
  typeof addAction | typeof addMultipleAction | typeof listLoadedAction | typeof removeAction
>
