/* eslint-disable react/no-unused-prop-types */
import { View } from 'react-primitives'
import React from 'react'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Map, MapLink, OnLayout } from 'src/retired/elements'
import LocationLatLng from 'src/entities/locationLatLng/model'
import Location from 'src/entities/location/model'

interface BoxProps {
  children: React.ReactNode
  half?: boolean
  first?: boolean
  style?: any
}
// @TODO: extract to components
export const Box = ({ children, half = false, first = false, style = {} }: BoxProps): JSX.Element => {
  const { metrics, colors } = useDeedTheme()

  return (
    <View
      style={{
        width: metrics.isMedium && half ? '50%' : '100%',
      }}
    >
      <View
        style={[
          {
            alignSelf: 'stretch',
            borderStyle: 'solid',
            borderColor: colors.borderGray,
            backgroundColor: 'white',
            borderTopWidth: metrics.isSmall && first ? 0 : 1,
            borderBottomWidth: 1,
            paddingTop: 25,
            paddingBottom: 5,
            paddingLeft: 25,
            paddingRight: 25,
            overflow: 'hidden',
            marginBottom: metrics.isSmall ? 18 : metrics.isMedium ? 12 : 30,
            marginLeft: metrics.isMedium ? 6 : 0,
            marginRight: metrics.isMedium ? 6 : 0,
          },
          metrics.isSmall
            ? {}
            : {
                flexShrink: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderRadius: 12,
              },
          style,
        ]}
      >
        {children}
      </View>
    </View>
  )
}

export const MapBlock = ({
  location,
  locationLatLng,
}: {
  location: Location
  locationLatLng: LocationLatLng
}): JSX.Element => (
  <Box>
    <MapLink
      size={15}
      medium
      underline
      latitude={locationLatLng.coordinates[1]}
      longitude={locationLatLng.coordinates[0]}
    >
      {location}
    </MapLink>
    <View style={{ marginTop: 20, marginHorizontal: -26, marginBottom: -26 }}>
      <OnLayout>
        {({ width }: { width: number }) => (
          <Map
            width={width > 800 ? 800 : width}
            height={Math.floor(width * (width > 640 ? 0.4 : 0.8))}
            latitude={locationLatLng.coordinates[1]}
            longitude={locationLatLng.coordinates[0]}
          />
        )}
      </OnLayout>
    </View>
  </Box>
)
