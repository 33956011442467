import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Button, ActivityIndicator } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body2 } from 'src/retired/shared/Typography'
import { ColorsProps } from 'src/theme/colors'
import { ActivityStatus } from 'src/containers/modules/Actions/types'

const getButtonDataByStatus = (status: ActivityStatus | null, colors: ColorsProps, isActionExpired?: boolean) => {
  if (isActionExpired && status !== ActivityStatus.Completed) {
    return {
      backgroundColor: colors.grayLighter,
      translationKey: 'noLongerActive',
      iconName: 'disabledHourGlass',
      textColor: colors.gray,
    }
  }

  switch (status) {
    case ActivityStatus.Completed:
      return {
        backgroundColor: colors.greenLighter,
        translationKey: 'actionCompleted',
        iconName: 'checkmark',
        textColor: colors.greenDark,
      }

    case ActivityStatus.Joined:
      return {
        backgroundColor: colors.brandColor,
        translationKey: 'markAsCompleted',
        iconName: null,
        textColor: colors.white,
        outlined: true,
      }

    default: {
      return {
        backgroundColor: colors.brandColor,
        translationKey: 'joinAction',
        iconName: null,
        textColor: colors.white,
      }
    }
  }
}

export const ActionButton = ({
  status,
  onPress,
  style,
  loading,
  disabled,
  fluid,
  forceFilled,
  isActionExpired,
}: {
  status: ActivityStatus | null
  onPress: () => void
  style?: React.CSSProperties
  loading?: boolean
  disabled?: boolean
  fluid?: boolean
  forceFilled?: boolean
  isActionExpired?: boolean
}) => {
  const { t } = useTranslation('actions')
  const { colors } = useDeedTheme()

  const { backgroundColor, iconName, translationKey, textColor, outlined } = getButtonDataByStatus(
    status,
    colors,
    isActionExpired
  )

  const useOutlined = outlined && !forceFilled

  return (
    <Button
      style={{
        backgroundColor: useOutlined ? textColor : backgroundColor,
        shadowOpacity: 0,
        boxShadow: 'none',
        ...(useOutlined ? { borderWidth: 2, borderColor: backgroundColor } : {}),
        ...style,
      }}
      onPress={(e: any) => {
        e.stopPropagation()
        onPress()
      }}
      disabled={disabled || loading}
      fluid={fluid}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <ActivityIndicator color={useOutlined ? colors.brandColor : colors.white} />
        ) : (
          <>
            {iconName && (
              <View style={{ paddingRight: 10 }}>
                <Icon icon={iconName} width={17} height={17} hexColor={useOutlined ? backgroundColor : textColor} />
              </View>
            )}
            <Body2 style={{ fontWeight: '600', color: useOutlined ? backgroundColor : textColor }}>
              {t(translationKey)}
            </Body2>
          </>
        )}
      </View>
    </Button>
  )
}
