import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'

const DashedContainer = ({ style = {}, ...props }) => (
  <View
    {...props}
    style={[
      style,
      {
        backgroundColor: '#ECE9E9',
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        width: 130,
        height: 108,
      },
    ]}
  />
)
DashedContainer.propTypes = {
  style: PropTypes.any,
}
export default DashedContainer
