import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Row, ActivityIndicator } from 'src/retired/elements'
import { Activity, ActivityStatus } from 'src/containers/modules/Actions/types'
import { ActionButton } from 'src/containers/modules/Actions/Card/ActionButton'
import { OutlineButton } from 'src/retired/elements/Button/OutlineButton'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const ButtonBar = ({
  activity,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  loading,
  isExpired,
}: {
  activity: Activity | null
  onPrimaryButtonPress: () => void
  onSecondaryButtonPress: () => void
  loading: boolean
  isExpired?: boolean
}) => {
  const { t } = useTranslation('actions')
  const { metrics, colors } = useDeedTheme()

  // eslint-disable-next-line react/no-unstable-nested-components
  const Loader = ({ padding }: { padding: 'paddingLeft' | 'paddingRight' }) =>
    loading ? (
      <View style={{ marginBottom: 12, [padding]: 12 }}>
        <ActivityIndicator color={colors.brandColor} />
      </View>
    ) : null

  return !isExpired ? (
    <Row
      style={{ alignItems: 'center', justifyContent: metrics.isSmall ? 'flex-start' : 'flex-end', flexWrap: 'wrap' }}
    >
      {!metrics.isSmall && <Loader padding="paddingRight" />}
      {activity?.status === ActivityStatus.Joined && (
        <View style={{ paddingRight: 12, marginBottom: 12 }}>
          <OutlineButton onPress={onSecondaryButtonPress} disabled={loading}>
            {t('leaveAction')}
          </OutlineButton>
        </View>
      )}
      {activity?.status !== ActivityStatus.Completed && (
        <ActionButton
          status={activity?.status ?? null}
          onPress={onPrimaryButtonPress}
          disabled={loading}
          style={{ width: 'auto', paddingRight: 20, paddingLeft: 20, marginBottom: 12 }}
          forceFilled
        />
      )}
      {metrics.isSmall && <Loader padding="paddingLeft" />}
    </Row>
  ) : null
}
