import jwtDecode from 'jwt-decode'

import { State } from 'src/reducers'
import Auth from 'src/entities/auth/model'

export const selectAuth = (state: State): Auth => state.get('entities').auth

export const selectToken = (state: State): string => selectAuth(state).token

export const selectIsAuthenticated = (state: State): boolean => !!selectToken(state)

export const selectIsNew = (state: State): boolean => selectAuth(state).isNew

export const selectDecodedToken = (state: State): Record<string, any> | null => {
  try {
    const token = selectToken(state)

    if (!token) {
      return null
    }

    return jwtDecode(token)
  } catch (error) {
    return null
  }
}
