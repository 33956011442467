import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ErrorText } from 'src/components/ErrorText'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { CommunityUserRole, useCommunitiesQuery } from 'src/generated/graphql'
import { Spacing } from 'src/retired/elements'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import SelectBox from 'src/retired/shared/SelectBox'
import { Text } from 'src/retired/shared/Typography'

interface Props {
  onChange: (communityId: string[]) => void
  value: string[]
}

export const CommunitySelect = ({ onChange, value }: Props) => {
  const { t } = useTranslation('createDeedScreen')
  const user = useSelector(selectCurrentUser)
  const isCommunitiesFeatureEnabled = user?.hasFeature('communitiesSuperLeads')
  const leadCommunityIds =
    user?.communities?.filter((c) => c.role === CommunityUserRole.Lead).map((c) => c.communityId) || []
  const isLead = isCommunitiesFeatureEnabled && leadCommunityIds?.length > 0

  const { data, error, loading } = useCommunitiesQuery({
    skip: !isLead,
    variables: {
      where: {
        id: { in: leadCommunityIds },
      },
    },
  })

  const options = data?.communities?.map((c) => ({ value: c.id, title: c.title }))

  if (!isLead) {
    return null
  }
  if (loading) {
    return <ActivityIndicator size="small" />
  }
  return (
    <Spacing marginTop={15}>
      <Text fontSize={12} marginBottom={8}>{t`organizedByCommunity`}</Text>

      {/* NOTE-DP: We support only one organizing community per event for now */}
      <SelectBox
        onSelect={(v) => onChange([v])}
        onDeselect={(v) => onChange([v])}
        value={value?.[0]}
        placeholder={t`organizedByCommunityHint`}
        options={options}
        style={{ minWidth: 150 }}
      />

      {error && <ErrorText style={{ marginTop: 8 }} text={t`common:anErrorOccurred`} />}
    </Spacing>
  )
}
