import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { View } from 'react-primitives'
import { bindActionCreators, compose } from 'redux'
import { withTheme } from '@emotion/react'

import { injectReducer, injectEpics } from 'src/utils'
import { Label } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { Link, withRouter } from 'src/navigation'
import reducer from 'src/containers/screens/Notifications/reducer'
import epics from 'src/containers/screens/Notifications/epics'
import { initAction } from 'src/containers/screens/Notifications/actions'
import { selectNotificationsCount } from 'src/containers/screens/Notifications/selectors'
import Hoverable from 'src/retired/shared/Hoverable'

class NotificationsIcon extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    notifcationsCount: PropTypes.number,
    active: PropTypes.bool,
    desktop: PropTypes.bool,
    linkStyle: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/notifications') {
      this.props.actions.initAction()
    }
  }

  render() {
    const {
      notifcationsCount,
      active,
      desktop = false,
      linkStyle,
      theme: { colors },
    } = this.props

    return (
      <Hoverable>
        {(isHovered: boolean) => (
          <Link to="/notifications" style={linkStyle}>
            <View
              style={
                desktop
                  ? {
                      position: 'relative',
                      marginVertical: 2,
                      borderWidth: 1,
                      borderColor: isHovered ? colors.grayLight : colors.grayLighter,
                      borderRadius: 20,
                      width: 40,
                      height: 40,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
              }
            >
              <Icon
                icon="notificationNew"
                color={active ? 'blue' : null}
                width={desktop ? 16 : 24}
                height={desktop ? 16 : 24}
              />
              {notifcationsCount > 0 && (
                <View
                  style={{
                    position: 'absolute',
                    bottom: desktop ? -2 : -4,
                    right: desktop ? -6 : -8,
                    backgroundColor: '#E92D2D',
                    borderRadius: 9,
                    minWidth: 17,
                    height: 17,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Label weight="500" colour={colors.white}>
                    {notifcationsCount > 9 ? '9+' : notifcationsCount}
                  </Label>
                </View>
              )}
            </View>
          </Link>
        )}
      </Hoverable>
    )
  }
}

const withConnect = connect(
  (state) => ({
    notifcationsCount: selectNotificationsCount(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ initAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'notifications', reducer })
const withEpics = injectEpics({ key: 'notifications', epics })

export default compose(withRouter, withReducer, withEpics, withConnect, withTheme)(NotificationsIcon)
