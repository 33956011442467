import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'

interface Props<T> {
  options: Array<{
    id: T
    label: string
  }>
  onSelect: (id: T) => void
}

/**
 * A generic MUI dropdown menu
 */
export const DropdownMenu = <T extends string>({ options, onSelect }: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* @NOTE-DP: feel free to make this icon customisable, when the moment comes */}
        <EditIcon color="disabled" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              handleClose()
              onSelect(option.id)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
