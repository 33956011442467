import { AuthAction } from 'src/entities/auth/actions'

import Auth from './model'
import { STORE, UPDATE, CLEAR } from './constants'

const initialState = new Auth()

export default (state: Auth = initialState, action: AuthAction | { type: 'RESET' }): Auth => {
  switch (action.type) {
    case STORE:
      return state.merge({ token: action.token, isNew: action.isNew })

    case UPDATE:
      return state.set('token', action.token)

    case CLEAR:
    case 'RESET':
      return initialState
  }
  return state
}
