import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedDaysOrTime } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedDaysOrTimeSynchronizer = () => {
  const dispatch = useDispatch()
  const { days, times } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  let payload: string[] = []
  if (days) {
    payload = Array.isArray(days) ? days : [days]
  }
  if (times) {
    const timesPayload = Array.isArray(times) ? times : [times]
    payload = [...payload, ...timesPayload]
  }

  useEffect(() => {
    dispatch(setSelectedDaysOrTime(payload))
  }, [days, times])

  return null
}
