import React, { useState } from 'react'
import { View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link, useParams, useHistory } from 'src/navigation'
import { Divider, Image, Loading, Row, Screen, ScrollView } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Text, Body, Body1, Body2, H3 } from 'src/retired/shared/Typography'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import { useDeleteVolunteerTimeOffMutation, useVolunteerTimeOffQuery } from 'src/generated/graphql'
import { convertDuration } from 'src/utils/convertDuration'
import { ConfirmationModal } from 'src/components/ConfirmationModal'
import Header from 'src/retired/shared/Header'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { StyledContent, StyledStatusBar } from '../Profile.styled'

import { ModalError } from './ModalError'

const VolunteerTimeOff = (): JSX.Element => {
  const { t } = useTranslation('volunteerTimeOffProfile')
  const { id } = useParams<{ id: string }>()
  const { metrics, images } = useDeedTheme()
  const history = useHistory()
  const user = useSelector(selectCurrentUser)

  // @NOTE-DP: For now we only support vto editing for Workday
  const vtoEditingEnabled = user?.organization?.mergeApi?.provider === 'Workday'

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const {
    data: vtoData,
    loading: queryVTOLoading,
    error: queryVTOError,
  } = useVolunteerTimeOffQuery({
    variables: { id },
  })

  const [deleteVTOMutation, { loading: deleteVTOLoading }] = useDeleteVolunteerTimeOffMutation({
    variables: { id },
    onCompleted: () => {
      setIsDeleteConfirmationModalOpen(false)
    },
    onError: () => {
      setIsErrorModalOpen(true)
    },
  })

  const volunteerTimeOff = vtoData?.volunteerTimeOff

  const fontSize = metrics.isSmall ? 12 : 16

  return (
    <Screen>
      <ScrollView>
        {volunteerTimeOff ? (
          <>
            <Header>
              <BackArrowButton backTo="/profile/volunteer-time-off-hours" />
              <Text
                fontSize={metrics.isSmall ? 16 : 20}
                lineHeight={metrics.isSmall ? 24 : 32}
                weight="500"
                marginLeft={15}
                numberOfLines={1}
              >
                {volunteerTimeOff.Deed?.name
                  ? t('volunteeringForDeed', {
                      deedName: volunteerTimeOff.Deed?.name,
                    })
                  : t`volunteerTimeOff`}
              </Text>
              {!!volunteerTimeOff.Deed?.id && (
                <Link to={`/deeds/${volunteerTimeOff.Deed.id}`} style={{ marginLeft: 'auto' }}>
                  <Text
                    fontSize={metrics.isSmall ? 10 : 12}
                    weight="500"
                    colour="blue"
                    paddingLeft={metrics.isSmall ? 10 : 30}
                    paddingRight={metrics.isSmall ? 10 : 30}
                  >
                    {t`viewDeed`}
                  </Text>
                </Link>
              )}
            </Header>
            <View style={{ paddingTop: 20, paddingHorizontal: 20, paddingBottom: 40 }}>
              <Row style={{ marginBottom: 16, marginTop: 8 }}>
                <Text fontSize={fontSize} weight="500" marginRight={40}>
                  {t`timeSpent`}
                </Text>
                {volunteerTimeOff.duration ? (
                  <Text fontSize={fontSize}>{convertDuration(volunteerTimeOff.duration)}</Text>
                ) : null}
              </Row>
              <Row>
                <Text fontSize={fontSize} weight="500" marginRight={40}>
                  {t`date`}
                </Text>
                {volunteerTimeOff.duration && volunteerTimeOff.startingAt ? (
                  <Text fontSize={fontSize}>
                    {t('date:dayMonthYearDigits', { date: moment(volunteerTimeOff.startingAt).format('L') })}
                  </Text>
                ) : null}
              </Row>

              {volunteerTimeOff.VolunteerTime && (
                <View style={{ marginTop: 16 }}>
                  <Body1>
                    <Trans
                      t={t}
                      i18nKey="linkToLoggedVolunteerHours"
                      components={{
                        Link: <Link to={`/profile/volunteer-hours/${volunteerTimeOff.VolunteerTime.id}`} />,
                      }}
                    />
                  </Body1>
                </View>
              )}

              {!!volunteerTimeOff.note && (
                <View>
                  <Divider />
                  <Text fontSize={fontSize} weight="500" marginBottom={8}>
                    {t`note`}
                  </Text>
                  <Text fontSize={fontSize} lineHeight={metrics.isSmall ? 24 : 28}>
                    {volunteerTimeOff.note}
                  </Text>
                </View>
              )}

              <StyledStatusBar>
                <Text fontSize={metrics.isSmall ? 10 : 16} weight="500" colour="gray01" center>
                  {t('volunteerTime', { context: volunteerTimeOff.status })}
                </Text>
              </StyledStatusBar>
              {vtoEditingEnabled && (
                <Row style={{ justifyContent: 'center', marginBottom: 16 }}>
                  <Button
                    palette="secondary"
                    style={{ marginRight: 12 }}
                    onPress={() => history.push(`/profile/volunteer-time-off-hours/${volunteerTimeOff.id}/edit`)}
                  >
                    {t`edit`}
                  </Button>
                  <Button
                    palette="secondary"
                    loading={false}
                    onPress={() => {
                      setIsDeleteConfirmationModalOpen(true)
                    }}
                  >
                    {t`delete`}
                  </Button>
                </Row>
              )}
              {!!queryVTOError && (
                <Body colour="redDark" center>
                  {t`thereWasAnErrorDeletingVolunteerTime`}
                </Body>
              )}
            </View>
          </>
        ) : (
          <>
            <View style={{ padding: 20 }}>
              <BackArrowButton backTo="/profile/volunteer-time-off-hours" />
            </View>

            <View style={{ paddingTop: 30, paddingBottom: 50 }}>
              {queryVTOLoading && <Loading />}
              {!!queryVTOError ||
                (!queryVTOLoading && !volunteerTimeOff && (
                  <Body colour="redDark" center>
                    {t`thereWasAnErrorLoadingVolunteerTime`}
                  </Body>
                ))}
            </View>
          </>
        )}
      </ScrollView>
      {isDeleteConfirmationModalOpen && (
        <ConfirmationModal
          visible
          onConfirm={() => {
            void deleteVTOMutation({
              update(cache) {
                history.push('/profile/volunteer-time-off-hours')
                const normalizedId = cache.identify({ id, __typename: 'VolunteerTimeOff' })
                cache.evict({ id: normalizedId })
                cache.gc()
              },
            })
          }}
          onClose={() => setIsDeleteConfirmationModalOpen(false)}
          loading={deleteVTOLoading}
        >
          <StyledContent>
            <View style={{ marginBottom: 8 }}>
              <Image
                source={images.exclamationCircleGray}
                style={{ width: 40, height: 40, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20 }}
              />
              <H3 center>{t`deleteYourVTO`}</H3>
            </View>
            <Body2
              weight="normal"
              colour="grayMediumDark"
              marginTop={8}
              lineHeight={25}
              center
              style={{ maxWidth: 500 }}
            >
              {t`deletingYourVTOHours`}
            </Body2>
          </StyledContent>
        </ConfirmationModal>
      )}
      {isErrorModalOpen && (
        <ModalError visible description={t`anErrorOccuredWhileDeleting`} onClose={() => setIsErrorModalOpen(false)} />
      )}
    </Screen>
  )
}

export default VolunteerTimeOff
