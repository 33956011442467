import React from 'react'
import { View } from 'react-primitives'

import { Action } from 'src/containers/modules/Actions/types'
import { Body1 } from 'src/retired/shared/Typography'
import { Row } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import { Link } from 'src/navigation'
import { styled } from 'src/theme/styled'

export const Title = styled(Body1)`
  font-size: 12px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-right: 20px;
`

export const CampaignTag = ({ campaign }: { campaign: Action['Campaigns'][0] }) => (
  <Row style={{ paddingTop: 8, paddingBottom: 8, alignItems: 'center', flexGrow: 0 }}>
    <Link to={`/campaign/${campaign.id}`} key={campaign.id} style={{ width: '100%' }}>
      <Row style={{ alignItems: 'center' }}>
        <View>
          <Icon icon="megaphone" width={20} height={20} />
        </View>
        <Title numberOfLines={1} underline>
          {campaign.title}
        </Title>
      </Row>
    </Link>
  </Row>
)
