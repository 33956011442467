import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View, StyleSheet } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import { injectReducer, injectEpics } from 'src/utils'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import List from 'src/retired/blocks/List'
import { colors } from 'src/theme'
import { Avatar, Loading, Screen, Spacing, Text } from 'src/retired/elements'
import { Link } from 'src/navigation'
import { selectCurrentUser, selectUserById } from 'src/entities/user/selectors'
import User from 'src/entities/user/model'
import { formatFullName } from 'src/utils/formatFullName'

import { selectLoading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  content: {
    alignSelf: 'stretch',
    paddingBottom: 50,
  },
  friends: {
    alignSelf: 'stretch',
    width: '100%',
  },
  friend: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    marginLeft: 26,
    marginRight: 26,
    paddingTop: 26,
    paddingBottom: 26,
    borderBottomColor: colors.gray03,
    borderBottomWidth: 2,
  },
  friendNameContainer: {
    flex: 1,
    paddingLeft: 15,
    justifyContent: 'center',
  },
  friendRemove: {
    width: 40,
    height: 40,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
})

export class Friends extends PureComponent {
  componentDidMount() {
    const userId = this.props.match.params.id
    this.props.actions.initAction(userId)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextUserId = nextProps.match.params.id
    if (nextUserId && nextUserId !== this.props.match.params.id) {
      this.props.actions.initAction(nextUserId)
    }
  }

  render() {
    const user = this.props.user || (this.props.location.state && new User(this.props.location.state.user))
    const isMyProfile = !this.props.match.params.id
    const { t } = this.props
    if (!user) {
      return <Loading />
    }
    return (
      <Screen fixed center>
        <NavigationHeader
          backTo={isMyProfile ? '/profile' : `/profile/${user.id}`}
          title={isMyProfile ? t`myFriends` : t('usernamesFriends', { userName: user.fullName.first })}
        />
        <View style={styles.content}>
          <List
            style={styles.friends}
            data={user.myFriends}
            keyExtractor={(item) => item.id}
            renderItem={({ item: friend }) => (
              <View style={styles.friend} key={friend.id}>
                <Link
                  to={{
                    pathname: `/profile/${friend.id}`,
                    state: { user: friend },
                  }}
                >
                  <Avatar user={friend} size={50} spacing={0} style={{ marginBottom: 10 }} link={false} />
                </Link>
                <View style={styles.friendNameContainer}>
                  <Link
                    to={{
                      pathname: `/profile/${friend.id}`,
                      state: { user: friend },
                    }}
                  >
                    <Text size={15} lineHeight={24} bold left numberOfLines={1}>
                      {formatFullName(friend.fullName)}
                    </Text>
                  </Link>
                  {typeof friend.relationToMe !== 'undefined' && (
                    <>
                      {friend.relationToMe && friend.relationToMe.deed && (
                        <Link to={`/deeds/${friend.relationToMe.deed.id}`}>
                          <Text size={13} lineHeight={18} color={colors.brandColor} bold left numberOfLines={1}>
                            {friend.relationToMe.deed.name}&nbsp;
                          </Text>
                        </Link>
                      )}
                      <Text size={13} lineHeight={18} light left numberOfLines={1}>
                        {friend.relationToMe
                          ? friend.relationToMe.deed
                            ? t('date:dayMonthYear', {
                                date: friend.relationToMe.deed.startingAt,
                              })
                            : friend.relationToMe.date &&
                              t('friendsSince', {
                                date: friend.relationToMe.date,
                              })
                          : t`viaFacebook`}
                      </Text>
                    </>
                  )}
                </View>
              </View>
            )}
            ListFooterComponent={<Spacing marginBottom={10} />}
          />
        </View>
      </Screen>
    )
  }
}
Friends.propTypes = {
  user: PropTypes.object,
}

const withConnect = connect(
  (state, props) => ({
    loading: selectLoading(state),
    user: props.match.params.id ? selectUserById(state, props.match.params.id) : selectCurrentUser(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'profileFriends', reducer })
const withEpics = injectEpics({ key: 'profileFriends', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('friendsProfile'))(Friends)
