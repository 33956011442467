import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { useDeedTheme } from 'src/theme/ThemeProvider'

import getStyles from './styles'

export const Button = ({
  children,
  onPress,
  style,
  textStyle,
  color,
  size,
  match,
  location,
  fluid = false,
  ...props
}) => {
  if (onPress) {
    // eslint-disable-next-line no-param-reassign
    props.onClick = onPress
  }
  const { colors, metrics } = useDeedTheme()
  const styles = getStyles(colors, metrics)
  const buttonStyle = [
    styles.text,
    color !== 'transparent' && styles.button,
    color === 'transparent' && styles.transparent,
    color === 'primary' && styles.primary,
    color === 'secondary' && styles.secondary,
    color === 'tertiary' && styles.tertiary,
    color === 'light' && styles.light,
    color === 'gray' && styles.gray,
    color === 'light' && styles.alternativeText,
    color === 'gray' && styles.darkText,
    color === 'outlined' && styles.outlined,
    size === 'small' && styles.small,
    size === 'extraSmall' && styles.extraSmall,
    size === 'small' && styles.smallText,
    size === 'extraSmall' && styles.extraSmallText,
    props.disabled && styles.disabled,
    fluid && styles.fluid,
    fluid && (size === 'small' || size === 'extraSmall') && styles.fluidSmall,
    style,
    textStyle,
  ]
  return (
    <button type="button" style={_.assign({}, ...buttonStyle)} {...props}>
      {children}
    </button>
  )
}
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'light', 'gray', 'transparent', 'outlined']),
  size: PropTypes.oneOf(['default', 'small', 'extraSmall']),
  style: PropTypes.any,
  textStyle: PropTypes.object,
  onPress: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  fluid: PropTypes.bool,
}
export default Button
