import { StyleSheet } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { Platform } from 'src/utils'

export const isAndroid = Platform.OS === 'android'

export const ieScrollViewFix = 'isIE' in Platform ? { overflowY: 'visible', overflowX: 'visible' } : {}

export const Section = styled.View`
  background-color: white;
  border-width: 1px;
  border-color: #eaeaea;

  ${(props) =>
    !props.last &&
    `
      margin-bottom: 25px;
      border-bottom-width: 1px;
      border-bottom-color: #eaeaea;
  `}
`

export const SectionTitle = styled.View`
  padding: 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const SectionContent = styled.View`
  padding: 0 25px 15px;
`

export const SectionLink = styled.View`
  padding: 10px 25px;
  border-top-width: 1px;
  border-top-color: #eaeaea;
  align-items: center;
`

export const UserSection = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 25px;
`

export const AvatarContainer = styled.View`
  margin-right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background-color: ${({ colors }) => colors.gray03};
`

export const AvatarImage = styled.View`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  overflow: hidden;
`

export const AvatarEditIcon = styled.View`
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 2;
`

export const ConnectionItem = styled.View`
  width: 70px;
  margin-right: 10px;
  align-items: center;
`

export const Content = styled.View`
  flex: 1;
  flex-basis: auto;
  align-self: stretch;
  background-color: ${({ colors }) => colors.gray03};
`
export const styles = StyleSheet.create({
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginHorizontal: -25,
    marginBottom: -15,
    ...ieScrollViewFix,
  },
  scrollViewContainer: {
    paddingRight: Platform.OS === 'web' ? 10 : 35,
    paddingBottom: 15,
  },
})

export const StyledStatusBar = styled.View<object, EmotionTheme>`
  background-color: ${({ theme }) => theme.colors.gray03};
  border-radius: 2px;
  padding: 15px;
  margin: 30px 0;
`

export const StyledContent = styled.View`
  align-items: center;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
`
export const StyledHeader = styled.View`
  margin-bottom: 8px;
`
