import React, { FC } from 'react'

import { useHistory } from 'src/navigation'
import Touchable from 'src/retired/elements/Touchable'
import Button from 'src/retired/elements/Button'

export interface LinkButtonProps {
  button?: boolean
  replace?: boolean
  to: string
  onPress?: () => void
  Container?: (props: any) => JSX.Element
}

export const LinkButton: FC<LinkButtonProps> = ({
  button = true,
  replace = false,
  to,
  onPress,
  Container,
  children,
  ...props
}) => {
  const history = useHistory()
  const Component = button ? Button : Touchable

  const handleOnPress = (e: any) => {
    onPress?.()
    e.stopPropagation()
    e.preventDefault()
    if (replace) {
      history.replace(to)
    } else {
      history.push(to)
    }
  }

  return Container ? (
    <Container {...props} onPress={handleOnPress}>
      {children}
    </Container>
  ) : (
    <Component {...props} onPress={handleOnPress}>
      {children}
    </Component>
  )
}

export default LinkButton
