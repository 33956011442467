import React from 'react'
import PropTypes from 'prop-types'

const List = React.forwardRef(
  (
    {
      data,
      renderItem,
      keyExtractor,
      ListFooterComponent,
      ListEmptyComponent,
      ItemSeparatorComponent,
      initialNumToRender,
      windowSize,
      showsVerticalScrollIndicator,
      showsHorizontalScrollIndicator,
      style,
      refreshControl,
      onScrollEndDrag,
      loading,
      itemProps,
      numColumns,
      loaderPosition = 'top',
      ...props
    },
    ref
  ) => (
    <>
      {loaderPosition === 'top' && loading && refreshControl ? (
        <div style={{ width: '100%', alignItems: 'center' }}>{refreshControl}</div>
      ) : null}
      <ol
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start',
          flexWrap: 'wrap',
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '100%',
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          listStyle: 'none',
          ...style,
        }}
        {...props}
      >
        {data.map((item, index) => (
          <li key={keyExtractor ? keyExtractor(item) : item.index} {...itemProps}>
            {renderItem({ item, index })}
            {ItemSeparatorComponent && index !== data.length - 1 ? <ItemSeparatorComponent /> : null}
          </li>
        ))}
      </ol>
      {loaderPosition === 'bottom' && loading && refreshControl ? (
        <div style={{ width: '100%', alignItems: 'center' }}>{refreshControl}</div>
      ) : null}
    </>
  )
)

List.propTypes = {
  data: PropTypes.array,
  renderItem: PropTypes.func,
  keyExtractor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ListFooterComponent: PropTypes.element,
  ListEmptyComponent: PropTypes.func,
  ItemSeparatorComponent: PropTypes.elementType,
  style: PropTypes.any,
  refreshControl: PropTypes.element,
  onScrollEndDrag: PropTypes.func,
  loading: PropTypes.bool,
  loaderPosition: PropTypes.string,
  itemProps: PropTypes.object,
  numColumns: PropTypes.number,
}
export default List
