import { Map } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'
import { LocalSettingsSetAction } from 'src/localSettings/actions'
import type { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import Organization from 'src/entities/organization/model'
import { Coordinates } from 'src/utils/coordinates'

import { SET } from './constants'

export interface LocalSettingsMutable {
  currency?: CurrencyCode | null
  username?: string | null
  userId?: string | null
  organization?: string | null
  brand?: Organization | null
  previousLocation?: string | null
  expediteOnboarding?: boolean
  menuModalVisibility?: boolean
  filtersShown?: boolean
  searchLocation?: { location: string | false | null; coordinates: Coordinates | null } | null

  // legacy
  brandColor?: string | null
  deviceToken?: string
}

export type LocalSettingsState = TypedMap<LocalSettingsMutable>

const initialState = Map() as LocalSettingsState

export default (
  state = initialState,
  action:
    | LocalSettingsSetAction
    | { type: 'persist/REHYDRATE'; payload: { localSettings: LocalSettingsState } }
    | { type: 'RESET_LOCAL_SETTINGS' }
): LocalSettingsState => {
  switch (action.type) {
    case SET:
      return state.set(action.key, action.value)

    case 'persist/REHYDRATE':
      return action?.payload?.localSettings || state

    case 'RESET_LOCAL_SETTINGS':
      return initialState

    default:
      return state
  }
}
