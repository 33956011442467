import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { Redirect } from 'src/navigation'
import { storeAction } from 'src/entities/auth/actions'

const DonationRedirect = ({
  location: { hash },
  match: {
    params: { type, id },
  },
  actions,
}) => {
  // If a user clicks on the donate button in the mobile app, TokenLoginRedirect already stores the token
  // in redux and handles the auth. In this case 'hash' is undefined in this component.
  // (which is fine as the user is then already authenticated)
  if (hash) {
    actions.storeAction(hash.substr(1))
  }
  return (
    <Redirect
      to={
        type === 'recurring'
          ? '/profile/donations#recurring'
          : `/${type === 'deed' ? 'deeds' : 'organization'}/${id}/donate`
      }
    />
  )
}

const withConnect = connect(null, (dispatch) => ({
  actions: bindActionCreators({ storeAction }, dispatch),
}))

export default compose(withConnect)(DonationRedirect)
