import { Observable } from 'rxjs'
import i18n from 'i18next'

import FormApi from 'src/entities/form/api'
import DeedsApi from 'src/entities/deed/api'
import VolunteerTimeApi from 'src/entities/volunteerTime/api'
import { updateDeedFormsAction } from 'src/entities/deed/actions'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectFormById } from 'src/entities/form/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import truthy from 'src/utils/truthy'

import { initSuccessAction, initFailedAction, submitSuccessAction, submitFailedAction } from './actions'
import { INIT, INIT_STANDALONE, SUBMIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ deedId, formId, volunteerTimeId, standalone }) => {
    const state = store.getState()
    const deed = selectDeedById(state, deedId)
    const form = selectFormById(state, formId)
    const volunteerTime = selectVolunteerTimeById(state, volunteerTimeId)

    if (!standalone && deed) {
      return Observable.of(initSuccessAction())
    }

    const actions = [
      deedId && !deed && DeedsApi.fetch(deedId),
      formId && !form && FormApi.fetch(formId, volunteerTimeId),
      volunteerTimeId && !volunteerTime && VolunteerTimeApi.getByFilter({ id: { equals: volunteerTimeId } }),
    ].filter(truthy)

    if (actions.length) {
      return Observable.combineLatest(actions)
        .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
        .catch((e) => Observable.of(initFailedAction(e)))
    }
    return Observable.of(initSuccessAction())
  })

const initStandalone = (action$, store) =>
  action$.ofType(INIT_STANDALONE).mergeMap((payload) => {
    const state = store.getState()
    const form = selectFormById(state, payload.formId)

    if (!form && payload.formId) {
      return Observable.combineLatest([FormApi.fetch(payload.formId)])
        .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
        .catch((e) => Observable.of(initFailedAction(e)))
    }

    return Observable.of(initSuccessAction())
  })

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ deed, formId, volunteerTimeId, standalone, data, user, formStatus }) => {
      const payload = { data }
      if (formId && formId !== 'formQuestions') {
        // Global form
        payload.form = formId
      }
      if (deed) {
        payload.deed = deed.id
      }
      if (user) {
        payload.user = user.id
      }
      if (formStatus) {
        payload.status = formStatus
      }
      if (volunteerTimeId) {
        payload.volunteerTimeId = volunteerTimeId
      }

      return FormApi.submit(payload)
        .mergeMap((response) =>
          !standalone && deed
            ? [updateDeedFormsAction(deed, formId, formStatus, data), submitSuccessAction()]
            : [submitSuccessAction(response?.areResponsesValid)]
        )
        .catch((e) => {
          if (e.response) {
            return Observable.from(e.response.text()).mergeMap((responseData) => [
              submitFailedAction(responseData),
              showErrorAction(i18n.t('deedScreen:anErrorOccurredWhileSubmitingTheForm')),
            ])
          }
          return Observable.of(
            submitFailedAction(e),
            showErrorAction(i18n.t('deedScreen:anErrorOccurredWhileSubmitingTheForm'))
          )
        })
    })
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [init, initStandalone, submit]
