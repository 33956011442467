export const GMapCircle = (latitude, longitude, radius, detail = 8) => {
  const r = 6371
  const pi = Math.PI

  const lat = (latitude * pi) / 180
  const lng = (longitude * pi) / 180
  const d = (radius * 0.3048) / 1000 / r

  let i = 0
  let path = ''
  for (i = 0; i <= 360; i += detail) {
    const brng = (i * pi) / 180

    let pLat = Math.asin(Math.sin(lat) * Math.cos(d) + Math.cos(lat) * Math.sin(d) * Math.cos(brng))
    const pLng =
      ((lng + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(lat), Math.cos(d) - Math.sin(lat) * Math.sin(pLat))) *
        180) /
      pi
    pLat = (pLat * 180) / pi

    path += `|${pLat},${pLng}`
  }

  return path
}
