import { Observable } from 'rxjs'
import _ from 'lodash'
import { Store, Action } from 'redux'
import { ActionsObservable } from 'redux-observable'

import { selectLoaded as selectCampaignsLoaded } from 'src/entities/campaign/selectors'
import { selectCauses } from 'src/entities/cause/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import {
  selectFeaturedDeedsLoaded,
  selectSuggestedDeedsLoaded,
  selectUpcomingDeedsLoaded,
} from 'src/entities/deed/selectors'
import CampaignsApi from 'src/entities/campaign/api'
import CausesApi from 'src/entities/cause/api'
import SkillsApi from 'src/entities/skill/api'
import DeedsApi from 'src/entities/deed/api'
import { type State } from 'src/reducers'
import * as Sentry from 'src/utils/Sentry'

import {
  initSuccessAction,
  initFailedAction,
  fetchCampaignsAction,
  fetchSkillsAction,
  fetchCausesAction,
} from './actions'
import {
  INIT,
  FETCH_CAMPAIGNS,
  FETCH_CAUSES,
  FETCH_SKILLS,
  FETCH_UPCOMING_DEEDS,
  FETCH_FEATURED_DEEDS,
  FETCH_SUGGESTED_DEEDS,
} from './constants'

const fetchCampaigns = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_CAMPAIGNS).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const campaignsLoaded = selectCampaignsLoaded(state)
    if (!campaignsLoaded) {
      actions.push(CampaignsApi.fetchAll())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const fetchCauses = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_CAUSES).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)
    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const fetchSkills = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_SKILLS).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const init = (action$: ActionsObservable<Action>) =>
  action$.ofType(INIT).mergeMap(() => {
    const actions = [
      Observable.of(fetchCampaignsAction()),
      Observable.of(fetchSkillsAction()),
      Observable.of(fetchCausesAction()),
    ]
    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const fetchUpcomingDeeds = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_UPCOMING_DEEDS).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const upcomingDeedsLoaded = selectUpcomingDeedsLoaded(state)
    if (!upcomingDeedsLoaded) {
      actions.push(DeedsApi.fetchUpcoming())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((error) => {
        Sentry.captureException(error)
        return []
      })
  })

const fetchFeaturedDeeds = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_FEATURED_DEEDS).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const featuredDeedsLoaded = selectFeaturedDeedsLoaded(state)
    if (!featuredDeedsLoaded) {
      actions.push(DeedsApi.fetchFeaturedDeeds())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((error) => {
        Sentry.captureException(error)
        return []
      })
  })

const fetchSuggestedDeeds = (action$: ActionsObservable<Action>, store: Store<State>) =>
  action$.ofType(FETCH_SUGGESTED_DEEDS).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const suggestedDeedsLoaded = selectSuggestedDeedsLoaded(state)
    if (!suggestedDeedsLoaded) {
      actions.push(DeedsApi.fetchSuggestedDeeds())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)])
      .catch((error) => {
        Sentry.captureException(error)
        return []
      })
  })

export default [
  init,
  fetchCampaigns,
  fetchCauses,
  fetchSkills,
  fetchUpcomingDeeds,
  fetchFeaturedDeeds,
  fetchSuggestedDeeds,
]
