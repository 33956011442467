import { Map } from 'immutable'

import Api from '../api'

import DonationCredit from './model'
import { addMultipleAction } from './actions'

export default class DonationCreditApi {
  static fetchAll() {
    return Api.get('api/walletTransactions/users/me').map((donationCredits) =>
      addMultipleAction(
        Map(
          donationCredits.map((donationCredit: DonationCredit) => [
            donationCredit.id,
            new DonationCredit(donationCredit),
          ])
        )
      )
    )
  }
}
