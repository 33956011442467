import React, { useState } from 'react'
import S3Upload from 'react-s3-uploader/s3upload'
import { View } from 'react-primitives'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import uniqid from 'uniqid'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Api from 'src/entities/api'
import { Divider, Image, Loading, Spacing } from 'src/retired/elements'
import { Text, Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import ImageCropper from 'src/retired/blocks/ImageCropper'
import { Platform } from 'src/utils'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { ErrorText } from 'src/components/ErrorText'

import { setStepAction } from './actions'

const UploadCoverPhoto = ({ formik, userId }) => {
  const { t } = useTranslation('createDeedScreen')
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const { colors } = useDeedTheme()

  const dispatch = useDispatch()

  const showUploadError = (uploadError: string) => dispatch(showErrorAction(uploadError))

  const getSignedUrl = (file: File, callback: any) => {
    Api.post('api/files/credentials', {
      // eslint-disable-next-line camelcase
      file_name: S3Upload.prototype.scrubFilename(
        uniqid() + file.name.slice(file.name.includes('.') ? file.name.indexOf('.') : 0)
      ),
      // eslint-disable-next-line camelcase
      file_type: file.type,
      acl: 'private',
      bucket: 'primary',
      objectPrefix: userId,
    }).subscribe(
      // eslint-disable-next-line node/no-callback-literal
      (response) => callback({ signedUrl: response.signedRequest, publicUrl: response.url }),
      (e) => console.error(e) // eslint-disable-line no-console
    )
  }

  const uploadBlob = (file) => {
    const processedFile = file
    processedFile.name = S3Upload.prototype.scrubFilename(`${uniqid()}${file.type === 'image/png' ? '.png' : '.jpg'}`)
    const options = {
      files: [file],
      getSignedUrl,
      contentDisposition: 'auto',
      uploadRequestHeaders: { 'x-amz-acl': 'private' },
      onFinishS3Put: (info) => {
        setUploading(false)
        setError(false)
        formik.setFieldValue('picture', info.publicUrl)
      },
      onProgress: () => setUploading(true),
      onError: () => setError(true),
    }
    new S3Upload(options) // eslint-disable-line no-new
  }

  return (
    <>
      <Divider />
      {uploading ? (
        <Spacing marginBottom={30} marginTop={30}>
          <Loading fill={false} />
        </Spacing>
      ) : formik.values.picture ? (
        <View style={{ borderWidth: 1, borderStyle: 'dashed', borderColor: colors.gray05, padding: 10 }}>
          <Image source={{ uri: formik.values.picture }} resizeMode="contain" style={{ height: 390, width: '100%' }} />
          <Text
            fontSize={12}
            colour={colors.gray05}
            onPress={() => formik.setFieldValue('picture', '')}
            center
            style={{ marginTop: 8, textDecoration: 'underline' }}
          >
            {t`pressHereToReplaceThisPhoto`}
          </Text>
        </View>
      ) : (
        <ImageCropper
          onCrop={uploadBlob}
          onError={showUploadError}
          uploading={uploading}
          customCopy={
            <Trans
              t={t}
              i18nKey={Platform.select({ web: 'dndYourPhotoOrPressHere', default: 'clickToSelectAPicture' })}
              components={{ u: <Body2 style={{ textDecoration: 'underline' }}>\1</Body2> }}
            />
          }
          customWidth="100%"
          customHeight={410}
          customRatio={1.5}
          disableResize
          freeStyleCropEnabled
          cropperCircleOverlay={false}
          useFrontCamera={false}
        />
      )}
      {error && (
        <Spacing marginTop={30}>
          <ErrorText text={t`errorUploadingAFile`} />
        </Spacing>
      )}
      <Spacing marginTop={30}>
        <Button palette="primary" disabled={!formik.values.picture} onPress={() => dispatch(setStepAction(3))}>
          {t`next`}
        </Button>
      </Spacing>
    </>
  )
}

UploadCoverPhoto.displayName = 'UploadCoverPhoto'
export default UploadCoverPhoto
