/* eslint-disable array-callback-return */

import { OrderedMap, OrderedSet } from 'immutable'
import { stringify } from 'query-string'

import { loadFeedForDeedTypeSuccessAction } from 'src/containers/screens/Feed/actions'
import { completeDeedAction } from 'src/entities/user/actions'
import { Coordinates } from 'src/utils/coordinates'
import truthy from 'src/utils/truthy'

import Api from '../api'
import { addAction as addCampaignAction } from '../campaign/actions'
import Campaign from '../campaign/model'

import {
  addAction,
  addMultipleAction,
  updateAction,
  removeAction,
  campaignLoadedAction,
  upcomingDeedsLoadedAction,
  pastDeedsLoadedAction,
  userSubmittedDeedsLoadedAction,
  forCausesLoadedAction,
  communityDeedsLoadedAction,
  addUserToDeedCheckInsAction,
  featuredDeedsLoadedAction,
  suggestedDeedsLoadedAction,
  setEventSearchResultsDeedIds,
  setProjectSearchResultsDeedIds,
  setFundraiserSearchResultsDeedIds,
} from './actions'
import Deed from './model'

export interface DeedFetchQueryType {
  type?: 'Project' | 'Event' | 'Campaign' | 'BaseEvent' | 'Feed'
  limit?: number
  page?: number
  featured?: boolean
  categories?: string[]
  pillars?: string[]
  sdgs?: string[]
  ergs?: string[]
  location?: string | boolean
  coordinates?: Coordinates
  radius?: number
  longitude?: number
  latitude?: number
  isLoadMore?: boolean
}

const LIMITED_DEED_QUERY_RESULT_SIZE = 20

export default class DeedsApi {
  static fetchFeed(inputQuery?: DeedFetchQueryType, deedAction = addMultipleAction) {
    const query = inputQuery
    // @NOTE-CH: if not specified, let's always limit the number of returned deeds
    if (query && !query.limit) {
      query.limit = LIMITED_DEED_QUERY_RESULT_SIZE
    }

    if (query && query.location && ['false', false].includes(query?.location)) {
      delete query.coordinates
    }

    if (query && query.coordinates?.length === 2) {
      // @NOTE-CH: We always expect to receive [Latitude,Longitude] pair. The `addressToLocation` give us as [Longitude,Latitude], but we flip it when filtering on SRP
      // eslint-disable-next-line prefer-destructuring
      query.latitude = query.coordinates[0]
      // eslint-disable-next-line prefer-destructuring
      query.longitude = query.coordinates[1]
    }
    // @NOTE: always delete query.coordinates as it's not used in the server-side
    delete query?.coordinates

    if (query && 'radius' in query && typeof query.radius !== 'number') {
      delete query.radius
    }

    if (query && query.longitude && query.longitude && query.location) {
      delete query.location
    }

    const isLoadMore = query?.isLoadMore ?? false
    if (query && query.isLoadMore) {
      // always delete query.isLoadMore as it's not used on the api server
      delete query.isLoadMore
    }

    return Api.get(`api/deeds/feed?${query ? stringify(query) : ''}`).map((response) => {
      const deeds = Array.isArray(response) ? response : response.results

      const causesList = query?.categories || query?.ergs || query?.pillars || query?.sdgs
      return [
        deedAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
        // @NOTE-CH: Checking if page and limit were sent, this is the only guarantee the api will return the expected pagination fields below
        query?.type &&
          query?.page &&
          query?.limit &&
          loadFeedForDeedTypeSuccessAction(query.type, {
            moreResults: Boolean(response.moreResults || false),
            totalItems: Number(response?.totalItems || 0),
            page: Number(response?.page || 1),
          }),
        query?.type === 'Event' &&
          setEventSearchResultsDeedIds(OrderedSet(deeds.map((deed: any) => deed.id)), isLoadMore),
        query?.type === 'Project' &&
          setProjectSearchResultsDeedIds(OrderedSet(deeds.map((deed: any) => deed.id)), isLoadMore),
        query?.type === 'Campaign' &&
          setFundraiserSearchResultsDeedIds(OrderedSet(deeds.map((deed: any) => deed.id)), isLoadMore),
        causesList && forCausesLoadedAction(causesList),
      ].filter(truthy)
    })
  }

  static searchFeed(searchTerm?: string) {
    return Api.get(`api/deeds/feed?${stringify({ search: searchTerm })}`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
    ])
  }

  static fetchCommunityDeeds(communityId: string) {
    return Api.get(`api/deeds/feed?${stringify({ community: communityId })}`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
      communityDeedsLoadedAction(communityId),
    ])
  }

  static fetchFeaturedDeeds() {
    // Be careful: If you add &scope=Limited the structure of the response changes
    return Api.get(`api/deeds/feed?featured=true&limit=15`).map((deeds) => {
      const deedResults = deeds?.length ? deeds : []
      return [
        addMultipleAction(OrderedMap(deedResults.map((deed: any) => [deed.id, new Deed(deed)]))),
        featuredDeedsLoadedAction(),
      ]
    })
  }

  static fetchSuggestedDeeds() {
    // Be careful: If you add &scope=Limited the structure of the response changes
    return Api.get(`api/deeds/feed?suggested=true&limit=15`).map((deeds) => {
      const deedResults = deeds?.length ? deeds : []
      return [
        addMultipleAction(OrderedMap(deedResults.map((deed: any) => [deed.id, new Deed(deed)]))),
        suggestedDeedsLoadedAction(),
      ]
    })
  }

  static fetchUpcoming(userId = 'me') {
    return Api.get(`api/deeds/${userId}/upcoming`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
      upcomingDeedsLoadedAction(userId),
    ])
  }

  static fetchPast(userId = 'me') {
    return Api.get(`api/deeds/${userId}/past`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
      pastDeedsLoadedAction(userId),
    ])
  }

  static fetch(id: string) {
    return Api.get(`api/deeds/${id}`).map((deed) => addAction(new Deed(deed)))
  }

  static fetchInfo(id: string) {
    return Api.get(`api/deeds/${id}`)
  }

  static fetchExternalLinkFollows(id: string) {
    return Api.get(`api/deeds/${id}/externalLinkFollows`)
  }

  static searchExternalDeeds(searchTerm: string) {
    return Api.get(`api/deeds/searchExternalDeeds/?searchTerm=${searchTerm}`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
    ])
  }

  static join(id: string, roleIds?: string[]) {
    const properties = ['attendees', 'waitlist']
    if (roleIds?.length) {
      properties.push('roles')
    }

    return Api.post('api/deeds/join', { id, roleIds }).map((deed) => updateAction(new Deed(deed), properties))
  }

  static unjoin(id: string, roleIds?: string[]) {
    return Api.post('api/deeds/unjoin', { id, roleIds }).map((deed) =>
      updateAction(new Deed(deed), ['attendees', 'waitlist', 'formQuestions', 'forms', 'roles'])
    )
  }

  static checkIn(deedId: string, userId: string) {
    return Api.patch(`api/deeds/${deedId}/checkIn`).map((response) => {
      if (response.success === true) {
        return addUserToDeedCheckInsAction(userId, deedId)
      }

      throw new Error('Error during check In')
    })
  }

  static invite(id: string, users: string[], emails: string[]) {
    return Api.post(`api/deeds/${id}/invite`, { users, emails }).map((deed) =>
      updateAction(new Deed(deed), ['invites'])
    )
  }

  static acceptInvite(id: string, token: string, roleIds: string[]) {
    return Api.post(`api/deeds/${id}/invite/${token}`, { roleIds }).map((deed) =>
      updateAction(new Deed(deed), ['attendees', 'roles', 'invites'])
    )
  }

  static declineInvite(id: string, token: string) {
    return Api.delete(`api/deeds/${id}/invite${token ? `/${token}` : ''}`).map((deed) =>
      updateAction(new Deed(deed), ['invites'])
    )
  }

  static applyForRole(id: string, roleId: string) {
    return Api.post(`api/deeds/${id}/roles/${roleId}`).map((deed) => updateAction(new Deed(deed)))
  }

  static complete(id: string) {
    return Api.post(`api/deeds/${id}/complete`).map(() => completeDeedAction(id))
  }

  static fetchCampaign(id: string) {
    return Api.get(`api/deeds/campaign/${id}`).map((data) => [
      addCampaignAction(new Campaign(data.campaign)),
      addMultipleAction(OrderedMap(data.deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
      campaignLoadedAction(id),
    ])
  }

  static fetchCampaignInfo(id: string) {
    return Api.get(`api/campaigns/${id}/info`)
  }

  static fetchCampaignStats(id: string) {
    return Api.get(`api/campaigns/${id}/stats`)
  }

  static fetchCampaignNonprofits(id: string) {
    return Api.get(`api/campaigns/${id}/nonprofits`)
  }

  static fetchCampaignDonations(id: string, limit?: number) {
    return Api.get(
      `api/campaigns/${id}/donations?${stringify({
        limit,
      })}`
    )
  }

  static create(data: object) {
    return Api.post('api/deeds', data).map((deed) => addAction(new Deed(deed)))
  }

  static update(id: string, data: object) {
    return Api.patch(`api/deeds/${id}`, data).map((deed) => updateAction(new Deed(deed)))
  }

  static delete(id: string) {
    return Api.delete(`api/deeds/${id}`).map(() => removeAction(id))
  }

  static fetchSubmittedByUser(userId = 'me') {
    return Api.get(`api/deeds/${userId}/submitted`).map((deeds) => [
      addMultipleAction(OrderedMap(deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
      userSubmittedDeedsLoadedAction(),
    ])
  }
}
