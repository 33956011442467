import React from 'react'

import { CorporateOnlySynchronizer } from './CorporateOnlySynchronizer'
import { SelectedLocationsSynchronizer } from './SelectedLocationsSynchronizer'
import { SelectedDateSynchronizer } from './SelectedDateSynchronizer'
import { SelectedDaysOrTimeSynchronizer } from './SelectedDaysOrTimeSynchronizer'
import { SelectedSkillsSynchronizer } from './SelectedSkillsSynchronizer'
import { SelectedCausesSynchronizer } from './SelectedCausesSynchronizer'
import { SearchTermSynchronizer } from './SearchTermSynchronizer'
import { SelectedLocationCodeSynchronizer } from './SelectedLocationCodeSynchronizer'

export const VolunteerFiltersSynchronizers = () => (
  <>
    <CorporateOnlySynchronizer />
    <SelectedLocationsSynchronizer />
    <SelectedDateSynchronizer />
    <SelectedDaysOrTimeSynchronizer />
    <SelectedSkillsSynchronizer />
    <SelectedLocationCodeSynchronizer />
    <SelectedCausesSynchronizer />
    <SearchTermSynchronizer />
  </>
)
