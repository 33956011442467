import React from 'react'
import { View } from 'react-primitives'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { colors } from 'src/theme'
import { H5 } from 'src/retired/shared/Typography'
import Image from 'src/retired/elements/Image'
import { Link } from 'src/navigation'
import Icon from 'src/retired/shared/Icon'

import { IconData } from '../DeedCard/helper'

interface AbstractCardProps {
  size: 'standard' | 'small' | 'variable'
  image: string
  link?: string
  title?: string
  topLeft?: React.ReactNode
  topRight?: React.ReactNode
  bottomLeft?: React.ReactNode
  bottomRight?: React.ReactNode
  children?: React.ReactNode
  titleNumberOfLines?: number
  wrapperProps?: any
  fallbackIconData?: IconData
}
export type AbstractCardElement = React.ReactElement<AbstractCardProps>

const AbstractCard: React.FC<AbstractCardProps> = ({
  size = 'standard',
  link,
  title,
  titleNumberOfLines = 1,
  image,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  children,
  wrapperProps,
  fallbackIconData,
}): AbstractCardElement => {
  const { metrics } = useDeedTheme()
  const maxWidth = size === 'variable' ? 'auto' : size === 'small' ? '300px' : '368px'
  const imageAspectRatio = 327 / 150
  const Wrapper = link ? Link : View

  const showFallbackIcon = !image && !!fallbackIconData?.iconName
  return (
    <Wrapper {...wrapperProps} to={link}>
      <View
        style={[
          css`
            width: ${maxWidth};
            margin-left: ${metrics.isSmall ? '0' : '8'}px;
            margin-right: ${metrics.isSmall ? '0' : '8'}px;
            margin-bottom: ${metrics.isLarge ? '40' : metrics.isMedium ? '20' : '14'}px;
          `,
        ]}
      >
        <View
          style={[
            css`
              width: ${maxWidth};
              background-color: white;
              border-radius: 12px;
              shadow-offset: 0 1px;
              shadow-opacity: 0.1;
              shadow-radius: 15px;
              shadow-color: ${colors.black};
              elevation: 1;
            `,
          ]}
        >
          <View
            aspectRatio={imageAspectRatio}
            style={[
              css`
                overflow: hidden;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                width: 100%;
                padding-bottom: ${`${(1 / imageAspectRatio) * 100}%`};
              `,
            ]}
          >
            {image ? (
              <Image
                source={{ uri: image }}
                backgroundPosition="center"
                backgroundSize="cover"
                style={[
                  css`
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    max-width: 100%;
                    max-height: 100%;
                  `,
                ]}
              />
            ) : showFallbackIcon ? (
              <View
                style={[
                  css`
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background-color: ${fallbackIconData.hexCode};
                  `,
                ]}
              >
                <Icon width={60} icon={fallbackIconData.iconName} />
              </View>
            ) : null}
            <View
              style={[
                css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  ${!showFallbackIcon &&
                  `background-color: ${colors.black};
                    opacity: 0.55;`}
                `,
              ]}
            />
            {topLeft && (
              <View
                style={[
                  css`
                    position: absolute;
                    top: 16px;
                    left: 24px;
                  `,
                ]}
              >
                {topLeft}
              </View>
            )}
            {topRight && (
              <View
                style={[
                  css`
                    position: absolute;
                    top: 16px;
                    right: 18px;
                  `,
                ]}
              >
                {topRight}
              </View>
            )}
            {bottomLeft && (
              <View
                style={[
                  css`
                    position: absolute;
                    bottom: 16px;
                    left: 24px;
                  `,
                ]}
              >
                {bottomLeft}
              </View>
            )}
            {bottomRight && (
              <View
                style={[
                  css`
                    position: absolute;
                    bottom: 16px;
                    right: 18px;
                  `,
                ]}
              >
                {bottomRight}
              </View>
            )}
          </View>
          <View
            style={[
              css`
                margin: 16px 24px;
              `,
            ]}
          >
            {title ? (
              <View style={{ paddingBottom: titleNumberOfLines * 4, height: titleNumberOfLines * 30 }}>
                <H5
                  weight="500"
                  numberOfLines={titleNumberOfLines}
                  lineHeight={26}
                  marginTop={2}
                  marginBottom={-2}
                  style={{}}
                >
                  {title}
                </H5>
              </View>
            ) : null}
            {children}
          </View>
        </View>
      </View>
    </Wrapper>
  )
}
export default AbstractCard
