import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import { H5, Label } from 'src/retired/shared/Typography'
import {
  Action,
  ActivityIndicator,
  Button,
  ScrollView,
  Screen,
  Text,
  Loading,
  Spacing,
  Touchable,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { metrics, colors } from 'src/theme'
import { withRouter } from 'src/navigation'

import { selectSubmitting, selectError, selectSelectedSkills } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export class RegisterSkills extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    const { t, user, skills, selectedSkills, submitting, location, actions } = this.props

    if (!user || !skills) {
      return <Loading />
    }

    const path = location.pathname

    return (
      <Screen fixed>
        <ScrollView>
          {path === '/profile/skills' ? <NavigationHeader transparent backTo="/profile" /> : <Spacing marginTop={45} />}
          <Screen header narrow={metrics.screenWidth < 1024} middle={metrics.screenWidth > 640}>
            <Spacing marginHorizontal={25} marginTop={20} marginBottom={48}>
              <H5>{t`whatAreYourSuperpowers`}</H5>
            </Spacing>
            <View
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                marginHorizontal: 15,
              }}
            >
              {skills &&
                skills.toArray().map((skill) => {
                  const selected = selectedSkills.has(skill.id)
                  return (
                    <Touchable
                      key={skill.id}
                      onPress={() => actions.toggleSelectedSkillAction(skill.id)}
                      style={{
                        marginRight: 12,
                        marginBottom: 12,
                        borderRadius: 8,
                        paddingHorizontal: 16,
                        paddingTop: 8,
                        paddingBottom: 8,
                        backgroundColor: selected ? colors.blueLight : colors.white,
                        borderColor: selected ? 'transparent' : colors.primaryBlue,
                        borderWidth: 1,
                      }}
                    >
                      <Label weight="500" colour="primaryBlue">
                        {skill.name.replace('-', '')}
                      </Label>
                    </Touchable>
                  )
                })}
            </View>
            <Spacing paddingBottom={user && !user.get('skills') ? 130 : 100} />
          </Screen>
        </ScrollView>
        <Action>
          <Button
            onPress={() => actions.submitAction(selectedSkills.toJS(), path)}
            color={user && !user.get('skills') && selectedSkills.size === 0 ? 'light' : 'primary'}
          >
            {submitting ? <ActivityIndicator color="#fff" /> : t`confirm`}
          </Button>
          {user && !user.get('skills') && (
            <View
              style={{
                marginTop: 20,
                backgroundColor: '#fff',
                borderRadius: 20,
                boxShadow: '0px 0px 10px 15px #fff',
              }}
            >
              <Button onPress={() => actions.submitAction([], path)} color="transparent">
                <Text size={15} color={colors.grayMediumDark}>
                  {t`skip`}
                </Text>
              </Button>
            </View>
          )}
        </Action>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    selectedSkills: selectSelectedSkills(state),
    skills: selectSkills(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerSkills', reducer })
const withEpics = injectEpics({ key: 'registerSkills', epics })

export default compose(
  withReducer,
  withEpics,
  withConnect,
  withRouter,
  withTranslation('skillsRegister')
)(RegisterSkills)
