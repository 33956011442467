// SEE: https://developer.paypal.com/docs/api/reference/locale-codes/
const payPalSupportedLocaleCodes = [
  'en_US',
  'ar_EG',
  'fr_XC',
  'es_XC',
  'zh_XC',
  'en_AU',
  'de_DE',
  'nl_NL',
  'fr_FR',
  'pt_BR',
  'fr_CA',
  'zh_CN',
  'cs_CZ',
  'da_DK',
  'ru_RU',
  'fi_FI',
  'el_GR',
  'en_GB',
  'zh_HK',
  'hu_HU',
  'en_IN',
  'id_ID',
  'he_IL',
  'it_IT',
  'ja_JP',
  'no_NO',
  'pl_PL',
  'pt_PT',
  'sk_SK',
  'ko_KR',
  'es_ES',
  'sv_SE',
  'zh_TW',
  'th_TH',
]
export default payPalSupportedLocaleCodes
