import React from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Communications, Platform } from 'src/utils'
import { ActivityIndicator, Alert, Touchable, Text, ScrollView, Spacing } from 'src/retired/elements'
import { colors } from 'src/theme'

import { NonprofitsList } from './NonprofitsList'

const NonprofitsContainer = ({
  loading,
  nonprofits,
  actions,
  isEmployee,
  searching,
  searchFailed,
  onScroll,
  onScrollEndDrag,
  listRef,
  style,
  corporateOnly,
}) => {
  const { metrics } = useDeedTheme()
  const { t } = useTranslation('nonprofitsScreen')

  const listProps = { onScroll, onScrollEndDrag }

  const numberOfColumns = metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1

  if (Platform.OS !== 'web') {
    const cardImageAspectRatio = 327 / 150
    const cardImageHeight = Math.floor((metrics.screenWidth - 20 * (numberOfColumns + 1)) / cardImageAspectRatio)
    const cardHeight = cardImageHeight + 124 + 12
    listProps.getItemLayout = (data, index) => ({ length: cardHeight, offset: cardHeight * index, index })
    listProps.listKey = 'nonprofits'
  }

  const supportEmail = 'support@joindeed.com'

  return (
    <View
      style={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        ...style,
      }}
    >
      {searching && (
        <View style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
          <ActivityIndicator color={colors.pink} size="large" />
        </View>
      )}
      {searchFailed && (
        <View
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <Text bold size={18} color={colors.redDark}>
            {t`thereWasAnErrorPerformingTheSearch`}
          </Text>
        </View>
      )}
      {loading || searching || searchFailed || nonprofits.size > 0 ? (
        <NonprofitsList
          nonprofits={nonprofits}
          onScroll={onScroll}
          onScrollEndDrag={onScrollEndDrag}
          listRef={listRef}
          loading={loading}
          actions={actions}
          corporateOnly={corporateOnly}
        />
      ) : (
        <ScrollView style={{ flex: 1, padding: 25 }}>
          <Spacing marginTop={isEmployee && metrics.isSmall ? 35 : 10} />
          <Text size={22} lineHeight={26} bold style={{ marginBottom: 25 }}>
            {t`looksLikeThereAreNoMatchingOrganizations`}
          </Text>
          <Text size={22} lineHeight={26}>
            <Trans
              t={t}
              i18nKey="ifYouKnowOfAGreatOrganization"
              values={{
                supportEmail,
              }}
              components={{
                TouchableEmailLink: (
                  <Touchable
                    onPress={() => {
                      Alert.alert(t('contactSupport', { supportEmail }), '', [
                        { text: t`common:Cancel`, style: 'cancel' },
                        {
                          text: t`common:Yes`,
                          onPress: () => Communications.email([supportEmail], '', '', t`supportEmailSubjectLine`, ''),
                        },
                      ])
                    }}
                  ></Touchable>
                ),
                SupportEmail: <Text size={22} lineHeight={26} underline />,
              }}
            />
          </Text>

          <Text size={22} lineHeight={26} style={{ marginTop: 25 }}>
            {t`thankYou`}
          </Text>
          <Spacing marginBottom={Platform.OS === 'web' ? 25 : 50} />
        </ScrollView>
      )}
    </View>
  )
}
export default NonprofitsContainer
