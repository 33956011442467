import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { DonationProvider } from '@joindeed/calculate-fees'

import { getLanguageCode } from 'src/utils/languageUtils'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import { ActivityIndicator, Spacing, Button } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import DonationsApi from 'src/entities/donation/api'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import type { FrontendFees } from 'src/containers/screens/Donate/calculateFeesWithHistory'

import { DonationFrequency, BASE_SPACING } from '../constants'
import * as actions from '../actions'
import { selectDataClientToken, selectDataClientTokenLoading, selectDataClientTokenError } from '../selectors'
import { type AnyProviderData } from '../payment/useProviderData'

import { Donor } from './DonorDetailsForm'
import PayPalMessage from './PayPalMessage'

import { StripeForm, NFGForm, GlobalGivingForm, PayPalForm, PayPalRecurringForm } from '.'

interface PaymentFormProps {
  providerData: AnyProviderData
  donor?: Donor
  donorDetailsRequired?: boolean
  donating: boolean
  total: number
  amountSelected: number
  amountValid: boolean
  deed: Deed
  user: User
  nonprofitId: string
  nonprofitCountryCode: string
  campaignId?: string
  checkedCoverFee: boolean
  baseCurrency: CurrencyCode
  privacy: string
  donorPrivacy: DonorPrivacy
  designation: string
  dedication: string
  cardCountryCode: string
  setCardCountryCode: (v: string) => void
  setGiveIndiaCommited: (v: boolean) => void
  donationFrequency: DonationFrequency
  feesHistoryItem: FrontendFees
  desiredMatchAmount: number | undefined
  desiredMatchingRule?: string
  giftAidApplies?: boolean
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  providerData,
  donor,
  donorDetailsRequired,
  donating,
  total,
  amountSelected,
  amountValid,
  deed,
  user,
  nonprofitId,
  nonprofitCountryCode,
  campaignId,
  checkedCoverFee,
  baseCurrency,
  privacy,
  donorPrivacy,
  designation,
  dedication,
  donationFrequency,
  cardCountryCode,
  setCardCountryCode,
  setGiveIndiaCommited,
  feesHistoryItem,
  desiredMatchAmount,
  desiredMatchingRule,
  giftAidApplies,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('donateScreen')
  const { colors, metrics } = useDeedTheme()

  // ONLY FOR STRIPE
  const { i18n } = useTranslation()
  const languageCode = getLanguageCode(i18n.language)
  const stripeLocale = languageCode || 'auto'

  // ONLY FOR PAYPAL
  const dataClientToken = useSelector(selectDataClientToken)
  const dataClientTokenLoading = useSelector(selectDataClientTokenLoading)
  const dataClientTokenError = useSelector(selectDataClientTokenError)
  useEffect(() => {
    if (donationFrequency && donationFrequency !== 'Onetime' && !dataClientToken && !dataClientTokenLoading) {
      dispatch(actions.createCustomerIdAction())
    }
  }, [dataClientToken, dataClientTokenLoading, dispatch, donationFrequency])

  // ONLY FOR GIVE INDIA
  const invalidState = !amountValid || (donorDetailsRequired && !(donor?.firstName && donor?.lastName && donor?.email))

  return (
    <>
      {(providerData.donationProvider === DonationProvider.PayPal ||
        providerData.donationProvider === DonationProvider.CAF) &&
        !metrics.isSmall && (
          <Body1 colour={colors.grayDark} marginTop={BASE_SPACING * 0.75}>
            <PayPalMessage providerData={providerData} />
          </Body1>
        )}

      <Spacing marginBottom={BASE_SPACING} />

      {providerData.donationProvider === DonationProvider.Stripe && (
        <Elements stripe={providerData.stripePromise} options={{ locale: stripeLocale }}>
          <StripeForm
            user={user}
            donor={donor}
            donating={donating}
            amountValid={amountValid}
            donorPrivacy={donorPrivacy}
            createIntent={async ({ billingAddress }) =>
              DonationsApi.createStripePaymentIntent({
                donationProvider: DonationProvider.Stripe,
                [deed ? 'deed' : 'nonprofit']: deed?.id || nonprofitId,
                amount: amountSelected,
                desiredMatchAmount,
                desiredMatchingRule,
                coverFee: checkedCoverFee,
                currency: baseCurrency,
                cardCountryCode,
                privacy,
                donorPrivacy,
                designation,
                dedication,
                donor,
                campaign: campaignId,
                feeCovered: user?.feeCovered,
                billingAddress,
                feesHistoryItem,
              }).toPromise()
            }
            registerDonation={(intentId: string) => {
              dispatch(actions.registerStripeDonationAction(intentId))
            }}
            setCardCountryCode={setCardCountryCode}
          />
        </Elements>
      )}

      {providerData.donationProvider === DonationProvider.NFG && (
        <NFGForm
          submitting={donating}
          total={total}
          amountValid={amountValid}
          user={user}
          donor={donor}
          onSubmit={(data) => {
            dispatch(
              actions.donateAction({
                donationProvider: DonationProvider.NFG,
                amount: amountSelected,
                desiredMatchAmount,
                desiredMatchingRule,
                coverFee: checkedCoverFee,
                [deed ? 'deed' : 'nonprofit']: deed?.id || nonprofitId,
                creditCardData: data.creditCard,
                billingAddress: data.donor?.billingAddress,
                privacy,
                donorPrivacy,
                designation,
                dedication,
                donor,
                campaign: campaignId,
                feeCovered: user?.feeCovered,
              })
            )
          }}
        />
      )}

      {providerData.donationProvider === DonationProvider.GlobalGiving && (
        <GlobalGivingForm
          submitting={donating}
          total={total}
          user={user}
          donor={donor}
          amountValid={amountValid}
          onToken={(token) => {
            dispatch(
              actions.donateAction({
                donationProvider: DonationProvider.GlobalGiving,
                amount: amountSelected,
                desiredMatchAmount,
                desiredMatchingRule,
                coverFee: checkedCoverFee,
                currency: baseCurrency,
                [deed ? 'deed' : 'nonprofit']: deed?.id || nonprofitId,
                token,
                privacy,
                donorPrivacy,
                designation,
                dedication,
                donor,
                campaign: campaignId,
                feeCovered: user?.feeCovered,
              })
            )
          }}
        />
      )}

      {providerData.donationProvider === DonationProvider.GiveIndia && (
        <Button
          color="primary"
          size="small"
          style={{ width: 300 }}
          disabled={invalidState}
          onClick={() => {
            setGiveIndiaCommited(true)
          }}
        >
          {t`continue`}
        </Button>
      )}

      {(providerData.donationProvider === DonationProvider.PayPal ||
        providerData.donationProvider === DonationProvider.CAF) && (
        <>
          {donationFrequency === 'Onetime' && (
            <PayPalForm
              providerData={providerData}
              disabled={!user && !donor}
              donor={donor}
              submitting={donating}
              amount={amountSelected}
              amountValid={amountValid}
              deed={deed || undefined}
              nonprofitId={deed ? undefined : nonprofitId}
              currencyCode={baseCurrency}
              coverFee={checkedCoverFee}
              privacy={privacy}
              donorPrivacy={donorPrivacy}
              dedication={dedication}
              designation={designation}
              campaignId={campaignId}
              feeCovered={user?.feeCovered}
              feesHistoryItem={feesHistoryItem}
              desiredMatchAmount={desiredMatchAmount}
              desiredMatchingRule={desiredMatchingRule}
              nonprofitCountryCode={nonprofitCountryCode}
              giftAidApplies={giftAidApplies}
            />
          )}

          {donationFrequency !== 'Onetime' && (
            <>
              {dataClientTokenLoading && <ActivityIndicator />}

              {dataClientTokenError && (
                <Body2 colour={colors.redDark}>{t`anErrorOccurredWhileLoadingDonationForm`}</Body2>
              )}

              {!dataClientTokenLoading && dataClientToken && (
                <PayPalRecurringForm
                  providerData={providerData}
                  disabled={!user && !donor}
                  submitting={donating}
                  amount={amountSelected}
                  amountValid={amountValid}
                  nonprofitId={deed ? undefined : nonprofitId}
                  nonprofitCountryCode={nonprofitCountryCode}
                  currencyCode={baseCurrency}
                  coverFee={checkedCoverFee}
                  privacy={privacy}
                  donorPrivacy={donorPrivacy}
                  dedication={dedication}
                  donationFrequency={donationFrequency}
                  designation={designation}
                  feeCovered={user?.feeCovered}
                  feesHistoryItem={feesHistoryItem}
                  desiredMatchAmount={desiredMatchAmount}
                  desiredMatchingRule={desiredMatchingRule}
                  giftAidApplies={giftAidApplies}
                />
              )}
            </>
          )}
        </>
      )}

      {providerData.donationProvider === null && (
        <Body2 colour={colors.redDark}>{t`unfortunatelyDonationIsNotPossibleFromYourRegion`}</Body2>
      )}
    </>
  )
}

export default PaymentForm
