import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedCausesAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedCausesSynchronizer = () => {
  const dispatch = useDispatch()
  const { currentActiveCauses } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    if (!currentActiveCauses?.size) {
      dispatch(setSelectedCausesAction([]))
      return
    }

    const causeIds = currentActiveCauses.toArray().map((cause) => cause.id)

    dispatch(setSelectedCausesAction(causeIds))
  }, [currentActiveCauses])

  return null
}
