import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation, useHistory } from 'src/navigation'
import { Spacing, LinkButton, DashedContainer, Text } from 'src/retired/elements'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { colors } from 'src/theme'

import RecurringDonation from '../elements/RecurringDonation'

export const RecurringDonations = ({ donations, isLoading }) => {
  const { t } = useTranslation('donationsProfile')
  const history = useHistory()
  const { hash } = useLocation()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    hash !== '#recurring' && history.replace('/profile/donations#recurring')
  }, [hash, history])

  return donations.size > 0 ? (
    <>
      {donations.toArray().map((donation) => (
        <RecurringDonation key={donation.id} donation={donation} />
      ))}
      {isLoading ? <ActivityIndicator /> : null}
    </>
  ) : !isLoading ? (
    <DashedContainer>
      <Spacing marginBottom={20} style={{ width: '100%' }}>
        <Text size={12} medium color={colors.grayMediumDark}>
          {t`youHaveNoRecurringDonations`}
        </Text>
      </Spacing>
      <LinkButton
        to="/donate"
        color="transparent"
        style={{
          borderRadius: 100,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.grayMediumDark,
          alignSelf: 'flex-start',
          paddingLeft: 15,
          paddingRight: 15,
          height: 21,
          justifyContent: 'center',
        }}
      >
        <Text size={12} lineHeight={21} light left color={colors.grayMediumDark}>
          {t`browse`}
        </Text>
      </LinkButton>
    </DashedContainer>
  ) : (
    <ActivityIndicator />
  )
}

export default RecurringDonations
