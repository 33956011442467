import React from 'react'
import { Dialog } from '@mui/material'

import { ContentWrapper } from './ContentWrapper'

export const ModalWithCloseButton = ({
  children,
  onClose,
  visible,
  title,
  style,
}: {
  children: JSX.Element
  onClose: () => void
  visible: boolean
  title?: string | JSX.Element
  style?: React.CSSProperties
}): JSX.Element => (
  <Dialog
    open={visible}
    PaperProps={{
      square: true,
      style: {
        borderRadius: 4,
        maxWidth: '95%',
        ...style,
      },
    }}
  >
    <ContentWrapper onClose={onClose} title={title}>
      {children}
    </ContentWrapper>
  </Dialog>
)
