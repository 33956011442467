import React from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import { Row, Spacing } from 'src/retired/elements'
import Tooltip from 'src/retired/elements/Tooltip'
import { currencyFormat, CurrencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import { Platform } from 'src/utils'
import { Body2, Label } from 'src/retired/shared/Typography'
import CapBalance from 'src/retired/blocks/CapBalance'
import CapLimitPerUser from 'src/retired/blocks/CapLimitPerUser'
import User from 'src/entities/user/model'

interface DonationsSummaryProps {
  user: User
}

const DonationsSummary = ({ user }: DonationsSummaryProps): JSX.Element => {
  const { t } = useTranslation('profileScreen')
  const { colors } = useDeedTheme()

  const displayCurrency = useCurrency()
  const userBudgets = user._capBalanceByBudget
  const currencyConversion = !userBudgets.every(({ currencyCode }) => currencyCode === displayCurrency.code)
  const showDonationCreditTooltip =
    user.hasFeature('donationCredit') &&
    user.donationCreditsWallet &&
    user.donationCreditsWallet.currencyCode !== displayCurrency?.code

  const currencyContext = userBudgets.some((budget) => Number.isNaN(budget.limitPerUser) || budget.balance === null)
    ? 'noLimit'
    : currencyConversion
    ? 'approximate'
    : 'exact'

  const i18nKeyMap = {
    noLimit: 'yourDonationMayBeMatched_noLimit',
    approximate: 'yourDonationMayBeMatched_approximate',
    exact: 'yourDonationMayBeMatched_exact',
  }
  const i18nKey = i18nKeyMap[currencyContext]

  return (
    <View
      style={{
        borderRadius: 3,
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderColor: colors.borderGray,
        marginBottom: 15,
      }}
    >
      {user.hasFeature('donationCredit') && (
        <Row
          style={{
            alignItems: 'center',
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderColor: colors.borderGray,
            [showDonationCreditTooltip ? 'paddingHorizontal' : 'padding']: 12,
            paddingRight: showDonationCreditTooltip ? 7 : 12,
          }}
        >
          <View
            style={{
              flexGrow: 1,
              marginRight: 8,
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: showDonationCreditTooltip ? 12 : 0,
            }}
          >
            <Body2>
              {t`creditBalance`}
              {showDonationCreditTooltip ? (
                <View style={{ marginLeft: 5 }}>
                  <Tooltip title={t`creditBalance`}>
                    <Body2 colour={colors.black} center>
                      {t('creditBalanceToolTip', {
                        creditBalance: currencyFormat(
                          user.donationCreditsWallet.balance,
                          user.donationCreditsWallet.currencyCode
                        ),
                        displayCurrency: displayCurrency?.code,
                        walletCurrency: user.donationCreditsWallet.currencyCode,
                        currencyRate: displayCurrency.rates?.[user.donationCreditsWallet.currencyCode],
                      })}
                    </Body2>
                  </Tooltip>
                </View>
              ) : null}
            </Body2>
          </View>
          <View
            style={{
              paddingVertical: showDonationCreditTooltip ? 12 : 0,
            }}
          >
            <Link to="/profile/credits">
              <Body2 weight="500" colour={colors.brandColor}>
                <CurrencyFormat
                  amount={user.donationCreditsWallet?.balance ?? 0}
                  baseCurrency={user.donationCreditsWallet?.currencyCode ?? displayCurrency?.code}
                />
              </Body2>
            </Link>
          </View>
        </Row>
      )}
      <Row
        style={{
          borderStyle: 'solid',
          borderBottomWidth: 1,
          borderColor: colors.borderGray,
          padding: 12,
        }}
      >
        <View style={{ flexGrow: 1, marginRight: 8 }}>
          <Body2>{t`yourDonations`}</Body2>
        </View>
        <View>
          <Link to="/profile/donations">
            <Body2 weight="500" colour={colors.brandColor}>
              <CurrencyFormat amountCurrencies={user.donationsAmountCurrencies} />
            </Body2>
          </Link>
        </View>
      </Row>
      {user.hasFeature('donationMatching') && (
        <Row
          style={{
            alignItems: 'center',
            paddingHorizontal: 12,
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderColor: colors.borderGray,
          }}
        >
          <View style={{ marginRight: 8, paddingVertical: 12 }}>
            <Body2>{t`totalMatched`} </Body2>

            {userBudgets.length > 0 && (
              <Row>
                <Label weight="500" colour={colors.grayMediumDark}>
                  <Trans
                    t={t}
                    i18nKey="remainingBalance"
                    values={{
                      baseCurrency: displayCurrency?.code,
                    }}
                    components={{
                      CapBalance: <CapBalance capBalanceByBudget={userBudgets} unlimitedMessaging={t`noLimitIn`} />,
                    }}
                  />
                </Label>
                <View style={{ marginLeft: 5 }}>
                  <Tooltip title={t`matchingProgram`}>
                    <Spacing paddingLeft={10} paddingRight={10}>
                      <Body2 colour={colors.grayMediumDark} center marginTop={Platform.OS !== 'web' ? 15 : 0}>
                        <Trans
                          t={t}
                          i18nKey={i18nKey}
                          components={{
                            CapBalancePerUser: <CapLimitPerUser capBalanceByBudget={userBudgets} />,
                            CapBalance: <CapBalance capBalanceByBudget={userBudgets} />,
                          }}
                          tOptions={{ context: currencyContext }}
                        />
                      </Body2>
                    </Spacing>
                  </Tooltip>
                </View>
              </Row>
            )}
          </View>
          <View style={{ flexGrow: 1, paddingVertical: 12 }}>
            <Body2 weight="500" right>
              <CurrencyFormat amountCurrencies={user._matchedAmountTotalCurrencies} />
            </Body2>
          </View>
        </Row>
      )}
    </View>
  )
}
export default DonationsSummary
