import type { InitOptions } from 'i18next'
import { fallbackLng } from '@joindeed/i18n/src/fallbackLng'

import resources from './resources'
import { interpolationFormat } from './interpolationFormat'

const detection = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18nextLng',
  excludeCacheFor: ['cimode'],
}

export const config: InitOptions = {
  compatibilityJSON: 'v3',
  cleanCode: true,
  resources,
  fallbackLng,
  load: 'currentOnly',
  detection,
  interpolation: {
    escapeValue: false,
    format: interpolationFormat,
  },
}
