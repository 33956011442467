import { useEffect, useState } from 'react'

import config from 'src/config'

export const loadGoogleMapsApi = (callback: () => void): void => {
  const scriptExists = document.getElementById('googleMapsApi')

  if (!scriptExists) {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=places`
    script.id = 'googleMapsApi'
    script.type = 'text/javascript'
    script.onload = () => callback()
    document.body.appendChild(script)
  } else {
    callback()
  }
}
export default loadGoogleMapsApi

export const useGoogleMapsApi = () => {
  const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false)

  useEffect(() => {
    loadGoogleMapsApi(() => {
      setGoogleMapsApiLoaded(true)
    })
  }, [])

  if (!googleMapsApiLoaded) {
    return null
  }

  return googleMapsApiLoaded && window.google?.maps
}
