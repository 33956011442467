import React from 'react'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectBrand } from 'src/localSettings/selectors'
import Organization from 'src/entities/organization/model'
import { Screen, Image, Button, LinkButton } from 'src/retired/elements'
import SingleSignOnButton from 'src/retired/shared/SingleSignOnButton'
import IconButton from 'src/retired/shared/IconButton/index'
import { images } from 'src/theme'
import { LargeUp } from 'src/retired/elements/MediaQuery'
import { H3, H4, H5 } from 'src/retired/shared/Typography'
import { Platform } from 'src/utils'

const heroImagesArray = [
  images.guestCheckoutImage01,
  images.guestCheckoutImage02,
  images.guestCheckoutImage04,
  images.guestCheckoutImage05,
  images.guestCheckoutImage06,
  images.guestCheckoutImage07,
]
const heroImage = heroImagesArray[Math.floor(Math.random() * heroImagesArray.length)]

interface LoginButtonBlockProps {
  partner: Organization
  handleModalDismissed: () => void
  secondaryAction: 'CreateNew' | 'AsGuest'
  onRedirect?: () => void
  isGuestCheckout?: boolean
  createAccountLink: string
}

const LoginButtonBlock = ({
  partner,
  handleModalDismissed,
  secondaryAction,
  onRedirect,
  isGuestCheckout,
  createAccountLink,
}: LoginButtonBlockProps): JSX.Element => {
  const { t } = useTranslation('guestCheckoutScreen')
  const { colors, metrics } = useDeedTheme()
  const brand = useSelector(selectBrand)

  const fontSize = metrics.isSmall ? 14 : 16
  const primaryButtonStyle = {
    backgroundColor: isGuestCheckout ? colors.black : partner?.brandColor || brand?.brandColor || colors.purple,
    boxShadow: 'unset',
    fontSize,
  }

  const secondaryButtonStyle = {
    backgroundColor: colors.white,
    color: colors.black,
    boxShadow: 'unset',
    border: `1px solid ${colors.mediumGray}`,
    fontSize,
  }

  return (
    <View>
      <H5 center colour={colors.grayDark} marginBottom={16} weight="500">
        {t(isGuestCheckout ? 'notAPartnerEmployee' : 'areYouAnEmployee', {
          partnerName: partner?.name,
          context: partner?.name ? 'isPartnerTrue' : 'isPartnerFalse',
        })}
      </H5>

      <View>
        {isGuestCheckout ? (
          <LinkButton to="/login" style={primaryButtonStyle} onPress={onRedirect}>
            {t`common:logIn`}
          </LinkButton>
        ) : (
          <SingleSignOnButton partner={partner} onRedirect={onRedirect} style={primaryButtonStyle} />
        )}
        <View style={{ marginTop: 20 }}>
          {
            {
              CreateNew: (
                <LinkButton to={createAccountLink} style={secondaryButtonStyle} onPress={onRedirect}>
                  {t`deedScreen:createAnAccount`}
                </LinkButton>
              ),
              AsGuest: (
                <Button onPress={handleModalDismissed} style={secondaryButtonStyle}>
                  {t`continueAsGuest`}
                </Button>
              ),
            }[secondaryAction]
          }
        </View>
      </View>
    </View>
  )
}

interface GuestCheckoutProps {
  handleModalDismissed: () => void
  partner: Organization
  secondaryAction: 'CreateNew' | 'AsGuest'
  onRedirect?: () => void
}

const GuestCheckout = ({
  handleModalDismissed,
  partner,
  secondaryAction,
  onRedirect,
}: GuestCheckoutProps): JSX.Element => {
  const { t } = useTranslation('guestCheckoutScreen')
  const { metrics, colors } = useDeedTheme()
  const brand = useSelector(selectBrand)

  const ssoAuthEnabled = !!brand?.providers?.find((provider: string) => ['saml', 'openidconnect'].includes(provider))
  const localAuthEnabled = !!brand?.providers?.includes('local')
  const isVanityDomainContext = Boolean(Platform.OS === 'web' && brand?.appVanityDomain?.match(window?.location.host))
  const isGuestCheckoutEnabled = isVanityDomainContext ? brand?.settings?.enableGuestUsers : true

  const Title = metrics.isLarge ? H3 : H4

  return (
    <Screen
      fixed
      center
      middle
      safeArea={false}
      style={{
        position: 'fixed',
        zIndex: 100,
        width: '100vw',
        background: 'rgba(0, 0, 0, 0.4)',
      }}
    >
      <View
        style={{
          backgroundColor: colors.gray03,
          borderRadius: 20,
          flexDirection: 'row',
          width: '95vw',
          padding: 20,
          maxWidth: metrics.isLarge ? 1200 : 500,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: metrics.isLarge ? 20 : 0,
            marginRight: metrics.isLarge ? 20 : 0,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 40,
              alignItems: 'center',
            }}
          >
            <IconButton
              icon="arrowLeftNew"
              size={metrics.isLarge ? 48 : 40}
              onPress={handleModalDismissed}
              style={{ marginRight: 20 }}
            />
            <Title weight="500">
              {t`letsDoSomethingGoodToday`}{' '}
              <Image style={{ width: 25, height: 25 }} source={{ uri: images.smileyBlush }}></Image>
            </Title>
          </View>

          <View style={{ alignItems: 'center' }}>
            <LoginButtonBlock
              secondaryAction={secondaryAction}
              partner={partner}
              handleModalDismissed={handleModalDismissed}
              onRedirect={onRedirect}
              createAccountLink={ssoAuthEnabled && !localAuthEnabled ? '/' : '/register'}
            />
            {isGuestCheckoutEnabled ? (
              <>
                <View
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    width: '100%',
                    height: 1,
                    backgroundColor: colors.mediumGray,
                  }}
                />
                <LoginButtonBlock
                  secondaryAction={secondaryAction}
                  partner={partner}
                  handleModalDismissed={handleModalDismissed}
                  onRedirect={onRedirect}
                  isGuestCheckout
                  createAccountLink="/register/individual"
                />
              </>
            ) : null}

            <Image style={{ width: 68, height: 70, marginTop: 40 }} source={{ uri: images.logoStackedBlack }} />
          </View>
        </View>

        <LargeUp>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image style={{ width: '100%', height: '100%', borderRadius: 20 }} source={{ uri: heroImage }}></Image>
          </View>
        </LargeUp>
      </View>
    </Screen>
  )
}

export default GuestCheckout
