import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedLocationsAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'

import { useSearchParams } from '../../utils'

export const SelectedLocationsSynchronizer = () => {
  const dispatch = useDispatch()
  const { attendanceOptions } = useSearchParams()

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    if (!attendanceOptions) {
      dispatch(setSelectedLocationsAction([]))
      return
    }

    dispatch(setSelectedLocationsAction(Array.isArray(attendanceOptions) ? attendanceOptions : [attendanceOptions]))
  }, [attendanceOptions])

  return null
}
