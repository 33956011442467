import { Observable } from 'rxjs'

import SettingApi from 'src/entities/setting/api'

import { INIT } from './constants'
import { initSuccessAction, initFailedAction } from './actions'

const init = (action$) =>
  action$
    .ofType(INIT)
    .mergeMap(() => SettingApi.fetch().mergeMap((action) => [action, initSuccessAction()]))
    .catch((e) => Observable.of(initFailedAction(e)))

export default [init]
