export const getHours = (duration: number): number => Math.floor(duration / 60)

export const getMinutes = (duration: number): number => duration - getHours(duration) * 60

export const convertDuration = (duration: number, full = false): string => {
  const hours = getHours(duration)
  const minutes = getMinutes(duration)
  return full
    ? `${hours ? `${hours} Hour${hours > 1 ? 's' : ''}` : ''} ${minutes ? `${minutes} Minutes` : ''}`
    : `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`
}

export const getDuration = (hours: string, minutes: string): number => Number(hours) * 60 + Number(minutes)
