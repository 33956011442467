import { createSelector } from 'reselect'

import { initialState, initialFormState } from './reducer'

const selectState = (state) => state.get('deedForms') || initialState

export const selectFormById = createSelector(
  selectState,
  (state, formId) => formId,
  (form, formId) => form.get(formId) || initialFormState
)

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectSubmitSuccess = createSelector(selectState, (state) => state.get('submitSuccess'))

export const selectAreResponsesValid = createSelector(selectState, (state) => state.get('areResponsesValid'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
