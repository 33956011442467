import { View } from 'react-primitives'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ActivityIndicator, LinkButton, Loading, Row, Spacing, Touchable } from 'src/retired/elements'
import { Link } from 'src/navigation'
import { convertDuration } from 'src/utils/convertDuration'
import Deed from 'src/entities/deed/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { H5, Body2, Body1 } from 'src/retired/shared/Typography'
import { selectVolunteerTimeByDeed } from 'src/entities/volunteerTime/selectors'

import { selectCompleting, selectVolunteerTimesError, selectVolunteerTimesLoading } from '../selectors'
import { completeDeedAction } from '../actions'

import { Box } from './MapBlock'

export const VolunteerTimeBlock = ({ deed }: { deed: Deed }): JSX.Element => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()

  const volunteerTimesLoading = useSelector(selectVolunteerTimesLoading)

  const user = useSelector(selectCurrentUser)
  const isComplete = user?.hasCompletedDeed?.(deed.id)

  const volunteerTimes = useSelector((state) => selectVolunteerTimeByDeed(state, deed.id))
  const volunteerTimesError = useSelector(selectVolunteerTimesError)
  const completing = useSelector(selectCompleting)

  // Only users who've logged at least 1 VT entry can complete the deed
  const canComplete = deed.type === 'Project' && !isComplete && !deed.ended && volunteerTimes?.size > 0
  const dispatch = useDispatch()
  return (
    <Box>
      <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 14, flexWrap: 'wrap' }}>
        <H5 weight="500">{t`yourHours`}</H5>
        {!isComplete && (
          <LinkButton
            fluid
            size="small"
            to={`/profile/volunteer-hours/add/${deed.id}/deed`}
            style={{ backgroundColor: colors.gold }}
          >
            {t`logHours`}
          </LinkButton>
        )}
      </Row>

      {volunteerTimesLoading && (
        <Spacing marginBottom={40} marginTop={40}>
          <Loading fill={false} />
        </Spacing>
      )}

      {volunteerTimesError && (
        <View style={{ borderColor: colors.gray02, borderRadius: 3, borderWidth: 1, padding: 14, marginBottom: 18 }}>
          <Body2 colour={colors.gray01} center>
            {t`thereWasAnErrorLoading`}
          </Body2>
        </View>
      )}

      {volunteerTimes.size > 0 ? (
        <View>
          {volunteerTimes.toArray().map((volunteerTime) => (
            <Link
              key={volunteerTime.id}
              to={`/profile/volunteer-hours/${volunteerTime.id}`}
              style={{ borderColor: colors.gray02, borderRadius: 3, borderWidth: 1, padding: 14, marginBottom: 18 }}
            >
              <Row style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 8 }}>
                <Body1 weight="500">{convertDuration(volunteerTime.duration, true)}</Body1>
                <Body2>{t('date:dayMonthYearDigits', { date: volunteerTime.date })}</Body2>
              </Row>
              {volunteerTime.milestone && <Body2 weight="500">{volunteerTime.milestone.name}</Body2>}
              <Body2>{volunteerTime.notes}</Body2>
            </Link>
          ))}

          {canComplete ? (
            completing ? (
              <View style={{ marginBottom: 15 }}>
                <ActivityIndicator color={colors.pink} size="small" />
              </View>
            ) : (
              <Touchable onPress={() => dispatch(completeDeedAction(deed.id))}>
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <View
                    style={{
                      borderBottomColor: colors.borderGray,
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      paddingBottom: 4,
                      marginBottom: 15,
                      flexGrow: 0,
                      flexShrink: 1,
                    }}
                  >
                    <Body2 center colour={colors.lightGray}>
                      {t`markThisDeedComplete`}
                    </Body2>
                  </View>
                </View>
              </Touchable>
            )
          ) : null}

          {isComplete && (
            <View
              style={{
                marginBottom: 15,
                flexGrow: 0,
                flexShrink: 1,
              }}
            >
              <Body2 center colour={colors.lightGray}>
                {t`yourDeedIsComplete`}
              </Body2>
            </View>
          )}
        </View>
      ) : (
        !volunteerTimesLoading &&
        !volunteerTimesError && (
          <View style={{ borderColor: colors.gray02, borderRadius: 3, borderWidth: 1, padding: 14, marginBottom: 18 }}>
            <Body2 colour={colors.gray01} center>
              {t`onceYouveCompleted`}
            </Body2>
          </View>
        )
      )}
    </Box>
  )
}
