import React from 'react'
import { List } from 'immutable'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { formatAmount } from 'src/utils/format'
import { Text } from 'src/retired/shared/Typography'
import { Row } from 'src/retired/elements'
import DonationCredit from 'src/entities/donationCredit/model'
import { currencies } from 'src/containers/modules/CurrencyFormat'

const TableRow = styled.View<{ last?: boolean }, EmotionTheme>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0;
  border-color: ${({ theme }) => theme.colors.gray02};
  border-bottom-width: 1px;

  ${({ last }) =>
    last &&
    `
    border-bottom-width: 0;
  `}
`

const LongColumn = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0px 10px' : '0px 25px')};
  flex: auto;
`

const ShortColumn = styled.View<object, EmotionTheme>`
  width: ${({ theme }) => (theme.metrics.isSmall ? '110px' : '150px')};
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0px 10px' : '0px 25px')};
`

export interface Props {
  donationCredits: List<DonationCredit>
}

export const DonationCreditsTableBody = ({ donationCredits }: Props): JSX.Element => {
  const { t } = useTranslation('donationCreditsProfile')
  const { metrics } = useDeedTheme()

  return (
    <>
      <TableRow style={{ opacity: 0.5 }}>
        <LongColumn>
          <Text fontSize={12}>{t`note`}</Text>
        </LongColumn>
        <Row style={{ flexShrink: 0, flexGrow: 0 }}>
          <ShortColumn>
            <Text fontSize={12} center>
              {t`credit`}
            </Text>
          </ShortColumn>
          <ShortColumn>
            <Text fontSize={12} center>
              {t`expiration`}
            </Text>
          </ShortColumn>
        </Row>
      </TableRow>
      {donationCredits.toArray().map((donationCredit: DonationCredit, index) => (
        <TableRow key={donationCredit.id} last={donationCredits.size === index + 1}>
          <LongColumn>
            <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
              <Text
                fontSize={metrics.isSmall ? 12 : 14}
                weight="500"
                style={{ width: metrics.isSmall ? '100%' : 'auto' }}
              >
                {donationCredit.deed?.name || donationCredit.note}
              </Text>
            </Row>
          </LongColumn>
          <Row style={{ flexShrink: 0, flexGrow: 0 }}>
            <ShortColumn>
              <Text fontSize={12} center weight="500">
                {currencies[donationCredit.currencyCode].symbol}
                {formatAmount(donationCredit.creditAmount, currencies[donationCredit.currencyCode].zeroDecimal)}
              </Text>
            </ShortColumn>
            <ShortColumn>
              <Text fontSize={12} center weight="500">
                {donationCredit.expiryDate ? t('date:dayMonthYearDigits', { date: donationCredit.expiryDate }) : '-'}
              </Text>
            </ShortColumn>
          </Row>
        </TableRow>
      ))}
    </>
  )
}
