import _ from 'lodash'
import { Observable } from 'rxjs'
import { goBack } from 'connected-react-router'
import type { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'
import { ApolloError } from '@apollo/client'

import DonationScheduleApi from 'src/entities/donationSchedule/api'
import { selectDonationScheduleById } from 'src/entities/donationSchedule/selectors'
import { showMessageAction } from 'src/containers/modules/Alerts/actions'
import type { State } from 'src/reducers'

import {
  INIT,
  DELETE,
  UPDATE,
  initSuccessAction,
  initFailedAction,
  deleteSuccessAction,
  deleteFailedAction,
  updateSuccessAction,
  updateFailedAction,
  InitAction,
  DeleteAction,
  UpdateAction,
  UpdateErrorPlain,
  ValidationErrorObject,
  ValidationErrors,
  DeleteErrorPlain,
} from './types'

/* NOTE-ZR:
    This unpacks the Error message we get from the graph service.
    Each field validation error message is a stingified object.
    All fields are then stringified again as the error message.
    
    (All of this because due to translation reasons.
      We need to send complex validation errors that are not just plain text from the graphService.
      We can't use the built-in Error metadata because our GraphQL mutation wrappers used for testing throw error.message instead of error.
      Which makes the backend validation untestable)
*/
const parseValidarionErrorsMessage = (errorMessage: string): ValidationErrors => {
  const partiallyParsedValidationErrors = JSON.parse(errorMessage) as Record<keyof ValidationErrors, string[]>

  const validationErrors = Object.entries(partiallyParsedValidationErrors).reduce<ValidationErrors>(
    (acc, [field, errorStrings]) => {
      const errorObjects = errorStrings.map((errosString) => JSON.parse(errosString) as ValidationErrorObject)

      if (field in partiallyParsedValidationErrors) {
        acc[field as keyof typeof partiallyParsedValidationErrors] = errorObjects
      }
      return acc
    },
    {}
  )
  return validationErrors
}

const makeError = (error: Error): UpdateErrorPlain | DeleteErrorPlain => {
  const isMutationError = error instanceof ApolloError && error?.graphQLErrors[0]?.extensions?.code === 'MUTATION_ERROR'
  if (isMutationError) {
    return { mutationError: error }
  }

  const isValidationError =
    error instanceof ApolloError && error?.graphQLErrors[0]?.extensions?.code === 'VALIDATION_ERRORS'
  if (isValidationError) {
    return { validationErrors: parseValidarionErrorsMessage(error.message) }
  }

  return { unknownError: error }
}

const init = (action$: ActionsObservable<InitAction>, store: Store<State>) =>
  action$.ofType(INIT).exhaustMap(({ id }) => {
    const state = store.getState()
    const actions = []

    const donationSchedule = selectDonationScheduleById(state, id)
    if (!donationSchedule) {
      actions.push(DonationScheduleApi.getByFilter({ id: { equals: id } }))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e: Error) => Observable.of(initFailedAction(e)))
  })

const del = (action$: ActionsObservable<DeleteAction>) =>
  action$.ofType(DELETE).exhaustMap(({ id }) =>
    DonationScheduleApi.cancel(id)
      .mergeMap((resultingAction) => [
        resultingAction,
        goBack(),
        showMessageAction(i18n.t('donationScheduleDetailsScreen:canceled')),
        deleteSuccessAction(),
      ])
      .catch((error: Error) => [deleteFailedAction(makeError(error))])
  )

const update = (action$: ActionsObservable<UpdateAction>) =>
  action$.ofType(UPDATE).exhaustMap(({ id, data }) =>
    DonationScheduleApi.update(id, data)
      .mergeMap((resultingAction) => [resultingAction, updateSuccessAction()])
      .catch((error: Error) => [updateFailedAction(makeError(error))])
  )

export default [init, del, update]
