import {
  ADD,
  ADD_MULTIPLE,
  ADD_PLEDGE,
  REMOVE,
  CLEAR,
  DONATIONS_FOR_USER_LOADED,
  DONATIONS_FOR_DEED_LOADED,
  LOAD_DONATIONS_FOR_CAMPAIGN,
  DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS,
  DONATIONS_FOR_CAMPAIGN_LOADING_FAILED,
} from './constants'
import Donation from './model'
import { DonationMap } from './reducer'

export const addAction = (donation: Donation) => ({
  type: ADD,
  donation,
})

export const addPlegdeAction = ({ donationScheduleId }: { donationScheduleId: string }) => ({
  type: ADD_PLEDGE,
  donation: { donationScheduleId },
})

export const addMultipleAction = (donations: DonationMap) => ({
  type: ADD_MULTIPLE,
  donations,
})

export const removeAction = (id: string) => ({
  type: REMOVE,
  id,
})

export const clearAction = () => ({
  type: CLEAR,
})

export const donationsForUserLoadedAction = (userId: string, value = true) => ({
  type: DONATIONS_FOR_USER_LOADED,
  userId,
  value,
})

export const donationsForDeedLoadedAction = (deedId: string) => ({
  type: DONATIONS_FOR_DEED_LOADED,
  deedId,
})

export interface LoadDonationsForCampaign {
  type: typeof LOAD_DONATIONS_FOR_CAMPAIGN
  campaignId: string
  limit?: number
}

export const loadDonationsForCampaign = (campaignId: string, limit?: number): LoadDonationsForCampaign => ({
  type: LOAD_DONATIONS_FOR_CAMPAIGN,
  campaignId,
  limit,
})

export const donationsForCampaignLoadingSuccessAction = (campaignId: string) => ({
  type: DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS,
  campaignId,
})

export const donationsForCampaignLoadingFailedAction = (campaignId: string) => ({
  type: DONATIONS_FOR_CAMPAIGN_LOADING_FAILED,
  campaignId,
})
