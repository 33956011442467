import {
  INIT,
  TOGGLE_SELECTED_CAUSE,
  SET_SELECTED_CAUSES,
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  RESET,
  TOGGLE_CORPORATE_ONLY,
} from './constants'

export const initAction = (corporateOnly) => ({
  type: INIT,
  corporateOnly,
})

export const toggleSelectedCauseAction = (cause) => ({
  type: TOGGLE_SELECTED_CAUSE,
  cause,
})

export const setSelectedCausesAction = (causes) => ({
  type: SET_SELECTED_CAUSES,
  causes,
})

export const searchAction = (searchTerm) => ({
  type: SEARCH,
  searchTerm,
})

export const searchSuccessAction = () => ({
  type: SEARCH_SUCCESS,
})

export const searchFailedAction = (error) => ({
  type: SEARCH_FAILED,
  error,
})

export const resetAction = () => ({
  type: RESET,
})

export const toggleCorporateOnlyAction = (state) => ({
  type: TOGGLE_CORPORATE_ONLY,
  state,
})
