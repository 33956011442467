import { INIT, INIT_SUCCESS, INIT_FAILED, FOLLOW, FOLLOW_SUCCESS, FOLLOW_FAILED } from './constants'

export const initAction = (id) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const followAction = (id, unfollow = false) => ({
  type: FOLLOW,
  id,
  unfollow,
})
export const followSuccessAction = () => ({
  type: FOLLOW_SUCCESS,
})
export const followFailedAction = (error, errorCode) => ({
  type: FOLLOW_FAILED,
  error,
  errorCode,
})
