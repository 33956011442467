import { fromJS, Set } from 'immutable'

import { ADD as ADD_USER } from 'src/entities/user/constants'

import {
  INIT,
  INIT_SUCCESS,
  SUBMIT,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  TOGGLE_SELECTED_INTEREST,
  TOGGLE_EXPAND_SDGS,
} from './constants'

export const initialState = fromJS({
  submitting: false,
  error: null,
  selectedInterests: Set(),
  expandSDGs: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ error: null })

    case ADD_USER:
    case INIT_SUCCESS:
      if (!action.user || !action.user.me) {
        return state
      }
      return state.set('selectedInterests', new Set(action.user.interests))

    case SUBMIT:
      return state.merge({ submitting: true, error: null })

    case SUBMIT_SUCCESS:
      return initialState

    case SUBMIT_FAILED:
      return state.merge({ submitting: false, error: action.error })

    case TOGGLE_SELECTED_INTEREST: {
      const selectedInterests = state.get('selectedInterests')
      return state.set(
        'selectedInterests',
        selectedInterests[selectedInterests.has(action.interest) ? 'delete' : 'add'](action.interest)
      )
    }

    case TOGGLE_EXPAND_SDGS: {
      return state.set('expandSDGs', action.expanded)
    }

    default:
      return state
  }
}
