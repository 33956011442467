// Rounds number to two decimal places
const roundNumber = (num: number, truncateRemainder = false): number => {
  let roundedNumber = Math.round((num + Number.EPSILON) * 100)

  if (truncateRemainder) {
    roundedNumber = Math.trunc(roundedNumber)
  }

  return roundedNumber / 100
}

export default roundNumber
