import { createSelector } from 'reselect'

const selectState = (state) => state.get('registerInterests')

export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectSelectedInterests = createSelector(selectState, (state) => state.get('selectedInterests'))

export const selectExpandSDGs = createSelector(selectState, (state) => state.get('expandSDGs'))
