import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'

import { Label, Text } from 'src/retired/shared/Typography'
import { CurrencyFormat, CurrencyCode, CurrencyRates } from 'src/containers/modules/CurrencyFormat'
import { Row, Spacing, AlignedImage, Touchable } from 'src/retired/elements'
import Tooltip from 'src/retired/elements/Tooltip'
import getFullPaymentMethodName from 'src/utils/paymentMethods'
import type { MatchData } from 'src/containers/screens/Donate/match'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { DesiredMatchDialog } from './DesiredMatchDialog'
import { MatchedLabelStyled, MatchingContainer, MatchingRightColumn } from './styles'
import { DesiredMatchingRuleBlock } from './DesiredMatchingRuleBlock'

interface Props {
  /**
   * Contains:
   * - matchedAmountMaximum: Estimated maximal matched amount provided by the backend. (For donatePage the currency is the currency of the nonprofit)
   * - matchedAmount: Matched amount taking into account the desired matched amount
   * - capBalance: static object which contains the info about what your matching limit is, how much there is available and the currency.
   *     Only needed for the "$xy of $xy remaining"-part. Matching budgets can have specific currencies (which can be different to the baseCurrency)
   *     thats why we can not use the baseCurrency for everything and need to make use of capBalance's currency.
   */
  matchData: Omit<MatchData, 'matchingApplicableForPaymentMethod'>
  netAmount: number
  // if you don't want the matched amount and the remaining match amounts to be displayed in the user's setting currency
  // but in any other you need to set customCurrency.
  customCurrency: CurrencyCode
  baseCurrency: CurrencyCode
  brandImage?: string
  hasCurrencyConversion?: boolean
  recurring?: boolean
  matchingPercentage?: number
  matchingInactive?: boolean
  donationPaymentMethod?: string
  customMatchCopy?: string
  bigMatchedText?: boolean
  usdRates: CurrencyRates
  onMatchingRuleOptionChange: (matchingRuleId: string) => void
  onDesiredMatchedAmountChange?: (amount: number) => void
}

export const MatchedAmountBlock = ({
  matchData,
  netAmount,
  baseCurrency,
  customCurrency,
  brandImage,
  hasCurrencyConversion,
  recurring,
  matchingPercentage,
  matchingInactive,
  donationPaymentMethod,
  customMatchCopy,
  bigMatchedText,
  usdRates,
  onMatchingRuleOptionChange = () => {},
  onDesiredMatchedAmountChange = () => {},
}: Props) => {
  const { t } = useTranslation('donateScreen')
  const { capBalance, matchableRules, desiredMatchingRule, matchedAmount } = matchData

  const capBalanceLoaded = capBalance?.balance !== null && capBalance?.balance !== undefined
  const [showPartialMatchDialog, setShowPartialMatchDialog] = useState(false)
  const { colors } = useDeedTheme()

  return (
    <View style={{ width: '100%' }}>
      <DesiredMatchingRuleBlock
        onMatchingRuleOptionChange={(matchingRuleId) => {
          matchData.setDesiredMatchingRule(matchingRuleId)
          onMatchingRuleOptionChange(matchingRuleId)
        }}
        matchableRules={matchableRules}
        desiredMatchingRule={desiredMatchingRule}
      />
      {!!matchData.matchedAmountMaximum && (
        <DesiredMatchDialog
          matchData={matchData}
          netAmount={netAmount}
          customCurrency={customCurrency}
          baseCurrency={baseCurrency}
          visible={showPartialMatchDialog}
          onDesiredMatchedAmountChange={onDesiredMatchedAmountChange}
          onClose={() => setShowPartialMatchDialog(false)}
          usdRates={usdRates}
        />
      )}
      <MatchingContainer inactive={matchingInactive}>
        <Row style={{ justifyContent: 'space-between', gap: 4 }}>
          {!matchingInactive ? (
            <MatchedLabelStyled active>
              <Label center weight="500">
                {customMatchCopy || t`common:matched`}
              </Label>
            </MatchedLabelStyled>
          ) : (
            <MatchedLabelStyled>{t`common:notMatched`}</MatchedLabelStyled>
          )}

          <MatchingRightColumn>
            {!matchingInactive ? (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {brandImage && (
                  <AlignedImage
                    alignment="right"
                    width={70}
                    height={20}
                    style={{ marginLeft: 8, marginRight: 8 }}
                    source={{ uri: brandImage }}
                  />
                )}

                {capBalanceLoaded ? (
                  <Tooltip title={t`matchingProgram`}>
                    <Trans
                      t={t}
                      i18nKey={
                        recurring ? 'yourDonationMayBeMatchedBalanceRecurring' : 'yourDonationMayBeMatchedBalance'
                      }
                      values={{
                        approximately: hasCurrencyConversion ? t`approximately` : '',
                      }}
                      components={{
                        CurrencyFormatLimit: (
                          <CurrencyFormat
                            baseCurrency={capBalance.currencyCode}
                            amount={capBalance.limitPerUser}
                            customCurrency={customCurrency}
                          />
                        ),
                        CurrencyFormatBalance: (
                          <CurrencyFormat
                            baseCurrency={capBalance.currencyCode}
                            amount={capBalance.balance || undefined}
                            customCurrency={customCurrency}
                          />
                        ),
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={t`matchingProgram`}>
                    {recurring
                      ? t`yourDonationMayBeMatchedBalanceNullRecurring`
                      : t`yourDonationMayBeMatchedBalanceNull`}
                  </Tooltip>
                )}
              </View>
            ) : (
              donationPaymentMethod && (
                <Tooltip title={t`matchingProgram`}>
                  {t('yourDonationMayBeMatched', {
                    fullPaymentName: getFullPaymentMethodName(donationPaymentMethod),
                  })}
                </Tooltip>
              )
            )}
          </MatchingRightColumn>
        </Row>

        <Spacing marginBottom={8} />

        <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: 4, alignItems: 'baseline' }}>
          <Text weight="500" fontSize={bigMatchedText ? 20 : 16}>
            {Number.isFinite(matchedAmount) ? (
              <CurrencyFormat
                baseCurrency={baseCurrency}
                amount={matchedAmount}
                customCurrency={customCurrency}
                disambiguateCurrency
              />
            ) : (
              matchingPercentage && `${matchingPercentage}%`
            )}
          </Text>

          {matchData.partialMatchingEnabled && !!matchData.matchedAmountMaximum && (
            <Touchable onPress={() => setShowPartialMatchDialog(true)} style={{ flex: '1 0 auto', maxWidth: '100%' }}>
              <Text
                colour={colors.brandColor}
                underline
                style={{ textDecorationColor: colors.brandColor, fontWeight: '500', marginLeft: 'auto', fontSize: 12 }}
              >
                {t`changeMatchAmount`}
              </Text>
            </Touchable>
          )}

          {capBalanceLoaded && (
            <MatchingRightColumn
              style={{ display: 'flex', justifyContent: 'center', marginLeft: 'auto', flex: '1 1 auto' }}
            >
              <Label right style={{ display: 'flex', alignItems: 'center' }}>
                <Trans
                  t={t}
                  i18nKey="currencyOf"
                  components={{
                    Currency: (
                      <CurrencyFormat
                        baseCurrency={capBalance?.currencyCode}
                        amount={capBalance?.balance || undefined}
                        customCurrency={customCurrency}
                      />
                    ),
                  }}
                />
                <Trans
                  t={t}
                  i18nKey="currencyRemaining"
                  components={{
                    Currency: (
                      <CurrencyFormat
                        baseCurrency={capBalance?.currencyCode}
                        amount={capBalance?.limitPerUser}
                        customCurrency={customCurrency}
                      />
                    ),
                  }}
                />
              </Label>
            </MatchingRightColumn>
          )}
        </Row>
      </MatchingContainer>
    </View>
  )
}
