import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Column, AlignedImage } from 'src/retired/elements'
import { Body1, Body2, Label } from 'src/retired/shared/Typography'
import ProgressBar from 'src/retired/shared/ProgressBar'
import { CurrencyCode, CurrencyFormatWithTooltip } from 'src/containers/modules/CurrencyFormat'
import truthy from 'src/utils/truthy'
import { formatNumber } from 'src/utils/format'
import Deed from 'src/entities/deed/model'
import Organization from 'src/entities/organization/model'

interface EntryRowProps extends Pick<CampaignBarProps, 'amountCurrencies' | 'baseCurrency'> {
  label: string
  color: string
}

const EntryRow = ({ label, amountCurrencies, baseCurrency, color }: EntryRowProps): JSX.Element => {
  const { colors } = useDeedTheme()
  return (
    <Row
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: colors.gray04,
        paddingTop: 20,
        paddingBottom: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Column style={{ flexGrow: 1, paddingRight: 10, maxWidth: '60%' }}>
        <Row style={{ alignItems: 'center' }}>
          <View style={{ width: 15, height: 15, borderRadius: 7, backgroundColor: color, marginRight: 10 }} />
          <Body1>{label}</Body1>
        </Row>
      </Column>
      <Column style={{ flexGrow: 0 }}>
        <CurrencyFormatWithTooltip
          baseCurrency={baseCurrency}
          amountCurrencies={amountCurrencies}
          textStyle={{
            fontSize: 14,
            right: true,
            whiteSpace: 'nowrap',
          }}
        />
      </Column>
    </Row>
  )
}

const DonationCreditRowStyled = styled(Row)<EmotionTheme>(
  ({ theme }: { theme: EmotionTheme }) => `
  width: ${theme.metrics.isSmall ? `${theme.metrics.screenWidth}px;` : '100%'}
  margin: -25px -25px 25px -25px;
  padding: 15px 25px;
  align-items: center;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.gray02};
`
)

export interface CampaignBarProps {
  baseCurrency: CurrencyCode
  organization: Organization
  amountCurrencies: Deed['goalAmountCurrencies']
  donationStats: Deed['donationStats']
  deed: Deed
}

export const CampaignBar = ({
  baseCurrency,
  organization,
  amountCurrencies,
  donationStats = {},
  deed,
}: CampaignBarProps): JSX.Element => {
  const { t } = useTranslation('campaignBar')
  const { colors, metrics } = useDeedTheme()
  const { totalAmountCurrencies, otherAmountCurrencies, employeeAmountCurrencies, matchedByEmployerAmountCurrencies } =
    donationStats

  const items = [
    otherAmountCurrencies?.[baseCurrency] > 1 && {
      label: employeeAmountCurrencies?.[baseCurrency] > 1 ? t`others` : t`donors`,
      color: colors.teal,
      value: otherAmountCurrencies[baseCurrency],
      valueCurrencies: otherAmountCurrencies,
    },
    employeeAmountCurrencies?.[baseCurrency] > 1 && {
      label: t('employees', { organizationName: organization.name }),
      color: colors.brandColor,
      value: employeeAmountCurrencies[baseCurrency],
      valueCurrencies: employeeAmountCurrencies,
    },
    matchedByEmployerAmountCurrencies?.[baseCurrency] > 1 && {
      label: t`totalMatched`,
      color: colors.yellow,
      value: matchedByEmployerAmountCurrencies[baseCurrency],
      valueCurrencies: matchedByEmployerAmountCurrencies,
    },
  ].filter(truthy)

  return (
    <View style={{ width: '100%' }}>
      {organization &&
      deed.matchingPercentage &&
      deed.matchingMaximumCurrencies &&
      deed.matchingMaximumCurrencies[deed.currencyCode] > 0 ? (
        <DonationCreditRowStyled>
          <AlignedImage
            alignment="left"
            width={30}
            height={30}
            style={{ marginRight: 15 }}
            source={{ uri: organization.logo }}
          />
          <Body2 weight="500" style={{ width: metrics.isSmall ? metrics.screenWidth - 80 : '100%' }}>
            {t(`willMatchYourDonationXTimes`, {
              organizationName: organization.name,
              xTimes: formatNumber(deed.matchingPercentage / 100),
            })}
          </Body2>
        </DonationCreditRowStyled>
      ) : null}
      <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'flex-start' }}>
        <Column style={{ flexShrink: 0, flexGrow: 0, marginBottom: 20, marginRight: 10 }}>
          <Label marginBottom={8} weight="500" style={{ textTransform: 'uppercase' }}>
            {t`raised`}
          </Label>
          <CurrencyFormatWithTooltip baseCurrency={baseCurrency} amountCurrencies={totalAmountCurrencies} />
        </Column>
        {deed.goalAmount > 0 && (
          <Column style={{ flexShrink: 1, flexGrow: 0, marginBottom: 20 }}>
            <Label marginBottom={8} weight="500" style={{ textTransform: 'uppercase' }}>
              {t`goal`}
            </Label>
            <CurrencyFormatWithTooltip baseCurrency={baseCurrency} amountCurrencies={amountCurrencies} />
          </Column>
        )}
      </Row>
      <View style={{ marginBottom: 32 }}>
        <ProgressBar items={items} total={amountCurrencies?.[baseCurrency]} backgroundColor={colors.gray02} />
      </View>
      <View>
        {items.map((item) => (
          <EntryRow
            key={item.label}
            label={item.label}
            amountCurrencies={item.valueCurrencies}
            color={item.color}
            baseCurrency={baseCurrency}
          />
        ))}
      </View>
    </View>
  )
}
