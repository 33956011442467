import { fromJS, Map as ImmutableMap } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'
import type { DonationScheduleActionTypes } from 'src/entities/donationSchedule/actions'

import { ADD, ADD_MULTIPLE, LIST_LOADED, REMOVE } from './constants'
import type DonationSchedule from './model'

export type DonationScheduleMap = ImmutableMap<string, DonationSchedule>

export type DonationScheduleState = TypedMap<{
  donationSchedules: DonationScheduleMap
  donationSchedulesListLoaded: boolean
}>

export const initialState = fromJS({
  donationSchedules: ImmutableMap(),
  donationSchedulesListLoaded: false,
}) as DonationScheduleState

export default (
  state: DonationScheduleState = initialState,
  action: DonationScheduleActionTypes
): DonationScheduleState => {
  switch (action.type) {
    case ADD:
      return state.set(
        'donationSchedules',
        state.get('donationSchedules').set(action.donationSchedule.id, action.donationSchedule)
      )

    case ADD_MULTIPLE:
      return state.set('donationSchedules', state.get('donationSchedules').merge(action.donationSchedules))

    case LIST_LOADED:
      return state.set('donationSchedulesListLoaded', true)

    case REMOVE:
      return state.set('donationSchedules', state.get('donationSchedules').remove(action.id))
  }
  return state
}
