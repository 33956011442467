import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import { SmallOnly, MediumUp } from 'src/retired/elements/MediaQuery'

const styles = StyleSheet.create({
  root: {
    position: 'fixed',
    width: '100%',
    alignItems: 'center',
    bottom: 25,
    left: 0,
    right: 0,
    zIndex: 25,
    paddingBottom: 5,
    marginBottom: -5,
  },
  overlay: {
    // Since overlay has its own inner scroll, we no longer need to rely on `position: fixed`
    position: 'absolute',
  },
})

const Action = ({ children, style = {}, fullScreen = false, ...props }) => (
  <>
    <SmallOnly>
      <View style={[styles.root, style]} {...props}>
        {children}
      </View>
    </SmallOnly>
    <MediumUp>
      <View style={[styles.root, !fullScreen && styles.overlay, style]} {...props}>
        {children}
      </View>
    </MediumUp>
  </>
)
Action.propTypes = {
  children: PropTypes.node,
  style: PropTypes.any,
  fullScreen: PropTypes.bool,
}

export default Action
