import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import Api from 'src/entities/api'
import AuthApi from 'src/entities/auth/api'
import UserApi from 'src/entities/user/api'
import OrganizationApi from 'src/entities/organization/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCompaniesLoaded } from 'src/entities/organization/selectors'
import { selectCookieConsentResponse } from 'src/containers/modules/CookieConsent/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { selectSelectedLocation } from 'src/containers/screens/Register/Location/selectors'
import truthy from 'src/utils/truthy'

import {
  initSuccessAction,
  initFailedAction,
  authSuccessAction,
  authFailedAction,
  appleAuthSuccessAction,
  appleAuthFailedAction,
} from './actions'
import { INIT, AUTH, APPLE_LOGIN } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const state = store.getState()
    if (selectCompaniesLoaded(state)) {
      return Observable.of(initSuccessAction())
    }

    return OrganizationApi.fetchCompanies()
      .mergeMap((actions) => [...actions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('loginScreen:failedToLogin'))))
  })

const auth = (action$, store) =>
  action$.ofType(AUTH).exhaustMap(({ email, password }) => {
    const state = store.getState()
    const cookieConsentResponse = selectCookieConsentResponse(state)
    let previousLocation
    if (cookieConsentResponse) {
      previousLocation = selectLocalSetting(state, 'previousLocation')
    } else if (window?.sessionStorage) {
      previousLocation = window.sessionStorage.getItem('previousLocation')
      if (previousLocation) {
        window.sessionStorage.removeItem('previousLocation')
      }
    }
    return AuthApi.authLocal(email, password)
      .mergeMap((action) => {
        Api.defaultHeaders.Authorization = `Bearer ${action.token}`
        return [
          action,
          replace(previousLocation || '/home'),
          previousLocation && setLocalSettingAction('previousLocation', null),
          authSuccessAction(),
        ].filter(truthy)
      })
      .catch((e) => Observable.of(authFailedAction(e), showErrorAction(i18n.t('loginScreen:failedToLogin'))))
  })

const appleAuthenticate = (action$, store) =>
  action$.ofType(APPLE_LOGIN).exhaustMap(({ appleToken: { identityToken, familyName, givenName } }) => {
    const state = store.getState()
    const cookieConsentResponse = selectCookieConsentResponse(state)
    let previousLocation
    if (cookieConsentResponse) {
      previousLocation = selectLocalSetting(state, 'previousLocation')
    } else if (window?.sessionStorage) {
      previousLocation = window.sessionStorage.getItem('previousLocation')
      if (previousLocation) {
        window.sessionStorage.removeItem('previousLocation')
      }
    }
    return AuthApi.authApple(identityToken, { familyName, givenName })
      .mergeMap((action) => {
        Api.defaultHeaders.Authorization = `Bearer ${action.token}`
        const selectedLocation = selectSelectedLocation(store.getState())
        if (action.isNew && selectedLocation) {
          return UserApi.update({ location: selectedLocation }).mergeMap((updateAction) =>
            [
              action,
              updateAction,
              replace(previousLocation || '/home'),
              previousLocation && setLocalSettingAction('previousLocation', null),
              appleAuthSuccessAction(),
            ].filter(truthy)
          )
        }
        return [
          action,
          replace(previousLocation || (action.isNew && !selectedLocation ? '/register/location' : '/home')),
          previousLocation && setLocalSettingAction('previousLocation', null),
          appleAuthSuccessAction(),
        ].filter(truthy)
      })
      .catch((e) => Observable.of(appleAuthFailedAction(e), showErrorAction(i18n.t('loginScreen:failedToLogin'))))
  })

export default [init, auth, appleAuthenticate]
