import { isBefore } from 'date-fns'

import type Donation from 'src/entities/donation/model'
import type { PayrollApiDonation, PayrollFlatFileDonation } from 'src/entities/donation/model'

import { isPayrollFlatFileDonation, useFlatFileCancellationDeadline } from './payrollFlatFile'
import { isPayrollApiDonation, useApiCancellationDeadline } from './payrollApi'

export const isPayrollDonation = (donation?: Donation): donation is PayrollApiDonation | PayrollFlatFileDonation =>
  isPayrollApiDonation(donation) || isPayrollFlatFileDonation(donation)

export const useCancellationDeadline = (donation?: Donation) => {
  const apiCancellationDeadline = useApiCancellationDeadline(donation)
  const flatFileCancellationDeadline = useFlatFileCancellationDeadline(donation)

  const cancellationDeadline = apiCancellationDeadline?.deadline || flatFileCancellationDeadline?.deadline
  const isDonationCancelable = cancellationDeadline && isBefore(new Date(), cancellationDeadline)

  return {
    cancellationDeadline,
    isDonationCancelable,
    loading: apiCancellationDeadline?.loading || flatFileCancellationDeadline?.loading || false,
  }
}
