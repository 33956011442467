import React from 'react'
import { useTranslation, Trans, TFunction } from 'react-i18next'

import { ColorsProps } from 'src/theme/colors'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { currencies, CurrencyFormat } from '../../CurrencyFormat'
import { IncentiveUnit } from '../types'

import { ActionChip } from './ActionChip'

const getChipDataByType = ({
  unit,
  amount,
  colors,
  t,
}: {
  unit: string
  amount?: number
  colors: ColorsProps
  t: TFunction<'actions'>
}) => {
  if (!unit) {
    return null
  }

  if (unit === IncentiveUnit.tree) {
    return {
      backgroundColor: colors.greenLighter,
      iconColor: colors.green,
      iconName: 'trees',
      text: t('reward_tree_planting', { count: amount }),
    }
  }

  // this applies to when donations are 0 dollars or 0 trees
  if (amount === 0) {
    return null
  }

  return (
    currencies[unit] && {
      backgroundColor: colors.greenLighter,
      iconColor: colors.green,
      iconName: 'heartHand',
      text: (
        <Trans
          t={t}
          i18nKey="reward_money"
          count={amount}
          components={{
            CurrencyFormat: (
              <CurrencyFormat
                amount={amount}
                baseCurrency={currencies[unit].code}
                customCurrency={currencies[unit].code}
                short
              />
            ),
          }}
        />
      ),
    }
  )
}

export const RewardChip = ({ amount, unit }: { amount?: number; unit: string }) => {
  const { t } = useTranslation('actions')
  const { colors } = useDeedTheme()

  const chipData = getChipDataByType({ unit, amount, colors, t })
  if (!chipData) {
    return null
  }

  const { text, iconName, backgroundColor, iconColor } = chipData
  return <ActionChip content={text} iconName={iconName} backgroundColor={backgroundColor} iconColor={iconColor} />
}
