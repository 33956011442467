import { Operation } from '@apollo/client'
import { AnyAction } from 'redux'

import config from 'src/config'

import {
  buildApolloOperationEventDetails,
  buildReduxActionEventDetails,
  defaultContext,
  pageDefaults,
} from '../../util'
import { AnalyticsMockClient, generateMockClient, LocationData } from '../util'

interface SegmentTrackConfiguration {
  traits: Record<string, any> | null
  properties: Record<string, any> | null
  context: Record<string, any> | null
  options: Record<string, any> | null
}

export class AbstractSegmentMetrics {
  public name: string

  public options?: object

  protected configured: SegmentTrackConfiguration

  private readonly _mock: AnalyticsMockClient

  constructor({ name = 'segment', debug = !config.isProduction, ...options } = {}) {
    this.name = name
    this.options = options
    this._mock = generateMockClient(name, debug)
    this.configured = {
      traits: null,
      properties: null,
      context: null,
      options: null,
    }
    this.pageView = this.pageView.bind(this)
    this.track = this.track.bind(this)
    this.identify = this.identify.bind(this)
    this.trackReduxAction = this.trackReduxAction.bind(this)
    this.trackApolloOperation = this.trackApolloOperation.bind(this)
    this.configure = this.configure.bind(this)
    this.reset = this.reset.bind(this)
  }

  get analytics(): AnalyticsMockClient {
    return this._mock
  }

  configure(configuration = {}): void {
    this.configured = {
      ...this.configured,
      ...configuration,
    }
  }

  async identify(
    userId: string | null,
    { __options: { context: passedContext = {}, ...options } = {}, ...traits } = {}
  ): Promise<void> {
    await this.analytics.identify(
      userId,
      {
        ...this.configured.traits,
        ...traits,
      },
      {
        context: {
          ...defaultContext,
          ...this.configured.context,
          ...passedContext,
        },
        ...this.configured.options,
        ...options,
      }
    )
  }

  async pageView(location: LocationData): Promise<void> {
    await this.analytics.screen(location?.pathname || 'no pathname', {
      ...this.configured.properties,
      ...pageDefaults,
      context: {
        ...defaultContext,
        ...this.configured.context,
      },
      ...this.configured.options,
    })
  }

  async reset(): Promise<void> {
    await this.analytics.reset()
  }

  async track(
    event: string,
    { __options: { context: passedContext = {}, ...options } = {}, ...properties } = {}
  ): Promise<void> {
    await this.analytics.track(
      event,
      {
        ...this.configured.properties,
        ...properties,
      },
      {
        context: {
          ...defaultContext,
          ...this.configured.context,
          ...passedContext,
        },
        ...this.configured.options,
        ...options,
      }
    )
  }

  async trackReduxAction(action: AnyAction, supplementaryDetails = {}): Promise<void> {
    return this.track('redux-action', buildReduxActionEventDetails(action, supplementaryDetails))
  }

  async trackApolloOperation(operation: Operation, supplementaryDetails = {}): Promise<void> {
    return this.track('apollo-operation', buildApolloOperationEventDetails(operation, supplementaryDetails))
  }
}

export default AbstractSegmentMetrics
