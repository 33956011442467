import { View } from 'react-primitives'

import { Image } from 'src/retired/elements'
import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

export const NOTICE_HIDE_SHARE_ICONS = true
export const BASE_SPACING_UNIT = 8

export const Container = styled(View)<object, EmotionTheme>`
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 798px;
  align-self: center;
  overflow: hidden;
  margin-top: ${({ theme }) => `${BASE_SPACING_UNIT * (theme.metrics.isSmall ? 2 : 8)}px`};
`

export const LeftSide = styled(View)`
  width: 100%;
`

export const OverlayColor = styled(View)`
  position: absolute;
  top: 0;
  background-color: rgba(234, 234, 234, 0.85);
  width: 100%;
  height: 100%;
`

export const RightSide = styled(View)<object, EmotionTheme>`
  margin-horizontal: ${({ theme }) => (theme.metrics.isSmall ? '8px' : '64px')};
  margin-vertical: 32px;
  flex: none;
`

export const ShareIcons = styled.View`
  flex-direction: row;
`

export const Details = styled.View`
  justify-content: space-around;
`

export const Detail = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`

export const DetailRightPart = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex: 1 1 auto;
`

export const Nonprofits = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: -13px;
`

export const LeftImage = styled(Image)<object, EmotionTheme>`
  width: 100%;
  object-fit: cover;
  height: ${({ theme }) => (theme.metrics.isSmall ? '150px' : '304px')};
`

export const CauseNameOnImage = styled(View)<object, EmotionTheme>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
`
