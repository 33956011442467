import { fromJS } from 'immutable'

import { SHOW_MESSAGE, SHOW_ERROR, CLOSE } from './constants'

export const initialState = fromJS({ title: '', message: '', error: null, open: false, reload: false })

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return state.merge({
        open: true,
        title: action.title,
        message: action.message,
        error: null,
        reload: false,
      })

    case SHOW_ERROR:
      return state.merge({
        open: true,
        title: action.title,
        error: action.error,
        message: '',
        reload: action.reload,
      })

    case CLOSE:
      return state.merge({ open: false, title: '', message: '', error: null, reload: false })

    default:
      return state
  }
}

export default notificationReducer
