import { fromJS, Set } from 'immutable'

import { DeviceInfo } from 'src/utils'

import {
  INIT,
  TOGGLE_SELECTED_CAUSE,
  SET_SELECTED_CAUSES,
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  TOGGLE_CORPORATE_ONLY,
  RESET,
} from './constants'

export const initialState = fromJS({
  initialized: false,
  selectedCauses: Set(),
  searchTerm: '',
  searching: false,
  searchFailed: false,
  corporateOnly: true,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ initialized: true, corporateOnly: action.corporateOnly })

    case TOGGLE_SELECTED_CAUSE: {
      const selectedCauses = state.get('selectedCauses')
      return state.set(
        'selectedCauses',
        selectedCauses[selectedCauses.has(action.cause) ? 'delete' : 'add'](action.cause)
      )
    }

    case SET_SELECTED_CAUSES: {
      return state.set('selectedCauses', Set(action.causes))
    }

    case SEARCH:
      return state.merge({
        searchTerm: action.searchTerm,
        searching: DeviceInfo.getApplicationName() === 'DEED-Dev' ? action.searchTerm.length > 2 : false,
        searchFailed: false,
      })

    // @TODO: Enable external nonprofit search
    case SEARCH_SUCCESS:
      return state.merge({ searching: false, searchFailed: false })

    case SEARCH_FAILED:
      return state.merge({ searching: false, searchFailed: true })

    case TOGGLE_CORPORATE_ONLY:
      return state.set('corporateOnly', action.state)

    case RESET:
      return initialState

    default:
      return state
  }
}
