import { Record } from 'immutable'
import _ from 'lodash'

import truthy from 'src/utils/truthy'

const properties = {
  address: '',
  city: '',
  area: '',
  postalCode: '',
  state: '',
  stateCode: '',
  country: '',
  countryCode: '',
}

export default class LocationObject extends Record(properties, 'LocationObject') implements LocationObject {
  public readonly address!: string

  public readonly city!: string

  public readonly area!: string

  public readonly postalCode!: string

  public readonly state!: string

  public readonly stateCode!: string

  public readonly country!: string

  public readonly countryCode!: string

  constructor(values = {}) {
    super(_.pick(values, Object.keys(properties)))
  }

  public areaString(skipCountry = false): string {
    return [
      this.area !== this.city && this.area,
      this.state ? this.city : `${this.postalCode} ${this.city}`.trim(),
      this.state && this.postalCode ? `${this.state} ${this.postalCode}` : this.state,
      !skipCountry && this.country,
    ]
      .filter(truthy)
      .join(', ')
  }

  public cityString(skipCountry = false): string {
    return [
      this.state ? this.city : `${this.postalCode} ${this.city}`.trim(),
      this.state && this.postalCode ? `${this.state} ${this.postalCode}` : this.state,
      !skipCountry && this.country,
    ]
      .filter(truthy)
      .join(', ')
  }

  public toString(skipCountry = true): string {
    return [
      this.address,
      this.state ? this.city : `${this.city ?? ''} ${this.postalCode ?? ''}`.trim(),
      this.state && this.postalCode ? `${this.state ?? ''} ${this.postalCode ?? ''}` : this.state ?? '',
      !skipCountry ? this.country : '',
    ]
      .filter(truthy)
      .join(', ')
  }

  public get locationCode() {
    if (this.stateCode) {
      const stateCodeParts = this.stateCode.split('-')
      if (stateCodeParts[0] === this.countryCode && stateCodeParts.length > 1) {
        // sometimes stateCode also includes countryCode
        return this.stateCode
      }
      return `${this.countryCode}-${this.stateCode}`
    }
    return `${this.countryCode}`
  }
}
