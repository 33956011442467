import { Observable } from 'rxjs'

import { selectOrganizationById } from 'src/entities/organization/selectors'
import OrganizationApi from 'src/entities/organization/api'

import { INIT } from './constants'
import { initSuccessAction, initFailedAction } from './actions'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap((payload) => {
    const actions = []
    const state = store.getState()

    const organization = selectOrganizationById(state, payload.id)
    if (!organization) {
      actions.push(OrganizationApi.fetch(payload.id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
