import type { DonationProvider } from '@joindeed/calculate-fees'

import type { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import type Donation from 'src/entities/donation/model'
import type { Donor } from 'src/containers/screens/Donate/elements/DonorDetailsForm'
import type { FrontendFees } from 'src/containers/screens/Donate/calculateFeesWithHistory'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SET_DONATING,
  DONATE,
  DONATE_SUCCESS,
  DONATE_FAILED,
  NON_STRIPE_DONATION,
  REGISTER_BETTERPLACE_DONATION,
  REGISTER_GIVEINDIA_DONATION,
  REGISTER_STRIPE_DONATION,
  REGISTER_PAYPAL_DONATION,
  REGISTER_RECURRING_PAYPAL_DONATION,
  REGISTER_RESUME_RECURRING_PAYPAL_DONATION,
  FETCH_PROVIDER_DATA,
  FETCH_PROVIDER_DATA_SUCCESS,
  FETCH_PROVIDER_DATA_FAILED,
  CREATE_CUSTOMER_ID,
  CREATE_CUSTOMER_ID_SUCCESS,
  CREATE_CUSTOMER_ID_FAILED,
  PAYPAL_CANCEL,
} from './actionNames'

export const initAction = (id: string, kind: 'deed' | 'organization', campaignId?: string) => ({
  type: INIT,
  id,
  kind,
  campaignId,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: object, errorCode?: number) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const setDonatingAction = () => ({
  type: SET_DONATING,
})

export const nonStripeDonationAction = () => ({
  type: NON_STRIPE_DONATION,
})

export const registerBetterplaceDonationAction = (data: object) => ({
  type: REGISTER_BETTERPLACE_DONATION,
  data,
})

export const registerGiveIndiaDonationAction = (data: object) => ({
  type: REGISTER_GIVEINDIA_DONATION,
  data,
})

export const registerStripeDonationAction = (intentId: string) => ({
  type: REGISTER_STRIPE_DONATION,
  intentId,
})

export const registerPaypalDonationAction = (orderId: string) => ({
  type: REGISTER_PAYPAL_DONATION,
  orderId,
})

interface RecurringPaypalDonationPayload {
  orderId: string
  amount: number
  desiredMatchAmount: number | undefined
}
export const registerRecurringPaypalDonationAction = (data: RecurringPaypalDonationPayload) => ({
  type: REGISTER_RECURRING_PAYPAL_DONATION,
  data,
})

interface CaptureRecurringPaypalDonationPayload {
  orderId: string
  paymentToken: string
  donationScheduleId: string
}
export const registerCaptureRecurringPaypalDonationAction = (data: CaptureRecurringPaypalDonationPayload) => ({
  type: REGISTER_RESUME_RECURRING_PAYPAL_DONATION,
  data,
})

// @TODO-AC: This is incomplete and needs to be expanded
export interface DonatePayload {
  donationProvider: DonationProvider | null
  amount: number
  currency: CurrencyCode
  coverFee: boolean
  desiredMatchAmount: number | undefined
  desiredMatchingRule: string | undefined
  creditCardData?: {
    number: string
    securityCode: string
    expiration: { month: string; year: string }
  }
  billingAddress: { street: string; postalCode: string }
  feesHistoryItem: FrontendFees
  privacy: string
  donorPrivacy: string
  designation: string
  dedication: string
  donor: Donor | undefined
  campaign?: string
}
export interface DonateAction {
  type: typeof DONATE
  data: DonatePayload
}
export const donateAction = (data: DonatePayload): DonateAction => ({
  type: DONATE,
  data,
})
export const donateSuccessAction = (donation: Donation) => ({
  type: DONATE_SUCCESS,
  donation,
})
export const donateFailedAction = (error: object, errorCode?: number) => ({
  type: DONATE_FAILED,
  error,
  errorCode,
})

export interface DonateViaNonprofit {
  payeeId: string
  nonprofitId: string
  name: string
  childname: string
  routed: boolean
}
export interface FetchedProviderData {
  donationProvider: DonationProvider
  donateViaNonprofits: DonateViaNonprofit[]
}
export const fetchProviderDataAction = (deedId?: string, nonprofitId?: string, campaignId?: string) => ({
  type: FETCH_PROVIDER_DATA,
  deedId,
  nonprofitId,
  campaignId,
})
export const fetchProviderDataSuccessAction = (identifier: string, providerData: FetchedProviderData) => ({
  type: FETCH_PROVIDER_DATA_SUCCESS,
  identifier,
  providerData,
})
export const fetchProviderDataFailedAction = (error: object, errorCode?: number) => ({
  type: FETCH_PROVIDER_DATA_FAILED,
  error,
  errorCode,
})

export const createCustomerIdAction = () => ({
  type: CREATE_CUSTOMER_ID,
})
export const createCustomerIdSuccessAction = (clientToken: string, paymentToken: string) => ({
  type: CREATE_CUSTOMER_ID_SUCCESS,
  clientToken,
  paymentToken,
})
export const createCustomerIdFailedAction = () => ({
  type: CREATE_CUSTOMER_ID_FAILED,
})

export const payPalCancelAction = (orderId: string) => ({
  type: PAYPAL_CANCEL,
  orderId,
})
