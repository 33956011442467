import React, { PureComponent } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { StyleSheet, View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import { colors, images } from 'src/theme'
import { DeviceInfo } from 'src/utils'
import { Action, Text, LinkButton, Image, Spacing } from 'src/retired/elements'
import { SmallOnly, MediumOnly, MediumDown, LargeUp } from 'src/retired/elements/MediaQuery'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import LanguageSwitcher from 'src/containers/modules/LanguageSwitcher'

import 'src/retired/blocks/Carousel/style.css'
import slides from './slides'

class WebCarousel extends PureComponent {
  state = {
    activeSlide: 0,
  }

  render() {
    const { t } = this.props
    return (
      <View style={styles.containerStyle}>
        <Carousel
          width="100vw"
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          emulateTouch
          useKeyboardArrows
          infiniteLoop={false}
          selectedItem={this.state.activeSlide}
          onChange={(i) => {
            this.setState({ activeSlide: i })
          }}
        >
          {slides.map((slide, i) => (
            <View key={`slide-${i}`}>
              <Image
                source={{ uri: slide.image }}
                backgroundPosition={slide.backgroundPosition}
                style={[styles.imageStyle]}
              />
              <View style={styles.textWrapperStyle}>
                <MediumDown>
                  <Text size={28} style={styles.textStyle}>
                    {slide.text}
                  </Text>
                </MediumDown>
                <LargeUp>
                  <Text size={36} style={styles.textStyle}>
                    {slide.text}
                  </Text>
                </LargeUp>
              </View>
            </View>
          ))}
        </Carousel>
        <SmallOnly>
          <Image source={{ uri: images.logoStackedWhite }} style={[styles.logo, { width: 97, height: 100 }]} />
        </SmallOnly>
        <MediumOnly>
          <Image source={{ uri: images.logoStackedWhite }} style={[styles.logo, { width: 146, height: 150 }]} />
        </MediumOnly>
        <LargeUp>
          <Image source={{ uri: images.logoStackedWhite }} style={[styles.logo, { width: 196, height: 200 }]} />
        </LargeUp>
        <Action style={styles.action}>
          <LinkButton to="/register" color={this.state.activeSlide === 2 ? 'primary' : 'outlined'}>
            {t`joinUs`}
          </LinkButton>
          <Spacing marginTop={10} marginBottom={10}>
            <Text size={14} color={colors.white} center>
              {t`or`}
            </Text>
          </Spacing>
          <LinkButton to="/login" color="outlined">
            {t`signIn`}
          </LinkButton>

          {/* It is so weird to show the language switcher here and not somewhere in the corner, don't blame me, blame product */}
          <div style={{ marginTop: 20 }}>
            <LanguageSwitcher transparent />
          </div>
        </Action>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    position: 'absolute',
    top: 55,
  },
  imageStyle: {
    width: '100%',
    height: `calc(100vh - ${DeviceInfo.getNotchValue()}px)`,
  },
  textWrapperStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    color: colors.white,
    padding: 10,
  },
  action: {
    position: 'fixed',
    width: '100%',
    bottom: 25,
    alignItems: 'center',
  },
})

export default withTranslation('carousel')(WebCarousel)
