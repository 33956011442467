import React, { Fragment } from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import StepNumber from 'src/retired/shared/StepNumber'
import { colors } from 'src/theme'

const Divider = () => (
  <View style={{ flexDirection: 'row', justifyContent: 'center', marginHorizontal: 24, flexGrow: 1 }}>
    <View
      style={{
        height: 1,
        backgroundColor: colors.gray02,
        alignSelf: 'center',
        flex: 1,
      }}
    />
  </View>
)

interface StepsProps {
  steps: number
  current?: number
}
export type StepsElement = React.ReactElement<StepsProps>

const Steps: React.FC<StepsProps> = ({ steps, current = 1 }): StepsElement => {
  const { metrics } = useDeedTheme()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: metrics.isLarge ? 72 : 16,
      }}
    >
      {[...Array(steps + 1).keys()].slice(1).map((step) => (
        <Fragment key={step}>
          <StepNumber number={step} state={step === current ? 'current' : current > step ? 'completed' : 'default'} />
          {step < steps && <Divider />}
        </Fragment>
      ))}
    </View>
  )
}
export default Steps
