import { fromJS } from 'immutable'

import { INIT, INIT_SUCCESS, INIT_FAILED, SET_SELECTED_COMPANY_ID, SET_URL, UNSET_URL } from './constants'

export const initialState = fromJS({
  loading: false,
  error: null,
  companyId: '',
  url: '',
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null, companyId: '', url: '' })

    case INIT_SUCCESS:
      return state.merge({ loading: false })

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case SET_SELECTED_COMPANY_ID:
      return state.set('companyId', action.companyId)

    case SET_URL:
      return state.set('url', action.url)

    case UNSET_URL:
      return state.set('url', '')

    case 'RESET':
      return initialState

    default:
      return state
  }
}
