import React from 'react'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import ExternalLink from 'src/retired/elements/ExternalLink'
import NotificationBanner from 'src/retired/elements/NotificationBanner'
import { selectCurrentUser } from 'src/entities/user/selectors'

const CompanyNotificationBanner = (): JSX.Element | null => {
  const user = useSelector(selectCurrentUser)
  const { colors, metrics } = useDeedTheme()

  const notificationBanner = user?.organization?.notificationBanner

  if (!notificationBanner?.isActive) {
    return null
  }

  return (
    <NotificationBanner backgroundColor={notificationBanner.backgroundColor} textColor={notificationBanner.textColor}>
      {notificationBanner.bannerText}
      {notificationBanner.linkIsActive && notificationBanner.linkText && notificationBanner.linkUrl ? (
        <>
          {' '}
          <ExternalLink
            underline
            color={notificationBanner.textColor || colors.white}
            style={{
              fontSize: metrics.isSmall ? 12 : 14,
              fontFamily: 'GTWalsheimLC',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }}
            href={notificationBanner.linkUrl}
          >
            {notificationBanner.linkText}
          </ExternalLink>
        </>
      ) : null}
    </NotificationBanner>
  )
}
CompanyNotificationBanner.displayName = 'CompanyNotificationBanner'
export default CompanyNotificationBanner
