import { createSelector } from 'reselect'

const selectState = (state) => state.get('SingleSignOn')

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))

export const selectSelectedCompanyId = createSelector(selectState, (state) => state.get('companyId'))

export const selectUrl = createSelector(selectState, (state) => state.get('url'))
