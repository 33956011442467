import React, { useContext, useState } from 'react'
import classNames from 'classnames'
import { EnrichedReaction, UR } from 'getstream'
import { Avatar, useTranslationContext } from 'react-activity-feed'
import type { DefaultUT } from 'react-activity-feed'
import { Flex } from 'react-activity-feed/dist/components/Flex'
import { humanizeTimestamp, textRenderer } from 'react-activity-feed/dist/utils'
import type { OnClickUserHandler, PropsWithElementAttributes } from 'react-activity-feed/dist/utils'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { DropdownMenu } from 'src/components/DropdownMenu/DropdownMenu'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Box } from 'src/components/Box/Box'
import { CommunityUserRole } from 'src/generated/graphql'
import { Chip } from 'src/components/Chip/Chip'

import { LikeButton } from './LikeButton'
import { DeleteCommentConfirmationModal } from './DeleteCommentConfirmationModal'
import { CommunityContext } from './CommunityFeed'

export type CommentItemProps<
  UT extends DefaultUT = DefaultUT,
  RT extends UR = UR,
  CRT extends UR = UR
> = PropsWithElementAttributes<
  {
    comment: EnrichedReaction<RT, CRT, UT>
    onClickUser?: OnClickUserHandler<UT>
  } & Partial<Record<'onClickMention' | 'onClickHashtag', (word: string) => void>>
>

export const CommentItem = <UT extends DefaultUT = DefaultUT, RT extends UR = UR, CRT extends UR = UR>({
  comment,
  onClickHashtag,
  onClickMention,
  className,
  style,
}: CommentItemProps<UT, RT, CRT>): JSX.Element => {
  const { tDateTimeParser } = useTranslationContext()
  const { t } = useTranslation('communityFeed')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { metrics } = useDeedTheme()

  const currentUser = useSelector(selectCurrentUser)
  const { role } = useContext(CommunityContext)

  const { user, created_at, data } = comment

  const dropdownMenu = (role === CommunityUserRole.Lead || currentUser?.id === comment?.user_id) && (
    <DropdownMenu
      options={[
        // @TODO-DP: implement editing
        // { id: 'edit' as const, label: t`edit` },
        { id: 'delete' as const, label: t`delete` },
      ]}
      onSelect={(optionId) => {
        if (optionId === 'delete') {
          setDeleteModalOpen(true)
        }
      }}
    />
  )

  return (
    <Box className={classNames('raf-comment-item', className)} style={style}>
      <Flex>
        <Flex a="flex-start">
          {user?.data.profileImage && (
            <Avatar image={user.data.profileImage} circle size={metrics.screenWidth >= 640 ? 56 : 40} />
          )}
        </Flex>
        <Flex d="column" className="raf-comment-item__content-wrapper">
          <div className="raf-comment-item__content">
            <Flex a="center">
              <div style={{ marginRight: 0 }}>
                <Flex a="center" j="flex-start">
                  <div className="raf-comment-item__author">{user?.data.name}</div>
                  {user?.data.role === CommunityUserRole.Lead && (
                    <Chip color="secondary" size="small" label={t`lead`} />
                  )}
                </Flex>
                <div>
                  <time dateTime={created_at} title={created_at}>
                    <small>{humanizeTimestamp(created_at, tDateTimeParser)}</small>
                  </time>
                </div>
              </div>
              <div style={{ marginLeft: 2 }}>{dropdownMenu}</div>
            </Flex>
            <p>{textRenderer(data.text as string, 'raf-comment-item', onClickMention, onClickHashtag)}</p>
          </div>
        </Flex>
        <Flex>
          <LikeButton reaction={comment} />
        </Flex>
      </Flex>

      {deleteModalOpen && (
        <DeleteCommentConfirmationModal
          onClose={() => {
            setDeleteModalOpen(false)
          }}
          activityId={comment?.activity_id}
          commentId={comment.id}
        />
      )}
    </Box>
  )
}
