import i18n from 'i18next'
interface ExternalLinkMessage {
  title: string
  message: string
}

const getExternalLinkAlerts = (locationHash: string): ExternalLinkMessage | void => {
  switch (locationHash) {
    case '#eventEnded':
      return {
        title: i18n.t('deedScreen:thisEventHasEnded'),
        message: i18n.t('deedScreen:checkoutOtherDeeds'),
      }

    case '#notAttending':
      return {
        title: i18n.t('deedScreen:youHaveBeenInvited'),
        message: i18n.t('deedScreen:signUpBelowToContinue'),
      }

    case '#waitList':
      return {
        title: i18n.t('deedScreen:youHaveBeenInvited'),
        message: i18n.t('deedScreen:thisEventIsFull'),
      }

    case '#notStarted':
      return {
        title: i18n.t('deedScreen:thisEventHasNotStarted'),
        message: i18n.t('deedScreen:theEventLinkIsAvailableOnThisPage'),
      }
  }
}

export default getExternalLinkAlerts
