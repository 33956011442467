import React, { Dispatch, Fragment, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Box } from '@mui/material'

import DeedCancel from 'src/containers/modules/DeedCancel'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { resetFormStatusAction } from 'src/entities/deed/actions'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { State } from 'src/reducers'
import { Action, Button, LinkButton } from 'src/retired/elements'
import DonateButton from 'src/retired/shared/DonateButton'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { eventTypes } from './InfoBlock'

const CampaignDonateButtonBlock = ({
  small,
  disabled,
  deed,
  user,
  signUpLocation,
}: {
  small?: boolean
  disabled?: boolean
  deed: Deed
  user: User
  signUpLocation: string
}) => {
  const { t } = useTranslation('deedScreen')
  const { lite } = useParams<{ deed?: string; lite?: string }>()
  const Wrapper = small ? Action : Fragment
  const wrapperProps = small ? { fullScreen: true } : {}
  const donationText = t`donate`
  const style = { minWidth: small ? 0 : 250 }

  return deed.type === 'Campaign' && deed.isOngoing() ? (
    <Wrapper {...wrapperProps}>
      {user?.hasFeature('donations') || lite || deed.public ? (
        <DonateButton model={deed} fluid={!small} style={style} disabled={disabled}>
          {donationText}
        </DonateButton>
      ) : user ? (
        <Button fluid={!small} palette="primary" disabled>
          {donationText}
        </Button>
      ) : (
        <LinkButton fluid={!small} color="primary" to={signUpLocation} style={style}>
          {donationText}
        </LinkButton>
      )}
    </Wrapper>
  ) : null
}

export const ActionButtonsBlock = ({
  small,
  deed,
  user,
  signUpLocation,
  setShowGuestCheckoutModal,
  dispatch,
}: {
  small?: boolean
  deed: Deed
  user: User
  signUpLocation: string
  setShowGuestCheckoutModal: Dispatch<SetStateAction<boolean>>
  dispatch: () => void
}): JSX.Element | null => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()

  const isAuthenticated = useSelector((state: State) => selectIsAuthenticated(state))

  const Wrapper = small ? Action : Box
  const wrapperProps = small ? { fullScreen: true, style: { gap: '8px' } } : { style: { display: 'flex', gap: '8px' } }
  const style = { minWidth: small ? 0 : 250 }
  const isFull = deed.isFull()
  const isWaitlistFull = deed.isWaitlistFull()
  const isComplete = user?.hasCompletedDeed?.(deed.id)
  const isPastRegistrationDeadline = deed.registrationEndingAt && deed.registrationEndingAt < new Date()
  const hasNoSpots = isFull && isWaitlistFull
  const disabled = isPastRegistrationDeadline || deed.status !== 'published' || hasNoSpots
  const tooltip = hasNoSpots
    ? t`eventFull`
    : isPastRegistrationDeadline
    ? t('registrationClosedAfter', {
        date: { value: deed.registrationEndingAt, timeZone: deed.timeZone },
      })
    : undefined

  const isMultipleShiftsFeatureEnabledForEvent = user?.hasFeature('multipleShifts') && deed.type === 'Event'
  const hasUserAppliedForRole = user && deed.hasUserAppliedForRole(user.id)

  if (isComplete) {
    return null
  }

  if (deed.type === 'Campaign') {
    return (
      <CampaignDonateButtonBlock
        small={small}
        disabled={disabled}
        deed={deed}
        user={user}
        signUpLocation={signUpLocation}
      />
    )
  }

  if (deed.canUserLogHours(user)) {
    return (
      <Wrapper {...wrapperProps}>
        <LinkButton
          fluid={!small}
          to={`/profile/volunteer-hours/add/${deed.id}/deed`}
          style={{ backgroundColor: colors.gold }}
        >
          {t`logHours`}
        </LinkButton>
      </Wrapper>
    )
  }

  if (deed.isPast()) {
    return null
  }

  const renderCancelButton = () => {
    if (user && (deed.isUserAttending(user) || deed.isUserWaitlisted(user) || hasUserAppliedForRole)) {
      return <DeedCancel fluid={!small} deed={deed} user={user} style={style} />
    }
  }

  const getVolunteerCta = () => {
    const userAlreadySignedUp = user && deed.numberOfRolesUserApplied(user.id) > 0

    if (isFull && !isWaitlistFull) {
      return t`joinWaitlist`
    }
    if (deed.type === 'BaseEvent') {
      return t`register`
    }

    if (isMultipleShiftsFeatureEnabledForEvent && deed.roles?.size) {
      return userAlreadySignedUp ? t`signUpForMoreShifts` : t`signUpForShifts`
    }

    return t`imReadyToVolunteer`
  }

  const renderVolunteerButton = () => {
    const isCancelActive = user && (deed.isUserAttending(user) || deed.isUserWaitlisted(user) || hasUserAppliedForRole)
    if (
      (deed.roles.size > 0 && user && !deed.hasAnyRoleToApplyLeft(user.id)) ||
      (deed.roles.size === 0 && isCancelActive)
    ) {
      return null
    }

    const volunteerColor = eventTypes.includes(deed.type) ? 'tertiary' : 'primary'

    const volunteerCta = getVolunteerCta()

    if (isAuthenticated) {
      return (
        <LinkButton
          fluid={!small}
          disabled={disabled}
          to={signUpLocation}
          color={volunteerColor}
          style={style}
          title={tooltip}
          onPress={() => dispatch(resetFormStatusAction(deed))}
        >
          {volunteerCta}
        </LinkButton>
      )
    }
    return (
      <Button
        fluid={!small}
        style={style}
        disabled={disabled}
        title={tooltip}
        onPress={() => {
          setShowGuestCheckoutModal(true)

          // Set `expediteOnboarding` setting and store `previousLocation`, to return after signup/signin finishes
          dispatch(setLocalSettingAction('expediteOnboarding', true))
          if (window?.sessionStorage) {
            window.sessionStorage.setItem('expediteOnboarding', true)
          }
          dispatch(setLocalSettingAction('previousLocation', signUpLocation))
          if (window?.sessionStorage) {
            window.sessionStorage.setItem('previousLocation', signUpLocation)
          }
        }}
        color={volunteerColor}
      >
        {volunteerCta}
      </Button>
    )
  }

  if (isMultipleShiftsFeatureEnabledForEvent) {
    return (
      <Wrapper {...wrapperProps}>
        {renderCancelButton()}
        {renderVolunteerButton()}
      </Wrapper>
    )
  }

  if (user && (deed.isUserAttending(user) || deed.isUserWaitlisted(user) || hasUserAppliedForRole)) {
    return (
      <Wrapper {...wrapperProps}>
        <DeedCancel fluid={!small} deed={deed} user={user} style={style} />
      </Wrapper>
    )
  }

  return <Wrapper {...wrapperProps}>{renderVolunteerButton()}</Wrapper>
}
