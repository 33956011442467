import React from 'react'
import { View } from 'react-primitives'

import { metrics } from 'src/theme'
import { Touchable } from 'src/retired/elements'
import { H5 } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const CloseButton = ({ onClose }: { onClose: () => void }) => {
  const { colors } = useDeedTheme()
  return (
    <Touchable onPress={onClose} style={{ position: 'absolute', right: 0 }}>
      <View
        style={{
          width: 40,
          height: 40,
          borderRadius: 50,
          borderWidth: 1,
          borderColor: colors.grayMild,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.white,
        }}
      >
        <Icon icon="closeBlack" width={10} height={10} />
      </View>
    </Touchable>
  )
}

export const ContentWrapper = ({
  children,
  onClose,
  title,
}: {
  children: React.ReactNode
  onClose: () => void
  title?: string | JSX.Element
}): JSX.Element => {
  const { colors } = useDeedTheme()
  return (
    <View
      style={{
        minWidth: 150,
        minHeight: 200,
        padding: metrics.isLarge ? 36 : 20,
        backgroundColor: colors.white,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {title && <H5 style={{ width: '100%', textAlign: 'center' }}>{title}</H5>}
        {onClose && <CloseButton onClose={onClose} />}
      </View>
      {children}
    </View>
  )
}
