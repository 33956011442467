import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'src/navigation'

import InformativeScreen from './VolunteerReward/InformativeScreen'

const FormFilledScreen = () => {
  const { t } = useTranslation('formFilled')
  const history = useHistory()

  return (
    <InformativeScreen
      headerTitle={t`youAreAllGood`}
      headerContent={t`youHaveAlready`}
      content={t`whileYou`}
      buttonText={t`findYourNext`}
      buttonOnPress={() => history.push(`/search?tab=volunteer`)}
    />
  )
}

export default FormFilledScreen
