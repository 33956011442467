import { DonationProvider } from '@joindeed/calculate-fees'

const countriesNotSupportingPaypal = ['IN', 'TR']

interface OffPlatformDonationProps {
  userCountryCode?: string
  donationProvider?: DonationProvider | null
}

export const mustDonateOffPlatform = ({ userCountryCode, donationProvider }: OffPlatformDonationProps): boolean => {
  if (
    userCountryCode &&
    countriesNotSupportingPaypal.includes(userCountryCode) &&
    donationProvider === DonationProvider.PayPal
  ) {
    return true
  }

  return false
}
