import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('profileFriends') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectRelationRequestInProgress = createSelector(selectState, (state) =>
  state.get('relationRequestInProgress')
)

export const selectRelationAcceptInProgress = createSelector(selectState, (state) =>
  state.get('relationAcceptInProgress')
)

export const selectRelationRemovalInProgress = createSelector(selectState, (state) =>
  state.get('relationRemovalInProgress')
)

export const selectError = createSelector(selectState, (state) => state.get('error'))
