import { Record } from 'immutable'
import _ from 'lodash'

import Deed from '../deed/model'

const properties = {
  type: 'Relation',
  state: 'pending',
  createdAt: null,
  updatedAt: null,
  to: '',
  from: {},
  deed: null,
}

export default class Relation extends Record(properties, 'Relation') implements Relation {
  public readonly type!: string

  public readonly state!: string

  public readonly createdAt!: Date

  public readonly updatedAt!: Date

  public readonly to!: string

  public readonly from!: any

  public readonly deed!: Deed

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      createdAt: new Date(values.createdAt),
      updatedAt: new Date(values.updatedAt),
      deed: values.deed && new Deed(values.deed),
    })
  }
}
