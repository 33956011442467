import React from 'react'

import { Links, Spacing } from 'src/retired/elements'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface TextBlockProps {
  title: string
  children?: string
  wide?: boolean
}

export const TextBlock = ({ title, children, wide = true }: TextBlockProps): JSX.Element | null => {
  const { colors } = useDeedTheme()
  if (!children) {
    return null
  }

  return (
    <>
      <H5 weight="500">{title}</H5>
      <Links>
        <Body2 colour={colors.grayDark}>{children}</Body2>
      </Links>
      <Spacing marginBottom={wide ? 30 : 15} />
    </>
  )
}
