import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { Redirect } from 'src/navigation'
import { storeAction } from 'src/entities/auth/actions'

const SingleSignOnCallback = ({ location: { hash, search }, match, previousLocation, actions }) => {
  actions.storeAction(hash.substr(1), Boolean(match.params.new))
  const destination = search.indexOf('?d=') === 0 && search.substr(3)
  if (previousLocation) {
    actions.setLocalSettingAction('previousLocation', null)
  } else if (window?.sessionStorage) {
    // eslint-disable-next-line no-param-reassign
    previousLocation = window.sessionStorage.getItem('previousLocation')
    if (previousLocation) {
      window.sessionStorage.removeItem('previousLocation')
    }
  }
  return <Redirect to={destination || previousLocation || (match.params.new ? '/register/location' : '/home')} />
}

const withConnect = connect(
  (state) => ({
    previousLocation: selectLocalSetting(state, 'previousLocation'),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ storeAction, setLocalSettingAction }, dispatch),
  })
)
SingleSignOnCallback.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  previousLocation: PropTypes.string,
  actions: PropTypes.object.isRequired,
}

export default compose(withConnect)(SingleSignOnCallback)
