export const INIT = 'containers/Notifications/INIT'
export const INIT_SUCCESS = 'containers/Notifications/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Notifications/INIT_FAILED'

export const REFRESH = 'containers/Notifications/REFRESH'
export const REFRESH_SUCCESS = 'containers/Notifications/REFRESH_SUCCESS'
export const REFRESH_FAILED = 'containers/Notifications/REFRESH_FAILED'

export const RELATION_ACCEPT = 'containers/Notifications/RELATION_ACCEPT'
export const RELATION_ACCEPT_FAILED = 'containers/Notifications/RELATION_ACCEPT_FAILED'
export const RELATION_REJECT = 'containers/Notifications/RELATION_REJECT'
export const RELATION_REJECT_FAILED = 'containers/Notifications/RELATION_REJECT_FAILED'
export const RELATION_REQUEST_FINISHED = 'containers/Notifications/RELATION_REQUEST_FINISHED'

export const INVITATION_REJECT = 'containers/Notifications/INVITATION_REJECT'
export const INVITATION_REJECT_FAILED = 'containers/Notifications/INVITATION_REJECT_FAILED'
export const INVITATION_REQUEST_FINISHED = 'containers/Notifications/INVITATION_REQUEST_FINISHED'
