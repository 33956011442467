export const INIT = 'containers/Cause/INIT'
export const INIT_SUCCESS = 'containers/Cause/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Cause/INIT_FAILED'

export const FETCH_CAUSE_NONPROFITS = 'containers/Cause/FETCH_CAUSE_NONPROFITS'
export const FETCH_CAUSE_NONPROFITS_SUCCESS = 'containers/Cause/FETCH_CAUSE_NONPROFITS_SUCCESS'
export const FETCH_CAUSE_NONPROFITS_FAILED = 'containers/Cause/FETCH_CAUSE_NONPROFITS_FAILED'

export const FOLLOW = 'containers/Cause/FOLLOW'
export const FOLLOW_SUCCESS = 'containers/Cause/FOLLOW_SUCCESS'
export const FOLLOW_FAILED = 'containers/Cause/FOLLOW_FAILED'

export const FETCH_CAUSE_DEEDS_BY_DEED_TYPE = 'FETCH_CAUSE_DEEDS_BY_DEED_TYPE'
export const FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS = 'FETCH_CAUSE_DEEDS_BY_DEED_TYPE_SUCCESS'
export const FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED = 'FETCH_CAUSE_DEEDS_BY_DEED_TYPE_FAILED'
