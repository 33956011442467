import {
  PayPalHostedField,
  PayPalHostedFieldsProvider,
  PayPalScriptProvider,
  usePayPalHostedFields,
} from '@paypal/react-paypal-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentInputsWrapper } from 'react-payment-inputs'
import { View } from 'react-primitives'

import { Spacing } from 'src/retired/elements'
import { Body } from 'src/retired/shared/Typography'
import { css, styled } from 'src/theme/styled'

import { DonateButton } from '..'

interface HostedScriptOption {
  dataClientToken: string
  paypalClientId: string
}

export interface PayPalError {
  name: string
  details?: Array<{
    field?: string
    value?: string
    location?: string
    issue?: string
    description?: string
  }>
  message?: string
  links?: Array<{
    href?: string
    rel?: string
  }>
  debug_id: string
}

interface HostedPayPalContainerProps {
  onApprove: ({ orderId }: { orderId: string }) => Promise<void>
  onError?: (e: Error | PayPalError) => void
}

export const PayPalHostedFields = ({ onApprove, onError }: HostedPayPalContainerProps) => {
  const { t } = useTranslation('donateScreen')
  const [loadingFields, setLoadingFields] = useState(false)
  const { cardFields } = usePayPalHostedFields()

  const submitHandler = () => {
    if (typeof cardFields?.submit !== 'function') {
      return
    }
    setLoadingFields(true)
    cardFields
      ?.submit({ vault: true })
      .then((order) => {
        if (onApprove) {
          void onApprove.call(this, order)
        }
      })
      .catch((e: Error) => {
        setLoadingFields(false)
        if (onError) {
          onError.call(this, e)
        }
      })
  }

  return (
    <>
      <Body colour="gray01" style={{ marginLeft: 20 }}>
        {t`creditCard`}
      </Body>
      <Spacing marginBottom={12} />
      <PaymentInputsWrapper
        styles={{
          inputWrapper: {
            base: css`
              border-radius: 20px;
              font-family: 'GTWalsheimLC';
              padding: 0 11px;
              height: 40px;
              border-color: rgb(235, 238, 240);
              font-size: 12px;
              width: 360px;
            `,
          },
        }}
      >
        <PayPalHostedField
          style={{ height: 40, marginLeft: 10 }}
          id="card-number"
          hostedFieldType="number"
          options={{ selector: '#card-number', placeholder: t`cardNumber` }}
        />
        <PayPalHostedField
          style={{ height: 40, width: 68, flexGrow: 0, flexShrink: 0 }}
          id="expiration-date"
          hostedFieldType="expirationDate"
          options={{
            selector: '#expiration-date',
            placeholder: t`mmyyyy`,
          }}
        />
        <PayPalHostedField
          style={{ height: 40, width: 30, flexGrow: 0, flexShrink: 0 }}
          id="cvv"
          hostedFieldType="cvv"
          options={{ selector: '#cvv', placeholder: t`cvv` }}
        />
      </PaymentInputsWrapper>
      <Spacing />
      <DonateButton disabled={loadingFields} color="primary" size="small" onPress={submitHandler}>
        {t`completeDonation`}
      </DonateButton>
      {loadingFields && <DisabledOverlay />}
    </>
  )
}

const DisabledOverlay = styled(View)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 100000;
  cursor: not-allowed;
`

const NotEligible = () => <div>Your account is not eligible</div>

export const PayPalHostedFieldContainer = ({
  scriptOptions,
  createOrder,
  onApprove,
  onError,
}: HostedPayPalContainerProps & { scriptOptions: HostedScriptOption; createOrder: () => Promise<string> }) => {
  const initialOptions = {
    'client-id': scriptOptions.paypalClientId,
    'data-client-token': scriptOptions.dataClientToken,
    components: 'hosted-fields',
  }
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalHostedFieldsProvider notEligibleError={<NotEligible />} createOrder={createOrder}>
        <PayPalHostedFields onApprove={onApprove} onError={onError} />
      </PayPalHostedFieldsProvider>
    </PayPalScriptProvider>
  )
}
