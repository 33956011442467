import { createSelector } from 'reselect'

import { selectUpcomingDeeds } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'

export const selectCheckinDeeds = createSelector(
  selectUpcomingDeeds,
  selectCurrentUser,
  (state) => state.get('feedFilter'),
  (state, deedType) => deedType,
  (deeds, user) => {
    const checkInDeeds = [
      ...deeds
        .filter((deed) => !deed.virtual)
        .filter((deed) => deed.isDuringCheckInWindow())
        .filter((deed) => user && deed.isUserAttending(user))
        .values(),
    ]
    return checkInDeeds
  }
)
