import { Record } from 'immutable'
import _ from 'lodash'

const properties = {
  id: '',
  name: '',
  description: undefined,
  completed: null,
}

export default class Milestone extends Record(properties, 'Milestone') {
  public readonly id!: string

  public readonly name!: string

  public readonly description!: string

  public readonly completed!: Date

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      completed: values.completed && new Date(values.completed),
    })
  }
}
