import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'

interface SpacingProps {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  marginHorizontal?: number
  marginVertical?: number
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
  paddingHorizontal?: number
  paddingVertical?: number
  style?: any
  children?: React.ReactNode
}
export type SpacingElement = React.ReactElement<SpacingProps>

const Spacing: React.FC<SpacingProps> = ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  marginHorizontal,
  marginVertical,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingHorizontal,
  paddingVertical,
  style,
  children,
  ...props
}): SpacingElement => {
  const viewStyle = [
    marginHorizontal && {
      marginLeft: marginHorizontal,
      marginRight: marginHorizontal,
    },
    marginVertical && {
      marginTop: marginVertical,
      marginBottom: marginVertical,
    },
    marginTop && { marginTop },
    marginBottom && { marginBottom },
    marginLeft && { marginLeft },
    marginRight && { marginRight },
    paddingHorizontal && {
      paddingLeft: paddingHorizontal,
      paddingRight: paddingHorizontal,
    },
    paddingVertical && {
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical,
    },
    paddingTop && { paddingTop },
    paddingBottom && { paddingBottom },
    paddingLeft && { paddingLeft },
    paddingRight && { paddingRight },
    style,
  ]
  return (
    <View style={viewStyle} {...props}>
      {children}
    </View>
  )
}
Spacing.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginHorizontal: PropTypes.number,
  marginVertical: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number,
  style: PropTypes.any,
  children: PropTypes.node,
}

export default Spacing
