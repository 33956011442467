import { Observable } from 'rxjs'
import i18n from 'i18next'
import { ActionsObservable } from 'redux-observable'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'

import { PERSIST_LANGUAGE } from './constants'
import { PersistLanguageAction } from './actions'

const switchLanguage = (action$: ActionsObservable<PersistLanguageAction>) =>
  action$.ofType(PERSIST_LANGUAGE).exhaustMap((payload) =>
    UserApi.update({ locale: payload.locale })
      .mergeMap((resultingAction) => [resultingAction])
      .catch(() => Observable.of(showErrorAction(i18n.t`languageSwitcher:savingFailed`)))
  )

export default [switchLanguage]
