import { Map, fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'
import type Organization from 'src/entities/organization/model'
import type Donation from 'src/entities/donation/model'

import type { FetchedProviderData } from './actions'
import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SET_DONATING,
  DONATE,
  DONATE_SUCCESS,
  DONATE_FAILED,
  REGISTER_PAYPAL_DONATION,
  REGISTER_RECURRING_PAYPAL_DONATION,
  FETCH_PROVIDER_DATA,
  FETCH_PROVIDER_DATA_SUCCESS,
  FETCH_PROVIDER_DATA_FAILED,
  CREATE_CUSTOMER_ID,
  CREATE_CUSTOMER_ID_FAILED,
  CREATE_CUSTOMER_ID_SUCCESS,
  REGISTER_RESUME_RECURRING_PAYPAL_DONATION,
} from './actionNames'

export type ProviderDataMap = Map<string, FetchedProviderData>

interface MutableState {
  loading: boolean
  donationProviderLoading: boolean
  loadingError: null | unknown
  donating: boolean
  donationError: null | unknown
  providerData: ProviderDataMap
  lastDonation: null | Donation
  dataClientTokenLoading: boolean
  dataClientTokenError: boolean
  dataClientToken: string
  paymentToken: string
}

export type DonateState = TypedMap<MutableState>

export const initialState = fromJS({
  loading: true,
  donationProviderLoading: true,
  loadingError: null,
  donating: false,
  donationError: null,
  providerData: Map(),
  lastDonation: null,
  dataClientTokenLoading: false,
  dataClientTokenError: false,
  dataClientToken: '',
  paymentToken: '',
}) as DonateState

export default (
  state: DonateState = initialState,
  action: {
    type: string
    error?: object
    nonprofit?: Organization
    identifier?: string
    providerData?: FetchedProviderData
    donation?: Donation
    clientToken?: string
    paymentToken?: string
  }
): DonateState => {
  switch (action.type) {
    case INIT:
      return state.merge({
        loading: true,
        loadingError: null,
        donating: false,
        donationError: null,
      })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, loadingError: action.error })

    case REGISTER_PAYPAL_DONATION:
      return state.merge({ donating: true, donationError: null })

    case REGISTER_RECURRING_PAYPAL_DONATION:
      return state.merge({ donating: true, donationError: null })

    case REGISTER_RESUME_RECURRING_PAYPAL_DONATION:
      return state.merge({ donating: true, donationError: null })

    case DONATE:
      return state.merge({ donating: true, donationError: null })

    case SET_DONATING:
      return state.merge({ donating: true, donationError: null })

    case DONATE_SUCCESS:
      // @ts-expect-error hack
      if (action.donation?.donationScheduleId) {
        return state
      }
      return state.merge({ donating: false, lastDonation: action.donation })

    case DONATE_FAILED:
      return state.merge({ donating: false, donationError: action.error })

    case FETCH_PROVIDER_DATA:
      return state.merge({ donationProviderLoading: true })

    case FETCH_PROVIDER_DATA_SUCCESS:
      return state.merge({
        donationProviderLoading: false,
        providerData: state
          .get('providerData')
          .set(action.identifier as string, action.providerData as FetchedProviderData),
      })

    case FETCH_PROVIDER_DATA_FAILED:
      return state.merge({
        donationProviderLoading: false,
      })

    case CREATE_CUSTOMER_ID:
      return state.merge({ dataClientTokenLoading: true, dataClientTokenError: false })

    case CREATE_CUSTOMER_ID_FAILED:
      return state.merge({ dataClientTokenLoading: false, dataClientTokenError: true })

    case CREATE_CUSTOMER_ID_SUCCESS:
      return state.merge({
        dataClientTokenLoading: false,
        dataClientToken: action.clientToken,
        paymentToken: action.paymentToken,
        dataClientTokenError: false,
      })

    default:
      return state
  }
}
