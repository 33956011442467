import React from 'react'
import { useTranslation } from 'react-i18next'

import { Action } from 'src/containers/modules/Actions/types'

import { ActionModal, ActionModalType } from './ActionModal'

export const ConfirmCompletionModal = ({
  action,
  visible,
  buttonsDisabled = false,
  onClose,
  onPressPrimaryButton,
}: {
  action: Action
  visible: boolean
  buttonsDisabled?: boolean
  onClose: () => void
  onPressPrimaryButton: () => void
}) => {
  const { t } = useTranslation('actions')
  const title = t('confirmCompletion')
  const subTitle = t('haveYouCompleted', { action: action.title })

  return (
    <ActionModal
      type={ActionModalType.ConfirmCompletion}
      title={title}
      subTitle={subTitle}
      visible={visible}
      buttonsDisabled={buttonsDisabled}
      onClose={onClose}
      primaryButtonData={{ text: t('markAsCompleted'), onPress: onPressPrimaryButton }}
      secondaryButtonData={{ text: t('stillOnIt'), onPress: onClose }}
    />
  )
}
