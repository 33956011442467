import { List } from 'immutable'

import { ADD, ADD_MULTIPLE, REMOVE } from './constants'
import Relation from './model'

export type RelationList = List<Relation>
const initialState: RelationList = List()

export default (state: RelationList = initialState, action: any): RelationList => {
  switch (action.type) {
    case ADD:
      return state.push(action.relation)

    case ADD_MULTIPLE:
      return state.push(...action.relations)

    case REMOVE:
      return state.delete(
        state.findIndex((relation: Relation) => relation.from.id === action.id || relation.to === action.id)
      )

    case 'RESET':
      return initialState
  }
  return state
}
