import 'whatwg-fetch'

import { ApolloLink } from '@apollo/client'

import * as Sentry from 'src/utils/Sentry'

import metricsClient from './client'

const { trackApolloOperation } = metricsClient

export const metricsLink = new ApolloLink((operation, forward) => {
  try {
    trackApolloOperation(operation)
  } catch (error) {
    Sentry.captureException(error)
  }
  return forward(operation)
})
