import { fromJS, Set } from 'immutable'

import { ADD as ADD_USER } from 'src/entities/user/constants'

import { INIT, INIT_SUCCESS, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED, TOGGLE_SELECTED_SKILL } from './constants'

export const initialState = fromJS({
  submitting: false,
  error: null,
  selectedSkills: Set(),
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.set('error', null)

    case ADD_USER:
    case INIT_SUCCESS:
      if (!action.user || !action.user.me) {
        return state
      }
      return state.set('selectedSkills', new Set(action.user.skills))

    case SUBMIT:
      return state.merge({ submitting: true, error: null })

    case SUBMIT_SUCCESS:
      return initialState

    case SUBMIT_FAILED:
      return state.merge({ submitting: false, error: action.error })

    case TOGGLE_SELECTED_SKILL: {
      const selectedSkills = state.get('selectedSkills')
      return state.set(
        'selectedSkills',
        selectedSkills[selectedSkills.has(action.skill) ? 'delete' : 'add'](action.skill)
      )
    }

    case 'RESET':
      return initialState

    default:
      return state
  }
}
