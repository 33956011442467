import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import CardHorizontal from 'src/retired/shared/CardHorizontal'
import DonateButton from 'src/retired/shared/DonateButton'
import { selectCurrentUser } from 'src/entities/user/selectors'

const CardWrapper = styled.View`
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: #d8d8d8;
  padding: ${({ theme }) => (theme.metrics.isSmall ? '0 25px' : '0 15px')};
`

const NonprofitHorizontal = ({ nonprofit, ignoreCampaignParam = false }) => {
  const { t } = useTranslation('common')
  const user = useSelector(selectCurrentUser)

  return (
    <CardWrapper>
      <CardHorizontal
        link={`/organization/${nonprofit.id}`}
        line1={nonprofit.name}
        line2={nonprofit.locationObject?.toString(true)}
        matchingPercentage={nonprofit?.canDonate() && nonprofit?.matchingPercentage}
        cta={
          nonprofit?.canDonate() &&
          (!user || user?.hasFeature('donations')) && (
            <DonateButton model={nonprofit} size="extraSmall" ignoreCampaignParam={ignoreCampaignParam}>
              {t`donate`}
            </DonateButton>
          )
        }
      />
    </CardWrapper>
  )
}

NonprofitHorizontal.propTypes = {
  nonprofit: PropTypes.object.isRequired,
}

export default NonprofitHorizontal
