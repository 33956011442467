import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { selectCauses } from 'src/entities/cause/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import type User from 'src/entities/user/model'
import type { State } from 'src/reducers'
import { computeDonationProviderForNonprofit } from 'src/containers/screens/Donate/computeDonationProviderForNonprofit'

import Organization from './model'
import type { OrganizationState } from './reducer'

export const selectOrganizationState = (state: State): OrganizationState => state.get('entities').organization

export const selectOrganizations = createSelector(selectOrganizationState, (organizationState) =>
  organizationState.get('organizations').sort((a: Organization, b: Organization) => a.name.localeCompare(b.name))
)

export const selectOrganizationById = createSelector(
  selectOrganizations,
  (_state: State, organizationId: string | undefined) => organizationId,
  (organizations, organizationId) =>
    (organizationId &&
      (organizations.get(organizationId) ||
        (organizationId?.startsWith('ext-') &&
          organizations.find((organization: Organization) => organization.externalId === organizationId)) ||
        organizations.find((organization: Organization) => organization.nonprofitId === organizationId))) ||
    undefined
)

export const selectOrganizationsByCause = createSelector(
  selectOrganizations,
  selectCauses,
  (_state: State, _deedType: string, causeId: string) => causeId,
  (organizations, causes, causeId) => {
    const cause = causes.get(causeId)
    return organizations.filter((organization: Organization) => cause && organization.hasCause(cause))
  }
)

export const selectNonprofits = createSelector(selectOrganizations, (organizations) =>
  organizations.filter((organization: Organization) => organization.isNonprofit())
)

export const selectApprovedNonprofits = createSelector(selectNonprofits, (organizations) =>
  organizations.filter((nonprofit: Organization) => nonprofit.approved)
)

export const selectNonprofitsSearchResults = createSelector(
  selectNonprofits,
  selectCurrentUser,
  (_state: State, _user: User, searchTerm: string) => searchTerm,
  (_state, _user, _searchTerm, countryCode: string) => countryCode,
  (_state, _user, _searchTerm, _countryCode, acceptsDonations?: boolean) => acceptsDonations,
  (_state, _user, _searchTerm, _countryCode, _acceptsDonations, stateCode: string) => stateCode,
  (_state, _user, _searchTerm, _countryCode, _acceptsDonations, _stateCode, excludeDonationProviders) =>
    excludeDonationProviders,
  (organizations, user, searchTerm, countryCode, acceptsDonations, stateCode, excludeDonationProviders) => {
    if (!searchTerm) {
      return Map()
    }

    return organizations
      .filter((nonprofit: Organization) => {
        const donationProvider =
          nonprofit.donationProvider ||
          computeDonationProviderForNonprofit(nonprofit, Boolean(user?.organization?.settings?.preferStripe))

        return (
          nonprofit.matchForSearchTerm?.trim().toLowerCase() === searchTerm?.trim().toLowerCase() &&
          (!countryCode || nonprofit.locationObject?.countryCode === countryCode) &&
          (!stateCode || nonprofit.locationObject?.stateCode === stateCode) &&
          (acceptsDonations ? nonprofit.canDonate() : true) &&
          (!donationProvider || !excludeDonationProviders.includes(donationProvider))
        )
      })
      .sort((a: Organization, b: Organization) => b.searchTermMatchScore - a.searchTermMatchScore)
  }
)

export const selectDisplayedLocalOrganizations = createSelector(
  selectOrganizationState,
  selectOrganizations,
  (organizationState, nonprofits) =>
    organizationState.get('displayedLocalOrganizationIds').map((nonprofitId: string) => nonprofits.get(nonprofitId))
)

export const selectDisplayedPartnerOrganizations = createSelector(
  selectOrganizationState,
  selectOrganizations,
  (organizationState, nonprofits) =>
    organizationState.get('displayedPartnerOrganizationIds').map((nonprofitId: string) => nonprofits.get(nonprofitId))
)

export const selectCompanies = createSelector(selectOrganizations, (organizations) =>
  organizations.filter((organization: Organization) => organization.isCompany())
)

export const selectCompaniesWithSSO = createSelector(selectCompanies, (companies) =>
  companies.filter((company: Organization) => company.sso.provider || company.sso2.provider)
)

export const selectCompaniesWithoutSSO = createSelector(selectCompanies, (companies) =>
  companies.filter((company: Organization) => !company.sso.provider)
)

export const selectNonprofitsLoaded = createSelector(selectOrganizationState, (organizationState) =>
  organizationState.get('nonprofitsLoaded')
)

export const selectCompaniesLoaded = createSelector(selectOrganizationState, (organizationState) =>
  organizationState.get('companiesLoaded')
)

export const selectNonprofitsForCauseLoaded = createSelector(
  selectOrganizationState,
  (_state: State, id: string) => id,
  (organizationState, id) => organizationState.get('forCausesLoaded').includes(id)
)
