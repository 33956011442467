import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Image, Divider, LinkButton, Screen, Spacing, Text, AlignedImage } from 'src/retired/elements'
import { injectReducer } from 'src/utils'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { Redirect } from 'src/navigation'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { images } from 'src/theme'
import isDefaultAppOrigin from 'src/utils/isDefaultAppOrigin'

import * as Actions from './actions'
import reducer from './reducer'

// Shows a simple Login/Signup UI, used if you try to access the deed page.
// If you can access the deed page, it forwards you to it.
// If you can't, for vanity domains it forwards you to the relevant login route and stores the route where you initially came from
export const SimpleAuthenticate = ({ isAuthenticated, match, actions }) => {
  const { t } = useTranslation('deedScreen')
  const { metrics } = useDeedTheme()
  const deedId = match.params.deed
  const restricted = !!match.params.restricted
  if (!restricted) {
    actions.initAction(deedId)
  }
  if (isAuthenticated) {
    return <Redirect to={`/deeds/${deedId}${!restricted ? '/confirm' : ''}`} />
  }
  const previousLocation = `/deeds/${deedId}/authenticate${restricted ? '/restricted' : ''}`
  actions.setLocalSettingAction('previousLocation', previousLocation)
  if (window?.sessionStorage) {
    window.sessionStorage.setItem('previousLocation', previousLocation)
  }

  // If users are on a vanity domain, they most likely have SSO and therefore they should land directly on the SSO login screen which is the home "/" route
  if (!isDefaultAppOrigin(window.location.host)) {
    return <Redirect to="/" />
  }
  return (
    <Screen fixed>
      <Screen padding middle style={{ alignSelf: 'center', alignItems: 'center' }}>
        {metrics.isSmall ? (
          <AlignedImage source={images.logoGraphicBlack} width={80} height={48} style={{ marginBottom: 25 }} />
        ) : null}
        <View
          style={{
            alignSelf: 'stretch',
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: 10,
          }}
        >
          <Text size={24} lineHeight={24} bold center>
            {t`letsDoSomethingGoodToday`}{' '}
            <Image source={images.smileyBlush} style={{ width: 24, height: 24, verticalAlign: 'bottom' }} />
          </Text>
        </View>
        <Spacing marginBottom={20} />
        <LinkButton to="/register" color="primary">
          {t`createAnAccount`}
        </LinkButton>
        <Divider>{t`common:Or`}</Divider>
        <LinkButton to="/login">{t`signIn`}</LinkButton>
      </Screen>
    </Screen>
  )
}
SimpleAuthenticate.propTypes = {
  isAuthenticated: PropTypes.bool,
  match: PropTypes.object,
  actions: PropTypes.object,
}

const withConnect = connect(
  (state) => ({
    isAuthenticated: selectIsAuthenticated(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, setLocalSettingAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'authenticate', reducer })

export default compose(withReducer, withConnect)(SimpleAuthenticate)
