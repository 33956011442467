import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import hexRgb from 'hex-rgb'

import Icon from 'src/retired/elements/Icon'
import SVGIcon from 'src/retired/shared/Icon'
import Image from 'src/retired/elements/Image'
import Text from 'src/retired/elements/Text'
import { Link } from 'src/navigation'
import { colors } from 'src/theme'
import { formatFullName } from 'src/utils'

const getAvatarDimensions = (size: number) => ({
  borderRadius: size / 2,
  width: size,
  height: size,
})

const Avatar = ({
  user,
  link = true,
  size = 40,
  spacing = 10,
  style = {},
  icon,
  selected = false,
  credentials = false,
  iconImage,
  type = 'user',
}) => {
  const userName = formatFullName(user?.preferredName || user?.fullName) || user?.name

  const getLink = () => {
    if (type === 'organization') {
      return { pathname: `/organization/${user.id || user._id}` }
    }
    return { pathname: user.me ? '/profile' : `/profile/${user.id || user._id}`, state: { user } }
  }

  const picture = iconImage ? (
    <View
      style={[
        style,
        {
          ...getAvatarDimensions(size),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      ]}
    >
      <View style={{ borderBottomRadius: 10, overflow: 'hidden' }}>
        <SVGIcon icon={iconImage} width={20} height={30} />
      </View>
    </View>
  ) : (
    <Image
      source={{ uri: user?.mainPicture || 'https://files.godeed.today/profile.jpg' }}
      alt={userName}
      title={userName}
      style={[style, getAvatarDimensions(size)]}
    />
  )
  let avatar = (
    <View
      style={{
        borderRadius: size / 2,
        width: size,
        height: size,
        backgroundColor: style.backgroundColor || colors.gray03,
      }}
    >
      {picture}
    </View>
  )
  if (icon) {
    avatar = (
      <View style={{ position: 'relative', margin: 2 }}>
        {avatar}
        <View style={{ position: 'absolute', bottom: -2, right: -2 }}>
          <Icon icon={icon} width={16} height={16} />
        </View>
      </View>
    )
  }
  if (selected) {
    const blackRGB = hexRgb(colors.black)
    const backgroundColor = `rgba(${blackRGB.red}, ${blackRGB.green}, ${blackRGB.blue}, .6)`
    avatar = (
      <View style={{ position: 'relative' }}>
        {avatar}
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: size / 2,
          }}
        >
          {user && credentials ? (
            <Text size={23} color="#fff" title={userName}>
              {(user.preferredName || user.fullName).first.substr(0, 1).toUpperCase()}
              {(user.preferredName || user.fullName).last.substr(0, 1).toUpperCase()}
            </Text>
          ) : (
            <Icon icon="tickWhite" width={15} height={11} />
          )}
        </View>
      </View>
    )
  }
  return (user?.id || user?._id) && link ? (
    <Link to={getLink()} style={{ marginRight: spacing }} title={user && type === 'user' ? userName : undefined}>
      {avatar}
    </Link>
  ) : (
    <View style={{ marginRight: spacing }}>{avatar}</View>
  )
}
Avatar.propTypes = {
  user: PropTypes.object,
  link: PropTypes.bool,
  size: PropTypes.number,
  spacing: PropTypes.number,
  style: PropTypes.any,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  credentials: PropTypes.bool,
  iconImage: PropTypes.string,
}
export default Avatar
