import {
  FETCH_DEED,
  FETCH_DEED_SUCCESS,
  FETCH_DEED_FAILED,
  LOAD_VOLUNTEER_TIMES,
  LOAD_VOLUNTEER_TIMES_SUCCESS,
  LOAD_VOLUNTEER_TIMES_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  APPLY_FOR_ROLE,
  APPLY_FOR_ROLE_SUCCESS,
  APPLY_FOR_ROLE_FAILED,
  COMPLETE_DEED,
  COMPLETE_DEED_SUCCESS,
  COMPLETE_DEED_FAILED,
  APPLY_TO_EXTERNAL_DEED,
} from './constants'

export const fetchDeedAction = (id) => ({
  type: FETCH_DEED,
  id,
})
export const fetchDeedSuccessAction = (id) => ({
  type: FETCH_DEED_SUCCESS,
  id,
})
export const fetchDeedFailedAction = (error, errorCode) => ({
  type: FETCH_DEED_FAILED,
  error,
  errorCode,
})

export const loadVolunteerTimesAction = (deedId) => ({
  type: LOAD_VOLUNTEER_TIMES,
  deedId,
})
export const loadVolunteerTimesSuccessAction = () => ({
  type: LOAD_VOLUNTEER_TIMES_SUCCESS,
})
export const loadVolunteerTimesFailedAction = (error, errorCode) => ({
  type: LOAD_VOLUNTEER_TIMES_FAILED,
  error,
  errorCode,
})

export const refreshAction = () => ({
  type: REFRESH,
})

export const refreshSuccessAction = () => ({
  type: REFRESH_SUCCESS,
})

export const refreshFailedAction = (error) => ({
  type: REFRESH_FAILED,
  error,
})

export const applyForRoleAction = (deedId, roleId, userId) => ({
  type: APPLY_FOR_ROLE,
  deedId,
  roleId,
  userId,
})

export const applyForRoleSuccessAction = () => ({
  type: APPLY_FOR_ROLE_SUCCESS,
})

export const applyForRoleFailedAction = (error) => ({
  type: APPLY_FOR_ROLE_FAILED,
  error,
})

export const completeDeedAction = (id) => ({
  type: COMPLETE_DEED,
  id,
})

export const completeDeedSuccessAction = () => ({
  type: COMPLETE_DEED_SUCCESS,
})

export const completeDeedFailedAction = (error) => ({
  type: COMPLETE_DEED_FAILED,
  error,
})

export const applyToExternalDeedAction = (id) => ({
  type: APPLY_TO_EXTERNAL_DEED,
  id,
})
