import { Observable } from 'rxjs'
import { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import _ from 'lodash'

import { selectUserSubmittedDeedsLoaded } from 'src/entities/deed/selectors'
import DeedsApi from 'src/entities/deed/api'

import { INIT, InitAction, initFailedAction } from './types'

const init = (action$: ActionsObservable<InitAction>, store: Store) =>
  action$.ofType(INIT).mergeMap(() => {
    const actions = []
    const state = store.getState()

    if (!selectUserSubmittedDeedsLoaded(state)) {
      actions.push(DeedsApi.fetchSubmittedByUser())
    }

    if (actions.length === 0) {
      return []
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => _.flatten(resultingActions))
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
