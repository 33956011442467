import { isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { DayOptions, TimeOptions, allDays, allTimes } from '../GeneralSearch/utils'

const shouldFilterDay = (deedDate: Date | null, selectedDaysAndTimes: string[]) => {
  if (!deedDate) {
    return false
  }

  if (!selectedDaysAndTimes.some((selectedDayOrTime) => allDays.includes(selectedDayOrTime as DayOptions))) {
    return false
  }

  const allowMonday = selectedDaysAndTimes.includes(DayOptions.monday)
  const allowTuesday = selectedDaysAndTimes.includes(DayOptions.tuesday)
  const allowWednesday = selectedDaysAndTimes.includes(DayOptions.wednesday)
  const allowThursday = selectedDaysAndTimes.includes(DayOptions.thursday)
  const allowFriday = selectedDaysAndTimes.includes(DayOptions.friday)
  const allowSaturday = selectedDaysAndTimes.includes(DayOptions.saturday)
  const allowSunday = selectedDaysAndTimes.includes(DayOptions.saturday)

  if (
    (allowMonday && isMonday(deedDate)) ||
    (allowTuesday && isTuesday(deedDate)) ||
    (allowWednesday && isWednesday(deedDate)) ||
    (allowThursday && isThursday(deedDate)) ||
    (allowFriday && isFriday(deedDate)) ||
    (allowSaturday && isSaturday(deedDate)) ||
    (allowSunday && isSunday(deedDate))
  ) {
    return false
  }

  return true
}

const isHourMorning = (hour: number) => hour >= 2 && hour < 12
const isHourAfternoon = (hour: number) => hour >= 12 && hour < 18
const isHourEvening = (hour: number) => hour >= 18 || (hour >= 0 && hour < 2)

const shouldFilterTime = (deedDate: Date | null, deedTimezone: string, selectedDaysAndTimes: string[]) => {
  if (!deedDate) {
    return false
  }

  if (!selectedDaysAndTimes.some((selectedDayOrTime) => allTimes.includes(selectedDayOrTime as TimeOptions))) {
    return false
  }

  const allowMorning = selectedDaysAndTimes.includes(TimeOptions.morning)
  const allowAfternoon = selectedDaysAndTimes.includes(TimeOptions.afternoon)
  const allowEvening = selectedDaysAndTimes.includes(TimeOptions.evening)

  const startHour = Number(formatInTimeZone(deedDate, deedTimezone, 'H'))
  if (
    (allowMorning && isHourMorning(startHour)) ||
    (allowAfternoon && isHourAfternoon(startHour)) ||
    (allowEvening && isHourEvening(startHour))
  ) {
    return false
  }

  return true
}

export const shouldFilterDayOrTime = (deedDate: Date | null, deedTimezone: string, selectedDaysAndTimes: string[]) =>
  shouldFilterDay(deedDate, selectedDaysAndTimes) || shouldFilterTime(deedDate, deedTimezone, selectedDaysAndTimes)
