import { AnyAction } from 'redux'
import { Operation } from '@apollo/client'
import UAParser from 'ua-parser-js'

import config from 'src/config'

const userAgentParser = new UAParser()
const parsedUserAgent = userAgentParser.getResult()

// @ts-expect-error TS2339: Property 'screen' does not exist on type 'false | (Window & typeof globalThis)'.
const { devicePixelRatio: windowDpr, screen: windowScreen } = navigator.product !== 'ReactNative' && window

export const pageDefaults = {
  window: {
    height: navigator.product !== 'ReactNative' && window.innerHeight,
    width: navigator.product !== 'ReactNative' && window.innerWidth,
  },
  browser: parsedUserAgent.browser.name,
  // eslint-disable-next-line camelcase
  browser_version: parsedUserAgent.browser.version,
  environment: config.environment,
  ua: parsedUserAgent.ua,
}

export const getBaseFontSize = () =>
  navigator.product !== 'ReactNative' && getComputedStyle(document.documentElement).fontSize
export const baseFontSize = getBaseFontSize()

export const buildReduxActionEventDetails = (action: AnyAction, supplementaryDetails = {}) => ({
  label: action.type,
  ...supplementaryDetails,
  type: action.type,
  meta: action.meta,
  nonInteraction: 1,
  environment: config.environment,
})

export const buildApolloOperationEventDetails = (operation: Operation, supplementaryDetails = {}) => ({
  label: operation.operationName,
  ...supplementaryDetails,
  operationName: operation.operationName,
  operationType: operation.query ? 'query' : 'mutation',
  variables: operation.variables,
  nonInteraction: 1,
  environment: config.environment,
})

export const defaultContext = {
  app: {
    name: 'app',
  },
  device: {
    type: parsedUserAgent.device.type,
    model: parsedUserAgent.device.model,
    manufacturer: parsedUserAgent.device.vendor,
  },
  os: parsedUserAgent.os,
  screen: {
    density: windowDpr,
    height: windowScreen?.height,
    width: windowScreen?.width,
  },
}
