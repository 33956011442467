import React from 'react'

import CardList from 'src/retired/shared/CardList'
import CommunityCard from 'src/containers/screens/Community/CommunityCard'
import { CommunitiesQuery } from 'src/generated/graphql'

type Community = CommunitiesQuery['communities'][number]

interface CommunityCardListProps {
  communities: Community[]
  scrollViewOffset: number
  onPress?: (community: Community) => void
  small?: boolean
}
export type CommunityCardListElement = React.ReactElement<CommunityCardListProps>

const CommunityCardList: React.FC<CommunityCardListProps> = ({
  communities,
  scrollViewOffset,
  onPress,
  small = false,
}): CommunityCardListElement => (
  <CardList scrollViewOffset={scrollViewOffset} small={small}>
    {communities.map((community, index) => (
      <CommunityCard
        key={community.id}
        community={community}
        onPress={onPress ? () => onPress(community) : undefined}
        small={small}
        index={index}
      />
    ))}
  </CardList>
)

export default CommunityCardList
