import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import Platform from 'src/utils/Platform'
import { Action, Screen, Text, Button, Spacing } from 'src/retired/elements'
import DeviceInfo from 'src/utils/DeviceInfo'
import { Communications, injectEpics } from 'src/utils'
import { selectSetting } from 'src/entities/setting/selectors'

import * as Actions from './actions'
import epics from './epics'

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  },
})

const Update = ({ actions, minimumAppVersion }) => {
  const { t } = useTranslation('updateScreen')

  const [currentAppVersion, setCurrentAppVersion] = useState(0)

  useEffect(() => {
    actions.initAction()
  }, [])

  useEffect(() => {
    const fetchAppVersion = async () => {
      setCurrentAppVersion(await DeviceInfo.getBuildNumber())
    }

    fetchAppVersion()
  }, [])

  const updateApp = () => {
    const url =
      Platform.OS === 'ios'
        ? 'itms-apps://itunes.apple.com/us/app/deed-app/id1116827022?mt=8'
        : 'market://details?id=co.godeed'

    Communications.web(url)
  }

  if (Platform.OS === 'web') {
    return null
  }

  if (!minimumAppVersion || (currentAppVersion && minimumAppVersion <= currentAppVersion)) {
    return null
  }

  return (
    <Screen style={styles.root}>
      <Screen padding center middle root>
        <Text size={26} bold style={styles.titleStyle}>
          {t`updateDeedNow`}
        </Text>
        <Spacing marginBottom={25} />
        <Text size={24} center>
          {t`thisVersionIsNoLongerLive`}
        </Text>
      </Screen>
      <Action>
        <Button color="primary" onPress={updateApp}>
          {Platform.OS === 'ios' ? t`goToAppStore` : t`goToGooglePlay`}
        </Button>
      </Action>
    </Screen>
  )
}

const withConnect = connect(
  (state) => ({
    minimumAppVersion: selectSetting(state, `minimumAppVersion${Platform.OS === 'ios' ? '' : 'Android'}`),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withEpics = injectEpics({ key: 'update', epics })

export default compose(withEpics, withConnect)(Update)
