import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useActionsQuery } from 'src/generated/graphql'
import { ActionCard } from 'src/containers/modules/Actions/Card/ActionCard'
import { Button, ActivityIndicator } from 'src/retired/elements'
import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'

import { NUM_INITIAL_ACTIVITIES } from '../Actions/constants'

import { CampaignSectionHeader } from './CampaignSectionBlock/CampaignSectionBlock'

const NUMBER_INITIAL_ELEMENTS = 4

export const Container = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.grayMild};
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0 56px' : '0 20px')};
`

export const ContentWrapper = styled.View`
  padding: 20px 0 40px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: ${({ theme }: { theme: EmotionTheme }) => theme.metrics.isLarge && `${theme.metrics.maxWidthWide}px`};
  margin-top: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0' : '20px')};
`

export const CardsWrapper = styled.View`
  display: flex;
  flex-wrap: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'wrap' : 'nowrap')};
  flex-direction: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'row' : 'column')};
  padding-bottom: 30px;
  justify-content: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'flex-start' : 'center')};
`

export const CampaignActionsSection = ({ campaignId }: { campaignId: string }) => {
  const { metrics, colors } = useDeedTheme()
  const { t } = useTranslation('actions')
  const campaignI18n = useTranslation('campaignScreen')

  const {
    data: actionsData,
    error: actionsError,
    loading: actionsLoading,
  } = useActionsQuery({
    variables: {
      where: {
        campaignIds: { has: campaignId },
      },
      activityArgs: {
        take: NUM_INITIAL_ACTIVITIES,
      },
    },
  })

  const actions = actionsData?.actions || []

  const [numberShownActions, setNumberShownActions] = useState<number>(0)

  const showInitialActions = () => {
    setNumberShownActions(actions?.length > NUMBER_INITIAL_ELEMENTS ? NUMBER_INITIAL_ELEMENTS : actions.length)
  }

  useEffect(() => {
    if (!actionsLoading) {
      showInitialActions()
    }
  }, [actionsLoading])

  if (actionsError) {
    return null
  }

  if (!actionsLoading && !actions?.length) {
    return null
  }

  return (
    <Container>
      {actionsLoading ? (
        <View style={{ marginTop: -65 }}>
          <ActivityIndicator color={colors.userOrganizationBrandColor || colors.coral} size="large" />
        </View>
      ) : (
        <ContentWrapper>
          <CampaignSectionHeader title={t('actions')} subTitle={actions?.length} color={colors.black} />
          <CardsWrapper>
            {actions.slice(0, numberShownActions)?.map((action) => (
              <View key={action.id} style={{ paddingRight: metrics.isLarge ? 20 : 0, paddingVertical: 20 }}>
                <ActionCard action={action} campaignId={campaignId} hideCampaignTag />
              </View>
            ))}
          </CardsWrapper>

          {actions.length > NUMBER_INITIAL_ELEMENTS ? (
            <View style={{ alignItems: 'center' }}>
              <Button
                style={{ marginHorizontal: 'auto' }}
                onPress={() =>
                  numberShownActions < actions.length ? setNumberShownActions(actions.length) : showInitialActions()
                }
              >
                {numberShownActions < actions.length ? campaignI18n.t`viewMore` : campaignI18n.t`viewLess`}
              </Button>
            </View>
          ) : null}
        </ContentWrapper>
      )}
    </Container>
  )
}
