import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { Typography, Button } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import { useSelector } from 'react-redux'

import { styled } from 'src/theme/styled'
import { colors } from 'src/theme'
import { Platform } from 'src/utils'
import { CommunityUserRole, useCommunityMembersQuery } from 'src/generated/graphql'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Avatar, Row, TextField, Divider, ScrollView, ActivityIndicator, Checkbox } from 'src/retired/elements'
import { Body1, Label } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import { Modal, ModalButton, ModalContents, ModalTitle } from 'src/components/Modal/Modal'
import { BackButton } from 'src/components/Buttons/BackButton/BackButton'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { formatFullName } from 'src/utils/formatFullName'
import useDebounce from 'src/utils/useDebounce'

import { RemoveMembersModal } from './RemoveMembersModal/RemoveMembersModal'
import { AddMembersModal } from './AddMembersModal/AddMembersModal'

const PlusItem = styled.View<{ color: string }>`
  background-color: ${({ color }: { color: string }) => color};
  border-radius: 18px;
  height: 32px;
  padding: 8px 16px;
`

const initialMembersToDisplayCount = 20
const membersSearchResultToDisplayCount = 50

const CommunityMembers = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const {
    loading: communityMembersLoading,
    error: queryCommunityMembersError,
    data: communityMembersData,
    refetch: queryCommunityMembers,
  } = useCommunityMembersQuery({
    variables: {
      communityId: id,
      membersTake: initialMembersToDisplayCount,
    },
    notifyOnNetworkStatusChange: true,
  })

  const [addModalShown, setAddModalShown] = useState(false)
  const [removeModalShown, setRemoveModalShown] = useState(false)
  const [membersSelection, setMembersSelection] = useState<string[]>([])
  const toggleMember = (memberId: string) =>
    setMembersSelection(
      membersSelection.includes(memberId)
        ? membersSelection.filter((m) => m !== memberId)
        : [...membersSelection, memberId]
    )
  const [searchInput, setSearchInput] = useState('')
  const debouncedValue = useDebounce(searchInput, 500)
  const history = useHistory()
  const user = useSelector(selectCurrentUser)

  const communityRole = user?.getCommunityRole(id)
  const isLead = communityRole === CommunityUserRole.Lead && user?.hasFeature('communitiesSuperLeads')

  const onClickClose = () => {
    history.replace(`/community/${id}`)
  }

  const { t } = useTranslation('communityScreen')
  const { metrics } = useDeedTheme()

  const fetchCommunityMembers = useCallback((): void => {
    void queryCommunityMembers({
      communityId: id,
      membersSearch: debouncedValue,
      membersTake: debouncedValue ? membersSearchResultToDisplayCount : initialMembersToDisplayCount,
    })
  }, [id, debouncedValue, queryCommunityMembers])

  useEffect(() => {
    void fetchCommunityMembers()
  }, [debouncedValue, fetchCommunityMembers])

  const onChangeSearchInput = (_: InputEvent, text: string): void => {
    setSearchInput(text)
  }

  const members = communityMembersData?.community?.members

  if (addModalShown) {
    return (
      <AddMembersModal
        communityId={id}
        onClickClose={() => {
          setAddModalShown(false)
        }}
      />
    )
  }
  if (removeModalShown) {
    return (
      <RemoveMembersModal
        communityId={id}
        members={members?.filter((m) => membersSelection.includes(m.User.id)) || []}
        onClickClose={() => {
          setRemoveModalShown(false)
        }}
        removeMember={toggleMember}
      />
    )
  }
  return (
    <Modal open fullWidth onClose={onClickClose}>
      <ModalContents
        onClickClose={onClickClose}
        body={
          <View>
            <Row style={{ alignItems: 'center', marginBottom: 30, justifyContent: 'space-between' }}>
              <Row style={{ alignItems: 'center' }}>
                <BackButton onPress={onClickClose} />
                <ModalTitle style={{ marginBottom: 0, marginLeft: 12 }}>{t`communityMembers`}</ModalTitle>
              </Row>
              {isLead && (
                <Button color="primary" type="button" variant="text" onClick={() => setAddModalShown(true)}>
                  {t`add`} <PersonAddAltOutlinedIcon style={{ marginLeft: 5 }} />
                </Button>
              )}
            </Row>

            <View
              style={{
                borderRadius: 33,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.grayLighter,
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 15,
                height: Platform.OS === 'android' ? 52 : 42,
                width: '100%',
                marginBottom: 30,
              }}
            >
              <View style={{ marginRight: 10 }}>
                <Icon icon="searchNew" width={16} />
              </View>
              <TextField
                placeholder={metrics.screenWidth > 430 ? t`searchMembers` : t`search`}
                value={searchInput}
                onChangeText={onChangeSearchInput}
                autoCapitalize="none"
                autoFocus
              />
              <Touchable
                onPress={() => setSearchInput('')}
                accessibilityRole="button"
                accessibilityLabel={t`close`}
                focusable
              >
                <View style={{ paddingHorizontal: 15, paddingVertical: 13 }}>
                  <Icon icon="closeThick" width={14} height={14} />
                </View>
              </Touchable>
            </View>

            {isLead && (
              <Row style={{ alignItems: 'center', marginBottom: 16 }}>
                <Checkbox
                  color="primary"
                  checked={members?.length === membersSelection.length && members?.length > 0}
                  onChange={() => {
                    if (members?.length === membersSelection.length) {
                      setMembersSelection([])
                    } else {
                      setMembersSelection(members?.map((m) => m.User.id) || [])
                    }
                  }}
                  disabled={false}
                />
                <Typography variant="subtitle1">Name</Typography>
              </Row>
            )}

            <ScrollView
              style={{
                maxHeight: 'calc(100vh - 400px)',
                minHeight: 150,
                flex: 1,
                position: 'relative',
              }}
            >
              <View>
                {communityMembersLoading && <ActivityIndicator />}
                {queryCommunityMembersError && (
                  <View style={{ marginBottom: 16, alignItems: 'center' }}>
                    <Body1>{t`failedToSearch`}</Body1>
                  </View>
                )}
                {(members || []).map((member, i) => (
                  <View key={member.User.id}>
                    {i > 0 && <Divider marginTop={8} marginBottom={8} />}
                    <Row style={{ alignItems: 'center', flexGrow: 1, width: '100%' }}>
                      {isLead && (
                        <Checkbox
                          color="primary"
                          checked={membersSelection.includes(member.User.id)}
                          onChange={() => {
                            toggleMember(member.User.id)
                          }}
                        />
                      )}
                      <Row style={{ flexGrow: 1, alignItems: 'center' }}>
                        <Avatar user={member.User} style={{ borderWidth: 2, borderColor: colors.blue }} />
                        <Body1 style={{ marginLeft: 12 }}>
                          {formatFullName(member.User.preferredName || member.User.fullName)}
                        </Body1>
                      </Row>
                      {member.role === CommunityUserRole.Lead && (
                        <PlusItem color={colors.grayMild}>
                          <Label colour={colors.primary}>{t`communityLead`}</Label>
                        </PlusItem>
                      )}
                    </Row>
                  </View>
                ))}
                {debouncedValue && !communityMembersLoading && !members?.length && (
                  <Body1 style={{ width: '100%', textAlign: 'center' }}>{t`noResult`}</Body1>
                )}
              </View>
            </ScrollView>
          </View>
        }
        actions={
          isLead ? (
            <ModalButton
              variant="outlined"
              onClick={() => setRemoveModalShown(true)}
              disabled={membersSelection.length === 0}
            >
              {t`remove`} <DeleteOutlinedIcon style={{ marginLeft: 5 }} />
            </ModalButton>
          ) : undefined
        }
      />
    </Modal>
  )
}

export default CommunityMembers
