import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

export const MainContainer = styled(View)<object, EmotionTheme>(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
}))

export const InnerContainer = styled(View)<object, EmotionTheme>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
