import React from 'react'
import { useSelector, connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { useTranslation, Trans } from 'react-i18next'

import { Screen, Spacing, Text, Button, ActivityIndicator, Action } from 'src/retired/elements'
import { injectReducer, injectEpics } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { Redirect, useLocation } from 'src/navigation'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSubmitting, selectError } from './selectors'

const RegisterCompanyConfirm = ({ submitting, actions, user }) => {
  const { t } = useTranslation('companyRegister')
  const { state } = useLocation()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (user && user.status !== 'pending') {
    return <Redirect to="/register/location" />
  }

  if (!isAuthenticated && !state?.email) {
    return <Redirect to="/login" />
  }

  return (
    <Screen fixed>
      <Screen padding narrow middle action>
        <Text size={24} lineHeight={32} bold style={{ alignSelf: 'stretch' }}>
          {t`awesome`}
        </Text>
        <Spacing marginTop={25} marginBottom={25}>
          <Text size={24} lineHeight={32}>
            <Trans
              t={t}
              i18nKey="youAreAlmostReadyToDoSomeGood"
              values={{
                userEmail: user?.email || state?.email,
              }}
              components={{
                EmailAddress: <Text size={24} lineHeight={32} bold />,
              }}
            />
          </Text>
        </Spacing>
      </Screen>
      <Action>
        <Button onPress={actions.submitAction} color="light" fluid>
          {submitting ? <ActivityIndicator color="rgba(0, 0, 0, 0.2)" /> : t`common:Next`}
        </Button>
      </Action>
    </Screen>
  )
}

const withConnect = connect(
  (state) => ({
    submitting: selectSubmitting(state),
    error: selectError(state),
    user: selectCurrentUser(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerCompanyConfirm', reducer })
const withEpics = injectEpics({ key: 'registerCompanyConfirm', epics })

export default compose(withReducer, withEpics, withConnect)(RegisterCompanyConfirm)
