/**
 * Applies specified resize preset to an S3 url
 * Won't affect any other urls
 *
 * Allowed preset:
 * 192x192
 * 300x300
 * 1024x682
 * 1504x1002
 * 1920x1280
 *
 * Plus optional quality option (add @60) after dimensions.
 *
 * Lifted directly from https://github.com/joindeed/marketplace/blob/602db023803b9ad33e4bd6ee58478e019de41493/packages/marketplace-next/utils/resize.ts
 */
import { useDeedTheme } from 'src/theme/ThemeProvider'

type validSizes =
  | '192x192'
  | '300x300'
  | '960x540'
  | '1024x682'
  | '1200x1200'
  | '1200x630'
  | '1504x1002'
  | '1920x1080'
  | '1920x1280'

export const resize = (url: string, size: validSizes, quality?: number): string =>
  url?.replace(
    /https:\/\/(files|images).(.*\.?)(godeed.today|joindeed.org)\/((c?)((\d+)x(\d+)|(\d+)x(\d+)@(\d+))\/)?/g,
    `https://$1.$2$3/c${size}${quality ? `@${quality}` : ''}/`
  )

export const useBannerSize = (): validSizes => {
  const { metrics } = useDeedTheme()

  if (metrics.isExtraLarge) {
    return '1920x1280'
  }

  if (metrics.isMedium) {
    return '1200x1200'
  }

  if (metrics.isSmall) {
    return '960x540'
  }

  return '1504x1002'
}

export default resize
