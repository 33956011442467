export const ADD = 'entities/donation/ADD'
export const ADD_PLEDGE = 'entities/donation/ADD_PLEDGE'
export const ADD_MULTIPLE = 'entities/donation/ADD_MULTIPLE'
export const REMOVE = 'entities/donation/REMOVE'
export const CLEAR = 'entities/donation/CLEAR'
export const DONATIONS_FOR_USER_LOADED = 'entities/donation/DONATIONS_FOR_USER_LOADED'
export const DONATIONS_FOR_DEED_LOADED = 'entities/donation/DONATIONS_FOR_DEED_LOADED'
export const LOAD_DONATIONS_FOR_CAMPAIGN = 'containers/Campaign/LOAD_DONATIONS_FOR_CAMPAIGN'
export const DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS = 'containers/Campaign/DONATIONS_FOR_CAMPAIGN_LOADING_SUCCESS'
export const DONATIONS_FOR_CAMPAIGN_LOADING_FAILED = 'containers/Campaign/DONATIONS_FOR_CAMPAIGN_LOADING_FAILED'
