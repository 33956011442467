import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useCommunityQuery, useCurrentUserCommunityRoleQuery } from 'src/generated/graphql'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { resize, useBannerSize } from 'src/utils/resize'
import Layout from 'src/retired/blocks/Layout'
import { Image, Avatar, Row, Loading } from 'src/retired/elements'
import { Body2, H3, H4 } from 'src/retired/shared/Typography'
import Organization from 'src/entities/organization/model'
import Touchable from 'src/retired/shared/Touchable'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import NotFoundScreen from 'src/containers/screens/NotFound'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { Box } from 'src/components/Box/Box'
import { Chip } from 'src/components/Chip/Chip'
import { Container } from 'src/components/Container/Container'
import { Grid } from 'src/components/Grid/Grid'
import { RenderHtml } from 'src/components/RenderHtml'

import { TotalImpact } from './TotalImpact'
import { JoinLeaveCommunity } from './JoinLeaveCommunity'
import { CommunityDeeds } from './CommunityDeeds'
import { CommunityFeed } from './CommunityFeed/CommunityFeed'

const Community = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { metrics, colors } = useDeedTheme()
  const membersCountToDisplay = metrics.isSmall ? 4 : 8

  const {
    data: communityData,
    error: communityQueryError,
    loading: communityDataLoading,
    refetch: queryCommunity,
  } = useCommunityQuery({
    variables: {
      communityId: id,
      membersTake: membersCountToDisplay,
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: currentUserCommunityRole,
    loading: currentUserCommunityRoleLoading,
    refetch: queryCurrentUserCommunityRole,
  } = useCurrentUserCommunityRoleQuery({
    variables: {
      communityId: id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const isCurrentUserCommunityMember =
    typeof currentUserCommunityRole?.community?.myMembershipRole !== 'undefined'
      ? Boolean(currentUserCommunityRole?.community?.myMembershipRole)
      : undefined

  const { t } = useTranslation('communityScreen')
  const { pathname } = useLocation()
  const history = useHistory()

  const bannerSize = useBannerSize()

  if (communityQueryError) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  if (!communityData) {
    return <Loading />
  }

  if (communityData && !communityData.community) {
    return <NotFoundScreen />
  }
  const community = communityData?.community

  const company = community?.Company

  const nonprofits = community?.Nonprofits?.map((nonprofit) => new Organization(nonprofit)) || []

  const members = community?.members
  const membersCount = community?.membersCount

  const onClickAvatar = (): void => {
    history.push(`${pathname}/members`)
  }

  const image = community?.headerImage || community?.tileImage

  return (
    <Layout fullWidth withoutHeader keepTabMenu={metrics.isMedium}>
      {image ? (
        <Image
          source={{ uri: resize(image, bannerSize) }}
          style={{
            width: '100%',
            height: 300,
            resizeMode: 'cover',
            backgroundColor: '#000',
          }}
        />
      ) : (
        <View style={{ height: 30 }} />
      )}
      <Container>
        <Grid container rowSpacing={4} columnSpacing={8} columns={16}>
          <Grid item xs={16}>
            <Box
              sx={{
                my: { xs: 3, md: 6 },
                ml: 3,
                display: 'flex',
                gap: 3,
                flexDirection: { lg: 'row', xs: 'column' },
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ mb: 1, position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  <Box sx={{ position: 'absolute', right: '100%', pr: 1 }}>
                    <BackArrowButton backTo="/home" />
                  </Box>
                  <H3>{community?.title}</H3>
                </Box>

                <Body2 style={{ color: colors.grayMedium }}>
                  {t('companyCommunity', { companyName: company?.name })}
                </Body2>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: { xs: 'flex-start', lg: 'center' },
                }}
              >
                <Touchable onPress={onClickAvatar}>
                  <Row style={{ alignItems: 'center', flexGrow: 0 }}>
                    {members?.slice(0, membersCountToDisplay)?.map((member) => (
                      <Avatar
                        link={false}
                        key={member?.User.id}
                        user={member?.User}
                        style={{ borderWidth: 2, borderColor: colors.blue }}
                      />
                    ))}
                    {!!(members && membersCount && membersCount > members.length) && (
                      <Chip color="secondary" label={`+ ${membersCount - members.length}`} />
                    )}
                  </Row>
                </Touchable>

                {community && typeof isCurrentUserCommunityMember === 'boolean' && (
                  <JoinLeaveCommunity
                    communityId={community?.id}
                    communityTitle={community?.title || ''}
                    onComplete={() => {
                      void queryCurrentUserCommunityRole({ communityId: id })
                      void queryCommunity({
                        communityId: id,
                        membersTake: membersCountToDisplay,
                      })
                    }}
                    isCurrentUserCommunityMember={isCurrentUserCommunityMember}
                    isCommunityLoading={currentUserCommunityRoleLoading || communityDataLoading}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={16} lg={7}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
              {Boolean(community?.description) && (
                <Box mx={3}>
                  <H4 marginLeft={2} marginBottom={8} colour="black" style={{ display: 'block' }}>
                    {t`about`}
                  </H4>

                  <RenderHtml
                    customStyles={{ maxWidth: 700, color: colors.grayMedium, marginTop: 16 }}
                    source={{ html: community?.description || '' }}
                  />
                </Box>
              )}

              <TotalImpact
                communityTitle={community?.title || ''}
                nonprofits={nonprofits}
                totalDonations={community?.totalDonations || 0}
                totalVolunteering={community?.totalVolunteering || 0}
              />

              <CommunityDeeds communityId={id} />
            </Box>
          </Grid>
          <Grid item xs={16} lg={9}>
            <CommunityFeed communityId={id} readOnly={!isCurrentUserCommunityMember} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Community
