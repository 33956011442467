import Api from '../api'

import Relation from './model'
import { addAction, addMultipleAction, removeAction } from './actions'

export default class RelationApi {
  static fetch(type = 'pending') {
    return Api.get(`api/relations/${type}?bidirectional=1`).map((relations) =>
      addMultipleAction(relations.map((relation: any) => new Relation(relation)))
    )
  }

  static accept(fromUserId: string) {
    return Api.patch(`api/relations/${fromUserId}/accept`).map(() => removeAction(fromUserId))
  }

  static reject(fromUserId: string) {
    return Api.patch(`api/relations/${fromUserId}/reject`).map(() => removeAction(fromUserId))
  }

  static request(toUserId: string, deedId: string) {
    return Api.post(`api/relations/${toUserId}/request${deedId ? `/${deedId}` : ''}`).map(() =>
      addAction(new Relation({ to: toUserId }))
    )
  }

  static remove(toUserId: string) {
    return Api.delete(`api/relations/${toUserId}/remove`).map(() => removeAction(toUserId))
  }
}
