import { endOfMonth, subDays } from 'date-fns'

import Donation, { DonationType, PayrollApiDonation } from 'src/entities/donation/model'
import { usePayrollSettingsQuery } from 'src/generated/graphql'

export const isPayrollApiDonation = (donation?: Donation): donation is PayrollApiDonation =>
  donation?.__t === DonationType.PayrollDonation

export const useApiCancellationDeadline = (donation?: Donation) => {
  const skip = !isPayrollApiDonation(donation)
  const payrollSettingsQuery = usePayrollSettingsQuery({ skip }) // ?.data?.payrollSettings
  const payrollDonationsCancellation = payrollSettingsQuery?.data?.payrollSettings?.payrollDonationsCancellation

  if (
    !isPayrollApiDonation(donation) ||
    !payrollDonationsCancellation ||
    donation?.payrollStatus === 'Deducted' ||
    donation?.payrollStatus === 'Failure'
  ) {
    return null
  }

  return {
    loading: !skip && payrollSettingsQuery.loading,
    deadline: subDays(endOfMonth(new Date()), 5),
  }
}
