import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'

import { SmallOnly, MediumUp } from 'src/retired/elements/MediaQuery'
import { Button, Text } from 'src/retired/elements'
import { Platform, injectReducer } from 'src/utils'

import reducer from './reducer'
import * as Actions from './actions'
import { selectShowCookieConsent } from './selectors'

const styles = {
  container: {
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 50,
    backgroundColor: 'white',
    padding: 10,
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowOffset: { width: 2, height: 1 },
    shadowRadius: 4,
  },
  column: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  buttonText: {
    color: 'black',
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}

const CookieConsent = ({ showCookieConsent, actions }) => {
  const { t } = useTranslation('cookieConsent')
  if (!showCookieConsent || Platform.OS !== 'web') {
    return null
  }
  const weUseCookies = (
    <Text>
      <Trans
        t={t}
        i18nKey="weUseCookies"
        // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
        components={{ a: <a href="https://www.joindeed.com/privacy-policy.html" target="_blank" /> }}
      />
    </Text>
  )
  return (
    <>
      <SmallOnly>
        <View style={[styles.column, styles.container]}>
          {weUseCookies}
          <View style={styles.row}>
            <Button
              onPress={actions.acceptAction}
              color="transparent"
              size="small"
              style={styles.button}
              textStyle={styles.buttonText}
            >
              {t`accept`.toUpperCase()}
            </Button>
            <Button
              onPress={actions.declineAction}
              color="transparent"
              size="small"
              style={styles.button}
              textStyle={styles.buttonText}
            >
              {t`decline`.toUpperCase()}
            </Button>
          </View>
        </View>
      </SmallOnly>
      <MediumUp>
        <View style={[styles.row, styles.container]}>
          {weUseCookies}
          <View style={styles.row}>
            <Button
              onPress={actions.acceptAction}
              color="transparent"
              size="small"
              style={styles.button}
              textStyle={styles.buttonText}
            >
              {t`accept`.toUpperCase()}
            </Button>
            <Button
              onPress={actions.declineAction}
              color="transparent"
              size="small"
              style={styles.button}
              textStyle={styles.buttonText}
            >
              {t`decline`.toUpperCase()}
            </Button>
          </View>
        </View>
      </MediumUp>
    </>
  )
}

CookieConsent.propTypes = {
  actions: PropTypes.object,
  showCookieConsent: PropTypes.bool,
}

const withConnect = connect(
  (state) => ({
    showCookieConsent: selectShowCookieConsent(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'cookieConsent', reducer })

export default compose(withReducer, withConnect)(CookieConsent)
