import { Observable } from 'rxjs'
import { Action } from 'redux'
import { Map } from 'immutable'

import Api from '../api'

import Campaign from './model'
import { addMultipleAction, AddMultipleAction, loadedAction } from './actions'

export default class CampaignApi {
  public static fetchAll(): Observable<[AddMultipleAction, Action]> {
    return Api.get('api/campaigns').map((campaigns: any): [AddMultipleAction, Action] => [
      addMultipleAction(Map(campaigns.map((campaign: Campaign) => [campaign.id, new Campaign(campaign)]))),
      loadedAction(),
    ])
  }
}
