import React from 'react'
import { useTranslation } from 'react-i18next'

import { useBadgesQuery } from 'src/generated/graphql'
import User from 'src/entities/user/model'
import { ActivityIndicator } from 'src/retired/elements'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { Link } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { Section, SectionContent, SectionTitle, SectionLink } from '../../Profile.styled'
import { BadgesEarnedText } from '../BadgesEarnedText'
import { BadgeEmpty } from '../BadgeEmpty'

import { BadgesRow } from './BadgesRow'

export const BadgesSection = ({ user }: { user?: User }) => {
  const isBadgesFeatureEnabled = user?.hasFeature('badges')
  const { t } = useTranslation('badges')
  const { colors } = useDeedTheme()

  const useBadgesResponse = useBadgesQuery({
    skip: user && !isBadgesFeatureEnabled,
  })

  const { data: badgesData, error: badgesError, loading: badgesLoading } = useBadgesResponse

  if (!isBadgesFeatureEnabled || badgesError) {
    return null
  }

  const badges = badgesData?.badges
  const badgesWithAwards = badges?.filter((badge) => !!badge.awardedBadge) || []

  return (
    <Section>
      <SectionTitle>
        <H5>{t`myBadges`}</H5>
        {!badgesLoading && badges?.length ? (
          <BadgesEarnedText badgesWithAwardsLength={badgesWithAwards?.length} allBadgesLength={badges.length} />
        ) : null}
      </SectionTitle>
      <SectionContent>
        {badgesLoading ? (
          <ActivityIndicator />
        ) : badgesWithAwards?.length ? (
          <BadgesRow badges={badgesWithAwards} />
        ) : (
          <BadgeEmpty />
        )}
      </SectionContent>
      {!badgesLoading && !!badges?.length && (
        <SectionLink>
          <Link to="/profile/badges">
            <Body2 weight="500" colour={colors.brandColor}>{t`seeAllBadges`}</Body2>
          </Link>
        </SectionLink>
      )}
    </Section>
  )
}
