import { fromJS } from 'immutable'

import Deed from 'src/entities/deed/model'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  SET_STEP,
  SUBMIT_DEED,
  SUBMIT_DEED_SUCCESS,
  SUBMIT_DEED_FAILED,
  DELETE_DEED,
  DELETE_DEED_SUCCESS,
  DELETE_DEED_FAILED,
} from './constants'

export const initialState = fromJS({
  loading: false,
  loadingError: Error,
  step: 0,
  submitting: false,
  deleting: false,
  submittingError: null,
  deletingError: null,
  submittedDeed: null,
})

export default (state = initialState, action: { type: string; error?: Error; submittedDeed?: Deed; step?: number }) => {
  switch (action.type) {
    case INIT:
      return state.set('loading', true)

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, loadingError: action.error })

    case SET_STEP:
      return state.merge({ step: action.step })

    case SUBMIT_DEED:
      return state.merge({ submitting: true, submittingError: null })

    case SUBMIT_DEED_SUCCESS:
      return state.merge({ submitting: false, submittedDeed: action.submittedDeed })

    case SUBMIT_DEED_FAILED:
      return state.merge({ submitting: false, submittingError: action.error })

    case DELETE_DEED:
      return state.merge({ deleting: true, deletingError: null })

    case DELETE_DEED_SUCCESS:
      return state.merge({ deleting: false })

    case DELETE_DEED_FAILED:
      return state.merge({ deleting: false, deletingError: action.error })

    default:
      return state
  }
}
