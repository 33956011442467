import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { TextField, Spacing } from 'src/retired/elements'
import { Body } from 'src/retired/shared/Typography'
import { notEmpty, isEmail } from 'src/utils/validators'

const BASE_SPACING = 36

export interface Donor {
  firstName?: string
  lastName?: string
  email?: string
}

interface Errors {
  firstName?: string
  lastName?: string
  email?: string
}

interface DonorDetailsFormType {
  ssoPrompt: Function | null
  updateState: (donor: Donor) => void
}

const DonorDetailsForm = ({ updateState, ssoPrompt }: DonorDetailsFormType): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<Errors>({})

  useEffect(() => {
    if (notEmpty(firstName)) {
      errors.firstName = t`required`
    } else {
      if (notEmpty(lastName)) {
        errors.lastName = t`required`
      } else {
        if (notEmpty(email)) {
          errors.email = t`required`
        } else if (isEmail(email)) {
          errors.email = t`invalidEmailAddress`
        } else {
          delete errors.email
        }
        delete errors.lastName
      }
      delete errors.firstName
    }
    setErrors(errors)
    if (Object.keys(errors).length === 0) {
      updateState({ firstName, lastName, email })
    } else {
      updateState(undefined)
    }
  }, [firstName, lastName, email])

  useEffect(() => {
    if (ssoPrompt) {
      setFirstName('')
      setLastName('')
      setEmail('')
    }
  }, [ssoPrompt])

  return (
    <DonorDetailsContainer>
      <DonorFieldsContainer>
        <DonorFieldWrapper>
          <DonorFieldContainer>
            <TextField
              placeholder={t`firstName*`}
              defaultValue={firstName}
              onChangeText={(name: string, value: string) => setFirstName(value)}
              name="firstName"
              maxLength={50}
              value={firstName}
            />
          </DonorFieldContainer>
          {errors?.firstName && (
            <View>
              <Body colour="redDark" lineHeight={21} style={{ marginLeft: 20 }}>
                {errors.firstName}
              </Body>
              <Spacing marginBottom={BASE_SPACING / 4} />
            </View>
          )}
        </DonorFieldWrapper>

        <DonorFieldWrapper>
          <DonorFieldContainer>
            <TextField
              placeholder={t`lastName*`}
              onChangeText={(name: string, value: string) => setLastName(value)}
              defaultValue={lastName}
              name="lastName"
              maxLength={50}
              value={lastName}
            />
          </DonorFieldContainer>
          {errors?.lastName && (
            <View>
              <Body colour="redDark" lineHeight={21} style={{ marginLeft: 20 }}>
                {errors.lastName}
              </Body>
              <Spacing marginBottom={BASE_SPACING / 4} />
            </View>
          )}
        </DonorFieldWrapper>
      </DonorFieldsContainer>

      <DonorFieldsContainer>
        <DonorFieldWrapper>
          <DonorFieldContainer>
            <TextField
              placeholder={t`email*`}
              defaultValue={email}
              onChangeText={(name: string, value: string) => setEmail(value)}
              name="email"
              maxLength={100}
              value={email}
            />
          </DonorFieldContainer>
          {errors?.email && (
            <View>
              <Body colour="redDark" lineHeight={21} style={{ marginLeft: 20 }}>
                {errors.email}
              </Body>
              <Spacing marginBottom={BASE_SPACING / 4} />
            </View>
          )}
        </DonorFieldWrapper>
      </DonorFieldsContainer>
    </DonorDetailsContainer>
  )
}

const DonorDetailsContainer = styled(View)`
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
`

const DonorFieldsContainer = styled(View)`
  flex-direction: ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'column' : 'row')};
  justify-content: space-between;
  margin: 0 -5px;
`

const DonorFieldContainer = styled(View)`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin-bottom: 9px;
  padding-horizontal: 20px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
`

const DonorFieldWrapper = styled(View)`
  flex: ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'auto' : '1')};
`

export default DonorDetailsForm
