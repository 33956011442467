import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  AUTH,
  AUTH_SUCCESS,
  AUTH_FAILED,
  APPLE_LOGIN,
  APPLE_LOGIN_FAILED,
  APPLE_AUTH,
  APPLE_AUTH_SUCCESS,
  APPLE_AUTH_FAILED,
} from './constants'

export const initAction = (email: string, password: string) => ({
  type: INIT,
  email,
  password,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const authAction = (email, password) => ({
  type: AUTH,
  email,
  password,
})
export const authSuccessAction = () => ({
  type: AUTH_SUCCESS,
})
export const authFailedAction = (error, errorCode) => ({
  type: AUTH_FAILED,
  error,
  errorCode,
})

export const appleLoginAction = (appleToken) => ({
  type: APPLE_LOGIN,
  appleToken,
})
export const appleLoginFailedAction = (error) => ({
  type: APPLE_LOGIN_FAILED,
  error,
})

export const appleAuthAction = (appleToken) => ({
  type: APPLE_AUTH,
  appleToken,
})
export const appleAuthSuccessAction = () => ({
  type: APPLE_AUTH_SUCCESS,
})
export const appleAuthFailedAction = (error) => ({
  type: APPLE_AUTH_FAILED,
  error,
})
