import { fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'

import { INIT, INIT_SUCCESS, INIT_FAILED, DonationCompleteActions } from './types'

export type DonationCompleteState = TypedMap<{
  loading: boolean
  error: Error | null
}>

export const initialState = fromJS({
  loading: false,
  error: null,
}) as DonationCompleteState

export default (
  state: DonationCompleteState = initialState,
  action: DonationCompleteActions
): DonationCompleteState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    default:
      return state
  }
}
