import { fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  TOGGLE_SEARCH,
  SEARCH,
  SEARCH_NONPROFITS,
  SEARCH_DEEDS_SUCCESS,
  SEARCH_DEEDS_FAILED,
  SEARCH_NONPROFITS_SUCCESS,
  SEARCH_NONPROFITS_FAILED,
  RESET,
  SEARCH_PROGRESS,
  RESET_SEARCH_PROGRESS,
} from './constants'
import type {
  InitActionPayload,
  InitSuccessActionPayload,
  InitFailedActionPayload,
  ToggleSearchActionPayload,
  SearchActionPayload,
  SearchNonprofitsActionPayload,
  SearchDeedsSuccessActionPayload,
  SearchDeedsFailedActionPayload,
  SearchNonprofitsSuccessActionPayload,
  SearchNonprofitsFailedActionPayload,
  ResetActionPayload,
  SearchProgressActionPayload,
  ResetSearchProgressActionPayload,
} from './actions'

type SearchActions =
  | InitActionPayload
  | InitSuccessActionPayload
  | InitFailedActionPayload
  | ToggleSearchActionPayload
  | SearchActionPayload
  | SearchNonprofitsActionPayload
  | SearchDeedsSuccessActionPayload
  | SearchDeedsFailedActionPayload
  | SearchNonprofitsSuccessActionPayload
  | SearchNonprofitsFailedActionPayload
  | ResetActionPayload
  | SearchProgressActionPayload
  | ResetSearchProgressActionPayload

const searchProgressIncrements = 50

interface SearchState {
  loading: boolean
  loadingFailed: boolean
  searchVisible: boolean
  searchTerm: string
  countryCode?: string
  stateCode?: string
  searchingDeeds: boolean
  searchingDeedsFailed: boolean
  searchingNonprofits: boolean
  searchingNonprofitsFailed: boolean
  searchProgress: number
  nonprofitsOnly: boolean
}

export type TypedSearchState = TypedMap<SearchState>

export const initialState = fromJS({
  loading: true,
  loadingFailed: false,
  searchVisible: false,
  searchTerm: '',
  countryCode: undefined,
  stateCode: undefined,
  searchingDeeds: false,
  searchingDeedsFailed: false,
  searchingNonprofits: false,
  searchingNonprofitsFailed: false,
  searchProgress: 0,
  nonprofitsOnly: false,
}) as TypedSearchState

export default (state = initialState, action: SearchActions): TypedSearchState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loadingFailed: false })

    case INIT_SUCCESS:
      return state.merge({
        loading: false,
        loadingFailed: false,
        searchProgress:
          state.get('searchingNonprofits') || state.get('searchingDeeds')
            ? state.get('searchProgress') + searchProgressIncrements
            : 0,
      })

    case INIT_FAILED:
      return state.merge({ loading: false, loadingFailed: true })

    case TOGGLE_SEARCH: {
      return state.merge({
        searchVisible: action.params.show,
        countryCode: action.params.countryCode ?? state.get('countryCode'),
        stateCode: action.params.stateCode ?? state.get('stateCode'),
        nonprofitsOnly: action.params.nonprofitsOnly ?? state.get('nonprofitsOnly'),
        searchTerm: action.params.searchTerm || '',
      })
    }

    case SEARCH:
      return state.merge({
        searchTerm: action.searchTerm,
        countryCode: action.countryCode,
        searchingDeeds: action.searchTerm.length > 2,
        searchingNonprofits: action.searchTerm.length > 2,
        searchProgress: action.searchTerm.length > 2 && !state.get('loading') ? searchProgressIncrements : 0,
        searchingDeedsFailed: false,
        searchingNonprofitsFailed: false,
      })

    case SEARCH_NONPROFITS:
      return state.merge({
        searchTerm: action.searchTerm,
        countryCode: action.countryCode,
        stateCode: action.stateCode,
        searchingNonprofits: action.searchTerm.length > 2,
        searchProgress:
          action.searchTerm.length > 2 && !state.get('loading')
            ? state.get('nonprofitsOnly')
              ? searchProgressIncrements * 2
              : searchProgressIncrements
            : 0,
        searchingNonprofitsFailed: false,
      })

    case SEARCH_DEEDS_SUCCESS:
      return state.merge({ searchingDeeds: false, searchingDeedsFailed: false })

    case SEARCH_DEEDS_FAILED:
      return state.merge({ searchingDeeds: false, searchingDeedsFailed: true })

    case SEARCH_NONPROFITS_SUCCESS:
      return state.merge({ searchingNonprofits: false, searchingNonprofitsFailed: false })

    case SEARCH_NONPROFITS_FAILED:
      return state.merge({ searchingNonprofits: false, searchingNonprofitsFailed: true })

    case RESET:
      return initialState

    case SEARCH_PROGRESS:
      return state.set('searchProgress', state.get('searchProgress') + searchProgressIncrements)

    case RESET_SEARCH_PROGRESS:
      return state.set('searchProgress', 0)

    default:
      return state
  }
}
