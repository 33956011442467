import { Record } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

const properties = {
  id: undefined,
  isPledge: undefined,
  pledgedDonationStartingAt: undefined,
  pledgedDonationEndingAt: undefined,
  title: undefined,
  description: undefined,
}

export default class GivingPledgeCampaign
  extends Record(properties, 'GivingPledgeCampaign')
  implements GivingPledgeCampaign
{
  public readonly id!: string

  public readonly isPledge!: boolean

  public readonly pledgedDonationStartingAt!: Date

  public readonly pledgedDonationEndingAt!: Date

  public readonly title!: string

  public readonly description!: string

  constructor(values: Partial<GivingPledgeCampaign> = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    const { isPledge = false, pledgedDonationEndingAt, pledgedDonationStartingAt } = values

    super({
      ...validValues,
      isPledge,
      pledgedDonationEndingAt: isPledge ? new Date(pledgedDonationEndingAt!) : undefined,
      pledgedDonationStartingAt: isPledge ? new Date(pledgedDonationStartingAt!) : undefined,
    })
  }

  public isGivingPledgeCampaign() {
    const { pledgedDonationStartingAt, isPledge } = this

    if (!isPledge) {
      return false
    }

    if (!moment(pledgedDonationStartingAt).isValid()) {
      return false
    }

    return true
  }

  public isActivePledgeCampaign() {
    const isGivingPledgeCampaign = this.isGivingPledgeCampaign()

    if (!isGivingPledgeCampaign) {
      return false
    }

    const { pledgedDonationStartingAt, pledgedDonationEndingAt } = this

    if (!moment(pledgedDonationStartingAt).isValid()) {
      return false
    }

    if (moment(pledgedDonationEndingAt).isValid()) {
      return moment(moment.now()).isBefore(pledgedDonationEndingAt)
    }

    return moment(moment.now()).isBefore(pledgedDonationStartingAt)
  }
}
