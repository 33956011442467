import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { usePayrollTransmissionDate } from 'src/containers/screens/Donate/payroll/useIsPayrollEnabled'

import { DonationFrequency } from '../../constants'

export interface PayrollDeductedDisplayTextProps {
  donationFrequency: DonationFrequency
  donationDate: Date
}

export const PayrollDeductedDisplayText = ({
  donationFrequency,
  donationDate,
}: PayrollDeductedDisplayTextProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')

  const transmissionDate = usePayrollTransmissionDate(donationDate)

  const displayText = transmissionDate
    ? {
        whenRecurring: 'thisRecurringDonationWillBeNextTransmittedOnDate',
        whenOneTime: 'thisOneTimeDonationWillBeTransmittedOn',
        values: {
          date: t('date:dayMonthYearShort', {
            date: transmissionDate,
          }),
        },
      }
    : {
        whenRecurring: 'thisRecurringDonationWillBeDeductedNoDate',
        whenOneTime: 'thisOneTimeDonationWillBeDeductedNoDate',
        values: {},
      }

  return (
    <Trans
      t={t}
      i18nKey={donationFrequency === 'Monthly' ? displayText.whenRecurring : displayText.whenOneTime}
      values={displayText.values}
    />
  )
}

export default PayrollDeductedDisplayText
