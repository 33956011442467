import React, { useCallback, useState } from 'react'
import type {
  StreamClient,
  FeedAPIResponse,
  GetFeedOptions,
  ReactionFilterConditions,
  ReactionFilterAPIResponse,
} from 'getstream'
import { StreamApp } from 'react-activity-feed'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './styles/index.scss'

import { ActivityIndicator, Text } from 'src/retired/elements'
import config from 'src/config'
import {
  CommunityUserRole,
  useCommunityFeedCommentsQuery,
  useCommunityFeedQuery,
  useFeedDeleteActivityMutation,
  useFeedDeleteReactionMutation,
  useStreamTokenQuery,
} from 'src/generated/graphql'
import colors from 'src/theme/colors'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { AddActivityForm } from './AddActivityForm'
import { FlatFeedInner } from './FlatFeedInner'
import { StreamFeed } from './StreamFeed'

export const CommunityContext = React.createContext<{ readOnly: boolean; role: CommunityUserRole | null }>({
  readOnly: false,
  role: null,
})
interface CommunityFeedProps {
  communityId: string
  readOnly?: boolean
}

export const CommunityFeed = ({ communityId, readOnly }: CommunityFeedProps): JSX.Element => {
  const { data: streamTokenData, loading: streamTokenLoading, error: streamTokenError } = useStreamTokenQuery()
  const streamToken = streamTokenData?.streamToken

  const { t } = useTranslation('communityFeed')
  const [feedError, setFeedError] = useState(false)

  const currentUser = useSelector(selectCurrentUser)

  const { refetch: communityFeedQuery } = useCommunityFeedQuery({ variables: { communityId }, skip: true })

  const { refetch: communityFeedCommentsQuery } = useCommunityFeedCommentsQuery({ skip: true })

  const [feedDeleteActivityMutation] = useFeedDeleteActivityMutation()
  const [feedDeleteReactionMutation] = useFeedDeleteReactionMutation()

  const feedDeleteActivity = async (activityId: string): Promise<unknown> =>
    feedDeleteActivityMutation({ variables: { activityId, communityId } })

  const feedDeleteReaction = async (reactionId: string): Promise<unknown> =>
    feedDeleteReactionMutation({ variables: { reactionId, communityId } })

  const fetchFeedActivities = useCallback(
    async (
      _client: StreamClient,
      _feedGroup: string,
      _userId?: string,
      options?: GetFeedOptions
    ): Promise<FeedAPIResponse> => {
      try {
        const feedData = await communityFeedQuery({ communityId, id_lt: options?.id_lt })
        const feedString = feedData?.data?.community?.feed
        if (typeof feedString !== 'string') {
          throw new Error('Invalid response')
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const activities = JSON.parse(feedString) as FeedAPIResponse
        return activities
      } catch (e) {
        setFeedError(true)
        throw e
      }
    },
    [communityFeedQuery, communityId]
  )

  const fetchFeedComments = async ({
    activity_id,
    id_lt,
  }: ReactionFilterConditions): Promise<ReactionFilterAPIResponse> => {
    try {
      const commentsData = await communityFeedCommentsQuery({ communityId, activityId: activity_id, id_lt })
      const commentsString = commentsData?.data?.community?.comments
      if (typeof commentsString !== 'string') {
        throw new Error('Invalid response')
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(commentsString) as ReactionFilterAPIResponse
    } catch (e) {
      setFeedError(true)
      throw e
    }
  }

  const communityRole = currentUser?.getCommunityRole(communityId) || null

  if (streamTokenError || feedError) {
    return <Text size={30} color={colors.redDark}>{t`loadingError`}</Text>
  }

  if (streamTokenLoading || !streamToken) {
    return (
      <div style={{ marginTop: 48 }}>
        <ActivityIndicator />
      </div>
    )
  }
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CommunityContext.Provider value={{ readOnly: Boolean(readOnly), role: communityRole }}>
      <StreamApp apiKey={config.streamApiKey} appId={config.streamAppId} token={streamToken}>
        <StreamFeed
          feedGroup="community"
          userId={communityId}
          doFeedRequest={fetchFeedActivities}
          doActivityDeleteRequest={feedDeleteActivity}
          doReactionsFilterRequest={fetchFeedComments}
          doReactionDeleteRequest={feedDeleteReaction}
        >
          {readOnly ? null : <AddActivityForm communityId={communityId} />}
          <FlatFeedInner />
        </StreamFeed>
      </StreamApp>
    </CommunityContext.Provider>
  )
}
