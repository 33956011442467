import { Record } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

import { DonationScheduleStatus } from 'src/generated/graphql'
import { currencies, CurrencyCode } from 'src/containers/modules/CurrencyFormat'

import GivingPledgeCampaign from '../campaign/GivingPledgeCampaign'

const properties = {
  id: undefined,
  Campaign: undefined,
  frequency: undefined,
  startingAt: undefined,
  endingAt: undefined,
  createdAt: undefined,
  amount: undefined,
  status: undefined,
  currencyCode: undefined,
}

export enum GivingPledgeStatus {
  Inactive = 'inactive',
  Active = 'active',
  Completed = 'completed',
}

type Frequencies = 'PPC' | 'P1Y' | 'P1M' | 'P3M' | 'OneTime' | 'Payroll'

export default class GivingPledge extends Record(properties, 'GivingPledge') implements GivingPledge {
  public readonly id!: string

  public readonly Campaign?: GivingPledgeCampaign

  public readonly frequency?: Frequencies

  public readonly startingAt!: Date

  public readonly endingAt?: Date

  public readonly createdAt!: Date

  public readonly amount!: number

  public readonly status!: DonationScheduleStatus | null | undefined

  public readonly currencyCode!: CurrencyCode

  constructor(values: Partial<GivingPledge> = {}) {
    const validValues = _.pick(values, Object.keys(properties))

    const { id, startingAt, createdAt, endingAt, Campaign: ApoloCampaign } = values

    super({
      ...validValues,
      id,
      Campaign: ApoloCampaign && new GivingPledgeCampaign(ApoloCampaign),
      startingAt: new Date(startingAt as Date),
      createdAt: new Date(createdAt as Date),
      endingAt: endingAt && new Date(endingAt),
    })
  }

  public getCurrencySymbol(): string {
    return currencies?.[this.currencyCode?.toUpperCase()]?.symbol || '$'
  }

  public isCurrentlyPledged(): boolean {
    if (!this.Campaign) {
      return false
    }

    return this.Campaign.isActivePledgeCampaign()
  }

  public getPledgeStatus(): GivingPledgeStatus {
    const isActive = this.isCurrentlyPledged()

    const { status } = this

    if (status === DonationScheduleStatus.Inactive) {
      return GivingPledgeStatus.Inactive
    }

    if (isActive) {
      return GivingPledgeStatus.Active
    }

    return GivingPledgeStatus.Completed
  }

  public isRecurring() {
    const { endingAt } = this

    if (!endingAt) {
      return true
    }

    if (moment(moment.now()).isAfter(endingAt)) {
      return false
    }

    return true
  }
}
