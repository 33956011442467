interface CurrencyFormatData {
  groupSeparator: string
  decimalSeparator: string
  hasPrefix: boolean
}

const usFormat: CurrencyFormatData = {
  groupSeparator: ',',
  decimalSeparator: '.',
  hasPrefix: true,
}

// hasPrefix is currently not used. Just kept it if in the future we
// want to put the currency symbol behind the numbers.
const currencyFormats: { [key: string]: CurrencyFormatData } = {
  'en-US': usFormat,
  'es-ES': {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  pt: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: true,
  },
  fr: {
    groupSeparator: ' ',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  'fr-CA': {
    groupSeparator: ' ',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  it: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  de: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  ru: {
    groupSeparator: ' ',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  // in fact its this "₹1,23,456.78" formatting. But it would be tricky to get 2 digits group separator working.
  // So We just use US format
  hi: usFormat,
  ko: {
    groupSeparator: ',',
    decimalSeparator: '.',
    hasPrefix: true,
  },
  ja: {
    groupSeparator: ',',
    decimalSeparator: '.',
    hasPrefix: true,
  },
  'zh-CN': {
    groupSeparator: ',',
    decimalSeparator: '.',
    hasPrefix: true,
  },
  'zh-TW': {
    groupSeparator: ',',
    decimalSeparator: '.',
    hasPrefix: true,
  },
  nl: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: false,
  },
  da: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: true,
  },
  id: {
    groupSeparator: '.',
    decimalSeparator: ',',
    hasPrefix: true,
  },
}

export const getCurrencyFormatting = (locale: string | undefined): CurrencyFormatData => {
  if (locale) {
    return currencyFormats[locale] || usFormat
  }
  return usFormat
}
