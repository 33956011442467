import { fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'

import { Actions, CampaignsByNonprofitPayload, MatchEstimation } from './actions'
import {
  SUBMIT_EXTERNAL_DONATION,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  ESTIMATE_MATCH,
  ESTIMATE_MATCH_RULE_CHANGE,
  ESTIMATE_MATCH_SUCCESS,
  ESTIMATE_MATCH_FAILED,
  FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS,
  FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED,
} from './constants'

export type LogOffPlatformDonationState = TypedMap<{
  submitting: boolean
  submittingError: unknown | null
  estimating: boolean
  estimationError: unknown | null
  estimation: MatchEstimation | undefined
  campaignsLimited: CampaignsByNonprofitPayload[]
  campaignsError?: Error
}>

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const initialState: LogOffPlatformDonationState = fromJS({
  submitting: false,
  submittingError: null,
  estimating: false,
  estimationError: null,
  estimation: undefined,
  campaignsLimited: [],
  campaignsError: null,
})

export default (state: LogOffPlatformDonationState = initialState, action: Actions): LogOffPlatformDonationState => {
  switch (action.type) {
    case SUBMIT_EXTERNAL_DONATION:
      return state.merge({ submitting: true, submittingError: null })

    case SUBMIT_SUCCESS:
      return state.set('submitting', false)

    case SUBMIT_FAILED:
      return state.merge({ submitting: false, submittingError: action.error })

    case ESTIMATE_MATCH:
      return state.merge({ estimating: true, estimationError: null })

    case ESTIMATE_MATCH_SUCCESS:
    case ESTIMATE_MATCH_RULE_CHANGE:
      return state
        .merge({
          estimating: false,
          estimationError: null,
        })
        .set('estimation', action.estimation as MatchEstimation) // Prevent converting object to Immutable

    case ESTIMATE_MATCH_FAILED:
      return state.merge({ estimating: false, estimationError: action.error, estimation: undefined })

    case FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS:
      return state.merge({ campaignsLimited: action.campaignsLimited })

    case FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED:
      return state.merge({ campaignsError: action.error })

    default:
      return state
  }
}
