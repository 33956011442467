import i18n from 'i18next'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Organization from 'src/entities/organization/model'
import User from 'src/entities/user/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { ExternalLink } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useWebUrl } from 'src/utils/webUrl'

const NonprofitMatchingNote = (): JSX.Element => {
  const { colors } = useDeedTheme()
  const user = useSelector(selectCurrentUser)
  const { t } = useTranslation('data')
  return (
    <Trans
      t={t}
      i18nKey="beforeSubmittingAnOrganization"
      components={{
        ExternalLink: <ExternalLink color={colors.brandColor} href={user?.organization?.faqLink} />,
      }}
    />
  )
}

export interface EmailTemplate {
  type: string
  note: JSX.Element
  title: string
  body: string
}

const useEmailTemplates = (
  _brandColor: string,
  user?: User,
  nonprofit?: Organization,
  deedType?: string
): {
  'nominate-nonprofit-for-matching': EmailTemplate
  'create-deed': EmailTemplate
} => {
  const webUrl = useWebUrl()
  return {
    'nominate-nonprofit-for-matching': {
      type: 'nominateNonprofitForMatching',
      title: i18n.t('data:nominateAnOrganizationForMatching'),
      note: (
        <>
          {i18n.t('data:wantToNominateAnOrganization')} {user?.organization?.faqLink ? <NonprofitMatchingNote /> : ''}
        </>
      ),
      body: nonprofit
        ? i18n.t('data:nominateOrganizationMatchingEmailForm_organization', {
            nonprofitName: nonprofit.name,
            ein: nonprofit.ein,
            nonprofitWebsite: nonprofit.website,
            nonprofitDeedProfile: nonprofit.id ? `${webUrl}/organization/${nonprofit.id}` : '',
          })
        : i18n.t('data:nominateOrganizationMatchingEmailForm'),
    },
    'create-deed': {
      type: 'createDeed',
      title: i18n.t('data:createADeed'),
      note: i18n.t('data:pleaseAnswerTheFollowingQuestions'),
      body: i18n.t('data:createDeedEmailForm', {
        userName: user?.fullName ? `${user.fullName.first} ${user.fullName.last}` : '',
        deedType: deedType ? `: ${deedType}` : ` ${i18n.t('data:fundraiserOrVolunteerOpportunity')}`,
      }),
    },
  }
}

export default useEmailTemplates
