import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { CookieConsentState, initialState } from './reducer'

const selectState = (state: State): CookieConsentState => state.get('cookieConsent') || initialState

export const selectCookieConsentSeen = createSelector(selectState, (state) => state.get('hasResponded'))

export const selectCookieConsentResponse = createSelector(selectState, (state) => state.get('hasAcceptedCookies'))

export const selectShowCookieConsent = createSelector(selectState, (state) => state.get('showCookieConsent'))
