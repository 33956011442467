import { fromJS } from 'immutable'

import { INIT, INIT_FAILED } from './types'

export const initialState = fromJS({ error: null })

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ error: null })

    case INIT_FAILED:
      return state.merge({ error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
