import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Touchable from 'src/retired/shared/Touchable'
import { Text } from 'src/retired/shared/Typography'
import Icon from 'src/retired/shared/Icon'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { TabType } from '../utils'

interface StyledTouchableProps {
  isActive: boolean
  tabSize: number
}
const StyledTouchable = styled(Touchable)<StyledTouchableProps>(
  ({ isActive, theme: { colors } }: StyledTouchableProps & { theme: EmotionTheme }) => ({
    flexDirection: 'column',
    flex: 1,
    padding: 8,
    alignItems: 'center',
    borderStyle: 'solid',
    borderBottomColor: isActive ? colors.black : colors.lightBorderGray,
    borderBottomWidth: isActive ? 3 : 1,
  })
)

const getIconNameByTabType = (tabType: TabType) => {
  if (tabType === TabType.donate) {
    return 'heartWhiteOutlined'
  } else if (tabType === TabType.volunteer) {
    return 'volunteerPerson'
  }
  return 'ticket'
}

export const Tab = ({
  tabType,
  isActive,
  tabSize,
  onPress,
}: {
  tabType: TabType
  isActive: boolean
  tabSize: number
  onPress: () => void
}) => {
  const { t } = useTranslation('searchScreen')
  const { colors } = useDeedTheme()

  const user = useSelector(selectCurrentUser)
  const isDonationFeatureEnabled = user?.hasFeature('donations')
  const tabText = !isDonationFeatureEnabled && tabType === TabType.donate ? 'organizations' : tabType

  return (
    <StyledTouchable onPress={onPress} key={tabType} isActive={isActive} tabSize={tabSize}>
      <Icon
        icon={getIconNameByTabType(tabType)}
        hexColor={isActive ? colors.black : colors.gray}
        width={20}
        height={20}
      />
      <Text style={{ paddingTop: 10, fontSize: 12 }} colour={isActive ? colors.black : colors.gray}>
        {t(tabText).toUpperCase()}
      </Text>
    </StyledTouchable>
  )
}
