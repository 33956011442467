/* eslint-disable @typescript-eslint/no-shadow */
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import { debounce } from 'lodash'

import User from 'src/entities/user/model'
import validators from 'src/utils/validators'

export interface PasswordRequirement {
  message: string
  parameters?: any
  validator: (password: string, userInputs?: string[]) => boolean
}

export const MINIMUM_PASSWORD_LENGTH = 10
export const MAXIMUM_PASSWORD_LENGTH = 64
export const MINIMUM_PASSWORD_SCORE = 3

/* Returns true if it's valid */
export const getPasswordRequirements: (password: string, userInputs?: string[]) => PasswordRequirement[] = (
  password,
  userInputs
) => [
  {
    message: '',
    validator: (password: string) => !validators.notEmpty(password),
  },
  {
    message: 'atLeastXChar',
    parameters: { amount: MINIMUM_PASSWORD_LENGTH },
    validator: (password: string) => !validators.minLength(MINIMUM_PASSWORD_LENGTH)(password),
  },
  {
    message: 'maximumXChar',
    parameters: { amount: MAXIMUM_PASSWORD_LENGTH },
    validator: (password: string) => !validators.maxLength(MAXIMUM_PASSWORD_LENGTH)(password),
  },
  {
    message: 'oneUppercase',
    validator: (password: string) => /(?=.*[A-Z])/.test(password),
  },
  {
    message: 'oneLowercase',
    validator: (password: string) => /(?=.*[a-z])/.test(password),
  },
  {
    message: 'oneNumber',
    validator: (password: string) => /(?=.*\d)/.test(password),
  },
  {
    message: 'oneSpecialChar',
    validator: (password: string) => /(?=.*[^a-zA-Z0-9\u00C0-\u00FF])/.test(password),
  },
  {
    message: 'personalInformation',
    validator: (password: string, userInputs = []) =>
      !userInputs.filter(Boolean).find((input) => {
        /* Remove accents from strings: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript */
        const lowerCasePassword = password
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
        const cleanInput = input
          .replace(/[\s-']/g, '')
          .toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')

        return (
          cleanInput?.length >= 3 && (lowerCasePassword.includes(cleanInput) || cleanInput.includes(lowerCasePassword))
        )
      }),
  },
  ((password: string, userInputs = []) => {
    const passwordStrength = getPasswordStrength(password, userInputs)
    const isStrongEnough = passwordStrength.score >= MINIMUM_PASSWORD_SCORE

    return {
      message: passwordStrength.feedback.warning ?? '',
      validator: () => isStrongEnough,
    }
  })(password, userInputs),
]

export const getUserInputs = ({ currentUser, formValues }: { currentUser?: User; formValues: Record<string, any> }) =>
  [
    formValues?.email,
    formValues?.firstName,
    formValues?.lastName,
    formValues?.fullName?.first,
    formValues?.fullName?.last,
    currentUser?.fullName?.first,
    currentUser?.fullName?.last,
    currentUser?.email,
    currentUser?.organization?.name,
  ].filter(Boolean)

export const getPasswordStrength = (password: string, userInputs: string[] = []) => {
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
      userInputs: userInputs.filter(Boolean),
    },
  }
  zxcvbnOptions.setOptions(options)

  return zxcvbn(password)
}

/* Returns a String if there is something wrong */
const validatePasswordActual = (
  password: string,
  userInputs: string[] = [],
  passwordRequirements = getPasswordRequirements
) => passwordRequirements(password, userInputs).find(({ validator }) => !validator(password, userInputs))?.message

export const validatePassword = debounce(validatePasswordActual, 300)
