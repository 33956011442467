import React, { useRef, useState, FormEvent, useEffect, useContext } from 'react'
import classNames from 'classnames'
import { EnrichedActivity, Activity } from 'getstream'
import type { Data as EmojiDataSet } from 'emoji-mart'
import Send from '@mui/icons-material/Send'
import { Avatar, Textarea, useFeedContext } from 'react-activity-feed'
import type { DefaultAT, DefaultUT, TextareaProps } from 'react-activity-feed'
import { inputValueFromEvent } from 'react-activity-feed/dist/utils'
import type { PropsWithElementAttributes } from 'react-activity-feed/dist/utils'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'

import { CommunityContext } from './CommunityFeed'

export type CommentFieldProps<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT
> = PropsWithElementAttributes<
  {
    activity: EnrichedActivity<UT, AT>
    /** Override the default emoji dataset, library has a light set of emojis
     * to show more emojis use your own or emoji-mart sets
     * https://github.com/missive/emoji-mart#datasets
     */
    emojiData?: EmojiDataSet
    image?: string
    onSuccess?: () => void
    targetFeeds?: string[]
    trigger?: TextareaProps['trigger']
  },
  HTMLFormElement
>

export const CommentField = <UT extends DefaultUT = DefaultUT, AT extends DefaultAT = DefaultAT>({
  activity,
  emojiData,
  onSuccess,
  image,
  trigger,
  targetFeeds,
  className,
  style,
}: CommentFieldProps<UT, AT>): JSX.Element | null => {
  const feed = useFeedContext<UT, AT>()
  const { t } = useTranslation('communityFeed')
  const textareaReference = useRef<HTMLTextAreaElement>()
  const [text, setText] = useState<string>()
  const { metrics } = useDeedTheme()

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement> | KeyboardEvent): Promise<void> => {
    event.preventDefault()

    if (!text) {
      return
    }

    try {
      await feed.onAddReaction('comment', activity as Activity<AT>, { text }, { targetFeeds })
    } catch (error) {
      console.error(error)
    }

    setText('')
    onSuccess?.()
  }

  useEffect(() => {
    if (!textareaReference.current) {
      return
    }

    const handleFormSubmitKey = (event: KeyboardEvent): void => {
      const { current: textarea } = textareaReference
      if (event.key === 'Enter' && textarea?.nextSibling === null) {
        void handleFormSubmit(event)
      }
    }

    textareaReference.current.addEventListener('keydown', handleFormSubmitKey)

    return () => textareaReference.current?.removeEventListener('keydown', handleFormSubmitKey)
  }, [])

  const readOnly = useContext(CommunityContext)?.readOnly

  if (readOnly) {
    return null
  }

  return (
    <form
      onSubmit={(event: FormEvent<HTMLFormElement> | KeyboardEvent) => {
        void handleFormSubmit(event)
      }}
      className={classNames('raf-comment-field', className)}
      style={style}
    >
      {image && <Avatar image={image} circle size={metrics.screenWidth >= 640 ? 56 : 40} />}
      <div className="raf-comment-field__group">
        <Textarea
          rows={1}
          value={text}
          placeholder={t`writeAComment`}
          onChange={(event) => setText((pv) => inputValueFromEvent<HTMLTextAreaElement>(event, true) ?? pv)}
          emojiData={emojiData}
          trigger={trigger}
          maxLength={280}
          // eslint-disable-next-line no-return-assign
          innerRef={(element) => (textareaReference.current = element)}
        />
        <Button title={t`post`} color="primary" variant="text" type="submit" style={{ padding: 0 }}>
          <Send />
        </Button>
      </div>
    </form>
  )
}
