import { Record } from 'immutable'
import _ from 'lodash'

interface Deed {
  id: string
  name: string
  startingAt: Date
  endingAt: Date
}

const properties = {
  id: '',
  deed: undefined,
  status: '',
  duration: 0,
  user: '',
  requestedAt: undefined,
  startingAt: undefined,
  endingAt: undefined,
}

export default class VolunteerTimeOff extends Record(properties, 'VolunteerTimeOff') implements VolunteerTimeOff {
  public readonly id!: string

  public readonly deed!: Deed

  public readonly status!: 'REQUESTED' | 'APPROVED'

  public readonly duration!: number

  public readonly requestedAt!: Date

  public readonly startingAt!: Date

  public readonly endingAt!: Date

  public readonly user!: string

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    // @FIXME-AS: this is temporary solution until we define new data modeling
    const deed = values.deed || values.Deed
    super({
      ...validValues,
      id: values._id || values.id,
      deed: deed
        ? {
            ...deed,
            // @FIXME-AS: this is temporary solution until we define new data modeling
            id: deed._id || deed.id,
          }
        : undefined,
      requestedAt: new Date(values.requestedAt),
      startingAt: new Date(values.startingAt),
      endingAt: new Date(values.endingAt),
    })
  }
}
