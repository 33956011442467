import { Map } from 'immutable'

import { ADD, ADD_MULTIPLE } from './constants'
import VolunteerTimeOff from './model'

export type VolunteerTimeOffMap = Map<string, VolunteerTimeOff>

export const initialState: VolunteerTimeOffMap = Map()

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: VolunteerTimeOffMap = initialState, action: any): VolunteerTimeOffMap => {
  switch (action.type) {
    case ADD:
      return state.set(action.volunteerTimeOff.id, action.volunteerTimeOff)

    case ADD_MULTIPLE:
      return state.merge(action.volunteerTimesOff)
  }
  return state
}
