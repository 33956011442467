import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View, StyleSheet } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import config from 'src/config'
import {
  ActivityIndicator,
  Alert,
  Button,
  Form,
  Screen,
  Spacing,
  Text,
  TextField,
  ScrollView,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators, matchCompanySso } from 'src/utils'
import { colors, metrics } from 'src/theme'
import { PageTitle } from 'src/components'
import { selectCompaniesWithSSO } from 'src/entities/organization/selectors'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSuccess, selectSubmitting, selectError } from './selectors'

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch',
    flex: 1,
  },
  field: {
    marginTop: 25,
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  label: {
    marginRight: 15,
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 10,
  },
})

let ssoPrompt = null

export class ForgotPassword extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    const {
      t,
      success,
      submitting,
      actions,
      values,
      errors,
      touched,
      isValid,
      setFieldValue,
      setFieldTouched,
      companiesWithSSO,
      history,
    } = this.props
    if (success) {
      return (
        <Screen fixed>
          <NavigationHeader title={t`forgotPassword`} backTo="/login" />
          <Screen padding middle={metrics.screenWidth > 640} narrow>
            <Text size={24} lineHeight={32} bold style={{ alignSelf: 'stretch' }}>
              {t`checkYourEmail`}
            </Text>
            <Spacing marginTop={25} />
            <Text size={24} lineHeight={32}>
              {t('anEmailHasBeenSentTo', { emailAddress: values.email })}
            </Text>
            <Spacing marginTop={25} />
            <Text size={24} lineHeight={32}>
              {t`ifYouDoNotSeeTheEmail`}
            </Text>
          </Screen>
        </Screen>
      )
    }

    const handleSubmit = (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      }
      if (values.email && isValid) {
        actions.submitAction(values.email)
      }
    }

    const emailHost = values.email && values.email.split('@')[1]
    const company = emailHost && matchCompanySso(values.email, companiesWithSSO)
    const isDeedAllowedEmailDomain =
      !config.isProduction || (emailHost?.endsWith('joindeed.com') && values.email.includes('+'))
    if (company && !ssoPrompt && !isDeedAllowedEmailDomain) {
      ssoPrompt = setTimeout(() => {
        Alert.alert(t`pleaseUseSSOtoLogIn`, t('toLogInWithCompany', { companyName: company.name }), [
          { text: t`cancel`, style: 'cancel' },
          {
            text: t`accept`,
            onPress: () => {
              history.push(`/login/sso/${company.id}#${values.email}`)
            },
          },
        ])
        setFieldValue('email', '')
        setFieldTouched('email', false)
        ssoPrompt = null
      }, 500)
    }
    return (
      <Screen fixed>
        <PageTitle title={t`forgotPassword`} />
        <ScrollView>
          <NavigationHeader title={t`forgotPassword`} backTo="/login" showLanguageSwitcher />
          <Screen padding narrow action middle={metrics.screenWidth > 640}>
            <Form onSubmit={handleSubmit} style={styles.form}>
              <Text size={24} lineHeight={32} bold style={{ alignSelf: 'stretch' }}>
                {t`pleaseEnterYourEmailAddress`}
              </Text>
              <View style={styles.field}>
                <TextField
                  onChangeText={setFieldValue}
                  onTouched={setFieldTouched}
                  placeholder={t`email`}
                  name="email"
                  value={values.email}
                  maxLength={50}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoFocus
                  onSubmitEditing={handleSubmit}
                />
              </View>
              <Text color={colors.redDark} style={styles.error}>
                {touched.email && errors.email && errors.email}
              </Text>
              <Spacing marginTop={25} />
              <Button
                type="submit"
                disabled={!values.email && !isValid}
                onPress={handleSubmit}
                color={values.email && isValid ? 'primary' : 'light'}
                style={{ maxWidth: '100%' }}
                fluid
              >
                {submitting ? <ActivityIndicator color="#fff" /> : 'Confirm'}
              </Button>
            </Form>
          </Screen>
        </ScrollView>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    success: selectSuccess(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    companiesWithSSO: selectCompaniesWithSSO(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'forgotPassword', reducer })
const withEpics = injectEpics({ key: 'forgotPassword', epics })
const withForm = withFormik({
  validate: (values) => {
    const errors = {
      email: validators.notEmpty(values.email) || validators.isEmail(values.email),
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  validateOnBlur: true,
  validateOnChange: true,
  mapPropsToValues: () => ({
    email: '',
  }),
})

export default compose(withReducer, withEpics, withConnect, withForm, withTranslation('loginScreen'))(ForgotPassword)
