import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

import injectReducer from 'src/utils/injectReducer'
import injectEpics from 'src/utils/injectEpics'
import { Loading, Tabs, Screen, ScrollView } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { selectCurrentUser, selectUserById } from 'src/entities/user/selectors'
import { selectDonationsForUserLoaded, selectDonationsForUser } from 'src/entities/donation/selectors'
import {
  selectActiveDonationSchedules,
  selectDonationSchedulesListLoaded,
} from 'src/entities/donationSchedule/selectors'
import User from 'src/entities/user/model'

import { selectLoading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'
import { AllDonations } from './AllDonations/AllDonations'
import { RecurringDonations } from './RecurringDonations/RecurringDonations'

class Donations extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id } = nextProps.match.params
    if (id && id !== this.props.match.params.id) {
      this.props.actions.initAction(id)
    }
  }

  render() {
    const { t, match, location, donations, recurringDonations, donationsLoaded, recurringDonationsLoaded } = this.props
    const user = this.props.user || (location.state && new User(location.state.user))
    const isMyProfile = !match.params.id

    if (!user) {
      return <Loading />
    }

    return (
      <Screen>
        <ScrollView>
          <NavigationHeader
            backTo={isMyProfile ? '/profile' : `/profile/${user.id}`}
            title={isMyProfile ? t`myDonations` : t('usernamesDonations', { userName: user.fullName.first })}
            transparent
          />
          {user.hasFeature('recurringDonations') ? (
            <Tabs activeTab={this.props.location.hash === '#recurring' ? 1 : 0}>
              <Tabs.Tab title={t`all`}>
                <AllDonations donations={donations} isLoading={!donationsLoaded} isMyProfile={isMyProfile} />
              </Tabs.Tab>
              <Tabs.Tab title={t`recurring`}>
                <RecurringDonations donations={recurringDonations} isLoading={!recurringDonationsLoaded} />
              </Tabs.Tab>
            </Tabs>
          ) : (
            <AllDonations donations={donations} isLoading={!donationsLoaded} />
          )}
        </ScrollView>
      </Screen>
    )
  }
}
Donations.propTypes = {
  user: PropTypes.object,
  donations: PropTypes.object,
}

const withConnect = connect(
  (state, props) => ({
    loading: selectLoading(state),
    donationsLoaded: selectDonationsForUserLoaded(state, props.match.params.id),
    donations: selectDonationsForUser(state, props.match.params.id || selectCurrentUser(state)),
    recurringDonations: selectActiveDonationSchedules(state),
    recurringDonationsLoaded: selectDonationSchedulesListLoaded(state),
    user: props.match.params.id ? selectUserById(state, props.match.params.id) : selectCurrentUser(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'profileDonations', reducer })
const withEpics = injectEpics({ key: 'profileDonations', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('donationsProfile'))(Donations)
