import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { H5 } from 'src/retired/shared/Typography'
import { Image } from 'src/retired/elements'
import { providerLogos } from 'src/theme/images'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface PoweredByProps {
  type: 'gg' | 'vm' | 'pp' | 'cf' | 'vg' | 'pl' | 'id' | 'go'
}

const PoweredBy = ({ type }: PoweredByProps): JSX.Element => {
  const { t } = useTranslation('poweredBy')
  const { colors } = useDeedTheme()

  return (
    <View
      style={
        type === 'pp' && {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
        }
      }
    >
      <H5 weight="500" colour={colors.gray} style={type === 'pp' && { marginRight: 10 }}>
        {t`poweredBy`}
      </H5>

      <Image
        style={{
          width: 240,
          height: 48,
        }}
        backgroundPosition="left center"
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        source={providerLogos[`${type}Logo` as keyof typeof providerLogos]}
      />
    </View>
  )
}
export default PoweredBy
