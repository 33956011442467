import React, { useEffect, useState, useRef } from 'react'
import { Dimensions, View, Animated, Easing } from 'react-primitives'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Platform } from 'src/utils'

const LoadingBar: React.FC<{ progress: number }> = ({ progress }) => {
  const { colors } = useDeedTheme()

  const [localProgress, setProgress] = useState(0)

  const prevProgress = useRef(localProgress)

  const animation = useRef(new Animated.Value(localProgress))

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let progressTimer: NodeJS.Timeout
    if (progress === 0 && prevProgress.current) {
      setProgress(100)
      progressTimer = setTimeout(() => setProgress(0), 500)
    } else {
      setProgress(progress)
    }
    prevProgress.current = progress

    return () => {
      clearTimeout(progressTimer)
    }
  }, [progress])

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: localProgress,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start()
  }, [localProgress])

  const widthInterpolated = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp',
  })

  return (
    <View
      style={[
        css`
          flex-direction: row;
          height: ${Platform.OS === 'web' ? '7px' : '4px'};
          width: ${Platform.OS === 'web' ? 'calc(100% + 30px)' : `${Dimensions.get('window').width}px`};
          margin-left: ${Platform.OS === 'web' ? '-15px' : '-25px'};
          background-color: ${localProgress ? colors.gray02 : colors.white};
        `,
      ]}
    >
      {!!localProgress && (
        <Animated.View
          style={{
            width: widthInterpolated,
            height: '100%',
            backgroundColor: colors.userOrganizationBrandColor || colors.coral,
          }}
        />
      )}
    </View>
  )
}

export default LoadingBar
