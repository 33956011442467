import { TFunction } from 'react-i18next'

import { ActionsQuery, IncentiveType } from 'src/generated/graphql'
export { ActivityStatus, IncentiveType } from 'src/generated/graphql'

/* Types based off the Actions query return */
export type Action = ActionsQuery['actions'][0]
export type ActionCause = Action['Causes'][0]
export type Activity = Action['Activities'][0]

export enum IncentiveUnit {
  'co2' = 'co2',
  'tree' = 'tree',
  'USD' = 'USD',
}

export const getRewardText = (action: Action, t: TFunction<'actions'>, defaultText: string) => {
  const amountSum = action.incentives.reduce((accumulator, currentObject) => accumulator + currentObject.amount, 0) ?? 0
  const [reward] = action.incentives.filter(({ type }) => type === IncentiveType.Reward) ?? []

  if (amountSum === 0) {
    return t('thankYouForCompletingAction')
  }

  if (reward?.unit === IncentiveUnit.tree) {
    return t('theCompanyWillPlant', { companyName: action.Organization.name, amount: reward.amount })
  }

  if (reward?.unit === IncentiveUnit.USD) {
    if (action.Nonprofit?.name) {
      return t('theCompanyWillDonateNonprofit', {
        companyName: action.Organization.name,
        nonprofitName: action.Nonprofit?.name,
        amount: reward.amount,
      })
    }
    return t('theCompanyWillDonate', { companyName: action.Organization.name, amount: reward.amount })
  }

  return defaultText
}
