import { Observable } from 'rxjs'
import _ from 'lodash'
import i18n from 'i18next'

import { selectUserById } from 'src/entities/user/selectors'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'
import RelationApi from 'src/entities/relation/api'

import {
  initSuccessAction,
  initFailedAction,
  relationRequestSuccessAction,
  relationRequestFailedAction,
  relationAcceptSuccessAction,
  relationAcceptFailedAction,
  relationRemoveSuccessAction,
  relationRemoveFailedAction,
} from './actions'
import { INIT, RELATION_REQUEST, RELATION_ACCEPT, RELATION_REMOVE } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).exhaustMap((payload) => {
    const actions = []
    const state = store.getState()

    if (payload.id) {
      const user = selectUserById(state, payload.id)
      if (!user) {
        actions.push(UserApi.fetch(payload.id))
      }
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export const relationRequest = (action$) =>
  action$.ofType(RELATION_REQUEST).exhaustMap(({ id }) =>
    RelationApi.request(id)
      .mergeMap((resultingAction) => [
        resultingAction,
        showMessageAction(i18n.t('friendsProfile:friendRequestSent')),
        relationRequestSuccessAction(),
      ])
      .catch((e) =>
        Observable.of(relationRequestFailedAction(e), showErrorAction(i18n.t('friendsProfile:friendRequestFailed')))
      )
  )

export const relationAccept = (action$) =>
  action$.ofType(RELATION_ACCEPT).exhaustMap(({ id }) =>
    RelationApi.accept(id)
      .mergeMap((resultingAction) =>
        UserApi.fetch('me').mergeMap((userUpdateAction) => [
          resultingAction,
          userUpdateAction,
          showMessageAction(i18n.t('friendsProfile:friendRequestAccepted')),
          relationAcceptSuccessAction(),
        ])
      )
      .catch((e) =>
        Observable.of(
          relationAcceptFailedAction(e),
          showErrorAction(i18n.t('friendsProfile:friendRequestAcceptFailed'))
        )
      )
  )

export const relationRemove = (action$) =>
  action$.ofType(RELATION_REMOVE).exhaustMap(({ id, cancellation }) =>
    RelationApi.remove(id)
      .mergeMap((resultingAction) =>
        UserApi.fetch('me').mergeMap((userUpdateAction) => [
          resultingAction,
          userUpdateAction,
          showMessageAction(
            cancellation ? i18n.t('friendsProfile:friendRequestCancelled') : i18n.t('friendsProfile:friendRemoved')
          ),
          relationRemoveSuccessAction(),
        ])
      )
      .catch((e) =>
        Observable.of(
          relationRemoveFailedAction(e),
          showErrorAction(
            cancellation
              ? i18n.t('friendsProfile:friendRequestCancellationFailed')
              : i18n.t('friendsProfile:friendRemovalFailed')
          )
        )
      )
  )

export default [init, relationRequest, relationAccept, relationRemove]
