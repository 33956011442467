import React from 'react'
import { useTranslation } from 'react-i18next'

import { Action, getRewardText } from 'src/containers/modules/Actions/types'

import { ActionModal, ActionModalType } from './ActionModal'

export const JoinSuccessModal = ({
  action,
  visible,
  buttonsDisabled = false,
  onClose,
}: {
  action: Action
  visible: boolean
  buttonsDisabled?: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation('actions')
  const title = t('actionJoined')
  const reward = getRewardText(action, t, t('thanksForJoining', { action: action.title }))
  const amountSum = action.incentives.reduce((accumulator, currentObject) => accumulator + currentObject.amount, 0) ?? 0
  let subTitle = t('thanksForJoiningOnceCompleted', { action: action.title, reward })
  if (amountSum === 0) {
    subTitle = t('thanksForJoiningOnceCompletedDefault', { action: action.title })
  }

  return (
    <ActionModal
      type={ActionModalType.JoinSuccess}
      title={title}
      subTitle={subTitle}
      visible={visible}
      buttonsDisabled={buttonsDisabled}
      onClose={onClose}
      primaryButtonData={{ text: t('gotIt'), onPress: onClose }}
    />
  )
}
