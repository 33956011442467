import { createSelector } from 'reselect'
import { OrderedMap } from 'immutable'
import Fuse from 'fuse.js'

import {
  filterFundraiserSearchResultsDeedsByType,
  filterVolunteerSearchResultsDeedsByType,
  selectUpcomingDeeds,
  filterUpcomingDeeds,
} from 'src/entities/deed/selectors'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { TypedMap } from 'src/utils/typed-map'
import { DeedMap } from 'src/entities/deed/reducer'
import { CauseMap } from 'src/entities/cause/reducer'
import Cause from 'src/entities/cause/model'
import { DeedType } from 'src/generated/graphql'

import { initialState, TypedSearchState } from './reducer'

type SearchState = TypedMap<{ search: TypedSearchState }>

const selectState = (state: SearchState) => state.get('search') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectSearchVisible = createSelector(selectState, (state) => state.get('searchVisible'))

export const selectSearchTerm = createSelector(selectState, (state) => state.get('searchTerm'))

export const selectCountryCode = createSelector(selectState, (state) => state.get('countryCode'))

export const selectStateCode = createSelector(selectState, (state) => state.get('stateCode'))

export const selectSearchProgress = createSelector(selectState, (state) => state.get('searchProgress'))

export const selectSearchingDeeds = createSelector(selectState, (state) => state?.get('searchingDeeds'))

export const selectSearchingNonprofits = createSelector(selectState, (state) => state?.get('searchingNonprofits'))

export const selectSearchingDeedsFailed = createSelector(selectState, (state) => state?.get('searchingDeedsFailed'))

export const selectSearchingNonprofitsFailed = createSelector(selectState, (state) =>
  state?.get('searchingNonprofitsFailed')
)

export const selectNonprofitsOnly = createSelector(selectState, (state) => state?.get('nonprofitsOnly'))

export const selectFilteredDeedsBySearchTerm = createSelector(
  selectUpcomingDeeds,
  selectSearchTerm,
  (deeds, searchTerm) => (searchTerm ? fuseSearchDeeds(deeds, searchTerm) : (OrderedMap() as DeedMap))
)

export const selectFilteredDeedsByAllFilters = ({
  searchTerm,
  location,
  causes,
  deedType,
}: {
  searchTerm?: string
  location?: string
  causes?: CauseMap
  deedType?: DeedType
}) =>
  createSelector(
    deedType === 'Campaign' ? filterFundraiserSearchResultsDeedsByType : filterVolunteerSearchResultsDeedsByType,
    selectCurrentUser,
    selectUserLocations,
    (filteredDeeds, user, locations) => {
      const deeds = filteredDeeds.filter(filterUpcomingDeeds)
      const userCountryCode = locations?.get(user?.location)?.countryCode
      const locationWithFallback = location || userCountryCode || ''
      const exactLocationMatch = locationWithFallback.includes('-') // @NOTE-CH: Allow countryCode match when stateCode wasn't provided
      return fuseSearchDeeds(deeds, searchTerm)
        .filter((deed) => deed.matchLocation(locationWithFallback, { exact: exactLocationMatch }))
        .filter((deed) => (causes?.size ? causes.some((cause: Cause) => deed.hasCause(cause)) : true))
    }
  )

export const fuseSearchDeeds = (deeds: DeedMap, searchTerm?: string) => {
  if (!searchTerm) {
    return deeds
  }

  const searchTermFields = ['name', 'matchForSearchTerm']
  const fuse = new Fuse(deeds.toList().toArray(), {
    keys: searchTermFields.map((field) => ({
      name: field,
      getFn: (deed) => deed.get(field),
    })),
    threshold: 0.3,
  })

  return OrderedMap(fuse.search({ name: searchTerm }).map(({ item }) => [item.id, item])) as DeedMap
}
