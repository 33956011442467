import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { rgba } from 'polished'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Donation from 'src/entities/donation/model'
import Deed from 'src/entities/deed/model'
import { DeviceInfo, Platform, formatFullName } from 'src/utils'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import TabMenu from 'src/retired/blocks/TabMenu'
import DeedCompact from 'src/retired/blocks/DeedCompact'
import { DonationCompact } from 'src/retired/blocks/DonationCompact/DonationCompact'
import CreatedDeedCompact from 'src/retired/blocks/CreatedDeedCompact'
import AffiliationCompact from 'src/retired/blocks/AffiliationCompact'
import NonprofitNominationsCompact from 'src/retired/blocks/NonprofitNominationsCompact'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import { SmallOnly } from 'src/retired/elements/MediaQuery'
import {
  ActivityIndicator,
  Avatar,
  Divider,
  Icon,
  Image,
  LinkButton,
  Loading,
  Row,
  Screen,
  ScrollView,
  Spacing,
  Text,
} from 'src/retired/elements'
import { Link } from 'src/navigation'
import { selectCauses } from 'src/entities/cause/selectors'
import { selectCurrentUser, selectUserById, selectUserLocations } from 'src/entities/user/selectors'
import {
  selectUpcomingDeedsForUser,
  selectUpcomingDeedsLoaded,
  selectPastDeedsForUser,
  selectPastDeedsLoaded,
  selectOngoingDeedsForUser,
  selectUserSubmittedDeedsLoaded,
  selectUserSubmittedDeeds,
} from 'src/entities/deed/selectors'
import { selectDonationsForUser, selectDonationsForUserLoaded } from 'src/entities/donation/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import NotifcationsIcon from 'src/containers/modules/NotifcationsIcon'
import { SearchToggle } from 'src/containers/modules/Search'
import User from 'src/entities/user/model'
import { Body2, H5, Label } from 'src/retired/shared/Typography'
import { CardCompactEmpty } from 'src/components/CardCompactEmpty/CardCompactEmpty'
import { PageTitle } from 'src/components'
import { TextWithIcon } from 'src/components/TextWithIcon'
import { PledgesSection } from 'src/components/GivingPledge/GivingPledgeSection'

import VolunteerHours from './VolunteerHours/VolunteerHours'
import DonationsSummary from './DonationsSummary'
import { selectLoading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'
import { FriendRequestButtonWrapper } from './FriendRequestButton/Wrapper'
import {
  isAndroid,
  Section,
  SectionTitle,
  SectionContent,
  SectionLink,
  UserSection,
  AvatarContainer,
  AvatarImage,
  AvatarEditIcon,
  ConnectionItem,
  Content,
  styles,
} from './Profile.styled'
import CommunitiesSection from './CommunitiesSection'
import { ActionsSections } from './ActionsSection'
import { BadgesSection } from './Badges/BadgesSection'
import { VolunteerRewards } from './VolunteerRewards'

const MAX_COMPACT_DONATION_COUNT = 7

export const Profile = (): JSX.Element => {
  useInjectEpics({ key: 'profile', epics })
  useInjectReducer({ key: 'profile', reducer })

  const { t } = useTranslation('profileScreen')

  const dispatch = useDispatch()
  const { colors, metrics } = useDeedTheme()
  const { id: profileId } = useParams<{ id: string }>()
  const { state: locationState } = useLocation<{ user: User }>()

  const isMyProfile = !profileId // TODO: remove

  const currentUser = useSelector(selectCurrentUser)

  const loading = useSelector(selectLoading) as boolean
  const locations = useSelector(selectUserLocations)
  const skills = useSelector(selectSkills)
  const causes = useSelector(selectCauses)
  const upcomingDeedsLoaded = useSelector((state) => selectUpcomingDeedsLoaded(state, profileId))
  const selectUser = useSelector((state) => (profileId ? selectUserById(state, profileId) : currentUser)) as User
  const upcomingDeeds = useSelector((state) => selectUpcomingDeedsForUser(state, selectUser))
  const pastDeedsLoaded = useSelector((state) => selectPastDeedsLoaded(state, profileId))
  const pastDeeds = useSelector((state) => selectPastDeedsForUser(state, selectUser))
  const ongoingDeeds = useSelector((state) => selectOngoingDeedsForUser(state, selectUser))
  const donationsLoaded = useSelector((state) => selectDonationsForUserLoaded(state, profileId))
  const donations = useSelector((state) => selectDonationsForUser(state, profileId || currentUser))
  const userSubmittedDeeds = useSelector((state) => selectUserSubmittedDeeds(state, currentUser))
  const userSubmittedDeedsLoaded = useSelector(selectUserSubmittedDeedsLoaded)

  const user = selectUser || (locationState?.user && new User(locationState.user))

  useEffect(() => {
    dispatch(Actions.initAction(profileId))
  }, [dispatch, profileId])

  if (!user) {
    return <Loading />
  }

  const formattedName = formatFullName(user.fullName)
  const userLocation = locations.get(user.location)
  const followedOrganizations = user?.following?.filter((organization) => Boolean(organization?.organization)).toArray()
  const friendOfCurrentUser = currentUser?.myFriends.find((friend) => friend.id === user.id)
  const disableNonprofitSearch = currentUser?.getSetting('partnerNonprofitsOnly') ?? true

  // is used in only one Places, just i am not fan of bulky iterators
  const userDraftAndPublishFeatures =
    (user?.hasFeature('userCreateEvent') || user?.hasFeature('userCreateFundraiser')) &&
    (user?.hasFeature('userPublishDeed') || user?.hasFeature('userDraftDeed'))

  return (
    <Screen fixed>
      <PageTitle title={isMyProfile ? t('myProfile') : `${t('profile')}: ${formattedName}`} />
      <SmallOnly>
        <View style={{ flexGrow: 0, padding: 15, paddingTop: isAndroid ? 10 : 0 }}>
          <Row style={{ alignItems: 'center' }}>
            <NotifcationsIcon />
            {!disableNonprofitSearch && (
              <View style={{ flexGrow: 1, marginLeft: 18 }}>
                <SearchToggle />
              </View>
            )}
          </Row>
        </View>
        <PendingApprovalBanner />
        <CompanyNotificationBanner />
      </SmallOnly>
      <ScrollView
        style={{ flex: 1, alignSelf: 'stretch', paddingBottom: metrics.isLarge ? 0 : DeviceInfo.getNotchValue() + 40 }}
      >
        {!isMyProfile && currentUser && (
          <FriendRequestButtonWrapper
            user={user}
            isFriend={!!friendOfCurrentUser}
            relationtoMe={friendOfCurrentUser?.relationToMe}
          />
        )}

        {!isMyProfile && <NavigationHeader transparent />}

        <Content colors={colors}>
          <Section>
            <SectionContent>
              <UserSection>
                <AvatarContainer colors={colors}>
                  <AvatarImage>
                    <Image
                      source={{ uri: user.mainPicture }}
                      alt={formattedName}
                      title={formattedName}
                      style={{ height: 45, width: 45 }}
                    />
                  </AvatarImage>
                  {/* The ProfileImageScreen is not properly implement for native. (You get a white screen which you can escape). That's why we only show on web for now  */}
                  {isMyProfile && Platform.OS === 'web' && (
                    <AvatarEditIcon>
                      <Link to="/profile/image" style={{ padding: 10 }}>
                        <Icon icon="plusCircleBlack" style={{ width: 19, height: 18 }} />
                      </Link>
                    </AvatarEditIcon>
                  )}
                </AvatarContainer>

                <View style={{ flex: 1 }}>
                  <Text size={22} medium numberOfLines={Platform.OS === 'web' ? 1 : 2} style={{ marginRight: 20 }}>
                    {formattedName}
                  </Text>

                  <Spacing marginTop={5}>
                    {!user?.getSetting('disableEmployeeLocationUpdate') && isMyProfile && locations.size > 0 ? (
                      <Link to="/profile/location">
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginRight: 2,
                          }}
                        >
                          <Text size={13} numberOfLines={1}>
                            {userLocation?.name || t`nA`}
                          </Text>
                          <Icon icon="arrowDownBlack" style={{ width: 8, height: 4, marginLeft: 6 }} />
                        </View>
                      </Link>
                    ) : (
                      userLocation && (
                        <Text size={13} numberOfLines={1}>
                          {userLocation?.name || ''}
                        </Text>
                      )
                    )}
                  </Spacing>
                </View>
                {isMyProfile && (
                  <Link to="/profile/settings" style={{ padding: 10 }}>
                    <Icon icon="gear" style={{ width: 17, height: 17 }} />
                  </Link>
                )}
              </UserSection>
            </SectionContent>
            {isMyProfile && currentUser?.hasFeature('volunteering') && <VolunteerHours user={user} />}
          </Section>

          {isMyProfile && (
            <Section>
              <VolunteerRewards />
            </Section>
          )}

          {isMyProfile && (currentUser?.hasFeature('donations') || donations.size > 0) && (
            <Section>
              <SectionTitle>
                <H5>{t`donations`}</H5>

                <Link to="/donations/select-time-period" style={{ textAlign: 'center' }}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: colors.brandColor,
                      borderRadius: 20,
                      paddingTop: 8,
                      paddingBottom: 8,
                      paddingLeft: 24,
                      paddingRight: 24,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <TextWithIcon
                      icon="receiptLongOutlined"
                      text={t`selectTimePeriod:donationSummary`}
                      color={colors.brandColor}
                    />
                  </View>
                </Link>
              </SectionTitle>

              <SectionContent>
                <DonationsSummary user={currentUser} />

                {donations.size > 0 ? (
                  <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewContainer}
                    showsHorizontalScrollIndicator={!isAndroid}
                    horizontal
                  >
                    {donations
                      .slice(0, MAX_COMPACT_DONATION_COUNT)
                      .toArray()
                      .map((donation: Donation) => {
                        const { id } = donation
                        return <DonationCompact key={id} donation={donation} />
                      })}
                    {!donationsLoaded && <ActivityIndicator />}
                  </ScrollView>
                ) : (
                  <View>
                    {donationsLoaded ? (
                      <CardCompactEmpty cardTitle={t`youHaveNoDonations`} link="/donate" />
                    ) : (
                      <ActivityIndicator />
                    )}
                  </View>
                )}
              </SectionContent>

              {donationsLoaded && donations.size > 0 && (
                <SectionLink>
                  <Link to={isMyProfile ? '/profile/donations' : `/profile/${user.id}/donations`}>
                    <Body2 weight="500" colour={colors.brandColor}>{t`seeAllDonations`}</Body2>
                  </Link>
                </SectionLink>
              )}
            </Section>
          )}

          {isMyProfile && currentUser?.hasFeature('givingPledgeCampaigns') && (
            <PledgesSection showsHorizontalScrollbar={!isAndroid}></PledgesSection>
          )}

          <Section>
            <SectionTitle>
              <H5>{t`upcomingDeeds`}</H5>
            </SectionTitle>

            <SectionContent>
              {upcomingDeeds.size > 0 ? (
                <ScrollView
                  style={styles.scrollView}
                  contentContainerStyle={styles.scrollViewContainer}
                  showsHorizontalScrollIndicator={!isAndroid}
                  horizontal
                >
                  {upcomingDeeds.toArray().map((deed: Deed) => {
                    const isUserAttending = deed.isUserAttending(user)
                    const hasUserAppliedForRole = deed.hasUserAppliedForRole(user)
                    const canCheckIn = isUserAttending && deed.isDuringCheckInWindow() && deed.type !== 'BaseEvent'
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <DeedCompact
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        canCheckIn={canCheckIn}
                        isCheckedIn={isCheckedIn}
                        applied={hasUserAppliedForRole}
                        approved={isUserAttending}
                      />
                    )
                  })}
                  {!upcomingDeedsLoaded && <ActivityIndicator />}
                </ScrollView>
              ) : (
                <View>
                  {upcomingDeedsLoaded ? (
                    isMyProfile ? (
                      <CardCompactEmpty cardTitle={t`youHaveNoUpcomingDeeds`} link="/search?tab=volunteer" />
                    ) : (
                      <CardCompactEmpty
                        cardTitle={t('inviteUserNameToOneOfYours', {
                          userName: user.fullName.first,
                        })}
                        link="/profile/deeds"
                      />
                    )
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </SectionContent>
            {upcomingDeedsLoaded && upcomingDeeds.size > 0 && (
              <SectionLink>
                <Link to={isMyProfile ? '/profile/deeds' : `/profile/${user.id}/deeds`}>
                  <Body2 weight="500" colour={colors.brandColor}>
                    {t`seeAllUpcomingDeeds`}
                  </Body2>
                </Link>
              </SectionLink>
            )}
          </Section>

          <Section>
            <SectionTitle>
              <H5>{t`ongoingDeeds`}</H5>
            </SectionTitle>

            <SectionContent>
              {ongoingDeeds.size > 0 ? (
                <ScrollView
                  style={styles.scrollView}
                  contentContainerStyle={styles.scrollViewContainer}
                  showsHorizontalScrollIndicator={!isAndroid}
                  horizontal
                >
                  {ongoingDeeds.toArray().map((deed: Deed) => {
                    const isUserAttending = deed.isUserAttending(user)
                    const hasUserAppliedForRole = deed.hasUserAppliedForRole(user)
                    const canCheckIn = isUserAttending && deed.isDuringCheckInWindow() && deed.type !== 'BaseEvent'
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <DeedCompact
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        canCheckIn={canCheckIn}
                        isCheckedIn={isCheckedIn}
                        applied={hasUserAppliedForRole}
                        approved={isUserAttending}
                      />
                    )
                  })}
                  {!upcomingDeedsLoaded && <ActivityIndicator />}
                </ScrollView>
              ) : (
                <View>
                  {upcomingDeedsLoaded ? (
                    isMyProfile ? (
                      <CardCompactEmpty cardTitle={t`youHaveNoOngoingDeeds`} link="/search?tab=volunteer" />
                    ) : (
                      <CardCompactEmpty
                        cardTitle={t('inviteUserNameToOneOfYours', { userName: user.fullName.first })}
                        link="/profile/deeds"
                      />
                    )
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </SectionContent>
            {upcomingDeedsLoaded && ongoingDeeds.size > 0 && (
              <SectionLink>
                <Link to={isMyProfile ? '/profile/deeds#ongoing' : `/profile/${user.id}/deeds#ongoing`}>
                  <Body2 weight="500" colour={colors.brandColor}>
                    {t`seeAllOngoingDeeds`}
                  </Body2>
                </Link>
              </SectionLink>
            )}
          </Section>

          <Section>
            <SectionTitle>
              <H5>{t`pastDeeds`}</H5>
            </SectionTitle>

            <SectionContent>
              {pastDeeds.size > 0 ? (
                <ScrollView
                  style={styles.scrollView}
                  contentContainerStyle={styles.scrollViewContainer}
                  showsHorizontalScrollIndicator={!isAndroid}
                  horizontal
                >
                  {pastDeeds.toArray().map((deed: Deed) => {
                    const isUserAttending = deed.isUserAttending(user)
                    const hasUserAppliedForRole = deed.hasUserAppliedForRole(user)
                    const canCheckIn = isUserAttending && deed.isDuringCheckInWindow() && deed.type !== 'BaseEvent'
                    const isCheckedIn = deed.isUserCheckedIn(user)
                    return (
                      <DeedCompact
                        key={deed.id}
                        deed={deed}
                        isMyProfile={isMyProfile}
                        canCheckIn={canCheckIn}
                        isCheckedIn={isCheckedIn}
                        applied={hasUserAppliedForRole}
                        approved={isUserAttending}
                      />
                    )
                  })}
                  {!pastDeedsLoaded && <ActivityIndicator />}
                </ScrollView>
              ) : (
                <View>
                  {pastDeedsLoaded ? (
                    isMyProfile ? (
                      <CardCompactEmpty cardTitle={t`youHaveNoPastDeeds`} link="/search?tab=volunteer" />
                    ) : (
                      <CardCompactEmpty
                        cardTitle={t('inviteUserNameToOneOfYours', { userName: user.fullName.first })}
                        link="/profile/deeds"
                      />
                    )
                  ) : (
                    <ActivityIndicator />
                  )}
                </View>
              )}
            </SectionContent>
            {pastDeedsLoaded && pastDeeds.size > 0 && (
              <SectionLink>
                <Link to={isMyProfile ? '/profile/deeds#past' : `/profile/${user.id}/deeds#past`}>
                  <Body2 weight="500" colour={colors.brandColor}>
                    {t`seeAllPastDeeds`}
                  </Body2>
                </Link>
              </SectionLink>
            )}
          </Section>

          {isMyProfile && (userDraftAndPublishFeatures || user?.hasFeature('userCreateDeed')) && (
            <Section>
              <SectionTitle>
                <H5>{t`deedsCreated`}</H5>
              </SectionTitle>

              <SectionContent>
                {userSubmittedDeeds.size > 0 ? (
                  <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewContainer}
                    showsHorizontalScrollIndicator={!isAndroid}
                    horizontal
                  >
                    {userSubmittedDeeds.toArray().map((deed: Deed) => (
                      <CreatedDeedCompact key={deed.id} deed={deed} />
                    ))}
                    {!userSubmittedDeedsLoaded && <ActivityIndicator />}
                  </ScrollView>
                ) : user?.hasFeature('userCreateDeed') ? (
                  <CardCompactEmpty
                    cardTitle={t`helpAnOrganizationYouCareAbout`}
                    link="/email-form/create-deed"
                    buttonText={t`createADeed`}
                  />
                ) : (
                  <View>
                    {userSubmittedDeedsLoaded ? (
                      <CardCompactEmpty
                        cardTitle={t`helpAnOrganizationYouCareAbout`}
                        link="/create-deed/select-type"
                        buttonText={t`createADeed`}
                      />
                    ) : (
                      <ActivityIndicator />
                    )}
                  </View>
                )}
              </SectionContent>

              {!user?.hasFeature('userCreateDeed') && userSubmittedDeedsLoaded && userSubmittedDeeds.size > 0 && (
                <SectionLink>
                  <Link to="/profile/created-deeds">
                    <Body2 weight="500" colour={colors.brandColor}>
                      {t`manageCreatedDeeds`}
                    </Body2>
                  </Link>
                </SectionLink>
              )}
            </Section>
          )}

          <ActionsSections user={user} />

          <BadgesSection user={user} />

          <CommunitiesSection user={user} />

          {user?.hasFeature('employeeAffiliations') && <AffiliationCompact />}

          <Section>
            <SectionTitle>
              <H5>{t`friends`}</H5>
            </SectionTitle>

            <SectionContent>
              {user.myFriends?.length > 0 ? (
                <ScrollView
                  style={[styles.scrollView, { marginLeft: -35, marginRight: -30 }]}
                  contentContainerStyle={styles.scrollViewContainer}
                  showsHorizontalScrollIndicator={!isAndroid}
                  horizontal
                >
                  {user.myFriends.slice(0, 10).map((friend) => (
                    <ConnectionItem key={friend.id}>
                      <Link
                        to={{
                          pathname: `/profile/${friend.id}`,
                          state: { user: friend },
                        }}
                      >
                        <Avatar user={friend} size={50} spacing={0} style={{ marginBottom: 16 }} link={false} />
                        <View>
                          <Label center numberOfLines={1}>
                            {friend.fullName.first}
                          </Label>
                          <Label center numberOfLines={1}>
                            {friend.fullName.last}
                          </Label>
                        </View>
                      </Link>
                    </ConnectionItem>
                  ))}
                </ScrollView>
              ) : (
                <Body2 center marginBottom={16}>
                  {isMyProfile
                    ? t`youCanAddFriendsAfterAttending`
                    : t('doesNotHaveAnyFriends', { userName: user.fullName.first })}
                </Body2>
              )}
            </SectionContent>

            {user.myFriends?.length > 0 &&
              (!loading ? (
                <SectionLink>
                  <Link to={isMyProfile ? '/profile/friends' : `/profile/${user.id}/friends`}>
                    <Body2 weight="500" colour={colors.brandColor}>{t`seeAllFriends`}</Body2>
                  </Link>
                </SectionLink>
              ) : (
                <ActivityIndicator />
              ))}
          </Section>

          {user?.hasFeature('userNominateNonprofit') && <NonprofitNominationsCompact />}

          <Section last={!isMyProfile}>
            <SectionTitle>
              <H5>{t`favoriteOrganizations`}</H5>
            </SectionTitle>

            <SectionContent>
              {followedOrganizations || !loading ? (
                <View>
                  {followedOrganizations && followedOrganizations.length > 0 ? (
                    <ScrollView
                      style={styles.scrollView}
                      contentContainerStyle={styles.scrollViewContainer}
                      showsHorizontalScrollIndicator={!isAndroid}
                      horizontal
                    >
                      {followedOrganizations.map((followedOrganization) => {
                        if (!followedOrganization.organization) {
                          return null
                        }
                        return (
                          <Link
                            to={`/organization/${followedOrganization.organization.id}`}
                            key={followedOrganization.organization.id}
                          >
                            <ConnectionItem>
                              {followedOrganization.organization.mainPicture ? (
                                <Image
                                  source={{
                                    uri: followedOrganization.organization.mainPicture,
                                  }}
                                  style={{
                                    width: '100%',
                                    height: 70,
                                    resizeMode: 'contain',
                                  }}
                                />
                              ) : (
                                <Spacing marginTop={70} />
                              )}
                              <Body2 center numberOfLines={Platform.OS === 'web' ? 1 : 2}>
                                {followedOrganization.organization.name}
                              </Body2>
                            </ConnectionItem>
                          </Link>
                        )
                      })}
                    </ScrollView>
                  ) : null}

                  {user.following?.size === 0 && (
                    <Body2 center marginBottom={16}>
                      {t`youSupportOrganizationsBy`}
                    </Body2>
                  )}
                </View>
              ) : (
                <ActivityIndicator />
              )}
            </SectionContent>
          </Section>

          {isMyProfile && (
            <Section>
              <SectionTitle>
                <H5>{t`skills`}</H5>

                {isMyProfile && (
                  <LinkButton
                    to="/profile/skills"
                    color="transparent"
                    style={{
                      borderRadius: 100,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: colors.brandColor,
                      alignSelf: 'flex-start',
                      paddingLeft: 15,
                      paddingRight: 15,
                      height: metrics.isSmall ? 26 : 30,
                      justifyContent: 'center',
                    }}
                  >
                    <Label colour={colors.brandColor}>{t`update`}</Label>
                  </LinkButton>
                )}
              </SectionTitle>

              <SectionContent>
                {loading ? (
                  <ActivityIndicator />
                ) : user?.skills?.length && skills.size ? (
                  <ScrollView
                    style={[styles.scrollView, { marginLeft: -25, marginRight: -20, overflowY: 'scroll' }]}
                    contentContainerStyle={styles.scrollViewContainer}
                    showsHorizontalScrollIndicator={!isAndroid}
                    horizontal
                  >
                    {user.skills.map((skillId) => (
                      <View
                        style={{
                          marginRight: 5,
                          borderRadius: 20,
                          paddingHorizontal: 10,
                          paddingTop: 8,
                          paddingBottom: 8,
                          backgroundColor: rgba(colors.brandColor, 0.05),
                        }}
                        key={skillId}
                      >
                        <Text color={colors.brandColor}>{skills.get(skillId)?.name.replace('-', '') || skillId}</Text>
                      </View>
                    ))}
                  </ScrollView>
                ) : (
                  <Body2 center>{t`noSkillsSelected`}</Body2>
                )}
              </SectionContent>

              <Divider marginTop={0} marginBottom={0} style={{ marginLeft: 25, marginRight: 25 }} />

              <SectionTitle>
                <H5>{i18n.t('profileScreen:causes')}</H5>

                {isMyProfile && (
                  <LinkButton
                    to="/profile/interests"
                    color="transparent"
                    style={{
                      borderRadius: 100,
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: colors.brandColor,
                      alignSelf: 'flex-start',
                      paddingLeft: 15,
                      paddingRight: 15,
                      height: metrics.isSmall ? 26 : 30,
                      justifyContent: 'center',
                    }}
                  >
                    <Label colour={colors.brandColor}>{i18n.t('profileScreen:update')}</Label>
                  </LinkButton>
                )}
              </SectionTitle>

              <SectionContent>
                {loading ? (
                  <ActivityIndicator />
                ) : user?.interests?.length > 0 && causes.size > 0 ? (
                  <ScrollView
                    style={[styles.scrollView, { marginLeft: -25, marginRight: -20, overflowY: 'scroll' }]}
                    contentContainerStyle={styles.scrollViewContainer}
                    showsHorizontalScrollIndicator={!isAndroid}
                    horizontal
                  >
                    {user.interests.map((causeId) => {
                      const cause = causes.get(causeId)
                      return (
                        cause &&
                        cause.type !== 'SDG' && (
                          <View
                            style={{
                              marginRight: 5,
                              borderRadius: 20,
                              paddingHorizontal: 10,
                              paddingTop: 8,
                              paddingBottom: 8,
                              backgroundColor: rgba(colors.brandColor, 0.05),
                            }}
                            key={causeId}
                          >
                            <Text color={colors.brandColor}>{cause?.label()}</Text>
                          </View>
                        )
                      )
                    })}
                  </ScrollView>
                ) : (
                  <Body2 center>{i18n.t('profileScreen:noCausesSelected')}</Body2>
                )}
              </SectionContent>
            </Section>
          )}
        </Content>
      </ScrollView>

      {isMyProfile && (
        <SmallOnly>
          <TabMenu />
        </SmallOnly>
      )}
    </Screen>
  )
}

export default Profile
