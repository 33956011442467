import React from 'react'
import PropTypes from 'prop-types'

const Form = ({ children, style, onSubmit, ...props }) => (
  <form onSubmit={onSubmit} style={{ display: 'contents', ...style }} {...props}>
    {children}
  </form>
)
Form.propTypes = {
  children: PropTypes.node,
  style: PropTypes.any,
  onSubmit: PropTypes.func,
}

export default Form
