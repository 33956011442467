import React from 'react'
import { useFeedContext, useStreamContext, LoadMorePaginator } from 'react-activity-feed'
import type { LoadMorePaginatorProps, ActivityProps } from 'react-activity-feed'
import { smartRender } from 'react-activity-feed/dist/utils'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { ActivityIndicator } from 'src/retired/elements'
import { formatFullName } from 'src/utils/formatFullName'

import { FeedActivity } from './FeedActivity'
import { FeedPlaceholder } from './FeedPlaceholder'
import { LoadMoreButton } from './LoadMoreButton'

const Paginator = (props: LoadMorePaginatorProps): JSX.Element => (
  <LoadMorePaginator {...props} LoadMoreButton={LoadMoreButton} />
)

export const FlatFeedInner = (): JSX.Element => {
  const feed = useFeedContext()
  const { client } = useStreamContext()

  const user = useSelector(selectCurrentUser)

  React.useEffect(() => {
    if (user && client?.currentUser?.full && client?.currentUser?.data) {
      const name = formatFullName(user.preferredName || user.fullName)
      client.currentUser.full.data.name = name
      client.currentUser.full.data.profileImage = user.mainPicture
      client.currentUser.data.name = name
      client.currentUser.data.profileImage = user.mainPicture
    }
  })

  if (feed.refreshing && !feed.hasDoneRequest) {
    return (
      <div style={{ marginTop: 24 }}>
        <ActivityIndicator />
      </div>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {feed.activities.size === 0 && feed.hasDoneRequest ? (
        <FeedPlaceholder />
      ) : (
        smartRender<LoadMorePaginatorProps>(Paginator, {
          loadNextPage: () => {
            void feed.loadNextPage()
          },
          hasNextPage: feed.hasNextPage,
          refreshing: feed.refreshing,
          children: feed.activityOrder.map((id) =>
            smartRender<ActivityProps>(FeedActivity, {
              activity: feed.activities.get(id)?.toJS(),
              feedGroup: feed.feedGroup,
              userId: feed.userId,
              key: id,
            })
          ),
        })
      )}
    </>
  )
}
