import { Observable } from 'rxjs'
import _ from 'lodash'
import { replace } from 'connected-react-router'

import { selectDonationsForDeedLoaded } from 'src/entities/donation/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import DonationsApi from 'src/entities/donation/api'
import DeedsApi from 'src/entities/deed/api'

import { initSuccessAction, initFailedAction } from './actions'
import { INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const deed = selectDeedById(state, id)
    if (!deed) {
      actions.push(DeedsApi.fetch(id))
    }

    if (!selectDonationsForDeedLoaded(state, id)) {
      actions.push(DonationsApi.fetchForDeed(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) =>
        e.response?.status === 401
          ? Observable.of(replace(`/deeds/${id}/authenticate/protected`))
          : Observable.of(initFailedAction(e))
      )
  })

export default [init]
