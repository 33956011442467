import { Observable } from 'rxjs'
import { Map } from 'immutable'

import {
  VolunteerTimesQuery,
  VolunteerTimesDocument,
  VolunteerTimesQueryVariables,
  CreateVolunteerTimeDocument,
  CreateVolunteerTimeMutation,
  CreateVolunteerTimeMutationVariables,
  VolunteerTimeCreateInput,
  UpdateVolunteerTimeMutation,
  UpdateVolunteerTimeMutationVariables,
  UpdateVolunteerTimeDocument,
  VolunteerTimeUpdateInput,
  DeleteVolunteerTimeMutation,
  DeleteVolunteerTimeMutationVariables,
  DeleteVolunteerTimeDocument,
  VolunteerTimeWhereInput,
} from 'src/generated/graphql'

import { query, mutate } from '../graphql'

import { addAction, addMultipleAction, removeAction } from './actions'
import VolunteerTime from './model'

export default class VolunteerTimeApi {
  public static getByFilter(where: VolunteerTimeWhereInput): Observable<{
    type: string
    volunteerTimes: Map<string, VolunteerTime>
  }> {
    return Observable.from(
      query<VolunteerTimesQuery, VolunteerTimesQueryVariables>(VolunteerTimesDocument, {
        where,
      })
    ).map((result) =>
      addMultipleAction(
        Map(
          result.data.volunteerTimes.map((volunteerTime: any) => {
            const volunteerTimeRecord = new VolunteerTime(volunteerTime)
            return [volunteerTimeRecord.id, volunteerTimeRecord]
          })
        )
      )
    )
  }

  public static submit(data: VolunteerTimeCreateInput): Observable<{
    type: string
    volunteerTime: VolunteerTime
  }> {
    return Observable.from(
      mutate<CreateVolunteerTimeMutation, CreateVolunteerTimeMutationVariables>(CreateVolunteerTimeDocument, { data })
    ).map((result) => addAction(new VolunteerTime(result.data?.createVolunteerTime)))
  }

  public static edit(
    id: string,
    data: VolunteerTimeUpdateInput
  ): Observable<{
    type: string
    volunteerTime: VolunteerTime
  }> {
    return Observable.from(
      mutate<UpdateVolunteerTimeMutation, UpdateVolunteerTimeMutationVariables>(UpdateVolunteerTimeDocument, {
        id,
        data,
      })
    ).map((result) => addAction(new VolunteerTime(result.data?.updateVolunteerTime)))
  }

  public static delete(id: string): Observable<{
    type: string
    id: string
  }> {
    return Observable.from(
      mutate<DeleteVolunteerTimeMutation, DeleteVolunteerTimeMutationVariables>(
        DeleteVolunteerTimeDocument,
        {
          id,
        },
        () => [
          {
            query: VolunteerTimesDocument,
            variables: { where: { userId: { equals: 'me' } } },
          },
        ]
      )
    ).map((result) => removeAction(result.data?.deleteVolunteerTime?.id || ''))
  }
}
