import React, { FC } from 'react'

export interface EmphasisFormatProps {
  children: React.ReactNode
}

// `children` argument has the special format:
// `format **stuff here** with the help of EmphasisFormat component`
export const EmphasizeText = ({
  children,
  EmphasisFormat,
}: {
  children: string | string[]
  EmphasisFormat: FC<EmphasisFormatProps>
}): Array<JSX.Element | string> | string | null => {
  if (Array.isArray(children)) {
    return children.map((child, index) => (
      <EmphasizeText key={index} EmphasisFormat={EmphasisFormat}>
        {child}
      </EmphasizeText>
    ))
  }
  if (typeof children !== 'string') {
    return children
  }
  if (!children.includes('**')) {
    return children
  }

  return children.split('**').map((text, index) => {
    if (index % 2 !== 0) {
      return <EmphasisFormat key={`${text}-${index}`}>{text}</EmphasisFormat>
    }
    return text
  })
}

export default EmphasizeText
