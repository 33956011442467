/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'
import { View } from 'react-primitives'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getHours, getMinutes } from 'src/utils/convertDuration'
import SelectBox from 'src/retired/shared/SelectBox'
import { Row, TextField, Text, Form } from 'src/retired/elements'
import { validators } from 'src/utils'
import Platform from 'src/utils/Platform'
import User from 'src/entities/user/model'
import Deed from 'src/entities/deed/model'
import { ErrorText } from 'src/components/ErrorText'
import { Label } from 'src/retired/shared/Typography'

const roundedInputStyle = css`
  border-radius: 20px;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: ${Platform.OS === 'web' ? '11px 20px' : '0px 20px'};
  min-height: 40px;
  height: auto;
  flex-grow: 0;
`

interface VolunteerTimeOffProps {
  user: User
  deed: Deed
  selectedRoleId: string
  validateForm: () => {}
  updateForm: () => {}
}

const VolunteerTimeOffSelector = ({
  validateForm,
  updateForm,
  user,
  deed,
  selectedRoleId,
}: VolunteerTimeOffProps): React.ReactElement => {
  const { t } = useTranslation('volunteerTimeOffSelector')
  const { colors } = useDeedTheme()

  const userRemainingBalance = {
    hours: Math.floor(user?.volunteerTimeOffSummary?.balance / 60),
    minutes: user?.volunteerTimeOffSummary?.balance % 60,
  }

  const duration = deed.getTotalDurationInMinutesForUser(user?.id, selectedRoleId)

  const initialValues =
    duration === 0
      ? {
          hours: '0',
          minutes: '0',
          deed: undefined,
          status: 'REQUESTED',
          date: new Date(),
          userId: user.id,
        }
      : {
          hours: String(
            duration > user?.volunteerTimeOffSummary?.balance ? userRemainingBalance.hours : getHours(duration)
          ),
          minutes: String(
            duration > user?.volunteerTimeOffSummary?.balance ? userRemainingBalance.minutes : getMinutes(duration)
          ),
          deed: {
            id: deed.id,
            name: deed.name,
            startingAt: deed.startingAt,
            endingAt: deed.endingAt,
          },
          status: 'REQUESTED',
          date: new Date(),
          userId: user.id,
        }
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {
        hours:
          validators.balanceRemaining(values, user) ||
          validators.isNumber(values.hours) ||
          (values.hours < 0 || values.hours > 24 ? t`invalidNumber` : '') ||
          validators.maxRequestLength(values, deed),
        minutes:
          validators.balanceRemaining(values, user) ||
          validators.notEmpty(values.minutes) ||
          validators.maxRequestLength(values, deed),
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    onSubmit: () => {
      formik.setSubmitting(false)
    },
  })

  useEffect(() => {
    updateForm({ ...formik.values, isVtoRequest })
  }, [formik.values])

  const [isVtoRequest, setIsVtoRequest] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null)

  const handleRadioChange = (enableVto: boolean) => {
    if (!enableVto) {
      void formik.setValues(initialValues)
    }
    updateForm({ ...formik.values, isVtoRequest: enableVto })
    setIsVtoRequest(enableVto)
  }

  validateForm(
    !isVtoRequest ||
      (Object.keys(formik.errors).length === 0 && Number(formik.values.hours) > 0) ||
      (Object.keys(formik.errors).length === 0 && Number(formik.values.minutes) > 0)
  )

  const onLayout = (event) => {
    if (dimensions) {
      return
    }
    const { width, height } = event.nativeEvent.layout

    setDimensions({ width, height })
  }

  const showVTOMinutesSelector = !user?.getSetting('vtoIncrementsInHours')
  const vtoBalanceInHour = user?.volunteerTimeOffSummary?.balance / 60

  useEffect(() => {
    if (user?.volunteerTimeOffSummary?.balance) {
      setIsVtoRequest(true)
    }
  }, [user])

  return deed.type === 'Event' ? (
    <View
      style={{ paddingRight: 30, paddingLeft: 30, width: '100%', display: 'flex', marginTop: 10 }}
      onLayout={onLayout}
    >
      <div>
        <Label
          style={{
            color: !user?.volunteerTimeOffSummary?.balance && colors.gray,
            fontWeight: 500,
            fontSize: 16,
          }}
        >{t`vto`}</Label>
      </div>

      <FormControlLabel
        label={
          <Label
            style={{
              color: !user?.volunteerTimeOffSummary?.balance && colors.gray,
            }}
          >
            {t('useVolunteerTimeOff', { vtoBalance: vtoBalanceInHour })}
          </Label>
        }
        control={
          <Radio
            onChange={() => {
              handleRadioChange(true)
            }}
            checked={isVtoRequest && user?.volunteerTimeOffSummary?.balance}
            name="useVto"
            disabled={!user?.volunteerTimeOffSummary?.balance}
          />
        }
      />

      {!!user?.volunteerTimeOffSummary?.balance && (
        <FormControlLabel
          style={{ marginTop: -10 }}
          label={<Label>{t('dontUseVto')}</Label>}
          control={
            <Radio
              onChange={() => {
                handleRadioChange(false)
              }}
              checked={!isVtoRequest}
            />
          }
        />
      )}
      {isVtoRequest && (
        <Form>
          <View style={{ marginTop: 8 }}>
            <Row>
              <View style={{ width: showVTOMinutesSelector ? '48%' : '100%' }}>
                <Text fontSize={12}>{t`hours`}</Text>
                <TextField
                  placeholder={t`enterHours`}
                  onChangeText={(fieldName: string, value: string): void => {
                    if (/^[0-9]+$/.test(value) || !value) {
                      formik.setFieldValue(fieldName, value)
                    }
                  }}
                  onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                  name="hours"
                  value={String(isVtoRequest ? formik.values.hours : 0)}
                  pattern="\d{1,3}"
                  step="1"
                  min="0"
                  max="24"
                  keyboardType="number-pad"
                  returnKeyType="done"
                  style={roundedInputStyle}
                  disabled={!isVtoRequest}
                />
              </View>
              {showVTOMinutesSelector && (
                <View style={{ width: '48%', marginLeft: 5 }}>
                  <Text fontSize={12}>{t`minutes`}</Text>
                  <SelectBox
                    onSelect={async (value) => formik.setFieldValue('minutes', value)}
                    onDeselect={async () => formik.setFieldValue('minutes', '')}
                    onFocus={async () => formik.setFieldTouched('minutes', true, false)}
                    value={String(isVtoRequest ? formik.values.minutes : 0)}
                    disabled={!isVtoRequest}
                    placeholder={t`selectMinutes`}
                    options={[
                      { value: '0', title: '0' },
                      { value: '15', title: '15' },
                      { value: '30', title: '30' },
                      { value: '45', title: '45' },
                    ]}
                    style={{ marginTop: 8, marginBottom: 16, minWidth: 'auto', width: '100%', marginLeft: 0 }}
                  />
                </View>
              )}
            </Row>
            {!!(formik.errors.hours || formik.errors.minutes) && (formik.touched.hours || formik.touched.minutes) && (
              <ErrorText text={formik.errors.hours || formik.errors.minutes || ''} style={{ marginTop: 5 }} />
            )}
          </View>
        </Form>
      )}
    </View>
  ) : (
    <View style={{ maxWidth: '80%' }}>
      <Text>{t`onlyVTOforVolunteerEvents`}</Text>
    </View>
  )
}

export default VolunteerTimeOffSelector
