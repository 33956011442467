import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import IconButton from 'src/retired/shared/IconButton'
import Button from 'src/retired/shared/Button'
import { Image, Modal, ModalProps, Row } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { StyledContent, StyledHeader } from '../Profile.styled'

interface ModalErrorProps extends ModalProps {
  description: string
}

export const ModalError = ({ visible, onClose, description }: ModalErrorProps): JSX.Element => {
  const { t } = useTranslation('volunteerTimeOffProfile')
  const { colors, images, metrics } = useDeedTheme()

  return (
    <Modal
      maskClosable
      transparent
      visible={visible}
      style={{ width: 550, maxWidth: '100%', borderRadius: 4 }}
      bodyStyle={{ borderRadius: 4 }}
      onClose={onClose}
    >
      <StyledContent style={{ paddingTop: 0 }}>
        <StyledHeader>
          <Image
            source={images.deedLogoRed}
            style={{ width: 40, height: 35, marginLeft: 'auto', marginRight: 'auto', marginTop: 8 }}
          />
        </StyledHeader>
        <Body2
          weight="normal"
          colour="grayMediumDark"
          marginTop={8}
          lineHeight={25}
          center
          style={{ maxWidth: 500, paddingVertical: metrics.isSmall ? 40 : 0 }}
        >
          {description}
        </Body2>
      </StyledContent>

      <Row style={{ marginTop: 20, paddingLeft: 25, paddingRight: 25, justifyContent: 'center', gap: 20 }}>
        <Button
          onPress={onClose}
          palette="primary"
          style={{ backgroundColor: colors.redStrong, color: colors.white, border: 0 }}
        >
          {t`common:Ok`}
        </Button>
      </Row>
      <View style={{ position: 'absolute', top: 20, left: 20 }}>
        <IconButton icon="closeNew" onPress={onClose} size={30} />
      </View>
    </Modal>
  )
}
