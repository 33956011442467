import type { PayrollApiDonation, PayrollFlatFileDonation } from 'src/entities/donation/model'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  REFRESH,
  REFRESH_SUCCESS,
  REFRESH_FAILED,
  CANCEL,
  CANCEL_SUCCESS,
  CANCEL_FAILED,
} from './constants'

export const initAction = (id: string) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const refreshAction = (id: string) => ({
  type: REFRESH,
  id,
})

export const refreshSuccessAction = () => ({
  type: REFRESH_SUCCESS,
})

export const refreshFailedAction = (error) => ({
  type: REFRESH_FAILED,
  error,
})

export type CancelAction = {
  type: typeof CANCEL
  donation: PayrollApiDonation | PayrollFlatFileDonation
}
export const cancelAction = (donation: CancelAction['donation']): CancelAction => ({
  type: CANCEL,
  donation,
})
export const cancelSuccessAction = () => ({
  type: CANCEL_SUCCESS,
})
export const cancelFailedAction = () => ({
  type: CANCEL_FAILED,
})
