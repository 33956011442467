import React, { useState, useEffect, useRef, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import type { PayPalNamespace } from '@paypal/paypal-js'
import { DonationProvider } from '@joindeed/calculate-fees'

import { styled } from 'src/theme/styled'
import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import Deed from 'src/entities/deed/model'
import DonationApi from 'src/entities/donation/api'
import { selectCurrentUser, selectUserBrand, selectUserLocations } from 'src/entities/user/selectors'
import config from 'src/config'
import { ActivityIndicator, Spacing } from 'src/retired/elements'
import useScript from 'src/utils/useScript'
import { Text } from 'src/retired/shared/Typography'
import getPayPalLanguageCode from 'src/utils/getPayPalLanguageCode'
import * as Sentry from 'src/utils/Sentry'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'
import type { FrontendFees } from 'src/containers/screens/Donate/calculateFeesWithHistory'
import truthy from 'src/utils/truthy'

import { registerPaypalDonationAction, donateFailedAction, payPalCancelAction } from '../actions'
import { type ProviderData } from '../payment/useProviderData'

import { BillingAddressForm, BillingAddress, isBillingAddressAdded } from './BillingAddressForm'
import { Donor } from './DonorDetailsForm'

interface PayPalActions {
  enable: () => void
  disable: () => void
}

interface PayPalFormProps {
  providerData: ProviderData<DonationProvider.PayPal | DonationProvider.CAF>
  disabled: boolean
  donor?: Donor
  amount: number
  amountValid: boolean
  submitting: boolean
  currencyCode: CurrencyCode
  coverFee: boolean
  privacy: string
  donorPrivacy: DonorPrivacy
  dedication: string
  designation: string
  deed?: Deed | null
  nonprofitId?: string
  campaignId?: string
  feeCovered?: boolean
  feesHistoryItem: FrontendFees
  desiredMatchAmount?: number
  desiredMatchingRule?: string
  nonprofitCountryCode: string
  giftAidApplies?: boolean
}

const PayPalForm = ({
  providerData,
  disabled = false,
  donor,
  amount,
  amountValid,
  deed = null,
  nonprofitId = '',
  campaignId,
  currencyCode,
  coverFee,
  privacy,
  donorPrivacy,
  dedication,
  feeCovered,
  designation,
  submitting,
  feesHistoryItem,
  desiredMatchAmount,
  desiredMatchingRule,
  nonprofitCountryCode,
  giftAidApplies,
}: PayPalFormProps): JSX.Element | null => {
  const { t, i18n } = useTranslation('donateScreen')
  const user = useSelector(selectCurrentUser)
  const locations = useSelector(selectUserLocations)
  const userCountryCode = user?.location && locations?.get(user.location)?.countryCode
  const dispatch = useDispatch()

  const userBrand = useSelector(selectUserBrand)
  const hidePaypalPaymentMethod = userBrand?.settings?.hidePaypalPaymentMethod

  const enableFunding = ['venmo']
  const enableFundingQuery = `&enable-funding=${enableFunding.join()}`

  const disableFunding = [
    'credit',
    userCountryCode === 'BR' && 'card', // See https://app.shortcut.com/deed/story/61631
  ]
    .filter(truthy)
    .concat((userBrand?.settings?.paypalDisableFunding as unknown as string[]) ?? [])
  const disableFundingQuery = `&disable-funding=${disableFunding.join()}`

  const [PayPalButton, setPayPalButton] = useState(() => ActivityIndicator)
  const [touched, setTouched] = useState(false)
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    street1: '',
    city: '',
    country: 'US',
    postalCode: '',
  })

  const payPalLanguageCode = getPayPalLanguageCode(i18n.language)
  const payPalLocaleQueryParam = payPalLanguageCode ? `&locale=${payPalLanguageCode}` : ''

  const displayBillingAddressForm =
    // https://app.shortcut.com/deed/story/13037
    (providerData.donationProvider === DonationProvider.PayPal &&
      providerData.payPalModel === 'DIRECT' &&
      donorPrivacy !== 'Anonymous') ||
    // https://app.shortcut.com/deed/story/61669
    nonprofitCountryCode === 'CA'

  const submitDisabled = useMemo(
    () => displayBillingAddressForm && !isBillingAddressAdded(billingAddress),
    [billingAddress, displayBillingAddressForm]
  )

  const [loaded, error, paypal] = useScript<PayPalNamespace>(
    // @see: https://developer.paypal.com/docs/platforms/checkout/reference/javascript-sdk/
    `https://www.paypal.com/sdk/js?client-id=${config.paypalClientId}${payPalLocaleQueryParam}&currency=${currencyCode}${disableFundingQuery}${enableFundingQuery}`,
    'paypal',
    undefined,
    true
  )

  const actionsRef = useRef<PayPalActions>()

  useEffect(() => {
    if (paypal && loaded && !error) {
      setPayPalButton(() => paypal.Buttons?.driver('react', { React, ReactDOM }))
    }
  }, [paypal, loaded, error])

  useEffect(() => {
    if (error) {
      const e = new Error(`Error loading PayPal script`)
      console.error(e) // eslint-disable-line no-console
      Sentry.captureException(e)
    }
  }, [error])

  useEffect(() => {
    if (displayBillingAddressForm) {
      if (submitDisabled) {
        actionsRef.current?.disable()
      } else {
        actionsRef.current?.enable()
      }
    }
  }, [submitDisabled, displayBillingAddressForm])

  if (error) {
    return (
      <Text colour="redDark" fontSize={16} style={{ fontWeight: 'bold' }}>
        {t`errorLoadingPayPalAPI`}
      </Text>
    )
  }

  if (!loaded) {
    return <ActivityIndicator />
  }

  if (!amountValid) {
    return null
  }

  if (submitting) {
    return <ActivityIndicator />
  }

  return (
    <View style={{ position: 'relative', display: 'flex' }}>
      {displayBillingAddressForm && (
        <>
          <BillingAddressForm billingAddress={billingAddress} setBillingAddress={setBillingAddress} touched={touched} />
          <Spacing marginBottom={10} />
        </>
      )}

      <View
        onClick={() => setTouched(true)}
        style={{
          ...(hidePaypalPaymentMethod && { overflow: 'hidden' }),
          ...(submitDisabled && {
            cursor: 'not-allowed',
          }),
        }}
      >
        <View
          style={{
            ...(hidePaypalPaymentMethod && { marginTop: -55 }),
            ...(submitDisabled && {
              pointerEvents: 'none',
              opacity: '0.4',
            }),
          }}
        >
          <PayPalButton
            disabled={submitDisabled}
            onInit={(_data: unknown, actions: PayPalActions) => {
              actionsRef.current = actions
            }}
            createOrder={async () =>
              DonationApi.createPaypalOrder({
                amount,
                desiredMatchAmount,
                desiredMatchingRule,
                nonprofit: nonprofitId,
                deed: deed?.id,
                currencyCode,
                coverFee,
                privacy,
                donorPrivacy,
                dedication,
                designation,
                donor,
                campaign: campaignId,
                feeCovered,
                billingAddress: displayBillingAddressForm ? billingAddress : undefined,
                feesHistoryItem,
                giftAidApplies,
              })
                .toPromise()
                .then(({ orderId }: { orderId: string }) => orderId)
            }
            onApprove={({ orderID: orderId }: { orderID: string }) => dispatch(registerPaypalDonationAction(orderId))}
            onCancel={({ orderID: orderId }: { orderID: string }) => dispatch(payPalCancelAction(orderId))}
            onError={(errorMessage: any) => {
              const e =
                errorMessage instanceof Error
                  ? errorMessage
                  : new Error(`An error occurred while donating: ${JSON.stringify(errorMessage)}`)
              dispatch(donateFailedAction(e))
            }}
            style={{
              tagline: false,
              label: 'paypal',
              shape: 'pill',
              color: 'black',
              layout: 'vertical',
              height: 50,
            }}
          />
        </View>
      </View>

      {disabled && <DisabledOverlay />}
    </View>
  )
}

const DisabledOverlay = styled(View)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 100000;
  cursor: not-allowed;
`

export default PayPalForm
