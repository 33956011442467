import React from 'react'
import { View } from 'react-primitives'

import { Body1 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const FilterItemContainer = ({
  children,
  title,
  divider = true,
  style,
}: {
  children?: any
  title?: string
  divider?: boolean
  style?: React.CSSProperties
}) => {
  const { colors, metrics } = useDeedTheme()
  return (
    <View
      style={{
        paddingHorizontal: 10,
        paddingVertical: metrics.isSmall ? 24 : 32,
        borderWidth: divider ? 1 : 0,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderTopColor: colors.lightBorderGray,
        ...style,
      }}
    >
      {title ? <Body1 marginBottom={16}>{title}</Body1> : null}
      {children}
    </View>
  )
}
