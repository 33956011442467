import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'

import { AlignedImage, Button, Spacing } from 'src/retired/elements'
import ImageOverlay from 'src/retired/blocks/ImageOverlay'
import { injectReducer, injectEpics } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { ModalWithCloseButton } from 'src/components/Modal/ModalWithCloseButton'
import { RenderHtml } from 'src/components/RenderHtml'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSubmitting, selectError } from './selectors'

export const Disclaimer = ({ user, submitting, actions }) => {
  const [visible, setVisible] = useState(true)
  const { t } = useTranslation('imageOverlay')

  if (submitting || !user || !user?.organization?.disclaimer?.content) {
    return null
  }

  const { disclaimer } = user.organization

  if (user.consent.disclaimer) {
    if (disclaimer.disclaimerExpire) {
      const acknowledgedDate = new Date(user.consent.disclaimer)
      acknowledgedDate.setMinutes(acknowledgedDate.getMinutes() + disclaimer.disclaimerExpire)
      if (acknowledgedDate > new Date()) {
        return null
      }
    } else {
      return null
    }
  }

  const handleOnPress = () => {
    actions.submitAction()
    setVisible(false)
  }

  return disclaimer.contentType === 'IMAGE' ? (
    <ImageOverlay image={disclaimer.content} close={handleOnPress} buttonText={disclaimer.buttonText} />
  ) : (
    <ModalWithCloseButton visible={visible} style={{ maxWidth: 800 }}>
      {user.organization?.mainPicture && (
        <AlignedImage style={{ margin: 'auto' }} source={user.organization?.mainPicture} width={46} height={46} />
      )}
      <Spacing marginVertical={25}>
        <RenderHtml source={{ html: disclaimer.content }} />
      </Spacing>
      <Button onPress={handleOnPress} style={{ margin: 'auto', maxWidth: 450 }} fluid>
        {disclaimer.buttonText || t`iAgree`}
      </Button>
    </ModalWithCloseButton>
  )
}

Disclaimer.propTypes = {
  user: PropTypes.object,
  submitting: PropTypes.bool,
  actions: PropTypes.object,
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'disclaimer', reducer })
const withEpics = injectEpics({ key: 'disclaimer', epics })

export default compose(withReducer, withEpics, withConnect)(Disclaimer)
