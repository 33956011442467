import { combineReducers } from 'redux'
import type { Reducer } from 'redux'

import type { AuthAction } from 'src/entities/auth/actions'
import type { DeedActions } from 'src/entities/deed/actions'
import Auth from 'src/entities/auth/model'

import auth from './auth/reducer'
import campaign, { CampaignState } from './campaign/reducer'
import cause, { CauseState } from './cause/reducer'
import skill from './skill/reducer'
import deed, { DeedState } from './deed/reducer'
import form from './form/reducer'
import organization, { OrganizationState } from './organization/reducer'
import donation, { DonationState } from './donation/reducer'
import location, { LocationMap } from './location/reducer'
import setting from './setting/reducer'
import user, { UserMap } from './user/reducer'
import relation from './relation/reducer'
import volunteerTime, { VolunteerTimeState } from './volunteerTime/reducer'
import donationCredit from './donationCredit/reducer'
import donationSchedule, { DonationScheduleState } from './donationSchedule/reducer'
import volunteerTimeOff from './volunteerTimeOff/reducer'

export interface EntityState {
  auth: Auth
  campaign: CampaignState
  cause: CauseState
  deed: DeedState
  organization: OrganizationState
  donation: DonationState
  location: LocationMap
  skill: any
  user: UserMap
  relation: any
  setting: any
  form: any
  volunteerTime: VolunteerTimeState
  donationCredit: any
  donationSchedule: DonationScheduleState
  volunteerTimeOff: any
}

export const entitiesReducer: Reducer<EntityState, AuthAction | DeedActions> = combineReducers({
  auth,
  campaign,
  cause,
  deed,
  organization,
  donation,
  location,
  skill,
  user,
  relation,
  setting,
  form,
  volunteerTime,
  donationCredit,
  donationSchedule,
  volunteerTimeOff,
})
