import React from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import HistoryIcon from '@mui/icons-material/History'
import { PaymentMethodType } from '@joindeed/calculate-fees'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Label, H5 } from 'src/retired/shared/Typography'
import { CurrencyFormat, CurrencyCode, Currency, CurrencyRates } from 'src/containers/modules/CurrencyFormat'
import { Divider, Row } from 'src/retired/elements'
import Tooltip from 'src/retired/elements/Tooltip'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import { SelectFrequency } from 'src/containers/screens/Donate/FrequencyInput'
import type { MatchData } from 'src/containers/screens/Donate/match'
import Organization from 'src/entities/organization/model'
import { MatchedAmountBlock } from 'src/components/MatchedAmountBlock/MatchedAmountBlock'
import { DonationPayrollPaymentOptionStatus, PayrollPaymentOption } from 'src/generated/graphql'

import { DonationFrequency, PaymentType } from '../constants'
import { SelectPayrollPaymentOptions, SelectPayrollPaymentOptionCode } from '../PaymentOptionInput'

import { PayrollDeductedDisplayText } from './PayrollDeductedDisplayText/PayrollDeductedDisplayText'

import { TotalPaymentContainer } from '.'
interface SidebarProps {
  matchData: MatchData
  netAmount: number
  donationPaymentMethod?: PaymentMethodType
  baseCurrency: CurrencyCode
  currency: Currency
  total: number
  processingFee: number
  nonprofits: Organization[]
  coverFees: boolean
  donationFrequency: DonationFrequency
  donationDate: Date
  frequencyOptions: DonationFrequency[]
  paymentType: PaymentType
  nonprofitNamesFormatted: string
  feeCoveredSetting: boolean
  onDonationFrequencyChange: (value: DonationFrequency) => void
  brand?: Organization
  applicationFeeSetting?: number
  usdRates: CurrencyRates
  payrollPaymentOptions: PayrollPaymentOption[]
  selectedPayrollPaymentOption?: PayrollPaymentOption
  onPayrollPaymentOptionChange: SelectPayrollPaymentOptionCode
}

const isPayrollPaymentOptionsActive = (paymentType: PaymentType, payrollPaymentOptions: PayrollPaymentOption[]) =>
  paymentType === PaymentMethodType.Payroll &&
  payrollPaymentOptions.some((option) => option.status === DonationPayrollPaymentOptionStatus.Active)

export const Sidebar = ({
  matchData,
  netAmount,
  donationPaymentMethod,
  baseCurrency,
  currency,
  total,
  processingFee,
  nonprofits,
  coverFees,
  donationFrequency,
  donationDate,
  frequencyOptions,
  paymentType,
  nonprofitNamesFormatted,
  feeCoveredSetting,
  onDonationFrequencyChange,
  brand,
  applicationFeeSetting,
  usdRates,
  payrollPaymentOptions,
  selectedPayrollPaymentOption,
  onPayrollPaymentOptionChange,
}: SidebarProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  return (
    <View
      style={{
        borderRadius: 8,
        borderColor: colors.gray02,
        borderWidth: 1,
        marginBottom: 20,
        backgroundColor: colors.white,
        overflow: 'hidden',
      }}
    >
      <View style={{ paddingHorizontal: 32, paddingVertical: 20 }}>
        <H5 weight="500" center paddingBottom={20}>
          {t`common:donation`}
        </H5>

        <Label paddingBottom={8}>{t`supporting`}</Label>

        <View style={{ alignItems: 'flex-start' }}>
          {nonprofits.map((nonprofit) => (
            <View key={nonprofit.id} style={{ marginBottom: 5 }}>
              <OrganizationChip organization={nonprofit} />
            </View>
          ))}
        </View>
      </View>

      {isPayrollPaymentOptionsActive(paymentType, payrollPaymentOptions) && (
        <View style={{ paddingHorizontal: 32 }}>
          <Label paddingBottom={8}>{t`paymentOptions`}</Label>
          <SelectPayrollPaymentOptions
            payrollPaymentOptions={payrollPaymentOptions}
            selectedPayrollPaymentOption={selectedPayrollPaymentOption}
            setSelectedPayrollPaymentOptionCode={onPayrollPaymentOptionChange}
          />
        </View>
      )}

      {frequencyOptions.length > 1 && (
        <View style={{ paddingHorizontal: 32, paddingTop: 10 }}>
          <SelectFrequency
            frequencyOptions={frequencyOptions}
            donationFrequency={donationFrequency}
            setDonationFrequency={onDonationFrequencyChange}
            paymentType={paymentType}
          />
        </View>
      )}

      {/* Amount donated block */}
      <View style={{ paddingHorizontal: 32, paddingVertical: 20 }}>
        <View style={{ paddingVertical: 8 }}>
          <View>
            <Label paddingBottom={8}>{t`amountDonated`}</Label>

            <H5 weight="500">
              <Trans
                t={t}
                i18nKey="totalPerPeriod"
                values={{
                  period:
                    donationFrequency !== 'Onetime' ? (paymentType === 'Payroll' ? t`perPaycheck` : t`perMonth`) : '',
                }}
                components={{
                  Amount: total ? (
                    <CurrencyFormat baseCurrency={baseCurrency} amount={netAmount} disambiguateCurrency />
                  ) : (
                    '-'
                  ),
                }}
              />
            </H5>
          </View>
        </View>

        {coverFees && feeCoveredSetting && (
          <View>
            <Label>
              {t('100%OfYourDonation', {
                nonprofitNames: nonprofitNamesFormatted,
              })}
            </Label>
          </View>
        )}

        {currency?.code !== baseCurrency && (
          <View>
            <Divider marginTop={10} marginBottom={10} />

            <Label>
              <Label colour={colors.brandColor}>{t`pleaseNote`}</Label>

              {t(
                donationFrequency !== 'Onetime'
                  ? 'yourRecurringDonationWillBeProcessed'
                  : 'yourOnetimeDonationWillBeProcessedInNonprofitCurrency',
                { baseCurrency }
              )}
            </Label>
          </View>
        )}

        {/* TODO: Use real dates from Payroll API */}
        {paymentType === 'Payroll' && (
          <View>
            <Divider marginTop={16} marginBottom={10} />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 24 }}>
              <HistoryIcon />

              <div>
                <Label>
                  <PayrollDeductedDisplayText donationFrequency={donationFrequency} donationDate={donationDate} />
                </Label>
              </div>
            </div>

            <Divider marginTop={10} marginBottom={10} />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 24 }}>
              <MoneyOffIcon />

              <div>
                <Label marginTop={12}>
                  <Trans t={t} i18nKey="ifDonationExceedsAvailableNetPay" />
                </Label>
              </div>
            </div>
          </View>
        )}
      </View>

      {/* Total payment block */}
      {!feeCoveredSetting && (
        <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
          <TotalPaymentContainer>
            <View>
              <Row style={{ justifyContent: 'space-between' }}>
                <View>
                  <Label>{t`totalPayment`}</Label>

                  <H5 weight="500">
                    {total ? <CurrencyFormat baseCurrency={baseCurrency} amount={total} disambiguateCurrency /> : '-'}
                  </H5>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    flexShrink: 1,
                    marginBottom: 3,
                  }}
                >
                  <Tooltip title={t`processingFee`}>
                    {t(`nonprofitsTypicallySpend`, { applicationFees: applicationFeeSetting })}
                  </Tooltip>
                  <Label>
                    <Trans
                      t={t}
                      i18nKey="processingFeeSidebar"
                      components={{
                        DonationAmount: <CurrencyFormat baseCurrency={baseCurrency} amount={netAmount} />,
                        Fee: <CurrencyFormat baseCurrency={baseCurrency} amount={processingFee} />,
                      }}
                    />
                  </Label>
                </View>
              </Row>
            </View>
          </TotalPaymentContainer>
        </View>
      )}

      {/* Matched amount block */}
      {matchData?.matchingPercentage && matchData?.matchingMaximum && matchData?.matchedAmountMaximum > 0 ? (
        <View style={{ padding: 20, borderColor: colors.gray02, borderTopWidth: 1 }}>
          <MatchedAmountBlock
            matchData={matchData}
            netAmount={netAmount}
            brandImage={brand?.mainPicture || undefined}
            matchingInactive={!matchData.matchingApplicableForPaymentMethod}
            baseCurrency={baseCurrency}
            customCurrency={currency.code}
            recurring={donationFrequency !== 'Onetime'}
            donationPaymentMethod={donationPaymentMethod}
            hasCurrencyConversion={matchData.capBalance?.currencyCode !== currency.code}
            bigMatchedText
            usdRates={usdRates}
          />
        </View>
      ) : null}
    </View>
  )
}

export default Sidebar
