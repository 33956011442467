import React, { FC, useState } from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Gradient from 'src/retired/elements/Gradient'
import { H4, Text } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { useHistory, useParams } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import IconButton from 'src/retired/shared/IconButton'
import Campaign from 'src/entities/campaign/model'
import { Image, Loading } from 'src/retired/elements'
import { colors } from 'src/theme'
import { DeviceInfo } from 'src/utils'
import { resize, useBannerSize } from 'src/utils/resize'
import { DeedMap } from 'src/entities/deed/reducer'

import { EmphasizeText, EmphasisFormatProps } from './EmphasizeText'
import { DonationGoalProgress } from './DonationGoalProgress'

interface Props {
  campaignMetadataLoading: boolean
  campaign: Campaign
  onCtaPress: (value: any) => void
  fundraisers: DeedMap
}

const HeroBanner: FC<Props> = ({ campaignMetadataLoading, campaign, onCtaPress, fundraisers }) => {
  const { metrics } = useDeedTheme()
  const { lite } = useParams()

  // eslint-disable-next-line react/no-unstable-nested-components
  const EmphasizeFormat: FC<EmphasisFormatProps> = ({ children }) => (
    <Text colour={campaign.colorA} customFont="adineuePROCond-Bold">
      {children}
    </Text>
  )

  const history = useHistory()
  const goBack = makeGoBack(history, '/home', true)

  const bannerSize = useBannerSize()

  const [gradientHeight, setGradientHeight] = useState(0)

  return (
    <View
      style={{
        position: 'relative',
        marginLeft: metrics.isLarge ? -56 : -20,
        marginRight: metrics.isLarge ? -56 : -20,
        paddingHorizontal: metrics.isLarge ? 56 : 20,
        paddingTop: DeviceInfo.getNotchValue() + 100,
        paddingBottom: metrics.isSmall ? 100 : 130,
        backgroundColor: campaign.backgroundHeroColor || 'transparent',
      }}
      onLayout={(event) => setGradientHeight(Math.round(event.nativeEvent.layout.height))}
    >
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {!!campaign.backgroundImage && (
          <>
            <Image
              source={{
                uri: resize(campaign.backgroundImage, bannerSize),
              }}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                resizeMode: 'cover',
                backgroundColor: '#000',
              }}
            />
            <View
              style={{
                width: '100%',
                height: '100%',
                opacity: 0.4,
                backgroundColor: colors.black,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </>
        )}

        {!campaign.backgroundHeroColor && !campaign.backgroundImage && (
          <Gradient
            // In theory 100% height should work but on native the gradient height doesn't update automatically once the action stats are loaded
            height={gradientHeight}
            startColor={campaign.colorA}
            startOpacity={1}
            endColor={campaign.colorB}
            endOpacity={1}
            vertical
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </View>

      {metrics.isSmall && !lite ? (
        <View style={{ position: 'absolute', top: DeviceInfo.getNotchValue() + 25, left: 20 }}>
          <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />
        </View>
      ) : null}

      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <H4
          colour={campaign.nameColor || campaign.ctaButtonColor || 'white'}
          marginBottom={30}
          center
          lineHeight={23}
          customFont={campaign.customFont}
          style={{
            width: '80%',
            maxWidth: 1200,
          }}
        >
          {campaign.description}
        </H4>
        <Text
          colour="white"
          fontSize={
            metrics.isSmall
              ? campaign.customFont
                ? 50
                : 35
              : metrics.isMedium
              ? campaign.customFont
                ? 70
                : 45
              : campaign.customFont
              ? 100
              : 65
          }
          customFont={campaign.customFont}
          marginBottom={30}
          center
          lineHeight={metrics.isSmall ? 45 : metrics.isMedium ? 71 : 101}
        >
          {campaign?.title && <EmphasizeText EmphasizeFormat={EmphasizeFormat}>{campaign.title}</EmphasizeText>}
        </Text>
        {campaignMetadataLoading ? (
          <View style={{ marginBottom: 20, marginTop: 40, minHeight: 50 }}>
            <Loading fill={false} backgroundColor="transparent" />
          </View>
        ) : (
          campaign?.campaignStats && <DonationGoalProgress campaign={campaign} fundraisers={fundraisers} />
        )}

        {!metrics.isSmall && (
          <Button
            palette="primary"
            style={{
              backgroundColor: campaign.ctaButtonColor || 'black',
              padding: 20,
              height: 'auto',
              borderRadius: 19,
              borderWidth: 0,
            }}
            textStyle={{
              ...(campaign.customFont
                ? {
                    fontFamily: `${campaign.customFont}, Helvetica, sans-serif`,
                  }
                : {}),
              fontSize: 22,
              fontWeight: 500,
              color: campaign.ctaTextColor || 'white',
              lineHeight: 26,
            }}
            onPress={onCtaPress}
          >
            {campaign.cta}
          </Button>
        )}
      </View>
    </View>
  )
}
export default HeroBanner
