import { Map } from 'immutable'
import type { AnyAction } from 'redux'

import { SET_USER_CONTEXT } from './constants'

const initialState = Map<string, unknown>()

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_CONTEXT:
      return state.set('userContext', action.userContext)

    case 'RESET':
      return initialState

    default:
      return state
  }
}
