import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { CommunityScreenState, initialState } from './reducer'

const selectState = (state: State): CommunityScreenState => state.get('community') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
