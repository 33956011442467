import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Image, Row, Modal, ModalProps } from 'src/retired/elements'
import Touchable from 'src/retired/shared/Touchable'
import { Body1, H5, Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface ModalLeaveCommunityProps extends ModalProps {
  onConfirm: () => void
  communityTitle: string
}

export const ModalLeaveCommunity = ({
  visible,
  onClose,
  onConfirm,
  communityTitle,
}: ModalLeaveCommunityProps): JSX.Element => {
  const { t } = useTranslation('communityScreen')
  const { metrics, images, colors } = useDeedTheme()

  const ModalTitle = metrics.isSmall ? Body1 : H5
  const ModalSubtitle = metrics.isSmall ? Body2 : Body1

  return (
    <Modal
      maskClosable
      transparent
      visible={visible}
      style={{ width: 550, maxWidth: '100vw', borderRadius: 4 }}
      bodyStyle={{ borderRadius: 4 }}
      onClose={onClose}
    >
      <Row style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Touchable onPress={onClose}>
          <Image
            source={images.closeCirclePurple}
            style={{
              width: metrics.isSmall ? 24 : 40,
              height: metrics.isSmall ? 24 : 40,
            }}
          />
        </Touchable>
      </Row>
      <Row
        style={{
          backgroundColor: 'rgba(236, 101, 69, 0.2)',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#000000',
          borderRadius: 4,
          marginBottom: 20,
          padding: metrics.isSmall ? 12 : 24,
          position: 'relative',
          justifyContent: 'center',
          marginTop: 12,
        }}
      >
        <ModalTitle style={{ fontSize: metrics.isSmall ? 16 : 20 }}>{t`wereSad`}</ModalTitle>
        <Row
          style={{
            height: metrics.isSmall ? 32 : 48,
            flexGrow: 0,
            position: 'absolute',
            right: metrics.isSmall ? 12 : 24,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Image
            source={images.deedSadOrange}
            style={{
              width: metrics.isSmall ? 18 : 35,
              height: metrics.isSmall ? 18 : 35,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <Image
            source={images.deedRectangleOrange}
            style={{
              width: metrics.isSmall ? 10 : 20,
              height: metrics.isSmall ? 24 : 48,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </Row>
      </Row>
      <ModalSubtitle style={{ fontSize: metrics.isSmall ? 14 : 16 }}>
        {t('areYouSureYouWantToLeave', { communityTitle })}
      </ModalSubtitle>
      <View style={{ marginTop: 8, marginBottom: 24 }}>
        <Row
          style={{
            justifyContent: 'center',
            marginTop: 20,
            gap: metrics.isSmall ? 12 : 24,
          }}
        >
          <Touchable
            style={{
              padding: 12,
              display: 'flex',
              alignItems: 'center',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors.primaryLight,
              borderRadius: 12,
            }}
            onPress={onClose}
          >
            <ModalSubtitle
              style={{ width: metrics.isSmall ? 128 : 144 }}
              fontSize={metrics.isSmall ? 14 : 16}
              weight="500"
              center
            >
              {t`common:Cancel`}
            </ModalSubtitle>
          </Touchable>
          <Touchable
            style={{
              padding: 12,
              display: 'flex',
              alignItems: 'center',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors.primaryLight,
              borderRadius: 12,
            }}
            onPress={onConfirm}
          >
            <ModalSubtitle
              style={{ width: metrics.isSmall ? 128 : 144 }}
              fontSize={metrics.isSmall ? 14 : 16}
              weight="500"
              colour={colors.brandColor}
              center
            >
              {t`leaveCommunity`}
            </ModalSubtitle>
          </Touchable>
        </Row>
      </View>
    </Modal>
  )
}
