import { Observable } from 'rxjs'
import _ from 'lodash'

import { selectUserById } from 'src/entities/user/selectors'
import { selectRelations } from 'src/entities/relation/selectors'
import {
  selectUpcomingDeedsLoaded,
  selectPastDeedsLoaded,
  selectUserSubmittedDeedsLoaded,
} from 'src/entities/deed/selectors'
import { selectDonationsForUserLoaded } from 'src/entities/donation/selectors'
import { selectLocations } from 'src/entities/location/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import UserApi from 'src/entities/user/api'
import DeedsApi from 'src/entities/deed/api'
import DonationsApi from 'src/entities/donation/api'
import LocationApi from 'src/entities/location/api'
import RelationApi from 'src/entities/relation/api'
import SkillsApi from 'src/entities/skill/api'

import { initSuccessAction, initFailedAction } from './actions'
import { INIT } from './constants'
import { selectLoadedOnce } from './selectors'

const init = (action$, store) =>
  action$.ofType(INIT).exhaustMap(({ id }) => {
    const actions = []
    const state = store.getState()

    if (id) {
      const user = selectUserById(state, id)
      if (!user) {
        actions.push(UserApi.fetch(id))
      }
      if (!selectLoadedOnce(state) && selectRelations(state).size === 0) {
        actions.push(RelationApi.fetch())
      }
    }

    if (!selectUpcomingDeedsLoaded(state, id)) {
      actions.push(DeedsApi.fetchUpcoming(id))
    }

    if (!selectPastDeedsLoaded(state, id)) {
      actions.push(DeedsApi.fetchPast(id))
    }

    if (!selectDonationsForUserLoaded(state, id)) {
      actions.push(DonationsApi.fetchForUser(id))
    }

    if (!selectUserSubmittedDeedsLoaded(state)) {
      actions.push(DeedsApi.fetchSubmittedByUser())
    }

    if (selectLocations(state).size === 0) {
      actions.push(LocationApi.fetchAll())
    }

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
