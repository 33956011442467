import { UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAILED, UPLOAD_CANCELLED } from './constants'

export const uploadAction = (data) => ({
  type: UPLOAD,
  data,
})
export const uploadSuccessAction = () => ({
  type: UPLOAD_SUCCESS,
})
export const uploadFailedAction = (error, errorCode) => ({
  type: UPLOAD_FAILED,
  error,
  errorCode,
})
export const uploadCancelledAction = () => ({
  type: UPLOAD_CANCELLED,
})
