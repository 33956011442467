import React from 'react'
import PropTypes from 'prop-types'

import Image from 'src/retired/elements/Image'
import { icons } from 'src/theme'

const Icon = ({ icon, ...props }) => <Image source={icons[icon]} {...props} />
Icon.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}
export default Icon
