import { fromJS } from 'immutable'

import { RATE, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const initialState = fromJS({ loading: false, rating: 0, error: null })

export default (state = initialState, action) => {
  switch (action.type) {
    case RATE:
      return state.set('rating', action.rating)

    case SUBMIT:
      return state.merge({ loading: true, error: null })

    case SUBMIT_SUCCESS:
      return state.merge({ loading: false, rating: 0 })

    case SUBMIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
