import { Record, Set } from 'immutable'
import _ from 'lodash'

const properties = {
  id: '',
  name: '',
  autoApproval: false,
  description: undefined,
  requiredAmount: 0,
  durationMin: 0,
  durationMax: 0,
  estimatedHoursMin: 0,
  estimatedHoursMax: 0,
  durationUnit: '',
  applicants: Set(),
  approved: Set(),
  rejected: Set(),
  skills: Set(),
  mode: undefined,
  startingAt: null,
  endingAt: null,
}

export default class Role extends Record(properties, 'Role') {
  public readonly id!: string

  public readonly name!: string

  public readonly description!: string

  public readonly autoApproval!: boolean

  public readonly requiredAmount!: number

  public readonly durationMin!: number

  public readonly durationMax!: number

  public readonly estimatedHoursMin!: number

  public readonly estimatedHoursMax!: number

  public readonly durationUnit!: string

  public readonly applicants!: Set<string>

  public readonly approved!: Set<string>

  public readonly rejected!: Set<string>

  public readonly skills!: Set<string>

  public readonly mode!: string

  public readonly startingAt!: Date

  public readonly endingAt!: Date

  constructor(values: any = {}) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      applicants: values.applicants && Set(values.applicants),
      approved: values.approved && Set(values.approved),
      rejected: values.rejected && Set(values.rejected),
      skills: values.skills && Set(values.skills),
      startingAt: values.startingAt && new Date(values.startingAt),
      endingAt: values.endingAt && new Date(values.endingAt),
    })
  }
}
