import { Map } from 'immutable'

import { ADD, RESET_FORMS } from './constants'
import Form from './model'

export type FormMap = Map<string, Form>

export const initialState: FormMap = Map()

export default (state = initialState, action: any): FormMap => {
  switch (action.type) {
    case ADD:
      return state.set(action.form.id, action.form)

    case RESET_FORMS:
    case 'RESET':
      return initialState

    default:
      return state
  }
}
