import { INIT, INIT_SUCCESS, INIT_FAILED, CREATE, CREATE_SUCCESS, CREATE_FAILED } from './constants'

export const initAction = () => ({
  type: INIT,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const createAction = (firstName, lastName, email, password, company, externalId) => ({
  type: CREATE,
  firstName,
  lastName,
  email,
  password,
  company,
  externalId,
})
export const createSuccessAction = () => ({
  type: CREATE_SUCCESS,
})
export const createFailedAction = (error, errorCode) => ({
  type: CREATE_FAILED,
  error,
  errorCode,
})
