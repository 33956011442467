import React, { FC } from 'react'

import CommunityCardList from 'src/containers/screens/Community/CommunityCardList'
import CommunityCard from 'src/containers/screens/Community/CommunityCard'
import ScrollContainer from 'src/retired/shared/ScrollContainer'
import List from 'src/retired/blocks/List'
import { Spacing } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { CommunitiesQuery } from 'src/generated/graphql'

type Community = CommunitiesQuery['communities'][number]

interface CommunitiesBlockProps {
  communities: Community[]
  title: string
  link?: string
  back?: string
  isResizable?: boolean
}

const CommunitiesBlock: FC<CommunitiesBlockProps> = ({ communities, title, link, back, isResizable }) => {
  const { metrics } = useDeedTheme()
  const numberOfColumns = metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListView = (): JSX.Element => (
    <List
      numColumns={numberOfColumns}
      style={{
        marginLeft: metrics.isLarge ? -8 : undefined,
        marginRight: metrics.isLarge ? -8 : undefined,
        marginTop: metrics.isLarge ? 20 : undefined,
        marginBottom: metrics.isLarge ? 20 : undefined,
        zIndex: -1,
      }}
      itemProps={{
        style: {
          width: metrics.isSmall
            ? '50%'
            : metrics.isMedium
            ? '25%'
            : metrics.screenWidth > metrics.XLScreen
            ? '20%'
            : metrics.isLarge
            ? '25%'
            : '33.3%',
        },
      }}
      data={communities}
      keyExtractor={(item) => item.id}
      renderItem={({ item, index }) => (
        <CommunityCard
          community={item}
          forceLarge={false}
          isResizable={isResizable}
          index={index}
          fullWidthText={numberOfColumns === 1}
        />
      )}
      ListFooterComponent={<Spacing marginBottom={10} />}
      loading={false}
    />
  )

  const isRowView = !link

  return communities.length === 0 ? null : (
    <ScrollContainer
      title={title}
      subtitle={communities.length}
      cardType="card"
      back={back}
      link={link}
      dimension={communities.length}
      hideNavArrow={isRowView}
      render={(scrollViewOffset) =>
        isRowView ? (
          <ListView />
        ) : (
          <Spacing marginBottom={20}>
            <CommunityCardList communities={communities} scrollViewOffset={scrollViewOffset} />
          </Spacing>
        )
      }
    />
  )
}
export default CommunitiesBlock
