import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ActivityIndicator } from 'src/retired/elements'
import DeedsBlock from 'src/retired/blocks/DeedsBlock'
import { Box } from 'src/components/Box/Box'
import { ErrorText } from 'src/components/ErrorText'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { selectCommunityDeeds } from 'src/entities/deed/selectors'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { State } from 'src/reducers'

import reducer from './reducer'
import epics from './epics'
import { selectError, selectLoading } from './selectors'
import { initAction } from './actions'

export const CommunityDeeds = ({ communityId }: { communityId: string }) => {
  const { t } = useTranslation('communityScreen')
  const { metrics } = useDeedTheme()
  useInjectReducer({ key: 'community', reducer })
  useInjectEpics({ key: 'community', epics })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initAction(communityId))
  }, [dispatch, communityId])

  const deeds = useSelector((state: State) => selectCommunityDeeds(state, communityId))
  const isError = useSelector(selectError)
  const isLoading = useSelector(selectLoading)

  if (isError) {
    return <ErrorText text={t`common:anErrorOccurred`} />
  }
  if (isLoading) {
    return (
      <Box mx={3}>
        <ActivityIndicator />
      </Box>
    )
  }
  return deeds?.size ? (
    <Box mx={metrics.isSmall ? 0 : 3}>
      <DeedsBlock title={t`takeAction`} deeds={deeds} hidePartnerLogo noMargin noPadding />
    </Box>
  ) : null
}
