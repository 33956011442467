import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import Alert from 'src/retired/elements/Alert'
import { injectReducer, injectEpics } from 'src/utils'
import { PureRender } from 'src/utils/componentHelpers'

import reducer from './reducer'
import epics from './epics'
import { selectTitle, selectMessage, selectError, selectOpen, selectReload } from './selectors'

const Alerts = ({ title, message, error, open, reload = false }) => {
  if (open) {
    Alert.alert(title || message || error, title ? message || error : null)

    if (reload) {
      window.location.reload() // @TODO-AS: Not supported on native
    }
  }
  return null
}
Alerts.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  open: PropTypes.bool,
}

const withConnect = connect(
  createStructuredSelector({
    title: selectTitle,
    message: selectMessage,
    error: selectError,
    open: selectOpen,
    reload: selectReload,
  })
)
const withReducer = injectReducer({ key: 'alerts', reducer })
const withEpics = injectEpics({ key: 'alerts', epics })

export default compose(withReducer, withEpics, withConnect)(PureRender(Alerts))
