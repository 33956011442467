import { Map, OrderedSet } from 'immutable'
import { stringify } from 'query-string'
import { Observable } from 'rxjs'

import { setPaginationDetailsAction } from 'src/containers/screens/Nonprofits/actions'
import truthy from 'src/utils/truthy'
import { apolloClient } from 'src/entities/graphql'
import { SearchNonprofitsDocument } from 'src/generated/graphql'

import Api from '../api'
import { followOrganizationAction, unfollowOrganizationAction } from '../user/actions'

import Organization from './model'
import {
  addAction,
  addMultipleAction,
  nonprofitsLoadedAction,
  companiesLoadedAction,
  setDisplayedPartnerOrganizationIdsAction,
  setDisplayedLocalOrganizationIdsAction,
  forCausesLoadedAction,
} from './actions'

interface NonprofitsQuery {
  categories?: string[]
  pillars?: string[]
  sdgs?: string[]
  ergs?: string[]
  limit?: number
}

export default class OrganizationApi {
  static fetch(id: string) {
    return Api.get(`api/organizations/${id}`).map((organization) => addAction(new Organization(organization)))
  }

  static fetchCompanies() {
    return Api.get('api/organizations/companies').map((companies) => [
      addMultipleAction(Map(companies.map((company: any) => [company.id, new Organization(company)]))),
      companiesLoadedAction(),
    ])
  }

  static fetchNonprofits(query: NonprofitsQuery = {}) {
    const causesList = query?.categories || query?.ergs || query?.pillars || query?.sdgs

    return Api.get(`api/organizations/nonprofits?${stringify(query)}`).map((response) => {
      const nonprofits = response.results || []

      return [
        addMultipleAction(Map(nonprofits.map((nonprofit: any) => [nonprofit.id, new Organization(nonprofit)]))),
        !query && nonprofitsLoadedAction(),
        causesList && forCausesLoadedAction(causesList),
      ].filter(truthy)
    })
  }

  static fetchPartnerNonprofits(query: { countryCode?: string; stateCode?: string; pageSize?: number; page: number }) {
    return Api.get(
      `api/organizations/partners/nonprofits/?${stringify(query, { skipEmptyString: true, skipNull: true })}`
    ).map((nonprofits) => [
      addMultipleAction(Map(nonprofits.partners.map((nonprofit: any) => [nonprofit.id, new Organization(nonprofit)]))),
      setDisplayedPartnerOrganizationIdsAction(OrderedSet(nonprofits.partners.map((nonprofit: any) => nonprofit.id))),
      setPaginationDetailsAction(nonprofits.totalItems, nonprofits.totalPages, '', query.page + 1),
    ])
  }

  static listNonprofits(
    variables: {
      searchTerm?: string
      countryCode?: string
      stateCode?: string
      // @FIXME-CH: rename it to `causes`
      cause?: string
      includeApproved?: boolean
      acceptsDonations?: boolean
      pageSize?: number
      page: number
      queryId?: string
    },
    useNonprofitDbSearch = false
  ) {
    if (useNonprofitDbSearch) {
      const { cause, page, pageSize, queryId, ...vars } = variables
      const searchParams = {
        ...vars,
        causes: cause,
        limit: pageSize,
        offset: pageSize ? Math.max(page - 1, 0) * pageSize : 0,
      }

      return Observable.from(
        apolloClient.query({
          query: SearchNonprofitsDocument,
          variables: searchParams,
        })
      ).map(({ data: { searchNonprofits } }) => [
        addMultipleAction(Map(searchNonprofits.map((nonprofit: any) => [nonprofit.id, new Organization(nonprofit)]))),
        setDisplayedLocalOrganizationIdsAction(OrderedSet(searchNonprofits.map((nonprofit: any) => nonprofit.id))),
        setPaginationDetailsAction(
          searchNonprofits?.[0]?.meta?.totalItems,
          searchNonprofits?.[0]?.meta?.totalPages,
          '',
          variables.page + 1
        ),
      ])
    }

    return Api.get(
      `api/organizations/nonprofits/list/?${stringify(variables, { skipEmptyString: true, skipNull: true })}`
    ).map((nonprofits) => [
      addMultipleAction(Map(nonprofits.results.map((nonprofit: any) => [nonprofit.id, new Organization(nonprofit)]))),
      setDisplayedLocalOrganizationIdsAction(OrderedSet(nonprofits.results.map((nonprofit: any) => nonprofit.id))),
      setPaginationDetailsAction(nonprofits.totalItems, nonprofits.totalPages, nonprofits.queryId, variables.page + 1),
    ])
  }

  static searchNonprofits(
    variables: {
      searchTerm: string
      countryCode?: string
      stateCode?: string // state code is prefixed by country code
      includeApproved?: boolean
      acceptsDonations?: boolean
      excludeDonationProviders: string[]
      populateMatchingResults?: boolean
      cause?: string
    } = { searchTerm: '', excludeDonationProviders: [] },
    useNonprofitDbSearch = false
  ) {
    if (useNonprofitDbSearch) {
      return Observable.from(
        apolloClient.query({
          query: SearchNonprofitsDocument,
          variables,
          fetchPolicy: 'network-only',
        })
      ).map(({ data: { searchNonprofits } }) =>
        addMultipleAction(
          Map(
            searchNonprofits.map((nonprofit: any) => {
              const org = new Organization(nonprofit)
              return [org.id, org]
            })
          )
        )
      )
    }

    return Api.get(
      `api/organizations/searchNonprofits/?${stringify(variables, { skipEmptyString: true, skipNull: true })}`
    ).map((nonprofits) =>
      addMultipleAction(
        Map(
          nonprofits.map((nonprofit: any) => {
            const org = new Organization(nonprofit)
            return [org.id, org]
          })
        )
      )
    )
  }

  static follow(id: string) {
    return Api.post(`api/organizations/${id}/follow`).map((following) => followOrganizationAction(following))
  }

  static unfollow(id: string) {
    return Api.delete(`api/organizations/${id}/follow`).map(() => unfollowOrganizationAction(id))
  }

  static fetchEnrolled(ein: string, name: string) {
    return Api.get(
      `api/organizations/nonprofit/enrolled/${ein}/?${stringify({ name }, { skipEmptyString: true, skipNull: true })}`
    ).map((organization) => organization && new Organization(organization))
  }

  static fetchBrand() {
    return Api.get('api/organizations/brand')
  }
}
