import React from 'react'
import { View } from 'react-primitives'
import { OrderedSet } from 'immutable'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Platform } from 'src/utils'
import List from 'src/retired/blocks/List'
import { Spacing, RefreshControl } from 'src/retired/elements'
import OrganizationCard, { OrganizationCardProps } from 'src/retired/shared/OrganizationCard'
import Organization from 'src/entities/organization/model'
import { getInnerListStyle, getCardWidth } from 'src/retired/blocks/Feed/List/helpers'

const OrganizationCardIPadWrapper = (props: OrganizationCardProps) =>
  Platform.isPad ? (
    <View
      style={{
        width: '50%',
      }}
    >
      <OrganizationCard {...props} />
    </View>
  ) : (
    <OrganizationCard {...props} />
  )

export const NonprofitsList = ({
  nonprofits,
  onScroll,
  onScrollEndDrag,
  listRef,
  loading,
  corporateOnly,
  cardWidth,
  numberOfColumns,
  withoutPaddingBottom,
}: {
  nonprofits: OrderedSet<Organization | undefined>
  onScroll?: Function
  onScrollEndDrag?: Function
  loading?: boolean
  corporateOnly?: boolean
  listRef?: unknown
  cardWidth?: string | number
  numberOfColumns?: number
  withoutPaddingBottom?: boolean
}) => {
  const { metrics, colors } = useDeedTheme()

  const listProps = { onScroll, onScrollEndDrag }

  const numColumns = numberOfColumns || (metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1)

  if (Platform.OS !== 'web') {
    const cardImageAspectRatio = 327 / 150
    const cardImageHeight = Math.floor((metrics.screenWidth - 20 * (numColumns + 1)) / cardImageAspectRatio)
    const cardHeight = cardImageHeight + 124 + 12
    listProps.getItemLayout = (_, index: number) => ({ length: cardHeight, offset: cardHeight * index, index })
    listProps.listKey = 'nonprofits'
  }

  return (
    <List
      {...listProps}
      initialNumToRender={3}
      windowSize={3}
      ref={listRef}
      numColumns={numColumns}
      style={getInnerListStyle(metrics.isSmall, !!withoutPaddingBottom)}
      itemProps={{
        style: {
          width: cardWidth ?? getCardWidth(metrics),
        },
      }}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      data={nonprofits.toArray()}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => (
        <OrganizationCardIPadWrapper size="variable" organization={item} hidePartnerLogo={corporateOnly} />
      )}
      ListFooterComponent={<Spacing marginBottom={10} />}
      loading={loading}
      refreshControl={<RefreshControl refreshing={!!loading} fullHeight={false} tintColor={colors.pink} />}
    />
  )
}
