import _ from 'lodash'
import type { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { Map } from 'immutable'

import { selectSkills } from 'src/entities/skill/selectors'
import { selectCampaignLoaded } from 'src/entities/deed/selectors'
import SkillsApi from 'src/entities/skill/api'
import DeedsApi from 'src/entities/deed/api'
import type { State } from 'src/reducers'
import { campaignLoadedAction, addMultipleAction } from 'src/entities/deed/actions'
import Campaign from 'src/entities/campaign/model'
import Deed from 'src/entities/deed/model'
import { addAction as addCampaignAction } from 'src/entities/campaign/actions'
import { selectCampaignById } from 'src/entities/campaign/selectors'
import { LOAD_DONATIONS_FOR_CAMPAIGN } from 'src/entities/donation/constants'
import {
  LoadDonationsForCampaign,
  donationsForCampaignLoadingFailedAction,
  donationsForCampaignLoadingSuccessAction,
} from 'src/entities/donation/actions'

import { InitAction, initSuccessAction, initFailedAction } from './actions'
import { INIT } from './constants'

function loadData(id: string, state: State) {
  const alreadyLoadedCampaign = selectCampaignById(state, id)

  // If we know already, that the campaign has no nonprofits, we don't need to make the call to populate them
  const shouldSkipNonprofitFetch = alreadyLoadedCampaign && alreadyLoadedCampaign?.nonprofitsIds?.length === 0

  const campaignInfoObservable = Observable.from(DeedsApi.fetchCampaignInfo(id))
  const campaignStatsObservable = Observable.from(DeedsApi.fetchCampaignStats(id))

  const campaignNonprofitsObservable = Observable.from(
    // Instead of empty promise we could use undefined, too. But lets satisfy ts
    shouldSkipNonprofitFetch ? new Promise(() => {}) : DeedsApi.fetchCampaignNonprofits(id)
  )

  // Start the campaignStats and campaignNonprofits observables but delay their processing
  const combinedObservables = shouldSkipNonprofitFetch
    ? Observable.forkJoin(campaignStatsObservable)
    : Observable.forkJoin(campaignStatsObservable, campaignNonprofitsObservable)

  return Observable.merge(
    // The response from campaignInfo is emitted as soon as it arrives.
    campaignInfoObservable.map((campaignInfoResp) => [
      addCampaignAction(new Campaign(campaignInfoResp)),
      campaignLoadedAction(id),
    ]),
    // After the campaignInfo response is emitted, the combined responses from campaignStats and campaignNonprofits are processed and emitted.
    campaignInfoObservable.switchMap((campaignInfoResp) =>
      combinedObservables.map(([campaignStatsResp, campaignNonprofitsResp]) => {
        // Currently we could also hardcode an empty array as we only skip the np-fetch if we know there are none.
        // But then we need to be careful if there ever will be pre-loaded non-empty nonprofits.
        const rawAlreadyLoadedNonprofits = alreadyLoadedCampaign?.nonprofits?.map((np) => (np.toJS ? np.toJS() : np))
        const nonprofits = shouldSkipNonprofitFetch ? rawAlreadyLoadedNonprofits : campaignNonprofitsResp?.nonprofits
        return [
          addCampaignAction(
            new Campaign({
              ...campaignInfoResp,
              ...campaignStatsResp,
              nonprofits: nonprofits || [],
            })
          ),
          addMultipleAction(Map(campaignStatsResp.deeds.map((deed: any) => [deed.id, new Deed(deed)]))),
          initSuccessAction(),
        ]
      })
    )
  )
}

const init = (action$: ActionsObservable<InitAction>, store: Store<State>) =>
  action$.ofType(INIT).exhaustMap(({ id }) => {
    const actions = []

    const state = store.getState()
    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    if (!selectCampaignLoaded(state, id)) {
      actions.push(loadData(id, state))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(...actions)
      .mergeMap((resultingActions) => [..._.flattenDeep(resultingActions)])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const loadDonationsForCampaign = (action$: ActionsObservable<LoadDonationsForCampaign>, store: Store<State>) =>
  action$.ofType(LOAD_DONATIONS_FOR_CAMPAIGN).mergeMap(({ campaignId, limit }) => {
    const actions = []
    const state = store.getState()

    const alreadyLoadedCampaign = selectCampaignById(state, campaignId)

    if (alreadyLoadedCampaign) {
      actions.push(
        DeedsApi.fetchCampaignDonations(campaignId, limit).map((data) => [
          addCampaignAction(
            new Campaign({
              ...alreadyLoadedCampaign.toJS(),
              allDonationsList: data,
            })
          ),
        ])
      )
    }

    if (actions.length === 0) {
      return Observable.of(donationsForCampaignLoadingSuccessAction(campaignId))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        donationsForCampaignLoadingSuccessAction(campaignId),
      ])
      .catch(() => Observable.of(donationsForCampaignLoadingFailedAction(campaignId)))
  })

export default [init, loadDonationsForCampaign]
