import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useLocation, useHistory } from 'src/navigation'
import { Spacing, LinkButton, DashedContainer, Text } from 'src/retired/elements'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { colors } from 'src/theme'
import Donation from 'src/retired/blocks/Donation'

export const AllDonations = ({ donations, isLoading, isMyProfile }) => {
  const { t } = useTranslation('donationsProfile')
  const history = useHistory()
  const { hash } = useLocation()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    hash !== '#all' && history.replace('/profile/donations#all')
  }, [hash, history])

  return donations.size > 0 ? (
    <>
      {isLoading ? (
        <Spacing marginBottom={20}>
          <ActivityIndicator />
        </Spacing>
      ) : null}
      {donations.toArray().map((donation) => (
        <Donation key={donation.id} donation={donation} showPrivacy={isMyProfile} />
      ))}
    </>
  ) : !isLoading ? (
    <DashedContainer>
      <Spacing marginBottom={20} style={{ width: '100%' }}>
        <Text size={12} medium color={colors.grayMediumDark}>
          {t`youHaveNoDonations`}
        </Text>
      </Spacing>
      <LinkButton
        to="/donate"
        color="transparent"
        style={{
          borderRadius: 100,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.grayMediumDark,
          alignSelf: 'flex-start',
          paddingLeft: 15,
          paddingRight: 15,
          height: 21,
          justifyContent: 'center',
        }}
      >
        <Text size={12} lineHeight={21} light left color={colors.grayMediumDark}>
          {t`browse`}
        </Text>
      </LinkButton>
    </DashedContainer>
  ) : (
    <ActivityIndicator />
  )
}

export default AllDonations
