import React, { useState } from 'react'
import { View } from 'react-primitives'

import { Icon } from 'src/retired/elements'
import Touchable from 'src/retired/shared/Touchable'
import { Body1 } from 'src/retired/shared/Typography'

export const Collapsible = ({ children, title }: { children: JSX.Element; title: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <>
      <Touchable onPress={() => setIsCollapsed(!isCollapsed)}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Body1>{title}</Body1>
          <Icon
            icon="arrowDownBlack"
            style={{ width: 12, height: 6, marginRight: 10, transform: isCollapsed && 'rotate(270deg)' }}
          />
        </View>
      </Touchable>
      {isCollapsed ? null : children}
    </>
  )
}
