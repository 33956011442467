import React from 'react'
import { useTranslation } from 'react-i18next'

import { LinkButton, Spacing } from 'src/retired/elements'
import { Body2, Label } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface CardCompactEmptyProps {
  cardTitle: string
  link: string
  buttonText?: string
}

export const CardCompactEmpty = ({ cardTitle, link, buttonText }: CardCompactEmptyProps): JSX.Element => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('profileScreen')

  return (
    <>
      <Spacing marginTop={16} marginBottom={20} style={{ width: '100%' }}>
        <Body2 colour={colors.grayMediumDark}>{cardTitle}</Body2>
      </Spacing>
      <LinkButton
        to={link}
        color="transparent"
        style={{
          borderRadius: 100,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.grayMediumDark,
          alignSelf: 'flex-start',
          paddingLeft: 15,
          paddingRight: 15,
          justifyContent: 'center',
        }}
      >
        <Label colour={colors.grayMediumDark}>{buttonText ?? t`browse`}</Label>
      </LinkButton>
    </>
  )
}
