import React from 'react'
import { View, Text } from 'react-primitives'

import { Image } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface UserChipProps {
  user?: {
    fullName?: {
      first?: string | null
      last?: string | null
    } | null
    mainPicture?: string | null
  }
  small?: boolean
  disabled?: boolean
}
const UserChip = ({ user, small, disabled }: UserChipProps): JSX.Element | null => {
  const { colors } = useDeedTheme()
  const logo = user?.mainPicture
  const name = `${user?.fullName?.first} ${user?.fullName?.last}`

  if (!user) {
    return null
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        height: 32,
        overflow: 'hidden',
        padding: logo ? 0 : '6px 2px',
        opacity: disabled ? 0.38 : 1,
      }}
    >
      {logo ? (
        <Image
          source={logo}
          style={{
            width: small ? 24 : 32,
            height: small ? 24 : 32,
            borderRadius: 16,
            margin: 1,
            overflow: 'hidden',
            borderWidth: 1,
            borderColor: colors.purple,
          }}
        />
      ) : null}
      <Text
        style={{
          fontSize: 12,
          marginLeft: logo ? 6 : 12,
          marginRight: 12,
          // @TODO-DP: this needs to be adjusted for Native
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {name}
      </Text>
    </View>
  )
}

export default UserChip
