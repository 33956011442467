/* eslint-disable react/no-unstable-nested-components */
import { Box, Button } from '@mui/material'
import { parse } from 'query-string'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

import { PageTitle } from 'src/components'
import { RenderHtml } from 'src/components/RenderHtml'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import GuestCheckout from 'src/containers/modules/GuestCheckout'
import { DonateTypes, TabType, getSearch } from 'src/containers/screens/GeneralSearch/utils'
import { selectCausesLoaded } from 'src/entities/cause/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser, selectUserBrand, selectUserBrandColor } from 'src/entities/user/selectors'
import { Link, useHistory, useLocation, useParams } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import { State } from 'src/reducers'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import Layout from 'src/retired/blocks/Layout'
import PoweredBy from 'src/retired/blocks/PoweredBy'
import {
  Column,
  Divider,
  ExternalLink,
  FluidImage,
  Gradient,
  Icon,
  Links,
  Loading,
  RefreshControl,
  Row,
  Spacing,
  Text,
  Touchable,
} from 'src/retired/elements'
import { LargeUp, MediumDown } from 'src/retired/elements/MediaQuery'
import CauseChips from 'src/retired/shared/CauseChips'
import Chip from 'src/retired/shared/Chip'
import IconButton from 'src/retired/shared/IconButton'
import ScrollToTop from 'src/retired/shared/ScrollToTop'
import { Body2, H3, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { DeviceInfo, Platform, Share } from 'src/utils'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { resize } from 'src/utils/resize'

import { fetchCausesAction, fetchSkillsAction } from '../Home/actions'
import homeEpics from '../Home/epics'
import homeReducer from '../Home/reducer'

import { fetchDeedAction, refreshAction } from './actions'
import {
  ActionButtonsBlock,
  AddToCalendarBlock,
  AttendeesBlock,
  CampaignBlock,
  CampaignDonationsBlock,
  InfoBlock,
  MapBlock,
  ProjectInfoBlock,
  RolesBlock,
  ShareBlock,
  TextBlock,
  VolunteerTimeBlock,
} from './components'
import { eventTypes } from './components/InfoBlock'
import { selectError, selectLoading, selectRefreshing } from './selectors'
import { getEndDateFormattedTime, getStartDateFormattedTime } from './utils'

export const Deed = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  useInjectReducer({ key: 'home', reducer: homeReducer })
  useInjectEpics({ key: 'home', epics: homeEpics })

  const [showGuestCheckoutModal, setShowGuestCheckoutModal] = useState(false)

  const params = useParams<{ deed?: string; lite?: string }>()
  const { t } = useTranslation('deedScreen')
  const { colors, metrics } = useDeedTheme()
  const scrollRef = useRef(null)

  const deed = useSelector((state: State) => params.deed && selectDeedById(state, params.deed))
  const user = useSelector((state: State) => selectCurrentUser(state))
  const brandColor = useSelector((state: State) => selectUserBrandColor(state))
  const loading = useSelector((state: State) => selectLoading(state))
  const refreshing = useSelector((state: State) => selectRefreshing(state))
  const error = useSelector((state: State) => selectError(state))
  const userBrand = useSelector(selectUserBrand)
  const causesLoaded = useSelector(selectCausesLoaded)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDeedAction(params.deed))
    dispatch(fetchSkillsAction())

    if (!causesLoaded) {
      dispatch(fetchCausesAction())
    }
  }, [dispatch, params.deed])

  const { lite } = params
  const campaignId = parse(search)?.campaign
  const backToMap = {
    Campaign: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.donate, donateType: DonateTypes.fundraisers })}`,
    Project: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.volunteer })}`,
    Event: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.volunteer })}`,
  }
  const backTo = backToMap[deed?.type]
  const goBack = makeGoBack(history, backTo, !!campaignId && !user)

  if (error) {
    return <ErrorScreen>{t('problemOccurredWhenLoading', { errorMessage: error.message })}</ErrorScreen>
  }

  if (loading || !deed) {
    return <Loading />
  }
  const isFull = deed.isFull()
  let signUpLocation = `/deeds/${deed.id}/confirm`
  if (!isFull && user) {
    const isFirstDeedForIndividual = user.deeds === 0 && !user.isEmployee()
    if (isFirstDeedForIndividual) {
      signUpLocation = `/deeds/${deed.id}/first`
    } else if (!user.consent || !user.consent.shareData) {
      signUpLocation = `/register/consent/${deed.id}`
    }

    if (!user.phone && !isFirstDeedForIndividual) {
      signUpLocation = `/register/phone/${deed.id}`
    }
  }

  const virtualEvent = eventTypes.includes(deed.type) && deed.virtual && deed.externalLink
  signUpLocation = deed.externalLink && !virtualEvent ? `/deeds/${deed.id}/external` : signUpLocation

  const startDateformattedTime = getStartDateFormattedTime(deed)
  const endDateFormattedTime = getEndDateFormattedTime(deed)

  const wide = metrics.screenWidth > 640

  const nonprofits = deed.getNonprofits()

  const sharingMessage =
    deed.type === 'Campaign'
      ? t('helpSupport', { nonprofits: nonprofits.map((nonprofit) => nonprofit.name)?.join(', '), deedName: deed.name })
      : nonprofits.first()?.name
      ? t('volunteerWithMeNonprofit', { organizationName: nonprofits.first()?.name, deedName: deed.name })
      : t('volunteerWithMe', { deedName: deed.name })

  const isMultipleShiftsFeatureEnabledForEvent = user?.hasFeature('multipleShifts') && deed?.type === 'Event'

  const DutiesBlock = (): JSX.Element => (
    <TextBlock title={t`duties`} wide={wide}>
      {deed.duties}
    </TextBlock>
  )

  const MustKnowsBlock = (): JSX.Element => (
    <TextBlock title={t`mustKnows`} wide={wide}>
      {deed.mustKnows}
    </TextBlock>
  )

  const MissionBlock = (): JSX.Element => (
    <>
      {nonprofits
        .filter((nonprofit) => nonprofit.id !== user?.organization?.id)
        .map((nonprofit) => (
          <Spacing key={nonprofit.id} marginBottom={wide ? 30 : 15}>
            <Link to={`/organization/${nonprofit.id}`} style={{ marginBottom: 8 }}>
              <H5 weight="500" marginBottom={8}>
                {nonprofit.name}
              </H5>
            </Link>
            {nonprofit?.brief ? (
              <Links>
                <Body2 lineHeight={20} colour={colors.grayDark}>
                  {nonprofit.brief}
                </Body2>
              </Links>
            ) : null}
          </Spacing>
        ))}
    </>
  )

  const DonationBlock = (): JSX.Element => (
    <>
      {deed.donationText || deed.donationLink ? (
        <Spacing marginTop={30}>
          <H5 weight="500" marginBottom={8}>{t`donationRequired`}</H5>
        </Spacing>
      ) : null}

      {!deed.donationText ? (
        <Links>
          <Body2 colour={colors.grayDark}>{deed.donationText}</Body2>
        </Links>
      ) : null}

      {deed.donationLink ? (
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="followThisLinkToDonate"
            components={{
              a: (
                <ExternalLink
                  href={deed.donationLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      ) : null}
    </>
  )

  const BackgroundCheckBlock = (): JSX.Element | null =>
    deed.backgroundCheckLink ? (
      <Spacing marginTop={30}>
        <H5 marginBottom={8}>{t`backgroundCheck`}</H5>
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="aBackgroundCheckIsRequired"
            components={{
              a: (
                <ExternalLink
                  href={deed.backgroundCheckLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      </Spacing>
    ) : null

  const VolunteerFormBlock = (): JSX.Element | null =>
    deed.volunteerFormLink ? (
      <Spacing marginTop={30}>
        <H5 marginBottom={8}>{t`volunteerForm`}</H5>
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="aVolunteerFormIsRequired"
            components={{
              a: (
                <ExternalLink
                  href={deed.volunteerFormLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      </Spacing>
    ) : null

  const MilestonesBlock = (): JSX.Element | null =>
    deed.milestones && deed.milestones.size > 0 ? (
      <>
        <Divider />
        <H5 marginBottom={8}>{t`milestones`}</H5>
        {deed.milestones.toArray().map((milestone, index, milestones) => (
          <Fragment key={milestone.get('id') as string}>
            <View style={{ marginBottom: index === milestones.length - 1 ? 0 : 15, width: '100%' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Text medium size={15} lineHeight={24}>
                  #{index + 1} {milestone.get('name')}
                </Text>
                {milestone.get('completed') && (
                  <View style={{ marginLeft: 'auto' }}>
                    <Row>
                      <Icon icon="tickCirclePink" style={{ width: 20, height: 20 }} />
                      <Text size={15} style={{ marginLeft: 10, lineHeight: 20 }}>
                        {t`completed`}
                      </Text>
                    </Row>
                  </View>
                )}
              </Row>
              <Links>
                <Body2 colour={colors.grayDark}>{milestone.get('description')}</Body2>
              </Links>
            </View>
          </Fragment>
        ))}
      </>
    ) : null

  const textBlocks = {
    summary: t`summary`,
    challenge: t`challenge`,
    solution: t`solution`,
    longtermimpact: t`longtermimpact`,
  }
  const TextBlocksElement = (): JSX.Element => (
    <>
      {Object.keys(textBlocks).map((textKey) => {
        const text = deed[textKey]

        if (!text) {
          return null
        }

        return (
          <TextBlock key={textKey} title={textBlocks[textKey]} wide={wide}>
            {text}
          </TextBlock>
        )
      })}
    </>
  )

  const DescriptionBlock = (): JSX.Element => (
    <>
      <H5 weight="500" marginBottom={8}>{t`description`}</H5>
      <Links>
        <View>
          <RenderHtml source={{ html: deed.description }} />
        </View>
      </Links>
      <Spacing marginBottom={wide ? 30 : 15} />
    </>
  )

  const RegistrationDeadlineBlock = (): JSX.Element => (
    <WarningMessage>
      {t('registrationDeadline', {
        date: { value: deed.registrationEndingAt, timeZone: deed.timeZone },
      })}
    </WarningMessage>
  )

  const scrollToSection = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      <PageTitle title={deed.name} />
      {showGuestCheckoutModal && (
        <GuestCheckout
          partner={deed?.partner}
          secondaryAction="CreateNew"
          handleModalDismissed={() => setShowGuestCheckoutModal(false)}
        />
      )}

      <MediumDown>
        <ScrollToTop />
        <ImageHeaderScrollView
          styleScrollView={{ backgroundColor: colors.gray03 }}
          centered={false}
          backTo={backTo}
          image={deed.mainPicture}
          title={deed.name}
          status={deed.isSubmittedByUser(user) ? deed.status : undefined}
          link={
            deed.isSubmittedByUser(user) && !deed.isPast()
              ? { pathname: `/edit-deed/${deed.urlType}/${deed.id}`, state: { fromDeedPage: true } }
              : null
          }
          linkTitle={t`edit`}
          subTitle={
            startDateformattedTime
              ? `${startDateformattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
              : ''
          }
          subTitle2={deed.getShortLocation()}
          noBack={!user}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => dispatch(refreshAction())}
              tintColor={colors.white}
            />
          }
          renderTouchableFixedForeground={
            Platform.OS === 'web'
              ? null
              : () => (
                  <View style={{ position: 'absolute', right: 15, top: 16 + DeviceInfo.getNotchValue() }}>
                    <Touchable
                      onPress={async () => {
                        await Share.share({
                          title: deed.name,
                          text: sharingMessage,
                          url: deed.shareLink,
                          dialogTitle: t`shareLink`,
                        }).catch((e) => {
                          if (!['share canceled'].includes(e?.message?.toLowercase?.().trim())) {
                            alert(t`common:anErrorOccurred`)
                          }
                        })
                      }}
                      style={{
                        width: 40,
                        height: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon icon="shareWhite" style={{ width: 20, height: 20 }} />
                    </Touchable>
                  </View>
                )
          }
        >
          {!!brandColor && <Gradient startColor={brandColor} endColor={brandColor} height={5} />}
          <View
            style={{
              alignSelf: 'stretch',
              paddingBottom: 10,
              alignItems: 'flex-start',
              backgroundColor: colors.gray03,
            }}
          >
            <View
              style={{
                flexDirection: metrics.isSmall ? 'column' : 'row',
                margin: metrics.isSmall ? 0 : 6,
                flexWrap: 'wrap',
                alignSelf: 'stretch',
              }}
            >
              <CampaignBlock deed={deed} userBrand={userBrand} />
              <CampaignDonationsBlock deed={deed} />
              <InfoBlock deed={deed} signUpLocation={signUpLocation} />
              {deed.registrationEndingAt && (
                <View style={{ marginTop: metrics.isSmall ? -20 : 0, marginBottom: 20 }}>
                  <RegistrationDeadlineBlock />
                </View>
              )}
              <ProjectInfoBlock deed={deed} user={user} />
              <AttendeesBlock deed={deed} colors={colors} styles={styles} user={user} />
            </View>

            <View
              style={{
                paddingHorizontal: 18,
                alignSelf: 'stretch',
              }}
            >
              <CauseChips model={deed} link />
            </View>
            <Spacing marginBottom={30} />
            {deed.canUserLogHours(user) && <VolunteerTimeBlock deed={deed} />}
            <View
              style={{
                paddingHorizontal: 18,
                alignSelf: 'stretch',
              }}
            >
              <DescriptionBlock />
              <TextBlocksElement />

              <MissionBlock />
              <DutiesBlock />
              <MustKnowsBlock />
              <DonationBlock />
              <BackgroundCheckBlock />
              <VolunteerFormBlock />

              <RolesBlock deed={deed} />

              <MilestonesBlock />

              {deed.externalSource && (
                <Spacing marginTop={30}>
                  <PoweredBy type={deed.externalSource} />
                </Spacing>
              )}
              <Spacing marginBottom={20} />
            </View>
          </View>
          {deed.location && !deed.virtual ? (
            <MapBlock location={deed.location} locationLatLng={deed.locationLatLng} />
          ) : null}
          {Platform.OS === 'web' && !user?.organization?.settings?.disableSocialSharing ? (
            <Row>
              <Column cols={8} padded style={{ marginVertical: 20 }}>
                <ShareBlock deed={deed} centered />
              </Column>
            </Row>
          ) : null}
          <Spacing marginBottom={100} />
        </ImageHeaderScrollView>
        <ActionButtonsBlock
          small
          deed={deed}
          user={user}
          dispatch={dispatch}
          signUpLocation={signUpLocation}
          setShowGuestCheckoutModal={setShowGuestCheckoutModal}
        />
      </MediumDown>
      <LargeUp>
        <Layout narrow>
          <View style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Row
              style={{
                padding: 15,
                paddingTop: 30,
                justifyContent: 'space-between',
              }}
            >
              <View style={{ flexShrink: 1 }}>
                <Row>
                  <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />
                  <View style={{ flexShrink: 1, paddingVertical: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <H3 numberOfLines={2}>{deed.name}</H3>
                    {deed.isSubmittedByUser(user) && (
                      <View style={{ marginLeft: 10 }}>
                        <Chip type="square" backgroundColor="grayLight" textSize={12}>
                          {deed.status.charAt(0).toUpperCase() + deed.status.slice(1)}
                        </Chip>
                      </View>
                    )}
                  </View>
                </Row>
                <AddToCalendarBlock deed={deed} />
              </View>
              <View style={{ marginLeft: 25, flexDirection: 'row', alignItems: 'center' }}>
                {deed.isSubmittedByUser(user) && !deed.isPast() && (
                  <Link
                    to={{ pathname: `/edit-deed/${deed.urlType}/${deed.id}`, state: { fromDeedPage: true } }}
                    style={{ textDecoration: 'underline', marginRight: 20 }}
                  >
                    {t`edit`}
                  </Link>
                )}
                <ActionButtonsBlock
                  deed={deed}
                  user={user}
                  dispatch={dispatch}
                  signUpLocation={signUpLocation}
                  setShowGuestCheckoutModal={setShowGuestCheckoutModal}
                />
              </View>
            </Row>
            <Row style={{ alignItems: 'flex-start' }}>
              <Column cols={8} padded>
                <View style={{ borderRadius: 12, position: 'relative', paddingBottom: '56.25%', overflow: 'hidden' }}>
                  <FluidImage
                    source={{ uri: resize(deed.mainPicture, '1920x1080') }}
                    aspectRatio={960 / 540}
                    style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </View>

                <Box sx={{ margin: '15px 0px', display: 'flex', justifyContent: 'space-between' }}>
                  <CauseChips model={deed} link />
                  {isMultipleShiftsFeatureEnabledForEvent && deed?.roles?.size > 0 ? (
                    <Button
                      variant="outlined"
                      startIcon={<VisibilityOutlinedIcon />}
                      onClick={scrollToSection}
                      style={{ borderRadius: 32, color: 'black', borderColor: 'black' }}
                      disableElevation
                    >
                      {t`seeShifts`}
                    </Button>
                  ) : null}
                </Box>

                <DescriptionBlock />
                <TextBlocksElement />

                <MissionBlock />
                <DutiesBlock />
                <MustKnowsBlock />
                <DonationBlock />

                <BackgroundCheckBlock />
                <VolunteerFormBlock />

                <RolesBlock deed={deed} scrollRef={scrollRef} />
                <MilestonesBlock />

                <Divider />
              </Column>
              <Column cols={4} padded>
                {deed.registrationEndingAt && (
                  <View style={{ marginBottom: 15 }}>
                    <RegistrationDeadlineBlock />
                  </View>
                )}
                <CampaignBlock deed={deed} userBrand={userBrand} />
                <CampaignDonationsBlock deed={deed} />
                <InfoBlock deed={deed} signUpLocation={signUpLocation} />
                <ProjectInfoBlock deed={deed} user={user} />
                <AttendeesBlock deed={deed} colors={colors} styles={styles} user={user} />
                {deed.canUserLogHours(user) && <VolunteerTimeBlock deed={deed} />}
                {deed.location && !deed.virtual ? (
                  <MapBlock location={deed.location} locationLatLng={deed.locationLatLng} />
                ) : null}
              </Column>
            </Row>
            <Row>
              {user?.organization?.settings?.disableSocialSharing ? null : (
                <Column cols={8} padded style={{ marginBottom: 30 }}>
                  {Platform.OS === 'web' && <ShareBlock deed={deed} />}
                </Column>
              )}
              <Column cols={4} padded>
                {deed.externalSource && <PoweredBy type={deed.externalSource} />}
              </Column>
            </Row>
          </View>
        </Layout>
      </LargeUp>
    </>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginLeft: -25,
    marginRight: -25,
  },
  scrollViewContainer: {
    paddingRight: Platform.OS === 'web' ? 10 : 35,
  },
})
