import React from 'react'
import { differenceInDays, isAfter, parseISO, isBefore } from 'date-fns'
import { useTranslation, TFunction } from 'react-i18next'

import { Label } from 'src/retired/shared/Typography'
import { Row } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import { ActivityStatus } from 'src/containers/modules/Actions/types'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ColorsProps } from 'src/theme/colors'

const getDateData = (
  status: ActivityStatus | null,
  t: TFunction<'actions'>,
  colors: ColorsProps,
  endingAt?: string
) => {
  if (!endingAt) {
    return {
      copy: t('noEndDate'),
      color: colors.gray,
    }
  }

  const endingDate = parseISO(endingAt)
  const currentDate = new Date()
  const dayDifference = differenceInDays(endingDate, currentDate)

  if (dayDifference > 14) {
    return {
      copy: t('expiresIn', { count: dayDifference }),
      color: colors.black,
    }
  }
  if (dayDifference > 0 && dayDifference <= 14) {
    return {
      copy: t('expiresInImportant', { count: dayDifference }),
      color: status !== ActivityStatus.Completed ? colors.redStrong : colors.black,
    }
  }

  if (dayDifference === 0 && isAfter(endingDate, currentDate)) {
    return {
      copy: t('expiresToday'),
      color: status !== ActivityStatus.Completed ? colors.redStrong : colors.black,
    }
  }
  if (dayDifference === 0 && isBefore(endingDate, currentDate)) {
    return {
      copy: t('expiredToday'),
      color: colors.gray,
    }
  }

  return {
    copy: t('expired', { count: Math.abs(dayDifference) }),
    color: colors.gray,
  }
}

export const EndDateTag = ({ endingAt, status }: { endingAt?: string; status: ActivityStatus | null }) => {
  const { t } = useTranslation('actions')
  const { colors } = useDeedTheme()

  const dateData = getDateData(status, t, colors, endingAt)

  return (
    <Row style={{ paddingBottom: 8, paddingTop: 8, alignItems: 'center' }}>
      <Row style={{ alignItems: 'center' }}>
        <Icon icon="notepad" width={20} height={20} />
        <Label style={{ marginLeft: 10, color: dateData.color }} numberOfLines={2}>
          {dateData.copy}
        </Label>
      </Row>
    </Row>
  )
}
