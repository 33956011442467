import {
  SEARCH,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  CANCEL_SEARCH,
  SET_PAGINATION_DETAILS,
  SET_SCROLL_POSITION,
  RESET_NONPROFITS,
} from './constants'

export const searchNonprofits = (partnerOnly: boolean, searchParams: string, acceptsDonations: boolean) => ({
  type: SEARCH,
  partnerOnly,
  searchParams,
  acceptsDonations,
})

export const searchNonprofitsSuccess = () => ({
  type: SEARCH_SUCCESS,
})

export const searchNonprofitsFailed = (error) => ({
  type: SEARCH_FAILED,
  error,
})

export const cancelSearchAction = () => ({
  type: CANCEL_SEARCH,
})

export const setPaginationDetailsAction = (totalItems: number, totalPages: number, queryId: string, page: number) => ({
  type: SET_PAGINATION_DETAILS,
  totalItems,
  totalPages,
  queryId,
  page,
})

export const setScrollPositionAction = (scrollPosition: number) => ({
  type: SET_SCROLL_POSITION,
  scrollPosition,
})

export const resetNonprofits = () => ({
  type: RESET_NONPROFITS,
})
