import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Text } from 'src/retired/shared/Typography'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

const FileSelector = ({ returnFile }: { returnFile: (file: File) => void }): JSX.Element => {
  const { t } = useTranslation('fileUploadComponent')
  const { colors } = useDeedTheme()
  const dispatch = useDispatch()

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop: (files: File[], rejectedFiles) => {
      if (rejectedFiles.length) {
        dispatch(showErrorAction(t('invalidFileType')))
      }
      // If the user drag-and-drops invalid file types: files === []
      if (files.length > 0) {
        returnFile(files[0])
      }
    },
    multiple: false,
    accept: 'image/jpeg, image/png, application/pdf',
  })

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      style={{
        margin: 20,
        padding: 40,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `2px dashed ${isDragReject ? colors.pink : colors.gray}`,
      }}
    >
      <input {...getInputProps()} />
      <Text fontSize={12}>
        <Trans
          t={t}
          i18nKey="dragNDrop"
          values={{
            pressHereText: t`clickHere`,
          }}
          components={{
            PressHere: <span style={{ textDecoration: 'underline', color: colors.brandColor }} />,
          }}
        />
      </Text>
    </div>
  )
}

export default FileSelector
