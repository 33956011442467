import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'

import { useHistory, useLocation, useParams } from 'src/navigation'
import Layout from 'src/retired/blocks/Layout'
import { useActionsQuery } from 'src/generated/graphql'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { NUM_INITIAL_ACTIVITIES } from '../constants'

import { ActionScreenView } from './ActionScreenView'

const ActionScreenContainer = () => {
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { t } = useTranslation('errorTemplates')

  const { search } = useLocation()
  const { campaignId } = parse(search) as { campaignId?: string }

  const user = useSelector(selectCurrentUser)
  const isActionsFeatureEnabled = user?.hasFeature('actions')

  const {
    data: actionsData,
    error: actionsError,
    loading: actionsLoading,
  } = useActionsQuery({
    variables: {
      where: {
        id: { equals: params.id },
        ...(campaignId ? { campaignIds: { has: campaignId } } : {}),
      },
      activityArgs: {
        take: NUM_INITIAL_ACTIVITIES,
      },
    },
    skip: user && !isActionsFeatureEnabled,
  })

  const [action] = actionsData?.actions ?? []

  if (user && !isActionsFeatureEnabled) {
    history.replace('/home')
    return null
  }

  if ((!actionsLoading && !action && user) || actionsError) {
    alert(t`somethingWentWrongTitle`)
    history.replace('/home')
    return null
  }

  return (
    <Layout padding>
      <ActionScreenView action={action} campaignId={campaignId} loading={actionsLoading} />
    </Layout>
  )
}

export default ActionScreenContainer
