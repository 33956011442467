import Donation, { DonationType, PayrollFlatFileDonation } from 'src/entities/donation/model'
import { PayrollRunsFlatFileListQuery, usePayrollSettingsQuery } from 'src/generated/graphql'

import { usePayrollRunFlatFileForDonation } from '../../DonationScheduleDetails/useNextPayDate'

export type ListedPayrollRunsFlatFile = PayrollRunsFlatFileListQuery['payrollRunsFlatFile'][number]
export type ListedPayrollRunsFlatFileWithDates = Omit<
  ListedPayrollRunsFlatFile,
  'startDate' | 'endDate' | 'payDate'
> & { startDate?: Date; endDate: Date; payDate?: Date }

export const isPayrollFlatFileDonation = (donation?: Donation): donation is PayrollFlatFileDonation =>
  donation?.__t === DonationType.PayrollFlatFileDonation

export const useFlatFileCancellationDeadline = (donation?: Donation) => {
  const skip = !isPayrollFlatFileDonation(donation)
  const queryPayrollRun = usePayrollRunFlatFileForDonation(donation)
  const payrollSettings = usePayrollSettingsQuery({ skip })?.data?.payrollSettings
  return {
    loading: !skip && queryPayrollRun.loading,
    deadline:
      payrollSettings?.payrollDonationsCancellation && queryPayrollRun.payrollRunFlatFile?.endDate
        ? new Date(queryPayrollRun.payrollRunFlatFile.endDate)
        : null,
  }
}

export const parsePayrollRunDates = <T extends Partial<ListedPayrollRunsFlatFile>>(
  ppr: T
): Omit<T, 'startDate' | 'endDate' | 'payDate' | 'transmissionDate'> & {
  startDate: Date | undefined
  endDate: Date
  payDate: Date | undefined
  transmissionDate: Date | undefined
} => ({
  ...ppr,
  startDate: ppr?.startDate && new Date(ppr.startDate),
  endDate: new Date(ppr.endDate),
  payDate: ppr?.payDate && new Date(ppr.payDate),
  transmissionDate: ppr?.transmissionDate && new Date(ppr.transmissionDate),
})
