import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import {
  Action,
  ActivityIndicator,
  Button,
  Checkbox,
  Form,
  Screen,
  Text,
  Loading,
  ScrollView,
  Spacing,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { metrics, colors } from 'src/theme'
import { Link, Redirect } from 'src/navigation'

import { selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch',
    flex: 1,
  },
})

export const RegisterConsent = ({
  match,
  user,
  submitting,
  actions,
  values,
  isValid,
  setFieldValue,
  setFieldTouched,
}) => {
  const { t } = useTranslation('consentRegister')

  if (!user) {
    return <Loading />
  }
  if (match.params.deed && user.consent.shareData) {
    return <Redirect to={`/register/phone/${match.params.deed}`} />
  }
  if (!match.params.deed && Object.keys(user.consent).length > 0) {
    return <Redirect to="/home" />
  }
  const handleSubmit = (e) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    if ((values.shareData || values.marketingEmails) && isValid) {
      actions.submitAction(match.params.deed, values.shareData, values.marketingEmails)
    }
  }
  const smallScreen = metrics.screenHeight > 650
  const isEmployee = user.isEmployee()
  const employeeContext = isEmployee ? 'employee' : 'individual'
  return (
    <Screen fixed>
      <ScrollView>
        <NavigationHeader transparent back={!!match.params.deed} />
        <Screen padding narrow action middle={metrics.screenWidth > 640}>
          <Form onSubmit={handleSubmit} style={styles.form}>
            <Text size={smallScreen ? 20 : 18} lineHeight={20} center>
              {t`whenYouSignUpForAVolunteerEvent`}
            </Text>
            {!user.consent.shareData && (
              <Spacing marginTop={20}>
                <Checkbox
                  checked={values.shareData}
                  onChange={() => {
                    // See https://github.com/formik/formik/issues/2266
                    setTimeout(() => {
                      setFieldValue('shareData', !values.shareData)
                      setFieldTouched('shareData')
                    }, 0)
                  }}
                >
                  <Text
                    size={14}
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      whiteSpace: 'normal',
                    }}
                    onPress={() => {
                      // See https://github.com/formik/formik/issues/2266
                      setTimeout(() => {
                        setFieldValue('shareData', !values.shareData)
                        setFieldTouched('shareData')
                      }, 0)
                    }}
                  >
                    {t('thatsCoolIUnderstand', { context: employeeContext })}
                  </Text>
                </Checkbox>
              </Spacing>
            )}
            {!isEmployee && !user.consent.marketingEmails && (
              <Spacing marginTop={20}>
                <Checkbox
                  checked={values.marketingEmails}
                  onChange={() => {
                    // See https://github.com/formik/formik/issues/2266
                    setTimeout(() => {
                      setFieldValue('marketingEmails', !values.marketingEmails)
                      setFieldTouched('marketingEmails')
                    }, 0)
                  }}
                >
                  <Text
                    size={14}
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      whiteSpace: 'normal',
                    }}
                    onPress={() => {
                      // See https://github.com/formik/formik/issues/2266
                      setTimeout(() => {
                        setFieldValue('marketingEmails', !values.marketingEmails)
                        setFieldTouched('marketingEmails')
                      }, 0)
                    }}
                  >
                    {t`checkHereIfYouWishToReceiveEmails`}
                  </Text>
                </Checkbox>
              </Spacing>
            )}
            <Spacing marginBottom={match.params.deed ? 0 : 40} style={{ backgroundColor: 'red' }} />
          </Form>
        </Screen>
      </ScrollView>
      <Action>
        <Button
          type="submit"
          disabled={!(values.shareData || values.marketingEmails) || !isValid}
          onPress={handleSubmit}
          color={(values.shareData || values.marketingEmails) && isValid ? 'primary' : 'light'}
          fluid
        >
          {submitting ? <ActivityIndicator color="#fff" /> : t`confirm`}
        </Button>
        {!match.params.deed && (
          <Spacing marginTop={20}>
            <Link to="/home">
              <Text size={15} color={colors.grayMediumDark}>
                {t`skip`}
              </Text>
            </Link>
          </Spacing>
        )}
      </Action>
    </Screen>
  )
}
RegisterConsent.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  submitting: PropTypes.bool,
  actions: PropTypes.object,
  values: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerConsent', reducer })
const withEpics = injectEpics({ key: 'registerConsent', epics })
const withForm = withFormik({
  validate: (values, props) => {
    const errors = {
      shareData: props.match.params.deed ? validators.notEmpty(values.shareData) : null,
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  enableReinitialize: true,
  mapPropsToValues: () => ({
    shareData: false,
    marketingEmails: false,
  }),
  validateOnBlur: false,
  validateOnChange: true,
  isInitialValid: true,
})

export default compose(withReducer, withEpics, withConnect, withForm)(RegisterConsent)
