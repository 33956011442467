import { Map } from 'immutable'
import type { AnyAction } from 'redux'

import { ADD, UPDATE, RATED_DEED, FOLLOW_ORGANIZATION, UNFOLLOW_ORGANIZATION, COMPLETE_DEED } from './constants'
import User from './model'

export type UserMap = Map<string, User>

export const initialState: UserMap = Map()

export default (state: UserMap = initialState, action: AnyAction): UserMap => {
  const currentUser = state.find((user) => !!user?.me)
  switch (action.type) {
    case RATED_DEED: {
      const ratePending = currentUser?.get('ratePending')
      ratePending.shift()
      currentUser.set('ratePending', ratePending)
      return state.set(currentUser.id, currentUser)
    }

    case ADD:
      return state.set(action.user.id, action.user)

    case UPDATE: {
      let { user } = action
      if (action.properties.length > 0) {
        user = state.get(action.user.id)
        if (!user) {
          return state.set(action.user.id, action.user)
        }
        action.properties.push('name')
        action.properties.forEach((property: string) => {
          user = user.set(property, action.user[property])
        })
      }
      return state.set(action.user.id, user)
    }

    case COMPLETE_DEED:
      return state.setIn([currentUser.id, 'completedDeeds'], currentUser.get('completedDeeds').add(action.id))

    case FOLLOW_ORGANIZATION:
      return state.setIn([currentUser.id, 'following'], currentUser.get('following').add(action.following))

    case UNFOLLOW_ORGANIZATION:
      return state.setIn(
        [currentUser.id, 'following'],
        currentUser.get('following').filter((following: any) => following.organization?.id !== action.id)
      )

    case 'RESET':
      return initialState
  }
  return state
}
