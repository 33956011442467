import React from 'react'
import Slider from '@mui/material/Slider'
import { useTranslation } from 'react-i18next'

import { Body2 } from 'src/retired/shared/Typography'

const radiusMaximum = 200
const radiusMinimum = 10

export const DistanceSlider = ({
  searchRadius,
  setSearchRadius,
}: {
  searchRadius: number
  setSearchRadius: (value: number) => void
}) => {
  const { t } = useTranslation('searchScreen')

  const marks = [
    {
      value: radiusMinimum,
      label: <Body2 style={{ marginLeft: 60, textWrap: 'nowrap' }}>{`${radiusMinimum} ${t`miles`}`}</Body2>,
    },
    {
      value: radiusMaximum,
      label: <Body2 style={{ marginRight: 60, textWrap: 'nowrap' }}>{`${radiusMaximum} ${t`miles`}`}</Body2>,
    },
  ]

  return (
    <div style={{ marginLeft: 5, marginRight: 5 }}>
      <Slider
        value={searchRadius}
        onChange={(_event, value) => {
          setSearchRadius(value as number)
        }}
        marks={marks}
        max={radiusMaximum}
        min={radiusMinimum}
        step={10}
        onKeyDown={(e) => e.stopPropagation()}
        aria-label="Small"
        valueLabelDisplay="auto"
      />
    </div>
  )
}
