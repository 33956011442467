import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in'
import { Sha256 } from '@aws-crypto/sha256-browser'

import { DeviceInfo, Platform } from 'src/utils'
import * as Sentry from 'src/utils/Sentry'
import { Button } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { appleLoginFailedAction } from 'src/containers/screens/Login/actions'

const sha256 = async (message: string) => {
  const hash = new Sha256()
  hash.update(message)

  const digest = await hash.digest()

  return Array.from(new Uint8Array(digest))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

const getOptions = async () =>
  ({
    clientId: `io.godeed.${DeviceInfo.getApplicationName()}`,
    redirectURI: '',
    scopes: 'name email',
    state: Math.random().toString(),
    nonce: await sha256(Math.random().toString()),
  } as SignInWithAppleOptions)

const AppleButton = ({
  onPress,
}: {
  onPress: (authorizationResponse: SignInWithAppleResponse['response']) => void
}) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('appleSignInButton')
  const dispatch = useDispatch()

  if (Platform.OS !== 'ios') {
    return null
  }

  return (
    <Button
      style={{
        width: 280,
        background: colors.black,
        color: colors.white,
      }}
      onPress={() => {
        getOptions()
          .then((options) => SignInWithApple.authorize(options))
          .then(({ response }: SignInWithAppleResponse) => {
            onPress(response)
          })
          .catch((error): void => {
            Sentry.captureException(error)
            dispatch(appleLoginFailedAction(error))
          })
      }}
    >
      {t`signInWithApple`}
    </Button>
  )
}

export default AppleButton
