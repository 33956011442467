import React, { FC } from 'react'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

import { ErrorBoundaryPage } from './ErrorBoundaryPage'

export const ErrorBoundary: FC = ({ children }) => (
  // eslint-disable-next-line react/no-unstable-nested-components
  <SentryErrorBoundary fallback={({ resetError }) => <ErrorBoundaryPage resetError={resetError} />}>
    {children}
  </SentryErrorBoundary>
)
