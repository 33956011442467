import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import useClipboard from 'react-use-clipboard'
import { EventContext } from '@joindeed/analytics-schema/dist/types/baseContext.schema'

import IconButton from 'src/retired/shared/IconButton'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Communications, Platform, Share } from 'src/utils'
import truthy from 'src/utils/truthy'
import metricsClient from 'src/metrics/client'
import { getAnalyticsContext } from 'src/utils/analyticsContext'

interface ShareIconsProps {
  source?: string
  userId?: string
  shareLink: string
  sharingMessage: string
  centered?: boolean
  translationKey?: 'signUpAt' | 'customMessage'
}

const trackSharingEvent = (
  source: string | undefined = undefined,
  userId: string | undefined = undefined,
  buttonType: string | undefined = undefined
) => {
  if (!source || !userId || !buttonType) {
    // tracking not configured yet
    return
  }

  const eventName = `${source}:intent:user-clicked-${buttonType}`
  const event = {
    version: 1,
    event: eventName,
    userId: userId || null,
    context: getAnalyticsContext({ userId }) as EventContext,
  }

  metricsClient.track(eventName, event)
}

export const ShareIconsRow = ({
  source,
  userId,
  shareLink,
  sharingMessage,
  centered = false,
  translationKey = 'signUpAt',
}: ShareIconsProps): JSX.Element => {
  const { t } = useTranslation('shareIcons')
  const { metrics } = useDeedTheme()
  const shareText = t(translationKey, { sharingMessage, shareLink })
  const [, setCopied] = useClipboard(shareLink)

  const nativeShare = ({ shareTitle }: { shareTitle: string }) =>
    Share.share({
      url: shareLink,
      text: (shareText ?? '').replace(shareLink, '').trim(),
      dialogTitle: shareTitle,
    }).catch((e) => {
      if (!['share canceled'].includes(e?.message?.toLowercase?.().trim())) {
        alert(t`common:anErrorOccurred`)
      }
    })

  const shareButtonsData = [
    Platform.OS === 'web'
      ? {
          iconName: 'link',
          title: t`copyToClipboard`,
          onPress: () => {
            trackSharingEvent(source, userId, 'copy-link')
            return setCopied()
          },
        }
      : null,
    {
      iconName: 'facebookNew',
      title: t`shareOnFacebook`,
      onPress: () => {
        trackSharingEvent(source, userId, 'facebook-share')
        return Platform.OS === 'web'
          ? Communications.web(
              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`,
              'fbShareWindow',
              'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=800'
            )
          : nativeShare({ shareTitle: t`shareOnFacebook` })
      },
    },
    {
      iconName: 'workplace',
      title: t`shareOnWorkplace`,
      onPress: () => {
        trackSharingEvent(source, userId, 'workplace-share')
        return Platform.OS === 'web'
          ? Communications.web(
              `https://work.workplace.com/sharer.php?display=popup&u=${shareLink}`,
              'workplaceShareWindow',
              'toolbar=no,status=no,resizable=yes,width=600,height=800'
            )
          : nativeShare({ shareTitle: t`shareOnWorkplace` })
      },
    },
    {
      iconName: 'linkedIn',
      title: t`shareOnLinkedIn`,
      onPress: () => {
        trackSharingEvent(source, userId, 'linkedIn-share')
        return Platform.OS === 'web'
          ? Communications.web(
              `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`,
              'linkedInShareWindow',
              'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=800'
            )
          : nativeShare({ shareTitle: t`shareOnLinkedIn` })
      },
    },
    {
      iconName: 'twitter',
      title: t`shareOnTwitter`,
      onPress: () => {
        trackSharingEvent(source, userId, 'twitter-share')
        return Platform.OS === 'web'
          ? Communications.web(
              `https://twitter.com/intent/tweet?text=${encodeURIComponent(sharingMessage)}&url=${encodeURIComponent(
                shareLink
              )}`,
              'twitterShareWindow',
              'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=300'
            )
          : nativeShare({ shareTitle: t`shareOnTwitter` })
      },
    },
    Platform.OS === 'web'
      ? {
          iconName: 'email',
          title: t`shareViaEmail`,
          onPress: () => {
            trackSharingEvent(source, userId, 'email-share')
            Communications.email(null, null, null, sharingMessage, shareLink)
          },
        }
      : null,
  ].filter(truthy)

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: centered ? 'center' : undefined,
        flexWrap: 'wrap',
      }}
    >
      {shareButtonsData.map((shareButton) => (
        <IconButton
          key={shareButton.iconName}
          icon={shareButton.iconName}
          title={shareButton.title}
          onPress={shareButton.onPress}
          style={{ marginHorizontal: metrics.isSmall ? 8 : 16 }}
        />
      ))}
    </View>
  )
}
