import React from 'react'
import { Box, Button, ButtonProps, Dialog, DialogProps, Typography, TypographyProps } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { colors } from 'src/theme'

export interface ModalContentsProps {
  header?: JSX.Element | string
  headerStyles?: Record<string, unknown>
  body: JSX.Element | string
  actions?: JSX.Element
  onClickClose?: () => void
}

/**
 * @NOTE-DP: this component is migrated from organizer and therefore not yet implemented in Native
 * Use in web-only places or implement the same interface using Native components
 */

export const ModalContents = ({
  header,
  headerStyles,
  body,
  actions,
  onClickClose,
}: ModalContentsProps): JSX.Element => (
  <>
    {header ? (
      <Box
        style={{
          backgroundColor: colors.purple,
          textAlign: 'center',
          ...headerStyles,
        }}
      >
        {header}
        {onClickClose ? (
          <CloseIcon
            style={{
              cursor: 'pointer',
              marginBottom: 20,
              position: 'absolute',
              background: colors.white,
              borderRadius: 50,
              width: 30,
              height: 30,
              margin: 18,
              right: 0,
            }}
            onClick={onClickClose}
          />
        ) : null}
      </Box>
    ) : null}

    <Box p={4}>{body}</Box>

    {actions ? (
      <Box p={4} pt={0} display="flex" justifyContent="flex-end" alignItems="flex-end">
        {actions}
      </Box>
    ) : null}
  </>
)

export const ModalTitle = ({ style, ...props }: TypographyProps<'h4'>): JSX.Element => (
  <Typography
    variant="h4"
    component="h4"
    style={{ fontWeight: 400, fontSize: 20, marginBottom: 16, ...style }}
    {...props}
  />
)

export const ModalTypography = ({ style, ...props }: TypographyProps<'p'>): JSX.Element => (
  <Typography
    variant="body2"
    component="p"
    style={{ fontSize: 14, color: '#00000060', marginBottom: 16, ...style }}
    {...props}
  />
)

export const ModalButton = ({ style, ...props }: ButtonProps): JSX.Element => (
  <Button color="primary" type="button" variant="contained" style={{ ...style }} {...props} />
)

export const Modal = ({ PaperProps, ...props }: DialogProps): JSX.Element => (
  <Dialog
    PaperProps={{
      square: true,
      style: {
        borderRadius: 4,
        maxWidth: 660,
      },
      ...PaperProps,
    }}
    {...props}
  />
)

export default Modal
