import React from 'react'
import PropTypes from 'prop-types'

/* eslint-disable jsx-a11y/iframe-has-title */

const WebView = ({ source, children, renderLoading, renderError, contentInset, startInLoadingState, ...props }) => (
  <iframe width="100%" height="100%" frameBorder="0" src={source.uri} {...props}>
    {children}
  </iframe>
)
WebView.propTypes = {
  source: PropTypes.object.isRequired,
  children: PropTypes.node,
  renderLoading: PropTypes.bool,
  renderError: PropTypes.bool,
  contentInset: PropTypes.bool,
  startInLoadingState: PropTypes.bool,
}

export default WebView
