/* global __DEV__ */
import { onError } from '@apollo/client/link/error'

import * as Sentry from 'src/utils/Sentry'

export const sentryLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (typeof __DEV__ !== 'undefined' && __DEV__) {
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.warn('[GraphQL errors]: ', graphQLErrors)
    }
    if (networkError) {
      // eslint-disable-next-line no-console
      console.info('[Network error]: ', networkError)
    }
  } else {
    const context = {
      operationName: operation.operationName,
      // We might send sensitive user data to sentry because of this.
      // If we realize we really need it for debugging, lets uncomment the next line.
      // (and maybe manually remove sensitive data)
      // operationVariables: operation.variables,
      operationType: operation.query ? 'query' : 'mutation',
    }
    Sentry.setExtras({ context })
    Sentry.setTags(context)

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        Sentry.captureException(new Error(`[GraphQL error]: ${message}`), {
          level: Sentry.Severity.Error,
          extra: {
            locations,
            path,
          },
        })
      })
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.info('[Network error]: ', networkError)
    }
  }
})
