import React from 'react'
import { useTranslation } from 'react-i18next'

import { Screen, ScrollView } from 'src/retired/elements'
import { useHistory } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Body2, H4 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'

const InformativeScreen = ({
  headerTitle,
  headerContent,
  content,
  buttonText,
  buttonOnPress,
  buttonSecondaryText,
  buttonSecondaryOnPress,
}: {
  headerTitle?: string
  headerContent?: string
  content?: string
  buttonText?: string
  buttonOnPress?: () => void
  buttonSecondaryText?: string
  buttonSecondaryOnPress?: () => void
}) => {
  const { t } = useTranslation('missingVolunteerTimeScreen')
  const { colors, metrics } = useDeedTheme()
  const history = useHistory()

  const contentPadding = metrics.isLarge ? '30px 80px 30px 55px' : '30px 25px 30px 25px'

  return (
    <Screen fixed>
      <ScrollView>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ maxWidth: 500 }}>
            <div
              style={{
                padding: contentPadding,
                display: 'flex',
                flexDirection: 'column',
                borderWidth: 0,
                borderBottomWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.lightBorderGray,
              }}
            >
              {headerTitle && (
                <H4 center marginBottom={13}>
                  {headerTitle}
                </H4>
              )}
              {headerContent && <Body2 center>{headerContent}</Body2>}
            </div>
            <div style={{ padding: contentPadding }}>{content && <Body2 center>{content}</Body2>}</div>
            <div
              style={{
                padding: '0px 30px 30px 30px',
                display: 'flex',
                columnGap: '16px',
                justifyContent: 'center',
              }}
            >
              {buttonText && buttonOnPress && (
                <Button
                  onPress={() => history.push(`/profile/volunteer-hours/select-deed`)}
                  palette="primary"
                  loading={false}
                  rounded
                  style={{ flex: 1 }}
                >
                  {t`logVolunteerHours`}
                </Button>
              )}

              {buttonSecondaryText && buttonSecondaryOnPress && (
                <Button
                  onPress={() => history.push(`/profile`)}
                  palette="primary"
                  loading={false}
                  rounded
                  style={{ flex: 1 }}
                >
                  {t`myProfile`}
                </Button>
              )}
            </div>
          </div>
        </div>
      </ScrollView>
    </Screen>
  )
}

export default InformativeScreen
