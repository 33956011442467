import React from 'react'
import { useTranslation } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import { styled } from 'src/theme/styled'
import { Label } from 'src/retired/shared/Typography'
import { ExternalLink } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Icon from 'src/retired/shared/Icon'

import { BASE_SPACING } from '../constants'

const globalGivingLink = 'https://support.globalgiving.org/hc/en-us'
const networkForGoodLink = 'https://www.networkforgood.com/support'

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconWrapper = styled.View`
  padding-right: 5px;
`

interface Props {
  donationProvider: DonationProvider
  donationError: unknown
}

export const DonationError = ({ donationProvider, donationError }: Props) => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()
  return (
    <Wrapper style={{ marginTop: BASE_SPACING * 1.5 }}>
      <IconWrapper>
        <Icon icon="infoCircleOutlinedRed" width={14} hexColor={colors.redDark} />
      </IconWrapper>
      <Label colour={colors.redDark}>
        {donationProvider === DonationProvider.GlobalGiving && (
          <>
            {t`ifYourCreditCardInformation`}{' '}
            <ExternalLink bold color={colors.redDark} size={14} underline href={globalGivingLink}>
              {globalGivingLink}
            </ExternalLink>
            .
          </>
        )}

        {donationProvider === DonationProvider.NFG && (
          <>
            {t`weAreSorry`}{' '}
            <ExternalLink bold color={colors.redDark} size={14} underline href={networkForGoodLink}>
              {networkForGoodLink}
            </ExternalLink>
            .
          </>
        )}

        {donationProvider !== DonationProvider.NFG &&
          donationProvider !== DonationProvider.GlobalGiving &&
          t`anErrorOccurred`}

        {typeof donationError === 'string' && (
          <>
            {'\n'}
            {'\n'}
            <Label colour={colors.redDark} weight="500">
              {t`reason`}:
            </Label>{' '}
            {donationError}
          </>
        )}
      </Label>
    </Wrapper>
  )
}
