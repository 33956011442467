import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocalizationProvider, DatePicker as MuiDatePicker, PickersDay } from '@mui/x-date-pickers'
import type { DatePickerProps as MuiDatePickerProps, PickersDayProps } from '@mui/x-date-pickers'
import { Button, DialogActions, styled, TextField } from '@mui/material'
import type { TextFieldProps } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format } from 'date-fns'

import { getLanguageCode } from 'src/utils/languageUtils'
import { locales } from 'src/i18n/interpolationFormat'
import { EmotionTheme } from 'src/theme/ThemeProvider'

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  ':hover': { borderRadius: 20, border: `1px solid #5291ff` }, // NOTE-AZ: replace with the variable from colors.js
}))

const StyledTextField = styled(TextField)<TextFieldProps & { theme?: EmotionTheme }>(({ theme }) => ({
  borderRadius: 20,
  border: `1px solid ${theme.colors.gray02}`,
  backgroundColor: 'white',
  padding: 0,

  '&.Mui-disabled .MuiInputBase-input': {
    background: '#f5f5f5',
    cursor: 'not-allowed',
    '&:focus, &:hover': {
      borderColor: theme.colors.gray02,
    },
  },
  '& .MuiButtonBase-root': {
    color: theme.palette.grey[300],
  },

  '& .MuiInputBase-input': {
    padding: '7px 0 7px 12px',
    fontSize: '12px',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    '& .MuiSvgIcon-root': {
      height: '0.65em',
    },
  },
}))

export interface CustomDatePickerProps<TDate> extends MuiDatePickerProps<Date, TDate> {
  error?: string | boolean
  placeholder?: string
  hideClearAction?: boolean
  hideTodayAction?: boolean
  disabledDate?: (day: TDate) => boolean
  disableTodayAction?: boolean
  availableDates?: Record<string, number>
  style?: React.CSSProperties
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
type DatePickerProps<TDate> = PartialBy<CustomDatePickerProps<TDate>, 'renderInput'>

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps<Date>>(
  (
    {
      value,
      disabled = false,
      onChange,
      error,
      minDate,
      maxDate,
      disabledDate,
      hideClearAction = false,
      hideTodayAction = false,
      disableTodayAction = true,
      availableDates,
      placeholder,
      style,
    },
    ref
  ): JSX.Element => {
    const { t, i18n } = useTranslation()
    const [open, setOpen] = useState(false)
    const languageCode = getLanguageCode(i18n.language)
    const hideActionBar = !hideClearAction && !hideTodayAction

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[languageCode] || locales.en}>
        {/* 
          Ideally this was supposed to be <StyledDatePicker<Date> but it breaks for some reason
          Because of that `shouldDisableDate`, `onChange` and `renderDay` are showing type mismatch
        */}
        <StyledDatePicker
          inputRef={ref}
          minDate={minDate}
          maxDate={maxDate}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          disabled={disabled}
          focusable
          onChange={onChange}
          shouldDisableDate={disabledDate}
          style={style}
          renderDay={(date: Date, _selectedDates: Array<Date | null>, pickersDayProps: PickersDayProps<Date>) => {
            const key = format(date, 'yyyy/MM/dd')
            const enabledDay = availableDates ? !!availableDates[key] : true

            return (
              <PickersDay
                {...pickersDayProps}
                day={date}
                outsideCurrentMonth={false}
                sx={({ palette }) => ({
                  color: enabledDay ? palette.grey[800] : palette.grey[400],
                })}
              />
            )
          }}
          PaperProps={{
            sx: ({ palette }) => ({
              '& button.Mui-selected:focus, & button.Mui-selected:active': { backgroundColor: palette.primary.light },
            }),
          }}
          renderInput={({ inputRef, ...props }) => (
            <StyledTextField
              {...props}
              ref={inputRef}
              size="small"
              error={!!error || (props.error && !!value)}
              helperText={error}
              onClick={() => setOpen(true)}
              inputProps={{
                ...props.inputProps,
                placeholder: placeholder ?? t('datePicker:selectDate'),
              }}
            />
          )}
          components={
            hideActionBar
              ? {}
              : {
                  ...{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    ActionBar: ({ onClear, onSetToday }) => (
                      <DialogActions>
                        {hideClearAction ? null : <Button onClick={onClear}>{t('datePicker:clear')}</Button>}
                        {hideTodayAction ? null : (
                          <Button onClick={onSetToday} disabled={disableTodayAction}>
                            {t('datePicker:today')}
                          </Button>
                        )}
                      </DialogActions>
                    ),
                  },
                }
          }
        />
      </LocalizationProvider>
    )
  }
)
