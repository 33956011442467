import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  RELATION_REQUEST,
  RELATION_REQUEST_SUCCESS,
  RELATION_REQUEST_FAILED,
  RELATION_ACCEPT,
  RELATION_ACCEPT_SUCCESS,
  RELATION_ACCEPT_FAILED,
  RELATION_REMOVE,
  RELATION_REMOVE_SUCCESS,
  RELATION_REMOVE_FAILED,
} from './constants'

export const initAction = (id) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})
export const relationRequestAction = (id) => ({
  type: RELATION_REQUEST,
  id,
})
export const relationRequestSuccessAction = () => ({
  type: RELATION_REQUEST_SUCCESS,
})
export const relationRequestFailedAction = (error, errorCode) => ({
  type: RELATION_REQUEST_FAILED,
  error,
  errorCode,
})
export const relationAcceptAction = (id) => ({
  type: RELATION_ACCEPT,
  id,
})
export const relationAcceptSuccessAction = () => ({
  type: RELATION_ACCEPT_SUCCESS,
})
export const relationAcceptFailedAction = (error, errorCode) => ({
  type: RELATION_ACCEPT_FAILED,
  error,
  errorCode,
})
export const relationRemoveAction = (id, cancellation = false) => ({
  type: RELATION_REMOVE,
  id,
  cancellation,
})
export const relationRemoveSuccessAction = () => ({
  type: RELATION_REMOVE_SUCCESS,
})
export const relationRemoveFailedAction = (error, errorCode) => ({
  type: RELATION_REMOVE_FAILED,
  error,
  errorCode,
})
