import { createSelector } from 'reselect'

import Skill from './model'
import { SkillMap } from './reducer'

const sortFunction = (a: Skill, b: Skill) => a.name.localeCompare(b.name)
const selectSkillState = (state: any): SkillMap => state.get('entities').skill

export const selectSkills = createSelector(selectSkillState, (skills) => skills.sort(sortFunction))

export const selectSkillById = createSelector(
  selectSkillState,
  (state: any, skillId: string) => skillId,
  (skills, skillId) => skills.get(skillId)
)
