import {
  amountForFeeCap,
  DonationProvider,
  DEFAULT_PROVIDER_FEE_CAP_USD,
  MAXIMUM_DONATION_LOCAL_CURRENCY_LIMIT_STRIPE,
  MAXIMUM_DONATION_USD_LIMIT,
  MAXIMUM_DONATION_USD_LIMIT_GIVEINDIA,
  MAXIMUM_DONATION_USD_LIMIT_PAY_PAL,
} from '@joindeed/calculate-fees'

import roundNumber from 'src/utils/roundNumber'
import { convert } from 'src/containers/modules/CurrencyFormat'
import User from 'src/entities/user/model'
import Deed from 'src/entities/deed/model'
import Organization from 'src/entities/organization/model'

export type MinimumDonationParams = {
  donationProvider: DonationProvider | null
  usdRate: number
  deed: Deed | undefined | null
  isPayrollPaymentSelected: boolean
}

// TODO: Use this on getMaximumDonationAmount() too
const convertAndRoundDonationAmount = (usdRate: number, amount: number) =>
  roundNumber(convert(amount, 1 / usdRate), true)

const getProviderMinimumAmount = (donationProvider: DonationProvider | null): number | undefined => {
  if (!donationProvider) {
    return undefined
  }

  const providerMinimums: Partial<Record<DonationProvider, number>> = {
    [DonationProvider.Stripe]: 5,
    [DonationProvider.NFG]: 5,
    [DonationProvider.GiveIndia]: 5,
    [DonationProvider.PayPal]: 5,
    [DonationProvider.CAF]: 5,
  }

  return providerMinimums[donationProvider]
}

export const getMinimumDonationAmount = ({
  donationProvider,
  usdRate,
  deed,
  isPayrollPaymentSelected,
}: MinimumDonationParams) => {
  const determineEffectiveMinimumDonationUsd = (): number => {
    // If there is a Deed and the Deed has a minimum amount set, it takes precedence over everything else
    if (deed?.minimumDonationAmount) {
      return deed.minimumDonationAmount
    }

    // Temporary: One Dollar Minimum Payroll based Donation [sc-62411]
    if (isPayrollPaymentSelected) {
      return 1
    }

    const defaultMinimumAmount = 10
    const providerMinimum = getProviderMinimumAmount(donationProvider)

    return providerMinimum ?? defaultMinimumAmount
  }

  const amountInUsd = determineEffectiveMinimumDonationUsd()
  return convertAndRoundDonationAmount(usdRate, amountInUsd)
}

const getMaximumDonationUsdLimit = ({
  donationProvider,
  feeCapUSD,
}: {
  donationProvider: DonationProvider | null
  feeCapUSD: number
}): number => {
  if (
    !donationProvider ||
    donationProvider === DonationProvider.Betterplace ||
    donationProvider === DonationProvider.External
  ) {
    return MAXIMUM_DONATION_USD_LIMIT
  }

  let limitForProviderUSD: number
  if (donationProvider === DonationProvider.PayPal) {
    limitForProviderUSD = MAXIMUM_DONATION_USD_LIMIT_PAY_PAL
  } else if (donationProvider === DonationProvider.GiveIndia) {
    limitForProviderUSD = MAXIMUM_DONATION_USD_LIMIT_GIVEINDIA
  } else {
    limitForProviderUSD = MAXIMUM_DONATION_USD_LIMIT
  }
  return Math.min(limitForProviderUSD, amountForFeeCap({ donationProvider, feeAmountCap: feeCapUSD }))
}

export const getMaximumDonationAmount = ({
  donationProvider,
  partner,
  usdRate,
  user,
}: {
  donationProvider: DonationProvider | null
  partner?: Organization
  usdRate: number
  user?: User
}) => {
  const maximumDonationUsd: number = getMaximumDonationUsdLimit({
    donationProvider: donationProvider && DonationProvider[donationProvider],
    feeCapUSD:
      user?.organization?.settings?.providerFeeCapUSD ||
      partner?.settings?.providerFeeCapUSD ||
      DEFAULT_PROVIDER_FEE_CAP_USD,
  })
  const maximumDonationLocalCurrency = roundNumber(convert(maximumDonationUsd, 1 / usdRate), true)

  if (donationProvider === DonationProvider.Stripe) {
    return Math.min(MAXIMUM_DONATION_LOCAL_CURRENCY_LIMIT_STRIPE, maximumDonationLocalCurrency)
  }
  return maximumDonationLocalCurrency
}

const getBase = (value: number) => {
  if (value > 50000) {
    return 10000
  }
  if (value > 10000) {
    return 5000
  }
  if (value > 5000) {
    return 1000
  }
  if (value > 1000) {
    return 500
  }
  if (value > 100) {
    return 50
  }
  return 10
}

export const getDonationAmountOptions = ({
  userCurrencyUsdRate,
  donationAmountOptionsDefaults,
}: {
  userCurrencyUsdRate: number
  donationAmountOptionsDefaults: number[]
}) => {
  let donationAmountOptions = donationAmountOptionsDefaults

  // @NOTE-AT: Options are defined in USD increments
  if (userCurrencyUsdRate !== 1) {
    donationAmountOptions = donationAmountOptions.map((donationAmount) => {
      const value = convert(donationAmount, 1 / userCurrencyUsdRate)

      const base = getBase(value)

      return Math.ceil(value / base) * base
    })
  }

  donationAmountOptions = [...new Set(donationAmountOptions)]

  return donationAmountOptions
}
