import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Spacing, Row, Icon, Text } from 'src/retired/elements'
import Chip from 'src/retired/shared/Chip'
import { Link } from 'src/navigation'
import { colors } from 'src/theme'
import { Platform } from 'src/utils'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { CardCompactWrapper } from 'src/components'
import { getDonationColor } from 'src/utils/getDonationColor'

const Donation = ({ donation, showUser, deed, showPrivacy = false, link = true }) => {
  const { t } = useTranslation('donation')
  const Wrapper = link ? Link : View
  const wrapperProps = link ? { to: `/donations/${donation.id}` } : {}
  return (
    <Wrapper {...wrapperProps} style={{ minHeight: 143 }}>
      <View
        style={{
          paddingBottom: 25,
        }}
      >
        <CardCompactWrapper cardColor={getDonationColor(donation, colors)} fullWidth>
          <View style={{ flexGrow: 1 }}>
            <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
              <Text size={13} light>
                {t('date:month', { date: donation.date })}
              </Text>
              {donation.amount ? (
                <Text size={15} bold>
                  <CurrencyFormat amountCurrencies={donation.amountCurrencies} />
                </Text>
              ) : null}
            </Row>
            <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
              <Spacing marginTop={2} marginBottom={15}>
                <Text size={15} medium underline>
                  {t('date:day', { date: donation.date })}
                </Text>
              </Spacing>
              <View>
                {donation.matchedAmount ? (
                  <Spacing marginTop={2}>
                    <Chip type="square" backgroundColor="yellow" textSize={12}>
                      {t`matched`}
                    </Chip>
                  </Spacing>
                ) : null}
                {donation.donationSchedule ? (
                  <Spacing marginTop={5}>
                    <Chip type="square" backgroundColor="gray02" textSize={12}>
                      {t`recurring`}
                    </Chip>
                  </Spacing>
                ) : null}
              </View>
            </Row>
            {showPrivacy ? (
              <Spacing marginTop={2} marginBottom={15}>
                <Text size={15} medium>
                  {donation.privacy}
                </Text>
              </Spacing>
            ) : null}
          </View>
          <Row style={{ justifyContent: 'space-between' }}>
            <View style={{ flex: 1, paddingRight: 15, alignSelf: 'flex-end' }}>
              {showUser ? (
                <Text size={15} bold numberOfLines={1}>
                  {donation.privacy === 'Anonymous' || !donation.user
                    ? t`anonymous`
                    : `${donation.user.fullName.first} ${donation.user.fullName.last}`}
                </Text>
              ) : (
                <>
                  {deed || donation.deed ? (
                    <Text size={15} bold numberOfLines={Platform.OS === 'web' ? 1 : 2}>
                      {(deed || donation.deed).name}
                    </Text>
                  ) : null}
                  <Text size={15} numberOfLines={Platform.OS === 'web' ? 1 : 2}>
                    {donation.nonprofits?.map((nonprofit) => nonprofit.name)?.join(', ') ||
                      donation.paymentMethodDetails?.additionalData?.nonprofit?.externalName}
                  </Text>
                </>
              )}
            </View>
            {link ? (
              <View style={{ alignSelf: 'flex-end' }}>
                <Icon icon="arrowCircleRightBlack" style={{ width: 26, height: 26 }} />
              </View>
            ) : null}
          </Row>
        </CardCompactWrapper>
      </View>
    </Wrapper>
  )
}
Donation.propTypes = {
  donation: PropTypes.object,
  showUser: PropTypes.bool,
  deed: PropTypes.object,
}

export default Donation
