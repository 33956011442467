import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { ReactReduxContext } from 'react-redux'

import getInjectors from './epicsInjectors'

interface Arguments {
  key: string
  epics: any
}

export default <T extends {}>({ key, epics }: Arguments) =>
  (WrappedComponent: React.ComponentType<T>) => {
    class InjectEpics extends React.Component {
      static WrappedComponent = WrappedComponent

      static contextType = ReactReduxContext

      static displayName = `withEpics(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`

      constructor(props: T, context: any) {
        super(props, context)

        getInjectors(context.store).injectEpics(key, epics)
      }

      render() {
        return <WrappedComponent {...(this.props as T)} />
      }
    }

    return hoistNonReactStatics(InjectEpics, WrappedComponent)
  }

const useInjectEpics = ({ key, epics }: Arguments) => {
  const context = React.useContext(ReactReduxContext)
  React.useEffect(() => {
    getInjectors(context.store).injectEpics(key, epics)
  }, [context.store, epics, key])
}

export { useInjectEpics }
