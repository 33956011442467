import React from 'react'
import PropTypes from 'prop-types'

import ExternalLink from 'src/retired/elements/ExternalLink'

const MapLink = ({ latitude, longitude, address, children, ...props }) => (
  <ExternalLink
    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address || children).replace(
      /%20/g,
      '+'
    )}`}
    {...props}
  >
    {children}
  </ExternalLink>
)
MapLink.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  address: PropTypes.string,
  children: PropTypes.node,
}

export default MapLink
