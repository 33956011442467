import { Observable } from 'rxjs'
import type { Store } from 'redux'
import type { ActionsObservable } from 'redux-observable'

import { selectDonationById } from 'src/entities/donation/selectors'
import DonationsApi from 'src/entities/donation/api'
import type { State } from 'src/reducers'

import { INIT, InitAction, initSuccessAction, initFailedAction } from './types'

const init = (action$: ActionsObservable<InitAction>, store: Store<State>): Observable<unknown> =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const donation = selectDonationById(state, id)
    if (!donation) {
      actions.push(DonationsApi.fetch(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
