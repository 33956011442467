import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export interface InitAction {
  type: typeof INIT
  communityId: string
}
export const initAction = (communityId: string): InitAction => ({
  type: INIT,
  communityId,
})

export interface InitSuccessAction {
  type: typeof INIT_SUCCESS
}
export const initSuccessAction = (): InitSuccessAction => ({
  type: INIT_SUCCESS,
})

export interface InitFailedAction {
  type: typeof INIT_FAILED
  error: Error
}
export const initFailedAction = (error: Error): InitFailedAction => ({
  type: INIT_FAILED,
  error,
})

export type CommunityScreenActions = InitAction | InitSuccessAction | InitFailedAction
