import React from 'react'

import makeGoBack from 'src/navigation/makeGoBack'
import { useHistory } from 'src/navigation'
import IconButton from 'src/retired/shared/IconButton'

export interface BackArrowProps {
  backTo?: string
  forceBackTo?: boolean
  onGoBack?: () => void
}
export type BackArrowElement = React.ReactElement<BackArrowProps>

const BackArrowButton = ({ backTo = '/home', onGoBack, forceBackTo }: BackArrowProps): BackArrowElement => {
  const history = useHistory()
  const goBack = onGoBack || makeGoBack(history, backTo, forceBackTo)

  return <IconButton icon="arrowLeftNew" onPress={goBack} size={30} />
}
export default BackArrowButton
