// Original inspiration from https://usehooks.com/useScript/
import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import * as Sentry from 'src/utils/Sentry'

const cachedScripts: Array<{ src: string; dataAttrs?: { [key: string]: string } }> = []
const useScript = <Response>(
  src: string,
  globalName: string,
  dataAttrs?: { [key: string]: string },
  disableCaching = false
): [boolean, boolean, Response | undefined] => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  const stringifiedDataAttrs = JSON.stringify(dataAttrs)

  useEffect(
    () => {
      // If cachedScripts array already includes the script that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      const sprintIndex = cachedScripts.findIndex((script) => script.src === src)
      if (sprintIndex !== -1 && !disableCaching) {
        if (dataAttrs && !isEqual(dataAttrs, cachedScripts[sprintIndex].dataAttrs)) {
          const error = new Error(
            `Updating data-attributes on an already inserted script is not supported. Script: ${src}, data-attributes: ${stringifiedDataAttrs}`
          )
          if (process.env.NODE_ENV === 'development') {
            throw error
          } else {
            Sentry.captureException(error)
          }
        }
        setState({
          loaded: true,
          error: false,
        })
      } else {
        cachedScripts.push({ src, dataAttrs })

        // Create script
        const script = document.createElement('script')
        script.src = src
        script.async = true

        if (dataAttrs) {
          Object.keys(dataAttrs).map((key) => script.setAttribute(key, dataAttrs[key]))
        }

        // Script event listener callbacks for load and error
        const onScriptLoad = (): void => {
          setState({
            loaded: true,
            error: false,
          })
        }

        const onScriptError = (): void => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.findIndex((cachedScript) => cachedScript.src === src)
          if (index >= 0) {
            cachedScripts.splice(index, 1)
          }
          script.remove()

          setState({
            loaded: true,
            error: true,
          })
        }

        script.addEventListener('load', onScriptLoad)
        script.addEventListener('error', onScriptError)

        // Add script to document body
        document.body.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad)
          script.removeEventListener('error', onScriptError)
        }
      }
    },
    [src, stringifiedDataAttrs, disableCaching] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error, window[globalName] as Response | undefined]
}
export default useScript
