import React from 'react'
import i18n from 'i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Redirect } from 'src/navigation'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { showMessageAction } from 'src/containers/modules/Alerts/actions'

interface HasFeaturesProps {
  component: React.ComponentType
  hasFeatures: string[]
  requireAll: boolean
  employeesOnly: boolean
  ignoreFeatureFlag: boolean // a workaround which allows to render a route even if it's restricted by feature flag
}

const HasFeatures = ({
  component: Component,
  hasFeatures,
  requireAll = true,
  employeesOnly = false,
  ignoreFeatureFlag = false,
  ...props
}: HasFeaturesProps): JSX.Element | null => {
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const featureComparator = requireAll ? 'every' : 'some'

  if (!user) {
    return null
  }

  if (
    !ignoreFeatureFlag &&
    user &&
    ((employeesOnly && !user.isEmployee()) || !hasFeatures?.[featureComparator]((feature) => user.hasFeature(feature)))
  ) {
    dispatch(showMessageAction(i18n.t('hasFeatures:thisPageIsRestricted')))
    return <Redirect to="/home" />
  }

  return <Component {...props} />
}

export default HasFeatures
