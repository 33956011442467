import { Observable } from 'rxjs'
import _ from 'lodash'

import { selectUserById } from 'src/entities/user/selectors'
import { selectDonationsForUserLoaded } from 'src/entities/donation/selectors'
import { selectDonationSchedulesListLoaded } from 'src/entities/donationSchedule/selectors'
import UserApi from 'src/entities/user/api'
import DonationsApi from 'src/entities/donation/api'
import DonationScheduleApi from 'src/entities/donationSchedule/api'

import { initSuccessAction, initFailedAction } from './actions'
import { INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id, from, to }) => {
    const actions = []
    const state = store.getState()

    const user = selectUserById(state, id)

    if (id && !user) {
      actions.push(UserApi.fetch(id))
    }

    if (!selectDonationsForUserLoaded(state, id)) {
      const query = from && to && { from, to }
      actions.push(DonationsApi.fetchForUser(id, query))
    }

    if (!selectDonationSchedulesListLoaded(state) && (!user || user.hasFeature('recurringDonations'))) {
      actions.push(DonationScheduleApi.getByFilter({ userId: { equals: 'me' }, status: { equals: 'Active' } }))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
