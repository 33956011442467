import React, { PropsWithChildren, MouseEvent } from 'react'
import classNames from 'classnames'
import { Button } from '@mui/material'
import { PropsWithElementAttributes } from 'react-activity-feed/dist/utils'
import { useTranslation } from 'react-i18next'

import { ActivityIndicator } from 'src/retired/elements'

export type LoadMoreButtonProps = PropsWithElementAttributes<
  PropsWithChildren<{
    onClick?: (event?: MouseEvent<HTMLButtonElement>) => void
    refreshing?: boolean
  }>
>

export const LoadMoreButton = ({
  onClick,
  refreshing = false,
  children,
  className,
  style,
}: LoadMoreButtonProps): JSX.Element => {
  const { t } = useTranslation('communityFeed')
  return (
    <div className={classNames('raf-load-more-button', className)} style={style}>
      <Button onClick={onClick} variant="text" disabled={refreshing} color="error">
        {refreshing ? <ActivityIndicator /> : children || t`loadMore`}
      </Button>
    </div>
  )
}
