import mergeWith from 'lodash/mergeWith'

function subtractionCustomizer(objValue?: number, srcValue?: number): number {
  if (objValue && srcValue) {
    return (objValue || 0) - (srcValue || 0)
  }
  return objValue || srcValue || 0
}

// Takes two objects and subtracts their values
export const subtractObjects = <T>(total: T, ...args: T[]): T => {
  const result = { ...total }
  // mergeWith mutates total
  mergeWith(result, ...args, subtractionCustomizer)
  return result
}

export default subtractObjects
