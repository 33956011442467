import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Link } from 'src/navigation'
import Icon from 'src/retired/elements/Icon'
import { colors } from 'src/theme'

const FeedCheckInBar = ({ deed, checkedIn }) => {
  const { t } = useTranslation('checkInBar')
  return (
    <Link to={`/deeds/${deed.id}/checkIn`} style={{ alignSelf: 'stretch' }}>
      <View style={styles.root}>
        <View
          style={{
            paddingLeft: 25,
            paddingRight: 15,
            flexShrink: 0,
            justifyContent: 'center',
          }}
        >
          <Icon icon={checkedIn ? 'tickCircleWhite' : 'exclamationMarkCircleWhite'} style={{ width: 24, height: 24 }} />
        </View>
        <View
          style={{
            flexGrow: 1,
            flexShrink: 1,
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text style={styles.checkIn} numberOfLines={1}>
            {checkedIn ? t`checkedIn` : t`checkIn`} – <Text style={styles.name}>{deed.name}</Text>
          </Text>
        </View>
        <View
          style={{
            paddingLeft: 15,
            paddingRight: 25,
            flexShrink: 0,
            justifyContent: 'center',
          }}
        >
          <Icon icon="arrowRightWhite" style={{ width: 19, height: 16 }} />
        </View>
      </View>
    </Link>
  )
}
FeedCheckInBar.propTypes = {
  deed: PropTypes.object,
  checkedIn: PropTypes.bool,
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#4168FA',
    height: 63,
    alignSelf: 'stretch',
    flexDirection: 'row',
    shadowOffset: { width: 0, height: -4 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    shadowColor: colors.black,
    elevation: 1,
  },
  checkIn: {
    fontSize: 13,
    color: colors.white,
    fontFamily: 'GTWalsheimLC',
    whiteSpace: 'nowrap',
  },
  name: {
    fontSize: 13,
    color: colors.white,
    fontFamily: 'GTWalsheimLC',
    fontWeight: 500,
  },
})

export default FeedCheckInBar
