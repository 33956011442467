import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { Platform } from 'src/utils'
import { ScrollView } from 'src/retired/elements'
import { useNonprofitNominationsQuery, NonprofitNomination } from 'src/generated/graphql'
import { H5 } from 'src/retired/shared/Typography'

import NonprofitNominationCard from './NonprofitNominationCard'

const NonprofitNominationsCompact = (): JSX.Element | null => {
  const ieScrollViewFix = Platform.isIE ? { overflowY: 'visible', overflowX: 'visible' } : {}
  const styles = StyleSheet.create({
    scrollView: {
      flex: 0,
      flexBasis: 'auto',
      alignSelf: 'stretch',
      paddingLeft: 25,
      marginHorizontal: -25,
      marginBottom: -15,
      ...ieScrollViewFix,
    },
    scrollViewContainer: {
      paddingRight: 10,
      paddingBottom: 15,
    },
  })

  const Section = styled.View`
    background-color: white;
    border-width: 1px;
    border-color: #eaeaea;

    ${(props) =>
      !props.last &&
      `
      margin-bottom: 25px;
      border-bottom-width: 1px;
      border-bottom-color: #eaeaea;
  `}
  `

  const SectionTitle = styled.View`
    padding: 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `

  const SectionContent = styled.View`
    padding: 0 25px 15px;
  `

  const { t } = useTranslation('nonprofitNominationsCompact')

  const { data } = useNonprofitNominationsQuery()

  // Sort first by if nomination is active, then by submitted date
  const sortNominations = (nominationsArray: NonprofitNomination[]): NonprofitNomination[] =>
    nominationsArray
      ?.filter((nomination) => nomination.status === 'Submitted')
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      .concat(
        nominationsArray
          .filter((nomination) => nomination.status !== 'Submitted')
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      )

  return data?.nonprofitNominations ? (
    <Section>
      <SectionTitle>
        <H5>{t`organizationNominations`}</H5>
      </SectionTitle>
      <SectionContent>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContainer}
          showsHorizontalScrollIndicator
          horizontal
        >
          {sortNominations(data.nonprofitNominations).map((nomination) => (
            <NonprofitNominationCard key={nomination.id} nomination={nomination} />
          ))}
        </ScrollView>
      </SectionContent>
    </Section>
  ) : null
}

export default NonprofitNominationsCompact
