import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { colors } from 'src/theme'
import { Touchable } from 'src/retired/elements'
import { Link, withRouter } from 'src/navigation'
import UserMenuModal from 'src/containers/modules/UserMenuModal'
import Icon from 'src/retired/shared/Icon'
import { Label } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { DeviceInfo } from 'src/utils'
import { setLocalSettingAction } from 'src/localSettings/actions'

const linkStyle = {
  alignSelf: 'stretch',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
}

const TabMenu = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('tabMenu')
  const { metrics } = useDeedTheme()
  const user = useSelector(selectCurrentUser)

  const [userModalActive, setUserModalActive] = useState(false)

  useEffect(() => {
    dispatch(setLocalSettingAction('menuModalVisibility', userModalActive))
  }, [userModalActive])

  return (
    <View
      style={[
        styles.root,
        {
          justifyContent: metrics.screenWidth > 640 ? 'flex-end' : 'space-around',
        },
      ]}
    >
      <View style={styles.tab}>
        <Link to="/home" style={linkStyle}>
          <Icon
            icon="homeFilled"
            color={location.pathname.indexOf('/home') === 0 ? 'blue' : 'gray01'}
            hexColor={location.pathname.indexOf('/home') === 0 && colors.brandColor}
            width={24}
          />
          <Label
            weight="500"
            marginTop={6}
            colour={location.pathname.indexOf('/home') === 0 ? colors.brandColor : 'gray01'}
          >
            {t`home`}
          </Label>
        </Link>
      </View>
      {(!user || user.hasFeature('volunteering')) && (
        <View style={styles.tab}>
          <Link to="/search?tab=volunteer" style={linkStyle}>
            <Icon
              icon="volunteer"
              color={
                location.pathname.indexOf('/volunteer') === 0 || location.search.includes('tab=volunteer')
                  ? colors.brandColor
                  : colors.gray01
              }
              hexColor={
                location.pathname.indexOf('/volunteer') === 0 || location.search.includes('tab=volunteer')
                  ? colors.brandColor
                  : colors.gray01
              }
              width={24}
            />
            <Label
              weight="500"
              marginTop={6}
              colour={
                location.pathname.indexOf('/volunteer') === 0 || location.search.includes('tab=volunteer')
                  ? colors.brandColor
                  : colors.gray01
              }
            >
              {t`volunteer`}
            </Label>
          </Link>
        </View>
      )}
      {(!user || user?.hasFeature('donations')) && (
        <View style={styles.tab}>
          <Link to="/donate" style={linkStyle}>
            <Icon
              icon="donate"
              color={location.pathname.indexOf('/donate') === 0 ? 'blue' : 'gray01'}
              hexColor={location.pathname.indexOf('/donate') === 0 && colors.brandColor}
              width={24}
            />
            <Label
              weight="500"
              marginTop={8}
              colour={location.pathname.indexOf('/donate') === 0 ? colors.brandColor : 'gray01'}
            >
              {t`donate`}
            </Label>
          </Link>
        </View>
      )}
      {(!user || user?.hasFeature('actions')) && (
        <View style={styles.tab}>
          <Link to="/actions" style={linkStyle}>
            <Icon
              icon="actions"
              color={location.pathname.indexOf('/actions') === 0 ? 'blue' : 'gray01'}
              hexColor={location.pathname.indexOf('/actions') === 0 && colors.brandColor}
              width={24}
            />
            <Label
              weight="500"
              marginTop={8}
              colour={location.pathname.indexOf('/actions') === 0 ? colors.brandColor : 'gray01'}
            >
              {t`actions`}
            </Label>
          </Link>
        </View>
      )}
      {(!user || !user?.getSetting('hideExploreSection') === true) && (
        <View style={styles.tab}>
          <Link to="/causes" style={linkStyle}>
            <Icon
              icon="explore"
              color={location.pathname.indexOf('/causes') === 0 ? 'blue' : 'gray01'}
              hexColor={location.pathname.indexOf('/causes') === 0 && colors.brandColor}
              width={24}
            />
            <Label
              weight="500"
              marginTop={6}
              colour={location.pathname.indexOf('/causes') === 0 ? colors.brandColor : 'gray01'}
            >
              {t`explore`}
            </Label>
          </Link>
        </View>
      )}
      <View style={styles.tab}>
        <Touchable style={linkStyle} onPress={() => setUserModalActive(true)}>
          <Icon
            icon="menuMore"
            color={location.pathname.indexOf('/profile') === 0 ? 'blue' : 'gray01'}
            hexColor={location.pathname.indexOf('/profile') === 0 && colors.brandColor}
            width={24}
          />
          <Label
            weight="500"
            marginTop={6}
            colour={location.pathname.indexOf('/profile') === 0 ? colors.brandColor : 'gray01'}
          >
            {t`more`}
          </Label>
        </Touchable>
      </View>
      {!!user && <UserMenuModal user={user} isVisible={userModalActive} changeState={setUserModalActive} />}
    </View>
  )
}
TabMenu.propTypes = {
  location: PropTypes.object,
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    backgroundColor: colors.gray03,
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 10,
    paddingBottom: DeviceInfo.getNotchValue('bottom') || 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderTopWidth: 1,
    borderTopColor: colors.gray01,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
  },
  tab: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexBasis: 0,
  },
})

export default withRouter(TabMenu)
