export const INIT = 'containers/Profile/Friends/INIT'
export const INIT_SUCCESS = 'containers/Profile/Friends/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Profile/Friends/INIT_FAILED'
export const RELATION_REQUEST = 'containers/Profile/Friends/RELATION_REQUEST'
export const RELATION_REQUEST_SUCCESS = 'containers/Profile/Friends/RELATION_REQUEST_SUCCESS'
export const RELATION_REQUEST_FAILED = 'containers/Profile/Friends/RELATION_REQUEST_FAILED'
export const RELATION_ACCEPT = 'containers/Profile/Friends/RELATION_ACCEPT'
export const RELATION_ACCEPT_SUCCESS = 'containers/Profile/Friends/RELATION_ACCEPT_SUCCESS'
export const RELATION_ACCEPT_FAILED = 'containers/Profile/Friends/RELATION_ACCEPT_FAILED'
export const RELATION_REMOVE = 'containers/Profile/Friends/RELATION_REMOVE'
export const RELATION_REMOVE_SUCCESS = 'containers/Profile/Friends/RELATION_REMOVE_SUCCESS'
export const RELATION_REMOVE_FAILED = 'containers/Profile/Friends/RELATION_REMOVE_FAILED'
