export const INIT = 'containers/Donation/INIT'
export const INIT_SUCCESS = 'containers/Donation/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Donation/INIT_FAILED'

export const REFRESH = 'containers/Donation/REFRESH'
export const REFRESH_SUCCESS = 'containers/Donation/REFRESH_SUCCESS'
export const REFRESH_FAILED = 'containers/Donation/REFRESH_FAILED'

export const CANCEL = 'containers/Donation/CANCEL'
export const CANCEL_SUCCESS = 'containers/Donation/CANCEL_SUCCESS'
export const CANCEL_FAILED = 'containers/Donation/CANCEL_FAILED'
