/** @jsx jsx */
import { Checkbox } from 'antd-mobile'
import { jsx, css } from '@emotion/react'

import { useDeedTheme } from 'src/theme/ThemeProvider'

const checkboxStyle = css`
  .am-checkbox {
    margin-right: 15px;
  }
  .am-checkbox-inner {
    border-radius: 4px;
  }
`

const radioStyle = css`
  min-height: 40px;
  background-color: transparent !important;
  padding-left: 0;

  .am-checkbox .am-checkbox-inner::after {
    display: none !important;
  }

  .am-checkbox .am-checkbox-inner::before {
    box-sizing: border-box;
    content: '';
    border: 3px solid #fff;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100%;
  }
`

const StyledCheckbox = ({ asRadio = false, ...props }) => {
  const { colors } = useDeedTheme()

  const style = css`
    display: flex;
    align-items: center;

    .am-checkbox.am-checkbox-checked .am-checkbox-inner {
      background: ${colors.brandColor};
    }
    .am-checkbox .am-checkbox-inner {
      border-color: #ebeef0 !important;
      border-width: 2px;
    }
  `

  if (asRadio) {
    return <Checkbox.CheckboxItem css={[style, radioStyle]} {...props} />
  }
  return <Checkbox css={[style, checkboxStyle]} {...props} />
}

export default StyledCheckbox
