import { calculateFees, DonationProvider, PaymentMethodType } from '@joindeed/calculate-fees'

import * as Sentry from 'src/utils/Sentry'
import config from 'src/config'
import User from 'src/entities/user/model'
import roundNumber from 'src/utils/roundNumber'
import { Currency, CurrencyCode } from 'src/containers/modules/CurrencyFormat'

export const calculateDeedBalanceProcessingFee = ({
  amount,
  cardCountryCode,
  coverFeesParam,
  donationProvider,
  isRecurring,
  nonprofitCountryCode,
  user,
  applicationFeeSetting,
  applicationFeeAmountCap,
}: {
  amount: number
  cardCountryCode: string
  coverFeesParam: boolean
  donationProvider: DonationProvider | null
  isRecurring: boolean
  nonprofitCountryCode: string
  user?: User
  applicationFeeSetting: number
  applicationFeeAmountCap: number
}): number => {
  if (user?.feeCovered) {
    return 0
  }

  if (donationProvider === DonationProvider.Stripe) {
    // Stripe is not supported for donation credit
    return 0
  }
  try {
    const { totalFeeAmount } = calculateFees({
      amount,
      applicationFeeSetting,
      applicationFeeAmountCap,
      cardCountryCode,
      coverFeesParam,
      donationPaymentMethod: PaymentMethodType.DeedCredit,
      donationProvider,
      isRecurring,
      isSandbox: config.payPalEnv === 'SANDBOX',
      nonprofitCountryCode,
    })

    // @NOTE-AC: [sc-47036] This needs to be ceiled (not rounded!) so total amounts can't go above the available Deed Credit
    return Math.ceil(totalFeeAmount * 100) / 100
  } catch (e) {
    // @NOTE-AC: tracking these errors just in case we're blowing something up
    Sentry.captureException(e, { level: Sentry.Severity.Warning })
    return 0
  }
}

export const isInsufficientBalance = ({
  baseCurrency,
  checkedCoverFee,
  deedBalanceProcessingFee,
  deedBalanceCurrency,
  user,
  amountSelected,
}: {
  baseCurrency: CurrencyCode
  checkedCoverFee: boolean
  deedBalanceCurrency: Currency
  user?: User
  deedBalanceProcessingFee: number
  amountSelected: number
}): boolean => {
  const totalForDeedBalance = roundNumber(
    checkedCoverFee && amountSelected ? amountSelected + deedBalanceProcessingFee : amountSelected
  )
  const balance = user?.donationCreditsWallet?.balance
  const deedBalance = deedBalanceCurrency?.rates?.[baseCurrency]
  const deedBalanceInBaseCurrency = balance && deedBalance && roundNumber(balance / deedBalance, true)

  return (
    !Number.isFinite(deedBalanceInBaseCurrency) ||
    !deedBalanceInBaseCurrency ||
    totalForDeedBalance > deedBalanceInBaseCurrency
  )
}
