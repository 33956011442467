import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Body1 } from 'src/retired/shared/Typography'

export const BadgesEarnedText = ({
  badgesWithAwardsLength,
  allBadgesLength,
}: {
  badgesWithAwardsLength: number
  allBadgesLength: number
}) => {
  const { t } = useTranslation('badges')

  return (
    <Trans
      t={t}
      i18nKey="badgesEarned"
      components={{
        Text: <Body1 />,
      }}
      values={{
        numbers: `${badgesWithAwardsLength}/${allBadgesLength}`,
        count: allBadgesLength || 0,
      }}
    />
  )
}
