import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectIsAuthenticated } from 'src/entities/auth/selectors'

const Authenticated = ({ isAuthenticated, render }) => render(isAuthenticated)

export default connect(
  createStructuredSelector({
    isAuthenticated: selectIsAuthenticated,
  })
)(Authenticated)
