/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import * as Sentry from 'src/utils/Sentry'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import DonationApi from 'src/entities/donation/api'
import Deed from 'src/entities/deed/model'
import config from 'src/config'
import { colors } from 'src/theme'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { ApiError, renderErrorMessage } from 'src/utils/errorMessages'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'

import { registerGiveIndiaDonationAction } from '../actions'

import { Donor } from './DonorDetailsForm'
import { GiveIndiaModal } from './GiveIndiaModal'

const GiveIndiaForm = ({
  nonprofitId,
  deed,
  amount,
  designation,
  dedication,
  privacy,
  donorPrivacy,
  donor,
  campaign,
  feeCovered,
  setGiveIndiaCommited,
  scrollViewEl,
}: {
  nonprofitId: string
  deed?: Deed
  amount: number
  designation: string
  dedication: string
  privacy: string
  donorPrivacy: DonorPrivacy
  donor?: Donor
  campaign?: string
  feeCovered: boolean
  setGiveIndiaCommited: (v: boolean) => void
  scrollViewEl: React.RefObject<HTMLDivElement>
}): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const [iframeReady, setIframeReady] = useState(false)
  const [iframeHeight, setIframeHeight] = useState(1245)
  const [waitingForIframe, setWaitingForIframe] = useState(false)
  const [donationReference, setDonationReference] = useState(null)
  const { metrics } = useDeedTheme()

  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const iframeUrl = `${config.giveIndiaApiUrl}/deed/donate/${donationReference}`

  const dispatch = useDispatch()

  const sentryContext = useMemo(
    () => ({
      amount,
      deed: deed?.id,
      nonprofit: nonprofitId,
      campaign,
      donor,
    }),
    [amount, campaign, deed?.id, donor, nonprofitId]
  )

  const handleMessage = (event: MessageEvent): void => {
    if (event.origin === config.giveIndiaApiUrl) {
      if (
        event.data === 'START_STRIPE_CHECKOUT' ||
        event.data === 'SCROLL_UP' ||
        event.data === 'INVALID_PASSPORT_NUMBER' ||
        event.data === 'INVALID_PAN_NUMBER'
      ) {
        scrollViewEl.current?.scrollTo(0, 0)
      }
      if (event.data?.includes?.('SET_HEIGHT')) {
        try {
          const data = JSON.parse(event.data)
          if (data.type === 'SET_HEIGHT' && data.value) {
            setIframeHeight(data.value)
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(`Couldn't decode GiveIndia iframe message`, e)
          Sentry.captureException(e, {
            extra: sentryContext,
          })
        }
      }
      if (event.data === 'START_FORM_SUBMIT') {
        setWaitingForIframe(true)
      }
    }
    if (event.origin === config.apiEndpoint) {
      setWaitingForIframe(false)
      const data = JSON.parse(event.data as string) as { errors?: ApiError[] }
      if (data?.errors) {
        const error = new Error(renderErrorMessage(data.errors))
        dispatch(showErrorAction(t('common:anErrorOccurred'), error))
        console.error(`GiveIndia iframe returned these errors`, error)
        Sentry.captureException(error, {
          extra: sentryContext,
        })
        // Probably the safest way to recreate a new GiveIndia session
        setGiveIndiaCommited(false)
      } else {
        dispatch(registerGiveIndiaDonationAction(data))
      }
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (amount) {
      DonationApi.createGiveIndiaDonationReference({
        [deed ? 'deed' : 'nonprofit']: deed?.id || nonprofitId,
        amount,
        designation,
        dedication,
        privacy,
        donorPrivacy,
        donor,
        campaign,
        feeCovered,
      })
        .toPromise()
        .then((ref) => setDonationReference(ref.additionalData.sessionId))
        .catch((e) => {
          dispatch(showErrorAction(t`common:anErrorOccurred`))
          setGiveIndiaCommited(false)
          Sentry.captureException(e, {
            extra: sentryContext,
          })
        })
    }
  }, [
    nonprofitId,
    deed,
    amount,
    designation,
    dedication,
    privacy,
    donorPrivacy,
    donor,
    campaign,
    feeCovered,
    dispatch,
    t,
    setGiveIndiaCommited,
    sentryContext,
  ])

  if (!donationReference) {
    return <ActivityIndicator color={colors.pink} size="large" />
  }

  const iframeStyle = metrics.isSmall
    ? {
        width: '100%',
        height: iframeHeight,
        overflow: 'hidden',
        border: 'none',
      }
    : {
        width: '100%',
        height: iframeHeight,
        overflow: 'hidden',
        borderStyle: 'solid',
        borderRadius: 8,
        borderColor: colors.gray02,
        borderWidth: 1,
      }

  return (
    <div
      style={{
        overflow: 'hidden',
        marginLeft: metrics.isSmall ? -20 : 0,
        width: '100%',
        borderRadius: 12,
        paddingTop: metrics.isSmall ? 50 : 0,
      }}
    >
      {(!iframeReady || waitingForIframe) && (
        <View style={{ marginTop: -50, marginLeft: 20, position: 'absolute' }}>
          <ActivityIndicator color={colors.pink} size="large" />
        </View>
      )}
      <iframe
        ref={iframeRef}
        style={{ ...iframeStyle, ...(waitingForIframe ? { visibility: 'hidden' } : {}) }}
        scrolling="no"
        onLoad={() => {
          setIframeReady(true)
        }}
        src={iframeUrl}
      />
      <View style={{ marginLeft: 32, marginTop: 25 }}>
        <GiveIndiaModal />
      </View>
    </div>
  )
}

export default GiveIndiaForm
