import React from 'react'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import Icon from 'src/retired/shared/Icon'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { NoTypeSelectedWithArrow } from './NoTypeSelectedWithArrow'

export enum NoResultType {
  NO_FUNDRAISERS = 'NO_FUNDRAISERS',
  NO_NONPROFITS = 'NO_NONPROFITS',
  NO_DEEDS = 'NO_DEEDS',
  NO_FILTER_TYPE = 'NO_FILTER_TYPE',
}

const Wrapper = styled.View`
  margin: 20px 0;
  width: 100%;
  border-radius: 16px;
  background-color: #5128aa0a;
`
const NoResultsWrapper = styled.View`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const NoNonprofitsWrapper = styled.View`
  padding: 60px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
`
const FlexWrapper = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NoResults = ({ noResultType, children }: { noResultType: NoResultType; children?: React.ReactNode }) => {
  const { metrics } = useDeedTheme()

  if (noResultType === NoResultType.NO_FILTER_TYPE) {
    return <NoTypeSelectedWithArrow />
  }

  return (
    <Wrapper>
      {noResultType === NoResultType.NO_FUNDRAISERS && (
        <NoResultsWrapper>
          <Icon icon="noResultsIllustration" width={130} height={110} />
        </NoResultsWrapper>
      )}

      {NoResultType.NO_NONPROFITS === noResultType && (
        <NoNonprofitsWrapper>
          <FlexWrapper>
            <View>
              <Icon icon="noNonprofitsIllustration" width={95} height={88} />
            </View>
            <View style={{ paddingLeft: 35, flex: 1 }}>{children}</View>
          </FlexWrapper>
        </NoNonprofitsWrapper>
      )}

      {NoResultType.NO_DEEDS === noResultType && (
        <NoNonprofitsWrapper>
          <FlexWrapper style={{ flexDirection: 'column' }}>
            <View>
              <Icon icon="filters" width={172} height={86} />
            </View>
            <View style={{ paddingLeft: metrics.isSmall ? 0 : 35, flex: 1, paddingTop: 24 }}>{children}</View>
          </FlexWrapper>
        </NoNonprofitsWrapper>
      )}
    </Wrapper>
  )
}
