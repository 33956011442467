import { Map } from 'immutable'
import type { AnyAction } from 'redux'

import { ADD_MULTIPLE } from './constants'
import Location from './model'

export type LocationMap = Map<string, Location>

export const initialState: LocationMap = Map()

export default (state: LocationMap = initialState, action: AnyAction): LocationMap => {
  switch (action.type) {
    case ADD_MULTIPLE:
      return state.merge(action.locations)
  }
  return state
}
