import React, { useState, useRef } from 'react'
import { View } from 'react-primitives'
import PaymentFields from 'payment-fields'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import config from 'src/config'
import { Label } from 'src/retired/shared/Typography'
import { Spacing, ActivityIndicator } from 'src/retired/elements'
import User from 'src/entities/user/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { DonateButton } from '..'

import { Donor } from './DonorDetailsForm'

import './GlobalGivingForm.css'

interface GlobalGivingFormProps {
  onToken: (token: string) => void
  total: number
  submitting: boolean
  donor?: Donor
  user?: User
  amountValid: boolean
}

const GlobalGivingForm = ({
  onToken,
  total,
  submitting,
  user,
  donor,
  amountValid,
}: GlobalGivingFormProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  const [isValid, setIsValid] = useState(false)
  const [tokenize, setTokenize] = useState(null)
  const paymentFields = useRef()
  const buttonDisabled = !amountValid || !isValid || !total || !(user || donor)

  const submit = () => {
    if (tokenize && !submitting) {
      tokenize()
        .then(({ token }: any) => onToken(token.nonce))
        .catch((error: any) => console.error(error))
    }
  }
  return (
    <View>
      <PaymentFields
        ref={paymentFields}
        vendor="Braintree"
        authorization={config.braintreeTokenizerKey}
        onValidityChange={(ev: any) => setIsValid(ev.isValid)}
        onReady={({ tokenize: newTokenize }: any) => {
          setTokenize(() => newTokenize)
        }}
        styles={{
          base: {
            'font-size': '12px',
            color: '#b6bdbd',
          },
          focus: { color: '#000000' },
          valid: { color: '#6369FF' },
          invalid: { color: '#a00000' },
        }}
      >
        <Label colour={colors.grayMediumDark} marginLeft={20}>
          {t`creditCard`}
        </Label>

        <Spacing marginBottom={12} />

        <View
          style={[
            css`
              display: flex;
              flex-direction: row;
              border-radius: 20px;
              background-color: #ffffff;
              border: 1px solid #ebeef0;
              margin: 10px 0;
              padding: 0 20px;
              height: 40px;
              max-width: 300px;
            `,
          ]}
        >
          <PaymentFields.Field
            onValidityChange={(e) => {
              if (e.isValid) {
                paymentFields.current?.api?.hostedFields?.focus?.('expirationDate')
              }
            }}
            className="gg-cardNumber"
            type="cardNumber"
            placeholder={t`cardNumber`}
          />

          <PaymentFields.Field
            onValidityChange={(e) => {
              if (e.isValid) {
                paymentFields.current?.api?.hostedFields?.focus?.('cvv')
              }
            }}
            className="gg-expirationDate"
            type="expirationDate"
            placeholder="MM/YY"
          />

          <PaymentFields.Field className="gg-cvc" type="cvv" placeholder="CVC" />
        </View>
      </PaymentFields>

      <Spacing marginBottom={36} />

      <DonateButton color="primary" size="small" disabled={buttonDisabled} onPress={submit}>
        {submitting ? <ActivityIndicator color="#fff" /> : t`completeDonation`}
      </DonateButton>
    </View>
  )
}

export default GlobalGivingForm
