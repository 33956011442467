import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import countryList from 'country-list'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Screen, Text, Loading, Spacing } from 'src/retired/elements'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { useNonprofitNominationByIdQuery } from 'src/generated/graphql'
import { PageTitle } from 'src/components'

const NonprofitNomination = (): JSX.Element => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('nominateNonprofitForm')
  const { nonprofitNominationId } = useParams<{ nonprofitNominationId: string }>()

  const { data, loading } = useNonprofitNominationByIdQuery({
    variables: {
      nonprofitNominationId,
    },
  })

  const nomination = data?.nonprofitNomination

  if (loading) {
    return <Loading />
  }

  return nomination ? (
    <Screen>
      <PageTitle title={t`organizationNomination`} />
      <ImageHeaderScrollView
        title={t`organizationNomination`}
        subTitle={t('date:dayMonthShort', { date: new Date(nomination.createdAt) })}
        status={t('nominationStatus', { context: nomination.status })}
        color={colors.brandColor}
        maxHeight={220}
      >
        <View style={{ padding: 40 }}>
          <Text>{t`organizationName`}</Text>
          <Text bold>{nomination?.nonprofitName}</Text>
          <Spacing marginBottom={20} />
          <Text>{t`country`}</Text>
          <Text bold>{countryList.getName(nomination.country)}</Text>
          <Spacing marginBottom={20} />
          {nomination?.email && (
            <>
              <Text>{t`organizationEmail`}</Text>
              <Text bold>{nomination?.email}</Text>
              <Spacing marginBottom={20} />
            </>
          )}
          {nomination?.ein && (
            <>
              <Text>{t`organizationEin`}</Text>
              <Text bold>{nomination?.ein}</Text>
              <Spacing marginBottom={20} />
            </>
          )}
          {nomination?.phone && (
            <>
              <Text>{t`organizationPhoneNumber`}</Text>
              <Text bold>{nomination?.phone}</Text>
              <Spacing marginBottom={20} />
            </>
          )}
          {nomination?.website && (
            <>
              <Text>{t`organizationWebsite`}</Text>
              <Text bold>{nomination?.website}</Text>
              <Spacing marginBottom={20} />
            </>
          )}
          <Text>{t`engagementType`}</Text>
          <Text bold>{nomination?.engagementType}</Text>
          <Spacing marginBottom={20} />
          {nomination?.otherNote && <Text italic>{nomination?.otherNote}</Text>}
        </View>
      </ImageHeaderScrollView>
    </Screen>
  ) : (
    <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen>
  )
}

export default NonprofitNomination
