import { Observable } from 'rxjs'
import { ActionsObservable } from 'redux-observable'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import MailApi from 'src/entities/mail/api'
import { showErrorAction, showMessageAction } from 'src/containers/modules/Alerts/actions'

import { SEND_EMAIL } from './constants'
import { SendEmailAction, sendEmailFailedAction, sendEmailSuccessAction } from './actions'

const sendEmail = (action$: ActionsObservable<SendEmailAction>) =>
  action$.ofType(SEND_EMAIL).exhaustMap(({ emailType, message }) =>
    MailApi.send(emailType, message)
      .mergeMap(() => [
        showMessageAction(i18n.t('mailFormScreen:weHaveReceivedYourRequest')),
        sendEmailSuccessAction(),
        replace('/'),
      ])
      .catch((e) => Observable.of(sendEmailFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred'))))
  )

export default [sendEmail]
