import { styled } from 'src/theme/styled'
import DeviceInfo from 'src/utils/DeviceInfo'
import { EmotionTheme } from 'src/theme/ThemeProvider'

export default styled.View<object, EmotionTheme>`
  align-self: stretch;
  padding-top: ${DeviceInfo.getNotchValue('top')}px;
  z-index: 10;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.gray03};
  border-bottom-color: ${({ theme }) => theme.colors.gray02};
  border-bottom-width: 1px;
`
