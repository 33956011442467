import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state: any) => state.get('createDeedForm') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))
export const selectStep = createSelector(selectState, (state) => state.get('step'))
export const selectSubmitting = createSelector(selectState, (state) => state.get('submitting'))
export const selectDeleting = createSelector(selectState, (state) => state.get('deleting'))
export const selectSubmittedDeed = createSelector(selectState, (state) => state.get('submittedDeed'))
