import { View } from 'react-primitives'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { LargeUp } from 'src/retired/elements/MediaQuery'
import Deed from 'src/entities/deed/model'
import { eventTypes } from 'src/containers/screens/Deed/components/InfoBlock'
import { AddToCalendar, Row, Spacing, Text } from 'src/retired/elements'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Body2 } from 'src/retired/shared/Typography'

import { getEndDateFormattedTime, getEventEndDateTime, getStartDateFormattedTime } from '../utils'

interface AddToCalendarBlockProps {
  deed: Deed
}

export const AddToCalendarBlock = ({ deed }: AddToCalendarBlockProps): JSX.Element => {
  const { t } = useTranslation('deedScreen')
  const { colors, metrics } = useDeedTheme()

  const user = useSelector(selectCurrentUser)

  const startDateFormattedTime = getStartDateFormattedTime(deed)
  const endDateFormattedTime = getEndDateFormattedTime(deed)
  const wide = metrics.screenWidth > 640

  return (
    <>
      {eventTypes.includes(deed.type) ? (
        <Spacing
          marginTop={!metrics.isLarge ? 0 : 20}
          marginBottom={metrics.isSmall ? 0 : 10}
          style={{ alignSelf: 'stretch', flexGrow: 1 }}
        >
          <Row style={wide ? {} : { justifyContent: 'space-between' }}>
            <View style={{ justifyContent: 'center' }}>
              <AddToCalendar size={15} deed={deed} userId={user?.id}>
                {`${startDateFormattedTime} – ${getEventEndDateTime(deed)}`}
              </AddToCalendar>
            </View>
          </Row>
          <Row style={{ marginTop: 12 }}>
            <Text style={{ marginRight: 3 }}>{t`addToCalendar:addTo`}</Text>
            <AddToCalendar style={{ color: colors.teal, marginRight: 3 }} deed={deed} userId={user?.id}>
              {t`addToCalendar:calendar`}
            </AddToCalendar>
            <Text>&bull;</Text>
            <AddToCalendar style={{ color: colors.teal, marginLeft: 3 }} deed={deed} userId={user?.id} type="GOOGLE">
              Google
            </AddToCalendar>
          </Row>
        </Spacing>
      ) : null}

      {deed.type === 'Campaign' && startDateFormattedTime && (
        <LargeUp>
          <Spacing marginTop={20}>
            <Body2>{`${startDateFormattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`}</Body2>
          </Spacing>
        </LargeUp>
      )}
    </>
  )
}
