import React from 'react'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

interface FieldErrorProps {
  theme: EmotionTheme
}

const FieldError = styled.Text<object, EmotionTheme>`
  color: ${({ theme }: FieldErrorProps) => theme.colors.redDark};
  font-size: 12px;
  margin-top: -14px;
  margin-bottom: 16px;
`

export const ErrorText = ({ text, style }: { text: string; style?: object }): JSX.Element => (
  <FieldError style={style}>{text}</FieldError>
)
