import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StyleSheet, View } from 'react-primitives'

import { selectUserBrand } from 'src/entities/user/selectors'
import { PageTitle } from 'src/components'
import { TextWithIcon } from 'src/components/TextWithIcon'
import { useBadgeCertificateQuery } from 'src/generated/graphql'
import { useParams } from 'src/navigation'
import { H1, H4, H5 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { AlignedImage, Loading } from 'src/retired/elements'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'

import './styles.css'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 64,
  },
  topBorder: {
    height: 68,
    width: '100%',
  },
  printButtonView: { width: '100%', alignItems: 'flex-end', marginTop: 16, paddingRight: 32 },
  centerView: { width: '80%', alignItems: 'center', paddingTop: 16 },
  footerColumn: { flexDirection: 'column', alignItems: 'center', paddingTop: 16 },
})

export const BadgeCertificate = () => {
  const { t } = useTranslation('badgeCertificate')
  const { colors, metrics, images } = useDeedTheme()
  const brand = useSelector(selectUserBrand)
  const params = useParams<{ id: string }>()
  const { data, error, loading } = useBadgeCertificateQuery({
    variables: {
      where: { id: params?.id },
    },
    skip: !params?.id,
  })

  if (loading) {
    return <Loading fill={false} />
  }

  if (error || !data || !data?.badgeCertificate) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  const certificate = data?.badgeCertificate
  const { certificateNumber, userName, logo, earnedAt, name, badgeCertificateHeaderImage } = certificate
  const brandColor = certificate.brandColor ?? brand?.brandColor ?? undefined

  return (
    <View style={styles.container}>
      <PageTitle title={t(`title`, { name: name || 'Deed' })} />
      {badgeCertificateHeaderImage ? (
        <img src={badgeCertificateHeaderImage} width="100%" alt="Header" />
      ) : (
        <View style={[styles.topBorder, { backgroundColor: brandColor }]} />
      )}
      <View style={styles.printButtonView}>
        <Button
          onClick={() => window.print()}
          variant="outlined"
          sx={{
            height: 32,
            displayPrint: 'none',
            borderColor: brandColor,
          }}
        >
          <TextWithIcon icon="receiptLongOutlined" text={t`printOrSaveAsPdf`} color={brandColor} />
        </Button>
      </View>
      <View style={styles.centerView}>
        <View style={{ marginBottom: 32 }}>
          <AlignedImage source={logo ?? images.deedLogoHorizontal} alt={name} width={100} height={100} />
        </View>
        <H4 style={{ textAlign: 'center' }}>{t`certifyThat`}</H4>
        <H1 colour={brandColor} style={{ textAlign: 'center' }}>
          {userName}
        </H1>
        <View style={{ marginTop: 20, height: 3, backgroundColor: colors.gray08, width: '100%', marginBottom: 32 }} />
        <H4 style={{ textAlign: 'center' }}>{t`successfullyCompleted`}</H4>
        <H4 colour={brandColor}>{name}</H4>
      </View>
      <div
        style={{
          display: 'flex',
          flexDirection: metrics.isSmall ? 'column' : 'row',
          width: '80%',
          justifyContent: 'space-between',
          paddingTop: 16,
        }}
        className="certificate-footer"
      >
        <View style={styles.footerColumn}>
          <H4>{certificateNumber}</H4>
          <H5 colour={colors.gray05}>{t`certificationNumber`}</H5>
        </View>
        <View style={styles.footerColumn}>
          <H4>{t('date:dayMonthYear', { date: new Date(earnedAt) })}</H4>
          <H5 colour={colors.gray05}>{t`dateObtained`}</H5>
        </View>
      </div>
    </View>
  )
}

export default BadgeCertificate
