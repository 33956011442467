import { SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const submitAction = (token, password, operation) => ({
  type: SUBMIT,
  token,
  password,
  operation,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
