/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { View } from 'react-primitives'

import { Body1, Body2, H3 } from 'src/retired/shared/Typography'
import { LinkButton } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Icon from 'src/retired/shared/Icon'

import { Chip } from '../Chip/Chip'

export const InformationCard = ({
  children,
  style,
  headerStyle,
  childrenStyle,
  buttonText,
  secondaryButtonText,
  buttonTo,
  secondaryButtonTo,
  title,
  subtitle,
  chipLabel,
  headerContent,
  bottomText,
  bottomTextIconName,
}: {
  children?: JSX.Element
  style?: React.CSSProperties
  headerStyle?: React.CSSProperties
  childrenStyle?: React.CSSProperties
  buttonText?: string
  secondaryButtonText?: string
  buttonTo?: string
  secondaryButtonTo?: string
  title?: string | JSX.Element
  subtitle?: string
  chipLabel?: string
  headerContent?: any
  bottomText?: string
  bottomTextIconName?: string
}) => {
  const { colors, metrics } = useDeedTheme()

  const styles = {
    header: {
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      backgroundColor: colors.gray03,
      width: '100%',
      height: metrics.isSmall ? 'content-fit' : 'auto',
      minHeight: !metrics.isSmall && 154,
      paddingVertical: 32,
      paddingHorizontal: metrics.isSmall ? 16 : 20,
    },
    bottomText: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 20,
      backgroundColor: colors.gray03,
      width: '100%',
      padding: metrics.isSmall ? 24 : 32,
      borderWidth: 1,
      borderColor: colors.lightBorderGray,
      marginTop: 24,
      flex: 1,
    },
    pointer: {
      width: 20,
      height: 20,
      borderBottomWidth: 1,
      borderRightWidth: 1,
      backgroundColor: colors.gray03,
      borderColor: colors.lightBorderGray,
      transform: 'rotate(45deg)',
      position: 'absolute',
      bottom: -10,
      left: 'calc(50% - 14px)',
    },
  }
  return (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 40,
        backgroundColor: colors.white,
        borderColor: colors.borderGray,
        padding: metrics.isSmall ? 16 : 20,
        flex: 1,
        ...style,
      }}
    >
      <View style={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
        {chipLabel ? (
          <Chip
            label={chipLabel}
            style={{
              backgroundColor: colors.greenLighter,
              fontSize: 12,
              height: metrics.isSmall ? 24 : 32,
              color: colors.greenDark,
              marginBottom: metrics.isSmall ? 8 : 16,
              alignSelf: 'flex-start',
            }}
          />
        ) : null}
        {title || subtitle ? (
          <View style={{ ...headerStyle, ...styles.header }}>
            {title && (
              <H3 center style={{ fontSize: metrics.isMedium || metrics.isSmall ? 24 : 40, fontWeight: '500' }}>
                {title}
              </H3>
            )}
            {subtitle && (
              <Body1 center style={[metrics.isSmall ? { fontSize: 14 } : null, { paddingTop: 6 }]}>
                {subtitle}
              </Body1>
            )}
            {headerContent}
          </View>
        ) : null}
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            ...childrenStyle,
          }}
        >
          {children}
        </View>
        {bottomText ? (
          <View style={styles.bottomText}>
            {bottomTextIconName ? (
              <View style={{ marginRight: 24 }}>
                <Icon icon={bottomTextIconName} />
              </View>
            ) : null}
            <Body2>{bottomText}</Body2>
            <View style={styles.pointer} />
          </View>
        ) : null}
        {secondaryButtonText && secondaryButtonTo ? (
          <View style={{ paddingTop: 12 }}>
            <LinkButton
              to={secondaryButtonTo}
              Container={(props) => (
                <Button
                  {...props}
                  palette="secondary"
                  style={{ border: 0, textDecoration: 'underline', backgroundColor: 'transparent' }}
                >
                  {secondaryButtonText}
                </Button>
              )}
            />
          </View>
        ) : null}
        {buttonTo && buttonText ? (
          <View style={{ paddingTop: 24, width: '100%' }}>
            <LinkButton fluid to={buttonTo} style={{ backgroundColor: colors.brandColor, boxShadow: 'none' }}>
              {buttonText}
            </LinkButton>
          </View>
        ) : null}
      </View>
    </View>
  )
}
