import { Observable } from 'rxjs'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { submitSuccessAction, submitFailedAction } from './actions'
import { SUBMIT } from './constants'

const submit = (action$, store) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(() => {
      const user = selectCurrentUser(store.getState())
      const consent = {
        ...user.consent,
        disclaimer: new Date(),
        disclaimerId: user?.organization?.disclaimer?._id?.toString(),
      }
      return UserApi.update({ consent })
        .mergeMap((action) => [action, submitSuccessAction()])
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    })
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [submit]
