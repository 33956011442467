import { createSelector } from 'reselect'

import { initialState } from './reducer'

const selectState = (state) => state.get('login') || initialState

export const selectAuthenticating = createSelector(selectState, (state) => state.get('authenticating'))

export const selectAuthenticatingApple = createSelector(selectState, (state) => state.get('authenticatingApple'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
