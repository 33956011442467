import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { OrderedSet } from 'immutable'

import { DeedMap } from 'src/entities/deed/reducer'
import { OrganizationMap } from 'src/entities/organization/reducer'
import { styled } from 'src/theme/styled'
import FeedList from 'src/retired/blocks/Feed/List/index'
import Button from 'src/retired/shared/Button'
import { NonprofitsList } from 'src/containers/screens/Nonprofits/NonprofitsList'
import Organization from 'src/entities/organization/model'
import { Loading } from 'src/retired/elements'

const ButtonWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
`

interface DeedListWithPaginationProps {
  deeds: DeedMap | OrganizationMap
  hasMoreItems: boolean
  onSeeMore?: () => void
  loading: boolean
  useNonprofitList?: boolean
  cardWidth?: string
  numberOfColumns?: number
}

export const DeedListWithPagination = ({
  deeds,
  hasMoreItems,
  onSeeMore,
  loading,
  useNonprofitList,
  cardWidth,
  numberOfColumns,
}: DeedListWithPaginationProps) => {
  const { t } = useTranslation('searchScreen')

  return (
    <View>
      {useNonprofitList ? (
        <NonprofitsList
          loading={loading && !deeds.count()}
          nonprofits={deeds as unknown as OrderedSet<Organization | undefined>}
          cardWidth={cardWidth}
          numberOfColumns={numberOfColumns}
          withoutPaddingBottom
        />
      ) : (
        <FeedList
          loading={loading}
          loaderPosition="bottom"
          deeds={deeds}
          cardWidth={cardWidth}
          numberOfColumns={numberOfColumns}
          backgroundColorForSmallScreens="transparent"
          withoutPaddingBottom
        />
      )}

      {/* Needed when pressing "Show more" on nonprofit list */}
      {useNonprofitList && loading && !!deeds.count() && (
        <View style={{ marginTop: 20 }}>
          <Loading fill={false} backgroundColor="transparent" fullHeight />
        </View>
      )}

      {onSeeMore && !!deeds?.count() && hasMoreItems && !loading && (
        <ButtonWrapper>
          <Button palette="secondary" onPress={onSeeMore}>
            {t('seeMore')}
          </Button>
        </ButtonWrapper>
      )}
    </View>
  )
}
