import Dimensions from 'src/utils/Dimensions'
import Platform from 'src/utils/Platform'
import DeviceInfo from 'src/utils/DeviceInfo'

interface MetricsBase {
  doubleSection: number
  images: { small: number; large: number; logo: number; medium: number }
  deedImageRatio: number
  maxSmall: number
  baseMargin: number
  baseSpacing: number
  section: number
  doubleBaseMargin: number
  XLScreen: number
  icons: { small: number; large: number; xl: number; tiny: number; medium: number }
  maxWidthWide: number
  horizontalLineHeight: number
  marginHorizontal: number
  marginVertical: number
  smallMargin: number
  maxWidthNarrow: number
  maxMedium: number
}

interface MetricsExtra {
  screenWidth: number
  screenHeight: number
  isSmall: boolean
  tabMenuHeight: number
  isMedium: boolean
  isLarge: boolean
  isExtraLarge: boolean
}

export type MetricsProps = MetricsBase & MetricsExtra

const metricsBase: MetricsBase = {
  marginHorizontal: 10,
  marginVertical: 10,
  section: 25,
  baseMargin: 10,
  baseSpacing: 8,
  doubleBaseMargin: 20,
  smallMargin: 5,
  doubleSection: 50,
  horizontalLineHeight: 1,
  deedImageRatio: 16 / 9,
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 50,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 200,
  },
  maxWidthNarrow: 1336,
  maxWidthWide: 1592,
  maxSmall: 639,
  maxMedium: 1023,
  XLScreen: 1600,
}

export const calculateMetrics = (): MetricsProps => {
  const { width, height } = Dimensions.get('window')

  const screenWidth = width < height || Platform.OS === 'web' || width > 800 ? width : height
  const screenHeight = width < height || Platform.OS === 'web' || height > 800 ? height : width

  return {
    ...metricsBase,
    screenWidth,
    screenHeight,
    isSmall: screenWidth <= metricsBase.maxSmall,
    isMedium: screenWidth > metricsBase.maxSmall && screenWidth <= metricsBase.maxMedium,
    isLarge: screenWidth > metricsBase.maxMedium,
    isExtraLarge: screenWidth > metricsBase.XLScreen,
    tabMenuHeight: screenHeight > 650 ? (DeviceInfo.hasNotch() ? 60 : 50) : 40,
    baseSpacing: screenWidth > metricsBase.maxMedium ? metricsBase.baseSpacing : 6,
  }
}

export default calculateMetrics()
