import { createSelector } from 'reselect'

import { DonationCreditState, DonationCreditMap } from './reducer'
import DonationCredit from './model'

export const selectDonationCreditState = (state: any): DonationCreditState => state.get('entities').donationCredit

export const selectDonationCreditTransactions = createSelector<any, DonationCreditState, DonationCreditMap>(
  selectDonationCreditState,
  (donationCreditState) => donationCreditState.get('donationCredits')
)

export const selectDonationCredits = createSelector<any, DonationCreditMap, DonationCreditMap>(
  selectDonationCreditTransactions,
  (donationCreditTransactions) =>
    donationCreditTransactions.filter((donationCredit: DonationCredit) => donationCredit?.creditAmount > 0)
)
