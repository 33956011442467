import React from 'react'
import { View, StyleSheet } from 'react-primitives'

import { Action, ActivityIndicator, Button, Form, Screen, Text, TextField } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { PasswordRequirements } from 'src/components/PasswordRequirements'
import { colors, metrics } from 'src/theme'

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch',
    flex: 1,
  },
  field: {
    marginTop: 25,
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  label: {
    marginRight: 15,
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 10,
  },
})

const PasswordForm = ({
  formProps: { submitting, values, errors, dirty, touched, isValid, setFieldValue, setFieldTouched, handleSubmit },
  formFields: { title, backTo, subTitle, placeholder, placeholder2, submitCta },
  userInputs = [],
}) => (
  <Screen fixed>
    <NavigationHeader title={title} backTo={backTo} />
    <Screen padding narrow action middle={metrics.screenWidth > 640}>
      <Form onSubmit={handleSubmit} style={styles.form}>
        <Text size={24} lineHeight={32} bold style={{ alignSelf: 'stretch' }}>
          {subTitle}
        </Text>
        <View style={styles.field}>
          <TextField
            placeholder={placeholder}
            onChangeText={setFieldValue}
            onTouched={setFieldTouched}
            name="password"
            secureTextEntry
            value={values.password}
            maxLength={50}
            autoCapitalize="none"
            autoFocus
          />
        </View>
        {values.password.length ? <PasswordRequirements password={values.password} userInputs={userInputs} /> : null}

        <View style={styles.field}>
          <TextField
            placeholder={placeholder2}
            onChangeText={setFieldValue}
            onTouched={setFieldTouched}
            name="password2"
            secureTextEntry
            value={values.password2}
            maxLength={50}
            autoCapitalize="none"
            onSubmitEditing={handleSubmit}
          />
        </View>
        <Text color={colors.redDark} style={styles.error}>
          {touched.password2 && errors.password2 && errors.password2}
        </Text>
      </Form>
    </Screen>
    <Action>
      <Button type="submit" disabled={!(dirty && isValid)} onPress={handleSubmit} color="primary" fluid>
        {submitting ? <ActivityIndicator color="#fff" /> : submitCta}
      </Button>
    </Action>
  </Screen>
)

export default PasswordForm
