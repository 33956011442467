import { fromJS, Map } from 'immutable'
import type { AnyAction } from 'redux'

import { TypedMap } from 'src/utils/typed-map'

import { ADD, ADD_MULTIPLE, REMOVE, VOLUNTEER_TIMES_FOR_DEED_LOADED } from './constants'
import VolunteerTime from './model'

export type VolunteerTimeMap = Map<string, VolunteerTime>

export type VolunteerTimeState = TypedMap<{
  volunteerTimes: VolunteerTimeMap
  volunteerTimesForDeedLoaded: Map<string, boolean>
}>

export const initialState = fromJS({
  volunteerTimes: Map(),
  volunteerTimesForDeedLoaded: Map(),
}) as VolunteerTimeState

export default (state: VolunteerTimeState = initialState, action: AnyAction): VolunteerTimeState => {
  switch (action.type) {
    case ADD:
      return state.set('volunteerTimes', state.get('volunteerTimes').set(action.volunteerTime.id, action.volunteerTime))

    case ADD_MULTIPLE:
      return state.set('volunteerTimes', state.get('volunteerTimes').merge(action.volunteerTimes))

    case REMOVE:
      return state.set('volunteerTimes', state.get('volunteerTimes').remove(action.id))

    case VOLUNTEER_TIMES_FOR_DEED_LOADED:
      return state.set('volunteerTimesForDeedLoaded', state.get('volunteerTimesForDeedLoaded').set(action.deedId, true))
  }
  return state
}
