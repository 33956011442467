import { ADD, UPDATE, RATED_DEED, COMPLETE_DEED, FOLLOW_ORGANIZATION, UNFOLLOW_ORGANIZATION } from './constants'
import User from './model'
import Following from './following'

export interface AddUser {
  type: typeof ADD
  user: User
}
export const addAction = (user: User): AddUser => ({
  type: ADD,
  user,
})

export interface UpdateUser {
  type: typeof UPDATE
  user: User
  properties: string[]
}
export const updateAction = (user: User, properties: string[] = []): UpdateUser => ({
  type: UPDATE,
  user,
  properties,
})

export const ratedDeedAction = () => ({
  type: RATED_DEED,
})

export const completeDeedAction = (id: string) => ({
  type: COMPLETE_DEED,
  id,
})

export const followOrganizationAction = (following: Following) => ({
  type: FOLLOW_ORGANIZATION,
  following,
})

export const unfollowOrganizationAction = (id: string) => ({
  type: UNFOLLOW_ORGANIZATION,
  id,
})
