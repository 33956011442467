import React from 'react'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Avatar, Row } from 'src/retired/elements'
import { Chip } from 'src/components/Chip/Chip'
import { AvatarData } from 'src/entities/campaign/model'
import { NUM_INITIAL_ACTIVITIES } from 'src/containers/screens/Actions/constants'

export enum AvatarType {
  user = 'user',
  organization = 'organization',
}

export const NoParticipants = ({ label }: { label: string }) => {
  const { colors } = useDeedTheme()

  return (
    <Row>
      <Avatar
        link={false}
        iconImage="peaceFingers"
        style={{ borderWidth: 1, borderColor: colors.primary, backgroundColor: colors.grayMild }}
      />
      <ParticipantNumberChip label={label} style={{ paddingLeft: 10, paddingRight: 10 }} />
    </Row>
  )
}

export const ParticipantNumberChip = ({ label, style }: { label: string; style?: React.CSSProperties }) => {
  const { colors } = useDeedTheme()

  return (
    <Chip
      color="secondary"
      label={label}
      style={{
        height: 40,
        borderRadius: 20,
        color: colors.primary,
        borderWidth: 0,
        backgroundColor: colors.grayMild,
        ...style,
      }}
      textColor={colors.primary}
    />
  )
}

export const AvatarRow = ({
  items,
  maxImageItems = NUM_INITIAL_ACTIVITIES,
  withLink = true,
  withParticipantExtension = true,
  type = AvatarType.user,
  numAllItems,
}: {
  items: AvatarData[]
  maxImageItems?: number
  withLink?: boolean
  withParticipantExtension?: boolean
  type?: AvatarType
  // Use numAllItems in case the items-array length doesn't represent
  // the full list of all items
  numAllItems?: number | null
}) => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('actions')
  const itemsLength = numAllItems || items.length || 0

  return (
    <Row style={{ alignItems: 'center', flexGrow: 0 }}>
      {items?.length ? (
        <>
          {items?.slice(0, maxImageItems)?.map((item, index) => (
            <Avatar
              key={index}
              user={item}
              style={{ borderWidth: 1, borderColor: colors.primary }}
              link={withLink}
              type={type}
            />
          ))}
          {itemsLength > maxImageItems ? (
            <ParticipantNumberChip
              label={`+ ${itemsLength - maxImageItems}`}
              style={{
                fontSize: itemsLength < 1000 ? 14 : 10,
              }}
            />
          ) : null}
          {itemsLength && itemsLength < 3 && withParticipantExtension ? (
            <ParticipantNumberChip
              label={t('participants', { count: itemsLength })}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            />
          ) : null}
        </>
      ) : (
        <NoParticipants label={t(metrics.isSmall ? 'beFirst' : 'beFirstOne')} />
      )}
    </Row>
  )
}
