import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { initialState } from './reducer'

const selectState = (state: State) => state.get('cause') || initialState

export const selectLoading = createSelector(selectState, (state) => state.loading)

export const selectError = createSelector(selectState, (state) => state.error)

export const selectFetchingNonprofits = createSelector(selectState, (state) => state.fetchingNonprofits)

export const selectFetchingProjects = createSelector(selectState, (state) => state.fetchingProjects)

export const selectFetchingCampaigns = createSelector(selectState, (state) => state.fetchingCampaigns)

export const selectFetchingEvents = createSelector(selectState, (state) => state.fetchingEvents)

export const selectFetchingBaseEvents = createSelector(selectState, (state) => state.fetchingBaseEvents)

export const selectFetchingNonprofitsError = createSelector(selectState, (state) => state.fetchingNonprofitsError)

export const selectSubmitting = createSelector(selectState, (state) => state.submitting)
