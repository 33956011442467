import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { View } from 'react-primitives'
import { DonationProvider } from '@joindeed/calculate-fees'

import { H5, Label } from 'src/retired/shared/Typography'
import getFullDonationProviderName from 'src/utils/donationProviders'
import { colors } from 'src/theme'

import { type AnyProviderData } from './payment/useProviderData'

type Props = {
  providerData: AnyProviderData
  nonprofitNamesFormatted: string
}

const TaxReceiptsLabel = ({ providerData, nonprofitNamesFormatted }: Props) => {
  const { t } = useTranslation('donateScreen')

  const tParam = {
    providerName: providerData.donationProvider
      ? getFullDonationProviderName(
          providerData.donationProvider,
          providerData.countryCode === 'US' ? '' : providerData.country
        )
      : undefined,
    nonprofitNames: nonprofitNamesFormatted,
  }

  if (providerData.donationProvider === DonationProvider.PayPal && providerData.payPalModel === 'PPGF') {
    return <Label>{t('receiptPPGFCountries', tParam)}</Label>
  }

  if (
    (providerData.donationProvider === DonationProvider.PayPal &&
      ['DIRECT', 'COMMERCE'].includes(providerData.payPalModel)) ||
    providerData.donationProvider === DonationProvider.Stripe
  ) {
    return (
      <p>
        <Trans
          t={t}
          i18nKey="receiptDirect"
          values={tParam}
          components={{
            SupportLink: (
              <a href="mailto:support@joindeed.com" style={{ color: colors.blue }}>
                \1
              </a>
            ),
          }}
        />
      </p>
    )
  }

  if (providerData.donationProvider === DonationProvider.NFG) {
    // @NOTE-AC: Looks bad, does work.
    // 'receiptGlobalGiving' is actually provider-agnostic 🤷
    // "receiptGlobalGiving": "[...] receipt from {{providerName}} [...]"
    return <Label>{t('receiptGlobalGiving', tParam)}</Label>
  }

  if (providerData.donationProvider === DonationProvider.GlobalGiving) {
    return <Label>{t('receiptGlobalGiving', tParam)}</Label>
  }

  if (providerData.donationProvider === DonationProvider.GiveIndia) {
    return <Label>{t('receiptGiveIndia', tParam)}</Label>
  }

  return <Label>{t('receiptOther', tParam)}</Label>
}

export const TaxReceipts = ({ providerData, nonprofitNamesFormatted }: Props) => {
  const { t } = useTranslation('donateScreen')
  return (
    <>
      <H5 weight="500" marginBottom={8}>{t`taxReceipts`}</H5>

      <View>
        <TaxReceiptsLabel providerData={providerData} nonprofitNamesFormatted={nonprofitNamesFormatted} />
      </View>
    </>
  )
}
