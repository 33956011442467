import { Box, Button } from '@mui/material'
import { TuneOutlined, HighlightOffRounded } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface EditButtonProps {
  inEditing: boolean
  onEdit: () => void
  onCancel: () => void
}

export const EditButton = ({ inEditing, onEdit, onCancel }: EditButtonProps) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')

  return (
    <Box>
      <Button
        variant="text"
        onClick={inEditing ? onCancel : onEdit}
        startIcon={inEditing ? <HighlightOffRounded /> : <TuneOutlined />}
      >
        {inEditing ? t('common:Cancel') : t('common:edit')}
      </Button>
    </Box>
  )
}
