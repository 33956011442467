import type { AnyAction } from 'redux'

import { SHOW_MESSAGE, SHOW_ERROR, CLOSE } from './constants'

export const showMessageAction = (title: string, message?: string): AnyAction => ({
  type: SHOW_MESSAGE,
  title,
  message,
})

export const showErrorAction = (title = 'Error', error?: Error, reload = false): AnyAction => ({
  type: SHOW_ERROR,
  title,
  error,
  reload,
})

export const closeAction = (): AnyAction => ({
  type: CLOSE,
})
