import { compose } from 'redux'

import { injectReducer, injectEpics } from 'src/utils'
import { Deed } from 'src/containers/screens/Deed/Deed'

import epics from './epics'
import reducer from './reducer'

const withReducer = injectReducer({ key: 'deed', reducer })
const withEpics = injectEpics({ key: 'deed', epics })

export default compose(withReducer, withEpics)(Deed)
