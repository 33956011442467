/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react'
import { View } from 'react-primitives'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import colors from 'src/theme/colors'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import UserApi from 'src/entities/user/api'
import { formatAmount } from 'src/utils/format'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { Body1, Body2, H5, Label } from 'src/retired/shared/Typography'
import { Select, SelectProps } from 'src/components'
import Tooltip, { TooltipContext } from 'src/retired/elements/Tooltip'
import Row from 'src/retired/elements/Row'
import Button from 'src/retired/shared/Button'
import Spacing from 'src/retired/elements/Spacing'
import type { State } from 'src/reducers'

export interface CurrencyData {
  currencyCode: CurrencyCode
  rates: {
    [sourceCurrencyCode: string]: {
      [targetCurrencyCode: string]: number
    }
  }
}

interface RatesCache {
  [cacheKey: string]: Promise<CurrencyData>
}

export interface AmountCurrencies {
  [currencyCode: string]: number
}

const ratesCache: RatesCache = {}
const fetchRates = async (): Promise<CurrencyData> => {
  const cacheKey = `${new Date().toISOString().slice(0, 10)}`
  if (cacheKey in ratesCache) {
    return ratesCache[cacheKey]
  }
  const fetchPromise = UserApi.myCurrency().toPromise() as Promise<CurrencyData>
  ratesCache[cacheKey] = fetchPromise
  return fetchPromise
}

export type CurrencyRates = Record<CurrencyCode, number>

export interface Currency {
  symbol: string
  code: CurrencyCode
  label: string
  rates: CurrencyRates | null
  zeroDecimal?: boolean
}

type Currencies = Record<CurrencyCode, Currency>

export const currencies: Currencies = {
  USD: {
    symbol: '$',
    code: 'USD',
    label: 'US dollar',
    rates: null,
  },
  EUR: {
    symbol: '€',
    code: 'EUR',
    label: 'Euro',
    rates: null,
  },
  GBP: {
    symbol: '£',
    code: 'GBP',
    label: 'Pound sterling',
    rates: null,
  },
  CHF: {
    symbol: 'CHF',
    code: 'CHF',
    label: 'Swiss franc',
    rates: null,
  },
  CAD: {
    symbol: 'CA$',
    code: 'CAD',
    label: 'Canadian dollar',
    rates: null,
  },
  AUD: {
    symbol: 'AU$',
    code: 'AUD',
    label: 'Australian dollar',
    rates: null,
  },
  RON: {
    symbol: 'lei',
    code: 'RON',
    label: 'Romanian Leu',
    rates: null,
  },
  SGD: {
    symbol: 'S$',
    code: 'SGD',
    label: 'Singapore dollar',
    rates: null,
  },
  INR: {
    symbol: '₹',
    code: 'INR',
    label: 'Indian rupee',
    rates: null,
  },
  HKD: {
    symbol: 'HK$',
    code: 'HKD',
    label: 'Hong Kong dollar',
    rates: null,
  },
  NZD: {
    symbol: 'NZ$',
    code: 'NZD',
    label: 'New Zealand dollar',
    rates: null,
  },
  JPY: {
    symbol: '¥',
    code: 'JPY',
    label: 'Yen',
    rates: null,
    zeroDecimal: true,
  },
  DKK: {
    symbol: 'kr.',
    code: 'DKK',
    label: 'Danish krone',
    rates: null,
  },
  ILS: {
    symbol: '₪',
    code: 'ILS',
    label: 'Israeli shekel',
    rates: null,
  },
  ZAR: {
    symbol: 'R',
    code: 'ZAR',
    label: 'South African rand',
    rates: null,
  },
  TRY: {
    symbol: '₺',
    code: 'TRY',
    label: 'Turkish lira',
    rates: null,
  },
  ARS: {
    symbol: 'ARS$',
    code: 'ARS',
    label: 'Argentine peso',
    rates: null,
  },
  BRL: {
    symbol: 'R$',
    code: 'BRL',
    label: 'Brazilian real',
    rates: null,
  },
  MXN: {
    symbol: 'Mex$',
    code: 'MXN',
    label: 'Mexican peso',
    rates: null,
  },
  CLP: {
    symbol: 'CLP$',
    code: 'CLP',
    label: 'Chilean peso',
    rates: null,
    zeroDecimal: true,
  },
  PEN: {
    symbol: 'S/',
    code: 'PEN',
    label: 'Peruvian sol',
    rates: null,
  },
  COP: {
    symbol: 'COL$',
    code: 'COP',
    label: 'Colombian peso',
    rates: null,
  },
  PLN: {
    symbol: 'zł',
    code: 'PLN',
    label: 'Polish złoty',
    rates: null,
  },
  BOB: {
    symbol: 'Bs',
    code: 'BOB',
    label: 'Bolivian boliviano',
    rates: null,
  },
  CNY: {
    symbol: 'CN¥',
    code: 'CNY',
    label: 'Chinese yuan renminbi',
    rates: null,
  },
  KRW: {
    symbol: '₩',
    code: 'KRW',
    label: 'South Korean won',
    rates: null,
    zeroDecimal: true,
  },
  PHP: {
    symbol: '₱',
    code: 'PHP',
    label: 'Philippine peso',
    rates: null,
  },
  THB: {
    symbol: '฿',
    code: 'THB',
    label: 'Thai baht',
    rates: null,
  },
  KZT: {
    symbol: '₸',
    code: 'KZT',
    label: 'Kazakhstani tenge',
    rates: null,
  },
  UAH: {
    symbol: '₴',
    code: 'UAH',
    label: 'Ukrainian hryvnia',
    rates: null,
  },
  CZK: {
    symbol: 'Kč',
    code: 'CZK',
    label: 'Czech Koruna',
    rates: null,
  },
  HUF: {
    symbol: 'Ft',
    code: 'HUF',
    label: 'Hungarian forint',
    rates: null,
    zeroDecimal: true,
  },
  SEK: {
    symbol: 'kr',
    code: 'SEK',
    label: 'Swedish Krona',
    rates: null,
  },
  NOK: {
    symbol: 'kr',
    code: 'NOK',
    label: 'Norwegian Krone',
    rates: null,
  },
  CRC: {
    symbol: '₡',
    code: 'CRC',
    label: 'Costa Rican colón',
    rates: null,
  },
  VND: {
    symbol: '₫',
    code: 'VND',
    label: 'Vietnamese dong',
    rates: null,
  },
  IDR: {
    symbol: 'Rp',
    code: 'IDR',
    label: 'Indonesian Rupian',
    rates: null,
  },
  // EGP: {
  //   symbol: 'E£',
  //   code: 'EGP',
  //   label: 'Egyptian pound',
  //   rates: null,
  // },
  // JOD: {
  //   symbol: 'JOD',
  //   code: 'JOD',
  //   label: 'Jordanian dinar',
  //   rates: null,
  // },
  // MAD: {
  //   symbol: 'DH',
  //   code: 'MAD',
  //   label: 'Moroccan dirham',
  //   rates: null,
  // },
}

export type CurrencyCode =
  | 'USD'
  | 'EUR'
  | 'CHF'
  | 'GBP'
  | 'CAD'
  | 'AUD'
  | 'RON'
  | 'SGD'
  | 'INR'
  | 'HKD'
  | 'NZD'
  | 'JPY'
  | 'DKK'
  | 'ILS'
  | 'ZAR'
  | 'TRY'
  | 'ARS'
  | 'BRL'
  | 'MXN'
  | 'CLP'
  | 'PEN'
  | 'COP'
  | 'PLN'
  | 'BOB'
  | 'CNY'
  | 'KRW'
  | 'PHP'
  | 'THB'
  | 'KZT'
  | 'UAH'
  | 'CZK'
  | 'HUF'
  | 'SEK'
  | 'NOK'
  | 'CRC'
  | 'IDR'
  | 'VND'
// | 'EGP'
// | 'JOD'
// | 'MAD'

export const useCurrency = (passedCurrencyCode?: CurrencyCode): Currency => {
  const [currencyData, setExchangeRates] = useState<null | CurrencyData>(null)
  const savedCurrencyCode = useSelector<State>((state) => selectLocalSetting(state, 'currency'))
  const me = useSelector(selectCurrentUser)
  const locationId = me?.location
  useEffect(() => {
    let isCancelled = false
    fetchRates()
      .then((data) => {
        if (!isCancelled) {
          setExchangeRates(data)
        }
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e))
    return () => {
      isCancelled = true
    }
  }, [locationId])

  const currencyCode: CurrencyCode = passedCurrencyCode || savedCurrencyCode || currencyData?.currencyCode || 'USD'
  const ratesForCurrency: CurrencyRates = {}
  Object.keys(currencyData?.rates || {}).forEach((sourceCurrencyCode) => {
    // @NOTE-AC: It looks quite dangerous to treat missing rates as 1
    ratesForCurrency[sourceCurrencyCode] = currencyData?.rates?.[sourceCurrencyCode][currencyCode] || 1
  })

  const currency = currencies[currencyCode] ? currencies[currencyCode] : currencies.USD

  currency.rates = ratesForCurrency

  return currency
}

function formatAbbreviated(value: number): string {
  if (value < 1e3) {
    return `${Math.round(value / 100) * 100}`
  }
  if (value < 1e6) {
    return `${Math.round(value / 1e3)}k`
  }
  return `${Math.round(value / 1e6)}m`
}

export const currencyFormat = (
  amount: number,
  currencyCode: CurrencyCode,
  short = false,
  process?: 'ceil' | 'floor' | 'round',
  abbreviated = false
): string => {
  const currencySymbol = currencies[currencyCode]?.symbol || ''
  let processedAmount = amount
  if (process) {
    processedAmount = Math[process](processedAmount)
  }
  return `${currencySymbol}${abbreviated ? formatAbbreviated(processedAmount) : formatAmount(processedAmount, short)}`
}

export const currenciesFormat = (
  amountCurrencies: AmountCurrencies,
  currencyCode: CurrencyCode,
  short = false,
  process?: 'ceil' | 'floor' | 'round',
  abbreviated = false
): string => {
  const amount = amountCurrencies?.[currencyCode] || 0
  return currencyFormat(amount, currencyCode, short, process, abbreviated)
}

export const convert = (amount: number, conversionRate: number): number => amount * conversionRate

export interface CurrencyFormatProps {
  // `amount` and `baseCurrency` must be set together
  // Used for live amounts that are based on today's rate
  amount?: number
  baseCurrency?: CurrencyCode

  // Used for pre-converted amounts on the server-side
  amountCurrencies?: AmountCurrencies

  short?: boolean
  abbreviated?: boolean
  process?: 'ceil' | 'floor' | 'round'

  // If you don't want your currency be formatted to the user's setting currency but to another one.
  customCurrency?: CurrencyCode

  disambiguateCurrency?: boolean
}

const confusingCurrencies = ['DKK', 'NOK', 'SEK', 'USD'] as const

export const CurrencyFormat: React.FC<CurrencyFormatProps> = ({
  amount,
  baseCurrency,
  amountCurrencies,
  short,
  process,
  abbreviated,
  customCurrency,
  disambiguateCurrency,
}) => {
  const currency = useCurrency(customCurrency)

  const currencyCodeSuffix =
    disambiguateCurrency && currency.code && (confusingCurrencies as readonly string[]).includes(currency.code) ? (
      <>
        {' '}
        <span style={{ color: colors.grayMediumDark, fontSize: '0.8em', fontWeight: 'normal' }}>({currency.code})</span>
      </>
    ) : null

  if (Number.isFinite(amount) && baseCurrency) {
    const rate = currency.rates?.[baseCurrency]
    if (!rate) {
      return <></>
    }
    return (
      <>
        {currencyFormat(amount * rate, currency.code, short || currency.zeroDecimal, process, abbreviated)}
        {currencyCodeSuffix}
      </>
    )
  }
  if (amountCurrencies) {
    return (
      <>
        {currenciesFormat(amountCurrencies, currency.code, short || currency.zeroDecimal, process, abbreviated)}
        {currencyCodeSuffix}
      </>
    )
  }
  return null
}

interface CurrencyFormatWithTooltipProps {
  amount?: number
  amountCurrencies?: AmountCurrencies
  baseCurrency: CurrencyCode
  short?: boolean
  textStyle?: any
}
export const CurrencyFormatWithTooltip: React.FC<CurrencyFormatWithTooltipProps> = React.memo(
  ({ amount, amountCurrencies, baseCurrency, short = false, textStyle = {} }) => {
    const { t } = useTranslation('currencyFormat')
    const currency = useCurrency()

    const value = (
      <H5 weight="500" style={textStyle} {...textStyle}>
        <CurrencyFormat amount={amount} baseCurrency={baseCurrency} amountCurrencies={amountCurrencies} short={short} />
      </H5>
    )

    if (currency.code === baseCurrency) {
      return value
    }

    return (
      <Tooltip
        Toggle={() => (
          <View style={{ overflow: 'hidden' }}>
            <View
              style={{
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: colors.lightGray,
                marginTop: -1,
                marginLeft: -1,
                marginRight: -1,
              }}
            >
              {value}
            </View>
          </View>
        )}
      >
        <View style={{ padding: 15 }}>
          <Body1 weight="500" marginBottom={16}>
            {t`currencyConversion`}
          </Body1>
          {currency.code !== baseCurrency && (
            <Body1>
              <Trans
                t={t}
                i18nKey="theAmountIsConvertedFrom"
                components={{
                  targetAmount: (
                    <CurrencyFormat
                      amount={amount}
                      baseCurrency={baseCurrency}
                      amountCurrencies={amountCurrencies}
                      short={short}
                    />
                  ),
                  baseAmount: amount
                    ? currencyFormat(amount, baseCurrency)
                    : amountCurrencies && currenciesFormat(amountCurrencies, baseCurrency),
                }}
              />
            </Body1>
          )}
        </View>
      </Tooltip>
    )
  }
)

const CurrencySelectOptionStyle = {
  display: 'flex',
  gap: '8px',
}

const CurrencySelectSymbolStyle = {
  order: 1,
  fontSize: '80%',
  fontWeight: 'normal',
  color: colors.gray,
  width: '24px',
  textAlign: 'center',
}

const CurrencySelectLabelStyle = {
  order: 2,
}

const CurrencySelectCodeStyle = {
  order: 3,
  fontSize: '80%',
  fontWeight: 'normal',
  color: colors.grayLight,
}

export type CurrencySelectProps = React.FC<SelectProps> & {
  showcasedOptions?: string[]
  availableOptions?: string[]
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  showcasedOptions = [],
  availableOptions = [],
  ...selectProps
}) => {
  const allCurrencies: Currency[] = Object.values(currencies)

  const showcasedCurrencies = allCurrencies.filter((currency) => showcasedOptions.includes(currency.code))
  const availableCurrencies = allCurrencies.filter(
    (currency) =>
      !showcasedOptions.includes(currency.code) &&
      (availableOptions.length === 0 || availableOptions.includes(currency.code))
  )
  availableCurrencies.sort((a: Currency, b: Currency) => a.label.localeCompare(b.label))

  const displayedCurrencies = showcasedCurrencies.concat(availableCurrencies)

  const options = displayedCurrencies.map((currency) => ({
    value: currency.code,
    title: (
      <div style={CurrencySelectOptionStyle}>
        <span style={CurrencySelectLabelStyle}>{currency?.label}</span>
        <span style={CurrencySelectSymbolStyle}>{currency?.symbol}</span>
        <span style={CurrencySelectCodeStyle}>({currency?.code})</span>
      </div>
    ),
  }))

  return <Select options={options} {...selectProps} />
}

interface CurrencySelectorTooltipProps {
  textStyle?: any
  text: string
  labelStyle?: React.CSSProperties
  labelContainerStyle?: React.CSSProperties
}
export const CurrencySelectorTooltip: React.FC<CurrencySelectorTooltipProps> = React.memo(
  ({ text, labelContainerStyle, labelStyle }) => {
    const { t } = useTranslation('currencyFormat')
    const currency = useCurrency()
    const dispatch = useDispatch()
    const { metrics } = useDeedTheme()

    return (
      <Tooltip
        width={Math.min(metrics.screenWidth - 20, 600)}
        Toggle={() => (
          <View style={{ overflow: 'hidden' }}>
            <View
              style={{
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: colors.gray,
                marginTop: -1,
                marginLeft: -1,
                marginRight: -1,
                ...labelContainerStyle,
              }}
            >
              <Label colour={colors.grayMediumDark} style={labelStyle}>
                {text}
              </Label>
            </View>
          </View>
        )}
      >
        <View style={{ padding: metrics.isSmall ? 0 : 15 }}>
          <Row style={{ flexWrap: 'wrap', alignItems: 'center' }}>
            <Body2 weight="500" marginRight={10}>
              {t`selectCurrency`}
            </Body2>
            <CurrencySelect
              value={currency.code}
              showcasedOptions={['USD', 'EUR']}
              onSelect={(value) => dispatch(setLocalSettingAction('currency', value))}
            />
          </Row>
          <Spacing marginTop={20} />
          <Body2 colour={colors.darkGray}>{t`changingTheCurrency`}</Body2>
          <Spacing marginTop={10} />
          <Body2 colour={colors.darkGray}>{t`youWillStillBeCharged`}</Body2>
          <Spacing marginTop={20} />
          <TooltipContext.Consumer>
            {(onClose) => (
              <Button palette="primary" onPress={onClose} style={{ width: metrics.isSmall ? undefined : 150 }}>
                {t`apply`}
              </Button>
            )}
          </TooltipContext.Consumer>
        </View>
      </Tooltip>
    )
  }
)
