import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectCheckinDeeds } from 'src/containers/modules/CheckInBar/selectors'
import FeedCheckInBar from 'src/retired/blocks/Feed/CheckInBar'
import { useDeedTheme } from 'src/theme/ThemeProvider'

const CheckInBar = () => {
  const { metrics } = useDeedTheme()

  const user = useSelector(selectCurrentUser)
  const checkInDeeds = useSelector(selectCheckinDeeds)

  if (checkInDeeds.length === 0) {
    return null
  }

  const DeedFeedCheckInBar = () =>
    checkInDeeds.map((deed) => <FeedCheckInBar key={deed.id} deed={deed} checkedIn={deed.isUserCheckedIn(user)} />)

  return (
    <View
      style={{
        position: 'sticky',
        bottom: metrics.screenWidth <= 1024 ? 67 : 0,
        left: 0,
        right: 0,
        // TODO: Above Zendesk Widget
        zIndex: 999999,
      }}
    >
      <DeedFeedCheckInBar />
    </View>
  )
}

export default CheckInBar
