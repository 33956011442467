import { Observable } from 'rxjs'
import i18n from 'i18next'
import _ from 'lodash'

import { selectCauses } from 'src/entities/cause/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import CausesApi from 'src/entities/cause/api'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import OrganizationApi from 'src/entities/organization/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import SkillsApi from 'src/entities/skill/api'

import { initSuccessAction, initFailedAction, followSuccessAction, followFailedAction } from './actions'
import { INIT, FOLLOW } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap((payload) => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)
    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    const organization = selectOrganizationById(state, payload.id)
    if (
      !organization ||
      // @TODO: this is a hackish way to check for fully loaded state.
      // Replace it with a proper partial fetching mechanism when new API comes
      (organization.acceptsDonations === undefined &&
        // External orgs don't need full fetching
        !organization.isExternal()) ||
      organization?.__typename === 'DeedNonprofit'
    ) {
      actions.push(OrganizationApi.fetch(payload.id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const follow = (action$) =>
  action$
    .ofType(FOLLOW)
    .exhaustMap(({ id, unfollow }) =>
      (unfollow ? OrganizationApi.unfollow(id) : OrganizationApi.follow(id))
        .mergeMap((action) => [action, followSuccessAction()])
        .catch((e) =>
          Observable.of(
            followFailedAction(e),
            showErrorAction(
              unfollow ? i18n.t('organizationScreen:unfollowingFailed') : i18n.t('organizationScreen:followingFailed')
            )
          )
        )
    )

export default [init, follow]
