import { Map } from 'immutable'
import { Observable } from 'rxjs'

import { useCountriesQuery, LocationsDocument, LocationsQuery } from 'src/generated/graphql'
import * as Sentry from 'src/utils/Sentry'

import { query } from '../graphql'

import Location from './model'
import { addMultipleAction } from './actions'
import { LocationMap } from './reducer'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class LocationApi {
  static fetchAll(): Observable<{
    type: string
    locations: LocationMap
  }> {
    return Observable.from(
      query<LocationsQuery>(LocationsDocument).catch((error) => {
        Sentry.captureException(error)
        return { data: { locations: [] } }
      })
    ).map((result) =>
      addMultipleAction(Map(result.data.locations.map((location) => [location.id, new Location(location)])))
    )
  }
}

export const useCountries = (): Location[] => {
  const { data } = useCountriesQuery({ onError: (error) => Sentry.captureException(error) })
  const countries = data?.countries || []
  return countries.map((country) => new Location(country))
}
