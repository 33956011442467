import { css } from 'src/theme/styled'

const borderRadius = '20px'
const backgroundColor = '#ffffff'
const border = '1px solid #ebeef0'
const margin = '8px 0 16px'
const padding = '11px 20px'
const minHeight = '40px'
const height = 'auto'
const fontSize = '12px'

export const roundedInputStyleObject = {
  borderRadius,
  backgroundColor,
  border,
  margin,
  padding,
  minHeight,
  height,
  fontSize,
}

export const innerStyle = css`
  border: 0px;
  font-size: 12px;
`

export const roundedInputStyle = css`
  border-radius: ${borderRadius};
  background-color: ${backgroundColor};
  border: ${border};
  margin: ${margin};
  padding: ${padding};
  min-height: ${minHeight};
  height: ${height};
  font-size: ${fontSize};
`
