import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import DonationCreditApi from 'src/entities/donationCredit/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { INIT, initSuccessAction, initFailedAction, InitAction } from './types'

const init = (action$: ActionsObservable<InitAction>) =>
  action$.ofType(INIT).exhaustMap(() =>
    DonationCreditApi.fetchAll()
      .mergeMap((resultingAction) => [resultingAction, initSuccessAction()])
      .catch((e) => [initFailedAction(e), showErrorAction(i18n.t('common:thereWasAnErrorLoading'))])
  )

export default [init]
