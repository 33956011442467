import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { stripTime } from 'src/utils/stripTime'
import { getNextPaymentDateFromLatestDonation } from 'src/utils/recurringDonations'
import type Donation from 'src/entities/donation/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { PaymentMethodType, useNextPayrollRunFlatFileQuery } from 'src/generated/graphql'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'
import { Spacing } from 'src/retired/elements'

import { BASE_SPACING_UNIT, Detail } from './elements'

const useNextPaymentDate = (donation: Donation) => {
  const user = useSelector(selectCurrentUser)

  const { data: payrollRunFlatFileQuery } = useNextPayrollRunFlatFileQuery({
    skip: !user?.hasFeature?.('payrollFlatFile') || donation.paymentMethodDetails?.type !== PaymentMethodType.Payroll,
    variables: { onlyDonatable: true },
  })

  if (user?.hasFeature?.('payrollApi')) {
    if (donation.paymentMethodDetails?.type === 'Payroll' && donation.payrollRun) {
      return new Date(stripTime(donation.payrollRun.payDate || donation.payrollRun.endDate))
    }
    if (donation.donationSchedule?.startingAt) {
      return getNextPaymentDateFromLatestDonation(donation.donationSchedule.startingAt, donation)
    }
  }

  if (user?.hasFeature?.('payrollFlatFile') && payrollRunFlatFileQuery?.nextPayrollRunFlatFile) {
    return new Date(
      stripTime(
        payrollRunFlatFileQuery.nextPayrollRunFlatFile.payDate || payrollRunFlatFileQuery.nextPayrollRunFlatFile.endDate
      )
    )
  }

  return null
}

export const NextPaymentDate = ({ donation }: { donation: Donation }) => {
  const { t } = useTranslation('donationCompleteScreen')
  const paymentDate = useNextPaymentDate(donation)

  if (!paymentDate) {
    return null
  }

  return (
    <>
      <Detail>
        <Body2 colour={colors.grayMediumDark}>{t`nextPayment`}</Body2>
        <Body1 weight="500" right>
          {t('date:weekdayDayMonthTimeShort', {
            date: paymentDate,
          })}
        </Body1>
      </Detail>
      <Spacing marginTop={BASE_SPACING_UNIT} />
    </>
  )
}
