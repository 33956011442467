import React from 'react'
import type { Activity as ActivityType, NewActivity } from 'getstream'
import { StatusUpdateForm, useFeedContext } from 'react-activity-feed'
import { fromJS } from 'immutable'
import { useTranslation } from 'react-i18next'

import { Alert } from 'src/retired/elements'
import { useFeedAddActivityMutation } from 'src/generated/graphql'

interface Props {
  communityId: string
}
export const AddActivityForm = ({ communityId }: Props): JSX.Element => {
  const { feedManager } = useFeedContext()
  const [feedAddActivityMutation] = useFeedAddActivityMutation()
  const { t } = useTranslation('communityFeed')

  const feedAddActivity = async (activity: NewActivity): Promise<ActivityType> => {
    try {
      const response = await feedAddActivityMutation({
        variables: {
          communityId,
          activityString: JSON.stringify(activity),
        },
      })
      const postedActivityString = response?.data?.feedAddActivity
      if (!postedActivityString) {
        throw new Error('Invalid Stream API response')
      }
      const postedActivity = JSON.parse(postedActivityString) as ActivityType

      postedActivity.latest_reactions = {}
      postedActivity.latest_reactions_extra = {}
      postedActivity.own_reactions = {}

      feedManager.setState((prevState) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        activities: prevState.activities.set(postedActivity.id, fromJS(postedActivity)),
        activityOrder: [postedActivity.id, ...prevState.activityOrder],
        activityIdToPaths: { ...prevState.activityIdToPaths, [postedActivity.id]: [[postedActivity.id]] },
      }))
      return postedActivity
    } catch (e) {
      Alert.alert(t`postActivityFailure`)
      throw e
    }
  }
  return (
    <div className="raf-status-update-form">
      <StatusUpdateForm Header={<></>} feedGroup="community" userId={communityId} doRequest={feedAddActivity} />
    </div>
  )
}
