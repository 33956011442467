import { useTranslation } from 'react-i18next'

import { useUpsertActionActivityMutation } from 'src/generated/graphql'
import * as Sentry from 'src/utils/Sentry'

import { Activity, ActivityStatus } from './types'

interface ActivityMutationsParams {
  actionId?: string
  userActivity: Activity | null
  campaignId?: string
}

export const useActivityMutations = ({ userActivity, campaignId, actionId }: ActivityMutationsParams) => {
  const { t } = useTranslation('errorTemplates')

  const onError = (error: any) => {
    Sentry.captureException(error)
    alert(t`somethingWentWrongTitle`)
  }

  const [upsertActionActivity, { loading: loadingUpsertActionActivity }] = useUpsertActionActivityMutation({ onError })

  const onUpsertAction = async (nextStatus: ActivityStatus | null) => {
    if (!actionId) {
      return
    }

    await upsertActionActivity({
      variables: {
        actionId,
        campaignId,
        status: nextStatus,
        activityId: userActivity?.id,
      },
    })
  }

  return {
    onUpsertAction,
    loadingUpsertActionActivity,
  }
}
