import React, { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
export {
  Route,
  Switch,
  Switch as Stack,
  Redirect,
  withRouter,
  Prompt,
  matchPath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'

interface BackButtonProps {
  children: React.ReactNode
}
export const BackButton = ({ children }: BackButtonProps): ReactNode => children

interface LinkProps {
  to: string
  children: React.ReactNode
  style?: object
}
export type LinkElement = React.ReactElement<LinkProps>
export const Link: React.FC<LinkProps> = ({ to, children, style, ...rest }): LinkElement => (
  <RouterLink to={to} {...rest} style={{ textDecorationLine: 'none', ...style }}>
    {children}
  </RouterLink>
)

export const Linking = null

export const historyStack = []
