import React, { useState } from 'react'
import { View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Text } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import UserApi from 'src/entities/user/api'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { DeleteAccountModal } from './DeleteAccountModal'

export const DeleteAccountSection = ({ textStyle }: { textStyle: object }) => {
  const { t } = useTranslation('settingsProfile')
  const { colors } = useDeedTheme()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const [deleted, setDeleted] = useState(false)

  const deletionAlreadyRequested = !!useSelector(selectCurrentUser).deletionRequestedAt

  const onConfirmDelete = async (): Promise<void> => {
    try {
      setError(false)
      setSubmitting(true)
      await UserApi.requestDeletion().toPromise()
      setShowConfirmationModal(false)
      setDeleted(true)
    } catch (e) {
      setError(true)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <View style={{ paddingTop: 25 }}>
      <Text size={12} medium style={textStyle}>
        {deleted || deletionAlreadyRequested ? (
          t`deletionProcessed`
        ) : (
          <Trans
            t={t}
            i18nKey="deleteMyAccount"
            components={{
              DeleteButton: (
                <Text
                  size={12}
                  medium
                  style={{
                    color: colors.blue,
                  }}
                  onPress={() => {
                    setShowConfirmationModal(true)
                  }}
                />
              ),
            }}
          />
        )}
      </Text>
      {showConfirmationModal && (
        <DeleteAccountModal
          onClose={() => {
            setShowConfirmationModal(false)
          }}
          onConfirm={onConfirmDelete}
          loading={submitting}
          showError={!!error}
        />
      )}
    </View>
  )
}
