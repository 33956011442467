export default {
  alert: (title, message, options) => {
    const text = message ? `${title}\n\n${message}` : title
    if (options) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(text)) {
        if (options[0].onPress) {
          options[0].onPress()
        } else {
          options[1].onPress()
        }
      }
    } else {
      alert(text)
    }
  },
}
