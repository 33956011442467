import { fromJS } from 'immutable'

import { SELECT_DEPARTMENT, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const initialState = fromJS({
  selectedDepartment: null,
  submitting: false,
  error: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DEPARTMENT:
      return state.set('selectedDepartment', action.department)

    case SUBMIT:
      return state.merge({ submitting: true, error: null })

    case SUBMIT_SUCCESS:
      return state.merge({ submitting: false })

    case SUBMIT_FAILED:
      return state.merge({ submitting: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
