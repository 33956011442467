import { createSelector } from 'reselect'

import Location from './model'
import { LocationMap } from './reducer'

const sortFunction = (a: Location, b: Location) => a.name.localeCompare(b.name)
const selectLocationState = (state: any): LocationMap => state.get('entities').location

// WARNING! Use selectUserLocation instead to work with employee users locations
export const selectLocations = createSelector(selectLocationState, (locations) => locations.sort(sortFunction))

export const selectLocationById = createSelector(
  selectLocationState,
  (state: any, locationId: string) => locationId,
  (locations, locationId) => locations.get(locationId)
)
