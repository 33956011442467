import React, { FC, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import type { History } from 'history'

import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row, Spacing } from 'src/retired/elements'
import { H4, H5, Body2, Body1 } from 'src/retired/shared/Typography'
import { MediumUp } from 'src/retired/elements/MediaQuery'
import IconButton from 'src/retired/shared/IconButton'
import Touchable from 'src/retired/elements/Touchable'
import { Link, useHistory } from 'src/navigation'

const CONTAINER_LEFT_MARGIN = 56

const TitleContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
  flex: 1;
`

const LinksContainer = styled.View`
  flex-direction: row;
  align-items: baseline;
`
type callback = () => void

// REASON : no history in Storybook at the moment
// @ts-expect-error: stubbing History
const historyStub = (): History => ({
  push: () => null,
})

interface BackButtonProps {
  back: string | callback
}
const BackButton: FC<BackButtonProps> = ({ back }) => {
  let history: History

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    history = useHistory()
  } catch (e) {
    history = historyStub()
  }

  return <IconButton icon="arrowLeftNew" onPress={typeof back === 'function' ? back : () => history.push(back)} />
}

interface LinkComponentProps {
  link: string | callback
  children: React.ReactNode
}
const LinkComponent: React.FC<LinkComponentProps> = ({ link, children }) =>
  typeof link === 'function' ? <Touchable onPress={link}>{children}</Touchable> : <Link to={link}>{children}</Link>

interface ScrollContainerProps {
  title?: string
  subtitle?: number | string
  dimension?: number
  render: (scrollViewOffset: number) => void
  renderList?: () => void
  link?: string | callback
  linkColour?: string
  cardType?: 'deed' | 'card' | 'campaign'
  back?: string | callback
  small?: boolean
  titleStyle?: object
  titleWeight?: 'bold' | '500' | 'normal'
  expandable?: boolean
  hideNavArrow?: boolean
  withoutTopSpacing?: boolean
  withoutBottomSpacing?: boolean
  customScrollViewOffset?: number
}

const ScrollContainer: React.FC<ScrollContainerProps> = ({
  title,
  titleStyle,
  titleWeight,
  subtitle,
  render,
  renderList,
  dimension,
  link,
  linkColour,
  cardType,
  back,
  small,
  expandable = false,
  hideNavArrow,
  withoutTopSpacing = false,
  withoutBottomSpacing = false,
  customScrollViewOffset,
}) => {
  const { t } = useTranslation('scrollContainer')
  const { colors, metrics } = useDeedTheme()

  const [expanded, setExpanded] = useState(false)

  const sizes = {
    card: {
      WIDTH: 286,
      RIGHT_MARGIN: 40,
    },
    deed: {
      /* NOTICE this is a rough estimate based on FeedDeed implementation */
      WIDTH: metrics.screenWidth < 640 ? metrics.screenWidth : metrics.screenWidth / 3,
      RIGHT_MARGIN: 10,
    },
    campaign: {
      // @NOTE-CH: Keep it in sync with app/src/containers/screens/Home/CampaignBlock/CampaignItem.tsx:32
      WIDTH: metrics.isLarge ? 930 : metrics.screenWidth - 40,
      RIGHT_MARGIN: 20,
    },
  }

  const { WIDTH, RIGHT_MARGIN } = sizes[cardType || 'card']

  const cardsInScreen = Math.floor((metrics.screenWidth - CONTAINER_LEFT_MARGIN) / (WIDTH + RIGHT_MARGIN))

  const [currentPage, setCurrentPage] = useState(0)
  const numberOfPages = dimension && dimension > 0 ? Math.ceil(dimension / (cardsInScreen || 1)) : 0
  const offsetPerNavigation = customScrollViewOffset ?? WIDTH + RIGHT_MARGIN * cardsInScreen
  const scrollViewOffset = offsetPerNavigation * currentPage

  const Header = metrics.isMedium ? H5 : H4

  const topSpacing = withoutTopSpacing ? 0 : link ? 16 : 20

  const bottomSpacing = withoutBottomSpacing && numberOfPages <= 1 ? 0 : metrics.isSmall || small ? 16 : 20

  return (
    <View>
      <Spacing marginTop={topSpacing} marginBottom={bottomSpacing}>
        <Row
          style={{
            justifyContent: link ? 'space-between' : 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          {back && (
            <Spacing marginRight={20}>
              <BackButton back={back} />
            </Spacing>
          )}
          {title ? (
            <TitleContainer>
              {numberOfPages > 1 && link ? (
                <LinkComponent link={link}>
                  <Header weight="500">{title}</Header>
                </LinkComponent>
              ) : (
                <Header weight={titleWeight || '500'} style={titleStyle || {}}>
                  {title}
                </Header>
              )}
              {(subtitle ?? undefined) !== undefined && <Body2 weight="500"> ({subtitle})</Body2>}
            </TitleContainer>
          ) : null}
          {dimension && dimension > 1 && (
            <LinksContainer>
              {!hideNavArrow && !expanded && (
                <MediumUp>
                  <IconButton
                    icon="arrowLeftNew"
                    style={{ marginRight: 16 }}
                    onPress={() => currentPage > 0 && setCurrentPage(currentPage - 1)}
                    accessibilityLabel={t`moveLeft`}
                  />
                  <IconButton
                    icon="arrowRight"
                    style={{ marginRight: 24 }}
                    onPress={() => currentPage <= numberOfPages && setCurrentPage(currentPage + 1)}
                    accessibilityLabel={t`moveRight`}
                  />
                </MediumUp>
              )}
              {!expandable && link && (
                <LinkComponent link={link}>
                  <Body1 weight="500" colour={linkColour || colors.brandColor} hoverColor="black">
                    {t`viewAll`}
                  </Body1>
                </LinkComponent>
              )}
              {expandable && (
                <Body1
                  weight="500"
                  colour={linkColour || colors.brandColor}
                  hoverColor="black"
                  onPress={() => setExpanded(!expanded)}
                >
                  {expanded ? t`collapse` : t`viewAll`}
                </Body1>
              )}
            </LinksContainer>
          )}
        </Row>
      </Spacing>
      {expanded && renderList ? renderList() : render(scrollViewOffset)}
    </View>
  )
}

export default ScrollContainer
