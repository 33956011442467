import React from 'react'
import { useSelector } from 'react-redux'

import Spacing from 'src/retired/elements/Spacing'
import ScrollView from 'src/retired/elements/ScrollView'
import Chip from 'src/retired/shared/Chip'
import Deed from 'src/entities/deed/model'
import { selectSkills } from 'src/entities/skill/selectors'

interface SkillsChipsProps {
  deed: Deed
}

export type SkillsChipElement = React.ReactElement<SkillsChipsProps>

const SkillsChips: React.FC<SkillsChipsProps> = ({ deed }): SkillsChipElement => {
  const skills = useSelector(selectSkills)
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={{ marginHorizontal: -24 }}
      contentContainerStyle={{ paddingLeft: 12, paddingRight: 24 }}
    >
      {deed.allSkills().map((skillId) => {
        const skill = skills?.get(skillId)
        return (
          skill && (
            <Spacing key={skillId} marginLeft={12}>
              <Chip type="skill">{skill.name}</Chip>
            </Spacing>
          )
        )
      })}
    </ScrollView>
  )
}

export default SkillsChips
