import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Checkbox } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { CurrencyFormatWithTooltip, CurrencyCode } from 'src/containers/modules/CurrencyFormat'

interface FeeCheckboxType {
  donationAmount: number
  checked: boolean
  setChecked: (checked: boolean) => void
  nonprofitNames: string[]
  baseCurrency: CurrencyCode
}

const FeeCheckbox = ({
  baseCurrency,
  donationAmount,
  checked,
  setChecked,
  nonprofitNames,
}: FeeCheckboxType): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()
  const [nonprofitName] = nonprofitNames

  return (
    <Checkbox checked={checked} onChange={() => setChecked(!checked)} style={{ alignItems: 'flex-end' }}>
      <Body2>
        <Trans
          t={t}
          i18nKey="illCoverTheProcessingFee"
          values={{ nonprofitName }}
          count={nonprofitNames.length}
          components={{
            CurrencyFormatWithTooltip: (
              <CurrencyFormatWithTooltip
                baseCurrency={baseCurrency}
                amount={donationAmount}
                textStyle={{ colour: colors.brandColor }}
              />
            ),
          }}
        />
      </Body2>
    </Checkbox>
  )
}

export default FeeCheckbox
