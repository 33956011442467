import { Capacitor } from '@capacitor/core'

interface Platforms {
  ios: any
  android: any
  web: any
  native: any
  default: any
}

export default {
  OS: Capacitor.getPlatform(),
  isIE: window.navigator.userAgent.includes('Trident/') || window.navigator.userAgent.includes('MSIE '),
  isFF: window.navigator.userAgent.includes('Firefox') && !window.navigator.userAgent.includes('Seamonkey'),
  select: (obj: Partial<Platforms>) => {
    const platform = Capacitor.getPlatform() as keyof Platforms

    if (Capacitor.isNativePlatform()) {
      if ('native' in obj) {
        return obj.native
      }
    }

    if (platform in obj) {
      return obj[platform]
    }

    return obj.default ?? {}
  },
}
