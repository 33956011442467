export const INIT = 'containers/Profile/createdDeeds/INIT'
export const INIT_SUCCESS = 'containers/Profile/createdDeeds/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Profile/createdDeeds/INIT_FAILED'

export interface InitAction {
  type: typeof INIT
}
export const initAction = () => ({
  type: INIT,
})

export const initFailedAction = (error: Error) => ({
  type: INIT_FAILED,
  error,
})
