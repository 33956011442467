import { Observable } from 'rxjs'
import S3Upload from 'react-s3-uploader/s3upload'
import { replace } from 'connected-react-router'
import _ from 'lodash'
import i18n from 'i18next'

import Api from 'src/entities/api'
import UserApi from 'src/entities/user/api'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'

import { uploadSuccessAction, uploadFailedAction } from './actions'
import { UPLOAD, UPLOAD_CANCELLED } from './constants'

const s3FileUpload = (blob, signedUrl, publicUrl) =>
  Observable.fromPromise(
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new S3Upload({
        files: [blob],
        getSignedUrl: (file, uploadToS3Callback) => uploadToS3Callback({ signedUrl, publicUrl }),
        contentDisposition: 'auto',
        uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
        onFinishS3Put: () => resolve(publicUrl),
        onError: (error) => reject(error),
      })
    })
  )

const upload = (action$) =>
  action$
    .ofType(UPLOAD)
    .exhaustMap((payload) => {
      // eslint-disable-next-line no-param-reassign
      payload.data.name = `${_.uniqueId()}.${payload.data.type === 'image/png' ? 'png' : 'jpg'}`
      return Api.post('api/files/credentials', {
        file_name: payload.data.name,
        file_type: payload.data.type,
      })
        .mergeMap((response) => s3FileUpload(payload.data, response.signedRequest, response.url))
        .mergeMap((publicUrl) => UserApi.update({ mainPicture: publicUrl }))
    })
    .mergeMap((resultingAction) => [
      resultingAction,
      showMessageAction(i18n.t('profileImageProfile:profileImageUploaded')),
      replace('/profile'),
      uploadSuccessAction(),
    ])
    .catch((e) =>
      Observable.of(uploadFailedAction(e), showErrorAction(i18n.t('profileImageProfile:couldNotUploadImage')))
    )

const uploadCancel = (action$) => action$.ofType(UPLOAD_CANCELLED).mergeMap(() => [replace('/profile')])

export default [upload, uploadCancel]
