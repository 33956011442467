import React from 'react'
import { Helmet } from 'react-helmet'
import type { HelmetProps } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const PageTitle = ({ title, titleTemplate = 'Deed - %s' }: HelmetProps): JSX.Element => {
  const { t } = useTranslation('pageTitle')

  return <Helmet titleTemplate={titleTemplate} title={title ?? t`doSomethingGoodToday`} />
}
