import React from 'react'
import PropTypes from 'prop-types'
import { View, StyleSheet } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import LogoLoader from 'src/retired/shared/LogoLoader'

const Loading = ({ fill = true, backgroundColor, fullHeight = true }) => {
  const { metrics, colors } = useDeedTheme()
  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: backgroundColor || colors.white,
          height: fullHeight ? '100vh' : 100,
        },
      ]}
    >
      <View style={styles.activityIndicator}>
        {fill ? (
          <LogoLoader />
        ) : (
          <ActivityIndicator
            color={fill && metrics.isSmall ? colors.white : colors.userOrganizationBrandColor || colors.coral}
            size="large"
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    position: 'absolute',
  },
})
Loading.propTypes = {
  fill: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

export default Loading
