import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Layout from 'src/retired/blocks/Layout'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { Spacing, Loading } from 'src/retired/elements'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { PageTitle } from 'src/components'
import CommunitiesBlockComponent from 'src/containers/screens/Communities/CommunitiesBlock'
import { useCommunitiesQuery } from 'src/generated/graphql'

const Communities = (): JSX.Element => {
  const { t } = useTranslation('communitiesScreen')
  const user = useSelector(selectCurrentUser)
  const { metrics } = useDeedTheme()

  const isCommunitiesFeatureEnabled = user?.hasFeature('communities')

  const { data: communitiesData, error, loading } = useCommunitiesQuery({ skip: !isCommunitiesFeatureEnabled })

  const communities = communitiesData?.communities || []

  if (error) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }
  if (loading || !user) {
    return <Loading />
  }

  const backLink = '/home'

  return (
    <Layout fullWidth padding>
      <PageTitle title={t`communities`} />
      <Spacing paddingTop={metrics.isSmall ? 0 : 30}>
        <CommunitiesBlockComponent communities={communities} title={t`communities`} back={backLink} isResizable />
      </Spacing>
    </Layout>
  )
}

export default Communities
