import { Observable } from 'rxjs'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import OrganizationApi from 'src/entities/organization/api'

import { SUBMIT, INIT } from './constants'
import { initSuccessAction, initFailedAction, submitSuccessAction, submitFailedAction } from './actions'

const init = (action$) =>
  action$.ofType(INIT).mergeMap(() =>
    OrganizationApi.fetchCompanies()
      .mergeMap((actions) => [...actions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred'))))
  )

const submit = (action$) =>
  action$.ofType(SUBMIT).exhaustMap(({ email }) =>
    UserApi.requestResetPassword(email)
      .mergeMap(() => [submitSuccessAction()])
      .catch((e) => {
        if (!e.response) {
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        }
        return Observable.of(
          submitFailedAction(e),
          e.response.status === 403
            ? showErrorAction(i18n.t('loginScreen:cannotResetPassword'))
            : showErrorAction(i18n.t('common:anErrorOccurred'))
        )
      })
  )

export default [init, submit]
