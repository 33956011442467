import { fromJS, Set } from 'immutable'

import {
  INIT,
  TOGGLE_SELECTED_DAYS_OR_TIME,
  SET_SELECTED_DAYS_OR_TIME,
  SET_SELECTED_DATE,
  TOGGLE_SELECTED_LOCATION,
  SET_SELECTED_LOCATION,
  SET_SELECTED_LOCATION_CODE,
  TOGGLE_SELECTED_CAUSE,
  SET_SELECTED_CAUSES,
  TOGGLE_SELECTED_SKILL,
  SET_SELECTED_SKILLS,
  TOGGLE_CORPORATE_ONLY,
  RESET,
  SET_SEARCH_TERM,
} from './constants'

export const initialState = fromJS({
  initialized: false,
  selectedDaysAndTimes: Set(),
  selectedDate: null,
  selectedLocation: Set(),
  selectedLocationCode: null,
  selectedCauses: Set(),
  selectedSkills: Set(),
  corporateOnly: true,
  searchTerm: '',
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ initialized: true, corporateOnly: action.corporateOnly })

    case TOGGLE_SELECTED_DAYS_OR_TIME: {
      const selectedDaysAndTimes = state.get('selectedDaysAndTimes')
      return state.set(
        'selectedDaysAndTimes',
        selectedDaysAndTimes[selectedDaysAndTimes.has(action.daysOrTime) ? 'delete' : 'add'](action.daysOrTime)
      )
    }

    case SET_SELECTED_DAYS_OR_TIME: {
      return state.set('selectedDaysAndTimes', Set(action.daysOrTime))
    }

    case SET_SELECTED_DATE: {
      return state.set('selectedDate', action.date)
    }

    case TOGGLE_SELECTED_LOCATION: {
      const selectedLocation = state.get('selectedLocation')
      return state.set(
        'selectedLocation',
        selectedLocation[selectedLocation.has(action.location) ? 'delete' : 'add'](action.location)
      )
    }

    case SET_SELECTED_LOCATION: {
      return state.set('selectedLocation', Set(action.locations))
    }

    case SET_SELECTED_LOCATION_CODE: {
      return state.set('selectedLocationCode', action.locationCode)
    }

    case TOGGLE_SELECTED_CAUSE: {
      const selectedCauses = state.get('selectedCauses')
      return state.set(
        'selectedCauses',
        selectedCauses[selectedCauses.has(action.cause) ? 'delete' : 'add'](action.cause)
      )
    }

    case SET_SELECTED_CAUSES: {
      return state.set('selectedCauses', Set(action.causes))
    }

    case TOGGLE_SELECTED_SKILL: {
      const selectedSkills = state.get('selectedSkills')
      return state.set(
        'selectedSkills',
        selectedSkills[selectedSkills.has(action.skill) ? 'delete' : 'add'](action.skill)
      )
    }

    case SET_SELECTED_SKILLS: {
      return state.set('selectedSkills', Set(action.skills))
    }

    case TOGGLE_CORPORATE_ONLY:
      return state.set('corporateOnly', action.state)

    case SET_SEARCH_TERM:
      return state.set('searchTerm', action.searchTerm)

    case RESET: {
      return initialState
    }

    default: {
      return state
    }
  }
}
