import React from 'react'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import CardList from 'src/retired/shared/CardList'
import DeedCard from 'src/retired/shared/DeedCard'
import ScrollViewCard from 'src/retired/shared/ScrollViewCard'
import { DeedMap } from 'src/entities/deed/reducer'
import Deed from 'src/entities/deed/model'

interface DeedCardListProps {
  deeds: DeedMap
  scrollViewOffset: number
  hidePartnerLogo?: boolean
  hideLocation?: boolean
  noPadding?: boolean
  noMargin?: boolean
}

export type DeedCardListElement = React.ReactElement<DeedCardListProps>

const DeedCardList: React.FC<DeedCardListProps> = ({
  deeds,
  scrollViewOffset,
  hidePartnerLogo = false,
  hideLocation,
  noPadding,
  noMargin,
}): DeedCardListElement => {
  const { metrics } = useDeedTheme()

  return (
    <CardList noPadding={noPadding} noMargin={noMargin} scrollViewOffset={scrollViewOffset}>
      {/* Warning, we are using immutable v3 but the types somehow get taken from immutable v4 which is imported by redux-immutable
      This is probably the source of many bugs, as our code can be using 2 conflicting versions of immutable.
      In this particular case immutable v3 is being used.
      See:
        - https://immutable-js.com/docs/v3.8.2/Map/#toArray() -> toArray(): Array<V>
        - https://immutable-js.com/docs/v4.1.0/Map/#toArray() -> toArray(): Array<[K, V]>
      @ts-expect-error Type '[string, Deed]' is not assignable to type 'Deed' */}
      {deeds.toArray().map((deed: Deed) => (
        <ScrollViewCard key={deed.get('id')}>
          <DeedCard
            deed={deed}
            size={metrics.isSmall ? 'variable' : 'standard'}
            cardStyle={{ marginLeft: metrics.isSmall ? 8 : 0, marginRight: metrics.isSmall ? 8 : 0 }}
            hidePartnerLogo={hidePartnerLogo}
            hideLocation={hideLocation}
          />
        </ScrollViewCard>
      ))}
    </CardList>
  )
}

export default DeedCardList
