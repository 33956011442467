import React from 'react'

import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { TextField } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'
import { styled } from 'src/theme/styled'
import Touchable from 'src/retired/shared/Touchable'
import Hoverable from 'src/retired/shared/Hoverable'

interface InputContainerProps {
  theme?: EmotionTheme
  outlined?: boolean
}
const InputContainer = styled.View<InputContainerProps>`
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-color: ${({ outlined }: InputContainerProps) => (outlined ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.04)')};
  box-shadow: ${({ outlined }: InputContainerProps) => (outlined ? 'none' : '0px 2px 29px rgba(0, 0, 0, 0.03)')};
  flex-direction: row;
  align-items: center;
  padding: 0 23px 0 32px;
  width: 100%;
  background-color: ${({ theme }: InputContainerProps) => theme?.colors.white};
`

const IconWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface SearchInputProps {
  inputValue: string
  onChange: (fieldName: string, value: string) => void
  onReset: () => void
  triggerSearch: () => void
  fieldName: string
  placeholder: string
  outlined?: boolean
  small?: boolean
}

export const SearchInput = ({
  inputValue,
  onChange,
  onReset,
  triggerSearch,
  fieldName,
  placeholder,
  outlined,
  small,
}: SearchInputProps) => {
  const { colors } = useDeedTheme()

  return (
    <InputContainer outlined={outlined}>
      <TextField
        placeholder={placeholder}
        name={fieldName}
        returnKeyType="done"
        value={inputValue}
        onChangeText={onChange}
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            triggerSearch()
          }
        }}
        style={{ height: small ? 48 : 64, fontSize: 16 }}
      />
      <IconWrapper>
        {!!inputValue && (
          <Touchable style={{ paddingRight: 12 }} onPress={onReset} accessibilityRole="button" focusable>
            <Hoverable>
              <Icon icon="crossWhite" width={14} height={14} hexColor={colors.gray} />
            </Hoverable>
          </Touchable>
        )}
        <Touchable onPress={triggerSearch}>
          <Hoverable>
            <Icon icon="searchNew" width={18} height={18} hexColor={colors.gray} />
          </Hoverable>
        </Touchable>
      </IconWrapper>
    </InputContainer>
  )
}
