import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Checkbox, Touchable } from 'src/retired/elements'
import { Body } from 'src/retired/shared/Typography'

import { SHARE_ALL_KEY, SHARE_NAME_ONLY_KEY, SHARE_NOTHING_KEY } from '../actionNames'

type SharePrivacyOption = typeof SHARE_ALL_KEY | typeof SHARE_NAME_ONLY_KEY | typeof SHARE_NOTHING_KEY

export const shareOptions: Array<{ key: SharePrivacyOption; description: string }> = [
  {
    key: SHARE_ALL_KEY,
    description: 'shareAllKey',
  },
  {
    key: SHARE_NAME_ONLY_KEY,
    description: 'shareNameOnlyKey',
  },
  {
    key: SHARE_NOTHING_KEY,
    description: 'shareNothingKey',
  },
]

interface ShareCheckboxType {
  selected: string
  togglePrivacyType: (key: SharePrivacyOption) => void
  isCorporate: boolean
}

const ShareCheckbox = ({ selected, togglePrivacyType, isCorporate }: ShareCheckboxType) => {
  const { t } = useTranslation('donateScreen')

  return (
    <>
      {shareOptions
        .filter(({ key }) => !isCorporate || key !== SHARE_ALL_KEY)
        .map(({ key, description }) => (
          <Touchable key={key} onPress={() => togglePrivacyType(key)}>
            <Checkbox
              key={key}
              asRadio
              checked={key === selected}
              onChange={() => {
                togglePrivacyType(key)
              }}
            >
              <Trans t={t} i18nKey={description} values={{ privacyText: description }} components={{ Body }}></Trans>
            </Checkbox>
          </Touchable>
        ))}
    </>
  )
}

export default ShareCheckbox
