import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { AffiliationProfileFragment } from 'src/generated/graphql'
import { LinkButton } from 'src/retired/elements'
import { Link } from 'src/navigation'
import { colors } from 'src/theme'
import { CardCompactWrapper } from 'src/components/CardCompactWrapper/CardCompactWrapper'
import { Body1, Label } from 'src/retired/shared/Typography'

const AffiliationCard = ({ affiliation }: { affiliation: AffiliationProfileFragment }): JSX.Element => {
  const { t } = useTranslation('affiliationCompact')

  return (
    <Link key={affiliation.id} to={`/organization/${affiliation.Nonprofit.id}`}>
      <CardCompactWrapper cardColor={colors.brandColor}>
        <Label marginBottom={12}>
          {t('status', {
            context: (Date.now() < (Date.parse(affiliation.endingAt) || Infinity)).toString(),
          })}
        </Label>

        <Body1 numberOfLines={1} marginBottom={8}>
          {affiliation.affiliationType === 'BoardMember'
            ? t('affiliation', { context: affiliation.boardPosition })
            : t('affiliation', { context: affiliation.affiliationType })}
        </Body1>

        <Label numberOfLines={1}>{affiliation.Nonprofit.name}</Label>

        <View style={{ position: 'absolute', right: 12, bottom: 12 }}>
          <LinkButton
            to={`/organization/${affiliation.Nonprofit.id}/affiliations/edit`}
            color="transparent"
            style={{
              display: 'flex',
              borderRadius: 100,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: colors.brandColor,
              alignSelf: 'flex-start',
              paddingLeft: 15,
              paddingRight: 15,
              height: 21,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Label>{t`viewDetails`}</Label>
          </LinkButton>
        </View>
      </CardCompactWrapper>
    </Link>
  )
}
export default AffiliationCard
