export const INIT = 'containers/FeedFilter/INIT'
export const TOGGLE_SELECTED_DAYS_OR_TIME = 'containers/FeedFilter/TOGGLE_SELECTED_DAYS_OR_TIME'
export const SET_SELECTED_DAYS_OR_TIME = 'containers/FeedFilter/SET_SELECTED_DAYS_OR_TIME'
export const SET_SELECTED_DATE = 'containers/FeedFilter/SET_SELECTED_DATE'
export const TOGGLE_SELECTED_LOCATION = 'containers/FeedFilter/TOGGLE_SELECTED_LOCATION'
export const SET_SELECTED_LOCATION = 'containers/FeedFilter/SET_SELECTED_LOCATION'
export const SET_SELECTED_LOCATION_CODE = 'containers/FeedFilter/SET_SELECTED_LOCATION_CODE'
export const TOGGLE_SELECTED_CAUSE = 'containers/FeedFilter/TOGGLE_SELECTED_CAUSE'
export const SET_SELECTED_CAUSES = 'containers/FeedFilter/SET_SELECTED_CAUSES'
export const TOGGLE_SELECTED_SKILL = 'containers/FeedFilter/TOGGLE_SELECTED_SKILL'
export const SET_SELECTED_SKILLS = 'containers/FeedFilter/SET_SELECTED_SKILLS'
export const TOGGLE_CORPORATE_ONLY = 'containers/FeedFilter/TOGGLE_CORPORATE_ONLY'
export const SET_SEARCH_TERM = 'containers/FeedFilter/SET_SEARCH_TERM'
export const RESET = 'containers/FeedFilter/RESET'
