import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Text } from 'src/retired/shared/Typography'
import { Row } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Campaign from 'src/entities/campaign/model'

type Props = {
  campaign: Campaign | undefined
}
export const CampaignPledgeDetails = ({ campaign }: Props) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')

  const { colors } = useDeedTheme()

  if (!campaign || !campaign.isPledge) {
    return null
  }

  const { pledgedDonationStartingAt, pledgedDonationEndingAt } = campaign
  return (
    <>
      <View style={{ borderColor: colors.gray02, borderTopWidth: 1, paddingHorizontal: 36, paddingVertical: 20 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <View>
            <Text fontSize={12} paddingBottom={5}>
              {t`campaign`}
            </Text>
            <Text fontSize={14} weight="500">
              {campaign?.title}
            </Text>
          </View>
        </Row>
      </View>
      <View style={{ borderColor: colors.gray02, borderTopWidth: 1, paddingHorizontal: 36, paddingVertical: 20 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <View>
            <Text fontSize={12} paddingBottom={5}>
              {t`pledgeStarts`}
            </Text>
            <Text fontSize={14} weight="500">
              {t('date:dayMonthYearShort', {
                date: pledgedDonationStartingAt,
              })}
            </Text>
          </View>
        </Row>
      </View>
      <View style={{ borderColor: colors.gray02, borderTopWidth: 1, paddingHorizontal: 36, paddingVertical: 20 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <View>
            <Text fontSize={12} paddingBottom={5}>
              {t`pledgeEnds`}
            </Text>
            <Text fontSize={14} weight="500">
              {pledgedDonationEndingAt !== undefined
                ? t('date:dayMonthYearShort', { date: pledgedDonationEndingAt })
                : '-'}
            </Text>
          </View>
        </Row>
      </View>
    </>
  )
}
