import { PayPalCharityType } from 'src/entities/organization/model'

// NOTE-RA: [sc-65287] same array defined in MP here: https://github.com/joindeed/marketplace/blob/c9d616407f18888b33038ecd8fd052736f3d81df/packages/mongoose-models/src/utils/estimateDonationMatchDisbursedAt.ts
const PPGF_CHARITY_ENROLLMENT_STATUSES = [PayPalCharityType.PPGF_ENROLLED, PayPalCharityType.DIRECT_MODEL_ENROLLED]

export const isPPGFEnrolled = (paypalCharityType?: PayPalCharityType) =>
  paypalCharityType ? PPGF_CHARITY_ENROLLMENT_STATUSES.includes(paypalCharityType) : false

export const estimatePayPalMatchDisbursementDate = (paypalCharityType?: PayPalCharityType): number => {
  const isEnrolled = isPPGFEnrolled(paypalCharityType)
  const now = new Date()
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  const daysLeft = endOfMonth.getDate() - now.getDate()

  // NOTE-RA: some hardcoded logic because PayPal usually handles disbursements from their end 25 days into the month
  // to NPOs that are PPGF enrolled, otherwise they try to unboard them, and this takes up an extra 90 days
  return daysLeft + 25 + (!isEnrolled ? 90 : 0)
}
