import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentUser } from 'src/entities/user/selectors'

import CurrencyInput from './CurrencyInput'
import { getCurrencyFormatting } from './util'

interface Props {
  name: string
  value: string
  onValueChange: (value?: string, name?: string) => void
  currencySymbol: string
  customPlaceholder?: string
  // Set decimalsLimit to 0 if you want to just allow full numbers
  decimalsLimit?: number
  // Currently we don't use locale based formatting because there would be inconsistencies in formatting between inputs and text fields.
  useLocaleFormatting?: boolean
  maxLength?: number
  style?: object
  autoFocus?: boolean
  editable?: boolean // prop to control editability
}

const Input = ({
  name,
  value,
  onValueChange,
  currencySymbol,
  customPlaceholder,
  decimalsLimit,
  useLocaleFormatting,
  maxLength,
  style,
  autoFocus,
  editable = true,
}: Props) => {
  const user = useSelector(selectCurrentUser)

  const { groupSeparator, decimalSeparator } = getCurrencyFormatting(useLocaleFormatting ? user?.locale : 'en-US')
  const currencyPrefix = currencySymbol
  const placeholder = customPlaceholder ?? `${currencySymbol}0${decimalSeparator}00`

  return (
    <CurrencyInput
      name={name}
      placeholder={placeholder}
      value={value ? String(value)?.replace('.', decimalSeparator) : value}
      onValueChange={(currentValue, currentName) => {
        // Makes sure we always call the callback with a "." separation.
        // (Needed for web and e.g. German where we have comma decimal-separation)
        const newValue = currentValue?.replace(decimalSeparator, '.')
        onValueChange(newValue, currentName)
      }}
      currencyPrefix={currencyPrefix}
      decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator}
      decimalsLimit={decimalsLimit ?? 2}
      maxLength={maxLength}
      style={style}
      autoFocus={autoFocus}
      editable={editable}
    />
  )
}

export default Input
