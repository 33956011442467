import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import { Touchable, Divider, Screen, Spacing, Text, Row, Icon } from 'src/retired/elements'
import { colors } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { useHistory } from 'src/navigation'

const RegisterCompanyPending: FC = () => {
  const { t } = useTranslation('companyRegister')
  const user = useSelector(selectCurrentUser)
  const previousLocation = useSelector((state) => selectLocalSetting(state, 'previousLocation'))
  const history = useHistory()
  const dispatch = useDispatch()
  const browseDeeds = () => {
    if (previousLocation) {
      dispatch(setLocalSettingAction('previousLocation', null))
    }
    history.push(previousLocation || '/home')
  }
  return (
    <Screen fixed>
      <Screen padding middle narrow>
        <Text size={32} lineHeight={46} bold style={{ alignSelf: 'stretch' }}>
          {t`niceOne`}
        </Text>
        <Spacing marginTop={25}>
          {!user?.emailVerificationToken && (
            <Text size={16} lineHeight={24}>
              {t`yourInformationHasBeenSentToYourEmployer`}
            </Text>
          )}
          <Spacing marginTop={8}>
            <Text color={colors.blue} size={16} lineHeight={24}>
              <Trans
                t={t}
                i18nKey={
                  user?.emailVerificationToken ? 'youAreAlmostReadyToDoSomeGood' : 'youWillReceiveAConfirmationViaEmail'
                }
                values={{
                  userEmail: user?.email,
                }}
                components={{
                  EmailAddress: <Text size={16} color={colors.blue} lineHeight={24} bold />,
                }}
              />
            </Text>
          </Spacing>
        </Spacing>
        <Divider />
        <Text size={16} lineHeight={24}>
          {t`inTheMeantime`}
        </Text>
        <Spacing marginTop={25} />
        <Touchable onPress={browseDeeds}>
          <Row style={{ alignItems: 'center' }}>
            <Text size={16} lineHeight={24} bold underline>
              {t`browseNonCorporateDeeds`}
            </Text>
            <Icon icon="arrowRight" style={{ width: 19, height: 16, marginLeft: 8 }} />
          </Row>
        </Touchable>
      </Screen>
    </Screen>
  )
}
export default RegisterCompanyPending
