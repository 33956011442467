import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Deed from 'src/entities/deed/model'
import User from 'src/entities/user/model'
import VolunteerTime from 'src/entities/volunteerTime/model'
import { Body2 } from 'src/retired/shared/Typography'
import { formatFullName } from 'src/utils'
import { ExternalLink } from 'src/retired/elements'

export const VolunteerRewardInfo = ({
  user,
  deed,
  volunteerTime,
}: {
  user: User
  deed?: Deed
  volunteerTime: VolunteerTime
}) => {
  const { t } = useTranslation()
  const { metrics, colors } = useDeedTheme()
  const durationHours = Math.floor(volunteerTime.duration / 60)
  const durationMinutes = volunteerTime.duration % 60
  const deedName = deed?.name || volunteerTime?.deed?.name

  const information = [
    {
      title: t`common:employeeId`,
      content: user?.externalId,
    },
    {
      title: t`common:deed`,
      content: deedName ?? t(`common:externalVolunteeringFor`, { nonprofitName: volunteerTime.externalNonprofitName }),
    },
    {
      title: t`common:employeeName`,
      content: formatFullName(user?.fullName),
    },
    {
      title: t`common:Nonprofit`,
      content: deed?.organization?.name ?? volunteerTime.nonprofit?.name ?? volunteerTime.externalNonprofitName ?? '-',
    },
    {
      title: t`common:email`,
      content: user?.email,
    },
    {
      title: t`common:Duration`,
      content: `${durationHours} ${t`common:hours`}${
        durationMinutes ? ` ${durationMinutes} ${t`common:minutes`}` : ''
      }`,
    },
    {
      title: t`common:date`,
      content: t('date:dayMonthYear', { date: volunteerTime.date }),
    },
  ]

  const externalLink = user?.organization?.settings?.volunteerReward?.externalLink
  return (
    <div>
      <View
        style={{
          paddingVertical: 16,
          paddingHorizontal: 20,
          backgroundColor: colors.gray03,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.borderGray,
          borderRadius: 4,

          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          marginTop: 15,
        }}
      >
        {information.map(({ title, content }) => (
          <View
            key={title}
            style={{
              width: metrics.isSmall ? '100%' : '50%',
              paddingRight: metrics.isSmall ? 0 : 8,
              marginBottom: 8,
            }}
          >
            <Body2 weight="500" style={{ textOverflow: 'ellipsis' }}>
              {title}: <Body2>{content || '-'}</Body2>
            </Body2>
          </View>
        ))}
      </View>
      {externalLink ? (
        <div style={{ marginTop: 10 }}>
          <ExternalLink
            size={16}
            lineHeight={25}
            color={colors.brandColor}
            underline
            href={externalLink}
          >{t`volunteerTimeSuccessProfile:reviewTheProgramGuidelines`}</ExternalLink>
        </div>
      ) : null}
    </div>
  )
}
