export const INIT = 'containers/Deed/Confirm/INIT'
export const INIT_SUCCESS = 'containers/Deed/Confirm/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Deed/Confirm/INIT_FAILED'

export const CONFIRM = 'containers/Deed/Confirm/CONFIRM'
export const CONFIRM_SUCCESS = 'containers/Deed/Confirm/CONFIRM_SUCCESS'
export const CONFIRM_FAILED = 'containers/Deed/Confirm/CONFIRM_FAILED'

export const VTO_REQUEST = 'containers/Deed/Confirm/VTO_REQUEST'
export const VTO_REQUEST_SUCCESS = 'containers/Deed/Confirm/VTO_REQUEST_SUCCESS'
export const VTO_REQUEST_FAILED = 'containers/Deed/Confirm/VTO_REQUEST_FAILED'
