import React from 'react'

import { styled } from 'src/theme/styled'
import { Row, Icon } from 'src/retired/elements'

const StatusBlock = styled.View<{ statusType: string }>`
  border-radius: 20px;
  background-color: ${({ statusType, theme }) => theme.colors[`status${statusType}`]};
  padding: 5px 10px;
`

const StatusText = styled.Text<{ statusType: string }>`
  color: ${({ statusType, theme }) => theme.colors[`statusText${statusType}`]};
  font-size: 12px;
`

interface StatusProps {
  title: string
  type: 'Success' | 'Error' | 'Neutral'
  icon: string
}

const Status = ({ title, type, icon }: StatusProps): React.ReactElement => (
  <StatusBlock statusType={type}>
    <Row style={{ alignItems: 'center' }}>
      <StatusText statusType={type}>{title}</StatusText>
      <Icon icon={icon} style={{ marginLeft: 12, width: 12, height: 12 }} />
    </Row>
  </StatusBlock>
)

export default Status
