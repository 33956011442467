import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import NonprofitsContainer from 'src/containers/screens/Nonprofits/NonprofitsContainer'
import Campaign from 'src/entities/campaign/model'
import { DeedMap } from 'src/entities/deed/reducer'
import Spacing from 'src/retired/elements/Spacing'
import Organization from 'src/entities/organization/model'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import NonprofitsSearchBar from 'src/containers/modules/NonprofitsSearchBar'
import { Body1 } from 'src/retired/shared/Typography'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'

import { CampaignSectionBlock, CampaignSectionHeader } from './CampaignSectionBlock/CampaignSectionBlock'
import { SectionsSorting, defaultSectionsSorting } from './sectionSorting'

const DonateSearchBar = ({
  campaign,
  hasOrganizationOrFundraisers,
}: {
  campaign: Campaign
  hasOrganizationOrFundraisers: boolean
}) => {
  const { colors } = useDeedTheme()
  const { t } = useTranslation('campaignScreen')

  const isAuthenticated = useSelector(selectIsAuthenticated)
  if (!isAuthenticated) {
    return null
  }

  return (
    <div style={{ marginBottom: 40 }}>
      <CampaignSectionHeader
        customFont={campaign.customFont}
        title={hasOrganizationOrFundraisers ? t`findMoreDonationOpportunities` : t`findDonationOpportunities`}
        subTitle=""
        rowStyle={{ marginBottom: 8 }}
      />
      {campaign.autoTagDonations && (
        <div style={{ paddingBottom: 30 }}>
          <Body1 style={{ color: colors.white }}>{t`duringThePeriod`}</Body1>
        </div>
      )}
      <div>
        <NonprofitsSearchBar campaignId={campaign.id} withoutBackground />
      </div>
    </div>
  )
}

export const CampaignDeeds = ({
  campaign,
  fundraisersList,
  nonprofitsList,
  volunteeringList,
  baseEventsList,
  pastDeeds,
}: {
  campaign: Campaign
  fundraisersList: DeedMap
  nonprofitsList: Map<string, Organization>
  volunteeringList: DeedMap
  baseEventsList: DeedMap
  pastDeeds: DeedMap
}) => {
  const { t } = useTranslation('campaignScreen')

  const sectionSorting =
    Array.isArray(campaign?.contentSorting?.sections) &&
    // To make sure if sections array contains the same value as defaultSectionsSorting
    _.isEmpty(_.xor(campaign.contentSorting?.sections, defaultSectionsSorting))
      ? campaign.contentSorting?.sections
      : defaultSectionsSorting

  const { showLinkToDonateSearch } = campaign

  const hasFundraisers = !!fundraisersList?.size
  const hasNonprofits = !!nonprofitsList?.size

  return (
    <>
      {sectionSorting.map((section) => {
        switch (section) {
          case SectionsSorting.fundraisers:
            return (
              <div style={{ marginBottom: 40 }}>
                <CampaignSectionBlock
                  key={section}
                  deedList={fundraisersList.filter((deed) => !deed.ended)}
                  customFont={campaign.customFont}
                  title={t`fundraisers`}
                  withSpacing={false}
                />
              </div>
            )

          case SectionsSorting.volunteering:
            return (
              <CampaignSectionBlock
                key={section}
                deedList={volunteeringList.filter((deed) => !deed.ended)}
                customFont={campaign.customFont}
                title={t`volunteering`}
              />
            )

          case SectionsSorting.nonprofits:
            if (!nonprofitsList.size && !showLinkToDonateSearch) {
              return null
            }

            return (
              <Spacing marginBottom={40} key={section}>
                {!!nonprofitsList.size && (
                  <>
                    <CampaignSectionHeader
                      customFont={campaign.customFont}
                      title={t`organizations`}
                      subTitle={nonprofitsList.size}
                    />
                    <NonprofitsContainer nonprofits={nonprofitsList} />
                  </>
                )}
                {showLinkToDonateSearch && (
                  <DonateSearchBar campaign={campaign} hasOrganizationOrFundraisers={hasNonprofits || hasFundraisers} />
                )}
              </Spacing>
            )

          case SectionsSorting.events:
            return (
              <CampaignSectionBlock
                key={section}
                deedList={baseEventsList.filter((deed) => !deed.ended)}
                customFont={campaign.customFont}
                title={t`events`}
              />
            )

          default:
            return null
        }
      })}
      {pastDeeds?.size > 0 && (
        <View style={{ filter: 'grayscale(1)' }}>
          <CampaignSectionBlock deedList={pastDeeds} customFont={campaign.customFont} title={t`past`} />
        </View>
      )}{' '}
    </>
  )
}
