import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View, StyleSheet } from 'react-primitives'
import getDistance from 'geolib/es/getDistance'
import convertDistance from 'geolib/es/convertDistance'
import { withTranslation } from 'react-i18next'
import { Geolocation } from '@capacitor/geolocation'

import { injectReducer, injectEpics } from 'src/utils'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import StatusBar from 'src/retired/blocks/StatusBar'
import { selectDeedById } from 'src/entities/deed/selectors'
import {
  Action,
  ActivityIndicator,
  Alert,
  Avatar,
  Button,
  ExternalLink,
  Gradient,
  Icon,
  Links,
  Text,
  Map,
  MapLink,
  OnLayout,
  ScrollView,
  Spacing,
} from 'src/retired/elements'
import DeedCancel from 'src/containers/modules/DeedCancel'
import Platform from 'src/utils/Platform'
import { Redirect, Linking } from 'src/navigation'
import { colors, metrics } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { getEventStartDateTime, getEventEndDateTime } from 'src/containers/screens/Deed/utils'

import { selectGeolocation, selectLoading, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export class DeedCheckIn extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isWithinRange: false,
    }
    this.withinRange = this.withinRange.bind(this)
  }

  componentDidMount() {
    if (this.props.deed && !this.props.deed.isUserCheckedIn(this.props.user)) {
      this.props.actions.initAction()
    }
  }

  componentWillUnmount() {
    this.props.actions.stopWatchingAction()
  }

  distanceInFeet(geolocation) {
    const { deed } = this.props
    const distanceInMeters = getDistance(
      { latitude: geolocation.latitude, longitude: geolocation.longitude },
      {
        latitude: deed.locationLatLng.coordinates[1],
        longitude: deed.locationLatLng.coordinates[0],
      }
    )
    return convertDistance(distanceInMeters, 'ft')
  }

  withinRange(distanceInFeet) {
    const isWithinRange = distanceInFeet <= 2000
    if (isWithinRange) {
      this.setState({ isWithinRange })
    }
    return isWithinRange
  }

  render() {
    const { t, deed, user, geolocation, loading, actions } = this.props
    if (!deed || !deed.isDuringCheckInWindow()) {
      return <Redirect to="/home" />
    }
    let withinRange = false
    let distanceInFeet
    if (geolocation) {
      distanceInFeet = this.distanceInFeet(geolocation)
      withinRange = this.withinRange(distanceInFeet)
    }
    const tooFar = !withinRange && geolocation
    let checkInButton
    const userCheckedIn = deed.isUserCheckedIn(user)
    if (userCheckedIn) {
      checkInButton = (
        <View style={styles.checkedIn}>
          <Icon icon="checkedIn" style={{ width: 36, height: 36 }} />
          <Text size={15} medium color={colors.darkGray} style={{ marginLeft: 10 }}>
            {t`youAreCheckedIn`}
          </Text>
        </View>
      )
    } else if (withinRange) {
      checkInButton = (
        <Button style={{ backgroundColor: '#4168FA' }} onPress={() => actions.checkInAction(deed.id, user.id)}>
          {loading ? <ActivityIndicator color="#fff" /> : t`checkIn`}
        </Button>
      )
    } else if (tooFar) {
      checkInButton = (
        <Button
          color="light"
          onPress={() => {
            Alert.alert(t`whoops`, t`youMustBeWithin1000ft`)
            actions.disabledCheckInClickAction(t`outOfRange`)
          }}
        >
          {t`checkIn`}
        </Button>
      )
    } else {
      checkInButton = (
        <Button
          color="light"
          style={{ padding: 20, lineHeight: 1 }}
          onPress={async () => {
            try {
              const currentGeolocation = await Geolocation.getCurrentPosition({
                timeout: 5000,
                enableHighAccuracy: true,
              })
              actions.setGeolocationAction(currentGeolocation.coords)
              distanceInFeet = this.distanceInFeet(currentGeolocation.coords)
              if (this.withinRange(distanceInFeet)) {
                actions.checkInAction(deed.id)
              } else {
                actions.watchPositionAction()
              }
            } catch (e) {
              Alert.alert(t`whoops`, t`beSureToTurnOnYouLocation`, [
                { text: t`common:Cancel`, style: 'cancel' },
                {
                  text: Platform.OS === 'ios' ? t`settings` : t`common:Ok`.toUpperCase(),
                  onPress: () => (Platform.OS === 'ios' ? Linking.openURL('app-settings:') : null),
                },
              ])
              actions.disabledCheckInClickAction(e.message || t`unknown`)
            }
          }}
        >
          {`${t`checkIn`} ${t`locationOn`}`}
        </Button>
      )
    }

    return (
      <>
        <StatusBar barStyle="dark-content" />
        <ImageHeaderScrollView
          backTo={`/deeds/${deed.id}`}
          close
          color="#4168FA"
          minOverlayOpacity={1}
          maxOverlayOpacity={1}
          maxHeight={userCheckedIn ? (Platform.OS === 'web' ? 275 : 285) : 340}
          minHeight={215}
          title={deed.name}
          subTitle={`${t('date:weekdayDayMonthTimeShort', {
            date: deed.virtual
              ? deed.startingAt || deed.createdAt
              : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
          })} – ${getEventEndDateTime(deed)}`}
          subTitle2={deed.location}
          renderForeground={() => (
            <View style={styles.titleContainer}>
              <Text size={24} color={colors.white} medium style={{ minHeight: 30, marginBottom: 25 }} numberOfLines={2}>
                {deed.name}
              </Text>
              <Text size={15} color={colors.white} center style={{ height: 20 }}>
                {t('date:weekdayDayMonthShort', {
                  date: deed.virtual
                    ? deed.startingAt || deed.createdAt
                    : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
                })}
              </Text>
              <Text size={15} color={colors.white} center style={{ height: 20 }}>
                {getEventStartDateTime(deed)} – {getEventEndDateTime(deed)}
              </Text>
            </View>
          )}
          renderTouchableFixedForeground={() => (
            <View style={styles.touchable}>
              <Text size={15} medium color={colors.white} center>
                {t`pointOfContact`}
              </Text>
              {deed.organizerName ? (
                <Text size={15} color={colors.white} center>
                  {deed.organizerName}
                </Text>
              ) : null}
              {deed.organizerPhone ? (
                <ExternalLink
                  href={`tel:${deed.organizerPhone}`}
                  size={15}
                  color={colors.white}
                  center
                  style={{ marginTop: 5 }}
                >
                  {deed.organizerPhone.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g, '($1) $2-$3')}
                </ExternalLink>
              ) : (
                <View marginTop={25} />
              )}
              <View style={styles.cancel}>
                <Text size={12} lineHeight={16} color={colors.white}>
                  *{t`pleaseClick`}
                  <DeedCancel
                    deed={deed}
                    textStyle={{
                      fontSize: 12,
                      fontWeight: 700,
                    }}
                  >
                    {' '}
                    {t`here`}{' '}
                  </DeedCancel>
                  {t`ifYouCanNoLongerAttend`}
                </Text>
              </View>
            </View>
          )}
        >
          <Gradient height={20} startColor="#000" startOpacity={0.1} endColor="#fff" endOpacity={0} vertical />
          <View style={styles.addressContainer}>
            <View>
              <Icon icon="markerDarkBlue" style={{ width: 20, height: 24 }} />
              {distanceInFeet ? (
                <Text size={12} center style={{ paddingTop: 5, marginLeft: -14, width: 50 }}>
                  {distanceInFeet > 2000 ? '2000+' : distanceInFeet.toFixed(0)} ft
                </Text>
              ) : null}
            </View>
            <View style={styles.address}>
              <MapLink
                latitude={deed.locationLatLng.coordinates[1]}
                longitude={deed.locationLatLng.coordinates[0]}
                address={deed.location}
              >
                <View>
                  <Text size={15} lineHeight={20} numberOfLines={1}>
                    {deed.locationObject.address}
                  </Text>
                  <Text size={15} lineHeight={20} numberOfLines={1}>
                    {deed.locationObject.city},{' '}
                    {deed.locationObject.state && deed.locationObject.postalCode
                      ? `${deed.locationObject.state} ${deed.locationObject.postalCode}`
                      : deed.locationObject.state}
                  </Text>
                </View>
              </MapLink>
            </View>
          </View>
          <OnLayout>
            {({ width }) => (
              <Map
                width={width > 800 ? 800 : width}
                height={Math.floor(width * (width > 640 ? 0.4 : 0.8))}
                latitude={deed.locationLatLng.coordinates[1]}
                longitude={deed.locationLatLng.coordinates[0]}
                circle={1000}
                geolocation={geolocation}
                isWithinRange={this.state.isWithinRange}
                type="followUser"
                withinRange={this.withinRange}
              />
            )}
          </OnLayout>
          <View style={styles.attendeesContainer}>
            <View style={styles.attendeesLabel}>
              <Icon icon="profilePinkFilled" style={{ width: 12, height: 12 }} />
              <Text size={12} medium color={colors.darkGray} style={{ marginLeft: 10, marginTop: 2 }}>
                {t`whosGoing`}
              </Text>
            </View>
            {deed.attendees.length > 0 && (
              <ScrollView style={styles.attendees} horizontal>
                {deed.attendees.map((attendee) => (
                  <Avatar
                    key={attendee.id}
                    user={attendee}
                    style={{ marginRight: 10 }}
                    icon={deed.isUserCheckedIn(user) ? 'checkedInMini' : ''}
                  />
                ))}
              </ScrollView>
            )}
          </View>
          <View style={styles.content}>
            {deed.duties ? (
              <>
                <Text size={15} medium color={colors.darkGray} style={{ marginBottom: 10 }}>
                  {t`duties`}
                </Text>
                <Links>
                  <Text size={15} lineHeight={20} color={colors.darkGray}>
                    {deed.duties}
                  </Text>
                </Links>
              </>
            ) : null}
            <Spacing marginBottom={30} />
            {deed.mustKnows ? (
              <>
                <Text size={15} medium color={colors.darkGray} style={{ marginBottom: 10 }}>
                  {t`mustKnows`}
                </Text>
                <Links>
                  <Text size={15} lineHeight={20} color={colors.darkGray}>
                    {deed.mustKnows}
                  </Text>
                </Links>
                <Spacing marginBottom={30} />
              </>
            ) : null}
            {deed.organization && deed.organization.brief ? (
              <>
                <Text size={15} medium color={colors.darkGray} style={{ marginBottom: 10 }}>
                  {t`organization`}
                </Text>
                <Links>
                  <Text size={15} lineHeight={20} color={colors.darkGray}>
                    {deed.organization && deed.organization.brief}
                  </Text>
                </Links>
                <Spacing marginBottom={10} />
              </>
            ) : null}
          </View>
        </ImageHeaderScrollView>
        <Action>{checkInButton}</Action>
      </>
    )
  }
}
DeedCheckIn.propTypes = {
  loading: PropTypes.bool,
  deed: PropTypes.object,
  user: PropTypes.object,
  geolocation: PropTypes.object,
  actions: PropTypes.object,
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 0,
    paddingBottom: 20,
    position: 'relative',
    height: 'auto',
    marginTop: 20,
  },
  addressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 18,
    position: 'relative',
  },
  address: {
    marginLeft: 20,
    marginRight: 25,
  },
  attendeesContainer: {
    backgroundColor: '#F6F4F4',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 10,
  },
  attendeesLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  attendees: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: 15,
    paddingBottom: 10,
    paddingLeft: 25,
    marginLeft: -25,
    marginRight: -25,
  },
  touchable: {
    alignItems: 'center',
  },
  cancel: {
    flexDirection: 'row',
    marginTop: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkedIn: {
    position: 'fixed',
    width: '100%',
    height: 75,
    bottom: metrics.screenWidth > 640 ? 40 : 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.white,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    shadowColor: colors.black,
    elevation: 15,
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 10px',
  },
  content: {
    alignSelf: 'stretch',
    padding: 25,
    paddingBottom: 90,
    alignItems: 'flex-start',
  },
})

const withConnect = connect(
  (state, props) => ({
    deed: selectDeedById(state, props.match.params.deed),
    user: selectCurrentUser(state),
    geolocation: selectGeolocation(state),
    loading: selectLoading(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedCheckIn', reducer })
const withEpics = injectEpics({ key: 'deedCheckIn', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('deedScreen'))(DeedCheckIn)
