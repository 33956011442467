import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectSelectedLocation } from 'src/containers/screens/Register/Location/selectors'
import { selectDeedId } from 'src/containers/screens/Deed/Authenticate/selectors'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { submitSuccessAction, submitFailedAction } from './actions'
import { SUBMIT } from './constants'

const submit = (action$, store) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ email }) => {
      const data = { email }
      // Attach selected location in case user signed up with Facebook
      const state = store.getState()
      const location = selectSelectedLocation(state)
      if (location) {
        data.location = location
      }
      const authenticateDeedId = selectDeedId(state)
      return UserApi.update(data)
        .mergeMap((action) => [
          action,
          action.user.location
            ? replace(authenticateDeedId ? `/deeds/${authenticateDeedId}/first` : '/register/interests')
            : replace('/register/location'),
          submitSuccessAction(),
        ])
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    })
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [submit]
