import { Observable } from 'rxjs'
import _ from 'lodash'
// eslint-disable-next-line import/named
import { Store, Action } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import { DeedType } from 'src/generated/graphql'
import { selectNonprofitsLoaded, selectNonprofitsForCauseLoaded } from 'src/entities/organization/selectors'
import OrganizationApi from 'src/entities/organization/api'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectForCauseLoaded } from 'src/entities/deed/selectors'
import DeedsApi from 'src/entities/deed/api'
import { selectCauses, selectCauseById } from 'src/entities/cause/selectors'
import CausesApi from 'src/entities/cause/api'
import { selectSkills } from 'src/entities/skill/selectors'
import SkillsApi from 'src/entities/skill/api'
import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { renderErrorMessage } from 'src/utils/errorMessages'

import {
  FollowAction,
  initSuccessAction,
  initFailedAction,
  fetchCauseNonprofitsSuccessAction,
  fetchCauseNonprofitsFailedAction,
  followSuccessAction,
  followFailedAction,
  fetchCauseDeedsByDeedTypeSuccessAction,
  fetchCauseDeedsByDeedTypeFailedAction,
  FetchCauseNonprofitsAction,
} from './actions'
import { INIT, FETCH_CAUSE_NONPROFITS, FOLLOW, FETCH_CAUSE_DEEDS_BY_DEED_TYPE } from './constants'

const causeFilter = {
  Pillar: 'pillars',
  Category: 'categories',
  ERG: 'ergs',
  SDG: 'sdgs',
}

const init = (action$: ActionsObservable<Action>, store: Store) =>
  action$.ofType(INIT).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)
    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const fetchCauseNonprofits = (action$: ActionsObservable<FetchCauseNonprofitsAction>, store: Store) =>
  action$.ofType(FETCH_CAUSE_NONPROFITS).mergeMap(({ id, limit }) => {
    const actions = []
    const state = store.getState()

    const cause = selectCauseById(state, id)

    if (!selectNonprofitsForCauseLoaded(state, id) && !selectNonprofitsLoaded(state)) {
      actions.push(OrganizationApi.fetchNonprofits({ [causeFilter[cause.type]]: [id], limit }))
    }

    if (actions.length === 0) {
      return Observable.of(fetchCauseNonprofitsSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), fetchCauseNonprofitsSuccessAction()])
      .catch((e) => Observable.of(fetchCauseNonprofitsFailedAction(e)))
  })

const fetchCauseDeedsByDeedType = (
  action$: ActionsObservable<{ id: string; deedType: DeedType; type: typeof FETCH_CAUSE_DEEDS_BY_DEED_TYPE }>,
  store: Store
) =>
  action$.ofType(FETCH_CAUSE_DEEDS_BY_DEED_TYPE).mergeMap(({ id, deedType }) => {
    const actions = []
    const state = store.getState()

    const cause = selectCauseById(state, id)

    if (!selectForCauseLoaded(state, id) && cause) {
      actions.push(DeedsApi.fetchFeed({ [causeFilter[cause.type]]: [id], type: deedType, limit: 10 }))
    }

    if (actions.length === 0) {
      return Observable.of(fetchCauseDeedsByDeedTypeSuccessAction(deedType))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        fetchCauseDeedsByDeedTypeSuccessAction(deedType),
      ])
      .catch((e) => Observable.of(fetchCauseDeedsByDeedTypeFailedAction(e, deedType)))
  })

const follow = (action$: ActionsObservable<FollowAction>, store: Store) =>
  action$
    .ofType(FOLLOW)
    .exhaustMap(({ id }) => {
      const state = store.getState()
      const currentUser = selectCurrentUser(state)
      const userInterests = currentUser?.interests || []

      let interestsUpdated
      if (userInterests.includes(id)) {
        interestsUpdated = userInterests.filter((interest: string) => interest !== id)
      } else {
        interestsUpdated = [...userInterests, id]
      }

      return UserApi.update({ interests: interestsUpdated })
        .mergeMap((action) => [action, followSuccessAction()])
        .catch((e) => {
          if (e.responseJson) {
            return [
              followFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
        })
    })
    .catch((e) => Observable.of(followFailedAction(e)))

export default [init, fetchCauseNonprofits, follow, fetchCauseDeedsByDeedType]
