export const VOLUNTEER_TIME_OFF_STATUS = {
  REQUESTED: {
    name: 'REQUESTED',
    icon: 'timeCirclePending',
    chipColor: 'statusNeutral',
    textColor: 'statusTextNeutral',
  },
  APPROVED: {
    name: 'APPROVED',
    icon: 'tickSquareSuccess',
    chipColor: 'statusSuccess',
    textColor: 'statusTextSuccess',
  },
}
