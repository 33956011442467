import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Row } from 'src/retired/elements'
import { Link } from 'src/navigation'
import { getHours } from 'src/utils/convertDuration'
import User from 'src/entities/user/model'
import Icon from 'src/retired/shared/Icon'
import { Body1, Body2, StyledLink } from 'src/retired/shared/Typography'
import { Tooltip } from 'src/components/Tooltip/Tooltip'

import { SectionTitle } from '../Profile.styled'

import { VolunteerTabs } from './VolunteerTabs/VolunteerTabs'

export interface Props {
  user: User
}

export const VolunteerHours = ({ user }: Props): JSX.Element => {
  const { colors, metrics, icons } = useDeedTheme()
  const { t } = useTranslation('profileScreen')
  const balance = user?.volunteerTimeOffSummary?.balance
  const total = user?.volunteerTimeOffSummary?.total
  const isVTOSummaryDefined = Number.isFinite(balance) && Number.isFinite(total)
  const isVTOFeatureEnabled = Boolean(user.hasFeature('volunteerTimeOff'))
  const shouldDisplayVTOSummary = isVTOFeatureEnabled && isVTOSummaryDefined
  const isSmallOrMedium = metrics.isSmall || metrics.isMedium

  return (
    <View>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: isSmallOrMedium ? 'flex-start' : 'center',
          paddingRight: 10,
        }}
      >
        <SectionTitle
          style={{
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: isSmallOrMedium ? 'column' : 'row',
                alignItems: isSmallOrMedium ? 'flex-start' : 'center',
                paddingTop: isSmallOrMedium ? 14 : 5,
              }}
            >
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Body1 style={{ paddingRight: 5 }}>
                  {`${getHours(user.volunteerMinutes)} ${t`hours`} ${t`volunteered`}`}
                </Body1>
                <Tooltip
                  hoverIcon={icons.infoCircleOutlinedBlack}
                  title={t`loggedVolunteerHoursIsTotal`}
                  placement={isSmallOrMedium ? 'bottom' : 'right'}
                />
              </View>

              {shouldDisplayVTOSummary && (
                <View style={{ display: 'flex', flexDirection: 'row', paddingTop: isSmallOrMedium ? 14 : 0 }}>
                  {!isSmallOrMedium && (
                    <Body1 style={{ paddingLeft: 10, paddingRight: 10, color: colors.lightGray }}>•</Body1>
                  )}
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Body1 style={{ paddingRight: 5 }}>
                      {`${Math.round(Number(balance) / 60)} / ${Math.round(
                        Number(total) / 60
                      )} ${t`hours`} ${t`vtoAvailable`}`}
                    </Body1>
                    <Tooltip
                      hoverIcon={icons.infoCircleOutlinedBlack}
                      title={t`vtoIsABenefit`}
                      placement={isSmallOrMedium ? 'bottom' : 'right'}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: isSmallOrMedium ? 'column' : 'row',
              alignItems: isSmallOrMedium ? 'flex-end' : 'center',
            }}
          >
            <Link to="/profile/volunteer-hours/select-deed" style={{ display: 'flex', alignItems: 'center' }}>
              <StyledLink>
                <Body2 weight="500" colour={colors.white} marginRight={10}>
                  {t`logHours`}
                </Body2>
                <Icon icon="timeCirclePendingWhite" width={16} color={colors.black} />
              </StyledLink>
            </Link>

            {shouldDisplayVTOSummary && (
              <Link
                to="/profile/volunteer-time-off-hours"
                style={{ display: 'flex', alignItems: 'center', paddingTop: isSmallOrMedium ? 10 : 0 }}
              >
                <StyledLink
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <Body2 weight="500" colour={colors.white}>
                    {t`timeOff`}
                  </Body2>
                </StyledLink>
              </Link>
            )}
          </View>
        </SectionTitle>
      </Row>
      <VolunteerTabs />
    </View>
  )
}
export default VolunteerHours
