import { fromJS } from 'immutable'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  RELATION_REQUEST,
  RELATION_REQUEST_SUCCESS,
  RELATION_REQUEST_FAILED,
  RELATION_ACCEPT,
  RELATION_ACCEPT_SUCCESS,
  RELATION_ACCEPT_FAILED,
  RELATION_REMOVE,
  RELATION_REMOVE_SUCCESS,
  RELATION_REMOVE_FAILED,
} from './constants'

export const initialState = fromJS({
  loading: false,
  error: null,
  relationRequestInProgress: false,
  relationAcceptInProgress: false,
  relationRemovalInProgress: false,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case RELATION_REQUEST:
      return state.set('relationRequestInProgress', true)

    case RELATION_REQUEST_SUCCESS:
      return state.set('relationRequestInProgress', false)

    case RELATION_REQUEST_FAILED:
      return state.merge({
        relationRequestInProgress: false,
        error: action.error,
      })

    case RELATION_ACCEPT:
      return state.set('relationAcceptInProgress', true)

    case RELATION_ACCEPT_SUCCESS:
      return state.set('relationAcceptInProgress', false)

    case RELATION_ACCEPT_FAILED:
      return state.merge({
        relationAcceptInProgress: false,
        error: action.error,
      })

    case RELATION_REMOVE:
      return state.set('relationRemovalInProgress', true)

    case RELATION_REMOVE_SUCCESS:
      return state.set('relationRemovalInProgress', false)

    case RELATION_REMOVE_FAILED:
      return state.merge({
        relationRemovalInProgress: false,
        error: action.error,
      })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
