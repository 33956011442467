import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { Redirect } from 'src/navigation'
import { storeAction } from 'src/entities/auth/actions'

const TokenLoginRedirect = ({ location: { hash, search }, actions }) => {
  actions.storeAction(hash.substr(1))
  return <Redirect to={search.indexOf('?d=') === 0 ? search.substr(3) : '/home'} />
}
TokenLoginRedirect.propTypes = {
  location: PropTypes.object,
  actions: PropTypes.object,
}
const withConnect = connect(null, (dispatch) => ({
  actions: bindActionCreators({ storeAction }, dispatch),
}))

export default compose(withConnect)(TokenLoginRedirect)
