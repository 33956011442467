import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Divider, LinkButton, Screen, Spacing } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Body1 } from 'src/retired/shared/Typography'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { PageTitle } from 'src/components'

export const Register = () => {
  const { t } = useTranslation('registerScreen')
  const expediteOnboarding = useSelector((state) => selectLocalSetting(state, 'expediteOnboarding'))

  return (
    <Screen>
      <PageTitle title={t`register`} />
      <NavigationHeader showLanguageSwitcher />
      <Screen center padding narrow>
        <Body1>{t`registerAsAnIndividualOrPartOfACompany`}</Body1>
        <Spacing marginTop={50} marginBottom={25}>
          {/* Skip location and skills steps during expedite onboarding */}
          <LinkButton to={expediteOnboarding ? '/register/individual' : '/register/location/new'} color="primary">
            {t`individualRegistration`}
          </LinkButton>
        </Spacing>
        <Divider>{t`common:or`}</Divider>
        <Spacing marginTop={25} marginBottom={25}>
          <LinkButton to="/register/company">{t`registerWithCompany`}</LinkButton>
        </Spacing>
      </Screen>
    </Screen>
  )
}

export default Register
