import { createSelector } from 'reselect'

import { selectCurrentUser } from 'src/entities/user/selectors'

import { initialState } from './reducer'

const selectState = (state) => state.get('nonprofitsFilter') || initialState

export const selectInitialized = createSelector(selectState, (state) => state.get('initialized'))

export const selectSelectedCauses = createSelector(selectState, (state) => state.get('selectedCauses'))

export const selectSearchTerm = createSelector(selectState, (state) => state.get('searchTerm'))

export const selectSearching = createSelector(selectState, (state) => state && state.get('searching'))

export const selectSearchFailed = createSelector(selectState, (state) => state && state.get('searchFailed'))

export const selectCorporateOnly = createSelector(selectState, (state) => state.get('corporateOnly'))

export const selectIsNonprofitsFilterActive = createSelector(
  selectSearchTerm,
  selectSelectedCauses,
  selectCorporateOnly,
  selectCurrentUser,
  (searchTerm, causes, corporateOnly, user) => (user?.isEmployee() && corporateOnly) || searchTerm || causes.size > 0
)
