import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { colors } from 'src/theme'
import { injectReducer, injectEpics, Platform } from 'src/utils'
import { ActivityIndicator, Screen, Text } from 'src/retired/elements'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import ImageCropper from 'src/retired/blocks/ImageCropper'

import { selectUploading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

class ProfileImage extends PureComponent {
  state = {
    initialized: false,
  }

  componentDidMount() {
    this.setState({ initialized: true })
  }

  componentWillUnmount() {
    this.setState({ initialized: false })
  }

  render() {
    const { t, uploading, actions } = this.props
    if (uploading) {
      return (
        <Screen center middle padding>
          <ActivityIndicator size="large" color={colors.pink} />
          <Text style={{ marginTop: 10 }}>{t`uploading`}</Text>
        </Screen>
      )
    }
    if (Platform.OS !== 'web' && this.state.initialized) {
      return null
    }
    return (
      <Screen center>
        <ImageCropper
          onCrop={actions.uploadAction}
          onCancel={actions.uploadCancelledAction}
          onError={actions.showErrorAction}
          uploading={uploading}
          hideUploadUI
        />
      </Screen>
    )
  }
}
const withConnect = connect(
  (state) => ({
    uploading: selectUploading(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'profileImage', reducer })
const withEpics = injectEpics({ key: 'profileImage', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('profileImageProfile'))(ProfileImage)
