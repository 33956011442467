import { useEffect, useState } from 'react'
import type { PaymentMethodType } from '@joindeed/calculate-fees'

import type Deed from 'src/entities/deed/model'
import type Organization from 'src/entities/organization/model'
import { type MatchableRule } from 'src/entities/donation/matchableRule'
import type User from 'src/entities/user/model'
import { type CapBalanceInfo } from 'src/entities/user/model'
import { convert, type Currency, CurrencyCode } from 'src/containers/modules/CurrencyFormat'

import { MatchEstimation } from '../LogOffPlatformDonation/actions'

type UseMatchDataParams = {
  entity: Deed | Organization | MatchEstimation | undefined
  netAmount: number
  user: User | undefined
  donationPaymentMethod: PaymentMethodType | undefined
  baseCurrency: CurrencyCode
  currency: Currency
  currencyRate: number
}

export type MatchData = {
  capBalance?: CapBalanceInfo
  matchedAmount: number
  matchedAmountMaximum: number
  matchingApplicableForPaymentMethod: boolean
  matchingMaximum: number
  matchingPercentage?: number
  partialMatchingEnabled: boolean
  desiredMatchAmount: number | undefined
  setDesiredMatchAmount: (amount: number | undefined) => void
  desiredMatchingRule: string
  setDesiredMatchingRule: (selectedRuleId: string) => void
  matchableRules: Record<string, MatchableRule> | undefined
}

export const useMatchData = ({
  entity,
  netAmount,
  user,
  baseCurrency,
  donationPaymentMethod,
  currency,
  currencyRate,
}: UseMatchDataParams): MatchData | undefined => {
  const { matchableRule, matchableRules, matchingMaximumCurrencies } = entity || {}
  const [desiredMatchingPercentage, setDesiredMatchingPercentage] = useState<number | undefined>(undefined)
  const [desiredMatchingRule, setDesiredMatchingRule] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (matchableRule) {
      setDesiredMatchingRule(matchableRule.id)
    }
  }, [matchableRule])

  if (!entity) {
    return undefined
  }

  const matchingRule = matchableRules && desiredMatchingRule ? matchableRules[desiredMatchingRule] : matchableRule

  if (!matchingRule) {
    return undefined
  }

  const {
    matchingPercentage,
    paymentMethods,
    paymentMethodsExclude,
    bypassBudgetLimitPerUser,
    budget: matchingBudgetId,
  } = matchingRule

  // if the matchingRule doesn't provide matchingMaximumCurrencies then we can get it from the entity directly. matchingMaximumCurrencies value is picking the lowest of general limitPerUser and ruleLimitPerUser.
  const resolvedMatchingMaximumCurrencies = matchingRule.matchingMaximumCurrencies || matchingMaximumCurrencies

  const unrestrictedMatchedAmount = (matchingPercentage && netAmount && (matchingPercentage * netAmount) / 100) || 0

  const matchingMaximum = resolvedMatchingMaximumCurrencies?.[baseCurrency] ?? Infinity
  const matchingApplicableForPaymentMethod =
    paymentMethods?.length > 0 && donationPaymentMethod
      ? paymentMethodsExclude
        ? !paymentMethods.includes(donationPaymentMethod)
        : paymentMethods.includes(donationPaymentMethod)
      : true
  const matchingForPaymentMethod = matchingApplicableForPaymentMethod ? Infinity : 0

  // @NOTE-CH: Disregard the Budget's capBalance when the Rule bypassBudgetLimitPerUser is enable
  const capBalance =
    matchingBudgetId && !bypassBudgetLimitPerUser
      ? user?._capBalanceByBudget.find((b: any) => b.id === matchingBudgetId)
      : undefined
  const capBalanceToUserCurrencyConversionRate = (capBalance && currency?.rates?.[capBalance?.currencyCode]) || 1 // @NOTE-AT: Defines rates to convert capBalance.currencyCode into display currency

  // @NOTE-AT: We need to convert from capBalance into user's display currency and finally convert this amount into baseCurrency
  const capBalanceAmountInUserCurrency = convert(capBalance?.balance || 0, capBalanceToUserCurrencyConversionRate)
  const capBalanceAmountConverted = convert(capBalanceAmountInUserCurrency, 1 / currencyRate)
  const capBalanceAmount = capBalance?.contributionBased
    ? capBalanceAmountConverted * (matchingPercentage / 100)
    : capBalanceAmountConverted

  const matchedAmountMaximum = Math.min(
    matchingForPaymentMethod,
    capBalance?.balance || capBalance?.balance === 0
      ? Math.min(unrestrictedMatchedAmount, capBalanceAmount, matchingMaximum)
      : Math.min(unrestrictedMatchedAmount, matchingMaximum)
  )

  const partialMatchingEnabled = user?.hasFeature?.('partialMatching') ?? false

  const desiredMatchAmount =
    desiredMatchingPercentage === undefined
      ? undefined
      : Math.min((desiredMatchingPercentage * netAmount) / 100, matchedAmountMaximum)

  const setDesiredMatchAmount = (amount?: number) => {
    setDesiredMatchingPercentage(amount === undefined ? undefined : (amount / netAmount) * 100)
  }

  return {
    matchedAmount: desiredMatchAmount ?? matchedAmountMaximum,
    matchedAmountMaximum,
    matchingPercentage,
    matchingMaximum,
    matchingApplicableForPaymentMethod,
    capBalance,
    partialMatchingEnabled,
    desiredMatchAmount,
    setDesiredMatchAmount,
    desiredMatchingRule,
    setDesiredMatchingRule,
    matchableRules,
  } as MatchData
}
