import { fromJS } from 'immutable'

import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export const initialState = fromJS({ loading: false, error: null, loadedOnce: false })

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.merge({ loading: false, loadedOnce: true })

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
