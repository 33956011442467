import type { DonationProvider } from '@joindeed/calculate-fees'

import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import { type MatchableRule } from 'src/entities/donation/matchableRule'

import {
  GET_NONPROFIT,
  SUBMIT_EXTERNAL_DONATION,
  SUBMIT_SUCCESS,
  SUBMIT_FAILED,
  ESTIMATE_MATCH,
  ESTIMATE_MATCH_SUCCESS,
  ESTIMATE_MATCH_FAILED,
  FETCH_CAMPAIGNS_BY_NONPROFIT,
  FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS,
  FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED,
  ESTIMATE_MATCH_RULE_CHANGE,
} from './constants'

export interface GetNonprofit {
  type: typeof GET_NONPROFIT
  nonprofitId: string
}
export const getNonprofitAction = (nonprofitId: string): GetNonprofit => ({
  type: GET_NONPROFIT,
  nonprofitId,
})

export type ExternalDonationPayload = {
  nonprofit: string
  date: Date
  donationAmount: string
  donationAmountCurrency: CurrencyCode
  receipt: string
  designation?: string
  campaignId?: string | null
  dedication?: string
  confirmation: boolean
  desiredMatchAmount: number | undefined
  desiredMatchingRule?: string
}

export interface SubmitExternalDonation {
  type: typeof SUBMIT_EXTERNAL_DONATION
  data: ExternalDonationPayload
  nonprofit: string
  donationProvider: DonationProvider
}
export const submitExternalDonationAction = (
  data: ExternalDonationPayload,
  nonprofit: string,
  donationProvider: DonationProvider
): SubmitExternalDonation => ({
  type: SUBMIT_EXTERNAL_DONATION,
  data,
  nonprofit,
  donationProvider,
})

export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})

export const submitFailedAction = (error: Error) => ({
  type: SUBMIT_FAILED,
  error,
})

export interface MatchEstimation {
  matchableRules?: Record<string, MatchableRule>
  matchableRule?: MatchableRule
  matchingMaximumCurrencies?: Record<CurrencyCode, number>
  matchingPercentage?: number
  matchingBudgetId?: string
  matchableAmount?: number
  matchableAmountCurrencyCode?: string
}

export interface EstimateMatchPayload {
  nonprofitId: string
  amount: number
  currencyCode: CurrencyCode
  donationDate: Date
}
export const estimateMatchAction = (data: EstimateMatchPayload) => ({
  ...data,
  type: ESTIMATE_MATCH,
})

export const estimateMatchSuccessAction = (estimation: MatchEstimation | null) => ({
  type: ESTIMATE_MATCH_SUCCESS,
  estimation,
})

export const estimateMatchRuleChangeAction = (estimation: MatchEstimation | null) => ({
  type: ESTIMATE_MATCH_RULE_CHANGE,
  estimation,
})

export const estimateMatchFailedAction = (error: object) => ({
  type: ESTIMATE_MATCH_FAILED,
  error,
})

export const fetchCampaignsByNonprofitAction = (nonprofitId: string) => ({
  type: FETCH_CAMPAIGNS_BY_NONPROFIT,
  nonprofitId,
})

export interface CampaignsByNonprofitPayload {
  id: string
  title: string
}

export const fetchCampaignsByNonprofitSuccessAction = (campaignsLimited: CampaignsByNonprofitPayload[]) => ({
  type: FETCH_CAMPAIGNS_BY_NONPROFIT_SUCCESS,
  campaignsLimited,
})
export const fetchCampaignsByNonprofitFailedAction = (error: Error) => ({
  type: FETCH_CAMPAIGNS_BY_NONPROFIT_FAILED,
  error,
})

export type Actions =
  | ReturnType<typeof getNonprofitAction>
  | ReturnType<typeof submitExternalDonationAction>
  | ReturnType<typeof submitSuccessAction>
  | ReturnType<typeof submitFailedAction>
  | ReturnType<typeof estimateMatchAction>
  | ReturnType<typeof estimateMatchRuleChangeAction>
  | ReturnType<typeof estimateMatchSuccessAction>
  | ReturnType<typeof estimateMatchFailedAction>
  | ReturnType<typeof fetchCampaignsByNonprofitAction>
  | ReturnType<typeof fetchCampaignsByNonprofitSuccessAction>
  | ReturnType<typeof fetchCampaignsByNonprofitFailedAction>
