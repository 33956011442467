import React from 'react'
import PropTypes from 'prop-types'
import hexRgb from 'hex-rgb'
import { View } from 'react-primitives'

import { colors } from 'src/theme'

const Gradient = ({
  height = 1,
  startColor = colors.gray02,
  startOpacity = 1,
  middleColor = colors.gray02,
  middleOpacity = 1,
  endColor = colors.gray02,
  endOpacity = 1,
  vertical = false,
  middle = false,
  style,
  ...props
}) => {
  const gradient = (RGB, opacity, percentage) =>
    `rgba(${RGB.red}, ${RGB.green}, ${RGB.blue}, ${opacity}) ${percentage}%`
  const gradients = []
  gradients.push(gradient(hexRgb(startColor), startOpacity, 0))
  if (middle) {
    gradients.push(gradient(hexRgb(middleColor), middleOpacity, 50))
  }
  gradients.push(gradient(hexRgb(endColor), endOpacity, 100))
  return (
    <View
      style={[
        {
          alignSelf: 'stretch',
          height,
          backgroundImage: `linear-gradient(${vertical ? '180deg' : '90deg'}, ${gradients.join(',')})`,
        },
        style,
      ]}
      {...props}
    />
  )
}
Gradient.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  startColor: PropTypes.string,
  startOpacity: PropTypes.number,
  middleColor: PropTypes.string,
  middleOpacity: PropTypes.number,
  endColor: PropTypes.string,
  endOpacity: PropTypes.number,
  vertical: PropTypes.bool,
  middle: PropTypes.bool,
  style: PropTypes.any,
}

export default Gradient
