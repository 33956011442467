import { createSelector } from 'reselect'

import { selectCurrentUser } from 'src/entities/user/selectors'

import { initialState } from './reducer'

const selectState = (state) => state.get('feedFilter') || initialState

export const selectInitialized = createSelector(selectState, (state) => state.get('initialized'))

export const selectSelectedDaysAndTimes = createSelector(selectState, (state) => state.get('selectedDaysAndTimes'))

export const selectSelectedDate = createSelector(selectState, (state) => state.get('selectedDate'))

export const selectSelectedLocation = createSelector(selectState, (state) => state.get('selectedLocation'))

export const selectSelectedLocationCode = createSelector(selectState, (state) => state.get('selectedLocationCode'))

export const selectSelectedCauses = createSelector(selectState, (state) => state.get('selectedCauses'))

export const selectSelectedSkills = createSelector(selectState, (state) => state.get('selectedSkills'))

export const selectCorporateOnly = createSelector(selectState, (state) => state.get('corporateOnly'))

export const selectIsFeedFilterActive = createSelector(
  selectSelectedDaysAndTimes,
  selectSelectedDate,
  selectSelectedLocation,
  selectSelectedCauses,
  selectSelectedSkills,
  selectCorporateOnly,
  selectCurrentUser,
  (daysAndTimes, date, location, causes, skills, corporateOnly, user) =>
    (user?.isEmployee() && corporateOnly) || date || daysAndTimes.concat(location, causes, skills).size > 0
)
