import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ExternalLink } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const DonationReceiptEmpty = () => {
  const { t } = useTranslation('donationReceipt')
  const { colors } = useDeedTheme()

  return (
    <>
      <Body1>{t`noDonationsMessage`}</Body1>
      <br />
      <Body1>
        <Trans
          t={t}
          i18nKey="contactourSupport"
          components={{
            supportTeamLink: (
              <ExternalLink target="_blank" href="mailto:support@joindeed.com" size={16} color={colors.brandColor} />
            ),
          }}
        />
      </Body1>
    </>
  )
}
