// HEADS UP!!!
// Code copied from marketplace until `api` reliably provides `donationProvidersAvailability`
// https://github.com/joindeed/marketplace/blob/5ee961a3a1591ee108f412860a7a1b351914b3c6/packages/mongoose-models/src/utils/donationProviders.ts

import isEmpty from 'lodash/isEmpty'
import { DonationProvider } from '@joindeed/calculate-fees'

import type Organization from 'src/entities/organization/model'

type Nonprofit = Pick<
  Organization,
  'ein' | 'externalId' | 'identifiers' | 'locationObject' | 'payPalModel' | 'stripe' | 'donationProviders'
>
type NonExternalProviders = Exclude<DonationProvider, DonationProvider.External>

export const nonprofitNfgIdentifier = (nonprofit: Pick<Organization, 'ein' | 'identifiers'>) => {
  if (nonprofit.ein) {
    return { organizationIdType: 'Ein', organizationId: nonprofit.ein }
  }
  const ncesIdentifier = nonprofit.identifiers?.find((identifier) => identifier.type === 'nces')
  if (ncesIdentifier) {
    return { organizationIdType: 'NcesSchoolId', organizationId: ncesIdentifier.id }
  }
  return undefined
}
export const isDonatableViaNfg = (nonprofit: Nonprofit) =>
  Boolean(nonprofit.locationObject?.countryCode === 'US' && nonprofitNfgIdentifier(nonprofit))

export const isDonatableViaPayPal = (nonprofit: Nonprofit) =>
  Boolean(nonprofit.payPalModel === 'COMMERCE' || nonprofit.externalId?.startsWith('ext-pp-'))

export const isDonatableViaCAF = (nonprofit: Pick<Nonprofit, 'donationProviders'>) =>
  Boolean(nonprofit.donationProviders?.CAF)

export const getDonationProvidersAvailability = (
  nonprofit: Nonprofit
): {
  [provider in NonExternalProviders]: boolean
} => ({
  [DonationProvider.Betterplace]: !!nonprofit.externalId?.startsWith('ext-bp-'),
  [DonationProvider.GiveIndia]: !!nonprofit.externalId?.startsWith('ext-gi-'),
  [DonationProvider.GlobalGiving]: !!nonprofit.externalId?.startsWith('ext-gg-'),
  [DonationProvider.NFG]: isDonatableViaNfg(nonprofit),
  [DonationProvider.PayPal]: isDonatableViaPayPal(nonprofit),
  [DonationProvider.Stripe]: !!nonprofit.stripe?.userId,
  [DonationProvider.CAF]: isDonatableViaCAF(nonprofit),
})

export const defaultProvidersPriority = [
  DonationProvider.PayPal,
  DonationProvider.GlobalGiving,
  DonationProvider.Betterplace,
  DonationProvider.GiveIndia,
  DonationProvider.NFG,
  DonationProvider.Stripe,
  DonationProvider.CAF,
] as const

export const computeDonationProviderForNonprofit = (
  nonprofit: Organization | undefined,
  preferStripe: boolean
): DonationProvider | null => {
  if (!nonprofit) {
    return null
  }
  const providersPriority = preferStripe
    ? ([DonationProvider.Stripe, ...defaultProvidersPriority] as const)
    : defaultProvidersPriority

  const canDonateWith =
    nonprofit.donationProvidersAvailability && !isEmpty(nonprofit.donationProvidersAvailability)
      ? nonprofit.donationProvidersAvailability
      : getDonationProvidersAvailability(nonprofit)

  return providersPriority.find((provider) => canDonateWith[provider]) || null
}
