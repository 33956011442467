import React from 'react'

/**
 * Utility function to bring Array.join to JSX elements.
 * `reactComponents.join(SeparatorComponent)` does not work.
 * This function is an alternative that makes it work:
 * `jsxJoin(reactComponents, (key) => <SeparatorComponent key={key} />)`
 *
 * Warning: reactComponents should be keyed (just like arrays of react elements should always be keyed)
 *
 * @param elements - elements to join
 * @param joiner - function returning a component that should be placed between each element
 */
export const jsxJoin = (
  elements: React.ReactNode[],
  joiner: (k: string | number) => React.ReactNode
): React.ReactNode => {
  const joinedElements: React.ReactNode[] = []
  // eslint-disable-next-line no-restricted-syntax
  for (const [index, element] of elements.entries()) {
    if (index !== 0) {
      joinedElements.push(joiner(index))
    }
    joinedElements.push(element)
  }
  return joinedElements
}
