import AdineueProCondBoldWoff2 from 'src/assets/fonts/adineuePROCond-Bold.woff2'
import AdineueProCondBoldWoff from 'src/assets/fonts/adineuePROCond-Bold.woff'
import BebasNeueRegularWoff2 from 'src/assets/fonts/BebasNeue-Regular.woff2'
import BebasNeueRegularWoff from 'src/assets/fonts/BebasNeue-Regular.woff'

const fontStyles = `
@font-face {
  font-family: 'adineuePROCond-Bold';
  font-weight: bold;
  font-style: normal;
  src: url(${AdineueProCondBoldWoff2}) format('woff2'),
    url(${AdineueProCondBoldWoff}) format('woff');
}

@font-face {
  font-family: 'BebasNeue-Regular';
  font-weight: bold;
  font-style: normal;
  src: url(${BebasNeueRegularWoff2}) format('woff2'),
    url(${BebasNeueRegularWoff}) format('woff');
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = fontStyles
} else {
  style.appendChild(document.createTextNode(fontStyles))
}

// Inject stylesheet
document.head.appendChild(style)
