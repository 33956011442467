import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { TextField } from 'src/retired/elements'
import Icon from 'src/retired/shared/Icon'

interface FreeTextSearchProps {
  inputValue: string
  onChange: (fieldName: string, value: string) => void
  onEnter: (event: KeyboardEvent) => void
  fieldName: string
  placeholder: string
  backgroundColor?: string
}

export const FreeTextSearch = ({
  inputValue,
  onChange,
  onEnter,
  fieldName,
  placeholder,
  backgroundColor,
}: FreeTextSearchProps) => {
  const { colors } = useDeedTheme()

  return (
    <View
      style={{
        borderRadius: 33,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.grayLighter,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 15,
        backgroundColor,
      }}
    >
      <View style={{ marginRight: 10 }}>
        <Icon icon="searchNew" width={16} />
      </View>
      <TextField
        placeholder={placeholder}
        name={fieldName}
        returnKeyType="done"
        value={inputValue}
        onChangeText={onChange}
        onKeyPress={onEnter}
        style={{ height: 36 }}
      />
    </View>
  )
}
