import { stringify } from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { EventContext } from '@joindeed/analytics-schema/dist/types/baseContext.schema'

import { ShareIconsRow } from 'src/components/ShareIconsRow'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useBadgesQuery } from 'src/generated/graphql'
import { useHistory, useParams } from 'src/navigation'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { ActivityIndicator, Alert, Screen, ScrollView } from 'src/retired/elements'
import { OutlineButton } from 'src/retired/elements/Button/OutlineButton'
import Icon from 'src/retired/shared/Icon'
import { Body1, Text } from 'src/retired/shared/Typography'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'
import { Communications, Platform } from 'src/utils'
import { useWebUrl } from 'src/utils/webUrl'
import metricsClient from 'src/metrics/client'
import { getAnalyticsContext } from 'src/utils/analyticsContext'

import { BadgeWithTitle } from '../BadgeWithTitle'

const BADGE_SHARE = 'badge-share'
const buildURLParams = (params: any) => `?${stringify(params, { skipEmptyString: true, skipNull: true })}`

const extractMonthAndYear = (dateString: string): [string, string] => {
  const date = new Date(dateString)

  const month = (date.getMonth() + 1).toString() // Month is 0-indexed, so add 1.
  const year = date.getFullYear().toString()

  return [month, year]
}

const formatBadgeNameForLinkSharing = (input: string): string => {
  if (!input) {
    return ''
  }

  return input.replace(/!/g, '')
}

const NavbarContainer = styled.View<object, EmotionTheme>`
  display: flex;
  align-items: center;
  position: absolute;
  padding-right: 20px;
  padding-top: ${Platform.OS === 'web' ? '15px' : '40px'};
  right: 0;
  top: 0;
  z-index: 100;
`
const GreyBorderContainer = styled.View<object, EmotionTheme>`
  margin-top: 20px;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-style: solid;
  border-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.lightBorderGray};
  border-top-width: 1px;
`

interface AddCertificateToLinkedInButtonProps {
  source: string
  userId?: string
  linkedInCertificateURL: string
}
const AddCertificateToLinkedInButton: React.FC<AddCertificateToLinkedInButtonProps> = ({
  source,
  userId,
  linkedInCertificateURL,
}) => {
  const { t } = useTranslation('badges')
  return (
    <OutlineButton
      small
      onPress={() => {
        const eventName = `${source}:intent:user-clicked-add-certificate-to-linkedin`
        const event = {
          version: 1,
          event: eventName,
          userId: userId || null,
          context: getAnalyticsContext({ userId }) as EventContext,
        }

        metricsClient.track(eventName, event)
        Communications.web(linkedInCertificateURL)
      }}
    >
      <Text style={{ fontSize: 14 }}>{t('addCertificateToLinkedIn')}</Text>
    </OutlineButton>
  )
}

interface SocialSharingProps {
  certificateURL: string
  sharingMessage: string
  userId?: string
}

const SocialSharing: React.FC<SocialSharingProps> = ({ userId, certificateURL, sharingMessage }) => (
  <GreyBorderContainer>
    <ShareIconsRow
      source={BADGE_SHARE}
      userId={userId}
      shareLink={certificateURL}
      sharingMessage={sharingMessage}
      centered
      translationKey="customMessage"
    />
  </GreyBorderContainer>
)

interface BadgeInfoProps {
  badge: { id: string; description: string }
  socialSharingEnabled: boolean
  linkedInCertificateURL: string
  certificateURL: string
  sharingMessage: string
  textColour: string
  userId?: string
}

const BadgeInfo: React.FC<BadgeInfoProps> = ({
  badge,
  socialSharingEnabled,
  linkedInCertificateURL,
  certificateURL,
  sharingMessage,
  textColour,
  userId,
}) => (
  <>
    <GreyBorderContainer style={{ marginTop: 0 }}>
      <Text style={{ fontSize: 14, color: `${textColour}`, textAlign: 'center', paddingBottom: 40 }}>
        {badge.description}
      </Text>
      {socialSharingEnabled && (
        <AddCertificateToLinkedInButton
          source={BADGE_SHARE}
          userId={userId}
          linkedInCertificateURL={linkedInCertificateURL}
        />
      )}
    </GreyBorderContainer>
    {socialSharingEnabled && (
      <SocialSharing userId={userId} certificateURL={certificateURL} sharingMessage={sharingMessage} />
    )}
  </>
)

const BadgeScreen = () => {
  const { t } = useTranslation('badges')
  const history = useHistory()
  const { colors, metrics } = useDeedTheme()
  const params = useParams<{ id: string }>()
  const webUrl = useWebUrl()
  const badgeId = params.id
  const {
    data: badgesData,
    error: badgesError,
    loading: badgesLoading,
  } = useBadgesQuery({
    variables: {
      where: {
        id: { equals: badgeId },
      },
    },
    skip: !badgeId,
  })
  const badge = badgesData?.badges[0]

  if (badgesError || (!badgesLoading && !badge)) {
    Alert.alert(t`errorLoadingBadge`)
    history.replace('/profile')
  }

  const earnedAt = badge?.awardedBadge?.earnedAt
  const [month, year] = extractMonthAndYear(earnedAt)
  const certificateId = badge?.awardedBadge?.id
  const badgeName: string = badge?.name as string
  const certificateURL = `${webUrl}/certificates/${certificateId}`
  const organizationName = badge?.Organization?.name ?? t('common:deed')
  const linkedInParams = {
    startTask: 'CERTIFICATION_NAME',
    organizationName,
    name: badgeName,
    issueYear: year,
    issueMonth: month,
    certUrl: certificateURL,
    certId: certificateId,
  }
  const linkedInCertificateURL = `https://www.linkedin.com/profile/add${buildURLParams(linkedInParams)}`
  const sharingMessage = t('shareLinkMessage', { badgeName: formatBadgeNameForLinkSharing(badgeName) })
  const user = useSelector(selectCurrentUser)
  const socialSharingEnabled = !user?.organization?.settings?.disableSocialSharing && !!earnedAt

  return (
    <Screen>
      <ScrollView style={{ paddingBottom: 10 }}>
        <NavigationHeader transparent title={!metrics.isSmall && t`badges`} />
        {earnedAt && (
          <NavbarContainer>
            <OutlineButton
              small
              onPress={() => {
                const eventName = `${BADGE_SHARE}:intent:user-clicked-download-certificate`
                const userId = user?.id
                const event = {
                  version: 1,
                  event: eventName,
                  userId: userId || null,
                  context: getAnalyticsContext({ userId }) as EventContext,
                }

                metricsClient.track(eventName, event)
                Communications.web(certificateURL)
              }}
            >
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Icon icon="downloadOutlined" width={18} height={18} hexColor={colors.black} />
                <Body1 style={{ paddingLeft: 8, fontSize: 14 }}>{t('certificate')}</Body1>
              </View>
            </OutlineButton>
          </NavbarContainer>
        )}

        {badgesLoading && (
          <View style={{ paddingVertical: 40 }}>
            <ActivityIndicator color={colors.brandColor} />
          </View>
        )}
        {!badgesLoading && badge && (
          <>
            <GreyBorderContainer style={{ borderTopWidth: Number(!metrics.isSmall) }}>
              <BadgeWithTitle badge={badge} withEarnedSubtitle withoutLink bigTitle maxWidth="100%" imageWidth={85} />
            </GreyBorderContainer>
            <BadgeInfo
              badge={badge}
              socialSharingEnabled={socialSharingEnabled}
              linkedInCertificateURL={linkedInCertificateURL}
              certificateURL={certificateURL}
              sharingMessage={sharingMessage}
              textColour={colors.grayMediumDark}
              userId={user?.id}
            />
          </>
        )}
      </ScrollView>
    </Screen>
  )
}

export default BadgeScreen
