import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { selectOrganizationById } from 'src/entities/organization/selectors'
import { Loading } from 'src/retired/elements'
import * as OrganizationActions from 'src/containers/screens/Organization/actions'
import epics from 'src/containers/screens/Organization/epics'
import { useInjectEpics } from 'src/utils/injectEpics'

import { OffPlatformDonationView } from './OffPlatformDonationView'

const OffPlatformDonation = () => {
  useInjectEpics({ key: 'organization', epics })
  const dispatch = useDispatch()
  const { organization: organizationId } = useParams<{ organization: string }>()

  useEffect(() => {
    dispatch(OrganizationActions.initAction(organizationId))
  }, [dispatch, organizationId])

  const organization = useSelector((state) => selectOrganizationById(state, organizationId))

  if (!organization) {
    return <Loading />
  }

  return <OffPlatformDonationView organization={organization} />
}

export default OffPlatformDonation
