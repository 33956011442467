import { Record } from 'immutable'

const properties = {
  token: '',
  isNew: false,
}

// @NOTE-AC: It would be nice to change this type to something more TypeScript-friendly
// but it is hard to change this file without breaking `persistStore` in src/configureStore.ts
export default class Auth extends Record(properties, 'Auth') implements Auth {
  public readonly token!: string

  public readonly isNew!: boolean
}
