// Accepts our custom locale with private part and extracts a valid language code from it by stripping the private part, e.g. "en-GB"
export const getLanguageCode = (privateLocale: string): string => {
  const languageCode = privateLocale.split('-x-')[0]
  return languageCode
}

export const overrideLanguageCodeOnLocale = (privateLocale: string, newLanguageCode: string): string => {
  const privatePart = privateLocale.split('-x-')[1]

  // Override only the language code and leave the private part intact
  return privatePart ? `${newLanguageCode}-x-${privatePart}` : newLanguageCode
}

export const getClosestLocaleCode = (
  locale: string,
  locales: Array<{ code: string; name: string; folderName: string }>
): string => {
  const [language] = locale.split('-')
  return (
    locales.find(({ code }) => code === locale)?.code ??
    locales.find(({ code }) => code.startsWith(language))?.code ??
    'en-US'
  )
}
