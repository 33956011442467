import React, { Fragment } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'
import { Text } from 'src/retired/shared/Typography'
import Chip from 'src/retired/shared/Chip'
import { Row } from 'src/retired/elements'
import { formatNumber } from 'src/utils/format'
import { Platform } from 'src/utils'
import { getLanguageCode } from 'src/utils/languageUtils'

interface CardHorizontalProps {
  link?: string
  line1?: string
  line2?: string
  cta?: React.ReactNode
  matchingPercentage?: number
  creditAmountPerHour?: number
}
export type CardHorizontalElement = React.ReactElement<CardHorizontalProps>

const CardHorizontal: React.FC<CardHorizontalProps> = ({
  link = '',
  line1,
  line2,
  creditAmountPerHour,
  matchingPercentage,
  cta,
}): CardHorizontalElement => {
  const { t, i18n } = useTranslation('cardHorizontal')
  const Wrapper = link ? Link : Fragment
  const { colors, metrics } = useDeedTheme()

  return (
    <Wrapper to={link}>
      <Row style={{ alignItems: 'center', marginBottom: 18, marginTop: 18 }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <View
            style={{
              flexGrow: Platform.OS === 'web' ? 1 : undefined,
              flexShrink: Platform.OS === 'web' ? 1 : undefined,
              flexBasis: Platform.OS === 'web' ? 'auto' : undefined,
              width: Platform.OS === 'web' || !cta ? 'auto' : metrics.screenWidth - 200,
              paddingRight: 10,
            }}
          >
            {line1 ? (
              <Text marginBottom={8} fontSize={13} weight="bold" lineHeight={14} numberOfLines={2}>
                {line1}
              </Text>
            ) : null}
            {line2 ? (
              <Row
                style={{
                  alignItems: 'center',
                }}
              >
                <Text fontSize={13} weight="500" style={{ marginRight: 10 }} numberOfLines={1}>
                  {line2}
                </Text>
                {!!matchingPercentage && (
                  <Chip type="square" backgroundColor={colors.yellow} textColor="black">
                    {t('matched', {
                      percentage: formatNumber(matchingPercentage / 100),
                    }).toLocaleUpperCase(getLanguageCode(i18n.language))}
                  </Chip>
                )}
                {!!creditAmountPerHour && (
                  <Chip type="square" backgroundColor={colors.yellow} textColor="black">
                    {t`earnCredit`.toLocaleUpperCase(getLanguageCode(i18n.language))}
                  </Chip>
                )}
              </Row>
            ) : null}
          </View>
          <View>{cta}</View>
        </Row>
      </Row>
    </Wrapper>
  )
}

export default CardHorizontal
