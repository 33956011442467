import React from 'react'
import { Box, CircularProgress, InputAdornment, Alert, TextField, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Employee, useFetchEmployees } from 'src/utils/useFetchEmployees'

interface Props {
  UserRenderer: ({ user }: { user: Employee }) => JSX.Element
}
export const EmployeeSearch = ({ UserRenderer }: Props) => {
  const { t } = useTranslation('communityScreen')
  const {
    search,
    setSearch,
    result: { data, loading, error },
  } = useFetchEmployees()

  return (
    <Box>
      <TextField
        onChange={(event): void => setSearch(event.target.value)}
        value={search}
        label={t`searchEmployees`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search?.length > 0 && (
                <IconButton
                  aria-label={t`clearInput`}
                  onClick={(): void => setSearch('')}
                  edge="end"
                  style={{ marginRight: 10 }}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ width: '100%' }}
      />
      <Box mt={2} mb={2} maxHeight={300} style={{ overflowY: 'auto' }}>
        {loading ? (
          // @NOTE-DP: absolute position to prevent layout shift
          <View style={{ position: 'absolute' }}>
            <CircularProgress />
          </View>
        ) : error ? (
          <Alert severity="warning">{t`common:anErrorOccurred`}</Alert>
        ) : search.length > 0 && data?.employees.users.length === 0 ? (
          <View style={{ position: 'absolute' }}>
            <Alert severity="warning">{t`noMatchingResults`}</Alert>
          </View>
        ) : (
          data?.employees.users.map((user) => <UserRenderer key={user.id} user={user} />)
        )}
      </Box>
    </Box>
  )
}
