import React from 'react'
import Select from 'antd/lib/select'

import { useDeedTheme } from 'src/theme/ThemeProvider'

import './style.less'

const { Option, OptGroup } = Select

interface OptionActual {
  value: string
  title: string | React.ReactElement
}
interface OptionGroups {
  label: string
  options: OptionActual[]
}
interface Props {
  placeholder?: string
  options?: OptionActual[]
  optionGroups?: OptionGroups[]
  multiple?: boolean
  showSearch?: boolean
  disabled?: boolean
  onSelect: (value: string | string[]) => void
  onDeselect?: (value: any) => void
  onFocus: any
  value: any
  style?: React.CSSProperties
  largeSelectionPadding?: boolean
  allowClear?: boolean
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement
  customIcon?: React.ReactElement
}

const SelectBox: React.FC<Props> = ({
  value,
  placeholder = '',
  options = [],
  optionGroups = [],
  showSearch = false,
  multiple = false,
  disabled = false,
  onSelect,
  onDeselect,
  onFocus,
  style = {},
  largeSelectionPadding = false,
  allowClear = false,
  getPopupContainer,
  customIcon = false,
}) => {
  const { colors } = useDeedTheme()

  return (
    <Select
      value={value}
      placeholder={placeholder}
      mode={multiple ? 'multiple' : 'default'}
      showSearch={showSearch}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onClear={onDeselect}
      filterOption={(selectInput, option) =>
        typeof option?.props?.children === 'string' &&
        option.props.children.toLowerCase().indexOf(selectInput.toLowerCase()) >= 0
      }
      style={{ ...style, borderColor: colors.brandColor }}
      className={largeSelectionPadding ? 'large-selection-padding' : undefined}
      allowClear={allowClear}
      disabled={disabled}
      onFocus={onFocus}
      getPopupContainer={getPopupContainer}
      {...(customIcon && { suffixIcon: customIcon })}
    >
      {optionGroups.length > 0
        ? optionGroups.map((optionGroup) =>
            optionGroup.options ? (
              <OptGroup key={optionGroup.label} label={optionGroup.label}>
                {optionGroup.options.map((option) => (
                  <Option key={option.value + optionGroup.label || option} value={option.value || option}>
                    {option.title || option}
                  </Option>
                ))}
              </OptGroup>
            ) : (
              <Option
                key={optionGroup.value + optionGroup.title || optionGroup}
                value={optionGroup.value || optionGroup}
              >
                {optionGroup.title || optionGroup}
              </Option>
            )
          )
        : options.map((option) => (
            <Option key={option.value || option} value={option.value || option}>
              {option.title || option}
            </Option>
          ))}
    </Select>
  )
}

export default SelectBox
