import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'

import VolunteerTimeApi from 'src/entities/volunteerTime/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'

import { INIT, initSuccessAction, initFailedAction, InitAction } from './types'

const init = (action$: ActionsObservable<InitAction>) =>
  action$.ofType(INIT).exhaustMap(() =>
    // The cache of this query gets invalidated by the VolunteerTimeForm.
    // src/containers/screens/Profile/VolunteerTimeForm/index.tsx
    // If you change the query, pleas also adjust its `apolloClient.cache.evict`
    // in order to keep the cache invalidation working
    VolunteerTimeApi.getByFilter({ userId: { equals: 'me' } })
      .mergeMap((resultingAction) => [resultingAction, initSuccessAction()])
      .catch((e) => [initFailedAction(e), showErrorAction(i18n.t('volunteerTimesProfile:loadingFailed'))])
  )

export default [init]
