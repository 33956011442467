import type { AnyAction } from 'redux'
import { fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'

import {
  AUTH,
  AUTH_SUCCESS,
  AUTH_FAILED,
  APPLE_LOGIN,
  APPLE_LOGIN_FAILED,
  APPLE_AUTH_SUCCESS,
  APPLE_AUTH_FAILED,
} from './constants'

export type LoginScreenState = TypedMap<{
  authenticating: boolean
  authenticatingApple: boolean
  error?: Error | null
}>

export const initialState = fromJS({
  authenticating: false,
  authenticatingApple: false,
  error: null,
}) as LoginScreenState

export default (state = initialState, action: AnyAction): LoginScreenState => {
  switch (action.type) {
    case AUTH:
      return state.merge({ authenticating: true, error: null })

    case AUTH_SUCCESS:
      return state.merge({ authenticating: false })

    case AUTH_FAILED:
      return state.merge({ authenticating: false, error: action.error })

    case APPLE_LOGIN:
      return state.merge({ authenticatingApple: true, error: null })

    case APPLE_AUTH_SUCCESS:
      return state.merge({ authenticatingApple: false })

    case APPLE_LOGIN_FAILED:
    case APPLE_AUTH_FAILED:
      return state.merge({ authenticatingApple: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
