import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import Image from 'src/retired/elements/Image'
import { Body1, H5 } from 'src/retired/shared/Typography'

export const CardWrapper = styled.View`
  background-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.white};
  border-radius: 16px;
  shadow-offset: 0 1px;
  shadow-opacity: 0.1;
  shadow-radius: 15px;
  shadow-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.black};
  elevation: 1;
  height: 100%;
  justify-content: space-between;
`

export const CompanyIconWrapper = styled.View`
  background-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.white};
  position: absolute;
  border: 1px solid #0000001a;
  z-index: 2;
  top: 20px;
  right: 20px;
  border-radius: 20px;
`

export const ContentWrapper = styled.View`
  padding: 0 20px 20px 20px;
`

export const RewardWrapper = styled.View`
  height: 40px;
  margin-top: -20px;
`

export const Title = styled(H5)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 25px;
  text-overflow: ellipsis;
`

export const SubTitleWrapper = styled.View`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 8px;
  align-items: center;
`

export const SubTitle = styled(Body1)`
  font-size: 12px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CauseWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const BannerImage = styled(Image)`
  width: 100%;
  height: 150px;
  border-radius: 16px 16px 0 0;
`

export const CompanyIcon = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`
