import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import Platform from 'src/utils/Platform'
import ScrollView from 'src/retired/elements/ScrollView'

interface CardListProps {
  children: React.ReactNode
  scrollViewOffset: number
  small?: boolean
  noPadding?: boolean
  noMargin?: boolean
}
export type CardListElement = React.ReactElement<CardListProps>

const CardList: React.FC<CardListProps> = ({
  children,
  scrollViewOffset = 0,
  small = false,
  noPadding,
  noMargin,
}): CardListElement => {
  const scrollViewEl = useRef(null)
  const { metrics } = useDeedTheme()

  useEffect(() => {
    if (scrollViewEl) {
      scrollViewEl.current?.scrollTo({
        x: scrollViewOffset,
        animated: true,
      })
    }
  }, [scrollViewEl, scrollViewOffset])

  const marginLeft = metrics.isLarge && !small ? -56 : -28
  const marginRight = metrics.isLarge && !small ? -56 : -28
  const scrollPaddingLeft = metrics.isLarge && !small ? 46 : 20

  return (
    <ScrollView
      ref={scrollViewEl}
      style={{
        flexBasis: 'auto',
        alignSelf: 'stretch',
        overflow: 'hidden',
        marginLeft: noMargin ? 0 : marginLeft,
        marginRight: noMargin ? 0 : marginRight,
        scrollPaddingLeft: noPadding ? 0 : scrollPaddingLeft,
      }}
      contentContainerStyle={{
        paddingLeft: noPadding ? 0 : metrics.isLarge && !small ? 46 : 25,
        paddingRight: noPadding ? 0 : metrics.isLarge && !small ? 46 : 25,
      }}
      horizontal
      showsHorizontalScrollIndicator={false}
      pagingEnabled={Platform.OS === 'web'}
    >
      {children}
    </ScrollView>
  )
}

CardList.propTypes = {
  children: PropTypes.node,
}

export default CardList
