import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { View } from 'react-primitives'
import Lottie from 'lottie-react'

import { Icon } from 'src/retired/elements'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Body2 } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'
import calendarAnimation from 'src/assets/animations/calendarAnimation.json'

const Title = styled(Body2)<object, EmotionTheme>`
  margin: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`

const SubTitle = styled(Body2)`
  font-size: 12px;
`

const Wrapper = styled(View)`
  background-color: rgba(2, 136, 209, 0.1);
  text-align: left;
  border-radius: 0.25rem;
  padding: 1rem 0.5rem;
  margin: 1rem;
`

const Row = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`

const ItemWrapper = styled(View)`
  height: 4rem;
  max-height: 4rem;
  min-width: 2rem;
  justify-content: center;
`

interface Props {
  userOrganization: string
  estimatedMatchDisbursementNDays: number | null
  npoName?: string
  isPPGFEnrolled?: boolean
}

const Header = ({ userOrganization, estimatedMatchDisbursementNDays: numOfDays, npoName, isPPGFEnrolled }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { t } = useTranslation('logOffPlatformDonation')

  return (
    <>
      <PageTitle title={t`logOffPlatformDonation`} />
      <NavigationHeader transparent title={t`logOffPlatformDonation`} />

      <Title center>
        {t(`ifYourDonationIsEligibleForMatching`, { userOrganizationName: userOrganization })}
        {numOfDays && (
          <Wrapper onClick={() => setIsCollapsed(!isCollapsed)}>
            <Row>
              <ItemWrapper>
                <Lottie loop autoplay style={{ height: '100%' }} animationData={calendarAnimation} />
              </ItemWrapper>
              <SubTitle>
                <Trans
                  t={t}
                  i18nKey="estimatedExternalDonationMatchDisbursementSummary"
                  components={{
                    BlueText: <strong style={{ fontWeight: 500, color: '#015888' }}>\1</strong>,
                  }}
                  values={{
                    numOfDays,
                  }}
                />
              </SubTitle>
              <ItemWrapper>
                <Icon
                  icon="arrowDown"
                  style={{
                    width: '0.75rem',
                    height: '0.75rem',
                    margin: 'auto',
                    transform: isCollapsed && 'rotate(270deg)',
                  }}
                />
              </ItemWrapper>
            </Row>
            <SubTitle style={{ display: isCollapsed ? 'none' : 'block', marginLeft: '5rem' }}>
              <Trans
                t={t}
                i18nKey="estimatedExternalDonationMatchDisbursementDetail"
                values={{
                  numOfDays,
                  npoName,
                  status: isPPGFEnrolled ? t`enrolled` : t`unenrolled`,
                }}
              />
            </SubTitle>
          </Wrapper>
        )}
      </Title>
    </>
  )
}

export default Header
