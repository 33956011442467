import { Platform } from 'src/utils'
import { MetricsProps } from 'src/theme/metrics'

export const getInnerListStyle = (isSmall: boolean, withoutPaddingBottom: boolean) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minWidth: 100,
  minHeight: Platform.OS !== 'web' ? 100 : 0,
  marginTop: isSmall ? 0 : 20,
  paddingLeft: isSmall ? 6 : 0,
  paddingRight: isSmall ? 6 : 0,
  paddingTop: isSmall ? 14 : 0,
  paddingBottom: !withoutPaddingBottom ? 14 : 0,
})

export const getCardWidth = (metrics: MetricsProps) =>
  metrics.isSmall ? '100%' : metrics.isMedium ? '50%' : metrics.screenWidth < metrics.maxWidthNarrow ? '33.3%' : '25%'
