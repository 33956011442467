import React from 'react'

export const PureRender = (ComposedComponent) =>
  class extends React.PureComponent {
    // eslint-disable-line react/prefer-stateless-function
    render() {
      return <ComposedComponent {...this.props} />
    }
  }

export const onMount = (initializeFromProps) => (ComposedComponent) =>
  class extends React.Component {
    // eslint-disable-line react/no-multi-comp
    componentDidMount() {
      initializeFromProps(this.props)
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
