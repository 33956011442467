import { ADD, RESET_FORMS } from './constants'

export const addAction = (form: any) => ({
  type: ADD,
  form,
})

export const resetFormsAction = () => ({
  type: RESET_FORMS,
})
