import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import injectReducer from 'src/utils/injectReducer'
import injectEpics from 'src/utils/injectEpics'
import { Loading, DashedContainer, Text, Screen, ScrollView } from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import Donation from 'src/retired/blocks/Donation'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectDonationsForDeedLoaded, selectDonationsForDeed } from 'src/entities/donation/selectors'
import { Redirect } from 'src/navigation'

import { selectLoading } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

class Donations extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.deed)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deed } = nextProps.match.params
    if (deed && deed !== this.props.match.params.deed) {
      this.props.actions.initAction(deed)
    }
  }

  render() {
    const { t, deed, donations, donationsLoaded } = this.props

    if (!deed) {
      return <Loading />
    }

    if (deed.type !== 'Campaign') {
      return <Redirect to={`/deeds/${this.props.match.params.deed}`} />
    }

    return (
      <Screen>
        <ScrollView>
          <NavigationHeader
            backTo={`/deeds/${deed.id}`}
            title={t('donationsFor', { deedName: deed.name })}
            transparent
          />
          {donations.size > 0 ? (
            <View>
              {donations.toArray().map((donation) => (
                <Donation key={donation.id} donation={donation} deed={deed} showUser link={false} />
              ))}
              {!donationsLoaded ? <ActivityIndicator /> : null}
            </View>
          ) : (
            <View style={{ margin: 25 }}>
              {donationsLoaded ? (
                <DashedContainer>
                  <Text medium size={13} style={{ marginTop: 15, marginBottom: 15 }}>
                    {t`noDonationsForFundraiser`}
                  </Text>
                </DashedContainer>
              ) : (
                <ActivityIndicator />
              )}
            </View>
          )}
        </ScrollView>
      </Screen>
    )
  }
}
Donations.propTypes = {
  donationsLoaded: PropTypes.bool,
  donations: PropTypes.object,
  deed: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
}

const withConnect = connect(
  (state, props) => ({
    loading: selectLoading(state),
    donationsLoaded: selectDonationsForDeedLoaded(state, props.match.params.deed),
    donations: selectDonationsForDeed(state, props.match.params.deed),
    deed: selectDeedById(state, props.match.params.deed),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedDonations', reducer })
const withEpics = injectEpics({ key: 'deedDonations', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('deedScreen'))(Donations)
