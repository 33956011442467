import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import NotificationBanner from 'src/retired/elements/NotificationBanner'
import { selectCurrentUser } from 'src/entities/user/selectors'

const PendingApprovalBanner = (): JSX.Element | null => {
  const { t } = useTranslation('pendingApprovalBanner')
  const user = useSelector(selectCurrentUser)

  const company = user?.organization

  if (!user?.isPending()) {
    return null
  }

  return (
    <View>
      <NotificationBanner>{t('weveSent', { companyName: company.name })}</NotificationBanner>
    </View>
  )
}
PendingApprovalBanner.displayName = 'PendingApprovalBanner'
export default PendingApprovalBanner
