import { fromJS } from 'immutable'

import type { TypedMap } from 'src/utils/typed-map'

import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILED,
  CLEAR_DELETE_ERROR,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAILED,
  CLEAR_UPDATE_ERROR,
  UpdateErrorPlain,
  DeleteErrorPlain,
} from './types'

export type UpdateError = TypedMap<UpdateErrorPlain>
export type DeleteError = TypedMap<DeleteErrorPlain>

export type DonationScheduleDetailsState = TypedMap<{
  loading: boolean
  deleting: boolean
  updating: boolean
  error: Error | null
  deleteError: DeleteError | null
  updateError: UpdateError | null
  dataClientToken?: string
}>

export type ActionType = { type: string; error?: Error; updateError?: UpdateError; deleteError?: DeleteError }

export const initialState = fromJS({
  loading: false,
  deleting: false,
  updating: false,
  error: null,
  updateError: null,
  deleteError: null,
  dataClientToken: '',
}) as DonationScheduleDetailsState

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: DonationScheduleDetailsState = initialState,
  action: ActionType
): DonationScheduleDetailsState => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case DELETE:
      return state.set('deleting', true)

    case DELETE_SUCCESS:
      return state.set('deleting', false)

    case DELETE_FAILED:
      return state.merge({ deleting: false, deleteError: action.deleteError })

    case CLEAR_DELETE_ERROR:
      return state.set('deleteError', null)

    case UPDATE:
      return state.set('updating', true)

    case UPDATE_SUCCESS:
      return state.set('updating', false)

    case UPDATE_FAILED:
      return state.merge({ updating: false, updateError: action.updateError })

    case CLEAR_UPDATE_ERROR:
      return state.set('updateError', null)

    default:
      return state
  }
}
