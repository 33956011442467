/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-native-web-responsive'

// Fragment blocks in this file prevent proptype warning on native

export const LargeUp = ({ children, ...props }) => (
  <MediaQuery minWidth={1024} {...props}>
    <>{children}</>
  </MediaQuery>
)
LargeUp.propTypes = {
  children: PropTypes.node,
}

export const MediumUp = ({ children, ...props }) => (
  <MediaQuery minWidth={640} {...props}>
    <>{children}</>
  </MediaQuery>
)
MediumUp.propTypes = {
  children: PropTypes.node,
}

export const MediumOnly = ({ children, ...props }) => (
  <MediaQuery minWidth={640} maxWidth={1023} {...props}>
    <>{children}</>
  </MediaQuery>
)
MediumOnly.propTypes = {
  children: PropTypes.node,
}

export const MediumDown = ({ children, ...props }) => (
  <MediaQuery maxWidth={1023} {...props}>
    <>{children}</>
  </MediaQuery>
)
MediumDown.propTypes = {
  children: PropTypes.node,
}

export const SmallOnly = ({ children, ...props }) => (
  <MediaQuery maxWidth={639} {...props}>
    <>{children}</>
  </MediaQuery>
)
SmallOnly.propTypes = {
  children: PropTypes.node,
}

export const ShortOnly = ({ children, ...props }) => (
  <MediaQuery maxHeight={639} {...props}>
    <>{children}</>
  </MediaQuery>
)
ShortOnly.propTypes = {
  children: PropTypes.node,
}

export const TallOnly = ({ children, ...props }) => (
  <MediaQuery minHeight={640} {...props}>
    <>{children}</>
  </MediaQuery>
)
TallOnly.propTypes = {
  children: PropTypes.node,
}

export { MediaQuery }
