import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Tooltip from 'src/components/Tooltip/Tooltip'
import { selectUserBrand } from 'src/entities/user/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { VolunteerRewardBudgetingType } from 'src/generated/graphql'
import { useParams } from 'src/navigation'
import { LinkText } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { useInjectEpics, useInjectReducer } from 'src/utils'
import { convertDuration } from 'src/utils/convertDuration'

import epics from '../VolunteerTime/epics'
import reducer from '../VolunteerTime/reducer'

import { progressOrange } from './ProgressBar'
import { StepItem } from './StepItem'
import { calculateIsAllOfBudgetUsed } from './calculateIsAllOfBudgetUsed'

export const SubmitHoursStep = ({
  volunteerRewardBudgeting,
}: {
  volunteerRewardBudgeting?: VolunteerRewardBudgetingType
}) => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('volunteerTimeSuccessProfile')

  useInjectEpics({ key: 'volunteerTime', epics })
  useInjectReducer({ key: 'volunteerTime', reducer })

  const brand = useSelector(selectUserBrand)
  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, id))
  const volunteeredDeed = volunteerTime?.deed

  const isVolunteerRewardBudgetAlreadyUsed = calculateIsAllOfBudgetUsed(volunteerRewardBudgeting)

  const showTooltip =
    !volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget && !isVolunteerRewardBudgetAlreadyUsed

  return (
    <StepItem title={t`submittedVolunteerHours`} checked stepNumber={1}>
      <>
        {volunteeredDeed?.name ? (
          <Body1 style={{ fontWeight: '500' }} marginBottom={8}>
            {t`volunteerEvent`}
            <LinkText
              size={16}
              lineHeight={19}
              style={{ fontWeight: '200' }}
              color={brand?.brandColor}
              underline
              to={`/deeds/${volunteeredDeed?.id}`}
            >
              {volunteeredDeed?.name}
            </LinkText>
          </Body1>
        ) : null}
        <Body1 style={{ fontWeight: '500', display: 'flex' }}>
          {t`hoursVolunteered`}
          {volunteerTime?.duration ? (
            <div style={{ display: 'flex' }}>
              <Body1 style={{ color: showTooltip ? progressOrange : undefined }}>
                {convertDuration(volunteerTime?.duration)}
              </Body1>
              {showTooltip && (
                <Tooltip title={t`tooltipText`} showInfoIcon placement="bottom" infoIconColor={progressOrange} />
              )}
            </div>
          ) : null}
        </Body1>
      </>
    </StepItem>
  )
}
