import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useHistory, useLocation } from 'react-router'
import { compile } from 'country-states'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'query-string'
import { Alert } from '@mui/material'
import MaskedInput from 'react-input-mask'

import { css } from 'src/theme/styled'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { Screen, Text, ScrollView, TextField, Button, Spacing, Form, Row, ExternalLink } from 'src/retired/elements'
import { Tooltip } from 'src/components/Tooltip/Tooltip'
// NOTE-AZ: Because we should not limit the component
import { Radio } from 'src/retired/elements/Radio'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import {
  useCreateNonprofitNominationMutation,
  NonprofitNominationsDocument,
  EngagementType,
  NonprofitNomination,
  NonprofitEntityType,
  NonprofitNominationCreateInputType,
} from 'src/generated/graphql'
import payPalCountries from 'src/utils/payPalCountriesWithStates'
import { validators } from 'src/utils'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Label } from 'src/retired/shared/Typography'
import { PageTitle, Select } from 'src/components'
import { ErrorText } from 'src/components/ErrorText'
import { selectCurrentUser } from 'src/entities/user/selectors'
import CountrySelector from 'src/components/CountrySelector'

import NominationSuccessConfirmation from './NominationSuccessConfirmation'

interface PreviousNominations {
  nonprofitNominations: NonprofitNomination[]
}

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  width: 100%;
  flex-grow: 0;
`

const viewStyle = css`
  width: 100%;
  z-index: -1;
`

const ErrorTextStyled = ({ text }: { text: string }): JSX.Element => <ErrorText text={text} style={{ marginLeft: 8 }} />

const MaskedTextField = ({
  value,
  onChange,
  disabled,
  placeholder,
  name,
  mask,
}: {
  value: string
  onChange: (event: Event) => void
  disabled: boolean
  placeholder: string
  name: string
  mask: string
}) => (
  <MaskedInput mask={mask} value={value} onChange={onChange} disabled={disabled}>
    {() => <TextField placeholder={placeholder} name={name} style={roundedInputStyle} onChangeText={() => {}} />}
  </MaskedInput>
)

const NominateNonprofitForm = (): JSX.Element => {
  const { t } = useTranslation('nominateNonprofitForm')
  const { metrics, colors } = useDeedTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()

  compile(payPalCountries)

  const [showSuccess, setShowSuccess] = useState(false)

  const [createNonprofitNominationMutation, { loading }] = useCreateNonprofitNominationMutation()

  const user = useSelector(selectCurrentUser)
  const isVolunteeringFeatureEnabled = user?.hasFeature('volunteering')
  const isDonateFeatureEnabled = user?.hasFeature('donations')

  const handleSubmitForm = async (values: NonprofitNominationCreateInputType): Promise<void> => {
    const { ncesIdentifier, ein, otherNote, ...rest } = values
    const cleanedFormData: NonprofitNominationCreateInputType = {
      nonprofitName: values.nonprofitName,
      country: values.country,
      engagementType: values.engagementType,
      ...(values.engagementType === EngagementType.Other ? { otherNote } : {}),
      ...(values.country === 'US' ? { nonprofitEntityType: values.nonprofitEntityType } : {}),
      ...(values.country === 'US' && values.nonprofitEntityType === NonprofitEntityType.Nonprofit ? { ein } : {}),
      ...(values.country === 'US' && values.nonprofitEntityType === NonprofitEntityType.School
        ? { ncesIdentifier }
        : {}),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...Object.fromEntries(Object.entries(rest).filter(([_, value]) => value)),
    }
    try {
      await createNonprofitNominationMutation({
        variables: {
          data: cleanedFormData,
        },
        update: (cache, { data }) => {
          const prevNominationsList = cache.readQuery<PreviousNominations>({
            query: NonprofitNominationsDocument,
          })
          if (prevNominationsList) {
            const updatedNominationsList = [
              prevNominationsList.nonprofitNominations.slice(),
              data?.createNonprofitNomination,
            ]
            cache.writeQuery({
              query: NonprofitNominationsDocument,
              data: { nonprofitNominations: updatedNominationsList },
            })
          }
        },
      })
      setShowSuccess(true)
    } catch (e) {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    }
  }

  const searchParams = search ? parse(search) : {}

  const nonprofitName = searchParams.nonprofitName || ''

  const formik = useFormik({
    initialValues: {
      nonprofitEntityType: '',
      engagementType: isVolunteeringFeatureEnabled ? EngagementType.Both : EngagementType.Donate,
      nonprofitName,
      country: '',
      phone: '',
      ein: '',
      ncesIdentifier: '',
      website: '',
      email: '',
      otherNote: '',
    },
    validate: (values) => {
      const errors = {
        nonprofitName: validators.notEmpty(values.nonprofitName),
        country: validators.notEmpty(values.country),
        email: validators.notEmpty(values.email) || validators.isEmail(values.email),
        nonprofitEntityType: values.country === 'US' ? validators.notEmpty(values.nonprofitEntityType) : null,
        ein:
          values.country === 'US' && values.nonprofitEntityType === NonprofitEntityType.Nonprofit
            ? validators.notEmpty(values.ein) || validators.isEin(values.ein)
            : null,
        website: validators.isURL(values.website),
        phone: values.phone !== '' ? validators.isPhone(values.phone) : null,
        engagementType: validators.notEmpty(values.engagementType),
        otherNote: values.engagementType === EngagementType.Other ? validators.notEmpty(values.otherNote) : null,
        ncesIdentifier:
          values.country === 'US' && values.nonprofitEntityType === NonprofitEntityType.School
            ? validators.notEmpty(values.ncesIdentifier)
            : null,
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    validateOnMount: true,
    onSubmit: async (values) => {
      await handleSubmitForm(values)
    },
  })
  const setLocation = async (value: string | null): Promise<void> => {
    await formik.setFieldValue('country', value)
  }

  const onRadioChange = async (option: string): Promise<void> => {
    await formik.setFieldValue('engagementType', option)
    if (option !== 'Other') {
      await formik.setFieldValue('otherNote', null)
    }
  }

  if (showSuccess) {
    return <NominationSuccessConfirmation />
  }

  return (
    <Screen style={{ padding: 20 }} loading={loading}>
      <PageTitle title={t`nominateAnOrganization`} />
      <NavigationHeader
        title={
          <View>
            <Text size={16} center style={{ marginBottom: 8 }}>{t`nominateAnOrganization`}</Text>
            <Label center>{t`pleaseAnswerTheFollowingQuestions`}</Label>
          </View>
        }
      />

      <Spacing marginBottom={16} />

      <ScrollView>
        <Form onSubmit={formik.handleSubmit}>
          <View style={{ padding: 8 }}>
            <View style={{ alignItems: 'center' }}>
              <View
                style={{
                  flexDirection: metrics.isSmall ? 'column' : 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={viewStyle}>
                  <Label paddingLeft={10} paddingRight={10} marginBottom={8}>
                    {t`organizationLocationCopy`} *
                  </Label>

                  <View style={{ fontSize: '12px', marginTop: 8 }}>
                    <CountrySelector
                      handleChangeLocation={setLocation}
                      country={formik.values.country}
                      placeholder={t`country`}
                      disabled={loading}
                      showStates
                      width="100%"
                    />
                  </View>

                  <Spacing marginBottom={16} />

                  {formik.errors.country && formik.touched.country && <ErrorTextStyled text={formik.errors.country} />}
                </View>
              </View>

              {formik.values.country?.startsWith('US') && (
                <View style={viewStyle}>
                  <Label paddingLeft={10} paddingRight={10} marginBottom={8}>
                    <Row>
                      <View>{t`nonprofitEntityType`} * </View>
                      <Tooltip
                        title={
                          <Trans
                            t={t}
                            i18nKey="nonprofitEntityTypeTooltip"
                            components={{
                              OL: <ol />,
                              LI: <li />,
                              LinkIRS: (
                                <ExternalLink
                                  href="https://apps.irs.gov/app/eos/"
                                  size={12}
                                  style={{
                                    textDecorationLine: 'underline',
                                    color: 'white',
                                  }}
                                />
                              ),
                              LinkGov: (
                                <ExternalLink
                                  href="https://www.usa.gov/agency-index"
                                  size={12}
                                  style={{
                                    textDecorationLine: 'underline',
                                    color: 'white',
                                  }}
                                />
                              ),
                            }}
                          />
                        }
                        showInfoIcon
                        infoIconColor={colors.gray}
                        placement="right"
                      />
                    </Row>
                  </Label>

                  <Select
                    value={formik.values.nonprofitEntityType}
                    onSelect={(value) => {
                      void formik.setFieldValue('nonprofitEntityType', value)
                    }}
                    options={Object.values(NonprofitEntityType)
                      .filter((type) => type !== NonprofitEntityType.MemberAssociatedEntity)
                      .map((type) => ({ value: type, title: t(`nonprofitEntityTypeOption_${type}`) }))}
                  />
                  {formik.errors.nonprofitEntityType && formik.touched.nonprofitEntityType && (
                    <>
                      <Spacing marginBottom={16} />
                      <ErrorTextStyled text={formik.errors.nonprofitEntityType} />
                    </>
                  )}
                  <Spacing marginBottom={20} />
                </View>
              )}

              {formik.values.nonprofitEntityType === NonprofitEntityType.GovernmentEntity ? (
                <Alert severity="info">{t`governmentEntityNote`}</Alert>
              ) : (
                <>
                  <View style={viewStyle}>
                    <Label paddingLeft={10} paddingRight={10}>
                      {t`organizationNameCopy`} *
                    </Label>

                    <TextField
                      onChangeText={async (fieldName: string, value: string) => formik.setFieldValue(fieldName, value)}
                      name="nonprofitName"
                      style={roundedInputStyle}
                      placeholder={t`organizationName`}
                      disabled={loading}
                      value={formik.values.nonprofitName}
                    />

                    {formik.errors.nonprofitName && formik.touched.nonprofitName && (
                      <ErrorTextStyled text={formik.errors.nonprofitName} />
                    )}
                  </View>

                  <View style={viewStyle}>
                    <Label paddingLeft={10} paddingRight={10}>
                      {t`organizationEmailLabel`} *
                    </Label>

                    <TextField
                      onChangeText={async (fieldName: string, value: string) => formik.setFieldValue(fieldName, value)}
                      name="email"
                      style={roundedInputStyle}
                      placeholder={t`organizationEmail`}
                      disabled={loading}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && <ErrorTextStyled text={formik.errors.email} />}
                  </View>

                  {formik.values.country?.startsWith('US') && (
                    <>
                      {formik.values.nonprofitEntityType === NonprofitEntityType.Nonprofit && (
                        <View style={viewStyle}>
                          <Label paddingLeft={10} paddingRight={10}>
                            {t`organizationEinLabel`} *
                          </Label>

                          <MaskedTextField
                            mask="99-9999999"
                            name="ein"
                            onChange={(event) => formik.setFieldValue('ein', event?.target?.value)}
                            disabled={loading}
                            value={formik.values.ein}
                            placeholder={t`organizationEin`}
                          />
                          {formik.errors.ein && formik.touched.ein && <ErrorTextStyled text={formik.errors.ein} />}
                          <Text paddingLeft={10} paddingRight={10} size={11} color={colors.gray}>
                            <Trans
                              t={t}
                              i18nKey="organizationEinCopy"
                              components={{
                                IrsLink: <a href="https://www.irs.gov/" aria-label="IRS" target="_blank" />,
                              }}
                            />
                          </Text>
                        </View>
                      )}
                      {formik.values.nonprofitEntityType === NonprofitEntityType.School && (
                        <View style={viewStyle}>
                          <Label paddingLeft={10} paddingRight={10}>
                            {t`organizationNcesIdentifierLabel`} *
                          </Label>

                          <MaskedTextField
                            mask="999999999999"
                            name="ncesIdentifier"
                            onChange={(event) => formik.setFieldValue('ncesIdentifier', event?.target?.value)}
                            disabled={loading}
                            value={formik.values.ncesIdentifier}
                            placeholder={t`organizationNcesIdentifier`}
                          />

                          {formik.errors.ncesIdentifier && formik.touched.ncesIdentifier && (
                            <ErrorTextStyled text={formik.errors.ncesIdentifier} />
                          )}
                          <Text
                            paddingLeft={10}
                            paddingRight={10}
                            size={11}
                            color={colors.gray}
                          >{t`organizationNcesIdentifierCopy`}</Text>
                        </View>
                      )}
                      <Spacing marginBottom={20} />
                    </>
                  )}

                  <View style={viewStyle}>
                    <Label paddingLeft={10} paddingRight={10}>
                      {t`organizationWebsiteLabel`}
                    </Label>

                    <TextField
                      onChangeText={async (fieldName: string, value: string) => formik.setFieldValue(fieldName, value)}
                      name="website"
                      style={roundedInputStyle}
                      placeholder={t`organizationWebsite`}
                      disabled={loading}
                      value={formik.values.website}
                    />
                    {formik.errors.website && formik.touched.website && (
                      <ErrorTextStyled text={formik.errors.website} />
                    )}
                  </View>

                  <View style={viewStyle}>
                    <Label paddingLeft={10} paddingRight={10}>
                      {t`organizationPhoneNumberLabel`}
                    </Label>

                    <TextField
                      onChangeText={async (fieldName: string, value: string) => formik.setFieldValue(fieldName, value)}
                      name="phone"
                      style={roundedInputStyle}
                      placeholder={t`organizationPhoneNumber`}
                      disabled={loading}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone && formik.touched.phone && <ErrorTextStyled text={formik.errors.phone} />}
                  </View>

                  <Spacing marginBottom={20} />

                  {isVolunteeringFeatureEnabled && isDonateFeatureEnabled && (
                    <>
                      <Label>{t`engagementType`} *</Label>
                      <Spacing marginBottom={8} />
                      <Row style={{ justifyContent: 'space-evenly' }}>
                        {[
                          EngagementType.Volunteer,
                          EngagementType.Donate,
                          EngagementType.Both,
                          EngagementType.Other,
                        ].map((option) => (
                          <Radio
                            key={option}
                            name="engagementType"
                            onChange={() => {
                              onRadioChange(option).finally(() => true)
                            }}
                            disabled={loading}
                            checked={formik.values.engagementType === option}
                          >
                            <Label marginLeft={8}>{t(`engagementType_${option}`)}</Label>
                          </Radio>
                        ))}
                      </Row>
                      <Spacing marginBottom={8} />
                      {formik.values.engagementType === EngagementType.Other && (
                        <TextField
                          onChangeText={async (fieldName: string, value: string) =>
                            formik.setFieldValue(fieldName, value)
                          }
                          name="otherNote"
                          numberOfLines={5}
                          multiline
                          style={roundedInputStyle}
                          disabled={loading}
                          value={formik.values.otherNote}
                        />
                      )}
                    </>
                  )}
                  {formik.errors.otherNote && formik.touched.otherNote && (
                    <ErrorTextStyled text={formik.errors.otherNote} />
                  )}
                </>
              )}
            </View>
          </View>
        </Form>
      </ScrollView>

      <Spacing marginBottom={8} />

      <View style={{ alignItems: 'center' }}>
        {formik.values.nonprofitEntityType !== NonprofitEntityType.GovernmentEntity && (
          <Button disabled={loading} onPress={formik.handleSubmit}>{t`common:Submit`}</Button>
        )}
        <Spacing marginBottom={12} />
        <Text
          style={{ cursor: 'pointer' }}
          onPress={() => history.goBack()}
          underline
          color={colors.lightGray}
        >{t`common:Cancel`}</Text>
      </View>

      <Spacing marginBottom={8} />
    </Screen>
  )
}

export default NominateNonprofitForm
