import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toggleCorporateOnlyAction, initAction } from 'src/containers/modules/FeedFilter/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'
import { selectCorporateOnly } from 'src/containers/modules/FeedFilter/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { DeedsOrigin, useSearchParams } from '../../utils'

// The volunteer results use the selectFilteredDeeds-selector which mainly relies on the FeedFilter redux state
// The Corporate-Only's redux representation lives inside feedFilter->corporateOnly boolean
// The CorporateOnlySynchronizer sets the correct corporateOnly redux-value, based on the url and the organizers's corporateOnlyToggleDefault setting
export const CorporateOnlySynchronizer = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => selectCurrentUser(state))

  const { deedsOrigin: deedsOriginUrl } = useSearchParams()

  const corporateOnly = useSelector((state) => selectCorporateOnly(state))

  useInjectReducer({ key: 'feedFilter', reducer: feedFilterReducer })

  useEffect(() => {
    if (user && !deedsOriginUrl) {
      dispatch(initAction(user?.organization?.settings.corporateOnlyToggleDefault))
    }
  }, [user, deedsOriginUrl])

  useEffect(() => {
    const isCorporateOnlyUrlState = deedsOriginUrl === DeedsOrigin.company
    if (!!deedsOriginUrl && isCorporateOnlyUrlState !== corporateOnly) {
      dispatch(toggleCorporateOnlyAction(isCorporateOnlyUrlState))
    }
  }, [deedsOriginUrl])

  return null
}
