import { ADD, ADD_MULTIPLE, CLEAR, LOADED } from './constants'
import Campaign from './model'
import { CampaignMap } from './reducer'

export interface AddAction {
  type: typeof ADD
  campaign: Campaign
}
export const addAction = (campaign: Campaign): AddAction => ({
  type: ADD,
  campaign,
})

export interface AddMultipleAction {
  type: typeof ADD_MULTIPLE
  campaigns: CampaignMap
}
export const addMultipleAction = (campaigns: CampaignMap): AddMultipleAction => ({
  type: ADD_MULTIPLE,
  campaigns,
})

export interface ClearAction {
  type: typeof CLEAR
}
export const clearAction = (): ClearAction => ({
  type: CLEAR,
})

export interface LoadedAction {
  type: typeof LOADED
}
export const loadedAction = (): LoadedAction => ({
  type: LOADED,
})

export type CampaignActions = AddAction | AddMultipleAction | ClearAction | LoadedAction
