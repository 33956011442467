import { useEffect, useState } from 'react'
import type { History } from 'history'
import { ParsedQuery, parse, stringify } from 'query-string'
import { eachDayOfInterval } from 'date-fns'
import { useSelector } from 'react-redux'

import { useLocation } from 'src/navigation'
import { MetricsProps } from 'src/theme/metrics'
import { interpolationFormat } from 'src/i18n/interpolationFormat'
import { CauseMap } from 'src/entities/cause/reducer'
import { CauseTypes } from 'src/entities/cause/model'
import { selectFlatCauses } from 'src/entities/cause/selectors'
import { SkillMap } from 'src/entities/skill/reducer'
import { selectSkills } from 'src/entities/skill/selectors'
import { Coordinates } from 'src/utils/coordinates'

export enum TabType {
  donate = 'donate',
  volunteer = 'volunteer',
  // event = 'event',
}

export const getCardListDimensions = (metrics: MetricsProps, useBigList?: boolean) => {
  const LONG_LIST_LENGTH = 24

  if (metrics.isSmall) {
    return { cardWidth: '100%', numberOfColumns: 1, initialElements: useBigList ? LONG_LIST_LENGTH : 3 }
  }

  if (metrics.isMedium || metrics.screenWidth < metrics.maxWidthNarrow) {
    return { cardWidth: '50%', numberOfColumns: 2, initialElements: useBigList ? LONG_LIST_LENGTH : 4 }
  }

  if (metrics.screenWidth >= 2300) {
    return { cardWidth: '20%', numberOfColumns: 5, initialElements: useBigList ? LONG_LIST_LENGTH : 5 }
  }

  if (metrics.screenWidth >= 1920) {
    return { cardWidth: '25%', numberOfColumns: 4, initialElements: useBigList ? LONG_LIST_LENGTH : 4 }
  }

  return { cardWidth: '33.3%', numberOfColumns: 3, initialElements: useBigList ? LONG_LIST_LENGTH : 3 }
}

export enum DonateTypes {
  fundraisers = 'Fundraisers',
  nonprofits = 'Nonprofits',
}

export enum VolunteerTypes {
  ongoing = 'Ongoing',
  oneOff = 'OneOff',
}

export enum DeedsOrigin {
  company = 'Company',
  all = 'All',
}

export enum AttendanceOptions {
  inPerson = 'In-person',
  virtual = 'Virtual',
}

export enum DayOptions {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export const allDays = [
  DayOptions.monday,
  DayOptions.tuesday,
  DayOptions.wednesday,
  DayOptions.thursday,
  DayOptions.friday,
  DayOptions.saturday,
  DayOptions.sunday,
]

export enum TimeOptions {
  morning = 'Morning',
  afternoon = 'Afternoon',
  evening = 'Evening',
}

export const allTimes = [TimeOptions.morning, TimeOptions.afternoon, TimeOptions.evening]

export enum SearchParams {
  tab = 'tab',
  // Donate-tab params
  searchTerm = 'searchTerm',
  cause = 'cause',
  donateType = 'donateType',
  location = 'location',
  // Volunteer-tab params
  radius = 'radius',
  deedsOrigin = 'deedsOrigin',
  volunteerType = 'volunteerType',
  attendanceOption = 'attendanceOption',
  date = 'date',
  day = 'day',
  time = 'time',
  skill = 'skill',
  coordinates = 'coordinates',
}

export const getSearch = (params: any) => `?${stringify(params, { skipEmptyString: true })}`

export const addSearchDataToUrl = (
  newParams: Partial<Record<SearchParams, string | string[] | number | null | boolean | Coordinates>>,
  currentSearchParams: string,
  history: History
) => {
  const params = stringify({
    ...parse(currentSearchParams),
    ...newParams,
  })

  return history.replace({
    search: params,
  })
}

export const setSearchDataToUrl = (
  newParams: Partial<Record<SearchParams | 'campaign', string | string[] | number | null | boolean | Coordinates>>,
  history: History
) => {
  const params = stringify(newParams)

  return history.replace({
    search: params,
  })
}

export const getSearchTermFromParams = (parsedParams: ParsedQuery<string>): string | undefined =>
  typeof parsedParams.searchTerm === 'string' ? parsedParams.searchTerm : undefined

export const getLocationFromParams = (parsedParams: ParsedQuery<string>): string | undefined =>
  typeof parsedParams.location === 'string' ? parsedParams.location : undefined

export const getRadiusFromParams = (parsedParams: ParsedQuery<string>): number | null =>
  typeof parsedParams.radius === 'string' && !Number.isNaN(Number(parsedParams.radius))
    ? Number(parsedParams.radius)
    : null

export const getCausesFromParams = (parsedParams: ParsedQuery<string>): string[] | null =>
  typeof parsedParams.cause === 'string' ? [parsedParams.cause] : parsedParams.cause

export const getActiveTabFromParams = (parsedParams: ParsedQuery<string>): string =>
  typeof parsedParams.tab === 'string' && TabType[parsedParams.tab as TabType] ? parsedParams.tab : TabType.donate

export const getSkillsFromParams = (parsedParams: ParsedQuery<string>): string[] | null =>
  typeof parsedParams.skill === 'string' ? [parsedParams.skill] : parsedParams.skill

export const getDeedsOriginFromParams = (parsedParams: ParsedQuery<string>): DeedsOrigin | null =>
  typeof parsedParams.deedsOrigin === 'string' ? (parsedParams.deedsOrigin as DeedsOrigin) : null

export const getDateFromParams = (parsedParams: ParsedQuery<string>): string | null =>
  typeof parsedParams.date === 'string' ? parsedParams.date : null

export const getCoordinatesFromParams = (parsedParams: ParsedQuery<string>): Coordinates | null =>
  Array.isArray(parsedParams.coordinates) && parsedParams.coordinates.length === 2
    ? ([Number(parsedParams.coordinates[0]), Number(parsedParams.coordinates[1])] as Coordinates)
    : null

const randomMonday = '2023-07-03'
const eachDayOfTheWeekWithRandomDate = eachDayOfInterval({
  start: new Date(randomMonday),
  end: new Date(new Date(randomMonday).setDate(new Date(randomMonday).getDate() + 6)),
})

// We using this and not manual weekday translations in order to not need to main the weekday translation
export const getTranslatedWeekdays = (localeString: string) =>
  eachDayOfTheWeekWithRandomDate.map((day) => interpolationFormat(day, 'EEEE', localeString))

export const getCauseIdsByType = (causes: CauseMap, type: CauseTypes) => [
  ...new Set(
    causes
      .filter((cause) => cause.type === type)
      .map((cause) => cause.id)
      .toArray()
  ),
]

export const useSearchParams = () => {
  const { search } = useLocation()
  const allCauses = useSelector(selectFlatCauses)
  const allSkills = useSelector(selectSkills)

  // Initial query
  const [query, setQuery] = useState(buildQueryFromSearch(search, allCauses, allSkills))

  useEffect(() => {
    const updateQuery = () => {
      // Rebuild query
      const updatedQuery = buildQueryFromSearch(search, allCauses, allSkills)
      setQuery(updatedQuery)
    }

    // Call initially on mount
    updateQuery()
  }, [search, allCauses, allSkills])

  return query
}

const getUrlParamsArrayValue = <T>(parsedParams: ParsedQuery<string>, fieldName: string): T[] => {
  if (Array.isArray(parsedParams[fieldName])) {
    return parsedParams[fieldName] as unknown as T[]
  }
  return (parsedParams[fieldName] ? [parsedParams[fieldName]] : []) as unknown as T[]
}

// Helper function to build the query object from search params
const buildQueryFromSearch = (search: string, allCauses: CauseMap, allSkills: SkillMap) => {
  const parsedParams = parse(search)

  const searchTerm = getSearchTermFromParams(parsedParams)
  const location = getLocationFromParams(parsedParams)
  const coordinates = getCoordinatesFromParams(parsedParams)
  const activeTab = getActiveTabFromParams(parsedParams)
  const deedsOriginFromUrl = getDeedsOriginFromParams(parsedParams)
  const dateFromUrl = getDateFromParams(parsedParams)
  const radiusFromUrl = getRadiusFromParams(parsedParams)

  const causesFromUrl = getCausesFromParams(parsedParams)
  const currentActiveCauses = allCauses.filter((c) => causesFromUrl?.includes(c.name))

  const skillsFromUrl = getSkillsFromParams(parsedParams)
  const currentActiveSkills = allSkills.filter((s) => skillsFromUrl?.includes(s.code))

  const donateTypesFromUrl = getUrlParamsArrayValue<DonateTypes>(parsedParams, 'donateType')
  const volunteerTypesFromUrl = getUrlParamsArrayValue<VolunteerTypes>(parsedParams, 'volunteerType')
  const attendanceOptionsFromUrl = getUrlParamsArrayValue<AttendanceOptions>(parsedParams, 'attendanceOption')
  const daysFromUrl = getUrlParamsArrayValue<DayOptions>(parsedParams, 'day')
  const timesFromUrl = getUrlParamsArrayValue<TimeOptions>(parsedParams, 'time')

  return {
    activeTab,
    searchTerm,
    location,
    coordinates,
    donateTypes: donateTypesFromUrl,
    volunteerTypes: volunteerTypesFromUrl,
    radius: radiusFromUrl,
    deedsOrigin: deedsOriginFromUrl,
    attendanceOptions: attendanceOptionsFromUrl,
    date: dateFromUrl,
    days: daysFromUrl,
    times: timesFromUrl,
    currentActiveCauses,
    currentActiveSkills,
    categories: getCauseIdsByType(currentActiveCauses, CauseTypes.Category),
    ergs: getCauseIdsByType(currentActiveCauses, CauseTypes.ERG),
    pillars: getCauseIdsByType(currentActiveCauses, CauseTypes.Pillar),
    sdgs: getCauseIdsByType(currentActiveCauses, CauseTypes.SDG),
    filtersCount: [
      location,
      dateFromUrl,
      radiusFromUrl,
      ...(daysFromUrl || []),
      ...(timesFromUrl || []),
      ...(donateTypesFromUrl || []),
      ...(volunteerTypesFromUrl || []),
      ...(attendanceOptionsFromUrl || []),
      ...(currentActiveCauses || []),
      ...(currentActiveSkills || []),
    ].filter(Boolean).length,
  }
}
