import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import IconButton from 'src/retired/shared/IconButton'
import Button from 'src/retired/shared/Button'
import { Modal, ModalProps, Row } from 'src/retired/elements'

interface ConfirmationModalProps extends ModalProps {
  loading: boolean
  onConfirm: () => void
  children: JSX.Element
}

export const ConfirmationModal = ({ children, onClose, onConfirm, loading }: ConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal
      maskClosable
      transparent
      visible
      style={{ width: 550, maxWidth: '100%', borderRadius: 4 }}
      bodyStyle={{ borderRadius: 4 }}
      onClose={onClose}
    >
      {children}
      <Row style={{ marginTop: 20, paddingLeft: 25, paddingRight: 25, justifyContent: 'center', gap: 20 }}>
        <Button color="transparent" onPress={onClose}>
          {t`common:Cancel`}
        </Button>
        <Button loading={loading} disabled={loading} palette="primary" onPress={onConfirm}>
          {t`common:confirm`}
        </Button>
      </Row>
      <View style={{ position: 'absolute', top: 20, left: 20 }}>
        <IconButton icon="closeNew" onPress={onClose} size={30} />
      </View>
    </Modal>
  )
}
