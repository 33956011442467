import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { ActivityIndicator, Screen, Text, WebView } from 'src/retired/elements'
import { Platform } from 'src/utils'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { PageTitle } from 'src/components'

const pages = {
  '/terms': {
    title: i18n.t('webScreen:termsOfUse'),
    uri: 'https://www.joindeed.com/terms',
    removeHeader: true,
  },
  '/privacy': {
    title: i18n.t('webScreen:privacyPolicy'),
    uri: 'https://www.joindeed.com/privacy-policy',
    removeHeader: true,
  },
  '/cancellation-policy': {
    title: i18n.t('webScreen:cancellationPolicy'),
    uri: 'https://www.joindeed.com/cancellation-policy',
    removeHeader: true,
  },
  '/help-us-launch-near-you': {
    title: i18n.t('webScreen:helpUsLaunchNearYou'),
    uri: 'https://deed.typeform.com/to/bVKUj4',
  },
}

export const Web = ({ location }) => {
  const { t } = useTranslation('webScreen')
  const { colors, metrics } = useDeedTheme()

  const { title, uri, removeHeader } = pages[location.pathname]
  return (
    <Screen fixed>
      <PageTitle title={title} />
      <NavigationHeader title={title} gradient />
      <WebView
        contentInset={removeHeader && { top: -70 }}
        style={{
          flex: 1,
          width: Platform.OS === 'web' ? '100%' : metrics.screenWidth,
          marginTop: removeHeader && Platform.OS === 'web' ? -70 : 0,
          minHeight: Platform.OS === 'web' ? '75vh' : 0,
        }}
        source={{ uri }}
        renderLoading={() => (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <ActivityIndicator color={colors.userOrganizationBrandColor || colors.coral} size="large" />
          </View>
        )}
        startInLoadingState
        renderError={() => <Text>{t`pageCouldNotBeLoaded`}</Text>}
      />
    </Screen>
  )
}

export default Web
