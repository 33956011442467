import { createBreakpoints } from '@mui/system'

import metrics from 'src/theme/metrics'

export const breakpoints = createBreakpoints({
  values: {
    xs: 0,

    // we don't really have the distinction between xs and sm in our legacy breakpoints, so we can start from sm and not xs.
    // putting 1 not 0, just to prevent potential bugs different breakpoints have the same value
    sm: 1,

    md: metrics.maxSmall,
    lg: metrics.maxMedium,
    xl: metrics.XLScreen,
  },
})
