import { Observable } from 'rxjs'
import 'rxjs/add/operator/delay'

import { SHOW_MESSAGE, SHOW_ERROR } from './constants'
import { closeAction } from './actions'

const close = (action$) =>
  action$.ofType(SHOW_MESSAGE, SHOW_ERROR).mergeMap(() => Observable.of(closeAction()).delay(1000))

export default [close]
