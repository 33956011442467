import React, { useState } from 'react'
import { View } from 'react-primitives'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { TextField, Spacing, ActivityIndicator } from 'src/retired/elements'
import User from 'src/entities/user/model'
import { Body, Label } from 'src/retired/shared/Typography'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'

import * as actions from '../actions'
import { DonateButton } from '..'

import { Donor } from './DonorDetailsForm'
import { BillingAddressForm, BillingAddress, isBillingAddressAdded } from './BillingAddressForm'

const BASE_SPACING = 36

interface CardErrors {
  name?: string
  card?: string
  address?: string
}

interface StripeFormProps {
  createIntent: ({
    billingAddress,
  }: {
    billingAddress?: BillingAddress
  }) => Promise<{ intentSecret: string; intentId: string }>
  registerDonation: (intentId: string) => void
  donating: boolean
  donor?: Donor
  user?: User
  amountValid: boolean
  donorPrivacy: DonorPrivacy
  userLastName?: string
  userFirstName?: string
  setCardCountryCode: (countryCode: string) => void
}

const StripeForm = ({
  createIntent,
  registerDonation,
  donor,
  user,
  donating,
  amountValid,
  userLastName,
  userFirstName,
  setCardCountryCode,
  donorPrivacy,
}: StripeFormProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  const stripe = useStripe()
  const elements = useElements()
  const [cardElementReady, setCardElementReady] = useState(false)
  const [firstName, setFirstName] = useState(userFirstName)
  const [lastName, setLastName] = useState(userLastName)
  const [cardFilled, setCardFilled] = useState(false)
  const dispatch = useDispatch()
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    street1: '',
    city: '',
    country: 'US',
    postalCode: '',
  })
  const [touched, setTouched] = useState(false)

  const [cardErrors, setCardErrors] = useState<CardErrors>({})

  const displayBillingAddressForm = donorPrivacy !== 'Anonymous'
  const billingAddressError = displayBillingAddressForm && !isBillingAddressAdded(billingAddress)

  return (
    <PaymentDetailsContainer>
      <Label colour={colors.grayMediumDark} style={{ marginLeft: 20 }}>
        {t`cardholderName`}
      </Label>

      <Spacing marginBottom={BASE_SPACING / 3} />

      <CardNameContainer>
        <CardNameFieldContainer>
          <TextField
            value={firstName}
            onChangeText={(_: string, value: string) => setFirstName(value)}
            placeholder={t`firstName*`}
            name="firstName"
            maxLength={50}
            autoCapitalize="none"
            style={{
              fontSize: 12,
            }}
            autoComplete="new-first-name"
          />
        </CardNameFieldContainer>

        <CardNameFieldContainer>
          <TextField
            placeholder={t`lastName*`}
            value={lastName}
            onChangeText={(_: string, value: string) => setLastName(value)}
            name="lastName"
            maxLength={50}
            style={{
              fontSize: 12,
            }}
            autoComplete="new-last-name"
          />
        </CardNameFieldContainer>
      </CardNameContainer>

      {touched && (!firstName || !lastName) && (
        <View>
          <Label colour={colors.redDark} lineHeight={21} style={{ marginLeft: 20 }}>
            {t`required`}
          </Label>
        </View>
      )}

      <Spacing marginBottom={BASE_SPACING / 2} />

      {displayBillingAddressForm && (
        <>
          <BillingAddressForm billingAddress={billingAddress} setBillingAddress={setBillingAddress} touched={touched} />

          <Spacing marginBottom={BASE_SPACING / 2} />
        </>
      )}

      <Label colour={colors.grayMediumDark} style={{ marginLeft: 20 }}>
        {t`creditOrDebitCard`}
      </Label>

      <Spacing marginBottom={BASE_SPACING / 3} />

      <CardNumberInputContainer>
        {!cardElementReady && <ActivityIndicator color="#b6bebe" />}
        <View style={{ display: cardElementReady ? 'block' : 'none' }}>
          <CardElement
            onReady={() => setCardElementReady(true)}
            onChange={(ev) => {
              if (ev.complete) {
                const card = elements?.getElement(CardElement)
                if (card) {
                  setCardFilled(true)
                  stripe
                    ?.createPaymentMethod({
                      type: 'card',
                      card,
                    })
                    .then((response) => {
                      const countryCode = response?.paymentMethod?.card?.country
                      if (countryCode) {
                        setCardCountryCode(countryCode)
                      }
                    })
                }
              } else {
                setCardFilled(false)
              }
              setCardErrors((newCardErrors: CardErrors) => {
                const { card, ...otherErrors } = newCardErrors
                if (ev.error?.message) {
                  return {
                    ...otherErrors,
                    card: ev.error?.message,
                  }
                }
                return otherErrors
              })
            }}
          />
        </View>
      </CardNumberInputContainer>

      {cardErrors?.card && (
        <>
          <Spacing marginBottom={BASE_SPACING / 4} />
          <View>
            <Label colour={colors.redDark} lineHeight={21} style={{ marginLeft: 20 }}>
              {cardErrors.card}
            </Label>
          </View>
        </>
      )}

      {touched && !cardFilled && (
        <>
          <Spacing marginBottom={BASE_SPACING / 4} />
          <View>
            <Body colour="redDark" lineHeight={21} style={{ marginLeft: 20 }}>
              {t`emptyCardNumber`}
            </Body>
          </View>
        </>
      )}

      <Spacing marginBottom={BASE_SPACING * 1} />

      <DonateButton
        color="primary"
        disabled={!amountValid || !(user || donor)}
        size="small"
        fluid
        onPress={async (ev: Event) => {
          if (!donating) {
            ev.preventDefault()

            setTouched(true)
            setCardErrors({})

            if (billingAddressError || Object.keys(cardErrors).length > 0 || !cardFilled) {
              return
            }

            const card = elements?.getElement(CardElement)

            if (card) {
              dispatch(actions.setDonatingAction())
              try {
                const { intentSecret, intentId } = await createIntent({
                  billingAddress: displayBillingAddressForm ? billingAddress : undefined,
                })

                if (!intentId) {
                  return dispatch(
                    actions.donateFailedAction({
                      error: {
                        message: t`unableToCreateAStripePayment`,
                      },
                    })
                  )
                }
                const result = await stripe?.confirmCardPayment(intentSecret, {
                  payment_method: {
                    card,
                    billing_details: {
                      name: `${firstName} ${lastName}`,
                    },
                  },
                })
                if (result?.paymentIntent) {
                  registerDonation(intentId)
                } else {
                  return dispatch(
                    actions.donateFailedAction({
                      error: result?.error || {
                        message: t`unableToConfirmTheStripePayment`,
                      },
                    })
                  )
                }
              } catch (e) {
                dispatch(
                  actions.donateFailedAction({
                    error: e,
                  })
                )
              }
            }
          }
        }}
      >
        {donating ? <ActivityIndicator color={colors.white} /> : t`completeDonation`}
      </DonateButton>
    </PaymentDetailsContainer>
  )
}

const PaymentDetailsContainer = styled(View)`
  max-width: 500px;
`

const CardNameContainer = styled(View)<object, EmotionTheme>`
  flex-direction: ${({ theme }) => (theme.metrics.isSmall ? 'column' : 'row')};
  justify-content: space-between;
`

const CardNameFieldContainer = styled(View)<object, EmotionTheme>`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin-bottom: 9px;
  font-size: 12px;
  padding-horizontal: 20px;
  padding-vertical: 7px;
  width: ${({ theme }) => (theme.metrics.isSmall ? '100%' : '45%')};
`

const CardNumberInputContainer = styled(View)`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  font-size: 12px;
  padding-horizontal: 20px;
  padding-vertical: 12px;
`

export default StripeForm
