import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { injectReducer, injectEpics } from 'src/utils'
import { Screen, Loading, Text, Tabs, DashedContainer, ScrollView } from 'src/retired/elements'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import Deed from 'src/retired/blocks/Deed'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { styled } from 'src/theme/styled'

import { selectLoading, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const DeedsWrapper = styled.View`
  padding-top: 15;
  margin-bottom: -15;
`

export class OrganizationDeeds extends Component {
  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.organization)
  }

  render() {
    const { t, organization, error, loading } = this.props
    const eventTypes = ['Event', 'BaseEvent']
    if (error) {
      return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
    }
    if (loading || !organization) {
      return <Loading />
    }

    const now = new Date()
    const upcomingDeeds = organization.deeds
      .filter(
        (deed) => !deed.startingAt || new Date(eventTypes.includes(deed.type) ? deed.startingAt : deed.endingAt) > now
      )
      .sort((a, b) => (a.startingAt < b.startingAt ? -1 : 1))

    const pastDeeds = organization.deeds
      .filter(
        (deed) => deed.startingAt && new Date(eventTypes.includes(deed.type) ? deed.startingAt : deed.endingAt) < now
      )
      .sort((a, b) => (a.startingAt > b.startingAt ? -1 : 1))

    return (
      <Screen>
        <ScrollView>
          <NavigationHeader
            backTo={`/organization/${organization.id}`}
            title={t('deedsFor', { organizationName: organization.name })}
            transparent
          />
          <Tabs>
            <Tabs.Tab title={t`upcoming`}>
              {upcomingDeeds.length > 0 ? (
                <DeedsWrapper>
                  {upcomingDeeds.map((deed) => (
                    <Deed key={deed.id} deed={deed} />
                  ))}
                </DeedsWrapper>
              ) : (
                <DashedContainer>
                  <Text medium size={13}>
                    {t`noUpcomingDeeds`}
                  </Text>
                </DashedContainer>
              )}
            </Tabs.Tab>
            <Tabs.Tab title={t`past`}>
              {pastDeeds.length > 0 ? (
                <DeedsWrapper>
                  {pastDeeds.map((deed) => (
                    <Deed key={deed.id} deed={deed} />
                  ))}
                </DeedsWrapper>
              ) : (
                <DashedContainer>
                  <Text medium size={13}>
                    {t`noPastDeeds`}
                  </Text>
                </DashedContainer>
              )}
            </Tabs.Tab>
          </Tabs>
        </ScrollView>
      </Screen>
    )
  }
}

const withConnect = connect(
  (
    state,
    {
      match: {
        params: { organization = '' },
      },
    }
  ) => ({
    organization: selectOrganizationById(state, organization),
    user: selectCurrentUser(state),
    loading: selectLoading(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'organizationDeeds', reducer })
const withEpics = injectEpics({ key: 'organizationDeeds', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('organizationScreen'))(OrganizationDeeds)
