import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H3, Body1 } from 'src/retired/shared/Typography'
import { AvatarRow, AvatarType } from 'src/containers/modules/AvatarRow'
import Organization from 'src/entities/organization/model'

import { CampaignStatsCard } from './CampaignStatsCard'

const PrimaryElement = ({ nonprofitLength }: { nonprofitLength: number }) => {
  const { t } = useTranslation('campaignScreen')
  const { metrics, colors } = useDeedTheme()

  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
      <H3>{nonprofitLength}</H3>
      <View
        style={{
          display: 'flex',
          flexDirection: metrics.isSmall ? 'column' : 'row',
          paddingBottom: metrics.isSmall ? 0 : 5,
          paddingLeft: 7,
        }}
      >
        <Body1
          style={{
            fontSize: 16,
            color: colors.gray08,
          }}
        >
          {t('campaignSupported')}
        </Body1>
        <Body1
          style={{
            fontSize: 16,
            color: colors.gray08,
            paddingLeft: metrics.isSmall ? 0 : 3,
          }}
        >
          {t('campaignOrganizations', { count: nonprofitLength })}
        </Body1>
      </View>
    </View>
  )
}

export const NonprofitsCard = ({ nonprofits }: { nonprofits: Organization[] }) => {
  const { metrics } = useDeedTheme()

  return nonprofits?.length > 0 ? (
    <CampaignStatsCard
      primaryElement={<PrimaryElement nonprofitLength={nonprofits.length} />}
      secondaryElement={
        <AvatarRow
          items={nonprofits.map((nonprofit) => ({
            mainPicture: nonprofit.mainPicture,
            id: nonprofit.id,
          }))}
          maxImageItems={metrics.isSmall ? 1 : 2}
          withParticipantExtension={false}
          type={AvatarType.organization}
        />
      }
    />
  ) : null
}
