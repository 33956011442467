import { createSelector } from 'reselect'

import type { State } from 'src/reducers'

import type { DonateState } from './reducer'
import { initialState, ProviderDataMap } from './reducer'

const selectState = (state: State): DonateState => state.get('donate') || initialState

export const selectLoading = createSelector(selectState, (state) => state.get('loading'))

export const selectLoadingError = createSelector(selectState, (state) => state.get('loadingError'))

export const selectDonationError = createSelector(selectState, (state) => state.get('donationError'))

export const selectDonating = createSelector(selectState, (state) => state.get('donating'))

export const selectProviderLoading = createSelector(selectState, (state) => state.get('donationProviderLoading'))

export const selectProviderData = createSelector(selectState, (state) => state.get('providerData'))

export const selectProviderDataByIdentifier = createSelector(
  selectProviderData,
  (_state: State, identifier: string) => identifier,
  (providerData: ProviderDataMap, identifier) => providerData.get(identifier)
)

export const selectLastDonation = createSelector(selectState, (state) => state.get('lastDonation'))

export const selectDataClientToken = createSelector(selectState, (state) => state.get('dataClientToken'))

export const selectPaymentToken = createSelector(selectState, (state) => state.get('paymentToken'))

export const selectDataClientTokenLoading = createSelector(selectState, (state) => state.get('dataClientTokenLoading'))

export const selectDataClientTokenError = createSelector(selectState, (state) => state.get('dataClientTokenError'))
