import _ from 'lodash'
import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import Alert from 'src/retired/elements/Alert'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { updateAction, addAction } from 'src/entities/deed/actions'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectVolunteerTimesForDeedLoaded } from 'src/entities/volunteerTime/selectors'
import { volunteerTimesForDeedLoadedAction } from 'src/entities/volunteerTime/actions'
import DeedsApi from 'src/entities/deed/api'
import VolunteerTimeApi from 'src/entities/volunteerTime/api'
import { ADD as ADD_USER } from 'src/entities/user/constants'
import { Platform } from 'src/utils'
import Deed from 'src/entities/deed/model'

import {
  fetchDeedSuccessAction,
  fetchDeedFailedAction,
  loadVolunteerTimesSuccessAction,
  loadVolunteerTimesFailedAction,
  refreshSuccessAction,
  refreshFailedAction,
  applyForRoleSuccessAction,
  applyForRoleFailedAction,
  completeDeedSuccessAction,
  completeDeedFailedAction,
  loadVolunteerTimesAction,
} from './actions'
import getExternalLinkAlerts from './getExternalLinkAlerts'
import {
  FETCH_DEED,
  REFRESH,
  LOAD_VOLUNTEER_TIMES,
  APPLY_FOR_ROLE,
  COMPLETE_DEED,
  APPLY_TO_EXTERNAL_DEED,
  FETCH_DEED_SUCCESS,
} from './constants'

function loadDeedWithExternalLinkFollows(id) {
  const deedInfoObservable = Observable.from(DeedsApi.fetchInfo(id))
  const deedExternalLinkFollowsObservable = Observable.from(DeedsApi.fetchExternalLinkFollows(id))

  // Start both observables but delay processing
  const combinedObservables = Observable.forkJoin(deedInfoObservable, deedExternalLinkFollowsObservable)

  return Observable.merge(
    // Combined response from deedInfo and deedExternalLinkFollows are processed
    combinedObservables.map(([deedInfoResp, deedExternalLinkFollowsResp]) => [
      addAction(
        new Deed({
          ...deedInfoResp,
          ...deedExternalLinkFollowsResp,
        })
      ),
    ])
  )
}

const fetchDeed = (action$, store) =>
  action$.ofType(FETCH_DEED).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const deed = selectDeedById(state, id)
    if (!deed) {
      actions.push(loadDeedWithExternalLinkFollows(id))
    } else if (deed && deed.type === 'Project' && deed.externalLink && deed.externalLinkFollowsCount === 0) {
      // we are proactive about loading the externalLinkFollows data,
      // since when displaying a list of deeds we don't have the externalLinkFollows data in the response,
      // and the deed listing response overwrites the externalLinkFollows data for each deed, sets to empty even if exists
      // so we need to refetch the externalLinkFollows data
      actions.push(
        DeedsApi.fetchExternalLinkFollows(id).map((deedExternalLinkFollowsResp) => {
          if (!deedExternalLinkFollowsResp) {
            return
          }
          const updatedDeed = deed
            .set('externalLinkFollows', deedExternalLinkFollowsResp.externalLinkFollows)
            .set('externalLinkFollowsCount', deedExternalLinkFollowsResp.externalLinkFollowsCount)
          return [updateAction(updatedDeed, ['externalLinkFollows', 'externalLinkFollowsCount'])]
        })
      )
    }

    if (actions.length === 0) {
      return Observable.of(fetchDeedSuccessAction(id))
    }

    if (Platform.OS === 'web' && window.location.hash) {
      const externalLinkMessage = getExternalLinkAlerts(window.location.hash)
      if (externalLinkMessage) {
        setTimeout(() => {
          if (externalLinkMessage) {
            Alert.alert(externalLinkMessage.title, externalLinkMessage.message)
            // eslint-disable-next-line no-restricted-globals
            history.pushState(null, null, location.pathname + location.search)
          }
        }, 3000)
      }
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flattenDeep(resultingActions), fetchDeedSuccessAction(id)])
      .catch((e) =>
        e.response?.status === 401
          ? Observable.of(replace(`/deeds/${id}/authenticate/restricted`))
          : Observable.of(fetchDeedFailedAction(e))
      )
  })

let latestDeedId
const triggerLoadVolunteerTimesIfNecessary = (action$, store) =>
  action$.ofType(FETCH_DEED_SUCCESS, ADD_USER).mergeMap((payload) => {
    if (payload.type === ADD_USER && !payload.user.me) {
      return []
    }
    if (payload.type === FETCH_DEED_SUCCESS) {
      latestDeedId = payload.id
    }
    const state = store.getState()
    const deed = selectDeedById(state, latestDeedId)
    const user = selectCurrentUser(state)
    if (!user || !deed) {
      return []
    }
    if (deed.canUserLogHours(user)) {
      return [loadVolunteerTimesAction(deed.id)]
    }
    return []
  })

const loadVolunteerTimes = (action$, store) =>
  action$.ofType(LOAD_VOLUNTEER_TIMES).exhaustMap(({ deedId }) => {
    const state = store.getState()
    const volunteerTimesForDeedLoaded = selectVolunteerTimesForDeedLoaded(state, deedId)

    if (!volunteerTimesForDeedLoaded) {
      return VolunteerTimeApi.getByFilter({ userId: { equals: 'me' }, deedId: { equals: deedId } })
        .mergeMap((resultingActions) => [
          resultingActions,
          volunteerTimesForDeedLoadedAction(deedId),
          loadVolunteerTimesSuccessAction(),
        ])
        .catch((e) => {
          // In case of not authorized VT request we just silently ignore it
          // We might end up in this state if we have the token, but it expired
          if (e.message === 'Not Authorized!') {
            return []
          }
          // In case of other kind of failures, log it
          return Observable.of(loadVolunteerTimesFailedAction(e))
        })
    }
    return Observable.of(loadVolunteerTimesSuccessAction())
  })

const refresh = (action$) =>
  action$.ofType(REFRESH).exhaustMap(() =>
    DeedsApi.fetchFeed()
      .mergeMap((resultingActions) => [...resultingActions, refreshSuccessAction()])
      .catch((e) => Observable.of(refreshFailedAction(e)))
  )

const applyForRole = (action$) =>
  action$.ofType(APPLY_FOR_ROLE).mergeMap(({ deedId, roleId, userId }) =>
    DeedsApi.applyForRole(deedId, roleId, userId)
      .mergeMap((resultingActions) => [...resultingActions, applyForRoleSuccessAction()])
      .catch((e) => Observable.of(applyForRoleFailedAction(e)))
  )

const completeDeed = (action$) =>
  action$.ofType(COMPLETE_DEED).mergeMap(({ id }) =>
    DeedsApi.complete(id)
      .mergeMap((resultingAction) => [resultingAction, completeDeedSuccessAction()])
      .catch((e) =>
        Observable.of(completeDeedFailedAction(e), showErrorAction(i18n.t('deedScreen:completingDeedFailed')))
      )
  )

const applyToExternalDeed = (action$, store) =>
  action$.ofType(APPLY_TO_EXTERNAL_DEED).mergeMap(({ id }) => {
    const state = store.getState()
    const deed = selectDeedById(state, id)
    const user = selectCurrentUser(state)
    const externalLinkFollows = deed.get('externalLinkFollows')
    externalLinkFollows.push({ user: user.id, date: new Date() })
    const updatedDeed = deed.set('externalLinkFollows', externalLinkFollows)
    return [updateAction(updatedDeed, ['externalLinkFollows']), replace(`/deeds/${id}`)]
  })

export default [
  fetchDeed,
  triggerLoadVolunteerTimesIfNecessary,
  loadVolunteerTimes,
  refresh,
  applyForRole,
  completeDeed,
  applyToExternalDeed,
]
