import { ADD, ADD_MULTIPLE } from './constants'
import VolunteerTimeOff from './model'
import { VolunteerTimeOffMap } from './reducer'

export const addAction = (volunteerTimeOff: VolunteerTimeOff) => ({
  type: ADD,
  volunteerTimeOff,
})

export const addMultipleAction = (volunteerTimesOff: VolunteerTimeOffMap) => ({
  type: ADD_MULTIPLE,
  volunteerTimesOff,
})
