import React from 'react'
import { View } from 'react-primitives'

import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'
import { Action } from 'src/containers/modules/Actions/types'
import { ActionCard } from 'src/containers/modules/Actions/Card/ActionCard'
import List from 'src/retired/blocks/List'

export const CardsWrapper = styled.View`
  display: flex;
  padding-bottom: 30px;
  flex-wrap: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'wrap' : 'nowrap')};
  flex-direction: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'row' : 'column')};
  justify-content: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? 'flex-start' : 'center')};
`

export const ActionsList = ({ actions }: { actions?: Action[] }) => {
  const { metrics } = useDeedTheme()

  if (!actions?.length) {
    return null
  }

  const getCardWidth = () => {
    if (metrics.isSmall) {
      return '100%'
    }

    if (metrics.isMedium) {
      return '50%'
    }

    return 'auto'
  }

  return (
    <CardsWrapper>
      <List
        data={actions}
        keyExtractor={(item) => item.id}
        itemProps={{
          style: {
            width: getCardWidth(),
          },
        }}
        renderItem={({ item }) => (
          <View
            style={{
              padding: metrics.isLarge ? 7 : 10,
              paddingBottom: 15,
              height: '100%',
            }}
          >
            <ActionCard action={item} />
          </View>
        )}
      />
    </CardsWrapper>
  )
}
