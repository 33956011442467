import { fromJS } from 'immutable'
import type { AnyAction } from 'redux'

import type { TypedMap } from 'src/utils/typed-map'

import { SEARCH_NONPROFITS, SEARCH_SUCCESS, SEARCH_FAILED } from './constants'

export type NonprofitsSearchState = TypedMap<{
  searching: boolean
  searchError: null | unknown
  searchSuccess: boolean
}>

export const initialState: NonprofitsSearchState = fromJS({
  searching: false,
  searchError: null,
  searchSuccess: false,
})

export default (state: NonprofitsSearchState = initialState, action: AnyAction): NonprofitsSearchState => {
  switch (action.type) {
    case SEARCH_NONPROFITS:
      return state.merge({ searching: true, searchError: null, searchSuccess: false })

    case SEARCH_SUCCESS:
      return state.merge({ searching: false, searchSuccess: action.value })

    case SEARCH_FAILED:
      return state.merge({ searching: false, searchError: action.error })

    default:
      return state
  }
}
