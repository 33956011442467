import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { BadgeSubsetFragment } from 'src/generated/graphql'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link } from 'src/navigation'

import { BADGE_SIZE_BIG, BADGE_SIZE_SMALL, BadgeImage } from './BadgeImage'

interface BadgeAndTitleProps {
  badge: BadgeSubsetFragment
  hideBadgeTitle?: boolean
  showEarnedSubtitle: boolean
  imageWidth?: number
  bigTitle?: boolean
  maxWidth?: string | number
  subtitleStyle?: React.CSSProperties
}

const BadgeAndTitle: React.FC<BadgeAndTitleProps> = ({
  badge,
  hideBadgeTitle,
  showEarnedSubtitle,
  imageWidth,
  bigTitle,
  maxWidth,
  subtitleStyle,
}) => {
  const { t } = useTranslation('badges')
  const earnedAt = badge.awardedBadge?.earnedAt

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth,
      }}
    >
      <BadgeImage dimension={imageWidth} imageURL={badge.imageURL} isEarned={!!earnedAt} />
      {showEarnedSubtitle && (
        <Body2 style={{ fontSize: 12, paddingTop: 12, textAlign: 'center', ...subtitleStyle }}>
          {t('obtained', { at: moment(earnedAt).fromNow() })}
        </Body2>
      )}
      {hideBadgeTitle ? null : (
        <Body2 style={{ fontSize: bigTitle ? 20 : 12, paddingTop: 12, textAlign: 'center' }}>{badge.name}</Body2>
      )}
    </View>
  )
}

export const BadgeWithTitle = ({
  badge,
  hideBadgeTitle = false,
  withEarnedSubtitle,
  bigTitle,
  maxWidth,
  withoutLink,
  imageWidth,
  subtitleStyle,
}: {
  badge: BadgeSubsetFragment
  hideBadgeTitle?: boolean
  withEarnedSubtitle?: boolean
  bigTitle?: boolean
  maxWidth?: string | number
  withoutLink?: boolean
  imageWidth?: number
  subtitleStyle?: React.CSSProperties
}) => {
  const { metrics } = useDeedTheme()

  const showEarnedSubtitle = withEarnedSubtitle && badge.awardedBadge?.earnedAt
  const defaultMaxWidth = metrics.isSmall ? BADGE_SIZE_SMALL : BADGE_SIZE_BIG

  return !withoutLink ? (
    <Link to={`/profile/badge/${badge.id}`}>
      <BadgeAndTitle
        badge={badge}
        hideBadgeTitle={hideBadgeTitle}
        showEarnedSubtitle={showEarnedSubtitle}
        imageWidth={imageWidth}
        bigTitle={bigTitle}
        maxWidth={maxWidth || defaultMaxWidth}
        subtitleStyle={subtitleStyle}
      />
    </Link>
  ) : (
    <BadgeAndTitle
      badge={badge}
      hideBadgeTitle={hideBadgeTitle}
      showEarnedSubtitle={showEarnedSubtitle}
      imageWidth={imageWidth}
      bigTitle={bigTitle}
      maxWidth={maxWidth || defaultMaxWidth}
      subtitleStyle={subtitleStyle}
    />
  )
}
