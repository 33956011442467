import { createSelector } from 'reselect'

import Form from './model'
import { FormMap } from './reducer'

export const selectFormState = (state: any): FormMap => state.get('entities').form

export const selectFormById = createSelector<any, string, FormMap, string, Form>(
  selectFormState,
  (state: any, formId: string): string => formId,
  (forms, formId) => forms.get(formId)
)
