import { Record } from 'immutable'
import _ from 'lodash'

const properties = {
  type: '',
  coordinates: [],
}

export default class LocationLatLng extends Record(properties, 'LocationLatLng') implements LocationLatLng {
  public readonly type!: string

  public readonly coordinates!: [number, number]

  constructor(values = {}) {
    super(_.pick(values, Object.keys(properties)))
  }
}
