import React from 'react'

import CardList from 'src/retired/shared/CardList'
import CauseCard from 'src/retired/shared/CauseCard'
import Cause from 'src/entities/cause/model'

interface CauseCardListProps {
  causes: Cause[]
  scrollViewOffset: number
  onPress?: (cause: Cause) => void
  small?: boolean
}
export type CauseCardListElement = React.ReactElement<CauseCardListProps>

const CauseCardList: React.FC<CauseCardListProps> = ({
  causes,
  scrollViewOffset,
  onPress,
  small = false,
}): CauseCardListElement => (
  <CardList scrollViewOffset={scrollViewOffset} small={small}>
    {causes.map((cause: Cause) => (
      <CauseCard
        key={cause.get('id')}
        cause={cause}
        onPress={onPress ? () => onPress(cause) : undefined}
        small={small}
      />
    ))}
  </CardList>
)

export default CauseCardList
