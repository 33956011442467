import Api from '../api'

import { storeAction, clearAction } from './actions'

export default class AuthApi {
  static authLocal(email: string, password: string) {
    return Api.post('auth/local', { email, password }).map((response) => storeAction(response.token))
  }

  static authApple(identityToken: string, fullName: string) {
    return Api.post('auth/apple', { identityToken, fullName }).map((response) =>
      storeAction(response.token, response.isNew)
    )
  }

  static logoutLocal() {
    return Api.get('auth/local/logout').map(() => clearAction())
  }
}
