import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import { withTheme } from '@emotion/react'
import { withTranslation } from 'react-i18next'
import { format } from 'date-fns'

import Header from 'src/retired/blocks/Header'
import { Text, Touchable, Spacing } from 'src/retired/elements'
import ScrollView from 'src/retired/elements/ScrollView'
import { Platform } from 'src/utils'

class FeedCalendar extends PureComponent {
  static propTypes = {
    deeds: PropTypes.object.isRequired,
    activeDate: PropTypes.string,
    scrollListIntoView: PropTypes.func.isRequired,
    small: PropTypes.bool,
  }

  calendarRef = React.createRef()

  //
  // Scrolls given date item into view
  //
  // eslint-disable-next-line react/no-unused-class-component-methods
  scrollCalendarIntoView = (date) => {
    const days = this.getDays()
    const indexOfDate = Object.keys(days).findIndex((i) => i === date)

    if (indexOfDate !== -1) {
      const now = new Date()
      const dateObj = new Date(date)
      const differenceInMonth = dateObj.getMonth() - now.getMonth()
      const itemWidth = 40
      // for every month there's an extra offset for month's label
      let scrollOffset = itemWidth * (indexOfDate + differenceInMonth)
      if (Platform.OS === 'ios') {
        const maxScrollOffset = (Object.keys(days).length + 1) * itemWidth - this.props.theme.metrics.screenWidth
        if (scrollOffset > maxScrollOffset) {
          scrollOffset = maxScrollOffset
        }
      }
      if (this.calendarRef.current) {
        this.calendarRef.current.scrollTo({
          x: scrollOffset,
          y: 0,
          animated: true,
        })
      }
    }
  }

  //
  // Generates dates object for calendar of shape:
  // { '2019-01-01': numberOfDeedsForDate }
  //
  getDays() {
    const { deeds } = this.props
    const deedsCount = deeds.reduce((result, deed) => {
      const key = format(deed.startingAt, 'yyyy-MM-dd', { timeZone: deed.timeZone })
      // eslint-disable-next-line no-param-reassign
      result[key] = result[key] ? result[key] + 1 : 1
      return result
    }, {})
    let lastDate = deeds.last() && deeds.last().startingAt

    const inOneMonth = new Date()
    inOneMonth.setMonth(inOneMonth.getMonth() + 1)
    if (!lastDate || lastDate < inOneMonth) {
      lastDate = inOneMonth
    }
    const nextDate = new Date(lastDate.getTime())
    nextDate.setDate(nextDate.getDate() + 1)
    const lastDateString = format(nextDate, 'yyyy-MM-dd')
    const days = {}

    for (const d = new Date(); format(d, 'yyyy-MM-dd') !== lastDateString; d.setDate(d.getDate() + 1)) {
      // eslint-disable-line no-unmodified-loop-condition
      const key = format(d, 'yyyy-MM-dd')
      days[key] = deedsCount[key] || 0
    }
    return days
  }

  render() {
    const {
      t,
      activeDate,
      scrollListIntoView,
      small,
      theme: { colors, metrics },
    } = this.props
    const days = this.getDays()

    if (!days) {
      return null
    }

    const moveLeft = Platform.OS !== 'web' && metrics.screenWidth >= 1024

    return (
      <View
        style={{
          alignSelf: 'stretch',
          left: moveLeft ? -320 : 0,
          position: small ? 'relative' : 'fixed',
          marginTop: small ? 0 : -20,
          paddingTop: small ? 8 : 10,
          paddingBottom: 8,
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: '#EBEEF0',
          backgroundColor: colors.white,
        }}
      >
        <Header shadow={false}>
          <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal
            ref={this.calendarRef}
            style={{
              paddingLeft: 5,
              marginLeft: -20,
              marginRight: -20,
            }}
            contentContainerStyle={{
              paddingRight: Platform.OS === 'web' ? 5 : 10,
            }}
          >
            {Object.keys(days).map((day) => {
              const date = new Date(day)
              const hasDeeds = Boolean(days[day])
              const isActive = activeDate === day
              const isBeginingOfMonth = date.getDate() === 1

              return (
                <Fragment key={day}>
                  {isBeginingOfMonth && (
                    <View
                      style={{
                        position: 'relative',
                        backgroundColor: colors.white,
                        borderRadius: 10,
                        width: 44,
                        height: 62,
                        marginRight: 4,
                        marginLeft: 4,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          color: colors.black,
                          fontSize: 18,
                          lineHeight: 18,
                        }}
                        center
                      >
                        |
                      </Text>
                      <Spacing marginTop={5}>
                        <Text style={{ fontSize: 9 }} color={isActive ? colors.white : colors.lightGray}>
                          {t('date:monthShort', { date })}
                        </Text>
                      </Spacing>
                    </View>
                  )}
                  <Touchable onPress={() => hasDeeds && scrollListIntoView(day)} style={{ alignItems: 'center' }}>
                    <View
                      style={{
                        position: 'relative',
                        backgroundColor: isActive ? colors.brandColor : colors.white,
                        borderRadius: 10,
                        width: 44,
                        height: 58,
                        marginRight: 4,
                        marginLeft: 4,
                        paddingTop: 7,
                        paddingBottom: 6,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text style={{ fontSize: 12 }} lineHeight={12} color={isActive ? colors.white : colors.lightGray}>
                        {t('date:weekdayShort', { date }).toUpperCase()}
                      </Text>
                      <Spacing marginTop={8} marginBottom={2}>
                        <Text
                          style={{
                            color: isActive ? colors.white : colors.black,
                            fontSize: 14,
                            lineHeight: 14,
                          }}
                          center
                        >
                          {t('date:day', { date })}
                        </Text>
                      </Spacing>
                      {hasDeeds && (
                        <View
                          style={{
                            borderRadius: 2,
                            width: 4,
                            height: 4,
                            backgroundColor: isActive ? colors.white : colors.blue,
                          }}
                        />
                      )}
                    </View>
                  </Touchable>
                </Fragment>
              )
            })}
          </ScrollView>
        </Header>
      </View>
    )
  }
}

export default withTranslation()(withTheme(FeedCalendar))
