import { Observable } from 'rxjs'
import _ from 'lodash'

import { selectUserById } from 'src/entities/user/selectors'
import { selectUpcomingDeedsLoaded, selectPastDeedsLoaded } from 'src/entities/deed/selectors'
import UserApi from 'src/entities/user/api'
import DeedsApi from 'src/entities/deed/api'

import { initSuccessAction, initFailedAction } from './actions'
import { INIT } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap((payload) => {
    const actions = []
    const state = store.getState()

    if (payload.id) {
      const user = selectUserById(state, payload.id)
      if (!user) {
        actions.push(UserApi.fetch(payload.id))
      }
    }

    if (!selectUpcomingDeedsLoaded(state, payload.id)) {
      actions.push(DeedsApi.fetchUpcoming(payload.id))
    }

    if (!selectPastDeedsLoaded(state, payload.id)) {
      actions.push(DeedsApi.fetchPast(payload.id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init]
