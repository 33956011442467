import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Label } from 'src/retired/shared/Typography'
import SelectBox from 'src/retired/shared/SelectBox'
import { type MatchableRule } from 'src/entities/donation/matchableRule'

interface SelectionItem {
  value: string
  title: string
}

export const DesiredMatchingRuleBlock = ({
  matchableRules,
  desiredMatchingRule,
  onMatchingRuleOptionChange,
  disabled = false,
  disableText = '',
}: {
  matchableRules: Record<string, MatchableRule> | undefined
  desiredMatchingRule: string
  onMatchingRuleOptionChange: (matchingRuleId: string) => void
  disabled?: boolean
  disableText?: string
}) => {
  const { t } = useTranslation('donateScreen')
  const [matchingRuleOptions, setMatchingRuleOptions] = useState<SelectionItem[]>([])

  const getMatchingRatioForLabel = (item: MatchableRule): string => {
    const matchFraction = Number(item.matchingPercentage) / 100
    return `${matchFraction}X – ${String(item.title)}`
  }

  useEffect(() => {
    const options = Object.values(matchableRules || {}).map(
      (v): SelectionItem => ({
        value: v.id,
        title: getMatchingRatioForLabel(v),
      })
    )
    setMatchingRuleOptions(options)
  }, [matchableRules])

  if (!matchableRules || Object.keys(matchableRules).length < 2) {
    return null
  }

  return (
    <View style={{ flex: 1, marginBottom: 25 }}>
      <Label paddingBottom={8}>{t`desiredMatchingRule`}</Label>
      <SelectBox
        onFocus={() => {}}
        onSelect={(matchingRuleId: string) => onMatchingRuleOptionChange(matchingRuleId)}
        value={disabled ? disableText : desiredMatchingRule}
        options={matchingRuleOptions}
        disabled={disabled}
      />
    </View>
  )
}
