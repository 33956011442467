import { Observable } from 'rxjs'
import _ from 'lodash'

import { selectCauses } from 'src/entities/cause/selectors'
import CausesApi from 'src/entities/cause/api'
import OrganizationApi from 'src/entities/organization/api'
import DeedApi from 'src/entities/deed/api'
import { selectCurrentUser } from 'src/entities/user/selectors'

import {
  initSuccessAction,
  initFailedAction,
  searchDeedsSuccessAction,
  searchDeedsFailedAction,
  searchNonprofitsSuccessAction,
  searchNonprofitsFailedAction,
  searchProgressAction,
} from './actions'
import { INIT, SEARCH, SEARCH_NONPROFITS, SEARCH_DEEDS } from './constants'
import { selectSearchTerm, selectCountryCode, selectStateCode } from './selectors'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)
    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const searchNonprofits = (action$, store) =>
  action$
    .ofType(SEARCH, SEARCH_NONPROFITS)
    .debounceTime(500)
    .mergeMap(() => {
      const state = store.getState()

      const searchTerm = selectSearchTerm(state)
      const stateCode = selectStateCode(state)
      const countryCode = selectCountryCode(state)
      const user = selectCurrentUser(state)
      const searchNonprofitDB = user?.organization?.settings?.searchNonprofitDB

      if (searchTerm.length < 3) {
        return []
      }

      return OrganizationApi.searchNonprofits(
        { searchTerm, countryCode, stateCode, includeApproved: true },
        searchNonprofitDB
      )
        .takeUntil(action$.ofType(SEARCH, SEARCH_NONPROFITS))
        .mergeMap((resultingAction) => [resultingAction, searchProgressAction(), searchNonprofitsSuccessAction()])
        .catch((e) => Observable.of(searchNonprofitsFailedAction(e)))
    })
    .catch((e) => Observable.of(searchNonprofitsFailedAction(e)))

const searchDeeds = (action$, store) =>
  action$
    .ofType(SEARCH, SEARCH_DEEDS)
    .debounceTime(500)
    .mergeMap(({ searchTerm: actionSearchTerm }) => {
      const state = store.getState()
      const searchTerm = actionSearchTerm || selectSearchTerm(state)
      if (searchTerm.length < 3) {
        return []
      }
      return DeedApi.searchFeed(searchTerm)
        .takeUntil(action$.ofType(SEARCH, SEARCH_DEEDS))
        .mergeMap((resultingActions) => [...resultingActions, searchProgressAction(), searchDeedsSuccessAction()])
        .catch((e) => Observable.of(searchDeedsFailedAction(e), searchProgressAction()))
    })
    .catch((e) => Observable.of(searchDeedsFailedAction(e)))

// const searchExternalDeeds = (action$, store) =>
//   action$
//     .ofType(SEARCH)
//     .debounceTime(500)
//     .mergeMap(() => {
//       const state = store.getState()
//       const searchTerm = selectSearchTerm(state)
//       if (searchTerm.length < 3) {
//         return []
//       }
//       return DeedApi.searchExternalDeeds(searchTerm)
//         .takeUntil(action$.ofType(SEARCH))
//         .mergeMap((resultingActions) => [...resultingActions, searchProgressAction(), searchDeedsSuccessAction()])
//         .catch((e) => Observable.of(searchDeedsFailedAction(e), searchProgressAction()))
//     })
//     .catch((e) => Observable.of(searchDeedsFailedAction(e)))

export default [init, searchNonprofits, searchDeeds]
