import invariant from 'invariant'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'

import checkStore from './checkStore'

export function injectEpicsFactory(store, isValid) {
  return function injectEpics(key, epics) {
    if (!isValid) {
      checkStore(store)
    }

    invariant(
      isString(key) && !isEmpty(key) && isArray(epics),
      '(app/utils...) injectEpics: Expected `epics` to be an array of observables'
    )

    if (Reflect.has(store.injectedEpics, key)) {
      return
    }

    store.injectedEpics[key] = epics // eslint-disable-line no-param-reassign
    epics.map((epic) => store.rootEpic.next(epic))
  }
}

export default function getInjectors(store) {
  checkStore(store)

  return {
    injectEpics: injectEpicsFactory(store, true),
  }
}
