import { Map } from 'immutable'

import { ADD_MULTIPLE, CAUSES_LOADED, RESET } from './constants'
import Cause from './model'
import { CauseActions } from './actions'

export type CauseMap = Map<string, Cause>

export interface CauseState {
  causes: CauseMap
  causesLoaded: boolean
}

const causeState: CauseState = {
  causes: Map(),
  causesLoaded: false,
}

export const initialState = causeState

export default (state: CauseState = initialState, action: CauseActions): CauseState => {
  switch (action.type) {
    case ADD_MULTIPLE:
      return { ...state, causes: action.causes }

    case CAUSES_LOADED:
      return { ...state, causesLoaded: true }

    case RESET:
      return initialState
  }

  return state
}
