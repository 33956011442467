import { Observable } from 'rxjs'
import _ from 'lodash'
import { parse } from 'query-string'
import i18n from 'i18next'

import OrganizationApi from 'src/entities/organization/api'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { selectCauses } from 'src/entities/cause/selectors'
import { ADD as ADD_USER } from 'src/entities/user/constants'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { CauseTypes } from 'src/entities/cause/model'

import { searchNonprofitsSuccess, searchNonprofitsFailed } from './actions'
import { SEARCH, CANCEL_SEARCH } from './constants'
import { selectQueryId, selectPage } from './selectors'

let partnerOnly
let searchParams
let acceptsDonations

// searches for nonprofits
const search = (action$, store) =>
  action$
    .ofType(SEARCH, ADD_USER)
    .mergeMap((payload) => {
      if (payload.type === ADD_USER && !payload.user.me) {
        return []
      }
      if (payload.type === SEARCH) {
        partnerOnly = payload.partnerOnly
        searchParams = payload.searchParams
        acceptsDonations = payload.acceptsDonations
      }

      const actions = []
      const state = store.getState()
      const user = selectCurrentUser(state)
      if (!user) {
        return []
      }

      const allCauses = selectCauses(state)
      const locations = selectUserLocations(state)
      const userCountryCode = locations?.get(user?.location)?.countryCode || 'US'
      const userStateCode = locations?.get(user?.location)?.stateCode
      const queryId = selectQueryId(state)
      const page = selectPage(state)

      const parsedParams = searchParams && parse(searchParams)
      const partnerNonprofitsOnly = user?.getSetting('partnerNonprofitsOnly')

      if (partnerOnly) {
        // @FIXME: this doesn't really render?!
        actions.push(
          OrganizationApi.fetchPartnerNonprofits({
            countryCode: userCountryCode,
            stateCode: userStateCode,
            pageSize: 24,
            page,
          }).takeUntil(action$.ofType(SEARCH, CANCEL_SEARCH))
        )
      } else {
        // HOTFIX: disable new search for closed environments (only partners are searchable)
        const searchNonprofitDB = user?.organization?.settings.searchNonprofitDB && !partnerNonprofitsOnly
        const cause = getCauseParameter({ searchNonprofitDB, allCauses, parsedParams })
        const searchFilterParams = {
          searchTerm: parsedParams?.searchTerm,
          countryCode: parsedParams?.location?.substr(0, 2) ?? userCountryCode,
          stateCode: parsedParams ? parsedParams.location?.substr(3) : userStateCode,
          includeApproved: true,
          acceptsDonations,
          pageSize: 24,
          cause,
          page,
          queryId,
        }

        const apiListNonprofits = OrganizationApi.listNonprofits(searchFilterParams, searchNonprofitDB).takeUntil(
          action$.ofType(SEARCH, CANCEL_SEARCH)
        )

        actions.push(apiListNonprofits)
      }

      return Observable.combineLatest(actions).mergeMap((resultingActions) => [
        ..._.flatten(resultingActions),
        searchNonprofitsSuccess(),
      ])
    })
    .catch((e) => {
      const isExpiredQueryError = e.responseJson?.message?.includes('INVALID_QUERY_PARAM_VALUE_QUERY_')

      return isExpiredQueryError
        ? [showErrorAction(null, i18n.t('common:searchExpiredReload'), true)]
        : [searchNonprofitsFailed(e), showErrorAction(i18n.t('common:anErrorOccurred'))]
    })

const getCauseParameter = ({ searchNonprofitDB, allCauses, parsedParams }) => {
  const selectedCauseCode = allCauses
    .filter((c) => c.type === CauseTypes.SDG || c.type === CauseTypes.Category)
    .find((c) => c.name === parsedParams?.cause)?.code

  if (searchNonprofitDB && selectedCauseCode) {
    return [selectedCauseCode]
  }

  if (!searchNonprofitDB && parsedParams?.cause) {
    return parsedParams.cause
  }

  return undefined
}

export default [search]
