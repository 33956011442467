import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { styled } from 'src/theme/styled'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { Avatar, Loading, Row } from 'src/retired/elements'
import { Body1, Body2, H3, Label } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { MediumUp, SmallOnly } from 'src/retired/elements/MediaQuery'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { formatFullName, getBoxShadow } from 'src/utils'
import { selectAllDonationsForCampaign } from 'src/entities/campaign/selectors'
import { State } from 'src/reducers'
import { selectDonationsForCampaignLoading } from 'src/entities/donation/selectors'
import { loadDonationsForCampaign } from 'src/entities/donation/actions'

const CommunityFeedWrapper = styled.View<object, EmotionTheme>`
  margin: ${({ theme }) => (theme.metrics.isSmall ? '50px 0 0' : '100px 0 0')};
  align-items: center;
`
const CommunityFeedList = styled.View`
  max-width: 653px;
  width: 100%;
  background-color: white;
  border-radius: 40px;
  margin: 44px 0 0;
  padding: 14px;
  ${getBoxShadow({ offset: '0px 3px', radius: '44px', color: 'rgba(0, 0, 0, 0.05)' })}
`

const CommunityFeedListItem = styled.View<{ withBackground?: boolean }, EmotionTheme>`
  border-radius: 32px;
  padding: 10px 16px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ theme }) => (theme.metrics.isSmall ? 'flex-start' : 'space-between')};
  background-color: ${({ withBackground, theme }) => (withBackground ? theme.colors.gray03 : theme.colors.white)};
`

const INITIAL_DONATIONS_AMOUNT_TO_SHOW = 10
const INITIAL_LOAD_LIMIT = INITIAL_DONATIONS_AMOUNT_TO_SHOW + 1
interface CommunityFeedProps {
  ctaButtonColor: string
  ctaTextColor: string
  campaignId: string
}

const CommunityFeed: React.FC<CommunityFeedProps> = ({ ctaButtonColor, ctaTextColor, campaignId }) => {
  const { t } = useTranslation('campaignScreen')
  const { colors } = useDeedTheme()
  const dispatch = useDispatch()
  const isAllDonationsLoaded = useRef<null | 'loading' | 'loaded'>(null)

  const allDonations = useSelector((state: State) => selectAllDonationsForCampaign(state, campaignId))
  const donationsLoading = useSelector((state: State) => selectDonationsForCampaignLoading(state, campaignId))
  const initialDonationActivitiesList = allDonations.slice(0, INITIAL_DONATIONS_AMOUNT_TO_SHOW)
  const [isViewAllExpanded, setIsViewAllExpanded] = useState(false)
  const [donationActivitiesList, setDonationsActivitiesList] = useState(initialDonationActivitiesList)
  const totalDonationsCount = allDonations.length

  const handleViewAllButtonPress = () => {
    if (!isViewAllExpanded) {
      if (allDonations.length > INITIAL_DONATIONS_AMOUNT_TO_SHOW && isAllDonationsLoaded.current === 'loaded') {
        setDonationsActivitiesList(allDonations)
      } else {
        dispatch(loadDonationsForCampaign(campaignId))
        isAllDonationsLoaded.current = 'loading'
      }
    } else {
      setDonationsActivitiesList(initialDonationActivitiesList)
    }
    setIsViewAllExpanded(!isViewAllExpanded)
  }

  useEffect(() => {
    // initially load 11 items to decide showing View All button.
    dispatch(loadDonationsForCampaign(campaignId, INITIAL_LOAD_LIMIT))
  }, [campaignId, dispatch])

  useEffect(() => {
    if (initialDonationActivitiesList.length && !isAllDonationsLoaded.current) {
      setDonationsActivitiesList(initialDonationActivitiesList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDonations])

  useEffect(() => {
    if (allDonations.length >= INITIAL_LOAD_LIMIT && isViewAllExpanded && isAllDonationsLoaded.current === 'loading') {
      setDonationsActivitiesList(allDonations)
      isAllDonationsLoaded.current = 'loaded'
    }
  }, [allDonations, allDonations.length, isViewAllExpanded])

  return (
    <CommunityFeedWrapper>
      <H3 marginBottom={12} weight="500" center>{t`communityFeed`}</H3>
      <Body1 colour={colors.gray} center>{t`latestDonationActivity`}</Body1>

      {donationsLoading && !donationActivitiesList.length ? (
        <View style={{ marginTop: 40 }}>
          <Loading fill={false} />
        </View>
      ) : (
        <>
          <CommunityFeedList>
            {donationActivitiesList.map((activity, i) => (
              <CommunityFeedListItem key={activity.id} withBackground={!!(i % 2)}>
                <MediumUp>
                  <Row style={{ alignItems: 'center' }}>
                    <Avatar
                      user={activity.privacy === 'Anonymous' ? undefined : activity.user}
                      link={false}
                      size={56}
                    />
                    <Body1 weight="500" marginLeft={16} marginRight={16}>
                      {activity.privacy === 'Anonymous' ? t`anonymous` : activity.user?.name}
                    </Body1>
                    <Body1 colour={colors.darkGray}>
                      <Trans
                        t={t}
                        i18nKey="donatedAmount"
                        components={{
                          CurrencyFormat:
                            activity.privacy === 'Public' ? (
                              <CurrencyFormat amountCurrencies={activity.amountCurrencies} process="round" short />
                            ) : (
                              <></>
                            ),
                        }}
                      />
                    </Body1>
                  </Row>
                  <Body2 colour={colors.gray}>{moment(activity.createdAt).fromNow()}</Body2>
                </MediumUp>
                <SmallOnly>
                  <Avatar user={activity.privacy === 'Anonymous' ? undefined : activity.user} link={false} size={32} />
                  <View style={{ marginRight: 10, flex: 1 }}>
                    <Row style={{ flexWrap: 'wrap' }}>
                      <Body2 weight="500" marginRight={8}>
                        {activity.privacy === 'Anonymous'
                          ? t`anonymous`
                          : formatFullName(activity.user?.fullName) || activity.user?.name}
                      </Body2>
                      <Body2>
                        <Trans
                          t={t}
                          i18nKey="donatedAmount"
                          components={{
                            CurrencyFormat:
                              activity.privacy === 'Public' ? (
                                <CurrencyFormat amountCurrencies={activity.amountCurrencies} process="round" short />
                              ) : (
                                <></>
                              ),
                          }}
                        />
                      </Body2>
                    </Row>
                    <Label colour={colors.gray} marginTop={6}>
                      {moment(activity.createdAt).fromNow()}
                    </Label>
                  </View>
                </SmallOnly>
              </CommunityFeedListItem>
            ))}
          </CommunityFeedList>
          {totalDonationsCount > INITIAL_DONATIONS_AMOUNT_TO_SHOW && (
            <Button
              loading={donationsLoading}
              style={{ marginHorizontal: 'auto', backgroundColor: ctaButtonColor, marginTop: 44 }}
              textStyle={{ color: ctaTextColor }}
              onPress={handleViewAllButtonPress}
            >
              {isViewAllExpanded ? t`viewLess` : t`viewMore`}
            </Button>
          )}
        </>
      )}
    </CommunityFeedWrapper>
  )
}

export default CommunityFeed
