import React from 'react'
import { View } from 'react-primitives'

import Icon from 'src/retired/shared/Icon'

export const IconHeader = ({ iconName, backgroundColor }: { iconName: string; backgroundColor: string }) => (
  <View
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor,
      paddingTop: 30,
    }}
  >
    <Icon icon={iconName} width={100} />
  </View>
)
