import React from 'react'
import { View } from 'react-primitives'
import { subMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Chip from 'src/retired/shared/Chip'
import { ColorsProps } from 'src/theme/colors'
import { LinkButton } from 'src/retired/elements'
import { CardCompactWrapper } from 'src/components'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Label, Body1 } from 'src/retired/shared/Typography'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import GivingPledgeCampaign from 'src/entities/campaign/GivingPledgeCampaign'
import GivingPledge, { GivingPledgeStatus } from 'src/entities/donationSchedule/GivingPledge'

interface PledgeCompactProps {
  pledge: GivingPledge
}

const PledgeStatus = ({ pledge }: { pledge: GivingPledge }): JSX.Element => {
  const { t } = useTranslation('profileScreen')

  const { colors } = useDeedTheme()

  const pledgeStatus = pledge.getPledgeStatus()

  let backgroundColor = colors.mediumPurple as keyof ColorsProps

  if (pledgeStatus === GivingPledgeStatus.Inactive) {
    backgroundColor = colors.grayLight as keyof ColorsProps
  } else if (pledgeStatus === GivingPledgeStatus.Completed) {
    backgroundColor = colors.greenLight as keyof ColorsProps
  }

  return (
    <span style={{ marginTop: '1rem' }}>
      <Chip type="square" backgroundColor={backgroundColor} textStyle={{ textAlign: 'center' }}>
        {t(pledgeStatus)}
      </Chip>
    </span>
  )
}

const PledgeDate = ({ pledge }: { pledge: GivingPledge }): JSX.Element => {
  const { colors } = useDeedTheme()

  const { createdAt } = pledge

  const { t } = useTranslation('donationCompact')

  const textColour = colors.black
  const oldDate = subMonths(new Date(), 11) > createdAt

  let translationTextKey = 'date:dayMonth'

  if (oldDate) {
    translationTextKey = 'date:dayMonthYearShort'
  }

  return (
    <Label colour={textColour} marginBottom={8}>
      {t(translationTextKey, { date: createdAt })}
    </Label>
  )
}

const PledgeAmount = ({ pledge }: { pledge: GivingPledge }): JSX.Element | null => {
  const { colors } = useDeedTheme()

  const textColour = colors.black

  const { amount, currencyCode } = pledge

  if (!Number.isFinite(amount)) {
    return null
  }

  return (
    <Body1 weight="500" colour={textColour} style={{ marginTop: '5px' }}>
      <CurrencyFormat baseCurrency={currencyCode} amount={amount} />
    </Body1>
  )
}

const PledgeCampaign = ({
  campaign,
  numberOfLines,
}: {
  campaign?: GivingPledgeCampaign
  numberOfLines: number
}): JSX.Element | null => {
  const { colors } = useDeedTheme()

  if (!campaign) {
    return null
  }

  const { title } = campaign

  return (
    <Label style={{ marginTop: 'auto' }} colour={colors.black} numberOfLines={numberOfLines}>
      {title}
    </Label>
  )
}

const GivingPledgeCard = ({
  cardColor,
  pledge,
  pledgeCampaign,
  numberOfLines,
  style,
}: {
  pledge: GivingPledge
  pledgeCampaign: GivingPledgeCampaign | undefined
  cardColor: string
  numberOfLines: number
  style: any
}): JSX.Element => (
  <CardCompactWrapper cardColor={cardColor}>
    <View style={style}>
      <PledgeDate pledge={pledge}></PledgeDate>

      <PledgeAmount pledge={pledge}></PledgeAmount>

      <PledgeStatus pledge={pledge}></PledgeStatus>

      <PledgeCampaign campaign={pledgeCampaign} numberOfLines={numberOfLines}></PledgeCampaign>
    </View>
  </CardCompactWrapper>
)

export const GivingPledgeCompact = ({ pledge }: PledgeCompactProps): JSX.Element | null => {
  const { colors } = useDeedTheme()

  const { id, Campaign: campaign } = pledge
  const numberOfLines = 2

  if (!campaign) {
    return null
  }

  const pledgeCampaign = new GivingPledgeCampaign(campaign)

  const toLink = `/donations/recurring/${id}`

  const style = { flexGrow: 1, alignItems: 'center' }

  let cardColor = colors.purple

  if (!pledgeCampaign?.isActivePledgeCampaign()) {
    cardColor = colors.grayLight
  }

  // No link, as they're not allowed to currently navigate to a pledge that has completed
  if (!pledgeCampaign?.isActivePledgeCampaign()) {
    return (
      <GivingPledgeCard
        style={style}
        pledge={pledge}
        cardColor={cardColor}
        numberOfLines={numberOfLines}
        pledgeCampaign={pledgeCampaign}
      ></GivingPledgeCard>
    )
  }

  return (
    <LinkButton to={toLink} button={false}>
      <GivingPledgeCard
        style={style}
        pledge={pledge}
        cardColor={cardColor}
        numberOfLines={numberOfLines}
        pledgeCampaign={pledgeCampaign}
      ></GivingPledgeCard>
    </LinkButton>
  )
}

export default GivingPledgeCompact
