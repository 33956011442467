import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import UserApi from 'src/entities/user/api'

import { selectSelectedDepartment } from './selectors'
import { SUBMIT } from './constants'
import { submitSuccessAction, submitFailedAction } from './actions'

const submit = (action$, store) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(() =>
      UserApi.update({
        organizationDepartment: selectSelectedDepartment(store.getState()),
      }).mergeMap((action) => [
        action,
        !action.user.location ? replace('/register/location') : replace('/register/interests'),
        submitSuccessAction(),
      ])
    )
    .catch((e) => Observable.of(submitFailedAction(e), showErrorAction(i18n.t('companyRegister:savingFailed'))))

export default [submit]
