import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { selectLocations } from 'src/entities/location/selectors'
import Organization from 'src/entities/organization/model'
import { selectBrand, selectBrandColor } from 'src/localSettings/selectors'
import { State } from 'src/reducers'
import { colors } from 'src/theme'

import { LocationMap } from '../location/reducer'
import Location from '../location/model'

import User from './model'
import { UserMap } from './reducer'

export const selectUsers = (state: State): UserMap => state.get('entities').user

export const selectUserById = createSelector(
  selectUsers,
  (_state: State, userId: string): string => userId,
  (users, userId) => users.get(userId)
)

export const selectCurrentUser = createSelector(selectUsers, (users) => users.find((user) => !!user?.me))

export const selectUserLocations = createSelector(selectCurrentUser, selectLocations, (user, locations) => {
  if (typeof user === 'undefined' || typeof user.organization === 'undefined') {
    return locations
  }

  if (user.organization.locations?.size > 0) {
    return user.organization.locations
  }

  const locationId = user.location
  const location = user.get('_location')
  if (typeof locationId !== 'undefined' && typeof location !== 'undefined') {
    return Map([[locationId, new Location(location)]]) as LocationMap
  }

  return locations
})

export const selectUserBrand = createSelector(
  selectCurrentUser,
  (state: State) => selectBrand(state),
  (user: User | undefined, brand): Organization | undefined => user?.organization || brand || undefined
)

export const selectUserBrandColor = createSelector(
  selectUserBrand,
  selectBrandColor,
  (userBrand?: Organization, localBrandColor?: string | null): string =>
    userBrand?.brandColor || localBrandColor || colors.primary
)
