import { Record } from 'immutable'
import _ from 'lodash'

const properties = {
  id: '',
  code: '',
  name: '',
  state: '',
  stateCode: '',
  country: '',
  countryCode: '',
  currencyCode: '',
  region: '',
  picture: '',
  coordinates: [],
  phone: false,
}

export default class Location extends Record(properties, 'Location') implements Location {
  public readonly id!: string

  public readonly code!: string

  public readonly name!: string

  public readonly state!: string

  public readonly stateCode!: string

  public readonly country!: string

  public readonly countryCode!: string

  public readonly currencyCode!: string

  public readonly region!: string

  public readonly picture!: string

  public readonly coordinates!: [number, number]

  public readonly phone!: boolean

  constructor(values: any = {}) {
    super(_.pick(values, Object.keys(properties)))
  }
}
