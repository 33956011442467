import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { Gradient, Image } from 'src/retired/elements'
import { H2, H3, H4, H5 } from 'src/retired/shared/Typography'
import { MediumDown, LargeUp } from 'src/retired/elements/MediaQuery'

import landing8 from '../../../assets/landing/landing8.jpg'

const DonateFeedBanner = (): JSX.Element => {
  const { t } = useTranslation('donateFeedScreen')
  const { metrics, colors } = useDeedTheme()
  const user = useSelector(selectCurrentUser)
  const disableNonprofitSearch = user?.getSetting('partnerNonprofitsOnly') ?? true
  const height = metrics.isLarge ? 350 : metrics.isMedium ? 264 : 314

  return (
    <>
      <MediumDown>
        <View
          style={{
            backgroundColor: colors.black,
            position: 'relative',
            height,
            marginLeft: -24,
            marginRight: -24,
          }}
        >
          <>
            <Image source={landing8} style={{ width: '100%', height }} />
            <Gradient
              height="100%"
              startColor={colors.imageOverlay}
              startOpacity={0.2}
              endColor={colors.imageOverlay}
              endOpacity={0.95}
              vertical
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
            <View style={{ position: 'absolute', left: 48, right: 24, top: 65 }}>
              <H3 colour="white" weight="500" marginBottom={8}>
                {t`supportYourFavoriteCause`}
              </H3>
              {!disableNonprofitSearch && (
                <H5 colour="white" style={{ opacity: 0.9 }}>
                  {t`exploreOverTwoMillionRegisteredOrganizations`}
                </H5>
              )}
            </View>
          </>
        </View>
      </MediumDown>
      <LargeUp>
        <View
          style={{
            backgroundColor: colors.black,
            position: 'relative',
            marginLeft: -56,
            marginRight: -56,
            height,
          }}
        >
          <>
            <Image source={landing8} style={{ width: '100%', height }} />
            <Gradient
              height="100%"
              startColor={colors.imageOverlay}
              startOpacity={0.2}
              endColor={colors.imageOverlay}
              endOpacity={0.95}
              vertical
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
              }}
            />
          </>
        </View>
        <View style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <View style={{ maxWidth: !metrics.isLarge ? metrics.maxWidthNarrow : metrics.maxWidthWide, width: '100%' }}>
            <View
              style={{
                marginLeft: -56,
                marginTop: -300,
                marginRight: -56,
                zIndex: 1000,
              }}
            >
              <View style={{ zIndex: 1000, marginTop: -20, paddingLeft: 80, paddingRight: 80 }}>
                <H2 weight="500" colour="white" marginTop={72} marginBottom={8}>
                  {t`supportYourFavoriteCause`}
                </H2>

                {!disableNonprofitSearch && (
                  <H4 colour="white" style={{ opacity: 0.9 }}>
                    {t`exploreOverTwoMillionRegisteredOrganizations`}
                  </H4>
                )}
              </View>
            </View>
          </View>
        </View>
      </LargeUp>
    </>
  )
}
export default DonateFeedBanner
