import { fromJS } from 'immutable'

import {
  LOAD_FUNDRAISERS,
  LOAD_FUNDRAISERS_SUCCESS,
  LOAD_FUNDRAISERS_FAILURE,
  LOAD_LOCAL_NONPROFITS,
  LOAD_LOCAL_NONPROFITS_SUCCESS,
  LOAD_LOCAL_NONPROFITS_FAILURE,
  LOAD_PARTNER_NONPROFITS,
  LOAD_PARTNER_NONPROFITS_SUCCESS,
  LOAD_PARTNER_NONPROFITS_FAILURE,
} from './constants'

export const initialState = fromJS({
  fundraisersLoading: false,
  localNonprofitsLoading: false,
  partnerNonprofitsLoading: false,
})

export default (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_FUNDRAISERS:
      return state.set('fundraisersLoading', true)

    case LOAD_LOCAL_NONPROFITS:
      return state.set('localNonprofitsLoading', true)

    case LOAD_PARTNER_NONPROFITS:
      return state.set('partnerNonprofitsLoading', true)

    case LOAD_FUNDRAISERS_SUCCESS:
    case LOAD_FUNDRAISERS_FAILURE:
      return state.set('fundraisersLoading', false)

    case LOAD_LOCAL_NONPROFITS_SUCCESS:
    case LOAD_LOCAL_NONPROFITS_FAILURE:
      return state.set('localNonprofitsLoading', false)

    case LOAD_PARTNER_NONPROFITS_SUCCESS:
    case LOAD_PARTNER_NONPROFITS_FAILURE:
      return state.set('partnerNonprofitsLoading', false)

    default:
      return state
  }
}
