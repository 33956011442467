import { fromJS } from 'immutable'

import { INIT, INIT_SUCCESS, INIT_FAILED, SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const initialState = fromJS({ loading: false, submitting: false, error: null })

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    case SUBMIT:
      return state.set('submitting', true)

    case SUBMIT_SUCCESS:
      return state.set('submitting', false)

    case SUBMIT_FAILED:
      return state.merge({ submitting: false, error: action.error })

    case 'RESET':
      return initialState

    default:
      return state
  }
}
