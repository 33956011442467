import React from 'react'
import { View } from 'react-primitives'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import { styled } from 'src/theme/styled'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import { injectReducer, DeviceInfo } from 'src/utils'
import feedFilterReducer from 'src/containers/modules/FeedFilter/reducer'
import { withRouter } from 'src/navigation'
import nonprofitsFilterReducer from 'src/containers/modules/NonprofitsFilter/reducer'
import { selectIsFeedFilterActive } from 'src/containers/modules/FeedFilter/selectors'
import { selectIsNonprofitsFilterActive } from 'src/containers/modules/NonprofitsFilter/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'

const FilterStickyButton = styled(View)`
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: ${({ theme }) => (theme.metrics.isLarge ? '20px' : `${DeviceInfo.getNotchValue() + 80}px`)};
  right: 20px;
  background-color: #e9eaff;
  border-radius: 28px;
  border: 1px solid #ebeef0;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => `0 1px 4px ${theme.colors.black}`};
`

const FilterButton = ({ isFeedFilterActive, isNonprofitsFilterActive, actions, match, sticky = false }) => {
  const WrapperComponent = sticky ? FilterStickyButton : React.Fragment

  return (
    <WrapperComponent>
      <Touchable
        onPress={(e) => {
          e.preventDefault()
          actions.setLocalSettingAction('filtersShown', true)
        }}
      >
        <Icon
          icon={
            (match.url.indexOf('nonprofits') !== -1 ? isNonprofitsFilterActive : isFeedFilterActive)
              ? 'filterActive'
              : 'filter'
          }
          width={48}
        />
      </Touchable>
    </WrapperComponent>
  )
}

FilterButton.propTypes = {
  isFeedFilterActive: PropTypes.bool,
  isNonprofitsFilterActive: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object,
  sticky: PropTypes.bool,
}

const withConnect = connect(
  (state) => ({
    isFeedFilterActive: selectIsFeedFilterActive(state),
    isNonprofitsFilterActive: selectIsNonprofitsFilterActive(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ setLocalSettingAction }, dispatch),
  })
)
const withFeedFilterReducer = injectReducer({
  key: 'feedFilter',
  reducer: feedFilterReducer,
})
const withFeedNonprofitsReducer = injectReducer({
  key: 'nonprofitsFilter',
  reducer: nonprofitsFilterReducer,
})

export default compose(withFeedFilterReducer, withFeedNonprofitsReducer, withConnect, withRouter)(FilterButton)
