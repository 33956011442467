import { fromJS, Map } from 'immutable'

import { ADD_MULTIPLE } from './constants'
import DonationCredit from './model'

export type DonationCreditMap = Map<string, DonationCredit>

export interface DonationCreditState extends Map<string, any> {
  donationCredits: DonationCreditMap
}

interface DonationCreditStateMap extends Map<string, any> {
  toJS: () => DonationCreditState
  get: <K extends keyof DonationCreditState>(key: K) => DonationCreditState[K]
}

export const initialState: DonationCreditStateMap = fromJS({
  donationCredits: Map(),
})

export default (state: DonationCreditStateMap = initialState, action: any): DonationCreditStateMap => {
  switch (action.type) {
    case ADD_MULTIPLE:
      return state.set('donationCredits', state.get('donationCredits').merge(action.donationCredits))
  }
  return state
}
