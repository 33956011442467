import { useFormik } from 'formik'
import React from 'react'
import { View } from 'react-primitives'
import { Alert } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { validators } from 'src/utils'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Screen, ScrollView, Row, Column } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { CurrentPledgeDocument, useCurrentPledgeQuery, useSetCurrentPledgeMutation } from 'src/generated/graphql'
import { ErrorText } from 'src/components/ErrorText'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { currencies, CurrencyCode, currencyFormat } from 'src/containers/modules/CurrencyFormat'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import CurrencyInput from 'src/components/CurrencyInput'

import { Tips } from './Tips'
import { calculateTargetForRange } from './calculateTargetForRange'

const PledgeScreen = () => {
  const user = useSelector(selectCurrentUser)
  const { t } = useTranslation('pledge')
  const history = useHistory()
  const { metrics, colors } = useDeedTheme()

  const { data, loading, error } = useCurrentPledgeQuery()
  const [setCurrentPledgeMutation, { loading: updating, error: updateError }] = useSetCurrentPledgeMutation({
    onCompleted: () => {
      history.replace('/')
    },
    update(cache, { data: result }) {
      const newPledge = result?.setCurrentPledge
      if (newPledge) {
        cache.writeQuery({
          query: CurrentPledgeDocument,
          data: {
            currentPledge: newPledge,
          },
        })
      }
    },
  })

  const donationsPledge = data?.currentPledge?.donations

  const userCurrencyCode = useSelector((state) => selectLocalSetting(state, 'currency'))
  const currencyCode = (data?.currentPledge?.donations?.currencyCode || userCurrencyCode || 'USD') as CurrencyCode
  const currencySymbol = currencies[currencyCode].symbol

  const formik = useFormik({
    initialValues: {
      id: data?.currentPledge?.id,
      amount: donationsPledge?.amount,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {
        amount: validators.notEmpty(values.amount) || validators.isNumber(values.amount),
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    validateOnMount: false,
    onSubmit: (values) => {
      if (values.amount) {
        void setCurrentPledgeMutation({
          variables: { id: values.id, donations: { amount: Number(values.amount), currencyCode } },
        })
      }
    },
  })

  const donated = data?.currentPledge?.donations?.donated || 0
  const givingGoalStartDate = data?.currentPledge?.givingGoalConfig?.startDate || undefined
  const givingGoalEndDate = data?.currentPledge?.givingGoalConfig?.endDate || undefined
  const isGoalEditable = data?.currentPledge?.givingGoalConfig?.isEditableByEmployee ?? true

  const target = formik.values.amount || 0
  const monthlyTarget = calculateTargetForRange(target - donated, givingGoalStartDate, givingGoalEndDate)

  if (!user?.hasFeature('pledge')) {
    return null
  }

  return (
    <Screen fixed>
      <ScrollView>
        <Row
          style={
            metrics.isSmall
              ? {
                  flexDirection: 'column',
                }
              : {}
          }
        >
          <Column cols={metrics.isSmall ? 12 : 5}>
            <NavigationHeader transparent title={isGoalEditable ? t`title` : t`preConfiguredGoal`} />
            <View style={{ padding: 30 }}>
              {loading ? (
                <View style={{ marginBottom: 30 }}>
                  <ActivityIndicator color={colors.userOrganizationBrandColor || colors.coral} size="large" />
                </View>
              ) : (
                <>
                  <View style={{ position: 'relative' }}>
                    <CurrencyInput
                      name="amount"
                      onValueChange={(value, fieldName) => {
                        if (/^[0-9]{1,8}$/.test(value) || !value) {
                          void formik.setFieldValue(fieldName, value)
                        }
                      }}
                      value={String(formik.values.amount || '')}
                      currencySymbol={currencySymbol}
                      customPlaceholder={t`placeholder`}
                      decimalsLimit={0}
                      editable={isGoalEditable}
                    />
                  </View>
                  {Boolean(formik.errors.amount) && <ErrorText text={formik.errors.amount || ''} />}
                  {monthlyTarget > 0 && (
                    <Body2 center marginBottom={25} colour="rgba(0, 0, 0, 0.6);">
                      <Trans
                        t={t}
                        i18nKey={givingGoalEndDate ? 'hintWithFullDate' : 'hint'}
                        components={{
                          Highlight: <Body2 weight="500" />,
                        }}
                        values={{
                          amount: currencyFormat(monthlyTarget, currencyCode),
                          dateVal: givingGoalEndDate
                            ? new Date(givingGoalEndDate)
                            : data?.currentPledge?.id || new Date().getFullYear(),
                        }}
                      />
                    </Body2>
                  )}
                  {isGoalEditable && (
                    <View style={{ alignItems: 'center' }}>
                      <Button
                        loading={updating}
                        palette="primary"
                        style={{ maxWidth: 170 }}
                        onPress={() => formik.handleSubmit()}
                      >
                        {t`pledge`}
                      </Button>
                    </View>
                  )}
                </>
              )}

              {updateError && (
                <View style={{ marginTop: 20 }}>
                  <Alert severity="error">{t`common:anErrorOccurred`}</Alert>
                </View>
              )}
              {error && (
                <View style={{ marginTop: 20 }}>
                  <Alert severity="error">{t`common:thereWasAnErrorLoading`}</Alert>
                </View>
              )}

              <View style={{ marginTop: 20 }}>
                <Alert severity="info">{t`privacyHint`}</Alert>
              </View>
            </View>
          </Column>
          <Column cols={metrics.isSmall ? 12 : 7}>
            <Tips />
          </Column>
        </Row>
      </ScrollView>
    </Screen>
  )
}

export default PledgeScreen
