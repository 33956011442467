import React, { Dispatch, SetStateAction } from 'react'

import DonationSchedule from 'src/entities/donationSchedule/model'
import { Body } from 'src/retired/shared/Typography'
import { CurrencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import CurrencyInput from 'src/components/CurrencyInput'

interface EditableAmountProps {
  inputAmount: string
  setInputAmount: Dispatch<SetStateAction<string>>
  inEditing: boolean
  donationSchedule: DonationSchedule
}

export const EditableAmount = ({ donationSchedule, inEditing, inputAmount, setInputAmount }: EditableAmountProps) => {
  const currencySymbol = useCurrency().symbol

  if (!inEditing) {
    return (
      <Body weight="500">
        <CurrencyFormat baseCurrency={donationSchedule.currencyCode} amount={donationSchedule.amount} />
      </Body>
    )
  }

  return (
    <CurrencyInput
      name="amount"
      value={inputAmount}
      onValueChange={(value) => {
        if (!value) {
          setInputAmount('')
          return
        }
        setInputAmount(value)
      }}
      currencySymbol={currencySymbol}
      maxLength={8}
      style={{
        color: '#000000',
        width: '100%',
        height: '100%',
        textAlign: 'start',
        display: 'inline',
        fontSize: 14,
        flexGrow: 1,
        minHeight: 25,
        fontFamily: 'GTWalsheimLC',
        fontWeight: 500,
        border: '1px solid #ebeef0',
        borderRadius: '7px',
      }}
      customPlaceholder=""
      autoFocus
    />
  )
}
