import React, { useState } from 'react'
import { View } from 'react-primitives'

import { Body } from 'src/retired/shared/Typography'
import { Icon, Row } from 'src/retired/elements'
import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'

const Wrapper = styled(Body)<object, EmotionTheme>`
  text-align: left;
  border-radius: 0.25rem;
`

const ItemWrapper = styled(View)`
  height: 2rem;
  max-height: 4rem;
  min-width: 2rem;
  justify-content: center;
`

const TextWrapper = styled(Body)`
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: pointer;
`

const CollapsibleText = ({ primaryText, secondaryText }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev)
  }

  return (
    <Wrapper>
      <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TextWrapper onClick={toggleCollapse}>{primaryText}</TextWrapper>
        <ItemWrapper>
          <Icon
            icon="arrowDown"
            style={{
              width: '0.75rem',
              height: '0.75rem',
              margin: 'auto',
              transform: isCollapsed ? 'rotate(270deg)' : 'rotate(0deg)',
              cursor: 'pointer',
            }}
            onClick={toggleCollapse}
          />
        </ItemWrapper>
      </Row>
      <TextWrapper
        style={{
          display: isCollapsed ? 'none' : 'block',
          marginTop: '0.5rem',
        }}
      >
        {secondaryText}
      </TextWrapper>
    </Wrapper>
  )
}

export default CollapsibleText
