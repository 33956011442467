import React from 'react'
import PropTypes from 'prop-types'
import { Text, StyleSheet } from 'react-primitives'

import { colors } from 'src/theme'

const styles = StyleSheet.create({
  light: {
    fontFamily: 'GTWalsheimLC',
  },
  medium: {
    fontFamily: 'GTWalsheimLC',
    fontWeight: 500,
  },
  bold: {
    fontFamily: 'GTWalsheimLC',
    fontWeight: 'bold',
  },
  normal: {
    fontFamily: 'GTWalsheimLC',
  },
  center: {
    textAlign: 'center',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  italic: {
    fontStyle: 'italic',
  },
})

const TextElement = ({
  size = 13,
  color = colors.darkGray,
  light = false,
  medium = false,
  bold = false,
  left = false,
  center = false,
  right = false,
  underline = false,
  italic = false,
  lineHeight = 'normal',
  style = {},
  children,
  ...props
}) => (
  <Text
    style={[
      light && styles.light,
      medium && styles.medium,
      bold && styles.bold,
      !light && !medium && !bold && styles.normal,
      center && styles.center,
      left && styles.left,
      right && styles.right,
      underline && styles.underline,
      italic && styles.italic,
      {
        fontSize: size,
        color,
        lineHeight: lineHeight === 'normal' ? size * 1.2 : lineHeight,
      },
      style,
    ]}
    {...props}
  >
    {children}
  </Text>
)
TextElement.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  light: PropTypes.bool,
  medium: PropTypes.bool,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  underline: PropTypes.bool,
  italic: PropTypes.bool,
  lineHeight: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
}
export default TextElement
