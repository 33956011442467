import React from 'react'

import { withRouter } from 'src/navigation'
import Text from 'src/retired/elements/Text'

export default withRouter(({ children, to, history, staticContext, ...props }) => (
  <Text
    {...props}
    onPress={(e) => {
      e.stopPropagation()
      history.push(to)
    }}
  >
    {children}
  </Text>
))
