import { Record } from 'immutable'

import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'

interface Deed {
  id: string
  name: string
}

const properties = {
  id: '',
  deed: undefined,
  creditAmount: 0,
  currencyCode: 'USD',
  createdAt: undefined,
  publishedAt: undefined,
  expiryDate: undefined,
  transactionType: '',
  transactionId: '',
  note: '',
}

export default class DonationCredit extends Record(properties, 'DonationCredit') implements DonationCredit {
  public readonly id!: string

  public readonly deed!: Deed

  public readonly creditAmount!: number

  public readonly currencyCode!: CurrencyCode

  public readonly createdAt!: Date

  public readonly publishedAt!: Date

  public readonly expiryDate!: Date

  public readonly transactionType!: string

  public readonly transactionId!: string

  public readonly note!: string

  constructor({ createdAt, publishedAt, expiryDate, ...values }: any = {}) {
    super({
      ...values,
      createdAt: new Date(createdAt),
      publishedAt: new Date(publishedAt),
      expiryDate: expiryDate && new Date(expiryDate),
    })
  }
}
