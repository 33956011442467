import React from 'react'
import { useTranslation } from 'react-i18next'

import { DeedMap } from 'src/entities/deed/reducer'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { OrganizationMap } from 'src/entities/organization/reducer'
import { Body2 } from 'src/retired/shared/Typography'

import { getCardListDimensions } from '../utils'

import { NoResults, NoResultType } from './NoResults/NoResults'
import { DeedListWithPagination } from './DeedListWithPagination'

export const StaticDeedListWithPagination = ({
  deeds,
  loading,
  useBigList,
  hasMoreItems,
  loadMore,
}: {
  deeds: DeedMap | OrganizationMap
  loading: boolean
  useBigList?: boolean
  hasMoreItems: boolean
  loadMore?: () => void
}) => {
  const { t } = useTranslation('searchScreen')
  const { metrics, colors } = useDeedTheme()

  /* FIXME: Bring back the limited listing on initial fundraiser load. */
  const { cardWidth, numberOfColumns } = getCardListDimensions(metrics, useBigList)

  return !deeds.count() && !loading ? (
    <NoResults noResultType={NoResultType.NO_DEEDS}>
      <Body2 style={{ maxWidth: 340, textAlign: 'center', color: colors.grayMediumDark }}>
        {t('noResultsMatchingYourSearch')}
      </Body2>
    </NoResults>
  ) : (
    <DeedListWithPagination
      hasMoreItems={hasMoreItems}
      deeds={deeds}
      loading={loading}
      onSeeMore={loadMore}
      cardWidth={cardWidth}
      numberOfColumns={numberOfColumns}
    />
  )
}
