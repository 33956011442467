import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import { Loading, Touchable, Screen } from 'src/retired/elements'
import { injectReducer, injectEpics } from 'src/utils'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectCategories, selectPillars } from 'src/entities/cause/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { colors } from 'src/theme'
import { withRouter } from 'src/navigation'
import { H5, Body, Label, Title } from 'src/retired/shared/Typography'
import Onboarding, { getOnboardingStepCount } from 'src/retired/blocks/Onboarding'
import Icon from 'src/retired/shared/Icon'

import { selectSubmitting, selectError, selectSelectedInterests } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export class RegisterInterests extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    const { t, user, location, categories, pillars, selectedInterests, actions, submitting } = this.props

    if (!user || !categories) {
      return <Loading />
    }

    const path = location.pathname
    const onboarding = path === '/register/interests'

    return (
      <Screen fixed>
        <Onboarding
          page={!onboarding ? undefined : getOnboardingStepCount(user).interestsStep}
          confirmHandler={() => actions.submitAction(selectedInterests.toJS(), path)}
          confirmDisabled={!!user?.get('interests') && selectedInterests.size === 0}
          confirmSubmitting={submitting}
          skip={!user?.get('interests') ? () => actions.submitAction([]) : null}
        >
          <View
            style={{
              alignItems: 'center',
              paddingTop: 30,
              width: '100%',
            }}
          >
            <View
              style={{
                marginBottom: 8,
                marginHorizontal: 8,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <H5>{t`whatCausesDoYouCareAbout`} </H5>
              <Icon icon="heart" width={22} height={22} />
            </View>
            {onboarding && (
              <Body weight="normal" colour="grayMediumDark" marginTop={8} center>
                {t`youCanChangeYourSelectionsAtAnyTime`}
              </Body>
            )}
          </View>
          {pillars.size > 0 && (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 40,
                  marginBottom: 24,
                  alignItems: 'center',
                }}
              >
                <Title>
                  {user?.organization?.name
                    ? t('organizationNameCauseAreas', { orgName: user.organization.name })
                    : t`deedCauseAreas`}
                </Title>

                <Label colour="gray01" marginLeft={5}>
                  ({pillars.size})
                </Label>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 18,
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {pillars.toArray().map((pillar) => {
                  const selected = selectedInterests.has(pillar.id)
                  return (
                    <Touchable
                      key={pillar.id}
                      onPress={() => actions.toggleSelectedInterestAction(pillar.id)}
                      style={{
                        flexBasis: 'auto',
                        marginRight: 11,
                        marginBottom: 12,
                        borderRadius: 8,
                        paddingHorizontal: 16,
                        justifyContent: 'center',
                        height: 36,
                        borderColor: selected ? 'transparent' : colors.grayMediumDark,
                        backgroundColor: selected ? colors.grayLight : 'transparent',
                        borderWidth: 1,
                      }}
                    >
                      <Label colour="grayMediumDark" weight="500">
                        {pillar.name}
                      </Label>
                    </Touchable>
                  )
                })}
              </View>
            </>
          )}
          {categories.size > 0 && (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: pillars.size ? 0 : 40,
                  marginBottom: 24,
                  alignItems: 'center',
                }}
              >
                <Title>{pillars.size > 0 ? t`otherCauseAreas` : t`causeAreas`}</Title>
                <Label colour="gray01" marginLeft={5}>
                  ({categories.size})
                </Label>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 18,
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {categories.toArray().map((category) => {
                  const selected = selectedInterests.has(category.id)
                  return (
                    <Touchable
                      key={category.id}
                      onPress={() => actions.toggleSelectedInterestAction(category.id)}
                      style={{
                        flexBasis: 'auto',
                        marginRight: 11,
                        marginBottom: 12,
                        borderRadius: 8,
                        paddingHorizontal: 16,
                        justifyContent: 'center',
                        height: 36,
                        borderColor: selected ? 'transparent' : colors.grayMediumDark,
                        backgroundColor: selected ? colors.grayLight : 'transparent',
                        borderWidth: 1,
                      }}
                    >
                      <Label colour="grayMediumDark" weight="500">
                        {category.name}
                      </Label>
                    </Touchable>
                  )
                })}
              </View>
            </>
          )}
        </Onboarding>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    categories: selectCategories(state),
    pillars: selectPillars(state),
    // SDGs: selectSDGs(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    selectedInterests: selectSelectedInterests(state),
    // expandSDGs: selectExpandSDGs(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerInterests', reducer })
const withEpics = injectEpics({ key: 'registerInterests', epics })

export default compose(
  withReducer,
  withEpics,
  withConnect,
  withRouter,
  withTranslation('interestsRegister')
)(RegisterInterests)
