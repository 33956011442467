import React from 'react'
import { useTranslation } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import { Checkbox, Touchable } from 'src/retired/elements'
import truthy from 'src/utils/truthy'
import { Body1 } from 'src/retired/shared/Typography'

import { type AnyProviderData } from '../payment/useProviderData'

export type DonorPrivacy = null | 'ProvideAll' | 'ProvideNameAndEmailOnly' | 'Anonymous'
interface PrivacyProps {
  providerData: AnyProviderData
  selected: string
  onChange: (key: DonorPrivacy) => void
}

const Privacy = ({ providerData, selected, onChange }: PrivacyProps): JSX.Element => {
  const { t } = useTranslation('donateScreen')

  const receipt =
    providerData.donationProvider === DonationProvider.Stripe ||
    (providerData.donationProvider === DonationProvider.PayPal && providerData.payPalModel === 'DIRECT')

  const privacyOptions = [
    providerData.donationProvider === DonationProvider.NFG && {
      key: 'ProvideAll' as const,
      description: t`myFullContactInformation`,
    },
    {
      key: 'ProvideNameAndEmailOnly' as const,
      description: t('myNameAndEmailAddressOnly', {
        context: receipt ? 'receipt' : '',
      }),
    },
    {
      key: 'Anonymous' as const,
      description: t('noneOfMyPersonalInformation', { context: receipt ? 'receipt' : '' }),
    },
  ].filter(truthy)
  return (
    <>
      {privacyOptions.map(({ key, description }) => (
        <Touchable key={key} onPress={() => onChange(key)}>
          <Checkbox
            key={key}
            asRadio
            checked={key === selected}
            onChange={() => {
              onChange(key)
            }}
          >
            <Body1>{description}</Body1>
          </Checkbox>
        </Touchable>
      ))}
    </>
  )
}

export default Privacy
