import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useFormik } from 'formik'
import { View } from 'react-primitives'
import { endOfDay, startOfYear, subYears, isValid } from 'date-fns'
import { useTranslation, Trans } from 'react-i18next'
import { baseCurrencies } from '@joindeed/currency'
import { PaymentMethodType } from '@joindeed/calculate-fees'

import type { State } from 'src/reducers'
import { css, styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import {
  ActivityIndicator,
  Checkbox,
  Divider,
  ExternalLink,
  Form,
  Loading,
  TextField,
  Screen,
  ScrollView,
  Spacing,
  Row,
} from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import {
  currencies,
  useCurrency,
  CurrencyCode,
  currenciesFormat,
  CurrencySelect,
} from 'src/containers/modules/CurrencyFormat'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
// eslint-disable-next-line import/named
import SelectBox, { Option } from 'src/retired/shared/SelectBox'
import { Label } from 'src/retired/shared/Typography'
import Chip from 'src/retired/shared/Chip'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { formatNumber } from 'src/utils/format'
import { Platform, validators } from 'src/utils'
import { computeDonationProviderForNonprofit } from 'src/containers/screens/Donate/computeDonationProviderForNonprofit'
import { getLanguageCode } from 'src/utils/languageUtils'
import { ErrorText } from 'src/components/ErrorText'
import CurrencyInput from 'src/components/CurrencyInput'
import { roundedInputStyle } from 'src/components/Input/roundedInputStyles'
import useDebounce from 'src/utils/useDebounce'
import { Tooltip } from 'src/components/Tooltip/Tooltip'
import { MatchedAmountBlock } from 'src/components/MatchedAmountBlock/MatchedAmountBlock'
import FileUploader from 'src/components/FileUpload/FileUploader'
import { type MatchableRule } from 'src/entities/donation/matchableRule'
import { useMatchData } from 'src/containers/screens/Donate/match'

import { selectCampaignsLimited, selectEstimating, selectEstimation, selectSubmitting } from './selectors'
import {
  CampaignsByNonprofitPayload,
  MatchEstimation,
  estimateMatchAction,
  estimateMatchRuleChangeAction,
  fetchCampaignsByNonprofitAction,
  getNonprofitAction,
  submitExternalDonationAction,
} from './actions'
import reducer from './reducer'
import epics from './epics'
import Header from './Header'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '195px' : '15px')};
  margin-bottom: 50px;
`

export interface FormikDataProps {
  nonprofit: string
  date: Date
  donationAmount: string
  donationAmountCurrency: CurrencyCode
  receipt: string
  designation?: string
  campaignId?: string | null
  dedication?: string
  confirmation: boolean
  desiredMatchAmount: number | undefined
  desiredMatchingRule?: string
}

const LogOffPlatformDonationForm = (): JSX.Element => {
  useInjectReducer({ key: 'logOffPlatformDonation', reducer })
  useInjectEpics({ key: 'logOffPlatformDonation', epics })

  const { t, i18n } = useTranslation('logOffPlatformDonation')
  const dispatch = useDispatch()

  const { nonprofit: nonprofitId = '' } = useParams<{ nonprofit?: string }>()

  const user = useSelector(selectCurrentUser)
  const nonprofit = useSelector((state: State) => selectOrganizationById(state, nonprofitId)) || undefined
  const currency = useCurrency()
  const currencyUsd = useCurrency('USD')
  const submitting = useSelector(selectSubmitting)
  const brand = useSelector(selectUserBrand)

  const preferStripe = user?.organization?.settings?.preferStripe

  const { colors, icons } = useDeedTheme()

  useEffect(() => {
    if (!nonprofit) {
      dispatch(getNonprofitAction(nonprofitId))
    }
  }, [nonprofitId, nonprofit, dispatch])

  const initialValues = useMemo<FormikDataProps>(
    () => ({
      nonprofit: nonprofit?.name || '',
      date: new Date(),
      donationAmount: '',
      donationAmountCurrency: (nonprofit?.currencyCode as CurrencyCode) || currency.code || 'USD',
      receipt: '',
      designation: '',
      campaignId: null,
      dedication: '',
      confirmation: false,
      desiredMatchAmount: undefined,
      desiredMatchingRule: undefined,
    }),
    []
  )

  const formik = useFormik<FormikDataProps>({
    initialValues,
    validate: (values) => {
      const errors = {
        donationAmountNotEmpty: validators.notEmpty(values.donationAmount),
        donationAmount: validators.isNumber(values.donationAmount),
        receipt: validators.notEmpty(values.receipt),
        confirmation: values.confirmation ? null : t`common:required`,
        date: validators.notEmpty(values.date) || (isValid(values.date) ? null : t('pleaseEnterAValidDate')),
      }

      const isFormikValid = Object.values(errors).every((value) => !value)
      return isFormikValid ? {} : errors
    },
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(
        submitExternalDonationAction(values, nonprofitId, computeDonationProviderForNonprofit(nonprofit, preferStripe))
      )
    },
  })
  const selectedCurrencyCode = formik.values.donationAmountCurrency

  const debouncedAmount = useDebounce(formik.values.donationAmount, 1000)

  const estimating = useSelector(selectEstimating)
  const estimation = useSelector(selectEstimation)

  const onDesiredMatchedAmountChange = (value: number) => {
    void formik.setFieldValue('desiredMatchAmount', value)
  }

  const currencyRate = currency?.rates?.[selectedCurrencyCode] || 1

  const matchData = useMatchData({
    entity: estimation,
    netAmount: Number(debouncedAmount),
    user,
    baseCurrency: selectedCurrencyCode,
    donationPaymentMethod: PaymentMethodType.External,
    currency,
    currencyRate,
  })

  useEffect(() => {
    dispatch(
      estimateMatchAction({
        nonprofitId,
        amount: Number(debouncedAmount),
        currencyCode: selectedCurrencyCode,
        donationDate: formik.values.date,
      })
    )
  }, [nonprofitId, debouncedAmount, selectedCurrencyCode, formik.values.date])

  useEffect(() => {
    void formik.setFieldValue('nonprofit', nonprofit?.name || '')
    void formik.setFieldValue('donationAmountCurrency', nonprofit?.currencyCode || currency.code || 'USD')
  }, [nonprofit, currency.code])

  const desiredMatchingRule = matchData?.desiredMatchingRule
  const desiredMatchAmount = matchData?.matchedAmount

  useEffect(() => {
    void formik.setFieldValue('desiredMatchingRule', desiredMatchingRule)
    void formik.setFieldValue('desiredMatchAmount', desiredMatchAmount)
  }, [desiredMatchingRule, desiredMatchAmount])

  const onMatchingRuleOptionChange = (matchingRuleId: string) => {
    const selectedMatchingRule = estimation?.matchableRules?.[matchingRuleId] as MatchableRule
    if (!selectedMatchingRule) {
      return
    }
    const newMatchableAmount = (Number(debouncedAmount) > 0 && selectedMatchingRule.matchableAmount) || 0
    const newEstimation = {
      matchableAmountCurrencyCode: estimation?.matchableAmountCurrencyCode,
      matchableRules: estimation?.matchableRules,
      matchableRule: selectedMatchingRule,
      matchingBudgetId: selectedMatchingRule.budget,
      matchableAmount: newMatchableAmount,
      matchingMaximumCurrencies: selectedMatchingRule.matchingMaximumCurrencies,
      matchingPercentage: selectedMatchingRule.matchingPercentage,
    } as MatchEstimation
    dispatch(estimateMatchRuleChangeAction(newEstimation))
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const CurrencyFormat = () =>
    estimation?.matchingMaximumCurrencies ? (
      <>{currenciesFormat(estimation.matchingMaximumCurrencies, selectedCurrencyCode)}</>
    ) : null

  const currencySymbol = currencies[formik.values.donationAmountCurrency]
    ? currencies[formik.values.donationAmountCurrency].symbol
    : '$'

  const usdRates = currencyUsd.rates

  const timesMatchedCopy =
    estimation?.matchingPercentage &&
    estimation?.matchingMaximumCurrencies &&
    (estimation?.matchingMaximumCurrencies?.[selectedCurrencyCode] > 0 ||
      estimation?.matchingMaximumCurrencies?.[selectedCurrencyCode] === null)
      ? t('timesMatched', {
          matchPercentage: formatNumber(estimation.matchingPercentage / 100),
        }).toLocaleUpperCase(getLanguageCode(i18n.language))
      : undefined

  const selectedCampaignsByNonprofit = useSelector(selectCampaignsLimited)

  const campaignsByNonprofit = useMemo(() => {
    const campaigns = selectedCampaignsByNonprofit
      ?.toJS()
      .map(({ id, title }: CampaignsByNonprofitPayload) => ({ value: id, title })) as Option[]

    if (campaigns?.length === 1) {
      void formik.setFieldValue('campaignId', campaigns[0].value)
    }
    return campaigns
  }, [selectedCampaignsByNonprofit])

  useEffect(() => {
    if (nonprofit?.id && !nonprofit.id.includes('ext-')) {
      dispatch(fetchCampaignsByNonprofitAction(nonprofit.id))
    }
  }, [dispatch, nonprofit])

  return (
    <Screen fixed>
      <ScrollView>
        <Header userOrganization={user?.organization?.name} />
        <FormWrapper>
          <Divider style={{ paddingLeft: 15, paddingRight: 15 }} />
          {!user || !nonprofit ? (
            <Spacing marginBottom={30} marginTop={30}>
              <Loading fill={false} />
            </Spacing>
          ) : (
            <>
              {estimating ? (
                // Make the loader the same height as the Label, to prevent content jump
                <View style={{ height: 39 }}>
                  <ActivityIndicator color={colors.userOrganizationBrandColor || colors.coral} size="small" />
                </View>
              ) : (
                estimation?.matchingMaximumCurrencies && (
                  <Label
                    center
                    weight="500"
                    colour={colors.brandColor}
                    marginBottom={25}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {estimation.matchingMaximumCurrencies[selectedCurrencyCode] === null ? (
                      t('noLimitMatchingBalance', { currencyBalance: currencies[selectedCurrencyCode].symbol })
                    ) : (
                      <Trans
                        t={t}
                        i18nKey="yourRemainingMatchingBalance"
                        components={{
                          CurrencyFormat: <CurrencyFormat />,
                        }}
                      />
                    )}
                  </Label>
                )
              )}

              <Form onSubmit={formik.handleSubmit}>
                <View style={{ position: 'relative' }}>
                  <Label weight="500">{t`organization`}</Label>
                  <TextField
                    name="nonprofit"
                    disabled
                    value={formik.values.nonprofit}
                    style={css`
                      ${roundedInputStyle};
                      padding-right: 100px;
                    `}
                  />
                  {estimation?.matchingPercentage && (
                    <View style={{ position: 'absolute', top: 32, right: 15 }}>
                      <Chip type="square" backgroundColor="yellow" textColor="black">
                        {timesMatchedCopy}
                      </Chip>
                    </View>
                  )}
                </View>

                <Label weight="500">{t`originalDate`}</Label>
                <View style={{ marginBottom: 15, marginTop: 8 }}>
                  <DatePicker
                    minDate={subYears(startOfYear(new Date()), 1)}
                    maxDate={endOfDay(new Date())}
                    value={formik.values.date}
                    onChange={async (date) => formik.setFieldValue('date', date)}
                  />
                  {!!formik.errors.date && <ErrorText text={`${formik.errors.date}`} />}
                </View>
                <Label weight="500">{t`donationAmountLabel`}</Label>
                <Row style={{ alignItems: 'center' }}>
                  <View style={{ marginRight: 10, marginBottom: 15, marginTop: 8 }}>
                    <CurrencySelect
                      value={formik.values.donationAmountCurrency}
                      showcasedOptions={['USD', 'EUR']}
                      availableOptions={baseCurrencies}
                      onSelect={(value) => formik.setFieldValue('donationAmountCurrency', value)}
                    />
                  </View>
                  <View style={{ flexGrow: 1 }}>
                    <CurrencyInput
                      name="donationAmount"
                      onValueChange={(value) => {
                        formik.setFieldValue('donationAmount', value)
                      }}
                      value={formik.values.donationAmount}
                      currencySymbol={currencySymbol}
                    />
                  </View>
                </Row>
                <Row>
                  {!!formik.errors.donationAmount && (
                    <ErrorText
                      text={formik.errors.donationAmount}
                      style={{ paddingLeft: Platform.OS === 'web' ? 90 : 110, paddingTop: 5 }}
                    />
                  )}
                </Row>
                {!!estimation?.matchingPercentage && !!usdRates?.USD && matchData && Number(debouncedAmount) > 0 && (
                  <Row>
                    <MatchedAmountBlock
                      matchData={matchData}
                      netAmount={Number(debouncedAmount)}
                      baseCurrency={selectedCurrencyCode}
                      customCurrency={selectedCurrencyCode}
                      customMatchCopy={timesMatchedCopy}
                      brandImage={brand?.mainPicture || undefined}
                      usdRates={usdRates}
                      onMatchingRuleOptionChange={onMatchingRuleOptionChange}
                      onDesiredMatchedAmountChange={onDesiredMatchedAmountChange}
                    />
                  </Row>
                )}

                <Spacing marginBottom={20} />

                {campaignsByNonprofit.length > 0 && (
                  <View style={{ flexGrow: 1, marginBottom: 16 }}>
                    <Row>
                      <Label weight="500">{t`associatedCampaign`}</Label>
                      <Tooltip
                        title={t`associatedCampaignTooltip`}
                        hoverIcon={icons.infoCircleOutlinedBlack}
                        placement="right"
                      />
                    </Row>

                    <SelectBox
                      value={formik.values.campaignId}
                      onSelect={(value) => {
                        void formik.setFieldValue('campaignId', value)
                      }}
                      options={campaignsByNonprofit}
                      showSearch
                      allowClear
                      onDeselect={() => {
                        void formik.setFieldValue('campaignId', null)
                      }}
                      style={{ marginLeft: 0, marginTop: 8 }}
                    />
                  </View>
                )}

                <View style={{ flexGrow: 1, marginVertical: 10 }}>
                  <Row>
                    <Label weight="500">{t`designationOptional`}</Label>
                    <Tooltip
                      title={t`designationTooltip`}
                      hoverIcon={icons.infoCircleOutlinedBlack}
                      placement="right"
                    />
                  </Row>

                  <TextField
                    name="designation"
                    onChangeText={(name: string, value: string) => {
                      void formik.setFieldValue(name, value)
                    }}
                    style={roundedInputStyle}
                    maxLength={100}
                  />
                </View>

                <View style={{ flexGrow: 1, marginVertical: 10 }}>
                  <Row>
                    <Label weight="500">{t`dedicationOptional`}</Label>
                    <Tooltip title={t`dedicationTooltip`} hoverIcon={icons.infoCircleOutlinedBlack} placement="right" />
                  </Row>

                  <TextField
                    name="dedication"
                    onChangeText={(name: string, value: string) => {
                      void formik.setFieldValue(name, value)
                    }}
                    style={roundedInputStyle}
                    maxLength={100}
                  />
                </View>
                <Label weight="500">{t`uploadYourReceiptLabel`}</Label>
                <FileUploader
                  returnFileUrl={(fileUrl) => {
                    void formik.setFieldValue('receipt', fileUrl)
                  }}
                  objectPrefix={user.id}
                />
                <Divider style={{ paddingLeft: 15, paddingRight: 15 }} />
                <Checkbox
                  name="confirmation"
                  checked={formik.values.confirmation}
                  onChange={async () => formik.setFieldValue('confirmation', !formik.values.confirmation)}
                >
                  {user?.organization?.faqLink ? (
                    <Label>
                      <Trans
                        t={t}
                        i18nKey="iHaveNotReceivedARewardForThisDonationAndThatMyDonationAligns"
                        values={{ donationRequirements: t`donationMatchingRequirements` }}
                        components={{
                          ExternalLink: (
                            <ExternalLink
                              color={colors.brandColor}
                              href={user.organization.faqLink}
                              style={{ textDecoration: 'underline' }}
                            />
                          ),
                        }}
                      />
                    </Label>
                  ) : (
                    <Label>{t`iConfirmThatIHaveNotReceivedAReward`}</Label>
                  )}
                </Checkbox>
                <Spacing marginTop={30}>
                  <Button
                    palette="primary"
                    type="submit"
                    onPress={formik.handleSubmit}
                    disabled={!formik.isValid}
                    loading={submitting}
                    style={{ justifyContent: 'center' }}
                  >
                    {t`logOffPlatformDonation`}
                  </Button>
                </Spacing>
              </Form>
            </>
          )}
        </FormWrapper>
      </ScrollView>
    </Screen>
  )
}

export default LogOffPlatformDonationForm
