import { AUserClickedDonateToANonprofit } from '@joindeed/analytics-schema/dist/types/donations/userClickedDonateToNonprofit.schema'
import { EventContext } from '@joindeed/analytics-schema/dist/types/baseContext.schema'

import { getAnalyticsContext } from 'src/utils/analyticsContext'
import metricsClient from 'src/metrics/client'

export const makeUserClickedDonateEvent = ({
  nonprofitId,
  userId,
}: {
  nonprofitId: string
  userId?: string | undefined
}): AUserClickedDonateToANonprofit => ({
  version: 1,
  event: 'donations:intent:user-clicked-donate-nonprofit',
  userId: userId || null,
  context: getAnalyticsContext({ userId }) as EventContext,
  properties: {
    nonprofitId,
  },
})

export const track = async (event: object) => {
  const eventName = 'donations:intent:user-clicked-donate-nonprofit'
  await metricsClient.track(eventName, event)
}
