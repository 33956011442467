export const SectionsSorting = {
  fundraisers: 'Fundraisers',
  volunteering: 'Volunteering',
  events: 'Events',
  nonprofits: 'Nonprofits',
} as const

export const defaultSectionsSorting = [
  SectionsSorting.fundraisers,
  SectionsSorting.nonprofits,
  SectionsSorting.volunteering,
  SectionsSorting.events,
]
