// eslint-disable-next-line @typescript-eslint/no-redeclare
/* global window */
import React, { useEffect } from 'react'

import { withRouter } from 'src/navigation'

interface Location {
  hash: string
  pathname: string
  search: string
  state: string | undefined
}
interface ScrollToTopProps {
  children?: React.ReactNode
  location: Location
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children, location: { pathname } }): React.ReactElement => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <>{children}</>
}

export default withRouter(ScrollToTop)
