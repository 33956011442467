/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Touchable, Row, Image, AlignedImage } from 'src/retired/elements'
import { withRouter, Link } from 'src/navigation'
import { colors, images } from 'src/theme'
import { Body1 } from 'src/retired/shared/Typography'
import { selectNonprofitsLoaded } from 'src/entities/organization/selectors'
import { selectFilteredDeedsWithLocation, selectDeedTypeFeedLoading } from 'src/containers/screens/Feed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'

/* NOTICE build to target iPhone SE and tested around that */
const XS_SCREEN_MAX = 320

const Line = styled(View)`
  border-color: ${({ selected }) => (selected ? colors.black : colors.gray02)};
  ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'width: 100%; border-radius: 30px; border-width: 1px;' : '')}
`
const VerticalDivider = styled(View)`
  width: 1px;
  height: 32px;
  background-color: ${colors.gray02};
  margin: ${({ theme }) => (theme.metrics.screenWidth <= 375 ? '0 5px 0 10px' : '0 18px')};
  ${({
    theme: {
      metrics: { isSmall, screenWidth },
    },
  }) => (isSmall && screenWidth > XS_SCREEN_MAX ? ' ' : 'display: none;')};
`

const RowContainer = styled(Row)`
  ${({
    theme: {
      metrics: { isSmall, screenWidth },
    },
  }) =>
    isSmall
      ? screenWidth > XS_SCREEN_MAX
        ? 'border-bottom-width: 2px; padding-left: 20px; padding-right: 20px;'
        : 'border-bottom-width: 2px; padding-left: 12px; padding-right: 12px;'
      : ''};
  border-bottom-color: ${colors.gray02};
  align-items: center;
  height: 42px;
`

const LogoLink = styled(View)`
  ${({ theme }) => (theme.metrics.isSmall ? '' : 'display: none;')};
  ${({ theme }) =>
    theme.metrics.isSmall && theme.metrics.screenWidth > XS_SCREEN_MAX ? 'margin-top: -5px;' : 'margin-right:5px;'};
`

const TouchableType = styled(Touchable)`
  flex-grow: 0;
  ${({
    theme: {
      metrics: { isSmall },
    },
  }) => (isSmall ? 'margin-top: 10px; margin-bottom: -2px;' : 'margin-right: 25px; padding-bottom: 5px;')};
  border-bottom-color: ${colors.black};
  border-bottom-width: ${({
    theme: {
      metrics: { isSmall },
    },
    selected,
  }) => (isSmall ? '' : selected ? '2px' : '0')};
`

const TouchableText = styled(Body1)`
  ${({ theme }) => (theme.metrics.isSmall ? 'padding-bottom: 16px; text-align: center;' : '')};
  ${({
    theme: {
      metrics: { isSmall, screenWidth },
    },
  }) => (screenWidth <= 375 ? 'padding-horizontal: 8px;' : isSmall ? 'padding-horizontal: 25px' : '')};
`

const DeedTypeToggle = ({ feedTypes, history, match }) => {
  const { metrics } = useDeedTheme()

  const user = useSelector(selectCurrentUser)

  return (
    <RowContainer>
      <LogoLink>
        <Link to="/home">
          {user?.organization?.mainPicture && user?.status !== 'pending' ? (
            <AlignedImage
              width={metrics.screenWidth > XS_SCREEN_MAX ? 48 : 36}
              height={metrics.screenWidth > XS_SCREEN_MAX ? 29 : 21.75}
              source={{ uri: user.organization.mainPicture }}
            />
          ) : (
            <Image
              source={images.logoGraphicBlack}
              style={{
                width: metrics.screenWidth > XS_SCREEN_MAX ? 48 : 36,
                height: metrics.screenWidth > XS_SCREEN_MAX ? 29 : 21.75,
              }}
            />
          )}
        </Link>
      </LogoLink>
      {feedTypes && (
        <>
          <VerticalDivider />
          {Object.keys(feedTypes).map((path) => {
            const { label, deedType } = feedTypes[path]
            const count = useSelector((state) => selectFilteredDeedsWithLocation(state, deedType)).size
            const feedLoaded = useSelector((state) => selectDeedTypeFeedLoading(state, deedType))
            const nonprofitsLoaded = useSelector(selectNonprofitsLoaded)
            const selected = match.url.indexOf(path) === 0
            return (
              <TouchableType
                key={path}
                onPress={() => {
                  if (!selected) {
                    history.push(path)
                  }
                }}
                selected={selected}
              >
                <TouchableText colour={selected ? colors.black : colors.grayMediumDark}>
                  {label} {(deedType && feedLoaded) || nonprofitsLoaded ? `(${count})` : null}
                </TouchableText>
                <Line selected={selected}></Line>
              </TouchableType>
            )
          })}
        </>
      )}
    </RowContainer>
  )
}

DeedTypeToggle.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  feedTypes: PropTypes.object,
}

export default withRouter(DeedTypeToggle)
