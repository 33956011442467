import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'

import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { apolloClient } from 'src/entities/graphql'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { useUpdateVolunteerRewardMutation, useVolunteerRewardsQuery } from 'src/generated/graphql'
import i18n from 'src/i18n'
import { historyStack, useHistory, useParams } from 'src/navigation'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Action, Divider, LinkText, Loading, Screen, ScrollView, Alert } from 'src/retired/elements'
import Icon from 'src/retired/elements/Icon'
import { Body1, H4, H5, Label } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useInjectEpics, useInjectReducer } from 'src/utils'
import * as Sentry from 'src/utils/Sentry'
import { getLanguageCode } from 'src/utils/languageUtils'

import { TabType, getSearch } from '../../GeneralSearch/utils'
import organizationEpics from '../../Organization/epics'
import organizationReducer from '../../Organization/reducer'
import epics from '../VolunteerTime/epics'
import reducer from '../VolunteerTime/reducer'
import { initAction } from '../VolunteerTime/types'
import { Header } from '../VolunteerTimeForm'

import { ApplyForVolunteerRewardStep } from './ApplyForVolunteerRewardStep'
import { SubmitHoursStep } from './SubmitHoursStep'

const VolunteerTimeSuccess = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('volunteerTimeSuccessProfile')
  const { t: tVto } = useTranslation('editVolunteerTimeOffSuccess')

  const { colors, metrics } = useDeedTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const isVTO = history.location.pathname.includes('vto')

  useInjectEpics({ key: 'volunteerTime', epics })
  useInjectReducer({ key: 'volunteerTime', reducer })
  useInjectEpics({ key: 'organization', epics: organizationEpics })
  useInjectReducer({ key: 'organization', reducer: organizationReducer })

  const [notClaimLoading, setNotClaimLoading] = useState(false)

  const dismissScreenPath = historyStack.reverse().find((path: string) => !path.includes('volunteer-hours')) || ''

  const user = useSelector(selectCurrentUser)
  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')
  const volunteerRewardSettings = user?.organization?.settings?.volunteerReward

  const validationForm = volunteerRewardSettings?.validationForm

  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, id))
  const volunteeredDeed = volunteerTime?.deed
  const volunteeredNonprofit = volunteerTime?.nonprofit

  const fallbackNonprofitId = volunteerRewardSettings?.fallbackNonprofit
  const volunteeredNonprofitId = volunteeredNonprofit?.id
  const nonprofitWithDetails = useSelector((state) => {
    if (isVolunteerRewardEnabled) {
      return selectOrganizationById(state, volunteeredNonprofitId)
    }
  })
  const nonprofitAcceptsDonations = nonprofitWithDetails?.acceptsDonations

  const isNonprofitStepSkipped = !volunteeredDeed && !volunteeredNonprofit

  const { data: volunteerRewardData, loading: volunteerRewardLoading } = useVolunteerRewardsQuery({
    skip: Boolean(!user || !id || !isVolunteerRewardEnabled),
    variables: {
      take: 1,
      where: {
        user: { equals: user?.id },
        volunteerTime: { equals: id },
      },
      skipVolunteerTimeStatus: true,
    },
  })

  const [updateVolunteerRewardMutation] = useUpdateVolunteerRewardMutation({
    onError: (err: Error) => {
      Sentry.captureException(err)
    },
  })

  useEffect(() => {
    dispatch(initAction(id))
  }, [dispatch, id])

  const handleContinue = () => {
    if (validationForm) {
      history.push(`/profile/volunteer-hours/${id}/forms/${validationForm}`)
    } else {
      history.push(`/profile/volunteer-hours/${id}/result`)
    }
  }

  const handleNotClaim = async () => {
    const volunteerRewardId = volunteerRewardData?.volunteerRewards?.items[0]?.id
    if (!volunteerRewardId) {
      dispatch(showErrorAction(t('common:anErrorOccurred')))
    } else {
      setNotClaimLoading(true)
      await updateVolunteerRewardMutation({
        variables: {
          ids: [volunteerRewardId],
          data: { optedOut: true },
        },
      })
      setNotClaimLoading(false)
    }
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'volunteerTimes({"where":{"userId":{"equals":"me"}}})',
    })
    history.replace(dismissScreenPath)
  }

  const handleFindNextOpportunity = () => {
    history.push({
      pathname: '/search',
      search: getSearch({
        tab: TabType.volunteer,
      }),
    })
  }

  const volunteerRewardBudgeting = volunteerRewardData?.volunteerRewards?.items[0]?.budgetingData

  const showVolunteerReward =
    !isNonprofitStepSkipped &&
    isVolunteerRewardEnabled &&
    (nonprofitAcceptsDonations || fallbackNonprofitId) &&
    volunteerRewardBudgeting?.requestedVolunteerRewardAmountInMinutes &&
    volunteerRewardData?.volunteerRewards?.items[0].amount > 0

  const userComesFromProfilePage = historyStack.length > 1 && historyStack[1] === '/profile'

  if (volunteerRewardData?.volunteerRewards?.items[0]?.amount === 0) {
    Alert.alert(t`zeroHoursError`)
  }

  return (
    <Screen action safeArea={false}>
      <ScrollView>
        <NavigationHeader transparent back={!showVolunteerReward || userComesFromProfilePage} />
        <Header>
          <Label marginBottom={8} center weight="500" colour={colors.brandColor}>
            {t`logHours`.toLocaleUpperCase(getLanguageCode(i18n.language))}
          </Label>
          <H4 center marginBottom={8}>
            {isVTO ? tVto`thanksForSubmittingYourTimeOff` : t`thanksForSubmittingYourHours`}
          </H4>
          <Body1 center>
            <Trans
              t={isVTO ? tVto : t}
              i18nKey={isVTO ? 'yourVTOWillBeReflected' : 'yourHoursWillBeReviewedShortly'}
              components={{
                Link: (
                  <LinkText
                    size={16}
                    lineHeight={25}
                    color="grayMediumDark"
                    underline
                    to={isVTO ? '/profile/volunteer-time-off-hours' : '/profile'}
                  />
                ),
              }}
            />
          </Body1>
        </Header>
        <Divider style={{ marginLeft: 20, marginRight: 20 }} />

        {!user || volunteerRewardLoading ? (
          <View style={{ height: 100 }}>
            <Loading fill={false} backgroundColor="transparent" />
          </View>
        ) : showVolunteerReward ? (
          <View
            style={{
              width: 'fit-content',
              maxWidth: metrics.isSmall ? undefined : '80%',
              alignSelf: 'center',
              paddingHorizontal: 32,
              paddingBottom: 16,
              paddingVertical: metrics.isSmall ? 32 : 16,
            }}
          >
            <SubmitHoursStep volunteerRewardBudgeting={volunteerRewardBudgeting} />
            <ApplyForVolunteerRewardStep volunteerRewardBudgeting={volunteerRewardBudgeting} />
          </View>
        ) : (
          <View style={{ alignItems: 'center', paddingHorizontal: 32, paddingVertical: 16 }}>
            <Icon icon="womanStarIllustration" style={{ width: 125, height: 159, marginBottom: 32 }} />
            <H5 center>{t`successMessage`}</H5>
          </View>
        )}
      </ScrollView>
      <Action
        style={{
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        {showVolunteerReward && volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget ? (
          <View style={{ flexDirection: metrics.isSmall ? 'column' : 'row', justifyContent: 'center', columnGap: 16 }}>
            <Tooltip title={t`youCanApplyLater`} enterTouchDelay={0}>
              <Button
                variant="outlined"
                onClick={handleNotClaim}
                style={{ borderRadius: 32, color: 'black' }}
                disableElevation
              >
                {volunteerRewardLoading || notClaimLoading ? <ActivityIndicator /> : t`skipForNow`}
              </Button>
            </Tooltip>
            <Button variant="contained" onClick={handleContinue} style={{ borderRadius: 32 }} disableElevation>
              {validationForm ? t`ctaContinueApplyVolunteerReward` : t`ctaContinueClaimVolunteerReward`}
            </Button>
          </View>
        ) : (
          <Button
            variant={
              showVolunteerReward && !volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget
                ? 'outlined'
                : 'contained'
            }
            onClick={handleFindNextOpportunity}
            style={{
              borderRadius: 32,
              color:
                showVolunteerReward && !volunteerRewardBudgeting?.isVolunteerRewardAmountInPersonalBudget
                  ? 'black'
                  : undefined,
            }}
            disableElevation
          >{t`ctaFindYourNextOpportunity`}</Button>
        )}
      </Action>
    </Screen>
  )
}

export default VolunteerTimeSuccess
