import React from 'react'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'

import { H5 } from 'src/retired/shared/Typography'
import Deed from 'src/entities/deed/model'
import { useLocation } from 'src/navigation'
import { ShareIconsRow } from 'src/components/ShareIconsRow'
import { useDeedTheme } from 'src/theme/ThemeProvider'

interface ShareBlockProps {
  deed: Deed
  title?: string
  centered?: boolean
}

export const ShareBlock = ({ deed, title, centered = false }: ShareBlockProps): JSX.Element => {
  const { t } = useTranslation('deedScreen')
  const { search } = useLocation()
  const { metrics } = useDeedTheme()
  const campaignId = parse(search)?.campaign

  const nonprofits = deed.getNonprofits()

  const sharingMessage =
    deed.type === 'Campaign'
      ? t('helpSupport', { nonprofits: nonprofits.map((nonprofit) => nonprofit.name)?.join(', '), deedName: deed.name })
      : nonprofits.first()?.name
      ? t('volunteerWithMeNonprofit', { organizationName: nonprofits.first()?.name, deedName: deed.name })
      : t('volunteerWithMe', { deedName: deed.name })

  const shareLink = `${deed.shareLink}${campaignId ? `?campaign=${campaignId}` : ''}`

  return (
    <>
      <H5
        weight="500"
        style={{
          flex: 1,
          marginBottom: 16,
          paddingHorizontal: metrics.isSmall ? 15 : 0,
          textAlign: centered ? 'center' : 'left',
        }}
      >
        {title ?? t`shareThisDeedWithFriends`}
      </H5>

      <ShareIconsRow shareLink={shareLink} sharingMessage={sharingMessage} centered={centered} />
    </>
  )
}
