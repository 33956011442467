import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { Action, ActivityStatus } from 'src/containers/modules/Actions/types'
import { NoParticipants } from 'src/containers/modules/AvatarRow'
import { Spacing } from 'src/retired/elements'
import { OutlineButton } from 'src/retired/elements/Button/OutlineButton'
import { Body1 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { NUM_INITIAL_ACTIVITIES } from '../constants'

import { ActivitiesList } from './ActivitiesList'
import { ActivitiesModal } from './ActivitiesModal'

const SeeAllButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useTranslation('actions')
  const { colors } = useDeedTheme()

  return (
    <View style={{ display: 'flex', alignItems: 'flex-start' }}>
      <OutlineButton hexColor={colors.gray01} onPress={onPress} small>
        <Body1 colour={colors.black}>{t('seeAll')}</Body1>
      </OutlineButton>
    </View>
  )
}

export const ActivitiesContainer = ({ action }: { action: Action }) => {
  const { t } = useTranslation('actions')
  const [activitiesModalVisible, setActivitiesModalVisible] = useState(false)
  const activities = (action?.Activities ?? []).filter(({ status }) => status !== ActivityStatus.Left)
  const limit = NUM_INITIAL_ACTIVITIES
  const { colors, metrics } = useDeedTheme()

  return (
    <View
      style={{
        width: '100%',
        maxHeight: 400,
        padding: 24,
        borderRadius: 24,
        borderWidth: 1,
        borderColor: colors.gray02,
        backgroundColor: colors.white,
      }}
    >
      {activities.length ? (
        <>
          <ActivitiesList activities={activities} limit={limit} />
          {(action.activitiesCount || 0) > limit ? (
            <>
              <Spacing style={{ marginTop: 20 }}>
                <SeeAllButton onPress={() => setActivitiesModalVisible(true)} />
              </Spacing>
              <ActivitiesModal
                action={action}
                visible={activitiesModalVisible}
                onClose={() => setActivitiesModalVisible(false)}
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          <Body1 marginBottom={15}>{t`noOneHasJoined`}</Body1>
          <NoParticipants label={t(metrics.isSmall ? 'beFirst' : 'beFirstOne')} />
        </>
      )}
    </View>
  )
}
