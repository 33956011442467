import React, { useEffect, useState } from 'react'

import Tooltip from 'src/components/Tooltip/Tooltip'
import { Body1 } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'

const progressBlue = 'rgba(128, 195, 231, 1)'
const progressBlueLight = 'rgba(128, 195, 231, 0.48)'
export const progressOrange = 'rgba(255, 116, 0, 1)'
const progressOrangeLight = 'rgba(255, 116, 0, 0.55)'

const ProgressBar = ({
  progress1,
  progress2 = 0,
  progress2label,
  progress2labelTooltip,
  title,
}: {
  progress1: number // progress values should be percentage
  progress2?: number
  progress2label?: string
  progress2labelTooltip?: string
  title?: string
}) => {
  const [progressValue1, setProgressValue1] = useState(0)
  const [progressValue2, setProgressValue2] = useState(0)
  const [opacityLabel, setOpacityLabel] = useState(0)

  const progress2Total = progress1 + progress2
  const isBarAlreadyFull = progress1 === 100

  const isBarExceeded = progress2Total > 100 && !isBarAlreadyFull
  const progress2Color = isBarExceeded ? progressOrange : progressBlue
  const progress2ColorLight = isBarExceeded ? progressOrangeLight : progressBlueLight

  const styles = {
    progressBarContainer: {
      width: '100%',
      paddingBottom: '16px',
    },
    progressBar: {
      width: '100%',
      backgroundColor: '#EBEEF0',
      borderRadius: '10px',
      position: 'relative',
      height: '12px',
      overflow: 'hidden',
    },
    progressBarFill: {
      height: '100%',
      position: 'absolute',
      top: 0,
      transition: 'width 2s ease-in-out',
    },
    progressBarFill1: {
      backgroundColor: '#2E7D32',
      zIndex: 1,
      width: `${progressValue1}%`,
      borderRadius: progress2 ? 0 : '10px',
    },
    progressBarFill2: {
      zIndex: 0,
      borderRadius: '10px',
      width: `${progressValue2}%`,
      background: `repeating-linear-gradient(45deg,${progress2ColorLight},${progress2ColorLight} 5px, ${progress2Color}  5px, ${progress2Color} 10px)`,
    },
    progress2Label: {
      display: 'flex',
      width: 'fit-content',
      position: 'relative',
      top: '8px',
      left: `calc(${progress1 + progress2 / 2}% - 14px)`, // align label to the center of progress2
    },
    limitReachLine: {
      minHeight: '30px',
      borderRight: `1px dashed ${progress2Color}`,
      position: 'relative',
    },
    limitReachText: {
      color: progress2Color,
      marginRight: '8px',
      textAlign: 'right',
    },
    progressTopContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '30px',
      alignItems: 'center',
      paddingBottom: '4px',
    },
    opacityAnimation: {
      opacity: opacityLabel,
      transition: 'opacity 1s linear',
    },
  }

  useEffect(() => {
    setProgressValue1(progress1)
    setProgressValue2(progress2Total)

    const timer = setTimeout(() => {
      setOpacityLabel(1)
    }, 1000)

    return () => clearTimeout(timer)
  }, [progress1, progress2Total])

  return (
    <div style={styles.progressBarContainer}>
      <div style={styles.progressTopContainer}>
        <Body1>{title}</Body1>
        {isBarExceeded && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Body1 style={{ ...styles.limitReachText, ...styles.opacityAnimation }}>Limit reached</Body1>
            <div style={{ ...styles.limitReachLine, ...styles.opacityAnimation }} />
          </div>
        )}
      </div>

      <div style={styles.progressBar}>
        <div style={{ ...styles.progressBarFill, ...styles.progressBarFill1 }} />
        <div style={{ ...styles.progressBarFill, ...styles.progressBarFill2 }} />
      </div>
      {progress2label && !isBarExceeded && !isBarAlreadyFull ? (
        <div style={{ ...styles.progress2Label, ...styles.opacityAnimation }}>
          <Body1 style={{ marginRight: 4, color: colors.teal }}>{progress2label}</Body1>
          {progress2labelTooltip && (
            <Tooltip title={progress2labelTooltip} showInfoIcon placement="bottom" infoIconColor={colors.teal} />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ProgressBar
