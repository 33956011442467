import { INIT, INIT_SUCCESS, INIT_FAILED, CONFIRM, CONFIRM_FAILED } from './constants'

export const initAction = (id) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const confirmAction = (id, invites) => ({
  type: CONFIRM,
  id,
  invites,
})

export const confirmFailedAction = (error, errorCode) => ({
  type: CONFIRM_FAILED,
  error,
  errorCode,
})
