/** Externals */
import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useSelector, useDispatch } from 'react-redux'
import { parse, stringify, stringifyUrl } from 'query-string'
import { useTranslation, Trans, TFunction } from 'react-i18next'
import i18n from 'i18next'
import { Alert, Box } from '@mui/material'

import { stripTime } from 'src/utils/stripTime'
import { useDeedTheme } from 'src/theme/ThemeProvider'
/** Internals */
import { DonationProvider } from 'src/generated/graphql'
import { Spacing, AlignedImage, Divider, ExternalLink, Loading, Row, LinkButton } from 'src/retired/elements'
import IconButton from 'src/retired/shared/IconButton'
import { Label, H4, H3, Body2, H5, Body1 } from 'src/retired/shared/Typography'
import { useParams, useLocation, Link, Redirect } from 'src/navigation'
import { CurrencyFormat, useCurrency, currencies } from 'src/containers/modules/CurrencyFormat'
import { formatAmount } from 'src/utils/format'
import getFullDonationProviderName from 'src/utils/donationProviders'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import Chip from 'src/retired/shared/Chip'
import Layout from 'src/retired/blocks/Layout'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { selectDonationById } from 'src/entities/donation/selectors'
import { selectLastDonation } from 'src/containers/screens/Donate/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { frequencyOptions } from 'src/utils/recurringDonations'
import PostDonationFormBanner from 'src/retired/shared/PostDonationFormBanner'
import AffiliationBanner from 'src/retired/blocks/AffiliationBanner'
import truthy from 'src/utils/truthy'
import { PageTitle } from 'src/components'
import type { State } from 'src/reducers'
import type Donation from 'src/entities/donation/model'
import type Organization from 'src/entities/organization/model'
import {
  BASE_SPACING_UNIT,
  NOTICE_HIDE_SHARE_ICONS,
  CauseNameOnImage,
  Container,
  Detail,
  DetailRightPart,
  Details,
  LeftImage,
  LeftSide,
  Nonprofits,
  OverlayColor,
  RightSide,
  ShareIcons,
} from 'src/containers/screens/DonationComplete/elements'
import { NextPaymentDate } from 'src/containers/screens/DonationComplete/NextPaymentDate'
import { selectDonationScheduleById } from 'src/entities/donationSchedule/selectors'
import User from 'src/entities/user/model'
import DonationSchedule from 'src/entities/donationSchedule/model'
import { usePayrollRunFlatFileForDonationDate } from 'src/containers/screens/DonationScheduleDetails/useNextPayDate'
import { PayrollPayDate } from 'src/containers/screens/Donate/payroll/useIsPayrollEnabled'

import { initAction as initDonationScheduleAction } from '../DonationScheduleDetails/types'
import donationScheduleEpics from '../DonationScheduleDetails/epics'
import donationScheduleReducer from '../DonationScheduleDetails/reducer'

import { selectLoading, selectError } from './selectors'
import epics from './epics'
import reducer from './reducer'
import { initAction, key } from './types'

export const shareOptions = [
  {
    key: 'Public',
    description: i18n.t('donationCompleteScreen:shareMyNameAndAmountDonated'),
  },
  {
    key: 'Limited',
    description: i18n.t('donationCompleteScreen:shareMyNameButHideDonationAmount'),
  },
  {
    key: 'Anonymous',
    description: i18n.t('donationCompleteScreen:anonymous'),
  },
]

const Thx = ({
  user,
  donation,
  donationSchedule,
  t,
}: {
  user?: User
  donation?: Donation
  donationSchedule?: DonationSchedule
  t: TFunction<'donationCompleteScreen'>
}) => {
  const { colors } = useDeedTheme()
  const to =
    (donation && `/donations/${donation.id}`) || (donationSchedule && `/donations/recurring/${donationSchedule.id}`)
  const getI18nKey = () => {
    if (donation) {
      return to ? 'thanksForYourDonationUser' : 'thanksForYourDonation'
    }
    if (donationSchedule) {
      return to ? 'thanksForYourPledgeUser' : 'thanksForYourPledge'
    }
    return undefined
  }
  const i18nKey = getI18nKey()
  const userName = user?.fullName?.first || donation?.billingDetails?.name
  return (
    <H4 weight="500">
      {user && to ? (
        <Trans
          t={t}
          i18nKey={i18nKey}
          components={{
            DonationLink: (
              <Link style={{ color: colors.brandColor }} to={to}>
                \1
              </Link>
            ),
          }}
          values={{ userName }}
        />
      ) : (
        t('thanksForYourDonation', { userName })
      )}
    </H4>
  )
}

/** Screen */

const supportUrlMap: Record<string, string> = {
  Stripe: 'https://support.stripe.com',
  GlobalGiving: 'https://support.globalgiving.org',
  NFG: 'https://www.networkforgood.com/support',
  Betterplace: 'https://www.betterplace.org/c/help/topics/donors',
  PayPal: 'https://www.paypal.com/us/smarthelp/contact-us',
}

/** Screen */

const DonationComplete = (): JSX.Element => {
  const { t } = useTranslation('donationCompleteScreen')
  const { colors, metrics } = useDeedTheme()

  const dispatch = useDispatch()
  useInjectReducer({ key, reducer })
  useInjectEpics({ key, epics })

  useInjectReducer({ key: 'donationSchedule', reducer: donationScheduleReducer })
  useInjectEpics({ key: 'donationSchedule', epics: donationScheduleEpics })

  const {
    donation: donationId,
    donationSchedule: donationScheduleId,
    lite,
  } = useParams<{ donation?: string; donationSchedule?: string; lite: string }>()
  const isPledge = !!donationScheduleId
  const { search } = useLocation()
  const campaignId = parse(search).campaign

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const lastDonation = useSelector(selectLastDonation)
  const donationFromSelector =
    useSelector((state: State) => donationId && selectDonationById(state, donationId)) || undefined
  const donationScheduleFromSelector =
    useSelector((state: State) => donationScheduleId && selectDonationScheduleById(state, donationScheduleId)) ||
    undefined

  const donation: Donation | undefined =
    (donationFromSelector?.toJS?.() as Donation | undefined) || donationFromSelector || lastDonation || undefined
  const donationSchedule = donationScheduleFromSelector || donation?.donationSchedule

  const donationEntity = donation || donationSchedule
  const donationCurrency = donation?.currency || donationSchedule?.currencyCode
  const campaign = donation?.campaign || donationSchedule?.campaign
  const brand = useSelector(selectUserBrand)

  useEffect(() => {
    if (isAuthenticated && donationId) {
      dispatch(initAction(donationId))
    }
  }, [dispatch, donationId, isAuthenticated])

  useEffect(() => {
    if (isAuthenticated && donationScheduleId) {
      dispatch(initDonationScheduleAction(donationScheduleId))
    }
  }, [dispatch, donationScheduleId, isAuthenticated])

  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const user = useSelector(selectCurrentUser)

  const currency = useCurrency()

  const { payrollRunFlatFile } = usePayrollRunFlatFileForDonationDate(donationScheduleFromSelector?.startingAt)

  const payrollPayDate: Date | undefined =
    payrollRunFlatFile?.payDate ||
    payrollRunFlatFile?.endDate ||
    (donation?.paymentMethodDetails?.type === 'Payroll' &&
      (donation?.payrollRun?.payDate || donation?.payrollRun?.endDate) &&
      new Date(stripTime(donation.payrollRun.payDate || donation.payrollRun.endDate))) ||
    undefined
  if (error) {
    if (error.message === 'Not Found') {
      return <Redirect to="/profile/donations" />
    }
    return <ErrorScreen>{t`thereWasAnErrorLoadingThePage`}</ErrorScreen>
  }

  if (isAuthenticated && donationId && (!donation || !user)) {
    return loading || !user ? <Loading /> : <ErrorScreen />
  }

  if (!isAuthenticated && donationId && !donation) {
    return <Redirect to={campaignId ? `${lite ? '/lite' : ''}/campaign/${campaignId}` : '/'} />
  }

  if (!donationEntity) {
    return <Loading />
  }

  const deed = donation?.deed
  const donor = donation?.user
  const processingFee = donation?.processingFee
  const totalPaymentAmount = (donation?.amount ?? 0) + (donation?.processingFee ?? 0)
  const provider = donation?.provider || donationSchedule?.provider
  const nonprofits = donation?.nonprofits || donationSchedule?.nonprofits
  const nonprofitsArray = nonprofits ? ('toArray' in nonprofits ? nonprofits.toArray?.() : nonprofits) : []
  const corporateProgram = donation?.corporateProgramId

  if (isAuthenticated && donationId && donor?.id !== user?.id) {
    return <Redirect to="/" />
  }

  const firstNonprofit: Organization | undefined = nonprofitsArray[0]

  const entity = deed || firstNonprofit

  const picture = entity?.mainPicture

  const supportUrl = supportUrlMap[provider as string]
  const currencyCode = donation?.currency.toUpperCase() || 'USD'
  const baseCurrency = currencies[currencyCode]

  const payPalModel = firstNonprofit?.payPalModel
  const payPalCountryCode = firstNonprofit?.locationObject?.countryCode
  const payPalCountry = firstNonprofit?.locationObject?.country
  const donationProviderName =
    provider && getFullDonationProviderName(provider, payPalCountryCode === 'US' ? '' : payPalCountry)

  const nonprofitNames = nonprofitsArray?.map((n) => n?.name).filter(truthy)

  const corporateProgramPostDonationText = corporateProgram?.metadata?.postDonation?.text
  const corporateProgramPostDonationUrl = corporateProgram?.metadata?.postDonation?.url

  return (
    <Layout narrow padding withoutHeader>
      <PageTitle title={t`donationCompleted`} />

      <Container>
        <LeftSide>
          <LeftImage source={{ uri: picture }} />
          <OverlayColor />
          <CauseNameOnImage>
            {brand?.mainPicture ? (
              <AlignedImage
                width="100%"
                height={metrics.isSmall ? 60 : 90}
                source={{ uri: brand.mainPicture }}
                style={{ margin: 'auto' }}
              />
            ) : (
              <H3 colour={colors.black} weight="500" center>
                {entity?.name}
              </H3>
            )}
          </CauseNameOnImage>
        </LeftSide>

        <RightSide>
          <Row
            style={{
              flexDirection: metrics.isSmall ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <View style={{ flex: 1, marginRight: 20 }}>
              <Thx t={t} user={user} donation={donation} donationSchedule={donationSchedule} />
            </View>

            <Spacing marginTop={metrics.isSmall ? 20 : 0} />

            {donation && (
              <LinkButton
                fluid
                size="small"
                color="primary"
                to={
                  donation.nextDonationRecommendationLink
                    ? stringifyUrl({
                        url: donation.nextDonationRecommendationLink,
                        query: { campaign: campaignId, prevDonation: donation.id },
                      })
                    : `/donate?${stringify({ prevDonation: donation.id })}`
                }
              >
                {t`donateAgain`}
              </LinkButton>
            )}
          </Row>

          <Spacing marginTop={32} marginBottom={24} />

          {donation?.paymentMethodDetails.type === 'DeedCredit' ? (
            <Body2 marginBottom={24}>
              {t('deedCredit', {
                baseCurrencySymbol: baseCurrency?.symbol,
                balance: formatAmount(
                  user?.donationCreditsWallets?.[donation?.currency]?.balance,
                  baseCurrency?.zeroDecimal
                ),
              })}
            </Body2>
          ) : (provider === DonationProvider.PayPal && payPalModel === 'DIRECT') ||
            provider === DonationProvider.Stripe ? (
            <Body2 marginBottom={24}>
              <Trans
                t={t}
                i18nKey="youWillReceiveAReceipt"
                components={{
                  ExternalLink: <ExternalLink bold color={colors.brandColor} size={15} underline href={supportUrl} />,
                }}
                values={{
                  linkUrl: supportUrl.replace('https://', ''),
                }}
              />
            </Body2>
          ) : corporateProgram && corporateProgramPostDonationUrl ? (
            <Body2 marginBottom={24}>
              <Trans
                i18nKey={corporateProgramPostDonationText || ''}
                components={{
                  ExternalLink: (
                    <ExternalLink
                      bold
                      color={colors.brandColor}
                      size={15}
                      underline
                      href={corporateProgramPostDonationUrl}
                    />
                  ),
                }}
                values={{
                  linkUrl: corporateProgramPostDonationUrl.replace('https://', ''),
                }}
              />
            </Body2>
          ) : supportUrl ? (
            <Body2 marginBottom={24}>
              <Trans
                t={t}
                i18nKey="youWillReceiveAReceiptFromProviderName"
                components={{
                  ExternalLink: <ExternalLink bold color={colors.brandColor} size={15} underline href={supportUrl} />,
                }}
                values={{
                  linkUrl: supportUrl.replace('https://', ''),
                  donationProviderName,
                }}
              />
            </Body2>
          ) : null}

          {donation?.paymentMethodDetails?.type !== 'Payroll' &&
            donation?.provider === 'PayPal' &&
            donation?.status === 'Pending' && (
              <Box paddingTop={1} paddingBottom={3} maxHeight={300} style={{ overflowY: 'auto' }}>
                <Alert severity="warning" sx={{ '& .v5-MuiAlert-message': { marginTop: '2px' } }}>
                  <Trans
                    t={t}
                    i18nKey="yourDonationIsPending"
                    components={{
                      ExternalLinkSupport: (
                        <ExternalLink
                          size={14}
                          underline
                          color={colors.black}
                          href="www.paypal.com/us/smarthelp/contact-us"
                        />
                      ),
                      ExternalLinkTeam: (
                        <ExternalLink size={14} underline color={colors.brand} href="mailto:support@joindeed.com" />
                      ),
                    }}
                    values={{
                      support: 'www.paypal.com/us/smarthelp/contact-us',
                      deedTeam: t`deedSupportTeam`,
                    }}
                  />
                </Alert>
              </Box>
            )}

          {donation && <PostDonationFormBanner donation={donation} />}

          <H5 weight="500" marginBottom={16}>
            {t`yourDonation`}
            {donation?.matchedAmount ? (
              <Spacing marginLeft={8} style={{ verticalAlign: 'middle' }}>
                <Chip type="square" backgroundColor="yellow" textSize={12}>
                  {t`matched`}
                </Chip>
              </Spacing>
            ) : null}
          </H5>

          <Details>
            {[
              donation?.billingDetails?.name && { key: t`billingName`, value: donation?.billingDetails?.name },
              donation?.billingDetails?.address?.line1 && {
                key: t`common:address`,
                value: donation?.billingDetails?.address?.line1,
              },
              donation?.billingDetails?.address?.city && {
                key: t`common:city`,
                value: donation?.billingDetails?.address?.city,
              },
              donation?.billingDetails?.address?.state && {
                key: t`common:stateProvince`,
                value: donation?.billingDetails?.address?.state,
              },
              donation?.billingDetails?.address?.postal_code && {
                key: t`common:zipPostalCode`,
                value: donation?.billingDetails?.address?.postal_code,
              },
              donation?.billingDetails?.email && { key: t`common:email`, value: donation?.billingDetails?.email },
              donation?.billingDetails?.phone && { key: t`common:phone`, value: donation?.billingDetails?.phone },
              donationEntity?.amount && {
                key: t`totalDonationAmount`,
                value: (
                  <>
                    {baseCurrency?.symbol}
                    {formatAmount(donationEntity?.amount, baseCurrency.zeroDecimal)}
                    {currency.code !== donationCurrency && (
                      <>
                        {' '}
                        <Trans
                          t={t}
                          i18nKey="totalDonationAmount"
                          components={{
                            totalDonationAmount: (
                              <CurrencyFormat baseCurrency={donationCurrency} amount={donationEntity?.amount} />
                            ),
                          }}
                        />
                      </>
                    )}
                  </>
                ),
              },
              (processingFee || user?.feeCovered) && {
                key: t`feeAmount`,
                value: processingFee ? (
                  <>
                    {baseCurrency?.symbol}
                    {formatAmount(processingFee, baseCurrency?.zeroDecimal)}
                    {currency.code !== donation?.currency && (
                      <>
                        {' '}
                        <Trans
                          t={t}
                          i18nKey="feeAmount"
                          components={{
                            feeAmount: <CurrencyFormat baseCurrency={donation?.currency} amount={processingFee} />,
                          }}
                        />
                      </>
                    )}
                  </>
                ) : (
                  t('100%OfYourDonation', {
                    nonprofitNames,
                  })
                ),
              },
              totalPaymentAmount > donationEntity?.amount && {
                key: t`totalPaymentAmount`,
                value: (
                  <>
                    {baseCurrency?.symbol}
                    {formatAmount(totalPaymentAmount, baseCurrency.zeroDecimal)}
                    {currency.code !== donationCurrency && (
                      <>
                        {' '}
                        <Trans
                          t={t}
                          i18nKey="totalPaymentAmount"
                          components={{
                            totalPaymentAmount: (
                              <CurrencyFormat baseCurrency={donationCurrency} amount={totalPaymentAmount} />
                            ),
                          }}
                        />
                      </>
                    )}
                  </>
                ),
              },
              donation?.matchedAmount && {
                key: t`matchedAmount`,
                value: (
                  <>
                    {baseCurrency?.symbol}
                    {formatAmount(donation?.matchedAmount, baseCurrency?.zeroDecimal)}
                    {currency.code !== donation?.currency && (
                      <>
                        {' '}
                        <Trans
                          t={t}
                          i18nKey="estimatedMatched"
                          components={{
                            MatchedAmount: (
                              <CurrencyFormat baseCurrency={donation?.currency} amount={donation?.matchedAmount} />
                            ),
                          }}
                        />
                      </>
                    )}
                  </>
                ),
              },
              donationEntity?.privacy && {
                key: t`donationPrivacy`,
                value: shareOptions.find((o) => o.key === donationEntity?.privacy)?.description,
              },
              donationEntity?.dedication && { key: t`dedication`, value: donationEntity?.dedication },
              donationEntity?.designation && { key: t`designation`, value: donationEntity?.designation },
              donation?.paymentMethodDetails?.type && {
                key: t`methodOfPayment`,
                value: donation?.paymentMethodDetailsType,
              },
              donation?.billingDetails?.name &&
                donation?.paymentMethodDetails?.type !== 'Payroll' && {
                  key: t`nameOnCreditCard`,
                  value: donation?.billingDetails?.name,
                },
              donation?.paymentMethodDetails?.card?.last4 && {
                key: t`creditCardLast4`,
                value: donation?.paymentMethodDetails?.card?.last4,
              },
              {
                key: t`common:date`,
                value: t('date:weekdayDayMonthShort', { date: donation?.date || payrollPayDate }),
                ...((donation?.paymentMethodDetails?.type === 'Payroll' || payrollPayDate) && {
                  additionalInfo: payrollPayDate
                    ? t(`donationCompleteScreen:donationWillBeDeductedOn`, {
                        nextPaycheckDate: t('date:weekdayDayMonthShort', {
                          date: payrollPayDate,
                        }),
                      })
                    : t(`donationCompleteScreen:donationWillBeDeducted`),
                }),
              },
              {
                key: t`common:time`,
                value: t('date:time', { date: donation?.date || payrollPayDate || donationSchedule?.startingAt }),
              },
              donation && { key: t`referenceNumber`, value: donation?.receiptNumber || donation?.identifier },
              campaign && {
                key: t`common:campaign`,
                value: <Link to={`${lite ? '/lite' : ''}/campaign/${campaign.id}`}>{campaign.title}</Link>,
              },
              // { key: 'Authorization', value: '' },
              // { key: 'Authorization Code', value: '' },
            ]
              .filter(truthy)
              .map(({ key: optionKey, value, additionalInfo }) => (
                <React.Fragment key={optionKey}>
                  <Spacing marginTop={BASE_SPACING_UNIT * 2} />
                  <Detail>
                    <Body2 colour={colors.grayMediumDark} marginRight={10}>
                      {optionKey}
                    </Body2>
                    <DetailRightPart>
                      <Body1 weight="500" right>
                        {value}
                      </Body1>
                      {!additionalInfo && (
                        <Label colour={colors.grayMediumDark} right>
                          {additionalInfo}
                        </Label>
                      )}
                    </DetailRightPart>
                  </Detail>
                </React.Fragment>
              ))}
          </Details>

          <H5 weight="500" marginTop={BASE_SPACING_UNIT * 8} marginBottom={BASE_SPACING_UNIT * 4}>
            {donation?.donationSchedule ? t`recurringDonationDetails` : t`donationDetails`}
          </H5>

          <Details>
            {deed && (
              <Detail>
                <Body2 colour={colors.grayMediumDark} marginRight={10}>
                  {t`fundraiser`}
                </Body2>
                <Body1 weight="500">
                  <Link style={{ color: colors.brandColor }} to={`/deeds/${deed.id}`}>
                    {deed.name}
                  </Link>
                </Body1>
              </Detail>
            )}

            <Spacing marginTop={BASE_SPACING_UNIT * 3} />

            {nonprofitsArray.length > 0 && (
              <>
                <Detail>
                  <Body2 colour={colors.grayMediumDark} marginRight={10} marginBottom={13}>
                    {t('benefittingTheOrganization', { count: nonprofitsArray.length })}
                  </Body2>
                  <Nonprofits>
                    {nonprofitsArray.map((nonprofit) => (
                      <Spacing key={nonprofit.id} marginBottom={13}>
                        <OrganizationChip organization={nonprofit} link />
                      </Spacing>
                    ))}
                  </Nonprofits>
                </Detail>
                <Spacing marginTop={BASE_SPACING_UNIT * 2} />
              </>
            )}
          </Details>

          {donationSchedule && (
            <>
              <Spacing marginTop={BASE_SPACING_UNIT * 2} />
              <Detail>
                <Body2 colour={colors.grayMediumDark}>{t('amount')}</Body2>
                <Body1 weight="500" right>
                  <CurrencyFormat baseCurrency={donationSchedule?.currencyCode} amount={donationSchedule?.amount} />
                </Body1>
              </Detail>

              <Spacing marginTop={BASE_SPACING_UNIT * 2} />

              <Detail>
                <Body2 colour={colors.grayMediumDark}>{t`frequency`}</Body2>
                <Body1 weight="500" right>
                  {donation?.paymentMethodDetails?.type === 'Payroll'
                    ? t`everyPayroll`
                    : donationSchedule.frequency && frequencyOptions[donationSchedule.frequency].adverb}
                </Body1>
              </Detail>

              <Spacing marginTop={BASE_SPACING_UNIT * 2} />

              {donation ? (
                <NextPaymentDate donation={donation} />
              ) : (
                donationScheduleId &&
                donationSchedule.startingAt && (
                  <PayrollPayDate
                    donationDate={donationSchedule.startingAt}
                    render={({ payDate }) => (
                      <Detail>
                        <Body2 colour={colors.grayMediumDark}>{t`nextPayment`}</Body2>
                        <Body1 weight="500" right>
                          {t('date:weekdayDayMonthTimeShort', {
                            date: payDate,
                          })}
                        </Body1>
                      </Detail>
                    )}
                  />
                )
              )}

              <Spacing marginTop={BASE_SPACING_UNIT} />

              <Label colour={colors.grayMediumDark} right style={{ alignSelf: 'flex-end', maxWidth: 500 }}>
                {donation?.paymentMethodDetails?.type === 'Payroll' || isPledge ? (
                  <Trans
                    t={t}
                    i18nKey="yourCardWillBeChargedPayroll"
                    components={{
                      DonationAmount: <CurrencyFormat baseCurrency={donation?.currency} amount={donation?.amount} />,
                    }}
                  />
                ) : (
                  donationSchedule.startingAt && (
                    <Trans
                      t={t}
                      i18nKey="yourCardWillBeCharged"
                      values={{
                        dateofTheMonth: t(`date:dayMonthOrdinal`, { date: donationSchedule.startingAt }),
                      }}
                      components={{
                        DonationAmount: <CurrencyFormat baseCurrency={donation?.currency} amount={donation?.amount} />,
                      }}
                    />
                  )
                )}
              </Label>
            </>
          )}

          <Spacing marginTop={32} />

          {user?.hasFeature('employeeAffiliations') &&
            nonprofitsArray.map(
              (nonprofit) => nonprofit && <AffiliationBanner key={nonprofit.id} nonprofit={nonprofit} />
            )}

          <Spacing marginTop={32} />

          {provider === 'NFG' && (
            <>
              <Divider />

              <Spacing marginTop={BASE_SPACING_UNIT * 4} />

              <Body1>
                <Trans
                  t={t}
                  i18nKey="NFGDetails"
                  components={{
                    ExternalLinkPrivacy: (
                      <ExternalLink
                        size={14}
                        underline
                        color={colors.brand}
                        href="https://www.networkforgood.com/about/privacy/"
                      />
                    ),
                    ExternalLinkSupport: (
                      <ExternalLink
                        size={14}
                        underline
                        color={colors.black}
                        href="https://www.networkforgood.com/support/donor"
                      />
                    ),
                  }}
                  values={{
                    name: t`networkForGood`,
                    support: t`support`,
                  }}
                />
              </Body1>
            </>
          )}

          {provider === DonationProvider.Stripe && (
            <>
              <Divider />

              <Body1>
                <Trans
                  t={t}
                  i18nKey="StripeDetails"
                  components={{
                    OrgLink: <Link to={`/organization/${deed?.organization?.id || entity?.id}`}>\1</Link>,
                    ProfileLink: <Link to="/profile">\1</Link>,
                  }}
                  values={{
                    orgName: deed?.organization?.name || entity?.name,
                    email: donation?.billingDetails?.email || user?.email,
                    deedProfile: t`deedProfile`,
                  }}
                />
              </Body1>

              {!NOTICE_HIDE_SHARE_ICONS && (
                <ShareIcons>
                  <IconButton icon="instagramBlack" title={t`shareOnInstagram`} />
                  <Spacing marginRight={BASE_SPACING_UNIT * 2} />
                  <IconButton icon="facebookBlack" title={t`shareOnFacebook`} />
                </ShareIcons>
              )}
            </>
          )}
        </RightSide>
      </Container>
    </Layout>
  )
}

export default DonationComplete
