import { isAfter } from 'date-fns'

import {
  DonationStatus,
  PayrollRunFlatFileStateType,
  PayrollRunsFlatFileListQuery,
  usePayrollRunsFlatFileListQuery,
} from 'src/generated/graphql'
import Donation, { PayrollFlatFileDonation } from 'src/entities/donation/model'
import {
  isPayrollFlatFileDonation,
  parsePayrollRunDates,
  type ListedPayrollRunsFlatFileWithDates,
} from 'src/containers/screens/Donation/payroll/payrollFlatFile'
import { getNextPaymentDate, getNextPaymentDateFromLatestDonation } from 'src/utils/recurringDonations'
import DonationSchedule from 'src/entities/donationSchedule/model'

type PayrollRunFlatFile = PayrollRunsFlatFileListQuery['payrollRunsFlatFile'][number] & {
  startDate?: Date
  endDate: Date
  payDate?: Date
}

export const payrollRunFlatFileForDonationDate = <T extends Pick<PayrollRunFlatFile, 'runState' | 'endDate'>>(
  payrollRuns: T[],
  date: Date
) =>
  payrollRuns
    .filter((p) => p.runState === PayrollRunFlatFileStateType.New)
    .sort((a, b) => (isAfter(a.endDate, b.endDate) ? 1 : -1))
    .find((p) => isAfter(p.endDate, date))

const payrollRunFlatFileForDonation = <T extends Pick<PayrollRunFlatFile, 'id' | 'runState' | 'endDate'>>(
  donation: PayrollFlatFileDonation,
  payrollRuns: T[]
): T | undefined => {
  if (donation.payrollRunFlatFileId) {
    return payrollRuns.find((pr) => pr.id === donation.payrollRunFlatFileId)
  }

  if (donation.status === DonationStatus.Pending) {
    return payrollRunFlatFileForDonationDate(payrollRuns, donation.date)
  }
}

export const usePayrollRunFlatFileForDonation = (
  donation: Donation | undefined
): {
  loading: boolean
  error?: string
  payrollRunFlatFile?: ListedPayrollRunsFlatFileWithDates
} => {
  const skip = !donation || !isPayrollFlatFileDonation(donation)

  const payrollRunsFlatFileListQuery = usePayrollRunsFlatFileListQuery({ skip })

  if (skip) {
    return { loading: false }
  }

  const payrollRunsFlatFile = payrollRunsFlatFileListQuery.data?.payrollRunsFlatFile?.map(parsePayrollRunDates)

  return {
    loading: !skip && payrollRunsFlatFileListQuery.loading,
    error: payrollRunsFlatFileListQuery.error?.message,
    payrollRunFlatFile: payrollRunsFlatFile && payrollRunFlatFileForDonation(donation, payrollRunsFlatFile),
  }
}
export const usePayrollRunFlatFileForDonationDate = (
  date?: Date
): {
  loading: boolean
  error?: string
  payrollRunFlatFile?: ListedPayrollRunsFlatFileWithDates
} => {
  const payrollRunsFlatFileListQuery = usePayrollRunsFlatFileListQuery({ skip: !date })
  const payrollRunsFlatFile = payrollRunsFlatFileListQuery.data?.payrollRunsFlatFile?.map(parsePayrollRunDates)
  return {
    loading: payrollRunsFlatFileListQuery.loading,
    error: payrollRunsFlatFileListQuery.error?.message,
    payrollRunFlatFile: payrollRunsFlatFile && date && payrollRunFlatFileForDonationDate(payrollRunsFlatFile, date),
  }
}

const getActualNextPaymentDate = (donationSchedule: DonationSchedule, donationData: Donation | undefined) => {
  if (donationSchedule?.executionFailure?.length === 0) {
    return getNextPaymentDateFromLatestDonation(donationSchedule.startingAt, donationData)
  }
  return getNextPaymentDate(donationSchedule.startingAt)
}

export const getNextDonation = (donations?: Donation[]) =>
  donations?.filter((d) => d.status === DonationStatus.Pending).sort((a, b) => (a.date > b.date ? -1 : 1))[0]

export const useNextPayDate = (
  donationSchedule: DonationSchedule,
  donations: Donation[] | undefined
): {
  loading: boolean
  payDate?: Date
} => {
  const nextDonation = getNextDonation(donations)

  const payrollRunFlatFileQuery = usePayrollRunFlatFileForDonation(nextDonation)

  if (!donations) {
    return { loading: true }
  }

  if (nextDonation?.__t === 'PayrollFlatFileDonation') {
    return payrollRunFlatFileQuery.payrollRunFlatFile
      ? { loading: false, payDate: payrollRunFlatFileQuery.payrollRunFlatFile?.payDate }
      : { loading: payrollRunFlatFileQuery.loading }
  }

  const date = donationSchedule?.isPayroll()
    ? donations?.[0]?.payrollRun?.payDate
    : getActualNextPaymentDate(donationSchedule, donations && donations[donations.length - 1])

  return { loading: false, payDate: date && new Date(date) }
}
