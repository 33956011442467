import { fromJS } from 'immutable'

import {
  INIT_FAILED,
  CHECK_IN,
  CHECK_IN_SUCCESS,
  CHECK_IN_FAILED,
  DISABLED_CHECK_IN_CLICKED,
  SET_GEOLOCATION,
  SET_GEOLOCATION_WATCH_ID,
} from './constants'

export const initialState = fromJS({
  loading: false,
  geolocation: null,
  geolocationWatchId: null,
  error: null,
  disabledCheckInClicks: 0,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_FAILED:
      return state.merge({ error: action.error })

    case CHECK_IN:
      return state.merge({ loading: true, error: null })

    case CHECK_IN_SUCCESS:
      return state.set('loading', false)

    case CHECK_IN_FAILED:
      return state.merge({ loading: false, error: action.error })

    case DISABLED_CHECK_IN_CLICKED:
      return state.set('disabledCheckInClicks', state.get('disabledCheckInClicks') + 1)

    case SET_GEOLOCATION:
      return state.set('geolocation', action.geolocation)

    case SET_GEOLOCATION_WATCH_ID:
      return state.set('geolocationWatchId', action.geolocationWatchId)

    case 'RESET':
      return initialState

    default:
      return state
  }
}
