import { DonationProvider as DonationProvider1 } from '@joindeed/calculate-fees'

import { DonationProvider as DonationProvider2 } from 'src/generated/graphql'

const getFullDonationProviderName = (provider: DonationProvider1 | DonationProvider2, country: string): string =>
  ({
    [DonationProvider1.Stripe]: 'Stripe',
    [DonationProvider1.GlobalGiving]: 'GlobalGiving',
    [DonationProvider1.NFG]: 'Network for Good',
    [DonationProvider1.Betterplace]: 'betterplace.org',
    [DonationProvider1.PayPal]: `PayPal Giving Fund${country && ` ${country}`}`,
    [DonationProvider1.External]: 'External',
    [DonationProvider1.GiveIndia]: 'GiveIndia',
  }[provider])

export default getFullDonationProviderName
