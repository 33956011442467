// noinspection TypeScriptCheckImport @NOTE-RT: Suppress this for IntelliJ
/* eslint-disable import/no-unresolved */
import {
  APPLICATION_STAGE,
  DEED_LEGACY_API_URI,
  DEED_WEB_URI,
  DEED_GRAPH_API_URI,
  DEED_NONPROFIT_API_URI,
  DEED_LOGGED_TIME_API_URI,
  DEED_TIME_OFF_API_URI,
  DEED_PAYROLL_API_URI,
  GIVE_INDIA_API_URI,
  STRIPE_PUBLIC_KEY,
  STRIPE_PAY_MODE,
  BRAINTREE_TOKENIZER_KEY,
  BETTERPLACE_DOMAIN,
  PAYPAL_CLIENT_ID,
  SEGMENT_WRITE_KEY_WEB,
  SEGMENT_WRITE_KEY_NATIVE,
  SENTRY_DSN_WEB,
  SENTRY_DSN_NATIVE,
  SEGMENT_CDN,
  SEGMENT_API_PROXY,
  PAYPAL_ENV,
  STREAM_API_KEY,
  STREAM_APP_ID,
  SEGMENT_DEBUG,
  GOOGLE_MAPS_API_KEY,
  NFG_RECEIPT_URL,
  GLOBAL_GIVING_RECEIPT_URL,
  BETTER_PLACE_RECEIPT_URL,
  // @ts-expect-error @env is not a package
} from '@env'

import Platform from 'src/utils/Platform'

const config = {
  apiEndpoint: DEED_LEGACY_API_URI as string,
  webUrl: DEED_WEB_URI as string,
  graphApiUrl: DEED_GRAPH_API_URI as string,
  nonprofitApiUrl: DEED_NONPROFIT_API_URI as string,
  loggedTimeApiUrl: DEED_LOGGED_TIME_API_URI as string,
  timeOffApiUrl: DEED_TIME_OFF_API_URI as string,
  payrollApiUrl: DEED_PAYROLL_API_URI as string,
  giveIndiaApiUrl: GIVE_INDIA_API_URI as string,
  stripePubKey: STRIPE_PUBLIC_KEY as string,
  stripePayMode: STRIPE_PAY_MODE as string,
  braintreeTokenizerKey: BRAINTREE_TOKENIZER_KEY as string,
  betterplaceDomain: BETTERPLACE_DOMAIN as string,
  paypalClientId: PAYPAL_CLIENT_ID as string,
  segment: (Platform.OS !== 'web' ? SEGMENT_WRITE_KEY_NATIVE : SEGMENT_WRITE_KEY_WEB) as string,
  segmentCdn: SEGMENT_CDN as string,
  segmentApiProxy: SEGMENT_API_PROXY as string,
  sentryDsn: (Platform.OS !== 'web' ? SENTRY_DSN_NATIVE : SENTRY_DSN_WEB) as string,
  streamApiKey: STREAM_API_KEY as string,
  streamAppId: STREAM_APP_ID as string,
  environment: APPLICATION_STAGE as string,
  isProduction: APPLICATION_STAGE === 'production',
  isDemo: APPLICATION_STAGE === 'demo',
  isTesting: APPLICATION_STAGE === 'testing',
  isDevelopment: APPLICATION_STAGE === 'development',
  payPalEnv: PAYPAL_ENV as 'SANDBOX' | 'PRODUCTION',
  segmentDebug: SEGMENT_DEBUG === 'true',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY as string,
  nfgReceiptUrl: NFG_RECEIPT_URL as string,
  globalGivingReceiptUrl: GLOBAL_GIVING_RECEIPT_URL as string,
  betterplaceReceiptUrl: BETTER_PLACE_RECEIPT_URL as string,
}

export default config
