import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useDispatch } from 'react-redux'
import { FormikProvider, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Checkbox, Divider, Row, Spacing, Switch, TextField, Touchable } from 'src/retired/elements'
import SelectBox from 'src/retired/shared/SelectBox'
import { Text } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import Icon from 'src/retired/shared/Icon'
import { ErrorText } from 'src/components/ErrorText'

import { setStepAction } from '../actions'

const roundedInputStyle = css`
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  min-height: 40px;
  height: auto;
`
const paymentBlock = css`
  border-radius: 20px;
  border: 1px solid #ebeef0;
  padding: 11px 20px;
  margin-bottom: 10px;
`

const VolunteerDetails = ({ formik }) => {
  const { colors } = useDeedTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation('createDeedScreen')

  const [donationRequired, setDonationRequired] = useState(!!formik.values.donationText)
  const [payOnline, setPayOnline] = useState(!!formik.values.donationLink)

  const emptyRole = {
    name: '',
    description: '',
    requiredAmount: '',
  }
  const emptyQuestion = {
    isRequired: true,
    questionType: 'TextInput',
    title: '',
  }

  const questionTypes = [
    {
      value: 'TextInput',
      title: t(`shortAnswer`),
    },
    {
      value: 'TextArea',
      title: t(`longAnswer`),
    },
    {
      value: 'Checkbox',
      title: t(`checkbox`),
    },
    {
      value: 'MultipleChoice',
      title: t(`multipleChoice`),
    },
    {
      value: 'MultipleAnswer',
      title: t(`multipleAnswer`),
    },
  ]

  return (
    <>
      <Divider />
      {/* @NOTE-DP: we temporarily disable adding roles to shifts, see https://app.shortcut.com/deed/story/57459/update-employee-create-volunteer-event-flow-in-app */}
      {formik.values.roles?.length > 0 && (
        <>
          <Text fontSize={12} marginBottom={10}>
            {t`volunteerRoles`}
          </Text>
          <FormikProvider value={formik}>
            <FieldArray
              name="roles"
              render={(arrayHelpers) => (
                <>
                  {formik.values.roles.map((role, i) => (
                    <View key={i}>
                      <Touchable
                        style={{
                          alignItems: 'flex-end',
                          marginBottom: 10,
                          cursor: 'pointer',
                        }}
                        onPress={() => arrayHelpers.remove(i)}
                      >
                        <Icon icon="closeBlack" width={12} height={12} />
                      </Touchable>
                      <Row style={{ alignItems: 'center' }}>
                        <View style={{ flexGrow: 1 }}>
                          <Text fontSize={12}>{t`roleName`}</Text>
                          <TextField
                            name={`${role}.name`}
                            scrollEnabled={false}
                            value={role.name}
                            placeholder={t`50CharactersMax`}
                            style={roundedInputStyle}
                            onChangeText={(e, value) => formik.setFieldValue(`roles[${i}].name`, value)}
                          />
                        </View>

                        <Text
                          fontSize={12}
                          style={{ marginLeft: 20, marginRight: 10, marginTop: 8, flex: 3, flexWrap: 'wrap' }}
                        >
                          {t`peopleNeeded`}
                        </Text>

                        <View style={{ width: 80, marginTop: 15 }}>
                          <TextField
                            name={`${role}.requiredAmount`}
                            scrollEnabled={false}
                            value={role.requiredAmount}
                            style={roundedInputStyle}
                            onChangeText={(e, value) => formik.setFieldValue(`roles[${i}].requiredAmount`, value)}
                          />
                        </View>
                      </Row>
                      <Text fontSize={12}>{t`roleDescription`}</Text>
                      <TextField
                        name={`${role}.description`}
                        numberOfLines={5}
                        multiline
                        scrollEnabled={false}
                        value={role.description}
                        placeholder={t`1000CharactersMax`}
                        style={roundedInputStyle}
                        onChangeText={(e, value) => formik.setFieldValue(`roles[${i}].description`, value)}
                      />
                    </View>
                  ))}
                  <Button
                    style={{ marginBottom: 30, paddingRight: 15 }}
                    iconLeft="plus"
                    onPress={() => arrayHelpers.push(emptyRole)}
                  >
                    {t`addRole`}
                  </Button>
                </>
              )}
            />
          </FormikProvider>
        </>
      )}
      <Text fontSize={12}>{t`paymentInformation`}</Text>
      <Row style={{ marginTop: 10, marginBottom: 10, alignItems: 'center' }}>
        <Switch
          value={donationRequired}
          onValueChange={() => setDonationRequired(!donationRequired)}
          style={{ marginRight: 10 }}
          trackColor={{ true: colors.brandColor, false: colors.lightGray }}
          thumbColor={colors.white}
        />
        <Text fontSize={12}>{t`volunteerDonationRequired`}</Text>
      </Row>
      {donationRequired && (
        <View style={paymentBlock}>
          <Checkbox
            name="virtual"
            disabled={!donationRequired}
            checked={payOnline}
            style={{ paddingLeft: 0 }}
            onChange={(e) => setPayOnline(e.target.checked)}
          >
            <Text fontSize={12}>{t`canVolunteersPayOnline`}</Text>
          </Checkbox>
          <TextField
            scrollEnabled={false}
            value={formik.values.donationText}
            disabled={!donationRequired}
            placeholder={t`donationDescription`}
            style={roundedInputStyle}
            onChangeText={(e, value) => formik.setFieldValue('donationText', value)}
          />
          <TextField
            scrollEnabled={false}
            value={formik.values.donationLink}
            disabled={!payOnline}
            placeholder={t`onlinePaymentLink`}
            style={roundedInputStyle}
            onChangeText={(e, value) => formik.setFieldValue('donationLink', value)}
            onTouched={() => formik.setFieldTouched('donationLink', true, false)}
          />
          {formik.touched.donationLink && !!formik.errors.donationLink && (
            <ErrorText text={formik.errors.donationLink} />
          )}
        </View>
      )}

      <Text fontSize={12} marginBottom={10} marginTop={20}>
        {t`additionalVolunteerQuestions`}
      </Text>
      <FormikProvider value={formik}>
        <FieldArray
          name="formQuestions"
          render={(arrayHelpers) => (
            <>
              {formik.values.formQuestions.map((question, i) => (
                <View key={i}>
                  <Touchable
                    style={{
                      alignItems: 'flex-end',
                      marginBottom: 10,
                      cursor: 'pointer',
                    }}
                    onPress={() => arrayHelpers.remove(i)}
                  >
                    <Icon icon="closeBlack" width={12} height={12} />
                  </Touchable>
                  <Row style={{ marginBottom: 10, alignItems: 'center' }}>
                    <Switch
                      value={question.isRequired}
                      onValueChange={() => formik.setFieldValue(`formQuestions[${i}].isRequired`, !question.isRequired)}
                      style={{ marginRight: 10 }}
                      trackColor={{ true: colors.brandColor, false: colors.lightGray }}
                      thumbColor={colors.white}
                    />
                    <Text fontSize={12}>{t`required`}</Text>
                  </Row>
                  <Row>
                    <SelectBox
                      name={`${question}.questionType`}
                      value={question.questionType}
                      onSelect={(value) => {
                        formik.setFieldValue(`formQuestions[${i}].questionType`, value)
                        if (value === 'MultipleChoice' || value === 'MultipleAnswer') {
                          formik.setFieldValue(`formQuestions[${i}].typeOptions`, [])
                        }
                      }}
                      options={questionTypes}
                      style={{ minWidth: 150, marginRight: 10, marginTop: 8, marginBottom: 16 }}
                    />
                    <TextField
                      name={`${question}.title`}
                      value={question.title}
                      scrollEnabled={false}
                      placeholder="Text"
                      style={roundedInputStyle}
                      onChangeText={(e, value) => formik.setFieldValue(`formQuestions[${i}].title`, value)}
                    />
                  </Row>
                  {(question.questionType === 'MultipleChoice' || question.questionType === 'MultipleAnswer') && (
                    <FieldArray
                      name={`formQuestions[${i}].typeOptions`}
                      render={(formQuestionsArrayHelpers) => (
                        <View style={{ marginLeft: 160 }}>
                          {question.typeOptions.map((option, a) => (
                            <Row key={a} style={{ alignItems: 'center' }}>
                              <TextField
                                scrollEnabled={false}
                                name={`${option}.title`}
                                value={option.title}
                                placeholder="Option text"
                                style={roundedInputStyle}
                                onChangeText={(e, value) =>
                                  formik.setFieldValue(`formQuestions[${i}].typeOptions[${a}].title`, value)
                                }
                              />
                              <Touchable
                                style={{
                                  marginLeft: 10,
                                  cursor: 'pointer',
                                }}
                                onPress={() => arrayHelpers.remove(i)}
                              >
                                <Icon icon="closeBlack" width={12} height={12} />
                              </Touchable>
                            </Row>
                          ))}
                          <View>
                            <Button
                              style={{ marginBottom: 20, height: 30 }}
                              iconLeft="plus"
                              onPress={() => formQuestionsArrayHelpers.push({ title: '' })}
                            >
                              {t`addOption`}
                            </Button>
                          </View>
                        </View>
                      )}
                    />
                  )}
                </View>
              ))}
              <Button iconLeft="plus" onPress={() => arrayHelpers.push(emptyQuestion)}>
                {t`addQuestion`}
              </Button>
            </>
          )}
        />
      </FormikProvider>

      <Spacing marginTop={30}>
        <Button palette="primary" onPress={() => dispatch(setStepAction(4))} disabled={formik.errors.donationLink}>
          {t`next`}
        </Button>
      </Spacing>
    </>
  )
}

VolunteerDetails.displayName = 'VolunteerDetails'
export default VolunteerDetails
