import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import injectReducer from 'src/utils/injectReducer'
import Loading from 'src/retired/elements/Loading'

import reducer from './reducer'
import { selectIsBootstrapped, selectIsBranded } from './selectors'

const PersistGate = ({ bootstrapped, branded, children }) => (bootstrapped && branded ? children : <Loading />)

PersistGate.propTypes = {
  bootstrapped: PropTypes.bool,
  branded: PropTypes.bool,
  children: PropTypes.node,
}

const withConnect = connect((state) => ({
  bootstrapped: selectIsBootstrapped(state),
  branded: selectIsBranded(state),
}))

const withReducer = injectReducer({ key: 'persistGate', reducer })

export default compose(withReducer, withConnect)(PersistGate)
