import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PaymentInputsWrapper } from 'react-payment-inputs'
import type { HostedFieldsSubmitResponse, PayPalNamespace } from '@paypal/paypal-js'
import { DonationProvider } from '@joindeed/calculate-fees'

import { css, styled } from 'src/theme/styled'
import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import DonationApi from 'src/entities/donation/api'
import config from 'src/config'
import { ActivityIndicator, Spacing } from 'src/retired/elements'
import useScript from 'src/utils/useScript'
import { Body, Text } from 'src/retired/shared/Typography'
import * as Sentry from 'src/utils/Sentry'
import { DonorPrivacy } from 'src/containers/screens/Donate/elements/Privacy'
import { ErrorText } from 'src/components/ErrorText'
import type { FrontendFees } from 'src/containers/screens/Donate/calculateFeesWithHistory'

import { setDonatingAction, registerRecurringPaypalDonationAction, donateFailedAction } from '../actions'
import { selectDataClientToken, selectPaymentToken } from '../selectors'
import { DonateButton } from '..'
import { DonationFrequency } from '../constants'
import { type ProviderData } from '../payment/useProviderData'

import { BillingAddressForm, BillingAddress, isBillingAddressAdded } from './BillingAddressForm'

const StyledErrorText = ({ text }: { text: string }): JSX.Element => <ErrorText text={text} style={{ marginTop: 0 }} />

interface PayPalRecurringFormProps {
  providerData: ProviderData<DonationProvider.PayPal | DonationProvider.CAF>
  disabled: boolean
  amount: number
  amountValid: boolean
  submitting: boolean
  currencyCode: CurrencyCode
  coverFee: boolean
  privacy: string
  donorPrivacy: DonorPrivacy
  dedication: string
  designation: string
  donationFrequency: DonationFrequency
  nonprofitId?: string
  nonprofitCountryCode?: string
  feeCovered?: boolean
  feesHistoryItem: FrontendFees
  desiredMatchAmount: number | undefined
  desiredMatchingRule?: string
  giftAidApplies?: boolean
}

// @NOTE-AC: This type isn't documented in PayPal
export interface PayPalError {
  name: string
  details?: Array<{
    field?: string
    value?: string
    location?: string
    issue?: string
    description?: string
  }>
  message?: string
  links?: Array<{
    href?: string
    rel?: string
  }>
  debug_id: string
}

const reportError = (error: string | Error): void => {
  const e = error instanceof Error ? error : new Error(error)
  console.error(e) // eslint-disable-line no-console
  Sentry.captureException(e)
}

const PayPalRecurringForm = ({
  providerData,
  disabled = false,
  amount,
  amountValid,
  nonprofitId = '',
  currencyCode,
  coverFee,
  privacy,
  donorPrivacy,
  dedication,
  designation,
  donationFrequency,
  submitting,
  nonprofitCountryCode,
  feeCovered,
  feesHistoryItem,
  desiredMatchAmount,
  desiredMatchingRule,
  giftAidApplies,
}: PayPalRecurringFormProps): JSX.Element | null => {
  const { t } = useTranslation('donateScreen')
  const dispatch = useDispatch()
  const dataClientToken = useSelector(selectDataClientToken)
  const paymentToken = useSelector(selectPaymentToken)

  const ccForm = useRef<HTMLFormElement>(null)

  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    street1: '',
    city: '',
    country: 'US',
    postalCode: '',
  })

  const [formLoadingError, setFormLoadingError] = useState(false)
  const [cardNumberError, setCardNumberError] = useState('')
  const [cardExpiryError, setCardExpityError] = useState('')
  const [touched, setTouched] = useState(false)
  const giftAidAppliesRef = useRef(giftAidApplies)
  const amountRef = useRef(amount)
  const coverFeeRef = useRef(coverFee)

  const [payload, setPayload] = useState<HostedFieldsSubmitResponse>()

  const displayBillingAddressForm =
    nonprofitCountryCode === 'CA' ||
    giftAidApplies ||
    (providerData.donationProvider === DonationProvider.PayPal && providerData.payPalModel === 'DIRECT')

  // @TODO-AC: Use @paypal/paypal-js instead of our custom solution
  const [loaded, error, paypal] = useScript<PayPalNamespace>(
    // @see: https://developer.paypal.com/docs/platforms/checkout/reference/javascript-sdk/
    `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&client-id=${config.paypalClientId}`,
    'paypalRecurring',
    { 'data-client-token': dataClientToken, 'data-namespace': 'paypalRecurring' },
    true
  )

  useEffect(() => {
    // Keep the ref updated with the latest value because paypal.HostedFields.render() runs only once in the initial render and doesn't get updated with state changes because it is a js closure.
    giftAidAppliesRef.current = giftAidApplies
    amountRef.current = amount
    coverFeeRef.current = coverFee
  }, [giftAidApplies, amount, coverFee])

  useEffect(() => {
    if (payload) {
      dispatch(
        registerRecurringPaypalDonationAction({
          orderId: payload.orderId,
          amount: amountRef.current,
          desiredMatchAmount,
          desiredMatchingRule,
          nonprofit: nonprofitId,
          currencyCode,
          coverFee: coverFeeRef.current,
          privacy,
          donorPrivacy,
          dedication,
          designation,
          frequency: donationFrequency,
          paymentToken,
          billingAddress: displayBillingAddressForm ? billingAddress : undefined,
          feeCovered,
          giftAidApplies: giftAidAppliesRef.current,
        })
      )
    }
  }, [payload])

  useEffect(() => {
    if (paypal && loaded && !error) {
      if (paypal.HostedFields?.isEligible()) {
        paypal.HostedFields.render({
          createOrder: async () => {
            try {
              const response = await DonationApi.createRecurringPaypalOrder({
                amount: amountRef.current,
                desiredMatchAmount,
                desiredMatchingRule,
                nonprofit: nonprofitId,
                currencyCode,
                coverFee: coverFeeRef.current,
                feesHistoryItem,
                giftAidApplies: giftAidAppliesRef.current,

                // @NOTE-AC: The following fields don't really match what I see being used in the backend. Needs investigations
                frequency: donationFrequency,
                paymentToken,
                feeCovered,

                // Missing:
                // privacy, donorPrivacy, designation, dedication, billingAddress, campaign
              }).toPromise()
              return response.orderId
            } catch (e) {
              dispatch(donateFailedAction(e))
            }
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: t`cardNumber`,
            },
            cvv: {
              selector: '#cvv',
              placeholder: t`cvv`,
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: t`mmyyyy`,
            },
          },
        })
          .then((cardFields) => {
            ccForm?.current?.addEventListener('submit', (e) => {
              dispatch(setDonatingAction())
              setCardNumberError('')
              setCardExpityError('')
              e.preventDefault()
              cardFields
                .submit({ vault: true })
                .then(setPayload)
                .catch((payPalError: Error | PayPalError) => {
                  dispatch(donateFailedAction(new Error(t`anErrorOccurredDuringCardValidation`)))
                  if ('details' in payPalError) {
                    setCardNumberError(
                      payPalError?.details?.find((err) => err?.field === '/payment_source/card/number')?.description ||
                        ''
                    )
                    setCardExpityError(
                      payPalError?.details?.find((err) => err?.field === '/payment_source/card/expiry')?.description ||
                        ''
                    )
                  } else {
                    Sentry.captureException(
                      payPalError instanceof Error
                        ? payPalError
                        : new Error(payPalError.message || 'Unexpected PayPal error'),
                      { extra: { payPalError } }
                    )
                  }
                })
            })
          })
          .catch((e: Error) => {
            reportError(e || 'Error rendering PayPal hosted fields')
          })
      } else {
        setFormLoadingError(true)
      }
    }
  }, [paypal, loaded, error])

  useEffect(() => {
    if (error) {
      reportError('Error loading PayPal script')
    } else if (formLoadingError) {
      reportError('Error rendering PayPal hosted fields (ineligible)')
    }
  }, [error, formLoadingError])

  if (error || formLoadingError) {
    return (
      <Text colour="redDark" fontSize={16}>
        {t`errorLoadingPayPalAPI`}
      </Text>
    )
  }

  if (!loaded) {
    return <ActivityIndicator />
  }

  return (
    <View style={{ maxWidth: 600 }}>
      <form ref={ccForm}>
        {displayBillingAddressForm && (
          <BillingAddressForm
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            touched={touched}
            giftAidApplies={giftAidApplies}
          />
        )}
        <Body colour="gray01" style={{ marginLeft: 20 }}>
          {t`creditCard`}
        </Body>
        <Spacing marginBottom={12} />
        <PaymentInputsWrapper
          styles={{
            inputWrapper: {
              base: css`
                border-radius: 20px;
                font-family: 'GTWalsheimLC';
                padding: 0 11px;
                height: 40px;
                border-color: rgb(235, 238, 240);
                font-size: 12px;
                width: 360px;
              `,
            },
          }}
        >
          <div id="card-number" style={{ height: 40, marginLeft: 10 }} />
          <div id="expiration-date" style={{ height: 40, width: 68, flexGrow: 0, flexShrink: 0 }} />
          <div id="cvv" style={{ height: 40, width: 30, flexGrow: 0, flexShrink: 0 }} />
        </PaymentInputsWrapper>
        {!!cardNumberError && (
          <div>
            <StyledErrorText text={`${t('cardNumber')}: ${cardNumberError}`} />
          </div>
        )}
        {!!cardExpiryError && (
          <div>
            <StyledErrorText text={`${t('expirationDate')}: ${cardExpiryError}`} />
          </div>
        )}
        <Spacing marginBottom={20} />
        <DonateButton
          color="primary"
          size="small"
          disabled={submitting || !amountValid}
          onPress={() => {
            setTouched(true)
            if (displayBillingAddressForm && !isBillingAddressAdded(billingAddress)) {
              return
            }
            ccForm.current?.dispatchEvent(new Event('submit', { cancelable: true }))
          }}
        >
          {submitting ? <ActivityIndicator color="#fff" /> : t`completeDonation`}
        </DonateButton>
      </form>
      {disabled && <DisabledOverlay />}
    </View>
  )
}

const DisabledOverlay = styled(View)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 100000;
  cursor: not-allowed;
`

export default PayPalRecurringForm
