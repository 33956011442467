import React from 'react'
import { View } from 'react-primitives'

import { Body1 } from 'src/retired/shared/Typography'

export const Subtext = ({ width, text }: { width?: number; text: string }) => (
  <View style={{ width, marginTop: 12 }}>
    <Body1 weight="500" colour="black">
      {text}
    </Body1>
  </View>
)
