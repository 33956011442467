import React from 'react'
import CurrencyInput from 'react-currency-input-field'

import { roundedInputStyleObject } from '../Input/roundedInputStyles'

import type { CurrencyInputProps } from './types'

const Input = ({
  name,
  placeholder,
  value,
  onValueChange,
  currencyPrefix,
  decimalSeparator,
  groupSeparator,
  decimalsLimit,
  maxLength,
  style,
  autoFocus,
  editable = true, // by default component is editable
}: CurrencyInputProps) => (
  <CurrencyInput
    name={name}
    placeholder={placeholder}
    value={value}
    onValueChange={onValueChange}
    decimalsLimit={decimalsLimit}
    style={style || roundedInputStyleObject}
    prefix={currencyPrefix}
    decimalSeparator={decimalSeparator}
    groupSeparator={groupSeparator}
    maxLength={maxLength}
    data-testid="currency-input"
    autoFocus={autoFocus}
    disabled={!editable}
  />
)

export default Input
