import { ReactNode } from 'react'
import type { Theme } from '@emotion/react'

import * as deedTheme from 'src/theme'
import { css, styled } from 'src/theme/styled'
import { withHover } from 'src/retired/shared/Hoverable'
import { EmotionTheme } from 'src/theme/ThemeProvider'

interface FontFamilyProps {
  weight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
  customFont?: string
}

const fontFamilyStyle = ({ weight = 'normal', customFont = undefined }: FontFamilyProps): ReturnType<css> => {
  if (customFont) {
    // we need this for default font weight in case if custom font will not be loaded
    return css`
      font-weight: ${weight};
      font-family: '"${customFont}", Helvetica, sans-serif';
    `
  }

  return css`
    font-weight: ${weight};
    font-family: 'GTWalsheimLC, Helvetica, sans-serif';
  `
}

interface ThemeProps {
  theme?: Theme
}

interface ColorProps {
  // for now we can't use valid DOM attributes for custom props
  // see https://github.com/emotion-js/emotion/issues/900
  color?: never
  colour?: typeof deedTheme extends {
    colors: infer U
  }
    ? keyof U | string
    : never
  hoverColor?: typeof deedTheme extends {
    colors: infer U
  }
    ? keyof U
    : never
  isHovered?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const colorStyle = ({ theme, colour, isHovered, hoverColor }: ColorProps & ThemeProps) => css`
  color: ${isHovered && hoverColor
    ? theme?.colors[hoverColor] || hoverColor
    : colour
    ? theme?.colors[colour] || colour
    : theme?.colors.black};
`

interface AlignmentProps {
  center?: boolean
  right?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const alignmentStyle = ({ center, right }: AlignmentProps) => css`
  text-align: ${right ? 'right' : center ? 'center' : 'left'};
`

interface LineHeightProps {
  lineHeight?: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const lineHeightStyle = ({ lineHeight }: LineHeightProps) => css`
  ${lineHeight !== undefined && `line-height: ${lineHeight}px;`}
`

interface LetterSpacingProps {
  letterSpacing?: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const letterSpacingStyle = ({ letterSpacing }: LetterSpacingProps) => css`
  ${letterSpacing !== undefined && `letter-spacing: ${letterSpacing}px;`}
`

interface UnderlineProps {
  underline?: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const underlineStyle = ({ underline }: UnderlineProps) => css`
  ${!!underline && 'text-decoration: underline;'}
`

interface SpacingProps {
  marginTop?: number
  marginBottom?: number
  marginLeft?: number
  marginRight?: number
  paddingTop?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const spacingStyles = ({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
}: SpacingProps) => css`
  ${marginTop !== undefined && `margin-top: ${marginTop}px;`}
  ${marginBottom !== undefined && `margin-bottom: ${marginBottom}px;`}
  ${marginLeft !== undefined && `margin-left: ${marginLeft}px;`}
  ${marginRight !== undefined && `margin-right: ${marginRight}px;`}
  ${paddingTop !== undefined && `padding-top: ${paddingTop}px;`}
  ${paddingBottom !== undefined && `padding-bottom: ${paddingBottom}px;`}
  ${paddingLeft !== undefined && `padding-left: ${paddingLeft}px;`}
  ${paddingRight !== undefined && `padding-right: ${paddingRight}px;`}
`

interface TypographyProps
  extends ColorProps,
    AlignmentProps,
    SpacingProps,
    LetterSpacingProps,
    UnderlineProps,
    LineHeightProps {}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const typographyStyles = (props: TypographyProps & FontFamilyProps & ColorProps & ThemeProps) => css`
  ${fontFamilyStyle(props)}
  ${props.colour && colorStyle(props)}
  ${alignmentStyle(props)}
  ${spacingStyles(props)}
  ${lineHeightStyle(props)}
  ${letterSpacingStyle(props)}
  ${underlineStyle(props)}
`

interface BaseProps extends TypographyProps, FontFamilyProps {
  children: ReactNode
}

export const H1 = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 60px;
  letter-spacing: -0.4px;
`)
export const H2 = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${(props: BaseProps) => typographyStyles({ weight: props.weight || '500', ...props })}

  font-size: 48px;
  letter-spacing: -0.3px;
`)
export const H3 = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 32px;
  letter-spacing: -0.3px;
`)
export const H4 = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 24px;
  letter-spacing: -0.3px;
`)

export const H5 = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 20px;
  letter-spacing: -0.3px;
`)

interface TitleProps extends BaseProps {
  card?: boolean
}
export const Title = withHover(styled.Text<TitleProps, typeof deedTheme>`
  ${(props: BaseProps) => typographyStyles({ weight: 'bold', ...props })}
  font-size: 16px;
`)

export const Body = styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 14px;
  letter-spacing: -0.2px;
`

export const Label = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 12px;
  letter-spacing: -0.3px;
`)

export const MenuLabel = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${typographyStyles}
  font-size: 20px;
  letter-spacing: -0.3px;
`)

export const Metric = withHover(styled.Text<BaseProps, typeof deedTheme>`
  ${(props: BaseProps) => typographyStyles({ weight: '500', ...props })}
  font-size: 40px;
  letter-spacing: 0.3px;
`)

export interface TextProps extends BaseProps {
  fontSize?: number
}
export const Text = withHover(
  styled.Text<TextProps, typeof deedTheme>(
    ({ fontSize = 16, ...props }: TextProps) => `
      ${typographyStyles(props)}
      ${fontSize !== undefined && `font-size: ${fontSize}px`}
    `
  )
)

// These are new design Styles

export const Body1 = withHover(styled.Text<BaseProps & { small?: boolean }, typeof deedTheme>`
  ${typographyStyles}
  font-size: 16px;
`)

export const Body2 = withHover(styled.Text<BaseProps & { small?: boolean }, typeof deedTheme>`
  ${typographyStyles}
  font-size: 14px;
`)

export const StyledLink = styled.View<object, EmotionTheme>`
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.brandColor};
`
