export const INIT = 'containers/Register/Interests/INIT'
export const INIT_SUCCESS = 'containers/Register/Interests/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Register/Interests/INIT_FAILED'

export const SUBMIT = 'containers/Register/Interests/SUBMIT'
export const SUBMIT_SUCCESS = 'containers/Register/Interests/SUBMIT_SUCCESS'
export const SUBMIT_FAILED = 'containers/Register/Interests/SUBMIT_FAILED'

export const TOGGLE_SELECTED_INTEREST = 'containers/Register/Interests/TOGGLE_SELECTED_INTEREST'
export const TOGGLE_EXPAND_SDGS = 'containers/Register/Interests/TOGGLE_EXPAND_SDGS'
