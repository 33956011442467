import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Touchable from 'src/retired/shared/Touchable'
import { colors } from 'src/theme'

export const BackButton = ({ onPress }: { onPress: () => void }) => (
  <Touchable
    style={{
      borderRadius: 20,
      width: 40,
      height: 40,
      minWidth: 0,
      borderColor: colors.lightGray,
      backgroundColor: colors.white,
      borderWidth: 1,
      padding: 7,
    }}
    onPress={onPress}
  >
    <ArrowBackIcon style={{ fill: colors.gray }} />
  </Touchable>
)
