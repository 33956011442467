import React from 'react'

import { styled } from 'src/theme/styled'
import { Checkbox, Touchable } from 'src/retired/elements'

const StyledAccordionItem = styled.View<{ last?: boolean }>`
  padding: 10px 20px;
  ${({ last }) => !last && 'border-bottom-width: 1px; border-bottom-color: #d8d8d8'}
`

const AccordionContent = styled.View<{ selected?: boolean }>`
  padding-bottom: 10px;
  padding-left: 35px;
  display: ${({ selected }) => (selected ? 'block' : 'none')};
`

interface AccordionItemProps {
  title: React.ReactElement
  disabled?: boolean
  selected?: boolean
  children?: React.ReactElement
  onPress?: () => void
}

const AccordionItem: React.FC<AccordionItemProps> = ({ disabled, selected, title, children, onPress }) => (
  <StyledAccordionItem>
    <Touchable disabled={disabled} onPress={onPress}>
      <Checkbox asRadio disabled={disabled} checked={selected} onChange={onPress}>
        {title}
      </Checkbox>
    </Touchable>
    <AccordionContent selected={selected}>{children}</AccordionContent>
  </StyledAccordionItem>
)

export default AccordionItem
