import { createSelector } from 'reselect'

import { State } from 'src/reducers'

import { DeedCancelState, initialState } from './reducer'

const selectState = (state: State): DeedCancelState => state.get('deedCancel') || initialState

export const selectCancelling = createSelector(selectState, (state): boolean => state.get('cancelling'))

export const selectError = createSelector(selectState, (state) => state.get('error'))
