import { createSelector } from 'reselect'

import { TypedMap } from 'src/utils/typed-map'

import { initialState } from './reducer'
import type { TypedVolunteerTimeFormState } from './reducer'

type VolunteerTimeFormState = TypedMap<{ volunteerTimeForm: TypedVolunteerTimeFormState }>

const selectState = (state: VolunteerTimeFormState): TypedVolunteerTimeFormState =>
  state.get('volunteerTimeForm') || initialState

export const selectLoading = createSelector<VolunteerTimeFormState, TypedVolunteerTimeFormState, boolean>(
  selectState,
  (state) => state.get('loading')
)

export const selectError = createSelector<VolunteerTimeFormState, TypedVolunteerTimeFormState, Error | null>(
  selectState,
  (state) => state.get('error')
)
