import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import HailOutlinedIcon from '@mui/icons-material/HailOutlined'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Chip, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Deed from 'src/entities/deed/model'
import Role from 'src/entities/deed/role'
import User from 'src/entities/user/model'
import { Avatar } from 'src/retired/elements'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export enum ShiftStatusChipStatuses {
  Attending = 'Attending',
  Overlapping = 'Overlapping',
}

export const ShiftStatusChip = ({ status }: { status: ShiftStatusChipStatuses }) => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()

  let textColor: string = ''
  let backgroundColor: string = ''
  let label: string = ''

  if (status === ShiftStatusChipStatuses.Attending) {
    textColor = colors.statusTextSuccess
    backgroundColor = colors.statusSuccess
    label = t`youAreAttending`
  } else if (status === ShiftStatusChipStatuses.Overlapping) {
    textColor = colors.statusTextNeutral
    backgroundColor = colors.statusNeutral
    label = t`overlappingShifts`
  }

  return (
    <Chip
      sx={{
        backgroundColor,
        color: 'black',
        '& .MuiChip-icon': {
          color: textColor,
        },
        width: 'fit-content',
      }}
      icon={<InfoOutlinedIcon sx={{ color: textColor }} />}
      label={label}
    />
  )
}

export const ShiftCheckbox = ({
  role,
  user,
  deed,
  checked,
  onChange,
  disabled,
  style,
  isOverlappingShift = false,
  isAttending = false,
  hideCheckbox,
}: {
  role: Role
  user: User | undefined
  deed: Deed
  checked?: boolean
  onChange?: () => void
  disabled?: boolean
  isOverlappingShift?: boolean
  isAttending?: boolean
  style?: React.CSSProperties
  hideCheckbox?: boolean
}): JSX.Element => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('deedScreen')
  const spotsAvailableForRole = deed.roleSpotsAvailable(role)
  const spotsAvailableForRoleLength =
    typeof spotsAvailableForRole === 'number' ? spotsAvailableForRole : spotsAvailableForRole.size
  const name = role.get('name')
  const description = role.get('description')
  const roleRequiredAttendeeAmount = role.get('requiredAmount')

  return (
    <Box
      key={role.get('id')}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: `1px solid ${colors.gray04}`,
        borderRadius: '4px',
        padding: '20px',
        gap: '20px',
      }}
      style={{ backgroundColor: disabled ? colors.gray03 : colors.white, ...style }}
    >
      {hideCheckbox ? null : (
        <Box>
          <Checkbox
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            icon={!checked && disabled ? <IndeterminateCheckBoxIcon /> : undefined}
            style={{ margin: '-10px -10px 0 -10px' }}
            sx={{
              color: checked && disabled && colors.brandColor,
              '&.Mui-checked.Mui-disabled': {
                color: colors.brandColor,
                opacity: 0.5,
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        {name ? <Body1 style={{ fontWeight: '500' }}>{role.get('name')}</Body1> : null}
        {description && !_.isEmpty(_.trim(description)) ? (
          <Body2 style={{ marginTop: '8px', color: colors.gray08 }}>{description}</Body2>
        ) : null}
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            flex: 1,
            flexDirection: metrics.isSmall ? 'column' : 'row',
            gap: metrics.isSmall ? '8px' : '20px',
          }}
        >
          {role.get('startingAt') ? (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <CalendarTodayIcon fontSize="small" style={{ color: colors.grayMediumDark, marginRight: '4px' }} />
              <Body2>
                {t('date:weekdayDayMonth', {
                  date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                })}
              </Body2>
            </Box>
          ) : null}
          {role.get('endingAt') ? (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <ScheduleIcon fontSize="small" style={{ color: colors.grayMediumDark, marginRight: '4px' }} />
              <Body2>
                {t('date:time', {
                  date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                })}
                {` - `}
                {t('date:time', {
                  date: { value: role.get('endingAt'), timeZone: deed.timeZone },
                })}
              </Body2>
            </Box>
          ) : null}
        </Box>
        <Divider style={{ margin: '20px 0' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {spotsAvailableForRoleLength ? (
              <HailOutlinedIcon color="primary" fontSize="small" style={{ marginRight: '4px' }} />
            ) : null}
            <Box style={{ display: 'flex', marginRight: '8px' }}>
              {role
                .get('approved')
                .toArray()
                ?.map((attendee, index) => (
                  <Avatar
                    size={26}
                    spacing={0}
                    key={attendee.id}
                    user={attendee}
                    link={user && String(attendee.id) !== String(user.id)}
                    style={{
                      marginLeft: `-${index * 6}px`,
                      border: '1px solid white',
                      backgroundColor: 'transparent',
                    }}
                  />
                ))}
            </Box>
            <Body2>
              {t(`spotsStillAvailable`, {
                availableSpots: spotsAvailableForRoleLength,
                totalSpots: roleRequiredAttendeeAmount,
              })}
            </Body2>
          </Box>
          <Box sx={{ gap: '4px', display: 'flex' }}>
            {isOverlappingShift ? <ShiftStatusChip status={ShiftStatusChipStatuses.Overlapping} /> : null}
            {isAttending ? <ShiftStatusChip status={ShiftStatusChipStatuses.Attending} /> : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
