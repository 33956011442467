import BlockIcon from '@mui/icons-material/Block'
import DoneIcon from '@mui/icons-material/Done'
import HistoryIcon from '@mui/icons-material/History'
import { Box } from '@mui/material'
import React from 'react'

import { Body2 } from 'src/retired/shared/Typography'
import { ColorsProps } from 'src/theme/colors'

export enum StatusChipStatuses {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  PaidOut = 'PaidOut',
  NotEligible = 'NotEligible',
}

export const StatusChip = ({
  label,
  status,
  colors,
}: {
  label: string
  status: StatusChipStatuses
  colors: ColorsProps
}) => {
  let iconColor: string
  let backgroundColor: string

  if (status === StatusChipStatuses.Approved) {
    iconColor = colors.statusTextSuccess
    backgroundColor = colors.statusSuccess
  } else if (status === StatusChipStatuses.Rejected || status === StatusChipStatuses.NotEligible) {
    iconColor = colors.statusTextError
    backgroundColor = colors.statusError
  } else if (status === StatusChipStatuses.PaidOut) {
    iconColor = colors.blue
    backgroundColor = colors.blueLighter
  } else {
    iconColor = colors.statusTextNeutral
    backgroundColor = colors.statusNeutral
  }

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 12px 4px 8px',
      backgroundColor,
      borderRadius: 28,
      maxWidth: 'fit-content',
    },
    text: {
      fontWeight: '400',
      color: iconColor,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icon: {
      marginRight: '4px',
      color: iconColor,
    },
  }

  return (
    <Box style={styles.container}>
      {status === StatusChipStatuses.Approved || status === StatusChipStatuses.PaidOut ? (
        <DoneIcon style={styles.icon} />
      ) : status === StatusChipStatuses.Rejected || status === StatusChipStatuses.NotEligible ? (
        <BlockIcon style={styles.icon} />
      ) : (
        <HistoryIcon style={styles.icon} />
      )}
      <Body2 style={styles.text}>{label}</Body2>
    </Box>
  )
}
