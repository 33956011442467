import React from 'react'

import { RenderHtml } from 'src/components/RenderHtml'
import Expandable from 'src/components/Expandable'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { H5 } from 'src/retired/shared/Typography'

export const DescriptionBlock = ({ title, description }: { title: string; description?: string | null }) => {
  const { colors, metrics } = useDeedTheme()

  return (
    <>
      <H5
        style={{
          paddingBottom: 12,
          paddingTop: 20,
          borderTopWidth: metrics.isSmall ? 0 : 1,
          borderTopColor: colors.borderGray,
        }}
      >
        {title}
      </H5>
      {description && (
        <Expandable>
          <RenderHtml source={{ html: description }} />
        </Expandable>
      )}
    </>
  )
}
