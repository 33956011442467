import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import UserApi from 'src/entities/user/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { renderErrorMessage } from 'src/utils/errorMessages'

import { SUBMIT } from './constants'
import { submitSuccessAction, submitFailedAction } from './actions'

const submit = (action$) =>
  action$
    .ofType(SUBMIT)
    .exhaustMap(({ event, shareData, marketingEmails }) => {
      const consent = {}
      if (shareData) {
        consent.shareData = new Date()
      }
      if (marketingEmails) {
        consent.marketingEmails = new Date()
      }
      return UserApi.update({ consent })
        .mergeMap((action) => [action, replace(event ? `/register/phone/${event}` : '/home'), submitSuccessAction()])
        .catch((e) => {
          if (e.responseJson) {
            return [
              submitFailedAction(e.responseJson),
              showErrorAction(i18n.t('common:anErrorOccurred'), renderErrorMessage(e.responseJson.errors)),
            ]
          }
          return Observable.of(submitFailedAction(e), showErrorAction(i18n.t('common:anErrorOccurred')))
        })
    })
    .catch((e) => Observable.of(submitFailedAction(e)))

export default [submit]
