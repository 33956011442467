import { fromJS } from 'immutable'

import { INIT, INIT_SUCCESS, INIT_FAILED } from './types'

export const initialState = fromJS({ loading: true, error: null })

export default (state = initialState, action: { type: string; error?: Error }) => {
  switch (action.type) {
    case INIT:
      return state.merge({ loading: true, error: null })

    case INIT_SUCCESS:
      return state.set('loading', false)

    case INIT_FAILED:
      return state.merge({ loading: false, error: action.error })

    default:
      return state
  }
}
