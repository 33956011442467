import React, { Dispatch, SetStateAction, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Row, Touchable } from 'src/retired/elements'
import { Chip } from 'src/components/Chip/Chip'
import Icon from 'src/retired/shared/Icon'
import { IconData } from 'src/retired/shared/DeedCard/helper'
import { Text } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export interface ChipItemProps {
  id: string
  name: string
  status: boolean
  iconData?: IconData
  image?: string
  type?: string
}

export interface ToggleableChipListProps {
  values: ChipItemProps[]
  setValues: Dispatch<SetStateAction<ChipItemProps[]>>
  direction?: 'vertical' | 'horizontal'
  maxCollapsedItems?: number
  multipleSelection?: boolean
}

export const ToggleableChipList = ({
  values,
  setValues,
  direction = 'horizontal',
  maxCollapsedItems,
  multipleSelection = true,
}: ToggleableChipListProps) => {
  const { t } = useTranslation('searchScreen')
  const { colors } = useDeedTheme()

  const [isExpanded, setIsExpanded] = useState(false)

  const Container = direction === 'vertical' ? Row : View

  const onChipToggleHandler = (chipItem: ChipItemProps) => {
    setValues((prev) =>
      prev.map((item) => {
        if (item.id === chipItem.id) {
          // eslint-disable-next-line no-param-reassign
          item.status = !item.status
          return item
        }
        return { ...item, status: multipleSelection ? item.status : false }
      })
    )
  }

  if (!values.length) {
    return null
  }

  return (
    <>
      <View style={{ marginBottom: -12, flexWrap: 'wrap', flexDirection: 'row' }}>
        {values.map((chipItem, index) => (
          <Container
            key={`${chipItem.id}-${index}`}
            style={{
              display: !isExpanded && maxCollapsedItems && maxCollapsedItems <= index ? 'none' : 'flex',
              flexGrow: undefined,
            }}
          >
            <Chip
              label={chipItem.name}
              image={!chipItem.iconData?.iconName && chipItem.image ? chipItem.image : undefined}
              icon={
                chipItem.iconData?.iconName ? (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: chipItem.iconData?.hexCode,
                      width: 24,
                      height: 24,
                      borderRadius: 12,
                      marginLeft: 4,
                    }}
                  >
                    <Icon icon={chipItem.iconData?.iconName} width={17} />
                  </View>
                ) : undefined
              }
              color="primary"
              variant={chipItem.status ? 'filled' : 'outlined'}
              selected={chipItem.status}
              onDelete={() => onChipToggleHandler(chipItem)}
              onClick={() => onChipToggleHandler(chipItem)}
              style={{ marginBottom: 12, marginRight: 12 }}
            />
          </Container>
        ))}
      </View>
      {maxCollapsedItems && values?.length > maxCollapsedItems && (
        <Touchable
          onPress={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <Text colour={colors.brandColor} underline style={{ textDecorationColor: colors.brandColor, marginTop: 20 }}>
            {isExpanded ? t('seeLess') : t('seeMore')}
          </Text>
        </Touchable>
      )}
    </>
  )
}
