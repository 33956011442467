export const INIT = 'containers/Feed/INIT'
export const INIT_SUCCESS = 'containers/Feed/INIT_SUCCESS'
export const INIT_FAILED = 'containers/Feed/INIT_FAILED'

export const LOAD_FEED_FOR_DEED_TYPE = 'containers/Feed/LOAD_FEED_FOR_DEED_TYPE'
export const LOAD_FEED_FOR_DEED_TYPE_SUCCESS = 'containers/Feed/LOAD_FEED_FOR_DEED_TYPE_SUCCESS'
export const LOAD_FEED_FOR_DEED_TYPE_FAILURE = 'containers/Feed/LOAD_FEED_FOR_DEED_TYPE_FAILURE'

export const SET_SCROLL_POSITION = 'containers/Feed/SET_SCROLL_POSITION'
export const RESET_SCROLL_POSITIONS = 'containers/Feed/RESET_SCROLL_POSITIONS'
