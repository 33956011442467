export const INIT = 'containers/Deed/CHECK_IN/INIT'
export const INIT_FAILED = 'containers/Deed/CHECK_IN/INIT_FAILED'

export const WATCH_POSITION = 'containers/Deed/CHECK_IN/WATCH_POSITION'
export const STOP_WATCHING = 'containers/Deed/CHECK_IN/STOP_WATCHING'

export const SET_GEOLOCATION = 'containers/Deed/CHECK_IN/SET_GEOLOCATION'

export const SET_GEOLOCATION_WATCH_ID = 'containers/Deed/CHECK_IN/SET_GEOLOCATION_WATCH_ID'

export const CHECK_IN = 'containers/Deed/CHECK_IN/CHECK_IN'
export const CHECK_IN_SUCCESS = 'containers/Deed/CHECK_IN/CHECK_IN_SUCCESS'
export const CHECK_IN_FAILED = 'containers/Deed/CHECK_IN/CHECK_IN_FAILED'

export const DISABLED_CHECK_IN_CLICKED = 'containers/Deed/CHECK_IN/DISABLED_CHECK_IN_CLICKED'
