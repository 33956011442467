import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View, StyleSheet } from 'react-primitives'
import { withTranslation } from 'react-i18next'

import {
  ActivityIndicator,
  Loading,
  Gradient,
  Icon,
  Form,
  Row,
  Screen,
  Spacing,
  Text,
  Touchable,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators } from 'src/utils'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { colors } from 'src/theme'
import { Redirect } from 'src/navigation'
import PhoneInput from 'src/retired/elements/PhoneInput'

import { selectLoading, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch',
  },
  field: {
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  label: {
    marginRight: 15,
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 10,
  },
})
class RegisterPhone extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    const {
      t,
      match,
      user,
      loading,
      submitting,
      actions,
      values,
      errors,
      touched,
      isValid,
      setFieldValue,
      setFieldTouched,
      locations,
    } = this.props
    const handleSubmit = (e) => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      if (values.phone && isValid) {
        actions.submitAction(values.phone, match.params.deed)
      }
    }
    if (user && !user.consent.shareData) {
      return <Redirect to={`/register/consent/${match.params.deed}`} />
    }
    const userLocation = locations?.get(user?.location)
    if ((user?.phone && !validators.isPhone(user.phone)) || !userLocation || userLocation?.phone === false) {
      return <Redirect to={`/deeds/${match.params.deed}/confirm`} />
    }
    if (loading) {
      return <Loading />
    }
    return (
      <Screen fixed>
        <NavigationHeader transparent />
        <Screen padding narrow action middle>
          <Form onSubmit={handleSubmit} style={styles.form}>
            <Spacing marginTop={20} marginBottom={20}>
              <Text size={24} lineHeight={28}>
                {t`ourOrganizationPartnersAreCountingOnYou`}
              </Text>
            </Spacing>
            <Text size={12} medium>
              {t`weDoNotTolerateSpam`}
            </Text>
            <Gradient
              height={10}
              style={{
                marginTop: 20,
                marginBottom: 15,
                marginLeft: -1000,
                marginRight: -1000,
              }}
            />
            <View style={styles.field}>
              <PhoneInput
                placeholder={t`enterPhoneNumber`}
                user={user}
                locations={locations}
                value={values.phone}
                onChange={setFieldValue}
                onTouched={setFieldTouched}
                onSubmit={handleSubmit}
                autoFocus
              />
              <Touchable onPress={handleSubmit}>
                {submitting ? (
                  <ActivityIndicator color={colors.black} />
                ) : (
                  <Row style={{ alignItems: 'flex-start' }}>
                    <Text
                      bold
                      color={values.phone && isValid ? colors.black : colors.lightGray}
                      style={{ marginRight: 10 }}
                    >
                      {t`confirm`}
                    </Text>
                    <Icon
                      icon={values.phone && isValid ? 'arrowRightBlack' : 'arrowRightGray'}
                      style={{ width: 19, height: 16 }}
                    />
                  </Row>
                )}
              </Touchable>
            </View>
            <Text color={colors.redDark} style={styles.error}>
              {touched.phone && errors.phone && errors.phone}
            </Text>
          </Form>
          <Spacing marginBottom={20} />
        </Screen>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    user: selectCurrentUser(state),
    loading: selectLoading(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    locations: selectUserLocations(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerPhone', reducer })
const withEpics = injectEpics({ key: 'registerPhone', epics })
const withForm = withFormik({
  validate: (values) => {
    const errors = {
      phone: validators.notEmpty(values.phone) || validators.isPhone(values.phone),
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  enableReinitialize: true,
  mapPropsToValues: ({ user }) => ({
    phone: user ? user.phone : '',
  }),
  validateOnBlur: true,
  validateOnChange: true,
  isInitialValid: true,
})

export default compose(withReducer, withEpics, withConnect, withForm, withTranslation('phoneRegister'))(RegisterPhone)
