/* eslint-disable no-param-reassign */
import React from 'react'
import { View } from 'react-primitives'

import { getBoxShadow } from 'src/utils'
import { Text } from 'src/retired/shared/Typography'
import * as deedTheme from 'src/theme'
import { css } from 'src/theme/styled'
import { Image } from 'src/retired/elements'

interface ChipProps {
  image?: string
  type?: 'default' | 'square' | 'cause' | 'skill' | 'cause-suggestion'
  textWeight?: '500' | 'bold'
  textColor?: typeof deedTheme extends {
    colors: infer U
  }
    ? keyof U
    : never
  textSize?: number
  height?: number
  backgroundColor?: typeof deedTheme extends {
    colors: infer U
  }
    ? keyof U
    : never
  textStyle?: any
}

export type ChipElement = React.ReactElement<ChipProps>

const Chip: React.FC<ChipProps> = ({
  children,
  image,
  type = 'default',
  textColor,
  textSize,
  textWeight = '500',
  height,
  backgroundColor,
  textStyle,
}): ChipElement => {
  let marginLeft = 16
  let marginRight = 16
  let borderRadius = '20px'
  let boxShadow = ''
  let imageSize = 18

  switch (type) {
    case 'square':
      marginLeft = 6
      marginRight = 6
      borderRadius = '4px'
      break

    case 'skill':
      backgroundColor = 'lightGray02'
      textColor = 'black'
      textSize = textSize || 12
      height = height || 36
      borderRadius = '8px'
      break

    case 'cause':
      textColor = backgroundColor ? 'white' : 'grayMediumDark'
      backgroundColor = backgroundColor || 'gray04'
      textSize = textSize || 12
      break

    case 'cause-suggestion':
      textColor = 'grayMediumDark'
      backgroundColor = 'white'
      textSize = textSize || 14
      boxShadow = getBoxShadow({ color: '#9999994d', offset: '0px 2px', radius: '7px' })
      imageSize = 32
      marginLeft = image ? 6 : 10
      break
  }

  if (image && type !== 'cause-suggestion') {
    marginLeft = 6
  }

  return (
    <View
      style={[
        css`
          flex-direction: row;
          align-content: flex-start;
          align-items: center;
          justify-content: space-between;
          width: auto;
          height: ${`${height || imageSize + 4}px`};
          background-color: ${backgroundColor ? deedTheme.colors[backgroundColor] || backgroundColor : 'white'};
          border-radius: ${borderRadius};
          ${boxShadow}
        `,
      ]}
    >
      {image ? (
        <View
          style={[
            css`
              width: ${imageSize}px;
              height: ${imageSize}px;
              border-radius: ${(imageSize / 2).toFixed()}px;
              overflow: hidden;
              background-color: ${deedTheme.colors.white};
              align-items: center;
              justify-content: center;
              margin-left: 3px;
              margin-top: 3px;
              margin-bottom: 3px;
            `,
          ]}
        >
          <Image source={{ uri: image }} resizeMode="cover" style={{ width: imageSize, height: imageSize }} />
        </View>
      ) : null}
      {typeof children === 'string' ? (
        <Text
          weight={textWeight}
          fontSize={textSize || 12}
          lineHeight={textSize || 10}
          colour={textColor}
          paddingTop={2}
          paddingBottom={2}
          marginLeft={marginLeft}
          marginRight={marginRight}
          numberOfLines={1}
          style={textStyle}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </View>
  )
}
export default Chip
