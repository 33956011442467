import { EmotionTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'

interface MatchingContainerProps {
  theme?: EmotionTheme
  inactive?: boolean
}

export const MatchingContainer = styled.View<MatchingContainerProps>`
  align-self: flex-start;
  padding: 12px;
  background-color: ${({ theme, inactive }: MatchingContainerProps) =>
    inactive ? theme?.colors.grayLighter : theme?.colors.white};
  border: 1px solid ${({ theme }: MatchingContainerProps) => theme?.colors.grayLighter};
  border-radius: 8px;
  width: 100%;
`

interface MatchedLabelProps {
  active?: boolean
  theme?: EmotionTheme
}

export const MatchedLabelStyled = styled.View<MatchedLabelProps>`
  background: ${({ theme, active }: MatchedLabelProps) => active && theme?.colors.yellow};
  border-radius: 4px;
  line-height: 24px;
  padding: 4px 6px;
`

export const MatchingRightColumn = styled.View(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexShrink: 1,
}))
