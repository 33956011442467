import { SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAILED } from './constants'

export const submitAction = (event, shareData, marketingEmails) => ({
  type: SUBMIT,
  event,
  shareData,
  marketingEmails,
})
export const submitSuccessAction = () => ({
  type: SUBMIT_SUCCESS,
})
export const submitFailedAction = (error, errorCode) => ({
  type: SUBMIT_FAILED,
  error,
  errorCode,
})
