import React from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { EmotionTheme } from 'src/theme/ThemeProvider'
import { Screen, ScrollView, Spacing } from 'src/retired/elements'
import { PageTitle } from 'src/components'
import { H4, Text } from 'src/retired/shared/Typography'

import SelectNonprofit from './SelectNonprofit'

const FormWrapper = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics.isLarge ? '195px' : '15px')};
  margin-bottom: 50px;
`
const Title = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isLarge ? '0 55px 20px 55px' : '0 25px 25px 25px')};
`

const NominateNonprofitSearchForm = (): JSX.Element => {
  const { t } = useTranslation('nominateNonprofitSearchForm')

  const formik = useFormik({
    initialValues: { searchTerm: '' },
    onSubmit: () => {},
  })

  return (
    <Screen fixed>
      <PageTitle title={t`nominateAnOrganization`} />

      <ScrollView keyboardShouldPersistTaps="always">
        <Spacing marginTop={60} />

        <Title>
          <H4 center marginBottom={8}>
            {t`nominateAnOrganization`}
          </H4>
        </Title>

        <FormWrapper>
          <Text style={{ textAlign: 'center', width: 410 }}>{t`beforeYouNominate`}</Text>

          <Spacing marginTop={24} />

          <SelectNonprofit formik={formik} />
        </FormWrapper>
      </ScrollView>
    </Screen>
  )
}

NominateNonprofitSearchForm.displayName = 'NominateNonprofitSearchForm'

export default NominateNonprofitSearchForm
