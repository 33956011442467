import React from 'react'

import { H5 } from 'src/retired/shared/Typography'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { styled } from 'src/theme/styled'

const SearchTitleWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '20px' : '0px')};
`

interface SearchTitleProps {
  title: string
  searchTerm?: string
  loading: boolean
}

export const ResultsTitle = ({ title, searchTerm, loading }: SearchTitleProps) => {
  const { colors } = useDeedTheme()
  return (
    <SearchTitleWrapper>
      <H5 weight="500">{title}</H5>
      {searchTerm?.length && !loading ? (
        <H5 weight="500" colour={colors.grayMediumDark}>
          {' '}
          &apos;{searchTerm}&apos;
        </H5>
      ) : null}
    </SearchTitleWrapper>
  )
}
