import { fromJS } from 'immutable'

import { UPLOAD, UPLOAD_SUCCESS, UPLOAD_FAILED, UPLOAD_CANCELLED } from './constants'

export const initialState = fromJS({ uploading: false })

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD:
      return state.set('uploading', true)

    case UPLOAD_SUCCESS:
    case UPLOAD_FAILED:
    case UPLOAD_CANCELLED:
      return state.set('uploading', false)

    default:
      return state
  }
}
