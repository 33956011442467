import { Record } from 'immutable'
import _ from 'lodash'

const properties = {
  minimumAppVersion: 0,
  minimumAppVersionAndroid: 0,
  maxStrikes: 3,
}

export default class Setting extends Record(properties, 'Setting') implements Setting {
  minimumAppVersion!: number

  minimumAppVersionAndroid!: number

  maxStrikes!: number

  constructor(values: any = {}) {
    super(_.pick(values, Object.keys(properties)))
  }
}
