import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Row, Spacing, Icon, Text } from 'src/retired/elements'
import Chip from 'src/retired/shared/Chip'
import { Link } from 'src/navigation'
import { colors } from 'src/theme'
import { Platform } from 'src/utils'
import { formatAmount } from 'src/utils/format'
import { frequencyOptions } from 'src/utils/recurringDonations'
import DonationSchedule from 'src/entities/donationSchedule/model'
import { CardCompactWrapper } from 'src/components'

interface Props {
  donation: DonationSchedule
}

const RecurringDonation = ({ donation }: Props) => {
  const { t } = useTranslation()

  return (
    <Link to={`/donations/recurring/${donation.id}`}>
      <View
        style={{
          paddingBottom: 25,
        }}
      >
        <CardCompactWrapper cardColor={colors.blue} fullWidth>
          <View style={{ flexGrow: 1 }}>
            <Row style={{ justifyContent: 'space-between', flexGrow: 0 }}>
              <Text size={13} light>
                {t('date:dayMonth', { date: donation.startingAt })}
              </Text>
              {donation.amount ? (
                <Text size={15} bold>
                  {donation.getCurrencySymbol()}
                  {formatAmount(donation.amount)}
                </Text>
              ) : null}
            </Row>
            <Row style={{ justifyContent: 'flex-end', flexGrow: 0 }}>
              <Spacing marginTop={2}>
                <Chip type="square" backgroundColor="gray02" textSize={12}>
                  {frequencyOptions[donation.frequency].adverb}
                </Chip>
              </Spacing>
            </Row>
          </View>
          <Row style={{ justifyContent: 'space-between' }}>
            <View style={{ flex: 1, paddingRight: 15, alignSelf: 'flex-end' }}>
              <Text size={15} numberOfLines={Platform.OS === 'web' ? 1 : 2}>
                {donation.nonprofits?.map((nonprofit) => nonprofit.name)?.join(', ')}
              </Text>
            </View>

            <View style={{ alignSelf: 'flex-end' }}>
              <Icon icon="arrowCircleRightBlack" style={{ width: 26, height: 26 }} />
            </View>
          </Row>
        </CardCompactWrapper>
      </View>
    </Link>
  )
}

export default RecurringDonation
