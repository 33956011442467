import { PaymentMethodType } from '@joindeed/calculate-fees'

/** Types */
export enum DonationFrequency {
  Onetime = 'Onetime',
  Monthly = 'Monthly',
}

export type PaymentType = 'Other' | PaymentMethodType.Payroll
export type PayPalModel = 'DIRECT' | 'PPGF' | 'COMMERCE' | null
export type PayPalCountryCode = 'US' | 'CA' | 'AU' | 'GB' | 'IE'

/** Consts */
export const BASE_SPACING = 20
// missing logic so show on request
export const NOTICE_HIDE_IMPACT = true
export const SINGAPORE_CODE = 'SG'
