import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { ActionCause } from 'src/containers/modules/Actions/types'
import { Chip } from 'src/components/Chip/Chip'
import { useDeedTheme } from 'src/theme/ThemeProvider'

export const CauseChips = ({ causes }: { causes: ActionCause[] }) => {
  const { t } = useTranslation('causes')
  const { colors } = useDeedTheme()

  return (
    <>
      {causes.map((cause, index) => (
        <View key={index} style={{ marginRight: 10, marginBottom: 5 }}>
          <Chip
            label={cause.code ? t(`causes:${cause.code.toLowerCase()}-name`) : cause.name}
            style={{ borderWidth: 0, backgroundColor: colors.gray06 }}
            textColor={colors.black}
          />
        </View>
      ))}
    </>
  )
}
