import React from 'react'
import PropTypes from 'prop-types'

import config from 'src/config'
import Image from 'src/retired/elements/Image'
import { GMapCircle } from 'src/utils/map'
import truthy from 'src/utils/truthy'

const Map = ({ latitude, longitude, width = 640, height = 360, circle = 0 }) => {
  const widthRounded = Math.round(width)
  const heightRounded = Math.round(height)
  return (
    <Image
      style={{ width: widthRounded, height: heightRounded }}
      source={{
        uri: [
          'https://maps.googleapis.com/maps/api/staticmap',
          `?center=${latitude},${longitude}&size=${widthRounded}x${heightRounded}&scale=2&zoom=15`,
          `&markers=color:0x3185FC%7C${latitude},${longitude}`,
          circle && `&path=color:0x3185FC|fillcolor:0x3185FC|weight:1${GMapCircle(latitude, longitude, circle)}`,
          `&key=${config.googleMapsApiKey}`,
        ]
          .filter(truthy)
          .join(''),
      }}
      key={`${widthRounded}x${heightRounded}`}
      alt="Map"
    />
  )
}
Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  circle: PropTypes.number,
}

export default Map
